@charset "UTF-8";
/* stylelint-disable postman-stylelint/color-no-hex-rgb */ /*These two variables are legacy font-variables and must not be used*/
:root {
  /* DEPRECATED: The design tokens mentioned below are deprecated and will be removed in the upcoming releases.
     It is recommended to use the tokens defined above. */
  --text-font-regular: "Inter", "OpenSans", Helvetica, Arial, sans-serif;
  --text-font-code: "IBMPlexMono", "Cousine", monospace;
  --text-font-size-xs: 10px;
  --text-font-size-s: 11px;
  --text-font-size-m: 12px;
  --text-font-size-l: 14px;
  --text-font-size-xl: 16px;
  --text-font-size-xxl: 18px;
  --text-font-size-xxxl: 24px;
  --text-line-height-xs: 12px;
  --text-line-height-s: 16px;
  --text-line-height-m: 20px;
  --text-line-height-l: 24px;
  --text-line-height-xl: 28px;
  --text-line-height-xxl: 32px;
  --text-line-height-xxxl: 40px;
  --text-font-weight-regular: 400;
  --text-font-weight-medium: 600;
  --text-font-weight-bold: 600;
}

/* Variables */
/* Styles */
.btn {
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: var(--spacing-s) 10px;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  font-family: var(--text-family-default);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.btn use {
  fill: var(--content-color-secondary);
}
.btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.btn svg {
  color: var(--content-color-secondary);
}
.btn.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.btn-fluid {
  display: flex;
}

.btn-primary {
  background-color: var(--button-primary-background-color);
  box-shadow: 0 0 0 1px var(--button-primary-outline-color) inset;
  min-width: 100px;
  color: var(--button-primary-content-color);
}
.btn-primary use {
  fill: var(--button-primary-content-color);
}
.btn-primary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--button-primary-content-color);
}
.btn-primary svg {
  color: var(--button-primary-content-color);
}
.btn-primary .dropdown-caret use {
  fill: var(--button-primary-content-color);
}
.btn-primary .dropdown-caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--button-primary-content-color);
}
.btn-primary .dropdown-caret svg {
  color: var(--button-primary-content-color);
}
.btn-primary:hover, .btn-primary.is-hovered {
  background-color: var(--button-primary-hover-background-color);
}
.btn-primary:active, .btn-primary.is-active {
  background-color: var(--button-primary-active-background-color);
}
.btn-primary.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-primary.is-disabled:hover, .btn-primary.is-disabled.is-hovered {
  background-color: var(--button-primary-hover-background-color);
}
.btn-primary.is-disabled:active, .btn-primary.is-disabled.is-active {
  background-color: var(--button-primary-active-background-color);
}

.btn-secondary {
  background-color: var(--button-secondary-background-color);
  min-width: 100px;
  color: var(--content-color-primary);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
}
.btn-secondary use {
  fill: var(--content-color-primary);
}
.btn-secondary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.btn-secondary svg {
  color: var(--content-color-primary);
}
.btn-secondary:hover, .btn-secondary.is-hovered {
  background-color: var(--button-secondary-hover-background-color);
  color: var(--content-color-primary);
}
.btn-secondary:hover use, .btn-secondary.is-hovered use {
  fill: var(--content-color-primary);
}
.btn-secondary:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-secondary.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.btn-secondary:hover svg, .btn-secondary.is-hovered svg {
  color: var(--content-color-primary);
}
.btn-secondary:active, .btn-secondary.is-active {
  background-color: var(--button-secondary-active-background-color);
  color: var(--content-color-primary);
}
.btn-secondary:active use, .btn-secondary.is-active use {
  fill: var(--content-color-primary);
}
.btn-secondary:active svg:not([class*=PartnerIcon]) > path[fill], .btn-secondary.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.btn-secondary:active svg, .btn-secondary.is-active svg {
  color: var(--content-color-primary);
}
.btn-secondary.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-secondary.is-disabled:hover, .btn-secondary.is-disabled.is-hovered {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
}
.btn-secondary.is-disabled:hover use, .btn-secondary.is-disabled.is-hovered use {
  fill: var(--content-color-secondary);
}
.btn-secondary.is-disabled:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-secondary.is-disabled.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.btn-secondary.is-disabled:hover svg, .btn-secondary.is-disabled.is-hovered svg {
  color: var(--content-color-secondary);
}
.btn-secondary.is-disabled:active, .btn-secondary.is-disabled.is-active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
}
.btn-secondary.is-disabled:active use, .btn-secondary.is-disabled.is-active use {
  fill: var(--content-color-secondary);
}
.btn-secondary.is-disabled:active svg:not([class*=PartnerIcon]) > path[fill], .btn-secondary.is-disabled.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.btn-secondary.is-disabled:active svg, .btn-secondary.is-disabled.is-active svg {
  color: var(--content-color-secondary);
}

.dropdown-button .btn-secondary {
  background-color: transparent;
  box-shadow: 0 0 0 1px var(--input-border-color-default) inset;
}

.btn-tertiary {
  background-color: transparent;
  min-width: var(--size-m);
  height: var(--size-m);
  padding: var(--spacing-s);
  color: var(--content-color-secondary);
}
.btn-tertiary use {
  fill: var(--content-color-secondary);
}
.btn-tertiary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.btn-tertiary svg {
  color: var(--content-color-secondary);
}
.btn-tertiary:hover, .btn-tertiary.is-hovered {
  background-color: var(--highlight-background-color-primary);
  color: var(--content-color-primary);
}
.btn-tertiary:hover use, .btn-tertiary.is-hovered use {
  fill: var(--content-color-primary);
}
.btn-tertiary:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-tertiary.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.btn-tertiary:hover svg, .btn-tertiary.is-hovered svg {
  color: var(--content-color-primary);
}
.btn-tertiary:active, .btn-tertiary.is-active {
  background-color: var(--highlight-background-color-primary);
  color: var(--content-color-primary);
}
.btn-tertiary:active use, .btn-tertiary.is-active use {
  fill: var(--content-color-primary);
}
.btn-tertiary:active svg:not([class*=PartnerIcon]) > path[fill], .btn-tertiary.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.btn-tertiary:active svg, .btn-tertiary.is-active svg {
  color: var(--content-color-primary);
}
.btn-tertiary.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-tertiary.is-disabled:hover, .btn-tertiary.is-disabled.is-hovered {
  background-color: transparent;
  color: var(--content-color-secondary);
}
.btn-tertiary.is-disabled:hover use, .btn-tertiary.is-disabled.is-hovered use {
  fill: var(--content-color-secondary);
}
.btn-tertiary.is-disabled:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-tertiary.is-disabled.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.btn-tertiary.is-disabled:hover svg, .btn-tertiary.is-disabled.is-hovered svg {
  color: var(--content-color-secondary);
}
.btn-tertiary.is-disabled:active, .btn-tertiary.is-disabled.is-active {
  background-color: transparent;
  color: var(--content-color-secondary);
}
.btn-tertiary.is-disabled:active use, .btn-tertiary.is-disabled.is-active use {
  fill: var(--content-color-secondary);
}
.btn-tertiary.is-disabled:active svg:not([class*=PartnerIcon]) > path[fill], .btn-tertiary.is-disabled.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.btn-tertiary.is-disabled:active svg, .btn-tertiary.is-disabled.is-active svg {
  color: var(--content-color-secondary);
}

.btn-header {
  color: var(--header-color-content);
  background-color: var(--header-background-color-secondary);
}
.btn-header use {
  fill: var(--header-color-content);
}
.btn-header svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--header-color-content);
}
.btn-header svg {
  color: var(--header-color-content);
}
.btn-header .dropdown-caret use {
  fill: var(--header-color-content);
}
.btn-header .dropdown-caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--header-color-content);
}
.btn-header .dropdown-caret svg {
  color: var(--header-color-content);
}
.btn-header:hover, .btn-header.is-hovered {
  color: var(--content-color-constant);
  background-color: var(--header-background-highlight-color-secondary);
}
.btn-header:hover use, .btn-header.is-hovered use {
  fill: var(--content-color-constant);
}
.btn-header:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-header.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.btn-header:hover svg, .btn-header.is-hovered svg {
  color: var(--content-color-constant);
}
.btn-header:active, .btn-header.is-active {
  color: var(--content-color-constant);
  background-color: var(--header-background-highlight-color-secondary);
}
.btn-header:active use, .btn-header.is-active use {
  fill: var(--content-color-constant);
}
.btn-header:active svg:not([class*=PartnerIcon]) > path[fill], .btn-header.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.btn-header:active svg, .btn-header.is-active svg {
  color: var(--content-color-constant);
}
.btn-header.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-header.is-disabled:hover, .btn-header.is-disabled.is-hovered {
  background-color: var(--header-background-color-secondary);
  color: var(--header-color-content);
}
.btn-header.is-disabled:hover use, .btn-header.is-disabled.is-hovered use {
  fill: var(--header-color-content);
}
.btn-header.is-disabled:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-header.is-disabled.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--header-color-content);
}
.btn-header.is-disabled:hover svg, .btn-header.is-disabled.is-hovered svg {
  color: var(--header-color-content);
}
.btn-header.is-disabled:active, .btn-header.is-disabled.is-active {
  background-color: var(--header-background-color-secondary);
  color: var(--header-color-content);
}
.btn-header.is-disabled:active use, .btn-header.is-disabled.is-active use {
  fill: var(--header-color-content);
}
.btn-header.is-disabled:active svg:not([class*=PartnerIcon]) > path[fill], .btn-header.is-disabled.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--header-color-content);
}
.btn-header.is-disabled:active svg, .btn-header.is-disabled.is-active svg {
  color: var(--header-color-content);
}

.btn-text {
  color: var(--content-color-link);
  background-color: transparent;
  height: 20px;
}
.btn-text use {
  fill: var(--content-color-link);
}
.btn-text svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.btn-text svg {
  color: var(--content-color-link);
}
.btn-text:hover, .btn-text.is-hovered {
  background-color: transparent;
  color: var(--content-color-link);
}
.btn-text:hover use, .btn-text.is-hovered use {
  fill: var(--content-color-link);
}
.btn-text:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-text.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.btn-text:hover svg, .btn-text.is-hovered svg {
  color: var(--content-color-link);
}
.btn-text:active, .btn-text.is-active {
  background-color: transparent;
  color: var(--content-color-link);
}
.btn-text:active use, .btn-text.is-active use {
  fill: var(--content-color-link);
}
.btn-text:active svg:not([class*=PartnerIcon]) > path[fill], .btn-text.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.btn-text:active svg, .btn-text.is-active svg {
  color: var(--content-color-link);
}
.btn-text.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-text.is-disabled:hover, .btn-text.is-disabled.is-hovered {
  background-color: transparent;
  color: var(--content-color-link);
}
.btn-text.is-disabled:hover use, .btn-text.is-disabled.is-hovered use {
  fill: var(--content-color-link);
}
.btn-text.is-disabled:hover svg:not([class*=PartnerIcon]) > path[fill], .btn-text.is-disabled.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.btn-text.is-disabled:hover svg, .btn-text.is-disabled.is-hovered svg {
  color: var(--content-color-link);
}
.btn-text.is-disabled:active, .btn-text.is-disabled.is-active {
  background-color: transparent;
  color: var(--content-color-link);
}
.btn-text.is-disabled:active use, .btn-text.is-disabled.is-active use {
  fill: var(--content-color-link);
}
.btn-text.is-disabled:active svg:not([class*=PartnerIcon]) > path[fill], .btn-text.is-disabled.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.btn-text.is-disabled:active svg, .btn-text.is-disabled.is-active svg {
  color: var(--content-color-link);
}

.btn-small {
  height: var(--size-m);
  padding: var(--spacing-s) 10px;
  min-width: 60px;
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
}

.btn-medium {
  height: var(--size-m);
  padding: var(--spacing-s) 10px;
  min-width: 100px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}

.btn-huge {
  height: 50px;
  padding: var(--spacing-m) var(--spacing-l);
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
}

.btn-icon {
  background-color: var(--background-color-tertiary);
  height: var(--size-m);
  width: var(--size-m);
  padding: var(--spacing-zero);
}
.btn-icon:hover, .btn-icon.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.btn-icon:active, .btn-icon.is-active {
  background-color: var(--highlight-background-color-secondary);
}
.btn-icon.btn-icon-rect {
  width: 40px;
}
.btn-icon.btn-icon-circle {
  border-radius: calc(var(--size-m) * 0.5);
}
.btn-icon.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.btn-icon.is-disabled:hover, .btn-icon.is-disabled.is-hovered {
  background-color: var(--background-color-tertiary);
}
.btn-icon.is-disabled:active, .btn-icon.is-disabled.is-active {
  background-color: var(--background-color-tertiary);
}

/* Button Group */
.btn-group {
  display: flex;
  flex-direction: row;
}
.btn-group .btn {
  border-radius: 0;
}
.btn-group .btn:first-child {
  border-top-left-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
}
.btn-group .btn:last-child {
  border-top-right-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
}

.btn-group-separated .btn:not(:last-child) {
  border-right: var(--button-group-separator);
}

/* Tabs */
.tabs {
  display: inline-flex;
  flex-direction: row;
}
.tabs.tabs-fluid {
  display: flex;
}

.tabs-secondary {
  box-sizing: border-box;
  height: 30px;
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
}

.tabs-tertiary {
  box-sizing: border-box;
  height: 30px;
}

/* Tab */
.tab {
  flex: 0 0 auto;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
  text-align: center;
}
.tabs-fluid .tab {
  flex: 1;
}

.tab-primary,
.tab-primary-small {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
}
.tab-primary:hover, .tab-primary.is-hovered,
.tab-primary-small:hover,
.tab-primary-small.is-hovered {
  color: var(--content-color-primary);
}
.tab-primary.is-active,
.tab-primary-small.is-active {
  color: var(--content-color-primary);
}
.tab-primary.is-active .tab-text-wrapper,
.tab-primary-small.is-active .tab-text-wrapper {
  box-shadow: inset 0px -1px 0px var(--base-color-brand);
}
.tab-primary.is-disabled,
.tab-primary-small.is-disabled {
  color: var(--content-color-secondary);
  opacity: 0.4;
  cursor: not-allowed;
}

.tab-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-primary .tab-text-wrapper {
  margin-left: var(--spacing-m);
  padding: var(--spacing-s) var(--spacing-zero);
}

.tab-primary-small .tab-text-wrapper {
  margin: var(--spacing-zero) var(--spacing-s);
  padding: var(--spacing-s) var(--spacing-zero);
}

.tab-secondary {
  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-l);
  color: var(--content-color-secondary);
}
.tab-secondary:first-child {
  border-top-left-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
}
.tab-secondary:last-child {
  border-top-right-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
}
.tab-secondary:hover, .tab-secondary.is-hovered {
  color: var(--content-color-primary);
}
.tab-secondary:active, .tab-secondary.is-active {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-tertiary);
}

.tab-tertiary {
  padding: 6px 15px 6px 15px;
  color: var(--content-color-secondary);
  font-weight: 400;
}
.tab-tertiary:hover, .tab-tertiary.is-hovered {
  color: var(--content-color-primary);
  font-weight: 400;
}
.tab-tertiary:active, .tab-tertiary.is-active {
  color: var(--content-color-primary);
  font-weight: 400;
}

.tabs-vertical {
  flex-direction: column;
  text-align: left;
}
.tabs-vertical .tab {
  display: flex;
  align-items: center;
  color: var(--content-color-secondary);
  box-shadow: none;
  height: 35px;
  padding-left: 20px;
}
.tabs-vertical .tab.is-active {
  box-shadow: none;
  color: var(--content-color-primary);
  border-bottom: none;
}

.incomplete-tab-steps {
  color: var(--content-color-secondary);
  cursor: default;
}
.incomplete-tab-steps:hover, .incomplete-tab-steps.is-hovered {
  color: var(--content-color-secondary);
}

/* Variables */
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown.full-width {
  width: 100%;
}
.dropdown.full-width .dropdown-button .btn {
  width: 100%;
  justify-content: space-between;
}
.dropdown.scroll-menu .dropdown-menu {
  max-height: 120px;
  overflow-y: auto;
}

.dropdown-menu {
  padding: var(--spacing-s) var(--spacing-zero);
  box-sizing: border-box;
  min-width: 170px;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-strong);
}
.dropdown-menu .dropdown-menu-item--delete {
  color: var(--content-color-error);
}
.dropdown-menu .dropdown-menu-item--delete:hover {
  color: var(--content-color-constant);
  background-color: var(--base-color-error);
}
.dropdown-menu .dropdown-menu-item--delete:hover use {
  fill: var(--content-color-constant);
}
.dropdown-menu .dropdown-menu-item--delete:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.dropdown-menu .dropdown-menu-item--delete:hover svg {
  color: var(--content-color-constant);
}
.dropdown-menu .dropdown-menu-item--delete:hover .dropdown-menu-item-shortcut {
  color: var(--content-color-constant);
}
.dropdown-menu .is-destructive {
  color: var(--content-color-error);
}
.dropdown-menu .is-destructive:hover {
  color: var(--content-color-constant);
  background-color: var(--base-color-error);
}
.dropdown-menu .is-destructive:hover use {
  fill: var(--content-color-constant);
}
.dropdown-menu .is-destructive:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.dropdown-menu .is-destructive:hover svg {
  color: var(--content-color-constant);
}
.dropdown-menu .is-destructive:hover .dropdown-menu-item-shortcut {
  color: var(--content-color-constant);
}

.dropdown-menu-item-shortcut {
  color: var(--content-color-tertiary);
}

.dropdown-menu-item {
  position: relative;
  box-sizing: border-box;
  height: var(--controls-size-default);
  padding: var(--spacing-zero) var(--spacing-l);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  border-radius: var(--border-radius-default);
  margin: var(--spacing-zero) var(--spacing-s);
  display: flex;
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.dropdown-menu-item:hover, .dropdown-menu-item.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.dropdown-menu-item:hover .dropdown-menu-item-shortcut, .dropdown-menu-item.is-hovered .dropdown-menu-item-shortcut {
  color: var(--content-color-primary);
}
.dropdown-menu-item:focus, .dropdown-menu-item.is-focused {
  background-color: var(--highlight-background-color-secondary);
}
.dropdown-menu-item.align-right {
  text-align: right;
}
.dropdown-menu-item.is-focused {
  background: var(--highlight-background-color-secondary);
}
.dropdown-menu-item.is-selected {
  background: var(--highlight-background-color-tertiary);
}
.dropdown-menu-item.is-disabled {
  cursor: default;
  opacity: 0.4;
  position: relative;
}
.dropdown-menu-item.is-disabled .dropdown-menu-item__tooltip {
  position: absolute;
  padding: 10px;
}
.dropdown-menu-item span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu-item-icon {
  flex: 0 0 20px;
  margin-right: 10px;
}

.dropdown-menu-item-label {
  flex: 1;
}

.dropdown-caret {
  margin-left: 10px;
}
.dropdown-caret.pm-icon {
  display: inline-flex;
}
.btn-group-separated .dropdown-caret {
  margin-left: var(--spacing-zero);
}

.dropdown-sub-menu-item {
  position: absolute;
  top: 0;
  left: 100%;
  margin-top: var(--spacing-zero);
  visibility: hidden;
  border-radius: var(--border-radius-default);
}
.dropdown-sub-menu-item.show {
  visibility: visible;
}

.is-sub-item-available .expand-icon-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: var(--spacing-s);
  justify-content: flex-end;
  align-items: center;
}

.menu-item-header {
  font-size: var(--text-size-xs);
  text-transform: uppercase;
  color: var(--content-color-tertiary);
  height: var(--size-s);
  padding: var(--spacing-s) var(--spacing-l);
  box-sizing: border-box;
}

.textarea {
  width: 100%;
  background-color: var(--background-color-secondary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  outline: none;
  padding: var(--spacing-s);
  box-sizing: border-box;
  color: var(--content-color-primary);
  vertical-align: bottom;
  resize: vertical;
}
.textarea:hover, .textarea.is-hovered {
  background-color: var(--highlight-background-color-secondary);
  border-color: var(--border-color-strong);
}
.textarea:focus, .textarea.is-focused {
  background-color: var(--background-color-primary);
}
.textarea.textarea-warning {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-warning);
}
.textarea.textarea-error {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-error);
}

.textarea-warning-text {
  display: flex;
  font-size: var(--text-size-xs);
  color: var(--content-color-warning);
}

.textarea-error-text {
  display: flex;
  font-size: var(--text-size-xs);
  color: var(--content-color-error);
}

.texteditor-header {
  display: inline-flex;
  padding-bottom: var(--spacing-xs);
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}

/*
  TextEditor2 Styles
*/
.monaco-aria-container {
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.text-editor-wrapper {
  font-size: 12px;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
}

.text-editor {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  position: relative;
}
.text-editor > div {
  min-height: 0;
}
.text-editor .monaco-editor .monaco-placeholder {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  opacity: 0.6;
  cursor: text;
  line-height: var(--line-height-m);
}
.text-editor .monaco-editor .parameter-hints-widget {
  z-index: 100;
  margin-left: var(--spacing-s);
}
.text-editor .monaco-editor .suggest-widget {
  z-index: 101;
}
.text-editor .monaco-editor .monaco-editor-hover {
  z-index: 102;
}

.text-editor__lazy_loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.monaco-editor .suggest-widget p.docs {
  font-family: "IBMPlexMono", "Courier New", monospace;
}

.text-editor__feedback-text {
  margin-left: var(--spacing-s);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
}

.text-editor__loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.text-editor-tab {
  display: inline-flex;
  align-items: center;
}
.text-editor-tab .tab-beta-label {
  font-size: var(--text-size-xs);
  color: var(--content-color-info);
  font-weight: var(--text-weight-medium);
  position: relative;
  bottom: var(--spacing-xs);
  padding-left: var(--spacing-xs);
}
.text-editor-tab .tab-content-indicator {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-left: var(--spacing-xs);
  background-color: var(--base-color-success);
  display: inline-flex;
}

.text-editor-dropdown .text-editor-dropdown-button__label {
  text-transform: capitalize;
}

.text-editor__language-dropdown,
.text-editor__line-wrap-button {
  margin-left: var(--spacing-s);
}

.text-editor__line-wrap-button {
  background-color: var(--background-color-tertiary);
}
.text-editor__line-wrap-button.is-active {
  background-color: var(--background-color-tertiary);
}
.text-editor__line-wrap-button.is-active use {
  fill: var(--content-color-brand);
}
.text-editor__line-wrap-button.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.text-editor__line-wrap-button.is-active svg {
  color: var(--content-color-brand);
}
.text-editor__line-wrap-button:hover, .text-editor__line-wrap-button.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}

.text-editor__copy-button,
.text-editor__search-button {
  margin-right: var(--spacing-s);
  cursor: pointer;
}

.ReactModal__Overlay--after-open {
  background-color: var(--background-modal-backdrop) !important;
}

.modal {
  border-radius: var(--border-radius-l);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 120;
  font-family: var(--text-family-default);
  border: 1px solid var(--modal-border-color);
  box-shadow: var(--modal-box-shadow);
}
.modal .modal-header {
  border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
  flex: 0 0 48px;
  box-sizing: border-box;
}
.modal .modal-content {
  flex: 1;
  box-sizing: border-box;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.modal .modal-content.external-link {
  cursor: pointer;
  color: var(--content-color-link);
}
.modal .modal-content .learn-more-button {
  padding-left: var(--spacing-zero);
}
.modal .modal-footer {
  border-radius: 0 0 var(--border-radius-l) var(--border-radius-l);
  flex: 0 0 80px;
  box-sizing: border-box;
}

.modal-header {
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: row;
}
.modal-header .modal-header-title {
  border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}

.modal-header-title {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  padding: var(--spacing-m) var(--spacing-l);
}

.modal-header-close-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: var(--spacing-s);
  box-sizing: border-box;
}

.modal-header-close-button {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.modal-content {
  background-color: var(--background-color-primary);
  padding: var(--spacing-xl) var(--spacing-xl);
  color: var(--content-color-primary);
  overflow-y: auto;
}
.modal-content.is-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-footer {
  background-color: var(--background-color-primary);
  padding: var(--spacing-xl) var(--spacing-xl);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.modal-footer > .btn {
  margin-left: var(--spacing-m);
}
.modal-footer.is-separated {
  border-top: 1px solid var(--border-color-default);
}

.invite-signed-out-modal {
  height: 60vh;
}
.invite-signed-out-modal .modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
}
.invite-signed-out-modal .modal-content .btn-text {
  padding: var(--spacing-zero);
}
.invite-signed-out-modal .modal-content .modal-text .btn-text {
  padding: var(--spacing-zero) 3px;
}
.invite-signed-out-modal .signout-out-signin-btn {
  margin-top: var(--spacing-xxl);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}

.changelog-content hr {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  border-bottom-width: 0;
  color: var(--content-color-secondary);
}
.changelog-content a {
  color: var(--content-color-link);
  text-decoration: none;
}
.changelog-content img {
  width: 100%;
}

.release-notes-modal .release-notes-modal--view-changelog-link {
  padding-top: var(--spacing-xl);
  padding-left: var(--spacing-zero);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.tooltip {
  position: absolute;
  z-index: 1010;
  display: block;
  font-family: var(--text-family-default);
  margin: var(--spacing-zero);
  opacity: 0;
  max-width: 300px;
  padding: var(--spacing-zero) var(--spacing-xs);
  word-wrap: break-word;
}
.tooltip[data-x-out-of-boundaries] {
  display: none;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-wrapper {
  max-height: 500px;
  overflow-y: auto;
  padding: var(--spacing-s);
  color: var(--content-color-primary);
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--tooltip-border-color);
  box-shadow: var(--shadow-default);
}

.tooltip-header {
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-zero);
  margin-bottom: var(--spacing-m);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.tooltip-body {
  font-size: var(--text-size-m);
}
.tooltip-body .tooltip-body-title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  margin: var(--spacing-s);
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: var(--spacing-xs) var(--spacing-zero);
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
  filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.2));
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--background-color-secondary);
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 var(--spacing-xs);
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
  filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.2));
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--background-color-secondary);
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: var(--spacing-xs) 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
  filter: drop-shadow(-2px -2px 2px rgba(0, 0, 0, 0.2));
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--background-color-secondary);
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 var(--spacing-xs);
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
  filter: drop-shadow(2px -2px 1px rgba(0, 0, 0, 0.08));
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--background-color-secondary);
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.toggle-switch-container.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.toggle-switch {
  position: relative;
  width: var(--size-m);
  height: var(--size-xs);
  background: var(--switch-track-unchecked-background-color);
  border-radius: 8px;
  border: var(--border-width-default) var(--border-style-solid) var(--switch-track-unchecked-border-color);
  box-sizing: border-box;
}
.toggle-switch.is-active {
  background: var(--switch-track-checked-background-color);
  border-color: var(--switch-track-checked-border-color);
}
.toggle-switch:before {
  content: " ";
  position: absolute;
  height: 12px;
  width: 12px;
  top: 1px;
  left: 1px;
  border-radius: 6px;
  background: var(--switch-thumb-background-color);
}
.toggle-switch.is-active:before {
  right: 1px;
  left: initial;
  background: var(--switch-thumb-background-color);
}

.toggle-switch-text {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);
}
.toggle-switch-text .toggle-switch-text-on {
  color: var(--content-color-primary);
}
.toggle-switch-text .toggle-switch-text-off {
  color: var(--content-color-secondary);
}

.drop-files-dropzone {
  display: flex;
  min-width: 100px;
  min-height: 280px;
  align-items: center;
  cursor: pointer;
}
.drop-files-dropzone.is-entered {
  background-color: var(--background-color-primary);
}
.drop-files-dropzone.is-accepted {
  background-color: var(--background-color-secondary);
}
.drop-files-dropzone.is-rejected {
  background-color: var(--background-color-secondary);
}

.drop-files-dropzone-text {
  flex: 1;
  padding-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.drop-files-inner-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.drop-files-inner-container .drop-files-dropzone-new-formats {
  margin-top: var(--spacing-l);
}

.drop-file-import-icon {
  background-color: transparent;
  transform: scale(2);
  padding-right: 18px;
}

.drop-file-import-icon2 {
  background-color: var(--highlight-background-color-tertiary);
  padding: 5px;
  border-radius: var(--border-radius-max);
  transform: scale(2);
}

.drop-file-import-icon::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: var(--spacing-xl);
  height: var(--spacing-xl);
  background-color: var(--background-color-tertiary);
  border-radius: 50%;
  top: 50%;
  left: 24%;
  transform: translate(-50%, -50%);
}

.drop-overlay-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
@keyframes indeterminateProgress {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 7000px 0;
  }
}
.progress-bar {
  background-color: var(--background-color-tertiary);
  margin-top: 8px;
  height: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.progress-bar .indeterminate {
  height: 100%;
  background-image: -webkit-repeating-linear-gradient(-45deg, #F8A97B 0px, #F8A97B 40px, #F47023 41px, #F47023 80px);
  background-repeat: repeat-x;
  animation: indeterminateProgress 60s linear infinite;
}
.progress-bar .determinate {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
}
.progress-bar .determinate.success {
  background-color: var(--base-color-success);
}
.progress-bar .determinate.warning {
  background-color: var(--base-color-warning);
}
.progress-bar .determinate.error {
  background-color: var(--base-color-error);
}

@-webkit-keyframes bounce-middle {
  0% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
  50% {
    height: 20px;
    margin-top: var(--spacing-zero);
    margin-bottom: var(--spacing-zero);
  }
  100% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
}
@keyframes bounce-middle {
  0% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
  50% {
    height: 20px;
    margin-top: var(--spacing-zero);
    margin-bottom: var(--spacing-zero);
  }
  100% {
    height: 4px;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
}
.loading-indicator-wrapper {
  height: 20px;
}
.loading-indicator-wrapper .loading-indicator {
  position: relative;
  display: inline-block;
  -webkit-animation: bounce-middle 0.6s ease 0.1s infinite;
  animation: bounce-middle 0.6s ease 0.1s infinite;
}
.loading-indicator-wrapper .loading-indicator, .loading-indicator-wrapper .loading-indicator:before, .loading-indicator-wrapper .loading-indicator:after {
  width: 4px;
  height: 20px;
  border-radius: var(--border-radius-s);
  background-color: var(--highlight-background-color-tertiary);
}
.loading-indicator-wrapper .loading-indicator:before, .loading-indicator-wrapper .loading-indicator:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-10px) translateZ(0);
  transform: translateY(-10px) translateZ(0);
}
.loading-indicator-wrapper .loading-indicator:before {
  left: -6px;
  -webkit-animation: bounce-middle 0.6s ease 0s infinite;
  animation: bounce-middle 0.6s ease 0s infinite;
}
.loading-indicator-wrapper .loading-indicator:after {
  left: 6px;
  -webkit-animation: bounce-middle 0.6s ease 0.2s infinite;
  animation: bounce-middle 0.6s ease 0.2s infinite;
}

.radial-progress {
  position: relative;
}
.radial-progress .progress {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(270deg);
  stroke-width: 3px;
}
.radial-progress .progress .radial-progress__progress {
  z-index: 2;
  transition: stroke-dashoffset 1s;
}
.radial-progress .progress .radial-progress__background {
  stroke: var(--background-color-tertiary);
}
.radial-progress.is-running .progress {
  stroke: var(--base-color-info);
}
.radial-progress.is-running:after {
  color: var(--base-color-info);
}
.radial-progress.is-finished .progress {
  stroke: var(--base-color-success);
}
.radial-progress.is-finished:after {
  color: var(--base-color-success);
}
.radial-progress:after {
  content: attr(data-progress);
  position: absolute;
}

.expandable-tooltip {
  display: flex;
  flex-direction: column;
}
.expandable-tooltip .expandable-tooltip__item__header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.expandable-tooltip .expandable-tooltip__item__header .expandable-tooltip__item__expand {
  display: flex;
  align-items: center;
  justify-content: center;
}
.expandable-tooltip .expandable-tooltip__item__header .expandable-tooltip__item__title {
  display: flex;
  flex: 1;
}
.expandable-tooltip .expandable-tooltip__item__body--string {
  display: flex;
  align-items: center;
}
.expandable-tooltip .expandable-tooltip__item__body--json {
  display: flex;
  align-items: flex-start;
}
.expandable-tooltip .expandable-tooltip__item__body--json .expandable-tooltip__item__body__key {
  flex: 0 0 auto;
}

.expandable-tooltip {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  position: absolute;
  left: 75px;
  top: 25px;
}
.expandable-tooltip.bottom:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 15px;
  left: 5px;
  border: 7px solid transparent;
  border-bottom-color: var(--background-color-secondary);
  z-index: 2;
}
.expandable-tooltip.top:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  left: 5px;
  border: 7px solid transparent;
  border-top-color: var(--background-color-secondary);
  z-index: 2;
}
.expandable-tooltip .expandable-tooltip__item {
  border-bottom: 1px solid var(--border-color-default);
  border-radius: 2px;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__header {
  height: 30px;
  border-bottom: 1px solid var(--border-color-default);
  padding: 0 10px;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__header .expandable-tooltip__item__expand {
  height: 100%;
  width: 30px;
  cursor: pointer;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__title {
  margin-right: 10px;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__length {
  margin-left: 10px;
  color: var(--content-color-secondary);
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--string {
  height: auto;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--string pre {
  font-family: "IBMPlexMono", "Cousine", monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--string .expandable-tooltip__item__body__unavailable {
  padding: 5px 0;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--link {
  height: auto;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--link pre {
  font-family: "IBMPlexMono", "Cousine", monospace;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  cursor: pointer !important;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--link :hover {
  color: var(--content-color-link);
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--link .expandable-tooltip__item__body__unavailable {
  padding: 5px 0;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--json .expandable-tooltip__item__body__key {
  padding-top: var(--spacing-xs);
  color: var(--content-color-primary);
  font-weight: 700;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.expandable-tooltip .expandable-tooltip__item .expandable-tooltip__item__body--json .expandable-tooltip__item__body__value {
  padding-top: 3px;
  word-break: break-all;
  word-wrap: break-word;
  font-family: "IBMPlexMono", "Cousine", monospace;
  color: var(--content-color-secondary);
  padding-left: 5px;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.expandable-tooltip .expandable-tooltip__body {
  position: absolute;
  left: -10px;
  width: 480px;
  max-height: 360px;
  overflow-y: auto;
  background-color: var(--background-color-secondary);
  border-radius: 2px;
  z-index: 1;
  box-shadow: var(--shadow-default);
}
.expandable-tooltip .expandable-tooltip__body.bottom {
  top: 30px;
}
.expandable-tooltip .expandable-tooltip__body.top {
  bottom: 30px;
}
.expandable-tooltip .expandable-tooltip__body .expandable-tooltip__item__body {
  padding: 2px 20px;
  max-width: 480px;
}
.expandable-tooltip:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  top: -13px;
  border: 7px solid transparent;
  border-bottom-color: var(--background-color-secondary);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.diff-overlay-wrapper {
  display: flex;
  min-height: 100%;
}
.diff-overlay-wrapper .diff-char {
  padding: 20px;
}

.diff-view-modal-content {
  padding: 0;
}

.diff-line {
  display: flex;
  align-items: center;
}

.diff-wrapper {
  padding-top: 10px;
  margin: 0;
  overflow: visible;
  font-size: 12px;
  border-spacing: 0 1px;
  flex: 1;
}
.diff-wrapper.is-overlayed {
  padding: 2px;
  overflow: hidden;
}
.diff-wrapper .diff-normal {
  color: var(--content-color-primary);
  background: transparent;
}
.diff-wrapper .diff-added {
  margin: 1px 0;
  color: var(--content-color-success);
  background-color: var(--background-color-success);
}
.diff-wrapper .diff-removed {
  color: var(--content-color-error);
  background-color: var(--background-color-error);
}
.diff-wrapper .diff-text-wrapper {
  height: 15px;
  margin: 1px 0;
  line-height: 15px;
}
.diff-wrapper .diff-text-line {
  margin-right: 20px;
}

.is-expandable {
  position: relative;
  min-height: 40px;
  overflow: hidden;
  cursor: pointer;
  transition: all linear 0.1s;
}
.is-expandable:hover, .is-expandable.is-hovered {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.is-expandable:hover:before, .is-expandable.is-hovered:before {
  bottom: 0;
}
.is-expandable:before {
  position: absolute;
  right: 0;
  bottom: -40px;
  left: 0;
  z-index: 1;
  display: block;
  width: 100px;
  height: 25px;
  margin: 10px auto;
  font-size: 10px;
  line-height: 25px;
  color: var(--content-color-constant);
  text-align: center;
  cursor: pointer;
  content: "Click to expand";
  background: rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  transition: bottom cubic-bezier(0.22, 0.61, 0.36, 1) 0.1s;
}

.diff-lines-numbers-container {
  display: flex;
  padding: 10px 0px 20px 0;
  background: var(--background-color-tertiary);
}

.diff-line-numbers-wrapper {
  display: flex;
  flex-direction: column;
  width: 30px;
  color: var(--content-color-secondary);
  justify-content: flex-start;
  align-items: center;
}

.diff-line-numbers {
  height: 14px;
  padding: 1px 5px;
  margin: 0;
}

.input-select-wrapper {
  align-items: center;
  background-color: var(--background-color-tertiary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  display: flex;
  height: var(--controls-size-default);
  position: relative;
  width: 210px;
}
.input-select-wrapper.highlight {
  background-color: var(--highlight-background-color-secondary);
}
.input-select-wrapper:hover {
  background-color: var(--highlight-background-color-secondary);
}
.input-select-wrapper.is-open {
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-strong);
}
.input-select-wrapper .input-search-group {
  flex: 1;
  background: none;
  border: 0;
  border-radius: 0;
  padding-right: 0;
}
.input-select-wrapper .input-search-group .input {
  font-size: 12px;
}
.input-select-wrapper .input-search-group .input::-webkit-input-placeholder {
  font-size: 12px;
}
.input-select-wrapper .dropdown-button {
  align-self: center;
  border-left: 0;
  background: none;
  border-radius: 0;
  flex: 0 0 30px;
  height: 30px;
  margin-left: auto;
  padding: 0;
}
.input-select-wrapper .dropdown-button .dropdown-caret {
  margin-left: 0;
}
.input-select-wrapper .input-select-list {
  background: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  list-style: none;
  margin: 0;
  max-height: 420px;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 35px;
  width: 110%;
  z-index: 50;
  box-shadow: var(--shadow-default);
}
.input-select-wrapper .input-select-list .item {
  background: none;
  box-sizing: border-box;
  color: var(--content-color-primary);
  cursor: pointer;
  font-size: 12px;
  padding: 8px;
  white-space: pre;
  overflow: hidden;
  border-radius: var(--border-radius-default);
}
.input-select-wrapper .input-select-list .item.is-focused {
  background: var(--highlight-background-color-secondary);
}
.input-select-wrapper .input-select-list .item.is-selected {
  background: var(--highlight-background-color-secondary);
}
.input-select-wrapper .input-select-list .item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.input-select-wrapper .input-select-list .item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.input-select-v2-wrapper {
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--input-border-color-default);
  border-radius: var(--border-radius-default);
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: var(--controls-size-default);
  position: relative;
  width: 210px;
}
.input-select-v2-wrapper.is-disabled {
  background-color: var(--background-color-secondary);
  border-color: var(--border-color-strong);
  cursor: not-allowed;
}
.input-select-v2-wrapper.is-disabled .input {
  cursor: not-allowed;
}
.input-select-v2-wrapper.highlight {
  background-color: var(--highlight-background-color-secondary);
}
.input-select-v2-wrapper.is-open {
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.input-select-v2-wrapper .input-search-group {
  flex: 1;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding-right: var(--spacing-zero);
}
.input-select-v2-wrapper .input-search-group .input {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
}
.input-select-v2-wrapper .input-search-group .input::-webkit-input-placeholder {
  font-size: var(--text-size-m);
}
.input-select-v2-wrapper .dropdown-button {
  align-self: center;
  border-left: 0;
  background: transparent;
  border-radius: 0;
  flex: 0 0 30px;
  height: var(--controls-size-default);
  margin-left: auto;
  padding: var(--spacing-zero);
}
.input-select-v2-wrapper .dropdown-button .dropdown-caret {
  margin-left: var(--spacing-zero);
}

.input-select-v2-dropdown-menu .dropdown-menu-item {
  cursor: pointer;
}
.input-select-v2-dropdown-menu .dropdown-menu-item .input-select-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper {
  overflow: hidden;
  display: flex;
  flex: 1;
  white-space: nowrap;
  align-items: center;
}
.input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions {
  visibility: hidden;
  display: flex;
}
.input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--size-m);
  width: var(--size-m);
}
.input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action:hover use, .input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action.is-hovered use {
  fill: var(--content-color-brand);
}
.input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action:hover svg:not([class*=PartnerIcon]) > path[fill], .input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action:hover svg, .input-select-v2-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action.is-hovered svg {
  color: var(--content-color-brand);
}
.input-select-v2-dropdown-menu .dropdown-menu-item:hover .item-wrapper .item-actions, .input-select-v2-dropdown-menu .dropdown-menu-item.is-hovered .item-wrapper .item-actions {
  visibility: visible;
}
.input-select-v2-dropdown-menu .dropdown-menu-item.is-selected .highlight {
  color: var(--content-color-brand);
}
.input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--save_method {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result {
  color: var(--content-color-tertiary);
  cursor: default;
}
.input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result:hover, .input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result.is-hovered {
  background-color: inherit;
}
.input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result.is-focused {
  background-color: inherit;
}
.input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods {
  color: var(--content-color-tertiary);
  cursor: default;
}
.input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods:hover, .input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods.is-hovered {
  background-color: inherit;
}
.input-select-v2-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods.is-focused {
  background-color: inherit;
}
.input-select-v2-dropdown-menu.use-browser-tabs-header .dropdown-menu {
  min-width: 130px;
}

.input-select-v2-dropdown-menu--banner .dropdown-menu {
  padding-bottom: 0px;
}

.pm-list {
  overflow-y: scroll;
}

.pm-row {
  overflow-x: scroll;
  display: flex;
}
.pm-row::-webkit-scrollbar {
  display: none;
}

.inline-input__wrapper {
  width: 95%;
}
.inline-input__wrapper .input-box {
  border-color: var(--border-color-strong);
  border-radius: var(--border-radius-s);
  font-size: inherit;
  padding: 2px var(--spacing-m);
  height: auto;
}
.inline-input__wrapper .input-box.is-error {
  border-color: var(--base-color-error);
}

.inline-input__placeholder {
  word-break: break-all;
}

.inline-editor-wrapper .inline-editor__text-editor-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}
.inline-editor-wrapper .inline-editor__text-editor-wrapper .inline-editor__text-editor-body {
  position: relative;
}
.inline-editor-wrapper .inline-editor__text-editor-wrapper .inline-editor__text-editor-monaco {
  border-radius: var(--border-radius-default);
}
.inline-editor-wrapper .inline-editor__text-editor-wrapper .inline-editor__text-editor-monaco .overflow-guard {
  border-radius: var(--border-radius-default);
}
.inline-editor-wrapper .inline-editor__text-editor-wrapper .inline-editor__text-editor-monaco .monaco-placeholder {
  color: var(--content-color-secondary);
  font-size: var(--text-size-xs);
  opacity: 0.6;
  cursor: text;
  line-height: var(--line-height-s);
}
.inline-editor-wrapper .inline-editor__text-editor-wrapper .inline-editor__text-editor {
  min-height: 80px;
}
.inline-editor-wrapper .inline-editor__text-editor-wrapper .inline-editor__text-editor .ace_active-line {
  background: none;
}
.inline-editor-wrapper .inline-editor__text-editor-footer {
  display: flex;
  justify-content: space-between;
}
.inline-editor-wrapper .inline-editor__text-editor-footer .inline-editor__help-text {
  font-size: var(--text-size-xs);
  color: var(--content-color-tertiary);
  line-height: var(--text-size-xxxl);
}
.inline-editor-wrapper .inline-editor__actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
}
.inline-editor-wrapper .inline-editor__cancel-button {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  padding: var(--spacing-s);
  text-align: center;
  width: 50px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.inline-editor-wrapper .inline-editor__cancel-button:hover, .inline-editor-wrapper .inline-editor__cancel-button.is-hovered {
  color: var(--content-color-primary);
}
.inline-editor-wrapper .inline-editor__update-button {
  min-width: 65px;
}

.inline-editor-text-wrapper-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.inline-editor-text-wrapper {
  align-items: flex-start;
  display: flex;
  position: relative;
}
.inline-editor-text-wrapper .inline-editor__add__link-wrapper {
  display: flex;
}
.inline-editor-text-wrapper .inline-editor__add__link {
  color: var(--content-color-link);
  cursor: pointer;
  font-size: var(--text-size-s);
}
.inline-editor-text-wrapper .inline-editor-text {
  color: var(--content-color-secondary);
  font-family: var(--text-family-default);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  overflow: hidden;
  position: relative;
  color: var(--content-color-primary);
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.inline-editor-text-wrapper .inline-editor-text h1,
.inline-editor-text-wrapper .inline-editor-text h2,
.inline-editor-text-wrapper .inline-editor-text h3,
.inline-editor-text-wrapper .inline-editor-text h4,
.inline-editor-text-wrapper .inline-editor-text h5,
.inline-editor-text-wrapper .inline-editor-text h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.inline-editor-text-wrapper .inline-editor-text h1 {
  font-size: 18px;
  font-weight: 700;
}
.inline-editor-text-wrapper .inline-editor-text h2 {
  font-size: 16px;
  font-weight: 700;
}
.inline-editor-text-wrapper .inline-editor-text h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.inline-editor-text-wrapper .inline-editor-text h4 {
  font-size: 14px;
  font-weight: 700;
}
.inline-editor-text-wrapper .inline-editor-text h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.inline-editor-text-wrapper .inline-editor-text h6 {
  font-size: 12px;
  font-weight: 600;
}
.inline-editor-text-wrapper .inline-editor-text hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.inline-editor-text-wrapper .inline-editor-text blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.inline-editor-text-wrapper .inline-editor-text blockquote blockquote {
  margin-left: 20px;
}
.inline-editor-text-wrapper .inline-editor-text p, .inline-editor-text-wrapper .inline-editor-text span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.inline-editor-text-wrapper .inline-editor-text em {
  font-style: italic;
}
.inline-editor-text-wrapper .inline-editor-text strong {
  font-weight: var(--text-weight-medium);
}
.inline-editor-text-wrapper .inline-editor-text ul, .inline-editor-text-wrapper .inline-editor-text ol {
  margin: 5px;
}
.inline-editor-text-wrapper .inline-editor-text a {
  color: var(--content-color-link);
  text-decoration: none;
}
.inline-editor-text-wrapper .inline-editor-text a:hover {
  text-decoration: underline;
}
.inline-editor-text-wrapper .inline-editor-text pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.inline-editor-text-wrapper .inline-editor-text pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.inline-editor-text-wrapper .inline-editor-text code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.inline-editor-text-wrapper .inline-editor-text table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.inline-editor-text-wrapper .inline-editor-text table th,
.inline-editor-text-wrapper .inline-editor-text table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.inline-editor-text-wrapper .inline-editor-text table th:first-child,
.inline-editor-text-wrapper .inline-editor-text table td:first-child {
  border-left: none;
}
.inline-editor-text-wrapper .inline-editor-text table th:last-child,
.inline-editor-text-wrapper .inline-editor-text table td:last-child {
  border-right: none;
}
.inline-editor-text-wrapper .inline-editor-text table th {
  border-top: none;
}
.inline-editor-text-wrapper .inline-editor-text table tr:last-child th,
.inline-editor-text-wrapper .inline-editor-text table tr:last-child td {
  border-bottom: none;
}
.inline-editor-text-wrapper .inline-editor-text img {
  max-width: 100%;
}
.inline-editor-text-wrapper .inline-editor-text p {
  word-break: break-word;
}
.inline-editor-text-wrapper .inline-editor-text__edit-icon-wrapper {
  cursor: pointer;
  display: flex;
  margin-left: var(--spacing-xs);
  padding: var(--spacing-xs);
}
.inline-editor-text-wrapper .inline-editor-text__edit-icon {
  cursor: pointer;
  visibility: hidden;
}
.inline-editor-text-wrapper:hover.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon, .inline-editor-text-wrapper.is-hovered.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon {
  visibility: visible;
}
.inline-editor-text-wrapper:hover.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon use, .inline-editor-text-wrapper.is-hovered.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon use {
  fill: var(--content-color-secondary);
}
.inline-editor-text-wrapper:hover.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon svg:not([class*=PartnerIcon]) > path[fill], .inline-editor-text-wrapper.is-hovered.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.inline-editor-text-wrapper:hover.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon svg, .inline-editor-text-wrapper.is-hovered.inline-editor-text-wrapper--editable .inline-editor-text__edit-icon svg {
  color: var(--content-color-secondary);
}

.inline-editor__view-more-wrapper {
  display: flex;
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
}
.inline-editor__view-more-wrapper .inline-editor__view-more {
  color: var(--content-color-link);
  cursor: pointer;
  flex: 1;
  font-size: var(--text-size-m);
}

.inline-editor__markdown-link {
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-xs);
  font-size: var(--text-size-xs);
}

.breadcrumb__dropdown {
  position: absolute;
}
.breadcrumb__dropdown .breadcrumb__dropdown-button-wrapper {
  border: none;
  height: auto;
  left: -2px;
  padding: 0;
  position: relative;
  width: auto;
}
.breadcrumb__dropdown .breadcrumb__dropdown-button-wrapper .breadcrumb__dropdown-button {
  position: relative;
}
.breadcrumb__dropdown .dropdown-menu {
  left: -8.5px;
  max-width: 250px;
}
.breadcrumb__dropdown .dropdown-menu .dropdown-menu-item .dropdown-menu-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.breadcrumb__dropdown .dropdown-menu-item-icon {
  margin-right: 10px;
}

.breadcrumb {
  overflow: hidden;
  padding-right: 15px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.breadcrumb:after {
  content: "/";
  position: absolute;
  right: -1px;
  top: 0;
  width: 12px;
}
.breadcrumb .breadcrumb__name {
  padding-right: 2px;
  cursor: pointer;
}
.breadcrumb .breadcrumb__name:hover, .breadcrumb .breadcrumb__name.is-hovered {
  text-decoration: underline;
}
.breadcrumb:last-child {
  padding-right: unset;
}
.breadcrumb:last-child .breadcrumb__name {
  color: var(--content-color-primary);
  text-decoration: unset;
  cursor: unset;
}
.breadcrumb:last-child:after {
  content: "";
}

.breadcrumb__container {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.breadcrumb__container .dropdown-breadcrumb__separator {
  margin: 0 5px 0 30px;
}
.breadcrumb__container .breadcrumb__text {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-m);
  color: var(--content-color-tertiary);
}

.auto-suggest-group {
  display: flex;
  flex-direction: row;
}
.auto-suggest-group > * {
  flex: 1;
}

.read-only {
  cursor: not-allowed;
  color: var(--content-color-secondary);
}
.read-only:hover {
  opacity: 1 !important;
}

.auto-suggest {
  position: relative;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  flex: 1;
  align-self: flex-start;
}
.auto-suggest.is-focused:not(.show-content-inline) .public-DraftStyleDefault-block {
  white-space: normal !important;
}
.auto-suggest.is-focused {
  z-index: 2;
}
.auto-suggest.is-focused .public-DraftStyleDefault-block {
  text-overflow: unset;
}
.auto-suggest.is-focused .public-DraftEditor-content {
  display: flex;
  flex: 1 auto;
  width: 0;
}
.auto-suggest.is-focused .public-DraftEditor-content > div {
  flex: 1;
  overflow: hidden;
}
.auto-suggest.is-focused .public-DraftEditor-content > div > div:not(:first-child) {
  display: block;
}
.auto-suggest.is-focused .auto-suggest-box {
  z-index: 10;
}
.auto-suggest.is-focused .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:first-child::after {
  content: none;
}
.auto-suggest.is-focused .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:first-child .public-DraftStyleDefault-block > span br {
  display: block;
}
.auto-suggest.is-overridden .public-DraftStyleDefault-block {
  text-decoration: line-through;
  color: var(--content-color-tertiary);
}
.auto-suggest:not(.is-focused) .auto-suggest-cell--multiline.auto-suggest__collapsible .public-DraftEditor-content > div {
  display: flex;
}
.auto-suggest:not(.is-focused) .auto-suggest-cell--multiline.auto-suggest__collapsible .public-DraftEditor-content > div > div {
  display: block;
}
.auto-suggest:not(.is-focused) .auto-suggest-cell--multiline:not(.auto-suggest__collapsible) .public-DraftEditor-content div[data-block=true]:first-child::after {
  content: "...";
  opacity: 0.4;
}
.auto-suggest:not(.is-focused) .auto-suggest-cell--multiline div[data-block=true]:first-child {
  display: flex;
}
.auto-suggest:not(.is-focused) .auto-suggest__overflown div[data-block=true]:first-child {
  display: flex;
}
.auto-suggest:not(.is-focused) .auto-suggest__overflown div[data-block=true]:first-child .public-DraftStyleDefault-block {
  margin-right: 2px;
  height: 18px;
}
.auto-suggest:not(.is-focused) .auto-suggest__overflown div[data-block=true]:first-child::after {
  content: "...";
  opacity: 0.4;
}
.auto-suggest:not(.is-focused) .auto-suggest__multiline-overflown .public-DraftEditor-content::after {
  content: "...";
  opacity: 0.4;
}
.auto-suggest .DraftEditor-root {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.auto-suggest .auto-suggest-cell {
  padding: 0px 3px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.auto-suggest .auto-suggest-box {
  padding: 6px 10px;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--input-border-color-default);
}
.auto-suggest .auto-suggest-box.read-only {
  background-color: var(--background-color-secondary);
}
.auto-suggest .auto-suggest__info_wrapper {
  display: flex;
  flex-direction: row;
}
.auto-suggest .auto-suggest__info_wrapper .auto-suggest__info_span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.auto-suggest .auto-suggest__info_wrapper .auto-suggest__info_button {
  margin-left: var(--spacing-s);
  display: flex;
  align-items: center;
}
.auto-suggest .auto-suggest-preview-info__wrapper {
  display: flex;
}
.auto-suggest .auto-suggest-preview-info__wrapper .auto-suggest-preview-info__span {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.auto-suggest .auto-suggest-preview-info__wrapper .auto-suggest-preview-info_button {
  display: flex;
  margin-left: var(--spacing-s);
  align-items: center;
}
.auto-suggest .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:first-child .public-DraftStyleDefault-block > span br {
  display: none;
}
.auto-suggest .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:not(:last-child) .public-DraftStyleDefault-block > span br {
  display: none;
}
.auto-suggest .auto-suggest__fixed-max-height .public-DraftEditor-content {
  overflow: auto;
  max-height: 200px;
  align-items: flex-start;
}
.auto-suggest.show-whitespaces.has-error .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:not(:last-child) .public-DraftStyleDefault-block > span br {
  display: none;
}
.auto-suggest.show-whitespaces.has-error .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:not(:last-child) .public-DraftStyleDefault-block::after {
  content: "↵";
  font-size: 9px;
  color: var(--content-color-tertiary);
  padding: 3px 3px 1px;
  background: var(--background-color-error);
}
.auto-suggest.show-whitespaces:not(.has-error) .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:not(:last-child) .public-DraftStyleDefault-block > span br {
  display: none;
}
.auto-suggest.show-whitespaces:not(.has-error) .auto-suggest-cell--multiline .public-DraftEditor-content div[data-block=true]:not(:last-child) .public-DraftStyleDefault-block::after {
  content: "↵";
  opacity: 0.4;
  font-size: var(--spacing-s);
  padding: 5px 3px;
}
.auto-suggest .public-DraftEditorPlaceholder-root {
  color: var(--content-color-tertiary);
  white-space: nowrap;
}
.auto-suggest .DraftEditor-editorContainer {
  display: flex;
  flex: 1;
  min-width: 0;
}
.auto-suggest .public-DraftEditor-content {
  display: flex;
  flex: 1;
  width: 0;
  align-items: center;
  position: relative;
}
.auto-suggest .public-DraftEditor-content > div {
  flex: 1;
  overflow: hidden;
}
.auto-suggest .public-DraftEditor-content > div > div:not(:first-child) {
  display: none;
}
.auto-suggest .is-focused .public-DraftStyleDefault-block {
  text-overflow: unset;
}
.auto-suggest .public-DraftStyleDefault-block {
  white-space: nowrap !important;
  overflow: hidden;
}
.auto-suggest .public-DraftStyleDefault-block::-webkit-scrollbar {
  display: none;
}
.auto-suggest .inlineVariable:first-child {
  margin-left: var(--spacing-zero);
}
.auto-suggest .resolvedVariable, .auto-suggest .unresolvedVariable {
  padding: var(--spacing-zero) 2px 1px;
  margin: var(--spacing-zero) 1px;
  border-radius: var(--border-radius-default);
  font-size: 12px;
  cursor: text;
}
.auto-suggest .inlineVariable + .inlineVariable {
  margin-left: var(--spacing-zero);
}
.auto-suggest .unresolvedVariable {
  background: var(--background-color-error);
  color: var(--content-color-error);
  border: 1px dashed var(--variable-unresolved-border-color);
}
.auto-suggest .unresolvedVariable:hover {
  color: var(--variable-unresolved-hover-color);
  border: 1px dashed var(--variable-unresolved-hover-border-color);
}
.auto-suggest .resolvedVariable {
  background: var(--background-color-secondary);
  color: var(--content-color-info);
  border: 1px solid var(--border-color-default);
}
.auto-suggest .resolvedVariable:hover {
  border: 1px solid var(--content-color-tertiary);
  background: var(--background-color-tertiary);
}

.show-whitespaces .wf__item {
  white-space: pre-wrap;
}
.show-whitespaces .wf__item .wf__tk {
  word-break: break-all;
  line-height: 14px;
}
.show-whitespaces .wf__item .wf__ch {
  opacity: 0.6;
  margin: 1px;
}
.show-whitespaces .wf__item .wf__qt::after {
  content: '"';
  opacity: 0.6;
}
.show-whitespaces .wf__sp {
  opacity: 1;
  font-family: monospace;
  position: relative;
}
.show-whitespaces .wf__sp::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 2px;
  background-color: var(--color-whitespace-char);
  border-radius: var(--border-radius-default);
  transform: translateX(-5px) translateY(7px);
}
.show-whitespaces .wf__tb {
  font-size: var(--text-size-m);
  display: inline-block;
}
.show-whitespaces .wf__tb::before {
  content: "→";
  opacity: 0.4;
  font-size: 9px;
  position: absolute;
  padding: 2px 6px;
}

.auto-suggest__warning-btn,
.auto-suggest__error-btn {
  position: absolute;
  top: 10px;
  right: 8px;
  background: unset;
  height: unset;
  width: unset;
  z-index: 1;
  cursor: default;
}
.auto-suggest__warning-btn:hover, .auto-suggest__warning-btn:active,
.auto-suggest__error-btn:hover,
.auto-suggest__error-btn:active {
  background: unset;
}

.hint-wrapper {
  position: absolute; /* Remove from document flow */
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.auto-suggest__hint {
  color: var(--content-color-tertiary);
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1; /* Allow the hint to shrink */
  min-width: 0; /* Ensure it can shrink below its content size */
}

.highlight-with-error {
  background: var(--background-color-error);
}

.highlight-with-warning {
  background: var(--background-color-warning);
}

.auto-suggest.has-error .auto-suggest__warning-btn,
.auto-suggest.has-error .auto-suggest__error-btn, .auto-suggest.has-warning .auto-suggest__warning-btn,
.auto-suggest.has-warning .auto-suggest__error-btn {
  top: 6px;
}
.auto-suggest.has-error .DraftEditor-editorContainer .public-DraftEditor-content, .auto-suggest.has-warning .DraftEditor-editorContainer .public-DraftEditor-content {
  margin-right: var(--spacing-l);
}

.disable .auto-suggest .auto-suggest-box {
  background-color: var(--input-background-color-disabled);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.autocomplete-item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid var(--border-color-default);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  justify-content: space-between;
  min-height: var(--text-size-l);
}
.autocomplete-item.autocomplete-item-focused {
  background-color: var(--background-color-tertiary);
}
.autocomplete-item .autocomplete-item-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.autocomplete-item .autocomplete-item-scope {
  display: inline-block;
  position: absolute;
  height: 17px;
  width: 17px;
  border-radius: 1px;
  text-align: center;
  line-height: 17px;
  color: var(--content-color-constant);
  font-weight: 600;
  font-size: 12px;
}
.autocomplete-item .autocomplete-item-key {
  padding-left: 15px;
  color: var(--content-color-primary);
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 600;
}

.autocomplete-dropdown-menu {
  border-right: 1px solid var(--border-color-default);
  position: relative;
  width: 150px;
  background: var(--background-color-primary);
  cursor: pointer;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 11px;
  max-height: 196px;
  overflow-y: auto;
  overflow-x: hidden;
}
.autocomplete-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-background-color);
}
.autocomplete-dropdown-menu::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-background-color);
}

.autocomplete-menu-wrapper {
  position: absolute;
  margin-top: 20px;
  border-radius: var(--border-radius-default);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.37);
  width: 386px;
  height: 196px;
  display: flex;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  z-index: 100;
  padding: var(--spacing-s);
  background-color: var(--background-color-primary);
}
.autocomplete-menu-wrapper .autocomplete-meta-container {
  width: 220px;
  background: var(--background-color-primary);
  color: var(--content-color-primary);
  padding: var(--spacing-s);
}
.autocomplete-menu-wrapper .autocomplete-meta-container .override-label {
  background: var(--base-color-warning);
  border-radius: 2px;
  padding: 1px 2px;
  color: var(--content-color-constant);
  width: 62px;
  font-size: var(--text-size-xs);
  text-align: center;
}
.autocomplete-menu-wrapper .autocomplete-meta-container .overriding-help-info {
  font-size: var(--text-size-xs);
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.autocomplete-menu-wrapper .autocomplete-meta-container .autocomplete-meta-item--override {
  text-decoration: line-through;
  max-height: 42px !important;
  -webkit-line-clamp: 3 !important;
  color: var(--content-color-secondary);
}
.autocomplete-menu-wrapper .autocomplete-meta-container .autocomplete-meta-item {
  font-size: var(--text-size-xs);
  display: flex;
  margin-bottom: var(--spacing-s);
}
.autocomplete-menu-wrapper .autocomplete-meta-container .autocomplete-meta-item--label {
  flex: 2;
  text-align: left;
  color: var(--content-color-secondary);
}
.autocomplete-menu-wrapper .autocomplete-meta-container .autocomplete-meta-item--content {
  flex: 6;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 68px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-wrap: break-word;
}
.autocomplete-menu-wrapper .autocomplete-meta-container .autocomplete-meta-item--content input {
  height: var(--spacing-m);
  line-height: unset;
  text-overflow: ellipsis;
}

.infobar {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 40px;
  height: auto;
  font-size: var(--text-size-m);
  color: var(--content-color-constant);
}
.infobar a {
  color: var(--content-color-link);
  text-decoration: none;
}

.infobar__msg_text {
  flex: 2;
  display: flex;
  margin-left: var(--spacing-l);
  align-items: center;
  text-align: left;
}
.infobar__msg_text .infobar__icon-wrapper {
  display: flex;
}
.infobar__msg_text .infobar__icon {
  margin-right: var(--spacing-s);
}

.infobar-info {
  background-color: var(--background-color-info);
  color: var(--content-color-primary);
}
.infobar-info .infobar__icon use {
  fill: var(--content-color-primary);
}
.infobar-info .infobar__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-info .infobar__icon svg {
  color: var(--content-color-primary);
}
.infobar-info .infobar__dismiss_icon use {
  fill: var(--content-color-primary);
}
.infobar-info .infobar__dismiss_icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-info .infobar__dismiss_icon svg {
  color: var(--content-color-primary);
}

.infobar-warn {
  background-color: var(--background-color-warning);
  color: var(--content-color-primary);
}
.infobar-warn .infobar__icon use {
  fill: var(--content-color-primary);
}
.infobar-warn .infobar__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-warn .infobar__icon svg {
  color: var(--content-color-primary);
}
.infobar-warn .infobar__dismiss_icon use {
  fill: var(--content-color-primary);
}
.infobar-warn .infobar__dismiss_icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-warn .infobar__dismiss_icon svg {
  color: var(--content-color-primary);
}

.infobar-error {
  background-color: var(--background-color-error);
  color: var(--content-color-primary);
}
.infobar-error .infobar__icon use {
  fill: var(--content-color-primary);
}
.infobar-error .infobar__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-error .infobar__icon svg {
  color: var(--content-color-primary);
}
.infobar-error .infobar__dismiss_icon use {
  fill: var(--content-color-primary);
}
.infobar-error .infobar__dismiss_icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-error .infobar__dismiss_icon svg {
  color: var(--content-color-primary);
}

.infobar-success {
  background-color: var(--background-color-success);
  color: var(--content-color-primary);
}
.infobar-success .infobar__icon use {
  fill: var(--content-color-primary);
}
.infobar-success .infobar__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-success .infobar__icon svg {
  color: var(--content-color-primary);
}
.infobar-success .infobar__dismiss_icon use {
  fill: var(--content-color-primary);
}
.infobar-success .infobar__dismiss_icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.infobar-success .infobar__dismiss_icon svg {
  color: var(--content-color-primary);
}

.infobar__msg_container {
  display: flex;
  flex: auto;
  margin-right: auto;
  justify-content: space-between;
}
.infobar__msg_container a {
  color: inherit;
  font-weight: var(--text-weight-medium);
}
.infobar__msg_container .infobar-actions {
  align-self: center;
  margin-right: var(--spacing-l);
  display: flex;
  gap: var(--spacing-s);
}
.infobar__msg_container .infobar-actions .btn-small {
  padding: var(--spacing-zero) var(--spacing-l);
}
.infobar__msg_container .infobar-actions button {
  margin-right: var(--spacing-s);
}

.infobar__dismiss_container {
  margin-right: var(--spacing-l);
  cursor: pointer;
}
.infobar__dismiss_container .infobar__dismiss_icon {
  padding-top: var(--spacing-xs);
}

.infobar_trial-warning-banner-icon, .infobar_plan-update-banner-icon {
  margin-right: var(--spacing-s);
}

.infobar_plan-update-banner a {
  text-decoration: underline;
  font-weight: var(--text-weight-regular);
}

.list-carousal {
  display: flex;
  color: var(--content-color-primary);
  align-items: center;
}
.list-carousal .btn-icon {
  background-color: transparent;
}
.list-carousal .btn-icon:hover, .list-carousal .btn-icon.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.list-carousal .btn-icon:focus, .list-carousal .btn-icon.is-focused {
  background-color: var(--highlight-background-color-primary);
}
.list-carousal .btn-icon:active, .list-carousal .btn-icon.is-active {
  background-color: var(--highlight-background-color-primary);
}
.list-carousal .btn-icon.is-disabled {
  opacity: 0.5;
  cursor: default;
}
.list-carousal .btn-icon.is-disabled:focus, .list-carousal .btn-icon.is-disabled.is-focused {
  background-color: transparent;
}
.list-carousal .btn-icon.is-disabled:hover, .list-carousal .btn-icon.is-disabled.is-hovered {
  background-color: transparent;
}
.list-carousal .btn-icon.is-disabled:active, .list-carousal .btn-icon.is-disabled.is-active {
  background-color: transparent;
}
.list-carousal .list-carousal--label {
  white-space: pre;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0px 10px;
}
.list-carousal .list-carousal--next,
.list-carousal .list-carousal--previous {
  padding: 1px;
}

.user-card {
  display: flex;
  width: 240px;
  background: transparent;
  padding: var(--spacing-s) var(--spacing-l);
}
.user-card .user-card__left {
  padding-right: var(--spacing-s);
}
.user-card .user-card__right {
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.user-label__info {
  position: relative;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.user-label__name {
  position: absolute;
  left: 40px;
  line-height: 32px;
  color: var(--content-color-primary);
}
.user-label__email {
  margin-left: var(--spacing-xs);
  color: var(--content-color-secondary);
}

.Select-value .user-label__info {
  margin-top: 3px;
}

.info-button {
  display: flex;
}
.info-button.error use {
  fill: var(--base-color-error);
}
.info-button.error svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.info-button.error svg {
  color: var(--base-color-error);
}
.info-button .info-button__icon {
  display: flex;
}

.info-button-new {
  display: flex;
  cursor: default;
}
.info-button-new.error use {
  fill: var(--base-color-error);
}
.info-button-new.error svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.info-button-new.error svg {
  color: var(--base-color-error);
}
.info-button-new .info-button-new__icon {
  display: flex;
}
.info-button-new .info-button-new__icon use {
  fill: var(--content-color-secondary);
}
.info-button-new .info-button-new__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.info-button-new .info-button-new__icon svg {
  color: var(--content-color-secondary);
}

.info-button-new__tooltip.enable-whitespace {
  white-space: pre-wrap;
}

.info-button__icon {
  display: flex;
}

.folder-button {
  display: flex;
}
.folder-button.error use {
  fill: var(--base-color-error);
}
.folder-button.error svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.folder-button.error svg {
  color: var(--base-color-error);
}
.folder-button .folder-button__icon {
  display: flex;
}

.warning-button {
  display: flex;
  cursor: pointer;
}
.warning-button .warning-icon use {
  fill: var(--base-color-warning);
}
.warning-button .warning-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.warning-button .warning-icon svg {
  color: var(--base-color-warning);
}

.data-editor .data-editor__header {
  display: flex;
  border-bottom: 1px solid var(--border-color-default);
  margin-bottom: 0px;
  height: 30px;
  position: relative;
  box-sizing: border-box;
  padding-left: 15px;
}
.data-editor .data-editor__header .resize-preview {
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: var(--border-color-default);
  z-index: 20;
}
.data-editor .data-editor__header:hover, .data-editor .data-editor__header.is-hovered {
  background-color: transparent;
}
.data-editor .data-editor__header .data-editor__header__controls {
  color: var(--content-color-brand);
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
}
.data-editor .data-editor__header .data-editor__header__controls .data-editor-column-toggle {
  position: relative;
  border-left: 1px solid var(--border-color-default);
}
.data-editor .data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__menu {
  position: absolute;
  flex: 1;
  max-width: 175px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--background-color-secondary);
  z-index: 50;
  box-shadow: var(--shadow-default);
  right: 0;
  top: 25px;
  padding: 5px;
  color: var(--content-color-primary);
  border-radius: var(--border-radius-default);
}
.data-editor .data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__column {
  display: flex;
  padding: 5px;
  cursor: pointer;
}
.data-editor .data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__column:first-child {
  font-size: 10px;
  color: var(--content-color-secondary);
  cursor: default;
}
.data-editor .data-editor__header .data-editor__header__controls .data-editor-column-toggle .input-checkbox {
  margin-right: 10px;
}
.data-editor .data-editor__header .data-editor__reorder {
  flex: 0 0 15px;
}
.data-editor .data-editor__header .data-editor__header__cell {
  display: flex;
  align-items: center;
  position: relative;
  border-width: 0px 1px 0px 1px;
  box-sizing: border-box;
  border-style: solid;
  border-color: var(--border-color-default);
  margin: 0;
  padding: 5px;
  font-size: 10px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  cursor: default;
  overflow: hidden;
}
.data-editor .data-editor__header .data-editor__header__cell span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data-editor .data-editor__header .data-editor__header__cell .resize-handle {
  position: absolute;
  top: 0px;
  width: 16px;
  height: 100%;
  right: -10px;
  cursor: col-resize;
  z-index: 10;
}
.data-editor .data-editor__rows {
  flex: 1;
  border-top: none;
}
.data-editor .data-editor__row {
  display: flex;
  flex-direction: row;
  height: 30px;
  border-left: 1px solid var(--border-color-default);
  border-right: 1px solid var(--border-color-default);
}
.data-editor .data-editor__row .data-editor__custom-row {
  display: flex;
  flex: 1;
  height: 30px;
  position: relative;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__reorder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  flex: 0 0 15px;
  cursor: ns-resize;
  visibility: hidden;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__reorder .data-editor__reorder__icon {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__reorder:hover, .data-editor .data-editor__row .data-editor__custom-row .data-editor__reorder.is-hovered {
  background-color: transparent;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__reorder:hover .data-editor__reorder__icon, .data-editor .data-editor__row .data-editor__custom-row .data-editor__reorder.is-hovered .data-editor__reorder__icon {
  cursor: ns-resize;
}
.data-editor .data-editor__row .data-editor__custom-row.is-focused, .data-editor .data-editor__row .data-editor__custom-row:hover {
  background-color: var(--background-color-secondary);
}
.data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__reorder, .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__reorder {
  visibility: visible;
}
.data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__row__actions, .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__row__actions {
  display: flex;
}
.data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__cells .data-editor__cell:last-child, .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__cells .data-editor__cell:last-child {
  padding-right: 30px;
}
.data-editor .data-editor__row .data-editor__custom-row.is-selected {
  background-color: var(--background-color-secondary);
}
.data-editor .data-editor__row .data-editor__custom-row.is-dragged {
  opacity: 0.1;
}
.data-editor .data-editor__row .data-editor__custom-row.hover-top {
  position: relative;
}
.data-editor .data-editor__row .data-editor__custom-row.hover-top::before {
  background-color: var(--base-color-brand);
  top: 0;
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  z-index: 1;
}
.data-editor .data-editor__row .data-editor__custom-row.hover-bottom {
  position: relative;
}
.data-editor .data-editor__row .data-editor__custom-row.hover-bottom::before {
  background-color: var(--base-color-brand);
  bottom: 0;
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  z-index: 1;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cells {
  display: flex;
  flex: 1;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__row__actions {
  display: none;
  z-index: 10;
  position: absolute;
  right: 6px;
  height: 30px;
  align-items: center;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__row__actions .data-editor__row__action--delete {
  cursor: pointer;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--border-color-default);
  margin: 0px;
  flex-direction: row;
  /* Specific styles for inputs. Can be removed / replaced */
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell.is-disabled {
  color: var(--content-color-tertiary);
  text-decoration: line-through;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-suggestion-group,
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-field {
  width: 100%;
  margin: 1px 3px;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-suggestion-group .input-field,
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-field .input-field {
  margin: 0;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-suggestion-group input,
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-field input {
  height: 22px;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-suggestion-group input:focus,
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .input-field input:focus {
  background: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest {
  margin: 1px;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest .auto-suggest-cell {
  padding: 0;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest.is-focused .DraftEditor-editorContainer {
  border: 1px solid var(--border-color-default);
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest.is-focused .DraftEditor-root {
  background: var(--background-color-primary);
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest .public-DraftEditorPlaceholder-root {
  color: var(--content-color-tertiary);
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest .DraftEditor-editorContainer, .data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest .public-DraftEditorPlaceholder-root {
  padding: 3px;
  border: 1px solid transparent;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest .public-DraftEditor-content, .data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest .public-DraftEditorPlaceholder-root {
  font-size: var(--text-size-m);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .auto-suggest .DraftEditor-root {
  width: 100%;
}
.data-editor .data-editor__row .data-editor__custom-row .data-editor__cell .dropdown-menu {
  max-height: 180px;
}
.data-editor .data-editor__row:last-child {
  border-bottom: 1px solid var(--border-color-default);
}
.data-editor .data-editor__row:last-child .data-editor__custom-row .data-editor__row__actions {
  display: none;
}
.data-editor .data-editor__row:last-child .data-editor__custom-row {
  padding-left: 15px;
}
.data-editor .data-editor__row:last-child .data-editor__custom-row .data-editor__reorder {
  display: none;
}
.data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__reorder, .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__reorder {
  visibility: none;
}
.data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__row__actions, .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__row__actions {
  display: none;
}
.data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__cells .data-editor__cell:last-child, .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__cells .data-editor__cell:last-child {
  padding-right: 0;
}

/* @postman/data-editor styles */
.data-editor__header {
  border: 1px solid var(--border-color-default);
}

.data-editor__header__cell {
  display: flex;
  align-items: center;
  position: relative;
  border-width: 0px 1px 0px 1px;
  box-sizing: border-box;
  border-style: solid;
  border-color: var(--border-color-default);
  margin: 0;
  padding: 5px;
  font-size: 10px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.data-editor__cell .data-editor__cell__content {
  display: flex;
  flex: 1;
}
.data-editor__cell .data-editor__cell__content .auto-suggest {
  width: 100%;
}
.data-editor__cell .data-editor__cell__string,
.data-editor__cell .data-editor__cell__number {
  border: none;
}
.data-editor__cell .data-editor__cell__string input,
.data-editor__cell .data-editor__cell__number input {
  background-color: transparent;
  color: var(--content-color-primary);
  font-size: 12px;
  height: 24px;
  margin: 1px 3px;
  padding: 0 3px;
  border: 1px solid transparent;
}
.data-editor__cell .data-editor__cell__string input:focus,
.data-editor__cell .data-editor__cell__number input:focus {
  border: 1px solid var(--border-color-default);
}
.data-editor__cell .data-editor__object-key,
.data-editor__cell .data-editor__object-value {
  border: none;
}

.data-editor__header {
  margin-left: 0px;
}
.data-editor__header .data-editor__custom-header {
  display: flex;
  flex: 1;
  position: relative;
}
.data-editor__header .data-editor__header__controls {
  position: absolute;
  right: 0;
  top: 0;
}
.data-editor__header .data-editor__header__controls .data-editor-column-toggle {
  background-color: var(--background-color-primary);
}
.data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__menu {
  position: absolute;
  width: 250px;
  background: var(--background-color-secondary);
  z-index: 50;
  box-shadow: var(--shadow-default);
  right: 0;
  top: 25px;
  padding: 12px;
  color: var(--content-color-primary);
  border-radius: var(--border-radius-default);
}
.data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__group:not(:first-child) {
  margin-top: 10px;
}
.data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__header {
  font-size: 12px;
  margin-bottom: 8px;
}
.data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__column {
  display: flex;
  padding: 5px;
  cursor: pointer;
}
.data-editor__header .data-editor__header__controls .data-editor-column-toggle .data-editor-column-toggle__column:first-child {
  font-size: 10px;
  color: var(--content-color-secondary);
  cursor: default;
}
.data-editor__header .data-editor__header__controls .data-editor-column-toggle .input-checkbox {
  margin-right: 10px;
}

.sortable-table .sortable-table__header {
  height: 30px;
  background-color: var(--background-color-primary);
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sortable-table .sortable-table__header .sortable-table__header__sort {
  cursor: pointer;
}
.sortable-table .sortable-table__header .sortable-table__header__sort.sortable-table__header__sort--asc {
  transform: rotate(180deg);
}
.sortable-table .sortable-table__rows .sortable-table__row {
  height: 64px;
  box-sizing: border-box;
  padding: 16px;
}

.invite-users-file-drop {
  display: flex;
  flex: 1;
}

.invite-users-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.invite-users_error_wrapper {
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  margin-top: var(--spacing-s);
}

.invite-users_footer_message_wrapper {
  color: var(--content-color-tertiary);
  font-size: 12px;
  margin-top: var(--spacing-s);
}

.invite-users-file-drop_border_alert {
  display: flex;
  flex: 1;
  border: 1px solid var(--base-color-error);
}

.add-user__list {
  flex: 1;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  height: 240px;
  overflow: hidden;
}

.invite-user-tag {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
}
.invite-user-tag .avatar {
  margin: 0 var(--spacing-xs);
}
.invite-user-tag .user-email {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.invite-user-tag .user-group {
  width: calc(var(--size-s) / 2);
  height: calc(var(--size-s) / 2);
  padding: calc(var(--spacing-xs) / 2);
  margin: 0 var(--spacing-xs);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}

.add-user__option {
  display: flex;
  align-items: center;
}
.add-user__option .pm-icon {
  margin-right: 8px;
}

.empty-state--no-users .upload {
  color: var(--content-color-link);
  cursor: pointer;
}

.item-roles__container {
  height: 200px;
  overflow-y: auto;
}
.item-roles__container .permission-list-container .permission-list {
  padding-bottom: 0;
  border-bottom: none;
  margin: 0;
}
.item-roles__container .permission-list-container .permission-list .permission-list-item {
  margin: 8px;
}
.item-roles__container .permission-list-container .permission-list .permission-list-item--user {
  margin-right: 24px;
}
.item-roles__container .permission-list-container .permission-list .permission-list-item--user.can-remove {
  margin-right: initial;
}

.add-user-list-container {
  height: calc(100% - 80px);
}

.add-user__item {
  display: flex;
  min-height: 40px;
  padding: 0px 5px;
  justify-content: space-between;
  align-items: center;
}
.add-user__item.invalid-email {
  color: var(--base-color-error);
}
.add-user__item.invalid-email .add-user__invite-pending {
  color: var(--base-color-error);
}
.add-user__item .add-user__item__anonymous-icon-container {
  background-color: var(--background-color-tertiary);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-user__item .add-user__avatar-icon {
  flex: 0 0 20px;
  margin-right: 10px;
}
.add-user__item .add-user__avatar-name {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 8px;
  flex: 1;
}
.add-user__item .add-user__invite-pending {
  color: var(--content-color-secondary);
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 170px;
}
.add-user__item .add-user__item__label {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
  margin-left: 4px;
}
.add-user__item .add-user__item__roles__container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.add-user__item .add-user__item__roles__container.can-delete {
  margin-right: 0;
}
.add-user__item .add-user__item__roles__container .add-user__item__warning {
  margin-right: 8px;
}
.add-user__item .add-user__item__roles__container .add-user__item__warning use {
  fill: var(--base-color-warning);
}
.add-user__item .add-user__item__roles__container .add-user__item__warning svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.add-user__item .add-user__item__roles__container .add-user__item__warning svg {
  color: var(--base-color-warning);
}
.add-user__item .add-user__item__roles.dropdown {
  width: 170px;
}
.add-user__item .add-user__item__roles .dropdown-button .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.add-user__item .add-user__item__roles .dropdown-button span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.add-user__item.add-user__input {
  padding: 0px;
  position: relative;
  border-bottom: 1px solid var(--border-color-default);
  display: flex;
  max-height: 80px;
  overflow-y: auto;
  align-items: stretch;
}
.add-user__item.add-user__input .dropdown {
  display: flex;
  position: sticky;
  top: 0;
}
.add-user__item.add-user__input .dropdown .dropdown-button {
  border-right: 1px solid var(--border-color-default);
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
}
.add-user__item.add-user__input .input-suggestion-group {
  flex: 1;
}
.add-user__item.add-user__input .input {
  border-width: 0;
  border-radius: 0px;
  margin-left: 10px;
  background-color: transparent;
  padding: 0 70px 0 5px;
  height: 40px;
}
.add-user__item.add-user__input:hover, .add-user__item.add-user__input.is-hovered {
  background-color: var(--background-color-secondary);
}
.add-user__item .add-user__input-label {
  position: absolute;
  z-index: 1;
  border-radius: 0px;
  padding: 10px 11px;
}
.add-user__item .add-user__button {
  position: sticky;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.add-user__item .add-user__delete-icon {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  flex: 0 0 20px;
  margin-left: 10px;
}

.multi-select-entity__list {
  margin-top: 20px;
  border: 1px solid var(--border-color-default);
  height: 180px;
  overflow: auto;
  overflow-x: hidden;
}
.multi-select-entity__list .multi-select-entity__item {
  display: flex;
  height: 30px;
  align-items: center;
  padding: 0px 5px;
  cursor: pointer;
}
.multi-select-entity__list .multi-select-entity__item .input-checkbox {
  margin-right: 10px;
  flex-shrink: 0;
}
.multi-select-entity__list .multi-select-entity__item .multi-select-entity__item--name {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-select-entity__list .multi-select-entity__item:first-child {
  background-color: var(--background-color-secondary);
}
.multi-select-entity__list .multi-select-entity__item:hover, .multi-select-entity__list .multi-select-entity__item.is-hovered {
  background-color: var(--background-color-secondary);
}

.disable-component {
  opacity: 0.5;
  pointer-events: none;
}

.pm-icon {
  display: inline-flex;
}

.pm-icon-xs {
  height: 12px;
  width: 12px;
}
.pm-icon-xs svg {
  height: 12px;
  width: 12px;
}

.pm-icon-sm {
  height: 16px;
  width: 16px;
}
.pm-icon-sm svg {
  height: 16px;
  width: 16px;
}

.pm-icon-md {
  height: 24px;
  width: 24px;
}
.pm-icon-md svg {
  height: 24px;
  width: 24px;
}

.pm-icon-lg {
  height: 32px;
  width: 32px;
}
.pm-icon-lg svg {
  height: 32px;
  width: 32px;
}

.pm-icon-xl {
  height: 48px;
  width: 48px;
}
.pm-icon-xl svg {
  height: 48px;
  width: 48px;
}

.pm-icon-xxl {
  height: 64px;
  width: 64px;
}
.pm-icon-xxl svg {
  height: 64px;
  width: 64px;
}

.pm-icon-normal use {
  fill: var(--content-color-secondary);
}
.pm-icon-normal svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.pm-icon-normal svg {
  color: var(--content-color-secondary);
}

.pm-icon-primary use {
  fill: var(--content-color-constant);
}
.pm-icon-primary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.pm-icon-primary svg {
  color: var(--content-color-constant);
}

.pm-icon-secondary use {
  fill: var(--base-color-brand);
}
.pm-icon-secondary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.pm-icon-secondary svg {
  color: var(--base-color-brand);
}

.pm-icon-tertiary use {
  fill: var(--content-color-secondary);
}
.pm-icon-tertiary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.pm-icon-tertiary svg {
  color: var(--content-color-secondary);
}

.invite-user-team-link {
  display: flex;
  flex-direction: column;
}
.invite-user-team-link .invite-user-share-title {
  color: var(--content-color-primary);
  font-weight: 600;
}
.invite-user-team-link .invite-user-share-subtitle {
  color: var(--content-color-secondary);
}
.invite-user-team-link .invite-user-actions {
  display: flex;
  flex-direction: row;
}
.invite-user-team-link .invite-user-actions .btn-secondary {
  width: 116px;
}
.invite-user-team-link .invite-user-actions,
.invite-user-team-link .invite-user-share-subtitle {
  margin-top: 8px;
}
.invite-user-team-link .invite-user-team-link-input-container {
  flex: 1;
  display: flex;
  position: relative;
}
.invite-user-team-link .invite-user-team-link-input-container .btn-secondary {
  width: 84px;
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-input {
  margin-right: 8px;
  cursor: pointer;
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-input.is-disabled {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  color: var(--content-color-tertiary);
  cursor: not-allowed;
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-input.is-copied {
  border: 1px solid var(--base-color-success);
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-action {
  position: absolute;
  right: 0;
  padding: 8px;
  cursor: pointer;
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-action.is-disabled {
  cursor: not-allowed;
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-action.is-disabled svg use {
  fill-opacity: 0.5;
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-action.success use {
  fill: var(--base-color-success);
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-action.success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-success);
}
.invite-user-team-link .invite-user-team-link-input-container .invite-user-team-link-action.success svg {
  color: var(--base-color-success);
}
.invite-user-team-link .invite-user-team-link-input-container .dropdown {
  background-color: var(--background-color-tertiary);
  height: 30px;
  width: 30px;
  margin-left: 8px;
  cursor: pointer;
}
.invite-user-team-link .invite-user-team-link-input-container .dropdown .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}
.invite-user-team-link .invite-user-team-link--loading {
  cursor: not-allowed;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.carousel {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  justify-content: center;
  flex-direction: column;
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}

.carousel-item-next,
.carousel-item-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.carousel-item.active {
  display: flex;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: var(--content-color-constant);
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover, .carousel-control-prev.is-hovered,
.carousel-control-next:hover,
.carousel-control-next.is-hovered {
  color: var(--content-color-constant);
  text-decoration: none;
  outline: 0;
  opacity: 1;
  cursor: pointer;
}
.carousel-control-prev .pm-icon-sm,
.carousel-control-next .pm-icon-sm {
  height: 38px;
  width: 16px;
}
.carousel-control-prev .pm-icon-sm svg,
.carousel-control-next .pm-icon-sm svg {
  height: 38px;
  width: 16px;
}

.captain {
  display: flex;
}

.carousel-control-prev {
  left: 0;
  bottom: 116px;
}

.carousel-control-next {
  right: 0;
  bottom: 116px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-indicators {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 7px;
  margin-left: 7px;
  background-color: var(--highlight-background-color-tertiary);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: var(--content-color-tertiary);
}

.carousel-caption {
  z-index: 10;
  padding: 10px 20px;
  color: var(--content-color-constant);
  text-align: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.pie-progress-wrapper {
  position: relative;
  background-color: var(--background-color-success);
}

.pie-progress-base {
  position: absolute;
  top: 0px;
  left: 0px;
}

.pie-progress-slice {
  background-color: var(--base-color-success);
  position: absolute;
  top: 0px;
  left: 0px;
}

.markdown-base-content {
  padding: 10px 0px 0px 0px;
}
.markdown-base-content .markdown-editor-text-wrapper {
  width: 100%;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text {
  color: var(--content-color-secondary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  line-height: 21px;
  overflow: hidden;
  position: relative;
  color: var(--content-color-primary);
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h1,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h2,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h3,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h4,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h5,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h1 {
  font-size: 18px;
  font-weight: 700;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h2 {
  font-size: 16px;
  font-weight: 700;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h4 {
  font-size: 14px;
  font-weight: 700;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h6 {
  font-size: 12px;
  font-weight: 600;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text blockquote blockquote {
  margin-left: 20px;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text p, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text em {
  font-style: italic;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text strong {
  font-weight: var(--text-weight-medium);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ul, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ol {
  margin: 5px;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text a {
  color: var(--content-color-link);
  text-decoration: none;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text a:hover {
  text-decoration: underline;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table th,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table th:first-child,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table td:first-child {
  border-left: none;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table th:last-child,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table td:last-child {
  border-right: none;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table th {
  border-top: none;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table tr:last-child th,
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text table tr:last-child td {
  border-bottom: none;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text img {
  max-width: 100%;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text p {
  word-break: break-word;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h1, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h2, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h3, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h4, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h5, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h6 {
  line-height: 1.6;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h1 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h2 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h3 {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--text-weight-medium);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h4 {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h5 {
  font-size: 0.83em;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text h6 {
  font-size: 0.67em;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ol, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ul {
  margin: var(--spacing-zero) var(--spacing-xl);
  padding: var(--spacing-zero);
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ol li, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ul li {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
  display: list-item;
}
.markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ol li ol, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ol li ul, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ul li ol, .markdown-base-content .markdown-editor-text-wrapper .markdown-editor-text ul li ul {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}

.markdown-html-root ol, .markdown-html-root ul {
  margin: var(--spacing-zero) var(--spacing-xl);
  padding: var(--spacing-zero);
}
.markdown-html-root ol li, .markdown-html-root ul li {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
  display: list-item;
}
.markdown-html-root ol li ol, .markdown-html-root ol li ul, .markdown-html-root ul li ol, .markdown-html-root ul li ul {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}

.error-card-container {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--content-color-secondary);
  height: 100%;
}
.error-card-container .icon-button {
  background: var(--background-color-error);
}
.error-card-container .error-card-container__icon, .error-card-container .error-card-container__subtitle {
  margin-bottom: 16px;
}
.error-card-container .error-card-container__title {
  margin-bottom: 8px;
}
.error-card-container .error-card-container__actions {
  display: flex;
  color: var(--base-color-brand);
  cursor: pointer;
}
.error-card-container .error-card-container__actions .error-card-container__actions-icon {
  margin-right: 8px;
}

.tag {
  padding: var(--spacing-xs) var(--spacing-s);
  border-radius: var(--border-radius-default);
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tag.tag-info {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
}
.tag.tag-warn {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
}
.tag.tag-success {
  background-color: var(--background-color-success);
  color: var(--content-color-success);
}
.tag.tag-error {
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}

.avatar-group {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--background-color-brand);
  padding: var(--spacing-zero);
  list-style: none;
  box-sizing: content-box;
}
.avatar-group .member-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.avatar-group .member {
  height: var(--size-xs);
  width: var(--size-xs);
  border-radius: 50%;
}
.avatar-group.avatar-group-xs.avatar-group {
  height: 30px;
  width: 30px;
}
.avatar-group.avatar-group-xs .member {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
.avatar-group.avatar-group-xs.two-members .member-item:nth-of-type(1) {
  transform: rotate(0deg) translate(4.5px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.two-members .member-item:nth-of-type(2) {
  transform: rotate(180deg) translate(4.5px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.three-members .member-item:nth-of-type(1) {
  transform: rotate(30deg) translate(6px) rotate(-30deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.three-members .member-item:nth-of-type(2) {
  transform: rotate(150deg) translate(6px) rotate(-150deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.three-members .member-item:nth-of-type(3) {
  transform: rotate(270deg) translate(6px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.four-members .member-item:nth-of-type(1) {
  transform: rotate(180deg) translate(6px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.four-members .member-item:nth-of-type(2) {
  transform: rotate(270deg) translate(6px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.four-members .member-item:nth-of-type(3) {
  transform: rotate(0deg) translate(6px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-xs.four-members .member-item:nth-of-type(4) {
  transform: rotate(90deg) translate(6px) rotate(-90deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.avatar-group {
  height: 40px;
  width: 40px;
}
.avatar-group.avatar-group-sm .member {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.avatar-group.avatar-group-sm.two-members .member-item:nth-of-type(1) {
  transform: rotate(0deg) translate(6px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.two-members .member-item:nth-of-type(2) {
  transform: rotate(180deg) translate(6px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.three-members .member-item:nth-of-type(1) {
  transform: rotate(30deg) translate(8px) rotate(-30deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.three-members .member-item:nth-of-type(2) {
  transform: rotate(150deg) translate(8px) rotate(-150deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.three-members .member-item:nth-of-type(3) {
  transform: rotate(270deg) translate(8px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.four-members .member-item:nth-of-type(1) {
  transform: rotate(180deg) translate(8px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.four-members .member-item:nth-of-type(2) {
  transform: rotate(270deg) translate(8px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.four-members .member-item:nth-of-type(3) {
  transform: rotate(0deg) translate(8px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-sm.four-members .member-item:nth-of-type(4) {
  transform: rotate(90deg) translate(8px) rotate(-90deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.avatar-group {
  height: 60px;
  width: 60px;
}
.avatar-group.avatar-group-md .member {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.avatar-group.avatar-group-md.two-members .member-item:nth-of-type(1) {
  transform: rotate(0deg) translate(9px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.two-members .member-item:nth-of-type(2) {
  transform: rotate(180deg) translate(9px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.three-members .member-item:nth-of-type(1) {
  transform: rotate(30deg) translate(12px) rotate(-30deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.three-members .member-item:nth-of-type(2) {
  transform: rotate(150deg) translate(12px) rotate(-150deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.three-members .member-item:nth-of-type(3) {
  transform: rotate(270deg) translate(12px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.four-members .member-item:nth-of-type(1) {
  transform: rotate(180deg) translate(12px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.four-members .member-item:nth-of-type(2) {
  transform: rotate(270deg) translate(12px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.four-members .member-item:nth-of-type(3) {
  transform: rotate(0deg) translate(12px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-md.four-members .member-item:nth-of-type(4) {
  transform: rotate(90deg) translate(12px) rotate(-90deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.avatar-group {
  height: 80px;
  width: 80px;
}
.avatar-group.avatar-group-lg .member {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}
.avatar-group.avatar-group-lg.two-members .member-item:nth-of-type(1) {
  transform: rotate(0deg) translate(12px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.two-members .member-item:nth-of-type(2) {
  transform: rotate(180deg) translate(12px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.three-members .member-item:nth-of-type(1) {
  transform: rotate(30deg) translate(16px) rotate(-30deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.three-members .member-item:nth-of-type(2) {
  transform: rotate(150deg) translate(16px) rotate(-150deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.three-members .member-item:nth-of-type(3) {
  transform: rotate(270deg) translate(16px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.four-members .member-item:nth-of-type(1) {
  transform: rotate(180deg) translate(16px) rotate(-180deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.four-members .member-item:nth-of-type(2) {
  transform: rotate(270deg) translate(16px) rotate(-270deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.four-members .member-item:nth-of-type(3) {
  transform: rotate(0deg) translate(16px) rotate(0deg) translate(-50%, -50%);
}
.avatar-group.avatar-group-lg.four-members .member-item:nth-of-type(4) {
  transform: rotate(90deg) translate(16px) rotate(-90deg) translate(-50%, -50%);
}

.team-logo .team-logo--image {
  height: 32px;
  width: 32px;
  object-fit: contain;
}

.file-input {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
  /*
    Over-write the default behavior of native file input element
  */
}
.file-input.is-bleed {
  padding: 0;
}
.file-input input[type=file]::-webkit-file-upload-button {
  width: 0px;
  height: 0px;
}
.file-input .file-input__fake {
  position: relative;
  height: 20px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.file-input .file-input__fake .file-input__fake-loading {
  transform: scale(0.6);
  margin: var(--spacing-zero) var(--spacing-xl);
}
.file-input .file-input__native {
  opacity: 0;
  position: absolute;
  width: 0px;
  height: 0px;
  pointer-events: none;
}
.file-input .file-input__native:focus + .file-input__fake {
  background-color: var(--highlight-background-color-tertiary);
}
.file-input .file-input__name {
  box-sizing: border-box;
  display: flex;
  min-width: 0;
  height: 20px;
  align-items: center;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  color: var(--content-color-primary);
  background-color: transparent;
  padding: var(--spacing-xs) 2px;
}
.file-input .file-input__name.show-focus:focus, .file-input .file-input__name.show-focus.is-focused {
  border-color: var(--border-color-strong);
}
.file-input .file-input__name .file-input__name__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file-input .file-input__name .file-input__name__delete-icon {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-s);
  padding-right: 2px;
  cursor: pointer;
}

.file-input-container {
  max-width: 100%;
}
.file-input-container .tooltip .tooltip-wrapper {
  padding: var(--spacing-l);
}
.file-input-container .tooltip .tooltip__header {
  color: var(--content-color-primary);
}
.file-input-container .tooltip .tooltip__body {
  margin-top: var(--spacing-xs);
  max-width: 250px;
}
.file-input-container__warning-icon {
  position: absolute;
  top: 10px;
  right: var(--spacing-s);
  background: unset;
  height: unset;
  width: unset;
  z-index: 1;
  cursor: default;
}
.file-input-container.has-warning {
  margin-right: var(--spacing-xl);
}

.password-input {
  position: relative;
}
.password-input .password-input-message {
  position: absolute;
  display: inline;
  top: 0;
  right: 30px;
}
.password-input .password-input-message .password-input__warning-btn {
  background: unset;
}
.password-input .password-input-message .password-input__warning-btn use {
  fill: var(--base-color-warning);
}
.password-input .password-input-message .password-input__warning-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.password-input .password-input-message .password-input__warning-btn svg {
  color: var(--base-color-warning);
}
.password-input .password-input-message .password-input__error-btn {
  background: unset;
}
.password-input .password-input-message .password-input__error-btn use {
  fill: var(--base-color-error);
}
.password-input .password-input-message .password-input__error-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.password-input .password-input-message .password-input__error-btn svg {
  color: var(--base-color-error);
}
.password-input__toggle {
  display: inline;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
}
.password-input__toggle i {
  padding: var(--spacing-s);
}
.password-input input {
  padding-right: var(--spacing-xxl);
}
.password-input input .input-box .input-huge {
  padding-right: var(--spacing-xxxl);
}

.accordion {
  border-top: 1px solid var(--border-color-default);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.accordion .accordion-item {
  margin-top: 16px;
}
.accordion .accordion-item .title {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  height: 32px;
}
.accordion .accordion-item .panel {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  padding-bottom: 8px;
}

.right-overlay {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 110;
  color: var(--content-color-primary);
  background: var(--background-modal-backdrop);
}
.right-overlay .right-overlay__modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  background: var(--background-color-primary);
  height: 100%;
  right: 0;
  box-shadow: var(--shadow-default);
  font-size: var(--text-size-m);
}
.right-overlay .right-overlay__modal .right-overlay--resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  cursor: ew-resize;
}
.right-overlay .right-overlay__modal .right-overlay__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  font-family: var(--text-family-default);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header {
  display: flex;
  align-items: center;
  color: var(--content-color-tertiary);
  padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__breadcrumb {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  font-weight: normal;
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__breadcrumb .right-overlay__breadcrumb-item {
  cursor: pointer;
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__breadcrumb .right-overlay__breadcrumb-item:after {
  content: " / ";
  padding: var(--spacing-zero) var(--spacing-xs);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__breadcrumb .right-overlay__breadcrumb-item .right-overlay--breadcrumb-item-name:hover {
  text-decoration: underline;
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__close-icon-wrapper {
  margin-left: var(--spacing-s);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__close-icon {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__close-icon:hover use {
  fill: var(--content-color-primary);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__close-icon:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__header .right-overlay__close-icon:hover svg {
  color: var(--content-color-primary);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: var(--spacing-zero) var(--spacing-xl);
  font-size: var(--text-size-xxl);
  font-weight: var(--text-weight-medium);
}
.right-overlay .right-overlay__modal .right-overlay__content .right-overlay__details {
  padding: var(--spacing-zero) var(--spacing-xl);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.editable-tag {
  height: 20px;
  width: 170px;
  border-radius: var(--border-radius-default);
  margin: 4px;
  padding: 4px;
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
}
.editable-tag .text:hover {
  cursor: text;
}
.editable-tag .pm-icon {
  margin-right: 8px;
}
.editable-tag .tag-remove-btn:hover {
  cursor: pointer;
}
.editable-tag-regular {
  background-color: var(--background-color-secondary);
  border: solid 1px var(--border-color-default);
}
.editable-tag-error {
  border: 1px dashed var(--base-color-error);
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.editable-tag-warning {
  border: solid 1px var(--border-color-default);
  background-color: var(--background-color-warning);
}
.editable-tag.is-selected {
  border: 1px solid var(--base-color-info);
}

.text {
  font-family: var(--text-family-default);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-heading-h1 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
}

.text-heading-h2 {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--text-weight-medium);
}

.text-heading-h3 {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--text-weight-medium);
}

.text-heading-h4 {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
}

.text-heading-h5 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-heading-h6 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-body-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
}

.link-text,
.text-body-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.text-body-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
}

.text-button-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
}

.text-button-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-button-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-input-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
}

.text-input-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.text-input-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
}

.text-input-xSmall {
  font-size: var(--text-size-xs);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
}

.text-label-primary-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-primary-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-primary-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-label-secondary-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-secondary-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-label-secondary-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-status-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
}

.text-status-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}

.text-status-small {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-status-xSmall {
  font-size: var(--text-size-xs);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}

.text-code-large {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-code);
}

.text-code-medium {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-code);
}

.link-text {
  color: var(--content-color-link);
  cursor: pointer;
}
.link-text use {
  fill: var(--content-color-link);
}
.link-text svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.link-text svg {
  color: var(--content-color-link);
}
.link-text:hover {
  color: var(--content-color-link);
}
.link-text:hover use {
  fill: var(--content-color-link);
}
.link-text:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.link-text:hover svg {
  color: var(--content-color-link);
}

.pane-label {
  background-color: var(--background-color-primary);
  display: flex;
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.pane-label .pane-label__text {
  flex: 1;
  color: var(--content-color-tertiary);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.pane-label .pane-label__text span {
  cursor: pointer;
}
.pane-label .pane-label__actions {
  display: flex;
  align-items: center;
}
.pane-label .pane-label__arrow {
  margin: var(--spacing-s);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pane-label .pane-label__arrow i svg {
  color: var(--pane-chevron-color);
}
.pane-label.vertical {
  width: var(--size-m);
  height: 100%;
  flex-direction: column-reverse;
}
.pane-label.vertical .pane-label__text {
  padding-top: var(--spacing-s);
  writing-mode: tb;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
}
.pane-label.horizontal {
  height: var(--size-m);
}
.pane-label.horizontal .pane-label__text {
  margin-left: var(--spacing-s);
}

.pane-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}

.pane {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  min-height: 0;
  min-width: 0;
  position: relative;
}
.pane.collapsedX {
  flex-direction: row;
}

.pane-group {
  flex: 1;
  display: flex;
  min-height: 0;
  min-width: 0;
}
.pane-group.vertical {
  flex-direction: column;
}
.pane-group.horizontal {
  flex-direction: row;
}
.pane-group .pane-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.pane-group .pane-container .pane-resize-handle {
  position: absolute;
  box-sizing: border-box;
  z-index: 8;
}
.pane-group .pane-container .pane-resize-handle .pane-resize-handle__body {
  background-color: var(--border-color-default);
}
.pane-group .pane-container .pane-resize-handle:hover .pane-resize-handle__body, .pane-group .pane-container .pane-resize-handle:active .pane-resize-handle__body {
  background-color: var(--blue-30);
}
.pane-group .pane-container .pane-resize-handle.ew {
  padding: 5px 0;
  left: 0;
  right: 0;
  bottom: -5px;
  cursor: ns-resize;
}
.pane-group .pane-container .pane-resize-handle.ew .pane-resize-handle__body {
  height: 1px;
  width: 100%;
}
.pane-group .pane-container .pane-resize-handle.ns {
  padding: var(--spacing-zero) 5px;
  top: 0;
  bottom: 0;
  right: -5px;
  cursor: ew-resize;
}
.pane-group .pane-container .pane-resize-handle.ns .pane-resize-handle__body {
  width: 1px;
  height: 100%;
}

.variable-row-actions-dropdown .btn {
  height: 30px;
}

body,
.app-root,
.app-requester {
  position: absolute;
  height: 100%;
  width: 100%;
}
body .requester-content-loader,
.app-root .requester-content-loader,
.app-requester .requester-content-loader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

body {
  background-color: var(--background-color-primary);
  overflow: hidden;
}

.app-root {
  overflow-x: auto;
}

.app-requester {
  display: flex;
  flex-direction: column;
  min-width: 972px;
  overflow: hidden;
}
.app-requester .requester-contents {
  flex: 1;
}
.app-requester .offline-state-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-requester .offline-state-wrapper .action-button {
  margin-top: var(--spacing-s);
}

.loader-blocker {
  background-color: var(--background-color-primary);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  margin: auto;
  z-index: 3000;
  animation: fadein 0.2s;
}

.sidebar {
  z-index: 1;
}

.requester-contents {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-height: 0;
}
.requester-contents .is-hidden {
  display: none;
}

.requester-content {
  flex: 1;
  min-height: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}
.requester-sidebar-header {
  height: 100%;
}
.requester-sidebar-header .requester-sidebar-header__tabs {
  display: flex;
  flex-direction: column;
}
.requester-sidebar-header .requester-sidebar-header__tabs .pm-icon {
  padding: 8px;
  cursor: pointer;
}
.requester-sidebar-header .requester-sidebar-header__tabs .pm-icon use {
  fill: var(--content-color-secondary);
}
.requester-sidebar-header .requester-sidebar-header__tabs .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-sidebar-header .requester-sidebar-header__tabs .pm-icon svg {
  color: var(--content-color-secondary);
}
.requester-sidebar-header .requester-sidebar-header__tabs .pm-icon:hover use {
  fill: var(--content-color-primary);
}
.requester-sidebar-header .requester-sidebar-header__tabs .pm-icon:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-sidebar-header .requester-sidebar-header__tabs .pm-icon:hover svg {
  color: var(--content-color-primary);
}

/*
  - These classes provide the color to the icons with the above mentioned values
  - These classes are only specific to the Requester header and not intended to use anywhere else
*/
.header-success-icon-color use {
  fill: var(--content-color-success);
}
.header-success-icon-color svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.header-success-icon-color svg {
  color: var(--content-color-success);
}

.header-warning-icon-color use {
  fill: var(--content-color-warning);
}
.header-warning-icon-color svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-warning);
}
.header-warning-icon-color svg {
  color: var(--content-color-warning);
}

.header-error-icon-color use {
  fill: var(--content-color-error);
}
.header-error-icon-color svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.header-error-icon-color svg {
  color: var(--content-color-error);
}

.requester-header {
  -webkit-app-region: drag;
  background-color: var(--global-navigation-background-color);
  z-index: 90;
  display: flex;
  flex-direction: row;
  box-shadow: inset 0px -1px 0px var(--border-color-strong);
  align-items: stretch;
  flex: 0 0 48px;
  padding: 0 var(--spacing-m);
  user-select: none;
}
.requester-header-overlay-area {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.requester-header-windows-app {
  padding: 0;
}

.requester-header-no-click-style,
.requester-header-no-click-style * {
  cursor: default !important;
}

.requester-header-homepage-signed-out {
  max-width: 100vw;
  box-shadow: unset;
  flex-basis: 48px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .requester-header-homepage-signed-out .requester-header__section-left .top-navigation-buttons {
    display: none;
  }
}
.requester-header-homepage-signed-out .pm-search-box-container {
  flex: 1;
  display: flex;
  justify-content: center;
}
.requester-header-homepage-signed-out .pm-search-box-container.is_focused {
  border: 1px solid var(--border-color-strong);
  display: unset;
  position: absolute;
  transform: translateX(-50%);
}
.requester-header-homepage-signed-out .requester-header__section-right {
  min-width: unset;
}
@media screen and (max-width: 640px) {
  .requester-header-homepage-signed-out .requester-header__section-right {
    display: none;
  }
}
.requester-header-homepage-signed-out .requester-header__section-right-responsive {
  display: none;
}
@media screen and (max-width: 640px) {
  .requester-header-homepage-signed-out .requester-header__section-right-responsive {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.requester-header-homepage-signed-out .requester-header__section-right-responsive .top-navigation-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}
.requester-header-homepage-signed-out .requester-header__section-right-responsive .top-navigation-buttons .top-navigation-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  padding: var(--spacing-m);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.requester-header-homepage-signed-out .requester-header__section-right-responsive .top-navigation-buttons .top-navigation-button:hover, .requester-header-homepage-signed-out .requester-header__section-right-responsive .top-navigation-buttons .top-navigation-button.is-hovered {
  color: var(--content-color-primary);
}
.requester-header-homepage-signed-out .requester-header__section-right-responsive .top-navigation-buttons .top-navigation-button .dropdown-caret {
  margin-left: unset;
}
.requester-header-homepage-signed-out .requester-header__section-right-responsive .requester-header__user-info {
  margin-right: unset;
  margin-top: 36px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 640px) {
  .requester-header-homepage-signed-out .requester-header__section-right-responsive .mobile-search-container {
    display: flex;
  }
}
@media screen and (max-width: 640px) {
  .requester-header-homepage-signed-out .pm-search-box-container {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .requester-header-homepage-signed-out .mobile-search-container.is-hidden-on-mobile {
    display: none;
  }
}

.requester-header-signed-out-workspace {
  box-shadow: inset 0 -1px 0 var(--border-color-strong);
}

.requester-header-mac__section-left {
  margin-left: 76px;
}

.requester-header__section-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.requester-header__section-left.fit-content {
  max-width: fit-content;
}
.requester-header__section-left .top-navigation-buttons {
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: var(--spacing-xs);
}
.requester-header__section-left .top-navigation-buttons .top-navigation-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;
  cursor: pointer;
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
  margin-right: var(--spacing-xs);
}
@media screen and (max-width: 675px) {
  .requester-header__section-left .top-navigation-buttons .top-navigation-button {
    margin-right: var(--spacing-zero);
  }
}
.requester-header__section-left .top-navigation-buttons .top-navigation-button.is-active {
  box-shadow: none;
}
.requester-header__section-left .top-navigation-buttons .top-navigation-button .dropdown-caret {
  margin-left: unset;
}
.requester-header__section-left-divider {
  display: flex;
  flex-direction: column;
  height: var(--controls-size-small);
  margin-right: var(--spacing-xl);
  border: none;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.top-navigation-buttons-dropdown .dropdown-menu {
  box-sizing: border-box;
  padding: var(--spacing-l) 0;
  background-color: var(--background-color-primary);
}
.top-navigation-buttons-dropdown .dropdown-menu .dropdown-menu-item {
  padding: 0;
}
.top-navigation-buttons-dropdown .dropdown-menu .dropdown-menu-item a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 var(--spacing-xl);
}
.top-navigation-buttons-dropdown .dropdown-menu .dropdown-menu-item a:hover {
  color: var(--content-color-link);
}
.top-navigation-buttons-dropdown .dropdown-menu .dropdown-menu-item:hover {
  background-color: unset;
}
.top-navigation-buttons-dropdown .top-navigation-dropdown__menu {
  background-color: var(--background-color-primary);
  top: 55px !important;
  left: 55px !important;
}

.create-new-button-dropdown .dropdown-menu-item .dropdown-menu-item__text {
  margin-left: 10px;
}

.requester-header__section-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-m);
  z-index: 100;
}
@media screen and (max-width: 1190px) {
  .requester-header__section-center {
    margin-right: var(--spacing-xs);
  }
}
@media screen and (max-width: 675px) {
  .requester-header__section-center {
    margin-right: var(--spacing-zero);
  }
}

.requester-header__section-right {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.requester-header__section-right__action-button {
  margin-right: var(--spacing-xs);
}
@media screen and (max-width: 675px) {
  .requester-header__section-right__action-button {
    margin-right: var(--spacing-zero);
  }
}
.requester-header__section-right .requester-header__team-info__button .team-icon {
  margin-right: var(--spacing-s);
}
.requester-header__section-right .requester-header__team-info__button .dropdown-caret {
  margin-left: var(--spacing-xs);
}
.requester-header__section-right .requester-header__settings-group-dropdown {
  margin-right: 10px;
}
.requester-header__section-right .requester-header__section-right__group {
  display: flex;
  align-items: center;
}
.requester-header__section-right .requester-header__section-right__group > .notification-container {
  margin-right: 10px;
}
.requester-header__section-right .btn-icon {
  background: transparent;
}
.requester-header__section-right .btn-icon use {
  fill: var(--content-color-secondary);
}
.requester-header__section-right .btn-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-header__section-right .btn-icon svg {
  color: var(--content-color-secondary);
}
.requester-header__section-right .btn-icon:not(.sync-button):hover, .requester-header__section-right .btn-icon:not(.sync-button).is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.requester-header__section-right .btn-icon:not(.sync-button):hover use, .requester-header__section-right .btn-icon:not(.sync-button).is-hovered use {
  fill: var(--content-color-primary);
}
.requester-header__section-right .btn-icon:not(.sync-button):hover svg:not([class*=PartnerIcon]) > path[fill], .requester-header__section-right .btn-icon:not(.sync-button).is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-header__section-right .btn-icon:not(.sync-button):hover svg, .requester-header__section-right .btn-icon:not(.sync-button).is-hovered svg {
  color: var(--content-color-primary);
}
.requester-header__section-right .btn-icon:not(.sync-button).is-active {
  background-color: var(--highlight-background-color-primary);
}
.requester-header__section-right .btn-icon:not(.sync-button).is-active use {
  fill: var(--content-color-primary);
}
.requester-header__section-right .btn-icon:not(.sync-button).is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-header__section-right .btn-icon:not(.sync-button).is-active svg {
  color: var(--content-color-primary);
}
.requester-header__section-right .workspace-invite-icon-btn {
  display: none;
}
@media (max-width: 1190px) {
  .requester-header__section-right .workspace-invite-btn {
    display: none;
  }
  .requester-header__section-right .workspace-invite-icon-btn {
    display: flex;
  }
}

.user-info-dropdown .dropdown-menu {
  width: 300px;
  background: var(--background-color-primary);
  max-height: 500px;
  overflow-y: auto;
  padding: var(--spacing-s) var(--spacing-zero);
}
.user-info-dropdown .dropdown-menu .dropdown-menu-item {
  height: auto;
  background: transparent;
  padding: var(--spacing-zero);
}
.user-info-dropdown .dropdown-menu .dropdown-menu-item.dropdown-menu-item--username {
  opacity: 1;
}
.user-info-dropdown .dropdown-menu .dropdown-menu-item:not(.is-disabled):hover, .user-info-dropdown .dropdown-menu .dropdown-menu-item:not(.is-disabled).is-hovered {
  background-color: var(--background-color-secondary);
}
.user-info-dropdown .dropdown-menu .requester-header__user-info__menu-item {
  display: flex;
  align-items: center;
  height: var(--spacing-l);
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-l);
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  width: 100%;
}

.requester-header__user-info {
  margin-right: var(--spacing-m);
}
@media screen and (max-width: 675px) {
  .requester-header__user-info {
    margin-right: var(--spacing-s);
  }
}
.requester-header__user-info .requester-header__user-info__button {
  width: var(--controls-size-default);
  height: var(--controls-size-default);
  vertical-align: middle;
}
.requester-header__user-info .requester-header__user-info__button .requester-header__user-info__button-wrapper {
  display: flex;
  align-items: center;
}
.requester-header__user-info .requester-header__user-info__button .requester-header__user-info__button-wrapper .pm-icon-sm {
  width: 24px;
  height: 24px;
}
.requester-header__user-info .dropdown-filled-caret {
  margin-left: var(--spacing-zero);
}

.settings-button .settings-button-icon.indicator:after {
  content: "";
  background-color: var(--base-color-brand);
  position: absolute;
  border-radius: var(--border-radius-max);
  top: 4px;
  left: 22px;
  height: 7px;
  width: 7px;
}
.settings-button .settings-button-icon.indicator.error:after {
  background-color: var(--base-color-error);
}

#aether-popover-portal .settings-header-update-available,
#aether-popover-portal .settings-header-update-downloaded {
  background-color: var(--base-color-brand);
  color: var(--content-color-constant);
}
#aether-popover-portal .settings-header-update-available:hover, #aether-popover-portal .settings-header-update-available.is-hovered,
#aether-popover-portal .settings-header-update-downloaded:hover,
#aether-popover-portal .settings-header-update-downloaded.is-hovered {
  background-color: var(--highlight-background-color-brand);
}
#aether-popover-portal .settings-header-update-available:active, #aether-popover-portal .settings-header-update-available.is-active,
#aether-popover-portal .settings-header-update-downloaded:active,
#aether-popover-portal .settings-header-update-downloaded.is-active {
  background-color: var(--highlight-background-color-brand);
}
#aether-popover-portal .settings-header-update-failed {
  background-color: var(--base-color-error);
  color: var(--content-color-constant);
}
#aether-popover-portal .settings-header-update-failed:hover, #aether-popover-portal .settings-header-update-failed.is-hovered {
  background-color: var(--highlight-background-color-error);
}
#aether-popover-portal .settings-header-update-failed:active, #aether-popover-portal .settings-header-update-failed.is-active {
  background-color: var(--highlight-background-color-error);
}

.settings-dropdown {
  z-index: 100;
}
.settings-dropdown .dropdown-menu {
  background-color: var(--background-color-primary);
}
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-available,
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-downloaded {
  background-color: var(--base-color-brand);
  color: var(--content-color-constant);
}
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-available:hover, .settings-dropdown .dropdown-menu .dropdown-menu-item--update-available.is-hovered,
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-downloaded:hover,
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-downloaded.is-hovered {
  background-color: var(--highlight-background-color-brand);
}
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-available:active, .settings-dropdown .dropdown-menu .dropdown-menu-item--update-available.is-active,
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-downloaded:active,
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-downloaded.is-active {
  background-color: var(--highlight-background-color-brand);
}
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-failed {
  background-color: var(--base-color-error);
  color: var(--content-color-constant);
}
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-failed:hover, .settings-dropdown .dropdown-menu .dropdown-menu-item--update-failed.is-hovered {
  background-color: var(--highlight-background-color-error);
}
.settings-dropdown .dropdown-menu .dropdown-menu-item--update-failed:active, .settings-dropdown .dropdown-menu .dropdown-menu-item--update-failed.is-active {
  background-color: var(--highlight-background-color-error);
}

.interceptor-button.is-active {
  background-color: var(--base-color-brand);
}

.interceptor-wrapper {
  display: flex;
}
.interceptor-wrapper .interceptor-toggle-switch {
  margin-left: 10px;
}

.requester-header__user-info__divider {
  background: var(--background-color-tertiary);
  margin: var(--spacing-s) var(--spacing-zero);
  height: 1px;
  border: unset;
}

.requester-header-entity {
  -webkit-app-region: no-drag;
}

.team-info-dropdown .team-button {
  background-color: var(--button-secondary-background-color);
  font-weight: var(--text-weight-medium);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color);
}
.team-info-dropdown .team-button:hover {
  background-color: var(--button-secondary-hover-background-color);
}
.team-info-dropdown .team-button:active {
  background-color: var(--button-secondary-active-background-color);
}
.team-info-dropdown .upgrade-dropdown {
  height: var(--controls-size-default);
}
.team-info-dropdown .upgrade-dropdown .btn {
  height: var(--controls-size-default);
}
.team-info-dropdown .upgrade-dropdown .btn:nth-child(2) {
  padding-left: 5px;
  padding-right: 5px;
  min-width: 0px;
  border-left: var(--button-group-separator);
  margin-left: -1px;
  z-index: 2;
}
.team-info-dropdown .upgrade-dropdown .btn:nth-child(2).btn-secondary {
  background-color: var(--button-secondary-background-color);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
}
.team-info-dropdown .upgrade-dropdown .btn:nth-child(2).btn-secondary:hover {
  background-color: var(--button-secondary-hover-background-color);
}
.team-info-dropdown .with-upgrade-banner {
  padding-top: 0px;
}
.team-info-dropdown .dropdown-menu {
  width: 340px;
  background-color: var(--background-color-primary);
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--sign-in,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--enable-sync,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--upgrade-to-pro,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname {
  opacity: 1;
}
.team-info-dropdown .dropdown-menu .dropdown-free-user-header-menu-item {
  display: contents;
  cursor: default;
}
.team-info-dropdown .dropdown-menu .dropdown-free-user-header-menu-item_wrapper {
  padding: var(--spacing-l);
}
.team-info-dropdown .dropdown-menu .dropdown-free-user-header-menu-item:hover {
  background-color: var(--highlight-background-color-secondary);
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname {
  display: flex;
  align-items: flex-start;
  height: 70px;
  padding: 16px;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname:hover, .team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname.is-hovered {
  background-color: transparent;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-image {
  display: flex;
  height: 32px;
  width: 32px;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-image img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-image:hover, .team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-image.is-hovered {
  cursor: pointer;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details .team-info-dropdown__team-details-meta {
  max-width: 130px;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details .team-info-dropdown__team-details__name {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details .team-info-dropdown__team-details__name:hover, .team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details .team-info-dropdown__team-details__name.is-hovered {
  cursor: pointer;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details .team-info-dropdown__team-details__domain {
  font-size: 12px;
  line-height: normal;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details .team-info-dropdown__team-details__name,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--teamname .team-info-dropdown__team-details .team-info-dropdown__team-details__domain {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--sign-in,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--enable-sync,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--upgrade-to-pro {
  display: flex;
  flex-direction: column;
  height: 180px;
  padding: 20px;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--sign-in .dropdown-menu-item__subtitle,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--enable-sync .dropdown-menu-item__subtitle,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--upgrade-to-pro .dropdown-menu-item__subtitle {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 20px;
  color: var(--content-color-primary);
  text-align: center;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--sign-in .dropdown-menu-item__learn-more,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--enable-sync .dropdown-menu-item__learn-more,
.team-info-dropdown .dropdown-menu .dropdown-menu-item--upgrade-to-pro .dropdown-menu-item__learn-more {
  margin-top: 20px;
}
.team-info-dropdown .dropdown-menu .dropdown-menu-item--upgrade-to-pro {
  height: 220px;
}

.requester-content-builder {
  display: flex;
  flex-direction: row;
  contain: strict;
}
.requester-content-builder .requester-builder {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.response-pane__container,
.example-request-editor-pane__container,
.example-response-pane__container,
.request-editor-pane__container {
  flex: 1;
  display: flex;
  flex-direction: inherit;
  min-height: 0;
  min-width: 0;
}
.response-pane__section-top,
.example-request-editor-pane__section-top,
.example-response-pane__section-top,
.request-editor-pane__section-top {
  background-color: var(--background-color-primary);
}
.response-pane__section-content,
.example-request-editor-pane__section-content,
.example-response-pane__section-content,
.request-editor-pane__section-content {
  background-color: var(--background-color-primary);
  flex-direction: column;
}

.pane-header.horizontal.response-pane__section-top {
  padding-left: var(--spacing-s);
}

.requester-contents-group .requester-contents-group__section-top {
  background-color: var(--background-color-primary);
}
.requester-contents-group .requester-contents-group__section-content {
  background-color: var(--background-color-primary);
}

.requester-content-library {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-content-blanket {
  display: flex;
  height: 100%;
  width: 100%;
}

.example-response-pane__section-top {
  padding: var(--spacing-zero) var(--spacing-s);
}

.requester-left-sidebar-wrapper {
  flex: 0 0 auto;
  z-index: 20;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}
.requester-left-sidebar-wrapper.is-overlaid {
  position: absolute;
  height: 100%;
}
.requester-left-sidebar-wrapper.is-hidden {
  display: none;
}

.survey-modal-container-wrapper {
  background-color: var(--global-navigation-background-color);
}

.requester-left-sidebar-container {
  display: flex;
  background-color: var(--global-navigation-background-color);
  z-index: 20;
  flex: 1;
  overflow: hidden;
}

.requester-left-sidebar {
  contain: strict;
  background-color: var(--global-navigation-background-color);
  z-index: 20;
  box-shadow: inset -1px 0px 0px var(--background-color-secondary);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
}

.requester-left-sidebar__search-container {
  flex: 0 0 40px;
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}

.requester-left-sidebar__tabs-container {
  flex: 0 0 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.requester-left-sidebar__tabs-container .tab {
  font-size: 14px;
}

.requester-left-sidebar__tab-content-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.requester-left-sidebar__tab-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.requester-left-sidebar__tab-content.is-hidden {
  display: none;
}

.requester-left-sidebar-resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  z-index: 100;
  cursor: ew-resize;
}

.requester-sidebar-horizontal-header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--global-navigation-background-color);
}
.requester-sidebar-horizontal-header__active-workspace-name {
  display: flex;
  align-items: center;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  margin-left: var(--spacing-m);
  height: 20px;
}
.requester-sidebar-horizontal-header__active-workspace-name i {
  margin-right: var(--spacing-s);
}
.requester-sidebar-horizontal-header__active-workspace-name:hover {
  color: var(--base-color-info);
  cursor: pointer;
}
.requester-sidebar-horizontal-header__active-workspace-name:hover use {
  fill: var(--base-color-info);
}
.requester-sidebar-horizontal-header__active-workspace-name:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-info);
}
.requester-sidebar-horizontal-header__active-workspace-name:hover svg {
  color: var(--base-color-info);
}
.requester-sidebar-horizontal-header__right-container {
  padding: var(--spacing-s);
}
.requester-sidebar-horizontal-header .actions-container {
  display: flex;
}
.requester-sidebar-horizontal-header .actions-container .import-collection-button,
.requester-sidebar-horizontal-header .actions-container .create-new-button {
  color: var(--content-color-primary);
  background: var(--highlight-background-color-secondary);
}
.requester-sidebar-horizontal-header .actions-container .workspace-join-btn {
  min-width: 38px;
}
.requester-sidebar-horizontal-header .actions-container > * {
  height: var(--size-s);
  font-weight: 600;
  font-size: var(--text-size-s);
  line-height: var(--text-size-m);
  padding: 6px 8px;
  min-width: 0;
}
.requester-sidebar-horizontal-header .actions-container > *:not(:last-child) {
  margin-right: var(--spacing-xs);
}

.requester-left-sidebar__header-collapsed {
  height: 100%;
  position: relative;
  background-color: var(--global-navigation-background-color);
  min-width: 40px;
}
.requester-left-sidebar__header-collapsed .requester-left-sidebar__header-icons {
  padding: var(--spacing-s);
  cursor: pointer;
}
.requester-left-sidebar__header-collapsed.withLabels {
  width: 80px;
}
.requester-left-sidebar__header-collapsed.sidebarOverlay {
  cursor: pointer;
}

.requester-left-sidebar__header-uncollapsed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid var(--border-color-default);
  min-width: 40px;
  background-color: var(--global-navigation-background-color);
  align-items: center;
}
.requester-left-sidebar__header-uncollapsed .list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.requester-left-sidebar__header-uncollapsed.withLabels {
  width: 80px;
}

.requester-left-sidebar__header-uncollapsed-tab {
  width: 80px;
  height: 52px;
  padding: var(--spacing-zero) var(--spacing-xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--content-color-secondary);
  font-size: var(--text-size-xs);
  line-height: var(--text-size-xl);
  text-align: center;
  flex: none;
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-xs);
  border-radius: var(--border-radius-default);
}
.requester-left-sidebar__header-uncollapsed-tab.more-options-tab {
  margin: var(--spacing-xs) var(--spacing-zero);
}
.requester-left-sidebar__header-uncollapsed-tab use {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar__header-uncollapsed-tab svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar__header-uncollapsed-tab svg {
  color: var(--content-color-secondary);
}
.requester-left-sidebar__header-uncollapsed-tab:hover:not(.more-options-tab) {
  position: relative;
  background: var(--highlight-background-color-primary);
  color: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab:hover:not(.more-options-tab) use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab:hover:not(.more-options-tab) svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab:hover:not(.more-options-tab) svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab.active {
  background: var(--highlight-background-color-tertiary);
  position: relative;
  box-shadow: var(--base-color-brand);
  color: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab.active use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab.active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab.active svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar__header-uncollapsed-tab.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  background: var(--base-color-brand);
  border-radius: 0 var(--border-radius-s) var(--border-radius-s) 0;
  top: var(--spacing-xs);
  bottom: var(--spacing-xs);
}

.requester-left-sidebar__header-collapsed-tab {
  width: 40px;
  height: 40px;
  padding: var(--spacing-s);
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.requester-left-sidebar__header-collapsed-tab use {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar__header-collapsed-tab svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar__header-collapsed-tab svg {
  color: var(--content-color-secondary);
}
.requester-left-sidebar__header-collapsed-tab.more-options-tab-without-labels {
  margin: var(--spacing-xs) auto var(--spacing-zero) auto;
  border-radius: var(--border-radius-default);
}
.requester-left-sidebar__header-collapsed-tab span {
  height: var(--size-xs);
}
.requester-left-sidebar__header-collapsed-tab .tabs_more {
  min-width: 40px;
  height: 40px;
}
.requester-left-sidebar__header-collapsed-tab:hover {
  position: relative;
  background: var(--highlight-background-color-primary);
}
.requester-left-sidebar__header-collapsed-tab:hover use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-collapsed-tab:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-collapsed-tab:hover svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar__header-collapsed-tab.active {
  background: var(--highlight-background-color-primary);
}
.requester-left-sidebar__header-collapsed-tab.active use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-collapsed-tab.active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__header-collapsed-tab.active svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar__header-collapsed-tab.active::before {
  content: "";
  position: absolute;
  left: 0;
  height: 40px;
  width: 2px;
  background: var(--base-color-brand);
  top: 0;
}

.requester-left-sidebar-toggle-tabs {
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: var(--spacing-xs);
  background-color: transparent;
  height: var(--size-s);
  width: var(--size-s);
  border-radius: var(--border-radius-s);
}
.requester-left-sidebar-toggle-tabs use {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar-toggle-tabs svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar-toggle-tabs svg {
  color: var(--content-color-secondary);
}
.requester-left-sidebar-toggle-tabs:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.requester-left-sidebar-toggle-tabs:hover use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar-toggle-tabs:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar-toggle-tabs:hover svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar-toggle-tabs.is-disabled:hover {
  background: none;
}

.requester-left-sidebar__actions-container {
  display: flex;
  height: 40px;
  padding: var(--spacing-s);
  box-sizing: border-box;
  align-items: center;
  position: relative;
}
.requester-left-sidebar__actions-container .btn {
  background: none;
  height: var(--size-s);
  width: var(--size-s);
  border-radius: var(--border-radius-s);
}
.requester-left-sidebar__actions-container .btn.is-disabled:hover {
  background: none;
}
.requester-left-sidebar__actions-container .btn:hover {
  background-color: var(--highlight-background-color-secondary);
}
.requester-left-sidebar__actions-container .btn:hover use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .btn:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .btn:hover svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .create-new__btn {
  margin-right: var(--spacing-xs);
  width: var(--size-s);
}
.requester-left-sidebar__actions-container .input-search-group {
  height: var(--size-s);
  flex: 1;
  border-radius: var(--border-radius-s);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background-color: var(--global-navigation-background-color);
}
.requester-left-sidebar__actions-container .input-search-group use {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar__actions-container .input-search-group svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-left-sidebar__actions-container .input-search-group svg {
  color: var(--content-color-secondary);
}
.requester-left-sidebar__actions-container .input-search-group.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.requester-left-sidebar__actions-container .input-search-group.is-focused {
  background-color: var(--background-color-primary);
  border-color: var(--border-color-strong);
}
.requester-left-sidebar__actions-container .input-search-group.is-focused use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .input-search-group.is-focused svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .input-search-group.is-focused svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .input-search-group.is-hovered.is-focused {
  background-color: var(--background-color-primary);
  border-color: var(--border-color-strong);
}
.requester-left-sidebar__actions-container .input-search-group.is-hovered.is-focused use {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .input-search-group.is-hovered.is-focused svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .input-search-group.is-hovered.is-focused svg {
  color: var(--content-color-primary);
}
.requester-left-sidebar__actions-container .secondary-actions-container {
  display: flex;
  margin-left: var(--spacing-xs);
}

.requester-left-sidebar__header-tab-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80px;
}

.requester-left-sidebar__loading-container {
  margin-top: 40px;
}

.sidebar.overlay-mode + .pane-resize-handle {
  pointer-events: none;
}

.sidebar-list-item {
  display: flex;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--content-color-primary);
  padding: var(--spacing-zero) var(--spacing-s);
}
.sidebar-list-item:hover, .sidebar-list-item.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.sidebar-list-item:hover .sidebar-list-item__right-section-actions .btn,
.sidebar-list-item:hover .actions-dropdown .btn, .sidebar-list-item.is-hovered .sidebar-list-item__right-section-actions .btn,
.sidebar-list-item.is-hovered .actions-dropdown .btn {
  height: var(--size-s);
}
.sidebar-list-item.active {
  background-color: var(--highlight-background-color-secondary);
}
.sidebar-list-item.active .sidebar-list-item__right-section-actions,
.sidebar-list-item.active .actions-dropdown {
  display: flex;
}
.sidebar-list-item.active .sidebar-list-item__right-section-actions .btn,
.sidebar-list-item.active .actions-dropdown .btn {
  height: var(--size-s);
}
.sidebar-list-item:focus, .sidebar-list-item.is-focused {
  background-color: var(--background-color-brand);
  border-radius: var(--border-radius-default);
}
.sidebar-list-item.selected {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-tertiary);
}
.sidebar-list-item__actions-container {
  display: flex;
  align-items: center;
}
.sidebar-list-item__actions-container .btn {
  background-color: transparent;
  height: var(--size-s);
  width: var(--size-s);
  border-radius: var(--border-radius-s);
}
.sidebar-list-item__actions-container .btn use {
  fill: var(--content-color-secondary);
}
.sidebar-list-item__actions-container .btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.sidebar-list-item__actions-container .btn svg {
  color: var(--content-color-secondary);
}
.sidebar-list-item__actions-container .btn:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.sidebar-list-item__actions-container .btn:hover use {
  fill: var(--content-color-primary);
}
.sidebar-list-item__actions-container .btn:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.sidebar-list-item__actions-container .btn:hover svg {
  color: var(--content-color-primary);
}
.sidebar-list-item__actions-container .btn.is-disabled:hover {
  background: none;
}
.sidebar-list-item__actions-container .sidebar-list-item__right-section-actions,
.sidebar-list-item__actions-container .actions-dropdown {
  display: flex;
}
.sidebar-list-item__actions-container .sidebar-list-item__right-section-actions .btn,
.sidebar-list-item__actions-container .actions-dropdown .btn {
  height: var(--size-s);
  width: var(--size-s);
}
.sidebar-list-item__actions-container .btn use,
.sidebar-list-item__actions-container .actions-dropdown use {
  fill: var(--content-color-secondary);
}
.sidebar-list-item__actions-container .btn svg:not([class*=PartnerIcon]) > path[fill],
.sidebar-list-item__actions-container .actions-dropdown svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.sidebar-list-item__actions-container .btn svg,
.sidebar-list-item__actions-container .actions-dropdown svg {
  color: var(--content-color-secondary);
}
.sidebar-list-item__actions-container .btn:hover use,
.sidebar-list-item__actions-container .actions-dropdown:hover use {
  fill: var(--content-color-primary);
}
.sidebar-list-item__actions-container .btn:hover svg:not([class*=PartnerIcon]) > path[fill],
.sidebar-list-item__actions-container .actions-dropdown:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.sidebar-list-item__actions-container .btn:hover svg,
.sidebar-list-item__actions-container .actions-dropdown:hover svg {
  color: var(--content-color-primary);
}
.sidebar-list-item__left-actions-container {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}
.sidebar-list-item__icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: var(--size-xs);
  min-width: var(--size-s);
}
.sidebar-list-item__content-wrapper {
  display: flex;
  flex: 1;
  min-width: 0;
  margin-left: 28px;
  padding: var(--spacing-xs) var(--spacing-zero);
  align-items: center;
}
.sidebar-list-item__content-wrapper > *:not(:last-child) {
  margin-right: var(--spacing-s);
}
.sidebar-list-item__name-wrapper {
  line-height: var(--line-height-m);
  display: flex;
  min-width: 0px;
  margin-right: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar-list-item__name-wrapper .inline-input {
  height: var(--size-s);
}
.sidebar-list-item__name-wrapper:hover {
  color: var(--content-color-primary);
}
.sidebar-list-item__name-wrapper.use-browser-tabs {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.sidebar-list-item__content-wrapper.edit-mode .sidebar-list-item__name-wrapper {
  margin-right: var(--spacing-xs);
  width: 100%;
}
.sidebar-list-item__content-wrapper.edit-mode .inline-input__wrapper {
  width: 100%;
}
.sidebar-list-item__status-indicators {
  display: flex;
}
.sidebar-list-item__status-indicators i {
  margin-right: var(--spacing-xs);
}
.sidebar-list-item__link {
  flex: 1;
  overflow: hidden;
}
.sidebar-list-item__name {
  padding-right: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar-list-item .sidebar-action-btn {
  background-color: transparent;
  height: var(--size-s);
  width: var(--size-s);
  border-radius: var(--border-radius-s);
}
.sidebar-list-item .sidebar-action-btn use {
  fill: var(--content-color-secondary);
}
.sidebar-list-item .sidebar-action-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.sidebar-list-item .sidebar-action-btn svg {
  color: var(--content-color-secondary);
}
.sidebar-list-item .sidebar-action-btn:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.sidebar-list-item .sidebar-action-btn:hover use {
  fill: var(--content-color-primary);
}
.sidebar-list-item .sidebar-action-btn:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.sidebar-list-item .sidebar-action-btn:hover svg {
  color: var(--content-color-primary);
}
.sidebar-list-item .sidebar-action-btn.is-disabled:hover {
  background: none;
}

.requester-builder {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.requester-builder-header {
  flex: none;
  height: 41px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--border-color-default);
}
.requester-builder-header .requester-tabs-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  min-width: 0;
  height: 40px;
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-actions {
  display: inherit;
  position: sticky;
  right: 0;
  align-items: center;
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-nav-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-nav-btn-wrapper.nav-btn-left {
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: var(--spacing-xs);
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-nav-btn-wrapper.nav-btn-right {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-left: var(--spacing-xs);
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-nav-btn-wrapper .requester-tab-nav-previous-icon {
  opacity: 0.8;
  padding: 0 5px;
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-nav-btn-wrapper .requester-tab-nav-previous-icon:hover {
  cursor: pointer;
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-nav-btn-wrapper .requester-tab-nav-next-icon {
  opacity: 0.8;
  padding: 0 5px;
  margin-left: 10px;
}
.requester-builder-header .requester-tabs-wrapper .requester-tab-nav-btn-wrapper .requester-tab-nav-next-icon:hover {
  cursor: pointer;
}

.requester-builder-contents {
  background: inherit;
  flex: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.environment-resize-handle-wrapper {
  cursor: ew-resize;
  margin-right: var(--spacing-xs);
}
.environment-resize-handle-wrapper .environment-resize-handle {
  background: var(--border-color-default);
  height: 100%;
  width: 1px;
}
.environment-resize-handle-wrapper:hover .environment-resize-handle {
  background: var(--border-color-strong);
}

.requester-bottom-pane-header-container {
  /**
    We have picked this z-index value for the bottom pane because the following reasons:
    1. It should be greater than 1 for the status bar items to show when the bottom pane is open.
    2. It should be greater than 8 so that the .pane-resize-handle does not overlap the branch switcher.
    3. Needs to be at least 90 to allow Postbot to be expanded over the top of the app header.
  */
  z-index: 90;
}

.requester-bottom-pane-header {
  display: flex;
  align-items: center;
}
.collapsedY .requester-bottom-pane-header {
  background-color: var(--global-navigation-background-color);
}
.requester-bottom-pane-header .pane-header__section-left {
  flex: 1;
  display: flex;
  align-items: center;
}
.requester-bottom-pane-header .pane-header__section-left .tab-text-wrapper {
  padding: var(--spacing-xs) var(--spacing-zero);
}
.requester-bottom-pane-header .pane-header__tabs .tab {
  display: flex;
  align-items: center;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.requester-bottom-pane-header .pane-header__tabs .tab .pm-icon {
  margin-right: var(--spacing-xs);
}
.requester-bottom-pane-header .pane-header__tabs .tab .pm-icon use {
  fill: var(--content-color-secondary);
}
.requester-bottom-pane-header .pane-header__tabs .tab .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-bottom-pane-header .pane-header__tabs .tab .pm-icon svg {
  color: var(--content-color-secondary);
}
.requester-bottom-pane-header .pane-header__tabs .tab.is-active .pm-icon use, .requester-bottom-pane-header .pane-header__tabs .tab:hover .pm-icon use {
  fill: var(--content-color-primary);
}
.requester-bottom-pane-header .pane-header__tabs .tab.is-active .pm-icon svg:not([class*=PartnerIcon]) > path[fill], .requester-bottom-pane-header .pane-header__tabs .tab:hover .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-bottom-pane-header .pane-header__tabs .tab.is-active .pm-icon svg, .requester-bottom-pane-header .pane-header__tabs .tab:hover .pm-icon svg {
  color: var(--content-color-primary);
}
.requester-bottom-pane-header .pane-header__options {
  margin-left: var(--spacing-s);
}
.requester-bottom-pane-header .pane-header__actions {
  display: flex;
}
.requester-bottom-pane-header .pane-header__actions .actions__close-icon {
  margin: var(--spacing-s) var(--spacing-s) var(--spacing-xs) var(--spacing-s);
  cursor: pointer;
}
.requester-bottom-pane-header .pane-header__actions .actions__close-icon use {
  fill: var(--content-color-secondary);
}
.requester-bottom-pane-header .pane-header__actions .actions__close-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.requester-bottom-pane-header .pane-header__actions .actions__close-icon svg {
  color: var(--content-color-secondary);
}
.requester-bottom-pane-header .pane-header__actions .actions__close-icon:hover use, .requester-bottom-pane-header .pane-header__actions .actions__close-icon.is-hovered use {
  fill: var(--content-color-primary);
}
.requester-bottom-pane-header .pane-header__actions .actions__close-icon:hover svg:not([class*=PartnerIcon]) > path[fill], .requester-bottom-pane-header .pane-header__actions .actions__close-icon.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-bottom-pane-header .pane-header__actions .actions__close-icon:hover svg, .requester-bottom-pane-header .pane-header__actions .actions__close-icon.is-hovered svg {
  color: var(--content-color-primary);
}

.bottom-pane-container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.bottom-pane-tab-content {
  flex: 1 auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.bottom-pane-status-bar {
  flex-shrink: 0;
  background-color: var(--background-color-primary);
  z-index: 1;
  height: var(--size-s);
}

.bottom-pane-tab-content--console,
.bottom-pane-tab-content--find-replace {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.bottom-pane-tab-content--console .console-container,
.bottom-pane-tab-content--find-replace .console-container {
  flex: 1 auto;
}

.plugin__sidebar-shortcut {
  margin-right: var(--spacing-s);
  height: 20px;
}
.plugin__sidebar-shortcut:hover, .plugin__sidebar-shortcut.is-hovered {
  background-color: transparent;
}

@media only screen and (max-width: 1024px) {
  .pane-header-tabs--find-replace-label {
    display: none;
  }
}
@media only screen and (max-width: 320px) {
  .pane-header-tabs--console-label {
    display: none;
  }
}
.requester-scratchpad-info-container,
.public-info-container,
.requester-info-container {
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--content-color-primary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-sizing: border-box;
}
.requester-scratchpad-info-container div,
.public-info-container div,
.requester-info-container div {
  height: 100%;
}

.requester-scratchpad-info-container__light {
  background: var(--yellow-30);
}
.requester-scratchpad-info-container__dark {
  background: var(--yellow-70);
}
.requester-scratchpad-info-container .requester-scratchpad-info {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-small);
  position: relative;
}
.requester-scratchpad-info-container .requester-scratchpad-info span {
  margin-left: var(--spacing-s);
}
.requester-scratchpad-info-container .requester-scratchpad-info__switch {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}
.requester-scratchpad-info-container .requester-scratchpad-info__close {
  background-color: inherit;
  position: absolute;
  right: var(--spacing-s);
  height: var(--size-m);
  width: var(--size-m);
}
.requester-scratchpad-info-container .requester-scratchpad-info__close use {
  fill: var(--content-color-primary);
}
.requester-scratchpad-info-container .requester-scratchpad-info__close svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.requester-scratchpad-info-container .requester-scratchpad-info__close svg {
  color: var(--content-color-primary);
}

.public-info-container {
  background: var(--background-color-tertiary);
}
.public-info-container .public-info {
  display: flex;
  margin-left: var(--spacing-s);
  flex: 1;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.public-info-container .public-info .btn {
  width: fit-content;
  padding: 0 var(--spacing-s);
}
.public-info-container .public-info .btn span {
  margin-left: var(--spacing-s);
}

.requester-info-container__light {
  background: var(--yellow-10);
}
.requester-info-container__dark {
  background: var(--yellow-90);
}
.requester-info-container .requester-info {
  display: flex;
  margin-left: var(--spacing-s);
  flex: 1;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-small);
}
.requester-info-container .requester-info span {
  margin-left: var(--spacing-s);
}
.requester-info-container .requester-info .btn {
  text-decoration: underline;
  font-weight: var(--text-weight-primary);
  color: var(--content-color-primary);
  padding-left: var(--spacing-xs);
}

.context-bar__header {
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 100%;
}

.context-bar {
  display: flex;
  flex-direction: row;
}

.collection-sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.collection-sidebar .collection-sidebar-menu {
  flex: 0 0 40px;
  box-sizing: border-box;
}
.collection-sidebar .collection-sidebar-list {
  flex: 1;
}
.collection-sidebar.is-hidden {
  display: none;
}

.collection-sidebar-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.collection-sidebar__indent {
  flex: 0 0 var(--spacing-m);
  box-sizing: border-box;
  height: inherit;
}
.collection-sidebar__indent.active-indent {
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}

.collection-sidebar-empty__branch {
  display: flex;
}

.collection-sidebar-drag-layer {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.collection-sidebar-drag-preview {
  background-color: var(--highlight-background-color-primary);
  color: var(--content-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-s) var(--spacing-m);
  box-shadow: var(--shadow-default);
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: 18px;
  font-weight: var(--text-weight-regular);
  text-align: left;
  display: inline-block;
  word-break: break-word;
  max-width: 220px;
}

.collection-sidebar-menu .collection-sidebar-menu__filter {
  flex: 1;
}
.collection-sidebar-menu .collection-sidebar-menu__actions {
  flex: 1;
}

.collection-sidebar-menu__filter .tabs {
  margin-top: 5px;
  margin-left: 10px;
}
.collection-sidebar-menu__filter .tab {
  padding-left: 5px;
  padding-right: 5px;
}

.collection-sidebar-menu__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px;
}

.collection-sidebar-menu__actions-trash {
  padding: 0;
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
}
.collection-sidebar-menu__actions-trash.btn-text:hover, .collection-sidebar-menu__actions-trash.btn-text.is-disabled:hover {
  color: var(--content-color-primary);
}
.collection-sidebar-menu__actions-trash__icon {
  margin-right: var(--spacing-s);
}

.collection-sidebar-menu__actions-new-collection {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.collection-sidebar-menu__actions-new-button {
  padding-right: 6px;
}
.collection-sidebar-menu__actions-new-button use {
  fill: var(--base-color-brand);
}
.collection-sidebar-menu__actions-new-button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.collection-sidebar-menu__actions-new-button svg {
  color: var(--base-color-brand);
}

.collection-sidebar-menu__actions-sort-collection-wrapper {
  padding-top: 11px;
  margin-right: 15px;
}
.collection-sidebar-menu__actions-sort-collection-wrapper .dropdown .btn {
  height: auto;
  padding: 0;
}

.collection-sidebar-menu__actions-sort-collection-dropdown {
  background-color: transparent !important;
}

.collection-sidebar-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  margin-right: 1px;
  transform: translateZ(0);
}
.collection-sidebar-list.prerender-scroll {
  overflow-y: auto;
}
.collection-sidebar-list:hover .collection-sidebar__indent:not(.active-indent) {
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.collection-sidebar-list:hover .is-selected .collection-sidebar__indent {
  border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
}

/* Filter */
.collection-sidebar-list[data-filter=me] .collection-sidebar-list-item.is-subscribed {
  display: none;
}

.collection-sidebar-list[data-filter=team] .collection-sidebar-list-item.is-unshared {
  display: none;
}

.collection-sidebar-list-item__toggle-btn {
  margin-left: var(--spacing-xs);
  height: var(--size-xs);
  width: var(--size-xs);
  background-color: unset;
}
.collection-sidebar-list-item__toggle-btn use {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__toggle-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__toggle-btn svg {
  color: var(--content-color-primary);
}
.collection-sidebar-list-item__toggle-btn:hover, .collection-sidebar-list-item__toggle-btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.collection-sidebar-list-item__toggle-btn:hover use, .collection-sidebar-list-item__toggle-btn.is-hovered use {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__toggle-btn:hover svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__toggle-btn.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__toggle-btn:hover svg, .collection-sidebar-list-item__toggle-btn.is-hovered svg {
  color: var(--content-color-primary);
}

.collection-sidebar-list-item__example-icon__wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 28px;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xl);
}

.collection-sidebar-list-item__ec-item-icon__wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 0 0 28px;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}

.collection-sidebar-fork-label__text {
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-xs);
  line-height: var(--line-height-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  display: inline-block;
}

.collection-sidebar-list__request-spacing {
  height: inherit;
  width: var(--size-xs);
  margin-left: var(--spacing-xs);
}

@keyframes shimmer {
  to {
    background-position-x: -25%;
  }
}
.collection-sidebar-list-item {
  border-bottom: 1px solid var(--border-color-default);
  flex: 0 0 auto;
}
.collection-sidebar-list-item.is-hidden {
  display: none;
}

.collection-sidebar-list-item__head {
  display: flex;
  flex-direction: column;
}
.collection-sidebar-list-item__head .collection-sidebar-list-item__head__head {
  flex: 0 0 auto;
}

.collection-sidebar-list-item__head__head {
  display: flex;
  flex-direction: row;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--global-navigation-background-color);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.collection-sidebar-list-item__head__head.is-active {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-list-item__head__head.is-focused.is-sidebar-in-focus {
  box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
}
.collection-sidebar-list-item__head__head.is-selected {
  background-color: var(--highlight-background-color-primary);
}
.collection-sidebar-list-item__head__head.is-selected.is-sidebar-in-focus {
  background-color: var(--highlight-background-color-tertiary);
  box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset;
}
.collection-sidebar-list-item__head__head.is-selected.is-sidebar-in-focus.is-focused {
  box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset, 0px 0px 0px 2px var(--blue-30) inset;
}
.collection-sidebar-list-item__head__head.is-selected.is-sidebar-in-focus .collection-sidebar__indent.active-indent {
  border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
}
.collection-sidebar-list-item__head__head:hover, .collection-sidebar-list-item__head__head.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-list-item__head__head.is-drop-hovered {
  box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
}
.collection-sidebar-list-item__head__head.is-animated {
  background: linear-gradient(-45deg, var(--global-navigation-background-color) 45%, var(--background-color-primary) 50%, var(--global-navigation-background-color) 55%);
  background-size: 200%;
  background-position-x: 110%;
  animation: shimmer 1.5s infinite linear;
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__icon-wrapper {
  flex: 0 0 28px;
  display: flex;
  justify-content: flex-end;
  margin: var(--spacing-zero) var(--spacing-xs);
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__icon-wrapper .icon-collection {
  padding: 0 5px;
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__meta {
  flex: 1;
  margin-left: var(--spacing-s);
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__meta__transient {
  flex: 1;
  margin-left: var(--spacing-zero);
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__meta .collection-sidebar-list-item__head__name__wrapper {
  display: flex;
  align-items: center;
  height: var(--size-s);
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__meta .collection-sidebar-list-item__head__name__wrapper .inline-input__wrapper {
  height: var(--size-s);
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__meta .collection-sidebar-list-item__head__name__wrapper .inline-input {
  font-size: var(--text-size-m);
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__actions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__actions.is-selected {
  visibility: visible;
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__actions.is-pending {
  visibility: visible;
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__action {
  display: flex;
  flex: 0 0 var(--size-s);
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__action .collection-sidebar-list-item__collection-browser_action-open-icon {
  transform: rotate(-180deg);
}
.collection-sidebar-list-item__head__head .collection-sidebar-list-item__head__action .btn {
  width: var(--size-s);
  height: var(--size-s);
  padding: 0;
}
.collection-sidebar-list-item__head__head:hover:not(.is-multi-selection-active) .collection-sidebar-list-item__head__actions, .collection-sidebar-list-item__head__head.is-hovered:not(.is-multi-selection-active) .collection-sidebar-list-item__head__actions {
  visibility: visible;
}

.collection-sidebar-list-item__head__meta {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.collection-sidebar-list-item__head__actions {
  visibility: hidden;
}

.collection-sidebar-list-empty-item {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  padding: var(--spacing-xl) var(--spacing-xxl);
  color: var(--content-color-secondary);
  text-align: center;
}
.collection-sidebar-list-empty-item .entity-empty {
  border: none;
}
.collection-sidebar-list-empty-item .entity-empty .entity-empty__title {
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-l);
  font-weight: var(--text-weight-medium);
}
.collection-sidebar-list-empty-item .entity-empty .entity-empty__subtitle {
  margin-bottom: var(--spacing-xl);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
}
.collection-sidebar-list-empty-item .entity-empty .entity-empty__title,
.collection-sidebar-list-empty-item .entity-empty .entity-empty__subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.collection-sidebar-list-empty-item .entity-empty .entity-empty__illustration-wrapper--collections {
  margin-bottom: 16px;
}
.collection-sidebar-list-empty-item .entity-empty .entity-empty__illustration-wrapper--collections .collections-empty__illustration {
  display: block;
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/collection_cloud.svg");
}
.collection-sidebar-list-empty-item .entity-empty .entity-empty__action .btn-text {
  /*
     This has been done when the collection sidebar is squeezed down
     then the create collection buttons' text gets wrapped and moves to next line
     so to avoid that wrapping, the padding is being set to 0
  */
  padding: 0;
}
.collection-sidebar-list-empty-item .entity-empty .entity-empty__action .create-collection-icon {
  padding: 4px 8px 4px 4px;
}

.collection-sidebar-list-item__head__head:not(.is-favorited) .collection-sidebar-list-item__head__favorite-button {
  display: none;
}
.collection-sidebar-list-item__head__head:not(.is-favorited):hover .collection-sidebar-list-item__head__favorite-button, .collection-sidebar-list-item__head__head:not(.is-favorited).is-hovered .collection-sidebar-list-item__head__favorite-button {
  display: flex;
}

.collection-sidebar-list-item__body--empty {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);
}
.collection-sidebar-list-item__body--empty .collection-sidebar-list__empty-item__content {
  padding: var(--spacing-s) var(--spacing-xl);
}
.collection-sidebar-list-item__body--empty .collection-sidebar-list__empty-item__content.collection-sidebar-list__empty-item__content__for-collection {
  padding: var(--spacing-s) var(--spacing-l);
}
.collection-sidebar-list-item__body--empty .collection-sidebar-list__empty-item__content .btn-text {
  padding: var(--spacing-zero);
  padding-right: var(--spacing-xs);
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
}

.collection-sidebar-list-item__partial-retry {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: flex;
}
.collection-sidebar-list-item__partial-retry .collection-sidebar-list-item__partial-retry__content {
  padding-left: var(--spacing-xl);
  padding-top: var(--spacing-xs);
}
.collection-sidebar-list-item__partial-retry .collection-sidebar-list-item__partial-retry__content .btn-text {
  padding: var(--spacing-zero);
  padding-right: var(--spacing-xs);
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
}

.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__name-icon__wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__name-icon__wrapper .collection-sidebar-list-item__head__name {
  font-size: var(--text-size-m);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  line-height: var(--line-height-m);
  padding-right: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.collection-sidebar-list-item__head__name__wrapper .shared-icon {
  margin-left: 10px;
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button {
  background-color: transparent;
  width: var(--size-s);
  height: var(--size-s);
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button use {
  fill: var(--content-color-secondary);
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button svg {
  color: var(--content-color-secondary);
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button:hover, .collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button:hover .collection-sidebar-list-item__head__favorite-button-icon use, .collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button.is-hovered .collection-sidebar-list-item__head__favorite-button-icon use {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button:hover .collection-sidebar-list-item__head__favorite-button-icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button.is-hovered .collection-sidebar-list-item__head__favorite-button-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button:hover .collection-sidebar-list-item__head__favorite-button-icon svg, .collection-sidebar-list-item__head__name__wrapper .collection-sidebar-list-item__head__favorite-button.is-hovered .collection-sidebar-list-item__head__favorite-button-icon svg {
  color: var(--content-color-primary);
}
.collection-sidebar-list-item__head__meta-info {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: 4px;
  font-size: 11px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}

.collection-sidebar-list-item__head__requests {
  white-space: nowrap;
  color: var(--content-color-primary);
}

.collection-sidebar-list-item__head__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.collection-sidebar-dropdown-actions-wrapper {
  height: var(--size-s);
  width: var(--size-s);
}
.collection-sidebar-dropdown-actions-wrapper .collection-sidebar-list-item__spinner {
  margin-top: var(--spacing-xs);
}

.collection-sidebar-actions-dropdown .dropdown-button .btn,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn {
  border-radius: 0;
  background-color: inherit;
  min-width: unset;
}
.collection-sidebar-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon use,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon use,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon use {
  fill: var(--content-color-secondary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-request-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon svg,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon svg,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn .entity-actions-dropdown__icon svg {
  color: var(--content-color-secondary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn.is-active,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use {
  fill: var(--content-color-primary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg {
  color: var(--content-color-primary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn:hover, .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon use, .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon use,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon use,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon use,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon use,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon use {
  fill: var(--content-color-primary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon svg, .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon svg,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon svg,
.collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon svg,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover .entity-actions-dropdown__icon svg,
.collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered .entity-actions-dropdown__icon svg {
  color: var(--content-color-primary);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn {
  border-radius: var(--border-radius-default);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon use {
  fill: var(--content-color-secondary);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-active .entity-actions-dropdown__icon svg {
  color: var(--content-color-secondary);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn:hover, .collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn:hover, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn:hover, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use, .collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon use,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon use {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill],
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-list-item__head__action .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-list-item__folder .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-list-item__request .collection-sidebar-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__head__action .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__folder .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__request .collection-sidebar-folder-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__head__action .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__folder .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn:hover.is-active .entity-actions-dropdown__icon svg,
.collection-sidebar-list-item__request .collection-sidebar-request-actions-dropdown .dropdown-button .btn.is-hovered.is-active .entity-actions-dropdown__icon svg {
  color: var(--content-color-primary);
}
.collection-sidebar-actions-dropdown .dropdown-menu-item.is-sub-item-available .expand-icon-wrapper,
.collection-sidebar-folder-actions-dropdown .dropdown-menu-item.is-sub-item-available .expand-icon-wrapper,
.collection-sidebar-request-actions-dropdown .dropdown-menu-item.is-sub-item-available .expand-icon-wrapper {
  flex: 0 1 auto;
}

.collection-sidebar-list-item__folder {
  display: flex;
  flex-direction: column;
}
.collection-sidebar-list-item__folder .collection-sidebar-list-item__folder__head {
  flex: 0 0 auto;
}
.collection-sidebar-list-item__folder .collection-sidebar-list-item__folder__body {
  flex: 0 0 auto;
}

.collection-sidebar-list-item__folder.is-drop-hovered-top {
  box-shadow: 0 -2px 0 var(--base-color-brand);
}
.collection-sidebar-list-item__folder.is-drop-hovered-bottom {
  box-shadow: 0 2px 0 var(--base-color-brand);
}
.collection-sidebar-list-item__folder.is-hidden {
  display: none;
}

.collection-sidebar-list-item__folder__head {
  display: flex;
  flex-direction: row;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--global-navigation-background-color);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.collection-sidebar-list-item__folder__head.is-pending .collection-sidebar-list-item__folder__head__actions {
  visibility: visible;
}
.collection-sidebar-list-item__folder__head.is-active {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-list-item__folder__head.is-focused.is-sidebar-in-focus {
  box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
}
.collection-sidebar-list-item__folder__head.is-selected {
  background-color: var(--highlight-background-color-primary);
}
.collection-sidebar-list-item__folder__head.is-selected.is-sidebar-in-focus {
  background-color: var(--highlight-background-color-tertiary);
  box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset;
}
.collection-sidebar-list-item__folder__head.is-selected.is-sidebar-in-focus.is-focused {
  box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset, 0px 0px 0px 2px var(--blue-30) inset;
}
.collection-sidebar-list-item__folder__head.is-selected.is-sidebar-in-focus .collection-sidebar__indent.active-indent {
  border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
}
.collection-sidebar-list-item__folder__head:hover, .collection-sidebar-list-item__folder__head.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-list-item__folder__head:hover.is-multi-selection-active .collection-sidebar-list-item__folder__head__actions, .collection-sidebar-list-item__folder__head.is-hovered.is-multi-selection-active .collection-sidebar-list-item__folder__head__actions {
  visibility: hidden;
}
.collection-sidebar-list-item__folder__head .collection-sidebar-list-item__folder__icon-wrapper {
  flex: 0 0 28px;
  display: flex;
  justify-content: flex-end;
  margin: var(--spacing-zero) var(--spacing-xs);
}
.collection-sidebar-list-item__folder__head .collection-sidebar-list-item__folder__head__meta {
  flex: 1;
}
.collection-sidebar-list-item__folder__head .collection-sidebar-list-item__folder__head__actions {
  flex: 0 0 var(--size-s);
}
.collection-sidebar-list-item__folder__head.is-right-above {
  box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
}
.collection-sidebar-list-item__folder__head.is-drop-hovered {
  position: relative;
}
.collection-sidebar-list-item__folder__head.is-drop-hovered::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background-color: var(--base-color-brand);
}

.collection-sidebar-list-item__folder__body {
  padding-left: 10px;
}
.collection-sidebar-list-item__folder__body.is-dragged {
  opacity: 0.1;
}
.collection-sidebar-list-item__folder__body .collection-sidebar-list-item__folder__body--empty {
  padding-left: 20px;
}

.collection-sidebar-list-item__folder__head__meta {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  align-items: center;
}
.collection-sidebar-list-item__folder__head__meta .collection-sidebar-list-item__folder__head__name {
  line-height: var(--line-height-m);
  padding-right: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collection-sidebar-list-item__folder__head__icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.collection-sidebar-folder-dropdown-actions-wrapper .btn {
  width: var(--size-s);
  height: var(--size-s);
  padding: var(--spacing-zero);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn .entity-actions-dropdown__icon use {
  fill: var(--content-color-secondary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn .entity-actions-dropdown__icon svg {
  color: var(--content-color-secondary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn.is-active .entity-actions-dropdown__icon use {
  fill: var(--content-color-primary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn.is-active .entity-actions-dropdown__icon svg {
  color: var(--content-color-primary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn:hover .entity-actions-dropdown__icon use, .collection-sidebar-folder-dropdown-actions-wrapper .btn.is-hovered .entity-actions-dropdown__icon use {
  fill: var(--content-color-primary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn:hover .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-folder-dropdown-actions-wrapper .btn.is-hovered .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn:hover .entity-actions-dropdown__icon svg, .collection-sidebar-folder-dropdown-actions-wrapper .btn.is-hovered .entity-actions-dropdown__icon svg {
  color: var(--content-color-primary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn:hover.is-active .entity-actions-dropdown__icon use, .collection-sidebar-folder-dropdown-actions-wrapper .btn.is-hovered.is-active .entity-actions-dropdown__icon use {
  fill: var(--content-color-secondary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn:hover.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-folder-dropdown-actions-wrapper .btn.is-hovered.is-active .entity-actions-dropdown__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.collection-sidebar-folder-dropdown-actions-wrapper .btn:hover.is-active .entity-actions-dropdown__icon svg, .collection-sidebar-folder-dropdown-actions-wrapper .btn.is-hovered.is-active .entity-actions-dropdown__icon svg {
  color: var(--content-color-secondary);
}

.collection-sidebar-list-item__folder {
  display: flex;
  flex-direction: column;
}
.collection-sidebar-list-item__folder .collection-sidebar-list-item__request__head {
  flex: 0 0 auto;
}

.collection-sidebar-list-item__request__head {
  display: flex;
  flex-direction: row;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--global-navigation-background-color);
}
.collection-sidebar-list-item__request__head:hover, .collection-sidebar-list-item__request__head.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-list-item__request__head .collection-sidebar-list-item__request__label-wrapper {
  flex: 0 0 28px;
  margin: var(--spacing-zero) var(--spacing-xs);
}
.collection-sidebar-list-item__request__head .collection-sidebar-list-item__request__meta {
  flex: 1;
}
.collection-sidebar-list-item__request__head .collection-sidebar-list-item__request__actions {
  flex: 0 0 var(--size-s);
}
.collection-sidebar-list-item__request__head .collection-sidebar-list-item__request__name {
  line-height: var(--line-height-m);
  padding-right: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collection-sidebar-list-item__request__head {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.collection-sidebar-list-item__request__head.is-pending .collection-sidebar-list-item__request__actions {
  visibility: visible;
}
.collection-sidebar-list-item__request__head:hover:not(.is-multi-selection-active) .collection-sidebar-list-item__request__actions, .collection-sidebar-list-item__request__head.is-hovered:not(.is-multi-selection-active) .collection-sidebar-list-item__request__actions {
  visibility: visible;
}
.collection-sidebar-list-item__request__head.is-selected.is-sidebar-in-focus .collection-sidebar__indent.active-indent {
  border-right: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
}
.collection-sidebar-list-item__request__head.is-active {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-list-item__request__head.is-focused.is-sidebar-in-focus {
  box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
}
.collection-sidebar-list-item__request__head.is-selected {
  background-color: var(--highlight-background-color-primary);
}
.collection-sidebar-list-item__request__head.is-selected.is-sidebar-in-focus {
  background-color: var(--highlight-background-color-tertiary);
  box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset;
}
.collection-sidebar-list-item__request__head.is-selected.is-sidebar-in-focus.is-focused {
  box-shadow: 2px var(--spacing-zero) 0 var(--base-color-brand) inset, 0px 0px 0px 2px var(--blue-30) inset;
}
.collection-sidebar-list-item__request__head.is-drop-hovered-top:before {
  left: 0;
  background-color: var(--base-color-brand);
}
.collection-sidebar-list-item__request__head.is-drop-hovered-bottom:before {
  left: 0;
  background-color: var(--base-color-brand);
}
.collection-sidebar-list-item__request__head.is-drop-hovered-top {
  box-shadow: 0 -2px 0 var(--base-color-brand);
}
.collection-sidebar-list-item__request__head.is-drop-hovered-bottom {
  box-shadow: inset 0px -2px var(--base-color-brand);
}
.collection-sidebar-list-item__request__head.is-right-above {
  box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
}
.collection-sidebar-list-item__request__head.is-hidden {
  display: none;
}

.collection-sidebar-list-item__request__actions {
  visibility: hidden;
}

.collection-sidebar-list-item__request__label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.collection-sidebar-list-item__request__label-wrapper.next-entity-padding {
  padding-top: 3px !important;
}
.collection-sidebar-list-item__request__label-wrapper.folder-accordian-padding {
  padding-top: 1px !important;
}

.collection-sidebar-list-item__request__label {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: -0.2px;
  font-size: var(--text-size-xs);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  text-align: left;
  font-size: 9px;
  font-weight: var(--text-weight-bold);
}

.collection-sidebar-list-item__request__meta {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-m);
  font-weight: 400;
  color: var(--content-color-primary);
  align-items: center;
}

.collection-sidebar-request-dropdown-actions-wrapper .btn {
  width: var(--size-s);
  height: var(--size-s);
  padding: var(--spacing-zero);
}
.collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn .collection-sidebar-request-dropdown-actions-button use {
  fill: var(--content-color-secondary);
}
.collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn .collection-sidebar-request-dropdown-actions-button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn .collection-sidebar-request-dropdown-actions-button svg {
  color: var(--content-color-secondary);
}
.collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn:hover, .collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn:hover .collection-sidebar-request-dropdown-actions-button use, .collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn.is-hovered .collection-sidebar-request-dropdown-actions-button use {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn:hover .collection-sidebar-request-dropdown-actions-button svg:not([class*=PartnerIcon]) > path[fill], .collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn.is-hovered .collection-sidebar-request-dropdown-actions-button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn:hover .collection-sidebar-request-dropdown-actions-button svg, .collection-sidebar-list-item__request__actions .collection-sidebar-request-dropdown-actions-wrapper .btn.is-hovered .collection-sidebar-request-dropdown-actions-button svg {
  color: var(--content-color-primary);
}

.collection-sidebar-list-item__dummy {
  display: flex;
  flex-direction: column;
}
.collection-sidebar-list-item__dummy .collection-sidebar-list-item__dummy__head {
  flex: 0 0 auto;
}

.collection-sidebar-list-item__dummy__head {
  display: flex;
  flex-direction: row;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--background-color-secondary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.collection-sidebar-list-item__dummy__head:focus, .collection-sidebar-list-item__dummy__head.is-focused {
  background-color: var(--highlight-background-color-tertiary);
}
.collection-sidebar-list-item__dummy__head:hover, .collection-sidebar-list-item__dummy__head.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__dropdown-icon {
  display: flex;
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__dropdown-icon.is-disabled {
  visibility: hidden;
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__icon-wrapper {
  flex: 0 0 28px;
  display: flex;
  justify-content: flex-end;
  margin: var(--spacing-zero) var(--spacing-xs);
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__icon-wrapper .icon-collection {
  padding: 0 5px;
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy-api-collection__icon-wrapper {
  flex: 0 0 28px;
  display: flex;
  justify-content: flex-end;
  margin-left: var(--spacing-xs);
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy-api-collection__icon-wrapper .icon-collection {
  padding: 0 5px;
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta {
  flex: 1;
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta__transient {
  flex: 1;
  margin-left: var(--spacing-zero);
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta.is-environment {
  margin-left: 28px;
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta .collection-sidebar-list-item__dummy__name__wrapper {
  display: flex;
  align-items: center;
  height: var(--size-s);
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta .collection-sidebar-list-item__dummy__name__wrapper .inline-input__wrapper {
  height: var(--size-s);
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta .collection-sidebar-list-item__dummy__name__wrapper .inline-input {
  font-size: var(--text-size-m);
}
.collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta.is-collection:not(.source-api), .collection-sidebar-list-item__dummy__head .collection-sidebar-list-item__dummy__meta.is-extensibleCollection {
  margin-left: var(--spacing-s);
}

.collection-sidebar-list-item__dummy__meta {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.collection-sidebar-list-item__dummy__name__wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.collection-sidebar-list-item__dummy__name__wrapper .collection-sidebar-list-item__dummy__name {
  font-size: var(--text-size-m);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  line-height: var(--line-height-m);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.collection-sidebar-list-item__dummy__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addrequest-top-container-modal .addrequest-top-container,
.save-multiple-requests-modal .addrequest-top-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
}
.addrequest-top-container-modal .addrequest-top-container .addrequest-collection-selector-row,
.save-multiple-requests-modal .addrequest-top-container .addrequest-collection-selector-row {
  flex: 1;
  min-height: 0;
}
.addrequest-top-container-modal .addrequest-top-container .addrequest-collection-selector-row .collection-explorer,
.save-multiple-requests-modal .addrequest-top-container .addrequest-collection-selector-row .collection-explorer {
  height: 100%;
}
.addrequest-top-container-modal .addrequest-top-container .addrequest-collection-selector-row .collection-explorer .explorer-row__loading_icon,
.save-multiple-requests-modal .addrequest-top-container .addrequest-collection-selector-row .collection-explorer .explorer-row__loading_icon {
  margin-right: var(--spacing-s);
}

.addrequest-top-container .row {
  padding: 7px 0;
}
.addrequest-top-container .add-request-helptext {
  padding-bottom: 20px;
}
.addrequest-top-container .add-request-helptext .btn {
  padding: 0px;
}
.addrequest-top-container .addrequest-top-container-name-row {
  padding-top: 0px;
}
.addrequest-top-container .field-label {
  margin-bottom: var(--spacing-s);
}
.addrequest-top-container .addrequest-description-row {
  color: var(--content-color-tertiary);
}
.addrequest-top-container .addrequest-description-row .add-description-button {
  margin-bottom: var(--spacing-m);
}

.addrequest-collection-name-container .input-search,
.addrequest-collection-selector-row .input-search {
  font-size: 12px;
}
.addrequest-collection-name-container .addrequest-new-collection-name-row,
.addrequest-collection-selector-row .addrequest-new-collection-name-row {
  padding: 20px 0 0 0;
}
.addrequest-collection-name-container .addrequest-collection-selector-row,
.addrequest-collection-selector-row .addrequest-collection-selector-row {
  padding: 0;
}
.addrequest-collection-name-container .addrequest-collection-selector-row .field-label,
.addrequest-collection-selector-row .addrequest-collection-selector-row .field-label {
  text-align: center;
  position: relative;
  display: flex;
  flex: 1;
  margin-bottom: 10px;
  background-color: var(--background-color-primary);
}

.addrequest-top-container-name-row .addrequest__request-name:not(:focus-within) {
  background-color: var(--background-color-secondary);
}
.addrequest-top-container-name-row .addrequest__request-name:not(:focus-within):not(.error) {
  border-color: var(--border-color-default);
}

.addrequest-request-description-editor {
  height: 70px;
}
.addrequest-request-description-editor .text-editor .monaco-editor .margin {
  background-color: var(--background-color-secondary);
}
.addrequest-request-description-editor .text-editor .monaco-editor .monaco-editor-background {
  background-color: var(--background-color-secondary);
}
.addrequest-request-description-editor .text-editor .monaco-editor.focused .monaco-editor-background {
  background-color: var(--background-color-primary);
}
.addrequest-request-description-editor .text-editor .monaco-editor .monaco-placeholder {
  color: var(--content-color-secondary);
}

.save-request-modal .modal-footer .btn-primary .content,
.save-multiple-requests-modal .modal-footer .btn-primary .content {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.save-request-modal .modal-footer .action-button-wrapper .save-button,
.save-multiple-requests-modal .modal-footer .action-button-wrapper .save-button {
  margin-left: var(--spacing-m);
}
.save-request-modal .modal-footer .action-button-wrapper .cancel-button,
.save-multiple-requests-modal .modal-footer .action-button-wrapper .cancel-button {
  margin-top: var(--spacing-zero);
}
.save-request-modal .modal-footer .create-button-wrapper,
.save-multiple-requests-modal .modal-footer .create-button-wrapper {
  display: flex;
  margin-right: auto;
  margin-left: var(--spacing-xs);
}

.save-multiple-requests-modal .field-label {
  margin-bottom: 10px;
}

.add-collection-modal .field-label {
  margin-bottom: 5px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.add-collection-modal .add-collection-modal-name-input {
  margin-bottom: 15px;
}
.add-collection-modal .add-collection-modal-description-editor.ace_focus {
  border: 1px solid var(--border-color-strong);
}
.add-collection-modal .add-collection-modal-description-editor .ace_active-line {
  background: none;
}

.addnew-description-help-text {
  font-size: 10px;
  line-height: 24px;
  color: var(--content-color-secondary);
}

.edit-collection-modal .field-label {
  margin-bottom: 5px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.edit-collection-modal .edit-collection-modal-name-input {
  margin-bottom: 15px;
}
.edit-collection-modal .edit-collection-modal-description-editor.ace_focus {
  border: 1px solid var(--border-color-strong);
}
.edit-collection-modal .edit-collection-modal-description-editor .ace_active-line {
  background: none;
}

.export-collection-modal__message__collection-name {
  font-weight: 600;
}

.export-collection-modal__onboarding-recommendation-viewer {
  padding: 8px 8px 0 8px;
  background-color: var(--background-color-primary);
}

.export-collection-modal__subtext {
  margin-top: 20px;
  font-size: 13px;
  color: var(--content-color-secondary);
}
.export-collection-modal__subtext .export-collection-modal__subtext__learn-more {
  color: var(--content-color-link);
  text-decoration: underline;
  cursor: pointer;
}

.edit-request-modal .field-label {
  margin-bottom: 5px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.edit-request-modal .edit-request-modal-name-input {
  margin-bottom: 15px;
}
.edit-request-modal .edit-request-modal-description-editor {
  height: 300px;
}

.collection-browser {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  background-color: var(--background-color-secondary);
  border-right: var(--border-color-default);
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.2);
}

.collection-browser-details-header {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: var(--content-color-primary);
  padding: 20px 20px 5px 20px;
  display: flex;
  align-items: center;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.collection-browser-details-header .collection-browser-details-header__name, .collection-browser-details-header .inline-input__placeholder {
  display: inline;
  pointer-events: none;
}
.collection-browser-details-header .collection-browser-details-header__name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
}
.collection-browser-details-header .inline-input {
  font-size: 16px;
}

.dropdown-menu {
  min-width: 170px;
}
.dropdown-menu .dropdown-menu-item {
  padding: 0 var(--spacing-l);
}
.dropdown-menu .dropdown-menu-item .dropdown-menu-item-icon {
  margin-right: 10px;
}

.collection-browser-activity-feed-lists {
  display: flex;
  min-height: 100%;
}

.collection-browser-list-item__folder {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}
.collection-browser-list-item__folder:hover, .collection-browser-list-item__folder.is-hovered {
  background-color: var(--highlight-background-color-primary);
}

.collection-browser-list-item__request {
  display: flex;
  flex-direction: row;
  height: 30px;
  position: relative;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.collection-browser-list-item__request:hover, .collection-browser-list-item__request.is-hovered {
  background-color: var(--highlight-background-color-primary);
}

.collection-sidebar-folder-actions-dropdown .dropdown-button .btn {
  border-radius: 0;
  background-color: inherit;
}

.collection-sidebar-request-actions-dropdown.collection-sidebar-request-actions-dropdown--single-menu .dropdown-menu {
  width: 155px;
}
.collection-sidebar-request-actions-dropdown .dropdown-menu {
  margin-top: 0;
  margin-right: 3px;
  width: 175px;
}

.share-collection-modal-container-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.share-collection-modal-container-wrapper .tab {
  min-width: 90px;
}
.share-collection-modal-container-wrapper .add-to-workspace-modal__contents {
  padding: 20px;
}

.share-collection-modal-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.share-collection-modal-container--new-collection {
  padding: 20px;
}
.share-collection-modal-container--new-collection .collection-modal-onboarding-wrapper {
  padding-bottom: 0px;
}

.share-collection-modal-content-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

.share-collection-modal-tabs-header {
  padding-top: 8px;
  border-bottom: 1px solid var(--border-color-default);
}

.share-collection-modal-header {
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 24px;
}

.share-collection-modal-runinpostman-container,
.share-collection-modal-collection-link-container {
  height: 250px;
  margin-top: 15px;
}

.share-collection-modal-header-description {
  font-size: 11px;
}

.share-collection-modal-section-separator {
  padding: 10px;
}

.share-collection-modal-access-dropdown {
  margin-top: 10px;
}

.share-collection-modal-access-checkbox-label {
  padding-left: 10px;
}

.share-collection-modal-access-user-filtered-selector-container {
  padding: 10px;
  border: 1px solid var(--border-color-strong);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.share-collection-modal-access-show-users-description {
  padding-bottom: 10px;
  font-size: 11px;
  color: var(--content-color-tertiary);
}

.share-collection-modal-access-user-wrapper-container {
  flex: 1;
  min-height: 250px;
}

.share-collection-modal-access-user-list-container {
  height: 140px;
  padding: 5px 10px;
  overflow-y: auto;
  border: 1px solid var(--border-color-strong);
  border-top: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.share-collection-modal-fetching-link-button {
  color: var(--content-color-constant);
  background-color: var(--button-main-background-color);
}
.share-collection-modal-fetching-link-button:hover, .share-collection-modal-fetching-link-button.is-hovered {
  color: var(--content-color-constant);
  background-color: var(--button-main-hover-background-color);
}
.share-collection-modal-fetching-link-button:active, .share-collection-modal-fetching-link-button.is-active {
  background-color: var(--button-main-active-background-color);
}

.share-collection-modal-text-wrapper {
  flex: 1;
  margin-right: 10px;
}

.share-collection-modal-collection-link-text-wrapper {
  margin-bottom: 4px;
  color: var(--content-color-primary);
}

.share-collection-modal-collection-link-subtext-wrapper {
  color: var(--content-color-tertiary);
  margin-bottom: 16px;
}
.share-collection-modal-collection-link-subtext-wrapper .share-collection-modal-header-description-link {
  color: var(--content-color-tertiary);
  text-decoration: underline;
}
.share-collection-modal-collection-link-subtext-wrapper .share-collection-modal-header-description-link span:hover {
  color: var(--content-color-tertiary);
}

.share-collection-modal-collection-link-footer-wrapper {
  background-color: var(--background-color-warning);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.share-collection-modal-collection-link-footer-wrapper .share-collection-modal-collection-link-footer-warning-icon {
  margin: 16px 16px 0px 16px;
  position: absolute;
}
.share-collection-modal-collection-link-footer-wrapper .share-collection-modal-collection-link-footer-warning-icon.pm-icon-normal use {
  fill: var(--base-color-warning);
}
.share-collection-modal-collection-link-footer-wrapper .share-collection-modal-collection-link-footer-close-icon {
  margin-left: 16px;
  margin-right: 16px;
  align-self: center;
  cursor: pointer;
}
.share-collection-modal-collection-link-footer-wrapper .share-collection-modal-collection-link-footer-text {
  display: flex;
  flex-direction: column;
  margin: 16px 0px 16px 54px;
  font-size: 12px;
  line-height: 20px;
  color: var(--content-color-primary);
}
.share-collection-modal-collection-link-footer-wrapper .share-collection-modal-collection-link-footer-text .share-collection-modal-collection-link-footer-text-heading {
  font-weight: var(--text-weight-medium);
  margin-bottom: 4px;
}
.share-collection-modal-collection-link-footer-wrapper .share-collection-modal-collection-link-footer-text .share-collection-modal-collection-link-footer-content {
  color: var(--content-color-primary);
}

.share-collection-modal-uploadcollection-button {
  width: 100px;
}

.share-collection-modal-collection-link-wrapper {
  margin-bottom: 10px;
}

.share-collection-modal-collection-link-wrapper__link {
  display: flex;
}
.share-collection-modal-collection-link-wrapper__link .input-field {
  flex: 0;
}

.share-collection-modal-collection-link-wrapper__clipboard {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.share-collection-modal-collection-link {
  height: 30px;
  width: 355px;
  padding: 0 5px;
  margin-right: 5px;
  font-size: 10px;
  background-color: var(--background-color-secondary);
  border: 1px solid var(--border-color-strong);
  border-radius: var(--border-radius-default);
  align-items: center;
}
.share-collection-modal-collection-link:hover, .share-collection-modal-collection-link.is-hovered {
  background-color: var(--background-color-secondary);
}
.share-collection-modal-collection-link:focus, .share-collection-modal-collection-link.is-focused {
  background-color: var(--background-color-secondary);
}

input[disabled].share-collection-modal-collection-link {
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}

.share-collection-modal-collection-link-lastupdated-info {
  font-size: 11px;
  line-height: 18px;
  color: var(--content-color-secondary);
  align-self: center;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}

.share-collection-modal-current-share-status {
  font-weight: 600;
  color: var(--content-color-brand);
}

.edit-collection-description-editor {
  min-height: 100px;
}

.share-collection-modal-button {
  width: 100%;
}
.share-collection-modal-button.is-disabled {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--highlight-background-color-brand);
}

.share-collection-modal-upgrade-trial-link {
  padding-top: 20px;
}

.share-collection-modal-shareunshare-button-wrapper {
  align-self: baseline;
}

.share-collection-modal-rip-container {
  margin-top: var(--spacing-s);
}
.share-collection-modal-rip-container .accordion .accordion-item {
  padding-bottom: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.share-collection-modal-rip-container .accordion .accordion-item .title {
  height: var(--size-s);
}
.share-collection-modal-rip-container .accordion .accordion-item .title i, .share-collection-modal-rip-container .accordion .accordion-item .title svg {
  height: var(--size-s);
  width: var(--size-s);
}
.share-collection-modal-rip-container .accordion .accordion-item .panel {
  overflow: visible;
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__desc {
  display: inline-block;
  margin-top: var(--spacing-s);
  width: 70%;
}
.share-collection-modal-rip-container .share-collection-modal-rip-content .infobar {
  margin: var(--spacing-l) 0 0 0;
  background-color: var(--background-color-info);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content .infobar i {
  margin-right: var(--spacing-xs);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__env-selector--icon-wrapper {
  margin-right: var(--spacing-xs);
  height: var(--size-xs);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__env-selector--label {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__env-selector--label label {
  margin-right: var(--spacing-xs);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__env-selector--label span {
  display: flex;
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__env-selector .share-collection-modal-dropdown.dropdown {
  width: 320px;
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__code-container {
  padding: var(--spacing-s);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__code-container.team-collection {
  margin: var(--spacing-zero);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__code-container--copy i {
  margin-right: var(--spacing-xs);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__code-container--heading {
  margin-bottom: var(--spacing-s);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__code-container--code {
  margin-top: var(--spacing-m);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__code-container--code code {
  white-space: pre-wrap;
  word-break: break-all;
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__static-actions {
  margin-top: var(--spacing-s);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__static-actions--date span {
  font-weight: var(--text-weight-medium);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__static-actions .btn:first-child {
  margin-right: var(--spacing-s);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__success-btn {
  color: var(--content-color-success);
  cursor: default;
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__success-btn i {
  margin-right: var(--spacing-xs);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__success-btn, .share-collection-modal-rip-container .share-collection-modal-rip-content__success-btn:hover {
  background: none;
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__success-btn i svg, .share-collection-modal-rip-container .share-collection-modal-rip-content__success-btn:hover i svg {
  color: var(--content-color-success);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__create {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-s);
}
.share-collection-modal-rip-container .share-collection-modal-rip-content__create span {
  max-width: 70%;
}

.share-collection-modal-dropdown.dropdown {
  width: 320px;
}
.share-collection-modal-dropdown .dropdown-button .btn {
  display: flex;
  width: 100%;
}
.share-collection-modal-dropdown .dropdown-button .btn .share-collection-modal-rip-environment-selector-selected-label {
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.share-collection-modal-dropdown .share-collection-modal-rip-environment-selector-dropdown-menu {
  width: 100%;
  max-width: 320px;
  max-height: 190px;
  overflow-y: auto;
}
.share-collection-modal-dropdown .dropdown-menu-item {
  display: block;
  padding-top: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.share-collection-modal-collection-link-delete-button {
  margin-left: 5px;
  background-color: var(--background-color-tertiary);
}
.share-collection-modal-collection-link-delete-button:focus, .share-collection-modal-collection-link-delete-button.is-focused {
  background-color: var(--highlight-background-color-tertiary);
}
.share-collection-modal-collection-link-delete-button:hover, .share-collection-modal-collection-link-delete-button.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.share-collection-modal-collection-link-delete-button:active, .share-collection-modal-collection-link-delete-button.is-active {
  background-color: var(--highlight-background-color-tertiary);
}

.share-collection-modal-collection-link-delete:hover use, .share-collection-modal-collection-link-delete.is-hovered use {
  fill: var(--base-color-brand);
}
.share-collection-modal-collection-link-delete:hover svg:not([class*=PartnerIcon]) > path[fill], .share-collection-modal-collection-link-delete.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.share-collection-modal-collection-link-delete:hover svg, .share-collection-modal-collection-link-delete.is-hovered svg {
  color: var(--base-color-brand);
}

.share-collection-modal-copied-button,
.share-collection-modal-copy-button {
  width: 130px;
}

.share-collection-modal-run-in-postman-copied-button,
.share-collection-modal-copied-button {
  background-color: var(--base-color-success);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.share-collection-modal-run-in-postman-copied-button:hover, .share-collection-modal-run-in-postman-copied-button.is-hovered,
.share-collection-modal-copied-button:hover,
.share-collection-modal-copied-button.is-hovered {
  background-color: var(--base-color-success);
}

.share-collection-modal-permissions {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 415px;
}
.share-collection-modal-permissions .share-collection-modal-permissions--container {
  padding: 10px 0 5px;
}

.share-collection-modal-permissions--team {
  display: flex;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  align-items: center;
}
.share-collection-modal-permissions--team .share-collection-modal-permissions--team-label {
  flex: 1;
}

.share-collection-modal-permissions-unshare-message {
  min-height: 35px;
  padding: 10px 0 5px;
}

.share-collection-modal-permissions-controls {
  margin: 5px 0;
  justify-content: flex-end;
}
.share-collection-modal-permissions-controls .share-collection-modal-permissions-buttons {
  display: flex;
  margin-top: 10px;
}
.share-collection-modal-permissions-controls .share-collection-modal-permissions-buttons .btn {
  margin-left: 10px;
}
.share-collection-modal-permissions-controls .share-collection-modal-permissions-buttons .share-collection-modal-permissions-buttons--subscribe {
  min-width: 120px;
}
.share-collection-modal-permissions-controls .share-collection-modal-permissions-buttons .share-collection-modal-permissions-buttons--cancel {
  margin-left: auto;
  align-self: center;
}

.share-collection-modal-login-empty-message-container {
  display: flex;
  height: 300px;
  align-items: center;
}
.share-collection-modal-login-empty-message-container .share-collection-modal-login-empty-message {
  flex: 1;
  text-align: center;
  align-self: center;
}
.share-collection-modal-login-empty-message-container .share-collection-modal-login-link {
  color: var(--content-color-brand);
  cursor: pointer;
}

.share-collection-permissions-empty-container {
  display: flex;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  padding-top: 20px;
}
.share-collection-permissions-empty-container .non-pro-text .btn-text {
  padding: 0 3px;
}
.share-collection-permissions-empty-container .signout-out-signin-btn,
.share-collection-permissions-empty-container .learnmore-link {
  font-weight: 300;
  font-size: 12px;
  margin-top: 16px;
}

.share-collection-modal-container__tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.share-collection-modal--invite-user-empty-container {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.share-collection-modal--invite-user-empty-container .share-collection-modal--invite-user-empty-text {
  text-align: center;
  color: var(--content-color-tertiary);
}

.share-collection-modal--create-team {
  margin-top: 32px;
}

.share-collection-modal-collection-link-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.share-collection-modal-collection-link-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.share-collection-modal-collection-link-section {
  flex: 1;
  margin-bottom: var(--spacing-l);
}

.share-collection-modal-link {
  color: var(--content-color-secondary);
}
.share-collection-modal-link__disabled {
  color: var(--content-color-primary);
}

.mycollections-collections-list-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  overflow-y: auto;
}
.mycollections-collections-list-wrapper .mycollections-collections-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mycollections-collections-list-wrapper .mycollections-collections-list-item .mycollections-collections-list-item-details {
  flex: 1;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}

.mycollections-collections-list-item {
  padding: 10px 0;
  color: var(--content-color-secondary);
}
.mycollections-collections-list-item .mycollections-collections-list-item-head {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}
.mycollections-collections-list-item .mycollections-collections-list-item-link,
.mycollections-collections-list-item .mycollections-collections-list-item-last-updated {
  font-size: 10px;
  line-height: 20px;
}
.mycollections-collections-list-item .mycollections-collections-list-item-last-updated {
  opacity: 0.6;
}

.mycollections-collections-list-item-actions .btn-group {
  margin-left: 10px;
  border-radius: var(--border-radius-default);
}
.mycollections-collections-list-item-actions .btn-group .btn-icon {
  background-color: transparent;
}

.mycollections-footer {
  border-top: 1px solid var(--border-color-default);
}

.mycollections-footer-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.mycollections-footer-description {
  flex: 1;
  font-size: 12px;
  color: var(--content-color-primary);
}

.mycollections-buttons-wrapper {
  display: flex;
  align-items: center;
}
.mycollections-buttons-wrapper .mycollections-button {
  margin-left: 10px;
}

.divider {
  flex: 1;
  border-bottom: 1px solid var(--border-color-default);
}
.divider.divider--space {
  margin: 3px 0px;
}

.delete-confirmation-modal-subscriber-message {
  padding-bottom: 10px;
}

.delete-confirmation-modal-delete-button {
  background-color: var(--base-color-error);
  margin-left: 10px;
}
.delete-confirmation-modal-delete-button:hover, .delete-confirmation-modal-delete-button.is-hovered {
  background-color: var(--highlight-background-color-error);
}
.delete-confirmation-modal-delete-button:active, .delete-confirmation-modal-delete-button.is-active {
  background-color: var(--highlight-background-color-error);
}
.delete-confirmation-modal-delete-button:focus, .delete-confirmation-modal-delete-button.is-focused {
  background-color: var(--highlight-background-color-error);
}
.delete-confirmation-modal-delete-button.is-disabled:hover, .delete-confirmation-modal-delete-button.is-disabled.is-hovered {
  background-color: var(--highlight-background-color-error);
}
.delete-confirmation-modal-delete-button.is-disabled:active, .delete-confirmation-modal-delete-button.is-disabled.is-active {
  background-color: var(--highlight-background-color-error);
}
.delete-confirmation-modal-delete-button.is-disabled:focus, .delete-confirmation-modal-delete-button.is-disabled.is-focused {
  background-color: var(--highlight-background-color-error);
}

.delete-confirmation-modal .loading-indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leave-private-workspace-modal--message {
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.leave-private-workspace-modal--info {
  display: flex;
  padding: var(--spacing-l);
  margin-top: var(--spacing-l);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-info);
}
.leave-private-workspace-modal--info .pm-icon {
  margin-right: var(--spacing-s);
}
.leave-private-workspace-modal--info .pm-icon use {
  fill: var(--content-color-info);
}
.leave-private-workspace-modal--info .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-info);
}
.leave-private-workspace-modal--info .pm-icon svg {
  color: var(--content-color-info);
}

.cannot-remove-from-workspace-modal-message {
  display: flex;
  align-items: center;
  padding: 4px;
}
.cannot-remove-from-workspace-modal-message .help-text {
  padding-left: 16px;
}
.cannot-remove-from-workspace-modal-message .help-text .btn-text {
  padding: 0;
  font-weight: 600;
  line-height: 16px;
}
.cannot-remove-from-workspace-modal-message .help-text .btn-text.delete-link {
  color: var(--base-color-error);
}
.cannot-remove-from-workspace-modal-message .help-text .btn-text.share-link {
  color: var(--base-color-brand);
}

.remove-from-workspace-modal-message .helptext {
  padding-top: 20px;
}

.delete-collection-text {
  position: relative;
  min-height: 44px;
}
.delete-collection-text__loading-indicator.loading-indicator-wrapper {
  display: inline-block;
  margin-left: 8px;
  margin-top: 16px;
}
.delete-collection-text-message {
  position: absolute;
  margin-left: 16px;
  top: 18px;
}
.delete-collection-text-message__loading {
  margin-left: 16px;
  color: var(--content-color-tertiary);
}
.delete-collection-text-message__permanent-delete {
  color: var(--content-color-warning);
}
.delete-collection-text-message__info {
  color: var(--content-color-tertiary);
}
.delete-collection-text .delete-collection__status-icon {
  margin-top: 1px;
}

.delete-confirmation-modal-controls {
  display: flex;
  flex: 0 0 80px;
  box-sizing: border-box;
  padding-top: 20px;
  flex-direction: row-reverse;
  align-items: center;
}
.delete-confirmation-modal-controls .btn.is-focused {
  box-shadow: var(--shadow-focus);
}

.delete-confirmation-modal__warning-text {
  padding-top: var(--spacing-l);
}

.delete-monitor-modal {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l);
}

.collection-modal-onboarding-wrapper .collection-modal-onboarding-helptext {
  margin-bottom: 10px;
}
.collection-modal-onboarding-wrapper .collection-name-label {
  margin-bottom: 5px;
}

.learn-more-link {
  padding: 0px;
  font-weight: var(--text-weight-regular);
}

.learn-more {
  padding: 15px 0px;
  font-weight: var(--text-weight-regular);
}

.addnew-description-help-text {
  font-size: 10px;
  line-height: 24px;
  color: var(--content-color-secondary);
  margin-top: 8px;
}

.collection-modal-variables-alert {
  margin-top: 10px;
}

.collection-modal-variables-container {
  flex: 1;
  overflow-y: auto;
}
.collection-modal-variables-container .key-value-form-row:hover:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-error, .collection-modal-variables-container .key-value-form-row:hover:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-warning {
  margin-right: 45px;
}

.collection-modal-tabs-count {
  padding: 0 5px;
  color: var(--background-color-secondary);
}

.collection-modal-tabs-badge {
  display: inline-flex;
  width: 6px;
  height: 6px;
  margin-left: 5px;
  background-color: var(--base-color-success);
  border-radius: var(--border-radius-max);
}

.collection-modal-tabs-error-badge {
  display: inline-flex;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
  background-color: var(--base-color-error);
}

.collection-modal-tab-contents {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}

.collection-modal-tab-content {
  height: 100%;
  flex: 1;
  display: flex;
  box-sizing: border-box;
}
.collection-modal-tab-content-wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-l);
}
.collection-modal-tab-content.test-tab-deprecation-screen {
  flex: unset;
  height: unset;
  margin-top: var(--spacing-s);
}
.collection-modal-tab-content .collection-modal-tab-content-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-l);
  box-sizing: border-box;
}
.collection-modal-tab-content .collection-modal-tab-content-container--no-padding {
  padding: 0;
}
.collection-modal-tab-content .collection-modal-tab-content-container .collection-modal-editor {
  flex: 1 auto;
  min-height: 0;
}
.collection-modal-tab-content .collection-modal-tab-content-description {
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.collection-modal-tab-content .collection-editor-content__variables-editor {
  margin-top: var(--spacing-l);
}
.collection-modal-tab-content .collection-editor-content__variables-editor .variable-editor-fork-banner {
  margin-top: var(--spacing-l);
}
.collection-modal-tab-content .collection-editor-content__variables-editor .variables-editor__meta.is-bleed {
  padding-right: var(--spacing-m);
}

.collection-meta-icons {
  display: flex;
  margin-left: var(--spacing-xs);
  align-items: center;
}
.collection-meta-icons.has-hoverable-collection-label {
  margin-left: var(--spacing-zero);
  flex-shrink: 0.2;
}
.collection-sidebar-list-item__head__name-icon__wrapper .collection-meta-icons.has-hoverable-collection-label {
  max-width: 50%;
}
.collection-meta-icons.has-hoverable-collection-label .collection-sidebar-fork-label {
  min-width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.collection-meta-icons .collection-meta-icon {
  margin-right: var(--spacing-xs);
}
.collection-sidebar-list-item__head__meta .collection-meta-icons .collection-meta-icon use {
  fill: var(--content-color-tertiary);
}
.collection-sidebar-list-item__head__meta .collection-meta-icons .collection-meta-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.collection-sidebar-list-item__head__meta .collection-meta-icons .collection-meta-icon svg {
  color: var(--content-color-tertiary);
}
.collection-meta-icons .collection-meta-icon.collection-sidebar-fork-label {
  display: flex;
  height: var(--size-xs);
  align-items: center;
}
.entity-tab-header .collection-meta-icons .collection-meta-icon.fork-label {
  display: flex;
  min-width: 0;
}

.collection-fork-label {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  overflow: hidden;
  color: var(--content-color-secondary);
}
.collection-fork-label span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}
.collection-fork-label .pm-icon {
  margin-right: 4px;
}

.collection-fork-label-tooltip {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.collection-fork-label-tooltip .pm-icon {
  margin-right: 4px;
  margin-top: 4px;
}
.collection-fork-label-tooltip .collection-fork-label-tooltip__header {
  display: flex;
}
.collection-fork-label-tooltip .collection-fork-label-tooltip__description {
  margin-top: 8px;
}
.collection-fork-label-tooltip .collection-fork-label-tooltip__bold-text {
  font-weight: var(--text-weight-medium);
}

/**
 * Styling for `CollectionConfigurationList` and `FolderConfigurationList` component
 */
.collection-configuration-list {
  height: 100%;
  overflow-y: auto;
  padding: 0px 10px;
  width: 100%;
}

.collection-configuration-unavailable {
  margin-left: var(--spacing-l);
}

.fork-recommendation-modal__content {
  padding: 16px 24px;
}
.fork-recommendation-modal__content-description {
  margin-bottom: 16px;
  margin-top: unset;
}
.fork-recommendation-modal__option, .fork-recommendation-modal__option-disabled {
  border: 1px solid var(--border-color-strong);
  border-radius: var(--border-radius-default);
  margin-top: 8px;
  padding: 16px;
}
.fork-recommendation-modal__option-disabled {
  opacity: 0.5;
}
.fork-recommendation-modal__option:last-child, .fork-recommendation-modal__option-disabled:last-child {
  margin-bottom: 8px;
}
.fork-recommendation-modal__option-radio-button {
  margin-right: 16px;
}
.fork-recommendation-modal__option-description {
  margin: 4px 28px 0px;
}
.fork-recommendation-modal__option-title {
  cursor: pointer;
  position: relative;
  bottom: 1px;
}
.fork-recommendation-modal__option-title-tag {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
  border-radius: var(--border-radius-default);
  margin-left: 12px;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  bottom: 1px;
}

.collection-explorer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.collection-explorer .collection-explorer__path {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-bottom: var(--spacing-s);
}
.collection-explorer .collection-explorer__path__empty {
  margin-left: var(--spacing-s);
}
.collection-explorer .collection-explorer__path .breadcrumb__container {
  flex: 1;
  margin-left: var(--spacing-s);
}
.collection-explorer .explorer-empty-state {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: var(--border-width-default) solid var(--border-color-default);
}
.collection-explorer .explorer-empty-state__illustration {
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: var(--spacing-l);
  background-color: var(--background-color-tertiary);
}
.collection-explorer .explorer-empty-state__illustration__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.collection-explorer .explorer-empty-state__illustration__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}
.collection-explorer .explorer-empty-state__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}
.collection-explorer .explorer-empty-state__content__body {
  margin-top: var(--spacing-s);
  text-align: center;
}
.collection-explorer .explorer-empty-state__action {
  margin-top: var(--spacing-l);
}

.history-sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.history-sidebar .history-sidebar-menu {
  display: flex;
  box-sizing: border-box;
  padding: var(--spacing-s);
}
.history-sidebar .history-sidebar-menu .requester-left-sidebar__actions-container {
  flex: 1;
  padding: var(--spacing-zero);
  height: var(--size-s);
}
.history-sidebar .multiple-request-selected {
  background-color: var(--background-color-brand);
}
.history-sidebar .history-sidebar-list {
  flex: 1;
}

.history-sidebar__header-actions-dropdown--menu-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.history-sidebar__header-actions-dropdown--menu-item .btn {
  padding: var(--spacing-zero);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.history-sidebar-list__notification {
  display: flex;
  justify-content: center;
}

.history-sidebar-list-permission-error .sidebar-empty-state__title {
  padding: 0;
}

.history-sidebar-list-error {
  text-align: center;
  margin-top: var(--spacing-xl);
}
.history-sidebar-list-error h4 {
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-s);
}
.history-sidebar-list-error p {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: 0 auto var(--spacing-xl) auto;
  max-width: 256px;
  padding: 0 var(--spacing-l);
}

.history-sidebar-list__more-on-top,
.history-sidebar-list__refresh {
  position: absolute;
  height: 20px;
  border-radius: 10px;
  padding: var(--spacing-xs) var(--spacing-s);
  margin-right: var(--spacing-m);
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
  z-index: 10;
  font-weight: var(--text-weight-medium);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.history-sidebar-list__more-on-top .pm-icon,
.history-sidebar-list__refresh .pm-icon {
  margin-right: var(--spacing-xs);
}

.history-sidebar-menu {
  display: flex;
  flex-direction: row;
}
.history-sidebar-menu__left {
  flex: 1;
  display: flex;
  align-items: center;
}
.history-sidebar-menu__right {
  flex: 0 0 auto;
  align-items: center;
  display: flex;
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper {
  display: flex;
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .history-sidebar-menu__actions-spinner-wrapper {
  display: inline-flex;
  height: var(--size-s);
  width: var(--size-s);
  background-color: transparent;
  justify-content: center;
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .btn {
  height: var(--size-s);
  width: var(--size-s);
  background-color: transparent;
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .btn use {
  fill: var(--content-color-secondary);
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .btn svg {
  color: var(--content-color-secondary);
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .btn:hover use {
  fill: var(--content-color-primary);
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .btn:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.history-sidebar-menu__right .history-sidebar-menu__actions-delete-history-wrapper .btn:hover svg {
  color: var(--content-color-primary);
}

.history-sidebar-info-header {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
}
.history-sidebar-info-header__icon {
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-m);
}

.history-sidebar-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history-sidebar-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.history-sidebar-list:hover .history-sidebar__indent {
  flex: 0 0 var(--spacing-s);
  box-sizing: border-box;
  height: inherit;
  border-right: thin solid var(--border-color-default);
}

.history-sidebar-list-item-group {
  flex: 0 0 auto;
}
.history-sidebar-list-item-group.is-hidden {
  display: none;
}

.history-sidebar-list-item-group__error {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  padding: var(--spacing-xs) 0;
  color: var(--content-color-tertiary);
}
.history-sidebar-list-item-group__error span {
  cursor: pointer;
  display: inline-block;
  margin-left: var(--spacing-xs);
}

.history-sidebar-list-item-group__meta {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: var(--spacing-s);
  height: 28px;
  cursor: pointer;
}
.history-sidebar-list-item-group__meta:hover, .history-sidebar-list-item-group__meta.is-hovered {
  background: var(--highlight-background-color-secondary);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions {
  display: flex;
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn {
  background-color: transparent;
  height: var(--size-s);
  width: var(--size-s);
  border-radius: var(--border-radius-s);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn use, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn use {
  fill: var(--content-color-secondary);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn svg:not([class*=PartnerIcon]) > path[fill], .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn svg, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn svg {
  color: var(--content-color-secondary);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn:hover, .history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn.is-hovered, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn:hover, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn:hover use, .history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn.is-hovered use, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn:hover use, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn.is-hovered use {
  fill: var(--content-color-primary);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn:hover svg:not([class*=PartnerIcon]) > path[fill], .history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn.is-hovered svg:not([class*=PartnerIcon]) > path[fill], .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn:hover svg:not([class*=PartnerIcon]) > path[fill], .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn:hover svg, .history-sidebar-list-item-group__meta:hover .history-sidebar-list-item-group__actions .btn.is-hovered svg, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn:hover svg, .history-sidebar-list-item-group__meta.is-hovered .history-sidebar-list-item-group__actions .btn.is-hovered svg {
  color: var(--content-color-primary);
}
.history-sidebar-list-item-group__meta .caret {
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-xs);
}
.history-sidebar-list-item-group__meta .caret use {
  fill: var(--content-color-secondary);
}
.history-sidebar-list-item-group__meta .caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.history-sidebar-list-item-group__meta .caret svg {
  color: var(--content-color-secondary);
}
.history-sidebar-list-item-group__meta .caret.is-closed {
  transform: rotate(-90deg);
}
.history-sidebar-list-item-group__meta .history-sidebar-list-item-group__name {
  display: flex;
  line-height: var(--line-height-m);
  align-items: center;
}
.history-sidebar-list-item-group__meta .history-sidebar-list-item-group__actions {
  display: none;
  justify-content: space-around;
  align-items: center;
  flex: 0 0 50px;
  margin-right: var(--spacing-s);
}

.history-sidebar-list-item-group__items {
  margin-bottom: 10px;
}

.history-list__meta:first-of-type {
  border-top: unset;
}

.history-list__error {
  border-top: thin solid var(--border-color-default);
  border-bottom: thin solid var(--border-color-default);
}

.history-list__loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-sidebar-list__content {
  flex: 1;
  min-height: 0;
}

.sidebar-forbidden-error {
  height: 240px;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
}

.sidebar-forbidden-banner-container {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.history-sidebar-list-item {
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.history-sidebar-list-item .history-sidebar-list-item-updatedBy {
  align-items: center;
  display: flex;
  height: var(--size-xs);
  width: var(--size-xs);
}
.history-sidebar-list-item .sidebar-list-item__icon-container {
  min-width: 30px;
}
.history-sidebar-list-item .history-sidebar-list-item__label-wrapper {
  display: flex;
  flex: 0 0 50px;
  justify-content: center;
  align-items: center;
}
.history-sidebar-list-item .history-sidebar-list-item__meta {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.history-sidebar-list-item .history-sidebar-list-item__actions {
  position: absolute;
  height: 100%;
  justify-content: space-around;
  right: 0px;
  background-color: inherit;
}
.history-sidebar-list-item:hover .history-sidebar-list-item__actions, .history-sidebar-list-item.is-hovered .history-sidebar-list-item__actions {
  display: flex;
  justify-content: space-around;
}
.history-sidebar-list-item:hover .history-sidebar-list-item__actions .btn, .history-sidebar-list-item.is-hovered .history-sidebar-list-item__actions .btn {
  background-color: transparent;
}
.history-sidebar-list-item:hover .history-sidebar-list-item__actions .btn:hover, .history-sidebar-list-item:hover .history-sidebar-list-item__actions .btn.is-hovered, .history-sidebar-list-item.is-hovered .history-sidebar-list-item__actions .btn:hover, .history-sidebar-list-item.is-hovered .history-sidebar-list-item__actions .btn.is-hovered {
  background-color: transparent;
}
.history-sidebar-list-item.is-hidden {
  display: none;
}

.history-sidebar-list-item {
  max-height: 60px;
  -webkit-line-clamp: 3;
}

.history-sidebar-list-item__actions {
  display: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.history-sidebar-list-item__button__options use {
  fill: var(--content-color-secondary);
}
.history-sidebar-list-item__button__options svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.history-sidebar-list-item__button__options svg {
  color: var(--content-color-secondary);
}
.history-sidebar-list-item__button__options:hover use {
  fill: var(--content-color-primary);
}
.history-sidebar-list-item__button__options:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.history-sidebar-list-item__button__options:hover svg {
  color: var(--content-color-primary);
}

.history-sidebar-list-empty-item {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  padding: 20px 15px;
  color: var(--content-color-secondary);
  text-align: center;
}
.history-sidebar-list-empty-item .entity-empty {
  border: none;
}
.history-sidebar-list-empty-item .entity-empty .entity-empty__title {
  font-size: 14px;
  margin-bottom: 16px;
}
.history-sidebar-list-empty-item .entity-empty .entity-empty__subtitle {
  margin-bottom: 24px;
}
.history-sidebar-list-empty-item .entity-empty .entity-empty__title,
.history-sidebar-list-empty-item .entity-empty .entity-empty__subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.history-sidebar-list-empty-item .entity-empty .entity-empty__learn .entity-empty__learn__mouse-click-icon {
  padding-right: 8px;
}

.history-sidebar-list-item__request.sidebar-list-item,
.history-sidebar-list-item__collection.sidebar-list-item,
.history-sidebar-list-item__proxy-history.sidebar-list-item {
  height: 100%;
  min-height: var(--size-s);
}
.history-sidebar-list-item__request .sidebar-list-item__actions-container,
.history-sidebar-list-item__collection .sidebar-list-item__actions-container,
.history-sidebar-list-item__proxy-history .sidebar-list-item__actions-container {
  background-color: inherit;
  height: 100%;
}
.history-sidebar-list-item__request .sidebar-list-item__content-wrapper,
.history-sidebar-list-item__collection .sidebar-list-item__content-wrapper,
.history-sidebar-list-item__proxy-history .sidebar-list-item__content-wrapper {
  height: 100%;
  min-height: var(--size-xs);
}
.history-sidebar-list-item__request .sidebar-list-item__name-wrapper,
.history-sidebar-list-item__collection .sidebar-list-item__name-wrapper,
.history-sidebar-list-item__proxy-history .sidebar-list-item__name-wrapper {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.history-sidebar-list-item__request .sidebar-list-item__color-proxy,
.history-sidebar-list-item__collection .sidebar-list-item__color-proxy,
.history-sidebar-list-item__proxy-history .sidebar-list-item__color-proxy {
  color: var(--content-color-success);
}

.history-sidebar-list-item-avatar-tooltip {
  display: flex;
  flex-direction: column;
}

.requester-tabs:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0;
  pointer-events: none;
}
.requester-tabs:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.requester-tabs {
  height: 41px;
  display: flex;
  flex-direction: row;
  min-width: 0;
  overflow-x: scroll;
  scrollbar-width: none;
}
.requester-tabs.is-options-open {
  padding-bottom: 200px;
}
.requester-tabs .requester-tab-title-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
}
.requester-tabs .requester-tab-title-wrapper .requester-tab-title__text-wrapper {
  display: flex;
  overflow: hidden;
}
.requester-tabs .requester-tab-title-wrapper .requester-tab__icon {
  opacity: var(--requester-tab-icon-opacity);
  padding: var(--spacing-zero);
}
.requester-tabs .requester-tab-title-wrapper .requester-tab__icon .collection-sidebar-list-item__request__label-wrapper {
  margin-top: 1px;
}
.requester-tabs .requester-tab {
  box-sizing: border-box;
  width: 172px;
  flex: 1 1;
  background-color: var(--background-color-primary);
  border-left: var(--border-width-default) var(--border-style-solid);
  border-right: 0;
  border-bottom: 0;
}
.requester-tabs .requester-tab:last-child {
  border-right: 0;
}
.requester-tabs .requester-tab .requester-tab-title-wrapper {
  margin-bottom: 1px;
}
.requester-tabs .requester-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--border-color-default);
}
.requester-tabs .requester-tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
  height: 1px;
  background-color: var(--base-color-brand);
  opacity: 0;
}
.requester-tabs .requester-tab.is-active {
  height: 41px;
  border-left: var(--border-width-default) var(--border-style-solid);
  border-bottom: 0;
}
.requester-tabs .requester-tab.is-active::after {
  opacity: 1;
}
.requester-tabs .requester-tab-create,
.requester-tabs .requester-tab-options {
  flex: 0 0 30px;
}
.requester-tabs::-webkit-scrollbar {
  display: none;
}
.requester-tabs.is-scrolling-right:before {
  display: none;
}
.requester-tabs.is-scrolling-right:after {
  background-image: linear-gradient(to right, transparent, var(--background-color-primary));
}
.requester-tabs.is-scrolling-left:before {
  background-image: linear-gradient(to left, transparent, var(--background-color-primary));
}
.requester-tabs.is-scrolling-left:after {
  display: none;
}
.requester-tabs.is-scrolling-both:before {
  background-image: linear-gradient(to left, transparent, var(--background-color-primary));
}
.requester-tabs.is-scrolling-both:after {
  background-image: linear-gradient(to right, transparent, var(--background-color-primary));
}

.requester-tab,
.requester-tab-create {
  box-sizing: border-box;
  overflow: hidden;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero) var(--spacing-s);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.requester-tab-create > .requester-tab-create-icon,
.requester-tab-options > .requester-tab-options-icon {
  padding: var(--spacing-xs);
}

.requester-tab-options {
  box-sizing: border-box;
  border: 0;
  height: 39px;
  background-color: var(--background-color-primary);
  margin-right: 10px;
  padding: 0 6px;
}

.tab-titles-list {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-titles-list::-webkit-scrollbar {
  display: none;
}

.requester-tab {
  color: var(--content-color-secondary);
  height: 41px;
  min-width: 76px;
  position: relative;
  border-top: 0;
  border-image-slice: 0 1 0 1;
  border-image-source: linear-gradient(to bottom, var(--background-color-primary) 30%, var(--border-color-default) 30%, var(--border-color-default) 70%, var(--background-color-primary) 70%, var(--background-color-primary) calc(100% - 1px), var(--border-color-default) calc(100% - 1px));
}
.requester-tab.is-active {
  color: var(--content-color-primary);
}
.requester-tab.is-active .requester-tab__icon {
  opacity: 1;
}
.requester-tab.is-hidden {
  display: none;
}
.requester-tab.is-preview {
  font-style: italic;
}
.requester-tab:first-child {
  border-left: 0;
}
.requester-tab:last-child {
  border-right: var(--border-width-default) var(--border-style-solid);
}
.requester-tab:first-child.is-active {
  border-left: 0;
}
.requester-tab.is-dragged {
  opacity: 0.1;
}
.requester-tab.is-drop-hovered.is-hover-left {
  box-shadow: inset 3px 0px 0px 0px var(--background-color-tertiary);
}
.requester-tab.is-drop-hovered.is-hover-right {
  box-shadow: inset -3px 0px 0px 0px var(--background-color-tertiary);
}
.requester-tab .requester-tab__fade {
  height: 32px;
  width: 16px;
  position: absolute;
  background: var(--background-color-primary);
  right: 0;
}
.requester-tab:hover .requester-tab__fade {
  width: 32px;
}
.requester-tab:hover .requester-tab__icon {
  opacity: 1;
}
.requester-tab:hover .requester-tab__name {
  color: var(--content-color-primary);
}
.requester-tab:hover .requester-tab__name {
  color: var(--content-color-primary);
}
.requester-tab .requester-tab__close {
  position: absolute;
  right: 2px;
  z-index: 1;
}
.requester-tab:not(:hover) .requester-tab__close.is-dirty {
  height: 6px;
  width: 6px;
  background-color: var(--base-color-brand);
  background-image: none;
  border-radius: 16px;
  border: 6px solid var(--background-color-primary);
}
.requester-tab:not(:hover) .requester-tab__close.is-dirty .requester-tab__cross {
  display: none;
}
.requester-tab .requester-tab__cross {
  display: none;
  width: 20px;
  height: 20px;
}
.requester-tab:hover .requester-tab__cross, .requester-tab.is-hovered .requester-tab__cross {
  display: inline-flex;
}

.requester-tab-create,
.requester-tab-options {
  justify-content: center;
  padding: 0;
  margin-right: 0px;
}

.requester-tab-create {
  margin: var(--spacing-xs) 2px var(--spacing-xs) var(--spacing-xs);
  border-left: none;
}

.requester-tab-options__label {
  flex: 1;
  white-space: nowrap;
}

.requester-tab-options__shortcut {
  padding-left: 10px;
  color: var(--content-color-tertiary);
}

.requester-tab__name {
  font-family: var(--text-family-default);
  line-height: var(--line-height-s);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
  padding-left: var(--spacing-xs);
  flex: 1;
  align-self: center;
  white-space: nowrap;
}

.requester-tab__conflict-message {
  display: inherit;
  margin-right: var(--spacing-xs);
  line-height: var(--line-height-m);
}

.tab-actions-dropdown .dropdown-menu {
  min-width: 220px;
}
.tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-all:hover, .tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-all.is-hovered,
.tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-active:hover,
.tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-active.is-hovered {
  background-color: var(--base-color-error);
  color: var(--content-color-constant);
}
.tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-all:hover .requester-tab-options__shortcut, .tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-all.is-hovered .requester-tab-options__shortcut,
.tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-active:hover .requester-tab-options__shortcut,
.tab-actions-dropdown .dropdown-menu .dropdown-menu-item--force-close-active.is-hovered .requester-tab-options__shortcut {
  color: var(--content-color-constant);
}
.tab-actions-dropdown .dropdown-menu .dropdown-sub-menu-item {
  width: 220px;
}
.tab-actions-dropdown .requester-tab-options-btn {
  width: var(--size-m);
  height: var(--size-m);
  margin-right: var(--spacing-s);
}

.requester-tab__icon {
  padding: var(--spacing-zero) var(--spacing-xs);
  display: flex;
}

.requester-tabs.is-navigation-button .requester-tab:last-child {
  border-right: 0;
}

.requester-tab-contents,
.requester-tab-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.requester-tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.requester-tab-content.is-hidden {
  display: none;
}

.requester-tab-content-panes {
  flex: 1;
}

.layout-2-column .requester-tab-content-panes {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.layout-2-column .requester-tab-content-pane {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.requester-tab-contents-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.right-context-bar {
  display: flex;
  flex-direction: row;
}

.right-context-bar-options {
  width: 48px;
  height: 100%;
}
.right-context-bar-options ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.right-context-bar-options ul li {
  box-sizing: border-box;
  position: relative;
  padding: var(--spacing-m);
  height: 48px;
  width: 48px;
  cursor: pointer;
  text-align: center;
}
.right-context-bar-options ul li use {
  fill: var(--content-color-secondary);
}
.right-context-bar-options ul li svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.right-context-bar-options ul li svg {
  color: var(--content-color-secondary);
}
.right-context-bar-options ul li .icon-container {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.right-context-bar-options ul li:hover use {
  fill: var(--content-color-primary);
}
.right-context-bar-options ul li:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.right-context-bar-options ul li:hover svg {
  color: var(--content-color-primary);
}
.right-context-bar-options ul li.active use {
  fill: var(--content-color-primary);
}
.right-context-bar-options ul li.active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.right-context-bar-options ul li.active svg {
  color: var(--content-color-primary);
}
.right-context-bar-options ul li.active::before {
  content: "";
  display: block;
  position: absolute;
  left: 8px;
  top: 8px;
  bottom: 8px;
  right: 8px;
  background-color: var(--highlight-background-color-primary);
  border-radius: var(--border-radius-default);
}
.right-context-bar-options.contextbarOverlay:hover {
  cursor: pointer;
}

.right-context-bar-content-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
}
.right-context-bar-content-container .right-context-bar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-s);
}
.right-context-bar-content-container .right-context-bar-header .right-context-bar__title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-primary);
}
.right-context-bar-content-container .right-context-bar-header .right-context__actions-container {
  display: flex;
}
.right-context-bar-content-container.overlay {
  width: calc(100% - 48px);
}

.context-bar-container-stacking-order {
  z-index: 0;
}

.requester-tab-content-container.contextbarOverlay .requester-tab-content .pane-container .pane-resize-handle {
  pointer-events: none;
}

.requester-tab-content-container.contextbarOverlay .requester-tab-content .pane-container .pane-container .pane-resize-handle {
  pointer-events: all;
}

.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal_body-title {
  font-weight: 600;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal--confirmation-setting {
  flex: 1;
}
.tab-unsaved-confirmation-modal .confirmation-checkbox {
  display: flex;
  flex: 1;
  padding: 20px 0px 2px;
}
.tab-unsaved-confirmation-modal .confirmation-text {
  padding: 0px 0px 0px 10px;
}
.tab-unsaved-confirmation-modal .confirmation-helper-text {
  padding-left: 30px;
  opacity: 0.5;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer {
  flex-direction: row;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer.conflict {
  flex: 0 0 110px;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__left {
  display: flex;
  flex: 1;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__left > .btn.is-focused:focus, .tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__left > .btn.is-focused.is-focused {
  box-shadow: var(--shadow-focus);
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__right > .btn {
  margin-left: var(--spacing-m);
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__right > .btn.btn-text {
  height: 40px;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__right > .btn.is-focused:focus, .tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-actions .tab-unsaved-confirmation-modal__footer__right > .btn.is-focused.is-focused {
  box-shadow: var(--shadow-focus);
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-additional-actions {
  display: flex;
  margin-top: 8px;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-additional-actions > .btn {
  margin-left: var(--spacing-m);
  min-width: 80px;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-additional-actions > .btn.btn-text {
  height: 20px;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-additional-actions > .btn.is-focused:focus, .tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper .tab-unsaved-confirmation-modal__footer-additional-actions > .btn.is-focused.is-focused {
  box-shadow: var(--shadow-focus);
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper.conflict .btn-primary.large {
  width: 140px;
}
.tab-unsaved-confirmation-modal .tab-unsaved-confirmation-modal__footer .tab-unsaved-confirmation-modal__footer-wrapper.conflict .btn-text {
  padding: 0;
  margin-left: 0;
  width: 140px;
}

.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__content {
  padding-top: 15px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__header {
  display: flex;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__icon {
  margin-left: -5px;
  margin-top: 5px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__icon use {
  fill: var(--base-color-warning);
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__icon svg {
  color: var(--base-color-warning);
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__info__title {
  font-size: 14px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__info__desc {
  color: var(--content-color-secondary);
  padding-top: 5px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__info {
  margin-left: 10px;
  margin-top: 3px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__options {
  padding: 10px 0 0 0;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__option {
  cursor: pointer;
  display: flex;
  padding: 10px 0;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__option__info {
  display: flex;
  flex-direction: column;
  margin-top: -3px;
  margin-left: 10px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__option__desc {
  color: var(--content-color-secondary);
  font-size: 11px;
  padding-top: 3px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer {
  flex-direction: row;
  margin-top: -25px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer-wrapper {
  display: flex;
  flex: 1;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__left {
  flex: 1;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__left > .btn:focus, .tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__left > .btn.is-focused {
  border: 1px solid var(--base-color-info);
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__dont-save-button {
  margin-right: 10px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__right > .btn {
  margin-left: var(--spacing-m);
  min-width: 80px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__right > .btn:focus, .tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__right > .btn.is-focused {
  border: 1px solid var(--base-color-info);
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__right > .btn.btn-text {
  height: 40px;
}
.tab-conflict-confirmation-modal .tab-conflict-confirmation-modal__footer__right .btn-primary {
  min-width: 80px;
}

.requester-tab-empty-shell {
  flex: 1;
  display: flex;
}
.requester-tab-empty-shell .requester-tab-content-panes {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.requester-tab-empty-shell .requester-tab-content-pane {
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
}
.requester-tab-empty-shell .requester-contents-top {
  background-color: var(--background-color-primary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.requester-tab-empty-shell .dummy-request-meta-viewer {
  height: 48px;
  display: flex;
  width: 100%;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-sizing: border-box;
  align-items: center;
}
.requester-tab-empty-shell .dummy-entity-tab-header {
  height: var(--size-s);
  width: 20%;
  background-color: var(--background-color-tertiary);
  margin-left: var(--spacing-s);
  border-radius: var(--border-radius-default);
}
.requester-tab-empty-shell .flexed {
  display: flex;
}
.requester-tab-empty-shell .request-editor__tabs-wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.requester-tab-empty-shell .strip {
  background-color: var(--background-color-tertiary);
  width: 50px;
  height: var(--size-s);
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-s);
  border-radius: var(--border-radius-default);
}
.requester-tab-empty-shell .strip-cookie {
  margin-right: var(--spacing-s);
}
.requester-tab-empty-shell .tab-param {
  width: 42px;
}
.requester-tab-empty-shell .tab-auth {
  width: 76px;
}
.requester-tab-empty-shell .tab-header {
  width: 69px;
}
.requester-tab-empty-shell .tab-body {
  width: 28px;
}
.requester-tab-empty-shell .tab-pre-req-script {
  width: 100px;
}
.requester-tab-empty-shell .tab-tests {
  width: 30px;
}
.requester-tab-empty-shell .tab-settings {
  width: 44px;
}
.requester-tab-empty-shell .url {
  flex: 1;
  height: 40px;
  margin: var(--spacing-s);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
}
.requester-tab-empty-shell .grouped-buttons {
  flex: 0 0 100px;
  display: flex;
}
.requester-tab-empty-shell .btn-item {
  flex: 1;
  height: 40px;
  margin: var(--spacing-s);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
}
.requester-tab-empty-shell .btn-item.send {
  background-color: var(--base-color-info);
  opacity: 0.2;
  width: 100px;
}
.requester-tab-empty-shell .response-viewer-empty__header .strip-response {
  width: 64px;
  margin-left: 0;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.editor-tab-view-container {
  flex: 1;
  min-width: 0;
  min-height: 0;
  display: flex;
}
.editor-tab-view-container .request-editor-tab-view.editor-tab-view {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.editor-tab-view-container .request-editor-tab-view.editor-tab-view .editor-pane-response-viewer .response-pane {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}

.layout-2-column .request-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;
}
.layout-2-column .request-editor .requester-contents-group {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  min-height: 0;
}
.layout-2-column .request-editor .requester-contents-group__section-content {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.layout-2-column .request-editor .request-editor__tab-contents {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.request-urlparams-editor-container,
.request-header-editor-container,
.request-body-formdata-editor-wrapper,
.request-body-formdata-editor-wrapper,
.request-body-urlencoded-editor-wrapper,
.request-configuration-list {
  overflow-y: auto;
}

.requester-tab-content-panes,
.requester-tab-content-pane,
.requester-tab-content-pane--request-editor,
.request-editor,
.requester-contents-group,
.requester-contents-group__section-content,
.request-editor__tab-contents,
.request-editor-tab-contents {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  min-height: 0;
}

.request-editor__main {
  margin: var(--spacing-s) var(--spacing-l);
  display: flex;
  flex-direction: row;
}
.request-editor__main .request-editor__main__url-group {
  flex: 1;
  min-width: 0;
}
.request-editor__main .request-editor__main__button-group {
  flex: 0 0 100px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 11;
}

.request-editor__main {
  position: relative;
  box-sizing: border-box;
  background-color: var(--background-color-primary);
}

@keyframes animateBottomBorder {
  from {
    width: 50%;
  }
  to {
    width: 0%;
  }
}
.request-editor__main__url-group {
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
  margin-right: var(--spacing-s);
  max-height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.request-editor__main__url-group-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.request-editor__main__url-gradient {
  width: 100%;
}

.request-editor__main__url-gradient-left {
  height: 2px;
  background: linear-gradient(270deg, rgb(255, 108, 55), rgba(255, 108, 55, 0.5), transparent 50%);
  animation: animateBottomBorder 0.5s ease-in;
  float: left;
  width: 50%;
}

.request-editor__main__url-gradient-right {
  height: 2px;
  background: linear-gradient(90deg, rgb(255, 108, 55), rgba(255, 108, 55, 0.5), transparent 50%);
  animation: animateBottomBorder 0.5s ease-in;
  float: right;
  width: 50%;
}

.request-method-editor {
  flex: 0 0 115px;
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
}

.request-urlpathvariables-editor-button {
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-right-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.request-urlpathvariables-editor-button:hover, .request-urlpathvariables-editor-button.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.request-urlpathvariables-editor-button:active, .request-urlpathvariables-editor-button.is-active {
  background-color: var(--background-color-primary);
}
.layout-2-column .request-urlpathvariables-editor-button {
  flex: 0 0 60px;
}

.request-editor__main__button-group {
  display: flex;
  flex-direction: row;
}

.request-prscript-editor,
.request-tests-editor,
.request-script-editor {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
}
.request-prscript-editor .editor,
.request-tests-editor .editor,
.request-script-editor .editor {
  flex: 1;
}
.request-prscript-editor .text-editor-wrapper,
.request-tests-editor .text-editor-wrapper,
.request-script-editor .text-editor-wrapper {
  box-sizing: border-box;
}
.request-prscript-editor.comment-available__light .view-lines,
.request-tests-editor.comment-available__light .view-lines,
.request-script-editor.comment-available__light .view-lines {
  cursor: url("../assets/images/thumbnails/select-cursor-dark.svg") 4 8, text;
}
.request-prscript-editor.comment-available__light .view-lines .view-line span,
.request-tests-editor.comment-available__light .view-lines .view-line span,
.request-script-editor.comment-available__light .view-lines .view-line span {
  cursor: url("../assets/images/thumbnails/comment-select-cursor-dark.svg") 4 8, text;
}
.request-prscript-editor.comment-available__dark .view-lines,
.request-tests-editor.comment-available__dark .view-lines,
.request-script-editor.comment-available__dark .view-lines {
  cursor: url("../assets/images/thumbnails/select-cursor-light.svg") 4 8, text;
}
.request-prscript-editor.comment-available__dark .view-lines .view-line span,
.request-tests-editor.comment-available__dark .view-lines .view-line span,
.request-script-editor.comment-available__dark .view-lines .view-line span {
  cursor: url("../assets/images/thumbnails/comment-select-cursor-light.svg") 4 8, text;
}

.request-prscript-editor-snippets,
.request-tests-editor-snippets,
.request-script-snippets {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xs);
}
.request-prscript-editor-snippets.show-ai-gen,
.request-tests-editor-snippets.show-ai-gen,
.request-script-snippets.show-ai-gen {
  position: relative;
  padding-top: 72px;
}

.ai-ghost-text {
  color: var(--content-color-tertiary) !important;
}

.layout-2-column .request-urlparams-editor-container,
.layout-2-column .request-header-editor-container {
  flex: 1;
  overflow-y: auto;
}

.request-editor__tabs-wrapper {
  display: flex;
  height: var(--size-m);
  padding: var(--spacing-zero) var(--spacing-l);
}
.request-editor__tabs-wrapper .request-editor__tabs {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.request-editor__tabs-wrapper .request-editor__tabs .dropdown-label {
  font-weight: var(--text-weight-medium);
  white-space: pre;
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.request-editor__tabs-wrapper .request-editor__tabs .dropdown-caret.pm-icon {
  margin-left: var(--spacing-xs);
}
.request-editor__tabs-wrapper .request-editor__tabs .dropdown-button > .btn {
  padding-left: 0px;
}
.request-editor__tabs-wrapper .request-editor__tabs .entity-tabs-list--primary {
  gap: var(--spacing-xl);
}
.request-editor__tabs-wrapper .request-editor__tabs .entity-tabs-list--primary-small {
  gap: var(--spacing-l);
}
.request-editor__tabs-wrapper .request-editor__tabs .entity-tabs-list--primary .tab-primary .tab-text-wrapper,
.request-editor__tabs-wrapper .request-editor__tabs .entity-tabs-list--primary .tab-primary-small .tab-text-wrapper, .request-editor__tabs-wrapper .request-editor__tabs .entity-tabs-list--primary-small .tab-primary .tab-text-wrapper,
.request-editor__tabs-wrapper .request-editor__tabs .entity-tabs-list--primary-small .tab-primary-small .tab-text-wrapper {
  margin: unset;
}

.request-editor-actions {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-left: var(--spacing-s);
  justify-content: flex-end;
  align-self: center;
  line-height: var(--line-height-m);
}
.request-editor-actions__item {
  padding: var(--spacing-zero) var(--spacing-xs);
  font-size: var(--text-size-m);
  color: var(--content-color-link);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.request-editor-actions .dropdown-button {
  display: flex;
  align-items: center;
}

.request-editor-actions-comments,
.request-editor-actions-reset {
  padding: var(--spacing-zero) var(--spacing-zero);
  font-size: var(--text-size-m);
  color: var(--content-color-link);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.request-editor-actions-comments {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  margin-right: var(--spacing-s);
}
.request-editor-actions-comments > .comment-wrapper {
  cursor: pointer;
}
.request-editor-actions-comments > .comment-wrapper.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.request-editor {
  position: relative;
}

.request-editor-resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;
  cursor: ew-resize;
}

.entity-empty .entity-empty__subtitle--history-load {
  max-width: 300px !important;
}

.request-editor-tab--auth.is-disabled,
.request-editor-tab--configurations.is-disabled,
.dropdown-menu-item--auth.is-disabled,
.dropdown-menu-item--configurations.is-disabled {
  cursor: not-allowed;
}

.request-editor-tab--scripts .tab-text-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  width: calc(100% + var(--spacing-s));
  height: 95%;
  background-color: var(--background-color-tertiary);
  opacity: 0;
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  border-radius: var(--border-radius-default);
}
.request-editor-tab--scripts.is-highlighted .tab-text-wrapper::before {
  opacity: 1;
}
.request-editor-tab--scripts.is-highlighted .tab-text-wrapper > span {
  z-index: 1;
  color: var(--content-color-primary);
}
.request-editor-tab--scripts.is-highlighted .tab-text-wrapper .request-editor-tabs-badge {
  z-index: 1;
}
.request-editor-tab--scripts.is-highlighted .tab-text-wrapper .collection-modal-tabs-badge {
  z-index: 1;
}

.request-editor-tabs-count {
  padding: var(--spacing-zero) var(--spacing-xs);
  color: var(--content-color-success);
}

.request-editor-tabs-comment-badge {
  height: 14px;
  min-width: 14px;
  background-color: var(--base-color-brand);
  font-size: var(--text-size-s);
  color: var(--content-color-constant);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-default);
  position: absolute;
  top: var(--spacing-zero);
  right: -6px;
  padding: var(--spacing-zero) 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-editor-tabs-badge {
  display: inline-flex;
  width: 6px;
  height: 6px;
  margin-left: var(--spacing-xs);
  border-radius: 6px;
  background-color: var(--base-color-success);
}

.request-editor-tabs-error-badge {
  display: inline-flex;
  background-color: var(--base-color-error);
  width: 5px;
  height: 5px;
  margin-left: calc(var(--spacing-xs) + 1px);
  transform: rotate(45deg);
}

.request-editor-tab-body {
  position: relative;
}

.request-editor-tab-contents {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}

.request-editor-tab-content {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
  height: 100%;
}
.request-editor-tab-content.is-hidden {
  display: none;
}

.request-script-wrapper,
.request-tests-wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s);
  padding-right: var(--spacing-zero);
  min-height: 0;
}

.request-tests-editor-container,
.request-prscript-editor-container,
.prscript-editor-container,
.tests-editor-container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
  min-width: 0;
}

.request-prscript-editor-group,
.request-tests-editor-group,
.prscript-editor-group,
.tests-editor-group {
  flex: auto;
  display: flex;
  flex-direction: row;
  min-height: 0;
}

.request-method-editor {
  padding-right: 2px;
  border: none;
  background-color: var(--background-color-primary);
  max-width: 108px;
}
.request-method-editor .dropdown {
  width: 100%;
  text-align: center;
}
.request-method-editor .dropdown-button .btn {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  font-size: var(--text-size-l);
  height: 38px;
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.request-method-editor .dropdown-button .btn:hover, .request-method-editor .dropdown-button .btn.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.request-method-editor .dropdown-button .btn:active, .request-method-editor .dropdown-button .btn.is-active {
  background-color: var(--background-color-primary);
}
.request-method-editor .request-methods-list-wrapper {
  transition: all 0.2s ease-in-out;
  height: 100%;
  width: 108px;
  border-radius: inherit;
  box-sizing: border-box;
  border: var(--border-width-default) var(--border-style-solid) transparent;
}
.request-method-editor .request-methods-list-wrapper:focus-within {
  box-shadow: 0 0 0 3px var(--input-shadow-focus);
}
.request-method-editor .request-methods-list-wrapper .dropdown-button {
  padding-right: var(--spacing-s);
}
.request-method-editor .request-methods-list-wrapper .color-GET {
  color: var(--content-color-success);
}
.request-method-editor .request-methods-list-wrapper .color-POST {
  color: var(--content-color-warning);
}
.request-method-editor .request-methods-list-wrapper .color-DELETE {
  color: var(--content-color-error);
}
.request-method-editor .request-methods-list-wrapper .color-PUT {
  color: var(--content-color-info);
}
.request-method-editor .request-methods-list-wrapper .color-PATCH {
  color: var(--content-color-patch-method);
}
.request-method-editor .request-methods-list-wrapper .color-OPTIONS {
  color: var(--content-color-options-method);
}
.request-method-editor .request-methods-list-wrapper .color-HEAD {
  color: var(--content-color-success);
}
.request-method-editor .request-methods-list-wrapper .color-default {
  color: var(--content-color-primary);
  text-overflow: ellipsis;
}
.request-method-editor .request-methods-list-wrapper.is-open {
  transition: all 0.2s ease-in-out;
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) transparent;
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
  z-index: 11;
}

.request-methods-editor-dropdown-menu .dropdown-menu {
  min-width: 156px;
  font-weight: var(--text-weight-bold);
  margin-top: var(--spacing-xs);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item {
  color: var(--content-color-primary);
  margin: 0 var(--spacing-s);
  padding: 0 var(--spacing-s);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item .highlight {
  color: inherit;
  font-weight: 800;
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item--GET {
  color: var(--content-color-success);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item--POST {
  color: var(--content-color-warning);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item--PUT {
  color: var(--content-color-info);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item--DELETE {
  color: var(--content-color-error);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item--PATCH {
  color: var(--content-color-patch-method);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item--OPTIONS {
  color: var(--content-color-options-method);
}
.request-methods-editor-dropdown-menu .dropdown-menu .dropdown-menu-item--HEAD {
  color: var(--content-color-success);
}

.request-method--GET {
  color: var(--content-color-success);
  font-weight: var(--text-weight-medium);
}

.request-method--PATCH {
  color: var(--content-color-patch-method);
  font-weight: var(--text-weight-medium);
}

.request-method--OPTIONS {
  color: var(--content-color-options-method);
  font-weight: var(--text-weight-medium);
}

.request-method--PUT {
  color: var(--content-color-info);
  font-weight: var(--text-weight-medium);
}

.request-method--POST {
  color: var(--content-color-warning);
  font-weight: var(--text-weight-medium);
}

.request-method--DELETE {
  color: var(--content-color-error);
  font-weight: var(--text-weight-medium);
}

.request-method--HEAD {
  color: var(--content-color-success);
  font-weight: var(--text-weight-medium);
}

.request-method--default {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  text-overflow: ellipsis;
}

.request-url-editor {
  flex: 1;
  min-width: 0;
  color: var(--content-color-primary);
  background-color: var(--background-color-primary);
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.request-url-editor > div:first-child {
  border: none;
  border-radius: 0px var(--border-radius-default) var(--border-radius-default) 0px;
  padding: 0px;
}
.request-url-editor:focus, .request-url-editor.is-focused {
  outline: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
}
.request-url-editor .auto-suggest {
  transition: all 0.2s ease-in-out;
  height: 100%;
  width: 100%;
  border: none;
  box-sizing: border-box;
}
.request-url-editor .auto-suggest:hover, .request-url-editor .auto-suggest.is-hovered {
  background-color: var(--background-color-primary);
}
.request-url-editor .auto-suggest.is-focused {
  background-color: var(--background-color-primary);
}
.request-url-editor .auto-suggest.is-focused > div:first-child.auto-suggest__overflown {
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  border-bottom-right-radius: 4px;
  border-top: 0;
  border-right: 0;
}
.request-url-editor .auto-suggest.is-focused > div:first-child.auto-suggest__overflown::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: calc(100% - 36px);
  border-right: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  margin-top: 36px;
  margin-right: -1px;
  border-bottom-right-radius: 4px;
}
.request-url-editor .DraftEditor-root {
  min-height: 40px !important;
}
.request-url-editor .auto-suggest__collapsible {
  padding-right: var(--spacing-s);
  min-height: 40px;
}
.request-url-editor .public-DraftEditor-content, .request-url-editor .public-DraftEditorPlaceholder-root {
  font-size: var(--text-size-m);
  line-height: 1.5;
}

.request-url-editor-hard-focus .auto-suggest.is-focused > div:first-child.auto-suggest__overflown {
  border: 0;
}
.request-url-editor-hard-focus .auto-suggest.is-focused > div:first-child.auto-suggest__overflown::after {
  border-right: 0;
}

.input-suggestions.request-url-editor-suggestions {
  padding: var(--spacing-s) var(--spacing-zero);
  margin-top: 10px;
}

.request-body-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.request-body-type-selector-wrapper {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  padding: var(--spacing-xs) var(--spacing-l);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.request-body-type-selector-wrapper .request-body-type-selector-wrapper-container {
  flex: 1;
  display: flex;
  align-items: center;
}
.request-body-type-selector-wrapper .request-body-schema-selector-wrapper-container {
  display: flex;
  align-items: center;
}
.layout-2-column .request-body-type-selector-wrapper .request-body-schema-selector-wrapper-container {
  justify-content: flex-start;
}
.layout-1-column .request-body-type-selector-wrapper .request-body-schema-selector-wrapper-container {
  flex: 1;
}
.request-body-type-selector-wrapper .request-body-type-selector-wrapper-actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.request-body-type-selector-wrapper .request-body-type-selector-wrapper-actions .request-body-type-selector-beautify {
  display: flex;
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.request-body-type-selector-wrapper .request-body-type-selector-wrapper-actions .request-body-type-selector-beautify.is-disabled {
  cursor: default;
}
.request-body-type-selector-wrapper .request-body-type-selector-wrapper-actions .request-body-type-selector-beautify:hover, .request-body-type-selector-wrapper .request-body-type-selector-wrapper-actions .request-body-type-selector-beautify.is-hovered {
  background-color: var(--background-color-brand);
}
.request-body-type-selector-wrapper .request-body-raw-language-selector {
  display: flex;
}

.request-body-type-selector-buttons-dropdown .dropdown-button .btn-secondary {
  box-shadow: none;
  background-color: var(--button-secondary-background-color);
}
.request-body-type-selector-buttons-dropdown .dropdown-button .btn-secondary:hover {
  background-color: var(--button-secondary-hover-background-color);
}
.request-body-type-selector-buttons-dropdown .dropdown-button .btn-secondary.is-active {
  background-color: var(--button-secondary-active-background-color);
}

.request-body-type-selector-buttons {
  display: flex;
  height: var(--size-m);
}
.request-body-type-selector-buttons.is-disabled {
  opacity: 0.3;
}

.request-body-type-selector-button {
  display: inline-flex;
  align-items: center;
  margin: var(--spacing-xs) var(--spacing-s);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.request-body-type-selector-button input {
  margin-right: var(--spacing-xs);
}

.request-body-raw-language-selector-dropdown .dropdown-button {
  display: flex;
}
.request-body-raw-language-selector-dropdown .dropdown-menu {
  min-width: 210px;
}

.request-body-graphql-schema-selector {
  display: inline-flex;
}
.request-body-graphql-schema-selector .dropdown {
  display: flex;
}
.request-body-graphql-schema-selector .dropdown-button {
  display: flex;
  align-items: center;
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .btn {
  padding: var(--spacing-zero);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon:hover use, .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-hovered use {
  fill: var(--base-color-brand);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon:hover svg:not([class*=PartnerIcon]) > path[fill], .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon:hover svg, .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-hovered svg {
  color: var(--base-color-brand);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon:active use, .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-active use {
  fill: var(--base-color-brand);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon:active svg:not([class*=PartnerIcon]) > path[fill], .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon:active svg, .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-active svg {
  color: var(--base-color-brand);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-loading {
  cursor: default;
  -webkit-animation: rotating 1s linear infinite;
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-loading:hover use, .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-loading.is-hovered use {
  fill: var(--content-color-secondary);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-loading:hover svg:not([class*=PartnerIcon]) > path[fill], .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-loading.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-loading:hover svg, .request-body-graphql-schema-selector .request-body-graphql-schema-selector-refresh-icon-wrapper .request-body-graphql-schema-selector-refresh-icon.is-loading.is-hovered svg {
  color: var(--content-color-secondary);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-introspection-state {
  margin-left: var(--spacing-s);
  padding: var(--spacing-zero);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-warning-icon-wrapper .btn {
  padding: var(--spacing-zero);
  margin-left: var(--spacing-s);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-warning-icon-wrapper .btn .warning-icon {
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-warning-icon-wrapper .btn .warning-icon use {
  fill: var(--base-color-warning);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-warning-icon-wrapper .btn .warning-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.request-body-graphql-schema-selector .request-body-graphql-schema-selector-warning-icon-wrapper .btn .warning-icon svg {
  color: var(--base-color-warning);
}

.request-body-graphql-schema-selector-dropdown {
  display: flex;
}
.request-body-graphql-schema-selector-dropdown .dropdown-menu {
  max-width: 210px;
}
.request-body-graphql-schema-selector-dropdown .btn.btn-text {
  max-width: 210px;
}
.request-body-graphql-schema-selector-dropdown .request-body-graphql-current-schema {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.request-body-graphql-schema-selector-dropdown .request-body-graphql-schema-list-entry {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.request-body-graphql-schema-selector-offline-message .alert {
  color: var(--content-color-tertiary);
}

.request-body-graphql-schema-selector-signed-out-dropdown {
  text-align: center;
  min-width: 320px;
}
.request-body-graphql-schema-selector-signed-out-dropdown .entity-empty {
  border: none;
  padding: var(--spacing-xxl) var(--spacing-l);
}
.request-body-graphql-schema-selector-signed-out-dropdown .entity-empty .entity-empty__title {
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-l);
}
.request-body-graphql-schema-selector-signed-out-dropdown .entity-empty .entity-empty__subtitle {
  margin-bottom: var(--spacing-xl);
}
.request-body-graphql-schema-selector-signed-out-dropdown .entity-empty .entity-empty__title,
.request-body-graphql-schema-selector-signed-out-dropdown .entity-empty .entity-empty__subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.request-body-graphql-schema-selector-signed-out-dropdown .entity-empty .entity-empty__learn .entity-empty__learn__mouse-click-icon {
  padding-right: var(--spacing-s);
}

.request-body-unavailable {
  margin: var(--spacing-l);
}

.request-body-editors {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.request-body-editor-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.request-body-editor-wrapper.is-hidden {
  display: none;
}

.request-body-none-editor-wrapper {
  align-items: center;
  padding: var(--spacing-m) var(--spacing-zero);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.request-body-binary-editor-wrapper .request-body-binary-editor {
  padding: var(--spacing-xs) var(--spacing-m);
  display: flex;
  align-items: center;
}

.request-body-formdata-editor,
.request-body-urlencoded-editor {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}
.request-body-formdata-editor .key-value-form-editor,
.request-body-urlencoded-editor .key-value-form-editor {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default) !important;
}
.request-body-formdata-editor .key-value-bulk-editor,
.request-body-urlencoded-editor .key-value-bulk-editor {
  border-top: none;
}

.request-body-formdata-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.request-body-formdata-editor .key-value-editor {
  flex: 1;
}
.request-body-formdata-editor .key-value-form-editor {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.request-body-urlencoded-editor {
  flex: 1;
  display: flex;
}
.request-body-urlencoded-editor .key-value-editor {
  flex: 1;
  width: 100%;
}

.request-body-raw-editor {
  flex: 1;
  display: flex;
  position: relative;
  padding: var(--spacing-s);
  min-height: 0;
}
.request-body-raw-editor.comment-available__light .view-lines {
  cursor: url("../assets/images/thumbnails/select-cursor-dark.svg") 4 8, text;
}
.request-body-raw-editor.comment-available__light .view-lines .view-line span {
  cursor: url("../assets/images/thumbnails/comment-select-cursor-dark.svg") 4 8, text;
}
.request-body-raw-editor.comment-available__dark .view-lines {
  cursor: url("../assets/images/thumbnails/select-cursor-light.svg") 4 8, text;
}
.request-body-raw-editor.comment-available__dark .view-lines .view-line span {
  cursor: url("../assets/images/thumbnails/comment-select-cursor-light.svg") 4 8, text;
}
.request-body-raw-editor .resolvedVariableText {
  color: var(--content-color-info);
}
.request-body-raw-editor .unresolvedVariableText {
  color: var(--content-color-error);
}

.request-body-graphql-editor {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-xs);
  min-height: 0;
}
.layout-2-column .request-body-graphql-editor {
  flex-direction: column;
}
.request-body-graphql-editor .request-body-graphql-query-editor {
  flex: 1;
  margin: var(--spacing-xs);
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}
.request-body-graphql-editor .request-body-graphql-variables-editor {
  flex: 1;
  margin: var(--spacing-xs);
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}
.request-body-graphql-editor .request-body-graphql-variables-editor .texteditor-header .info-tooltip-icon {
  display: inline-flex;
  margin-left: var(--spacing-xs);
}
.request-body-graphql-editor .request-body-graphql-editor-ratio-resize-handle {
  z-index: 7;
}
.layout-2-column .request-body-graphql-editor .request-body-graphql-editor-ratio-resize-handle {
  cursor: ns-resize;
  height: 10px;
  margin: -5px 5px;
}
.layout-1-column .request-body-graphql-editor .request-body-graphql-editor-ratio-resize-handle {
  cursor: ew-resize;
  width: 10px;
  margin: var(--spacing-xl) -5px 5px;
}

.request-editor-send-button .send-request-split-button {
  height: 100%;
  width: 100px;
  background-color: transparent;
}
.request-editor-send-button .aether-split-button__primary-btn,
.request-editor-send-button .aether-split-button__secondary-btn {
  height: 100%;
  background-color: var(--button-main-background-color);
}
.request-editor-send-button .aether-split-button__primary-btn:hover, .request-editor-send-button .aether-split-button__primary-btn.is-hovered,
.request-editor-send-button .aether-split-button__secondary-btn:hover,
.request-editor-send-button .aether-split-button__secondary-btn.is-hovered {
  background-color: var(--button-main-hover-background-color);
  box-shadow: 0 0 0 1px var(--button-main-outline-color) inset;
}
.request-editor-send-button .aether-split-button__primary-btn:active, .request-editor-send-button .aether-split-button__primary-btn.is-active,
.request-editor-send-button .aether-split-button__secondary-btn:active,
.request-editor-send-button .aether-split-button__secondary-btn.is-active {
  background-color: var(--button-main-active-background-color);
  box-shadow: 0 0 0 1px var(--button-main-outline-color) inset;
}
.request-editor-send-button .aether-split-button__primary-btn:focus, .request-editor-send-button .aether-split-button__primary-btn.is-focused,
.request-editor-send-button .aether-split-button__secondary-btn:focus,
.request-editor-send-button .aether-split-button__secondary-btn.is-focused {
  z-index: 1;
}
.request-editor-send-button .aether-split-button__primary-btn {
  width: 70px;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  color: var(--button-main-content-color);
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
}
.request-editor-send-button .aether-split-button__secondary-btn {
  width: var(--size-m);
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
}
.request-editor-send-button .aether-split-button__splitter {
  background-color: var(--button-main-background-color);
  border-left: var(--button-group-separator) !important;
  height: 100%;
}
.request-editor-send-button.is-sending .aether-split-button__primary-btn, .request-editor-send-button.is-disabled .aether-split-button__primary-btn, .request-editor-send-button.hide-download .aether-split-button__primary-btn {
  flex: 100%;
  border-radius: var(--border-radius-default) !important;
}
.request-editor-send-button.is-sending .aether-split-button__secondary-btn,
.request-editor-send-button.is-sending .aether-split-button__splitter, .request-editor-send-button.is-disabled .aether-split-button__secondary-btn,
.request-editor-send-button.is-disabled .aether-split-button__splitter, .request-editor-send-button.hide-download .aether-split-button__secondary-btn,
.request-editor-send-button.hide-download .aether-split-button__splitter {
  display: none;
}
.request-editor-send-button.is-sending .aether-split-button__primary-btn {
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
  font-weight: var(--text-weight-regular);
}
.request-editor-send-button.is-sending .aether-split-button__primary-btn:hover, .request-editor-send-button.is-sending .aether-split-button__primary-btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.request-editor-send-button.is-loading .aether-split-button__primary-btn, .request-editor-send-button.is-disabled .aether-split-button__primary-btn {
  opacity: 0.5;
  background-color: var(--button-main-disabled-background-color);
  color: var(--button-main-content-color);
}
.request-editor-send-button.is-loading .aether-split-button__primary-btn:hover, .request-editor-send-button.is-loading .aether-split-button__primary-btn.is-hovered, .request-editor-send-button.is-disabled .aether-split-button__primary-btn:hover, .request-editor-send-button.is-disabled .aether-split-button__primary-btn.is-hovered {
  background-color: var(--button-main-background-color);
}
.request-editor-send-button.is-loading .aether-split-button__primary-btn:active, .request-editor-send-button.is-loading .aether-split-button__primary-btn.is-active, .request-editor-send-button.is-disabled .aether-split-button__primary-btn:active, .request-editor-send-button.is-disabled .aether-split-button__primary-btn.is-active {
  background-color: var(--button-main-background-color);
}

.send-button-tooltip__container.tooltip {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.send-button-tooltip__container.tooltip .tooltip-wrapper {
  padding: var(--spacing-l);
}
.send-button-tooltip__header {
  color: var(--content-color-error);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.send-button-tooltip__body {
  margin-top: var(--spacing-xs);
  max-width: 250px;
}
.send-button-tooltip__body-action {
  cursor: pointer;
  color: var(--content-color-brand);
}

.request-editor-save-button .btn-group {
  background-color: var(--button-secondary-background-color);
  border-radius: var(--border-radius-default);
}
.request-editor-save-button .btn-group button {
  background-color: transparent;
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
}
.request-editor-save-button .btn-group button:hover {
  background-color: var(--button-secondary-hover-background-color);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
}
.request-editor-save-button .btn-group button:disabled:hover {
  background-color: var(--button-secondary-disabled-background-color);
  box-shadow: 0 0 0 1px var(--button-secondary-disabled-outline-color) inset;
}
.request-editor-save-button .btn-group button:focus:not(:focus-visible) {
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
}
.request-editor-save-button .btn-group .btn {
  min-width: var(--size-s);
  height: var(--size-m);
  padding: var(--spacing-s) var(--spacing-xs);
  margin-left: -1px;
  border-left: var(--button-group-separator);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
  z-index: 2;
}
.request-editor-save-button .btn-group .btn:hover {
  background-color: var(--button-secondary-hover-background-color);
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
}
.request-editor-save-button__label {
  margin-left: var(--spacing-xs);
}
.request-editor-save-button .request-editor-save-button-dropdown {
  min-width: 100px;
}
.request-editor-save-button .use-browser-tabs-header.dropdown-button::after {
  position: absolute;
  width: 1px;
  height: 20px;
  background-color: var(--environment-divider-border-color);
  content: "";
  right: 24px;
  top: 6px;
}
.request-editor-save-button .use-browser-tabs-header.dropdown-button:hover::after, .request-editor-save-button .use-browser-tabs-header.dropdown-button:focus::after {
  background-color: transparent;
}
.request-editor-save-button .use-browser-tabs-header.dropdown-button .btn-secondary {
  background-color: transparent;
}
.request-editor-save-button .use-browser-tabs-header.dropdown-button .btn-secondary:hover, .request-editor-save-button .use-browser-tabs-header.dropdown-button .btn-secondary:focus {
  background-color: var(--highlight-background-color-primary);
}
.request-editor-save-button .use-browser-tabs-header.dropdown-button .btn-secondary.is-disabled {
  background-color: transparent;
}
.request-editor-save-button .btn {
  height: var(--size-m);
}
.request-editor-save-button .btn:first-child {
  min-width: 50px;
}
.request-editor-save-button .btn:not(:last-child) {
  border-right: none;
}
.request-editor-save-button .btn-group > .btn {
  min-width: var(--size-s);
  padding: var(--spacing-s) var(--spacing-xs);
}
.request-editor-save-button--new-share-experience {
  margin-right: 0;
}
.request-editor-save-button--new-share-experience .btn-group {
  background-color: transparent;
}

.request-meta-viewer {
  border-bottom: 0 !important;
}
.request-meta-viewer .request-meta-saved-responses-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: var(--spacing-s);
}
.request-meta-viewer .request-meta__options {
  margin-right: var(--spacing-s);
  line-height: var(--line-height-s);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.request-meta-viewer .request-meta__options use {
  fill: var(--content-color-secondary);
}
.request-meta-viewer .request-meta__options svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.request-meta-viewer .request-meta__options svg {
  color: var(--content-color-secondary);
}
.request-meta-viewer .requester-contents-group {
  flex: 0 0 20px;
  display: flex;
  flex-direction: row;
  background-color: var(--background-color-primary);
  padding: var(--spacing-xs) var(--spacing-s);
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.request-meta-viewer .request-meta-saved-responses-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: var(--spacing-l);
}
.request-meta-viewer .request-editor-comments-info {
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  margin-left: var(--spacing-l);
}
.request-meta-viewer .request-meta-name-group {
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: row;
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.request-meta-viewer .request-meta-name-group .request-meta-name-wrapper {
  display: flex;
  min-width: 0;
  cursor: pointer;
}
.request-meta-viewer .request-meta-name-group .request-meta-name-wrapper .request-meta-name-wrapper__tooltip {
  display: flex;
  flex: 1;
  min-width: 0;
}
.request-meta-viewer .request-meta-name-group.is-editing {
  height: 20px;
}
.request-meta-viewer .request-meta-name-group.is-editing .request-meta-name-wrapper {
  width: 100%;
}
.request-meta-viewer .request-meta-name-group.is-editing .request-meta-name-wrapper .inline-input__wrapper {
  width: 100%;
}
.request-meta-viewer .request-meta-name-group .request-meta-name-toggle {
  cursor: pointer;
}
.request-meta-viewer .request-meta-name-group .request-meta-name-toggle.is-open {
  transform: rotate(360deg);
}
.request-meta-viewer .request-meta-name-group .request-meta-name-toggle.is-hidden {
  display: none;
}
.request-meta-viewer .request-meta-name-group .inline-input__wrapper .input-box {
  font-size: 13px;
  font-weight: var(--text-weight-medium);
  padding: 2px;
}
.request-meta-viewer .request-meta-name-group .request-meta-name__edit__icon-wrapper {
  cursor: pointer;
  padding-left: var(--spacing-s);
  display: flex;
  align-items: center;
}
.request-meta-viewer .request-meta-name-group .request-meta-name__edit__icon-wrapper .request-meta-name__edit__icon {
  visibility: hidden;
}
.request-meta-viewer .request-meta-name-group .request-meta-name__edit__icon-wrapper .request-meta-name__edit__icon svg {
  height: 11px;
  width: 11px;
}
.request-meta-viewer .request-meta-name-group:hover .request-meta-name-toggle.is-hidden, .request-meta-viewer .request-meta-name-group.is-hovered .request-meta-name-toggle.is-hidden {
  display: none;
}
.request-meta-viewer .request-meta-name-group:hover.editable .request-meta-name__edit__icon, .request-meta-viewer .request-meta-name-group.is-hovered.editable .request-meta-name__edit__icon {
  visibility: visible;
}
.request-meta-viewer .request-meta-name {
  color: var(--content-color-primary);
  font-size: 13px;
  font-weight: var(--text-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 3px;
}
.request-meta-viewer .request-meta-name-untitled {
  cursor: default;
}

.status {
  padding: var(--spacing-zero) var(--spacing-s);
  line-height: var(--line-height-m);
  border-radius: 35px;
  font-size: var(--text-size-m);
  margin: var(--spacing-zero) var(--spacing-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--text-weight-medium);
  cursor: pointer;
}
.status-info {
  color: var(--content-color-secondary);
  background: var(--background-color-tertiary);
}
.status-info .status__caret-icon use {
  fill: var(--content-color-secondary);
}
.status-info .status__caret-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.status-info .status__caret-icon svg {
  color: var(--content-color-secondary);
}
.status-loading {
  color: var(--content-color-secondary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.status-loading .status__caret-icon use {
  fill: var(--content-color-secondary);
}
.status-loading .status__caret-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.status-loading .status__caret-icon svg {
  color: var(--content-color-secondary);
}
.status-success {
  color: var(--content-color-success);
  background: var(--background-color-tertiary);
}
.status-success .status__caret-icon use {
  fill: var(--base-color-success);
}
.status-success .status__caret-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-success);
}
.status-success .status__caret-icon svg {
  color: var(--base-color-success);
}
.status-warn {
  color: var(--content-color-primary);
  background: var(--background-color-warning);
}
.status-warn .status__caret-icon use {
  fill: var(--content-color-primary);
}
.status-warn .status__caret-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.status-warn .status__caret-icon svg {
  color: var(--content-color-primary);
}
.status-error {
  color: var(--content-color-error);
  background: var(--background-color-error);
}
.status-error .status__caret-icon use {
  fill: var(--base-color-error);
}
.status-error .status__caret-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.status-error .status__caret-icon svg {
  color: var(--base-color-error);
}
.status__info-icon use {
  fill: var(--content-color-tertiary);
}
.status__info-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.status__info-icon svg {
  color: var(--content-color-tertiary);
}
.status__loading-indicator {
  transform: scale(0.5);
  min-width: 8px;
  margin-left: var(--spacing-xs);
}
.status__success-icon {
  background: var(--background-color-success);
  border-radius: 50%;
  height: 12px;
  width: 12px;
  justify-content: center;
  align-items: center;
}
.status__success-icon svg {
  height: 8px;
  width: 8px;
}
.status__success-icon svg use {
  fill: var(--background-color-primary);
}
.status__warning-icon use {
  fill: var(--base-color-warning);
}
.status__warning-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.status__warning-icon svg {
  color: var(--base-color-warning);
}
.status__error-icon use {
  fill: var(--base-color-error);
}
.status__error-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.status__error-icon svg {
  color: var(--base-color-error);
}
.status__label {
  margin: var(--spacing-zero) 1px var(--spacing-zero) 5px;
}

.request-meta__mode-switcher {
  padding-left: var(--spacing-m);
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-left: var(--spacing-m);
}
.request-meta__mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper {
  display: flex;
  justify-content: center;
  height: var(--size-m);
  width: 70px;
}
.request-meta__mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  height: 28px;
  width: var(--size-m);
}
.request-meta__mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.right {
  left: 36px;
}
.request-meta__mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-active-item {
  align-self: center;
  display: flex;
}
.request-meta__mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  height: 100%;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.request-meta__mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper.disabled {
  opacity: 0.3;
}
.request-meta__mode-switcher .request-meta__mode-switcher__icon use {
  fill: var(--content-color-secondary);
}
.request-meta__mode-switcher .request-meta__mode-switcher__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.request-meta__mode-switcher .request-meta__mode-switcher__icon svg {
  color: var(--content-color-secondary);
}
.request-meta__mode-switcher .request-meta__mode-switcher__icon.active use {
  fill: var(--content-color-primary);
}
.request-meta__mode-switcher .request-meta__mode-switcher__icon.active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.request-meta__mode-switcher .request-meta__mode-switcher__icon.active svg {
  color: var(--content-color-primary);
}
.request-meta__mode-switcher.use-browser-tabs {
  border-left: none;
}

.request-meta__mode-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-tertiary);
  text-transform: uppercase;
  padding: var(--spacing-zero) var(--spacing-l);
}
.request-meta__mode-label.saved {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.request-meta__save-request__modal-header {
  line-height: var(--line-height-s);
  text-transform: uppercase;
}
.request-meta__save-request__modal-content {
  overflow: visible;
  line-height: var(--line-height-m);
}
.request-meta__save-request__modal-footer__save-btn {
  margin-left: var(--spacing-s);
}
.request-meta__save-request__modal-footer__save-btn:focus, .request-meta__save-request__modal-footer__save-btn.is-focused {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-info);
}

.request-meta-headers {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
}

.request-meta-successful {
  color: var(--content-color-success);
}

.request-meta-unsuccessful {
  color: var(--content-color-error);
}

.request-history-dropdown-options-menu-list, .request-history-dropdown-options-menu-button {
  z-index: 10000;
}

.example-response-list--wrapper {
  position: relative;
  line-height: var(--line-height-m);
  font-size: var(--text-size-m);
}
.example-response-list--wrapper .dropdown-caret {
  margin-left: var(--spacing-zero);
}
.example-response-list__button {
  padding: var(--spacing-zero);
  height: auto;
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.example-response-list__button .example-button__badge {
  background: var(--background-color-tertiary);
  border-radius: 2px;
  font-size: var(--text-size-xs);
  line-height: var(--line-height-s);
  margin: var(--spacing-zero) var(--spacing-xs);
  min-width: 16px;
  padding: var(--spacing-zero) var(--spacing-xs);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.example-response-list__button .example-button__caret {
  margin: unset;
  float: unset;
}
.example-response-list__button .example-button__caret use {
  fill: var(--content-color-secondary);
}
.example-response-list__button .example-button__caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.example-response-list__button .example-button__caret svg {
  color: var(--content-color-secondary);
}
.example-response-list__button:active, .example-response-list__button.is-active {
  color: var(--content-color-primary);
}
.example-response-list__button:active .example-button__caret use, .example-response-list__button.is-active .example-button__caret use {
  fill: var(--content-color-primary);
}
.example-response-list__button:active .example-button__caret svg:not([class*=PartnerIcon]) > path[fill], .example-response-list__button.is-active .example-button__caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.example-response-list__button:active .example-button__caret svg, .example-response-list__button.is-active .example-button__caret svg {
  color: var(--content-color-primary);
}

.example-response-list--menu {
  width: 320px;
  background-color: var(--background-color-secondary);
  z-index: 12;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  text-align: center;
  padding: var(--spacing-xs) var(--spacing-zero);
  word-break: break-word;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-default);
}
.example-response-list--menu.empty-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  align-items: center;
  padding: var(--spacing-s);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.example-response-list--menu.empty-list .add-new-example {
  width: initial;
  margin-left: var(--spacing-zero);
}
.example-response-list--menu.empty-list .example-response-learn-more {
  padding: var(--spacing-zero);
}
.example-response-list--menu.empty-list .example-response-list--title {
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-s) var(--spacing-l);
  margin-top: var(--spacing-s);
}
.example-response-list--menu.empty-list .example-response-list--body {
  padding: var(--spacing-s) var(--spacing-l);
}
.example-response-list--menu.empty-list .example-response-list--add-button {
  padding: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}
.example-response-list--menu.empty-list .example-response-list--permission-denied {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
  padding: var(--spacing-l);
}
.example-response-list--menu .add-new-example {
  width: 100%;
  height: 28px;
  justify-content: flex-start;
  padding: var(--spacing-xs) var(--spacing-s);
}
.example-response-list--menu .add-new-example.example-list {
  color: var(--content-color-primary);
  border-radius: 0;
}
.example-response-list--menu .add-new-example.example-list:hover, .example-response-list--menu .add-new-example.example-list.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.example-response-list--menu .example-response-list--item {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-s);
  position: relative;
}
.example-response-list--menu .example-response-list--item:hover .example-response-list--actions, .example-response-list--menu .example-response-list--item.is-hovered .example-response-list--actions {
  display: flex;
  cursor: pointer;
}
.example-response-list--menu .example-response-list--item.add-example {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.example-response-list--menu .example-response-list--actions {
  display: none;
}
.example-response-list--menu .example-response-list--name {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  text-align: left;
  line-height: var(--line-height-m);
}
.example-response-list--menu .example-response-list--label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.example-response-list--menu .example-response-list--delete {
  display: flex;
  cursor: pointer;
}
.example-response-list--menu .example-response-list--delete:hover use, .example-response-list--menu .example-response-list--delete.is-hovered use {
  fill: var(--base-color-error);
}
.example-response-list--menu .example-response-list--delete:hover svg:not([class*=PartnerIcon]) > path[fill], .example-response-list--menu .example-response-list--delete.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.example-response-list--menu .example-response-list--delete:hover svg, .example-response-list--menu .example-response-list--delete.is-hovered svg {
  color: var(--base-color-error);
}
.example-response-list--menu .example-response-list--delete:active use, .example-response-list--menu .example-response-list--delete.is-active use {
  fill: var(--base-color-error);
}
.example-response-list--menu .example-response-list--delete:active svg:not([class*=PartnerIcon]) > path[fill], .example-response-list--menu .example-response-list--delete.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.example-response-list--menu .example-response-list--delete:active svg, .example-response-list--menu .example-response-list--delete.is-active svg {
  color: var(--base-color-error);
}

.request-editor-snippet-generator-modal-content {
  display: flex;
  overflow: auto;
  padding: 0;
}

.request-editor-snippet-generator-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.request-editor-snippet-generator-options-settings-content {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-l);
  overflow-y: auto;
  flex: 1;
  flex-shrink: 0;
  margin-top: var(--spacing-s);
}

.request-editor-snippet-generator-editor-options-pane {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.request-editor-snippet-generator-editor-options-pane .request-editor-snippet-generator-header {
  display: flex;
  flex-direction: row;
  background-color: var(--background-color-primary);
  box-shadow: var(--shadow-default);
  border-radius: 0px;
  z-index: 2;
}
.request-editor-snippet-generator-editor-options-pane .request-editor-snippet-generator-header .request-editor-snippet-generator-header-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-left: var(--spacing-s);
}
.request-editor-snippet-generator-editor-options-pane .request-editor-snippet-generator-header .request-editor-snippet-generator-header__section-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.request-editor-snippet-generator-editor-options-pane .request-editor-snippet-generator-editor {
  flex: 1;
  display: flex;
}
.request-editor-snippet-generator-editor-options-pane .request-editor-snippet-generator-editor .request-editor-snippet-generator-text-editor {
  border: none;
}

.request-editor-snippet-generator-options {
  display: flex;
  flex-direction: column;
}

.snippet-wrapper {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  width: 250px;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: var(--text-size-s);
  overflow-y: scroll;
  scrollbar-width: none;
}

.snippet-header {
  color: var(--content-color-secondary);
  display: flex;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.snippet-header__help {
  margin-bottom: var(--spacing-s);
}
.snippet-header__help__text {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  font-family: var(--text-family-default);
}
.snippet-header__help__link {
  padding-left: var(--spacing-zero);
  font-size: var(--text-size-s);
}
.snippet-header__title {
  flex: 1;
  align-self: center;
  color: var(--content-color-secondary);
}
.snippet-header__left {
  margin-bottom: var(--spacing-xs);
}
.snippet-header .snippet-header__left {
  width: calc(100% - 36px);
}
.snippet-header .snippet-header__right {
  position: absolute;
  top: var(--spacing-xs);
  right: 0;
  z-index: 10;
}
.snippet-header.ai-enabled .snippet-header__help {
  margin-right: var(--spacing-m);
}

.snippet-items {
  flex: 1;
  min-height: 50%;
}

.snippet-item {
  padding: var(--spacing-xs) var(--spacing-s);
  padding-left: var(--spacing-zero);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  box-sizing: border-box;
  font-family: var(--text-family-default);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.snippet-toggle-button {
  height: var(--size-m);
  width: var(--size-m);
}

/**
 * Styling for `RequestConfigurationList` component
 */
.request-configuration-list {
  padding: var(--spacing-s) var(--spacing-zero);
}

.request-configuration-unavailable {
  margin: var(--spacing-l);
}

.request-header-editor-container .headers-editor__value-editor {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}
.request-header-editor-container .headers-editor__value-editor .key-value-form-editor {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.request-urlparams-editor-container .urlparams-editor__params, .request-urlparams-editor-container .urlparams-editor__path-variables {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}
.request-urlparams-editor-container .urlparams-editor__params .key-value-form-editor, .request-urlparams-editor-container .urlparams-editor__path-variables .key-value-form-editor {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default) !important;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.request-urlparams-editor-container .urlparams-editor__params .key-value-form-editor-sortable__selectable, .request-urlparams-editor-container .urlparams-editor__path-variables .key-value-form-editor-sortable__selectable {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.draft-request-source-badge {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-s);
  flex: 1;
  min-width: 100px;
  overflow: hidden;
  max-width: 240px;
}
.draft-request-source-badge::before {
  content: "•";
  margin-right: var(--spacing-s);
  margin-top: -2px;
  font-size: 14px;
  color: var(--content-color-tertiary);
}
.draft-request-source-badge__item {
  margin-left: var(--spacing-xs);
  display: flex;
  align-items: center;
  min-width: 0;
}
.draft-request-source-badge__item.is-clickable {
  cursor: pointer;
}
.draft-request-source-badge__icon {
  display: flex;
  justify-content: center;
}
.draft-request-source-badge__name {
  min-width: 0;
  margin-left: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.response-viewer-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  height: 100%;
  overflow: auto;
}

.response-viewer-wrapper--hidden {
  visibility: hidden;
}

.response-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.response-viewer .requester-contents-group__section-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.response-viewer .response-viewer__tab-contents {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.layout-2-column .response-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 0px;
}
.layout-2-column .response-viewer .requester-contents-group {
  flex: 1;
  height: 100%;
}

.response-viewer__section-top {
  display: flex;
  justify-content: space-between;
}
.response-viewer__section-top .response-viewer__tabs {
  height: var(--size-m);
}
.response-viewer__section-top .response-viewer__meta {
  flex: 1;
  height: var(--size-m);
}

.response-viewer__tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.response-viewer__meta {
  flex-direction: row-reverse;
  min-width: 0;
  align-self: center;
}
.response-viewer__meta .input-field {
  align-items: center;
  flex: none;
}

.request-loading-wrapper {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.request-loading-wrapper--hidden {
  display: none;
}

.request-loading__message {
  font-family: var(--text-family-default);
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
}

.request-loading__cancel-button {
  margin-top: var(--spacing-l);
}

.response-viewer-empty {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.response-viewer-empty__header {
  flex: 0 0 32px;
  background-color: var(--background-color-primary);
  border-style: solid;
  border-width: var(--border-width-default) 0 var(--border-width-default) 0;
  border-color: var(--border-color-default);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
  display: flex;
  align-items: center;
  padding-left: var(--spacing-s);
}
.layout-2-column .response-viewer-empty__header {
  border-top-width: 0;
}
.response-viewer-empty__content {
  flex: 1;
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
}
.response-viewer-empty__content__editor {
  flex: 1;
  margin: var(--spacing-s);
  display: flex;
  flex-direction: row;
}
.response-viewer-empty__content__editor__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.response-viewer-empty__onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.response-viewer-empty__onboarding__heading {
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  margin-top: var(--spacing-l);
}
.response-viewer-empty__onboarding-recommendation-viewer {
  position: sticky;
  margin: var(--spacing-zero) var(--spacing-l);
  bottom: 16px;
}

.onboarding-intro-video-modal .modal-content {
  padding: var(--spacing-zero);
  overflow: hidden;
}

.response-viewer-tabs .tab {
  font-size: 12px;
}
.response-viewer-tabs .dropdown-label {
  font-weight: var(--text-weight-medium);
  white-space: pre;
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.response-viewer-tabs .dropdown-caret.pm-icon {
  margin-left: var(--spacing-xs);
}

.response-viewer-tabs-content-count {
  margin-left: var(--spacing-xs);
  color: var(--content-color-success);
}
.response-viewer-tabs-content-count.response-viewer-tabs-test-failed-count {
  color: var(--content-color-error);
}
.response-viewer-tabs-content-count.response-viewer-security-tabs-count {
  color: var(--content-color-error);
}

.response-viewer-tabs-test-error-badge {
  display: inline-flex;
  width: 6px;
  height: 6px;
  margin-left: var(--spacing-xs);
  background-color: var(--base-color-error);
  border-radius: 6px;
}

.response-viewer-tab-contents {
  flex: 1 auto;
  display: flex;
  padding: var(--spacing-s) var(--spacing-l);
  padding-left: var(--spacing-l);
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}
.response-viewer-tab-contents:has(.refresh-tests-button-wrapper), .response-viewer-tab-contents:has(.response-tests-viewer) {
  padding-top: var(--spacing-xs);
  padding-inline: var(--spacing-s) var(--spacing-l);
}
.response-viewer-tab-contents:has(.response-cookies-table), .response-viewer-tab-contents:has(.response-header-viewer__table), .response-viewer-tab-contents:has(.response-header-editor) {
  padding-inline: var(--spacing-l) var(--spacing-xs);
  overflow: auto;
  scrollbar-gutter: stable;
}
.response-viewer-tab-contents .response-viewer-tab-content {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.response-viewer-tab-content.is-hidden {
  display: none;
}

.response-cannot-render-message {
  padding: var(--spacing-l);
  text-align: center;
}

.response-body-text-viewer,
.response-body-media-viewer {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
}
.response-body-text-viewer.disabled, .response-body-text-viewer.waiting,
.response-body-media-viewer.disabled,
.response-body-media-viewer.waiting {
  pointer-events: none;
  opacity: 0.2;
}

.response-body-viewer-tab-contents {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.response-body-viewer-tab-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.response-body-viewer-tab-content.is-hidden {
  display: none;
}

.response-body-pretty-viewer {
  flex: 1;
  display: flex;
  min-height: 0;
}
.response-body-pretty-viewer .editor {
  flex: 1;
}

.response-body-raw-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.response-body-raw-viewer .textarea-wrapper {
  display: flex;
  flex: 1;
}
.response-body-raw-viewer .textarea {
  flex: 1;
  resize: none;
}

.response-body-visualizer-empty {
  display: flex;
  margin: auto var(--spacing-zero);
  flex-direction: column;
  align-items: center;
  color: var(--content-color-primary);
  text-align: center;
  gap: var(--spacing-s);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.response-body-visualizer-empty__description {
  max-width: 470px;
}

.response-body-iframe-viewer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--content-color-constant);
  border-radius: var(--border-radius-default);
  min-height: 0;
}
.response-body-iframe-viewer .response-body-viewer-preview {
  flex: 1;
  width: 100%;
  border: 0;
  min-height: 0;
}

.response-visualizer-viewer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
.response-visualizer-viewer .response-visualizer-webview-viewer__webview {
  flex: 1;
  border: 0;
}

.response-body-viewer-tabs-wrapper {
  display: flex;
}
.response-body-viewer-tabs-wrapper .response-body-viewer__language-dropdown, .response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button {
  margin-left: var(--spacing-s);
}
.response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button {
  background-color: var(--background-color-tertiary);
}
.response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button:hover, .response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button:active, .response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button.is-active {
  background-color: var(--background-color-tertiary);
}
.response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button:active use, .response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button.is-active use {
  fill: var(--base-color-brand);
}
.response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button:active svg:not([class*=PartnerIcon]) > path[fill], .response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button.is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button:active svg, .response-body-viewer-tabs-wrapper .response-body-viewer__line-wrap-button.is-active svg {
  color: var(--base-color-brand);
}

.response-header-viewer__infobar {
  background-color: var(--background-color-secondary);
  color: var(--content-color-primary);
}
.response-header-viewer__infobar .infobar__dismiss_icon use {
  fill: var(--content-color-tertiary);
}
.response-header-viewer__infobar .infobar__dismiss_icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.response-header-viewer__infobar .infobar__dismiss_icon svg {
  color: var(--content-color-tertiary);
}
.response-header-viewer__infobar .response-header__infobar-icon {
  margin-right: var(--spacing-s);
}
.response-header-viewer__infobar .response-header__infobar-icon use {
  fill: var(--content-color-tertiary);
}
.response-header-viewer__infobar .response-header__infobar-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.response-header-viewer__infobar .response-header__infobar-icon svg {
  color: var(--content-color-tertiary);
}

.response-header-viewer {
  padding-bottom: var(--spacing-xs);
  overflow-y: auto;
  height: 100%;
}

.response-header-empty-message {
  margin: auto;
  text-align: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.response-cookie-viewer {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  flex: 1;
}
.response-cookie-viewer .cookie-table {
  min-width: 400px;
}
.response-cookie-viewer .cookie-table-header {
  display: flex;
  padding: var(--spacing-xs) var(--spacing-zero);
  justify-content: space-around;
  border-bottom: var(--border-width-l) var(--border-style-solid) var(--border-color-default);
}
.response-cookie-viewer .cookie-table-header__cookie-detail {
  width: 100px;
}
.response-cookie-viewer .cookie-table-content {
  padding: var(--spacing-zero);
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.response-cookie-viewer .cookie-table-content .cookie-table-content-row {
  padding: var(--spacing-zero);
  display: flex;
  padding-bottom: var(--spacing-s);
  word-wrap: break-word;
  justify-content: space-around;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  height: 30px;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.response-cookie-viewer .cookie-table-content .cookie-table-content-row__cookie-detail {
  width: 100px;
  padding-top: var(--spacing-s);
}
.response-cookie-viewer .cookie-table-content .cookie-table-content-row__cookie-detail .DraftEditor-root {
  width: 100%;
}
.response-cookie-viewer .cookie-table-content .cookie-table-content-row__cookie-detail .DraftEditor-editorContainer {
  padding: 3px;
}
.response-cookie-viewer .cookie-table-content .cookie-table-content-row__cookie-detail .is-focused .DraftEditor-root {
  background: var(--background-color-primary);
}
.response-cookie-viewer .cookie-table-content .cookie-table-content-row__cookie-detail .is-focused .DraftEditor-editorContainer {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: 3px;
}
.response-cookie-viewer .cookie-table-content .is-selected {
  background-color: var(--background-color-secondary);
}

.response-cookies-unavailable {
  align-items: center;
  margin: auto;
}
.response-cookies-unavailable__icon {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/cookie.svg");
}
.response-cookies-unavailable__message {
  text-align: center;
  width: 416px;
}

.response-tests-viewer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.response-tests-viewer-tabs {
  padding-bottom: var(--spacing-m);
}

.response-tests-empty-message {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  text-align: center;
  margin: auto;
}

.response-tests-list {
  flex: 1;
  overflow-y: auto;
  font-size: var(--text-size-m);
}
.response-tests-list .response-test-item {
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-s);
}

.response-test-status {
  min-width: 60px;
  padding: var(--spacing-xs) var(--spacing-zero);
  margin-right: var(--spacing-s);
  font-size: var(--text-size-m);
  font-weight: 500;
  color: var(--content-color-constant);
  text-transform: uppercase;
  border-radius: var(--border-radius-default);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.response-test-status.pass {
  background-color: var(--base-color-success);
}
.response-test-status.fail {
  background-color: var(--base-color-error);
}
.response-test-status.skipped {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
}

.response-test-text {
  color: var(--content-color-primary);
  align-self: center;
}

.response-tests-error-container {
  padding: var(--spacing-s);
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  text-align: center;
  background-color: var(--background-color-error);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.response-tests-error-container .response-tests-error-message {
  font-weight: var(--text-weight-medium);
}

.response-meta-viewer {
  display: flex;
  align-items: center;
  height: var(--size-m);
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  justify-content: flex-end;
  line-height: var(--line-height-m);
  overflow: hidden;
  padding-right: var(--spacing-l);
}
.response-meta-viewer .response-meta-item-name {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.editor-pane-response-viewer.small .response-meta-viewer .response-meta-item-name {
  display: none;
}
.response-meta-viewer .response-meta-items {
  display: flex;
  flex: 1;
  overflow: hidden;
  justify-content: flex-end;
}
.response-meta-viewer .response-meta-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.response-meta-viewer .response-meta-item:not(:last-child) {
  margin-right: var(--spacing-s);
}
.response-meta-viewer .response-meta-item:not(:last-child)::after {
  content: "";
  margin-left: var(--spacing-s);
  height: var(--border-width-xl);
  width: var(--border-width-xl);
  min-height: var(--border-width-xl);
  min-width: var(--border-width-xl);
  border-radius: var(--border-width-xl);
  background-color: var(--border-color-strong);
  display: inline-flex;
  vertical-align: middle;
}
.response-meta-viewer .response-meta-item:not(:last-child).status-indicator::after {
  content: none;
}
.response-meta-viewer .response-meta-item.is-collapsed:not(:last-child) {
  margin-right: var(--spacing-m);
}
.response-meta-viewer .response-meta-item.is-collapsed:not(:last-child)::after {
  content: none;
}
.response-meta-viewer .response-meta-item.status-indicator:last-child {
  padding-right: 0;
}
.response-meta-viewer .response-meta-item:last-child:not(:only-of-type)::after {
  content: "";
  margin-left: var(--spacing-s);
  height: 12px;
  width: 1px;
  background-color: var(--border-color-strong);
  display: inline-flex;
  vertical-align: middle;
}
.response-meta-viewer .response-meta-item__status {
  flex: 0 1 auto;
  overflow: hidden;
  justify-content: flex-end;
}
.response-meta-viewer .response-meta-item.small {
  margin-right: var(--spacing-s);
}
.response-meta-viewer .response-meta-item.is-tooltip-container .response-meta-item-name .response-meta-status-code-desc,
.response-meta-viewer .response-meta-item.is-tooltip-container .response-meta-item-value .response-meta-status-code-desc {
  padding-left: var(--spacing-xs);
}
.response-meta-viewer .response-meta-item.is-tooltip-container .response-meta-size__total.max-reached {
  color: var(--content-color-error);
}
.response-meta-viewer .response-meta-item.response-network-meta {
  display: flex;
}
.response-meta-viewer .response-meta-item.response-network-meta .network-icon use {
  fill: var(--content-color-secondary);
}
.response-meta-viewer .response-meta-item.response-network-meta .network-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.response-meta-viewer .response-meta-item.response-network-meta .network-icon svg {
  color: var(--content-color-secondary);
}
.response-meta-viewer .response-meta-item.response-network-meta .network-icon.warning use {
  fill: var(--base-color-error);
}
.response-meta-viewer .response-meta-item.response-network-meta .network-icon.warning svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.response-meta-viewer .response-meta-item.response-network-meta .network-icon.warning svg {
  color: var(--base-color-error);
}
.response-meta-viewer .response-meta-item-value {
  color: var(--content-color-success);
}
.response-meta-viewer .response-meta-item-value .is-large,
.response-meta-viewer .response-meta-item-value .is-unsuccessful {
  color: var(--content-color-error);
}
.response-meta-viewer .response-meta-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.response-details__unavailable {
  width: 290px;
}
.response-details__unavailable .response-details__unavailable__body {
  margin: var(--spacing-s);
}
.response-details__unavailable .response-details__unavailable__body .response-details__unavailable__title {
  color: var(--content-color-primary);
}
.response-details__unavailable .response-details__unavailable__body .response-details__unavailable__message {
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}

.network-meta__tooltip {
  max-width: 400px;
}
.network-meta__tooltip .network-meta__body {
  width: 330px;
  margin: var(--spacing-s);
  font-family: var(--text-family-default);
}
.network-meta__tooltip .network-meta__body .network-meta__header {
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.network-meta__tooltip .network-meta__body .network-meta__item {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-size-m);
  height: var(--size-s);
  align-items: center;
}
.network-meta__tooltip .network-meta__body .network-meta__item .item__label {
  width: 30%;
  color: var(--content-color-secondary);
}
.network-meta__tooltip .network-meta__body .network-meta__item .item__value {
  width: 70%;
  box-sizing: border-box;
  padding-left: var(--spacing-xxl);
  color: var(--content-color-primary);
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.network-meta__tooltip .network-meta__body .network-meta__warning {
  background-color: var(--background-color-error);
  padding: var(--spacing-s) var(--spacing-zero);
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  margin-top: var(--spacing-l);
}
.network-meta__tooltip .network-meta__body .network-meta__warning .warning__icon {
  margin-left: var(--spacing-l);
}
.network-meta__tooltip .network-meta__body .network-meta__warning .warning__icon use {
  fill: var(--content-color-brand);
}
.network-meta__tooltip .network-meta__body .network-meta__warning .warning__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.network-meta__tooltip .network-meta__body .network-meta__warning .warning__icon svg {
  color: var(--content-color-brand);
}
.network-meta__tooltip .network-meta__body .network-meta__warning .warning__message {
  margin: var(--spacing-zero) var(--spacing-l);
  color: var(--content-color-error);
  font-size: var(--text-size-m);
}
.network-meta__tooltip .network-meta__divider {
  margin: var(--spacing-s) var(--spacing-zero);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.response-meta-time--tooltip {
  max-width: 400px;
}
.response-meta-time--tooltip .response-meta-time--tooltip_body {
  width: 380px;
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-s);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: var(--line-height-l);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_event {
  font-style: normal;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_prepare {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-tertiary);
  margin-bottom: var(--spacing-s);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_socket {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-primary);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_dns {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-primary);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_tcp {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-primary);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_secureHandshake {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-primary);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_firstByte {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-primary);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_download {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_process {
  font-style: normal;
  font-weight: normal;
  color: var(--content-color-tertiary);
  margin-bottom: var(--spacing-s);
}
.response-meta-time--tooltip .response-meta-time--tooltip_row_total {
  font-style: normal;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-success);
}
.response-meta-time--tooltip .response-meta-time--phase_name {
  width: 120px;
}
.response-meta-time--tooltip .response-meta-time--phase_graph {
  width: 190px;
}
.response-meta-time--tooltip .response-meta-time--phase_value {
  width: 70px;
  text-align: right;
}
.response-meta-time--tooltip .response-meta-time--phase_value_cached {
  color: var(--content-color-tertiary);
}
.response-meta-time--tooltip .response-meta-time--phase_graph_bar {
  height: var(--size-xs);
  background: var(--content-color-success);
  margin: var(--spacing-xs) var(--spacing-zero);
}
.response-meta-time--tooltip .response-meta-time--phase_graph_bar_prepare {
  background: var(--response-meta-time-graph-system-bar-background-color);
}
.response-meta-time--tooltip .response-meta-time--phase_graph_bar_process {
  background: var(--response-meta-time-graph-system-bar-background-color);
}
.response-meta-time--tooltip .response-meta-time--phase_graph_separator {
  height: 220px;
  background-color: var(--border-color-default);
  width: 1px;
  position: absolute;
  margin-top: var(--spacing-xl);
  z-index: 1;
}

.response-meta-size--tooltip_body {
  width: 240px;
  font-style: normal;
  font-weight: normal;
  padding: var(--spacing-s);
  padding-bottom: var(--spacing-zero);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.response-meta-size--tooltip_section {
  padding-bottom: var(--spacing-l);
  padding-top: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  gap: var(--spacing-m);
}
.response-meta-size--tooltip_section:first-child {
  padding-top: var(--spacing-zero);
}

.response-meta-size--tooltip_body_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}

.response-meta-size_title_primary {
  font-style: normal;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  line-height: var(--line-height-s);
}

.response-meta-size_data_secondary {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}

.response-meta-size_psa {
  margin-top: var(--spacing-s);
  color: var(--content-color-tertiary);
}

.response-meta-size_warning {
  display: flex;
  margin-top: var(--spacing-m);
  align-items: center;
}
.response-meta-size_warning .response-meta-size_warning_icon {
  margin-right: 6px;
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}
.response-meta-size_warning .response-meta-size_warning_text {
  color: var(--content-color-error);
  padding-left: var(--spacing-xs);
}

.status-selector-container {
  display: flex;
  align-items: center;
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  padding: var(--spacing-zero) var(--spacing-s);
  justify-content: flex-end;
  height: 100%;
}
.status-selector-container .response-status-selector-wrapper {
  margin-left: var(--spacing-m);
}
.status-selector-container .response-status-selector-wrapper.response-custom {
  position: relative;
  display: flex;
}
.status-selector-container .response-status-selector-wrapper.response-custom .input-reset {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  transform: rotate(-180deg);
  position: absolute;
  top: 10px;
  left: 10px;
}
.status-selector-container .response-status-selector-wrapper__input {
  height: var(--controls-size-small);
}
.status-selector-container .response-status-selector-wrapper .input-select-list {
  max-height: 210px;
}

.response-meta-action {
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: var(--spacing-xs);
  padding-left: var(--spacing-s);
}
.response-meta-action__cancel,
.response-meta-action .dropdown-button .btn-text {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.response-meta-action__cancel {
  margin: var(--spacing-zero) var(--spacing-l);
  height: var(--size-m);
}
.response-meta-action .response-save-button {
  white-space: pre;
  padding-inline: var(--spacing-xs);
}
.response-meta-action .response-save-button .dropdown-caret {
  margin-left: var(--spacing-xs);
}
.response-meta-action .response-save-button .dropdown-caret use {
  fill: var(--content-color-link);
}
.response-meta-action .response-save-button .dropdown-caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.response-meta-action .response-save-button .dropdown-caret svg {
  color: var(--content-color-link);
}
.response-meta-action .dropdown-button {
  display: flex;
  align-items: center;
}

.response-meta__tooltip .tooltip-wrapper {
  background: var(--background-color-primary);
}

.response-error-viewer {
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.response-error-viewer__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-primary);
  overflow: auto;
}
.response-error-viewer__content__editor {
  flex: 1;
  margin: var(--spacing-s);
  display: flex;
  flex-direction: row;
}
.response-error-viewer__content__editor__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.response-error-viewer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.response-error-viewer__image {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/astronaut.svg");
}
.response-error-viewer__heading {
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
}
.response-error-viewer__error {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  margin-top: var(--spacing-l);
  display: flex;
  flex-direction: column;
}
.response-error-viewer__error-item {
  position: relative;
  padding: var(--spacing-xs) var(--spacing-s);
  background-color: var(--background-color-error);
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin: var(--spacing-m) var(--spacing-xxxl) var(--spacing-zero) var(--spacing-xxxl);
  justify-content: center;
  align-self: center;
}
.response-error-viewer__error-item__description {
  padding: var(--spacing-zero) var(--spacing-s);
  text-align: center;
  word-break: break-all;
  white-space: pre-wrap;
}
.response-error-viewer__error-item__action {
  border-radius: 0;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-active);
  color: var(--content-color-brand);
}
.response-error-viewer__error-item__action:hover, .response-error-viewer__error-item__action.is-hovered {
  opacity: 0.6;
}
.response-error-viewer__error-item__action__postbot {
  height: unset !important;
}
.response-error-viewer__error-item__action__postbot:hover {
  background-color: unset !important;
}
.response-error-viewer__link {
  margin-top: var(--spacing-l);
  color: var(--content-color-link);
  font-size: var(--text-size-m);
}

.response-body-image-viewer {
  flex: 1;
  position: relative;
  display: flex;
}

.response-body-image-viewer-image-container {
  flex: 1;
  box-sizing: border-box;
  border: none;
  min-width: 0;
}

.response-body-document-viewer {
  flex: 1;
  padding: 10px;
  position: relative;
  display: flex;
  background-color: var(--background-color-tertiary);
  justify-content: center;
  min-width: fit-content;
}

.response-loader.collapsed {
  opacity: 0;
}

.response-loading-cta,
.response-large-cta {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.response-loading-cta .response-loading-message,
.response-loading-cta .response-large-message,
.response-large-cta .response-loading-message,
.response-large-cta .response-large-message {
  color: var(--content-color-primary);
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.response-loading-cta .response-loading-message,
.response-large-cta .response-loading-message {
  margin-top: 90px;
}
.response-loading-cta .response-large-message,
.response-large-cta .response-large-message {
  margin-top: 50px;
}
.response-loading-cta .response-large-summary,
.response-large-cta .response-large-summary {
  color: var(--content-color-primary);
  margin-top: var(--spacing-s);
  font-style: normal;
  font-weight: normal;
  font-size: var(--spacing-m);
  line-height: var(--line-height-m);
  text-align: center;
}
.response-loading-cta .response-download-button,
.response-loading-cta .response-cancel-button,
.response-large-cta .response-download-button,
.response-large-cta .response-cancel-button {
  margin-top: var(--spacing-xl);
  height: var(--size-m);
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.response-loading-cta .response-cancel-button,
.response-large-cta .response-cancel-button {
  width: 72px;
}
.response-loading-cta .response-download-button,
.response-large-cta .response-download-button {
  width: 240px;
}
.response-loading-cta .response-open-settings,
.response-large-cta .response-open-settings {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-top: var(--spacing-s);
}

.response-large-cta {
  margin-top: 40px;
}

.layout-2-column .response-large-cta {
  margin-top: 80px;
}

.response-body-text-viewer__header {
  display: flex;
  flex-direction: row;
}
.response-body-text-viewer__header__left {
  display: flex;
  flex: 0 0 auto;
}
.response-body-text-viewer__header__right {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.response-body-text-viewer__content {
  padding-top: var(--spacing-s);
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.response-body-text-viewer__search-button {
  margin-right: 10px;
  cursor: pointer;
}
.response-body-text-viewer__copy-button {
  margin-right: 10px;
  cursor: pointer;
}
.response-body-text-viewer .tabs.tabs-secondary {
  height: 32px;
}
.response-body-text-viewer .dropdown-button .btn-secondary {
  box-shadow: none;
  font-weight: var(--text-weight-regular);
  background-color: var(--highlight-background-color-tertiary);
}
.response-body-text-viewer .dropdown-button .btn-secondary:hover {
  background-color: var(--button-secondary-hover-background-color);
}
.response-body-text-viewer .dropdown-button .btn-secondary.is-active {
  background-color: var(--button-secondary-active-background-color);
}

.key-value-form-enable-all-checkbox {
  display: flex;
  align-items: center;
}

.key-value-form-header-row {
  font-size: var(--text-size-m);
  font-family: var(--font-family-default);
}
.key-value-form-header-row .header-row {
  margin-bottom: var(--spacing-zero);
}
.key-value-form-header-row .header-row .auto-suggest-group > :last-child {
  border-right: 0;
}
.key-value-form-header-row .header-row:hover, .key-value-form-header-row .header-row.is-hovered {
  background-color: transparent;
}
.key-value-form-header-row .header-row .key-value-form-actions {
  border-top-width: 0;
}
.key-value-form-header-row .header-row .key-value-form-actions .auto-suggest {
  display: none;
}
.key-value-form-header-row .header-row .key-value-form-actions.hide-column {
  display: none;
}
.key-value-form-header-row .header-row .key-value-form-header-item {
  overflow: hidden;
  align-items: center;
  display: flex;
  position: relative;
  box-sizing: border-box;
  border-right: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);
  margin: var(--spacing-zero);
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-non-editable {
  background-color: var(--background-color-secondary);
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-sort-enabled {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-sort-enabled .header-content {
  cursor: pointer;
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-sort-enabled:hover {
  background-color: var(--background-color-tertiary);
  border-color: var(--border-color-strong);
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-sort-enabled:hover .header-content {
  color: var(--content-color-primary);
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-sort-enabled:active {
  background-color: var(--highlight-background-color-secondary);
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-sort-active {
  background-color: var(--background-color-secondary);
  border-color: var(--border-color-strong);
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-sort-active .header-content {
  color: var(--content-color-primary);
}
.key-value-form-header-row .header-row .key-value-form-header-item .header-content {
  padding: var(--spacing-s);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  transition: color 0.2s ease-in-out;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.key-value-form-header-row .header-row .key-value-form-header-item .key-value-form-header-item-tooltip-wrapper {
  align-items: center;
  height: 12px;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  overflow: hidden;
  width: 0;
}
.key-value-form-header-row .header-row .key-value-form-header-item .key-value-form-header-item-tooltip-wrapper .key-value-form-header-item-tooltip {
  display: flex;
  flex: 0;
  padding: var(--spacing-zero);
}
.key-value-form-header-row .header-row .key-value-form-header-item .key-value-form-header-item-tooltip-wrapper .key-value-form-header-item-tooltip use {
  fill: var(--content-color-secondary);
}
.key-value-form-header-row .header-row .key-value-form-header-item .key-value-form-header-item-tooltip-wrapper .key-value-form-header-item-tooltip svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.key-value-form-header-row .header-row .key-value-form-header-item .key-value-form-header-item-tooltip-wrapper .key-value-form-header-item-tooltip svg {
  color: var(--content-color-secondary);
}
.key-value-form-header-row .header-row .key-value-form-header-item:hover .key-value-form-header-item-tooltip-wrapper {
  margin-right: var(--spacing-xs);
  opacity: 1;
  visibility: visible;
  overflow: visible;
  width: auto;
}
.key-value-form-header-row .header-row .key-value-form-header-item .key-value-form-header-item__disabled-sync-icon {
  cursor: default;
}
.key-value-form-header-row .header-row .key-value-form-header-item.is-first-column:nth-child(1) {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls {
  max-width: fit-content;
  color: var(--content-color-brand);
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 5px;
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .preset-selector {
  flex: 1;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .preset-selector .btn-text {
  color: var(--content-color-primary);
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .preset-selector .caret {
  float: right;
  margin-left: var(--spacing-xs);
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .preset-selector .caret use {
  fill: var(--content-color-primary);
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .preset-selector .caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .preset-selector .caret svg {
  color: var(--content-color-primary);
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .key-value-column-toggle {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .key-value-column-toggle .dropdown-button {
  display: flex;
}
.key-value-form-header-row .header-row .bulk-editor-preset__controls .goto-bulk-editor, .key-value-form-header-row .header-row .bulk-editor-preset__controls .key-value-control-persist-all, .key-value-form-header-row .header-row .bulk-editor-preset__controls .key-value-control-reset-all {
  flex: 1;
  white-space: pre;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.key-value-form-header-row .header-row .key-value-control-persist-all.is-disabled, .key-value-form-header-row .header-row .key-value-control-reset-all.is-disabled {
  opacity: 0.4;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.key-value-form-header-row .header-row .resize-handle {
  height: 32px;
  position: absolute;
  top: 0;
  width: 16px;
  left: -8px;
  cursor: col-resize;
}

.key-value-column-toggle--menu use {
  fill: var(--content-color-primary);
}
.key-value-column-toggle--menu svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.key-value-column-toggle--menu svg {
  color: var(--content-color-primary);
}
.key-value-column-toggle--menu:hover use {
  fill: var(--content-color-primary);
}
.key-value-column-toggle--menu:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.key-value-column-toggle--menu:hover svg {
  color: var(--content-color-primary);
}
.key-value-column-toggle--menu .key-value-column-toggle__menu-item {
  padding: var(--spacing-zero) var(--spacing-s);
}
.key-value-column-toggle--menu .key-value-column-toggle__menu-item .key-value-column-toggle--option {
  font-size: var(--text-size-m);
  display: flex;
  cursor: pointer;
}
.key-value-column-toggle--menu .key-value-column-toggle__label {
  font-size: var(--text-size-xs);
  color: var(--content-color-secondary);
  display: flex;
  padding: 6px var(--spacing-xs);
  margin-left: 5px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.key-value-column-toggle--menu .input-checkbox {
  margin-right: var(--spacing-s);
}

@keyframes fadeOut {
  0% {
    filter: grayscale(0%);
  }
  25% {
    filter: grayscale(25%);
  }
  50% {
    filter: grayscale(50%);
  }
  75% {
    filter: grayscale(75%);
  }
  100% {
    filter: grayscale(100%);
    background-color: transparent;
  }
}
.key-value-form-row {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1 1 auto;
  height: 32px;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);
}
.key-value-form-row__is-highlighted {
  background-color: var(--background-color-info);
  animation: fadeOut 1.2s linear 1;
}
.key-value-form-row__is-highlighted .key-value-form-row__modification-options {
  background-color: var(--background-color-info);
  animation: fadeOut 1.2s linear 1;
}
.key-value-form-row__is-highlighted .variable-type-dropdown .aether-dropdown__control {
  background-color: var(--background-color-info);
  animation: fadeOut 1.2s linear 1;
}
.key-value-form-row__is-highlighted .options-dropdown .aether-dropdown__control {
  background-color: var(--background-color-info);
  animation: fadeOut 1.2s linear 1;
}
.key-value-form-row__is-highlighted :hover .key-value-form-row__modification-options {
  background-color: transparent;
}
.key-value-form-row__is-highlighted :hover .variable-type-dropdown .aether-dropdown__control {
  background-color: transparent;
}
.key-value-form-row__is-highlighted :hover .options-dropdown .aether-dropdown__control {
  background-color: transparent;
}
.key-value-form-row .key-value-form-column .auto-suggest-cell {
  padding: var(--spacing-zero) var(--spacing-xs);
}
.key-value-form-row:hover:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-error, .key-value-form-row:hover:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-warning, .key-value-form-row.is-hovered:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-error, .key-value-form-row.is-hovered:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-warning {
  margin-right: var(--spacing-xl);
}
.key-value-form-row .auto-suggest-group {
  position: relative;
}
.key-value-form-row .auto-suggest-group .key-value-form-row__blocked {
  margin: var(--spacing-zero);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 3;
}
.key-value-form-row .auto-suggest-group .input-field {
  padding-inline-start: var(--spacing-xs);
}
.key-value-form-row__draggable {
  display: flex;
  flex: 1;
  width: 100%;
}
.key-value-form-row .input-tooltip.warning .input-warning-tooltip {
  color: var(--content-color-warning);
}
.key-value-form-row .input-tooltip.error .input-warning-tooltip {
  color: var(--content-color-error);
}
.key-value-form-row .input-tooltip.message .input-warning-tooltip {
  color: var(--content-color-primary);
}
.key-value-form-row .input-warning-tooltip {
  max-width: 400px;
  display: none;
  position: absolute;
  top: 32px;
  left: 45px;
  font-size: var(--text-size-xs);
  background-color: var(--border-color-default);
  font-family: var(--text-family-primary);
  padding: var(--spacing-xs) var(--spacing-s);
  border-radius: var(--border-radius-default);
  font-weight: var(--text-weight-medium);
  z-index: 1000;
  box-shadow: var(--shadow-default);
}
.key-value-form-row .input-warning-tooltip:before {
  content: " ";
  position: absolute;
  color: transparent;
  width: 0;
  height: 0;
  top: -7px;
  left: 0px;
  border: 6px var(--border-style-solid);
  border-bottom-color: var(--border-color-default);
  border-left-color: var(--border-color-default);
}
.key-value-form-row:hover:not(.row-focused) {
  background-color: transparent;
}
.key-value-form-row:hover:not(.row-focused) .input-warning-tooltip {
  display: flex;
  align-items: center;
}
.key-value-form-row:hover:not(.row-focused) .key-value-form-column:last-child .auto-suggest-cell {
  padding-right: 30px !important;
}
.key-value-form-row:hover:not(.row-focused).is-file-enabled .key-value-form-column:first-child .auto-suggest-cell {
  padding-right: 60px !important;
}
.key-value-form-row:hover:not(.row-focused) .key-value-form-row__delete,
.key-value-form-row:hover:not(.row-focused) .key-value-form-row__types,
.key-value-form-row:hover:not(.row-focused) .key-value-form-row__sensitive-var-options,
.key-value-form-row:hover:not(.row-focused) .key-value-form-row__deep-link {
  opacity: 1;
}
.key-value-form-row:hover:not(.row-focused) .key-value-form-row__deep-link {
  visibility: visible;
}
.key-value-form-row:hover:not(.row-focused) .always-present i {
  margin-right: 6px;
}
.key-value-form-row:hover:not(.row-focused) .always-present span {
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-form-row:hover:not(.row-focused) .key-value-form-row__sort {
  display: flex;
}
.key-value-form-row:hover:not(.row-focused) .key-value-form-row__add-new-row {
  display: flex;
}
.key-value-form-row.row-focused {
  background-color: var(--background-color-secondary) !important;
}
.key-value-form-row.row-focused .variable-type-dropdown .aether-dropdown__control {
  background-color: var(--background-color-secondary) !important;
}
.key-value-form-row.row-focused .options-dropdown .aether-dropdown__control {
  background-color: var(--background-color-secondary) !important;
}
.key-value-form-row .is-focused input {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background: var(--background-color-primary);
  height: var(--size-s);
  padding-left: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: var(--spacing-s);
  padding-inline-start: var(--spacing-zero);
}
.key-value-form-row.row-selected {
  background-color: var(--background-color-secondary);
}
.key-value-form-row .auto-suggest-group {
  flex: 1;
  min-width: 0;
}
.key-value-form-row .auto-suggest-group .inline-comment-available .auto-suggest.is-comment-enabled:hover, .key-value-form-row .auto-suggest-group .inline-comment-available .key-value-cell.is-comment-enabled:hover {
  border: none;
  cursor: pointer;
}
.key-value-form-row .auto-suggest-group .inline-comment-available__focused .auto-suggest.is-comment-enabled:hover, .key-value-form-row .auto-suggest-group .inline-comment-available__focused .key-value-cell.is-comment-enabled:hover {
  border: none;
  cursor: pointer;
}
.key-value-form-row .auto-suggest-group .auto-suggest {
  margin: var(--spacing-xs) var(--spacing-zero);
}
.key-value-form-row .auto-suggest-group .auto-suggest.is-comment-enabled:hover {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
  margin: var(--spacing-zero);
  padding: 3px var(--spacing-zero);
}
.key-value-form-row .auto-suggest-group .auto-suggest.is-comment-enabled__light:hover {
  cursor: url("../assets/images/thumbnails/comment-cursor-dark.svg"), pointer;
}
.key-value-form-row .auto-suggest-group .auto-suggest.is-comment-enabled__dark:hover {
  cursor: url("../assets/images/thumbnails/comment-cursor-light.svg"), pointer;
}
.key-value-form-row .auto-suggest-group .auto-suggest.is-comment-disabled:hover {
  cursor: not-allowed;
}
.key-value-form-row .auto-suggest-group .key-value-cell.is-comment-enabled:hover {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
  margin: var(--spacing-zero) -1px;
}
.key-value-form-row .auto-suggest-group .key-value-cell.is-comment-enabled__light:hover {
  cursor: url("../assets/images/thumbnails/comment-cursor-dark.svg"), pointer;
}
.key-value-form-row .auto-suggest-group .key-value-cell.is-comment-enabled__dark:hover {
  cursor: url("../assets/images/thumbnails/comment-cursor-light.svg"), pointer;
}
.key-value-form-row .auto-suggest-group .key-value-cell.is-comment-disabled:hover {
  cursor: not-allowed;
}
.key-value-form-row .auto-suggest-group .inline-comment-available .auto-suggest-group .auto-suggest .is-comment-enabled:hover, .key-value-form-row .auto-suggest-group .inline-comment-available .auto-suggest-group .key-value-cell .is-comment-enabled:hover {
  border: none;
  cursor: pointer;
}
.key-value-form-row .auto-suggest-group .last-column {
  border-right: unset !important;
}
.key-value-form-row .auto-suggest-group .is-first-column:nth-child(1) {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-form-row .auto-suggest-group .key-value-form-column {
  position: relative;
  box-sizing: border-box;
  min-width: 0;
  border-right: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);
}
.key-value-form-row .auto-suggest-group .key-value-form-column.variable-type-dropdown .aether-dropdown__control {
  border: none;
  width: 100%;
  box-shadow: none;
}
.key-value-form-row .auto-suggest-group .key-value-form-column.options-dropdown .aether-dropdown__control {
  border: none;
  width: 80%;
  box-shadow: none;
}
.key-value-form-row .auto-suggest-group .key-value-form-column.is-non-editable {
  background-color: var(--background-color-secondary);
}
.key-value-form-row .auto-suggest-group .key-value-form-column.is-non-editable .auto-suggest, .key-value-form-row .auto-suggest-group .key-value-form-column.is-non-editable .key-value-cell, .key-value-form-row .auto-suggest-group .key-value-form-column.is-non-editable .aether-dropdown__single-value--is-disabled {
  color: var(--content-color-secondary);
}
.key-value-form-row .auto-suggest-group .key-value-form-column.is-non-editable .aether-dropdown__control--is-disabled {
  background-color: var(--background-color-secondary);
}
.key-value-form-row .auto-suggest-group .key-value-form-column__sessionValue {
  display: flex;
  justify-content: space-between;
}
.key-value-form-row .auto-suggest-group .key-value-form-column__sessionValue .key-value-cell {
  width: 100%;
}
.key-value-form-row .auto-suggest-group .key-value-form-column .input-field input {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-left: var(--spacing-xs);
}
.key-value-form-row .auto-suggest-group .key-value-form-column .is-focused .DraftEditor-editorContainer {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-form-row .auto-suggest-group .key-value-form-column .is-focused .DraftEditor-root {
  background: var(--background-color-primary);
}
.key-value-form-row .auto-suggest-group .key-value-form-column .public-DraftEditorPlaceholder-root {
  width: 100%;
  color: var(--content-color-tertiary);
}
.key-value-form-row .auto-suggest-group .key-value-form-column .public-DraftEditorPlaceholder-inner {
  white-space: nowrap !important;
  line-height: var(--line-height-m);
  padding: 2px var(--spacing-zero);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.key-value-form-row .auto-suggest-group .key-value-form-column .DraftEditor-editorContainer, .key-value-form-row .auto-suggest-group .key-value-form-column .public-DraftEditorPlaceholder-root {
  padding: 3px var(--spacing-xs);
  border: var(--border-width-default) var(--border-style-solid) transparent;
  box-sizing: border-box;
}
.key-value-form-row .auto-suggest-group .key-value-form-column .public-DraftEditor-content, .key-value-form-row .auto-suggest-group .key-value-form-column .public-DraftEditorPlaceholder-root {
  font-size: var(--text-size-m);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.key-value-form-row .auto-suggest-group .key-value-form-column .DraftEditor-root {
  width: 100%;
}
.key-value-form-row .key-value-form-actions {
  position: relative;
  display: flex;
  width: 44px;
  justify-content: flex-end;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-right: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);
}
.key-value-form-row .key-value-form-actions.is-disabled {
  background-color: var(--background-color-secondary);
}
.key-value-form-row .key-value-form-actions.error .feedback-state {
  width: 3px;
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.key-value-form-row .key-value-form-actions.message .feedback-state {
  width: 3px;
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}
.key-value-form-row .key-value-form-actions .feedback-state {
  height: 30px;
  width: 0;
  position: absolute;
  background-color: var(--border-color-default);
}
.key-value-form-row .key-value-form-actions.hide-column {
  display: none;
}
.key-value-form-row .key-value-form-actions.show-add-new-row-option {
  width: 72px;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
.key-value-form-row__toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-xs);
}
.key-value-form-row__toggle.disabled {
  opacity: 0.4;
}
.key-value-form-row__add-new-row {
  display: none;
}
.key-value-form-row__add-new-row .create-new__btn {
  width: var(--size-s);
  margin-right: 2px;
  margin-left: var(--spacing-xs);
}
.key-value-form-row__sort {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: grab;
  margin-right: var(--spacing-xs);
}
.key-value-form-row__sort__icon {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.key-value-form-row__sort:hover, .key-value-form-row__sort.is-hovered {
  background-color: transparent;
}
.key-value-form-row__sort:hover .key-value-form-row__sort__icon, .key-value-form-row__sort.is-hovered .key-value-form-row__sort__icon {
  cursor: grab;
}
.key-value-form-row__sort:hover .key-value-form-row__sort__icon__disabled, .key-value-form-row__sort.is-hovered .key-value-form-row__sort__icon__disabled {
  cursor: not-allowed;
}
.key-value-form-row__sort:active .key-value-form-row__sort__icon, .key-value-form-row__sort.is-active .key-value-form-row__sort__icon {
  cursor: grabbing;
}
.key-value-form-row.is-dragged {
  opacity: 0.1;
}
.key-value-form-row.is-drop-hovered-top {
  position: relative;
}
.key-value-form-row.is-drop-hovered-top::before {
  background-color: var(--base-color-brand);
  top: 0;
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  z-index: 1;
}
.key-value-form-row.is-drop-hovered-bottom {
  position: relative;
}
.key-value-form-row.is-drop-hovered-bottom::before {
  background-color: var(--base-color-brand);
  bottom: 0;
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  z-index: 1;
}
.key-value-form-row.is-disabled .auto-suggest, .key-value-form-row.is-disabled .key-value-cell, .key-value-form-row.is-disabled input, .key-value-form-row.is-disabled .aether-dropdown__single-value--is-disabled, .key-value-form-row.is-blocked .auto-suggest, .key-value-form-row.is-blocked .key-value-cell, .key-value-form-row.is-blocked input, .key-value-form-row.is-blocked .aether-dropdown__single-value--is-disabled {
  color: var(--content-color-secondary);
}
.key-value-form-row.is-disabled .key-value-form-column.is-non-editable .auto-suggest, .key-value-form-row.is-disabled .key-value-form-column.is-non-editable .key-value-cell, .key-value-form-row.is-disabled .key-value-form-column.is-non-editable input, .key-value-form-row.is-disabled .key-value-form-column.is-non-editable .aether-dropdown__single-value--is-disabled, .key-value-form-row.is-blocked .key-value-form-column.is-non-editable .auto-suggest, .key-value-form-row.is-blocked .key-value-form-column.is-non-editable .key-value-cell, .key-value-form-row.is-blocked .key-value-form-column.is-non-editable input, .key-value-form-row.is-blocked .key-value-form-column.is-non-editable .aether-dropdown__single-value--is-disabled {
  color: var(--content-color-secondary);
}
.key-value-form-row.is-system.is-blocked {
  background-color: var(--background-color-secondary);
}
.key-value-form-row.is-system.is-blocked .auto-suggest, .key-value-form-row.is-system.is-blocked .key-value-cell {
  color: var(--content-color-primary);
}
.key-value-form-row.is-system.is-disabled .auto-suggest, .key-value-form-row.is-system.is-disabled .key-value-cell {
  color: var(--content-color-secondary);
}
.key-value-form-row__controls {
  flex: 0 0 57px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.key-value-form-row__modification-options {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--background-color-primary);
}
.key-value-form-row__sensitive-var-options {
  display: flex;
  opacity: 0;
}
.key-value-form-row__sensitive-var-options span {
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-form-row__sensitive-var-options i {
  margin-right: 6px;
  margin-left: 6px;
  margin-top: 2px;
}
.key-value-form-row .sensitive-options-hide-icon {
  margin-top: var(--spacing-s);
  width: var(--size-s);
  height: var(--size-s);
}
.key-value-form-row .always-present {
  opacity: 1;
}
.key-value-form-row .always-present span {
  border-right-color: transparent;
}
.key-value-form-row__vault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-xs);
}
.key-value-form-row__vault__icon {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.key-value-form-row__delete, .key-value-form-row__types {
  display: flex;
  opacity: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.key-value-form-row__delete__icon, .key-value-form-row__types__icon {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.key-value-form-row__deep-link {
  position: absolute;
  right: 0;
  line-height: var(--line-height-xs);
  height: 32px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  background: var(--background-color-secondary);
  color: var(--content-color-secondary);
}
.key-value-form-row__deep-link__icon {
  padding-right: var(--spacing-xs);
}
.key-value-form-row__deep-link__icon use {
  fill: var(--content-color-secondary);
}
.key-value-form-row__deep-link__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.key-value-form-row__deep-link__icon svg {
  color: var(--content-color-secondary);
}
.key-value-form-row:hover .key-value-form-row__action, .key-value-form-row.is-hovered .key-value-form-row__action {
  display: block;
}
.key-value-form-row__type-select-container {
  display: flex;
  flex: 0 0 60px;
  z-index: 1;
  border-left: var(--border-width-default) var(--border-style-solid) var(--kv-editor-internal-border-color);
}
.key-value-form-row__type-select-container .dropdown-button {
  display: flex;
  align-items: center;
}
.key-value-form-row__type-select-container .dropdown-menu {
  min-width: 50px;
  margin-top: var(--spacing-zero);
}
.key-value-form-row__type-select-container .dropdown-button {
  flex: 1;
}
.key-value-form-row__type-select-container .dropdown-button .key-value-form-row__type-select {
  font-weight: var(--text-weight-regular);
  display: flex;
  gap: var(--spacing-xs);
  padding: var(--spacing-s);
  flex: 1;
  color: var(--content-color-primary);
  height: 32px;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  cursor: pointer;
  font-size: var(--text-size-s);
}
.key-value-form-row__type-select-container .dropdown-button .key-value-form-row__type-select use {
  fill: var(--content-color-tertiary);
}
.key-value-form-row__type-select-container .dropdown-button .key-value-form-row__type-select svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.key-value-form-row__type-select-container .dropdown-button .key-value-form-row__type-select svg {
  color: var(--content-color-tertiary);
}
.key-value-form-row__type-select-container .dropdown-button .is-last-row {
  color: var(--content-color-tertiary);
}
.key-value-form-row__modal-header {
  line-height: var(--line-height-s);
  text-transform: uppercase;
}
.key-value-form-row__modal-content {
  overflow: visible;
  line-height: var(--line-height-m);
  padding: var(--spacing-xs) var(--spacing-xl) var(--spacing-zero);
}
.key-value-form-row__modal-action {
  padding-top: var(--spacing-m);
}

.key-value-bulk-editor {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-bulk-editor .textarea-wrapper {
  flex: 1;
}
.layout-1-column .key-value-bulk-editor .textarea-wrapper .textarea {
  border-bottom: 0;
}
.key-value-bulk-editor.is-bleed .textarea,
.key-value-bulk-editor.is-bleed .bulk-editor__controls {
  border: 0;
}
.key-value-bulk-editor.is-bleed .bulk-editor__controls {
  padding-right: 5px;
}
.key-value-bulk-editor.is-bleed .bulk-editor__textarea {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.layout-2-column .key-value-bulk-editor.is-bleed .bulk-editor__textarea {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-bulk-editor .bulk-editor__controls {
  display: flex;
  height: 32px;
  align-items: center;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-right: var(--spacing-xs);
  padding-left: var(--spacing-s);
  justify-content: space-between;
}
.key-value-bulk-editor .bulk-editor__controls.hide-presets {
  flex: 0 0 80px;
  justify-content: flex-end;
}
.key-value-bulk-editor .bulk-editor__controls.hide-label {
  justify-content: flex-end;
}
.key-value-bulk-editor .bulk-editor__header-label-container {
  min-width: 0;
  color: var(--content-color-secondary);
}
.key-value-bulk-editor .bulk-editor__header-actions {
  white-space: nowrap;
  width: 200px;
  display: flex;
  justify-content: flex-end;
}

.key-value-form-editor-sortable {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.key-value-form-editor-sortable .key-value-form-editor-sortable__selectable {
  display: flex;
  flex-direction: column;
}

.key-value-form-editor {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: thin solid var(--border-color-default);
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.key-value-form-editor .key-value-form-row .auto-suggest .resolvedVariable, .key-value-form-editor .key-value-form-row .auto-suggest .unresolvedVariable {
  display: inline-block;
}
.key-value-form-editor.is-bleed {
  border-top: 0;
  border-right: 0;
}
.key-value-form-editor.is-bleed .key-value-form-row .auto-suggest-group > :last-child {
  border-right: 0;
}
.key-value-form-editor.is-bleed .key-value-form-row > :first-child {
  border-left: 0;
}
.key-value-form-editor.is-bleed .key-value-form-row .key-value-form-row__draggable > :first-child {
  border-left: 0;
}
.key-value-form-editor.is-bleed .key-value-form-editor-sortable__selectable > :last-child {
  border-bottom: 0;
}

.key-value-cell {
  flex: 1;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 3px 1px 9px;
  align-items: center;
  cursor: text;
  font-size: var(--text-size-m);
  user-select: none;
  color: var(--content-color-primary);
  display: flex;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}
.key-value-cell .inlineVariable:first-child {
  margin-left: var(--spacing-zero);
}
.key-value-cell .resolvedVariable, .key-value-cell .unresolvedVariable {
  padding: var(--spacing-zero) 2px 1px;
  margin: var(--spacing-zero) 1px;
  border-radius: var(--border-radius-default);
  font-size: 12px;
  cursor: text;
}
.key-value-cell .inlineVariable + .inlineVariable {
  margin-left: var(--spacing-zero);
}
.key-value-cell .unresolvedVariable {
  background: var(--background-color-error);
  color: var(--content-color-error);
  border: 1px dashed var(--variable-unresolved-border-color);
}
.key-value-cell .unresolvedVariable:hover {
  color: var(--variable-unresolved-hover-color);
  border: 1px dashed var(--variable-unresolved-hover-border-color);
}
.key-value-cell .resolvedVariable {
  background: var(--background-color-secondary);
  color: var(--content-color-info);
  border: 1px solid var(--border-color-default);
}
.key-value-cell .resolvedVariable:hover {
  border: 1px solid var(--content-color-tertiary);
  background: var(--background-color-tertiary);
}
.key-value-cell .resolvedVariable, .key-value-cell .unresolvedVariable {
  display: inline-block;
}
.key-value-cell__icon {
  margin-left: 8px;
}
.key-value-cell .key-value-cell__multiline__item:not(:last-child)::after {
  content: "↵";
  color: var(--content-color-tertiary);
  font-size: 9px;
  padding: 3px 3px 1px;
}
.key-value-cell.has-warning {
  margin-right: 24px;
}
.key-value-cell.has-error {
  margin-right: 24px;
}
.key-value-cell.has-error .key-value-cell__multiline__item:not(:last-child)::after {
  background: var(--background-color-error);
}
.key-value-cell.has-error.has-multiline .key-value-cell__text::after {
  color: var(--color-whitespace-char);
  opacity: 1;
  background: var(--background-color-error);
}
.key-value-cell.has-multiline .key-value-cell__text::after {
  content: "↵";
  color: var(--content-color-tertiary);
  font-size: 9px;
  padding: 3px 3px 2px;
}
.key-value-cell.has-multiline::after {
  content: "...";
  color: var(--content-color-tertiary);
}
.key-value-cell .key-value-cell__text {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.key-value-cell.is-overridden {
  text-decoration: line-through;
  color: var(--content-color-tertiary);
}

.key-value-cell__placeholder {
  flex: 1;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 7px 3px 0 9px;
  align-items: center;
  cursor: text;
  font-size: 12px;
  user-select: none;
  color: var(--content-color-tertiary);
}

.key-value-editor.is-filterable {
  display: flex;
  flex-direction: column;
}
.key-value-editor .search-variable {
  width: 240px;
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-s);
}

.search-bar-wrapper {
  position: relative;
  background-color: var(--highlight-background-color-secondary);
  border: 1px solid var(--border-color-strong);
  border-radius: var(--border-radius-default);
}
.search-bar-wrapper ::-webkit-scrollbar {
  background-color: var(--background-color-secondary);
}
.search-bar-wrapper ul {
  list-style: none;
}
.search-bar-wrapper li {
  font-size: 12px;
  color: var(--content-color-primary);
  cursor: pointer;
}
.search-bar-wrapper .filtered-selector-input-wrapper {
  display: flex;
  align-items: center;
}
.search-bar-wrapper .filtered-selector-input-wrapper .input-search-group {
  flex: 1;
  background-color: var(--background-color-tertiary);
  border: 1px solid transparent;
}
.search-bar-wrapper .filtered-selector-input-wrapper .input-search-group:hover, .search-bar-wrapper .filtered-selector-input-wrapper .input-search-group.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.search-bar-wrapper .filtered-selector-input-wrapper .input-search-group:focus, .search-bar-wrapper .filtered-selector-input-wrapper .input-search-group.is-focused {
  background-color: var(--background-color-primary);
}
.search-bar-wrapper .filtered-selector-input-wrapper .input-search-group .input::-webkit-input-placeholder {
  font-size: 13px;
}
.search-bar-wrapper .filtered-selector-input-wrapper .btn {
  flex: 0 0 30px;
  height: 30px;
  padding: 0;
  margin-left: auto;
  background-color: var(--background-color-tertiary);
  border-left: 1px solid var(--border-color-strong);
  border-radius: 0;
  align-self: center;
}
.search-bar-wrapper .filtered-selector-input-wrapper .btn:hover, .search-bar-wrapper .filtered-selector-input-wrapper .btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.search-bar-wrapper .filtered-selector-input-wrapper .btn .dropdown-caret {
  margin-left: 0;
}
.search-bar-wrapper .sub-item:first-child {
  border-top: 0;
}
.search-bar-wrapper input {
  color: var(--content-color-primary);
}
.search-bar-wrapper .input-search-group {
  background-color: var(--background-color-secondary);
  border: 0;
  border-radius: 0;
}
.search-bar-wrapper.is-overlaid {
  z-index: 1;
}
.search-bar-wrapper.is-overlaid .items-list {
  position: absolute;
  width: 100%;
  height: 0;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-default);
}
.search-bar-wrapper.is-open {
  border: 1px solid var(--border-color-strong);
}
.search-bar-wrapper.is-open .items-list {
  height: auto;
  max-height: 217px;
  margin-bottom: 10px;
  margin-top: 2px;
}
.search-bar-wrapper.is-open .input-search-group {
  background-color: var(--background-color-primary);
}

.items-list {
  height: 217px;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.item {
  padding-bottom: 0;
  background-color: var(--background-color-primary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.item .item-name {
  padding: 8px 10px;
  word-break: break-all;
}
.item .item-name:focus, .item .item-name.is-focused {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-secondary);
}
.item .item-name:hover, .item .item-name.is-hovered {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-secondary);
}
.item.is-selected .item-name {
  color: var(--content-color-constant);
  background-color: var(--base-color-brand);
}

.sub-item-wrapper {
  display: none;
  padding-left: 0;
  margin-left: -10px;
  background-color: var(--highlight-background-color-secondary);
}
.sub-item-wrapper.is-open {
  display: block;
  transition: all 0.4s ease-out;
}

.sub-item {
  padding: 8px 10px;
  margin-left: 20px;
  word-break: break-all;
  background-color: var(--background-color-primary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.sub-item:focus, .sub-item.is-focused {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-secondary);
}
.sub-item:hover, .sub-item.is-hovered {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-secondary);
}
.sub-item.is-selected {
  color: var(--content-color-constant);
  background-color: var(--base-color-brand);
}
.sub-item.is-selected .matched-text {
  color: var(--content-color-primary);
}

.searched-item {
  padding: 8px 10px;
  word-break: break-all;
  background-color: var(--background-color-secondary);
}
.searched-item:focus, .searched-item.is-focused {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-secondary);
}
.searched-item:hover, .searched-item.is-hovered {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-secondary);
}
.searched-item.is-selected {
  color: var(--content-color-constant);
  background-color: var(--base-color-brand);
}

.selected-item {
  color: var(--content-color-constant);
  background-color: var(--base-color-brand);
}

.highlight {
  color: var(--base-color-brand);
}

.is-selected .highlight {
  color: var(--content-color-constant);
}

.requester-library {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
}
.requester-library .requester-library-header {
  flex: 0 0 auto;
}
.requester-library .requester-team-collections {
  flex: 1;
}
.requester-library .requester-environment-templates {
  flex: 1;
}

.requester-library--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.requester-library .requester-library__offline-banner {
  display: none;
}
.requester-library.is-offline {
  position: relative;
}
.requester-library.is-offline .requester-library__offline-banner {
  display: flex;
}
.requester-library.is-offline:before {
  content: "";
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 80;
  background-color: var(--background-modal-backdrop);
}

.requester-library__offline-banner {
  background-color: var(--background-color-warning);
  font-size: 14px;
  color: var(--content-color-warning);
  height: 40px;
  align-items: center;
  justify-content: center;
  z-index: 80;
}
.requester-library__offline-banner .requester-library__offline-banner__button {
  text-decoration: underline;
  cursor: pointer;
}

.requester-team-overview {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 10px;
  overflow-y: visible;
}
.requester-team-overview .workspace-activity-item-content .workspace-activity-item-details {
  border: 0;
}

.requester-team-collections {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-environment-templates {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-library-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--border-color-default);
}
.requester-library-header .tab:first-child {
  margin-left: 20px;
}

.requester-library-team-collections {
  padding: 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-library-team-collections__contents {
  margin-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.requester-library-tabs {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.requester-library-tabs .requester-library-tabs--team-meta {
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  padding: 0px 15px 7px;
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  top: 6px;
}
.requester-library-tabs .requester-library-tabs--team-meta.not-admin {
  width: 220px;
}

.requester-library-wrapper--sync-disabled {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.requester-library-wrapper--sync-disabled .requester-library--sync-disabled {
  color: var(--content-color-primary);
  display: flex;
  flex-direction: column;
  width: 560px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.requester-library-wrapper--sync-disabled .sync-disabled-description {
  font-size: 12px;
  padding: 10px 0px;
  opacity: 0.6;
}
.requester-library-wrapper--sync-disabled .learn-more-sync {
  padding-top: 15px;
  padding-bottom: 30px;
}

.workspace-activity-feed-empty {
  display: flex;
  flex: 1;
  height: calc(100% - 50px);
}

.requester-library-header-container {
  display: flex;
  flex: 0 0 40px;
  flex-direction: row;
  padding: 0 10px;
  margin-top: 15px;
  align-items: center;
}

.requester-library-header__section__left {
  display: flex;
  flex-direction: row;
}
.requester-library-header__section__left .input-search-group {
  width: 300px;
}
.requester-library-header__section__left .requester-library-header__actions {
  display: flex;
  align-items: center;
}
.requester-library-header__section__left .requester-library-header__actions .requester-library-header__actions-sort-button {
  display: flex;
  width: 150px;
  justify-content: space-between;
}

.requester-library-header__section__right {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
}

.requester-library-header__actions {
  height: 30px;
}
.requester-library-header__actions .requester-library-header__actions__meta {
  padding: 0 10px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: var(--content-color-primary);
}

.requester-library-invite__wrapper {
  display: flex;
  padding: 20px;
  font-size: 12px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
}
.requester-library-invite__wrapper .requester-library-invite__description {
  flex: 0 0 400px;
}
.requester-library-invite__wrapper .requester-library-invite__heading {
  font-size: 16px;
  font-weight: var(--text-weight-medium);
}
.requester-library-invite__wrapper .requester-library-invite__textarea {
  flex: 1;
  display: flex;
  margin-right: 50px;
}
.requester-library-invite__wrapper .requester-library-invite__textarea .textarea-wrapper {
  flex: 1;
  padding: 0px 10px;
}
.requester-library-invite__wrapper .requester-library-invite__action {
  display: flex;
  flex-direction: column;
}

.team-library-invite__wrapper {
  text-align: center;
  font-size: 12px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  padding-top: 10vh;
  flex: 1;
  overflow-y: auto;
  line-height: 1.7;
}
.team-library-invite__wrapper.empty-activity-feed {
  margin: 100px 0;
  padding-top: 0;
}
.team-library-invite__wrapper.empty-activity-feed .empty-activity-feed__label {
  margin-top: var(--spacing-s);
  font-size: 14px;
  font-weight: 600;
}
.team-library-invite__wrapper.empty-activity-feed .empty-activity-feed__description {
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}

.environment-selector-container {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
.environment-selector-container .environment-selector-wrapper {
  width: 100%;
}
@media screen and (min-width: 769px) and (max-width: 1129px) {
  .environment-selector-container .environment-selector-wrapper.without-browser-tabs-wrapper .input-select-v2-wrapper {
    max-width: 210px;
  }
}
@media screen and (max-width: 768px) {
  .environment-selector-container .environment-selector-wrapper.without-browser-tabs-wrapper .input-select-v2-wrapper {
    max-width: 160px;
  }
}
.environment-selector-container .environment-selector-wrapper .input-search-group__search-cancel-button use {
  fill: var(--content-color-secondary);
}
.environment-selector-container .environment-selector-wrapper .input-search-group__search-cancel-button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.environment-selector-container .environment-selector-wrapper .input-search-group__search-cancel-button svg {
  color: var(--content-color-secondary);
}
.environment-selector-container .environment-selector-wrapper:hover, .environment-selector-container .environment-selector-wrapper.is-hovered {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper:hover .input-search-group__search-cancel-button:hover use, .environment-selector-container .environment-selector-wrapper:hover .input-search-group__search-cancel-button.is-hovered use, .environment-selector-container .environment-selector-wrapper.is-hovered .input-search-group__search-cancel-button:hover use, .environment-selector-container .environment-selector-wrapper.is-hovered .input-search-group__search-cancel-button.is-hovered use {
  fill: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper:hover .input-search-group__search-cancel-button:hover svg:not([class*=PartnerIcon]) > path[fill], .environment-selector-container .environment-selector-wrapper:hover .input-search-group__search-cancel-button.is-hovered svg:not([class*=PartnerIcon]) > path[fill], .environment-selector-container .environment-selector-wrapper.is-hovered .input-search-group__search-cancel-button:hover svg:not([class*=PartnerIcon]) > path[fill], .environment-selector-container .environment-selector-wrapper.is-hovered .input-search-group__search-cancel-button.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper:hover .input-search-group__search-cancel-button:hover svg, .environment-selector-container .environment-selector-wrapper:hover .input-search-group__search-cancel-button.is-hovered svg, .environment-selector-container .environment-selector-wrapper.is-hovered .input-search-group__search-cancel-button:hover svg, .environment-selector-container .environment-selector-wrapper.is-hovered .input-search-group__search-cancel-button.is-hovered svg {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper {
  background-color: initial;
  border: none;
  border-radius: 0;
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-focused + .btn.dropdown-button .dropdown-caret use, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-hovered + .btn.dropdown-button .dropdown-caret use {
  fill: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-focused + .btn.dropdown-button .dropdown-caret svg:not([class*=PartnerIcon]) > path[fill], .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-hovered + .btn.dropdown-button .dropdown-caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-focused + .btn.dropdown-button .dropdown-caret svg, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-hovered + .btn.dropdown-button .dropdown-caret svg {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-focused .input, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input-search-group.is-hovered .input {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper:hover, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper.is-hovered {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper:hover .input-search-group__input-wrapper input, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper.is-hovered .input-search-group__input-wrapper input {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper:hover .input-search-group__input-wrapper input:hover, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper:hover .input-search-group__input-wrapper input.is-hovered, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper.is-hovered .input-search-group__input-wrapper input:hover, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper.is-hovered .input-search-group__input-wrapper input.is-hovered {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper:hover .btn.dropdown-button .dropdown-caret use, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper.is-hovered .btn.dropdown-button .dropdown-caret use {
  fill: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper:hover .btn.dropdown-button .dropdown-caret svg:not([class*=PartnerIcon]) > path[fill], .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper.is-hovered .btn.dropdown-button .dropdown-caret svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper:hover .btn.dropdown-button .dropdown-caret svg, .environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper.is-hovered .btn.dropdown-button .dropdown-caret svg {
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .input {
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
  color: var(--content-color-secondary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .user-selected-environment-input {
  font-weight: var(--text-weight-bold);
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .user-selected-environment-input-loading {
  font-weight: var(--text-weight-lighter);
  color: var(--content-color-primary);
}
.environment-selector-container .environment-selector-wrapper .input-select-v2-wrapper .dropdown-button:hover .input-search-group .input-search {
  color: var(--content-color-primary);
}

.environment-selector-menu .menu-item-header {
  text-transform: unset;
  font-size: unset;
  display: flex;
  align-items: center;
}
.environment-selector-menu .menu-item-header .parent-wrapper .parent-label {
  flex: initial;
  font-weight: bold;
  color: var(--content-color-secondary);
}
.environment-selector-menu .dropdown-menu-item .item-wrapper .item-label {
  flex: initial;
  margin-left: var(--spacing-s);
}
.environment-selector-menu .dropdown-menu-item .item-wrapper .item-label.is-forked {
  max-width: 100px;
}
.environment-selector-menu .dropdown-menu-item.is-focused:not(.is-selected) {
  background: var(--background-color-tertiary);
}
.environment-selector-menu .dropdown-menu-item.is-selected {
  background: var(--highlight-background-color-tertiary);
}
.environment-selector-menu .dropdown-menu-item:hover, .environment-selector-menu .dropdown-menu-item.is-hovered, .environment-selector-menu .dropdown-menu-item.is-focused:hover, .environment-selector-menu .dropdown-menu-item.is-focused.is-hovered, .environment-selector-menu .dropdown-menu-item.is-selected:hover, .environment-selector-menu .dropdown-menu-item.is-selected.is-hovered {
  background: var(--highlight-background-color-secondary);
}

.environment-preview-container {
  margin-right: var(--spacing-s);
}
.environment-preview-container .environment-preview-button {
  background: none;
}
.environment-preview-container .environment-preview-button:hover, .environment-preview-container .environment-preview-button.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.environment-preview-container .environment-preview-button:active, .environment-preview-container .environment-preview-button.is-active {
  background-color: var(--highlight-background-color-primary);
}
.environment-preview-container .environment-preview-button:active .environment-preview-icon use, .environment-preview-container .environment-preview-button.is-active .environment-preview-icon use {
  fill: var(--content-color-primary);
}
.environment-preview-container .environment-preview-button:active .environment-preview-icon svg:not([class*=PartnerIcon]) > path[fill], .environment-preview-container .environment-preview-button.is-active .environment-preview-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.environment-preview-container .environment-preview-button:active .environment-preview-icon svg, .environment-preview-container .environment-preview-button.is-active .environment-preview-icon svg {
  color: var(--content-color-primary);
}
.environment-preview-container .environment-preview-button.is-open {
  background-color: var(--highlight-background-color-primary);
}

.environment-preview-button:hover .environment-preview-icon use {
  fill: var(--content-color-primary);
}
.environment-preview-button:hover .environment-preview-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.environment-preview-button:hover .environment-preview-icon svg {
  color: var(--content-color-primary);
}

.environment-preview {
  background-color: var(--background-color-secondary);
  position: relative;
  z-index: 120;
  border-radius: var(--border-radius-default);
  display: block;
  width: 720px;
  /*Media query to prevent clipping of the environment preview below 736px */
  -webkit-user-select: text;
}
@media screen and (max-width: 736px) {
  .environment-preview {
    max-width: 600px;
  }
}
.environment-preview.is-closed {
  display: none;
}

.environment-preview-dropdown .dropdown-menu {
  background-color: var(--background-color-secondary);
  padding-bottom: var(--spacing-zero);
}

.environment-preview-wrapper {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.environment-preview-wrapper .environment-header__meta {
  display: flex;
  padding: var(--spacing-s) var(--spacing-l);
  align-items: center;
  justify-content: space-between;
}
.environment-preview-wrapper .environment-header__meta .environment-header__meta__title__wrapper {
  flex: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
}
.environment-preview-wrapper .environment-header__meta .environment-header__meta__title__wrapper .environment-header__meta__title {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-virtualized-list {
  height: 200px;
  background: var(--background-color-primary);
}
@media screen and (max-width: 736px) {
  .environment-preview-wrapper .environment-preview-list .environment-preview-virtualized-list {
    max-width: 100%;
  }
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .input {
  height: fit-content;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header {
  display: flex;
  padding: var(--spacing-s) var(--spacing-l);
  word-wrap: break-word;
  word-break: break-word;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item > *:not(:last-child), .environment-preview-wrapper .environment-preview-list .environment-preview-list-header > *:not(:last-child) {
  padding-right: var(--spacing-xs);
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__key, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__key,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__value,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__key, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__key,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__value,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__session-value {
  text-align: left;
  box-sizing: border-box;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__key, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__key, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__key, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__key {
  flex: 0 0 20%;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__value {
  flex: 0 0 38%;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__session-value {
  flex: 0 0 38%;
  display: flex;
  justify-content: space-between;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value:hover .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value.is-hovered .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__session-value:hover .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__session-value.is-hovered .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value:hover .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value.is-hovered .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__session-value:hover .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__session-value.is-hovered .environment-preview-list-item__session-value__edit-icon {
  visibility: visible;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__key,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__value,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-header__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__key,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__value,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-header__session-value {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__key,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__value,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__key,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__value,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value {
  color: var(--content-color-primary);
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__value input,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value input, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__value input,
.environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value input {
  text-overflow: ellipsis;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value__edit-icon-wrapper, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value__edit-icon-wrapper {
  margin-left: var(--spacing-xs);
  display: flex;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value__edit-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value__edit-icon {
  visibility: hidden;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value {
  display: flex;
  align-items: center;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__session-value .warning-button, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__session-value .warning-button {
  margin-left: var(--spacing-xs);
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item .environment-preview-list-item__mask-icon, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header .environment-preview-list-item__mask-icon {
  display: inline-block;
  margin-top: var(--spacing-xs);
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-s);
  cursor: pointer;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item.is-session-value-deleted .environment-preview-list-item__session-value, .environment-preview-wrapper .environment-preview-list .environment-preview-list-header.is-session-value-deleted .environment-preview-list-item__session-value {
  color: var(--content-color-tertiary);
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item {
  align-items: flex-start;
}
.environment-preview-wrapper .environment-preview-list .environment-preview-list-item:hover, .environment-preview-wrapper .environment-preview-list .environment-preview-list-item.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.environment-preview-wrapper .environment-preview-list .globals-preview-list-item.is-overridden .environment-preview-list-item__session-value .collection-browser-details-header__name,
.environment-preview-wrapper .environment-preview-list .globals-preview-list-item.is-overridden .environment-preview-list-item__session-value .environment-preview-list-item__session-value__masked {
  color: var(--content-color-tertiary);
  text-decoration: line-through;
}

.environment-preview-list-item--empty-environment,
.environment-preview-list-item--no-environment,
.environment-preview-list-item--empty-globals {
  padding: var(--spacing-l);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.environment-preview-list-item--empty-environment .environment-preview-list-item--no-environment-text,
.environment-preview-list-item--empty-environment .environment-preview-list-item--empty-globals-text,
.environment-preview-list-item--no-environment .environment-preview-list-item--no-environment-text,
.environment-preview-list-item--no-environment .environment-preview-list-item--empty-globals-text,
.environment-preview-list-item--empty-globals .environment-preview-list-item--no-environment-text,
.environment-preview-list-item--empty-globals .environment-preview-list-item--empty-globals-text {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
  width: 380px;
  text-align: center;
}
.environment-preview-list-item--empty-environment .environment-preview-list-item--no-environment-header,
.environment-preview-list-item--empty-environment .environment-preview-list-item--empty-globals-header,
.environment-preview-list-item--no-environment .environment-preview-list-item--no-environment-header,
.environment-preview-list-item--no-environment .environment-preview-list-item--empty-globals-header,
.environment-preview-list-item--empty-globals .environment-preview-list-item--no-environment-header,
.environment-preview-list-item--empty-globals .environment-preview-list-item--empty-globals-header {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}

.environment-preview-divider {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.delete-environment {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.delete-environment-message {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-environment-controls {
  display: flex;
  flex-direction: row-reverse;
}
.delete-environment-controls .btn {
  margin-left: 10px;
}

.environment-meta-icons {
  display: flex;
  margin-left: var(--spacing-xs);
  align-items: center;
}
.environment-meta-icons .environment-meta-icon use {
  fill: var(--content-color-tertiary);
}
.environment-meta-icons .environment-meta-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.environment-meta-icons .environment-meta-icon svg {
  color: var(--content-color-tertiary);
}

.add-environment-template {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
}

.add-environment-template__header {
  display: flex;
}
.add-environment-template__header .add-environment-template__header__title {
  flex: 1;
  display: flex;
  align-items: center;
}

.add-environment__header__title {
  font-size: 13px;
  font-weight: 600;
}

.add-environment-template__section-name {
  display: flex;
  margin: 10px 0;
  align-items: flex-end;
}

.add-environment-template__section-name-extension {
  padding-left: 5px;
}

.add-environment-template__section-values {
  flex: 1;
  height: 0;
  overflow-y: auto;
}

.add-environment-template__section-controls {
  display: flex;
  flex-direction: row-reverse;
}

.add-environment-template__section-controls__cancel {
  margin-right: 10px;
}

.environment-template-newtemplate-wrapper {
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  border-top: 1px solid var(--border-color-default);
}

.environment-template-modal-empty-view-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}

.environment-template-modal-empty-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.environment-template-modal-loading-container {
  left: 47%;
  position: absolute;
  top: 5%;
}

.environment-template-modal-empty-view-content-header {
  padding: 20px 0 10px;
  font-size: 18px;
}

.environment-template-modal-empty-view-content-text {
  max-width: 310px;
  margin: 0 auto;
  margin-bottom: 10px;
  color: var(--content-color-secondary);
  text-align: center;
}

.environment-template-modal-list-empty-view-content-text {
  margin-bottom: 10px;
  color: var(--content-color-secondary);
  text-align: center;
}

.environment-template-modal-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  max-width: 80%;
}
.environment-template-modal-content-wrapper .environment-template-modal-empty-view-content-header {
  font-size: 12px;
}
.environment-template-modal-content-wrapper .learnmore-link {
  margin-bottom: 32px;
}
.environment-template-modal-content-wrapper .signout-out-signin-btn {
  font-size: 12px;
  max-width: 200px;
  align-self: center;
}

.environment-template-modal-action-button {
  margin-top: 10px;
  align-self: center;
  min-width: 200px;
}

.env-dropdown-menu {
  min-width: 200px;
}
.env-dropdown-menu .dropdown-menu-item {
  padding: 0 var(--spacing-l);
}
.env-dropdown-menu .dropdown-menu-item .dropdown-menu-item-icon {
  margin-right: var(--spacing-s);
}
.env-dropdown-menu .env-action-item {
  display: flex;
  flex: 1;
}

.env-actions-icon-container .btn {
  display: none;
}
.env-actions-icon-container.isHovered .active-env-icon {
  display: none;
}
.env-actions-icon-container.isHovered .inactive-env-icon {
  display: flex;
}
.env-actions-icon-container.isHovered .inactive-env-icon use {
  fill: var(--content-color-secondary);
}
.env-actions-icon-container.isHovered .inactive-env-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.env-actions-icon-container.isHovered .inactive-env-icon svg {
  color: var(--content-color-secondary);
}
.env-actions-icon-container.isActive .active-env-icon {
  display: flex;
}
.env-actions-icon-container.isActive .inactive-env-icon {
  display: none;
}
.env-actions-icon-container:hover .inactive-env-icon use {
  fill: var(--content-color-primary);
}
.env-actions-icon-container:hover .inactive-env-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.env-actions-icon-container:hover .inactive-env-icon svg {
  color: var(--content-color-primary);
}

.env-actions__more-actions-placeholder {
  height: var(--size-s);
  width: var(--size-s);
  background-color: transparent;
}

.environment-sidebar__indent {
  width: var(--spacing-m);
}

.api-sidebar-list .environment-sidebar-item .sidebar-list-item__icon-container {
  margin-left: 36px;
}

.environment-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.manage-header-preset-modal .modal-content {
  display: flex;
  flex-direction: column;
}

.manage-header-preset-modal-content-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.manage-header-preset-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.manage-header-preset-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.manage-header-preset-wrapper.is-hidden {
  display: none;
}

.manage-header-preset-buttons {
  margin: 10px 0;
}
.manage-header-preset-buttons .btn {
  margin-right: 5px;
}

.add-header-preset,
.edit-header-preset {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.add-header-preset__header,
.edit-header-preset__header {
  display: flex;
}
.add-header-preset__header__title,
.edit-header-preset__header__title {
  flex: 1;
  display: flex;
  align-items: center;
}
.add-header-preset__section-name,
.edit-header-preset__section-name {
  margin: var(--spacing-s) var(--spacing-zero);
}
.add-header-preset__section-values,
.edit-header-preset__section-values {
  flex: 1;
  height: 0;
  overflow-y: auto;
}
.add-header-preset__section-controls,
.edit-header-preset__section-controls {
  display: flex;
  flex-direction: row-reverse;
  margin-top: var(--spacing-l);
}
.add-header-preset__section-controls__cancel,
.edit-header-preset__section-controls__cancel {
  margin-right: var(--spacing-m);
}

.header-preset-list {
  flex: 1 1 auto;
  height: 0;
  overflow-y: auto;
}

.header-preset-list-item {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-xs) 0;
}
.header-preset-list-item:not(:last-child) {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.header-preset-list-item__name-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.header-preset-list-item__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.header-preset-list-item__actions {
  display: flex;
  gap: var(--spacing-xs);
}
.header-preset-list-item__actions .btn-icon {
  background-color: transparent;
}

.user-dropdown {
  max-width: 110px;
}
.user-dropdown .dropdown-button .btn {
  display: flex;
}
.user-dropdown .dropdown-button .btn > span:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-welcome-modal .modal-content {
  display: flex;
  padding: 0;
  height: 570px;
  overflow-y: initial;
}
.user-welcome-modal .user-welcome-modal--dismiss-icon {
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
}
.user-welcome-modal .user-welcome__left-section {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  padding: 10px 32px;
  box-sizing: border-box;
  background-color: var(--background-color-secondary);
  border-right: 1px solid var(--border-color-default);
  max-height: 100%;
}
.user-welcome-modal .user-welcome__left-section .section-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 4;
}
.user-welcome-modal .user-welcome__left-section .section-subtext {
  font-size: 14px;
  line-height: 1.7;
  flex: 1;
}
.user-welcome-modal .user-welcome__left-section .section-subtext.intro-text {
  flex: 0 0 150px;
}
.user-welcome-modal .user-welcome__left-section .section-subtext .section-subtext__info {
  color: var(--base-color-warning);
}
.user-welcome-modal .user-welcome__left-section .section-subtext .section-subtext__text .btn {
  color: inherit;
  font-size: 14px;
  text-decoration: underline;
  padding: 0;
}
.user-welcome-modal .user-welcome__left-section .section-subtext .section-subtext__text.links-label {
  font-size: 12px;
}
.user-welcome-modal .user-welcome__left-section .section-subtext .help-link {
  font-size: 16px;
  padding: 20px 0;
}
.user-welcome-modal .user-welcome__left-section .section-action {
  font-size: 14px;
}
.user-welcome-modal .user-welcome__left-section .section-action .section-action__action {
  font-weight: 300;
}
.user-welcome-modal .user-welcome__right-section {
  display: flex;
  min-width: 560px;
  padding: 10px 32px;
  box-sizing: border-box;
  background-color: var(--background-color-primary);
  overflow-y: auto;
  flex-direction: column;
}
.user-welcome-modal .user-welcome__right-section .tabs {
  border-bottom: 1px solid var(--border-color-default);
  margin: 0 -32px 20px;
  flex: 0 0 35px;
}
.user-welcome-modal .user-welcome__right-section .section__cards {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card {
  display: flex;
  min-height: 80px;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin: 8px 0;
  box-sizing: border-box;
  align-items: center;
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card:first-child {
  margin-top: 0;
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card:last-child {
  margin-bottom: 20px;
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card .section__card__illustration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 100%;
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card .section__card__text {
  padding: 24px;
  max-width: 350px;
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card .section__card__text .btn-text {
  padding: 0;
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card.section__card--collections .section__card__illustration {
  display: block;
  width: 48px;
  height: 39px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/organize-collections.svg");
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card.section__card--sharing .section__card__illustration {
  display: block;
  width: 64px;
  height: 31px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/share-collections.svg");
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card.section__card--docs .section__card__illustration {
  display: block;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/automatic_docs.svg");
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card.section__card--monitors .section__card__illustration {
  display: block;
  width: 72px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/test-collections.svg");
}
.user-welcome-modal .user-welcome__right-section .section__cards .section__card.section__card--mocks .section__card__illustration {
  display: block;
  width: 46px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/mock-collection.svg");
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list {
  overflow: auto;
  padding-left: 15px;
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list .activity-feed-list-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  margin: 10px 0;
  border-radius: var(--border-radius-default);
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list .activity-feed-list-item:hover, .user-welcome-modal .user-welcome__right-section .activity-feed-list .activity-feed-list-item.is-hovered {
  background-color: var(--background-color-tertiary);
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list .activity-feed-list-item:before {
  content: " ";
  position: absolute;
  top: 15px;
  width: 10px;
  left: -12px;
  height: 10px;
  background-color: var(--border-color-default);
  border-radius: 50%;
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list .activity-feed-list-item--name {
  font-weight: 600;
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list .activity-feed-list-item--date {
  opacity: 0.4;
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list .timeline {
  position: relative;
}
.user-welcome-modal .user-welcome__right-section .activity-feed-list .timeline:before {
  content: " ";
  position: absolute;
  top: 20px;
  bottom: 40px;
  left: -8px;
  width: 2px;
  background-color: var(--border-color-default);
}

.import-modal {
  height: 68vh;
}
.import-modal .import-modal-header {
  flex-grow: 0;
}
.import-modal .import-modal-content {
  display: flex;
  flex: 1;
  font-size: var(--text-size-m);
}
.import-modal .import-modal-content .import-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
}
.import-modal .import-modal-content .import-container .import-input-tabs-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
}
.import-modal .import-modal-content .import-loading-indicator {
  margin: auto;
}
.import-modal .import-modal-footer {
  border-top: 1px solid var(--border-color-default);
  flex-direction: row-reverse;
}
.import-modal .import-modal-footer .btn {
  margin-left: var(--spacing-m);
  margin-top: var(--spacing-xl);
}

.import-modal-integration-flex {
  margin: auto;
}

.import-modal-v2 {
  max-height: 68vh;
  transition: height 0.3s;
  overflow: hidden;
}
.import-modal-v2 .import-modal-header {
  flex-grow: 0;
}
.import-modal-v2 .import-modal-content {
  display: flex;
  flex: 1;
  font-size: var(--text-size-m);
}
.import-modal-v2 .import-modal-content .import-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
}
.import-modal-v2 .import-modal-content .import-container .import-input-tabs-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
}
.import-modal-v2 .import-modal-content .import-loading-indicator {
  min-height: 300px;
}
.import-modal-v2 .import-modal-footer {
  flex-direction: row-reverse;
}
.import-modal-v2 .import-modal-footer .btn {
  margin-left: var(--spacing-m);
  margin-top: var(--spacing-xl);
}

.import-modal-footer-import-button {
  margin-top: auto;
  margin-left: 6px;
  padding-top: var(--spacing-xl) !important;
  padding-bottom: var(--spacing-m) !important;
}

.import-offline-banner {
  margin-bottom: var(--spacing-xs);
  align-self: center;
}

.is-hidden {
  display: none;
}

.import-modal-api-options {
  padding-left: 6px !important;
  width: 100%;
}
.import-modal-api-options.import-settings {
  max-width: 100%;
  padding-left: 0px !important;
}

.import-more-icons-padding-azure {
  margin-left: calc(var(--spacing-m) * -1);
}

.import-tabs-header--file, .import-tabs-header--folder, .import-tabs-header--text, .import-tabs-header--link, .import-tabs-header--code-repository, .import-tabs-header--api-gateway {
  color: var(--content-color-secondary);
}
.import-tabs-header--file .tab-text-wrapper, .import-tabs-header--folder .tab-text-wrapper, .import-tabs-header--text .tab-text-wrapper, .import-tabs-header--link .tab-text-wrapper, .import-tabs-header--code-repository .tab-text-wrapper, .import-tabs-header--api-gateway .tab-text-wrapper {
  margin: 0 var(--spacing-l) 0 0;
}

.workspace-drop-zone-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}

.import-tab-contents {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content {
  padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content.is-hidden {
  display: none;
}
.import-tab-contents .import-tab-content .import-by-file-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content .import-by-file-container .import-by-file-text {
  color: var(--content-color-secondary);
  padding-top: var(--spacing-m);
  padding-left: var(--spacing-xs);
}
.import-tab-contents .import-tab-content .import-by-file-container .import-by-file-text-link {
  color: var(--content-color-link);
}
.import-tab-contents .import-tab-content .import-by-file-container .drop-files-dropzone-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone {
  display: flex;
  flex-grow: 1;
  border: 2px dashed var(--border-color-strong);
}
.import-tab-contents .import-tab-content .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-text {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  padding-bottom: var(--spacing-l);
}
.import-tab-contents .import-tab-content .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-text__OR {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  margin: var(--spacing-l) var(--spacing-zero);
}
.import-tab-contents .import-tab-content .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-formats {
  display: flex;
}
.import-tab-contents .import-tab-content .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-format {
  padding: var(--spacing-s);
  margin: var(--spacing-zero) var(--spacing-s);
  font-weight: var(--text-weight-medium);
  border: dashed var(--border-width-default) var(--border-color-default);
  color: var(--content-color-tertiary);
  display: flex;
}
.import-tab-contents .import-tab-content .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone .btn {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.import-tab-contents .import-tab-content .import-by-folder-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content .import-by-folder-container .drop-files-dropzone-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content .import-by-folder-container .drop-files-dropzone-container .drop-files-dropzone {
  border: 2px dashed var(--border-color-strong);
  display: flex;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content .import-by-folder-container .drop-files-dropzone-container .drop-files-dropzone-text {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  padding-bottom: var(--spacing-zero);
}
.import-tab-contents .import-tab-content .import-by-folder-container .drop-files-dropzone-container .drop-files-dropzone-text__OR {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  margin: var(--spacing-l) var(--spacing-zero);
}
.import-tab-contents .import-tab-content .import-by-folder-container .drop-files-dropzone-container .drop-files-dropzone .btn {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.import-tab-contents .import-tab-content .import-by-url-container .import-by-url-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
}
.import-tab-contents .import-tab-content .import-by-url-container .import-by-url-input {
  margin: var(--spacing-m) var(--spacing-zero);
}
.import-tab-contents .import-tab-content .import-by-text-container .import-by-raw-text-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
}
.import-tab-contents .import-tab-content .import-by-text-container .import-by-raw-text-input {
  margin: var(--spacing-m) 0 0 0;
  border: 2px dashed var(--border-color-strong);
}
.import-tab-contents .import-tab-content-v2 {
  padding: 0 var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.import-tab-contents .import-tab-content-v2.is-hidden {
  display: none;
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 252px;
  padding-bottom: 32px;
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .import-by-file-text {
  color: var(--content-color-secondary);
  padding-top: var(--spacing-m);
  padding-left: var(--spacing-xs);
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .import-by-file-text-link {
  color: var(--content-color-link);
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .drop-files-dropzone-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: auto;
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone {
  display: flex;
  flex-grow: 1;
  border: 1px dashed var(--border-color-strong);
  border-radius: 4px;
  cursor: auto;
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-text {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  padding-bottom: var(--spacing-l);
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-text__OR {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  margin: var(--spacing-l) var(--spacing-zero);
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-formats {
  display: flex;
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone-format {
  padding: var(--spacing-s);
  margin: var(--spacing-zero) var(--spacing-s);
  font-weight: var(--text-weight-medium);
  border: dashed var(--border-width-default) var(--border-color-default);
  color: var(--content-color-tertiary);
  display: flex;
}
.import-tab-contents .import-tab-content-v2 .import-by-file-container .drop-files-dropzone-container .drop-files-dropzone .btn {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.import-tab-contents .import-tab-content-v2 .import-by-text-container .import-by-raw-text-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
}
.import-tab-contents .import-tab-content-v2 .import-by-text-container .import-by-raw-text-input {
  margin: var(--spacing-m) 0 0 0;
}

.disable-import-cta {
  pointer-events: none;
  opacity: 0.4;
}

.import-options-seperator-line {
  border-left: 2px solid var(--border-color-strong);
  height: 16px;
  margin-top: 8px;
}

.import-raw-text-area-cover {
  padding-bottom: var(--spacing-s);
}

.import-text-container-interactive {
  height: 32px; /* set initial height value */
  overflow: hidden;
  z-index: 999;
}

.import-text-container-interactive.focused {
  height: 320px;
  overflow-y: scroll;
  font-family: var(--text-family-code);
}

.import-text-container-interactive.focusedbanner {
  height: 360px;
  overflow-y: scroll;
  font-family: var(--text-family-code);
}

.import-text-container-interactive.expanded {
  height: 222px;
  overflow-y: scroll;
  padding-top: 0px;
  font-family: var(--text-family-code);
}

.import-file-container-height-control {
  max-height: 100px;
  min-height: 100px;
}

.import-modal-import-settings.file-upload {
  max-height: 380px;
}

.import-paste-feature-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}

.import-paste-feature-icon {
  padding-right: 6px;
}

.import-folder-button {
  margin-bottom: 291px;
  margin-left: 10px;
}

.import-repository-modal-state {
  margin-left: auto;
  margin-right: auto;
}

.drop-zone-height-manip {
  height: 280px;
  transition: height 0.1s;
}

.import-modal-footer-top {
  padding-bottom: 6px;
}

.drop-zone-height-manip.invisible {
  height: 0px !important;
  visibility: hidden;
  display: none;
}

.import-modal-import-definition-files-checkbox-padding {
  padding-top: 4px;
  padding-left: 22px;
  height: 52px;
  max-width: 558px;
}

.import-modal-import-definition-files-preview-padding {
  padding-top: 8px;
  padding-left: 22px;
  width: 558px;
}

.import-api-preview-heading {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-bold);
}

.import-container__offline-banner {
  display: flex;
  align-items: center;
  background-color: var(--background-color-warning);
  padding: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
.import-container__offline-banner .import-container__offline-banner__icon use {
  fill: var(--content-color-warning);
}
.import-container__offline-banner .import-container__offline-banner__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-warning);
}
.import-container__offline-banner .import-container__offline-banner__icon svg {
  color: var(--content-color-warning);
}
.import-container__offline-banner .import-container__offline-banner__message {
  margin-left: var(--spacing-l);
  color: var(--content-color-warning);
}

.import-files-options-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
}
.import-files-options-container .import-files-options-header {
  display: flex;
  flex-direction: column;
}
.import-files-options-container .import-files-options-header__content {
  display: flex;
  flex-direction: row;
}
.import-files-options-container .import-files-options-header__content-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-m);
  margin-right: var(--spacing-s);
}
.import-files-options-container .import-files-options-header__content-selected-files {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-m);
  margin-left: var(--spacing-s);
}
.import-files-options-container .import-files-options {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  height: 100%;
}
.import-files-options-container .import-files-options-warning {
  margin-top: var(--spacing-xs);
}
.import-files-options-container .import-files-options .import-file-list-combined {
  max-height: 20vh;
  overflow-y: auto;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__header {
  padding-left: var(--spacing-zero);
  flex: 0 0 30px;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__header__cell {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  padding-left: var(--spacing-m);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__header__cell__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--content-color-secondary);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row .dropdown {
  margin: var(--spacing-zero) 5px;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row .dropdown-button .btn {
  background-color: transparent;
  padding: var(--spacing-zero);
  color: var(--content-color-primary);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row:last-child {
  display: none;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row:nth-last-child(2) {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row .data-editor__custom-row.is-focused .data-editor__cells .data-editor__cell:last-child, .import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row .data-editor__custom-row:hover .data-editor__cells .data-editor__cell:last-child {
  padding-right: var(--spacing-zero);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row .data-editor__custom-row .data-editor__cell {
  align-items: center;
  justify-content: flex-start;
  padding-left: var(--spacing-s);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__row .data-editor__custom-row .data-editor__cell__content .auto-suggest {
  color: var(--content-color-primary);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-enabled-cell, .import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-name-cell, .import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-format-cell {
  width: 100%;
  display: flex;
  padding: var(--spacing-xs);
  justify-content: flex-start;
  align-items: center;
  color: var(--content-color-primary);
  white-space: pre;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__custom-row.is-disabled .import-file-list__column-enabled-cell, .import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__custom-row.is-disabled .import-file-list__column-name-cell, .import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__custom-row.is-disabled .import-file-list__column-format-cell, .import-files-options-container .import-files-options .import-file-list-container .import-file-list .data-editor__custom-row.is-disabled .import-file-list__import-as-format {
  color: var(--content-color-tertiary);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-enabled-cell {
  justify-content: center;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-enabled-cell .is-selected {
  background-color: var(--base-color-brand);
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-name-cell-content {
  text-overflow: ellipsis;
  overflow: hidden;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-name-cell-folder-icon .btn {
  cursor: default;
}
.import-files-options-container .import-files-options .import-file-list-container .import-file-list__column-format-cell-content {
  text-overflow: ellipsis;
  overflow: hidden;
}
.import-files-options-container .import-files-options .import-select-workspace-container {
  margin-top: var(--spacing-xxl);
}
.import-files-options-container .import-files-options .import-select-workspace-container .import-select-workspace-dropdown-header {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.import-files-options-container .import-files-options .import-select-workspace-container .import-select-workspace-dropdown {
  display: flex;
  margin-top: var(--spacing-s);
}
.import-files-options-container .import-files-options .import-select-workspace-container .import-select-workspace-dropdown .import-select-workspace-dropdown-button {
  flex: 0 0 300px;
}
.import-files-options-container .import-files-options .import-select-workspace-container .import-select-workspace-dropdown .import-select-workspace-dropdown-button .btn {
  width: 100%;
  justify-content: space-between;
}
.import-files-options-container .import-files-options .import-generate-collection-container {
  margin-top: var(--spacing-xxl);
}
.import-files-options-container .import-files-options .import-generate-collection-container .import-generate-collection-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.import-files-options-container .import-files-options .import-generate-collection-container .import-generate-collection-checkbox .input-checkbox.is-selected {
  background-color: var(--base-color-brand);
}
.import-files-options-container .import-files-options .import-generate-collection-container .import-generate-collection-checkbox .import-generate-collection-checkbox-label {
  margin-left: 10px;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.import-files-options-container .import-files-options .import-generate-collection-container .import-select-api-element-dropdown-header {
  margin-left: 28px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
}
.import-files-options-container .import-files-options .import-generate-collection-container .import-select-api-element-dropdown-header.is-disabled {
  color: var(--content-color-tertiary);
  opacity: 0.6;
}
.import-files-options-container .import-files-options .import-options-container {
  margin-left: 28px;
}
.import-files-options-container .import-files-options .import-options-container.generation-disabled {
  margin-top: var(--spacing-l);
  margin-left: 0;
}
.import-files-options-container .import-files-options .import-options-container .import-options-header {
  display: flex;
  align-items: center;
  margin: var(--spacing-s) var(--spacing-zero);
  color: var(--content-color-primary);
  cursor: pointer;
}
.import-files-options-container .import-files-options .import-options-container .import-options-header .toggle-button-icon {
  margin-right: 6px;
}
.import-files-options-container .import-files-options .import-options-container .import-options-header.is-disabled {
  color: var(--content-color-tertiary);
  opacity: 0.6;
}
.import-files-options-container .import-files-options .import-options-container .import-options-header.is-disabled .toggle-button-icon use {
  fill: var(--content-color-tertiary);
}
.import-files-options-container .import-files-options .import-options-container .import-options-header.is-disabled .toggle-button-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.import-files-options-container .import-files-options .import-options-container .import-options-header.is-disabled .toggle-button-icon svg {
  color: var(--content-color-tertiary);
}
.import-files-options-container .import-files-options .import-options-container .import-options-content-wrapper {
  margin-left: var(--spacing-xs);
  margin-top: var(--spacing-l);
}
.import-files-options-container .import-files-options .import-options-container .import-options-content-wrapper .import-options-content {
  margin-bottom: var(--spacing-xxl);
}
.import-files-options-container .import-files-options .import-options-container .import-options-content-wrapper .import-options-content .import-options-content-header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
}
.import-files-options-container .import-files-options .import-options-container .import-options-content-wrapper .import-options-content .options-wrapper {
  padding: 10px;
  border: 1px solid var(--border-color-default);
}

.import-file-list__import-as-format {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  margin: var(--spacing-zero) 5px;
}
.import-file-list__import-as-format--disabled-api-text {
  text-decoration: line-through;
  color: var(--content-color-secondary);
}

.import-files-options-container .import-files-banner {
  padding: var(--spacing-l);
  background-color: var(--background-color-error);
  border-radius: var(--border-radius-default);
  margin-bottom: var(--spacing-m);
}
.import-files-options-container .import-files-banner .error {
  display: flex;
}
.import-files-options-container .import-files-banner .error__content-right {
  margin-left: var(--spacing-s);
  flex: 1;
}
.import-files-options-container .import-files-banner .error__message {
  margin-top: var(--spacing-xs);
}

.import-files-configurations-values {
  max-width: 200px;
  padding-right: 2px;
  justify-content: flex-end !important;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.bg-act-action-cta {
  padding-right: var(--spacing-xs) !important;
}
.bg-act-action-cta.single-activity {
  width: var(--spacing-l);
  padding-right: var(--spacing-zero);
  margin-right: var(--spacing-s);
}

.bg-activity-status-wrapper {
  z-index: 1;
  padding-left: var(--spacing-m);
}

.bg-activity-status-badge {
  cursor: pointer;
}
.bg-activity-status-badge__icon {
  vertical-align: middle;
  margin-right: var(--spacing-xs);
}

.bg-activity-status-badge-text {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 768px) {
  .bg-activity-status-badge-text {
    display: none;
  }
}
.bg-activty-content-flex {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bg-act-task-status-icon {
  padding-right: var(--spacing-m) !important;
}
.bg-act-task-status-icon.heading {
  padding-right: var(--spacing-xs) !important;
}

.bg-act-task-status-link {
  width: var(--spacing-xl);
  height: var(--spacing-xl) !important;
  margin-right: 8px !important;
}

.bg-act-task-status-link-heading {
  width: var(--spacing-xl);
  height: var(--spacing-xl);
  margin-left: calc(-1 * var(--spacing-xs));
}

.bg-act-heading-retry-text {
  font-size: 11px;
}

.bg-act-content-flex {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
  margin-left: calc(-1 * var(--spacing-s));
  background-color: var(--background-color-secondary);
  padding-left: var(--spacing-s) !important;
}

.bg-act-section-heading {
  background-color: var(--background-color-primary);
  height: 52px;
  padding-left: var(--spacing-m) !important;
  margin-top: calc(-1 * var(--spacing-s));
  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-right: var(--spacing-s) !important;
}
.bg-act-section-heading.single-activity {
  margin-top: -6px;
  margin-bottom: -6px;
}

.bg-act-section-content {
  background-color: var(--background-color-secondary);
  height: var(--spacing-xxl);
  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
  padding-left: var(--spacing-m) !important;
}
.bg-act-section-content.blink {
  background-color: var(--background-color-success);
  transition: background-color 0.5s linear;
}
.bg-act-section-content:hover {
  background-color: var(--highlight-background-color-primary);
}

.bg-act-section-wrapper {
  cursor: pointer;
}

.bg-act-section-content-component-text {
  max-width: 170px !important;
}
.bg-act-section-content-component-text.child-failed {
  max-width: 120px !important;
}

.bg-act-section-content-component-tooltip {
  margin-left: var(--spacing-xs);
}

.bg-act-content-top-border {
  background-color: var(--border-color-default);
  margin-left: calc(-1 * var(--spacing-s));
  height: 1px;
  width: 360px;
}

.bg-act-content-top-header {
  height: var(--spacing-xs);
  background-color: var(--background-color-secondary);
  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
}

.bg-act-content-footer {
  margin-bottom: calc(-1 * var(--spacing-s));
  border-bottom-right-radius: var(--spacing-xs);
  border-bottom-left-radius: var(--spacing-xs);
  height: var(--spacing-xs);
  background-color: var(--background-color-secondary);
  margin-left: calc(-1 * var(--spacing-s));
  margin-right: calc(-1 * var(--spacing-s));
}

@-webkit-keyframes badge-color-transition {
  0% {
    background-color: var(--content-color-secondary);
  }
  100% {
    background-color: var(--content-color-tertiary);
  }
}
@keyframes badge-color-transition {
  0% {
    background-color: var(--content-color-secondary);
  }
  100% {
    background-color: var(--content-color-tertiary);
  }
}
.bg-act-badge-counter {
  padding: var(--spacing-zero) 2.5px;
  background-color: var(--content-color-secondary);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  min-width: 7px;
  height: var(--spacing-m);
}
.bg-act-badge-counter.error {
  background-color: var(--base-color-error);
}
.bg-act-badge-counter.blink {
  -webkit-animation-name: badge-color-transition;
  animation-name: badge-color-transition;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.bg-activity-popover {
  padding-bottom: 6px;
}

.cookies-management-container {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.interceptor-bootcamp-lesson-banner {
  position: relative;
  padding: 0px 8px;
  background-color: var(--background-color-secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.interceptor-bootcamp-lesson-banner-description {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.interceptor-bootcamp-lesson-banner-button {
  height: 24px;
  font-weight: 600;
  line-height: 20px;
}
.interceptor-bootcamp-lesson-banner--dismiss {
  margin-left: var(--spacing-xs);
  flex-shrink: 0;
  cursor: pointer;
}

.cookies-management-domain-list-empty {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: var(--text-weight-medium);
  font-size: 16px;
  line-height: 28px;
}
.cookies-management-domain-list-empty .cookies-management-interceptor-lesson {
  display: flex;
  flex-direction: column;
}
.cookies-management-domain-list-empty .cookies-management-interceptor-lesson__text {
  font-weight: normal;
  padding-top: 8px;
  padding-bottom: 36px;
  font-size: 14px;
  line-height: 24px;
}

.cookies-management-banner-domain-list-wrapper {
  flex: 1;
}

.cookies-management-domain-list-wrapper {
  flex: 1;
  height: 460px;
  overflow-y: auto;
  border-radius: var(--border-radius-default);
}

.cookies-management-domain-list-item {
  min-height: 30px;
  align-items: center;
  border-bottom: 1px solid var(--border-color-strong);
  cursor: pointer;
}
.cookies-management-domain-list-item .cookies-management-domain-list-item__header {
  display: flex;
  padding: 5px 10px;
  align-items: center;
}
.cookies-management-domain-list-item .cookies-management-domain-list-item__header .cookies-management-domain-list-item__header__actions {
  visibility: hidden;
}
.cookies-management-domain-list-item .cookies-management-domain-list-item__header .cookies-management-domain-list-item__header__action-delete {
  margin-right: 10px;
}
.cookies-management-domain-list-item .cookies-management-domain-list-item__header .cookies-management-domain-list-item__header__name {
  color: var(--content-color-primary);
  margin: 7px 0;
}
.cookies-management-domain-list-item .cookies-management-domain-list-item__header .cookies-management-domain-list-item__header__cookie-count {
  padding-left: 10px;
  color: var(--content-color-secondary);
  flex: 1;
}
.cookies-management-domain-list-item:hover .cookies-management-domain-list-item__header, .cookies-management-domain-list-item.is-hovered .cookies-management-domain-list-item__header {
  background-color: var(--highlight-background-color-primary);
}
.cookies-management-domain-list-item:hover .cookies-management-domain-list-item__header__actions, .cookies-management-domain-list-item.is-hovered .cookies-management-domain-list-item__header__actions {
  visibility: visible;
}
.cookies-management-domain-list-item.is-selected {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-primary);
}
.cookies-management-domain-list-item.is-selected:hover .cookies-management-domain-list-item__header, .cookies-management-domain-list-item.is-selected.is-hovered .cookies-management-domain-list-item__header {
  background-color: var(--highlight-background-color-secondary);
}

.cookies-management-cookies-list-wrapper {
  overflow-y: auto;
  font-size: 10px;
  padding-left: 10px;
  margin-top: 10px;
}

.cookies-management-cookies-empty-list {
  padding: 10px;
  text-align: center;
}

.cookies-management-cookies-list-item {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  padding: 5px 5px 5px 10px;
  border-radius: var(--border-radius-default);
  margin-right: 10px;
  margin-bottom: 10px;
  color: var(--content-color-secondary);
  background-color: var(--background-color-primary);
  cursor: pointer;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.cookies-management-cookies-list-item .cookies-management-cookies-list-item__name {
  color: var(--content-color-secondary);
}
.cookies-management-cookies-list-item .cookies-management-cookies-list-item__remove {
  padding: 5px 5px;
  margin-left: 10px;
  cursor: pointer;
}
.cookies-management-cookies-list-item .cookies-management-cookies-list-item__remove:hover .cookies-management-cookies-list-item__remove-icon use, .cookies-management-cookies-list-item .cookies-management-cookies-list-item__remove.is-hovered .cookies-management-cookies-list-item__remove-icon use {
  fill: var(--base-color-brand);
}
.cookies-management-cookies-list-item .cookies-management-cookies-list-item__remove:hover .cookies-management-cookies-list-item__remove-icon svg:not([class*=PartnerIcon]) > path[fill], .cookies-management-cookies-list-item .cookies-management-cookies-list-item__remove.is-hovered .cookies-management-cookies-list-item__remove-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.cookies-management-cookies-list-item .cookies-management-cookies-list-item__remove:hover .cookies-management-cookies-list-item__remove-icon svg, .cookies-management-cookies-list-item .cookies-management-cookies-list-item__remove.is-hovered .cookies-management-cookies-list-item__remove-icon svg {
  color: var(--base-color-brand);
}
.cookies-management-cookies-list-item.is-selected {
  background-color: var(--highlight-background-color-secondary);
}
.cookies-management-cookies-list-item.is-selected .cookies-management-cookies-list-item__name {
  color: var(--content-color-primary);
}
.cookies-management-cookies-list-item.is-selected:hover, .cookies-management-cookies-list-item.is-selected.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.cookies-management-cookies-list-item:hover, .cookies-management-cookies-list-item.is-hovered {
  background-color: var(--highlight-background-color-primary);
}

.cookies-management-cookie-preview-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.cookies-management-cookie-domain-preview-interceptor-lesson {
  height: 32px;
}

.cookies-management-cookie-preview {
  flex: 2;
  margin-left: 20px;
}

.cookies-management-cookie-actions-container {
  display: flex;
  flex-direction: row;
}

.cookies-management-cookie-domain-actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.cookies-management-cookie-actions {
  display: flex;
  flex: 2;
  margin-left: 20px;
  justify-content: flex-end;
}

.cookies-management-cookie-action-button {
  margin-left: 10px;
  height: 32px;
}

.cookies-management-cookie-editor-wrapper {
  margin: 0 10px;
}
.cookies-management-cookie-editor-wrapper .cookies-management-cookie-editor {
  margin: 10px 0;
  border: 1px solid var(--border-color-strong);
  border-radius: var(--border-radius-default);
  height: 100px;
}
.cookies-management-cookie-editor-wrapper .cookies-management-cookie-editor__actions {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cookies-management-cookie-editor-wrapper .cookies-management-cookie-editor__actions .cookies-management-cookie-editor__action {
  margin-left: 10px;
}

.cookie-management-container-domain-whitelist__back {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.cookie-management-container-domain-whitelist__back .cookie-management-container-domain-whitelist__back__text {
  font-size: 12px;
  font-weight: 600;
  color: var(--content-color-brand);
  margin-left: 8px;
}
.cookie-management-container-domain-whitelist__back .cookie-management-container-domain-whitelist__back__icon {
  display: flex;
  align-items: center;
}

.cookies-management-domain-whitelist-back_button {
  margin-left: -8px;
}

.cookies-management-domain-whitelist-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 496px;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-xl);
}
.cookies-management-domain-whitelist-container .cookies-management-domain-whitelist-container__add-domain {
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-l);
}
.cookies-management-domain-whitelist-container .cookies-management-domain-whitelist-container__add-domain .cookies-management-domain-whitelist-container__input {
  height: 35px;
}
.cookies-management-domain-whitelist-container .cookies-management-domain-whitelist-container__add-domain .cookies-management-domain-whitelist-container__add-button {
  height: 35px;
  max-width: 60px;
  min-width: 60px;
  margin-left: 10px;
  color: var(--content-color-primary);
}
.cookies-management-domain-whitelist-container .cookies-management-domain-whitelist-list__empty {
  color: var(--content-color-tertiary);
  margin-top: 25px;
  user-select: none;
}

.cookies-management-domain-whitelist-list {
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 350px;
}

.cookies-management-domain-whitelist-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 32px;
  padding: 0 10px;
  align-items: center;
  user-select: none;
}
.cookies-management-domain-whitelist-list-item:hover {
  background-color: var(--background-color-secondary);
}
.cookies-management-domain-whitelist-list-item .cookies-management-domain-whitelist-list-item__name {
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
}
.cookies-management-domain-whitelist-list-item .cookies-management-domain-whitelist-list-item__action {
  display: flex;
  cursor: pointer;
}

.cookies-management-cookies__actions--add {
  margin: 0 0 10px 0;
}

.cookie-management-container__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--spacing-xl);
}
.cookie-management-container__footer .pm-link {
  text-decoration: underline;
  color: var(--content-color-secondary);
}
.cookie-management-container__footer .learn-more__section {
  color: var(--content-color-secondary);
  padding: var(--spacing-s) 10px;
  float: right;
  display: inline-flex;
  align-items: center;
  font-size: calc(var(--size-xs) - 3);
}

.cookies-management-modal-content-wrapper {
  margin-bottom: var(--spacing-s);
}
.cookies-management-modal-content-wrapper .cookies-management-modal-content {
  height: 620px;
}
.cookies-management-modal-content-wrapper .cookies-management-modal-content .cookies-unavailable {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cookies-management-modal-content-wrapper .cookies-management-modal-content .cookies-unavailable .cookies-unavailable__icon {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/cookie.svg");
}
.cookies-management-modal-content-wrapper .cookies-management-modal-content .cookies-unavailable .cookies-unavailable__message {
  white-space: pre-line;
  width: 416px;
}

.cookie-jar-modal-tabs {
  padding: var(--spacing-zero) !important;
  margin-top: calc(-1 * var(--spacing-xs));
}

.cookie-jar-add-domain-button-container {
  width: calc(var(--size-s) * 4);
}

.cookies-management-modal {
  margin-bottom: var(--spacing-m);
}

.cookie-jar-capture-cookies-section .cookie-jar-capture-cookies-section_tabs {
  padding-right: var(--spacing-zero);
  padding-left: var(--spacing-zero);
  padding-bottom: var(--spacing-zero);
  padding-top: var(--spacing-m);
}
.cookie-jar-capture-cookies-section .cookie-jar-capture-cookies-section_web {
  margin-top: var(--spacing-l);
}

.cookie-jar-capture-cookies-button {
  width: calc(var(--controls-size-small) * 4 + 8);
}

.cookie-jar-domain-list-item {
  align-items: center;
  font-size: calc(var(--size-xs) - 4);
  padding: var(--spacing-xs) var(--spacing-s);
  background-color: var(--background-color-tertiary);
  border-radius: calc(var(--size-xs) / 4);
  margin-bottom: var(--spacing-xs);
}

.cookie-jar-domain-list-item-close-icon {
  padding-top: var(--spacing-xs);
  cursor: pointer;
}

.proxy-settings-content {
  padding: 20px;
  font-size: 12px;
  overflow: auto;
}

.proxy-settings-connection-wrapper {
  padding: 0px;
}

.proxy-settings-toggle-switch-container {
  padding: 8px 20px 0px 20px;
  font-size: 12px;
}

.proxy-capture-requests-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.proxy-settings-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.proxy-settings-save-filters {
  padding-bottom: 16px;
}

.proxy-modal .modal-content {
  overflow: auto;
}

.proxy-settings-connection-group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.proxy-settings-connection-input {
  flex: 1;
}

.proxy-settings-connection-label {
  flex: 0 0 32px;
}

.proxy-settings-connection-target-action-label {
  flex: 1;
  margin-right: 0;
}

.proxy-settings-filters-container {
  padding-bottom: 16px;
}

.proxy-settings-filters-group {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.proxy-settings-filters-label {
  font-style: normal;
  font-weight: 600;
  flex: 0 0 32px;
  padding-right: 20px;
}

.proxy-settings-filters-input {
  flex: 1;
}

.proxy-settings-filters-input-help {
  color: var(--content-color-secondary);
  padding-top: 4px;
}

.proxy-settings-filters-wrapper {
  overflow: auto;
}

.proxy-settings-show-filters {
  margin-top: 16px;
  max-height: 100px;
}
.proxy-settings-show-filters .show-filters-btn {
  margin: 0px;
  padding: 0px;
}

.proxy-show-filters-btn {
  margin-top: 16px;
  padding: 0px;
}

.update-checking .update-checking__progress {
  margin: 20px 0;
}

.update-available .update-available__version {
  font-weight: 600;
}
.update-available .update-available__loading-container {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
}
.update-available .update-available__notes-loader {
  flex: 1;
  text-align: center;
}

.update-not-available .update-not-available__message {
  display: flex;
  align-items: center;
}
.update-not-available .update-not-available__message .postman-logo {
  height: 100px;
  width: 100px;
  flex: 0 0 100px;
  background-image: url("../assets/images/thumbnails/postman-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
}
.update-not-available .update-not-available__button {
  margin-top: 10px;
}

.update-downloading .update-downloading__progress {
  margin: 20px 0;
}

.beta-update {
  margin-top: 20px;
}
.beta-update .beta-update__heading {
  font-size: 14px;
  font-weight: 600;
}
.beta-update .beta-update__button {
  margin-top: 10px;
}

.update-idle .update-idle__button {
  margin-top: 10px;
}

.prscript-error-container {
  padding: var(--spacing-s);
  margin: var(--spacing-s);
  background-color: var(--background-color-error);
  border-radius: var(--border-radius-default);
  text-align: center;
}

.notification-container {
  position: relative;
  display: inline-block;
}

.notification-tabs {
  padding: 0px !important;
}
.notification-tabs > div:first-child {
  padding-left: 12px;
  margin-bottom: 5px;
  border-bottom: 1px solid var(--border-color-default);
}

.notification-tab-list li {
  padding-right: 4px;
}

.notification-feed {
  position: absolute;
  top: 100%;
  right: 0;
}
@media screen and (max-width: 666px) {
  .notification-feed.responsive-for-windows-app {
    position: fixed;
    top: inherit;
    left: 14px;
  }
}
.notification-feed.is-hidden {
  display: none;
}
.notification-feed .notification-panel-list-error-item__text {
  color: var(--content-color-secondary);
  text-align: center;
  padding: var(--spacing-xl) 0;
}
.notification-feed .notification-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notification-feed .notification-panel-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: var(--text-size-m);
}
.notification-feed .notification-panel-list .notification-panel-list-item {
  display: flex;
  height: auto;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
}
.notification-feed .notification-panel-list .notification-panel-list-item .notification-panel-list-item__body {
  display: flex;
  align-items: center;
}
.notification-feed .notification-panel-list .notification-panel-list-item .notification-panel-list-item__body .notification-panel-list-item__content {
  flex: 1;
  overflow: hidden;
}
.notification-feed .notification-panel-list .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
}
.notification-feed .notification-panel-list .notification-panel-list-error-item__text {
  color: var(--content-color-secondary);
  text-align: center;
  padding: var(--spacing-xl) 0;
}

.notification-container {
  border-radius: var(--border-radius-default);
}

.notifications-button-icon--unread:after {
  content: "";
  background-color: var(--base-color-brand);
  position: absolute;
  border-radius: var(--border-radius-max);
  top: 4px;
  left: 22px;
  height: 7px;
  width: 7px;
}

.notifications-button-icon-badge {
  background: var(--base-color-brand);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: -2px;
  width: 18px;
  height: 18px;
  font-weight: 400;
  font-size: 9px;
  border-radius: 50%;
}

.notification-feed {
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-default);
  width: 406px;
  margin-top: 3px;
  border-radius: var(--border-radius-default);
  margin-bottom: 10px;
}
.notification-feed .loading-indicator-wrapper {
  padding: 20px;
}
.notification-feed .notification-panel {
  max-height: 440px;
  overflow-y: auto;
  overflow-x: hidden;
}
.notification-feed .notification-panel-header__actions {
  display: flex;
}
.notification-feed .notification-panel-header {
  padding: 10px;
  border-bottom: 1px solid var(--border-color-default);
}
.notification-feed .notification-panel-header .notification-panel-header__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--content-color-primary);
}
.notification-feed .notification-panel-header .notification-panel-header__mark-all-read {
  color: var(--content-color-link);
  font-size: 12px;
  margin-right: 8px;
  line-height: 20px;
  cursor: pointer;
}
.notification-feed .notification-panel-header .btn {
  font-size: 11px;
}
.notification-feed .notification-panel-list-empty-item {
  font-size: 12px;
  color: var(--content-color-primary);
  text-align: center;
}
.notification-feed .notification-panel-list-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 340px;
}
.notification-feed .notification-panel-list-empty-text-primary {
  font-size: var(--text-size-l);
  margin: var(--spacing-l) 0 var(--spacing-xs);
  font-weight: var(--text-weight-bold);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.notification-feed .notification-panel-list-empty-text-secondary {
  font-size: var(--text-size-m);
  margin: var(--spacing-xs) 0;
  color: var(--content-color-secondary);
}
.notification-feed .btn {
  padding-left: 0;
}
.notification-feed .signin-btn {
  padding: 0;
  margin-right: 3px;
  min-width: initial;
}
.notification-feed .notification-panel-list {
  width: 100%;
  padding-bottom: var(--spacing-xl) 0;
  font-size: var(--text-size-m);
}
.notification-feed .notification-category-icon {
  width: 20px;
  height: 20px;
  background-color: var(--background-color-secondary);
  border-radius: 50%;
  display: flex;
  position: absolute;
  left: 24px;
  top: 20px;
  align-items: center;
  justify-content: center;
}
.notification-feed .notification-category-icon use {
  fill: var(--content-color-brand);
}
.notification-feed .notification-category-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.notification-feed .notification-category-icon svg {
  color: var(--content-color-brand);
}
.notification-feed .notification-action-button {
  font-weight: 600;
  margin-top: 8px;
  height: 32px;
  padding: 8px 12px;
}
.notification-feed .notification-status {
  display: flex;
  justify-content: center;
  align-self: baseline;
  margin-left: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--background-color-brand);
  cursor: pointer;
}
.notification-feed .notification-status-unread {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  align-self: center;
  background-color: var(--base-color-brand);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.notification-feed .notification-panel-list-item {
  border-bottom: 1px solid var(--border-color-default);
  font-size: 12px;
  padding: 10px;
}
.notification-feed .notification-panel-list-item.are-tabs-enabled {
  padding: 10px 0;
}
.notification-feed .notification-panel-list-item.is-unread {
  background-color: var(--background-color-primary);
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body {
  color: var(--content-color-primary);
  line-height: 16px;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .notification-panel-list-item__icon {
  align-self: flex-start;
  padding: 0 15px 0 5px;
  position: relative;
  min-width: 32px;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .notification-item-custom-base {
  font-size: 11px;
  color: var(--content-color-secondary);
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .notification-item-custom-base p {
  display: block;
  margin: 0;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .notification-item-custom-base a {
  color: var(--content-color-primary);
  text-decoration: none;
  word-break: break-word;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .markdown-notification-read strong {
  font-weight: 600;
  color: var(--content-color-secondary);
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .markdown-notification-read p {
  color: var(--content-color-secondary);
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .markdown-notification-unread strong {
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .markdown-notification-unread strong:hover {
  color: var(--content-color-link);
  text-decoration: underline;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .markdown-notification-unread p {
  color: var(--content-color-secondary);
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .notification-panel-list-item__content .notification-panel-list-item__content__text-markdown p {
  display: block;
  margin: 0;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .notification-panel-list-item__content .notification-panel-list-item__content__text-markdown a {
  color: var(--content-color-primary);
  text-decoration: none;
  word-break: break-word;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__body .notification-panel-list-item__content__text {
  height: 32px;
  overflow: hidden;
}
.notification-feed .notification-panel-list-item .notification-panel-list-item__content__meta {
  color: var(--content-color-tertiary);
  font-size: 11px;
  margin: 4px 0;
}
.notification-feed .notification-panel-list-end {
  font-size: 12px;
  color: var(--content-color-primary);
  text-align: center;
  padding: 20px;
}

.access-request-approval > .btn {
  padding: var(--spacing-s) 10px;
}

.changelog-learn-more-header {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-bottom: var(--spacing-l);
}
.changelog-learn-more-header__left-container {
  display: flex;
  flex: 1;
  align-items: center;
}
.changelog-learn-more-header .changelog-learn-more-header__icon {
  margin: 10px;
}
.changelog-learn-more-header .changelog-learn-more-header__text {
  color: var(--content-color-link);
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.changelog-learn-more-header__refresh-container {
  margin-left: var(--spacing-xs);
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.changelog-learn-more-header__refresh-container.is-loading-new {
  cursor: default;
}
.changelog-learn-more-header__refresh-container.is-loading-new .changelog-learn-more-header__refresh-icon {
  -webkit-animation: rotating 1s linear infinite;
}
.changelog-learn-more-header__refresh-container.is-disabled {
  cursor: default;
  opacity: 0.3;
}

.activity-feed-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.activity-feed {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
  color: var(--content-color-primary);
}

.activity-feed-loader {
  height: auto;
  padding: 20px;
  text-align: center;
}

.load-more-error-banner {
  font-size: 12px;
  color: var(--content-color-primary);
  background-color: var(--background-color-error);
}

.activity-feed-initial-fetch-error-container {
  padding: 20px;
  text-align: center;
}
.activity-feed-initial-fetch-error-container .initial-fetch-error-title {
  font-weight: var(--text-weight-medium);
  margin-bottom: 12px;
}
.activity-feed-initial-fetch-error-container .initial-fetch-error-subtitle {
  line-height: 20px;
  margin-bottom: 24px;
}
.activity-feed-initial-fetch-error-container .activity-feed-retry__btn {
  height: 32px;
  width: 43%;
  font-weight: 600;
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
}
.activity-feed-initial-fetch-error-container .activity-feed-retry__btn:hover {
  background-color: var(--highlight-background-color-tertiary);
  color: var(--content-color-primary);
}
.activity-feed-initial-fetch-error-container .activity-feed-retry__btn.is-disabled {
  opacity: 0.3;
  cursor: default;
}

.activity-feed-load-more-error-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activity-feed-load-more-error-container .load-more-error-info {
  display: flex;
  align-items: center;
}
.activity-feed-load-more-error-container .load-more-error-icon use {
  fill: var(--base-color-error);
}
.activity-feed-load-more-error-container .load-more-error-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.activity-feed-load-more-error-container .load-more-error-icon svg {
  color: var(--base-color-error);
}
.activity-feed-load-more-error-container .load-more-error-subtitle {
  text-align: left;
  margin: 0 20px;
}
.activity-feed-load-more-error-container .activity-feed-retry__btn {
  background-color: initial;
  font-weight: 600;
  color: var(--base-color-error);
}
.activity-feed-load-more-error-container .activity-feed-retry__btn.is-disabled {
  opacity: 0.3;
  cursor: default;
}

.activity-feed-date-group {
  padding-bottom: 10px;
  margin: 10px;
  font-size: 13px;
  font-weight: 600;
  color: var(--content-color-secondary);
  margin-left: 0;
}

.activity-feed-empty-container {
  padding: 30px 0;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: var(--content-color-secondary);
  text-align: center;
}
.activity-feed-empty-container .activity-feed-empty-signin-link {
  color: var(--content-color-link);
  cursor: pointer;
}

.activity-feed-context-bar__title {
  position: relative;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  color: var(--content-color-primary);
}

.activity-item-content {
  padding: 10px 0;
  width: 100%;
}

.activity-feed-connect-line {
  position: relative;
  top: 20px;
  left: 26px;
  z-index: -1;
  border-left: 1px solid var(--border-color-default);
}

.activity-item-header {
  display: flex;
  padding-bottom: 10px;
}
.activity-item-header .activity-item-head {
  flex: 1;
  margin-right: 20px;
}
.activity-item-header .activity-item-head > * {
  margin-bottom: 5px;
}

.activity-item-body {
  padding: 10px;
  margin-left: 50px;
  border: 1px solid var(--border-color-strong);
}

.activity-item-details-wrapper {
  padding: 0 10px;
}

.activity-item-details {
  border-left: 2px solid var(--border-color-default);
  margin-left: 14px;
  padding-left: 24px;
}

.activity-item__action {
  margin-left: 50px;
  color: var(--content-color-link);
  display: inline-block;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.activity-item__action-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.activity-item__action .activity-item-remove-tag.is-disabled {
  opacity: 0.3;
  cursor: default;
}
.activity-item__action .activity-item-restore {
  margin-left: var(--spacing-s);
  padding-left: var(--spacing-s);
  border-left: 1px solid var(--border-color-default);
}
.activity-item__action .activity-item-restore-loader {
  display: inline-block;
  margin-left: 16px;
  position: relative;
  top: 4px;
}

.activity-item-profile-pic-wrapper {
  margin: 0 16px auto 0;
  cursor: pointer;
}

.activity-item-meta {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 600;
  color: var(--content-color-tertiary);
}
.activity-item-meta .version-label-wrapper {
  display: flex;
  max-width: 100%;
}
.activity-item-meta .activity-item-tagged_version__label {
  margin-left: 8px;
  border-radius: var(--border-radius-default);
  padding: 0px 6px;
  border: 1px solid var(--border-color-default);
}
.activity-item-meta .activity-item-tagged_version__label.is-tag {
  color: var(--content-color-primary);
  background: var(--backgrouund-color-tertiary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  max-width: 70px;
}

.activity-item-heading {
  color: var(--content-color-primary);
}
.activity-item-heading .activity-item-head-content {
  display: inline;
}

.activity-item-model {
  font-weight: 600;
  color: var(--content-color-primary);
}

.activity-item-user {
  font-weight: 600;
  color: var(--content-color-primary);
  cursor: pointer;
}
.activity-item-user:hover {
  color: var(--content-color-link);
  text-decoration: underline;
}

.activity-item-wrapper:last-child .activity-feed-connect-line {
  visibility: hidden;
}

.activity-item-details-content {
  margin: 0 10px 10px;
  font-size: 11px;
  line-height: 12px;
}
.activity-item-details-content.normal-view {
  display: inline-flex;
  align-items: center;
}

.activity-item-details-content-left {
  margin-right: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-self: baseline;
}

.activity-item-details-content-right {
  font-style: normal;
  color: var(--content-color-secondary);
}

.activity-item-details-content-data {
  font-style: italic;
}

.activity-item-details-diff-view {
  margin-top: 10px;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.activity-item-details-diff-view .diff-wrapper {
  max-height: 300px;
}

.modal-content.is-modal-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content.version-tag__modal-content {
  overflow-y: hidden;
  padding-bottom: var(--spacing-m);
  padding-top: var(--spacing-m);
}

.version-tag__modal-content-heading {
  padding-bottom: 10px;
}

.version-tag__learn-btn-text {
  padding: 0px;
}

.version-tag__modal-content-dropdown {
  padding: 10px 0px;
}

.api-select__dropdown, .api-version-select__dropdown, .api-relation-select__dropdown {
  padding: 4px 0px;
}
.api-select__dropdown .dropdown-label, .api-version-select__dropdown .dropdown-label, .api-relation-select__dropdown .dropdown-label {
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 4px;
}
.api-select__dropdown .dropdown-head, .api-version-select__dropdown .dropdown-head, .api-relation-select__dropdown .dropdown-head {
  width: 310px;
  justify-content: space-between;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--border-color-strong);
  background: var(--background-color-secondary);
}
.api-select__dropdown .dropdown-head-label, .api-version-select__dropdown .dropdown-head-label, .api-relation-select__dropdown .dropdown-head-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  color: var(--content-color-primary);
}
.api-select__dropdown .dropdown-head-label.placeholder-text, .api-version-select__dropdown .dropdown-head-label.placeholder-text, .api-relation-select__dropdown .dropdown-head-label.placeholder-text {
  color: var(--content-color-tertiary);
}
.api-select__dropdown .dropdown-menu-list, .api-version-select__dropdown .dropdown-menu-list, .api-relation-select__dropdown .dropdown-menu-list {
  min-height: 30px;
}

.version-tag__modal-content-action {
  display: flex;
  padding-top: 10px;
}
.version-tag__modal-content-action .modal-cancel-btn {
  margin-right: 10px;
  font-weight: 600;
}
.version-tag__modal-content-action .modal-version-tag-btn {
  width: 100%;
  font-weight: 600;
}

.version-tag-modal-error {
  height: 100%;
  transform: scale(0.8);
}

.remove-tag-confirmation-modal__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.remove-tag-confirmation-modal__action {
  font-weight: 600;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
.remove-tag-confirmation-modal__action .btn.is-focused {
  box-shadow: var(--shadow-focus);
}

.remove-tag-confirmation-modal__delete-button {
  background: var(--base-color-error);
  margin-left: 10px;
}
.remove-tag-confirmation-modal__delete-button:hover, .remove-tag-confirmation-modal__delete-button.is-hovered {
  background-color: var(--highlight-background-color-error);
}
.remove-tag-confirmation-modal__delete-button:active, .remove-tag-confirmation-modal__delete-button.is-active {
  background-color: var(--highlight-background-color-error);
}
.remove-tag-confirmation-modal__delete-button:focus, .remove-tag-confirmation-modal__delete-button.is-focused {
  background-color: var(--highlight-background-color-error);
}
.remove-tag-confirmation-modal__delete-button.is-disabled:hover, .remove-tag-confirmation-modal__delete-button.is-disabled.is-hovered {
  background-color: var(--highlight-background-color-error);
}
.remove-tag-confirmation-modal__delete-button.is-disabled:active, .remove-tag-confirmation-modal__delete-button.is-disabled.is-active {
  background-color: var(--highlight-background-color-error);
}
.remove-tag-confirmation-modal__delete-button.is-disabled:focus, .remove-tag-confirmation-modal__delete-button.is-disabled.is-focused {
  background-color: var(--highlight-background-color-error);
}

.workspace-activity-feed-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.workspace-activity-feed-name-header {
  padding: 20px 20px 30px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: var(--content-color-primary);
  background-color: var(--background-color-secondary);
  border-bottom: 1px solid var(--border-color-strong);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workspace-activity-feed-name-header .workspace-activity-feed-name-header-content {
  display: inline;
}

.workspace-activity-feed {
  padding: 0 176px;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 12px;
  color: var(--content-color-primary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.workspace-activity-feed-loading-container {
  padding: 20px;
  text-align: center;
}

.workspace-activity-feed-loadmore-container {
  flex: 1;
  text-align: center;
}

.workspace-activity-item-loadfeed {
  height: 50px;
}

.workspace-activity-feed-date-group-wrapper {
  margin-top: var(--spacing-s);
}

.workspace-activity-feed-date-group {
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
}

.workspace-activity-feed-empty-container {
  padding: 30px 0;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: var(--content-color-secondary);
  text-align: center;
}
.workspace-activity-feed-empty-container .workspace-activity-feed-empty-signin-link {
  color: var(--content-color-link);
  cursor: pointer;
}

.workspace-activity-feed__end {
  color: var(--content-color-secondary);
  text-align: center;
  margin-bottom: 48px;
  font-size: 12px;
}

.workspace-activity-item-wrapper {
  display: flex;
  margin-bottom: var(--spacing-s);
}

.workspace-activity-item-content {
  width: 100%;
}
.workspace-activity-item-content:hover a {
  color: var(--content-color-link);
}
.workspace-activity-item-content a {
  text-decoration: none;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
}
.workspace-activity-item-content a:hover {
  text-decoration: underline;
}

.workspace-activity-feed-connect-line {
  position: relative;
  top: 20px;
  left: 26px;
  z-index: -1;
  border-left: 1px solid var(--border-color-default);
}

.workspace-activity-item-header {
  display: flex;
}
.workspace-activity-item-header .workspace-activity-item-head {
  flex: 1;
  margin-right: 20px;
}
.workspace-activity-item-header .workspace-activity-item-head blockquote {
  padding-left: 16px;
  margin-left: 0px;
  border-left: 3px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.workspace-activity-item-header .workspace-activity-item-head .workspace-activity-item-heading {
  word-break: break-all;
}
.workspace-activity-item-header .workspace-activity-item-head .workspace-activity-item-heading p {
  margin: var(--spacing-zero);
}
.workspace-activity-item-header .workspace-activity-item-head .workspace-activity-item-heading p strong {
  font-weight: 600;
}

.workspace-activity-item-body {
  padding: 10px;
  margin-left: 50px;
  border: 1px solid var(--border-color-strong);
}

.workspace-activity-item-details-wrapper {
  padding: 0 10px;
}

.workspace-activity-item-details {
  border-left: 3px solid var(--border-color-strong);
  margin-left: 14px;
  padding-left: 13px;
}

.workspace-activity-item-profile-pic-wrapper {
  padding-right: var(--spacing-s);
  display: flex;
  align-items: baseline;
  margin-top: var(--spacing-xs);
}
.workspace-activity-item-profile-pic-wrapper .pm-user-avatar-icon-NaN {
  background-image: none;
}

.workspace-activity-item-meta {
  color: var(--content-color-secondary);
}
.workspace-activity-item-meta .workspace-activity-item-user {
  font-weight: 500;
  color: var(--content-color-secondary);
  margin-right: 5px;
}

.workspace-activity-item-heading {
  color: var(--content-color-secondary);
}

.workspace-activity-item-user,
.workspace-activity-item-folder,
.workspace-activity-item-response,
.workspace-activity-item-request-name,
.workspace-activity-item-collection-name {
  font-weight: 600;
  color: var(--content-color-primary);
}

.workspace-activity-item-user {
  font-size: 11px;
}

.workspace-activity-item-restore {
  margin-left: 5px;
  color: var(--content-color-link);
  display: inline-block;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.workspace-activity-item-restore.workspace-activity-item-restore--is-restoring {
  display: inline-block;
}

.workspace-activity-item-private-user p strong:first-child a {
  pointer-events: none;
  cursor: default;
}

.workspace-activity-item-details-content {
  margin: 0 10px 10px;
  font-size: 11px;
  line-height: 12px;
}
.workspace-activity-item-details-content.normal-view {
  display: inline-flex;
  align-items: center;
}

.workspace-activity-item-details-content-left {
  margin-right: 10px;
  font-weight: 600;
  text-transform: uppercase;
  align-self: baseline;
}

.workspace-activity-item-details-content-right {
  font-style: normal;
  color: var(--content-color-secondary);
}

.workspace-activity-item-details-content-data {
  font-style: italic;
}

.workspace-activity-item-details-diff-view {
  margin-top: 10px;
  border: 1px solid var(--border-color-strong);
  border-radius: var(--border-radius-default);
}
.workspace-activity-item-details-diff-view .diff-wrapper {
  max-height: 300px;
}

.workspace-activity-feed-filter-container {
  display: flex;
  justify-content: space-between;
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter {
  display: flex;
  align-items: center;
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed__search-button {
  height: 32px;
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed__clear-button span span:hover {
  border-bottom-color: transparent;
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-heading {
  line-height: var(--size-s);
  vertical-align: middle;
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types {
  display: flex;
  margin-left: var(--spacing-s);
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container {
  min-width: unset;
  height: var(--size-s);
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: var(--border-radius-default);
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container .dropdown-button {
  width: 100%;
  border-radius: var(--border-radius-default);
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container .dropdown-button .select-dropdown-btn {
  color: var(--content-color-primary);
  height: var(--size-s);
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container.workspace-members {
  margin-left: var(--spacing-xs);
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .entity-select-container .dropdown {
  margin-left: 16px;
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container .is-open .select-dropdown-btn, .workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container .selected .select-dropdown-btn {
  background-color: var(--highlight-background-color-secondary);
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container .is-open .select-dropdown-btn i svg, .workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-types .select-dropdown-container .selected .select-dropdown-btn i svg {
  color: var(--content-color-secondary);
}
.workspace-activity-feed-filter-container .workspace-activity-feed-filter .workspace-activity-feed-filter-actions {
  margin-right: var(--spacing-l);
}

.workspace-activity-feed-error-state {
  text-align: center;
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  color: var(--content-color-primary);
  margin-top: 10vh;
  overflow-y: auto;
  line-height: var(--line-height-m);
}
.workspace-activity-feed-error-state__image {
  margin: auto;
}
.workspace-activity-feed-error-state__label {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
}
.workspace-activity-feed-error-state__description {
  margin-top: var(--spacing-s);
}
.workspace-activity-feed-error-state__refresh-btn {
  margin-top: var(--spacing-l);
}

.certificate-list-wrapper {
  border-top: 1px solid var(--border-color-default);
  padding-top: var(--spacing-l);
}
.certificate-list-wrapper .certificate-list-wrapper__heading {
  display: flex;
  justify-content: space-between;
}
.certificate-list-wrapper-desc {
  margin-top: var(--spacing-xs);
}
.certificate-list-wrapper-desc > .learn-more-button {
  margin-top: var(--spacing-s);
}
.certificate-list-wrapper .certificate-list {
  flex: 1;
  margin-top: var(--spacing-l);
}

.certificate-list-wrapper__heading {
  display: flex;
  align-items: center;
}
.certificate-list-wrapper__heading .certificate-list-wrapper__heading__title {
  flex: 1;
}

.certificate-list-item {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: var(--spacing-xs) var(--spacing-zero);
}

.certificate-list-item__row {
  display: flex;
}
.certificate-list-item__row .certificate-list-item__key {
  display: flex;
  align-items: center;
  flex: 0 0 80px;
}
.certificate-list-item__row .certificate-list-item__key .warning-button {
  margin: 0 0 2px 5px;
}
.certificate-list-item__row .certificate-list-item__value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.certificate-list-item__row .certificate-list-item__controls {
  flex: 0 0 auto;
  cursor: pointer;
  color: var(--content-color-error);
}

.certificate-list-item__value-password {
  display: inline-block;
}
.certificate-list-item__value-password input {
  padding-right: var(--spacing-xxl);
  height: var(--size-s);
  width: auto;
}
.certificate-list-item__value-password .password-input__toggle i {
  padding: var(--spacing-xs);
}

.add-certificate-fileuploader-wrapper .file-input-container {
  width: fit-content;
}
.add-certificate-fileuploader-wrapper .file-input {
  padding: var(--spacing-zero);
}
.add-certificate-fileuploader-wrapper .file-input .btn {
  background-color: var(--button-secondary-background-color);
  color: var(--content-color-primary);
  height: var(--size-m);
}
.add-certificate-fileuploader-wrapper .file-input .btn:hover {
  background-color: var(--button-secondary-hover-background-color);
}
.add-certificate-fileuploader-wrapper .file-input .btn.is-disabled {
  cursor: not-allowed;
}
.add-certificate-fileuploader-wrapper .file-input .btn:not(.is-disabled):hover {
  border-color: var(--button-outline-hover-border-color);
}
.add-certificate-fileuploader-wrapper .file-input .btn .text {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
.add-certificate-fileuploader-wrapper .file-input__name {
  background-color: var(--background-color-tertiary);
  border: none;
  height: var(--size-m);
  padding: var(--spacing-xs) var(--spacing-s);
}

.ca-certificate-wrapper {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-top: var(--spacing-l);
}
.ca-certificate-wrapper .ca-certificate-wrapper__heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ca-certificate-wrapper-desc {
  margin-top: var(--spacing-xs);
}
.ca-certificate-wrapper .ca-certificate__field {
  display: flex;
  margin-top: var(--spacing-xs);
}
.ca-certificate-wrapper .ca-certificate__field .ca-certificate__field__label {
  flex: none;
  margin-right: var(--spacing-s);
  display: flex;
  align-items: center;
}
.ca-certificate-wrapper .ca-certificate__field .ca-certificate__field__label .warning-button {
  margin: 0 0 1px 5px;
}
.ca-certificate-wrapper .ca-certificate__field.disabled {
  display: none;
}
.ca-certificate-wrapper .ca-certificate__field .file-input {
  padding: var(--spacing-zero);
}
.ca-certificate-wrapper .ca-certificate__field .file-input .btn {
  color: var(--content-color-primary);
  height: var(--size-s);
}
.ca-certificate-wrapper .ca-certificate__field .file-input .btn.is-disabled {
  cursor: not-allowed;
}
.ca-certificate-wrapper .ca-certificate__field .file-input .btn:hover {
  background-color: var(--highlight-background-color-primary);
}
.ca-certificate-wrapper .ca-certificate__field .file-input .btn:not(.is-disabled):hover {
  border-color: var(--button-outline-hover-border-color);
}
.ca-certificate-wrapper .ca-certificate__field .file-input .btn .text {
  font-weight: var(--text-weight-regular);
}
.ca-certificate-wrapper .ca-certificate__field .file-input__name {
  background-color: var(--background-color-tertiary);
  border: none;
  height: var(--size-s);
  padding: var(--spacing-xs) var(--spacing-s);
}

.explorer {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  border-radius: var(--border-radius-default);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  border: 1px solid var(--border-color-strong);
  flex: 1;
  min-height: 100px;
}
.explorer__create-in-progress-loading-state {
  display: flex;
  height: 30px;
  align-items: center;
  padding: var(--spacing-zero) var(--spacing-s);
  animation: fade 1s ease-in-out infinite;
}
.explorer__create-in-progress-loading-state__item {
  display: flex;
  width: 100%;
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-default);
  height: var(--size-xs);
}
.explorer .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.explorer .explorer__header-wrapper {
  background-color: var(--background-color-secondary);
}
.explorer .explorer__header__create-wrapper {
  display: flex;
  align-items: center;
  padding: 15px 5px 5px 5px;
}
.explorer .explorer__header__create-wrapper .explorer__header__breadcrumb-wrapper {
  color: var(--content-color-secondary);
}
.explorer .explorer__header__create-wrapper .explorer__header__create {
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-left: auto;
  font-size: 12px;
  color: var(--content-color-link);
  cursor: pointer;
}
.explorer .explorer__create {
  display: flex;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
}
.explorer .explorer-row__create {
  border: 1px solid var(--content-color-link);
  border-radius: 3px;
  width: 100%;
  padding-left: 5px;
}
.explorer .explorer-row__create.input-search-group {
  height: 24px;
}
.explorer .explorer-row__create.input-search {
  height: 22px;
}
.explorer .explorer-row__create .input::-webkit-input-placeholder {
  font-size: 12px;
}
.explorer .explorer__header-title {
  margin-right: 20px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.explorer .explorer-pop-btn {
  display: flex;
  flex: 3;
  cursor: pointer;
  align-items: center;
  padding-right: 20px;
  overflow: hidden;
}
.explorer .explorer-pop-btn:hover .explorer-pop-btn__text, .explorer .explorer-pop-btn.is-hovered .explorer-pop-btn__text {
  text-decoration: underline;
}
.explorer .explorer-pop-btn .explorer-pop-btn__text {
  margin-left: 5px;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.explorer .explorer-pop-btn .explorer-pop-btn__meta {
  margin-left: 8px;
  min-width: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.explorer .explorer__header-create-btn {
  flex: 1;
  color: var(--content-color-link);
  cursor: pointer;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.explorer .explorer-search {
  min-height: 30px;
  display: flex;
  align-items: center;
}
.explorer .explorer-search .input-search-group {
  flex: 1;
  border: 0px;
  background-color: var(--background-color-secondary);
}
.explorer .explorer-search .input-search-group.is-focused {
  border: 1px solid var(--base-color-brand);
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
}
.explorer .explorer-search .input-search-group:not(.is-focused) {
  border: 1px solid var(--background-color-secondary);
  border-bottom: 1px solid var(--border-color-strong);
  border-radius: 0;
}
.explorer .explorer-search .input-search-group .input::-webkit-input-placeholder {
  font-size: 12px;
}
.explorer .explorer__empty-state {
  align-self: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.explorer .explorer-rows,
.explorer .explorer-results {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
.explorer .explorer-create-row {
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.explorer .explorer-create-row .input-search-group__search-cancel-wrapper {
  display: none;
}
.explorer .explorer-create-row .explorer-row__save {
  margin-left: var(--spacing-xs);
  margin-right: 1px;
}
.explorer .explorer-create-row .explorer-row__cancel {
  margin-right: var(--spacing-m);
}
.explorer .explorer-row__icon {
  align-self: flex-start;
  margin: 7px;
  padding-top: var(--spacing-xs);
}
.explorer .explorer-row__icon .explorer-row__icon-leaf-icon {
  font-weight: 700;
  font-size: 9px;
}
.explorer .explorer-row {
  display: flex;
  cursor: pointer;
  align-items: center;
  color: var(--content-color-primary);
}
.explorer .explorer-row.is-disabled, .explorer .explorer-row.is-leaf {
  cursor: default;
  opacity: 0.4;
}
.explorer .explorer-row.explorer-result {
  padding: var(--spacing-s) var(--spacing-zero);
}
.explorer .explorer-row.explorer-result .explorer-row__text {
  padding-left: 0;
}
.explorer .explorer-row.explorer-result .explorer-row__icon {
  padding-top: 2px;
  margin-top: var(--spacing-zero);
}
.explorer .explorer-row .explorer-row__name {
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.explorer .explorer-row .explorer-row__icons {
  margin-right: 8px;
}
.explorer .explorer-row .explorer-row__icons:empty {
  margin-right: 0;
}
.explorer .explorer-row .explorer-row__meta {
  overflow: hidden;
  min-width: 30px;
  display: flex;
  align-items: center;
}
.explorer .explorer-row .explorer-row__content {
  overflow-x: hidden;
}
.explorer .explorer-row .explorer-result__path {
  padding-right: 20px;
  color: var(--content-color-tertiary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.explorer .explorer-row .explorer-result__path .highlight {
  opacity: 1;
  color: var(--content-color-secondary);
}
.explorer .explorer-row .explorer-row__forward {
  flex: 1;
  margin: 7px;
  visibility: hidden;
  justify-content: flex-end;
}
.explorer .explorer-row:hover:not(.is-leaf), .explorer .explorer-row.is-hovered:not(.is-leaf) {
  background-color: var(--highlight-background-color-secondary);
}
.explorer .explorer-row:hover .explorer-row__forward, .explorer .explorer-row.is-hovered .explorer-row__forward {
  visibility: visible;
}
.explorer .explorer-row__selectedRequest {
  background-color: var(--highlight-background-color-secondary);
}
.explorer .request-method--GET {
  color: var(--base-color-success);
}
.explorer .request-method--PUT {
  color: var(--content-color-info);
}
.explorer .request-method--POST {
  color: var(--base-color-warning);
}
.explorer .request-method--DELETE {
  color: var(--base-color-error);
}

.status-bar-container.status-bar {
  display: flex;
  /* put status bar at the bottom of the pane in collapsed + uncollapsed states */
  position: absolute;
  bottom: 0;
  right: 0;
  height: var(--size-s);
}
.status-bar-container .sb-section {
  display: flex;
  flex: 1;
}

.sb__item {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--content-color-secondary);
  cursor: pointer;
  margin: var(--spacing-zero) var(--spacing-s);
  box-sizing: border-box;
}
.sb__item use {
  fill: var(--content-color-secondary);
}
.sb__item svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.sb__item svg {
  color: var(--content-color-secondary);
}
.sb__item .sb__item__icon {
  height: inherit;
  display: flex;
  align-items: center;
}
.sb__item:hover .sb__item__icon svg, .sb__item.is-hovered .sb__item__icon svg {
  color: var(--content-color-primary);
}
.sb__item:hover .sb__item__text, .sb__item.is-hovered .sb__item__text {
  color: var(--content-color-primary);
}
.sb__item:hover .sb__item__text use, .sb__item.is-hovered .sb__item__text use {
  fill: var(--content-color-primary);
}
.sb__item:hover .sb__item__text svg:not([class*=PartnerIcon]) > path[fill], .sb__item.is-hovered .sb__item__text svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.sb__item:hover .sb__item__text svg, .sb__item.is-hovered .sb__item__text svg {
  color: var(--content-color-primary);
}
.sb__item:hover .copyright-text .copyright-text__meta, .sb__item.is-hovered .copyright-text .copyright-text__meta {
  color: var(--content-color-secondary);
  cursor: default;
}
.sb__item.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.sb__item.is-active .sb__item__text {
  color: var(--content-color-primary);
}

.sb__item__text {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-family: var(--text-family-default);
  cursor: pointer;
  max-width: 175px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sb__item__pane {
  position: absolute;
  bottom: 30px;
  right: 0;
  height: 200px;
  background-color: var(--background-color-secondary);
  width: 100%;
  z-index: 30;
  box-shadow: var(--shadow-default);
}
.sb__item__pane .plugin__pane-resize-wrapper {
  width: 100%;
  padding: 5px 0;
  cursor: row-resize;
  position: absolute;
}
.sb__item__pane.is-hidden {
  display: none;
}
.sb__item__pane .plugin__pane-close {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 10px;
}

.sb__drawer {
  position: relative;
}
.sb__drawer .dropdown .dropdown-menu {
  direction: ltr;
  bottom: 25px;
  right: -20px;
  min-width: initial;
}

.plugin__xFlowActivityFeed {
  position: relative;
}
.plugin__xFlowActivityFeed .sb__item__icon {
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plugin__xFlowActivityFeed .sb__item__icon svg {
  position: absolute;
}
.plugin__xFlowActivityFeed.ripple-effect .sb__item__icon {
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--base-color-brand), 0.3), 0 0 0 0.25em rgba(var(--base-color-brand), 0.3), 0 0 0 0.5em rgba(var(--base-color-brand), 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.25em rgba(var(--base-color-brand), 0.3), 0 0 0 0.5em rgba(var(--base-color-brand), 0.3), 0 0 0 1em rgba(var(--base-color-brand), 0);
  }
}
.plugin__workspace-view-switcher .plugin__workspace-view-switcher__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 174px;
  height: 24px;
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
  direction: ltr;
  position: relative;
}
.plugin__workspace-view-switcher .plugin__workspace-view-switcher__wrapper.disabled .plugin__workspace-view-switcher__switch__item {
  cursor: default;
}
.plugin__workspace-view-switcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  flex: 0 0 85px;
  text-align: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: var(--content-color-secondary);
  cursor: pointer;
  line-height: 24px;
}
.plugin__workspace-view-switcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  content: "";
  position: absolute;
  width: 85px;
  height: 20px;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--border-color-strong);
  color: var(--content-color-primary);
  text-align: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 20px;
  transition: left 0.3s;
  transition-timing-function: ease-out;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.plugin__workspace-view-switcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.left {
  left: 0;
}
.plugin__workspace-view-switcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.right {
  left: 87px;
}

.workspace-type-switcher__intro__wrap {
  max-width: 360px;
}

.workspace-type-switcher__intro {
  padding: 15px;
  text-align: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.workspace-type-switcher__intro .workspace-type-switcher__intro__title {
  color: var(--content-color-primary);
  font-weight: 600;
  font-size: 14px;
}
.workspace-type-switcher__intro .workspace-type-switcher__intro__description {
  color: var(--content-color-primary);
  font-size: 12px;
  margin: 10px 0;
}
.workspace-type-switcher__intro .workspace-type-switcher__intro__learn-more {
  margin-bottom: 20px;
}
.workspace-type-switcher__intro .workspace-type-switcher__intro__primary-btn .btn {
  width: 190px;
}

.plugin__help .plugin__help__drawer {
  display: flex;
}

.help-plugin-dropdown-menu {
  bottom: 24px;
}

.plugin__activeTrack .active-track-button {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  cursor: pointer;
}
.plugin__activeTrack .active-track-button .active-track-label {
  margin-left: var(--spacing-xs);
}
.plugin__activeTrack .active-track-button .sb__item__text {
  margin-bottom: var(--spacing-xs);
}

@media only screen and (max-width: 1024px) {
  .active-track-label {
    display: none;
  }
}
.trash-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.trash-label-button-disabled {
  cursor: not-allowed;
}
.trash-label-text {
  margin-left: var(--spacing-xs);
  font-size: var(--text-size-s);
}
.trash-label-text:hover {
  color: var(--content-color-primary);
}
@media only screen and (max-width: 1024px) {
  .trash-label-text {
    display: none;
  }
}

.runner-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.runner-label-button__disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.runner-label-button .runner-label-text {
  margin-left: var(--spacing-xs);
}

@media only screen and (max-width: 480px) {
  .runner-label-text {
    display: none;
  }
}
.copyright-container {
  max-width: none;
}
.copyright-text {
  direction: ltr;
}
.copyright-link {
  text-decoration: underline;
  color: var(--content-color-link);
}
.copyright-link:hover {
  cursor: pointer;
  color: var(--content-color-link);
}

.branch-switcher {
  max-width: 250px;
  align-self: center;
}
.branch-switcher .branch-switcher-trigger {
  margin-left: var(--spacing-s);
  cursor: pointer;
  color: var(--content-color-secondary);
}
.branch-switcher .branch-switcher-trigger use {
  fill: var(--content-color-secondary);
}
.branch-switcher .branch-switcher-trigger svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.branch-switcher .branch-switcher-trigger svg {
  color: var(--content-color-secondary);
}
.branch-switcher .branch-switcher-trigger :hover {
  color: var(--content-color-primary);
}
.branch-switcher .branch-switcher-trigger :hover use {
  fill: var(--content-color-primary);
}
.branch-switcher .branch-switcher-trigger :hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.branch-switcher .branch-switcher-trigger :hover svg {
  color: var(--content-color-primary);
}
@media only screen and (max-width: 768px) {
  .branch-switcher .branch-switcher-trigger > div > div, .branch-switcher .branch-switcher-trigger > div > span {
    display: none;
  }
}
.branch-switcher .branch-switcher-icon use {
  fill: var(--content-color-primary);
}
.branch-switcher .branch-switcher-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.branch-switcher .branch-switcher-icon svg {
  color: var(--content-color-primary);
}

.capture-requests-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.capture-requests-label-button .capture-requests-label-text {
  margin-left: var(--spacing-xs);
}

@media only screen and (max-width: 1024px) {
  .capture-requests-label-text {
    display: none;
  }
}
.plugin__startProxySession .start-proxy-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.plugin__startProxySession .start-proxy-label-button .start-proxy-label-text {
  margin-left: var(--spacing-xs);
}
.plugin__startProxySession .start-proxy-label-button .start-proxy-label-live-text {
  margin-left: var(--spacing-xs);
  color: var(--content-color-success);
  font-weight: bold;
}
.plugin__startProxySession .start-proxy-label-button.proxy-active use {
  fill: var(--content-color-success);
}
.plugin__startProxySession .start-proxy-label-button.proxy-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.plugin__startProxySession .start-proxy-label-button.proxy-active svg {
  color: var(--content-color-success);
}

@media only screen and (max-width: 1024px) {
  .start-proxy-label-text {
    display: none;
  }
  .start-proxy-label-live-text {
    display: none;
  }
}
.plugin__webSocketProxy .web-socket-proxy-button {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  cursor: pointer;
}
.plugin__webSocketProxy .web-socket-proxy-button .web-socket-proxy-label {
  margin-left: var(--spacing-xs);
}
.plugin__webSocketProxy .web-socket-proxy-button .sb__item__text {
  margin-bottom: var(--spacing-xs);
}

@media only screen and (max-width: 1024px) {
  .web-socket-proxy-label {
    display: none;
  }
}
.cookies-label-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.cookies-label-button .cookies-label-text {
  margin-left: var(--spacing-xs);
}
@media only screen and (max-width: 1024px) {
  .cookies-label-button .cookies-label-text {
    display: none;
  }
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.vault-status-container {
  position: relative;
  z-index: 0;
}
.vault-status-container .vault-button-container.hovered .vault-shortcut-container {
  display: flex;
}
.vault-status-container .vault-button-container .vault-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.vault-status-container .vault-button-container .vault-button .vault-buton-label {
  margin-left: var(--spacing-xs);
}
.vault-status-container .vault-button-container .vault-shortcut-container {
  position: absolute;
  top: -56px;
  padding: 6px var(--spacing-s);
  background-color: var(--tooltip-background-color);
  border-radius: var(--border-radius-s);
  box-shadow: var(--tooltip-box-shadow);
  border: 1px solid var(--tooltip-border-color);
}
.vault-status-container .vault-button-container .vault-feature-highlighter {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  overflow: clip;
  background-color: transparent;
}
.vault-status-container .vault-button-container .vault-feature-highlighter svg {
  margin: -8px auto;
}
.vault-status-container .vault-button-container .vault-feature-highlighter svg > path, .vault-status-container .vault-button-container .vault-feature-highlighter svg:hover > path {
  fill: #E4D8F6 !important;
}

.vault-sidebar-container .sidebar-list-item__content-wrapper {
  margin-left: 0px !important;
}

.vault-sidebar-dropdown-menu {
  min-width: 100px;
}

.vault-sidebar-cloud-vault-info {
  text-align: center;
}

.monitor-schedule-selector {
  display: flex;
  width: 500px;
  box-sizing: border-box;
}
.monitor-schedule-selector .dropdown {
  margin: 0 5px;
}
.monitor-schedule-selector .dropdown:first-child {
  margin-left: 0;
}
.monitor-schedule-selector .dropdown:last-child {
  margin-right: 0;
}
.monitor-schedule-selector .dropdown.time-type-dropdown {
  flex: 0 0 130px;
}
.monitor-schedule-selector .dropdown.time-option-dropdown {
  flex: 0 0 240px;
}
.monitor-schedule-selector .dropdown.time-dropdown {
  flex: 0 0 100px;
}

.monitor-info-context-view-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.monitor-info-context-view-wrapper .right-context-bar__title {
  color: var(--content-color-primary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-zero) var(--spacing-s);
  overflow-y: auto;
  --base-height: 20px;
  --min-height: 2px;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity {
  margin-bottom: var(--spacing-l);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__label {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-tertiary);
  margin-bottom: var(--min-height);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__icon {
  margin-right: var(--spacing-s);
  margin-top: var(--min-height);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  display: flex;
  flex: 1;
  padding-right: var(--spacing-xxl);
  align-items: center;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content .activity-item-profile-pic-wrapper {
  margin: 0;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content .activity-item-profile-pic-wrapper .pm-user-avatar-icon {
  height: var(--size-xs);
  width: var(--size-xs);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content .activity-item-user {
  margin-left: var(--spacing-s);
  font-weight: var(--text-weight-regular);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content .monitor-info-btn {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  pointer-events: none;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content .monitor-info-text-link {
  padding: var(--spacing-s);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content .monitor-info-text-link .create-monitor-collection-item__link {
  width: 100%;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content .monitor-info-text-link .create-monitor-collection-item__link > .aether-link-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__id {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px var(--spacing-s) 6px var(--spacing-s);
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  width: 100%;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__collection, .monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__environment {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__collection, .monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__environment {
  margin-left: calc(var(--spacing-s) * -1);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__environment-empty {
  color: var(--content-color-primary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__collection-empty {
  color: var(--content-color-primary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle {
  background-color: transparent;
  height: var(--size-s);
  width: var(--size-s);
  margin-left: var(--spacing-m);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy {
  --spacing-medium: 8px;
  padding: var(--spacing-medium);
  border-radius: var(--border-radius-default);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy use {
  fill: var(--content-color-secondary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy svg {
  color: var(--content-color-secondary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy:hover, .monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy.is-hovered {
  background-color: var(--background-color-tertiary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy:hover use, .monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy.is-hovered use {
  fill: var(--content-color-primary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy:hover svg:not([class*=PartnerIcon]) > path[fill], .monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy:hover svg, .monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__copy.is-hovered svg {
  color: var(--content-color-primary);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__success {
  cursor: default;
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__success use {
  fill: var(--content-color-success);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content__toggle__success svg {
  color: var(--content-color-success);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content--collection {
  padding-right: var(--spacing-l);
}
.monitor-info-context-view-wrapper .monitor-info-context-view__entity__content--environment {
  padding-right: var(--spacing-zero);
}
.monitor-info-context-view-wrapper .monitor-info-context-view .user-info {
  display: flex;
  align-items: center;
}
.monitor-info-context-view-wrapper .monitor-info-context-view .user-info.is-clickable {
  cursor: pointer;
}
.monitor-info-context-view-wrapper .monitor-info-context-view .user-info__avatar {
  margin-top: var(--spacing-xs);
  margin-right: var(--spacing-s);
}
.monitor-info-context-view-wrapper .monitor-info-context-view .user-info__text {
  margin-top: var(--spacing-xs);
}

.monitor-info-empty-state {
  visibility: hidden;
  max-height: 0;
}

.monitor-info-userinfo-offline {
  pointer-events: none;
}

.monitor-info-collection {
  display: flex;
  align-items: center;
  padding: var(--spacing-s);
}
.monitor-info-collection__collection-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-info-collection__collection-name > .aether-link-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-info-collection__collection-name-offline {
  padding: 0;
}
.monitor-info-collection__collection-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-info-collection__qualifiers {
  flex: 0 1 auto;
  margin-left: 8px;
  text-overflow: ellipsis;
}
.monitor-info-collection__name {
  margin-right: var(--spacing-s);
}
.monitor-info-collection__fork-label {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
}
.monitor-info-collection__fork-label use {
  fill: var(--content-color-secondary);
}
.monitor-info-collection__fork-label svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.monitor-info-collection__fork-label svg {
  color: var(--content-color-secondary);
}
.monitor-info-collection__fork-label:hover use, .monitor-info-collection__fork-label.is-hovered use {
  fill: var(--content-color-primary);
}
.monitor-info-collection__fork-label:hover svg:not([class*=PartnerIcon]) > path[fill], .monitor-info-collection__fork-label.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.monitor-info-collection__fork-label:hover svg, .monitor-info-collection__fork-label.is-hovered svg {
  color: var(--content-color-primary);
}
.monitor-info-collection__collection-version-tag {
  padding: 0px var(--spacing-s);
  border-radius: 30px;
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  background-color: var(--background-color-tertiary);
  line-height: var(--line-height-s);
}
.monitor-info-collection__collection-version-tag__current {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background-color: var(--background-color-primary);
  color: var(--content-color-tertiary);
}

.contextCustomScroll {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);
}

.loader-link {
  --margin-small: 5px;
  color: var(--content-color-link);
  margin-right: var(--margin-small);
  cursor: pointer;
  text-decoration: underline;
}

.loader-activity-logs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-link-name {
  --margin-small: 5px;
  font-weight: var(--text-weight-regular);
  cursor: pointer;
  color: var(--content-color-link);
  margin-right: var(--margin-small);
}

.pm-activity-section__refresh__btn {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  margin-top: var(--margin-spacing-custom-top);
  margin-right: var(--margin-spacing-custom-right);
  height: var(--size-m);
  width: var(--size-m);
  padding: var(--spacing-zero);
}
.pm-activity-section__refresh__btn:hover, .pm-activity-section__refresh__btn.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.pm-activity-section__refresh__btn:hover use, .pm-activity-section__refresh__btn.is-hovered use {
  fill: var(--content-color-primary);
}
.pm-activity-section__refresh__btn:hover svg:not([class*=PartnerIcon]) > path[fill], .pm-activity-section__refresh__btn.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.pm-activity-section__refresh__btn:hover svg, .pm-activity-section__refresh__btn.is-hovered svg {
  color: var(--content-color-primary);
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.pm-activity-section__refresh__btn.is-loading {
  cursor: default;
}
.pm-activity-section__refresh__btn.is-loading .pm-activity-section__refresh__btn__icon {
  -webkit-animation: rotating 1s linear infinite;
}

.monitor-activity {
  --activity-feed-content-margin: 56px;
  padding: var(--spacing-zero) var(--spacing-s);
  overflow-y: auto;
}
.monitor-activity .pm-activity-list-item {
  list-style-type: none;
}
.monitor-activity .pm-activity-list-item .pm-user-icon--custom {
  background-color: var(--background-color-primary);
}
.monitor-activity .pm-activity-section .show-user ~ .show-user {
  border-top: 1px solid var(--highlight-background-color-primary);
}
.monitor-activity .pm-monitor-activity-section__date {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.monitor-activity .pm-activity-list-item__text .user-info {
  display: inline-block;
  align-items: center;
  --margin-small: 5px;
  font-weight: var(--text-weight-medium);
  margin-right: var(--margin-small);
}
.monitor-activity .pm-activity-list-item__text .user-info.is-clickable {
  cursor: pointer;
}
.monitor-activity .pm-activity-list-item {
  color: var(--content-color-primary);
  border: none;
  padding: var(--spacing-s) 0;
  margin: var(--spacing-s) 0;
}
.monitor-activity .pm-monitor-activity-list-item__icon {
  position: absolute;
  margin-left: var(--spacing-s);
  width: var(--size-m);
  height: var(--size-m);
  border-radius: var(--size-m);
}
.monitor-activity .pm-activity-list-item__text {
  color: var(--content-color-primary);
  margin-left: var(--activity-feed-content-margin);
}
.monitor-activity .pm-activity-list-item__time {
  color: var(--content-color-tertiary);
  margin-left: var(--activity-feed-content-margin);
}
.monitor-activity__load-more {
  padding: var(--spacing-l) 0;
  text-align: center;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.monitor-activity .pm-activity-list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: var(--spacing-l);
  overflow: scroll;
  height: 100%;
}
.monitor-activity .pm-activity-list-item__description {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.monitor-activity .pm-activity-list-item__description strong {
  font-weight: var(--text-weight-medium);
}

.activity-title {
  margin-top: 0;
  margin-bottom: var(--spacing-l);
}

/* //////////////////////////////////////////// */
.pm-activity-list-item {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  position: relative;
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-xxl);
  border-left: 1px solid var(--border-color-default);
  border: none;
  margin: var(--spacing-s) 0;
}

.pm-activity-list-item__time {
  margin: 0;
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xs);
  display: inline-block;
}

.activity-logs-public-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-l);
}
.activity-logs-public-view__entity {
  margin-bottom: var(--spacing-l);
  font-size: var(--text-size-m);
}

.activity-logs-empty-state-no-logs, .activity-logs-empty-state-no-logs-with-filters {
  text-align: center;
  margin: 60px 0px 100px 0px;
  font-size: var(--text-size-s);
}
.activity-logs-empty-state-no-logs .empty-state__title, .activity-logs-empty-state-no-logs-with-filters .empty-state__title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.activity-logs-empty-state-no-logs .empty-state__body, .activity-logs-empty-state-no-logs-with-filters .empty-state__body {
  color: var(--content-color-primary);
}
.activity-logs-empty-state-no-logs .empty-state__image.activity, .activity-logs-empty-state-no-logs-with-filters .empty-state__image.activity {
  display: block;
  width: 160px;
  height: 126px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor-activity.svg");
}

.activity-logs-empty-state-no-logs-with-filters {
  padding-top: var(--activity-feed-content-margin);
}
.activity-logs-empty-state-no-logs-with-filters .empty-state__image.activity {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor-activity-with-filters.svg");
}

.activity-user-filter-dropdown-no-user {
  padding: var(--spacing-s) var(--spacing-xs);
}

.activity-logs-offline {
  pointer-events: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.monitor-job {
  color: var(--content-color-primary);
  line-height: initial;
  font-size: var(--text-size-m);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l);
}
.monitor-job .loading-indicator-wrapper {
  margin: auto;
}
.monitor-job .not-allowed-state {
  padding: var(spacing-xxl) 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.monitor-job .not-allowed-state__text {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}
.monitor-job__header {
  margin-top: var(--spacing-l);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.monitor-job__header .monitor-performance__region-selector {
  width: auto;
  margin-right: 0;
}
.monitor-job__header .monitor-performance__region-selector label {
  display: none;
  margin: var(--spacing-xs) 0;
}
.monitor-job__header small {
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
@media (min-width: 768px) {
  .monitor-job__header .monitor-performance__region-selector label {
    display: block;
  }
}
.monitor-job__summary--error {
  display: flex;
  align-items: center;
}
.monitor-job__summary {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-xxl);
  padding-right: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-job__summary-message {
  margin: var(--spacing-zero);
}
.monitor-job__summary-message small {
  margin-left: var(--spacing-zero);
}
.monitor-job__summary-error {
  color: var(--content-color-error);
}
.monitor-job__summary-success {
  color: var(--content-color-success);
}
.monitor-job__actions {
  margin: var(--spacing-s) 0;
  position: relative;
  border-bottom: var(--border-width-default) solid var(--border-color-strong);
  align-self: flex-start;
}
.monitor-job__actions .tabs .tab-primary {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.monitor-job__actions .tabs .tab-primary.is-active {
  color: var(--content-color-primary);
}
.monitor-job__empty-card {
  color: var(--content-color-primary);
  margin: var(--spacing-xxxl) 0;
  text-align: center;
}
.monitor-job__empty-card .empty-state__body {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-s);
  font-size: var(--text-size-m);
}
.monitor-job__loader {
  display: block;
  margin: 256px auto;
}
.monitor-job *,
.monitor-job *:before,
.monitor-job *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.monitor-job .pm-row {
  overflow-x: visible;
}

.empty-state__image {
  margin: auto;
  max-width: 160px;
  margin-bottom: var(--spacing-xl);
}
.empty-state__image.run {
  display: block;
  width: 133px;
  height: 99px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/run.svg");
}
.empty-state__image.monitor {
  display: block;
  width: 160px;
  height: 113px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor.svg");
}
.empty-state__image.warning {
  display: block;
  width: 133px;
  height: 133px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/warning.svg");
}
.empty-state__image.no-passes {
  display: block;
  width: 132px;
  height: 84px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/no-passes.svg");
}
.empty-state__image.no-failures {
  display: block;
  width: 132px;
  height: 84px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/no-failures.svg");
}
.empty-state__image.abort {
  display: block;
  width: 133px;
  height: 133px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/abort.svg");
}

.split-selector-label {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}

#monitor-message-log {
  --monitor-msg-log-cell-padding: 5px;
  --monitor-msg-log-first-cell-width: 25px;
  --monitor-msg-log-bullet-size: 8px;
  --monitor-msg-log-msg-field-padding-left: var(--spacing-s);
  --monitor-msg-log-timestamp-field-width: 60px;
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  padding: var(--spacing-l);
  overflow: auto;
}
#monitor-message-log .state-line {
  padding: var(--monitor-msg-log-cell-padding) 0;
}
#monitor-message-log .state-line .loading-indicator-wrapper {
  display: inline-block;
  vertical-align: middle;
  transform: scale(0.75);
}
#monitor-message-log .state-line__field {
  padding-right: var(--monitor-msg-log-cell-padding);
}
#monitor-message-log .state-line__field.bullet {
  display: inline-block;
  padding: 0;
  width: var(--monitor-msg-log-first-cell-width);
  text-align: center;
  /**
   * The background property of the :after element has been given a --content-color instead of a --background design token
   * because the bullet is essentially content, moreover using the --background token here wouldn't work well with the colors
   * after render.
   */
}
#monitor-message-log .state-line__field.bullet:after {
  content: "";
  display: inline-block;
  width: var(--monitor-msg-log-bullet-size);
  height: var(--monitor-msg-log-bullet-size);
  border-radius: 50%;
  background: var(--content-color-tertiary);
}
#monitor-message-log .state-line__field.timestamp {
  width: var(--monitor-msg-log-timestamp-field-width);
}
#monitor-message-log .state-line__field.message-body {
  padding-left: var(--monitor-msg-log-msg-field-padding-left);
}
#monitor-message-log .state-line__field--toggler {
  color: var(--content-color-link);
  cursor: pointer;
  font-family: var(--text-family-default);
}
#monitor-message-log .nested-log {
  padding: var(--monitor-msg-log-cell-padding) 0;
}
#monitor-message-log table.messages {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
#monitor-message-log table.messages tr.message {
  display: table-row;
  border-top: var(--border-width-default) solid var(--border-color-strong);
}
#monitor-message-log table.messages tr.message:last-child {
  border-bottom: var(--border-width-default) solid var(--border-color-strong);
}
#monitor-message-log table.messages tr.message.test.info .message-body {
  color: var(--content-color-success);
}
#monitor-message-log table.messages tr.message.warn .message-body {
  color: var(--content-color-warning);
}
#monitor-message-log table.messages tr.message.error .message-body {
  color: var(--content-color-error);
}
#monitor-message-log table.messages tr.message td {
  padding: var(--monitor-msg-log-cell-padding);
  white-space: nowrap;
}
#monitor-message-log table.messages tr.message td.line-number {
  text-align: center;
  width: var(--monitor-msg-log-first-cell-width);
}
#monitor-message-log table.messages tr.message td.timestamp {
  background-color: var(--background-color-secondary);
  width: var(--monitor-msg-log-timestamp-field-width);
  padding-right: var(--spacing-l);
}
#monitor-message-log table.messages tr.message td.message-body {
  padding-left: var(--monitor-msg-log-msg-field-padding-left);
  padding-right: var(--spacing-xs);
  white-space: pre;
}
#monitor-message-log table.messages tr.message td.message-body .request {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
#monitor-message-log table.messages tr.message td.message-body .request .icon {
  margin-left: var(--spacing-xl);
  visibility: hidden;
}
#monitor-message-log table.messages tr.message td.message-body:hover .request .icon {
  visibility: visible;
}

.form-content {
  display: flex;
  padding: 0;
  overflow: hidden;
}
.form-content .create-new-monitor__error {
  --monitor-form-error-boundary: 150px;
  flex: 1;
  display: flex;
  margin-top: var(--monitor-form-error-boundary);
}
.form-content .create-new-monitor__error .creation-error__icon {
  margin-top: 0px;
}
.form-content .monitor-form__empty-state {
  --monitor-form-empty-state-boundary: 320px;
  margin-top: var(--monitor-form-empty-state-boundary);
}
.form-content .create-new-monitor-form__content {
  --input-components-width: 320px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__header .tabs {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--border-color-default);
  background-color: var(--background-color-primary);
  position: relative;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body .tabs {
  flex: 0 0 30px;
  justify-content: center;
  width: fit-content;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body .create-new-monitor-form__right {
  overflow: hidden;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__right__table {
  height: 100%;
  flex: 1;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__right__table .create-new-monitor-form__data-editor {
  height: 100%;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__right__table .create-new-monitor-form__data-editor .data-editor {
  height: 100%;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__right__table .create-new-monitor-form__data-editor .data-editor .data-editor {
  overflow-y: auto;
  height: 100%;
}
.form-content .create-new-monitor-form__content .create-new-monitor-form__body .create-new-monitor-form__footer__helptext {
  margin-top: 20px;
}
.form-content .create-new-monitor-form__content .step1 {
  overflow: auto;
}
.form-content .create-new-monitor-form__content .step1 .entity-empty__illustration-wrapper,
.form-content .create-new-monitor-form__content .step1 .entity-empty__subtitle {
  margin-bottom: 10px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body {
  padding: 20px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .tabs {
  border: 1px solid var(--border-color-default);
  margin-bottom: 20px;
  border-radius: var(--border-radius-default);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .tabs .tab--collections {
  border-left: 1px solid var(--border-color-default);
  border-right: 1px solid var(--border-color-default);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .tabs .tab-primary {
  border-bottom: 0px;
  background-color: var(--background-color-tertiary);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .tabs .tab-primary.is-active {
  background-color: var(--background-color-primary);
  color: var(--content-color-brand);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .tabs .tab-primary.is-active .tab-text-wrapper {
  box-shadow: none;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right__intro {
  font-size: 12px;
  line-height: 16px;
  color: var(--content-color-secondary);
  width: 100%;
  margin-bottom: 20px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right__intro .btn-text {
  line-height: 16px;
  padding: 0 3px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right {
  flex: 1;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__footer__helptext {
  color: var(--content-color-primary);
  margin-top: 20px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__footer__helptext .btn-text {
  height: 0px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__data-editor {
  margin-right: 1px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor {
  display: flex;
  flex-direction: column;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__header {
  margin-right: 12px;
  flex: 0 0 30px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__rows {
  overflow-y: scroll;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__rows::-webkit-scrollbar-track {
  background-color: var(--background-color-primary);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__header .data-editor__header__cell {
  font-size: 11px;
  color: var(--content-color-primary);
  font-weight: 600;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__header .data-editor__header__cell .data-editor__header__cell__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__header .create-new-monitor-form__test-header {
  overflow: hidden;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__header .create-new-monitor-form__test-header .input-checkbox {
  min-width: 16px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__header .create-new-monitor-form__test-header span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row .data-editor__reorder {
  width: 14px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row .dropdown {
  margin: 0 5px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row .dropdown-button .btn {
  background-color: transparent;
  padding: 0;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row .data-editor__cell--time {
  display: flex;
  flex: 1;
  margin-right: 30px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row .data-editor__cell--time .postfix {
  font-size: 12px;
  padding-left: 5px;
  line-height: 25px;
  color: var(--content-color-secondary);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__cell--time, .form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: 0;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__cell--time, .form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: 30px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__test-header {
  display: flex;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__test-header .input-checkbox {
  margin-right: 5px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__right__table {
  flex: 1;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card {
  display: flex;
  align-items: center;
  flex: 0 0 230px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  border-radius: var(--border-radius-default);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card:hover, .form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card .create-new-monitor-form__card__icon {
  margin-right: 10px;
  flex: 0 0 22px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card .create-new-monitor-form__card__title {
  display: flex;
  align-items: center;
  max-width: 190px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card .create-new-monitor-form__card__title span {
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card .create-new-monitor-form__card__subtitle {
  display: flex;
  align-items: center;
  color: var(--content-color-secondary);
  font-size: 12px;
  overflow: hidden;
  max-width: 190px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__card .create-new-monitor-form__card__requests {
  white-space: nowrap;
  margin-right: 8px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__right__collections,
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-form__right__team-library {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--border-color-default);
  overflow: auto;
  margin-bottom: 20px;
  max-height: 35vh;
  padding: 10px;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form__right .create-new-monitor-disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form-offline {
  cursor: not-allowed;
  color: var(--content-color-primary);
  opacity: 0.4;
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form-offline use {
  fill: var(--content-color-primary);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form-offline svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.form-content .create-new-monitor-form__content .step1.create-new-monitor-form__body .create-new-monitor-form-offline svg {
  color: var(--content-color-primary);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  max-width: 900px;
  margin: 0 auto;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group {
  margin-bottom: 16px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__label, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions, .create-new-monitor-modal__body .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions {
  font-size: 12px;
  line-height: 36px;
  color: var(--content-color-primary);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__label.is-error, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor-modal__body .create-new-monitor__body__input-group .is-error.create-new-monitor__body__label--regions, .create-new-monitor-modal__body .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .is-error.create-new-monitor__body__label--regions {
  color: var(--base-color-error);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__label--schedule-selector {
  margin-bottom: var(--spacing-xs);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .region-label {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  margin-bottom: calc(-1 * var(--spacing-s));
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input {
  display: flex;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__form__body__input__helptext--regions, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--regions {
  margin-top: 18px;
  padding-left: 20px;
  color: var(--content-color-tertiary);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--schedule-selector {
  margin-top: 0;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field {
  display: flex;
  flex: 0 0 500px;
  margin-bottom: auto;
  height: 100%;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown {
  width: var(--input-components-width);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container-selected {
  max-width: 270px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container {
  max-width: 250px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .input-checkbox {
  margin-right: 5px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input.create-new-monitor__body__input--regions {
  margin-left: var(--spacing-xl);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input-error {
  color: var(--base-color-error);
  font-size: 11px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section-toggle {
  margin-left: -10px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section {
  margin: 10px 0;
  color: var(--content-color-primary);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__field-error {
  color: var(--base-color-error);
  font-size: 11px;
  margin: 5px 25px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox {
  display: flex;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field {
  display: flex;
  align-items: center;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field__input {
  width: 300px;
  margin: 5px 10px 5px 25px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field .advanced-section__field__meta {
  margin: 5px 25px;
  font-size: 11px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--notifications {
  display: block;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .monitor-notification-limit__input {
  width: 75px;
  margin: 0 8px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .input-error-section {
  top: 3px;
  margin-top: 0px;
  display: inline-block;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay {
  font-size: 11px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout .advanced-section__field__input, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay .advanced-section__field__input {
  width: 150px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select {
  margin: 5px 10px 5px 25px;
  display: flex;
  align-items: center;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select--retry > * {
  font-size: 11px;
  margin-right: 10px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select__dropdown--retry {
  width: 75px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox__label {
  margin-left: var(--spacing-xs);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__checkbox-label-helper {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual,
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual {
  white-space: pre-line;
  margin-bottom: 8px;
  width: fit-content;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual {
  margin-bottom: unset;
  margin-top: calc(-1 * var(--spacing-s));
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions__radio {
  margin-right: 5px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input--help {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext {
  color: var(--content-color-secondary);
  font-size: 12px;
  line-height: 24px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext .btn-text {
  padding: 0 3px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--private {
  margin-top: 10px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--auto {
  display: none;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--manual {
  display: flex;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__field {
  flex-wrap: wrap;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox {
  display: flex;
  width: 500px;
  color: var(--content-color-primary);
  margin-top: 8px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon {
  display: inline-block;
  padding-left: 4px;
  vertical-align: -2px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon use {
  fill: var(--content-color-tertiary);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg {
  color: var(--content-color-tertiary);
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-region {
  height: 18px;
}
.form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--name {
  color: var(--content-color-primary);
}
.form-content .create-new-monitor-form__content .is-loading {
  justify-content: center;
}
.form-content .create-new-monitor-form__content .version-dropdown {
  width: 500px;
}
.form-content .create-new-monitor-form__content .version-dropdown-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-content .create-new-monitor-form__content.enable_scrolling_tab {
  overflow: auto;
}
@media screen and (max-width: 1230px) {
  .form-content .create-new-monitor__body__input__helptext--regions {
    padding-top: var(--spacing-s);
  }
}

.tab-disabled-input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--background-color-primary);
}
.tab-disabled-input:hover {
  background-color: var(--background-color-primary);
}

.tab-enable-scrolling {
  overflow: auto;
}

.create-new-monitor-form.tab-body {
  z-index: 0;
  border: none;
}

.create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.tab-helpertext, .create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .tab-helpertext.create-new-monitor__form__body__input__helptext--regions, .create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .tab-helpertext.create-new-monitor__body__input__helptext--regions {
  margin-top: 0;
  margin-left: -32px;
}

.create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field.tab-form {
  width: 320px;
  flex: 0 0 320px;
}

.create-new-monitor__body__select-group--regions__radio.tab-radio-button {
  visibility: visible;
  -webkit-appearance: none;
  vertical-align: middle;
}

.create-new-monitor-form .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox.tab-region-listing {
  width: 320px;
  font-size: var(--text-size-m);
}

.tab-footer {
  text-align: center;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.tab-button_cta {
  float: right;
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.tab-button_cta--primary {
  width: 280px;
  margin-left: var(--spacing-s);
}

.edit-monitor-loader {
  margin-top: 20%;
  text-align: center;
}

.monitor-schedule-selector-tab {
  width: 320px;
  box-sizing: border-box;
}
.monitor-schedule-selector-tab > * {
  width: 100%;
  margin: 0 10px var(--spacing-s) 0;
}
.monitor-schedule-selector-tab > *:last-child {
  margin: 0;
}

.create-new-monitor__body__input__helptext .form-helper-link, .create-new-monitor__form__body__input__helptext--regions .form-helper-link, .create-new-monitor__body__input__helptext--regions .form-helper-link {
  color: var(--content-color-tertiary);
}
.create-new-monitor__body__input__helptext .form-helper-link use, .create-new-monitor__form__body__input__helptext--regions .form-helper-link use, .create-new-monitor__body__input__helptext--regions .form-helper-link use {
  fill: var(--content-color-tertiary);
}
.create-new-monitor__body__input__helptext .form-helper-link svg:not([class*=PartnerIcon]) > path[fill], .create-new-monitor__form__body__input__helptext--regions .form-helper-link svg:not([class*=PartnerIcon]) > path[fill], .create-new-monitor__body__input__helptext--regions .form-helper-link svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.create-new-monitor__body__input__helptext .form-helper-link svg, .create-new-monitor__form__body__input__helptext--regions .form-helper-link svg, .create-new-monitor__body__input__helptext--regions .form-helper-link svg {
  color: var(--content-color-tertiary);
}
.create-new-monitor__body__input__helptext .form-helper-link:hover, .create-new-monitor__form__body__input__helptext--regions .form-helper-link:hover, .create-new-monitor__body__input__helptext--regions .form-helper-link:hover {
  color: var(--content-color-link);
}
.create-new-monitor__body__input__helptext .form-helper-link:hover use, .create-new-monitor__form__body__input__helptext--regions .form-helper-link:hover use, .create-new-monitor__body__input__helptext--regions .form-helper-link:hover use {
  fill: var(--content-color-link);
}
.create-new-monitor__body__input__helptext .form-helper-link:hover svg:not([class*=PartnerIcon]) > path[fill], .create-new-monitor__form__body__input__helptext--regions .form-helper-link:hover svg:not([class*=PartnerIcon]) > path[fill], .create-new-monitor__body__input__helptext--regions .form-helper-link:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-link);
}
.create-new-monitor__body__input__helptext .form-helper-link:hover svg, .create-new-monitor__form__body__input__helptext--regions .form-helper-link:hover svg, .create-new-monitor__body__input__helptext--regions .form-helper-link:hover svg {
  color: var(--content-color-link);
}
.create-new-monitor__body__input__helptext .form-helper-link .form-helper-link--text, .create-new-monitor__form__body__input__helptext--regions .form-helper-link .form-helper-link--text, .create-new-monitor__body__input__helptext--regions .form-helper-link .form-helper-link--text {
  font-weight: var(--text-weight-regular);
  text-decoration: underline;
}
.create-new-monitor__body__input__helptext .form-helper-link .form-helper-link--icon, .create-new-monitor__form__body__input__helptext--regions .form-helper-link .form-helper-link--icon, .create-new-monitor__body__input__helptext--regions .form-helper-link .form-helper-link--icon {
  vertical-align: -2px;
}

.create-new-monitor__body__input__helptext--regions {
  padding-top: var(--spacing-xl);
}

.create-new-monitor__form__body__input__helptext--regions {
  padding-top: var(--spacing-xs);
}

.create-new-monitor__body__input__helptext__link {
  text-indent: 2px;
}

.create-monitor-collection-item {
  display: flex;
  max-width: 100%;
  align-items: center;
}
.create-monitor-collection-item__name, .create-monitor-collection-item__qualifiers {
  flex: 0 1 auto;
}
.create-monitor-collection-item__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: var(--spacing-s);
}
.create-monitor-collection-item__fork-label {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
}
.create-monitor-collection-item__fork-label svg > path[fill] {
  fill: currentColor !important;
}
.create-monitor-collection-item__fork-label svg {
  color: currentColor !important;
}

.monitor-form-help-text {
  flex: 1;
  box-sizing: border-box;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}

.modal-vertical {
  flex-direction: column;
}

.monitor-uptime-banner {
  height: 0;
  margin-left: 64px;
}

.monitor-file-upload {
  flex-direction: column;
}
.monitor-file-upload__error {
  display: flex;
  color: var(--content-color-error);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  margin-top: var(--spacing-xs);
}
.monitor-file-upload__error .error-icon {
  margin-right: var(--spacing-xs);
  margin-top: calc(var(--spacing-xs) / 2);
}

.monitor-topbar-actions-tooltip-wrapper,
.monitor-sidebar-tooltip-wrapper {
  display: flex;
  flex: 1;
}
.monitor-topbar-actions-tooltip-wrapper.d-flex,
.monitor-sidebar-tooltip-wrapper.d-flex {
  display: flex;
}
.monitor-topbar-actions-tooltip-wrapper.d-block,
.monitor-sidebar-tooltip-wrapper.d-block {
  display: block;
}
.monitor-topbar-actions-tooltip-wrapper.d-inline-block,
.monitor-sidebar-tooltip-wrapper.d-inline-block {
  display: inline-block;
}

.monitor-sidebar-tab-content {
  flex: 1;
  overflow-y: auto;
}

.monitor-listing {
  flex: 1;
}
.monitor-listing .sidebar-list-item__name-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-listing .monitor-list-item-health-status.actions-hidden {
  margin-right: 28px;
}
.monitor-listing .monitor-list-item-health-status.actions-hidden.use-browser-tabs {
  margin-right: 36px;
}
.monitor-listing .sidebar-list-item {
  padding: var(--spacing-zero) var(--spacing-xs);
}
.monitor-listing .sidebar-list-item__content-wrapper {
  margin-left: var(--spacing-zero);
}

.monitors-dropdown-menu {
  --dropdown-width: 220px;
  min-width: var(--dropdown-width);
}
.monitors-dropdown-menu .dropdown-menu-item {
  padding: 0 var(--spacing-l);
}
.monitors-dropdown-menu .dropdown-menu-item .dropdown-menu-item-icon {
  margin-right: var(--spacing-s);
}
.monitors-dropdown-menu .mock-action-item {
  display: flex;
  flex: 1;
}

.monitor-listing-empty-block {
  font-size: 12px;
  padding: 10px 15px;
  color: var(--content-color-secondary);
}
.monitor-listing-empty-block .entity-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 10px 20px;
  border: none;
  box-sizing: border-box;
}
.monitor-listing-empty-block .entity-empty .monitor-list-empty__title,
.monitor-listing-empty-block .entity-empty .monitor-list-empty__subtitle {
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: var(--content-color-primary);
  margin-bottom: 10px;
}
.monitor-listing-empty-block .entity-empty .monitor-list-empty__subtitle {
  font-weight: normal;
}
.monitor-listing-empty-block .entity-empty__illustration-wrapper--monitor {
  margin-bottom: 16px;
}
.monitor-listing-empty-block .entity-empty__illustration-wrapper--monitor .monitor-empty__illustration {
  display: block;
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor_cloud.svg");
}

.monitor-sidebar-loading-shell {
  height: 100%;
  border-right: 1px solid var(--background-color-tertiary);
  border-left: 1px solid var(--background-color-tertiary);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
}
.monitor-sidebar-loading-shell .monitor-sidebar-loading-shell--item {
  height: var(--size-m);
  border-bottom: 1px solid var(--background-color-tertiary);
  display: flex;
  align-items: center;
}
.monitor-sidebar-loading-shell .monitor-sidebar-loading-shell--item .monitor-sidebar-loading-shell--monitor__dropdown {
  height: 12px;
  width: 12px;
  background-color: var(--background-color-tertiary);
  margin-left: 8px;
  border-radius: var(--border-radius-default);
}
.monitor-sidebar-loading-shell .monitor-sidebar-loading-shell--item .monitor-sidebar-loading-shell--monitor__icon {
  height: 12px;
  width: 12px;
  background-color: var(--background-color-tertiary);
  margin-left: 8px;
  border-radius: var(--border-radius-default);
}
.monitor-sidebar-loading-shell .monitor-sidebar-loading-shell--item .monitor-sidebar-loading-shell--monitor__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.monitor-sidebar-loading-shell .monitor-sidebar-loading-shell--item .monitor-sidebar-empty-shell--monitor__info--name,
.monitor-sidebar-loading-shell .monitor-sidebar-loading-shell--item .monitor-sidebar-empty-shell--monitor__info--count {
  background-color: var(--background-color-tertiary);
  width: 100px;
  height: 8px;
  margin-left: 16px;
  margin-bottom: 5px;
  border-radius: var(--border-radius-default);
}

.monitor-sidebar-offline-state {
  margin-top: 80px;
}

.monitor-sidebar-offline-icon {
  margin-right: 10px;
}

.monitor-sidebar-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--size-m);
  justify-content: space-between;
  padding: 0px 10px;
  border-bottom: 1px solid var(--border-color-default);
  margin-bottom: var(--spacing-s);
}

.monitor-job .job-result-status {
  position: relative;
  background-color: var(--content-color-secondary);
  padding: var(--spacing-s) var(--spacing-l);
  border: var(--border-width-default) solid var(--border-color-strong);
  border-top: none;
}
.monitor-job .job-result-status .run-status {
  font-size: var(--text-size-xs);
  display: block;
}
@media (min-width: 568px) {
  .monitor-job .job-result-status .run-status {
    display: inline;
  }
}
.monitor-job .job-result-status .run-status:not(:first-child) {
  margin-top: var(--spacing-s);
}
@media (min-width: 568px) {
  .monitor-job .job-result-status .run-status:not(:first-child) {
    margin-top: 0;
    margin-left: var(--spacing-s);
  }
}
.monitor-job .job-result-status .run-status .pm-icon {
  margin-right: 5px;
  margin-top: -2px;
}
.monitor-job .job-results-container {
  border: var(--border-width-default) solid var(--border-color-default);
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
  overflow-y: auto;
  display: flex;
  margin-top: var(--spacing-s);
  --filter-control-cell-width:35px;
  --filter-control-cell-height:35px;
}
.monitor-job .icon.success {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/monitor_success.svg");
}
.monitor-job .icon.error {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/monitor_error.svg");
}
.monitor-job .job-results {
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 12em;
  background-color: var(--background-color-primary);
}
.monitor-job .job-results.padded {
  padding: var(--spacing-xxl) var(--spacing-l);
}
.monitor-job .job-results .empty-state {
  padding: var(--spacing-l) 0;
  text-align: center;
}
.monitor-job .job-results .empty-state__body {
  max-width: 360px;
  margin: 16px auto 32px;
}
.monitor-job .job-results .request {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  font-size: var(--text-size-m);
}
.monitor-job .job-results .request .ellipsify {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-job .job-results .request:last-child {
  border-bottom: none;
}
.monitor-job .job-results .request .request-header {
  padding: var(--spacing-s) var(--spacing-s);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  cursor: pointer;
}
.monitor-job .job-results .request .request-header .pm-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.monitor-job .job-results .request .request-header .pm-row .request-meta {
  flex-grow: 4;
}
.monitor-job .job-results .request .request-header .pm-row .request-summary {
  margin-right: var(--spacing-l);
  min-width: 200px;
}
.monitor-job .job-results .request .icon {
  float: left;
  margin-top: 2px;
}
.monitor-job .job-results .request .request-name {
  line-height: 1.5;
  font-weight: normal;
  display: inline;
  padding-left: var(--spacing-l);
}
.monitor-job .job-results .request .request-method {
  font-size: 11px;
  font-weight: 600;
  padding-left: 12px;
}
.monitor-job .job-results .request .request-method.get {
  color: var(--base-color-success);
}
.monitor-job .job-results .request .request-method.post {
  color: var(--base-color-warning);
}
.monitor-job .job-results .request .request-method.put {
  color: var(--base-color-info);
}
.monitor-job .job-results .request .request-method.patch {
  color: var(--content-color-secondary);
}
.monitor-job .job-results .request .request-method.delete {
  color: var(--base-color-brand);
}
.monitor-job .job-results .request .request-url {
  overflow-wrap: break-word;
  word-break: break-word;
  font-weight: 200;
  padding-left: 28px;
  margin-top: var(--spacing-xs);
  display: block;
}
@media (min-width: 768px) {
  .monitor-job .job-results .request .request-url {
    display: inline;
    padding-left: var(--spacing-s);
    margin-top: 0;
  }
}
.monitor-job .job-results .request .response-code {
  font-size: var(--text-size-m);
  display: block;
  margin-top: var(--spacing-l);
}
@media (min-width: 568px) {
  .monitor-job .job-results .request .response-code {
    margin-top: 0;
    text-align: right;
  }
}
.monitor-job .job-results .request .response-time {
  font-size: var(--text-size-xs);
  color: var(--content-color-link);
  display: block;
}
@media (min-width: 568px) {
  .monitor-job .job-results .request .response-time {
    text-align: right;
  }
}
.monitor-job .job-results .request .request-summary {
  margin-top: var(--spacing-s);
  padding-left: var(--spacing-xxxl);
}
@media (min-width: 768px) {
  .monitor-job .job-results .request .request-summary {
    margin-top: 0;
    padding-left: 0;
  }
}
.monitor-job .job-results .request .request-summary .run-status:not(:first-child) {
  padding-left: var(--spacing-s);
}
.monitor-job .job-results .request .request-summary .run-status .pm-icon {
  margin-right: 5px;
  margin-top: -2px;
}
.monitor-job .job-results .request .request-summary .no-response {
  color: var(--content-color-secondary);
}
.monitor-job .job-results .request .request-test-results .test-results {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 2;
}
.monitor-job .job-results .request .request-test-results .test-results li {
  padding: var(--spacing-s) var(--spacing-xxxl);
}
.monitor-job .job-results .request .request-test-results .test-results li .icon {
  margin-top: 5px;
}
.monitor-job .job-results .request .request-test-results .test-results .test-result {
  display: inline-block;
}
.monitor-job .job-results .request .request-test-results .push-half--sides {
  margin-right: 8px !important;
  margin-left: 8px !important;
}
.monitor-job .pm-link {
  color: var(--content-color-link);
  text-decoration: none;
}
.monitor-job .pm-link:hover {
  color: var(--content-color-brand);
  text-decoration: underline;
}
.monitor-job .hidden {
  display: none !important;
}

.monitor-details {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.monitor-details__monitor-actions__run {
  display: flex;
  margin-right: var(--spacing-m);
}
.monitor-details__monitor-actions__run--loading {
  margin-right: var(--spacing-xl);
}
.monitor-details__monitor-actions__run__text {
  margin-left: var(--spacing-s);
}
.monitor-details__body {
  flex: auto;
  overflow-y: auto;
}

.monitor-no-jobs .empty-state {
  max-width: 500px;
  margin: 0 auto;
  color: var(--content-color-primary);
  text-align: center;
  padding-top: var(--spacing-xxxl);
  font-size: var(--text-size-m);
}
.monitor-no-jobs .empty-state__title {
  font-size: inherit;
  font-weight: var(--text-weight-medium);
  padding-top: var(--spacing-m);
}
.monitor-no-jobs .empty-state__body {
  margin: 16px auto 32px;
  line-height: 20px;
}
.monitor-no-jobs .empty-state__image.monitor-logo {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor-logo.svg");
}

.monitor-details-header {
  display: flex;
  height: var(--spacing-xxxl);
  flex: var(--spacing-xxxl) 0 0;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  padding: 0 var(--spacing-l);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  box-sizing: border-box;
  --monitor-name-loader-width: 200px;
  --status-badge-border-radius: 30px;
}
.monitor-details-header__monitor-name-wrapper {
  display: flex;
  flex: 1;
  min-width: 0;
  color: var(--content-color-primary);
  align-items: center;
}
.monitor-details-header__monitor-name-wrapper .status-badge {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
  flex-shrink: 0;
  display: inline-block;
  padding: var(--spacing-zero) var(--spacing-s);
  border-radius: var(--status-badge-border-radius);
}
.monitor-details-header__monitor-name-wrapper .status-badge.paused {
  color: var(--content-color-secondary);
  background-color: var(--background-color-tertiary);
}
.monitor-details-header__monitor-name-wrapper .status-badge.healthy {
  color: var(--content-color-success);
  background-color: var(--background-color-success);
}
.monitor-details-header__monitor-name-wrapper .status-badge.unhealthy {
  color: var(--content-color-error);
  background-color: var(--background-color-error);
}
.monitor-details-header__monitor-name-wrapper > .monitor-lock-icon {
  margin-left: var(--spacing-xs);
}
.monitor-details-header__monitor-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.monitor-details-header__monitor-name__loading {
  animation: fade 1s linear infinite;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
  height: var(--size-s);
  width: var(--monitor-name-loader-width);
}
.monitor-details-header__copy-link {
  visibility: hidden;
}
.monitor-details-header__monitor-name-wrapper:hover .monitor-details-header__copy-link {
  visibility: visible;
}
.monitor-details-header__monitor-actions {
  display: flex;
  align-items: center;
}
.monitor-details-header__monitor-actions__run-btn {
  display: inline-flex;
  align-items: center;
}
.monitor-details-header__monitor-actions__run-btn .pm-icon {
  margin-right: var(--spacing-xs);
}
.monitor-details-header__monitor-actions__refresh {
  display: flex;
  cursor: pointer;
}
.monitor-details-header__monitor-actions__refresh__text {
  margin-left: var(--spacing-s);
  color: var(--content-color-secondary);
}
.monitor-details-header__monitor-actions__refresh--loading {
  margin-right: var(--spacing-xxl);
  margin-left: var(--spacing-xxl);
}
.monitor-details-header__monitor-actions__loading-block {
  animation: fade 1s linear infinite;
  height: var(--spacing-xl);
  width: var(--spacing-xl);
  margin-left: var(--spacing-s);
  background-color: var(--highlight-background-color-transparent);
  border-radius: var(--border-radius-default);
}

.monitor-actions-dropdown .dropdown-menu {
  margin-top: 0;
  margin-right: var(--spacing-xs);
  min-width: 220px;
}
.monitor-actions-dropdown .dropdown-menu .dropdown-menu-item {
  padding: 0 var(--spacing-l);
}
.monitor-actions-dropdown .dropdown-menu .dropdown-menu-item .dropdown-menu-item-icon {
  margin-right: var(--spacing-s);
}
.monitor-actions-dropdown .dropdown-menu-item--delete {
  border-top: var(--border-width-default) solid var(--border-color-default);
}

.monitor-action-item {
  display: flex;
  flex: 1;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.monitor-performance {
  border-bottom: var(--border-width-default) solid var(--border-color-strong);
}
.monitor-performance h4 {
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-s);
}
.monitor-performance__actions {
  position: relative;
  margin-top: var(--spacing-l);
  padding-left: var(--spacing-l);
}
.monitor-performance__split-selector {
  display: block;
  margin-bottom: var(--spacing-l);
  color: var(--content-color-primary);
}
.monitor-performance__split-selector label {
  font-size: var(--text-size-m);
  padding-right: var(--spacing-m);
  cursor: pointer;
}
.monitor-performance__split-selector .radio-button {
  vertical-align: middle;
  margin-right: var(--spacing-xs);
  -moz-appearance: initial;
}
.monitor-performance__request-selector, .monitor-performance__region-selector, .monitor-performance__aggregate-selector {
  margin-right: 14px;
  width: 176px;
}
.monitor-performance__request-selector .input-select-v2-wrapper, .monitor-performance__region-selector .input-select-v2-wrapper, .monitor-performance__aggregate-selector .input-select-v2-wrapper {
  align-items: center;
  background-color: var(--background-color-secondary);
  border: var(--border-width-default) solid var(--border-color-default);
  box-sizing: border-box;
  display: flex;
  height: var(--size-m);
  position: relative;
  width: inherit;
  font-weight: normal;
}
.monitor-performance__request-selector .input-select-v2-wrapper .input-search-group .input, .monitor-performance__region-selector .input-select-v2-wrapper .input-search-group .input, .monitor-performance__aggregate-selector .input-select-v2-wrapper .input-search-group .input {
  font-weight: normal;
  font-size: var(--text-size-m);
}
.monitor-performance__request-selector:hover, .monitor-performance__region-selector:hover, .monitor-performance__aggregate-selector:hover {
  cursor: pointer;
}
.monitor-performance__empty-state {
  color: var(--content-color-primary);
  text-align: center;
  margin: 60px 0px 100px 0px;
  font-size: var(--text-size-m);
}
.monitor-performance__empty-state .empty-state__title {
  font-size: inherit;
  font-weight: var(--text-weight-medium);
}
.monitor-performance__empty-state .empty-state__image.activity {
  display: block;
  width: 160px;
  height: 126px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor-activity.svg");
}
.monitor-performance__empty-state--loading {
  margin: 10% 0 0 0;
}
.monitor-performance__empty-state--loading .loading-indicator {
  transform: scale(1.5);
}
.monitor-performance__job-filters-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.monitor-performance__job-filters-wrapper > label {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  margin-right: var(--spacing-s);
}
@media (max-width: 1500px) {
  .monitor-performance__job-filters-wrapper > label {
    min-width: 100%;
  }
  .monitor-performance__job-filters-wrapper > * {
    margin-bottom: var(--spacing-s);
  }
}
.monitor-performance__job-filters-panel {
  text-align: left;
}
.monitor-performance__job-filters-panel .btn-secondary {
  width: inherit;
  color: var(--content-color-primary);
  padding: 0px 0px 0px 0px;
  height: var(--size-m);
  font-size: var(--text-size-m);
}
.monitor-performance__job-filters-selector {
  margin-right: 14px;
}
.monitor-performance__job-filters-selector .pm-btn-secondary {
  text-align: left;
  background-color: var(--background-color-secondary);
  border: var(--border-width-default) solid var(--border-color-default);
  width: 136px;
  color: var(--content-color-primary);
  padding: 0px 0px 0px 10px;
  max-width: 400px;
  height: var(--size-m);
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
}
.monitor-performance__job-filters-selector .filter-label {
  display: inline-block;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.monitor-performance__job-filters-selector .filter-arrow {
  vertical-align: middle;
  display: inline-block;
}
.monitor-performance__job-filters-selector .multi-select-entity__list {
  margin-top: 2px;
  border: 0.5px var(--border-style-solid) var(--border-color-strong);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  height: auto;
  width: 136px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 1000;
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
  position: absolute;
  background-color: var(--background-color-secondary);
}
.monitor-performance__job-filters-selector .multi-select-entity__list .multi-select-entity__item {
  display: flex;
  height: 30px;
  align-items: center;
  padding: 0px 5px;
  cursor: pointer;
  color: var(--content-color-primary);
}
.monitor-performance__job-filters-selector .multi-select-entity__list .multi-select-entity__item .input-checkbox {
  margin-right: 10px;
  flex-shrink: 0;
}
.monitor-performance__job-filters-selector .multi-select-entity__list .multi-select-entity__item .multi-select-entity__item--name {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.monitor-performance__job-filters-selector .multi-select-entity__list .multi-select-entity__item:first-child {
  border: var(--border-width-default) solid var(--border-color-default);
}
.monitor-performance__job-filters-selector .dropdown-caret {
  margin-right: var(--spacing-s);
}
.monitor-performance__job-filters-options--disabled .multi-select-entity__item {
  pointer-events: none;
  opacity: 0.7;
}
.monitor-performance__job-filters-selector, .monitor-performance__job-filters-options {
  width: auto;
}
@media (min-width: 1024px) {
  .monitor-performance__job-filters-options {
    width: 200px;
  }
  .monitor-performance__region-selector .Select, .monitor-performance__request-selector .Select {
    width: 200px;
  }
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
figure.job-history-chart {
  --bar-width: 32px;
  --top-bar-height: 240px;
  --bottom-bar-height: 80px;
  --bar-spacing: 4px;
  --y-axis-label-width: 30px;
  --y-axis-label-height: 16px;
  --top-bottom-spacing: calc(var(--text-size-l)*3);
  --breakpoint-md: 768px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* W3C standard */
  display: block;
  padding-left: calc(var(--y-axis-label-width) + var(--spacing-l));
  position: relative;
  margin: var(--spacing-l) 0;
}
figure.job-history-chart .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
figure.job-history-chart ol.y-axis {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - var(--spacing-xl));
  min-height: var(--top-bar-height);
  z-index: 0;
  padding-left: var(--spacing-l);
  margin-top: calc(var(--spacing-l) / 8);
}
figure.job-history-chart ol.y-axis li {
  font-size: 10px;
  height: var(--y-axis-label-height);
  color: var(--content-color-error);
  margin-bottom: calc(-1 * var(--y-axis-label-height) / 2);
}
figure.job-history-chart ol.y-axis li > div {
  width: 2.5em;
  text-align: right;
}
figure.job-history-chart ol.y-axis li:after {
  content: " ";
  display: block;
  border-top: solid var(--border-width-default) var(--border-color-default);
  position: relative;
  top: calc(-1 * var(--y-axis-label-height) / 2);
  left: var(--y-axis-label-width);
  width: calc(100% - (var(--y-axis-label-width) + var(--spacing-l)));
}
figure.job-history-chart ol.y-axis li.response-time-tick {
  color: var(--content-color-info);
  margin-top: calc(var(--top-bar-height) / 5 - var(--y-axis-label-height) / 2);
}
figure.job-history-chart ol.y-axis > li.failed-tick {
  margin-top: 34px;
}
figure.job-history-chart ol.y-axis > li.failed-tick ~ li.failed-tick {
  margin-top: calc(var(--bottom-bar-height) / 2 - var(--y-axis-label-height) / 2);
}
figure.job-history-chart div.x-axis {
  width: calc(100% - var(--spacing-xl));
  min-height: var(--top-bar-height);
  overflow: auto;
  position: relative;
  z-index: 1;
}
figure.job-history-chart div.x-axis .clearfix {
  display: inline-flex;
  position: relative;
}
figure.job-history-chart div.x-axis .clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
figure.job-history-chart div.x-axis div.day {
  position: relative;
  padding-bottom: 16px;
}
figure.job-history-chart div.x-axis div.day time.date {
  display: block;
  position: absolute;
  bottom: 10px;
  left: calc(50% - 27px);
  padding: 0 var(--spacing-l);
  font-size: 10px;
  text-align: center;
  color: var(--content-color-primary);
  background-color: var(--background-color-primary);
}
figure.job-history-chart div.x-axis div.day:before {
  content: " ";
  display: block;
  position: absolute;
  bottom: 16px;
  left: 3px;
  width: calc(100% - 6px);
  border-top: solid var(--border-width-default) var(--border-color-default);
}
figure.job-history-chart div.x-axis .response-line-graph {
  position: absolute;
  width: 100%;
  height: 100%;
  fill: none;
  stroke: var(--content-color-info);
  z-index: 5;
  pointer-events: none;
}
figure.job-history-chart div.x-axis .response-line-graph polyline {
  fill: none;
  stroke-width: 1;
}
figure.job-history-chart div.x-axis .response-line-graph circle {
  fill: white;
  stroke: var(--content-color-info);
  stroke-width: 1;
}
figure.job-history-chart ol.jobs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  white-space: nowrap;
}
figure.job-history-chart ol.jobs > li {
  display: inline-block;
}
figure.job-history-chart ol.jobs li.job {
  cursor: pointer;
  position: relative;
  --job-bar-animation-ribbon-color: rgba(51, 51, 51, 0.22);
  --job-bar-animation-background-size: 20px;
}
figure.job-history-chart ol.jobs li.job .top {
  background-color: var(--content-color-tertiary);
}
figure.job-history-chart ol.jobs li.job .passed {
  background-color: var(--content-color-success);
}
figure.job-history-chart ol.jobs li.job .failed {
  background-color: var(--content-color-error);
}
figure.job-history-chart ol.jobs li.job .unknown {
  background-image: linear-gradient(-45deg, transparent 25%, var(--job-bar-animation-ribbon-color) 25%, var(--job-bar-animation-ribbon-color) 50%, transparent 50%, transparent 75%, var(--job-bar-animation-ribbon-color) 75%, var(--job-bar-animation-ribbon-color));
  background-size: var(--job-bar-animation-background-size) var(--job-bar-animation-background-size);
  background-color: var(--content-color-tertiary);
}
figure.job-history-chart ol.jobs li.job .unknown.animated {
  animation: job-progress-loader 2s infinite linear;
}
@keyframes job-progress-loader {
  0% {
    background-position: 0px var(--job-bar-animation-background-size);
  }
  100% {
    background-position: var(--job-bar-animation-background-size) var(--job-bar-animation-background-size);
  }
}
figure.job-history-chart ol.jobs li.job .error {
  background-color: var(--content-color-error);
}
figure.job-history-chart ol.jobs li.job .bars .top,
figure.job-history-chart ol.jobs li.job .bars .unknown,
figure.job-history-chart ol.jobs li.job .bars .error {
  opacity: 0.2;
}
figure.job-history-chart ol.jobs li.job .bars .passed {
  opacity: 0.5;
}
figure.job-history-chart ol.jobs li.job:hover .bars .top,
figure.job-history-chart ol.jobs li.job:hover .bars .unknown,
figure.job-history-chart ol.jobs li.job:hover .bars .error {
  opacity: 0.35;
}
figure.job-history-chart ol.jobs li.job:hover .bars .passed {
  opacity: 0.75;
}
figure.job-history-chart ol.jobs li.job:hover .start-time {
  background-color: var(--highlight-background-color-tertiary);
}
figure.job-history-chart ol.jobs li.job:hover .start-time:after {
  border-bottom: 10px solid var(--highlight-background-color-tertiary);
}
figure.job-history-chart ol.jobs li.job.selected .bars .top,
figure.job-history-chart ol.jobs li.job.selected .bars .unknown,
figure.job-history-chart ol.jobs li.job.selected .bars .error, figure.job-history-chart ol.jobs li.job:active .bars .top,
figure.job-history-chart ol.jobs li.job:active .bars .unknown,
figure.job-history-chart ol.jobs li.job:active .bars .error, figure.job-history-chart ol.jobs li.job:focus .bars .top,
figure.job-history-chart ol.jobs li.job:focus .bars .unknown,
figure.job-history-chart ol.jobs li.job:focus .bars .error {
  opacity: 0.5;
}
figure.job-history-chart ol.jobs li.job.selected .bars .passed, figure.job-history-chart ol.jobs li.job:active .bars .passed, figure.job-history-chart ol.jobs li.job:focus .bars .passed {
  opacity: 0.85;
}
figure.job-history-chart ol.jobs li.job.selected .start-time, figure.job-history-chart ol.jobs li.job:active .start-time, figure.job-history-chart ol.jobs li.job:focus .start-time {
  color: var(--background-color-primary);
  background-color: var(--content-color-primary);
}
figure.job-history-chart ol.jobs li.job.selected .start-time:after, figure.job-history-chart ol.jobs li.job:active .start-time:after, figure.job-history-chart ol.jobs li.job:focus .start-time:after {
  border-bottom: 10px solid var(--content-color-primary);
}
figure.job-history-chart ol.jobs li.job .passed,
figure.job-history-chart ol.jobs li.job .top,
figure.job-history-chart ol.jobs li.job .failed,
figure.job-history-chart ol.jobs li.job .unknown,
figure.job-history-chart ol.jobs li.job .error {
  width: var(--bar-width);
  margin: 0 calc(var(--bar-spacing) / 2);
}
figure.job-history-chart ol.jobs li.job .passed-0,
figure.job-history-chart ol.jobs li.job .failed-0,
figure.job-history-chart ol.jobs li.job .unknown-0,
figure.job-history-chart ol.jobs li.job .error-0 {
  display: none;
}
figure.job-history-chart ol.jobs li.job .passed-100,
figure.job-history-chart ol.jobs li.job .failed-100,
figure.job-history-chart ol.jobs li.job .unknown-100,
figure.job-history-chart ol.jobs li.job .error-100 {
  height: var(--bottom-bar-height);
}
figure.job-history-chart ol.jobs li.job .top {
  height: calc(var(--top-bar-height) - var(--y-axis-label-height));
  margin-bottom: var(--top-bottom-spacing);
}
figure.job-history-chart ol.jobs li.job .failed-1 {
  height: 1px;
}
figure.job-history-chart ol.jobs li.job .error-1 {
  height: 0px;
}
figure.job-history-chart ol.jobs li.job .passed-1 {
  height: 0px;
}
figure.job-history-chart ol.jobs li.job .failed-2 {
  height: 2px;
}
figure.job-history-chart ol.jobs li.job .error-2 {
  height: 1px;
}
figure.job-history-chart ol.jobs li.job .passed-2 {
  height: 1px;
}
figure.job-history-chart ol.jobs li.job .failed-3 {
  height: 3px;
}
figure.job-history-chart ol.jobs li.job .error-3 {
  height: 2px;
}
figure.job-history-chart ol.jobs li.job .passed-3 {
  height: 2px;
}
figure.job-history-chart ol.jobs li.job .failed-4 {
  height: 4px;
}
figure.job-history-chart ol.jobs li.job .error-4 {
  height: 3px;
}
figure.job-history-chart ol.jobs li.job .passed-4 {
  height: 3px;
}
figure.job-history-chart ol.jobs li.job .failed-5 {
  height: 4px;
}
figure.job-history-chart ol.jobs li.job .error-5 {
  height: 4px;
}
figure.job-history-chart ol.jobs li.job .passed-5 {
  height: 4px;
}
figure.job-history-chart ol.jobs li.job .failed-6 {
  height: 5px;
}
figure.job-history-chart ol.jobs li.job .error-6 {
  height: 4px;
}
figure.job-history-chart ol.jobs li.job .passed-6 {
  height: 4px;
}
figure.job-history-chart ol.jobs li.job .failed-7 {
  height: 6px;
}
figure.job-history-chart ol.jobs li.job .error-7 {
  height: 5px;
}
figure.job-history-chart ol.jobs li.job .passed-7 {
  height: 5px;
}
figure.job-history-chart ol.jobs li.job .failed-8 {
  height: 7px;
}
figure.job-history-chart ol.jobs li.job .error-8 {
  height: 6px;
}
figure.job-history-chart ol.jobs li.job .passed-8 {
  height: 6px;
}
figure.job-history-chart ol.jobs li.job .failed-9 {
  height: 8px;
}
figure.job-history-chart ol.jobs li.job .error-9 {
  height: 7px;
}
figure.job-history-chart ol.jobs li.job .passed-9 {
  height: 7px;
}
figure.job-history-chart ol.jobs li.job .failed-10 {
  height: 8px;
}
figure.job-history-chart ol.jobs li.job .error-10 {
  height: 8px;
}
figure.job-history-chart ol.jobs li.job .passed-10 {
  height: 8px;
}
figure.job-history-chart ol.jobs li.job .failed-11 {
  height: 9px;
}
figure.job-history-chart ol.jobs li.job .error-11 {
  height: 8px;
}
figure.job-history-chart ol.jobs li.job .passed-11 {
  height: 8px;
}
figure.job-history-chart ol.jobs li.job .failed-12 {
  height: 10px;
}
figure.job-history-chart ol.jobs li.job .error-12 {
  height: 9px;
}
figure.job-history-chart ol.jobs li.job .passed-12 {
  height: 9px;
}
figure.job-history-chart ol.jobs li.job .failed-13 {
  height: 11px;
}
figure.job-history-chart ol.jobs li.job .error-13 {
  height: 10px;
}
figure.job-history-chart ol.jobs li.job .passed-13 {
  height: 10px;
}
figure.job-history-chart ol.jobs li.job .failed-14 {
  height: 12px;
}
figure.job-history-chart ol.jobs li.job .error-14 {
  height: 11px;
}
figure.job-history-chart ol.jobs li.job .passed-14 {
  height: 11px;
}
figure.job-history-chart ol.jobs li.job .failed-15 {
  height: 12px;
}
figure.job-history-chart ol.jobs li.job .error-15 {
  height: 12px;
}
figure.job-history-chart ol.jobs li.job .passed-15 {
  height: 12px;
}
figure.job-history-chart ol.jobs li.job .failed-16 {
  height: 13px;
}
figure.job-history-chart ol.jobs li.job .error-16 {
  height: 12px;
}
figure.job-history-chart ol.jobs li.job .passed-16 {
  height: 12px;
}
figure.job-history-chart ol.jobs li.job .failed-17 {
  height: 14px;
}
figure.job-history-chart ol.jobs li.job .error-17 {
  height: 13px;
}
figure.job-history-chart ol.jobs li.job .passed-17 {
  height: 13px;
}
figure.job-history-chart ol.jobs li.job .failed-18 {
  height: 15px;
}
figure.job-history-chart ol.jobs li.job .error-18 {
  height: 14px;
}
figure.job-history-chart ol.jobs li.job .passed-18 {
  height: 14px;
}
figure.job-history-chart ol.jobs li.job .failed-19 {
  height: 16px;
}
figure.job-history-chart ol.jobs li.job .error-19 {
  height: 15px;
}
figure.job-history-chart ol.jobs li.job .passed-19 {
  height: 15px;
}
figure.job-history-chart ol.jobs li.job .failed-20 {
  height: 16px;
}
figure.job-history-chart ol.jobs li.job .error-20 {
  height: 16px;
}
figure.job-history-chart ol.jobs li.job .passed-20 {
  height: 16px;
}
figure.job-history-chart ol.jobs li.job .failed-21 {
  height: 17px;
}
figure.job-history-chart ol.jobs li.job .error-21 {
  height: 16px;
}
figure.job-history-chart ol.jobs li.job .passed-21 {
  height: 16px;
}
figure.job-history-chart ol.jobs li.job .failed-22 {
  height: 18px;
}
figure.job-history-chart ol.jobs li.job .error-22 {
  height: 17px;
}
figure.job-history-chart ol.jobs li.job .passed-22 {
  height: 17px;
}
figure.job-history-chart ol.jobs li.job .failed-23 {
  height: 19px;
}
figure.job-history-chart ol.jobs li.job .error-23 {
  height: 18px;
}
figure.job-history-chart ol.jobs li.job .passed-23 {
  height: 18px;
}
figure.job-history-chart ol.jobs li.job .failed-24 {
  height: 20px;
}
figure.job-history-chart ol.jobs li.job .error-24 {
  height: 19px;
}
figure.job-history-chart ol.jobs li.job .passed-24 {
  height: 19px;
}
figure.job-history-chart ol.jobs li.job .failed-25 {
  height: 20px;
}
figure.job-history-chart ol.jobs li.job .error-25 {
  height: 20px;
}
figure.job-history-chart ol.jobs li.job .passed-25 {
  height: 20px;
}
figure.job-history-chart ol.jobs li.job .failed-26 {
  height: 21px;
}
figure.job-history-chart ol.jobs li.job .error-26 {
  height: 20px;
}
figure.job-history-chart ol.jobs li.job .passed-26 {
  height: 20px;
}
figure.job-history-chart ol.jobs li.job .failed-27 {
  height: 22px;
}
figure.job-history-chart ol.jobs li.job .error-27 {
  height: 21px;
}
figure.job-history-chart ol.jobs li.job .passed-27 {
  height: 21px;
}
figure.job-history-chart ol.jobs li.job .failed-28 {
  height: 23px;
}
figure.job-history-chart ol.jobs li.job .error-28 {
  height: 22px;
}
figure.job-history-chart ol.jobs li.job .passed-28 {
  height: 22px;
}
figure.job-history-chart ol.jobs li.job .failed-29 {
  height: 24px;
}
figure.job-history-chart ol.jobs li.job .error-29 {
  height: 23px;
}
figure.job-history-chart ol.jobs li.job .passed-29 {
  height: 23px;
}
figure.job-history-chart ol.jobs li.job .failed-30 {
  height: 24px;
}
figure.job-history-chart ol.jobs li.job .error-30 {
  height: 24px;
}
figure.job-history-chart ol.jobs li.job .passed-30 {
  height: 24px;
}
figure.job-history-chart ol.jobs li.job .failed-31 {
  height: 25px;
}
figure.job-history-chart ol.jobs li.job .error-31 {
  height: 24px;
}
figure.job-history-chart ol.jobs li.job .passed-31 {
  height: 24px;
}
figure.job-history-chart ol.jobs li.job .failed-32 {
  height: 26px;
}
figure.job-history-chart ol.jobs li.job .error-32 {
  height: 25px;
}
figure.job-history-chart ol.jobs li.job .passed-32 {
  height: 25px;
}
figure.job-history-chart ol.jobs li.job .failed-33 {
  height: 27px;
}
figure.job-history-chart ol.jobs li.job .error-33 {
  height: 26px;
}
figure.job-history-chart ol.jobs li.job .passed-33 {
  height: 26px;
}
figure.job-history-chart ol.jobs li.job .failed-34 {
  height: 28px;
}
figure.job-history-chart ol.jobs li.job .error-34 {
  height: 27px;
}
figure.job-history-chart ol.jobs li.job .passed-34 {
  height: 27px;
}
figure.job-history-chart ol.jobs li.job .failed-35 {
  height: 28px;
}
figure.job-history-chart ol.jobs li.job .error-35 {
  height: 28px;
}
figure.job-history-chart ol.jobs li.job .passed-35 {
  height: 28px;
}
figure.job-history-chart ol.jobs li.job .failed-36 {
  height: 29px;
}
figure.job-history-chart ol.jobs li.job .error-36 {
  height: 28px;
}
figure.job-history-chart ol.jobs li.job .passed-36 {
  height: 28px;
}
figure.job-history-chart ol.jobs li.job .failed-37 {
  height: 30px;
}
figure.job-history-chart ol.jobs li.job .error-37 {
  height: 29px;
}
figure.job-history-chart ol.jobs li.job .passed-37 {
  height: 29px;
}
figure.job-history-chart ol.jobs li.job .failed-38 {
  height: 31px;
}
figure.job-history-chart ol.jobs li.job .error-38 {
  height: 30px;
}
figure.job-history-chart ol.jobs li.job .passed-38 {
  height: 30px;
}
figure.job-history-chart ol.jobs li.job .failed-39 {
  height: 32px;
}
figure.job-history-chart ol.jobs li.job .error-39 {
  height: 31px;
}
figure.job-history-chart ol.jobs li.job .passed-39 {
  height: 31px;
}
figure.job-history-chart ol.jobs li.job .failed-40 {
  height: 32px;
}
figure.job-history-chart ol.jobs li.job .error-40 {
  height: 32px;
}
figure.job-history-chart ol.jobs li.job .passed-40 {
  height: 32px;
}
figure.job-history-chart ol.jobs li.job .failed-41 {
  height: 33px;
}
figure.job-history-chart ol.jobs li.job .error-41 {
  height: 32px;
}
figure.job-history-chart ol.jobs li.job .passed-41 {
  height: 32px;
}
figure.job-history-chart ol.jobs li.job .failed-42 {
  height: 34px;
}
figure.job-history-chart ol.jobs li.job .error-42 {
  height: 33px;
}
figure.job-history-chart ol.jobs li.job .passed-42 {
  height: 33px;
}
figure.job-history-chart ol.jobs li.job .failed-43 {
  height: 35px;
}
figure.job-history-chart ol.jobs li.job .error-43 {
  height: 34px;
}
figure.job-history-chart ol.jobs li.job .passed-43 {
  height: 34px;
}
figure.job-history-chart ol.jobs li.job .failed-44 {
  height: 36px;
}
figure.job-history-chart ol.jobs li.job .error-44 {
  height: 35px;
}
figure.job-history-chart ol.jobs li.job .passed-44 {
  height: 35px;
}
figure.job-history-chart ol.jobs li.job .failed-45 {
  height: 36px;
}
figure.job-history-chart ol.jobs li.job .error-45 {
  height: 36px;
}
figure.job-history-chart ol.jobs li.job .passed-45 {
  height: 36px;
}
figure.job-history-chart ol.jobs li.job .failed-46 {
  height: 37px;
}
figure.job-history-chart ol.jobs li.job .error-46 {
  height: 36px;
}
figure.job-history-chart ol.jobs li.job .passed-46 {
  height: 36px;
}
figure.job-history-chart ol.jobs li.job .failed-47 {
  height: 38px;
}
figure.job-history-chart ol.jobs li.job .error-47 {
  height: 37px;
}
figure.job-history-chart ol.jobs li.job .passed-47 {
  height: 37px;
}
figure.job-history-chart ol.jobs li.job .failed-48 {
  height: 39px;
}
figure.job-history-chart ol.jobs li.job .error-48 {
  height: 38px;
}
figure.job-history-chart ol.jobs li.job .passed-48 {
  height: 38px;
}
figure.job-history-chart ol.jobs li.job .failed-49 {
  height: 40px;
}
figure.job-history-chart ol.jobs li.job .error-49 {
  height: 39px;
}
figure.job-history-chart ol.jobs li.job .passed-49 {
  height: 39px;
}
figure.job-history-chart ol.jobs li.job .failed-50 {
  height: 40px;
}
figure.job-history-chart ol.jobs li.job .error-50 {
  height: 40px;
}
figure.job-history-chart ol.jobs li.job .passed-50 {
  height: 40px;
}
figure.job-history-chart ol.jobs li.job .failed-51 {
  height: 41px;
}
figure.job-history-chart ol.jobs li.job .error-51 {
  height: 40px;
}
figure.job-history-chart ol.jobs li.job .passed-51 {
  height: 40px;
}
figure.job-history-chart ol.jobs li.job .failed-52 {
  height: 42px;
}
figure.job-history-chart ol.jobs li.job .error-52 {
  height: 41px;
}
figure.job-history-chart ol.jobs li.job .passed-52 {
  height: 41px;
}
figure.job-history-chart ol.jobs li.job .failed-53 {
  height: 43px;
}
figure.job-history-chart ol.jobs li.job .error-53 {
  height: 42px;
}
figure.job-history-chart ol.jobs li.job .passed-53 {
  height: 42px;
}
figure.job-history-chart ol.jobs li.job .failed-54 {
  height: 44px;
}
figure.job-history-chart ol.jobs li.job .error-54 {
  height: 43px;
}
figure.job-history-chart ol.jobs li.job .passed-54 {
  height: 43px;
}
figure.job-history-chart ol.jobs li.job .failed-55 {
  height: 44px;
}
figure.job-history-chart ol.jobs li.job .error-55 {
  height: 44px;
}
figure.job-history-chart ol.jobs li.job .passed-55 {
  height: 44px;
}
figure.job-history-chart ol.jobs li.job .failed-56 {
  height: 45px;
}
figure.job-history-chart ol.jobs li.job .error-56 {
  height: 44px;
}
figure.job-history-chart ol.jobs li.job .passed-56 {
  height: 44px;
}
figure.job-history-chart ol.jobs li.job .failed-57 {
  height: 46px;
}
figure.job-history-chart ol.jobs li.job .error-57 {
  height: 45px;
}
figure.job-history-chart ol.jobs li.job .passed-57 {
  height: 45px;
}
figure.job-history-chart ol.jobs li.job .failed-58 {
  height: 47px;
}
figure.job-history-chart ol.jobs li.job .error-58 {
  height: 46px;
}
figure.job-history-chart ol.jobs li.job .passed-58 {
  height: 46px;
}
figure.job-history-chart ol.jobs li.job .failed-59 {
  height: 48px;
}
figure.job-history-chart ol.jobs li.job .error-59 {
  height: 47px;
}
figure.job-history-chart ol.jobs li.job .passed-59 {
  height: 47px;
}
figure.job-history-chart ol.jobs li.job .failed-60 {
  height: 48px;
}
figure.job-history-chart ol.jobs li.job .error-60 {
  height: 48px;
}
figure.job-history-chart ol.jobs li.job .passed-60 {
  height: 48px;
}
figure.job-history-chart ol.jobs li.job .failed-61 {
  height: 49px;
}
figure.job-history-chart ol.jobs li.job .error-61 {
  height: 48px;
}
figure.job-history-chart ol.jobs li.job .passed-61 {
  height: 48px;
}
figure.job-history-chart ol.jobs li.job .failed-62 {
  height: 50px;
}
figure.job-history-chart ol.jobs li.job .error-62 {
  height: 49px;
}
figure.job-history-chart ol.jobs li.job .passed-62 {
  height: 49px;
}
figure.job-history-chart ol.jobs li.job .failed-63 {
  height: 51px;
}
figure.job-history-chart ol.jobs li.job .error-63 {
  height: 50px;
}
figure.job-history-chart ol.jobs li.job .passed-63 {
  height: 50px;
}
figure.job-history-chart ol.jobs li.job .failed-64 {
  height: 52px;
}
figure.job-history-chart ol.jobs li.job .error-64 {
  height: 51px;
}
figure.job-history-chart ol.jobs li.job .passed-64 {
  height: 51px;
}
figure.job-history-chart ol.jobs li.job .failed-65 {
  height: 52px;
}
figure.job-history-chart ol.jobs li.job .error-65 {
  height: 52px;
}
figure.job-history-chart ol.jobs li.job .passed-65 {
  height: 52px;
}
figure.job-history-chart ol.jobs li.job .failed-66 {
  height: 53px;
}
figure.job-history-chart ol.jobs li.job .error-66 {
  height: 52px;
}
figure.job-history-chart ol.jobs li.job .passed-66 {
  height: 52px;
}
figure.job-history-chart ol.jobs li.job .failed-67 {
  height: 54px;
}
figure.job-history-chart ol.jobs li.job .error-67 {
  height: 53px;
}
figure.job-history-chart ol.jobs li.job .passed-67 {
  height: 53px;
}
figure.job-history-chart ol.jobs li.job .failed-68 {
  height: 55px;
}
figure.job-history-chart ol.jobs li.job .error-68 {
  height: 54px;
}
figure.job-history-chart ol.jobs li.job .passed-68 {
  height: 54px;
}
figure.job-history-chart ol.jobs li.job .failed-69 {
  height: 56px;
}
figure.job-history-chart ol.jobs li.job .error-69 {
  height: 55px;
}
figure.job-history-chart ol.jobs li.job .passed-69 {
  height: 55px;
}
figure.job-history-chart ol.jobs li.job .failed-70 {
  height: 56px;
}
figure.job-history-chart ol.jobs li.job .error-70 {
  height: 56px;
}
figure.job-history-chart ol.jobs li.job .passed-70 {
  height: 56px;
}
figure.job-history-chart ol.jobs li.job .failed-71 {
  height: 57px;
}
figure.job-history-chart ol.jobs li.job .error-71 {
  height: 56px;
}
figure.job-history-chart ol.jobs li.job .passed-71 {
  height: 56px;
}
figure.job-history-chart ol.jobs li.job .failed-72 {
  height: 58px;
}
figure.job-history-chart ol.jobs li.job .error-72 {
  height: 57px;
}
figure.job-history-chart ol.jobs li.job .passed-72 {
  height: 57px;
}
figure.job-history-chart ol.jobs li.job .failed-73 {
  height: 59px;
}
figure.job-history-chart ol.jobs li.job .error-73 {
  height: 58px;
}
figure.job-history-chart ol.jobs li.job .passed-73 {
  height: 58px;
}
figure.job-history-chart ol.jobs li.job .failed-74 {
  height: 60px;
}
figure.job-history-chart ol.jobs li.job .error-74 {
  height: 59px;
}
figure.job-history-chart ol.jobs li.job .passed-74 {
  height: 59px;
}
figure.job-history-chart ol.jobs li.job .failed-75 {
  height: 60px;
}
figure.job-history-chart ol.jobs li.job .error-75 {
  height: 60px;
}
figure.job-history-chart ol.jobs li.job .passed-75 {
  height: 60px;
}
figure.job-history-chart ol.jobs li.job .failed-76 {
  height: 61px;
}
figure.job-history-chart ol.jobs li.job .error-76 {
  height: 60px;
}
figure.job-history-chart ol.jobs li.job .passed-76 {
  height: 60px;
}
figure.job-history-chart ol.jobs li.job .failed-77 {
  height: 62px;
}
figure.job-history-chart ol.jobs li.job .error-77 {
  height: 61px;
}
figure.job-history-chart ol.jobs li.job .passed-77 {
  height: 61px;
}
figure.job-history-chart ol.jobs li.job .failed-78 {
  height: 63px;
}
figure.job-history-chart ol.jobs li.job .error-78 {
  height: 62px;
}
figure.job-history-chart ol.jobs li.job .passed-78 {
  height: 62px;
}
figure.job-history-chart ol.jobs li.job .failed-79 {
  height: 64px;
}
figure.job-history-chart ol.jobs li.job .error-79 {
  height: 63px;
}
figure.job-history-chart ol.jobs li.job .passed-79 {
  height: 63px;
}
figure.job-history-chart ol.jobs li.job .failed-80 {
  height: 64px;
}
figure.job-history-chart ol.jobs li.job .error-80 {
  height: 64px;
}
figure.job-history-chart ol.jobs li.job .passed-80 {
  height: 64px;
}
figure.job-history-chart ol.jobs li.job .failed-81 {
  height: 65px;
}
figure.job-history-chart ol.jobs li.job .error-81 {
  height: 64px;
}
figure.job-history-chart ol.jobs li.job .passed-81 {
  height: 64px;
}
figure.job-history-chart ol.jobs li.job .failed-82 {
  height: 66px;
}
figure.job-history-chart ol.jobs li.job .error-82 {
  height: 65px;
}
figure.job-history-chart ol.jobs li.job .passed-82 {
  height: 65px;
}
figure.job-history-chart ol.jobs li.job .failed-83 {
  height: 67px;
}
figure.job-history-chart ol.jobs li.job .error-83 {
  height: 66px;
}
figure.job-history-chart ol.jobs li.job .passed-83 {
  height: 66px;
}
figure.job-history-chart ol.jobs li.job .failed-84 {
  height: 68px;
}
figure.job-history-chart ol.jobs li.job .error-84 {
  height: 67px;
}
figure.job-history-chart ol.jobs li.job .passed-84 {
  height: 67px;
}
figure.job-history-chart ol.jobs li.job .failed-85 {
  height: 68px;
}
figure.job-history-chart ol.jobs li.job .error-85 {
  height: 68px;
}
figure.job-history-chart ol.jobs li.job .passed-85 {
  height: 68px;
}
figure.job-history-chart ol.jobs li.job .failed-86 {
  height: 69px;
}
figure.job-history-chart ol.jobs li.job .error-86 {
  height: 68px;
}
figure.job-history-chart ol.jobs li.job .passed-86 {
  height: 68px;
}
figure.job-history-chart ol.jobs li.job .failed-87 {
  height: 70px;
}
figure.job-history-chart ol.jobs li.job .error-87 {
  height: 69px;
}
figure.job-history-chart ol.jobs li.job .passed-87 {
  height: 69px;
}
figure.job-history-chart ol.jobs li.job .failed-88 {
  height: 71px;
}
figure.job-history-chart ol.jobs li.job .error-88 {
  height: 70px;
}
figure.job-history-chart ol.jobs li.job .passed-88 {
  height: 70px;
}
figure.job-history-chart ol.jobs li.job .failed-89 {
  height: 72px;
}
figure.job-history-chart ol.jobs li.job .error-89 {
  height: 71px;
}
figure.job-history-chart ol.jobs li.job .passed-89 {
  height: 71px;
}
figure.job-history-chart ol.jobs li.job .failed-90 {
  height: 72px;
}
figure.job-history-chart ol.jobs li.job .error-90 {
  height: 72px;
}
figure.job-history-chart ol.jobs li.job .passed-90 {
  height: 72px;
}
figure.job-history-chart ol.jobs li.job .failed-91 {
  height: 73px;
}
figure.job-history-chart ol.jobs li.job .error-91 {
  height: 72px;
}
figure.job-history-chart ol.jobs li.job .passed-91 {
  height: 72px;
}
figure.job-history-chart ol.jobs li.job .failed-92 {
  height: 74px;
}
figure.job-history-chart ol.jobs li.job .error-92 {
  height: 73px;
}
figure.job-history-chart ol.jobs li.job .passed-92 {
  height: 73px;
}
figure.job-history-chart ol.jobs li.job .failed-93 {
  height: 75px;
}
figure.job-history-chart ol.jobs li.job .error-93 {
  height: 74px;
}
figure.job-history-chart ol.jobs li.job .passed-93 {
  height: 74px;
}
figure.job-history-chart ol.jobs li.job .failed-94 {
  height: 76px;
}
figure.job-history-chart ol.jobs li.job .error-94 {
  height: 75px;
}
figure.job-history-chart ol.jobs li.job .passed-94 {
  height: 75px;
}
figure.job-history-chart ol.jobs li.job .failed-95 {
  height: 76px;
}
figure.job-history-chart ol.jobs li.job .error-95 {
  height: 76px;
}
figure.job-history-chart ol.jobs li.job .passed-95 {
  height: 76px;
}
figure.job-history-chart ol.jobs li.job .failed-96 {
  height: 77px;
}
figure.job-history-chart ol.jobs li.job .error-96 {
  height: 76px;
}
figure.job-history-chart ol.jobs li.job .passed-96 {
  height: 76px;
}
figure.job-history-chart ol.jobs li.job .failed-97 {
  height: 78px;
}
figure.job-history-chart ol.jobs li.job .error-97 {
  height: 77px;
}
figure.job-history-chart ol.jobs li.job .passed-97 {
  height: 77px;
}
figure.job-history-chart ol.jobs li.job .failed-98 {
  height: 79px;
}
figure.job-history-chart ol.jobs li.job .error-98 {
  height: 78px;
}
figure.job-history-chart ol.jobs li.job .passed-98 {
  height: 78px;
}
figure.job-history-chart ol.jobs li.job .failed-99 {
  height: 80px;
}
figure.job-history-chart ol.jobs li.job .error-99 {
  height: 79px;
}
figure.job-history-chart ol.jobs li.job .passed-99 {
  height: 79px;
}
figure.job-history-chart ol.jobs li.job time.start-time {
  display: inline-block;
  font-size: 10px;
  font-weight: var(--text-weight-medium);
  font-stretch: normal;
  text-align: center;
  color: var(--content-color-tertiary);
  background-color: transparent;
  padding: 0 2px 2px 2px;
  margin: 15px 0 25px 0;
  width: calc(var(--bar-width) + var(--bar-spacing));
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  position: relative;
}
figure.job-history-chart ol.jobs li.job time.start-time .time-hours-minutes,
figure.job-history-chart ol.jobs li.job time.start-time .time-am-pm {
  display: block;
}
figure.job-history-chart ol.jobs li.job time.start-time:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  margin-left: -2px;
  border-bottom: solid 10px transparent;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
}
figure.job-history-chart:hover > .slider-control {
  display: block;
}
figure.job-history-chart .slider-control {
  z-index: 2;
  position: absolute;
  top: 50%;
  margin-top: -62px;
  display: block;
}
@media (min-width: 768px) {
  figure.job-history-chart .slider-control {
    display: none;
  }
}
figure.job-history-chart .slider-control.back-button {
  left: 20px;
}
figure.job-history-chart .slider-control.next-button {
  right: 0px;
}

.job-bar-tooltip-wrapper {
  margin-bottom: 280px;
}
.job-bar-tooltip-wrapper .job-tooltip-body {
  display: block;
  visibility: visible;
  font-size: var(--text-size-l);
}
.job-bar-tooltip-wrapper .job-tooltip-body .error-red {
  color: var(--content-color-error);
}
.job-bar-tooltip-wrapper .job-tooltip-body .response-blue {
  color: var(--content-color-info);
}
.job-bar-tooltip-wrapper .arrow {
  margin-bottom: 280px;
}

.monitor-performance-goto {
  --goto-input-width: 190px;
  display: flex;
  left: var(--spacing-l);
  position: sticky;
  z-index: 6;
}
.monitor-performance-goto .flatpickr-input {
  padding-left: 6px;
  position: absolute;
  width: fit-content;
  width: -moz-fit-content;
  cursor: pointer;
  border: solid var(--border-width-default) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  font-family: var(--text-family-default);
}
.monitor-performance-goto .flatpickr-input--loading {
  pointer-events: none;
  cursor: default;
  width: calc(var(--goto-input-width) + var(--size-s));
}
.monitor-performance-goto .flatpickr-input:disabled {
  color: var(--content-color-primary);
  background: var(--background-color-primary);
}
.monitor-performance-goto .goto {
  top: var(--line-height-l);
  height: fit-content;
  height: -moz-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  position: sticky;
}
.monitor-performance-goto .goto .goto__loader {
  position: absolute;
  margin-left: var(--goto-input-width);
  margin-top: var(--spacing-xs);
}
.monitor-performance-goto .goto:disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.job-history-chart:hover .flatpickr-input {
  opacity: 0.6;
}

.job-history-chart .goto:hover .flatpickr-input {
  opacity: 1;
}

.flatpickr-calendar {
  --flatpickr-width: 308px;
  --flatpickr-height-s: 14px;
  --flatpickr-height-m: 34px;
  --flatpickr-height-l: 40px;
  --flatpickr-height-xl: 640px;
  --flatpickr-margin-xxs: 1px;
  --flatpickr-margin-xs: 2px;
  --flatpickr-margin-s: 5px;
  --flatpickr-margin-m: 10px;
  --flatpickr-margin-l: 22px;
  --flatpickr-day-radius: 150px;
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: var(--text-size-m);
  line-height: var(--line-height-l);
  border-radius: var(--border-radius-default);
  position: absolute;
  width: var(--flatpickr-width);
  box-sizing: border-box;
  touch-action: manipulation;
  background: var(--background-color-secondary);
  box-shadow: var(--border-width-default) 0 0 var(--border-color-strong), calc(-1 * var(--border-width-default)) 0 0 var(--border-color-strong), 0 var(--border-width-default) 0 var(--border-color-strong), 0 calc(-1 * var(--border-width-default)) 0 var(--border-color-strong);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: var(--flatpickr-height-xl);
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: var(--flatpickr-margin-xs);
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: var(--flatpickr-height-l);
  border-top: var(--border-width-default) solid var(--border-color-strong);
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: var(--flatpickr-margin-l);
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: var(--flatpickr-margin-l);
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: var(--flatpickr-margin-s);
  margin: 0 calc(-1 * var(--flatpickr-margin-s));
}

.flatpickr-calendar:after {
  border-width: var(--border-width-xl);
  margin: 0 calc(-1 * var(--border-width-xl));
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--background-color-secondary);
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--background-color-secondary);
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--background-color-secondary);
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--background-color-secondary);
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: var(--content-color-primary);
  fill: var(--content-color-primary);
  height: var(--flatpickr-height-m);
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: var(--flatpickr-height-m);
  padding: var(--flatpickr-margin-m);
  z-index: 3;
  color: var(--content-color-primary);
  fill: var(--content-color-primary);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: var(--highlight-background-color-secondary);
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--content-color-brand);
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: var(--flatpickr-height-s);
  height: var(--flatpickr-height-s);
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: var(--flatpickr-height-s);
  padding: 0 var(--spacing-xs) 0 var(--flatpickr-margin-xs);
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: var(--border-width-default) solid var(--border-color-strong);
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: var(--highlight-background-color-primary);
}

.numInputWrapper span:active {
  background: var(--highlight-background-color-secondary);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  --custom-spacer: 2px;
  top: 0;
  right: calc(-1 * var(--custom-spacer));
  border-bottom: 0;
  background-color: inherit;
}

.numInputWrapper span.arrowUp:after {
  border-left: var(--border-width-xl) solid transparent;
  border-right: var(--border-width-xl) solid transparent;
  border-bottom: var(--border-width-xl) solid var(--content-color-primary);
  top: 36%;
}

.numInputWrapper span.arrowDown {
  --custom-spacer: 2px;
  top: 50%;
  right: calc(-1 * var(--custom-spacer));
  background-color: inherit;
}

.numInputWrapper span.arrowDown:after {
  border-left: var(--border-width-xl) solid transparent;
  border-right: var(--border-width-xl) solid transparent;
  border-top: var(--border-width-xl) solid var(--content-color-primary);
  top: 10%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: var(--background-color-secondary);
}

.numInputWrapper:hover {
  background: var(--highlight-background-color-secondary);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: var(--text-size-m);
  line-height: inherit;
  font-weight: var(--text-weight-regular);
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: var(--spacing-s) 0 0 0;
  line-height: 1;
  height: var(--flatpickr-height-m);
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-medium);
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: var(--highlight-background-color-secondary);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--content-color-primary);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: var(--content-color-primary);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  width: 115%;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: var(--content-color-primary);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  height: auto;
  line-height: inherit;
  margin: calc(-1 * var(--flatpickr-margin-xxs)) 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: var(--highlight-background-color-secondary);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--background-color-secondary);
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(2 * var(--flatpickr-height-s));
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: var(--content-color-secondary);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: var(--flatpickr-margin-xxs) 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: var(--flatpickr-width);
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: var(--flatpickr-width);
  min-width: var(--flatpickr-width);
  max-width: var(--flatpickr-width);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.flatpickr-day {
  background: none;
  border: var(--border-width-default) solid transparent;
  border-radius: var(--flatpickr-day-radius);
  box-sizing: border-box;
  color: var(--content-color-primary);
  cursor: pointer;
  font-weight: var(--text-weight-regular);
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: var(--flatpickr-height-l);
  height: var(--flatpickr-height-l);
  line-height: var(--flatpickr-height-l);
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: var(--highlight-background-color-secondary);
  border-color: var(--background-color-secondary);
}

.flatpickr-day.today {
  border-color: var(--border-color-strong);
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: var(--border-color-strong);
  background: var(--content-color-secondary);
  color: var(--background-color-primary);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--base-color-brand);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--background-color-primary);
  border-color: var(--base-color-brand);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: var(--content-color-tertiary);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: var(--content-color-tertiary);
  opacity: 0.3;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: var(--flatpickr-margin-xxs);
}

.flatpickr-innerContainer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: var(--flatpickr-height-l);
  max-height: var(--flatpickr-height-l);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: var(--flatpickr-height-l);
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--content-color-primary);
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--content-color-primary);
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  position: relative;
  box-sizing: border-box;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: var(--text-weight-regular);
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: var(--text-weight-regular);
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  width: 2%;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: var(--text-weight-regular);
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--highlight-background-color-secondary);
}

.flatpickr-confirm {
  height: 40px;
  max-height: 0px;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.06);
}

.flatpickr-confirm svg path {
  fill: inherit;
}

.flatpickr-confirm.darkTheme {
  color: white;
  fill: white;
}

.flatpickr-confirm.visible {
  max-height: 40px;
  visibility: visible;
}

.flatpickr-confirm {
  display: inline-flex;
  width: 50%;
  box-sizing: border-box;
  border: var(--border-width-default) solid var(--border-color-strong);
  background: var(--background-color-secondary);
  color: var(--content-color-primary);
}

.flatpickr-confirm:focus {
  outline: none;
}

.flatpickr-confirm:hover {
  background-color: var(--highlight-background-color-secondary);
}

.monitor-list-item-health-status {
  --bullet-size: 8px;
  width: var(--bullet-size);
  height: var(--bullet-size);
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  cursor: default;
}
.monitor-list-item-health-status.healthy, .monitor-list-item-health-status.up {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/monitor_success.svg");
}
.monitor-list-item-health-status.unhealthy, .monitor-list-item-health-status.down {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/monitor_error.svg");
}
.monitor-list-item-health-status.paused {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/monitor_pause.svg");
}
.monitor-list-item-health-status.unknown {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/monitor_unknown.svg");
}

.monitor-context-bar-list {
  --monitor-cb-label-width: 220px;
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  padding-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
}
.monitor-context-bar-list__label-wrapper {
  display: flex;
}
.monitor-context-bar-list__label-wrapper-label {
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  cursor: default;
  flex: 1;
}
.monitor-context-bar-list__label-wrapper-create-new-monitor {
  cursor: pointer;
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  align-items: center;
  padding-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  padding-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  min-width: calc(var(--size-s) + var(--size-m));
  height: var(--size-s);
}
.monitor-context-bar-list__label-wrapper-create-new-monitor-icon {
  padding-right: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
}
.monitor-context-bar-list__monitor-list {
  display: grid;
}
.monitor-context-bar-list__item {
  height: 20px;
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  display: flex;
  align-items: center;
}
.monitor-context-bar-list__item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  font-size: var(--text-size-m);
  width: var(--monitor-cb-label-width);
  flex: 1;
}
.monitor-context-bar-list__item-label > .aether-link-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.monitor-context-bar-list__item-icon {
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-s);
  align-items: center;
}
.monitor-context-bar-list__loading {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-l);
}
.monitor-context-bar-list__empty-state {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  margin-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  display: inline-flex;
  line-height: var(--line-height-m);
}
.monitor-context-bar-list__error-state {
  display: block;
}
.monitor-context-bar-list__error-state-message {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  margin-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  line-height: var(--line-height-m);
}
.monitor-context-bar-list__error-state-retry-btn {
  display: flex;
  margin-top: var(--spacing-xs);
}
.monitor-context-bar-list__show-all-monitors {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
}
.monitor-context-bar-list__show-all-monitors:hover {
  cursor: pointer;
  text-decoration: underline;
}

.monitor-sidebar-error-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: var(--spacing-xxl) var(--spacing-l);
}
.monitor-sidebar-error-view__illustration {
  height: 120px;
  width: 140px;
  margin-bottom: var(--spacing-l);
}
.monitor-sidebar-error-view__title {
  margin-bottom: var(--spacing-s);
  text-align: center;
}
.monitor-sidebar-error-view__subtitle {
  text-align: center;
  display: flex;
}
.monitor-sidebar-error-view__subtitle span {
  display: inline-block;
}
.monitor-sidebar-error-view__button {
  margin-right: 10px;
  margin-top: 4px;
}

.monitor-offline-container {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.monitor-offline-detail-tab {
  justify-content: center;
}

.monitor-offline {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 200px;
}

.monitor-offline-text-container {
  text-align: center;
  line-height: var(--line-height-m);
  padding-bottom: 20px;
}
.monitor-offline-text-container__header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-secondary);
}
.monitor-offline-text-container__body {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.monitor-offline-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-l);
}

.monitor-offline-icon {
  height: var(--size-m);
  width: var(--size-m);
}
.monitor-offline-icon svg {
  height: var(--size-m);
  width: var(--size-m);
}

.monitor-offline-detail-body-results {
  margin-top: 60px;
}

.contextbar-offline-container {
  --custom-height: 280px;
  --custom-margin: 40px;
  width: auto;
  height: var(--custom-height);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 0 auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  margin-right: var(--custom-margin);
}
.contextbar-offline-container__title {
  color: var(--content-color-secondary);
  padding: var(--spacing-xs);
  text-align: center;
}
.contextbar-offline-container__title h4 {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: 0;
  margin-top: var(--spacing-l);
}
.contextbar-offline-container__subtitle {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
  padding: var(--spacing-xs) var(--spacing-m);
  text-align: center;
}

.icon-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.monitor-tab-error {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.monitor-tab-error__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.monitor-tab-error__content__title {
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.monitor-tab-error__content__body {
  color: var(--content-color-secondary);
}

.monitor-contextbar-error-view {
  --custom-height: 280px;
  --custom-margin: 40px;
  width: auto;
  height: var(--custom-height);
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 0 auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  margin-right: var(--custom-margin);
}
.monitor-contextbar-error-view__illustration {
  height: 120px;
  width: 140px;
  margin-bottom: var(--spacing-l);
}
.monitor-contextbar-error-view__title {
  margin-bottom: var(--spacing-s);
  text-align: center;
}
.monitor-contextbar-error-view__subtitle {
  text-align: center;
  display: flex;
}
.monitor-contextbar-error-view__subtitle span {
  display: inline-block;
}

.create-new-monitor-modal__body {
  --input-components-width: 320px;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  padding: var(--spacing-xs);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group {
  margin-bottom: 10px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions, .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions {
  font-size: 12px;
  line-height: 36px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label.is-error, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .is-error.create-new-monitor__body__label--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .is-error.create-new-monitor__body__label--regions, .create-new-monitor-modal__body .create-new-monitor__body__input-group .is-error.create-new-monitor__body__label--regions {
  color: var(--base-color-error);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--schedule-selector {
  margin-bottom: var(--spacing-xs);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions {
  line-height: var(--line-height-m);
  padding-top: var(--spacing-s);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input {
  display: flex;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext, .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--regions, .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__form__body__input__helptext--regions {
  margin-top: 16px;
  padding-left: 20px;
  color: var(--content-color-tertiary);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--schedule-selector {
  margin-top: -1px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field {
  display: flex;
  flex: 0 0 320px;
  margin-bottom: auto;
  height: 100%;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown {
  width: var(--input-components-width);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container-selected {
  max-width: 270px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container {
  max-width: 250px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .input-checkbox {
  margin-right: 5px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input.create-new-monitor__body__input--regions {
  margin-left: var(--spacing-xl);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input-error {
  color: var(--base-color-error);
  font-size: 11px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section-toggle {
  margin-left: -10px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section {
  margin: 10px 0;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__field-error {
  color: var(--base-color-error);
  font-size: 11px;
  margin: 5px 25px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox {
  display: flex;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field {
  display: flex;
  align-items: center;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field__input {
  width: 300px;
  margin: 5px 10px 5px 25px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field .advanced-section__field__meta {
  margin: 5px 25px;
  font-size: 11px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--notifications {
  display: block;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .monitor-notification-limit__input {
  width: 75px;
  margin: 0 8px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .input-error-section {
  top: 3px;
  margin-top: 0px;
  display: inline-block;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout, .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay {
  font-size: 11px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout .advanced-section__field__input, .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay .advanced-section__field__input {
  width: 150px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select {
  margin: 5px 10px 5px 25px;
  display: flex;
  align-items: center;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select--retry > * {
  font-size: 11px;
  margin-right: 10px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select__dropdown--retry {
  width: 75px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox__label {
  margin-left: 10px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__checkbox-label-helper {
  font-size: 10px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual, .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual, .create-new-monitor-modal__body .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual, .form-content .create-new-monitor-form__content .step2.create-new-monitor-form__body .create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual {
  white-space: pre-line;
  width: fit-content;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual {
  margin-bottom: unset;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions__radio {
  margin-right: 5px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input--help {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext {
  color: var(--content-color-secondary);
  font-size: 12px;
  line-height: 24px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext .btn-text {
  padding: 0 3px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--private {
  margin-top: 10px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--auto {
  display: none;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--manual {
  display: flex;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__field {
  flex-wrap: wrap;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox {
  display: flex;
  width: 500px;
  color: var(--content-color-primary);
  margin-top: 8px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon {
  display: inline-block;
  padding-left: 4px;
  vertical-align: -2px;
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon use {
  fill: var(--content-color-tertiary);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg {
  color: var(--content-color-tertiary);
}
.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-region {
  height: 18px;
}

.preserve-context-loader {
  margin-top: 220px;
  width: 15px;
}

.trash-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 626px;
  margin: auto;
}
.trash-header__title {
  color: var(--content-color-primary);
  font-size: var(--text-size-l);
  margin-left: var(--spacing-xl);
}

.trash-banner {
  display: flex;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-xl);
  font-size: var(--text-size-m);
  background: var(--background-color-info);
  color: var(--content-color-primary);
}
.trash-banner__text {
  display: flex;
  align-items: center;
}
.trash-banner__icon {
  margin-right: var(--spacing-s);
}
.trash-banner__icon use {
  fill: var(--content-color-info);
}
.trash-banner__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-info);
}
.trash-banner__icon svg {
  color: var(--content-color-info);
}
.trash-banner .btn {
  padding: 0;
  height: 16px;
  min-width: auto;
  margin-left: var(--spacing-m);
}
.trash-banner .loading-indicator-wrapper {
  display: inline-block;
  margin-left: var(--spacing-xl);
}

.trash-tabs {
  margin: var(--spacing-s) var(--spacing-l);
}

.trash-list {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  margin: var(--spacing-xxl) auto var(--spacing-xl) auto;
  width: 85%;
  table-layout: fixed;
}
.trash-list thead {
  font-weight: var(--text-weight-medium);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-secondary);
}
.trash-list thead td {
  padding: var(--spacing-m);
  width: 40%;
}
.trash-list thead td:first-child {
  width: 50%;
}
.trash-list thead td:nth-child(3) {
  width: 10%;
}
.trash-list tbody {
  font-size: var(--font-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.trash-list tbody td {
  padding: 0 var(--spacing-m);
  height: 44px;
}
.trash-list tbody td:nth-child(3) .actions {
  display: none;
  justify-content: flex-end;
}
.trash-list tbody td:nth-child(3) .actions .btn {
  font-weight: var(--text-weight-medium);
  padding: 0;
  min-width: auto;
  font-size: var(--text-size-m);
}
.trash-list tbody td:nth-child(3) .actions .btn:first-child {
  margin-right: var(--spacing-s);
}
.trash-list tbody td:nth-child(3) .options {
  display: flex;
  justify-content: flex-end;
}
.trash-list tbody td:nth-child(3):hover .actions {
  display: flex;
}
.trash-list tbody td:nth-child(3):hover .options {
  display: none;
}
.trash-list tbody tr {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.trash-list tbody tr:hover {
  background: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
}
.trash-list tbody tr:hover .trash-list__actions {
  visibility: visible;
}
.trash-list__name {
  display: flex;
}
.trash-list__name i {
  margin-right: var(--spacing-m);
}
.trash-list__name-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
.trash-list__name .collection-fork-label {
  margin-left: var(--spacing-m);
}
.trash-list__time {
  min-width: 120px;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.trash-list__restore-btn {
  transform: scaleX(-1);
}

.trash-modal {
  width: initial !important;
  background: var(--background-color-primary);
  padding: var(--spacing-xxl);
}
.trash-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trash-modal-header h4 {
  margin: 0;
  font-size: var(--text-size-xxl);
}
.trash-modal-body {
  margin: var(--spacing-l) 0;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-top: 0;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.trash-modal-actions {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-s);
  width: 200px;
}
.trash-modal-actions .btn {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
}
.trash-modal-actions__cancel {
  width: 30%;
}
.trash-modal-actions__confirm {
  width: 70%;
  margin-left: var(--spacing-m);
}
.trash-modal__collection-name {
  margin-bottom: var(--spacing-xl);
  color: var(--content-color-secondary);
}
.trash-modal__collection-icon {
  margin-right: var(--spacing-xs);
  vertical-align: top;
}

.trash-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.trash-workspace__select {
  padding-left: 0;
  border: var(--border-width-default) solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  max-height: 300px;
  overflow: auto;
}

.trash-offline {
  position: relative;
  top: 25%;
}
.trash-offline-image {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
  margin: auto;
}

.entity-empty.trash-empty {
  border: none;
  padding-top: var(--spacing-xxl);
  position: relative;
  top: 25%;
  height: auto;
}

.trash {
  overflow: auto;
  height: 100%;
}

.trash-modal__restore {
  width: 440px;
  height: 520px;
}
.trash-modal__restore .modal-header {
  background-color: var(--background-color-primary);
}
.trash-modal__restore .modal-header-title {
  margin-top: var(--spacing-m);
  margin-left: var(--spacing-s);
}
.trash-modal__restore .modal-content {
  padding-top: 0;
  overflow: hidden;
}
.trash-modal__restore .workspace-empty-state {
  margin-top: 50px;
}

.pm-multi-select {
  list-style: none;
}
.pm-multi-select li:first-of-type {
  background: var(--background-color-secondary);
  text-transform: uppercase;
  color: var(--content-color-secondary);
  font-size: var(--font-size-m);
  padding-top: var(--spacing-m);
  margin-bottom: var(--spacing-l);
}
.pm-multi-select li:first-of-type .pm-checkbox {
  top: 3px !important;
}
.pm-multi-select li:first-of-type .pm-label {
  line-height: var(--line-height-m);
}
.pm-multi-select__item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;
}
.pm-multi-select__item .pm-checkbox {
  top: 4px !important;
}
.pm-multi-select__item .pm-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-left: var(--spacing-s);
}
.pm-multi-select__label {
  color: var(--content-color-primary);
}
.pm-multi-select__checkbox {
  display: inline-block;
  margin-right: 16px;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.pm-multi-select__checkbox .pm-icon {
  padding: 2px;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb, postman-stylelint/no-legacy-color */
.menu {
  position: absolute;
  background: var(--background-color-primar);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin-top: 8px;
  min-width: 300px;
  max-width: 420px;
  box-shadow: 0 0 10px -5px #000;
}

.menu-section {
  border-bottom: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
}

.menu-section:first-of-type {
  border-top: 0;
}

.menu-section:last-of-type {
  border-bottom: 0;
}

.menu-section + .menu-section {
  border-top: 0;
}

.menu-section__title {
  font-size: 1rem;
  padding: 0.5rem;
  font-weight: 700;
}

.menu-section-title:empty {
  padding: 0;
}

.menu-section-title:empty + .menu-section {
  border-top: 0;
}

.option {
  min-height: 24px;
  padding: 8px 16px;
}

.option.focused,
.option:hover {
  background: var(--background-color-secondary);
  cursor: pointer;
}

.option:focus {
  border: 10px solid pink;
}

.option.disabled {
  opacity: 0.5;
  background: var(--background-color-secondary);
}

.option.disabled:hover {
  cursor: no-drop;
}

.Select {
  position: relative;
}

.Select,
.Select div,
.Select input,
.Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
}

.Select-control {
  background: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--border-color-default);
  color: var(--content-color-primary);
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 32px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Select-control .Select-input:focus {
  outline: none;
}

.is-searchable.is-open > .Select-control {
  cursor: text;
}

.is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: var(--background-color-primary);
  border-color: #bbb #d4d4d4 #e1e1e1;
}

.is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent var(--content-color-primary);
  border-width: 0 4px 4px;
}

.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}

.is-focused:not(.is-open) > .Select-control {
  border-color: #f26b3a #f47d52 #f47d52;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px fade(#f26b3a, 50%);
}

.Select--single > .Select-control .Select-value,
.Select-placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-input {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  max-width: calc(100% - 24px);
  overflow: hidden;
}

.Select-input > input {
  background: none transparent;
  border: 0;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
  margin-left: 14px;
  padding: 6px 0 10px;
  -webkit-appearance: none;
}

.is-focused .Select-input > input {
  cursor: text;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  text-align: center;
}

.Select-loading,
.Select-loading-zone {
  position: relative;
  vertical-align: middle;
  width: 16px;
}

.Select-loading {
  -webkit-animation: Select-animation-spin 0.4s linear infinite;
  -o-animation: Select-animation-spin 0.4s infinite linear;
  animation: Select-animation-spin 0.4s linear infinite;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid var(--border-color-default);
  border-right-color: var(--content-color-primary);
  display: inline-block;
}

.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 0.2s;
  -o-animation: Select-animation-fadeIn 0.2s;
  animation: Select-animation-fadeIn 0.2s;
  color: var(--content-color-primary);
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 50%;
  right: 15px;
  transform: translateY(50%);
  width: 15px;
  height: 15px;
  margin-bottom: 1px;
}

.Select-clear-zone:hover {
  color: #d0021b;
}

.Select-clear {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
}

.Select--multi .Select-clear-zone {
  width: 15px;
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  padding-right: 4px;
}

.Select-arrow {
  border-color: var(--content-color-primary) transparent transparent;
  border-style: solid;
  border-width: 4px 4px 2px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}

.is-open .Select-arrow,
.Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #535353;
}

@-webkit-keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Select--single.pm-select {
  font-size: 12px;
  height: 32px;
  max-width: 320px;
}

.Select--single.pm-select .Select-value {
  pointer-events: none;
}

.Select--single.pm-select .Select-control {
  display: inline-block;
  overflow: visible;
  max-width: 100%;
  width: auto;
  cursor: pointer;
}

.Select--single.pm-select .Select-control:hover {
  box-shadow: none;
}

.Select--single.pm-select .Select-placeholder,
.Select--single.pm-select .Select-value {
  position: static;
  padding-right: 24px;
}

.Select--single.pm-select .Select-input {
  top: 0;
  left: 0;
  position: absolute;
}

.Select--single.pm-select .Select-arrow-zone {
  top: 50%;
  right: 0;
  padding: 0;
  position: absolute;
  display: inline-block;
  transform: translateY(-50%);
}

.Select--single.pm-select .Select-menu-outer {
  width: auto;
  margin-top: 4px;
  min-width: 120px;
  max-width: 300px;
  max-height: 400px;
  border-radius: var(--border-radius-default);
  font-size: 14px;
}

.Select--single.pm-select .Select-menu {
  max-height: 398px;
}

.Select--single.pm-select .Select-option {
  overflow: hidden;
  padding-left: 16px;
  padding-right: 32px;
  color: var(--content-color-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Select--single.pm-select .Select-option:first-child {
  margin-top: 8px;
}

.Select--single.pm-select .Select-option:last-child {
  margin-bottom: 8px;
}

.Select--single.pm-select .Select-option:hover {
  background: var(--background-color-tertiary);
  color: var(--content-color-primary);
}

.Select--single.pm-select .Select-option:active {
  background: var(--background-color-secondary);
}

.Select--single.pm-select--align-right .Select-menu-outer {
  right: 0;
}

.Select--single.pm-select--loading .Select-arrow {
  display: none;
}

.Select--single.pm-select .Select-placeholder {
  color: #7e7e7e;
}

.Select--single.pm-select .Select-value {
  color: var(--content-color-primary);
}

.Select--single.pm-select-text {
  display: inline-block;
}

.Select--single.pm-select-text .Select-control {
  background: none;
  border: thin solid transparent;
}

.Select--single.pm-select-text .Select-arrow,
.Select--single.pm-select-text .Select-placeholder,
.Select--single.pm-select-text .Select-value {
  color: #f15a24;
  border-top-color: #f15a24;
}

.Select--single.pm-select-text:active .Select-arrow,
.Select--single.pm-select-text:active .Select-placeholder,
.Select--single.pm-select-text:active .Select-value,
.Select--single.pm-select-text:hover .Select-arrow,
.Select--single.pm-select-text:hover .Select-placeholder,
.Select--single.pm-select-text:hover .Select-value {
  color: #dd5524;
  border-top-color: #dd5524;
}

.Select--single.pm-select-text.is-open .Select-arrow {
  border-bottom-color: #f15a24;
}

.Select--single.pm-select-text.is-open:active .Select-arrow,
.Select--single.pm-select-text.is-open:hover .Select-arrow {
  border-bottom-color: #dd5524;
}

.Select--single.pm-select-text:not(.is-focused) .Select-control {
  border: thin solid transparent;
}

.Select--single.pm-select-text:not(.is-focused) .Select-control:hover {
  box-shadow: none;
}

.Select--single.pm-select-text--dark .Select-control {
  border: thin solid transparent;
}

.Select--single.pm-select-text--dark .Select-arrow,
.Select--single.pm-select-text--dark .Select-placeholder,
.Select--single.pm-select-text--dark .Select-value,
.Select--single.pm-select-text--dark:active .Select-arrow,
.Select--single.pm-select-text--dark:active .Select-placeholder,
.Select--single.pm-select-text--dark:active .Select-value,
.Select--single.pm-select-text--dark:hover .Select-arrow,
.Select--single.pm-select-text--dark:hover .Select-placeholder,
.Select--single.pm-select-text--dark:hover .Select-value {
  color: #fff;
  border-top-color: #fff;
}

.Select--single.pm-select-text--dark.is-open .Select-arrow,
.Select--single.pm-select-text--dark.is-open:active .Select-arrow,
.Select--single.pm-select-text--dark.is-open:hover .Select-arrow {
  border-bottom-color: #fff;
}

.Select--single .pm-select-loader {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.Select--single.pm-select-md {
  font-size: 14px;
}

.Select--single.pm-select-md,
.Select--single.pm-select-md .Select-control,
.Select--single.pm-select-md .Select-input {
  height: 40px;
}

.Select--single.pm-select-md .Select-input,
.Select--single.pm-select-md .Select-placeholder,
.Select--single.pm-select-md .Select-value {
  line-height: 40px;
}

.Select--single.pm-select-lg {
  font-size: 16px;
}

.Select--single.pm-select-lg,
.Select--single.pm-select-lg .Select-control,
.Select--single.pm-select-lg .Select-input {
  height: 48px;
}

.Select--single.pm-select-lg .Select-input,
.Select--single.pm-select-lg .Select-placeholder,
.Select--single.pm-select-lg .Select-value {
  line-height: 48px;
}

.Select--single.pm-select--block,
.Select--single.pm-select--block .Select-control {
  width: 100%;
  max-width: 100%;
}

.Select--single.pm-select--block .Select-menu-outer {
  width: 100%;
  max-width: none;
}

.Select--single.is-searchable .Select-control,
.Select--single.is-searchable .Select-multi-value-wrapper {
  display: block;
}

.react-16-dropdown {
  display: inline-block;
}

.react-16-dropdown .trigger-renderer {
  position: relative;
  padding: 8px;
}

.react-16-dropdown .trigger-renderer:focus {
  outline: none;
}

.react-16-dropdown.pm-dropdown--block,
.react-16-dropdown.pm-dropdown--block .trigger {
  display: block;
}

.react-16-dropdown.pm-dropdown--block .trigger-renderer {
  width: 100%;
  display: block;
}

.react-16-dropdown.pm-more-options-dropdown .pm-btn__default-icon svg {
  height: 14px;
  width: 14px;
}

.react-16-dropdown.pm-dropdown-md .trigger-renderer {
  font-size: 14px;
  line-height: 24px;
}

.react-16-dropdown.pm-dropdown-lg .trigger-renderer {
  font-size: 16px;
  line-height: 28px;
}

.react-16-dropdown-portal .option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-16-dropdown-portal .menu {
  padding: 8px 0;
  max-width: 300px;
  min-width: 120px;
  z-index: 100;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.react-16-dropdown-portal .menu:focus,
.react-16-dropdown-portal .menu > div:focus {
  outline: none;
}

.Select-menu-outer {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-top-color: var(--border-color-default);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  box-sizing: border-box;
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Select-option:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.Select-option.is-selected {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}

.Select-option.is-focused {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}

.Select-option.is-disabled {
  color: #c1c1c1;
  cursor: default;
}

.Select-noresults {
  box-sizing: border-box;
  color: #8e8e8e;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}

.Select--multi.has-value .Select-input {
  margin-left: 5px;
}

.Select--multi .Select-value {
  background-color: var(--background-color-primary);
  border-radius: 2px;
  border: 1px solid #c9e6f2;
  color: #08c;
  display: inline-block;
  font-size: 0.9em;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
}

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle;
}

.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px;
}

.Select--multi a.Select-value-label {
  color: #08c;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c9e6f2;
  padding: 1px 5px 3px;
}

.Select--multi .Select-value-icon:focus,
.Select--multi .Select-value-icon:hover {
  background-color: #ddeff7;
  color: #0077b3;
}

.Select--multi .Select-value-icon:active {
  background-color: #c9e6f2;
}

.Select--multi.is-disabled .Select-value {
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  color: #333;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon:active,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:hover {
  background-color: var(--background-color-primary);
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
.pull-request-topbar {
  position: relative;
  overflow: hidden;
  padding: var(--spacing-l) 0;
}
.pull-request-topbar__actions {
  margin-top: var(--spacing-s);
  right: 0;
  bottom: 16px;
}
@media (min-width: 1024px) {
  .pull-request-topbar__actions {
    position: absolute;
  }
}
.pull-request-topbar__actions-tooltip-fork-label {
  margin-left: var(--spacing-s);
  margin-bottom: 1px;
}
.pull-request-topbar__action-btn {
  display: none;
  margin-right: var(--spacing-s);
}
@media (min-width: 768px) {
  .pull-request-topbar__action-btn {
    display: inline-block;
    vertical-align: middle;
  }
}
.pull-request-topbar__approve span {
  border-radius: var(--border-radius-default);
  margin-left: var(--spacing-xs);
  padding: 0px var(--spacing-xs);
  color: var(--content-color-constant);
}
.pull-request-topbar__approver-list {
  margin: var(--spacing-s);
}
.pull-request-topbar__approver-list h4 {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-m);
  font-size: var(--text-size-l);
  margin: 0px;
  margin-bottom: var(--spacing-m);
  white-space: nowrap;
}
.pull-request-topbar__approver-list .approved-user {
  margin: var(--spacing-s) 0px;
  padding-left: var(--spacing-xs);
}
.pull-request-topbar__approver-list .approved-user span {
  position: static;
  padding: 0px var(--spacing-xxl);
  white-space: nowrap;
}
.pull-request-topbar__actions-comment-only {
  margin-top: var(--spacing-s);
  position: relative;
  right: 0;
  bottom: 16px;
}
@media (min-width: 768px) {
  .pull-request-topbar__actions-comment-only {
    position: absolute;
  }
}
.pull-request-topbar__action-btn-comment {
  display: inline-block;
  margin-top: var(--spacing-l);
}
@media (max-width: 768px) {
  .pull-request-topbar__action-btn-comment {
    margin-top: var(--spacing-l);
  }
}
.pull-request-topbar__action-btn-comment:not(:last-child) {
  margin-right: var(--spacing-s);
}
.pull-request-topbar__more-options {
  float: right;
}
.pull-request__title {
  font-size: var(--text-size-xxxl);
  color: var(--content-color-primary);
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.pull-request__status {
  border-radius: var(--border-radius-default);
  padding: 0 8px !important;
}
.pull-request__changes-count {
  margin-left: var(--spacing-s);
  height: 18px;
}
.pull-request__changes-count span {
  color: var(--content-color-secondary);
  line-height: inherit;
}
@media (min-width: 568px) {
  .pull-request__source-destination-label {
    width: 90%;
  }
}
@media (min-width: 1024px) {
  .pull-request__source-destination-label {
    width: 100%;
    padding-right: 440px;
  }
}

.pm-diff {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  width: 100%;
}
.pm-diff--added {
  background: var(--background-color-success);
  color: var(--content-color-success);
}
.pm-diff--removed {
  background: var(--background-color-error);
  color: var(--content-color-error);
}
.pm-diff--scrollable {
  overflow: auto;
}
.pm-diff-words {
  overflow-wrap: break-word;
  border: var(--border-width-default) solid var(--border-color-default);
  color: var(--content-color-primary);
}
.pm-diff-words > div {
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  padding-left: var(--spacing-s);
}
.pm-diff-sentences {
  overflow-wrap: break-word;
  font-family: var(--text-family-code);
  border: var(--border-width-default) solid var(--border-color-default);
  color: var(--content-color-primary);
  white-space: pre;
}
.pm-diff-code {
  overflow: auto;
  border: var(--border-width-default) solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  font-family: var(--text-family-code);
  color: var(--content-color-primary);
}
.pm-diff-code__index {
  display: table-cell;
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-xs);
  background: var(--background-color-secondary);
  text-align: right;
  color: var(--content-color-tertiary);
  width: 32px;
  max-width: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pm-diff-code__line {
  display: inline-block;
  padding: var(--spacing-xs);
  position: relative;
  border-left: var(--border-width-default) solid var(--border-color-strong);
  white-space: pre-wrap;
  margin: 0;
  color: var(--content-color-primary);
}
.pm-diff-code__row {
  margin-left: var(--spacing-s);
  display: table-row;
  height: 28px;
  color: var(--content-color-primary);
}
.pm-diff-code .pm-conflict-actions {
  padding-left: 64px;
  margin-right: var(--spacing-l);
}
.pm-diff-table {
  overflow-wrap: break-word;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: var(--border-width-default) solid var(--border-color-default);
  text-align: left;
  color: var(--content-color-primary);
}
.pm-diff-table th,
.pm-diff-table tr,
.pm-diff-table td {
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: var(--border-width-default) solid var(--border-color-default);
  position: relative;
  padding: 0 0 0 var(--spacing-s);
}
.pm-diff-table__enabled {
  width: 5%;
}
.pm-diff-table__enabled .pm-checkbox-input {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -10px);
  padding-left: var(--spacing-l);
}
.pm-diff-table__key {
  width: 25%;
}
.pm-diff-table__value {
  width: 30%;
}
.pm-diff-table__description {
  width: 40%;
}
.pm-diff-table th {
  text-transform: uppercase;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.pm-diff-body {
  margin-left: var(--spacing-s);
}
.pm-diff-item__toggle {
  margin-right: var(--spacing-s);
  vertical-align: text-top;
}
.pm-diff__label-icon {
  position: relative;
  top: 2px;
}

.pm-conflict {
  position: relative;
  margin: var(--spacing-s) 0;
}
.pm-conflict-actions {
  margin-right: var(--spacing-l);
  font-size: var(--text-size-m);
  cursor: pointer;
}
.pm-conflict-actions__source {
  color: var(--content-color-brand);
  font-weight: var(--text-weight-medium);
  font-family: var(--text-family-default);
}
.pm-conflict-actions__dest {
  color: var(--base-color-info);
  font-weight: var(--text-weight-medium);
  font-family: var(--text-family-default);
}
@media (min-width: 768px) {
  .pm-conflict-actions {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 0;
  }
}
.pm-conflict-actions .btn.btn-secondary.btn-small {
  padding: 0;
  margin-left: var(--spacing-l);
  height: 24px;
  border: var(--border-width-default) solid var(--border-color-strong);
}
.pm-conflict--source {
  background: var(--background-color-brand);
  min-height: 20px;
}
.pm-conflict--dest {
  background: var(--background-color-info);
  min-height: 20px;
}
@media (min-width: 768px) {
  .pm-conflict {
    padding-right: 168px;
  }
}

.is-multiline {
  top: 14px;
  position: absolute;
  right: 0;
}

.collection-diff__wrapper {
  padding: 0 var(--spacing-l);
  margin-top: var(--spacing-l);
  overflow-x: hidden;
}
.collection-diff-header-label {
  padding: 0 var(--spacing-l);
  border-bottom: 1px solid var(--border-color-default);
}
.collection-diff-header__text {
  margin-top: var(--spacing-l);
  display: inline-block;
  margin-left: var(--spacing-l);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.collection-diff-alert .pm-alert {
  padding: var(--spacing-s) var(--spacing-l);
}
@media (min-width: 1024px) {
  .collection-diff-alert .pm-alert-body {
    padding-right: 160px;
  }
}
.collection-diff-alert__actions {
  display: block;
  margin-top: var(--spacing-s);
}
@media (min-width: 1024px) {
  .collection-diff-alert__actions {
    position: absolute;
    margin: 0;
    right: 64pxvar(--spacing-s);
    top: 50%;
    transform: translateY(-50%);
  }
}
.collection-diff__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.collection-diff-body {
  overflow-x: hidden;
  font-size: var(--text-size-m);
}
.collection-diff-body__title {
  color: var(--content-color-primary);
  text-transform: uppercase;
  margin-bottom: var(--spacing-s);
}
.collection-diff-body__title .pm-badge-warning {
  display: inline;
  font-size: var(--text-size-s);
}
.collection-diff-body__request-label {
  margin-right: var(--spacing-s);
  display: inline-block;
  font-size: var(--text-size-m);
}
.collection-diff-body__heading {
  color: var(--content-color-secondary);
  margin: 0;
  cursor: pointer;
  display: inline-block;
}
.collection-diff-body__heading .pm-icon {
  vertical-align: middle;
}
.collection-diff-body__heading .pm-tooltip {
  text-transform: none;
}
.collection-diff-body__heading .pm-badge > span {
  display: inline;
}
.collection-diff-body__heading .pm-badge-success {
  background: transparent;
  color: var(--content-color-success);
  border: var(--border-width-default) solid var(--content-color-success);
}
.collection-diff-body__heading .pm-badge-secondary {
  background: transparent;
  color: var(--content-color-secondary);
  border: var(--border-width-default) solid var(--border-color-default);
}
.collection-diff-body__heading .pm-badge-error {
  background: transparent;
  color: var(--content-color-error);
  border: var(--border-width-default) solid var(--content-color-error);
}
.collection-diff-body__heading .pm-badge-info {
  background: transparent;
  color: var(--base-color-info);
  border: var(--border-width-default) solid var(--base-color-info);
}
.collection-diff-body__nested-section {
  padding: var(--spacing-s) var(--spacing-xl);
}
.collection-diff-body__nested-section .pm-conflict-actions {
  right: var(--spacing-l);
}
.collection-diff-body__nested-section h3 {
  margin-bottom: var(--spacing-xs);
}
.collection-diff-body__nested-section .collection-diff-body__collapsible {
  margin: 0;
}
.collection-diff-body__collapsible {
  margin-bottom: var(--spacing-l);
  padding: var(--spacing-m);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.collection-diff-body__collapsible.collapsed {
  background: var(--background-color-secondary);
}
.collection-diff-body__section {
  padding: 0 var(--spacing-l) 0 var(--spacing-xl);
  margin: var(--spacing-l) 0;
}
.collection-diff-body__toggle {
  cursor: pointer;
  color: var(--content-color-secondary);
  display: inline-block;
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-l);
  margin-left: var(--spacing-s);
}
.collection-diff-body__toggle:before {
  font-size: var(--text-size-xxxl);
  width: var(--spacing-s);
  top: -2px;
  left: -12px;
  height: 0;
  content: " ";
  position: relative;
  display: inline-block;
  border-color: transparent;
  border-left-color: inherit;
  border-width: 4px 0px 4px 5px;
  border-style: solid;
}
.collection-diff-body__toggle.is-visible:before {
  border-color: transparent;
  border-top-color: inherit;
  border-width: 5px 4px 0;
}
@media (min-width: 768px) {
  .collection-diff-body__nav-item {
    margin-left: 320px;
  }
}
.collection-diff__element-title {
  margin-left: 8px;
}
.collection-diff-nav {
  display: none;
  background: var(--content-color-constant);
  position: sticky;
  top: 0;
  width: 320px;
  padding-top: var(--spacing-l);
  height: 100vh;
  overflow: auto;
  float: left;
  font-size: var(--text-size-m);
  border-right: var(--border-width-default) solid var(--border-color-default);
}
@media (min-width: 768px) {
  .collection-diff-nav {
    display: block;
  }
}
.collection-diff-nav__prop {
  width: 100%;
  margin: 0;
  padding: var(--spacing-s);
  display: block;
  text-decoration: none;
  color: var(--content-color-primary);
}
.collection-diff-nav__prop--conflict {
  background: var(--background-color-warning);
}
.collection-diff-nav__prop:hover {
  background: var(--background-color-secondary);
}
.collection-diff-nav__type {
  display: inline-block;
  width: 5%;
  height: var(--spacing-l);
  border-radius: var(--border-radius-default);
  vertical-align: top;
  text-align: center;
  font-size: 10px;
  color: var(--content-color-secondary);
  border: var(--border-width-default) solid var(--content-color-secondary);
}
.collection-diff-nav__type--added {
  color: var(--content-color-success);
  border: var(--border-width-default) solid var(--content-color-success);
}
.collection-diff-nav__type--modified {
  color: var(--content-color-secondary);
  border: var(--border-width-default) solid var(--content-color-secondary);
}
.collection-diff-nav__type--deleted {
  color: var(--content-color-error);
  border: var(--border-width-default) solid var(--content-color-error);
}
.collection-diff-nav__type--conflict {
  border: none;
  background: var(--content-color-warning);
  color: var(--content-color-constant);
}
.collection-diff-nav__name {
  display: inline-block;
  text-transform: uppercase;
  width: 40%;
  white-space: nowrap;
  margin: auto var(--spacing-l);
  padding: 0;
  font-weight: var(--text-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
}
.collection-diff-nav__diff {
  padding: 0;
  width: 30%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.collection-diff-nav__header {
  display: block;
  text-transform: uppercase;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  padding: var(--spacing-s);
  text-decoration: none;
  margin-top: var(--spacing-l);
}
.collection-diff-nav__header:hover {
  background: var(--background-color-secondary);
}
.collection-diff-nav__item-name {
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: 80%;
  margin: auto var(--spacing-l);
  padding: 0;
  font-weight: var(--text-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
}
.collection-diff-topbar__header {
  display: inline-block;
}
.collection-diff-topbar__actions {
  float: right;
  margin-right: var(--spacing-l);
  margin-top: var(--spacing-s);
}
.collection-diff-topbar__actions-cancel {
  margin-right: var(--spacing-s);
}
.collection-diff-topbar__actions-merge {
  margin-right: var(--spacing-s);
}
.collection-diff-topbar__actions-refresh {
  vertical-align: middle;
  background-color: transparent;
}
.collection-diff-topbar__actions-refresh:hover {
  background-color: transparent;
}
.collection-diff-topbar__actions-refresh-icon {
  margin-right: var(--spacing-xs);
}
.collection-diff__source-destination-label {
  padding-bottom: var(--spacing-m);
  display: flex;
}
.collection-diff__empty-state {
  position: relative;
  top: 30%;
}
.collection-diff__empty-state-image {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/activity-feed.svg");
  margin: auto;
}
.collection-diff__error-state {
  margin-top: var(--spacing-xxl);
  position: relative;
  top: 30%;
}
.collection-diff__error-state-image {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
  margin: auto;
}
.collection-diff__infobar-icon {
  margin-right: var(--spacing-l);
}
.collection-diff-offline {
  position: relative;
  top: 25%;
}
.collection-diff-offline-image {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
  margin: auto;
}
.collection-diff__request {
  display: inline;
}
.collection-diff__request-method {
  display: inline;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
}
.collection-diff__request-method--small {
  display: inline;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}

.collection-conflict-source {
  background: var(--background-color-error);
}
.collection-conflict-dest {
  background: var(--background-color-info);
}

.empty-state {
  text-align: center;
}
.empty-state__image {
  max-width: 160px;
  margin-bottom: var(--spacing-xl);
}
.empty-state__title {
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
}
.empty-state__body {
  line-height: 20px;
  color: var(--content-color-primary);
  margin: var(--spacing-l) auto var(--spacing-xxl);
  font-size: var(--text-size-m);
}
.empty-state__action {
  margin-bottom: var(--spacing-xl);
}
.empty-state__crash-handler {
  margin-top: 200px;
}
.empty-state__crash-handler .empty-state__body {
  max-width: 500px;
}
.empty-state__subtext {
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
}

.pm-link {
  color: var(--content-color-primary);
  text-decoration: none;
}
.pm-link:hover {
  cursor: pointer;
}

.diff-entity {
  font-size: var(--text-size-l);
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: var(--spacing-l);
  width: 75%;
  display: flex;
  align-items: center;
  margin-top: var(--spacing-s);
}
@media (min-width: 568px) {
  .diff-entity {
    margin-bottom: 0;
  }
}
.diff-entity__separator {
  display: none;
  margin: 0 var(--spacing-xs);
}
@media (min-width: 568px) {
  .diff-entity__separator {
    display: inline-flex;
  }
}
.diff-entity__fork-label {
  margin-left: var(--spacing-s);
  max-width: 25%;
}
.diff-entity__fork-label span {
  font-weight: var(--text-weight-regular);
}
.diff-entity__label-group {
  display: flex;
  align-items: center;
}
.diff-entity__source {
  color: var(--content-color-brand);
  margin-right: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
  display: inline-block;
  font-size: var(--text-size-m);
}
.diff-entity__dest {
  color: var(--content-color-info);
  margin-right: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
  display: inline-block;
  font-size: var(--text-size-m);
}
.diff-entity__collection-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--content-color-primary);
  display: inline-block;
  vertical-align: middle;
  font-size: var(--text-size-m);
}
.diff-entity__collection-name-source {
  max-width: 50%;
}
@media (min-width: 480px) {
  .diff-entity__collection-name-source {
    max-width: 60%;
  }
}
@media (min-width: 568px) {
  .diff-entity__collection-name-source {
    max-width: 45%;
  }
}
@media (min-width: 768px) {
  .diff-entity__collection-name-source {
    max-width: 50%;
  }
}
.diff-entity__collection-name-destination {
  max-width: 60%;
  margin-right: var(--spacing-xs);
}
.diff-entity__collection-name-destination-fork {
  max-width: 40%;
}
@media (min-width: 480px) {
  .diff-entity__collection-name-destination-fork {
    max-width: 50%;
  }
}
@media (min-width: 568px) {
  .diff-entity__collection-name-destination-fork {
    max-width: 25%;
  }
}
@media (min-width: 768px) {
  .diff-entity__collection-name-destination-fork {
    max-width: 30%;
  }
}
.diff-entity__conflict-source {
  display: inline-block;
  background: rgba(var(--highlight-background-color-primary), 0.1);
}
.diff-entity__conflict-dest {
  display: inline-block;
  background: rgba(var(--highlight-background-color-primary), 0.1);
}
.diff-entity__alert-icon svg {
  height: 12px;
  width: 12px;
}
.diff-entity__collection-unavailable {
  position: relative;
  top: 2px;
}
.diff-entity__link--disabled {
  pointer-events: none;
}
.diff-entity__link--disabled .diff-entity__collection-name-source,
.diff-entity__link--disabled .diff-entity__collection-name,
.diff-entity__link--disabled .pull-request__fork-label {
  opacity: 0.5;
}

.custom-tooltip {
  display: inline;
  max-width: 100%;
  align-items: center;
  overflow: hidden;
  color: var(--content-color-secondary);
}
.custom-tooltip__body div {
  margin-bottom: var(--spacing-zero);
}
.custom-tooltip__action {
  float: right;
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
  margin-right: var(--spacing-xl);
}
.custom-tooltip span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-m);
}
.custom-tooltip .pm-icon {
  margin-right: var(--spacing-xs);
}

.fork-button__action {
  height: var(--size-m);
  min-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: var(--spacing-s);
}
.fork-button__action.has-label i {
  margin-right: var(--spacing-xs);
}

.fork-button__count {
  visibility: hidden;
  width: 0;
}
.fork-button__count.visible {
  visibility: visible;
  width: auto;
}
.fork-button__count.no-click {
  pointer-events: none;
}
.fork-button__count .btn {
  min-width: auto;
  height: var(---controls-size-default);
  display: inline-block;
  border-top-right-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: var(--spacing-s) var(--spacing-m);
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}

.fork-creator-popover {
  width: 256px;
}
.fork-creator-popover .tooltip-wrapper {
  padding: var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  background-color: var(--background-color-primary);
}
.fork-creator-popover .tooltip-wrapper h4 {
  margin: 0;
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  padding-bottom: var(--spacing-s);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.fork-creator-popover .loading-indicator-wrapper {
  margin: var(--spacing-l) 0;
  text-align: center;
}
.fork-creator-popover__error, .fork-creator-popover__empty {
  margin: var(--spacing-l) 0 0 0;
}
.fork-creator-popover__tooltip {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.fork-creator-popover__tooltip .tooltip-wrapper {
  padding: var(--spacing-l);
}

.fork-creator-list {
  list-style: none;
  padding-left: 0;
  margin: var(--spacing-l) 0 0 0;
  max-height: 128px;
  overflow: auto;
}
.fork-creator-list__item {
  display: flex;
  align-items: center;
  font-weight: var(--text-weight-regular);
  height: 32px;
}
.fork-creator-list__item a {
  color: var(--content-color-primary);
  text-decoration: none;
}
.fork-creator-list__item a:hover {
  text-decoration: underline;
}
.fork-creator-list__private {
  background: var(--background-color-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-right: var(--spacing-s);
}
.fork-creator-list .avatar {
  margin-right: var(--spacing-s);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.collection-merge {
  max-width: 480px;
  margin: 0 auto;
  position: relative;
}
.collection-merge__name {
  margin-top: var(--spacing-s);
  font-size: var(--text-size-l);
  color: var(--content-color-secondary);
}
.collection-merge__close {
  position: absolute;
  top: var(--spacing-xxl);
  right: var(--spacing-xxl);
}
.collection-merge__option {
  border: 1px solid var(--border-color-default);
  padding: var(--spacing-l);
  border-radius: var(--border-radius-default);
  margin-bottom: var(--spacing-l);
}
.collection-merge__option > label {
  cursor: pointer;
}
.collection-merge__option--active {
  border: 1px solid var(--base-color-brand);
  background: rgba(255, 108, 55, 0.03);
}
.collection-merge__option--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.collection-merge__option--disabled > label {
  cursor: not-allowed;
}
.collection-merge__label {
  display: block;
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-s);
  font-weight: var(--text-weight-medium);
}
.collection-merge__label-summary {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
.collection-merge__actions {
  margin-top: var(--spacing-xxl);
  position: relative;
  display: flex;
}
.collection-merge__actions-merge {
  margin-left: var(--spacing-s);
}
.collection-merge__actions-cancel {
  width: 30%;
}
.collection-merge__cancel {
  width: 88px;
  max-width: 88px;
}
@media (min-width: 768px) {
  .collection-merge__close {
    right: var(--spacing-xxl);
  }
}
.collection-merge__strategy-description {
  margin-top: var(--spacing-s);
}
.collection-merge__strategy-label {
  margin-left: var(--spacing-m);
}
.collection-merge__radio {
  position: relative;
  top: -8px;
}
.collection-merge__option-tooltip {
  z-index: 100;
}
.collection-merge__option-tooltip-anchor {
  display: block;
  color: unset;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.pull-request {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pull-request-body {
  max-width: 90%;
}
.pull-request__action--decline {
  color: var(--content-color-error);
}
.pull-request__action--decline:hover, .pull-request__action--decline:hover .dropdown-menu-item-label {
  color: var(--content-color-constant);
  background-color: var(--base-color-error);
}
.pull-request__inline-comment-header {
  cursor: pointer;
  display: inline-block;
}
.pull-request__actions--merge {
  margin-right: var(--spacing-s);
}
.pull-request__body {
  padding: var(--spacing-l);
  padding-top: 0;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.pull-request-view__header {
  margin-right: var(--spacing-xl);
  margin-left: var(--spacing-xl);
  border-bottom: 1px solid var(--border-color-default);
}
.pull-request__actions {
  float: right;
  margin-top: var(--spacing-s);
}
.pull-request__actions-approve {
  vertical-align: bottom;
  margin-right: var(--spacing-l);
  font-size: var(--text-size-m);
}
.pull-request__actions-unapprove-icon {
  vertical-align: sub;
  margin-right: var(--spacing-s);
  font-size: var(--text-size-m);
}
.pull-request__actions-merge-icon {
  margin-right: var(--spacing-s);
}
.pull-request__actions-dropdown .btn {
  height: var(--size-m);
  width: var(--size-m);
}
.pull-request__actions-dropdown:hover {
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-default);
}
.pull-request-description {
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
}
@media (min-width: 768px) {
  .pull-request-description {
    width: 75%;
    float: left;
  }
}
.pull-request-description-empty {
  color: var(--content-color-secondary);
}
.pull-request-meta {
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}
.pull-request-meta__section {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-s);
}
.pull-request-meta__section:not(:last-child) {
  margin-bottom: var(--spacing-xl);
}
.pull-request-meta__section-header {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  display: flex;
  align-items: center;
  line-height: var(--line-height-s);
}
.pull-request-meta__section p {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
}
.pull-request__pull-changes {
  margin: var(--spacing-l) var(--spacing-zero);
}
.pull-request__pull-changes-link {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-link);
  cursor: pointer;
}
.pull-request__pull-changes-separator {
  display: inline-block;
  border-left: 1px solid var(--border-color-default);
  vertical-align: middle;
  height: var(--spacing-xxl);
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
}
.pull-request__pull-changes-alert .pm-alert-cta {
  max-width: 160px;
  margin-right: var(--spacing-l);
}
.pull-request__fork-label {
  margin-left: var(--spacing-xs);
  color: var(--content-color-secondary);
}
.pull-request-tabs {
  width: 100%;
  margin-right: var(--spacing-xl);
}
.pull-request-tabs:first-child {
  margin-right: var(--spacing-s);
}
.pull-request-tab {
  padding-left: 0;
  padding-right: 0;
  margin-right: var(--spacing-l);
}
.pull-request-tab .tab-text-wrapper {
  margin-left: 0;
  margin-right: var(--spacing-xs);
  padding: var(--spacing-m) 0;
}
.pull-request-tab__changes-count {
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
  height: var(--spacing-l);
  min-width: var(--spacing-l);
  padding: 0px;
  font-weight: 600;
  pointer-events: none;
}
.pull-request-tab__changes-text {
  padding-right: var(--spacing-s);
}
.pull-request__fetch-error {
  margin-top: 200px;
}
.pull-request-reviewers-list {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
  width: 100%;
  padding-right: var(--spacing-s);
}
.pull-request-reviewers-list li + li {
  margin-top: var(--spacing-s);
}
.pull-request-settings-list {
  list-style-type: none;
  padding: 0;
  margin-top: var(--spacing-l);
  margin-left: var(--spacing-s);
}
.pull-request-merge-check {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-m);
}
.pull-request-merge-check__loading {
  position: relative;
  top: 50%;
  left: 50%;
}
.pull-request-merge-check__section {
  height: 100%;
}
.pull-request-merge-check__description {
  margin-left: var(--spacing-m);
  color: var(--content-color-primary);
}
.pull-request-merge-check__empty {
  color: var(--content-color-secondary);
}
.pull-request-reviewer {
  margin-bottom: var(--spacing-s);
  min-height: 33px;
}
.pull-request-reviewer-empty {
  color: var(--content-color-secondary);
  margin-top: 0;
}
.pull-request-reviewer-label {
  white-space: nowrap;
  color: var(--base-color-brand);
}
.pull-request-reviewer-label-inline {
  white-space: nowrap;
  color: var(--base-color-brand);
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 18px);
}
.pull-request-reviewer-label-inline span {
  position: static;
}
.pull-request-reviewer-label span {
  position: static;
}
.pull-request-reviewer-approved {
  display: inline-block;
  margin-left: var(--spacing-s);
  vertical-align: middle;
}
.pull-request-reviewer-approved svg {
  color: var(--base-color-success);
}
.pull-request-reviewer-approved svg path {
  fill: var(--base-color-success);
}
.pull-request-label {
  margin-bottom: var(--spacing-l);
}
.pull-request-user {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}
.pull-request-user__label {
  display: flex;
  margin-bottom: var(--spacing-l);
  margin-top: var(--spacing-m);
}
.pull-request-user__name {
  font-size: var(--text-size-m);
  margin-left: var(--spacing-s);
  display: inline-block;
}
.pull-request-group__icon {
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-left: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  padding-right: var(--spacing-xs);
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}
.pull-request__status {
  border-radius: 12px;
}
.pull-request__status-text {
  text-transform: capitalize;
  color: var(--content-color-primary);
}
.pull-request__status-alert {
  margin: 0 !important;
  padding: 2px var(--spacing-xxl);
  height: 40px;
}
.pull-request__status-description {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.pull-request__diff-body {
  padding: 0;
  height: 100%;
}
.pull-request__diff-items {
  display: flex;
  align-items: center;
  margin: var(--spacing-xs) 0 var(--spacing-s) 0;
}
.pull-request__retry {
  width: 300px;
}
.pull-request__diff-comments-container {
  float: right;
}
.pull-request__diff-comments-container-disabled {
  float: right;
  pointer-events: none;
  opacity: 0.4;
}
.pull-request__diff-comments {
  margin-top: var(--spacing-xs);
  float: none;
}
.pull-request__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.pull-request__diff-changes {
  font-size: var(--text-size-m);
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
}
.pull-request__alert-text {
  margin-left: var(--spacing-xs);
  color: var(--content-color-primary);
}
.pull-request__diff-conflicts {
  margin-left: var(--spacing-s);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.pull-request__approval-count {
  border-radius: var(--border-radius-default);
  margin-left: var(--spacing-s);
  padding: 2px 4px;
  background: var(--background-modal-backdrop);
  color: var(--content-color-constant);
}
.pull-request-offline {
  position: relative;
  top: 25%;
}
.pull-request-offline-image {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
  margin: auto;
}
.pull-request-entity-comments {
  float: right;
  position: relative;
  top: -4px;
}
.pull-request-entity-comments__label {
  margin-right: var(--spacing-l);
  cursor: pointer;
}
.pull-request-entity-comments__icon {
  vertical-align: middle;
}
.pull-request-entity-comments__text {
  color: var(--content-color-primary);
}
.pull-request-entity-comments__loading {
  display: inline-block;
  margin-left: var(--spacing-l);
  vertical-align: middle;
}
.pull-request-entity-comments__count {
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
  height: var(--spacing-l);
  min-width: var(--spacing-l);
  padding: 0;
  font-weight: var(--text-weight-medium);
  pointer-events: none;
  margin-left: var(--spacing-s);
}
.pull-request-entity-comments__list {
  position: absolute !important;
  right: 0;
  margin-top: var(--spacing-xl);
  background: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  z-index: 800;
  box-shadow: var(--shadow-default);
  min-width: 350px;
  margin-right: var(--spacing-m);
}
.pull-request-entity-comments__list:before {
  content: " ";
  position: absolute;
  box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.1);
  border-color: var(--background-color-primary) transparent transparent var(--background-color-primary);
  border-style: solid;
  border-width: 6px;
  top: -6px;
  right: 10px;
  transform: rotate(45deg);
}
.pull-request__more-options-icon {
  margin-right: var(--spacing-s);
}
.pull-request__merge-checks-header {
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.pull-request__error-state {
  margin: auto;
  width: 480px;
}

.merge-check {
  display: flex;
  align-items: center;
}

.pull-request-comments {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.pull-request-comments__empty-state-description i {
  position: relative;
  top: 3px;
  margin: 0 var(--spacing-xs);
}
.pull-request-comments .comments {
  height: 97%;
  display: flex;
  flex-direction: column;
}
.pull-request-comments .comments-list {
  max-height: initial;
}
.pull-request-comments .comments-list .comments-item {
  padding-top: var(--spacing-xl);
  padding-left: 0;
}
.pull-request-comments .comments-input {
  padding-left: 0;
  font-size: var(--text-size-m);
}

.pull-request-decline {
  position: relative;
}
.pull-request-decline__close {
  position: absolute;
  top: var(--spacing-xxl);
  right: var(--spacing-xxl);
}
.pull-request-decline__confirm {
  margin-left: var(--spacing-xs);
}
.pull-request-decline__text {
  font-size: var(--text-size-l);
}

.pull-request-edit {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pull-request-edit .pm-h1 {
  padding-top: var(--spacing-s);
}
.pull-request-edit__header-text {
  margin-top: var(--spacing-l);
  display: inline-block;
  margin-left: var(--spacing-l);
  font-size: 18px;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.pull-request-edit__title {
  color: var(--content-color-primary);
}
.pull-request-edit__header {
  margin-top: var(--spacing-m);
  border-bottom: 1px solid var(--border-color-default);
  padding: 0 var(--spacing-l);
  padding-bottom: var(--spacing-m);
}
.pull-request-edit__body {
  margin-left: var(--spacing-l);
  margin-right: var(--spacing-l);
}
.pull-request-edit__description {
  margin-top: var(--spacing-xxl);
  color: var(--content-color-primary);
}
.pull-request-edit__reviewers {
  margin-top: var(--spacing-xxl);
  color: var(--content-color-primary);
}
.pull-request-edit__changes_container {
  display: flex;
  align-items: flex-start;
}
.pull-request-edit__changes_container > div {
  margin-right: var(--spacing-xl);
}
.pull-request-edit__changes {
  width: 65%;
  margin-bottom: var(--spacing-l);
}
.pull-request-edit__changes--full-width {
  width: 100%;
}
.pull-request-edit__nav {
  margin-top: var(--spacing-l);
  flex: auto;
}
.pull-request-edit__diff-nav-wrapper {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-left: var(--spacing-l);
  color: var(--content-color-secondary);
}
.pull-request-edit__form {
  max-width: 400px;
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xl);
}
.pull-request-edit__form label {
  display: block;
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-s);
}
.pull-request-edit__form label + p {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-secondary);
}
.pull-request-edit__form .pm-invite-form,
.pull-request-edit__form .pm-input-field {
  max-width: 400px;
}
.pull-request-edit__form textarea {
  min-width: 200px;
  max-width: 1000px;
  font-family: var(--text-family-default);
}
.pull-request-edit__source-destination-label {
  width: 100%;
}
.pull-request-edit__max-reviewers {
  color: var(--base-color-error);
  margin-top: var(--spacing-s);
  display: block;
}
.pull-request-edit__actions {
  margin-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}
.pull-request-edit__actions-save {
  margin-right: var(--spacing-s);
}

.workspace-selector-menu .dropdown-menu-item.dropdown-menu-item--default {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}

.pm-invite-form {
  position: relative;
}
.pm-invite-form__field .pm-icon.pm-icon-md {
  margin: 8px;
}
.pm-invite-form__field .pm-icon.pm-icon-md svg {
  width: 16px;
}
.pm-invite-form__field .pm-icon.pm-icon-md path {
  fill: var(--grey-40);
}
.pm-invite-form__field .pm-form-control-md {
  border-color: var(--border-color-default);
  font-size: var(--text-size-m);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 48px 0 var(--spacing-xxl);
}
.pm-invite-form__add {
  position: absolute;
  top: 12px;
  font-size: var(--text-size-m);
  right: var(--spacing-l);
}
.pm-invite-form__actions {
  position: relative;
  margin: var(--spacing-xxl) 0 var(--spacing-l);
  padding-left: 125pxvar(--spacing-s);
}
.pm-invite-form__actions .pm-btn-secondary {
  left: 0;
  width: 125px;
  position: absolute;
}

.pm-invite-list {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 200px;
  overflow: auto;
  border: 1px solid var(--border-color-default);
  border-top: none;
  font-size: var(--text-size-m);
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
}
.pm-invite-list__item {
  height: 40px;
  width: 100%;
}
.pm-invite-list__item .avatar {
  margin-left: 8px;
}
.pm-invite-list__empty {
  position: relative;
}
.pm-invite-list__empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--content-color-secondary);
}
.pm-invite-list .pm-select {
  max-width: 120px;
}
.pm-invite-list .pm-select .Select-control {
  width: 120px;
}
.pm-invite-list__custom-tooltip {
  margin-top: -4px;
}
.pm-invite-list__roles {
  position: absolute;
  right: 40px;
  top: var(--spacing-xs);
}
.pm-invite-list__roles + p {
  padding: 10px 168px var(--spacing-s) 48px;
}
.pm-invite-list .pm-select.is-disabled .Select-control {
  cursor: not-allowed;
}
.pm-invite-list__icon {
  background-size: contain;
  position: absolute;
  top: var(--spacing-xs);
  left: var(--spacing-s);
}
.pm-invite-list__email-container {
  width: 60%;
  overflow: hidden;
}
.pm-invite-list__email {
  display: inline-block;
  padding: 10px var(--spacing-xxl) var(--spacing-s) 8px;
  margin: 0;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  color: var(--content-color-primary);
}
.pm-invite-list__remove {
  right: 0;
  padding: 0;
  top: 4px;
}
.pm-invite-list__additive-info {
  margin-right: var(--spacing-s)var(--spacing-xs);
  position: absolute;
  right: 0;
  padding: 0;
  height: 16px;
  width: 16px;
}
.pm-invite-list__additive-warn {
  position: absolute;
  right: 160px;
  top: 50%;
  margin-right: var(--spacing-s);
  transform: translateY(-50%);
}
.pm-invite-list__user-icon {
  top: var(--spacing-s);
  left: var(--spacing-s);
  height: 24px;
  width: 24px;
}
.pm-invite-list__button {
  display: block;
  margin-left: auto;
  background-color: var(--background-color-secondary);
  text-align: center;
  min-width: 110px;
}
.pm-invite-list__button:hover {
  background-color: var(--background-color-secondary);
}
.pm-invite-list__button-down-arrow {
  padding-left: var(--spacing-xs);
  vertical-align: top;
}
.pm-invite-list__label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
}
.pm-invite-list__disabled-option-tooltip {
  font-size: var(--text-size-m);
  margin: 0;
}
@media (max-width: 568px) {
  .pm-invite-list__disabled-option-tooltip {
    max-width: 90px;
  }
}
.pm-invite-list__disabled-option-tooltip-heading {
  font-weight: var(--text-weight-medium);
}
.pm-invite-list__disabled-option-tooltip-description {
  color: var(--content-color-secondary);
  margin: var(--spacing-xs) 0;
}

.collection-info-cb__pull-requests-label {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  cursor: pointer;
}
.collection-info-cb__pull-requests-icon {
  margin-right: var(--spacing-s);
}
.collection-info-cb__pull-requests__toggle-wrapper {
  float: left;
  cursor: pointer;
}
.collection-info-cb__pull-requests-loading {
  display: flex;
  justify-content: center;
  position: relative;
  top: 40%;
}
.collection-info-cb__pull-requests-title {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.collection-info-cb__pull-requests-title:hover {
  text-decoration: underline;
}
.collection-info-cb__pull-requests-time {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-s);
  margin-top: 0;
}
.collection-info-cb__pull-requests-elements {
  max-width: 50%;
  display: inline-block;
  cursor: pointer;
}
.collection-info-cb__pull-requests-status {
  margin-left: var(--spacing-xxl);
}
.collection-info-cb__pull-requests-status__wrapper {
  margin-top: var(--spacing-xs);
  display: inline-block;
  width: 75px;
}
.collection-info-cb__pull-requests-meta {
  display: flex;
  float: right;
  right: 60px;
  position: relative;
  margin-top: var(--spacing-s);
}
.collection-info-cb__pull-requests-collapsible {
  margin-top: var(--spacing-l);
  cursor: pointer;
  border-radius: var(--border-radius-s);
  max-width: 350px;
}
.collection-info-cb__pull-requests-toggle {
  display: inline-block;
  margin-left: 0;
  margin-top: var(--spacing-xs);
}
.collection-info-cb__pull-requests-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  padding: var(--spacing-zero) var(--spacing-s);
}
.collection-info-cb__pull-requests-description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  width: 80%;
}

.pull-request-container {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  height: 100%;
  overflow-x: hidden;
}
.pull-request-container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-s);
}
.pull-request-container__header-title {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
}
.pull-request-container__actions {
  display: flex;
  align-items: center;
}
.pull-request-container__actions .dropdown-button.is-open .btn-icon.btn-icon-rect.pull-request-container__actions-filter-button {
  background-color: var(--highlight-background-color-tertiary);
}
.pull-request-container__actions .btn-icon.btn-icon-rect.pull-request-container__actions-filter-button {
  height: var(--size-s);
  width: var(--size-s);
  background-color: var(--background-color-tertiary);
}
.pull-request-container__actions .btn-icon.btn-icon-rect.pull-request-container__actions-filter-button:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.pull-request-container__actions-filter-menu {
  height: 182px;
  width: 188px;
  background-color: var(--background-color-primary);
}
.pull-request-container__actions-filter--action-header {
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.pull-request-container__actions-filter--action-item {
  margin-top: var(--spacing-m);
  margin-left: var(--spacing-l);
}
.pull-request-container__actions-filter--action-item-name {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  margin-left: var(--spacing-s);
}
.pull-request-container__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 25%;
  margin-right: var(--spacing-xxl);
}

.pull-request-filter-dropdown-item-checkbox {
  display: inline-flex;
}

.pull-request-filter-dropdown-item-checkbox.is-selected {
  background-color: var(--base-color-brand);
}

@container (inline-size < 300px) {
  .pullRequest-list-item__info.pullRequest-list-item__info {
    align-items: flex-start;
    flex-direction: column;
  }
  .pullRequest-list-item__info.pullRequest-list-item__info .seperator.seperator {
    display: none;
  }
}
.pull-request-reviewers__option .pm-user-icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: var(--spacing-s);
}
.pull-request-reviewers__option .pm-user-icon svg {
  height: 12px;
  width: 12px;
}
.pull-request-reviewers__option-group-tag {
  width: var(--size-xs);
  height: var(--size-xs);
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-s);
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-left: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  padding-right: var(--spacing-xs);
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}
.pull-request-reviewers__option-reviewer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  max-width: 200px;
  vertical-align: top;
  margin-left: 8px;
  font-size: var(--text-size-m);
}
.pull-request-reviewers__option-permission {
  color: var(--content-color-tertiary);
  float: right;
  position: relative;
  font-size: var(--text-size-m);
}
.pull-request-reviewers__option-disabled {
  opacity: 0.5;
}
.pull-request-reviewers__option .avatar {
  margin-left: 8px;
}
.pull-request-reviewers__input {
  position: relative;
}
.pull-request-reviewers__input .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.pull-request-reviewers__input .Select--single .Select-menu-outer {
  margin-top: 0;
  border-radius: 0;
}
.pull-request-reviewers__input .Select--single .Select-menu-outer .Select-menu .Select-option {
  padding-right: var(--spacing-l);
}
.pull-request-reviewers__input .Select--single .Select-placeholder {
  padding-left: var(--spacing-xxl);
}
.pull-request-reviewers__input .Select--single .Select-input {
  padding: 0 var(--spacing-xxl);
  width: 100%;
}
.pull-request-reviewers__input .Select--single .Select-input > input {
  max-width: 100% !important;
}
.pull-request-reviewers__input > .pm-icon {
  position: absolute;
  top: 14px;
  left: 12px;
}
.pull-request-reviewers__input > .pm-icon svg {
  height: 12px;
  width: 12px;
}
.pull-request-reviewers__selected-warning {
  margin-right: var(--spacing-xs);
  transform: translateY(20%);
}
.pull-request-reviewers__selected-warning svg {
  color: var(--base-color-warning);
}
.pull-request-reviewers__selected-group-tag {
  margin-left: var(--spacing-s);
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-left: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  padding-right: var(--spacing-xs);
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}
.pull-request-reviewers__selected-permission {
  color: var(--content-color-tertiary);
}

.pull-request-settings__is-loading {
  display: flex;
  height: var(--spacing-xxxl);
  align-items: center;
  justify-content: center;
}
.pull-request-settings__error {
  display: flex;
  height: var(--spacing-xxl);
  margin-top: var(--spacing-m);
}
.pull-request-settings__error-text {
  height: 20px;
  margin-left: var(--spacing-s);
}
.pull-request-settings .merge-user-heading {
  height: 20px;
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-s);
  font-weight: var(--text-weight-medium);
}
.pull-request-settings .list-selection-radio {
  height: var(--spacing-xxl);
  display: flex;
  align-items: center;
}
.pull-request-settings .list-selection-radio .option-text {
  display: inline-block;
  margin-left: var(--spacing-s);
}
.pull-request-settings .select-users {
  margin-left: var(--spacing-xl);
}
.pull-request-settings .select-users__hint {
  margin-bottom: var(--spacing-s);
  color: var(--content-color-secondary);
}
.pull-request-settings .merge-checks {
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-s);
}
.pull-request-settings .merge-checks__description {
  margin-bottom: var(--spacing-s);
  color: var(--content-color-secondary);
}
.pull-request-settings .merge-checks__container {
  margin-bottom: var(--spacing-s);
}
.pull-request-settings .merge-checks__container .checks-checkbox.input-checkbox {
  display: inline-flex;
  margin-right: var(--spacing-s);
}
.pull-request-settings .merge-checks__container .checks-checkbox.input-checkbox.is-selected {
  background-color: var(--base-color-brand);
}
.pull-request-settings .merge-checks__container .checks-text {
  vertical-align: text-bottom;
  color: var(--content-color-primary);
}

.mergers-selection {
  overflow-y: auto;
  min-height: 40px;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-sizing: border-box;
  margin-right: 10px;
}

.user-invite-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  max-height: 80px;
}
.user-invite-container__placeholder {
  display: flex;
  position: relative;
  margin-left: var(--spacing-s);
}
.user-invite-container__placeholder-text {
  margin-left: var(--spacing-s);
  color: var(--content-color-secondary);
}
.user-invite-container .active-input {
  flex: 1;
  padding-left: var(--spacing-s);
}

.merge-user-suggestion {
  height: 56px;
}
.merge-user-suggestion .avatar {
  margin-right: var(--spacing-s);
}
.merge-user-suggestion .user-suggestion-details {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.merge-user-suggestion .user-suggestion-details .user-suggestion__email {
  margin-top: var(--spacing-xs);
  color: var(--content-color-secondary);
}

.fork-offline {
  position: relative;
  top: 25%;
}
.fork-offline-image {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
  margin: auto;
}

.entity-empty .entity-empty__illustration-wrapper--collections .collections-empty__illustration {
  display: block;
  width: 115px;
  height: 95px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/collection_cloud.svg");
}
.entity-empty .entity-empty__action--text {
  margin-top: 10px;
}

.entity-empty .entity-empty__illustration-wrapper--environment .environment-empty__illustration {
  display: block;
  width: 115px;
  height: 95px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/environment.svg");
}

.entity-empty .entity-empty__illustration-wrapper--integrations .integrations-empty__illustration {
  display: block;
  width: 115px;
  height: 95px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/integrations.svg");
}

.entity-empty .entity-empty__illustration-wrapper--mocks .mocks-empty__illustration {
  display: block;
  width: 115px;
  height: 95px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/mock_cloud.svg");
}

.entity-empty .entity-empty__illustration-wrapper--monitors .monitors-empty__illustration {
  display: block;
  width: 115px;
  height: 95px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor_cloud.svg");
}

.entity-empty .entity-empty__illustration-wrapper--history .history-empty__illustration {
  display: block;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/activity-feed.svg");
}

.add-to-workspace-empty--container {
  border: 1px solid var(--border-color-default);
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 40px 75px;
}
.add-to-workspace-empty--container .add-to-workspace-empty--title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
}
.add-to-workspace-empty--container .add-to-workspace-empty--subtext {
  opacity: 0.6;
  margin-bottom: 20px;
}
.add-to-workspace-empty--container .docs-link {
  margin: 15px 0 20px;
}

.app-crash-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  background-color: var(--background-color-primary);
  height: 100%;
}
.app-crash-wrapper .app-crash-wrapper__blank-state {
  padding: var(--spacing-l);
}
.app-crash-wrapper .app-crash-thumbnail {
  height: 200px;
  width: 200px;
  background: url("../assets/images/thumbnails/browse-view-offline.svg") no-repeat center;
  background-size: contain;
  margin-bottom: 10px;
}
.app-crash-wrapper .app-crash-content-container {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-crash-wrapper .app-crash-header {
  font-size: 18px;
  font-weight: 600;
  color: var(--content-color-primary);
}
.app-crash-wrapper .app-crash-content {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
  color: var(--content-color-secondary);
}
.app-crash-wrapper .app-crash__learn-more-link {
  color: var(--content-color-link, #0265D2);
  cursor: pointer;
}
.app-crash-wrapper .app-crash__learn-more-link:hover {
  text-decoration: underline;
}

.no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  color: var(--content-color-primary);
}
.no-result .no-result-image {
  display: block;
  width: 120px;
  height: 105px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/emptysearch.svg");
  margin-bottom: 20px;
}
.no-result .label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.no-result .subtext {
  font-size: 12px;
  opacity: 0.6;
}

.create-team-empty {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-family: var(--text-family-default);
  padding: 16px;
  background-color: var(--background-color-brand);
  border-radius: var(--border-radius-default);
}
.create-team-empty .create-team-empty__title {
  flex: 1;
  color: var(--content-color-primary);
  font-size: 12px;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.contextual-sign-in-modal-container {
  position: relative;
  margin-top: 10vh;
  height: 75vh;
  min-width: 760px;
  max-height: 570px;
  width: 80vw;
}
.contextual-sign-in-modal-container-content {
  height: 100%;
}
.contextual-sign-in-modal-container .modal-content {
  padding: 0;
}
.contextual-sign-in-modal-container-loading {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contextual-sign-in-modal-container-loading .loader {
  border: 3px solid transparent;
  border-top: 3px solid var(--content-color-constant);
  border-right: 3px solid var(--content-color-constant);
  border-left: 3px solid var(--content-color-constant);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.contextual-sign-in-modal-container-loading .loading-text {
  text-align: center;
  margin-top: 16px;
  color: var(--content-color-primary);
}

.contextual-sign-in-modal {
  display: flex;
  height: 100%;
  min-height: 420px;
  position: relative;
  background-color: var(--background-color-primary);
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--back-icon,
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--close-icon {
  position: absolute;
  top: 24px;
  cursor: pointer;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--back-icon.is-enabled,
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--close-icon.is-enabled {
  z-index: 1;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--back-icon {
  left: 24px;
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--close-icon {
  right: 24px;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section,
.contextual-sign-in-modal .contextual-sign-in-modal__right-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section {
  flex: 1;
  min-width: 210px;
  padding: 32px;
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section {
  flex: 2;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--icon {
  margin-bottom: 24px;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--invite-icon {
  display: block;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/share_collection.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--mock-icon {
  display: block;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/mock_cloud.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--monitor-icon {
  display: block;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitor-logo.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--documentation-icon {
  display: block;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/documentation-logo.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--title,
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--title {
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--subtitle {
  margin-top: 8px;
  text-align: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--title,
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--subtitle {
  color: var(--content-color-primary);
}
.contextual-sign-in-modal .contextual-sign-in-modal__left-section--create-btn {
  margin-top: 24px;
}
.contextual-sign-in-modal .contextual-sign-in-modal__separator-block {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contextual-sign-in-modal .contextual-sign-in-modal__separator-block .contextual-sign-in-modal__separator-block--separator {
  width: 54px;
  height: 1px;
  background-color: var(--border-color-strong);
}
.contextual-sign-in-modal .contextual-sign-in-modal__separator-block .contextual-sign-in-modal__separator-block--separator-text {
  margin: 0px 16px;
  color: var(--content-color-secondary);
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section {
  background-color: var(--background-color-secondary);
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--title {
  margin-bottom: 16px;
  color: var(--content-color-primary);
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--carousel {
  width: 500px;
}
.contextual-sign-in-modal .contextual-sign-in-modal--invite-1 {
  display: block;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/team.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal--invite-2 {
  display: block;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/share_collection.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal--invite-3 {
  display: block;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/collaborate.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal--monitor-1 {
  display: block;
  width: 250px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitoring-empty-state-1.png");
}
.contextual-sign-in-modal .contextual-sign-in-modal--monitor-2 {
  display: block;
  width: 250px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitoring-empty-state-2.png");
}
.contextual-sign-in-modal .contextual-sign-in-modal--monitor-3 {
  display: block;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/monitoring-empty-state-3.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal--mock-1 {
  display: block;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/mock-empty-state-1.png");
}
.contextual-sign-in-modal .contextual-sign-in-modal--mock-2 {
  display: block;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/mock_cloud.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal--mock-3 {
  display: block;
  width: 170px;
  height: 170px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/collaborate.svg");
}
.contextual-sign-in-modal .contextual-sign-in-modal--documentation-1 {
  display: block;
  width: 250px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/documentation-empty-state-1.png");
  box-shadow: var(--shadow-default);
  background-size: cover;
}
.contextual-sign-in-modal .contextual-sign-in-modal--documentation-2 {
  display: block;
  width: 250px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/documentation-empty-state-2.png");
  box-shadow: var(--shadow-default);
  background-size: cover;
}
.contextual-sign-in-modal .contextual-sign-in-modal--documentation-3 {
  display: block;
  width: 250px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/documentation-empty-state-3.png");
  box-shadow: var(--shadow-default);
  background-size: cover;
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--carousel .carousel .carousel-inner {
  height: 250px;
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--carousel.is-small .carousel-inner {
  height: 220px;
}
.contextual-sign-in-modal .contextual-sign-in-modal__right-section--learn-more {
  margin-top: 24px;
}

.permission-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--content-color-primary);
  padding: 10px 10px 20px;
  box-sizing: border-box;
}
.permission-denied .permission-denied--img {
  display: block;
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/no-permission.svg");
  margin-bottom: 16px;
}
.permission-denied .permission-denied--title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
}
.permission-denied .permission-denied--title,
.permission-denied .permission-denied--subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.sign-in-modal-container {
  margin-top: 0px;
  height: 40vh !important;
  width: 40vw !important;
  min-height: 400px;
  min-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sign-in-modal-container .modal-content {
  padding: 0;
}

.sign-in-modal {
  height: calc(100% - 32px);
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sign-in-modal .sign-in-modal__close-icon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.sign-in-modal .sign-in-modal__illustration {
  display: block;
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/share_collection.svg");
}
.sign-in-modal .learning-center-empty-state-icon {
  display: block;
  width: 75px;
  height: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/postman-wave.png");
}
.sign-in-modal .sign-in-modal__title {
  margin-top: var(--spacing-l);
}
.sign-in-modal .sign-in-modal__subtitle {
  margin-top: 8px;
  text-align: center;
  max-width: 340px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.sign-in-modal .sign-in-modal__buttons {
  display: flex;
  flex-direction: column;
}
.sign-in-modal .sign-in-modal__create-btn {
  margin: var(--spacing-l) var(--spacing-zero);
}
.sign-in-modal .sign-in-modal__separator-block {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-modal .sign-in-modal__separator-block .sign-in-modal__separator-block--separator {
  width: 54px;
  height: 1px;
  background-color: var(--border-color-default);
}
.sign-in-modal .sign-in-modal__separator-block .sign-in-modal__separator-block--separator-text {
  margin: 0px 16px;
  color: var(--content-color-secondary);
}

.collection-sidebar-empty-shell {
  height: 100%;
  border-right: 1px solid var(--border-color-default);
  border-left: 1px solid var(--border-color-default);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
}
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection {
  height: 60px;
  border-bottom: 1px solid var(--border-color-default);
  display: flex;
  align-items: center;
}
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection__dropdown,
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection__icon {
  height: 12px;
  width: 12px;
  background-color: var(--background-color-tertiary);
  margin-left: 8px;
  border-radius: var(--border-radius-default);
}
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection__dropdown {
  height: 8px;
  width: 8px;
}
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection__info--name,
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection__info--count {
  background-color: var(--background-color-tertiary);
  width: 100px;
  height: 8px;
  margin-left: 16px;
  border-radius: var(--border-radius-default);
}
.collection-sidebar-empty-shell .collection-sidebar-empty-shell--collection__info--count {
  margin-top: 8px;
  width: 55px;
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.share-empty-shell {
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: fade 1s ease-in-out infinite;
  overflow: hidden;
}
.share-empty-shell .share-empty-shell--search {
  height: 42px;
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
  margin: 16px 0 8px 0;
}
.share-empty-shell .share-empty-shell--filter {
  height: 56px;
  border-radius: var(--border-radius-default);
  margin-top: 8px;
}
.share-empty-shell .share-empty-shell--filter .share-empty-shell--filter-title {
  width: 70px;
  height: 12px;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.share-empty-shell .share-empty-shell--filter .share-empty-shell--filter-type {
  width: 430px;
  height: 12px;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
  margin-top: 8px;
}
.share-empty-shell .share-empty-shell--workspaces-title {
  height: 12px;
  width: 130px;
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
  margin-bottom: 8px;
}
.share-empty-shell .share-empty-shell--workspaces-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--background-color-tertiary);
}
.share-empty-shell .share-empty-shell--workspaces-item .share-empty-shell--workspaces-item--avatar {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: var(--background-color-tertiary);
}
.share-empty-shell .share-empty-shell--workspaces-item .share-empty-shell--workspaces-item--details {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.share-empty-shell .share-empty-shell--workspaces-item .share-empty-shell--workspaces-item--details .share-empty-shell--workspaces-item--details-title {
  height: 12px;
  width: 100px;
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
}
.share-empty-shell .share-empty-shell--workspaces-item .share-empty-shell--workspaces-item--details .share-empty-shell--workspaces-item--details-members {
  height: 12px;
  width: 250px;
  border-radius: var(--border-radius-default);
  margin-top: 5px;
  background-color: var(--background-color-tertiary);
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.tab-content-shell-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.tab-content-shell-wrapper .tab-content-shell {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  background-color: var(--background-color-primary);
  height: 100%;
  min-height: 350px;
}
.tab-content-shell-wrapper .tab-content-shell-thumbnail {
  margin-bottom: 10px;
  fill: var(--background-color-tertiary);
}
.tab-content-shell-wrapper .tab-content-shell-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tab-content-shell-wrapper .tab-content-shell-header {
  font-size: 18px;
  font-weight: 600;
  color: var(--content-color-primary);
}
.tab-content-shell-wrapper .tab-content-shell-content {
  font-size: 14px;
  text-align: center;
  margin: 30px 0;
  color: var(--content-color-secondary);
}
.tab-content-shell-wrapper .tab-content-shell-content .launchpad-open-icon {
  margin-right: 8px;
}

.empty-tab-state {
  text-align: center;
  padding-top: 96px;
}
.empty-tab-state__icon-container {
  height: 56px;
  width: 56px;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}
.empty-tab-state__icon-container .empty-tab-state__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.empty-tab-state__icon-container .empty-tab-state__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}
.empty-tab-state__title {
  margin: var(--spacing-l) auto var(--spacing-xs) auto;
}
.empty-tab-state__description {
  margin: 0 auto var(--spacing-xl) auto;
  max-width: 448px;
}

.entity-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 10px 20px;
  border: 1px solid var(--border-color-default);
  box-sizing: border-box;
}
.entity-empty .entity-empty__illustration-wrapper {
  margin-bottom: 35px;
}
.entity-empty .entity-empty__title {
  font-size: 16px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: var(--content-color-primary);
  margin-bottom: 20px;
}
.entity-empty .entity-empty__subtitle {
  font-size: 12px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-secondary);
  text-align: center;
  margin-bottom: 40px;
  max-width: 500px;
  line-height: 20px;
}
.entity-empty .entity-empty__subtitle .btn-text {
  font-size: 12px;
}
.entity-empty .empty-empty__action .btn-primary {
  height: 40px;
  font-size: 14px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 10px 20px;
}

.create-new-modal .modal-content {
  padding: 0;
  overflow-y: hidden;
}
.create-new-modal .modal-content .create-new-modal__content {
  height: 100%;
}
.create-new-modal .modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 35px;
  background-color: var(--background-color-secondary);
  border-top: 1px solid var(--border-color-default);
  padding: 0 20px;
  font-size: 12px;
}
.create-new-modal .modal-footer .btn-text {
  padding: 0;
}
.create-new-modal .modal-footer .btn-text {
  font-weight: 600;
}

.create-new-modal__content {
  overflow-y: auto;
}
.create-new-modal__content .create-new-modal__intro {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.create-new-modal__content .create-new-modal__intro__heading {
  font-size: 12px;
  font-weight: 600;
  color: var(--content-color-secondary);
  margin-bottom: 5px;
}
.create-new-modal__content .create-new-modal__intro__help {
  font-size: 12px;
  color: var(--content-color-secondary);
}
.create-new-modal__content .create-new-modal__intro__cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  position: relative;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card {
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  width: 33%;
  height: 80px;
  padding: 10px 5px 10px 0;
  cursor: pointer;
  box-sizing: border-box;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card:hover, .create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card.is-disabled {
  cursor: default;
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card .create-new-modal__intro__card__icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 0 0 64px;
  height: 64px;
  margin-right: 10px;
  padding-top: 10px;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card .create-new-modal__intro__card__icon .create-new-modal__intro__card__icon__icon use {
  fill: var(--content-color-primary);
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card .create-new-modal__intro__card__icon .create-new-modal__intro__card__icon__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card .create-new-modal__intro__card__icon .create-new-modal__intro__card__icon__icon svg {
  color: var(--content-color-primary);
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card .create-new-modal__intro__card__content {
  display: flex;
  flex-direction: column;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card .create-new-modal__intro__card__content .create-new-modal__intro__card__title {
  font-size: var(--text-size-l);
  font-weight: 600;
  color: var(--content-color-link);
  margin-bottom: 5px;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card .create-new-modal__intro__card__content .create-new-modal__intro__card__description {
  color: var(--content-color-secondary);
  font-size: 12px;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card--websocket-request .create-new-modal__intro__card__title {
  display: flex;
}
.create-new-modal__content .create-new-modal__intro__cards .create-new-modal__intro__card--grpc-request .create-new-modal__intro__card__title {
  display: flex;
}
.create-new-modal__content .create-new-modal__intro__cards.create-new-modal__intro__cards--basic {
  margin-bottom: 20px;
}

.create-new-monitor-modal .modal-header,
.create-new-documentation-modal .modal-header {
  flex: 0 0 45px;
  background-color: var(--background-color-primary);
  border-bottom: 1px solid var(--border-color-default);
}
.create-new-monitor-modal .modal-header .modal-header-title,
.create-new-documentation-modal .modal-header .modal-header-title {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 0;
  padding-left: 0;
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs,
.create-new-documentation-modal .modal-header .modal-header-title .tabs {
  flex: 1;
  height: 100%;
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab {
  display: flex;
  padding: var(--spacing-zero);
  font-size: 12px;
  line-height: 24px;
  height: 100%;
  cursor: default;
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-active:hover, .create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-active.is-hovered,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-active:hover,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-active.is-hovered {
  color: var(--content-color-primary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done {
  color: var(--content-color-secondary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done use,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done use {
  fill: var(--content-color-secondary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done svg:not([class*=PartnerIcon]) > path[fill],
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done svg,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done svg {
  color: var(--content-color-secondary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done:hover, .create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done:hover,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered {
  color: var(--content-color-primary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done:hover use, .create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered use,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done:hover use,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered use {
  fill: var(--content-color-primary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done:hover svg:not([class*=PartnerIcon]) > path[fill], .create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered svg:not([class*=PartnerIcon]) > path[fill],
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done:hover svg:not([class*=PartnerIcon]) > path[fill],
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done:hover svg, .create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered svg,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done:hover svg,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered svg {
  color: var(--content-color-primary);
}
.create-new-monitor-modal .modal-header .modal-header-title .tabs .tab.is-done span,
.create-new-documentation-modal .modal-header .modal-header-title .tabs .tab.is-done span {
  padding-left: 12px;
}
.create-new-monitor-modal .modal-header .modal-header-title .btn-text,
.create-new-documentation-modal .modal-header .modal-header-title .btn-text {
  font-size: 14px;
  margin-bottom: 5px;
}
.create-new-monitor-modal .modal-content,
.create-new-documentation-modal .modal-content {
  display: flex;
  padding: 0;
  overflow: hidden;
}
.create-new-monitor-modal .modal-content .create-new-monitor__error,
.create-new-documentation-modal .modal-content .create-new-monitor__error {
  flex: 1;
  display: flex;
}
.create-new-monitor-modal .modal-content .create-new-monitor__error .creation-error__icon,
.create-new-documentation-modal .modal-content .create-new-monitor__error .creation-error__icon {
  margin-top: 0px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__header .tabs,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__header .tabs {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--border-color-default);
  background-color: var(--background-color-primary);
  position: relative;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .tabs,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .tabs {
  flex: 0 0 30px;
  justify-content: center;
  width: fit-content;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right {
  overflow: hidden;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table {
  height: 100%;
  flex: 1;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table .create-new-monitor-modal__data-editor,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table .create-new-monitor-modal__data-editor {
  height: 100%;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table .create-new-monitor-modal__data-editor .data-editor,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table .create-new-monitor-modal__data-editor .data-editor {
  height: 100%;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table .create-new-monitor-modal__data-editor .data-editor .data-editor,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table .create-new-monitor-modal__data-editor .data-editor .data-editor {
  overflow-y: auto;
  height: 100%;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__footer__helptext,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .create-new-monitor-modal__body .create-new-monitor-modal__footer__helptext {
  margin-top: 20px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1 {
  overflow: auto;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1 .entity-empty__illustration-wrapper,
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1 .entity-empty__subtitle,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1 .entity-empty__illustration-wrapper,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1 .entity-empty__subtitle {
  margin-bottom: 10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body {
  padding: 20px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs {
  border: 1px solid var(--border-color-default);
  margin-bottom: 20px;
  border-radius: var(--border-radius-default);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab--collections,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab--collections {
  border-left: 1px solid var(--border-color-default);
  border-right: 1px solid var(--border-color-default);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab-primary,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab-primary {
  border-bottom: 0px;
  background-color: var(--background-color-tertiary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab-primary.is-active,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab-primary.is-active {
  background-color: var(--background-color-primary);
  color: var(--content-color-brand);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab-primary.is-active .tab-text-wrapper,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .tabs .tab-primary.is-active .tab-text-wrapper {
  box-shadow: none;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right__intro,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right__intro {
  font-size: 12px;
  line-height: 16px;
  color: var(--content-color-secondary);
  width: 100%;
  margin-bottom: 20px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right__intro .btn-text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right__intro .btn-text {
  line-height: 16px;
  padding: 0 3px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right {
  flex: 1;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__footer__helptext,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__footer__helptext {
  color: var(--content-color-primary);
  margin-top: 20px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__footer__helptext .btn-text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__footer__helptext .btn-text {
  height: 0px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__data-editor,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__data-editor {
  margin-right: 1px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor {
  display: flex;
  flex-direction: column;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header {
  margin-right: 12px;
  flex: 0 0 30px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__rows,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__rows {
  overflow-y: scroll;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__rows::-webkit-scrollbar-track,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__rows::-webkit-scrollbar-track {
  background-color: var(--background-color-primary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .data-editor__header__cell,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .data-editor__header__cell {
  font-size: 11px;
  color: var(--content-color-primary);
  font-weight: 600;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .data-editor__header__cell .data-editor__header__cell__title,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .data-editor__header__cell .data-editor__header__cell__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .create-new-monitor-modal__test-header,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .create-new-monitor-modal__test-header {
  overflow: hidden;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .create-new-monitor-modal__test-header .input-checkbox,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .create-new-monitor-modal__test-header .input-checkbox {
  min-width: 16px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .create-new-monitor-modal__test-header span,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__header .create-new-monitor-modal__test-header span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__reorder,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__reorder {
  width: 14px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .dropdown,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .dropdown {
  margin: 0 5px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .dropdown-button .btn,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .dropdown-button .btn {
  background-color: transparent;
  padding: 0;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__cell--time,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__cell--time {
  display: flex;
  flex: 1;
  margin-right: 30px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__cell--time .postfix,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__cell--time .postfix {
  font-size: 12px;
  padding-left: 5px;
  line-height: 25px;
  color: var(--content-color-secondary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__cell--time, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__cell--time,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__cell--time,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: 0;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__cell--time, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__cell--time,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__cell--time,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: 30px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__test-header,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__test-header {
  display: flex;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__test-header .input-checkbox,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__test-header .input-checkbox {
  margin-right: 5px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__table {
  flex: 1;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card {
  display: flex;
  align-items: center;
  flex: 0 0 230px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  border-radius: var(--border-radius-default);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card.is-disabled,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card.is-disabled {
  opacity: 0.5;
  cursor: default;
  user-select: none;
  pointer-events: none;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card:hover, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card.is-hovered,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card:hover,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__icon,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__icon {
  margin-right: 10px;
  flex: 0 0 22px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__title,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__title {
  display: flex;
  align-items: center;
  max-width: 190px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__title span,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__title span {
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__subtitle,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__subtitle {
  display: flex;
  align-items: center;
  color: var(--content-color-secondary);
  font-size: 12px;
  overflow: hidden;
  max-width: 190px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__requests,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__card .create-new-monitor-modal__card__requests {
  white-space: nowrap;
  margin-right: 8px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__collections,
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__team-library,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__collections,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-modal__right__team-library {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--border-color-default);
  overflow: auto;
  margin-bottom: 20px;
  max-height: 35vh;
  padding: 10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-disabled,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal__right .create-new-monitor-disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline {
  cursor: not-allowed;
  color: var(--content-color-primary);
  opacity: 0.4;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline use,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline use {
  fill: var(--content-color-primary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline svg:not([class*=PartnerIcon]) > path[fill],
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline svg,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .create-new-monitor-modal-offline svg {
  color: var(--content-color-primary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2 {
  --input-components-width: 500px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 950px;
  margin: 0 auto;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group {
  margin-bottom: 16px;
}
@media screen and (max-width: 1183px) {
  .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--version-select,
  .create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--version-select {
    margin-bottom: 8px;
  }
  .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--frequency,
  .create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--frequency {
    margin-bottom: 4px;
  }
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions {
  font-size: 12px;
  line-height: 36px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label.is-error, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .is-error.create-new-monitor__body__label--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label.is-error,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .is-error.create-new-monitor__body__label--regions {
  color: var(--base-color-error);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--schedule-selector,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--schedule-selector {
  margin-bottom: var(--spacing-xs);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__label--regions {
  line-height: var(--line-height-m);
  padding-top: var(--spacing-s);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .region-label,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .region-label {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  margin-bottom: calc(-1 * var(--spacing-s));
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input {
  display: flex;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--regions, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__form__body__input__helptext--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__form__body__input__helptext--regions {
  margin-top: 18px;
  padding-left: 20px;
  color: var(--content-color-tertiary);
}
@media screen and (max-width: 1183px) {
  .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.create-new-monitor__body__input__helptext--version-select, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--version-select.create-new-monitor__body__input__helptext--regions, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--version-select.create-new-monitor__form__body__input__helptext--regions,
  .create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.create-new-monitor__body__input__helptext--version-select,
  .create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--version-select.create-new-monitor__body__input__helptext--regions,
  .create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--version-select.create-new-monitor__form__body__input__helptext--regions {
    margin-top: 12px;
  }
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.create-new-monitor__body__input__helptext--schedule-selector, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--schedule-selector.create-new-monitor__body__input__helptext--regions, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--schedule-selector.create-new-monitor__form__body__input__helptext--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.create-new-monitor__body__input__helptext--schedule-selector,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--schedule-selector.create-new-monitor__body__input__helptext--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--schedule-selector.create-new-monitor__form__body__input__helptext--regions {
  margin-top: -8px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.create-new-monitor__body__input__helptext--regions, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext.create-new-monitor__body__input__helptext--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__input__helptext--regions {
  margin-top: 0;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field {
  display: flex;
  flex: 0 0 500px;
  margin-bottom: auto;
  height: 100%;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown {
  width: var(--input-components-width);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container-selected,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container-selected {
  max-width: 470px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field__dropdown__collection-item-container {
  max-width: 420px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field .dropdown-button .create-monitor-collection-item,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .create-new-monitor__body__field .dropdown-button .create-monitor-collection-item {
  max-width: 270px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .input-checkbox,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input .input-checkbox {
  margin-right: 5px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input.create-new-monitor__body__input--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input.create-new-monitor__body__input--regions {
  margin-left: var(--spacing-xl);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input-error,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input-error {
  color: var(--base-color-error);
  font-size: 11px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section-toggle,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section-toggle {
  margin-left: -10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section {
  margin: 10px 0;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__field-error,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__field-error {
  color: var(--base-color-error);
  font-size: 11px;
  margin: 5px 25px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox {
  display: flex;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field {
  display: flex;
  align-items: center;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field__input,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field__input {
  width: 300px;
  margin: 5px 10px 5px 25px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field .advanced-section__field__meta,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field .advanced-section__field__meta {
  margin: 5px 25px;
  font-size: 11px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--notifications,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--notifications {
  display: block;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .monitor-notification-limit__input,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .monitor-notification-limit__input {
  width: 75px;
  margin: 0 8px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .input-error-section,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .input-error-section {
  top: 3px;
  margin-top: 0px;
  display: inline-block;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay {
  font-size: 11px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout .advanced-section__field__input, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay .advanced-section__field__input,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout .advanced-section__field__input,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay .advanced-section__field__input {
  width: 150px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select {
  margin: 5px 10px 5px 25px;
  display: flex;
  align-items: center;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select--retry > *,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select--retry > * {
  margin-right: 10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select__dropdown--retry,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__select__dropdown--retry {
  width: 75px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox__label,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section .advanced-section__checkbox__label {
  margin-left: 10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__checkbox-label-helper,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__show-more .advanced-section .advanced-section__checkbox-label-helper {
  font-size: 10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions, .create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual {
  white-space: pre-line;
  width: fit-content;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions-manual {
  margin-bottom: unset;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions__radio,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__select-group--regions__radio {
  margin-right: 5px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input--help,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__input--help {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext {
  color: var(--content-color-secondary);
  font-size: 12px;
  line-height: 24px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext .btn-text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group .create-new-monitor__body__subtext .btn-text {
  padding: 0 3px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--private,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group.create-new-monitor__body__input-group--private {
  margin-top: 10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--auto,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--auto {
  display: none;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--manual,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input.create-new-monitor__body__input-regions--manual {
  display: flex;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__field,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__field {
  flex-wrap: wrap;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox {
  display: flex;
  width: 500px;
  color: var(--content-color-primary);
  margin-top: 8px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon {
  display: inline-block;
  padding-left: 4px;
  vertical-align: -2px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon use,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon use {
  fill: var(--content-color-tertiary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg:not([class*=PartnerIcon]) > path[fill],
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-icon svg {
  color: var(--content-color-tertiary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-region,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--regions .create-new-monitor__body__input .create-new-monitor__body__input__checkbox-region {
  height: 18px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .monitor-schedule-selector-tab,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .monitor-schedule-selector-tab {
  display: contents;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .monitor-schedule-selector-tab > *,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .monitor-schedule-selector-tab > * {
  margin-bottom: var(--spacing-s);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .monitor-schedule-selector-tab > *:last-child,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .monitor-schedule-selector-tab > *:last-child {
  margin-bottom: 0px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .is-loading,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .is-loading {
  justify-content: center;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .version-dropdown,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .version-dropdown {
  width: 500px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .version-dropdown-button,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .version-dropdown-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3 .create-new-monitor-modal__card__center,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3 .create-new-monitor-modal__card__center {
  width: 480px;
  margin: 0 auto;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body {
  padding: 30px 0;
  overflow-y: auto;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body.is-error,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body.is-error {
  justify-content: center;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .loading-indicator-wrapper,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .loading-indicator-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header {
  display: flex;
  margin-bottom: 20px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__icon,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 12px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text {
  flex: 1;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text .create-new-monitor-modal__content__header__text__title,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text .create-new-monitor-modal__content__header__text__title {
  font-size: 14px;
  line-height: 20px;
  color: var(--content-color-primary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text .create-new-monitor-modal__content__header__text__title b,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text .create-new-monitor-modal__content__header__text__title b {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text .create-new-monitor-modal__content__header__text__text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__header .create-new-monitor-modal__content__header__text .create-new-monitor-modal__content__header__text__text {
  font-size: 12px;
  color: var(--content-color-tertiary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .separator,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .separator {
  display: flex;
  color: var(--content-color-tertiary);
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 20px;
  margin-left: 40px;
  padding-top: 10px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .separator .separator-line,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .separator .separator-line {
  flex: 1;
  height: 1px;
  margin-top: 9px;
  margin-left: 10px;
  background-color: var(--border-color-default);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer {
  position: relative;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step {
  margin-bottom: 20px;
  position: relative;
  margin-left: 40px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step::before,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step::before {
  content: "∙";
  font-size: 40px;
  position: absolute;
  margin-left: -33px;
  top: 2px;
  color: var(--content-color-primary);
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__title,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__title {
  color: var(--content-color-primary);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text {
  color: var(--content-color-secondary);
  font-size: 12px;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text .btn-text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text .btn-text {
  padding-left: 0;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text .monitor-url.btn-text,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text .monitor-url.btn-text {
  text-align: left;
}
.create-new-monitor-modal .modal-content .create-new-monitor-modal__content.enable_scrolling_tab,
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content.enable_scrolling_tab {
  overflow: auto;
}
.create-new-monitor-modal .modal-footer,
.create-new-documentation-modal .modal-footer {
  padding-top: 20px;
  flex: 0 0 80px;
  border-top: 1px solid var(--border-color-default);
}
.create-new-monitor-modal .modal-footer .create-new-monitor-modal__footer,
.create-new-documentation-modal .modal-footer .create-new-monitor-modal__footer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  font-size: 12px;
  position: relative;
}
.create-new-monitor-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__info,
.create-new-documentation-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__info {
  flex: 1;
  align-self: center;
  margin-left: 150px;
}
.create-new-monitor-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__note,
.create-new-documentation-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__note {
  font-weight: 600;
  color: var(--content-color-info);
  margin-right: 10px;
}
.create-new-monitor-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__text,
.create-new-documentation-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__text {
  color: var(--content-color-primary);
}
.create-new-monitor-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__text .btn-text,
.create-new-documentation-modal .modal-footer .create-new-monitor-modal__footer .create-new-monitor-modal__footer__text .btn-text {
  padding: 0 3px;
  margin-bottom: 1px;
}
.create-new-monitor-modal .modal-footer .create-new-monitor-modal__footer .btn-secondary,
.create-new-documentation-modal .modal-footer .create-new-monitor-modal__footer .btn-secondary {
  margin-right: 10px;
}
.create-new-monitor-modal .loading-indicator-wrapper,
.create-new-documentation-modal .loading-indicator-wrapper {
  display: flex;
  align-self: center;
}
.create-new-monitor-modal .modal-content.step2,
.create-new-documentation-modal .modal-content.step2 {
  overflow-y: scroll;
}

.create-new-monitor__body__input--help-tooltip .btn-text {
  padding-left: 3px;
}

.create-new-monitor__modal__body__input__helptext--regions {
  margin-top: -4px;
  padding-left: 20px;
  color: var(--content-color-tertiary);
}

.create-monitor-modal-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-new-documentation-modal .modal-content {
  overflow-x: auto;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content.step2, .create-new-documentation-modal .modal-content .create-new-monitor-modal__content.step3 {
  min-width: 800px;
}

.create-new-mock-modal .modal-content {
  display: flex;
  padding: 0;
  overflow: overlay;
}
.create-new-mock-modal .modal-content .create-new-mock__error {
  flex: 1;
  display: flex;
}
.create-new-mock-modal .modal-content .create-new-mock__error .creation-error__icon {
  margin-top: 0px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__header .tabs {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--border-color-default);
  background-color: var(--background-color-primary);
  position: relative;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: unset;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .tabs {
  flex: 0 0 30px;
  justify-content: center;
  width: fit-content;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right {
  overflow: hidden;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table {
  height: 100%;
  flex: 1;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table .create-new-mock-modal__data-editor {
  height: 100%;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table .create-new-mock-modal__data-editor .data-editor {
  height: 100%;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table .create-new-mock-modal__data-editor .data-editor .data-editor {
  overflow-y: auto;
  height: 100%;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table .create-new-mock-modal__data-editor .data-editor__cell--path {
  display: flex;
  flex: 1;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table .create-new-mock-modal__data-editor .data-editor__cell--path .auto-suggest {
  margin: var(--spacing-zero);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table .create-new-mock-modal__data-editor .data-editor__cell--path .prefix {
  font-size: var(--text-size-m);
  padding-left: var(--spacing-s);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock-modal__body .create-new-mock-modal__footer__helptext {
  margin-top: 20px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1 {
  overflow: auto;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1 .entity-empty__illustration-wrapper,
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1 .entity-empty__subtitle {
  margin-bottom: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body {
  min-width: 800px;
  padding: 20px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body.is-loading {
  min-width: auto;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .tabs {
  border: 1px solid var(--border-color-default);
  margin-bottom: 20px;
  border-radius: var(--border-radius-default);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .tabs .tab--collections {
  border-left: 1px solid var(--border-color-default);
  border-right: 1px solid var(--border-color-default);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .tabs .tab-primary {
  border-bottom: 0px;
  background-color: var(--background-color-tertiary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .tabs .tab-primary.is-active {
  background-color: var(--background-color-primary);
  color: var(--content-color-brand);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .tabs .tab-primary.is-active .tab-text-wrapper {
  box-shadow: none;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right__intro {
  font-size: 12px;
  line-height: 16px;
  color: var(--content-color-secondary);
  width: 100%;
  margin-bottom: var(--spacing-s);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right__intro .btn-text {
  line-height: 16px;
  padding: 0 3px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right__intro__toggle {
  float: right;
  position: relative;
  top: -8px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right {
  flex: 1;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__footer__helptext {
  color: var(--content-color-primary);
  margin-top: 20px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__footer__helptext .btn-text {
  height: 0px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__data-editor {
  margin-right: 1px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__data-editor .data-editor__cell--path .prefix {
  display: flex;
  align-items: center;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__data-editor .dropdown-button .btn {
  height: 28px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__data-editor .DraftEditor-editorContainer .public-DraftEditor-content {
  height: 20px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor {
  display: flex;
  flex-direction: column;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__header {
  margin-right: 12px;
  flex: 0 0 30px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__rows {
  overflow-y: scroll;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__header .data-editor__header__cell {
  font-size: 11px;
  color: var(--content-color-primary);
  font-weight: 600;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__header .data-editor__header__cell .data-editor__header__cell__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__header .create-new-mock-modal__test-header {
  overflow: hidden;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__header .create-new-mock-modal__test-header .input-checkbox {
  min-width: 16px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__header .create-new-mock-modal__test-header span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row .data-editor__reorder {
  width: 14px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row .dropdown {
  margin: 0 5px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row .dropdown-button .btn {
  background-color: transparent;
  padding: 0;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row .data-editor__cell--time {
  display: flex;
  flex: 1;
  margin-right: 30px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row .data-editor__cell--time .postfix {
  font-size: 12px;
  padding-left: 5px;
  line-height: 25px;
  color: var(--content-color-secondary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__cell--time, .create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: 0;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__cell--time, .create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: 30px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__test-header {
  display: flex;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__test-header .input-checkbox {
  margin-right: 5px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__table {
  flex: 1;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 0 224px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  border-radius: var(--border-radius-default);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__card:hover, .create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__card.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__card .create-new-mock-modal__card__icon {
  margin-right: 10px;
  flex: 0 0 22px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__card .create-new-mock-modal__card__title {
  display: flex;
  align-items: center;
  max-width: 176px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__card .create-new-mock-modal__card__title span {
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__card .create-new-mock-modal__card__title .collection-meta-icons {
  overflow: visible;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .create-new-mock-modal__right__collections {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  border: var(--border-width-default) solid var(--border-color-default);
  overflow: auto;
  max-height: 100%;
  padding: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .assign-mock-collection__entity-empty {
  text-align: center;
  color: var(--content-color-secondary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .assign-mock-collection__entity-empty__title {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--line-height-m);
  margin-top: var(--spacing-l);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step1.create-new-mock-modal__body .create-new-mock-modal__right .assign-mock-collection__entity-empty__subtitle {
  font-size: var(--text-size-m);
  margin-top: var(--line-height-m);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  min-width: 800px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body.is-loading {
  min-width: auto;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group {
  margin-bottom: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__label {
  font-size: 12px;
  line-height: 36px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__label.is-error {
  color: var(--base-color-error);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input {
  display: flex;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input .create-new-mock__body__input__helptext {
  margin-top: -4px;
  padding-left: 20px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input .create-new-mock__body__input__helptext__content {
  max-width: 100%;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input .create-new-mock__body__field {
  display: flex;
  flex: 0 0 500px;
  margin-bottom: auto;
  height: 100%;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input .create-new-mock__body__field .input-box__error {
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-error);
  background-color: var(--background-color-primary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input .input-checkbox {
  margin-right: 5px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input-error {
  color: var(--base-color-error);
  font-size: 11px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section-toggle {
  margin-left: -10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section {
  margin: 10px 0;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section__field-error {
  color: var(--base-color-error);
  font-size: 11px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__checkbox {
  display: flex;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field {
  display: flex;
  align-items: center;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field .input-field {
  margin: 5px 10px 5px 25px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field .input-field input {
  flex: 0 0 300px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field .advanced-section__field__meta {
  margin: 5px 25px;
  font-size: 11px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--notifications {
  display: block;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .input-field {
  display: inline-block;
  margin-left: 10px;
  width: 60px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .input-field input {
  text-align: center;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field--notification-limit .input-error-section {
  top: 3px;
  margin-top: 0px;
  display: inline-block;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout .input-field, .create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay .input-field {
  flex: 0 0 150px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--timeout .input-field input, .create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__field.advanced-section__field--delay .input-field input {
  flex: 0 0 150px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section__select {
  margin: 5px 10px 5px 25px;
  display: flex;
  align-items: center;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section__select--retry > * {
  margin-right: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section .advanced-section__checkbox__label {
  margin-left: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__show-more .advanced-section .advanced-section__checkbox-label-helper {
  font-size: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__select-group--regions {
  white-space: pre-line;
  margin-bottom: 8px;
  width: fit-content;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__select-group--regions__radio {
  margin-right: 5px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__input--help {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__subtext {
  color: var(--content-color-secondary);
  font-size: 12px;
  line-height: 24px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .create-new-mock__body__subtext .btn-text {
  padding: 0 3px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group.create-new-mock__body__input-group--private {
  margin-top: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group.create-new-mock__body__input-group--create-environment {
  margin-bottom: var(--spacing-xl);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .mock-name-empty__msg-container {
  margin-top: var(--spacing-xs);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group .mock-name-empty__msg-container__text {
  color: var(--content-color-error);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--frequency .create-new-mock__body__input .dropdown {
  margin-right: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input.create-new-mock__body__input-regions--auto {
  display: none;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input.create-new-mock__body__input-regions--manual {
  display: flex;
  padding-top: 12px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__field {
  flex-wrap: wrap;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__input__checkbox {
  display: flex;
  width: 500px;
  height: 40px;
  color: var(--content-color-primary);
  margin-top: 8px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__input__checkbox .input-checkbox {
  margin-left: 16px;
  margin-right: 8px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__input__checkbox-icon {
  display: inline-block;
  padding-left: 4px;
  vertical-align: -2px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__input__checkbox-icon use {
  fill: var(--content-color-tertiary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__input__checkbox-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__input__checkbox-icon svg {
  color: var(--content-color-tertiary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step2.create-new-mock-modal__body .create-new-mock__body__input-group--regions .create-new-mock__body__input .create-new-mock__body__input__checkbox-region {
  height: 18px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .is-loading {
  justify-content: center;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock__body__field__version-dropdown {
  width: 500px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .create-new-mock__body__field__version-dropdown-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3 .create-new-mock-modal__card__center {
  width: 480px;
  margin: 0 auto;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body {
  padding: 30px 0;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body.is-error {
  justify-content: center;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .loading-indicator-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header {
  display: flex;
  margin-bottom: 20px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header .create-new-mock-modal__content__header__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 12px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header .create-new-mock-modal__content__header__text {
  flex: 1;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header .create-new-mock-modal__content__header__text .create-new-mock-modal__content__header__text__title {
  font-size: 14px;
  line-height: 20px;
  color: var(--content-color-primary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header .create-new-mock-modal__content__header__text .create-new-mock-modal__content__header__text__title b {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header .create-new-mock-modal__content__header__text .create-new-mock-modal__content__header__text__text {
  font-size: 12px;
  color: var(--content-color-tertiary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .separator {
  display: flex;
  color: var(--content-color-tertiary);
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 20px;
  margin-left: 40px;
  padding-top: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .separator .separator-line {
  flex: 1;
  height: 1px;
  margin-top: 9px;
  margin-left: 10px;
  background-color: var(--border-color-default);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer {
  position: relative;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step {
  margin-bottom: 20px;
  position: relative;
  margin-left: 40px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step::before {
  content: "∙";
  font-size: 40px;
  position: absolute;
  margin-left: -33px;
  top: 2px;
  color: var(--content-color-primary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step .create-new-mock-modal__step__title {
  color: var(--content-color-primary);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step .create-new-mock-modal__step__text {
  color: var(--content-color-secondary);
  font-size: 12px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step .create-new-mock-modal__step__text .btn-text {
  padding-left: 0;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step .create-new-mock-modal__step__text__link {
  font-weight: var(--text-weight-regular);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step .create-new-mock-modal__step__text__content {
  max-width: 100%;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer .create-new-mock-modal__step .create-new-mock-modal__step__text .mock-url.btn-text {
  text-align: left;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header__helper-text {
  color: var(--content-color-secondary);
  font-size: 12px;
  word-break: break-word;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header__helper-text .create-new-mock-modal__content__header__helper-text__entity-name {
  font-weight: 600;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header__text__mock-call-logs {
  color: var(--content-color-secondary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header__text__mock-call-logs__url {
  padding: 0px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header__text__mock-call-logs__url__icon {
  vertical-align: middle;
  padding-left: 4px;
  height: 10px;
  width: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__header__text__mock-call-logs__url__icon svg {
  height: 10px;
  width: 10px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__step__copy-button {
  height: 24px;
  padding: 0px 8px 0px 4px;
  font-size: var(--text-size-s);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__step__copy-button__icon {
  margin-right: 8px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__step__copy-button__icon svg {
  height: 14px;
  width: 14px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__step__copy-button__icon svg use {
  fill: var(--content-color-tertiary);
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__content__footer__title {
  color: var(--content-color-secondary);
  margin-left: 42px;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 24px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__footer__helptext {
  color: var(--content-color-secondary);
  margin-left: 42px;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content .step3.create-new-mock-modal__body .create-new-mock-modal__step__text__bold {
  font-weight: 600;
}
.create-new-mock-modal .modal-content .create-new-mock-modal__content.enable_scrolling_tab {
  overflow: auto;
}
.create-new-mock-modal .modal-footer {
  padding-top: 20px;
  flex: 0 0 80px;
  border-top: 1px solid var(--border-color-default);
}
.create-new-mock-modal .modal-footer .create-new-mock-modal__footer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  font-size: 12px;
  position: relative;
}
.create-new-mock-modal .modal-footer .create-new-mock-modal__footer .create-new-mock-modal__footer__info {
  flex: 1;
  align-self: center;
  margin-left: 150px;
}
.create-new-mock-modal .modal-footer .create-new-mock-modal__footer .create-new-mock-modal__footer__note {
  font-weight: 600;
  color: var(--content-color-info);
  margin-right: 10px;
}
.create-new-mock-modal .modal-footer .create-new-mock-modal__footer .create-new-mock-modal__footer__text {
  color: var(--content-color-primary);
}
.create-new-mock-modal .modal-footer .create-new-mock-modal__footer .create-new-mock-modal__footer__text .btn-text {
  padding: 0 3px;
  margin-bottom: 1px;
}
.create-new-mock-modal .modal-footer .create-new-mock-modal__footer .btn-secondary {
  margin-right: 10px;
}
.create-new-mock-modal .modal-header {
  flex: 0 0 45px;
  background-color: var(--background-color-primary);
  border-bottom: 1px solid var(--border-color-default);
}
.create-new-mock-modal .modal-header .modal-header-title {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 0;
  padding-left: 0;
}
.create-new-mock-modal .modal-header .modal-header-title .tabs {
  flex: 1;
  height: 100%;
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab {
  display: flex;
  padding: var(--spacing-zero);
  font-size: 12px;
  line-height: 24px;
  height: 100%;
  cursor: default;
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-active:hover, .create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-active.is-hovered {
  color: var(--content-color-primary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done {
  color: var(--content-color-secondary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done use {
  fill: var(--content-color-secondary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done svg {
  color: var(--content-color-secondary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done:hover, .create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered {
  color: var(--content-color-primary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done:hover use, .create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered use {
  fill: var(--content-color-primary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done:hover svg:not([class*=PartnerIcon]) > path[fill], .create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done:hover svg, .create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done.is-hovered svg {
  color: var(--content-color-primary);
}
.create-new-mock-modal .modal-header .modal-header-title .tabs .tab.is-done span {
  padding-left: 12px;
}
.create-new-mock-modal .modal-header .modal-header-title .btn-text {
  font-size: 14px;
  margin-bottom: 5px;
}
.create-new-mock-modal .permissions-loading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.cnx-mock-delay-selector {
  margin-top: 40px;
  width: 100%;
}
.cnx-mock-delay-selector .cnx-mock-delay__checkbox {
  display: flex;
  margin-top: 16px;
}
.cnx-mock-delay-selector .cnx-mock-delay__checkbox-label {
  cursor: pointer;
}
.cnx-mock-delay-selector .cnx-mock-delay__options {
  margin-left: 5px;
  min-width: 476px;
}
.cnx-mock-delay-selector .cnx-mock-delay__options .cnx-delay-duration-type-dropdown {
  margin-top: 12px;
  height: 32px;
  width: 476px;
}
.cnx-mock-delay-selector .cnx-mock-delay__options .cnx-delay-duration__input-wrapper {
  margin-top: 12px;
  margin-bottom: 32px;
}
.cnx-mock-delay-selector .cnx-mock-delay__options .cnx-delay-duration__input-wrapper .cnx-delay-duration__input {
  display: flex;
  width: fit-content;
}
.cnx-mock-delay-selector .cnx-mock-delay__options .cnx-delay-duration__input-wrapper .cnx-delay-duration__input .cnx-delay-duration__input-unit {
  margin: 6px auto;
  align-self: center;
  height: 100%;
  justify-content: center;
  position: relative;
  left: 6px;
  cursor: default;
}
.cnx-mock-delay-selector .cnx-mock-delay__options .cnx-delay-duration__input-wrapper .cnx-delay-duration__input .cnx-delay-duration__input-unit.is-disabled {
  color: var(--content-color-tertiary);
}
.cnx-mock-delay-selector .cnx-mock-delay__options .cnx-delay-duration__input-wrapper .cnx-delay-duration__input .cnx-delay-duration__input-field.is-disabled {
  cursor: default;
  opacity: 0.4;
}
.cnx-mock-delay-selector .cnx-mock-delay__options .cnx-delay-duration__error {
  display: flex;
  color: var(--base-color-error);
}
.cnx-mock-delay-selector .cnx-mock-delay__helptext {
  margin-top: -4px;
  padding-left: 20px;
  color: var(--content-color-tertiary);
}

.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1 .tabs {
  width: 400px;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1 .tabs-container .tab-primary, .create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1 .tabs-container__existing-collection {
  flex: 1;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .data-editor .dropdown {
  width: 100%;
  margin: 0 5px;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step1.create-new-monitor-modal__body .data-editor .dropdown-button .btn {
  background-color: transparent;
  padding: 0;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body {
  flex: 1;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input {
  flex-direction: row;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--description {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: initial;
  height: 100%;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step2.create-new-monitor-modal__body .create-new-monitor__body__input-group--description .create-new-monitor__body__input {
  flex: 1;
  height: 100%;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__card__center {
  width: 640px;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__card__center .view_documentation {
  margin-top: 8px;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__card__center .view_documentation .btn-text {
  padding-left: 0px;
  height: auto;
  text-align: left;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text .btn-text {
  height: initial;
}
.create-new-documentation-modal .modal-content .create-new-monitor-modal__content .step3.create-new-monitor-modal__body .create-new-monitor-modal__content__footer .create-new-monitor-modal__step .create-new-monitor-modal__step__text .btn-text div {
  text-align: left;
  white-space: pre;
}

.create-new-documentation-editor {
  border: 1px solid;
  border-radius: var(--border-radius-default);
  border-color: var(--border-color-strong);
}

.create-new-modal__template-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-y: overlay;
}
.create-new-modal__template-wrapper .create-new-template__error {
  display: flex;
  flex: 1;
}
.create-new-modal__template-wrapper .create-new-template__error .creation-error__icon {
  margin-top: -40px;
}
.create-new-modal__template-wrapper .create-new-modal__templates__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.create-new-modal__templates {
  display: flex;
  flex: 1;
  height: inherit;
  max-width: 100%;
}
.create-new-modal__templates .create-new-modal__templates__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 0 20px 20px;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__actions {
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  margin-bottom: 16px;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__search {
  width: 320px;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__search .input-search-group {
  border-radius: unset;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__template__version {
  margin-left: 6px;
  width: 158px;
  border-radius: var(--border-radius-default);
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__template__version .create-new-modal__template__version-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__template__version .btn-small {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__template-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow-y: auto;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__heading-wrapper {
  margin-left: 16px;
  margin-bottom: 16px;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__heading-wrapper .create-new-modal__templates__heading {
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__heading-wrapper .create-new-modal__templates__heading-info {
  line-height: 20px;
  font-size: 12px;
}
.create-new-modal__templates .create-new-modal__templates__content .create-new-modal__templates__heading-wrapper .create-new-modal__templates__heading-info .btn {
  padding: 0 3px;
}
.create-new-modal__templates .create-new-modal__templates__sidebar {
  width: 210px;
  height: inherit;
}
.create-new-modal__templates .create-new-modal__templates__sidebar .create-new-modal__templates__sidebar__title {
  font-size: 12px;
  font-weight: 600;
  color: var(--content-color-tertiary);
  text-transform: uppercase;
  margin: 20px 0 10px 20px;
}
.create-new-modal__templates .create-new-modal__templates__sidebar .tabs-vertical .tab.is-active {
  color: var(--content-color-primary);
  background-color: var(--background-color-brand);
}
.create-new-modal__templates .create-new-modal__templates__sidebar .tabs {
  width: 210px;
  border-right: 1px solid var(--border-color-default);
  overflow: auto;
  height: 90%;
}
.create-new-modal__templates .create-new-modal__templates__sidebar .tabs .tab {
  margin: 4px 8px;
  padding-left: 8px;
  border-radius: var(--border-radius-default);
  display: block;
  font-size: 12px;
  line-height: 22px;
  text-align: left;
  min-width: 150px;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-modal__templates .create-new-modal__templates__sidebar .tabs .tab:hover, .create-new-modal__templates .create-new-modal__templates__sidebar .tabs .tab.is-hovered {
  color: var(--content-color-primary);
  background-color: var(--background-color-brand);
}
.create-new-modal__templates .create-new-modal__template-card {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 144px;
  padding: 20px;
  margin: 16px;
  cursor: pointer;
  border: 1px solid var(--border-color-default);
  border-radius: 2px;
  box-sizing: border-box;
}
.create-new-modal__templates .create-new-modal__template-card:hover, .create-new-modal__templates .create-new-modal__template-card.is-hovered {
  background-color: var(--background-color-secondary);
}
.create-new-modal__templates .create-new-modal__template-card .create-new-modal__template-card__title-wrapper {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end;
}
.create-new-modal__templates .create-new-modal__template-card .create-new-modal__template-card__title-wrapper .tag {
  padding-left: 6px;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 55px;
  height: 16px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  overflow: unset;
}
.create-new-modal__templates .create-new-modal__template-card .create-new-modal__template-card__title {
  font-size: 14px;
  font-weight: 600;
  color: var(--base-color-brand);
  min-width: 150px;
  padding-right: 4px;
  flex: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-modal__templates .create-new-modal__template-card .create-new-modal__template-card__description {
  font-size: 12px;
  color: var(--content-color-secondary);
  overflow: hidden;
  height: 38px;
  text-overflow: ellipsis;
}
.create-new-modal__templates .create-new-modal__template-card .create-new-modal__template-card__downloads {
  font-size: 12px;
  color: var(--content-color-tertiary);
  overflow: hidden;
  margin-top: 8px;
}
.create-new-modal__templates .create-new-modal__template-card .create-new-modal__template-card__downloads .create-new-modal__template-card__downloads-count {
  color: var(--content-color-secondary);
  font-weight: 600;
}

.create-new-modal__template {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  padding: 0 24px 0 24px;
  box-sizing: border-box;
}
.create-new-modal__template .create-new-modal__template__content {
  flex: 1;
  padding: 30px 0px;
  width: 640px;
}
.create-new-modal__template .create-new-modal__template__content.is-loading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state {
  margin-top: 8px;
  color: var(--content-color-tertiary);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description {
  color: var(--content-color-primary);
  border: 1px solid var(--border-color-default);
  flex: 1;
  padding: 16px;
  margin: 16px 0;
  background-color: var(--background-color-secondary);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h1,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h2,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h3,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h4,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h5,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h1 {
  font-size: 18px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h2 {
  font-size: 16px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h4 {
  font-size: 14px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description h6 {
  font-size: 12px;
  font-weight: 600;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description blockquote blockquote {
  margin-left: 20px;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description p, .create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description em {
  font-style: italic;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description strong {
  font-weight: var(--text-weight-medium);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description ul, .create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description ol {
  margin: 5px;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description a {
  color: var(--content-color-link);
  text-decoration: none;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description a:hover {
  text-decoration: underline;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table th,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table th:first-child,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table td:first-child {
  border-left: none;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table th:last-child,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table td:last-child {
  border-right: none;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table th {
  border-top: none;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table tr:last-child th,
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description table tr:last-child td {
  border-bottom: none;
}
.create-new-modal__template .create-new-modal__template__content .api-network__empty-state--description img {
  max-width: 100%;
}
.create-new-modal__template .create-new-modal__template__info {
  display: flex;
  flex-direction: column;
  margin-left: 48px;
  margin-top: 54px;
}
.create-new-modal__template .create-new-modal__template__info .run-in-postman {
  margin: 16px 0 4px;
  height: 30px;
  align-self: flex-start;
  flex-shrink: 0;
  justify-content: space-around;
}
.create-new-modal__template .create-new-modal__template__info .run-in-postman .arrow {
  margin-top: 2px;
}
.create-new-modal__template .create-new-modal__template__info .view-documentation {
  margin: 8px 0 8px;
  height: 30px;
  color: var(--content-color-secondary);
  background-color: var(--background-color-tertiary);
  align-self: flex-start;
  flex-shrink: 0;
  justify-content: space-around;
}
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__categories,
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__published-by,
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__download {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  color: var(--content-color-tertiary);
  font-weight: 600;
}
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__categories--names,
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__download-count {
  color: var(--content-color-primary);
  font-weight: 300;
  margin-top: 8px;
}
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__publisher {
  margin-top: 8px;
  display: flex;
  cursor: pointer;
}
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__publisher .create-new-modal__template__info__publisher-name {
  color: var(--base-color-brand);
  font-weight: 600;
  display: flex;
  max-width: 198px;
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__publisher .create-new-modal__template__info__publisher-name:hover {
  text-decoration: underline;
}
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__publisher .create-new-modal__template__info__publisher-icon {
  display: flex;
  align-self: center;
  flex-direction: column;
}
.create-new-modal__template .create-new-modal__template__info .create-new-modal__template__info__categories--names {
  max-width: 140px;
  word-break: break-word;
}
.create-new-modal__template .create-new-modal__template__back-btn {
  padding: 0px;
  margin-bottom: 20px;
}
.create-new-modal__template .create-new-modal__template__back-btn .create-new-modal__template__back-icon {
  margin-right: 5px;
}
.create-new-modal__template .create-new-modal__template__title {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: var(--content-color-primary);
  flex-grow: 0;
  margin-right: 16px;
  overflow: hidden;
}
.create-new-modal__template .create-new-modal__template__title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 2;
}
.create-new-modal__template .create-new-modal__template__title .tag {
  padding: 0px 6px;
  height: 16px;
  font-size: 11px;
  line-height: 16px;
  position: relative;
  margin-left: 16px;
}
.create-new-modal__template .create-new-modal__template__loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.create-new-modal__template .create-new-modal__template__summary {
  margin-top: 20px;
  word-break: break-word;
}
.create-new-modal__template .create-new-modal__template__description {
  color: var(--content-color-primary);
  flex: 1;
  border: 1px solid var(--border-color-default);
  padding: 16px;
  margin: 16px 0;
  background-color: var(--background-color-secondary);
}
.create-new-modal__template .create-new-modal__template__description h1,
.create-new-modal__template .create-new-modal__template__description h2,
.create-new-modal__template .create-new-modal__template__description h3,
.create-new-modal__template .create-new-modal__template__description h4,
.create-new-modal__template .create-new-modal__template__description h5,
.create-new-modal__template .create-new-modal__template__description h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.create-new-modal__template .create-new-modal__template__description h1 {
  font-size: 18px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__description h2 {
  font-size: 16px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__description h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__description h4 {
  font-size: 14px;
  font-weight: 700;
}
.create-new-modal__template .create-new-modal__template__description h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.create-new-modal__template .create-new-modal__template__description h6 {
  font-size: 12px;
  font-weight: 600;
}
.create-new-modal__template .create-new-modal__template__description hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.create-new-modal__template .create-new-modal__template__description blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.create-new-modal__template .create-new-modal__template__description blockquote blockquote {
  margin-left: 20px;
}
.create-new-modal__template .create-new-modal__template__description p, .create-new-modal__template .create-new-modal__template__description span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.create-new-modal__template .create-new-modal__template__description em {
  font-style: italic;
}
.create-new-modal__template .create-new-modal__template__description strong {
  font-weight: var(--text-weight-medium);
}
.create-new-modal__template .create-new-modal__template__description ul, .create-new-modal__template .create-new-modal__template__description ol {
  margin: 5px;
}
.create-new-modal__template .create-new-modal__template__description a {
  color: var(--content-color-link);
  text-decoration: none;
}
.create-new-modal__template .create-new-modal__template__description a:hover {
  text-decoration: underline;
}
.create-new-modal__template .create-new-modal__template__description pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.create-new-modal__template .create-new-modal__template__description pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.create-new-modal__template .create-new-modal__template__description code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.create-new-modal__template .create-new-modal__template__description table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.create-new-modal__template .create-new-modal__template__description table th,
.create-new-modal__template .create-new-modal__template__description table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.create-new-modal__template .create-new-modal__template__description table th:first-child,
.create-new-modal__template .create-new-modal__template__description table td:first-child {
  border-left: none;
}
.create-new-modal__template .create-new-modal__template__description table th:last-child,
.create-new-modal__template .create-new-modal__template__description table td:last-child {
  border-right: none;
}
.create-new-modal__template .create-new-modal__template__description table th {
  border-top: none;
}
.create-new-modal__template .create-new-modal__template__description table tr:last-child th,
.create-new-modal__template .create-new-modal__template__description table tr:last-child td {
  border-bottom: none;
}
.create-new-modal__template .create-new-modal__template__description img {
  max-width: 100%;
}
.create-new-modal__template .create-new-modal__template__version {
  width: 170px;
}
.create-new-modal__template .create-new-modal__template__version .dropdown-button .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.create-new-modal__template .create-new-modal__template__version .create-new-modal__template__version-name,
.create-new-modal__template .create-new-modal__template__version .create-new-modal__run__button__version-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-modal__template .btn-primary {
  font-size: 12px;
  width: 145px;
  height: 40px;
  align-self: flex-end;
}

.customize-template-modal .modal-footer {
  border-top: 1px solid var(--border-color-default);
}
.customize-template-modal .modal-footer .btn-secondary {
  margin-right: 10px;
}
.customize-template-modal .customize-template {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 480px;
  margin: auto;
}
.customize-template-modal .customize-template .customize-template__error .creation-error__icon {
  margin-top: 80px;
}
.customize-template-modal .customize-template .customize-template__cards {
  display: flex;
  flex-wrap: wrap;
}
.customize-template-modal .customize-template .loading-indicator-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}
.customize-template-modal .customize-template .customize-template__description {
  width: 100%;
  font-size: 12px;
  color: var(--content-color-primary);
  margin-bottom: 15px;
}
.customize-template-modal .customize-template .customize-template__description b {
  font-weight: 600;
}
.customize-template-modal .customize-template .customize-template__card {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  box-sizing: border-box;
  overflow: hidden;
  color: var(--content-color-primary);
}
.customize-template-modal .customize-template .customize-template__card .customize-template__card__icon {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 38px;
  min-height: 38px;
  margin-right: 15px;
}
.customize-template-modal .customize-template .customize-template__card .customize-template__card__text {
  align-self: flex-start;
}
.customize-template-modal .customize-template .customize-template__card .customize-template__card__text .customize-template__card__title {
  font-size: 14px;
  color: var(--content-color-primary);
}
.customize-template-modal .customize-template .customize-template__card .customize-template__card__text .customize-template__card__title b {
  font-weight: 600;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.customize-template-modal .customize-template .customize-template__card .customize-template__card__text .customize-template__card__description {
  font-size: 12px;
  color: var(--content-color-tertiary);
}
.customize-template-modal .customize-template .customize-template__separator {
  border-top: 1px solid var(--border-color-default);
  position: relative;
  margin: 30px 0px 30px;
  font-size: 12px;
  font-weight: 600;
  color: var(--content-color-tertiary);
}
.customize-template-modal .customize-template .customize-template__separator .customize-template__separator__text {
  position: absolute;
  background: var(--background-color-primary);
  top: -9px;
  padding-right: 5px;
}
.customize-template-modal .customize-template .customize-template__advanced .btn-text {
  padding-left: 0;
  margin-bottom: 10px;
}
.customize-template-modal .customize-template .customize-template__advanced .customize-template__advanced__title {
  font-size: 12px;
  color: var(--content-color-primary);
  margin: 10px 0 20px 0;
}
.customize-template-modal .customize-template .customize-template__advanced .customize-template__options__option {
  margin-bottom: 20px;
}
.customize-template-modal .customize-template .customize-template__advanced .customize-template__options__option .customize-template__options__option__label {
  font-size: 12px;
  line-height: 20px;
  color: var(--content-color-tertiary);
  margin-bottom: 5px;
}
.customize-template-modal .customize-template .customize-template__advanced .customize-template__options__option .customize-template__options__option__value {
  margin-right: 10px;
}
.customize-template-modal .customize-template .customize-template__advanced .customize-template__options__option .customize-template__options__option__value input {
  max-width: 480px;
}

.finish-template {
  width: 480px;
  margin: auto;
}
.finish-template .loading-indicator-wrapper {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.finish-template .finish-template__title {
  color: var(--content-color-primary);
  font-size: 14px;
  margin: 0 10px 10px 0;
}
.finish-template .finish-template__cards {
  margin-top: 30px;
}
.finish-template .finish-template__cards .separator {
  display: flex;
  color: var(--content-color-tertiary);
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px 20px 40px;
}
.finish-template .finish-template__cards .separator .separator-line {
  flex: 1;
  height: 1px;
  margin-top: 9px;
  margin-left: 10px;
  background-color: var(--border-color-default);
}
.finish-template .finish-template__cards .finish-template__card__right {
  flex: 1;
  margin-bottom: 20px;
  position: relative;
  margin-left: 40px;
}
.finish-template .finish-template__cards .finish-template__card__right::before {
  content: "∙";
  font-size: 40px;
  position: absolute;
  margin-left: -35px;
}
.finish-template .finish-template__cards .finish-template__card__right .finish-template__card__right__next {
  font-size: 14px;
  font-weight: 600;
  color: var(--content-color-primary);
}
.finish-template .finish-template__cards .finish-template__card__right .finish-template__card__right__next b {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: var(--content-color-info);
}
.finish-template .finish-template__cards .finish-template__card__right .finish-template__card__right__description {
  font-size: 12px;
  color: var(--content-color-tertiary);
}
.finish-template .finish-template__cards .finish-template__card__right .finish-template__card__right__description .btn-text {
  padding-left: 0;
  padding-right: 0;
}
.finish-template .finish-template__cards .finish-template__card {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.finish-template .finish-template__cards .finish-template__card.is-loading {
  align-items: center;
  justify-content: center;
}
.finish-template .finish-template__cards .finish-template__card .finish-template__card__left {
  display: flex;
  flex: 1;
  padding-right: 0;
  align-items: flex-start;
  margin-bottom: 15px;
}
.finish-template .finish-template__cards .finish-template__card .finish-template__card__left .finish-template__card__left__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 8px;
}
.finish-template .finish-template__cards .finish-template__card .finish-template__card__left .finish-template__card__left__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.finish-template .finish-template__cards .finish-template__card .finish-template__card__left .finish-template__card__left__text .finish-template__card__left__text__text {
  font-size: 12px;
  color: var(--content-color-tertiary);
}
.finish-template .finish-template__cards .finish-template__card .finish-template__card__left .finish-template__card__left__text .finish-template__card__left__text__title {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--content-color-primary);
}
.finish-template .finish-template__cards .finish-template__card .finish-template__card__left .finish-template__card__left__text .finish-template__card__left__text__title b {
  display: inline-block;
  font-weight: 600;
  max-width: 250px;
  margin-right: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.finish-template .finish-template__cards .finish-template__card .finish-template__card__left .finish-template__card__left__text .finish-template__card__left__text__title span {
  line-height: 24px;
}

.disabled-operation-modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.disabled-operation-modal .disabled-operation-modal__icon {
  margin-bottom: 50px;
}
.disabled-operation-modal .disabled-operation-modal__icon .disabled-operation-modal__icon__icon {
  display: block;
  width: 161px;
  height: 133px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/share_collection.svg");
}
.disabled-operation-modal .disabled-operation-modal__title {
  font-size: 16px;
  font-weight: 600;
  color: var(--content-color-primary);
  margin-bottom: 30px;
}
.disabled-operation-modal .disabled-operation-modal__subtitle {
  font-size: 12px;
  color: var(--content-color-secondary);
  margin-bottom: 50px;
  max-width: 430px;
  text-align: center;
}
.disabled-operation-modal .disabled-operation-modal__actions {
  display: flex;
}
.disabled-operation-modal .disabled-operation-modal__actions .btn-primary {
  width: 185px;
  font-size: 14px;
  margin: 0 15px;
}

.creation-error {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.creation-error .creation-error__blank-state {
  padding: var(--spacing-l);
}
.creation-error .creation-error__icon {
  display: block;
  width: 149px;
  height: 132px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/warning_security.svg");
  background-position: 50%;
  margin-top: 40px;
  margin-bottom: 40px;
}
.creation-error .creation-error__title {
  font-size: 18px;
  color: var(--content-color-primary);
  font-weight: 600;
  margin-bottom: 20px;
}
.creation-error .creation-error__subtitle {
  font-size: 14px;
  color: var(--content-color-primary);
  margin-bottom: 20px;
  max-width: 410px;
  text-align: center;
}
.creation-error .creation-error__actions .btn {
  font-size: 16px;
  padding: 10px 20px;
}

.create-new-modal__templates .create-new-modal__templates__template-list__load-more {
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
.create-new-modal__templates.api-networks .api-network__label__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-new-modal__templates.api-networks .api-network__icon__wrapper {
  height: 64px;
  width: 64px;
  display: flex;
  flex-direction: row;
  border-radius: 1px;
}
.create-new-modal__templates.api-networks .api-network__icon__wrapper .team-logo .team-logo--image {
  height: 64px;
  width: 64px;
  border-radius: 1px;
  object-fit: contain;
}
.create-new-modal__templates.api-networks .api-network__info__wrapper {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 20px;
  margin-top: 6px;
}
.create-new-modal__templates.api-networks .api-network__info__wrapper .create-new-modal__run__button__downloads {
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
  color: var(--content-color-tertiary);
}
.create-new-modal__templates.api-networks .api-network__info__wrapper .create-new-modal__run__button__downloads .create-new-modal__run__button__downloads-count {
  display: flex;
  font-weight: 600;
  color: var(--content-color-secondary);
}
.create-new-modal__templates.api-networks .create-new-modal__template-card__title {
  padding-bottom: 0px;
  margin-top: 10px;
  justify-content: center;
  color: var(--content-color-primary);
  font-size: 16px;
  line-height: 28px;
  max-width: 100%;
  text-align: center;
}
.create-new-modal__templates.api-networks .create-new-modal__template-card {
  width: 260px;
  height: 182px;
  box-sizing: border-box;
  position: relative;
}
.create-new-modal__templates.api-networks .create-new-modal__template-card .api-network__icon__wrapper {
  border-radius: 1px;
}
.create-new-modal__templates.api-networks .create-new-modal__template-card .api-network__icon__wrapper img {
  border-radius: 1px;
}
.create-new-modal__templates.api-networks .create-new-modal__template-card .api-network__icon__wrapper .tag {
  padding: 0px 6px;
  width: 55px;
  height: 16px;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  overflow: unset;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 10px;
}

.create-new-modal__template .create-new-modal__flex__content {
  display: flex;
  justify-content: space-between;
}
.create-new-modal__template.api-network {
  height: 100%;
  align-items: center;
}
.create-new-modal__template.api-network .create-new-modal__template__content {
  align-self: flex-start;
}
.create-new-modal__template.api-network .api-network__icon--collection {
  margin-right: 10px;
}
.create-new-modal__template.api-network .create-new-modal__run__button__wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.create-new-modal__template.api-network .create-new-modal__run__button__wrapper .create-new-modal__template__info {
  margin-left: unset;
  margin-top: 16px;
}
.create-new-modal__template.api-network .create-new-modal__run__button__content-title {
  display: flex;
  flex-direction: row;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title {
  display: flex;
  align-items: center;
  color: var(--content-color-primary);
  margin-right: 16px;
  margin-bottom: 40px;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title .api-network__icon__wrapper {
  display: flex;
  flex-direction: column;
  height: 80px;
  width: 80px;
  border-radius: 1px;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title .api-network__icon__wrapper .team-logo .team-logo--image {
  height: 80px;
  width: 80px;
  border-radius: 1px;
  object-fit: contain;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title .api-network__team__info {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  overflow: hidden;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title .api-network__team__info .api-network__team__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title .api-network__team__info .api-network__team__name span:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title .api-network__team__info .api-network__team__name .tag {
  padding: 0px 6px;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  position: relative;
  margin-left: 16px;
  flex-shrink: 0;
}
.create-new-modal__template.api-network .create-new-modal__apinetwork__title .api-network__team__info .api-network__team__description {
  display: flex;
  flex-direction: row;
  overflow: ellipsis;
  line-height: 20px;
  font-size: 12px;
  margin-top: 8px;
}
.create-new-modal__template.api-network .create-new-modal__run__button__actionables {
  display: flex;
  flex-direction: row;
}
.create-new-modal__template.api-network .loading-indicator-wrapper {
  text-align: center;
}
.create-new-modal__template.api-network .create-new-modal__run__button__version .dropdown-button .btn {
  margin-right: 30px;
  margin-top: 24px;
}
.create-new-modal__template.api-network .create-new-modal__run__button__version .create-new-modal__run__button__version-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.create-new-modal__template.api-network .create-new-modal__run__button__content-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.create-new-modal__template.api-network .create-new-modal__run__button__content-title-container .dropdown {
  width: 170px;
}
.create-new-modal__template.api-network .create-new-modal__run__button__content-title-container .dropdown-button .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.create-new-modal__template.api-network .create-new-modal__run__button__content-title-container .create-new-modal__run__button__downloads {
  font-size: 12px;
  color: var(--content-color-tertiary);
  overflow: hidden;
  margin-top: 8px;
}
.create-new-modal__template.api-network .create-new-modal__run__button__content-title-container .create-new-modal__run__button__downloads .create-new-modal__run__button__downloads-count {
  color: var(--content-color-secondary);
  font-weight: 600;
}
.create-new-modal__template.api-network .create-new-modal__run__button__name {
  display: flex;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
}
.create-new-modal__template.api-network .create-new-modal__run__button__summary {
  margin-top: 8px;
  display: flex;
}
.create-new-modal__template.api-network .run-in-postman {
  margin: 8px 0 4px;
  height: 32px;
  align-self: flex-start;
  flex-shrink: 0;
  justify-content: space-around;
}
.create-new-modal__template.api-network .run-in-postman .arrow {
  margin-top: 2px;
}
.create-new-modal__template.api-network .api-network__loading-indicator {
  margin-top: 32px;
}
.create-new-modal__template.api-network .environment-empty {
  color: var(--content-color-tertiary);
}
.create-new-modal__template.api-network .create-new-modal__run__buttons__label {
  font-weight: 600;
  color: var(--content-color-secondary);
}
.create-new-modal__template.api-network .environment-name {
  color: var(--content-color-secondary);
}

/* OAuth 2 Manage Tokens Modal */
.auth-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
  overflow: auto;
  scrollbar-gutter: stable;
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.layout-2-column .auth-editor {
  flex-direction: column;
  overflow-y: auto;
}
.layout-2-column .auth-editor .auth-type-editor {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  max-width: 496px;
}
.layout-1-column .auth-editor .auth-selector-wrapper,
.layout-1-column .auth-editor .auth-type-editor {
  overflow-y: auto;
}
.layout-1-column .auth-editor .auth-type-editor {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.auth-editor .auto-suggest .resolvedVariable, .auth-editor .auto-suggest .unresolvedVariable {
  display: inline-block;
}

.auth-editor-wrapper--inactive {
  opacity: 0.5;
}

.auth-type-wrapper {
  flex: 1;
  word-wrap: break-word;
}

.auth-selector-wrapper {
  padding: var(--spacing-m);
  max-width: 496px;
}
.layout-1-column .auth-selector-wrapper {
  flex: 0.35 0;
}
.layout-2-column .auth-selector-wrapper {
  flex-shrink: 0;
  display: flex;
}
.auth-selector-wrapper .dropdown-button .btn > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auth-type-cta-wrapper {
  display: flex;
}
.auth-type-cta-wrapper .auth-type-cta-group {
  display: none;
  padding-top: 15px;
}
.auth-type-cta-wrapper .auth-type-cta-group.show {
  display: flex;
}
.auth-type-cta-wrapper .auth-type-cta-group .btn.btn-primary {
  height: 30px;
}
.layout-2-column .auth-type-cta-wrapper {
  flex-shrink: 0;
  padding: 10px 0 10px 10px;
}

.auth-type-dropdown-selector .dropdown-menu {
  min-width: 224px;
}

.auth-type-selector {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-s) var(--spacing-zero);
  flex-wrap: wrap;
}
.auth-type-selector__label {
  margin-right: var(--spacing-l);
  padding: var(--spacing-xs) var(--spacing-zero);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.layout-1-column .auth-type-selector__label {
  flex: 1;
  min-width: 90px;
}
.layout-2-column .auth-type-selector__label {
  flex: 1;
  min-width: 196px;
  max-width: 280px;
}
.layout-1-column .auth-type-selector__value {
  flex: 1;
  min-width: 90px;
}
.layout-2-column .auth-type-selector__value {
  flex: 1;
  min-width: 196px;
  max-width: 280px;
}
.auth-type-selector .input-checkbox {
  margin-right: var(--spacing-s);
}

.auth-target-description {
  padding: var(--spacing-s) var(--spacing-zero);
}
.auth-target-description__link {
  cursor: pointer;
}

.auth-target-input__selector {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.layout-1-column .auth-target-input__selector .auth-label {
  flex: 1;
  min-width: 90px;
  margin-right: var(--spacing-l);
}
.layout-1-column .auth-target-input__selector .auth-input {
  flex: 1;
  min-width: 90px;
}
.layout-2-column .auth-target-input__selector .auth-label {
  flex: 1;
  min-width: 196px;
  max-width: 280px;
  margin-right: var(--spacing-l);
}
.layout-2-column .auth-target-input__selector .auth-input {
  flex: 1;
  min-width: 196px;
  max-width: 280px;
}
.auth-target-input__selector .auth-label {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.layout-1-column .auth-target-input__selector .auth-label {
  padding-bottom: var(--spacing-s);
}
.auth-target-input__selector .auth-input__description {
  padding-top: var(--spacing-s);
}

.auth-retry-input {
  padding: var(--spacing-s) var(--spacing-zero);
}

.auth-type-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-m);
}

.auth-recommend-variables {
  padding: var(--spacing-s) var(--spacing-xs);
  margin-bottom: var(--spacing-l);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.auth-recommend-variables.is-warning {
  background-color: var(--background-color-warning);
}
.auth-recommend-variables__icon {
  margin-right: 7px;
  margin-top: 2px;
  flex-shrink: 0;
}
.auth-recommend-variables__text {
  padding-right: var(--spacing-s);
}
.auth-recommend-variables__dismiss-icon {
  margin-top: var(--spacing-xs);
  margin-left: auto;
  flex-shrink: 0;
  cursor: pointer;
}

.auth-no-auth-container,
.auth-basic-auth-container,
.auth-apikey-auth-container,
.auth-bearer-token-container,
.auth-digest-auth-container,
.auth-o-auth-1-container,
.auth-o-auth-2-container,
.auth-aws-signature-auth-container,
.auth-ntlm-auth-container,
.auth-akamai-edgegrid-auth-container {
  flex: 1;
  display: flex;
}
.layout-2-column .auth-no-auth-container,
.layout-2-column .auth-basic-auth-container,
.layout-2-column .auth-apikey-auth-container,
.layout-2-column .auth-bearer-token-container,
.layout-2-column .auth-digest-auth-container,
.layout-2-column .auth-o-auth-1-container,
.layout-2-column .auth-o-auth-2-container,
.layout-2-column .auth-aws-signature-auth-container,
.layout-2-column .auth-ntlm-auth-container,
.layout-2-column .auth-akamai-edgegrid-auth-container {
  flex-direction: column;
}

.auth-ntlm-unavailable-container {
  margin: var(--spacing-s);
}

.auth-no-auth-container {
  align-items: center;
  text-align: left;
  padding: var(--spacing-xxl) var(--spacing-l) var(--spacing-zero);
  color: var(--content-color-secondary);
}
.layout-1-column .auth-no-auth-container {
  justify-content: center;
}

.auth-input--add-empty-params-to-signature--wrapper,
.auth-input--payload-hash--wrapper {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-top: var(--spacing-l);
  padding-top: var(--spacing-xs);
}
.auth-input--add-empty-params-to-signature--wrapper .auth-input-group:not(:last-child),
.auth-input--payload-hash--wrapper .auth-input-group:not(:last-child) {
  padding-bottom: var(--spacing-s);
}

.auth-input-wrapper {
  flex-grow: 1;
}

.auth-input--token-type-dropdown {
  display: flex;
  align-items: center;
}
.auth-input--token-type-dropdown.is-hidden {
  display: none;
}

.auth-advanced-wrapper {
  padding: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  align-items: center;
}
.auth-advanced-wrapper .auth-advanced-label,
.auth-advanced-wrapper .auth-advanced-label label {
  cursor: pointer;
}
.auth-advanced-wrapper .auth-advanced-toggle {
  margin: 2px var(--spacing-zero) var(--spacing-zero) -2px;
  cursor: pointer;
}

.auth-advanced-description {
  margin: var(--spacing-xs) var(--spacing-zero);
}

.auth-input-group {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  padding-top: var(--spacing-s);
  align-items: center;
  flex: 1.6 4;
}
.auth-input-group .auth-label {
  display: flex;
  flex: 1;
  min-width: 196px;
  max-width: 280px;
  align-self: flex-start;
  padding-top: var(--spacing-m);
  margin-right: var(--spacing-l);
}
.auth-input-group .auth-label .info-button__icon {
  margin-left: var(--spacing-xs);
  margin-top: 1px;
}
.auth-input-group .auth-input-subgroup-wrapper {
  flex: 1;
  align-items: center;
  min-width: 196px;
  max-width: 280px;
}
.auth-input-group .auth-input-subgroup-wrapper .auth-input-subgroup {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.auth-input-group .auth-input-token-subgroup-wrapper {
  flex: 1;
  align-items: center;
  min-width: 196px;
  max-width: 280px;
}
.auth-input-group .auth-input-token-subgroup-wrapper .auth-input-subgroup {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.auth-input-group .auth-input {
  display: flex;
  flex: 1;
  align-items: center;
  max-height: var(--size-m);
  min-width: 196px;
  max-width: 280px;
  padding-top: var(--spacing-s);
}
.auth-input-group .auth-input.disabled {
  opacity: 0.4;
}
.auth-input-group .auth-input .input-checkbox {
  margin-right: var(--spacing-s);
}
.auth-input-group .auth-input .password-input {
  width: 100%;
}
.auth-input-group .auth-input .dropdown {
  width: 100%;
}
.auth-input-group .auth-input .dropdown .btn {
  display: flex;
}
.auth-input-group .auth-input .dropdown .btn span:not(.dropdown-caret) {
  flex: 1;
  text-align: left;
}
.auth-input-group .auth-input .auto-suggest__warning-btn,
.auth-input-group .auth-input .auto-suggest__error-btn {
  top: 0;
  margin: 9px 9px 0 0;
  padding: 0;
}
.auth-input-group .auth-input .warning-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 9px 9px 0 0;
  z-index: 100;
}
.auth-input-group .auth-input .file-input {
  padding: var(--spacing-zero);
}
.auth-input-group .auth-input.has-warning {
  position: relative;
}
.auth-input-group .auth-input.has-warning .DraftEditor-root {
  margin-right: 15px;
}
.auth-input-group .auth-input.has-warning .auto-suggest-box {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-warning) !important;
}
.auth-input-group .auth-input .public-DraftEditorPlaceholder-inner {
  white-space: nowrap !important;
}
.auth-input-group .auth-input .info-button {
  margin-left: var(--spacing-xs);
}
.auth-input-group .auth-input-dynamic {
  flex: 1;
}
.auth-input-group .auth-input-dynamic .oauth1-private-key-value {
  border-radius: var(--border-radius-default);
  min-height: 100px;
  height: 100px;
  word-wrap: normal;
  white-space: pre;
}
.auth-input-group .oauth2-auth-request-editor,
.auth-input-group .oauth2-token-request-editor,
.auth-input-group .oauth2-refresh-request-editor {
  margin-top: var(--spacing-l);
  max-width: 576px;
}

.auth-akamai-edgegrid--timestamp-tooltip {
  display: inline;
}

.oauth2-refresh-token-cta-wrapper {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-s);
}
.oauth2-refresh-token-cta-wrapper .oauth2-refresh-token-info-wrapper {
  display: flex;
  align-items: center;
}
.oauth2-refresh-token-cta-wrapper .oauth2-refresh-token-expiry-icon,
.oauth2-refresh-token-cta-wrapper .oauth2-refresh-token-expiry-info {
  margin-right: var(--spacing-xs);
}

.oauth2-token-config-msg {
  margin-top: var(--spacing-xs);
  padding: var(--spacing-m);
  background: var(--background-color-warning);
  border-radius: var(--border-radius-default);
}
.oauth2-token-config-msg__title {
  margin-bottom: var(--spacing-xs);
}
.oauth2-token-config-msg__action {
  margin-top: var(--spacing-s);
}

.oauth2-manage-tokens-modal__modal-content {
  padding: 0 !important;
  display: flex !important;
  flex-direction: row !important;
}

.oauth2-manage-tokens-modal__modal-header {
  max-height: 64px;
  min-height: 64px;
}
.oauth2-manage-tokens-modal__modal-header h3 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}

.oauth2-manage-tokens-modal {
  height: 80vh !important;
  max-height: 450px !important;
}

.oauth2-manage-tokens-sidebar {
  border-right: 1px solid var(--border-color-default);
  width: 250px;
  overflow-y: auto;
}

.oauth2-manage-tokens-description {
  width: 100%;
  overflow-y: auto;
}

.oauth2-manage-tokens-sidebar__header {
  display: flex;
  flex-direction: row;
  padding: 20px 20px 5px 20px;
  line-height: 20px;
  justify-content: space-between;
  font-weight: 600;
}
.oauth2-manage-tokens-sidebar__header .btn {
  padding: 0;
  color: var(--content-color-error);
  font-weight: 600;
}
.oauth2-manage-tokens-sidebar__header use {
  fill: var(--content-color-error);
}
.oauth2-manage-tokens-sidebar__header svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.oauth2-manage-tokens-sidebar__header svg {
  color: var(--content-color-error);
}

.oauth2-manage-tokens-sidebar__bulk-delete-actions .dropdown-menu {
  min-width: 130px;
}

.oauth2-manage-tokens-sidebar__token-name-wrapper {
  display: flex;
  padding: 10px 20px;
}

.oauth2-manage-tokens-sidebar__item {
  cursor: pointer;
}
.oauth2-manage-tokens-sidebar__item:hover {
  background-color: var(--background-color-tertiary);
}
.oauth2-manage-tokens-sidebar__item:hover .oauth2-token-delete-icon {
  display: block;
}

.oauth2-manage-tokens-sidebar__item--active {
  background-color: var(--background-color-tertiary);
}
.oauth2-manage-tokens-sidebar__item--active .oauth2-token-delete-icon {
  display: block;
}

.oauth2-manage-tokens-sidebar__token-name-wrapper .oauth2-manage-tokens-sidebar__token-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.oauth2-manage-tokens-sidebar__token-name-wrapper .oauth2-manage-tokens-sidebar__token-name--expired {
  text-decoration-line: line-through;
  color: var(--content-color-secondary);
}
.oauth2-manage-tokens-sidebar__token-name-wrapper .oauth2-token-delete-icon {
  margin-left: auto;
  display: none;
  flex-shrink: 0;
}

.oauth2-token-details-wrapper {
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.oauth2-token-details-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.oauth2-token-details-header .oauth2-token-details-header__title {
  flex: 1;
  font-weight: 600;
}
.oauth2-token-details-header .oauth2-token-details-header__action-group {
  margin-left: 16px;
  flex-shrink: 0;
}

.oauth2-token-details-warning {
  display: flex;
  flex-direction: row;
  justify-content: start;
  background: var(--background-color-secondary);
  padding: 16px;
  margin: -16px -16px 16px -16px;
}
.oauth2-token-details-warning .oauth2-token-details-warning__icon {
  margin-right: 16px;
}
.oauth2-token-details-warning .oauth2-token-details-warning__icon .pm-icon {
  vertical-align: middle;
}
.oauth2-token-details-warning .oauth2-token-details-warning__icon .pm-icon use {
  fill: #A9A9A9;
}
.oauth2-token-details-warning .oauth2-token-details-warning__icon .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: #A9A9A9;
}
.oauth2-token-details-warning .oauth2-token-details-warning__icon .pm-icon svg {
  color: #A9A9A9;
}
.oauth2-token-details-warning .oauth2-token-details-warning__message {
  line-height: 23px;
}

.oauth2-token-details-item-group {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  flex-shrink: 0;
  line-height: 20px;
}
.oauth2-token-details-item-group .oauth2-token-details-item-group__label {
  flex: 0.4;
  color: var(--content-color-primary);
}
.oauth2-token-details-item-group .oauth2-token-details-item-group__label .info-button,
.oauth2-token-details-item-group .oauth2-token-details-item-group__label .warning-button {
  display: inline-flex;
  margin-left: 5px;
}
.oauth2-token-details-item-group .oauth2-token-details-item-group__value {
  width: 250px;
  margin-left: 32px;
  word-break: break-all;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.oauth2-token-details-item-group .oauth2-token-details-item-group__value .inline-input__wrapper {
  margin-top: -4px;
  margin-left: -1px;
  height: 20px;
}
.oauth2-token-details-item-group .oauth2-token-details-item-group__value .oauth2-token-details-item-group__value-edit-button {
  flex-shrink: 0;
  display: flex;
}
.oauth2-token-details-item-group .oauth2-token-details-item-group__value .oauth2-token-details-item-group__value-edit-button .btn {
  height: auto;
}

.oauth2-token-details-item-group--warning .oauth2-token-details-item-group__label,
.oauth2-token-details-item-group--warning .oauth2-token-details-item-group__value {
  color: var(--content-color-error);
}

.oauth2-token-details-tooltip__highlight {
  font-weight: 600;
}

.oauth2-callback-waiting-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.oauth2-callback-waiting-modal-wrapper .oauth2-waiting-status {
  margin-top: 34px;
  margin-bottom: 34px;
}
.oauth2-callback-waiting-modal-wrapper .oauth2-waiting-title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  margin-bottom: 16px;
  line-height: 24px;
}
.oauth2-callback-waiting-modal-wrapper .oauth2-waiting-title .oauth2-waiting-title__success {
  color: var(--content-color-success);
}
.oauth2-callback-waiting-modal-wrapper .oauth2-waiting-title .oauth2-waiting-title__error {
  color: var(--content-color-error);
}
.oauth2-callback-waiting-modal-wrapper .oauth2-waiting-description {
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  text-align: center;
  margin-bottom: var(--spacing-xl);
  margin-right: var(--spacing-xxxl);
  margin-left: var(--spacing-xxxl);
}
.oauth2-callback-waiting-modal-wrapper .oauth2-callback-learn-more-link {
  cursor: pointer;
  color: var(--content-color-link);
}
.oauth2-callback-waiting-modal-wrapper .oauth2-waiting-action {
  margin-bottom: var(--spacing-l);
}
.oauth2-callback-waiting-modal-wrapper .oauth2-waiting-action .btn {
  width: 160px;
}
.oauth2-callback-waiting-modal-wrapper .oauth2-callback-return-link {
  cursor: pointer;
  color: var(--content-color-brand);
  margin-top: -50px;
  margin-bottom: 20px;
}

.auth-oauth2--manage-tokens {
  color: var(--content-color-brand);
}

.auth-oauth2--shared-token {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.auth-oauth2--shared-token use {
  fill: var(--content-color-success);
}
.auth-oauth2--shared-token svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.auth-oauth2--shared-token svg {
  color: var(--content-color-success);
}

.auth-oauth2--no-tokens {
  box-sizing: border-box;
  height: var(--controls-size-default);
  padding: var(--spacing-zero) var(--spacing-l);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  display: flex;
  align-items: center;
}

.auth-oauth2-actions-group {
  padding-top: var(--spacing-xl);
  display: block;
  flex-direction: column;
}
.auth-oauth2-actions-group__clear-cookies {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.auth-oauth2-actions-group__clear-cookies__icon {
  margin-right: var(--spacing-xs);
}
.auth-oauth2-actions-group__clear-cookies .btn-small {
  height: var(--size-s);
}
.auth-oauth2-actions-group__clear-cookies .info-button {
  margin-left: var(--spacing-xs);
}

.auth-oauth2-token-actions-group {
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.auth-oauth2-token-actions-group .auth-oauth2-share-access-token-title {
  margin-top: var(--spacing-l);
  display: flex;
  justify-content: space-between;
}
.auth-oauth2-token-actions-group .auth-oauth2-refresh-access-token-title {
  margin-top: var(--spacing-l);
  display: flex;
  justify-content: space-between;
}

.auth-input--refresh-token-toggle {
  margin-top: var(--spacing-xxl);
}

.auth-oauth2--key-access-token {
  flex: 1;
}

.auth-oauth2--key-id-token {
  flex: 1;
}

.oauth2-main-section-header {
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.oauth2-main-section-header .oauth2-main-section-header--sync-message {
  margin-top: var(--spacing-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-tertiary);
}

.oauth2-token_icon-wrapper--synced {
  margin-left: var(--spacing-xs);
  background: var(--background-color-success);
}
.oauth2-token_icon-wrapper--synced use {
  fill: var(--content-color-success);
}
.oauth2-token_icon-wrapper--synced svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.oauth2-token_icon-wrapper--synced svg {
  color: var(--content-color-success);
}
.oauth2-token_icon-wrapper--synced:hover, .oauth2-token_icon-wrapper--synced.is-hovered {
  background: var(--background-color-success);
}
.oauth2-token_icon-wrapper--synced.is-disabled:hover, .oauth2-token_icon-wrapper--synced.is-disabled.is-hovered {
  background: var(--background-color-success);
}

.oauth2-token_icon-wrapper--not-synced {
  margin-left: var(--spacing-xs);
  background: var(--background-color-warning);
}
.oauth2-token_icon-wrapper--not-synced use {
  fill: var(--content-color-warning);
}
.oauth2-token_icon-wrapper--not-synced svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-warning);
}
.oauth2-token_icon-wrapper--not-synced svg {
  color: var(--content-color-warning);
}
.oauth2-token_icon-wrapper--not-synced:hover, .oauth2-token_icon-wrapper--not-synced.is-hovered {
  background: var(--background-color-warning);
}
.oauth2-token_icon-wrapper--not-synced.is-disabled:hover, .oauth2-token_icon-wrapper--not-synced.is-disabled.is-hovered {
  background: var(--background-color-warning);
}

.auth-form-title {
  display: flex;
  justify-content: flex-start;
  margin-top: var(--spacing-l);
}

.auth-o-auth-2-container .request-editor__tabs-wrapper {
  padding: unset;
}

.oauth2-get-access-token-tabs .tab-text-wrapper .oauth2-access-token-tabs-badge {
  display: inline-flex;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-color: var(--base-color-success);
  border-radius: 8px;
}

.auth-oauth2-input--audience .auth-label {
  align-items: center;
}
.auth-oauth2-input--audience .auth-input {
  cursor: not-allowed;
  color: var(--content-color-secondary);
  background-color: var(--background-color-secondary);
}
.auth-oauth2-input--audience .auth-input:hover, .auth-oauth2-input--audience .auth-input.is-hovered {
  background-color: var(--background-color-secondary);
}
.auth-oauth2-input--audience .auth-input:focus, .auth-oauth2-input--audience .auth-input.is-focused {
  background-color: var(--background-color-secondary);
}

.auth-oauth2-input--resource .auth-label {
  align-items: center;
}
.auth-oauth2-input--resource .auth-input {
  cursor: not-allowed;
  color: var(--content-color-secondary);
  background-color: var(--background-color-secondary);
}
.auth-oauth2-input--resource .auth-input:hover, .auth-oauth2-input--resource .auth-input.is-hovered {
  background-color: var(--background-color-secondary);
}
.auth-oauth2-input--resource .auth-input:focus, .auth-oauth2-input--resource .auth-input.is-focused {
  background-color: var(--background-color-secondary);
}

.auth-oauth2-input--advanced {
  display: block;
}

.auth-target-description__lc-banner {
  max-width: 576px;
}

.oauth2-advanced-fields {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}
.oauth2-advanced-fields__add-field-button {
  margin-top: var(--spacing-m);
}
.oauth2-advanced-fields__field-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 280px;
}
.oauth2-advanced-fields__field-wrapper__close-button {
  padding: var(--spacing-xs);
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-xs);
}
.oauth2-advanced-fields__field-wrapper.added .auth-input {
  min-width: 168px;
}

.auth-jwt-container .auth-jwt-input--payload-box,
.auth-jwt-container .auth-jwt-input--header-box {
  box-sizing: border-box;
  height: 140px;
  margin: var(--spacing-xl) 0 0 0;
}
.auth-jwt-container .auth-jwt--base64-checkbox {
  padding: 0;
}
.auth-jwt-container .auth-jwt--header-info {
  padding-top: var(--spacing-xs);
}
.auth-jwt-container .auth-jwt-input--header-box {
  margin: var(--spacing-s) 0 0 0;
}
.auth-jwt-container .auth-jwt-private-key-value {
  border-radius: var(--border-radius-default);
  min-height: 100px;
  height: 100px;
  word-wrap: normal;
  white-space: pre;
}
.auth-jwt-container .auth-label {
  align-items: center;
}
.auth-jwt-container .auth-jwt-text-editor {
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.is-inherit .auth-input .auto-suggest-box {
  border: 1px dashed var(--content-color-tertiary);
}
.is-inherit .auth-input .auto-suggest-box.read-only {
  background-color: var(--background-color-primary);
}
.is-inherit .auth-input .dropdown > .dropdown-button > .btn-secondary {
  box-shadow: none;
  border: 1px dashed var(--content-color-tertiary);
  opacity: 1;
  color: var(--content-color-tertiary);
}
.is-inherit .auth-input .dropdown > .dropdown-button > .btn-secondary svg {
  color: var(--content-color-tertiary);
}
.is-inherit .auth-input [aria-checked=true][data-aether-id=aether-toggleSwitch] > label, .is-inherit .auth-input [aria-checked=true] [data-aether-id=aether-checkbox] {
  background-color: var(--content-color-tertiary);
}
.is-inherit .auth-input .auth-jwt-text-editor, .is-inherit .auth-input [data-aether-id=aether-textInput] {
  background-color: var(--background-color-primary);
  border: 1px dashed var(--content-color-tertiary);
}
.is-inherit .textarea, .is-inherit [data-testid|=input-form-gen-jsoneditor] {
  background-color: var(--background-color-primary);
  border: 1px dashed var(--content-color-tertiary);
}

.sync-token-confirmation-modal .sync-token-confirmation-modal__controls {
  display: flex;
  padding-top: 20px;
  flex-direction: row-reverse;
  align-items: center;
}
.sync-token-confirmation-modal .sync-token-confirmation-modal__controls .btn.is-focused {
  border: 1px solid var(--base-color-info);
}
.sync-token-confirmation-modal .sync-token-confirmation-modal__controls .sync-token-confirmation-modal__confirm-button {
  flex: 1;
  margin-left: var(--spacing-s);
  background: var(--base-color-brand);
}

.confirmation-modal__content {
  padding: 16px 24px 24px 24px;
}
.confirmation-modal__content .confirmation-modal__body {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.confirmation-modal__content .confirmation-modal__body .confirmation-modal__body__header {
  font-size: 16px;
  font-weight: var(--text-weight-medium);
  margin-bottom: 10px;
}
.confirmation-modal__content .confirmation-modal__footer {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 30px;
}
.confirmation-modal__content .confirmation-modal__footer .btn.is-focused {
  box-shadow: var(--shadow-focus);
}
.confirmation-modal__content .confirmation-modal__footer .confirmation-modal__discard-btn {
  display: flex;
  flex-basis: 65%;
  background-color: var(--base-color-error);
  font-weight: 600;
}
.confirmation-modal__content .confirmation-modal__footer .confirmation-modal__cancel-btn {
  display: flex;
  flex-basis: 30%;
  font-weight: 600;
}

.workspace-switcher {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.workspace-switcher:hover .workspace-switcher__icon use {
  fill: var(--content-color-primary);
}
.workspace-switcher:hover .workspace-switcher__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.workspace-switcher:hover .workspace-switcher__icon svg {
  color: var(--content-color-primary);
}
.workspace-switcher .error-state-wrapper__button {
  margin-top: var(--spacing-l);
}
.workspace-switcher .workspace-switcher__active,
.workspace-switcher .workspace-switcher__disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.workspace-switcher .workspace-switcher__icon {
  margin-left: var(--spacing-xs);
  height: var(--size-xs);
  width: var(--size-xs);
}
.workspace-switcher .workspace-switcher__icon use {
  fill: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__icon svg {
  color: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.workspace-switcher .workspace-switcher__selector {
  outline: 1px solid var(--popover-outline-color);
}
.workspace-switcher .workspace-switcher__selector__no-workspaces {
  max-width: 360px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__state {
  padding: 60px 24px 24px;
  text-align: center;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__state.workspace-switcher__upgrade_to_pro {
  padding: 35px 20px 24px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__state .workspace-switcher__state__title {
  color: var(--content-color-primary);
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 16px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__state .workspace-switcher__state__description {
  color: var(--content-color-primary);
  font-size: 12px;
  margin-bottom: 16px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__state .workspace-switcher__state__learn-more {
  margin-bottom: 32px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__state .workspace-switcher__state__learn-more .btn {
  padding-left: 0;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__search {
  font-weight: var(--text-weight-regular);
  display: flex;
  flex: 1;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__search .input-search-group {
  width: 100%;
  height: 32px;
  border-radius: var(--border-radius-default);
  padding-right: var(--spacing-zero);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__search .input-search-group .input-search-group__search-cancel-wrapper {
  padding-right: 10px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__header {
  display: flex;
  align-items: center;
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-s);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__header .workspace-switcher__actions {
  display: flex;
  align-items: center;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__header .workspace-switcher__actions .workspace-switcher__action.workspace-switcher__action--create {
  margin-left: var(--spacing-l);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list {
  height: 100%;
  overflow: auto;
  position: relative;
  font-weight: var(--text-weight-regular);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__no-result {
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--content-color-tertiary);
  margin: 0 40px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__spinner {
  display: flex;
  justify-content: center;
  padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-s);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-s);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__error-btn {
  color: var(--content-color-secondary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__header {
  display: flex;
  align-items: center;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__header .workspace-switcher__list__group__title {
  line-height: var(--line-height-m);
  margin-left: var(--spacing-l);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item {
  display: flex;
  padding: var(--spacing-zero) var(--spacing-l);
  height: var(--size-m);
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-actions-dropdown, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__join {
  display: none;
  font-size: 12px;
  font-weight: 600;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__join,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-actions-dropdown-button {
  height: var(--size-s);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__join .loading-indicator-wrapper,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-actions-dropdown-button .loading-indicator-wrapper {
  padding-top: 0;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__join:hover,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-actions-dropdown-button:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__list__group__list-item__active-icon,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__list__group__list-item__placeholder {
  height: var(--size-xs);
  width: var(--size-xs);
  margin-right: var(--spacing-s);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__list__group__list-item__details {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item .workspace-switcher__list__group__list-item__name {
  flex: 1;
  font-size: 12px;
  margin-left: var(--spacing-s);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  user-select: none;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item:hover, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item:hover .workspace-type-icon use, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-type-icon use {
  fill: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item:hover .workspace-type-icon svg:not([class*=PartnerIcon]) > path[fill], .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-type-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item:hover .workspace-type-icon svg, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-type-icon svg {
  color: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item:hover .workspace-switcher__list__group__list-item__details,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item:hover .workspace-actions-dropdown, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item:hover .workspace-switcher__join, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-switcher__list__group__list-item__details,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-actions-dropdown, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-switcher__join {
  display: flex;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-switcher__list__group__list-item__details,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-actions-dropdown, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-hovered .workspace-switcher__join {
  display: flex;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-focused {
  background-color: var(--highlight-background-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-focused .workspace-type-icon use {
  fill: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-focused .workspace-type-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-focused .workspace-type-icon svg {
  color: var(--content-color-primary);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-focused .workspace-switcher__list__group__list-item__details,
.workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-focused .workspace-actions-dropdown, .workspace-switcher .workspace-switcher__selector .workspace-switcher__list .workspace-switcher__list__group .workspace-switcher__list__group__list .workspace-switcher__list__group__list-item.is-focused .workspace-switcher__join {
  display: flex;
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__footer {
  cursor: pointer;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  padding: 6px var(--spacing-zero) 6px var(--spacing-l);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.workspace-switcher .workspace-switcher__selector .workspace-switcher__footer:hover {
  color: var(--content-color-link);
}

.workspace-invite-btn {
  background-color: var(--button-main-background-color);
  color: var(--button-main-content-color);
  padding: 6px var(--spacing-s);
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
  height: var(--size-s);
  box-shadow: 0 0 0 1px var(--button-main-outline-color) inset;
}
.workspace-invite-btn use {
  fill: var(--button-main-content-color);
}
.workspace-invite-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--button-main-content-color);
}
.workspace-invite-btn svg {
  color: var(--button-main-content-color);
}
.workspace-invite-btn.is-disabled {
  cursor: not-allowed;
}
.workspace-invite-btn.is-disabled:hover {
  background-color: var(--blue-40);
  color: var(--content-color-constant);
}
.workspace-invite-btn.is-disabled:hover use {
  fill: var(--content-color-constant);
}
.workspace-invite-btn.is-disabled:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.workspace-invite-btn.is-disabled:hover svg {
  color: var(--content-color-constant);
}
.workspace-invite-btn:hover {
  background-color: var(--button-main-hover-background-color);
  color: var(--button-main-content-color);
}
.workspace-invite-btn:hover use {
  fill: var(--button-main-content-color);
}
.workspace-invite-btn:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--button-main-content-color);
}
.workspace-invite-btn:hover svg {
  color: var(--button-main-content-color);
}
.workspace-invite-btn:active {
  background-color: var(--button-main-active-background-color);
}
.workspace-invite-btn:active {
  background-color: var(--button-main-active-background-color);
}

.workspace-switcher__list-loading-state {
  height: 384px;
  border-right: 1px solid var(--border-color-default);
  border-left: 1px solid var(--border-color-default);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
  padding-left: var(--spacing-l);
}
.workspace-switcher__list-loading-state .workspace-switcher__list-loading-state--item {
  display: flex;
  height: 32px;
  align-items: center;
}
.workspace-switcher__list-loading-state .workspace-switcher__list-loading-state--item .workspace-switcher__list-loading-state__icon {
  height: 8px;
  width: 8px;
  background-color: var(--highlight-background-color-secondary);
  margin: var(--spacing-zero) var(--spacing-m) var(--spacing-zero) var(--spacing-xxl);
  border-radius: var(--border-radius-s);
}
.workspace-switcher__list-loading-state .workspace-switcher__list-loading-state--item .workspace-switcher__list-loading-state__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 120px;
  height: 8px;
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-s);
}

.workspace-input-loading-state {
  margin-right: var(--spacing-m);
}

.workspace-join-modal {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.workspace-join-modal .workspace-join-modal--dismiss-icon {
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
}
.workspace-join-modal .workspace-join-modal__wrapper {
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.workspace-join-modal .workspace-join-thumbnail__container {
  height: 180px;
  width: 190px;
}
.workspace-join-modal .workspace-join-thumbnail__container .workspace-join-thumbnail {
  height: inherit;
  width: inherit;
  background-repeat: no-repeat;
  background-image: url("../assets/images/thumbnails/workspace-join.svg");
}
.workspace-join-modal .workspace-join-modal__content {
  margin-top: 10px;
  color: var(--content-color-secondary);
}
.workspace-join-modal .workspace-join-modal__header {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.8;
  cursor: default;
}
.workspace-join-modal .workspace-join-modal__subtext {
  font-size: 12px;
  margin: 30px 0;
  text-align: center;
  cursor: default;
}
.workspace-join-modal .workspace-join-modal__subtext span {
  line-height: 20px;
}
.workspace-join-modal .workspace-join-modal__subtext .workspace-join-modal__type-switcher-toggle {
  display: inline-block;
  margin: 0px 5px 0px 5px;
}
.workspace-join-modal .workspace-join-modal__action {
  display: flex;
  justify-content: center;
}
.workspace-join-modal .always-show-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
.workspace-join-modal .always-show-checkbox .checkbox-label {
  margin-left: 5px;
}

.create-new-workspace-modal, .share-workspace-modal {
  overflow-y: auto;
  margin: 0 auto;
  z-index: 0;
}
.create-new-workspace-modal .modal-content, .share-workspace-modal .modal-content {
  overflow-y: auto;
  padding: var(--spacing-xl) calc(50% - 444px);
}
.create-new-workspace-modal .modal-content .loading-indicator-wrapper, .share-workspace-modal .modal-content .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
}
.create-new-workspace-modal .add-user__list, .share-workspace-modal .add-user__list {
  height: 200px;
}
.create-new-workspace-modal .empty-state--no-users, .share-workspace-modal .empty-state--no-users {
  height: 160px;
  color: var(--content-color-secondary);
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-new-workspace-modal .item-roles__container, .share-workspace-modal .item-roles__container {
  height: 160px;
}
.create-new-workspace-modal__title {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-l);
}
.create-new-workspace-modal__error-state {
  height: 70%;
}
.create-new-workspace-modal .create-new-workspace-modal__team_plan__warning .alert, .share-workspace-modal .create-new-workspace-modal__team_plan__warning .alert {
  background-color: var(--background-color-warning);
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xl);
}
.create-new-workspace-modal .create-new-workspace-modal__team_plan__error .alert, .share-workspace-modal .create-new-workspace-modal__team_plan__error .alert {
  background-color: var(--background-color-error);
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xl);
}
.create-new-workspace-modal .create-new-workspace-modal__contents, .share-workspace-modal .create-new-workspace-modal__contents {
  display: flex;
  flex-direction: column;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group:not(:last-child), .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group:not(:last-child) {
  margin-bottom: 10px;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__label, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__label {
  margin-bottom: 7px;
  display: flex;
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__left-label, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__left-label {
  flex: 1;
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group--subtitle, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group--subtitle {
  flex: 1;
  color: var(--content-color-tertiary);
  font-size: 12px;
  margin-bottom: 8px;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__add-slots, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__add-slots {
  padding-right: 0px;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__team-size, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__team-size {
  color: var(--content-color-secondary);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input {
  display: flex;
  margin-bottom: 10px;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .is-disabled, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .is-disabled {
  cursor: not-allowed;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__wrapper, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__wrapper {
  display: flex;
  justify-content: center;
  height: 32px;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  height: 28px;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-active-item, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-active-item {
  align-self: center;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__disable, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .plugin__workspace-view-switcher__disable {
  opacity: 0.5;
  cursor: default;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .create-new-workspace__input-group__input--type, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .create-new-workspace__input-group__input--type {
  flex: 1;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .textarea-wrapper, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .textarea-wrapper {
  flex: 1;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .create-new-workspace__input-group__input__edit-text-area, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__input .create-new-workspace__input-group__input__edit-text-area {
  margin-bottom: 10px;
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__subtext, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group .create-new-workspace__input-group__subtext {
  color: var(--content-color-tertiary);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error {
  display: flex;
  align-items: center;
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-xs);
  color: var(--content-color-error);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon {
  margin-right: var(--spacing-s);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon use, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon use {
  fill: var(--content-color-error);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon svg:not([class*=PartnerIcon]) > path[fill], .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.create-new-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon svg, .share-workspace-modal .create-new-workspace-modal__contents .create-new-workspace__input-group--error .pm-icon svg {
  color: var(--content-color-error);
}
.create-new-workspace-modal .create-new-workspace__email-privileges-denied, .share-workspace-modal .create-new-workspace__email-privileges-denied {
  margin-bottom: 16px;
  margin-top: 8px;
  color: var(--base-color-error);
}
.create-new-workspace-modal .create-new-workspace__team_info, .share-workspace-modal .create-new-workspace__team_info {
  color: var(--content-color-tertiary);
}
.create-new-workspace-modal .create-new-workspace__team_creation_info, .share-workspace-modal .create-new-workspace__team_creation_info {
  color: var(--content-color-tertiary);
  margin-bottom: var(--spacing-m);
}
.create-new-workspace-modal .modal-footer, .share-workspace-modal .modal-footer {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin: 0 calc(50% - 444px);
}
.create-new-workspace-modal .button-small, .share-workspace-modal .button-small {
  width: 100px;
}
.create-new-workspace-modal .button-medium, .share-workspace-modal .button-medium {
  width: 120px;
}
.create-new-workspace-modal .button-large, .share-workspace-modal .button-large {
  width: 280px;
}
@media (max-width: 1259px) {
  .create-new-workspace-modal, .share-workspace-modal {
    width: 100%;
  }
}

.add-to-workspace-modal .modal-content {
  overflow: visible;
}
.add-to-workspace-modal .loading-indicator-wrapper {
  text-align: center;
}
.add-to-workspace-modal .add-to-workspace-modal__contents .add-to-workspace-modal__contents-box {
  height: inherit;
}

.add-to-workspace-modal__contents {
  display: flex;
  flex-direction: column;
}
.add-to-workspace-modal__contents .add-to-workspace__tabs {
  width: 100%;
  border-bottom: 1px solid var(--border-color-default);
}
.add-to-workspace-modal__contents .add-to-workspace-modal__choose-entities {
  margin-bottom: 20px;
}
.add-to-workspace-modal__contents .add-to-workspace-modal__contents-box {
  height: 550px;
}
.add-to-workspace-modal__contents .add-to-workspace__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add-to-workspace-modal__contents .add-to-workspace--helptext {
  margin-bottom: 20px;
}
.add-to-workspace-modal__contents .add-to-workspace-modal__entities-count {
  margin-top: 10px;
  color: var(--content-color-tertiary);
}
.add-to-workspace-modal__contents .add-to-workspace-modal__alert-wrapper {
  margin-top: 20px;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group:not(:last-child) {
  margin-bottom: 10px;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__label {
  margin-bottom: 7px;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__alert {
  margin-bottom: 8px;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__input {
  display: flex;
  margin-bottom: 8px;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__input.add-to-workspace__input-group--team-permissions {
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  padding: 10px;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__input .add-to-workspace__input-group__label__label {
  flex: 2;
  display: flex;
  align-items: center;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__input .add-to-workspace__input-group__input--team-permissions {
  align-self: center;
  flex: 1;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__input .add-to-workspace__input-group__input--selected-workspace .workspace-name {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__input .add-to-workspace__input-group__input--selected-workspace .dropdown-menu-item {
  justify-content: space-between;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__input .add-to-workspace__input-group__input--selected-workspace .dropdown-menu {
  max-height: 355px;
  overflow-y: auto;
  overflow-x: hidden;
}
.add-to-workspace-modal__contents .add-to-workspace__input-group .add-to-workspace__input-group__subtext {
  color: var(--content-color-tertiary);
}
.add-to-workspace-modal__contents .add-to-workspace__input-group.add-to-workspace__input-group--team-permissions, .add-to-workspace-modal__contents .add-to-workspace__input-group.add-to-workspace__input-group--user-permissions {
  /*border: 1px solid $app-border-color;
  border-radius: var(--border-radius-default);
  padding: 10px;*/
}
.add-to-workspace-modal__contents .add-to-workspace__create-team {
  margin-top: 32px;
}

.add-to-workspace__input-group__input--selected-workspace .workspace-name {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
.add-to-workspace__input-group__input--selected-workspace .dropdown-menu-item {
  justify-content: space-between;
}
.add-to-workspace__input-group__input--selected-workspace .dropdown-menu {
  max-height: 355px;
  overflow-y: auto;
  overflow-x: hidden;
}

.workspace-details-modal .modal-header {
  background-color: var(--background-color-primary);
}
.workspace-details-modal .modal-header .modal-header-close-button-wrapper {
  margin-top: 30px;
  margin-right: 20px;
}
.workspace-details-modal .modal-content {
  padding: 30px;
  padding-top: 0;
  overflow: hidden;
}
.workspace-details-modal .workspace-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.workspace-details-modal .workspace-details .workspace-details__name {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: var(--content-color-primary);
  margin-bottom: 15px;
}
.workspace-details-modal .workspace-details .workspace-details__summary {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: var(--content-color-primary);
  margin-bottom: 8px;
  line-height: 24px;
}
.workspace-details-modal .workspace-details .workspace-details__owner,
.workspace-details-modal .workspace-details .workspace-details__last-updated,
.workspace-details-modal .workspace-details .workspace-details__member-count {
  display: flex;
  align-items: center;
  flex: 0 0 40px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.workspace-details-modal .workspace-details .workspace-details__owner .workspace-details__owner__icon,
.workspace-details-modal .workspace-details .workspace-details__last-updated .workspace-details__owner__icon,
.workspace-details-modal .workspace-details .workspace-details__member-count .workspace-details__owner__icon {
  margin-right: 15px;
}
.workspace-details-modal .workspace-details .workspace-details__owner .workspace-details__last-updated__icon,
.workspace-details-modal .workspace-details .workspace-details__last-updated .workspace-details__last-updated__icon,
.workspace-details-modal .workspace-details .workspace-details__member-count .workspace-details__last-updated__icon {
  margin-right: 15px;
}
.workspace-details-modal .workspace-details .workspace-details__owner .workspace-details__member-count__icon,
.workspace-details-modal .workspace-details .workspace-details__last-updated .workspace-details__member-count__icon,
.workspace-details-modal .workspace-details .workspace-details__member-count .workspace-details__member-count__icon {
  margin-right: 15px;
}
.workspace-details-modal .workspace-details .workspace-details__owner .workspace-details__owner__owner,
.workspace-details-modal .workspace-details .workspace-details__owner .workspace-details__last-updated__last-updated,
.workspace-details-modal .workspace-details .workspace-details__owner .workspace-details__member-count__member-count,
.workspace-details-modal .workspace-details .workspace-details__last-updated .workspace-details__owner__owner,
.workspace-details-modal .workspace-details .workspace-details__last-updated .workspace-details__last-updated__last-updated,
.workspace-details-modal .workspace-details .workspace-details__last-updated .workspace-details__member-count__member-count,
.workspace-details-modal .workspace-details .workspace-details__member-count .workspace-details__owner__owner,
.workspace-details-modal .workspace-details .workspace-details__member-count .workspace-details__last-updated__last-updated,
.workspace-details-modal .workspace-details .workspace-details__member-count .workspace-details__member-count__member-count {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 14px;
}
.workspace-details-modal .workspace-details .workspace-details__member-count {
  margin-bottom: 30px;
}
.workspace-details-modal .workspace-details .workspace-details__members {
  flex: 1;
  overflow: hidden;
}
.workspace-details-modal .workspace-details .workspace-details__members .sortable-table {
  height: 100%;
  overflow: hidden;
}
.workspace-details-modal .workspace-details .workspace-details__members .sortable-table .sortable-table__rows {
  height: calc(100% - 40px);
  overflow-y: auto;
}
.workspace-details-modal .workspace-details .workspace-details__members .sortable-table .sortable-table__rows .user__row {
  display: flex;
  align-items: center;
}
.workspace-details-modal .workspace-details .workspace-details__members .sortable-table .sortable-table__rows .user__row .user__row__details {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.workspace-details-modal .workspace-details .workspace-details__members .sortable-table .sortable-table__rows .user__row .user__row__details .user__row__details__name {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: var(--content-color-primary);
}
.workspace-details-modal .workspace-details .workspace-details__members .sortable-table .sortable-table__rows .user__row .user__row__details .user__row__details__email {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: var(--content-color-primary);
}

.offline__illustration {
  background: url("../assets/images/thumbnails/browse-view-offline.svg") no-repeat center;
  background-size: contain;
  width: 200px;
  height: 200px;
}

.share-workspace-modal {
  width: 100%;
}
.share-workspace-modal .modal-content {
  padding: var(--spacing-xl) var(--spacing-xl);
}
.share-workspace-modal .modal-footer {
  margin: unset;
}
.share-workspace-modal .add-user__list {
  height: 240px;
}
.share-workspace-modal .empty-state--no-users {
  height: 200px;
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-workspace-modal .item-roles__container {
  height: 200px;
}
.share-workspace-modal .create-new-workspace__team_info {
  margin: 6px var(--spacing-zero) var(--spacing-l) var(--spacing-zero);
}
.share-workspace-modal .create-new-workspace__input-group__label {
  margin: var(--spacing-xs) var(--spacing-zero);
  line-height: 1.67;
}
.share-workspace-modal__subtext {
  color: var(--content-color-tertiary);
}
.share-workspace-modal__subtext a {
  flex: 1;
  color: var(--content-color-link);
  cursor: pointer;
}
.share-workspace-modal__alert {
  margin-bottom: var(--spacing-l);
}
.share-workspace-modal__copy-invite-link {
  margin-right: auto;
  margin-left: var(--spacing-zero);
}
.share-workspace-modal .button-medium {
  width: 120px;
}
.share-workspace-modal .button-large {
  width: 200px;
}

.invitation-error-modal .invitation-error-modal__contents {
  display: flex;
  flex-direction: column;
}
.invitation-error-modal .invitation-error-modal__contents .invitation-error-modal__info {
  color: var(--content-color-primary);
}
.invitation-error-modal .invitation-error-modal__contents .invitation-error-modal__help {
  color: var(--content-color-primary);
  margin-top: 16px;
}

.workspace-actions-dropdown .btn {
  height: 30px;
  background-color: transparent;
}

.invite-user-modal .empty-state--no-users {
  height: 200px;
  color: var(--content-color-secondary);
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.invite-user-modal .create-new-workspace__input-group {
  margin-top: 16px;
}
.invite-user-modal .modal-content {
  padding: 20px;
  overflow-y: auto;
  max-height: 75vh;
}
.invite-user-modal .modal-content .invite-user-modal__email-privileges-denied {
  margin: 16px 0;
  color: var(--base-color-error);
}
.invite-user-modal .modal-content .invite-user-workspace-meta-title {
  color: var(--content-color-primary);
  margin-bottom: 16px;
}
.invite-user-modal .modal-content .invite-user-workspace-meta-text {
  color: var(--content-color-tertiary);
  margin-top: 16px;
  margin-bottom: 16px;
}
.invite-user-modal .modal-content .invite-user-modal-alert {
  margin-top: 8px;
}
.invite-user-modal__copy-invite-link {
  margin-right: auto;
  margin-left: var(--spacing-zero);
}
.invite-user-modal .button-small {
  width: 100px;
}
.invite-user-modal .button-medium {
  width: 120px;
}
.invite-user-modal .button-large {
  width: 200px;
}

.invitation-sent-text-meta {
  margin-top: 32px;
  margin-left: 16px;
  color: var(--content-color-secondary);
}

.invitation-sent-info-meta {
  margin-top: 80px;
  margin-left: 16px;
  color: var(--content-color-secondary);
}

.setup-team-name-meta {
  margin-top: 32px;
  margin-left: 16px;
  color: var(--content-color-tertiary);
}

.setup-team-url-meta {
  margin-top: 50px;
  margin-left: 16px;
  color: var(--content-color-tertiary);
}

.share-entity-model .setup-team-model-content {
  padding: 16px;
}

.invite-user-alert-container {
  margin-bottom: 8px;
}

.invite-user-modal__team_plan__warning .alert {
  background-color: var(--background-color-warning);
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xl);
}
.invite-user-modal__team_plan__error .alert {
  background-color: var(--background-color-error);
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xl);
}

.share-entity-model .modal-content {
  overflow-y: hidden;
}
.share-entity-model .modal-content .create-new-modal__content {
  height: 100%;
}
.share-entity-model .tabs {
  padding: 10px 0px 0px 0px;
  border-bottom: 1px solid var(--border-color-default);
}
.share-entity-model .tabs .tab {
  cursor: default;
}

.share-entity-tab-content {
  padding: 16px;
  max-height: 60vh;
  overflow-y: auto;
}
.share-entity-tab-content .tab-share-content .tab-share-content-meta-text {
  color: var(--content-color-primary);
  font-size: 12px;
}

.select-workspace-entity-container .tabs {
  width: 50%;
}

.share-collections-content-container {
  padding: 8px 0px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.share-collections-content-container .loading-indicator-wrapper {
  text-align: center;
}
.share-collections-content-container .add-to-workspace-empty--container {
  width: 400px;
  display: flex;
  align-self: center;
}
.share-collections-content-container .workspace-selector-container {
  display: flex;
  flex: 100%;
  justify-content: space-between;
}
.share-collections-content-container .workspace-selector-container .workspace-selector-dropdown-container {
  display: flex;
  flex: 50%;
}
.share-collections-content-container .workspace-selector-container .workspace-selector-dropdown-container .workspace-selector-value {
  display: flex;
  flex: 50%;
}
.share-collections-content-container .workspace-selector-container .workspace-selector-dropdown-container .workspace-selector-meta-container {
  flex: 50%;
  display: flex;
  align-items: center;
  align-content: center;
}
.share-collections-content-container .workspace-selector-container .workspace-selector-dropdown-container .workspace-selector-meta-container .workspace-selector-meta {
  padding-left: 24px;
  color: var(--content-color-tertiary);
}
.share-collections-content-container .workspace-selector-container .workspace-selector {
  display: flex;
  flex: 50%;
  flex-direction: column;
  padding: 5px 0;
}
.layout-2-column .share-collections-content-container .workspace-selector-container .workspace-selector {
  flex: 1;
}
.share-collections-content-container .workspace-selector-container .workspace-selector .workspace-selector-label {
  padding-bottom: 5px;
  font-size: 11px;
  color: var(--content-color-primary);
  font-weight: 600;
}
.share-collections-content-container .workspace-selector-container .workspace-selector .input-checkbox {
  margin-right: 10px;
}
.share-collections-content-container .dropdown {
  width: 100%;
}
.share-collections-content-container .dropdown .dropdown-button {
  width: 100%;
}
.share-collections-content-container .dropdown .dropdown-button .btn {
  width: 100%;
  justify-content: space-between;
}
.share-collections-content-container .tab-share-content--subtab-entity {
  display: flex;
  flex: 100%;
}
.share-collections-content-container .tab-share-content--subtab-entity .entity-list-container-wrapper {
  height: 180px;
  display: flex;
  flex: 0 50%;
}
.share-collections-content-container .tab-share-content--subtab-entity .entity-list-container-wrapper .entity-list-container {
  height: 150px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color-default);
  margin-top: 8px;
  padding: 8px;
}
.share-collections-content-container .tab-share-content--subtab-entity .entity-list-container-wrapper .entity-list-container .entity-checkbox-item-container {
  flex: 1 0 20px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
}
.share-collections-content-container .tab-share-content--subtab-entity .entity-list-container-wrapper .entity-list-container .entity-checkbox-item-container .entity-checkbox-item-meta {
  margin-left: 8px;
}
.share-collections-content-container .tab-share-content--subtab-entity .entity-list-container-wrapper .entity-list-container .entity-checkbox-item-container:nth-child(1) {
  margin-top: 0px;
}
.share-collections-content-container .tab-share-content--subtab-entity .entity-selection-meta-container {
  display: flex;
  flex: 0 50%;
  align-items: center;
  align-content: center;
}
.share-collections-content-container .tab-share-content--subtab-entity .entity-selection-meta-container .entity-selection-meta {
  color: var(--content-color-tertiary);
  padding-left: 16px;
}
.share-collections-content-container .share-collections-content-container_warning-text {
  align-self: flex-end;
}

.setup-team-content .tab-share-content-meta-text {
  color: var(--content-color-primary);
  font-size: 12px;
}

.setup-team-container {
  margin-top: 24px;
  display: flex;
}
.setup-team-container .tab-share-content-meta-text {
  color: var(--content-color-primary);
  font-size: 12px;
}
.setup-team-container .left {
  flex: 50%;
}
.setup-team-container .right {
  flex: 50%;
}
.setup-team-container .input-container {
  margin-bottom: 24px;
}
.setup-team-container .input-container .input-label {
  font-weight: 600;
}
.setup-team-container .input-field {
  margin-top: 8px;
}

.entity-empty-text {
  display: block;
  min-height: 81px;
  color: var(--content-color-tertiary);
}

.setup-team-input-container {
  display: flex;
}
.setup-team-input-container .input-field {
  flex: 80%;
}
.setup-team-input-container .setup-team-input-meta-container {
  display: flex;
  align-items: center;
  align-content: center;
}
.setup-team-input-container .setup-team-input-meta {
  padding-left: 8px;
}

.team-setup-container {
  padding: 20px;
}

.publish-docs-signed-out-modal-content .publish-docs-signed-out-learn-more {
  padding: 10px 0;
  margin: 16px 0;
}

.edit-workspace-modal .modal-content {
  overflow-y: auto;
  max-height: 75vh;
}
.edit-workspace-modal .modal-content .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
}
.edit-workspace-modal .empty-state--no-users {
  height: 200px;
  color: var(--content-color-secondary);
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-workspace-modal .edit-workspace-modal__contents {
  display: flex;
  flex-direction: column;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group:not(:last-child) {
  margin-bottom: 10px;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__label {
  margin-bottom: 7px;
  display: flex;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__left-label {
  flex: 1;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__add-slots {
  padding-right: 0px;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__team-size {
  color: var(--content-color-secondary);
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input {
  display: flex;
  margin-bottom: 10px;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .is-disabled {
  cursor: not-allowed;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .plugin__workspace-view-switcher__wrapper {
  display: flex;
  justify-content: center;
  height: 32px;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  height: 28px;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-active-item {
  align-self: center;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .plugin__workspace-view-switcher__disable {
  opacity: 0.5;
  cursor: default;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .edit-workspace__input-group__input--type {
  flex: 1;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .textarea-wrapper {
  flex: 1;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__input .edit-workspace__input-group__input__edit-text-area {
  margin-bottom: 10px;
}
.edit-workspace-modal .edit-workspace-modal__contents .edit-workspace__input-group .edit-workspace__input-group__subtext {
  color: var(--content-color-tertiary);
}
.edit-workspace-modal .edit-workspace__email-privileges-denied {
  margin-bottom: 16px;
  margin-top: 8px;
  color: var(--base-color-error);
}
.edit-workspace-modal .edit-workspace__team_info {
  color: var(--content-color-tertiary);
}
.edit-workspace-modal .button-small {
  width: 100px;
}
.edit-workspace-modal .button-medium {
  width: 120px;
}
.edit-workspace-modal .button-large {
  width: 200px;
}

.workspace-visibility--title {
  color: var(--content-color-primary);
  font-weight: 600;
  font-size: 12px;
  margin: unset;
  margin-top: 16px;
  user-select: none;
}

.workspace__enterprise_visibility--disabled {
  opacity: 0.5;
  display: flex;
  margin-top: 16px;
  width: fit-content;
}
.workspace__enterprise_visibility--disabled .workspace__enterprise_info {
  margin-left: 8px;
  color: var(--content-color-primary);
  user-select: none;
  cursor: default;
}
.workspace__enterprise_visibility--disabled .input-checkbox:hover .pm-icon-normal {
  opacity: 0;
}

.workspace__enterprise_visibility {
  display: flex;
  margin-top: 8px;
}
.workspace__enterprise_visibility .workspace__enterprise_info {
  margin-left: 8px;
  color: var(--content-color-primary);
  user-select: none;
  cursor: default;
}

.workspace__enterprise-tooltip {
  margin-left: 5px;
}

.workspace-visibility-team {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  color: var(--content-color-primary);
}
.workspace-visibility-team .workspace-visibility-team--name {
  margin-left: 10px;
}
.workspace-visibility-team .pm-icon {
  display: flex;
  justify-content: center;
  padding: 2px;
}
.workspace-visibility-team--roles .dropdown {
  max-width: 140px;
}
.workspace-visibility-team--roles .dropdown-button .btn {
  background: unset;
  color: var(--content-color-secondary);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 0;
}
.workspace-visibility-team--roles .dropdown-button span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.workspace-visibility-v2 {
  width: 100%;
}
.workspace-visibility-v2--pending-request {
  padding: var(--spacing-l);
  margin-bottom: var(--spacing-m);
  color: var(--content-color-primary);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-info);
}
.workspace-visibility-v2--pending-request-heading {
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
}
.workspace-visibility-v2--pending-request > p:last-child {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-zero);
}
.workspace-visibility-v2--pending-request > p:last-child a {
  text-decoration: none;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.workspace-visibility-v2--pending-request > p:last-child a:hover {
  color: var(--content-color-link);
}
.workspace-visibility-v2--title {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  margin: unset;
  user-select: none;
  margin-bottom: var(--spacing-s);
}
.workspace-visibility-v2--title.with-subtitle {
  margin-bottom: var(--spacing-zero);
}
.workspace-visibility-v2--title-helper {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-s);
  margin: unset;
  user-select: none;
}
.workspace-visibility-v2--container {
  display: grid;
  row-gap: var(--spacing-xs);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-m);
}
.workspace-visibility-v2--subtitle {
  margin-left: 28px;
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.workspace-visibility-v2--roles {
  margin-left: 28px;
  display: inline-block;
  color: var(--content-color-secondary);
}
.workspace-visibility-v2--roles--dropdown-button {
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-zero) var(--spacing-xs);
  height: var(--size-x);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.workspace-visibility-v2--actions {
  margin-top: var(--spacing-l);
}
.workspace-visibility-v2--actions-create-team-btn {
  width: calc(8 * var(--size-xs));
  margin-right: var(--spacing-s);
}
.workspace-visibility-v2--actions-cancel-btn {
  background: none;
}
.workspace-visibility-v2--dropdown-menu .pm-icon {
  margin-right: var(--spacing-m);
}
.workspace-visibility-v2--dropdown-button {
  display: flex;
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-zero);
  height: var(--size-x);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.workspace-visibility-v2--dropdown-button .pm-icon {
  margin-right: var(--spacing-m);
}
.workspace-visibility-v2--beta-label {
  font-size: var(--text-size-xs);
  color: var(--content-color-info);
  font-weight: var(--text-weight-medium);
  position: relative;
  bottom: var(--spacing-xs);
  left: var(--spacing-xs);
}
.workspace-visibility-v2--info {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-m);
  line-height: var(--line-height-m);
  color: var(--content-color-tertiary);
}
.workspace-visibility-v2--info a {
  color: var(--content-color-link);
}
.workspace-visibility-v2--info a:hover {
  text-decoration: underline;
}
.workspace-visibility-v2--info .pm-icon {
  margin-right: var(--spacing-s);
}
.workspace-visibility-v2--info .pm-icon use {
  fill: var(--content-color-tertiary);
}
.workspace-visibility-v2--info .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.workspace-visibility-v2--info .pm-icon svg {
  color: var(--content-color-tertiary);
}
.workspace-visibility-v2--error {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-m);
  color: var(--content-color-error);
}
.workspace-visibility-v2--error .pm-icon {
  margin-right: var(--spacing-s);
}
.workspace-visibility-v2--error .pm-icon use {
  fill: var(--content-color-error);
}
.workspace-visibility-v2--error .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.workspace-visibility-v2--error .pm-icon svg {
  color: var(--content-color-error);
}
.workspace-visibility-v2--error a {
  margin-left: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-error);
  text-decoration: underline;
  cursor: pointer;
}
.workspace-visibility-v2--footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--spacing-l);
}
.workspace-visibility-v2--footer .btn:first-child {
  margin-right: var(--spacing-xl);
}

.invite-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  max-height: 80px;
}
.invite-container__teamPlan_alert {
  border: 1px solid var(--base-color-error);
}
.invite-container__empty-state--with-alert {
  font-size: var(--text-size-m);
  line-height: 20px;
  color: var(--content-color-error);
  margin-left: var(--spacing-xxl);
  margin-top: var(--spacing-s);
}
.invite-container .invite-placeholder {
  margin-left: 8px;
  position: absolute;
  color: var(--content-color-tertiary);
}
.invite-container .active-input {
  flex: 1;
  padding-left: 8px;
}

.user-suggestion {
  height: 56px;
}
.user-suggestion__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-suggestion .avatar {
  margin-right: 8px;
}
.user-suggestion .user-suggestion__group-tag {
  width: var(--size-s);
  height: var(--size-s);
  margin-right: var(--spacing-s);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}
.user-suggestion .user-suggestion-details {
  height: 100%;
  width: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.user-suggestion .user-suggestion-details .user-suggestion__email {
  margin-top: 4px;
  color: var(--content-color-secondary);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.sync-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}
.sync-status .sync-status-badge__icon svg {
  color: var(--content-color-secondary);
}
.sync-status:hover .sync-status-badge__icon svg {
  color: var(--content-color-primary);
}
.sync-status:hover .sync-status-badge > span {
  color: var(--content-color-primary);
}

@media only screen and (max-width: 1024px) {
  .sync-status-badge > span {
    display: none;
  }
}
.sync-button use {
  fill: var(--content-color-constant);
}
.sync-button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.sync-button svg {
  color: var(--content-color-constant);
}
.sync-button.sync-off {
  background-color: #b94a48;
}
.sync-button.sync-off:hover, .sync-button.sync-off.is-hovered {
  background-color: #ed4b48;
}
.sync-button.offline {
  background-color: #5a5a5a;
}
.sync-button.offline:hover, .sync-button.offline.is-hovered {
  background-color: #6e6e6e;
}
.sync-button.in-sync {
  background-color: #f47023;
}
.sync-button.error {
  background-color: #ED4B48;
}
.sync-button.syncing, .sync-button.connecting {
  background-color: #097bed;
}
.sync-button.syncing .pm-icon-sync, .sync-button.connecting .pm-icon-sync {
  animation: rotate 1s infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(-180deg);
  }
}

.sync-button-icon {
  display: block;
  width: 22px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/sync_normal.svg");
}

.sync-status-tooltip {
  z-index: 90;
}
.sync-status-tooltip .tooltip-wrapper {
  background-color: var(--background-color-primary);
  padding: var(--spacing-l);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.sync-status-tooltip-wrapper {
  max-width: 320px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.sync-status-tooltip-wrapper .sync-status-offline-icon use {
  fill: var(--content-color-primary);
}
.sync-status-tooltip-wrapper .sync-status-offline-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.sync-status-tooltip-wrapper .sync-status-offline-icon svg {
  color: var(--content-color-primary);
}
.sync-status-tooltip-wrapper .sync-status-success-icon use {
  fill: var(--content-color-success);
}
.sync-status-tooltip-wrapper .sync-status-success-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.sync-status-tooltip-wrapper .sync-status-success-icon svg {
  color: var(--content-color-success);
}
.sync-status-tooltip-wrapper .sync-status-error-icon use {
  fill: var(--content-color-error);
}
.sync-status-tooltip-wrapper .sync-status-error-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.sync-status-tooltip-wrapper .sync-status-error-icon svg {
  color: var(--content-color-error);
}
.sync-status-tooltip-wrapper .sync-error-tooltip-heading {
  color: var(--content-color-error);
}
.sync-status-tooltip-wrapper .sync-status-tooltip-header {
  font-size: 12px;
  display: inline;
  margin: 0 0 0 8px;
  vertical-align: top;
}
.sync-status-tooltip-wrapper .sync-status-tooltip-header__online {
  color: var(--content-color-success);
}
@media only screen and (max-width: 768px) {
  .sync-status-tooltip-wrapper .sync-status-tooltip-header {
    display: none;
  }
}
.sync-status-tooltip-wrapper .sync-status-tooltip-title {
  font-weight: 800;
  margin-bottom: 8px;
}
.sync-status-tooltip-wrapper .sync-status-tooltip-description {
  display: block;
  margin-top: 8px;
  font-size: 12px;
}
.sync-status-tooltip-wrapper .sync-status-tooltip-button {
  margin-top: 16px;
}

.sync-error-modal {
  max-height: 330px;
  scroll-behavior: auto;
}
.sync-error-modal .sync-error-modal--error-icon use {
  fill: var(--content-color-error);
}
.sync-error-modal .sync-error-modal--error-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.sync-error-modal .sync-error-modal--error-icon svg {
  color: var(--content-color-error);
}
.sync-error-modal__title {
  font-size: 14px;
  font-weight: 600;
  display: inline;
  margin-left: 8px;
  position: relative;
  bottom: 2px;
  color: var(--content-color-error);
}
.sync-error-modal__text {
  margin-top: 8px;
  color: var(--content-color-primary);
}
.sync-error-modal__element {
  margin-left: 8px;
}
.sync-error-modal__element-title {
  margin-top: 16px;
  margin-bottom: 16px;
  color: var(--content-color-primary);
  font-weight: 600;
}
.sync-error-modal__element-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  max-width: 200px;
  color: var(--content-color-primary);
}
.sync-error-modal__support-text {
  color: var(--content-color-error);
  margin-left: 8px;
  margin-bottom: 6px;
}
.sync-error-modal-retry {
  margin-left: 8px;
  margin-top: 16px;
}

.sync-status-badge {
  cursor: default;
}
.sync-status-badge__error, .sync-status-badge__off {
  background-color: var(--background-color-error);
}
.sync-status-badge__connecting {
  background-color: var(--background-color-warning);
}
.sync-status-badge__offline {
  background-color: var(--background-color-tertiary);
}
.sync-status-badge__insync, .sync-status-badge__syncing {
  background-color: var(--background-color-success);
}
.sync-status-badge__text {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  margin-right: var(--spacing-s);
  margin-top: var(--spacing-xs);
}
.sync-status-badge__text-error, .sync-status-badge__text-off {
  color: var(--content-color-error);
}
.sync-status-badge__text-offline {
  color: var(--content-color-secondary);
}
.sync-status-badge__text-connecting {
  color: var(--content-color-warning);
}
.sync-status-badge__text-insync, .sync-status-badge__text-syncing {
  color: var(--content-color-success);
}
.sync-status-badge__icon {
  vertical-align: middle;
  margin-right: var(--spacing-xs);
}

.sync-status-wrapper {
  z-index: 1;
}

.sync-online-icon__syncing {
  animation: rotate 1s infinite;
}
.sync-online-icon__syncing svg {
  color: var(--content-color-success);
}
.sync-online-icon__syncing svg path {
  fill: var(--content-color-success);
}
.sync-online-icon__insync svg path {
  fill: var(--content-color-success);
}
@keyframes rotate {
  100% {
    transform: rotate(-180deg);
  }
}

.sync-offline-icon__dark svg path {
  fill: var(--content-color-primary);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.sync-conflict {
  overflow-x: auto;
}
.sync-conflict-modal__tabs {
  border-bottom: 1px solid var(--border-color-strong);
  width: 100%;
  overflow-x: auto;
}
.sync-conflict-modal__tabs::-webkit-scrollbar {
  display: none;
}
.sync-conflict-modal__tab {
  position: relative;
  padding-bottom: 0;
  padding-left: 0;
  max-width: 200px;
  margin-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sync-conflict-modal__tab:first-child {
  margin-left: 20px;
}
.sync-conflict-modal__content {
  padding-top: 4px;
  padding-bottom: 32px;
  overflow-y: hidden;
  padding-left: 0;
  padding-right: 0;
}
.sync-conflict-modal__entity-name {
  display: inline-block;
  max-width: 170px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sync-conflict__model-icon {
  float: left;
  vertical-align: middle;
}
.sync-conflict__model-icon-disabled {
  opacity: 0.2;
}
.sync-conflict-badge {
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
  height: 16px;
  min-width: 16px;
  margin-left: 8px;
  padding: 0px;
  font-weight: 600;
  pointer-events: none;
  position: absolute;
  right: 0;
}
.sync-conflict-badge-disabled {
  background-color: rgba(236, 236, 236, 0.6);
  color: rgba(80, 80, 80, 0.5);
}
.sync-conflict__conflict-badge {
  margin-left: 8px;
  background-color: var(--base-color-warning);
  border-radius: var(--border-radius-default);
  font-size: 11px;
  color: var(--content-color-constant);
  padding: 0px 6px;
  height: 16px;
  font-weight: 600;
}
.sync-conflict__conflict-wrapper {
  margin-right: 4px;
  margin-top: 22px;
}
.sync-conflict__isLoading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.sync-conflict-footer-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 10px 20px 0px;
}

.sync-conflict-body {
  overflow-y: auto;
  max-height: 100%;
  scroll-behavior: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
}

.sync-conflict-tooltip {
  display: flex;
}

.sync-conflict-info {
  color: var(--base-color-brand);
  margin-top: 22px;
}

.sync-conflict-wrapper {
  display: flex;
  align-items: flex-start;
}

.sync-conflict-data-column {
  padding-right: 10px;
}

.sync-conflict-data-header {
  display: flex;
  width: 135px;
  min-height: 45px;
  font-weight: 600;
  align-items: center;
}

.sync-conflict-data-value {
  width: 135px;
  padding: 10px 5px 0 0;
  word-break: break-word;
}

.sync-conflict-checkbox-wrapper {
  display: flex;
  align-items: flex-start;
}

.sync-conflict-checkbox {
  flex: 0 0 16px;
  margin-right: 10px;
}

.sync-conflict-footer {
  display: flex;
  flex-direction: row-reverse;
}
.sync-conflict-footer .btn.is-focused {
  box-shadow: var(--shadow-focus);
}

.sync-conflict-resolve {
  margin-left: 8px;
  width: 280px;
}

.sync-conflict-item__property {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: var(--content-color-tertiary);
  display: inline-block;
}
.sync-conflict-item__request {
  display: inline;
}
.sync-conflict-item__request-method {
  display: inline;
  font-size: 14px;
  margin-right: 8px;
}
.sync-conflict-item-body {
  padding-left: 16px;
  border-left: 1px solid var(--border-color-strong);
  margin-left: 3px;
  margin-top: 24px;
}
.sync-conflict-item-body > div {
  margin-top: 16px;
}
.sync-conflict-item-name {
  color: var(--content-color-primary);
}
.sync-conflict-item-wrapper:not(:first-child) {
  margin-top: 16px;
}

.sync-conflict-radio-selector {
  display: inline;
  margin-left: 16px;
  vertical-align: middle;
}

.sync-issue-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}
.sync-issue-container .sync-issue-content-left {
  flex: 0 0 271px;
  margin-right: 20px;
}
.sync-issue-container .sync-issue-content-right {
  flex: 1;
}
.sync-issue-container .sync-issue-button {
  width: 100%;
}

.sync-issue-detailed-log-container {
  padding-bottom: 20px;
}
.sync-issue-detailed-log-container .sync-issue-detailed-log-head-text {
  padding-bottom: 10px;
}

.sync-offline-status-bar {
  min-height: 32px;
  line-height: 18px;
}
.sync-offline-status-bar .infobar__icon-wrapper {
  display: none;
}

.workspace-switcher__list .sync-offline-status-bar {
  height: auto;
}

.missing-current-workspace-modal {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.missing-current-workspace-modal .missing-current-workspace-modal__wrapper {
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.missing-current-workspace-modal .missing-current-workspace-thumbnail__container {
  height: 200px;
  width: 200px;
}
.missing-current-workspace-modal .missing-current-workspace-thumbnail__container .missing-current-workspace-thumbnail {
  height: inherit;
  width: inherit;
  background: url("../assets/images/thumbnails/browse-view-offline.svg") no-repeat center;
  background-size: contain;
}
.missing-current-workspace-modal .missing-current-workspace-modal__content {
  margin-top: 10px;
}
.missing-current-workspace-modal .missing-current-workspace-modal__header {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  opacity: 0.8;
}
.missing-current-workspace-modal .missing-current-workspace-modal__subtext {
  font-size: 12px;
  margin: 10px 0 30px;
  opacity: 0.6;
  text-align: center;
}
.missing-current-workspace-modal .missing-current-workspace-modal__action {
  display: flex;
  justify-content: center;
}
.missing-current-workspace-modal .modal-header-close-button {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.6;
}

.team-usage-container {
  border-top: 1px solid var(--border-color-default);
  border-bottom: 1px solid var(--border-color-default);
  padding: var(--spacing-l) 0 var(--spacing-xl);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
}
.team-usage-container .team-usage-item {
  margin-bottom: 10px;
}
.team-usage-container .team-usage-item .team-usage-item-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.team-usage-container .team-usage-item .team-usage-item-header .team-usage-item-title {
  font-size: var(--text-size-m);
  line-height: 20px;
  color: var(--content-color-primary);
}
.team-usage-container .team-usage-item .team-usage-item-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.team-usage-container .team-usage-item .team-usage-item-body .team-usage-item-meta {
  font-size: var(--text-size-m);
  position: absolute;
  right: 0;
  display: flex;
  width: 35%;
  flex-flow: wrap;
  justify-content: flex-end;
  color: var(--content-color-secondary);
}
.team-usage-container .team-usage-item .team-usage-item-body .team-usage-item-meta .success {
  color: var(--base-color-success);
}
.team-usage-container .team-usage-item .team-usage-item-body .team-usage-item-meta .warning {
  color: var(--base-color-warning);
}
.team-usage-container .team-usage-item .team-usage-item-body .team-usage-item-meta .error {
  color: var(--base-color-error);
}
.team-usage-container .team-usage-item .team-usage-item-body__progress-indicator {
  background-color: var(--content-color-tertiary);
}
.team-usage-container .team-usage-item .team-usage-item-body__progress-container {
  width: 60%;
}
.team-usage-container .team-usage-item:last-child {
  margin-bottom: 0px;
}

.team-usage-loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.team-usage-loading-wrapper {
  position: relative;
  margin: 0 var(--spacing-l) var(--spacing-s);
}

.team-usage-loading-wrapper.is-loading {
  opacity: 0.5;
}

.team-usage-error {
  border-top: 1px solid var(--border-color-default);
  border-bottom: 1px solid var(--border-color-default);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-usage-error-btn {
  display: flex;
  justify-content: center;
}

.team-usage-error-title {
  font-size: var(--text-size-l);
  font-weight: 600;
  color: var(--content-color-primary);
  text-align: center;
}

.team-usage-error-meta {
  font-size: var(--text-size-m);
  line-height: 20px;
  color: var(--content-color-primary);
  margin: 24px 0px 16px 0px;
  text-align: center;
}

.team-usage-item-header .info-button {
  margin-left: 8px;
}
.team-usage-item-header .info-button-v3 {
  margin-left: 8px;
  cursor: pointer;
}

.info__button-markdown_tooltip {
  color: var(--content-color-primary);
}
.info__button-markdown_tooltip h1,
.info__button-markdown_tooltip h2,
.info__button-markdown_tooltip h3,
.info__button-markdown_tooltip h4,
.info__button-markdown_tooltip h5,
.info__button-markdown_tooltip h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.info__button-markdown_tooltip h1 {
  font-size: 18px;
  font-weight: 700;
}
.info__button-markdown_tooltip h2 {
  font-size: 16px;
  font-weight: 700;
}
.info__button-markdown_tooltip h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.info__button-markdown_tooltip h4 {
  font-size: 14px;
  font-weight: 700;
}
.info__button-markdown_tooltip h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.info__button-markdown_tooltip h6 {
  font-size: 12px;
  font-weight: 600;
}
.info__button-markdown_tooltip hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.info__button-markdown_tooltip blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.info__button-markdown_tooltip blockquote blockquote {
  margin-left: 20px;
}
.info__button-markdown_tooltip p, .info__button-markdown_tooltip span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.info__button-markdown_tooltip em {
  font-style: italic;
}
.info__button-markdown_tooltip strong {
  font-weight: var(--text-weight-medium);
}
.info__button-markdown_tooltip ul, .info__button-markdown_tooltip ol {
  margin: 5px;
}
.info__button-markdown_tooltip a {
  color: var(--content-color-link);
  text-decoration: none;
}
.info__button-markdown_tooltip a:hover {
  text-decoration: underline;
}
.info__button-markdown_tooltip pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.info__button-markdown_tooltip pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.info__button-markdown_tooltip code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.info__button-markdown_tooltip table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.info__button-markdown_tooltip table th,
.info__button-markdown_tooltip table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.info__button-markdown_tooltip table th:first-child,
.info__button-markdown_tooltip table td:first-child {
  border-left: none;
}
.info__button-markdown_tooltip table th:last-child,
.info__button-markdown_tooltip table td:last-child {
  border-right: none;
}
.info__button-markdown_tooltip table th {
  border-top: none;
}
.info__button-markdown_tooltip table tr:last-child th,
.info__button-markdown_tooltip table tr:last-child td {
  border-bottom: none;
}
.info__button-markdown_tooltip img {
  max-width: 100%;
}

.alert {
  padding: 16px;
  display: flex;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  position: relative;
}
.alert a {
  font-weight: var(--text-weight-regular);
  text-decoration: underline;
}
.alert a:hover {
  color: var(--content-color-link);
}
.alert .alert-icon {
  margin: var(--spacing-xs) var(--spacing-s) var(--spacing-zero) var(--spacing-zero);
}
.alert .alert-content {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
}
.alert .alert-content .alert-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.alert .alert-content .alert-title * {
  margin: 0;
}
.alert .alert-content .alert-body {
  font-size: 12px;
  line-height: 20px;
}
.alert .alert-content .alert-body * {
  margin: 0;
}
.alert .alert-content .alert-actions .alert-primary-action {
  margin-right: 16px;
}
.alert .alert-content-shrink {
  padding-right: 30px;
}
.alert .alert-actions {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alert .alert-dismiss {
  width: 48px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0px 4px 4px 0px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.alert .alert-dismiss:hover,
.alert .alert-dismiss .alert-close:hover {
  cursor: pointer;
}

.alert-info {
  background-color: var(--background-color-secondary);
}

.alert-success {
  background-color: var(--background-color-success);
}

.alert-error {
  background-color: var(--background-color-error);
}

.alert-warn {
  background-color: var(--background-color-warning);
}

.alert-notification {
  background-color: var(--background-color-info);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.toast {
  z-index: 1000;
  padding: var(--spacing-l);
  display: flex;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  max-width: 400px;
  background-color: var(--background-color-secondary);
  color: var(--content-color-primary);
  position: relative;
}
.toast a {
  color: var(--content-color-link, #097BED);
  text-decoration: none;
}
.toast .toast-icon {
  margin: 2px 8px 0px auto;
  flex-shrink: 0;
}
.toast .toast-content {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
}
.toast .toast-content .toast-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.toast .toast-content .toast-title * {
  margin: 0;
}
.toast .toast-content .toast-body {
  font-size: 12px;
  line-height: 20px;
}
.toast .toast-content .toast-body * {
  margin: 0;
}
.toast .toast-content .toast-actions .toast-primary-action {
  margin-right: 16px;
}
.toast .toast-content .toast-actions .toast-secondary-action-only.btn-text {
  padding-left: 0px;
}
.toast .toast-content-shrink {
  padding-right: var(--spacing-xxl);
}
.toast .toast-actions {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.toast .toast-dismiss {
  width: var(--spacing-xxxl);
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0px 4px 4px 0px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.toast .toast-dismiss:hover,
.toast .toast-dismiss .toast-close:hover {
  cursor: pointer;
  background: var(--highlight-background-color-secondary);
}

.toast-info {
  border-left: 4px solid var(--background-color-tertiary);
}

.toast-success {
  border-left: 4px solid var(--base-color-success);
}

.toast-error {
  border-left: 4px solid var(--base-color-error);
}

.toast-warn {
  border-left: 4px solid var(--base-color-warning);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.tooltip-popover .tippy-content {
  border: solid 1px var(--base-color-brand);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.tooltip-popover .arrow {
  width: 0.9rem;
  height: 0.46rem;
  filter: none;
}
.tooltip-popover .arrow::after {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.tooltip-popover.bs-tooltip-top .arrow, .tooltip-popover.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
  margin-left: 1.1rem;
}
.tooltip-popover.bs-tooltip-top .arrow::before, .tooltip-popover.bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.6rem 0.6rem 0;
  margin-left: -0.6rem;
  border-top-color: var(--base-color-brand);
}
.tooltip-popover.bs-tooltip-top .arrow::after, .tooltip-popover.bs-tooltip-auto[x-placement^=top] .arrow::after {
  top: 0;
  border-width: 0.5rem 0.5rem 0;
  margin-left: -0.5rem;
  border-top-color: var(--background-color-secondary);
}
.tooltip-popover.bs-tooltip-right .arrow, .tooltip-popover.bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
  margin-top: 1.1rem;
}
.tooltip-popover.bs-tooltip-right .arrow::before, .tooltip-popover.bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: -1px;
  border-width: 0.6rem 0.6rem 0.6rem 0;
  margin-top: -0.6rem;
  border-right-color: var(--base-color-brand);
}
.tooltip-popover.bs-tooltip-right .arrow::after, .tooltip-popover.bs-tooltip-auto[x-placement^=right] .arrow::after {
  right: -1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  margin-top: -0.5rem;
  border-right-color: var(--background-color-secondary);
}
.tooltip-popover.bs-tooltip-bottom .arrow, .tooltip-popover.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
  margin-left: 1.1rem;
}
.tooltip-popover.bs-tooltip-bottom .arrow::before, .tooltip-popover.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.6rem 0.6rem;
  margin-left: -0.6rem;
  border-bottom-color: var(--base-color-brand);
}
.tooltip-popover.bs-tooltip-bottom .arrow::after, .tooltip-popover.bs-tooltip-auto[x-placement^=bottom] .arrow::after {
  bottom: 0;
  border-width: 0 0.5rem 0.5rem;
  margin-left: -0.5rem;
  border-bottom-color: var(--background-color-secondary);
}
.tooltip-popover.bs-tooltip-left .arrow, .tooltip-popover.bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
  margin-top: 1.1rem;
}
.tooltip-popover.bs-tooltip-left .arrow::before, .tooltip-popover.bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: -1px;
  border-width: 0.6rem 0 0.6rem 0.6rem;
  margin-top: -0.6rem;
  border-left-color: var(--base-color-brand);
}
.tooltip-popover.bs-tooltip-left .arrow::after, .tooltip-popover.bs-tooltip-auto[x-placement^=left] .arrow::after {
  left: -1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  margin-top: -0.5rem;
  border-left-color: var(--background-color-secondary);
}

.popover {
  min-height: 50px;
  min-width: 100px;
  max-width: 300px;
  margin: 8px;
  cursor: default;
  color: var(--content-color-primary);
}
.popover .popover-step {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 11px;
}
.popover .popover-step .popover-step-title {
  color: var(--base-color-success);
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 20px;
}
.popover .popover-step .popover-step-title .pie-progress-wrapper {
  margin-right: 10px;
}
.popover .popover-step .popover-dismiss {
  cursor: pointer;
  align-self: center;
  color: var(--content-color-secondary);
}
.popover .popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popover .popover-header .popover-header-title {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
}
.popover .popover-header .popover-header-title > span {
  overflow: hidden;
}
.popover .popover-header .popover-header-title .popover-dismiss {
  padding: 3px 0 0 16px;
}
.popover .popover-body {
  margin-top: 8px;
  line-height: 20px;
}
.popover .popover-body .popover-string {
  margin-top: 8px;
}
.popover .popover-body .popover-markdown-label * {
  margin: 0;
}
.popover .popover-body .popover-markdown-label code {
  flex: 1;
  display: flex;
  background: var(--background-color-tertiary);
  border-radius: 2px;
  padding: 5px;
  word-break: break-all;
  margin-top: 10px;
  user-select: all;
}
.popover .popover-body .popover-markdown-label h3 {
  color: var(--content-color-secondary);
  font-weight: 600;
  font-size: 12px;
}
.popover .popover-body .popover-markdown-label a {
  color: var(--base-color-brand);
}
.popover .popover-body .popover-select-list {
  margin-top: 8px;
  flex-shrink: 0;
}
.popover .popover-body .popover-select-list .dropdown {
  width: 100%;
  height: 30px;
}
.popover .popover-body .popover-select-list .dropdown-button .btn {
  width: 100%;
  display: flex;
}
.popover .popover-body .popover-select-list .dropdown-button .btn span {
  flex: 1;
  text-align: left;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popover .popover-body .popover-select-list .popover-select-list-title {
  font-weight: 600;
}
.popover .popover-body .popover-select-list .popover-select-list-dropdown {
  margin-top: 8px;
}
.popover .popover-body .popover-input {
  margin-top: 8px;
}
.popover .popover-body .popover-input .popover-input-title {
  font-weight: 600;
}
.popover .popover-body .popover-input .popover-input-body {
  margin-top: 8px;
}
.popover .popover-body .popover-checkbox {
  margin-top: 8px;
}
.popover .popover-body .popover-checkbox .popover-checkbox-title {
  font-weight: 600;
}
.popover .popover-body .popover-checkbox .popover-checkbox-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.popover .popover-body .popover-checkbox .popover-checkbox-label {
  margin-left: 8px;
}
.popover .popover-body .popover-radio {
  margin-top: 8px;
}
.popover .popover-body .popover-radio .popover-radio-title {
  font-weight: 600;
}
.popover .popover-body .popover-radio .popover-radio-selector-button-label {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.popover .popover-body .popover-radio .popover-radio-selector-button-label span {
  margin-left: 8px;
  flex: 1;
  text-align: left;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popover .popover-body .popover-toggle {
  margin-top: 8px;
}
.popover .popover-body .popover-toggle .popover-toggle-title {
  font-weight: 600;
}
.popover .popover-body .popover-toggle .popover-toggle-body {
  margin-top: 8px;
}
.popover .popover-actions {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}
.popover .popover-actions .popover-primary-action {
  margin-left: auto;
  background: transparent;
  color: var(--content-color-success);
}
.popover .popover-actions .popover-secondary-action {
  margin-right: auto;
  background: transparent;
}

.target-visible {
  visibility: visible;
}

.in-app-modal {
  padding: var(--spacing-zero);
}
.in-app-modal-header {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-zero);
}
.in-app-modal-content {
  padding: var(--spacing-zero) !important;
}
.in-app-modal-content-markdown {
  color: var(--content-color-primary);
}
.in-app-modal-content-markdown h1,
.in-app-modal-content-markdown h2,
.in-app-modal-content-markdown h3,
.in-app-modal-content-markdown h4,
.in-app-modal-content-markdown h5,
.in-app-modal-content-markdown h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.in-app-modal-content-markdown h1 {
  font-size: 18px;
  font-weight: 700;
}
.in-app-modal-content-markdown h2 {
  font-size: 16px;
  font-weight: 700;
}
.in-app-modal-content-markdown h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.in-app-modal-content-markdown h4 {
  font-size: 14px;
  font-weight: 700;
}
.in-app-modal-content-markdown h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.in-app-modal-content-markdown h6 {
  font-size: 12px;
  font-weight: 600;
}
.in-app-modal-content-markdown hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.in-app-modal-content-markdown blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.in-app-modal-content-markdown blockquote blockquote {
  margin-left: 20px;
}
.in-app-modal-content-markdown p, .in-app-modal-content-markdown span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.in-app-modal-content-markdown em {
  font-style: italic;
}
.in-app-modal-content-markdown strong {
  font-weight: var(--text-weight-medium);
}
.in-app-modal-content-markdown ul, .in-app-modal-content-markdown ol {
  margin: 5px;
}
.in-app-modal-content-markdown a {
  color: var(--content-color-link);
  text-decoration: none;
}
.in-app-modal-content-markdown a:hover {
  text-decoration: underline;
}
.in-app-modal-content-markdown pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.in-app-modal-content-markdown pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.in-app-modal-content-markdown code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.in-app-modal-content-markdown table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.in-app-modal-content-markdown table th,
.in-app-modal-content-markdown table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.in-app-modal-content-markdown table th:first-child,
.in-app-modal-content-markdown table td:first-child {
  border-left: none;
}
.in-app-modal-content-markdown table th:last-child,
.in-app-modal-content-markdown table td:last-child {
  border-right: none;
}
.in-app-modal-content-markdown table th {
  border-top: none;
}
.in-app-modal-content-markdown table tr:last-child th,
.in-app-modal-content-markdown table tr:last-child td {
  border-bottom: none;
}
.in-app-modal-content-markdown img {
  max-width: 100%;
}
.in-app-modal-content-center {
  text-align: center;
}
.in-app-modal-container {
  z-index: 50;
  position: relative;
}

.fullscreen-modal {
  padding: var(--spacing-zero);
}
.fullscreen-modal-header {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-zero);
}
.fullscreen-modal-content {
  box-shadow: var(--shadow-default);
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-l) var(--spacing-zero) !important;
}
.fullscreen-modal-content-markdown {
  color: var(--content-color-primary);
}
.fullscreen-modal-content-markdown h1,
.fullscreen-modal-content-markdown h2,
.fullscreen-modal-content-markdown h3,
.fullscreen-modal-content-markdown h4,
.fullscreen-modal-content-markdown h5,
.fullscreen-modal-content-markdown h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.fullscreen-modal-content-markdown h1 {
  font-size: 18px;
  font-weight: 700;
}
.fullscreen-modal-content-markdown h2 {
  font-size: 16px;
  font-weight: 700;
}
.fullscreen-modal-content-markdown h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.fullscreen-modal-content-markdown h4 {
  font-size: 14px;
  font-weight: 700;
}
.fullscreen-modal-content-markdown h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.fullscreen-modal-content-markdown h6 {
  font-size: 12px;
  font-weight: 600;
}
.fullscreen-modal-content-markdown hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.fullscreen-modal-content-markdown blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.fullscreen-modal-content-markdown blockquote blockquote {
  margin-left: 20px;
}
.fullscreen-modal-content-markdown p, .fullscreen-modal-content-markdown span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.fullscreen-modal-content-markdown em {
  font-style: italic;
}
.fullscreen-modal-content-markdown strong {
  font-weight: var(--text-weight-medium);
}
.fullscreen-modal-content-markdown ul, .fullscreen-modal-content-markdown ol {
  margin: 5px;
}
.fullscreen-modal-content-markdown a {
  color: var(--content-color-link);
  text-decoration: none;
}
.fullscreen-modal-content-markdown a:hover {
  text-decoration: underline;
}
.fullscreen-modal-content-markdown pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.fullscreen-modal-content-markdown pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.fullscreen-modal-content-markdown code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.fullscreen-modal-content-markdown table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.fullscreen-modal-content-markdown table th,
.fullscreen-modal-content-markdown table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.fullscreen-modal-content-markdown table th:first-child,
.fullscreen-modal-content-markdown table td:first-child {
  border-left: none;
}
.fullscreen-modal-content-markdown table th:last-child,
.fullscreen-modal-content-markdown table td:last-child {
  border-right: none;
}
.fullscreen-modal-content-markdown table th {
  border-top: none;
}
.fullscreen-modal-content-markdown table tr:last-child th,
.fullscreen-modal-content-markdown table tr:last-child td {
  border-bottom: none;
}
.fullscreen-modal-content-markdown img {
  max-width: 100%;
}
.fullscreen-modal-content-center {
  text-align: center;
}
.fullscreen-modal-container {
  z-index: 50;
  position: relative;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.lesson-center-container {
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  padding: var(--spacing-xxl);
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--background-color-primary);
  font-size: var(--text-size-m);
}
.lesson-center-container .loading-indicator-wrapper {
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
}
.lesson-center-container .lesson-center-header {
  margin: var(--spacing-xl) 0;
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.lesson-center-container .lesson-center-list {
  display: flex;
}
.lesson-center-container .lesson-center-list .lesson-center-list-column {
  flex: 1;
}
.lesson-center-container .lesson-center-list .empty-state {
  display: flex;
  padding-top: 180px;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  height: 420px;
  margin-left: 20px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-secondary);
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xxl);
  border-radius: var(--border-radius-default);
}
.lesson-center-container .lesson-center-list .empty-state .bootcamp-illustration-title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  margin: 20px 0 10px;
}
.lesson-center-container .lesson-center-list .empty-state .bootcamp-illustration-content {
  text-align: center;
  padding: 0 var(--spacing-l);
}
.lesson-center-container .lesson-center-list .learning-center-section-title {
  margin-bottom: var(--spacing-l);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  display: flex;
}
.lesson-center-container .lesson-center-list .learning-center-section-title .skills-list-progress {
  font-size: var(--text-size-xs);
  color: var(--content-color-brand);
  margin-left: 10px;
}
.lesson-center-container .lesson-center-list .resuming-learning-list-title {
  align-items: center;
  cursor: pointer;
}
.lesson-center-container .lesson-center-list .resume-learning-list-container {
  margin-bottom: var(--spacing-xl);
}
.lesson-center-container .lesson-center-list .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
}

.lesson-list-item {
  cursor: initial;
}
.lesson-list-item .lesson-list-item-description {
  font-weight: var(--text-weight-regular);
  margin-top: 10px;
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
}
.lesson-list-item .lesson-list-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.lesson-list-item .lesson-list-item-content .lesson-list-item-status.completed {
  color: var(--base-color-success);
}
.lesson-list-item .lesson-list-item-content .lesson-list-item-status.inprogress {
  color: var(--base-color-warning);
}
.lesson-list-item .lesson-list-item-content .lesson-actions {
  display: flex;
  font-size: var(--text-size-xs);
  margin-top: var(--spacing-l);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .lesson-action {
  margin-right: 30px;
  cursor: pointer;
  align-items: center;
}
.lesson-list-item .lesson-list-item-content .lesson-actions .pm-icon {
  margin-right: 5px;
}
.lesson-list-item .lesson-list-item-content .lesson-actions .pm-icon use {
  fill: var(--content-color-secondary);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .pm-icon svg {
  color: var(--content-color-secondary);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .pm-icon.primary use {
  fill: var(--base-color-brand);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .pm-icon.primary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .pm-icon.primary svg {
  color: var(--base-color-brand);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .action-label {
  display: flex;
  color: var(--content-color-secondary);
  height: 12px;
}
.lesson-list-item .lesson-list-item-content .lesson-actions .action-label.loading {
  color: var(--content-color-tertiary);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .action-label.primary {
  color: var(--base-color-brand);
  align-items: flex-end;
}
.lesson-list-item .lesson-list-item-content .lesson-actions .action-label.primary use {
  fill: var(--base-color-brand);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .action-label.primary svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .action-label.primary svg {
  color: var(--base-color-brand);
}
.lesson-list-item .lesson-list-item-content .lesson-actions .spinner {
  margin-right: var(--spacing-s);
}

.skills-list-container .skill-list-item {
  cursor: pointer;
}
.skills-list-container .skill-list-item .skill-list-item-content {
  display: flex;
  flex-direction: column;
}
.skills-list-container .skill-list-item .skill-list-item-content .skill-list-item-description {
  font-weight: var(--text-weight-regular);
  margin-top: 10px;
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
}
.skills-list-container .skill-list-item .skill-meta {
  display: flex;
  font-size: var(--text-size-xs);
  margin-top: 10px;
  color: var(--content-color-secondary);
}
.skills-list-container .skill-list-item .skill-meta .skill-lessons-number {
  margin-right: 20px;
}
.learning-center-skill .learning-center-skill--main {
  display: flex;
}
.learning-center-skill .learning-center-skill--left {
  flex: 1;
  padding-right: 20px;
}
.learning-center-skill .learning-center-skill--right {
  padding-left: 20px;
  flex: 1;
}
.learning-center-skill .learning-center-back {
  display: inline-flex;
  margin: 20px 0;
  color: var(--content-color-secondary);
  align-items: center;
  cursor: pointer;
}
.learning-center-skill .learning-center-back .learning-center-back-icon {
  margin-right: 10px;
}
.learning-center-skill .skill-details {
  display: flex;
  flex-direction: column;
}
.learning-center-skill .skill-details .skill-icon {
  margin-bottom: 16px;
}
.learning-center-skill .skill-details .skill-icon img {
  object-fit: contain;
}
.learning-center-skill .skill-details .skill-name {
  color: var(--content-color-primary);
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 16px;
}
.learning-center-skill .skill-details .skill-description {
  font-size: 12px;
  color: var(--content-color-secondary);
  margin-bottom: 16px;
}
.learning-center-skill .skill-additional--lesson-detail {
  display: flex;
  justify-content: space-between;
  padding: 6px 28px 6px 16px;
}
.learning-center-skill .skill-additional--label {
  margin-bottom: 16px;
  font-weight: 600;
}
.learning-center-skill .skill-additional-resources-content ol {
  padding: unset;
}
.learning-center-skill .skill-additional-resources-content ol li {
  margin: 16px 0px 16px 28px;
}
.learning-center-skill .skill-additional-resources-content ol li p {
  margin: unset;
}
.learning-center-skill .skill-additional-resources-content ol li p:first-child {
  margin-bottom: 4px;
}
.learning-center-skill .skill-additional-resources-content a {
  color: var(--content-color-brand);
  text-decoration: none;
}
.learning-center-skill .skill-additional-resources-content a:hover {
  cursor: pointer;
  text-decoration: underline;
}
.learning-center-skill .skill-additional-resources-content a:visited {
  text-decoration: none;
}

.learning-center-list-item {
  padding: 20px;
  background: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  font-weight: 600;
  font-size: var(--text-size-m);
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
}
.learning-center-list-item.skill-list-item {
  filter: drop-shadow(0px 0 2px rgba(0, 0, 0, 0.2));
}
.learning-center-list-item.lesson-list-item {
  border: 1px solid var(--border-color-default);
}
.learning-center-list-item.lesson-plan-list-item {
  align-items: center;
  height: 20px;
  filter: none;
  border: 1px solid var(--border-color-default);
}

.learning-center-list-item-header {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.learning-center-list-item-icon {
  margin-right: 15px;
  display: flex;
}
.learning-center-list-item-icon img {
  object-fit: contain;
}

.lesson-plan-list-item-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}
.lesson-plan-list-item-content .lesson-plan-actions {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.lesson-plan-list-item-content .lesson-plan-actions .lesson-plan-start-icon {
  display: flex;
  margin-right: 10px;
}
.lesson-plan-list-item-content .lesson-plan-actions .pm-icon use {
  fill: var(--base-color-brand);
}
.lesson-plan-list-item-content .lesson-plan-actions .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.lesson-plan-list-item-content .lesson-plan-actions .pm-icon svg {
  color: var(--base-color-brand);
}
.lesson-plan-list-item-content .lesson-plan-actions .lesson-plan-start {
  color: var(--content-color-brand);
  font-size: var(--text-size-m);
  font-weight: 600;
}

.plugin__learningCenter .learning-center-button {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  font-weight: var(--text-weight-regular);
  cursor: pointer;
}
.plugin__learningCenter .learning-center-button.loading {
  background-color: var(--background-color-secondary);
  color: var(--content-color-tertiary);
  padding: var(--spacing-xs) var(--spacing-s);
  pointer-events: none;
  cursor: not-allowed;
}
.plugin__learningCenter .learning-center-button .learning-center-label {
  margin-left: var(--spacing-xs);
  position: relative;
}
.plugin__learningCenter .learning-center-button .learning-center-label.dismissable {
  margin-right: 6px;
  margin-left: var(--spacing-xs);
}
.plugin__learningCenter .learning-center-button .sb__item__text {
  margin-bottom: var(--spacing-zero);
}
.plugin__learningCenter .learning-center-button.highlight {
  background: var(--background-color-brand);
  color: var(--content-color-brand);
  padding: var(--spacing-s);
  height: var(--size-s);
  box-sizing: border-box;
}
.plugin__learningCenter .learning-center-button.highlight .pm-icon use {
  fill: var(--content-color-brand);
}
.plugin__learningCenter .learning-center-button.highlight .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.plugin__learningCenter .learning-center-button.highlight .pm-icon svg {
  color: var(--content-color-brand);
}
.plugin__learningCenter .learning-center-button .spinner {
  flex-direction: row-reverse;
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
.spinner {
  display: flex;
  justify-content: space-between;
  width: 14px;
  height: 12px;
  text-align: center;
  font-size: var(--text-size-xs);
}
.spinner > div {
  background-color: var(--content-color-tertiary);
  height: 100%;
  width: 2px;
  border-radius: var(--border-radius-s);
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect-two {
  animation-delay: -1.1s;
}
.spinner .rect-three {
  animation-delay: -1s;
}
.spinner .rect-four {
  animation-delay: -0.9s;
}
.spinner .rect-five {
  animation-delay: -0.8s;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.comment-container {
  position: absolute;
  top: var(--spacing-l);
  right: var(--spacing-zero);
  margin-top: var(--spacing-l);
  overflow: visible;
  background: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-default);
  width: 400px;
  z-index: 12;
}
.comment-container::before {
  content: " ";
  position: absolute;
  top: -6px;
  right: var(--spacing-l);
  box-shadow: -2px -2px 2px 0 rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-width: 6px;
  border-style: var(--border-style-solid);
  border-color: var(--background-color-primary) transparent transparent var(--background-color-primary);
}
.comment-container .app-crash-wrapper {
  padding: var(--spacing-xxl);
}
.comment-container .app-crash-wrapper .app-crash-content-container {
  width: auto;
}

.comment-wrapper {
  display: flex;
  color: var(--content-color-link);
  align-items: center;
  line-height: var(--line-height-m);
}
.comment-wrapper .comment-badge {
  background: var(--background-color-tertiary);
  line-height: var(--line-height-s);
  min-width: 8px;
  font-size: var(--text-size-xs);
  margin-left: var(--spacing-xs);
  padding: var(--spacing-zero) var(--spacing-xs);
  border-radius: var(--border-radius-s);
  text-align: center;
  height: var(--size-xs);
}
.comment-wrapper__icon {
  margin-right: var(--spacing-s);
  height: var(--size-xs);
}
.comment-wrapper__icon use {
  fill: var(--content-color-secondary);
}
.comment-wrapper__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.comment-wrapper__icon svg {
  color: var(--content-color-secondary);
}
.comment-wrapper__icon-active {
  margin-right: var(--spacing-s);
  height: var(--size-xs);
}
.comment-wrapper__icon-active use {
  fill: var(--content-color-primary);
}
.comment-wrapper__icon-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.comment-wrapper__icon-active svg {
  color: var(--content-color-primary);
}
.comment-wrapper.is-active {
  color: var(--content-color-primary);
}
.comment-wrapper.is-active .comment-badge {
  color: var(--content-color-primary);
}

.comments {
  min-height: 100px;
  position: relative;
}
.comments > .loading-indicator-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.comments .entity-empty {
  padding: var(--spacing-xxl);
}
.comments-tag {
  cursor: pointer;
  background: var(--grey-50);
  border-radius: 12px;
  color: var(--content-color-primary) !important;
  padding: var(--spacing-zero) var(--spacing-xs);
}
.comments-tag.is-light-theme {
  background: var(--grey-30);
}
.comments-tag-private-user {
  cursor: default;
}
.comments-tag-private-user:hover {
  text-decoration: none !important;
}
.comments-tag-current-user {
  background: var(--yellow-70);
}
.comments-tag-current-user.is-light-theme {
  background: var(--yellow-30);
}
.comments-item {
  transition: background-color 0.3s ease-in-out;
}
.comments-item__body .edited {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-s);
}
.comments-item--focused {
  background: var(--background-color-brand);
  border-radius: var(--border-radius-default);
  transition: background-color 0.3s ease-in-out;
}
.comments-header {
  display: flex;
  height: var(--size-s);
  align-items: center;
  justify-content: space-between;
}
.comments-header-tooltip-arrow {
  left: var(--spacing-s) !important;
}
.comments-user {
  height: var(--size-s);
}
.comments-input, .comments-item {
  position: relative;
  padding: var(--spacing-l);
}
.comments-input .avatar, .comments-item .avatar {
  left: var(--spacing-l);
  display: inline-flex;
}
.comments-input {
  position: relative;
  display: flex;
  flex-direction: column;
}
.comments-input__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comments-input__actions-left-options {
  display: flex;
  align-items: center;
}
.comments-input__confirm {
  width: 120px;
  font-weight: var(--text-weight-medium);
}
.comments-input__cancel {
  margin-left: var(--spacing-s);
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-medium);
}
.comments-input__cancel:hover, .comments-input__cancel.is-focused {
  color: var(--content-color-secondary);
}
.comments-input .textarea {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin-top: var(--spacing-xs);
  min-height: 76px;
  resize: none;
  padding: var(--spacing-s);
}
.comments-input .textarea::placeholder {
  color: var(--content-color-tertiary);
}
.comments-input .comments-refresh {
  color: var(--content-color-tertiary);
  width: var(--size-s);
  min-width: var(--size-s);
  height: var(--size-s);
}
.comments-input .comments-refresh:hover, .comments-input .comments-refresh.is-focused {
  background-color: var(--background-color-tertiary);
}
.comments-input__user {
  display: flex;
}
.comments-footer {
  line-height: var(--line-height-s);
  font-size: var(--text-size-xs);
  color: var(--content-color-secondary);
  text-align: left;
  margin-bottom: var(--spacing-s);
  margin-top: var(--spacing-xs);
}
.comments-footer__markdown-link {
  cursor: pointer;
  font-weight: var(--text-weight-medium);
}
.comments-footer__markdown-link:hover, .comments-footer__markdown-link.is-hovered {
  text-decoration: underline;
  color: var(--content-color-primary);
}
.comments-footer__user-public-profile-text {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-s);
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-xs);
}
.comments-footer__user-public-profile-text a {
  color: var(--content-color-link);
}
.comments-footer__user-public-profile-text .pm-icon {
  margin-right: var(--spacing-s);
}
.comments--add-comment {
  margin: var(--spacing-s);
}
.comments-tag-list {
  font-size: var(--text-size-m);
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: overlay;
  list-style: none;
  padding-left: var(--spacing-zero);
  position: absolute;
  top: calc(100% - 60px);
  left: var(--spacing-l);
  width: 280px;
  background: var(--background-color-primary);
  box-shadow: var(--shadow-default);
  color: var(--content-color-primary);
  z-index: 8;
  margin-top: var(--spacing-s);
}
.comments-tag-list__wrapper {
  display: flex;
}
.comments-tag-list__avatar {
  pointer-events: none;
  cursor: default;
  padding: 6px var(--spacing-s);
  display: inherit;
}
.comments-tag-list__user {
  line-height: var(--line-height-l);
  padding: 6px var(--spacing-s) 6px var(--spacing-zero);
  width: calc(100% - 56px);
  display: flex;
}
.comments-tag-list__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(66% - 8px);
  padding-right: var(--spacing-s);
  flex: none;
}
.comments-tag-list__username {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--content-color-tertiary);
}
.comments-tag-list__item {
  cursor: pointer;
}
.comments-tag-list__item:hover, .comments-tag-list__item.focused {
  background: var(--background-color-secondary);
}
.comments-list {
  list-style: none;
  padding: var(--spacing-zero);
  margin: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  max-height: 300px;
  overflow: auto;
}
.comments-item {
  position: relative;
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  padding-right: var(--spacing-l);
}
.comments-item__time {
  color: var(--content-color-secondary);
  text-align: right;
  margin-left: var(--spacing-s);
  flex: 1;
  min-width: max-content;
}
.comments-item__creator {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  display: inline-block;
  color: var(--content-color-primary);
  margin-left: var(--spacing-s);
  line-height: var(--line-height-l);
}
.comments-item__creator:hover {
  color: var(--content-color-primary);
  text-decoration: none;
}
.comments-item__body {
  color: var(--content-color-primary);
  color: var(--content-color-primary);
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero);
  margin-left: var(--spacing-xxl);
}
.comments-item__body h1,
.comments-item__body h2,
.comments-item__body h3,
.comments-item__body h4,
.comments-item__body h5,
.comments-item__body h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.comments-item__body h1 {
  font-size: 18px;
  font-weight: 700;
}
.comments-item__body h2 {
  font-size: 16px;
  font-weight: 700;
}
.comments-item__body h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.comments-item__body h4 {
  font-size: 14px;
  font-weight: 700;
}
.comments-item__body h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.comments-item__body h6 {
  font-size: 12px;
  font-weight: 600;
}
.comments-item__body hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.comments-item__body blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.comments-item__body blockquote blockquote {
  margin-left: 20px;
}
.comments-item__body p, .comments-item__body span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.comments-item__body em {
  font-style: italic;
}
.comments-item__body strong {
  font-weight: var(--text-weight-medium);
}
.comments-item__body ul, .comments-item__body ol {
  margin: 5px;
}
.comments-item__body a {
  color: var(--content-color-link);
  text-decoration: none;
}
.comments-item__body a:hover {
  text-decoration: underline;
}
.comments-item__body pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.comments-item__body pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.comments-item__body code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.comments-item__body table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.comments-item__body table th,
.comments-item__body table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.comments-item__body table th:first-child,
.comments-item__body table td:first-child {
  border-left: none;
}
.comments-item__body table th:last-child,
.comments-item__body table td:last-child {
  border-right: none;
}
.comments-item__body table th {
  border-top: none;
}
.comments-item__body table tr:last-child th,
.comments-item__body table tr:last-child td {
  border-bottom: none;
}
.comments-item__body img {
  max-width: 100%;
}
.comments-item--deleting .comments-item__actions {
  display: block;
}
.comments-item--deleting .comments-item__time {
  display: none;
}
.comments-item--editable:hover, .comments-item--deletable:hover, .comments-item--copy-link:hover {
  background: var(--background-color-secondary);
}
.comments-item--editable:hover .comments-item__actions, .comments-item--deletable:hover .comments-item__actions, .comments-item--copy-link:hover .comments-item__actions {
  display: block;
}
.comments-item--editable:hover .comments-item__time, .comments-item--deletable:hover .comments-item__time, .comments-item--copy-link:hover .comments-item__time {
  display: none;
}
.comments-item__actions {
  display: none;
  text-align: right;
  flex: 1;
  min-width: max-content;
}
.comments-item__actions .btn {
  min-width: initial;
  height: var(--size-s);
  padding: 6px;
}
.comments-item__actions .btn use {
  fill: var(--content-color-tertiary);
}
.comments-item__actions .btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.comments-item__actions .btn svg {
  color: var(--content-color-tertiary);
}
.comments-item__actions .btn:hover {
  background-color: var(--background-color-tertiary);
}
.comments-item__actions .btn:hover use {
  fill: var(--content-color-secondary);
}
.comments-item__actions .btn:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.comments-item__actions .btn:hover svg {
  color: var(--content-color-secondary);
}
.comments-item__actions .btn.copy-link {
  margin-right: var(--spacing-s);
}
.comments-item__actions .btn.copy-link.success use {
  fill: var(--base-color-success);
}
.comments-item__actions .btn.copy-link.success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-success);
}
.comments-item__actions .btn.copy-link.success svg {
  color: var(--base-color-success);
}
.comments-item__actions .btn.copy-link.success.is-disabled {
  opacity: 1;
}
.comments-item__actions .btn.edit {
  margin-right: var(--spacing-s);
}
.comments-item__actions .btn.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.copy-comment-link-button {
  display: inline-block;
}

.inline-comment-button {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-xs);
  background-color: var(--background-color-primary);
}
.inline-comment-button i {
  margin-right: var(--spacing-s);
}
.inline-comment-button .comment-badge {
  height: var(--size-xs);
  width: var(--size-xs);
  margin-left: var(--spacing-xs);
  background-color: var(--background-color-tertiary);
}

.inline-comment .tooltip-wrapper {
  padding: var(--spacing-xs);
}
.inline-comment__tooltip-arrow::before {
  border-right-color: var(--background-color-primary) !important;
  border-left-color: var(--background-color-primary) !important;
}
.inline-comment .comments {
  min-height: 50px;
}
.inline-comment__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color-secondary);
  padding: var(--spacing-xs) var(--spacing-l);
  height: var(--size-m);
}
.inline-comment__header-comment {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.inline-comment__header-comment .comment-badge {
  height: var(--size-xs);
  width: var(--size-xs);
  padding: var(--spacing-zero) var(--spacing-xs);
  background-color: var(--background-color-tertiary);
}
.inline-comment__footer {
  padding: var(--spacing-zero) 6px var(--spacing-m);
}

.inline-comment.comment {
  z-index: 110;
  width: 350px;
}
.inline-comment.comment .tippy-content {
  padding: 0;
  min-width: 350px;
  max-height: 350px;
  border-radius: 4px;
  overflow: auto;
  background-color: var(--background-color-primary);
}
.inline-comment.comment .tooltip-wrapper {
  min-width: 350px;
  max-height: 520px;
  overflow: hidden;
  background-color: var(--background-color-primary);
}
.inline-comment.comment .comments-list {
  max-height: 250px;
  margin-top: var(--spacing-zero);
}

.inline-comment-available {
  background-color: var(--background-color-brand);
}
.inline-comment-available:hover {
  background-color: rgba(255, 108, 55, 0.2);
}
.inline-comment-available__focused {
  background-color: rgba(255, 108, 55, 0.2);
}

.new-comments .inline-comment-available__selected .key-value-cell__text, .new-comments .inline-comment-available__selected span, .new-comments .inline-comment-available .key-value-cell__text, .new-comments .inline-comment-available span {
  text-decoration: solid underline var(--yellow-50) 1px;
  background-color: var(--learning-center-highlight-background-color);
}
.new-comments .inline-comment-available__selected .key-value-cell__text:hover, .new-comments .inline-comment-available__selected span:hover, .new-comments .inline-comment-available .key-value-cell__text:hover, .new-comments .inline-comment-available span:hover {
  cursor: pointer;
}
.new-comments .inline-comment-available {
  background-color: inherit;
}
.new-comments .inline-comment-available:hover {
  background-color: inherit;
}
.new-comments .inline-comment-available__focused {
  background-color: inherit;
}
.new-comments .inline-comment-available__selected .key-value-cell__text, .new-comments .inline-comment-available__selected span {
  filter: saturate(2);
}
.new-comments .inline-comment-available__selected .key-value-form-row__type-select-container span {
  text-decoration: none;
  background-color: transparent;
}
.new-comments .inline-comment-available.inline-comment-available__focused .key-value-cell__text, .new-comments .inline-comment-available.inline-comment-available__focused span {
  filter: saturate(2);
}
.new-comments .inline-comment-available.inline-comment-available__focused .key-value-form-row__type-select-container span {
  text-decoration: none;
  background-color: transparent;
}
.new-comments .inline-comment-available.inline-comment-available__focused .file-input span {
  text-decoration: none;
  background-color: transparent;
}
.new-comments .inline-comment-available .key-value-form-row__type-select-container, .new-comments .inline-comment-available span {
  text-decoration: none;
  background-color: transparent;
}
.new-comments .inline-comment-available .file-input span {
  text-decoration: none;
  background-color: transparent;
}
.new-comments .monaco-editor-inline-comment-text {
  border-bottom: solid var(--yellow-50) 1px;
  background-color: var(--learning-center-highlight-background-color);
}
.new-comments .margin-comment-icon {
  content: url("../assets/images/icons/commentIconLight.svg");
  cursor: pointer;
  height: 100% !important;
  width: 12px !important;
  margin-left: 8px;
}
.new-comments .margin-comment-icon.margin-comment-icon-disabled {
  content: url("../assets/images/icons/commentIconLightDisabled.svg");
  cursor: not-allowed;
}
.new-comments.new-comments-dark .margin-comment-icon {
  content: url("../assets/images/icons/commentIconDark.svg");
}
.new-comments.new-comments-dark .margin-comment-icon.margin-comment-icon-disabled {
  content: url("../assets/images/icons/commentIconDarkDisabled.svg");
  cursor: not-allowed;
}
.new-comments .margin-comment-line::before {
  content: "";
  border: 0.5px solid var(--content-color-secondary);
  height: 100%;
  position: absolute;
  left: 13px;
  cursor: pointer;
}

.comment-drawer {
  height: 100%;
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);
  overflow-y: auto;
}
.comment-drawer .comments {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: unset;
  width: 100%;
}
.comment-drawer .comments .comments-list {
  max-height: unset;
}

.inline-comment-drawer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.inline-comment-drawer-wrapper .inline-comment-drawer-blankstate {
  max-width: 100%;
}
.inline-comment-drawer-wrapper__loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline-comment-drawer-wrapper__loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline-comment-drawer-wrapper__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  color: var(--content-color-primary);
  text-align: center;
  line-height: var(--line-height-m);
}
.inline-comment-drawer-wrapper__empty-state__illustration {
  margin-bottom: var(--spacing-l);
}
.inline-comment-drawer-wrapper__empty-state-title {
  color: var(--content-color-secondary);
}
.inline-comment-drawer-wrapper__empty-state-description {
  margin-top: var(--spacing-l);
}
.inline-comment-drawer-wrapper__empty-state-button {
  display: inline;
  padding: var(--spacing-zero);
}
.inline-comment-drawer-wrapper__empty-state-doc {
  margin-top: var(--spacing-s);
}
.inline-comment-drawer-wrapper .inline-doc-link {
  cursor: pointer;
  color: var(--content-color-link);
}

.inline-comment-drawer {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin: var(--spacing-s) var(--spacing-zero);
  border-radius: var(--border-radius-s);
}
.inline-comment-drawer .comments-list {
  overflow: unset;
  max-height: unset;
  margin-top: var(--spacing-zero);
}
.inline-comment-drawer .overflow-seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-s) var(--spacing-zero);
  cursor: pointer;
}
.inline-comment-drawer .overflow-seperator hr {
  margin: var(--spacing-zero);
  width: 60px;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.inline-comment-drawer .overflow-seperator span {
  padding: var(--spacing-zero) var(--spacing-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.inline-comment-drawer__header.is-resolved {
  background-color: var(--background-color-tertiary);
}
.inline-comment-drawer__header {
  height: 20px;
  padding: 10px var(--spacing-l);
  background-color: var(--background-color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inline-comment-drawer__header-left {
  width: calc(100% - 68px);
  color: var(--content-color-secondary);
  display: flex;
  align-items: center;
}
.inline-comment-drawer__header-left.is-orphaned {
  text-decoration: line-through;
}
.inline-comment-drawer__header--value {
  font-weight: var(--text-weight-medium);
  height: var(--size-xs);
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: var(--spacing-xs);
}
.inline-comment-drawer__header--anchor {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inline-comment-drawer__header--anchor-entity {
  cursor: pointer;
}
.inline-comment-drawer__header-resolved {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.inline-comment-drawer__content.is-resolved {
  background-color: var(--background-color-secondary);
}
.inline-comment-drawer__content--update-badge {
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  background-color: var(--background-color-brand);
  color: var(--content-color-link);
  font-weight: var(--text-weight-medium);
  padding-left: 20px;
}
.inline-comment-drawer__actions {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: var(--spacing-s) var(--spacing-zero) var(--spacing-zero);
  flex: 0 1 auto;
}
.inline-comment-drawer__actions-helptext {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.inline-comment-drawer__actions .dropdown-button.is-open .btn-icon.btn-icon-rect.inline-comment-drawer__actions-filter-button {
  background-color: var(--highlight-background-color-tertiary);
}
.inline-comment-drawer__actions .btn-icon.btn-icon-rect.inline-comment-drawer__actions-filter-button {
  height: var(--size-s);
  width: var(--size-s);
  background-color: var(--background-color-tertiary);
}
.inline-comment-drawer__actions .btn-icon.btn-icon-rect.inline-comment-drawer__actions-filter-button:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.inline-comment-drawer__actions-filter-menu {
  height: 108px;
  width: 153px;
  background-color: var(--background-color-primary);
}
.inline-comment-drawer__actions-filter--action-header {
  margin-left: var(--spacing-s);
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.inline-comment-drawer__actions-filter--action-item {
  margin-top: var(--spacing-m);
  margin-left: var(--spacing-l);
}
.inline-comment-drawer__actions-filter--action-item-name {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  margin-left: var(--spacing-s);
}
.inline-comment-drawer__footer.is-resolved {
  background-color: var(--background-color-secondary);
}
.inline-comment-drawer__footer {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-m) var(--spacing-l);
  display: flex;
  justify-content: space-between;
}
.inline-comment-drawer__footer--add-comment {
  display: flex;
  align-items: center;
  width: 100%;
}
.inline-comment-drawer__footer--show-all {
  display: flex;
  align-items: center;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  cursor: pointer;
  width: 100%;
  justify-content: flex-end;
}
.inline-comment-drawer__footer--show-all .comment-badge {
  background: var(--content-color-tertiary);
  line-height: var(--size-xs);
  min-width: 8px;
  font-size: var(--text-size-xs);
  margin-left: var(--spacing-xs);
  padding: var(--spacing-zero) var(--spacing-xs);
  border-radius: var(--border-radius-s);
  text-align: center;
  height: var(--size-xs);
  color: var(--content-color-constant);
}

.comment-filter-dropdown-item-checkbox {
  display: inline-flex;
}

.comment-filter-dropdown-item-checkbox.is-selected {
  background-color: var(--base-color-brand);
}

.comment-drawer--tabs .tab {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  padding: var(--spacing-zero);
  margin-right: var(--spacing-xl);
  height: 40px;
  display: flex;
  align-items: flex-end;
}
.comment-drawer--tabs .tab--inline {
  position: relative;
}
.comment-drawer--tabs :not(.is-active) {
  color: var(--content-color-tertiary);
}

.resolve-thread-confirmation-modal .delete-confirmation-modal-delete-button {
  background-color: var(--base-color-brand);
}

.comment-drawer-container {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  height: 100%;
}
.comment-drawer-container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-s);
}
.comment-drawer-container__header-title {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
}
.comment-drawer-container__actions {
  display: flex;
  align-items: center;
}
.comment-drawer-container__actions .dropdown-button.is-open .btn-icon.btn-icon-rect.comment-drawer-container__actions-filter-button {
  background-color: var(--highlight-background-color-tertiary);
}
.comment-drawer-container__actions .btn-icon.btn-icon-rect.comment-drawer-container__actions-filter-button {
  height: var(--size-s);
  width: var(--size-s);
  background-color: var(--background-color-tertiary);
}
.comment-drawer-container__actions .btn-icon.btn-icon-rect.comment-drawer-container__actions-filter-button:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.comment-drawer-container__actions-filter-menu {
  height: 108px;
  width: 153px;
  background-color: var(--background-color-primary);
}
.comment-drawer-container__actions-filter--action-header {
  margin-left: var(--spacing-s);
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.comment-drawer-container__actions-filter--action-item {
  margin-top: 10px;
  margin-left: var(--spacing-l);
}
.comment-drawer-container__actions-filter--action-item-name {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  margin-left: var(--spacing-s);
}

.comment-drawer-container.no-scroll .comment-drawer {
  overflow: hidden;
}

.release-notes-editor {
  padding: 20px;
  background-color: var(--background-color-primary);
  flex: 1;
  overflow: auto;
}
.release-notes-editor .markdown-base-content {
  max-width: 640px;
  margin: 0 auto;
  padding: 0;
}
.release-notes-editor .markdown-editor-text h1, .release-notes-editor .markdown-editor-text h2, .release-notes-editor .markdown-editor-text h3, .release-notes-editor .markdown-editor-text h4, .release-notes-editor .markdown-editor-text h5, .release-notes-editor .markdown-editor-text h6 {
  text-transform: none !important;
  margin: 1em 0 0 0 !important;
}
.release-notes-editor .markdown-editor-text h1 {
  margin-top: 0 !important;
}
.release-notes-editor .markdown-editor-text img {
  margin: 1em 0 !important;
}
.release-notes-editor .markdown-editor-text p {
  margin-bottom: 1em !important;
}

.share-entity-modal-container {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
}
.share-entity-modal-container .share-entity-modal-container--modal-content {
  overflow-y: visible;
  margin-bottom: var(--spacing-l);
}
.share-entity-modal-container .share-entity-modal-container--modal-content .share-modal {
  padding: 0px 4px;
}
.share-entity-modal-container .has-options {
  padding-bottom: 0px;
}
.share-entity-modal-container .infobar {
  margin: -20px -20px 0 -20px;
}
.share-entity-modal-container .move-only-infobar .infobar {
  margin: -8px 0 var(--spacing-l) 0;
  padding-right: var(--spacing-l);
}
.share-entity-modal-container .share-entity-modal-container--tabs {
  margin-bottom: var(--spacing-s);
}
.share-entity-modal-container .share-entity-modal-container--tabs :first-child .tab-text-wrapper {
  margin-left: 0;
}
.share-entity-modal-container .share-entity-modal-container--tabs + .infobar {
  margin-top: -16px;
}
.share-entity-modal-container .share-entity-modal-container--action {
  margin-left: 8px;
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon {
  margin-right: var(--spacing-s);
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon use {
  fill: var(--base-color-error);
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon svg {
  color: var(--base-color-error);
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon.is-offline {
  display: flex;
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon.is-offline use {
  fill: var(--base-color-warning);
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon.is-offline svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.share-entity-modal-container .share-entity-modal-container--infobar-icon.is-offline svg {
  color: var(--base-color-warning);
}
.share-entity-modal-container .share-entity-modal-container--move-only-infobar-icon {
  margin-right: var(--spacing-s);
}
.share-entity-modal-container__header {
  flex-grow: 0;
}
.share-entity-modal-container__subheading {
  margin: var(--spacing-xl);
  margin-top: -8px;
}
.share-entity-modal-container__via-workspace {
  padding-top: var(--spacing-l);
}
.share-entity-modal-container__via-workspace__heading {
  margin-top: var(--spacing-l);
}
.share-entity-modal-container__via-workspace__link {
  margin-top: var(--spacing-s);
}
.share-entity-modal-container__via-workspace__link__input input {
  color: transparent;
  text-shadow: 0 0 0 var(--content-color-primary);
}
.share-entity-modal-container__via-workspace__move-button {
  margin-left: var(--spacing-xs);
  font-size: var(--text-size-s);
}
.share-entity-modal-container__via-workspace__move-section {
  padding-top: var(--spacing-s);
  margin-top: var(--spacing-s);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.workspace-list-item {
  padding: 0 8px;
  min-height: 58px;
  max-height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color-default);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.workspace-list-item:hover {
  background-color: var(--highlight-background-color-primary);
}
.workspace-list-item.is-selected {
  background-color: var(--background-color-brand);
}
.workspace-list-item.is-disabled:not(.is-view-mode) {
  cursor: default;
}
.workspace-list-item.is-disabled:not(.is-view-mode) .avatar-group,
.workspace-list-item.is-disabled:not(.is-view-mode) .workspace-list-item__details {
  opacity: 0.5;
}
.workspace-list-item.is-disabled, .workspace-list-item.is-view-mode {
  background-color: transparent;
}
.workspace-list-item.is-view-mode {
  padding: 0 0 0 4px;
}
.workspace-list-item .avatar-group {
  margin: 0;
}
.workspace-list-item__workspace-subtitle {
  max-width: 100%;
  font-size: 11px;
  display: inline-flex;
  color: var(--content-color-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workspace-list-item__public-share-tooltip {
  display: inline-flex;
  align-items: center;
}
.workspace-list-item__public-share-tooltip .pm-icon {
  width: 14px;
  height: 14px;
  margin: var(--spacing-zero) var(--spacing-s);
}
.workspace-list-item .workspace-list-item__details-container {
  flex: 1;
  display: flex;
  min-width: 0;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__details {
  flex: 1;
  margin-left: 8px;
  min-width: 0;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__details .workspace-list-item__workspace-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__details .workspace-list-item__workspace-members {
  max-width: 100%;
  font-size: 11px;
  padding-right: 4px;
  display: inline-flex;
  color: var(--content-color-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__workspace-action {
  font-weight: 600;
  font-size: 11px;
  margin-left: 12px;
  color: var(--content-color-secondary);
  display: flex;
  align-items: center;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__workspace-action .btn-secondary {
  min-width: 54px;
  height: 24px;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__workspace-action .is-unjoined-and-shared {
  margin-right: 24px;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__workspace-action .radio {
  height: 16px;
  width: 16px;
  border: 1px solid var(--base-color-brand);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.workspace-list-item .workspace-list-item__details-container .workspace-list-item__workspace-action .radio .radio-inner {
  height: 14px;
  width: 14px;
  background-color: var(--base-color-brand);
  border-radius: 50%;
}
.workspace-list-item__visibility-icon {
  margin-top: var(--spacing-m);
  margin-left: var(--spacing-m);
}

.workspace-filter {
  margin: 8px 0;
}
.workspace-filter .workspace-filter__text-container {
  user-select: none;
  display: flex;
}
.workspace-filter .workspace-filter__text-container .workspace-filter__text-container--filter-by {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.workspace-filter .workspace-filter__text-container .workspace-filter__text-container--filter-by .workspace-filter__text-container-title {
  color: var(--content-color-secondary);
  font-weight: 600;
}
.workspace-filter .workspace-filter__text-container .workspace-filter__text-container--filter-by .workspace-filter__text-container-title--icon {
  margin-left: var(--spacing-xs);
  display: flex;
}
.workspace-filter .workspace-filter__text-container .workspace-filter__text-container--clear {
  color: var(--content-color-secondary);
  font-size: 11px;
  margin-left: 16px;
  cursor: pointer;
}
.workspace-filter .workspace-filter__text-container .workspace-filter__text-container--clear--highlight {
  color: var(--content-color-brand);
}
.workspace-filter .workspace-filter__text-container .dropdown-button {
  line-height: 0;
}
.workspace-filter .workspace-filter__filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
  user-select: none;
  min-height: 30px;
}
.workspace-filter .workspace-filter__filters .workspace-filter__filters-ws {
  display: flex;
}
.workspace-filter .workspace-filter__filters .workspace-filter__filters-ws .workspace-filter__filters-ws__title {
  color: var(--content-color-secondary);
  font-size: 11px;
  font-weight: 600;
}
.workspace-filter .workspace-filter__filters .workspace-filter__filters-ws .workspace-filter__filters-ws-types {
  display: flex;
  color: var(--content-color-secondary);
}
.workspace-filter .workspace-filter__filters .workspace-filter__filters-ws .workspace-filter__filters-ws-types .workspace-filter__filters-ws-type {
  display: flex;
  margin: 0 8px;
  cursor: pointer;
}
.workspace-filter .workspace-filter__filters .workspace-filter__filters-ws .workspace-filter__filters-ws-types .workspace-filter__filters-ws-type .workspace-filter__filters-ws-type-name {
  margin-left: 8px;
}
.workspace-filter .workspace-filter__filters .workspace-filter__separator {
  height: 24px;
  width: 1px;
  background-color: var(--border-color-strong);
  margin: 0 8px;
}
.workspace-filter .workspace-filter__filters .workspace-filter__people-container {
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
}
.workspace-filter .workspace-filter__filters .workspace_filter__tag {
  margin: 2px;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.select-dropdown-container .dropdown-menu {
  background-color: transparent;
  padding: var(--spacing-zero);
  box-shadow: unset;
}
.select-dropdown-container .select-dropdown {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background-color: var(--background-color-primary);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-default);
  padding: 8px;
  max-height: 260px;
  width: 230px;
}
.select-dropdown-container .select-dropdown .input-search-group {
  border-radius: var(--border-radius-default);
  margin-bottom: 4px;
  margin-top: 8px;
  min-height: 30px;
}
.select-dropdown-container .select-dropdown .select-dropdown-list.isLoading {
  overflow: unset;
  margin: auto;
}
.select-dropdown-container .select-dropdown .select-dropdown-list {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--content-color-secondary);
}
.select-dropdown-container .select-dropdown .select-dropdown-list .select-dropdown-item {
  cursor: pointer;
  user-select: none;
  padding: 8px 4px;
  position: relative;
  height: 18px;
  display: flex;
  align-items: center;
}
.select-dropdown-container .select-dropdown .select-dropdown-list .select-dropdown-item .select-dropdown-item-text {
  color: var(--content-color-primary);
  margin-left: 8px;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-dropdown-container .select-dropdown .select-dropdown-list .select-dropdown-item .select-dropdown-item-checkbox {
  position: absolute;
  right: 4px;
}
.select-dropdown-container .select-dropdown .select-dropdown-list .select-dropdown-item .input-checkbox.is-selected {
  background-color: var(--base-color-brand);
}
.select-dropdown-container .select-dropdown .select-dropdown-list .select-dropdown-item:hover {
  background-color: var(--highlight-background-color-primary);
}
.select-dropdown-container .select-dropdown .select-dropdown-list .select-dropdown-item:nth-of-type(1) {
  margin-top: 8px;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.modify-permissions-container__content {
  height: 430px;
  padding-right: var(--spacing-zero);
  padding-top: var(--spacing-zero);
}
.modify-permissions-container__content--extend {
  padding-right: var(--spacing-xl);
}
.modify-permissions-container__content__banner-container {
  padding-right: var(--spacing-xl);
}
.modify-permissions-container__no-access-content {
  height: 500px;
}
.modify-permissions-container .infobar {
  margin: -20px -20px 8px -20px;
}
.modify-permissions-container--infobar__info-icon {
  margin-right: 10px;
}
.modify-permissions-container--infobar__info-icon use {
  fill: var(--base-color-brand);
}
.modify-permissions-container--infobar__info-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.modify-permissions-container--infobar__info-icon svg {
  color: var(--base-color-brand);
}
.modify-permissions-container .modify-permissions-container--infobar-icon {
  margin-right: 10px;
}
.modify-permissions-container .modify-permissions-container--infobar-icon use {
  fill: var(--base-color-error);
}
.modify-permissions-container .modify-permissions-container--infobar-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.modify-permissions-container .modify-permissions-container--infobar-icon svg {
  color: var(--base-color-error);
}
.modify-permissions-container .modify-permissions-container--infobar-icon.is-offline use {
  fill: var(--base-color-warning);
}
.modify-permissions-container .modify-permissions-container--infobar-icon.is-offline svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.modify-permissions-container .modify-permissions-container--infobar-icon.is-offline svg {
  color: var(--base-color-warning);
}

.modify-permissions--banner {
  display: flex;
  align-items: center;
  background: var(--background-color-success);
  margin-bottom: 16px;
  padding: 0px 16px;
  flex: 1 0 auto;
}
.modify-permissions--banner-success svg use {
  fill: var(--base-color-success);
}
.modify-permissions--banner-text {
  margin: 10px 0px 10px 16px;
  line-height: 20px;
}
.modify-permissions--banner-close {
  cursor: pointer;
  margin-left: auto;
}

.modify-permissions-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.modify-permissions {
  display: flex;
  overflow: hidden;
  height: 100%;
}
.modify-permissions.is-loading {
  align-items: center;
  justify-content: center;
}
.modify-permissions .modify-permissions--editor {
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.modify-permissions .modify-permissions--editor .modify-permissions--editor__information {
  display: flex;
  flex-direction: row;
  margin: 12px 0px 12px 0px;
  align-items: flex-start;
}
.modify-permissions .modify-permissions--editor .modify-permissions--editor__information .modify-permissions--editor--information__workspace-name {
  font-weight: 600;
}
.modify-permissions .modify-permissions--editor .modify-permissions--editor__information .modify-permissions--editor--information__text {
  padding-left: 8px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--editor__information .modify-permissions--editor--information__icon {
  padding-top: var(--spacing-xs);
}
.modify-permissions .modify-permissions--editor .modify-permissions--editor__information .modify-permissions--editor--information__plan-link {
  padding-left: 4px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--search {
  margin-right: 10px;
  margin-top: var(--spacing-s);
}
.modify-permissions .modify-permissions--editor .modify-permissions--search__input-container {
  padding-left: 2px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--search__input-container .user-suggestion .avatar {
  margin-right: var(--spacing-s);
}
.modify-permissions .modify-permissions--editor .modify-permissions--search__input-container .user-suggestion__group-tag {
  width: var(--size-s);
  height: var(--size-s);
  padding: var(--spacing-zero) var(--spacing-xs);
  margin-right: var(--spacing-s);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}
.modify-permissions .modify-permissions--editor .modify-permissions--search__input-container .user-suggestion-details {
  height: 100%;
  width: 100%;
}
.modify-permissions .modify-permissions--editor .modify-permissions--search__input-container .user-suggestion-details__name {
  max-width: 600px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--search__input-container .user-suggestion-details__email {
  margin-top: var(--spacing-zero);
}
.modify-permissions .modify-permissions--editor .modify-permissions--search .input-search-group {
  height: 40px;
  border-radius: var(--border-radius-default);
}
.modify-permissions .modify-permissions--editor .modify-permissions--roles-container {
  flex: 1;
  overflow-y: auto;
  padding-right: var(--spacing-s);
}
.modify-permissions .modify-permissions--editor .modify-permissions--roles-container--full-width {
  padding-right: var(--spacing-xl);
}
.modify-permissions .modify-permissions--editor .modify-permissions--roles-container .permission-list {
  margin-bottom: var(--spacing-zero);
}
.modify-permissions .modify-permissions--editor .modify-permissions--roles-container__infobar .infobar-warn {
  background-color: rgba(242, 107, 58, 0.1);
}
.modify-permissions .modify-permissions--editor .modify-permissions--roles-container__infobar .infobar__text-wrapper p a {
  font-weight: normal;
  color: var(--content-color-brand);
}
.modify-permissions .modify-permissions--editor .modify-permissions--roles-container__infobar .infobar {
  margin: unset;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main {
  display: flex;
  align-items: center;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main .modify-permissions--main-title {
  flex: 1;
  margin-left: 8px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main .modify-permissions--main-roles {
  margin-right: 28px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main .modify-permissions--main-roles .dropdown {
  width: 170px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main .modify-permissions--main-roles .dropdown-button .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main .modify-permissions--main-roles .modify-permissions--main-roles--name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main.is-bulk {
  margin-left: 0;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main.is-bulk .modify-permissions--main-title {
  margin-left: 0;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main.is-bulk .modify-permissions--main-roles {
  margin-right: 4px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--main .modify-permissions--main-image .team-logo--image {
  height: 24px;
  width: 24px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--workspaces__team-access {
  display: inline-flex;
}
.modify-permissions .modify-permissions--editor .modify-permissions--workspaces__team-access .pm-icon {
  padding-top: 2px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--workspaces__team-access .btn-text {
  padding: 0;
  margin-left: 4px;
}
.modify-permissions .modify-permissions--editor .modify-permissions--workspaces__team-access .modify-permissions--workspaces__team-access-text {
  margin-left: 8px;
  color: var(--content-color-secondary);
}
.modify-permissions .modify-permissions--separator {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-s);
  background-color: var(--border-color-strong);
  width: 1px;
}
.modify-permissions .modify-permissions--workspaces {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.modify-permissions .modify-permissions--workspaces .modify-permissions--workspaces__shared-in {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: var(--spacing-xl);
}
.modify-permissions .modify-permissions--workspaces .modify-permissions--workspaces__shared-in .workspace-list-item-container {
  pointer-events: none;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.modify-permissions .modify-permissions--workspaces .modify-permissions--workspaces__shared-in .modify-permissions--workspaces__shared-in__is-loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.modify-permissions .modify-permissions--workspaces .modify-permissions--workspaces__shared-in--title {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
}
.modify-permissions .modify-permissions--workspaces .modify-permissions--workspaces__shared-in--team {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.modify-permissions .modify-permissions--workspaces .modify-permissions--workspaces__shared-in--team .modify-permissions--workspaces__shared-in--team-text {
  color: var(--content-color-secondary);
}
.modify-permissions .modify-permissions--workspaces .modify-permissions--workspaces__shared-in--team .info-button__icon {
  margin-left: 8px;
}

.permission-list-container {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.permission-list-container .permission-list--workspace {
  position: relative;
  display: flex;
  align-items: center;
}
.permission-list-container .permission-list--workspace__member-details,
.permission-list-container .permission-list--workspace__member-list-icon {
  cursor: pointer;
}
.permission-list-container .permission-list--workspace__member-details {
  margin-left: 8px;
}
.permission-list-container .permission-list--workspace__member-list-icon {
  padding-left: 16px;
}
.permission-list-container .permission-list--workspace__roles {
  position: absolute;
  right: 20px;
}
.permission-list-container .permission-list {
  margin: 4px 4px 8px 4px;
  padding-bottom: 8px;
}
.permission-list-container .permission-list .permission-list-item {
  position: relative;
  display: flex;
  align-items: center;
  margin: var(--spacing-l) 0;
}
.permission-list-container .permission-list .permission-list-item .permission-list-item--name {
  flex: 1;
  margin-left: 8px;
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permission-list-container .permission-list .permission-list-item .permission-list-item--name__user-group {
  cursor: pointer;
}
.permission-list-container .permission-list .permission-list-item .permission-list-item__user-group {
  width: var(--size-m);
  height: var(--size-m);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  cursor: pointer;
}
.permission-list-container .permission-list .permission-list-item .permission-list-item--info {
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 4px;
}
.permission-list-container .permission-list .permission-list-item .permission-list-item--info .permission-list-item--info-icon use {
  fill: var(--content-color-secondary);
}
.permission-list-container .permission-list .permission-list-item .permission-list-item--info .permission-list-item--info-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.permission-list-container .permission-list .permission-list-item .permission-list-item--info .permission-list-item--info-icon svg {
  color: var(--content-color-secondary);
}
.permission-list-container .permission-list .permission-list-item .permissions-list-item--permissions .permissions-list-item--role {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permission-list-container .permission-list .permission-list-item .permissions-list-item--permissions .dropdown {
  width: 170px;
}
.permission-list-container .permission-list .permission-list-item .permissions-list-item--permissions .dropdown-button .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: var(--size-s);
  font-weight: normal;
}
.permission-list-container .permission-list .permission-list-item .permissions-list-item--delete {
  margin-left: 8px;
  cursor: pointer;
}
.permission-list-container .permission-list .permisson-list-item:nth-of-type(1) {
  margin-top: 0;
}

.role-dropdown .dropdown-menu-item.is-disabled {
  cursor: not-allowed;
}

.permission-list-item__members-list {
  width: 240px;
  max-height: 300px;
}
.permission-list-item__members-list .tooltip-wrapper {
  padding: var(--spacing-xl);
}
.permission-list-item__members-list__title {
  display: flex;
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--spacing-xs);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.permission-list-item__members-list__title__group-name {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permission-list-item__members-list__title__no-members {
  max-width: unset;
}
.permission-list-item__members-list__content {
  max-height: 188px;
  overflow-y: auto;
  margin-bottom: var(--spacing-l);
}
.permission-list-item__members-list__content__no-members {
  margin-top: var(--spacing-xs);
}
.permission-list-item__members-list__content__no-members label {
  line-height: var(--line-height-m);
}
.permission-list-item__members-list__content__item {
  display: flex;
  margin-top: var(--spacing-s);
  align-items: center;
}
.permission-list-item__members-list__content__item__name {
  margin-left: var(--spacing-s);
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permission-list-item__members-list__no-members {
  margin-bottom: var(--spacing-s);
}

.permission-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.permission-error .permission-error--illustration {
  margin-bottom: var(--spacing-s);
}
.permission-error .permission-error--title,
.permission-error .permission-error--description {
  color: var(--content-color-primary);
  font-size: 12px;
}
.permission-error .permission-error--title {
  font-weight: 600;
  margin-bottom: var(--spacing-s);
  font-size: 14px;
}
.permission-error .permission-error--action {
  padding: 0 18px;
}
.permission-error .permission-error--description {
  margin-bottom: var(--spacing-l);
  text-align: center;
}
.permission-error .permission-error--description p {
  margin: 0px;
  line-height: 20px;
}

.permission-list-v2-container {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.permission-list-v2-container .permission-list--workspace {
  position: relative;
  display: flex;
  align-items: center;
}
.permission-list-v2-container .permission-list--workspace__member-details,
.permission-list-v2-container .permission-list--workspace__member-list-icon {
  cursor: pointer;
}
.permission-list-v2-container .permission-list--workspace__member-details {
  margin-left: var(--spacing-s);
}
.permission-list-v2-container .permission-list--workspace__member-list-icon {
  padding-left: var(--spacing-l);
}
.permission-list-v2-container .permission-list--workspace__roles {
  position: absolute;
  right: 20px;
}
.permission-list-v2-container .permission-list {
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-s) var(--spacing-xs);
  padding-bottom: var(--spacing-s);
}
.permission-list-v2-container .permission-list__public-info {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-m);
}
.permission-list-v2-container .permission-list__public-info__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-s);
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  height: var(--size-s);
  width: var(--size-s);
}
.permission-list-v2-container .permission-list .permission-list-item-v2 {
  position: relative;
  display: flex;
  align-items: center;
  margin: var(--spacing-m) var(--spacing-zero);
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permission-list-item--name {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permission-list-item--name__user-group {
  cursor: pointer;
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permission-list-item__user-group {
  width: var(--size-s);
  height: var(--size-s);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  cursor: pointer;
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permission-list-item--info {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs);
  margin-right: var(--spacing-xs);
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permission-list-item--info .permission-list-item--info-icon use {
  fill: var(--content-color-secondary);
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permission-list-item--info .permission-list-item--info-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permission-list-item--info .permission-list-item--info-icon svg {
  color: var(--content-color-secondary);
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permissions-list-item--permissions .permissions-list-item--role {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: var(--size-s);
  width: 72px;
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permissions-list-item--permissions .dropdown-button .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: var(--size-s);
  font-weight: normal;
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permissions-list-item--delete {
  display: flex;
  margin-left: var(--spacing-xs);
  height: var(--size-s);
  width: var(--size-s);
  cursor: pointer;
}
.permission-list-v2-container .permission-list .permission-list-item-v2 .permissions-list-item--delete--hide {
  opacity: 0;
  pointer-events: none;
}
.permission-list-v2-container .permission-list .permission-list-item-v2:nth-of-type(1) {
  margin-top: var(--spacing-l);
}

.role-dropdown .dropdown-menu-item.is-disabled {
  cursor: not-allowed;
}

.manage-role-dropdown {
  width: 100%;
  min-width: 250px;
  background-color: var(--background-color-primary);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  border: none;
}
.manage-role-dropdown .dropdown-menu-item.is-disabled {
  cursor: not-allowed;
}
.manage-role-dropdown .dropdown-menu-item {
  height: auto;
  padding: var(--spacing-zero);
}
.manage-role-dropdown .role-list-item {
  max-width: 100%;
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
}
.manage-role-dropdown .dropdown-menu-item span {
  white-space: unset;
}
.manage-role-dropdown .role-list-item-data {
  width: 100%;
}
.manage-role-dropdown .role-remove-button {
  color: var(--content-color-error);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.manage-role-dropdown .role-remove-button:hover {
  color: var(--content-color-constant);
  background-color: var(--base-color-error);
}

.permission-list-item__members-list {
  width: 240px;
  max-height: 300px;
  z-index: 10000;
}
.permission-list-item__members-list .tooltip-wrapper {
  padding: var(--spacing-xl);
}
.permission-list-item__members-list__title {
  display: flex;
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--spacing-xs);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.permission-list-item__members-list__title__group-name {
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permission-list-item__members-list__title__no-members {
  max-width: unset;
}
.permission-list-item__members-list__content {
  max-height: 188px;
  overflow-y: auto;
  margin-bottom: var(--spacing-l);
}
.permission-list-item__members-list__content__no-members {
  margin-top: var(--spacing-xs);
}
.permission-list-item__members-list__content__no-members label {
  line-height: var(--line-height-m);
}
.permission-list-item__members-list__content__item {
  display: flex;
  margin-top: var(--spacing-s);
  align-items: center;
}
.permission-list-item__members-list__content__item__name {
  margin-left: var(--spacing-s);
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.permission-list-item__members-list__no-members {
  margin-bottom: var(--spacing-s);
}

.add-entity-modal {
  display: flex;
  flex-direction: column;
  height: 320px;
}
@media (max-height: 400px) {
  .add-entity-modal {
    height: 75vh;
  }
}
.add-entity-modal__dropdown-options {
  width: 90%;
  display: flex;
}
.add-entity-modal__loading-indicator {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.add-entity-modal__dropdown-wrapper {
  width: 90%;
  display: flex;
}
.add-entity-modal .app-crash-wrapper {
  justify-content: flex-start;
}
.add-entity-modal .app-crash-wrapper .app-crash-header {
  font-size: var(--text-size-l);
}
.add-entity-modal .app-crash-wrapper .app-crash-content {
  font-size: var(--text-size-m);
  margin: var(--spacing-m) var(--spacing-zero);
}
.add-entity-modal .modal-content {
  padding: var(--spacing-l);
  padding-bottom: var(--spacing-zero);
}
.add-entity-modal .model-content__error {
  padding: var(--spacing-zero);
}
.add-entity-modal__label {
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--spacing-s);
}
.add-entity-modal .dropdown {
  display: flex;
}
.add-entity-modal .dropdown .dropdown-button {
  flex: 1;
  width: 100%;
}
.add-entity-modal__dropdown-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-entity-modal__dropdown-value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.add-entity-modal__dropdown-label-meta {
  margin-left: var(--spacing-s);
  display: flex;
  align-items: center;
  line-height: var(--line-height-m);
}
.add-entity-modal .add-entity__buttons {
  margin-top: var(--spacing-xxl);
  display: flex;
  flex-wrap: wrap;
}
.add-entity-modal .add-entity__buttons .add-entity__add-button {
  margin-right: var(--spacing-l);
}

.api-info-context-view-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.api-info-context-view-wrapper .right-context-bar__title {
  color: var(--content-color-primary);
}
.api-info-context-view-wrapper .api-info-context-view {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}
.api-info-context-view-wrapper .api-info-context-view__version-header {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
.api-info-context-view-wrapper .api-info-context-view__entity {
  margin-bottom: var(--spacing-l);
}
.api-info-context-view-wrapper .api-info-context-view__entity__label {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}
.api-info-context-view-wrapper .api-info-context-view__entity__content {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  display: flex;
  flex: 1;
  padding-right: var(--spacing-s);
  align-items: center;
  margin-top: var(--spacing-xs);
}
.api-info-context-view-wrapper .api-info-context-view__entity__content .btn {
  background-color: transparent;
}
.api-info-context-view-wrapper .api-info-context-view__entity__content .btn-icon {
  background-color: transparent;
  width: var(--size-xs);
  height: var(--size-xs);
}
.api-info-context-view-wrapper .api-info-context-view__entity__content .success-tick-icon {
  cursor: default;
}
.api-info-context-view-wrapper .api-info-context-view__entity__content .success-tick-icon use {
  fill: var(--content-color-success);
}
.api-info-context-view-wrapper .api-info-context-view__entity__content .success-tick-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.api-info-context-view-wrapper .api-info-context-view__entity__content .success-tick-icon svg {
  color: var(--content-color-success);
}
.api-info-context-view-wrapper .api-info-context-view__entity__content__id {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.api-info-context-view-wrapper .api-info-context-view__entity__content__id--entity {
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  padding: 6px var(--spacing-s) 6px var(--spacing-s);
  margin-right: var(--spacing-s);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-profile-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
}
.user-profile-wrapper .user-profile-pic-wrapper {
  cursor: pointer;
}
.user-profile-wrapper .user-profile-pic-wrapper .pm-user-avatar-icon {
  height: var(--size-s);
  width: var(--size-s);
}
.user-profile-wrapper .user-name-wrapper {
  margin-left: var(--spacing-s);
  font-weight: var(--text-weight-regular);
  cursor: pointer;
}
.user-profile-wrapper .is-not-action-link {
  cursor: text;
}

.api-changelog-context-view-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
}
.api-changelog-context-view-wrapper .right-context-bar-header {
  padding-bottom: var(--spacing-l);
}
.api-changelog-context-view-wrapper .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.api-changelog-context-view-wrapper__refresh-button__loading {
  cursor: default;
}
.api-changelog-context-view-wrapper__refresh-button__loading svg {
  animation: spin 1s linear infinite;
}
.api-changelog-context-view-wrapper .api-changelog {
  overflow-y: auto;
  color: var(--content-color-primary);
}

.api-changelog .activity-item__action {
  margin-left: 40px;
  display: flex;
  flex-direction: row;
}
.api-changelog .activity-item-restore {
  margin-left: var(--spacing-zero);
  padding-left: var(--spacing-zero);
  border-width: var(--spacing-zero);
}
.api-changelog hr {
  margin: 0;
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
  margin-right: var(--spacing-l);
  border: unset;
  border-top: solid 1px var(--border-color-default);
}
.api-changelog .no-changes-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--content-color-primary);
  height: 180px;
}
.api-changelog .no-changes-wrapper__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: var(--spacing-m) var(--spacing-m) var(--spacing-l);
  box-sizing: border-box;
}
.api-changelog .no-changes-wrapper__content__title {
  text-align: center;
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
}
.api-changelog .api-changelog__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
}
.api-changelog .api-changelog__header .api-changelog__header_left-section {
  display: flex;
  flex-direction: row;
}
.api-changelog .api-changelog__header .api-changelog__header_left-section .api-changelog__header_left-section__arrow {
  margin-right: var(--spacing-s);
}
.api-changelog .api-changelog__header .api-changelog__header_left-section .api-changelog__header_left-section__arrow .btn {
  padding: var(--spacing-zero);
  height: var(--size-xs);
}
.api-changelog .api-changelog__header .api-changelog__header_left-section .api-changelog__header_left-section__name {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
}
.api-changelog .api-changelog__header .api-changelog__header_left-section .api-changelog__header_left-section__name i {
  margin-left: var(--spacing-s);
}
.api-changelog .api-changelog__header .api-changelog__header_left-section .is-clickable {
  cursor: pointer;
}
.api-changelog .api-changelog__header .api-changelog__header__create-release {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  margin-right: var(--spacing-l);
}
.api-changelog .api-changelog__header .api-changelog__header__create-release .button-icon {
  margin-right: var(--spacing-xs);
}
.api-changelog .api-changelog__header .api-changelog__header__release-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.api-changelog .api-changelog__header .api-changelog__header__release-actions__tag-text {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-right: var(--spacing-s);
}
.api-changelog .api-changelog__header .api-changelog__header__release-actions__tag-icon {
  margin-right: var(--spacing-xs);
}
.api-changelog .api-changelog__header .api-changelog__header__release-actions__dropdown {
  height: var(--size-s);
  margin-right: var(--spacing-l);
}
.api-changelog .api-changelog__content {
  margin-left: var(--spacing-s);
  padding-left: var(--spacing-s);
  border-left: 1px var(--border-style-solid) var(--border-color-default);
}
.api-changelog .api-changelog__content__description {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  padding-left: var(--spacing-s);
  margin-top: var(--spacing-xs);
  word-break: break-word;
}
.api-changelog .api-changelog__content__release-created-at {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  padding-left: var(--spacing-s);
  margin-top: var(--spacing-xs);
  color: var(--content-color-tertiary);
}
.api-changelog .api-changelog__content__view-release {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--blue-60);
  align-items: center;
  display: flex;
  margin-top: var(--spacing-xs);
  padding-left: var(--spacing-s);
}
.api-changelog .api-changelog__content__view-release use {
  fill: var(--blue-60);
}
.api-changelog .api-changelog__content__view-release svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--blue-60);
}
.api-changelog .api-changelog__content__view-release svg {
  color: var(--blue-60);
}
.api-changelog .api-changelog__content .changelog {
  margin-bottom: var(--spacing-l);
}
.api-changelog .api-changelog__content .activity-item-header {
  padding-bottom: var(--spacing-zero);
}
.api-changelog .api-changelog__content .activity-item-details {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-left: var(--spacing-s);
}
.api-changelog .api-changelog__content .changelog-content-container {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.api-changelog .api-changelog__content .activity-feed-date-group {
  margin: var(--spacing-zero);
  padding-left: var(--spacing-s);
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-s);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}

.api-source-control {
  height: 100%;
  margin: var(--spacing-zero) var(--spacing-l) var(--spacing-zero) var(--spacing-s);
}
.api-source-control__subHeader {
  margin-bottom: var(--spacing-s);
}
.api-source-control__subHeader__button {
  padding-left: var(--spacing-s) !important;
  padding-right: var(--spacing-s) !important;
}
.api-source-control__subHeader__counter {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-default);
  word-break: normal;
}
.api-source-control__subHeader__createPullRequest__disabled-link, .api-source-control__subHeader__createPullRequest__disabled-link:hover {
  text-decoration: unset !important;
  color: var(--content-color-tertiary) !important;
  cursor: not-allowed !important;
}
.api-source-control__changesInPostman {
  max-height: 200px;
  min-height: 30px;
}
.api-source-control__changesInPostman__title:hover {
  cursor: pointer;
}
.api-source-control__changesInPostman__filesList {
  max-height: 150px;
  overflow-y: auto;
}
.api-source-control__changesInPostman__aetherBadge {
  margin: var(--spacing-zero);
}
.api-source-control__commitChanges {
  padding-top: var(--spacing-l);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.api-source-control__commitChanges__textArea {
  margin-bottom: var(--spacing-m);
}
.api-source-control__commitChanges__textInput {
  margin-left: 21px;
}
.api-source-control__commitChanges__radioOption > div:first-child {
  width: 100%;
}
.api-source-control__commitChanges__radioOption label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 95%;
}
.api-source-control__commitsList {
  min-height: 30px;
  margin-bottom: var(--spacing-xxxl);
}
.api-source-control__commitsList__title:hover {
  cursor: pointer;
}
.api-source-control__commitsList__list {
  overflow-y: auto;
  margin-bottom: var(--spacing-xxxl);
  margin-left: var(--spacing-xl);
}
.api-source-control__commitsList__list__listItem-title:hover {
  cursor: pointer;
}
.api-source-control__commitsList__list__listItem-unPushed {
  background-color: var(--highlight-background-color-tertiary);
  padding: 2px;
  border-radius: var(--border-radius-default);
}
.api-source-control__commitsList__list__listItem-commit {
  margin-left: var(--spacing-xs);
}

.api-source-control__context-bar-icon--unread:after {
  content: "";
  background-color: var(--base-color-brand);
  position: absolute;
  border-radius: 999px;
  top: -1px;
  right: -1px;
  height: 6px;
  width: 6px;
}

.api-source-control__on-prem-state {
  margin: auto;
}

.api-source-control__sections {
  min-height: 100px;
  max-height: 100%;
}

.api-source-control__changesInPostman__title-collapse-button {
  padding: 2px;
  height: auto;
}

.api-source-control__changesInPostman__fileEntry__action {
  display: none;
}

.api-source-control__changesInPostman__fileEntry:hover .api-source-control__changesInPostman__fileEntry__action {
  display: initial;
}

.api-diff-view-name-label__breadcrumb-link {
  min-width: 0;
}

.api-diff-view-name-label__breadcrumb-link:hover .api-diff-view-name-label__breadcrumb-link-child {
  color: var(--content-color-link);
  text-decoration: underline;
}
.api-diff-view-name-label__breadcrumb-link:hover .api-diff-view-name-label__breadcrumb-link-child--icon svg {
  color: var(--content-color-link);
}

.switch-branch-modal {
  display: flex;
  max-height: 70vh;
}
.switch-branch-modal .modal-content {
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-zero) var(--spacing-xl);
}
.switch-branch-modal .switch-branch-modal__content {
  margin-bottom: var(--spacing-l);
}
.switch-branch-modal .switch-branch-modal__content__label {
  font-weight: var(--text-weight-medium);
}
.switch-branch-modal .switch-branch-modal__content__header {
  display: flex;
  flex-direction: row;
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-m);
}
.switch-branch-modal .switch-branch-modal__footer .switch-branch-modal__footer__buttons {
  display: flex;
  flex-wrap: wrap;
}
.switch-branch-modal .switch-branch-modal__footer .switch-branch-modal__footer__buttons .switch-branch-modal__footer__add-button {
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-xl);
}
.switch-branch-modal .switch-branch-modal__footer .switch-branch-modal__footer__buttons .switch-branch-modal__footer__button {
  height: var(--size-m);
}
.switch-branch-modal .modal-footer {
  padding: var(--spacing-zero);
}

.connect-repo-tab {
  overflow: auto;
  padding: var(--spacing-xxl);
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}
.connect-repo-tab .api-repository-connecting {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}
.connect-repo-tab .api-repository-connecting__illustration_wrapper {
  background-color: var(--grey-00);
  width: 60px;
  height: 60px;
  border-radius: var(--border-radius-default);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-m);
}
.connect-repo-tab .api-repository-connecting__illustration__github {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/github_logo.png");
}
.connect-repo-tab .api-repository-connecting__illustration__bitbucket {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/bitbucket_logo.png");
}
.connect-repo-tab .api-repository-connecting__illustration__gitlab {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/gitlab.png");
}
.connect-repo-tab .api-repository-connecting__illustration__azure {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/azure.png");
}
.connect-repo-tab .api-repository-connecting__text-primary {
  margin-bottom: var(--spacing-l);
  font-size: var(--text-size-xxl);
  font-weight: var(--text-weight-bold);
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
}
.connect-repo-tab .api-repository-connecting__text-secondary {
  font-size: var(--text-size-l);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xl);
}
.connect-repo-tab .api-repository-connecting__cancel-btn {
  background-color: var(--background-color-tertiary);
}
.connect-repo-tab .api-repository-connecting__cancel-btn:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.connect-repo-tab .app-crash-wrapper .app-crash-header {
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-xs);
}
.connect-repo-tab .app-crash-wrapper .app-crash-content {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-xs);
}
.connect-repo-tab .app-crash-wrapper .btn {
  color: var(--blue-60);
}

.api-repo-enterprise-config {
  width: 480px;
}
.api-repo-enterprise-config__header {
  margin-bottom: var(--spacing-xl);
}
.api-repo-enterprise-config__panel__description {
  padding-left: var(--spacing-xs);
}
.api-repo-enterprise-config__input-wrapper {
  margin-bottom: var(--spacing-l);
}
.api-repo-enterprise-config__banner {
  margin-bottom: var(--spacing-l);
}
.api-repo-enterprise-config-access-token {
  margin-bottom: var(--spacing-l);
}
.api-repo-enterprise-config-url {
  margin-bottom: var(--spacing-l);
}
.api-repo-enterprise-config__domain-input {
  margin-top: var(--spacing-s);
}
.api-repo-enterprise-config__token-input {
  margin-top: var(--spacing-s);
}
.api-repo-enterprise-config__actions {
  display: flex;
}
.api-repo-enterprise-config__actions__primary {
  padding-right: var(--spacing-s);
}
.api-repo-enterprise-config__actions__secondary {
  margin-left: var(--spacing-s);
}
.api-repo-enterprise-config__on-prem__actions {
  margin-left: auto;
}
.api-repo-enterprise-config__connect-repo {
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}
.api-repo-enterprise-config__connect-repo__buttons {
  margin-top: var(--spacing-l);
  margin-left: auto;
}
.api-repo-enterprise-config__connect-repo__domain {
  margin-left: var(--spacing-s);
}

.api-repo-enterprise-domain-selection {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.hoverable-tooltip__message {
  padding-bottom: var(--spacing-l);
}
.hoverable-tooltip-wrapper {
  width: 100%;
}

.api-repo-integration-configuration__header {
  width: 480px;
  margin-bottom: var(--spacing-xl);
}
.api-repo-integration-configuration__content {
  width: 480px;
}
.api-repo-integration-configuration__branch-heading {
  margin-bottom: var(--spacing-m);
  margin-top: var(--spacing-m);
}
.api-repo-integration-configuration__user-info {
  margin-bottom: var(--spacing-l);
  display: flex;
  align-items: center;
}
.api-repo-integration-configuration__user-info__title {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-bold);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.api-repo-integration-configuration__user-info-details {
  display: flex;
  padding-top: var(--spacing-s);
}
.api-repo-integration-configuration__user-info-details__username {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.api-repo-integration-configuration__user-info .avatar {
  padding-right: var(--spacing-s);
}
.api-repo-integration-configuration__user-info__title, .api-repo-integration-configuration__select-api__title, .api-repo-integration-configuration__select-version__title, .api-repo-integration-configuration__select-repo__title, .api-repo-integration-configuration__select-org__title, .api-repo-integration-configuration__select-branch-develop__title, .api-repo-integration-configuration__select-branch-main__title, .api-repo-integration-configuration__select-schema-directory__title, .api-repo-integration-configuration__select-collection-directory__title, .api-repo-integration-configuration__folderPath__selection__title, .api-repo-integration-configuration__repo-custom-input__title, .api-repo-integration-configuration__schema-sync-banner__title {
  display: block;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-bold);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.api-repo-integration-configuration__user-info__title__label, .api-repo-integration-configuration__select-api__title__label, .api-repo-integration-configuration__select-version__title__label, .api-repo-integration-configuration__select-repo__title__label, .api-repo-integration-configuration__select-org__title__label, .api-repo-integration-configuration__select-branch-develop__title__label, .api-repo-integration-configuration__select-branch-main__title__label, .api-repo-integration-configuration__select-schema-directory__title__label, .api-repo-integration-configuration__select-collection-directory__title__label, .api-repo-integration-configuration__folderPath__selection__title__label, .api-repo-integration-configuration__repo-custom-input__title__label, .api-repo-integration-configuration__schema-sync-banner__title__label {
  display: block;
  margin-bottom: 10px;
}
.api-repo-integration-configuration__user-info__description, .api-repo-integration-configuration__select-api__description, .api-repo-integration-configuration__select-version__description, .api-repo-integration-configuration__select-repo__description, .api-repo-integration-configuration__select-org__description, .api-repo-integration-configuration__select-branch-develop__description, .api-repo-integration-configuration__select-branch-main__description, .api-repo-integration-configuration__select-schema-directory__description, .api-repo-integration-configuration__select-collection-directory__description, .api-repo-integration-configuration__folderPath__selection__description, .api-repo-integration-configuration__repo-custom-input__description, .api-repo-integration-configuration__schema-sync-banner__description {
  margin-bottom: var(--spacing-xs);
}
.api-repo-integration-configuration__user-info__dropdown-container, .api-repo-integration-configuration__select-api__dropdown-container, .api-repo-integration-configuration__select-version__dropdown-container, .api-repo-integration-configuration__select-repo__dropdown-container, .api-repo-integration-configuration__select-org__dropdown-container, .api-repo-integration-configuration__select-branch-develop__dropdown-container, .api-repo-integration-configuration__select-branch-main__dropdown-container, .api-repo-integration-configuration__select-schema-directory__dropdown-container, .api-repo-integration-configuration__select-collection-directory__dropdown-container, .api-repo-integration-configuration__folderPath__selection__dropdown-container, .api-repo-integration-configuration__repo-custom-input__dropdown-container, .api-repo-integration-configuration__schema-sync-banner__dropdown-container {
  display: flex;
  align-items: center;
  position: relative;
}
.api-repo-integration-configuration__user-info__dropdown-container__dropdown, .api-repo-integration-configuration__select-api__dropdown-container__dropdown, .api-repo-integration-configuration__select-version__dropdown-container__dropdown, .api-repo-integration-configuration__select-repo__dropdown-container__dropdown, .api-repo-integration-configuration__select-org__dropdown-container__dropdown, .api-repo-integration-configuration__select-branch-develop__dropdown-container__dropdown, .api-repo-integration-configuration__select-branch-main__dropdown-container__dropdown, .api-repo-integration-configuration__select-schema-directory__dropdown-container__dropdown, .api-repo-integration-configuration__select-collection-directory__dropdown-container__dropdown, .api-repo-integration-configuration__folderPath__selection__dropdown-container__dropdown, .api-repo-integration-configuration__repo-custom-input__dropdown-container__dropdown, .api-repo-integration-configuration__schema-sync-banner__dropdown-container__dropdown {
  width: 100%;
  margin-top: var(--spacing-xs);
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--flow-input-border-color);
  border-radius: var(--border-radius-default);
}
.api-repo-integration-configuration__user-info__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-api__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-version__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-repo__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-org__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-branch-develop__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-branch-main__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-schema-directory__dropdown-container__dropdown__button, .api-repo-integration-configuration__select-collection-directory__dropdown-container__dropdown__button, .api-repo-integration-configuration__folderPath__selection__dropdown-container__dropdown__button, .api-repo-integration-configuration__repo-custom-input__dropdown-container__dropdown__button, .api-repo-integration-configuration__schema-sync-banner__dropdown-container__dropdown__button {
  width: 100%;
  justify-content: space-between;
}
.api-repo-integration-configuration__user-info__dropdown-container__input, .api-repo-integration-configuration__select-api__dropdown-container__input, .api-repo-integration-configuration__select-version__dropdown-container__input, .api-repo-integration-configuration__select-repo__dropdown-container__input, .api-repo-integration-configuration__select-org__dropdown-container__input, .api-repo-integration-configuration__select-branch-develop__dropdown-container__input, .api-repo-integration-configuration__select-branch-main__dropdown-container__input, .api-repo-integration-configuration__select-schema-directory__dropdown-container__input, .api-repo-integration-configuration__select-collection-directory__dropdown-container__input, .api-repo-integration-configuration__folderPath__selection__dropdown-container__input, .api-repo-integration-configuration__repo-custom-input__dropdown-container__input, .api-repo-integration-configuration__schema-sync-banner__dropdown-container__input {
  font-weight: var(--text-weight-regular) !important;
}
.api-repo-integration-configuration__user-info__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-api__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-version__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-repo__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-org__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-branch-develop__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-branch-main__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-schema-directory__dropdown-container__loading-indicator, .api-repo-integration-configuration__select-collection-directory__dropdown-container__loading-indicator, .api-repo-integration-configuration__folderPath__selection__dropdown-container__loading-indicator, .api-repo-integration-configuration__repo-custom-input__dropdown-container__loading-indicator, .api-repo-integration-configuration__schema-sync-banner__dropdown-container__loading-indicator {
  background-color: var(--background-color-secondary);
  position: absolute;
  right: var(--spacing-s);
  top: calc(-1 * var(--spacing-xl));
}
.api-repo-integration-configuration__user-info__value, .api-repo-integration-configuration__select-api__value, .api-repo-integration-configuration__select-version__value, .api-repo-integration-configuration__select-repo__value, .api-repo-integration-configuration__select-org__value, .api-repo-integration-configuration__select-branch-develop__value, .api-repo-integration-configuration__select-branch-main__value, .api-repo-integration-configuration__select-schema-directory__value, .api-repo-integration-configuration__select-collection-directory__value, .api-repo-integration-configuration__folderPath__selection__value, .api-repo-integration-configuration__repo-custom-input__value, .api-repo-integration-configuration__schema-sync-banner__value {
  margin-left: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  display: inline-flex;
}
.api-repo-integration-configuration__user-info__error, .api-repo-integration-configuration__select-api__error, .api-repo-integration-configuration__select-version__error, .api-repo-integration-configuration__select-repo__error, .api-repo-integration-configuration__select-org__error, .api-repo-integration-configuration__select-branch-develop__error, .api-repo-integration-configuration__select-branch-main__error, .api-repo-integration-configuration__select-schema-directory__error, .api-repo-integration-configuration__select-collection-directory__error, .api-repo-integration-configuration__folderPath__selection__error, .api-repo-integration-configuration__repo-custom-input__error, .api-repo-integration-configuration__schema-sync-banner__error {
  display: flex;
  height: var(--spacing-l);
  color: var(--content-color-error);
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xs);
}
.api-repo-integration-configuration__user-info__error__message, .api-repo-integration-configuration__select-api__error__message, .api-repo-integration-configuration__select-version__error__message, .api-repo-integration-configuration__select-repo__error__message, .api-repo-integration-configuration__select-org__error__message, .api-repo-integration-configuration__select-branch-develop__error__message, .api-repo-integration-configuration__select-branch-main__error__message, .api-repo-integration-configuration__select-schema-directory__error__message, .api-repo-integration-configuration__select-collection-directory__error__message, .api-repo-integration-configuration__folderPath__selection__error__message, .api-repo-integration-configuration__repo-custom-input__error__message, .api-repo-integration-configuration__schema-sync-banner__error__message {
  padding-left: var(--spacing-xs);
}
.api-repo-integration-configuration__user-info__error__icon, .api-repo-integration-configuration__select-api__error__icon, .api-repo-integration-configuration__select-version__error__icon, .api-repo-integration-configuration__select-repo__error__icon, .api-repo-integration-configuration__select-org__error__icon, .api-repo-integration-configuration__select-branch-develop__error__icon, .api-repo-integration-configuration__select-branch-main__error__icon, .api-repo-integration-configuration__select-schema-directory__error__icon, .api-repo-integration-configuration__select-collection-directory__error__icon, .api-repo-integration-configuration__folderPath__selection__error__icon, .api-repo-integration-configuration__repo-custom-input__error__icon, .api-repo-integration-configuration__schema-sync-banner__error__icon {
  margin-top: 2px;
}
.api-repo-integration-configuration__user-info__error__alt, .api-repo-integration-configuration__select-api__error__alt, .api-repo-integration-configuration__select-version__error__alt, .api-repo-integration-configuration__select-repo__error__alt, .api-repo-integration-configuration__select-org__error__alt, .api-repo-integration-configuration__select-branch-develop__error__alt, .api-repo-integration-configuration__select-branch-main__error__alt, .api-repo-integration-configuration__select-schema-directory__error__alt, .api-repo-integration-configuration__select-collection-directory__error__alt, .api-repo-integration-configuration__folderPath__selection__error__alt, .api-repo-integration-configuration__repo-custom-input__error__alt, .api-repo-integration-configuration__schema-sync-banner__error__alt {
  margin-top: var(--spacing-xs);
}
.api-repo-integration-configuration__user-info__inputContainer, .api-repo-integration-configuration__select-api__inputContainer, .api-repo-integration-configuration__select-version__inputContainer, .api-repo-integration-configuration__select-repo__inputContainer, .api-repo-integration-configuration__select-org__inputContainer, .api-repo-integration-configuration__select-branch-develop__inputContainer, .api-repo-integration-configuration__select-branch-main__inputContainer, .api-repo-integration-configuration__select-schema-directory__inputContainer, .api-repo-integration-configuration__select-collection-directory__inputContainer, .api-repo-integration-configuration__folderPath__selection__inputContainer, .api-repo-integration-configuration__repo-custom-input__inputContainer, .api-repo-integration-configuration__schema-sync-banner__inputContainer {
  margin: var(--spacing-s) var(--spacing-zero);
}
.api-repo-integration-configuration__select-schema-directory__title, .api-repo-integration-configuration__select-collection-directory__title {
  margin-bottom: var(--spacing-xs);
}
.api-repo-integration-configuration__select-schema-directory__dropdown-container, .api-repo-integration-configuration__select-collection-directory__dropdown-container {
  margin-top: calc(var(--spacing-xs) / 2);
}
.api-repo-integration-configuration__dropdown-previous-level-trigger {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.api-repo-integration-configuration__dropdown-item-label {
  display: flex;
  width: 414px;
}
.api-repo-integration-configuration__dropdown-item-label__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.api-repo-integration-configuration__dropdown-selected-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--text-weight-regular);
}
.api-repo-integration-configuration__dropdown-item-icon {
  margin-right: var(--spacing-xs);
}
.api-repo-integration-configuration .action-button {
  display: flex;
  width: 480px;
  margin-top: var(--spacing-l);
}
.api-repo-integration-configuration .action-button__configure {
  width: 140px;
  margin-right: var(--spacing-l);
}
.api-repo-integration-configuration__non-enterprise__buttons {
  margin-top: var(--spacing-l);
}

.api-repo-integration-configuration__select-version__menu {
  width: 478px;
}
.api-repo-integration-configuration__select-version__menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.api-repo-integration-configuration__select-branch-develop__dropdown-container__menu .dropdown-menu {
  padding-bottom: var(--spacing-zero);
}
.api-repo-integration-configuration__select-branch-develop__dropdown-container__menu__create-branch-menuitem {
  position: sticky;
  bottom: var(--spacing-zero);
  background-color: var(--background-color-primary);
  padding-bottom: var(--spacing-s);
  border-bottom-left-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
}
.api-repo-integration-configuration__select-branch-develop__dropdown-container__menu__create-branch-menuitem hr {
  margin-right: var(--spacing-zero);
  margin-left: var(--spacing-zero);
}
.api-repo-integration-configuration__select-branch-develop__dropdown-container__menu__create-branch-menuitem span {
  margin-left: var(--spacing-xs);
}

.reauth-menu-container {
  display: flex;
  flex-direction: column;
}
.reauth-menu-container .reauth-menu {
  padding: var(--spacing-zero) var(--spacing-s);
  display: flex;
  align-items: baseline;
  font-size: var(--text-size-s);
}

.api-repo-integration-configuration__select-branch-develop__dropdown-container__dropdown__alt .api-repo-integration-configuration__select-branch-develop__error__message {
  margin-top: var(--spacing-zero) !important;
}
.api-repo-integration-configuration__select-branch-develop__dropdown-container__menu__alt .dropdown-menu {
  width: 100%;
  overflow-x: hidden;
}
.api-repo-integration-configuration__select-branch-develop__dropdown-container__menu__alt .dropdown-menu-item {
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
}
.api-repo-integration-configuration__select-branch-develop__dropdown-container__menu__alt .api-repo-integration-configuration__dropdown-item-wrapper {
  width: 100%;
}

.api-repo-integration-configuration__select-api__title__label {
  margin-bottom: var(--spacing-zero);
}

.create-branch-from-scratch-menu-item {
  margin-bottom: var(--spacing-s);
}

.api-repo-integration-configuration__select-schema-directory__dropdown-container__loading-indicator,
.api-repo-integration-configuration__select-collection-directory__dropdown-container__loading-indicator {
  top: var(--spacing-m);
}

.search-highlighted-api-git {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}

.api-repository-connect {
  font-weight: var(--text-weight-ragular);
  margin: 0;
}
.api-repository-connect.btn:hover {
  background-color: var(--highlight-background-color-tertiary);
}
.api-repository-connect.btn {
  background-color: var(--background-color-tertiary);
  margin-right: var(--spacing-s);
}
.api-repository-connect-dropdown {
  padding-right: var(--spacing-s);
}
.api-repository-connect-dropdown .dropdown-menu {
  width: 280px;
  padding-top: var(--spacing-zero);
}
.api-repository-connect-dropdown .dropdown-menu-item {
  margin-bottom: var(--spacing-xs);
}
.api-repository-connect-dropdown__learn-more {
  padding: var(--spacing-l);
}
.api-repository-connect-dropdown__disabled {
  cursor: not-allowed;
}
.api-repository-connect__illustration-wrapper {
  background-color: var(--grey-00);
  margin-right: var(--spacing-xs);
  border-radius: var(--border-radius-default);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}
.api-repository-connect__illustration__github {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/github_logo.png");
}
.api-repository-connect__illustration__bitbucket {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/bitbucket_logo.png");
}
.api-repository-connect__illustration__bitbucketv3 {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/bitbucket_logo.png");
}
.api-repository-connect__illustration__gitlab {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/gitlab.png");
}
.api-repository-connect__illustration__azure {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/azure.png");
}

.hoverable-tooltip-wrapper {
  width: auto;
  flex-grow: 1;
}

.connect-repository-button {
  font-weight: var(--text-weight-regular);
}
.connect-repository-button .dropdown-caret {
  margin-left: var(--spacing-xs);
}

.api-repository-integration-entity-select-dropdown__empty-state {
  margin: 0 var(--spacing-l);
  cursor: pointer;
  max-inline-size: -webkit-fill-available;
  width: -webkit-fill-available;
}

.api-repository-integration-entity-select-dropdown.is-open {
  border: var(--border-width-l) var(--border-style-solid) var(--blue-30);
}

.api-repository-integration-entity-select-dropdown.is-error {
  border: var(--border-width-default) var(--border-style-solid) var(--red-60);
}

.api-repository-integration-entity-select-dropdown-menu {
  overflow-x: hidden;
}
.api-repository-integration-entity-select-dropdown-menu__loader {
  display: flex;
  justify-content: center;
  margin: var(--spacing-xs);
}

.release__header .release__header__gitTag {
  margin-left: var(--spacing-s);
  display: flex;
  line-height: var(--line-height-s);
  align-items: center;
}
.release__header .release__header__gitTag__text {
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
  margin-left: var(--spacing-xs);
}

.publish-api-modal__content .version-label {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-l);
}
.publish-api-modal__content .version-notes {
  margin-bottom: var(--spacing-l);
}
.publish-api-modal__content .publish-elements {
  margin-bottom: var(--spacing-xxl);
}
.publish-api-modal__content .api-discoverable {
  margin-bottom: var(--spacing-l);
}
.publish-api-modal .publish-api-checkbox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.publish-api-modal .publish-api-checkbox > span {
  display: flex;
  width: 100%;
}
.publish-api-modal .publish-api-checkbox > div > span {
  color: var(--content-color-warning);
}

.release-tab-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.release-tab-contents {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.release-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.release-tab {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  min-height: 0;
}
.release-tab .release-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}
.release-tab .release-content .release-content-pane {
  overflow-y: hidden;
  overflow-x: auto;
}

.release-sub-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--background-color-primary);
  padding: var(--spacing-m) var(--spacing-s) var(--spacing-zero) var(--spacing-zero);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  min-width: 600px;
}
.release-sub-header .release-tab .release-tab-title {
  display: flex;
  align-items: center;
  position: relative;
}
.release-sub-header .release-tab .release-tab-title .release-tab-title__icon {
  padding-right: var(--spacing-s);
}
.release-sub-header .release-tab .release-tab-title .release-tab-title__icon use {
  fill: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title .release-tab-title__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title .release-tab-title__icon svg {
  color: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title .release-tab-title__label {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title:hover .release-tab-title__icon use, .release-sub-header .release-tab .release-tab-title.is-hovered .release-tab-title__icon use {
  fill: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title:hover .release-tab-title__icon svg:not([class*=PartnerIcon]) > path[fill], .release-sub-header .release-tab .release-tab-title.is-hovered .release-tab-title__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title:hover .release-tab-title__icon svg, .release-sub-header .release-tab .release-tab-title.is-hovered .release-tab-title__icon svg {
  color: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title:hover .release-tab-title__label, .release-sub-header .release-tab .release-tab-title.is-hovered .release-tab-title__label {
  color: var(--content-color-secondary);
}
.release-sub-header .release-tab .release-tab-title .release-tab-title__comment-badge {
  height: var(--size-xs);
  min-width: var(--size-xs);
  background-color: var(--base-color-brand);
  font-size: var(--text-size-s);
  color: var(--content-color-constant);
  font-weight: var(--font-weight-medium);
  border-radius: var(--border-radius-default);
  position: absolute;
  top: -15px;
  right: -10px;
  padding: var(--spacing-zero) 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.release-sub-header .release-tab.is-active .release-tab-title__label {
  color: var(--content-color-primary);
}
.release-sub-header .release-tab.is-active .release-tab-title__icon use {
  fill: var(--content-color-primary);
}
.release-sub-header .release-tab.is-active .release-tab-title__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.release-sub-header .release-tab.is-active .release-tab-title__icon svg {
  color: var(--content-color-primary);
}
.release-sub-header .release-tab.is-active:hover .release-tab-title__label, .release-sub-header .release-tab.is-active.is-hovered .release-tab-title__label {
  color: var(--content-color-primary);
}
.release-sub-header .release-tab.is-active:hover .release-tab-title__icon use, .release-sub-header .release-tab.is-active.is-hovered .release-tab-title__icon use {
  fill: var(--content-color-primary);
}
.release-sub-header .release-tab.is-active:hover .release-tab-title__icon svg:not([class*=PartnerIcon]) > path[fill], .release-sub-header .release-tab.is-active.is-hovered .release-tab-title__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.release-sub-header .release-tab.is-active:hover .release-tab-title__icon svg, .release-sub-header .release-tab.is-active.is-hovered .release-tab-title__icon svg {
  color: var(--content-color-primary);
}

.release-view--loading {
  display: flex;
  flex: 1;
}

.update-release-modal {
  max-height: 90vh;
  overflow: visible;
}
.update-release-modal .update-release-modal__container-header > div:first-child {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: var(--spacing-zero);
  padding-left: var(--spacing-l);
}
.update-release-modal .update-release-modal__container {
  margin-bottom: var(--spacing-zero);
  overflow: visible;
}
.update-release-modal .update-release-modal__container .update-release-modal__content--loader {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__banner {
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__banner .link-text {
  color: var(--content-color-secondary);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__banner .link-text .aether-link-text:hover {
  border-bottom: none;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__name {
  margin-bottom: var(--spacing-l);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__name .update-release-modal__content__name__label {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
  font-weight: var(--text-weight-bold);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__summary {
  margin-bottom: var(--spacing-l);
  margin-top: var(--spacing-xs);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__summary .update-release-modal__content__summary__label {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
  font-weight: var(--text-weight-bold);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__summary .textarea {
  height: 74px;
  font-size: var(--text-size-m);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__visibility {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-l);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__visibility .update-release-modal__content__visibility__checkbox {
  height: 14px;
  min-width: 14px;
  margin-right: var(--spacing-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__visibility .is-selected {
  background: var(--blue-60);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__visibility .update-release-modal__content__visibility__label {
  cursor: pointer;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  user-select: none;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__visibility .update-release-modal__content__visibility__info {
  margin-left: var(--spacing-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag__dropdown {
  display: flex;
  margin-top: var(--spacing-xs);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag__dropdown .dropdown {
  flex: 1;
  min-width: 0;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: calc(var(--spacing-m) / 2);
  padding-bottom: calc(var(--spacing-m) / 2);
  padding-left: var(--spacing-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button .update-release-modal__content__tag__dropdown--value {
  display: flex;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button .update-release-modal__content__tag__dropdown--value .tag-icon {
  height: 12px;
  width: 12px;
  margin-right: var(--spacing-xs);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button .update-release-modal__content__tag__dropdown--value .tag-placeholder {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag {
  position: relative;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__error {
  margin-top: var(--spacing-xs);
  color: var(--highlight-background-color-error);
  font-size: var(--text-size-m);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__error .link-text {
  color: var(--highlight-background-color-error);
  line-height: var(--line-height-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__error .link-text .aether-link-text {
  border-bottom: none;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__label-text {
  height: 40px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__label-text .update-release-modal__content__tag--label {
  font-weight: var(--text-weight-bold);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__label-text .update-release-modal__content__tag--text {
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__dropdown {
  display: flex;
  margin-top: var(--spacing-s);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__dropdown .dropdown {
  flex: 1;
  min-width: 0;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: calc(var(--spacing-m) / 2);
  padding-bottom: calc(var(--spacing-m) / 2);
  padding-left: var(--spacing-s);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button .update-release-modal__content__tag__dropdown--value {
  display: flex;
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button .update-release-modal__content__tag__dropdown--value .tag-icon {
  height: 12px;
  width: 12px;
  margin-right: var(--spacing-xs);
}
.update-release-modal .update-release-modal__container .update-release-modal__content .update-release-modal__content__tag .update-release-modal__content__tag__dropdown .dropdown .update-release-modal__content__tag__dropdown--button .update-release-modal__content__tag__dropdown--value .tag-placeholder {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}
.update-release-modal .update-release-modal__footer {
  padding-top: var(--spacing-l);
  flex-basis: 72px;
  justify-content: flex-end;
}
.update-release-modal .update-release-modal__footer .update-release-modal__footer__add-button {
  margin-left: var(--spacing-s);
}

.update-release-modal__content__tag__dropdown__check-icon {
  height: var(--size-xs);
  width: var(--size-xs);
  margin-right: var(--spacing-s);
}

.release-changelog-context-view-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
}
.release-changelog-context-view-wrapper .right-context-bar-header {
  padding-bottom: var(--spacing-l);
}
.release-changelog-context-view-wrapper .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.release-changelog-context-view-wrapper__refresh-button__loading {
  cursor: default;
}
.release-changelog-context-view-wrapper__refresh-button__loading svg {
  animation: spin 1s linear infinite;
}
.release-changelog-context-view-wrapper .release-changelog {
  overflow-y: auto;
  min-height: var(--spacing-xxl);
  color: var(--content-color-primary);
  padding-right: var(--spacing-l);
}

.release-info-context-view-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.release-info-context-view-wrapper .right-context-bar__title {
  color: var(--content-color-primary);
}
.release-info-context-view-wrapper .release-info-context-view {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}
.release-info-context-view-wrapper .release-info-context-view__version-header {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
.release-info-context-view-wrapper .release-info-context-view__entity {
  margin-bottom: var(--spacing-l);
}
.release-info-context-view-wrapper .release-info-context-view__entity__label {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}
.release-info-context-view-wrapper .release-info-context-view__entity__content {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  display: flex;
  flex: 1;
  padding-right: var(--spacing-s);
  align-items: center;
  margin-top: var(--spacing-xs);
}
.release-info-context-view-wrapper .release-info-context-view__entity__content .btn {
  background-color: transparent;
}
.release-info-context-view-wrapper .release-info-context-view__entity__content .btn-icon {
  background-color: transparent;
  width: var(--size-xs);
  height: var(--size-xs);
}
.release-info-context-view-wrapper .release-info-context-view__entity__content .success-tick-icon {
  cursor: default;
}
.release-info-context-view-wrapper .release-info-context-view__entity__content .success-tick-icon use {
  fill: var(--content-color-success);
}
.release-info-context-view-wrapper .release-info-context-view__entity__content .success-tick-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.release-info-context-view-wrapper .release-info-context-view__entity__content .success-tick-icon svg {
  color: var(--content-color-success);
}
.release-info-context-view-wrapper .release-info-context-view__entity__content__id {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.release-info-context-view-wrapper .release-info-context-view__entity__content__id--entity {
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  padding: 6px var(--spacing-s) 6px var(--spacing-s);
  margin-right: var(--spacing-s);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.release-info-context-view-wrapper .release-info-editors__editor {
  display: inline-flex;
  margin-top: var(--spacing-s);
  align-items: center;
}
.release-info-context-view-wrapper .release-info-editors__editor--name {
  margin-left: var(--spacing-s);
}

/*
The corresponding component file for the following SCSS file has been removed in
code cleanup. These SCSS file is kept intentionally because of generic class names and its
potential usage in other working component files.
*/
/**
@deprecated - No new changes should be added to this file as its corresponding
component file is removed. We have to keep this file because of generic class names
and its potential usage in other component files
*/
.overview {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.overview .overview__header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-s) var(--spacing-l);
  align-items: center;
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  padding-right: var(--spacing-s);
}
.overview .overview__header--network {
  padding-left: var(--spacing-zero);
}
.overview .overview__header-api-name {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  overflow: hidden;
  flex: 1;
}
.overview .overview__header-api-name .api-name-label {
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  min-width: 0px;
}
.overview .overview__header-api-name .api-name-label h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overview .overview__header-api-name .api-name-label .edit-icon-rename {
  display: none;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}
.overview .overview__header-api-name .api-name-label .edit-icon-rename:hover use, .overview .overview__header-api-name .api-name-label .edit-icon-rename.is-hovered use {
  fill: var(--content-color-primary);
}
.overview .overview__header-api-name .api-name-label .edit-icon-rename:hover svg:not([class*=PartnerIcon]) > path[fill], .overview .overview__header-api-name .api-name-label .edit-icon-rename.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.overview .overview__header-api-name .api-name-label .edit-icon-rename:hover svg, .overview .overview__header-api-name .api-name-label .edit-icon-rename.is-hovered svg {
  color: var(--content-color-primary);
}
.overview .overview__header-api-name .api-name-label:hover .edit-icon-rename, .overview .overview__header-api-name .api-name-label.is-hovered .edit-icon-rename {
  display: inline-flex;
  cursor: pointer;
}
.overview .overview__header-api-name .api-name-label:hover .copy-link-icon, .overview .overview__header-api-name .api-name-label.is-hovered .copy-link-icon {
  display: inline-flex;
  cursor: pointer;
}
.overview .overview__header-api-name .api-name-label .copy-link-icon {
  display: none;
  margin-right: var(--spacing-xs);
}
.overview .overview__header-api-name .api-name-label .api-name-input {
  min-width: var(--size-xs);
  margin-right: var(--spacing-xs);
  font-size: var(--text-size-l);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overview .overview__header .overview__header-right-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.overview .overview__header .overview__header-right-section .button-wrapper {
  margin-right: var(--spacing-xs);
}
.overview .overview__header .overview__header-right-section .api-report-button {
  font-weight: var(--text-weight-bold);
}
.overview .overview__header .overview__header-right-section .api-share-button {
  font-weight: var(--text-weight-bold);
}
.overview .overview__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  padding-bottom: var(--spacing-xxl);
}
.overview .overview__content__build {
  max-width: 880px;
}
.overview .overview__left-section {
  padding: var(--spacing-zero) var(--spacing-l);
  margin-top: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  flex-basis: 59%;
  flex-grow: 1;
  overflow: hidden;
}
.overview .overview__left-section--network {
  padding-left: var(--spacing-zero);
}
.overview .overview__right-section {
  padding: var(--spacing-zero) var(--spacing-l);
  margin-top: var(--spacing-xl);
  flex-basis: 33%;
  flex-grow: 1;
}
.overview .overview__right-section h4 {
  color: var(--content-color-primary);
}
.overview .overview__right-section .overview-editors .overview-editors__segment {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--spacing-s);
  align-items: center;
}
.overview .overview__right-section .overview-editors .overview-editors__segment .editor-username {
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.overview .overview__right-section .overview-editors .overview-editors__segment .overview-editors__editor {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.overview .overview__right-section .overview-editors .overview-editors__segment .overview-editors__editor-name {
  margin-left: 10px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.overview .overview__right-section .overview-editors-name {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  margin-bottom: calc(var(--spacing-m) + var(--spacing-s));
  color: var(--content-color-primary);
}
.overview__meta-editor {
  width: 100%;
}
.overview__meta-editor-summary {
  margin-bottom: var(--spacing-m);
  cursor: default;
}
.overview__meta-editor-summary-empty-state {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.overview__meta-editor-summary-text {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  word-break: break-word;
  width: 100%;
  padding: var(--spacing-xs);
}
.overview__meta-editor-summary-text-empty {
  color: var(--content-color-tertiary);
}
.overview__meta-editor-summary-text:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.overview__meta-editor-summary-no-edit {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  word-break: break-word;
  width: 100%;
}
.overview__meta-editor-summary-edit-mode {
  display: flex;
  font-size: var(--text-size-m);
  cursor: text;
}
.overview__meta-editor-summary-edit-mode-error .inline-input__wrapper .input-box {
  border-color: var(--content-color-error);
}
.overview__meta-editor-summary-edit-mode .inline-input__wrapper {
  margin-left: var(--spacing-s);
}
.overview__meta-editor-summary-edit-mode-description {
  display: flex;
  width: 95%;
  align-items: center;
  margin-top: var(--spacing-xs);
  margin-left: var(--spacing-s);
}
.overview__meta-editor-summary-edit-mode-description-left {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-xs);
  margin-left: var(--spacing-s);
}
.overview__meta-editor-summary-edit-mode-word-count {
  margin-left: auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  color: var(--content-color-tertiary);
}
.overview__meta-editor-summary-edit-mode-word-count-error {
  color: var(--content-color-error);
}
.overview__meta-editor-summary-edit-icon {
  margin-left: var(--spacing-l);
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.overview__meta-editor-summary-edit-icon use {
  fill: var(--content-color-secondary);
}
.overview__meta-editor-summary-edit-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.overview__meta-editor-summary-edit-icon svg {
  color: var(--content-color-secondary);
}
.overview__meta-editor-summary-edit-mode:hover .overview__meta-editor-summary-edit-icon {
  visibility: visible;
}
.overview__meta-editor .unsaved-changes {
  display: flex;
  align-items: center;
}
.overview__meta-editor .unsaved-changes__icon {
  margin-right: var(--spacing-s);
}
.overview__meta-editor .unsaved-changes__message {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.overview__meta-editor-description {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-right: calc(var(--spacing-s) * -1);
  margin-top: calc(var(--spacing-xs) * -1);
  width: 100%;
  margin-left: unset;
}
.overview__meta-editor-description-empty-state {
  color: var(--content-color-secondary);
}
.overview__meta-editor-description-label {
  margin-bottom: var(--spacing-s);
}
.overview__meta-editor-description-md {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.overview__meta-editor-description-md p {
  margin: var(--spacing-zero);
}
.overview__meta-editor-description-md a {
  color: var(--content-color-link);
  text-decoration: none;
}
.overview__meta-editor-description-edit-mode {
  display: flex;
  cursor: text;
  width: 100%;
}
.overview__meta-editor-description-edit-mode-wrapper {
  width: 100%;
}
.overview__meta-editor-description-edit-icon {
  margin-left: var(--spacing-l);
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.overview__meta-editor-description-edit-icon use {
  fill: var(--content-color-secondary);
}
.overview__meta-editor-description-edit-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.overview__meta-editor-description-edit-icon svg {
  color: var(--content-color-secondary);
}
.overview__meta-editor-description-edit-mode:hover .overview__meta-editor-description-edit-icon {
  visibility: visible;
}
.overview__meta-editor-description .description-preview--editable {
  padding: var(--spacing-s) var(--spacing-s);
}
.overview__meta-editor-description .overview__description__preview {
  padding: var(--spacing-zero);
}
.overview__meta-editor-summary-edit-icon, .overview__meta-editor-description-edit-icon {
  margin-left: var(--spacing-l);
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.overview__meta-editor-summary-edit-icon use, .overview__meta-editor-description-edit-icon use {
  fill: var(--content-color-secondary);
}
.overview__meta-editor-summary-edit-icon svg:not([class*=PartnerIcon]) > path[fill], .overview__meta-editor-description-edit-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.overview__meta-editor-summary-edit-icon svg, .overview__meta-editor-description-edit-icon svg {
  color: var(--content-color-secondary);
}
.overview__meta-editor-summary-edit-mode:hover .overview__meta-editor-summary-edit-icon, .overview__meta-editor-description-edit-mode:hover .overview__meta-editor-description-edit-icon {
  visibility: visible;
}
.overview-version-label {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-bottom: var(--spacing-l);
  margin-top: var(--spacing-xxl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.overview__versions__segment {
  display: flex;
  flex-direction: row;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  align-items: center;
  padding: var(--spacing-l) var(--spacing-s);
  cursor: pointer;
}
.overview__versions__segment__info {
  width: 100%;
}
.overview__versions__segment__info-non-editable {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--spacing-m);
  align-items: center;
}
.overview__versions__segment__info-non-editable .overview__versions__segment-name {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  display: flex;
  align-items: center;
  color: var(--content-color-primary);
  cursor: pointer;
  max-width: 240px;
}
.overview__versions__segment__info-non-editable .overview__versions__segment-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overview__versions__segment__info-non-editable .overview__versions__segment-visibility {
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-s);
  color: var(--content-color-tertiary);
  margin: var(--spacing-zero) var(--spacing-s);
}
.overview__versions__segment-btn {
  padding: var(--spacing-s);
  margin-left: var(--spacing-l);
}
.overview__versions__segment-summary {
  font-weight: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.overview__versions__segment:hover {
  background-color: var(--background-color-secondary);
}
.overview__versions__emptyState {
  margin-top: var(--spacing-s);
}
.overview__versions__emptyState .api-dev-empty-state__body {
  color: var(--content-color-secondary);
  margin: var(--spacing-zero);
  font-size: var(--text-size-m);
}
.overview__about-api {
  margin-left: calc(var(--spacing-s) * -1);
}
.overview__about-api .overview__meta-editor-summary-text {
  padding: var(--spacing-s) var(--spacing-s);
}

.overview__content__container {
  display: flex;
  justify-content: center;
  overflow: auto;
}

.loading {
  margin: auto;
}

.overview__description__preview {
  margin-left: calc(var(--spacing-s) * -1);
  padding: calc(var(--spacing-xs) / 2) var(--spacing-zero);
}
.overview__description__preview .description-preview__edit-icon {
  display: none;
}
.overview__description__preview:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}

.versions-repo-options {
  margin-left: var(--spacing-m);
  margin-right: var(--spacing-s);
}
.versions-repo-options .versions-repo-options__button {
  border-radius: var(--border-radius-default);
  max-width: 200px;
}
.versions-repo-options .versions-repo-options__button__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: var(--text-weight-regular);
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-xs);
}
.versions-repo-options .versions-repo-options__button__repo-icon {
  display: flex;
}
.versions-repo-options .versions-repo-options__button .versions-repo-options__button__icons {
  display: flex;
  align-items: center;
}
.versions-repo-options .versions-repo-options__button__icons {
  height: var(--size-xs);
}

.versions-repo-options-menu {
  border-width: var(--spacing-zero);
  padding: var(--spacing-zero);
  background: var(--background-color-primary);
  overflow: hidden !important;
}
.versions-repo-options-menu .versions-menu {
  display: flex;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  background: var(--background-color-primary);
  cursor: auto;
  height: calc(var(--size-s) * 2);
  padding: var(--spacing-zero);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.versions-repo-options-menu .versions-menu-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-xl);
  width: calc(var(--size-m) * 4);
  min-width: calc(var(--size-m) * 4);
  height: var(--size-m);
  color: var(--content-color-primary);
  box-shadow: inset 0px -1px 0px var(--highlight-background-color-primary);
}
.versions-repo-options-menu .versions-menu-left__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.versions-repo-options-menu .versions-menu-left--heading {
  font-weight: var(--text-weight-medium);
  color: var(--grey-50);
  line-height: var(--line-height-s);
}
.versions-repo-options-menu .versions-menu-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-s);
  width: 171px;
  height: var(--size-m);
  color: var(--content-color-link);
  box-shadow: inset 0px -1px 0px var(--highlight-background-color-primary);
}
.versions-repo-options-menu .versions-menu-right--btn {
  cursor: pointer;
}
.versions-repo-options-menu .versions-menu-right--heading {
  font-weight: var(--text-weight-medium);
  color: var(--grey-50);
  line-height: var(--line-height-s);
  flex-grow: 1;
}
.versions-repo-options-menu .versions-menu .versions-menu-right--error {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-s);
  width: 218px;
  height: var(--size-m);
  line-height: var(--line-height-s);
  box-shadow: inset 0px -1px 0px var(--highlight-background-color-primary);
  color: var(--content-color-secondary);
}
.versions-repo-options-menu .versions-menu .versions-menu-right--branch {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-s);
  width: 171px;
  height: var(--size-m);
  line-height: var(--line-height-s);
  box-shadow: inset 0px -1px 0px var(--highlight-background-color-primary);
  color: var(--content-color-secondary);
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.versions-repo-options-menu .versions-menu .versions-menu-right--branch--text {
  width: 100%;
}
.versions-repo-options-menu .versions-menu .versions-menu-right--branch .aether-link-text {
  max-width: 100%;
}
.versions-repo-options-menu .versions-menu .versions-menu-right--navigate {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: var(--size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.versions-repo-options-menu .versions-menu .versions-menu-right--navigate:hover {
  color: var(--content-color-link);
}
.versions-repo-options-menu .versions-menu__content {
  overflow: auto;
  height: calc(100% - 48px);
}

.remove-collection-from-api-dropdown {
  height: 120px;
}

.add-collection-to-api-dropdown {
  height: 165px;
}

.add-collection-to-api__loader {
  margin: auto;
}

.api-sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.api-sidebar-list {
  flex: 1;
  overflow-y: auto;
}
.api-sidebar-list .collection-list__loading-indicator {
  contain: layout size;
  display: flex;
  margin-left: 65px;
  height: 28px;
  width: 16px;
  align-items: center;
}
.api-sidebar-list .environment-list__loading-indicator {
  display: flex;
  margin-left: 65px;
  height: 28px;
  align-items: center;
}
.api-sidebar-list:hover .api-sidebar-list__item__left-section__indent {
  height: 100%;
  width: 11px;
  border-right: thin solid var(--border-color-default);
}
.api-sidebar-list:hover .collection-sidebar__indent:not(.active-indent) {
  border-right: thin solid var(--border-color-default);
}
.api-sidebar-list:hover .api-definition-item__left-section__indent {
  border-right: thin solid var(--border-color-default);
}
.api-sidebar-list .sidebar-list-item:focus, .api-sidebar-list .sidebar-list-item.is-focused {
  background-color: unset;
  border-radius: unset;
  box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
}
.api-sidebar-list .sidebar-list-item__name {
  margin-right: var(--spacing-xs);
}
.api-sidebar-list .sidebar-list-item__content-wrapper {
  margin-left: var(--spacing-zero);
}
.api-sidebar-list .sidebar-list-item__left-actions-container {
  position: relative;
}
.api-sidebar-list .api-sidebar-list-right-meta-content {
  margin-left: var(--spacing-xs);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}
.api-sidebar-list .api-sidebar-list-right-meta-content__text {
  max-width: 120px;
  display: inline-flex;
  align-items: center;
}
.api-sidebar-list .api-sidebar-list-right-meta-content__icon {
  overflow: unset;
}
.api-sidebar-list .api-sidebar-list-right-meta-content i {
  margin-top: var(--spacing-xs);
}
.api-sidebar-list .branch-switcher {
  position: absolute;
}
.api-sidebar-list .branch-switcher .aether-dropdown__control {
  background-color: var(--highlight-background-color-tertiary);
  height: 20px;
  max-width: 120px;
  min-width: 80px;
}
.api-sidebar-list .branch-switcher .aether-dropdown__control--is-focused {
  border: none;
  box-shadow: none;
}
.api-sidebar-list .branch-switcher .aether-dropdown__single-value {
  color: var(--content-color-secondary);
}
.api-sidebar-list .sidebar-list-item__icon-container {
  justify-content: start;
  margin-right: var(--spacing-zero);
}
.api-sidebar-list .api-sidebar-list__item-empty-state {
  padding: var(--spacing-s) 36px;
}

.api-sidebar-relations-loading-state {
  contain: layout size;
  width: 16px;
  height: 20px;
}

.api-sidebar-relations-loading-indicator {
  contain: layout size;
  height: 20px;
  width: 16px;
}

.api-sidebar-list-empty-item {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: var(--content-color-secondary);
  padding: 20px 15px;
  text-align: center;
}
.api-sidebar-list-empty-item .entity-empty {
  border: none;
  padding: 30px 25px 40px 25px;
}
.api-sidebar-list-empty-item .entity-empty .entity-empty__title {
  font-size: 14px;
  margin-bottom: 16px;
}
.api-sidebar-list-empty-item .entity-empty .entity-empty__subtitle {
  margin-bottom: 24px;
}
.api-sidebar-list-empty-item .entity-empty .entity-empty__title,
.api-sidebar-list-empty-item .entity-empty .entity-empty__subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.api-sidebar-list-empty-item .entity-empty .entity-empty__learn .entity-empty__learn__mouse-click-icon {
  padding-right: 8px;
}

.api-sidebar-empty__down {
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
  display: flex;
  flex: 1;
}
.api-sidebar-empty__down-section {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  flex: 1;
  flex-direction: column;
}
.api-sidebar-empty__down-section .lesson-container {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin-top: var(--spacing-m);
}
.api-sidebar-empty__down-section .lesson-container .lesson-section {
  display: flex;
  flex: 1;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.api-sidebar-empty__down-section .lesson-container .lesson-section .lesson-img {
  display: flex;
}
.api-sidebar-empty__down-section .lesson-container .lesson-section .lesson-img__content {
  display: block;
  width: 86px;
  height: 87px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/astronaut-postman.svg");
  flex: 1;
}
.api-sidebar-empty__down-section .lesson-container .lesson-section .lesson-txt {
  align-self: center;
  flex-direction: column;
  display: flex;
  flex: 1 0 58%;
  padding-right: var(--spacing-l);
}
.api-sidebar-empty__down-section .lesson-container .lesson-section .lesson-txt__header {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  flex: 1;
  display: flex;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.api-sidebar-empty__down-section .lesson-container .lesson-section .lesson-txt__summary {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
  flex: 1;
  display: flex;
  color: var(--content-color-secondary);
}
.api-sidebar-empty__down-section .lesson-container .lesson-start {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--spacing-s) var(--spacing-zero);
}
.api-sidebar-empty__down-section .lesson-container .lesson-start__btn {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}

.api-dropdown-menu {
  margin-top: 0;
  margin-right: 3px;
  width: 201px;
}
.api-dropdown-menu .dropdown-menu-item .api-action-item {
  display: flex;
  flex: 1;
}
.api-dropdown-menu .dropdown-menu-item.is-sub-item-available .api-action-item {
  flex: 2;
}

.api-sidebar-list__item__empty-state {
  margin-left: calc(var(--spacing-l) + var(--spacing-xs));
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  padding: var(--spacing-zero) var(--spacing-l) var(--spacing-s) var(--spacing-l);
}
.api-sidebar-list__item__left-section {
  display: flex;
  flex-direction: row;
  margin-right: var(--spacing-s);
  height: 100%;
  align-items: center;
}
.api-sidebar-list__item__left-section__indent {
  width: 12px;
}
.api-sidebar-list__item__left-section__toggle-list {
  margin-left: var(--spacing-xs);
  height: var(--size-xs);
  width: var(--size-xs);
  background-color: unset;
}
.api-sidebar-list__item__left-section__toggle-list use {
  fill: var(--content-color-primary);
}
.api-sidebar-list__item__left-section__toggle-list svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.api-sidebar-list__item__left-section__toggle-list svg {
  color: var(--content-color-primary);
}
.api-sidebar-list__item__left-section__toggle-list:hover, .api-sidebar-list__item__left-section__toggle-list.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.api-sidebar-list__item__left-section__toggle-list:hover use, .api-sidebar-list__item__left-section__toggle-list.is-hovered use {
  fill: var(--content-color-primary);
}
.api-sidebar-list__item__left-section__toggle-list:hover svg:not([class*=PartnerIcon]) > path[fill], .api-sidebar-list__item__left-section__toggle-list.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.api-sidebar-list__item__left-section__toggle-list:hover svg, .api-sidebar-list__item__left-section__toggle-list.is-hovered svg {
  color: var(--content-color-primary);
}
.api-sidebar-list__item__left-section .is-left-meta-visible {
  display: flex;
  visibility: visible;
}
.api-sidebar-list__item__left-section .is-left-meta-not-visible {
  visibility: hidden;
}

.hide-icon {
  padding: var(--spacing-xs);
}

.api-sidebar-list .sidebar-list-item__name-wrapper {
  align-items: center;
}

.api-sidebar-empty-shell {
  border-right: 1px solid var(--border-color-default);
  border-left: 1px solid var(--border-color-default);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
  background-color: var(--background-color-primary);
  padding-top: 8px;
}
.api-sidebar-empty-shell .api-sidebar-empty-shell--section--title__dropdown {
  height: 8px;
  width: 8px;
  border-radius: var(--border-radius-default);
  margin-left: 16px;
  background-color: var(--background-color-tertiary);
}
.api-sidebar-empty-shell .api-sidebar-empty-shell--section--title__name {
  height: 8px;
  width: 48px;
  margin-right: 32px;
  background-color: var(--background-color-tertiary);
  margin-left: 16px;
  border-radius: var(--border-radius-default);
}
.api-sidebar-empty-shell .api-sidebar-empty-shell--section--title,
.api-sidebar-empty-shell .api-sidebar-empty-shell--section--item {
  height: 30px;
  display: flex;
  align-items: center;
}
.api-sidebar-empty-shell .api-sidebar-empty-shell--section:nth-of-type(2) {
  margin-top: 16px;
}
.api-sidebar-empty-shell .api-sidebar-empty-shell--section--item__icon {
  height: 8px;
  width: 18px;
  border-radius: var(--border-radius-default);
  margin-left: 16px;
  background-color: var(--background-color-tertiary);
}
.api-sidebar-empty-shell .api-sidebar-empty-shell--section--item__url {
  height: 8px;
  margin-right: 32px;
  background-color: var(--background-color-tertiary);
  margin-left: 16px;
  border-radius: var(--border-radius-default);
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.api-entity-collapse-section .api-entity-header .api-entity-header__left-section {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.api-entity-collapse-section .api-entity-header .api-entity-header__left-section .api-entity-header__icon {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  margin-right: var(--spacing-xs);
  padding: var(--spacing-xs);
}
.api-entity-collapse-section .api-entity-header .api-entity-header__left-section .api-entity-header__icon:hover {
  background-color: var(--highlight-background-color-primary);
}
.api-entity-collapse-section .api-entity-header .api-entity-header__right-section .api-entity-header__action {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.api-entity-collapse-section .api-entity-header .api-entity-header__right-section .api-entity-header__action .api-entity-header__add-button {
  display: flex;
  font-size: var(--text-size-m);
  color: var(--content-color-link);
}
.api-entity-collapse-section .api-entity-header .api-entity-header__right-section .api-entity-header__action .api-entity-header__add-options {
  font-weight: var(--text-weight-regular);
}
.api-entity-collapse-section .api-entity-header .api-entity-header__right-section .api-entity-header__action .api-entity-header__add-options .add-options__dropdown-button {
  font-weight: var(--text-weight-medium);
}
.api-entity-collapse-section .api-entity-contents {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-xl);
}
.api-entity-collapse-section .api-entity-contents.is-collapsed {
  display: none;
}

.api-dev-table {
  font-family: var(--text-family-default);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.api-dev-table-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.api-dev-table-row {
  display: flex;
  align-items: center;
  height: var(--size-m);
}
.api-dev-table-row .row-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: var(--size-m) !important;
  height: var(--size-m);
}
.api-dev-table-row .api-dev-table-row__cell {
  display: flex;
  align-items: center;
  flex: 0 0 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.api-dev-table-row .api-dev-table-row__cell.shrinked {
  margin-right: 16px;
}
.api-dev-table-row .api-dev-table-row__cell:first-child {
  flex: 0 0 30%;
}
.api-dev-table-row .row-hover__cell:last-child {
  visibility: hidden;
  opacity: 0;
  margin-left: auto;
  justify-content: flex-end;
  overflow: unset;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.api-dev-table-row .row-hover__cell:last-child.shrinked {
  flex: 0 0 7%;
}

.api-dev-table-row-monitors {
  height: max-content !important;
  min-height: 43px;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: var(--spacing-zero) !important;
}

.api-dev-table-row-uptime-monitors {
  height: max-content !important;
  min-height: 43px;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: var(--spacing-zero) !important;
}

.api-dev-table-header-monitors {
  margin-left: var(--spacing-zero) !important;
  margin-right: var(--spacing-zero) !important;
}

.row-hover {
  padding: var(--spacing-xs) var(--spacing-s);
}
.row-hover:hover, .row-hover.is-hovered {
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
}
.row-hover:hover .row-hover__cell:last-child, .row-hover.is-hovered .row-hover__cell:last-child {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.api-dev-table-header {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
}
.api-dev-table-header .api-dev-table-header__cell:last-child {
  margin-left: auto;
}
.api-dev-table-header .api-dev-table-header__cell:last-child.shrinked {
  flex: 0;
}
.api-dev-table-header .empty-column {
  margin-right: var(--spacing-xxl);
}
.api-dev-table-header .api-dev-table-header__cell {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  flex: 0;
}
.api-dev-table-header .api-dev-table-header__cell-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.api-dev-table-header .api-dev-table-header__helper-icon {
  display: flex;
  padding: var(--spacing-zero) var(--spacing-xs);
  margin-right: var(--spacing-s);
}

.entity-last-updated__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entity-name__icon {
  display: flex;
  margin-right: var(--spacing-s);
}

/**
 * We needed to set min-width as 0 because of this issue -
 * https://css-tricks.com/flexbox-truncated-text/
 */
.entity-name__link {
  min-width: 0;
}

/**
 * We needed to set display as block because of this issue -
 * https://westerndevs.com/css/Using-Overflow-Ellipsis-in-Inline-Flex/
 */
.entity-name__label {
  display: block;
  font-size: var(--text-size-m);
  padding: 2px var(--spacing-zero);
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -webkit-fill-available;
  margin-right: var(--spacing-s);
}

.entity-version__label {
  height: var(--size-xs);
  padding: var(--spacing-zero) var(--spacing-s);
  margin-right: var(--spacing-xs);
  border-radius: 30px;
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  background-color: var(--background-color-tertiary);
  color: var(--content-color-tertiary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.entity-version__current-label {
  border: 1px solid var(--border-color-default);
  background-color: var(--background-color-primary);
  color: var(--content-color-tertiary);
}

.entity-version__icon {
  cursor: pointer;
}
.entity-version__icon .info__icon use {
  fill: var(--content-color-tertiary);
}
.entity-version__icon .info__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.entity-version__icon .info__icon svg {
  color: var(--content-color-tertiary);
}

.entity-version__markdown {
  color: var(--content-color-primary);
  line-height: 20px;
}
.entity-version__markdown h1,
.entity-version__markdown h2,
.entity-version__markdown h3,
.entity-version__markdown h4,
.entity-version__markdown h5,
.entity-version__markdown h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.entity-version__markdown h1 {
  font-size: 18px;
  font-weight: 700;
}
.entity-version__markdown h2 {
  font-size: 16px;
  font-weight: 700;
}
.entity-version__markdown h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.entity-version__markdown h4 {
  font-size: 14px;
  font-weight: 700;
}
.entity-version__markdown h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.entity-version__markdown h6 {
  font-size: 12px;
  font-weight: 600;
}
.entity-version__markdown hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.entity-version__markdown blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.entity-version__markdown blockquote blockquote {
  margin-left: 20px;
}
.entity-version__markdown p, .entity-version__markdown span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.entity-version__markdown em {
  font-style: italic;
}
.entity-version__markdown strong {
  font-weight: var(--text-weight-medium);
}
.entity-version__markdown ul, .entity-version__markdown ol {
  margin: 5px;
}
.entity-version__markdown a {
  color: var(--content-color-link);
  text-decoration: none;
}
.entity-version__markdown a:hover {
  text-decoration: underline;
}
.entity-version__markdown pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.entity-version__markdown pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.entity-version__markdown code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.entity-version__markdown table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.entity-version__markdown table th,
.entity-version__markdown table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.entity-version__markdown table th:first-child,
.entity-version__markdown table td:first-child {
  border-left: none;
}
.entity-version__markdown table th:last-child,
.entity-version__markdown table td:last-child {
  border-right: none;
}
.entity-version__markdown table th {
  border-top: none;
}
.entity-version__markdown table tr:last-child th,
.entity-version__markdown table tr:last-child td {
  border-bottom: none;
}
.entity-version__markdown img {
  max-width: 100%;
}
.entity-version__markdown p {
  margin: 0;
}

.entity-validation__status {
  font-size: var(--text-size-s);
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  padding: var(--spacing-xs) var(--spacing-s);
  cursor: pointer;
  background: var(--background-color-tertiary);
  height: var(--size-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.entity-validation__status.warning {
  font-weight: var(--text-weight-medium);
  background-color: var(--background-color-warning);
  border-radius: 100px;
}
.entity-validation__status.error {
  border-radius: 100px;
}
.entity-validation__status.refresh {
  border-radius: 100px;
}
.entity-validation__status.success {
  background-color: var(--background-color-success);
  border-radius: 100px;
  font-weight: var(--text-weight-medium);
}
.entity-validation__status.validating {
  background: var(--background-color-secondary);
  cursor: default;
}
.entity-validation__status.validating .loading-indicator-wrapper {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-s);
  transform: scale(0.7);
}
.entity-validation__status.validate .entity-validation__status_text {
  padding-left: unset;
}
.entity-validation__status.is-validate-disabled {
  cursor: default;
  opacity: 0.5;
}
.entity-validation__status .entity-validation__status_text {
  padding-left: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.documentation-validation__status {
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  cursor: pointer;
  background: transparent;
}
.documentation-validation__status.warning {
  border-radius: 100px;
  font-weight: var(--text-weight-medium);
  background-color: var(--background-color-warning);
}
.documentation-validation__status.refresh {
  border-radius: 100px;
  background: var(--background-color-secondary);
}
.documentation-validation__status.success {
  border-radius: 100px;
  background-color: var(--background-color-success);
}
.documentation-validation__status.validating {
  border-radius: 100px;
  background: var(--background-color-secondary);
  cursor: default;
}
.documentation-validation__status.validating .loading-indicator-wrapper {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-s);
  transform: scale(0.7);
}
.documentation-validation__status.validate .documentation-validation__status_text {
  padding-left: unset;
}
.documentation-validation__status.is-validate-disabled {
  cursor: default;
  opacity: 0.5;
}
.documentation-validation__status .documentation-validation__status_text {
  margin-right: var(--spacing-m);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.documentation-validation__status .documentation-validation__status_icon {
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-s);
}

.validation-info__action_secondary {
  flex: 0 0 40%;
}

.validation-info__action_primary {
  flex: 0 0 57%;
}

.api-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.api-editor {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  min-height: 0;
}
.api-editor .api-tab-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}
.api-editor .api-tab-content .api-tab-content-pane {
  overflow-y: hidden;
  overflow-x: auto;
}
.api-editor .api-tab-content .api-tab-content-pane__head {
  max-height: 100%;
  overflow-y: scroll;
}

.api-tab-body .accordion {
  border-top: 0;
}
.api-tab-body .api-tab-body__default {
  margin: var(--spacing-xxl) auto var(--spacing-l);
  max-width: 984px;
  padding: var(--spacing-zero) calc(var(--spacing-xl) - var(--spacing-xs)) var(--spacing-zero) var(--spacing-xl);
}
.api-tab-body .api-tab-body__default__shrunk {
  width: 100%;
}
.api-tab-body .api-tab-body__default__expanded {
  overflow: hidden;
}
.api-tab-body .api-tab-body__default__left-pane {
  flex: 2;
}
.api-tab-body .api-tab-body__default__right-pane {
  flex: 1;
  max-width: 300px;
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-l);
  background: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  box-shadow: 0 0 0 1px var(--popover-outline-color) inset;
  height: max-content;
}
.api-tab-body .api-tab-body__default__right-pane .right-tile {
  max-width: 300px;
  margin-right: var(--spacing-zero);
  padding-right: var(--spacing-xl);
  padding-top: var(--spacing-xl);
}
.api-tab-body .api-tab-body__default__right-pane .right-tile + .right-tile {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.api-tab-body .api-tab-body__default__right-pane .right-tile h4 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.api-tab-body .api-tab-body__default__right-pane .right-tile .api-tile__body {
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xl);
}
.api-tab-body .api-tab-body__screen__back-btn {
  margin-left: var(--spacing-m);
}
.api-tab-body .is-hidden {
  display: none;
}

.api-tab-body__default__tile-body {
  border-top: 1px solid var(--border-color-default);
  border-bottom: 1px solid var(--border-color-default);
  padding-right: var(--spacing-xs);
}
.api-tab-body__default__tile-body:hover .api-tab-body__default__tile-heading__action-item {
  opacity: 1;
  visibility: visible;
}

.api-tab-body__default__tile-body + .api-tab-body__default__tile-body {
  border-top: none;
}

.api-tab-body__default__tile-heading__action-item {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.power-up {
  padding-top: var(--spacing-s);
}
.power-up__tile {
  cursor: pointer;
}
.power-up__tile .power-up__tile-heading + strong {
  transform: translateX(0px);
  transition: transform 0.2s ease-in-out;
}
.power-up__tile:hover .power-up__tile-heading, .power-up__tile:hover .power-up__tile-heading + strong {
  color: var(--content-color-link);
}
.power-up__tile:hover .power-up__tile-heading + strong {
  transform: translateX(4px);
}
.power-up__tile + .power-up__tile {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-top: var(--spacing-l);
}
.power-up__tile-loading {
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-s);
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}
.power-up__tile-loading__label {
  order: 0;
  flex: none;
  flex-grow: 0;
  width: 39px;
  height: 8px;
  animation: fade 1s ease-in-out infinite;
  background: var(--highlight-background-color-secondary);
  border-radius: 2px;
}
.power-up__tile-loading__body {
  order: 1;
  flex: none;
  flex-grow: 0;
  width: 97px;
  height: 8px;
  animation: fade 1s ease-in-out infinite;
  background: var(--highlight-background-color-secondary);
  border-radius: 2px;
}
.power-up__tile-field {
  white-space: nowrap;
}
.power-up__apm-tool-tip {
  justify-content: center;
  white-space: pre-wrap;
}
.power-up__apm-tool-tip .tippy-content {
  padding: var(--spacing-xs) var(--spacing-s);
}

.power-up__tile-field__name {
  width: 64px;
  flex-shrink: 0;
}

.discover-api .publish-api-modal__content .version-label {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-l);
}
.discover-api .publish-api-modal__content .version-notes {
  margin-bottom: var(--spacing-l);
}
.discover-api .publish-api-modal__content .publish-elements {
  margin-bottom: var(--spacing-xxl);
}
.discover-api .publish-api-modal__content .api-discoverable {
  margin-bottom: var(--spacing-l);
}

.published-versions {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: var(--spacing-s);
  min-width: 0;
}
.published-versions:hover span {
  color: var(--content-color-link);
  text-decoration: underline;
}

.view-as-consumer {
  cursor: pointer;
}

.switch-to-producer {
  cursor: pointer;
}

.versions__branch_name {
  flex-shrink: 1;
}

.versions__branch_time {
  flex-shrink: 0;
}

.api-editor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-m);
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-s) var(--spacing-s);
  background-color: var(--background-color-primary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.api-editor-header__comments {
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: var(--content-color-primary);
  min-width: 0px;
}
.api-editor-header .api-editor-header__left-section .api-editor-header__back-button {
  margin-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .api-name-label {
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  min-width: 0px;
  max-width: 100%;
}
.api-editor-header .api-editor-header__left-section .api-name-input {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .breadcrumb__text {
  font-size: var(--text-size-l);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename {
  display: none;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename:hover use, .api-editor-header .api-editor-header__left-section .edit-icon-rename.is-hovered use {
  fill: var(--content-color-primary);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename:hover svg:not([class*=PartnerIcon]) > path[fill], .api-editor-header .api-editor-header__left-section .edit-icon-rename.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename:hover svg, .api-editor-header .api-editor-header__left-section .edit-icon-rename.is-hovered svg {
  color: var(--content-color-primary);
}
.api-editor-header .api-editor-header__left-section:hover .edit-icon-rename, .api-editor-header .api-editor-header__left-section.is-hovered .edit-icon-rename {
  display: inline-flex;
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section:hover .copy-link-icon, .api-editor-header .api-editor-header__left-section.is-hovered .copy-link-icon {
  visibility: visible;
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section .copy-link-icon {
  visibility: hidden;
  margin-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .api-version-label {
  color: var(--content-color-tertiary);
  background-color: var(--background-color-tertiary);
  padding: var(--spacing-zero) var(--spacing-s);
  height: var(--size-xs);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  white-space: pre;
  text-overflow: ellipsis;
  min-width: 0px;
  max-width: 100px;
  border-radius: 30px;
  cursor: auto;
}
.api-editor-header .api-editor-header__left-section .api-show-version {
  font-weight: var(--text-weight-regular);
  height: var(--size-s);
  border-radius: unset;
  color: var(--content-color-primary);
  padding: unset;
  margin-left: var(--spacing-s);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  margin-right: var(--spacing-s);
  white-space: nowrap;
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section .api-show-version:hover, .api-editor-header .api-editor-header__left-section .api-show-version.is-hovered {
  color: var(--content-color-link);
}
.api-editor-header .api-editor-header__right-section {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-xxl);
}
.api-editor-header .api-editor-header__right-section .button-wrapper {
  box-sizing: border-box;
  height: var(--size-m);
  display: flex;
  align-items: stretch;
  padding: var(--spacing-s);
  margin-right: 2px;
  cursor: pointer;
  user-select: none;
}
.api-editor-header .api-editor-header__right-section .button-wrapper .button-icon {
  margin-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button {
  background: initial;
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button.button-disabled:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button.button-disabled.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled.is-hovered {
  background: initial;
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .watch-label {
  margin-left: var(--spacing-s);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .unwatch-icon use {
  fill: var(--content-color-secondary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .unwatch-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .unwatch-icon svg {
  color: var(--content-color-secondary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-context-menu-button {
  padding: var(--spacing-zero);
  margin-right: var(--spacing-s);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-context-menu-button .button-icon {
  height: var(--size-m);
  width: var(--size-m);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-context-menu-button .button-icon.is-active {
  background-color: var(--highlight-background-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-watch-button {
  margin-right: var(--spacing-s);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher {
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-l);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper {
  display: flex;
  justify-content: center;
  height: var(--size-m);
  width: 70px;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  height: 28px;
  width: var(--size-m);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.right {
  left: 36px;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-active-item {
  align-self: center;
  display: flex;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  height: 100%;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper.disabled {
  opacity: 0.3;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon use {
  fill: var(--content-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon svg {
  color: var(--content-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon.active use {
  fill: var(--base-color-brand);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon.active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon.active svg {
  color: var(--base-color-brand);
}
.api-editor-header .api-editor-header__right-section .comments-ctx-toggler {
  padding: var(--spacing-zero) var(--spacing-s);
  font-size: var(--text-size-m);
  align-items: center;
  color: var(--content-color-brand);
  margin-right: var(--spacing-m);
}
.api-editor-header .api-editor-header__right-section .comments-ctx-toggler > .comment-wrapper {
  cursor: pointer;
}
.api-editor-header .api-editor-header__right-section .comments-ctx-toggler > .comment-wrapper.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.api-context-menu-dropdown .dropdown-menu {
  margin-top: var(--spacing-zero);
  margin-right: 3px;
  width: 200px;
}

.unsaved-api-version-confirmation-modal .unsaved-api-version-confirmation-modal__footer {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.unsaved-api-version-confirmation-modal .unsaved-api-version-confirmation-modal__footer .unsaved-api-version-confirmation-modal__footer__left {
  display: flex;
  flex: 1;
}
.unsaved-api-version-confirmation-modal .unsaved-api-version-confirmation-modal__footer .unsaved-api-version-confirmation-modal__footer__right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row-reverse;
  gap: var(--spacing-m);
  align-items: center;
}

.api-editor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-m);
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-s) var(--spacing-s);
  background-color: var(--background-color-primary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.api-editor-header__comments {
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
  color: var(--content-color-primary);
  min-width: 0px;
}
.api-editor-header .api-editor-header__left-section .api-editor-header__back-button {
  margin-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .api-name-label {
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  min-width: 0px;
  max-width: 100%;
}
.api-editor-header .api-editor-header__left-section .api-name-input {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .breadcrumb__text {
  font-size: var(--text-size-l);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename {
  display: none;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename:hover use, .api-editor-header .api-editor-header__left-section .edit-icon-rename.is-hovered use {
  fill: var(--content-color-primary);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename:hover svg:not([class*=PartnerIcon]) > path[fill], .api-editor-header .api-editor-header__left-section .edit-icon-rename.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.api-editor-header .api-editor-header__left-section .edit-icon-rename:hover svg, .api-editor-header .api-editor-header__left-section .edit-icon-rename.is-hovered svg {
  color: var(--content-color-primary);
}
.api-editor-header .api-editor-header__left-section:hover .edit-icon-rename, .api-editor-header .api-editor-header__left-section.is-hovered .edit-icon-rename {
  display: inline-flex;
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section:hover .copy-link-icon, .api-editor-header .api-editor-header__left-section.is-hovered .copy-link-icon {
  visibility: visible;
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section .copy-link-icon {
  visibility: hidden;
  margin-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__left-section .api-version-label {
  color: var(--content-color-tertiary);
  background-color: var(--background-color-tertiary);
  padding: var(--spacing-zero) var(--spacing-s);
  height: var(--size-xs);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  white-space: pre;
  text-overflow: ellipsis;
  min-width: 0px;
  max-width: 100px;
  border-radius: 30px;
  cursor: auto;
}
.api-editor-header .api-editor-header__left-section .api-show-version {
  font-weight: var(--text-weight-regular);
  height: var(--size-s);
  border-radius: unset;
  color: var(--content-color-primary);
  padding: unset;
  margin-left: var(--spacing-s);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  margin-right: var(--spacing-s);
  white-space: nowrap;
  cursor: pointer;
}
.api-editor-header .api-editor-header__left-section .api-show-version:hover, .api-editor-header .api-editor-header__left-section .api-show-version.is-hovered {
  color: var(--content-color-link);
}
.api-editor-header .api-editor-header__right-section {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-xxl);
}
.api-editor-header .api-editor-header__right-section .button-wrapper {
  box-sizing: border-box;
  height: var(--size-m);
  display: flex;
  align-items: stretch;
  padding: var(--spacing-s);
  margin-right: 2px;
  cursor: pointer;
  user-select: none;
}
.api-editor-header .api-editor-header__right-section .button-wrapper .button-icon {
  margin-right: var(--spacing-xs);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button {
  background: initial;
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button.button-disabled:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button.button-disabled.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.button-disabled.is-hovered {
  background: initial;
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-report-button.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.is-hovered, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button:hover, .api-editor-header .api-editor-header__right-section .button-wrapper.api-share-button.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .watch-label {
  margin-left: var(--spacing-s);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .unwatch-icon use {
  fill: var(--content-color-secondary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .unwatch-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper .unwatch-icon svg {
  color: var(--content-color-secondary);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-context-menu-button {
  padding: var(--spacing-zero);
  margin-right: var(--spacing-s);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-context-menu-button .button-icon {
  height: var(--size-m);
  width: var(--size-m);
}
.api-editor-header .api-editor-header__right-section .button-wrapper.api-context-menu-button .button-icon.is-active {
  background-color: var(--highlight-background-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-watch-button {
  margin-right: var(--spacing-s);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher {
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-l);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper {
  display: flex;
  justify-content: center;
  height: var(--size-m);
  width: 70px;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  height: 28px;
  width: var(--size-m);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.right {
  left: 36px;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-active-item {
  align-self: center;
  display: flex;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  height: 100%;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper.disabled {
  opacity: 0.3;
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon use {
  fill: var(--content-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon svg {
  color: var(--content-color-tertiary);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon.active use {
  fill: var(--base-color-brand);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon.active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.api-editor-header .api-editor-header__right-section .api-mode-switcher .api-mode-switcher__icon.active svg {
  color: var(--base-color-brand);
}
.api-editor-header .api-editor-header__right-section .comments-ctx-toggler {
  padding: var(--spacing-zero) var(--spacing-s);
  font-size: var(--text-size-m);
  align-items: center;
  color: var(--content-color-brand);
  margin-right: var(--spacing-m);
}
.api-editor-header .api-editor-header__right-section .comments-ctx-toggler > .comment-wrapper {
  cursor: pointer;
}
.api-editor-header .api-editor-header__right-section .comments-ctx-toggler > .comment-wrapper.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.api-context-menu-dropdown .dropdown-menu {
  margin-top: var(--spacing-zero);
  margin-right: 3px;
  width: 200px;
}

.api-tile + .left-tile {
  min-width: 300px;
}

.api-tile + .right-tile {
  max-width: 300px;
  min-width: 185px;
  margin-right: var(--spacing-xl);
}

.api-tile {
  margin-right: var(--spacing-l);
}
.api-tile.connect-repository {
  border: var(--border-width-default) solid var(--border-color-default);
  margin: var(--spacing-xs) var(--spacing-l) var(--spacing-xxl) var(--spacing-xl);
  padding-top: var(--spacing-l);
  border-radius: var(--border-radius-default);
}
.api-tile.connect-repository .api-tile__body {
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xl);
}
.api-tile.connect-repository .api-repository-connect-dropdown {
  margin-top: var(--spacing-xs);
}
.api-tile.connect-repository .connect-repository-button {
  color: var(--content-color-primary);
  padding-left: var(--spacing-m);
  background-color: var(--background-color-primary);
}
.api-tile.connect-repository .connect-repository-button:hover,
.api-tile.connect-repository .dropdown-button.is-open .connect-repository-button {
  background-color: var(--background-color-primary);
}
.api-tile__header {
  height: var(--size-m);
}
.api-tile__header__content {
  height: var(--size-m);
}
.api-tile__header__content-is-expanded {
  background-color: var(--highlight-background-color-primary);
}
.api-tile__header__content__action-item {
  margin-left: auto;
  margin-right: var(--spacing-xs);
}
.api-tile__header__content__text {
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-xxl);
}
.api-tile__header__content__icon {
  margin-left: var(--spacing-xs);
  display: inline-flex;
}
.api-tile__body {
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-xl);
  margin-bottom: var(--spacing-xxl);
}

.delete-api-container-wrapper {
  display: flex;
  flex-direction: column;
}
.delete-api-container-wrapper__alert {
  margin-top: var(--spacing-l);
  background-color: var(--background-color-error);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: row;
}
.delete-api-container-wrapper__alert__icon {
  padding: var(--spacing-m) var(--spacing-zero) var(--spacing-m) var(--spacing-m);
  display: flex;
  align-items: center;
}
.delete-api-container-wrapper__alert__icon use {
  fill: var(--content-color-error);
}
.delete-api-container-wrapper__alert__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.delete-api-container-wrapper__alert__icon svg {
  color: var(--content-color-error);
}
.delete-api-container-wrapper__alert__text {
  padding: var(--spacing-m);
  color: var(--content-color-error);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.api-offline {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.api-offline.sidebar {
  padding: var(--spacing-xxl) var(--spacing-l);
  margin-top: var(--spacing-xxl);
}
.api-offline.api-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.api-offline.context-bar {
  padding: var(--spacing-xl) var(--spacing-zero);
  margin-top: var(--spacing-xxxl);
}
.api-offline__blank-state {
  padding: var(--spacing-l);
}
.api-offline__content {
  text-align: center;
}
.api-offline__content__header {
  margin-bottom: var(--spacing-s);
}
.api-offline__content__sub-header {
  margin-bottom: var(--spacing-l);
}

.generation-config-options__toggle-button {
  display: inline-flex;
  align-items: center;
  margin-top: var(--spacing-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-link);
  cursor: pointer;
}
.generation-config-options__toggle-button .toggle-button-icon {
  margin-right: var(--spacing-xs);
}

.generation-config-options-wrapper {
  margin-top: var(--spacing-s);
  padding: var(--spacing-m);
  display: flex;
  flex-direction: column;
  flex: 1 0;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  min-width: 30%;
}

.generate-collection-modal {
  line-height: var(--line-height-m);
  display: flex;
}
.generate-collection-modal .generate-collection-content-container {
  display: flex;
  flex-direction: column;
  flex: 1 0;
}
.generate-collection-modal .generate-collection-content-container .generation-config-options-container {
  height: 30vh;
}
.generate-collection-modal .generate-collection-content-container .collection-name-input .input-field {
  margin-bottom: var(--spacing-xs);
}
.generate-collection-modal .generate-collection-content-container .collection-name-input .collection-name__error {
  visibility: hidden;
  color: var(--content-color-error);
  font-size: var(--text-size-s);
  margin-bottom: var(--spacing-s);
}
.generate-collection-modal .generate-collection-content-container .collection-name-input.is-required .collection-name__error {
  visibility: visible;
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-top: var(--spacing-l);
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options .api-element {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  margin: var(--spacing-xs);
  align-items: center;
  flex: 1 0 220px;
  padding: var(--spacing-xs) var(--spacing-s);
  cursor: pointer;
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options .api-element .api-elements-options__card {
  display: flex;
  align-items: flex-end;
  margin-left: var(--spacing-l);
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options .api-element:hover {
  background-color: var(--highlight-background-color-primary);
  border-radius: var(--spacing-xs);
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options .api-element .api-element-icon {
  align-self: center;
  display: flex;
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options .api-element .api-element-icon i {
  height: auto;
  width: auto;
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options .api-element .api-element-icon svg {
  width: var(--size-s);
  height: var(--size-s);
}
.generate-collection-modal .generate-collection-content-container .api-elements-options-wrapper .api-elements-options .api-element .api-element-button-text {
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-l);
}
.generate-collection-modal .modal-footer {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.create-test-suite-entity-modal .modal-content {
  line-height: var(--line-height-m);
  padding: var(--spacing-l);
  display: flex;
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0;
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .intro-content-wrapper {
  margin-bottom: var(--spacing-l);
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .intro-content-wrapper .intro-subtitle_learn-more {
  color: var(--content-color-link);
  cursor: pointer;
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .test-suite-name-input-wrapper {
  margin-bottom: var(--spacing-l);
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .test-suite-name-input-wrapper .test-suite-name-input-header {
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-s);
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .test-suite-name-input-wrapper .test-suite-name-input {
  width: 60%;
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .generation-config-options__toggle-button {
  display: inline-flex;
  align-items: center;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-link);
  cursor: pointer;
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .generation-config-options__toggle-button .toggle-button-icon {
  margin-right: var(--spacing-xs);
}
.create-test-suite-entity-modal .modal-content .create-test-suite-entity-wrapper .generation-config-options-wrapper {
  margin-top: var(--spacing-s);
  padding: var(--spacing-l);
  display: flex;
  flex-direction: column;
  flex: 1 0;
  overflow-y: auto;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.create-test-suite-entity-modal .modal-footer {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.create-test-suite-entity-modal .modal-footer .actions {
  display: flex;
  flex-basis: 60%;
}
.create-test-suite-entity-modal .modal-footer .actions .btn {
  font-weight: var(--text-weight-medium);
  height: var(--spacing-xxl);
}
.create-test-suite-entity-modal .modal-footer .actions .btn.btn-secondary {
  margin-right: var(--spacing-s);
}
.create-test-suite-entity-modal .modal-footer .actions .btn.btn-primary {
  flex: 1;
}

.entity-last-run__status {
  display: flex;
  margin-right: var(--spacing-s);
  border-radius: 30px;
  height: var(--size-xs);
  padding: var(--spacing-zero) var(--spacing-s);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  text-align: center;
}
.entity-last-run__status.success {
  background-color: var(--background-color-success);
  color: var(--content-color-success);
}
.entity-last-run__status.success.shrinked {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.entity-last-run__status.failed {
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.entity-last-run__status.failed.shrinked {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.entity-last-run__status.notest {
  background-color: var(--background-color-secondary);
  color: var(--content-color-secondary);
}
.entity-last-run__status.notest.shrinked {
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
.entity-last-run__status.error {
  align-items: center;
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.entity-last-run__status.no-test {
  color: var(--content-color-secondary);
  padding: unset;
}
.entity-last-run__status-icon {
  margin-right: var(--spacing-xs);
}
.entity-last-run__report {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  padding: var(--spacing-zero);
}
.entity-last-run__report:hover, .entity-last-run__report.is-hovered {
  color: var(--content-color-brand);
}

.entity-last-run__error {
  color: var(--content-color-error);
  cursor: pointer;
  font-size: var(--text-size-m);
}

.run-test-btn {
  min-width: 63px;
  height: var(--size-s);
  padding: var(--spacing-s);
}

.more-actions-dropdown.is-open {
  visibility: visible;
}

.test-suite-name {
  flex: 1 0 0 !important;
  margin-left: var(--spacing-s);
}

.test-suite-last-run {
  flex: 1 0 0 !important;
  height: var(--size-xs);
  flex-wrap: wrap;
}

.test-suite-validation {
  flex: 1 0 0 !important;
}

.test-suite-last-updated {
  flex: 1 0 0 !important;
}

.api-status-icon {
  margin-right: var(--spacing-xs);
}

.api-migration-nudge-icon {
  display: inline-flex;
  margin-right: var(--spacing-xs);
}

.collection-schema-sync-content__request {
  margin-left: var(--spacing-xs);
}
.collection-schema-sync-content__request-method {
  display: inline;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: 1.4;
}

.collection-schema-sync-content__collapsible {
  background: var(--background-color-secondary);
}

.collection-schema-sync-content__collapsible-container {
  overflow: hidden;
}

.collection-schema-sync-content__diff-section {
  padding-left: var(--spacing-xxl) !important;
  margin-left: var(--spacing-s);
}

.collection-schema-sync-content__container {
  overflow: auto !important;
  flex: 1 !important;
}

.api-dev-empty-state {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.api-dev-empty-state__image-wrapper {
  margin: var(--spacing-xl) var(--spacing-zero);
}
.api-dev-empty-state__blankState-wrapper {
  padding: var(--spacing-l);
}
.api-dev-empty-state__title {
  margin: var(--spacing-s);
}
.api-dev-empty-state__body {
  margin: var(--spacing-s) var(--spacing-zero);
  font-size: var(--text-size-l);
}
.api-dev-empty-state__action-wrapper {
  display: flex;
  margin-bottom: var(--spacing-xl);
}
.api-dev-empty-state__action-wrapper > * {
  margin: var(--spacing-s);
}
.api-dev-empty-state__subtext {
  margin: var(--spacing-l) var(--spacing-zero);
}

.new-api-options-tab {
  overflow: auto;
}
.new-api-options-tab .api-name-label {
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  min-width: 0px;
  flex-grow: 0.5;
}
.new-api-options-tab .api-name-label h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-api-options-tab .api-name-label .edit-icon-rename {
  display: none;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}
.new-api-options-tab .api-name-label .edit-icon-rename:hover use, .new-api-options-tab .api-name-label .edit-icon-rename.is-hovered use {
  fill: var(--content-color-primary);
}
.new-api-options-tab .api-name-label .edit-icon-rename:hover svg:not([class*=PartnerIcon]) > path[fill], .new-api-options-tab .api-name-label .edit-icon-rename.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.new-api-options-tab .api-name-label .edit-icon-rename:hover svg, .new-api-options-tab .api-name-label .edit-icon-rename.is-hovered svg {
  color: var(--content-color-primary);
}
.new-api-options-tab .api-name-label:hover .edit-icon-rename, .new-api-options-tab .api-name-label.is-hovered .edit-icon-rename {
  display: inline-flex;
  cursor: pointer;
}
.new-api-options-tab .api-name-label:hover .copy-link-icon, .new-api-options-tab .api-name-label.is-hovered .copy-link-icon {
  display: inline-flex;
  cursor: pointer;
}
.new-api-options-tab .api-name-label .copy-link-icon {
  display: none;
  margin-right: var(--spacing-xs);
}
.new-api-options-tab .api-name-label .api-name-input {
  min-width: var(--size-s);
  margin-right: var(--spacing-xs);
  font-size: var(--text-size-l);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.new-api-options-tab .repo-options-list {
  row-gap: var(--spacing-l);
}
.new-api-options-tab .connect-repo-heading-view {
  text-align: center;
}

.api-dev-multilevel-searchable-dropdown-wrapper .dropdown {
  width: 100%;
}
.api-dev-multilevel-searchable-dropdown-wrapper .dropdown .dropdown-button {
  width: 100%;
  justify-content: space-between;
  background-color: initial;
}
.api-dev-multilevel-searchable-dropdown-wrapper.is-disabled {
  background-color: var(--background-color-secondary);
  border-color: var(--border-color-strong);
  cursor: not-allowed;
}

.multilevel-searchable-dropdown div.search-input {
  position: sticky;
  top: 0px;
  z-index: 1;
  margin: var(--spacing-xs) var(--spacing-zero);
  border-radius: initial;
  margin-top: var(--spacing-zero);
  padding: var(--spacing-s) var(--spacing-l);
  border: 0px;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.multilevel-searchable-dropdown .dropdown-menu {
  padding: var(--spacing-zero);
  min-height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-default);
}
.multilevel-searchable-dropdown .dropdown-menu__previous-button-wrapper .btn {
  border-radius: initial;
  width: 100%;
  justify-content: flex-start;
}
.multilevel-searchable-dropdown .dropdown-menu__previous-button-text {
  width: 414px;
  padding-left: var(--spacing-m);
}
.multilevel-searchable-dropdown .dropdown-menu .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
  padding: var(--spacing-l);
}
.multilevel-searchable-dropdown .dropdown-menu-item {
  cursor: pointer;
}
.multilevel-searchable-dropdown .dropdown-menu-item--default {
  position: sticky;
  bottom: 0;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}
.multilevel-searchable-dropdown .dropdown-menu-item .item-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.multilevel-searchable-dropdown .dropdown-menu-item .item-wrapper .item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.multilevel-searchable-dropdown .empty-dropdown-menu-message {
  justify-content: center;
  display: flex;
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  flex-grow: 1;
  padding: var(--spacing-l);
  text-align: center;
}
.multilevel-searchable-dropdown .empty-dropdown-menu-message span {
  align-self: center;
}
.multilevel-searchable-dropdown .selected-label {
  width: 414px;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}

.api-diff-view-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.api-diff-view {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  min-height: 0;
}
.api-diff-view .api-diff-view-tab-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}
.api-diff-view .api-diff-view-tab-content .api-diff-view-tab-content-pane__head {
  max-height: 100%;
  overflow-y: auto;
}
.api-diff-view .api-diff-view-tab-content .api-tab-content-pane__head {
  height: 100%;
}

.api-diff-view-header {
  position: sticky;
}
.api-diff-view-header .api-diff-view-header__left-section {
  display: flex;
  align-items: center;
  color: var(--content-color-primary);
  min-width: 0px;
  margin-left: var(--spacing-s);
}
.api-diff-view-header .api-diff-view-header__left-section .api-diff-view-name-label {
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  min-width: 0px;
  max-width: 300px;
}
.api-diff-view-header .api-diff-view-header__left-section .api-diff-view-name-label .breadcrumb__text {
  font-size: var(--text-size-l);
}

.api-diff-view-tab-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.api-diff-view-tab-body .api-diff-view-tab-body-title {
  margin-bottom: var(--spacing-l);
}
.api-diff-view-tab-body .api-diff-view-tab-body__default {
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-xxl);
}

.test-tab-table .api-dev-table-header__cell {
  color: var(--content-color-secondary) !important;
}
.test-tab-table .row-hover__cell:last-child {
  margin-left: unset !important;
  flex: 0;
  position: absolute;
  right: 8px;
}
.test-tab-table .api-dev-table-row__cell.shrinked {
  margin-right: var(--spacing-zero) !important;
}
.test-tab-table .arrow-column {
  margin-right: var(--spacing-xxl);
}
.test-tab-table .api-dev-table-body .row-parent {
  display: flex;
  min-height: 36px;
  flex-direction: column;
  justify-content: center;
  border-bottom: var(--border-width-default) solid var(--border-color-default);
}
.test-tab-table .api-dev-table-body .row-parent .row-hover {
  padding: unset !important;
  min-height: 36px;
  box-sizing: border-box;
}
.test-tab-table .api-dev-table-body .row-parent .api-dev-table-row {
  color: var(--content-color-primary);
  position: relative;
}
.test-tab-table .api-dev-table-body .row-parent .api-dev-table-row .row-arrow:hover {
  background-color: var(--background-color-secondary);
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section {
  width: 100%;
  background: var(--background-color-secondary);
  display: flex;
  font-size: var(--text-size-m);
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .empty-section {
  width: 37.5%;
  margin-left: var(--spacing-xl);
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .filled-section {
  margin: var(--spacing-xs) var(--spacing-zero);
  margin-right: var(--spacing-m);
  margin-left: var(--spacing-s);
  font-size: inherit;
  color: var(--content-color-secondary);
  width: 75%;
  box-sizing: border-box;
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .filled-section .detail-row {
  min-height: var(--size-xs);
  padding: var(--spacing-m) var(--spacing-zero);
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .filled-section .detail-row .detail-row-header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-zero) var(--spacing-xs);
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .filled-section .detail-row .detail-row-header .view-report {
  cursor: pointer;
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .filled-section .detail-row ul {
  list-style: none;
  font-size: var(--text-size-s);
  padding-left: var(--spacing-xl);
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .filled-section .detail-row ul li::before {
  content: "•";
  color: var(--content-color-error);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.test-tab-table .api-dev-table-body .row-parent .detailed-section .filled-section .detail-row li {
  line-height: var(--line-height-m);
}

.release-test-tab-container .release-test-tab-heading {
  margin: var(--spacing-l);
  color: var(--content-color-primary);
}
.release-test-tab-container .release-test-tab {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-zero) var(--spacing-xl);
}
.release-test-tab-container .release-test-tab .release-test-tab-header {
  color: var(--content-color-secondary);
  height: var(--size-m);
  padding: var(--spacing-s);
}
.release-test-tab-container .release-test-tab .release-test-tab-body {
  border-top: var(--border-width-default) solid var(--border-color-default);
}
.release-test-tab-container .release-test-tab .release-test-tab-body .release-test-tab-body-row {
  background-color: var(--background-color-primary);
  height: 44px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-s);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  font-size: var(--text-size-m);
}
.release-test-tab-container .release-test-tab .release-test-tab-body .release-test-tab-body-row .test-tab-row-icon {
  margin-right: var(--spacing-xs);
}
.release-test-tab-container .release-test-tab .release-test-tab-body .release-test-tab-body-row .test-tab-row-text {
  margin-bottom: 2px;
  color: var(--content-color-primary);
}
.release-test-tab-container .release-test-tab .release-test-tab-body .release-test-tab-body-row .test-tab-row-text__error {
  color: var(--content-color-error);
  margin-bottom: 2px;
}
.release-test-tab-container .release-test-tab .release-test-tab-body .release-test-tab-body-row .test-tab-row-text__error__retry-link {
  cursor: pointer;
  text-decoration: underline;
}
.release-test-tab-container .release-test-tab .release-test-tab-body .release-test-tab-body-row .relation-cell {
  display: flex;
  align-items: center;
}
.release-test-tab-container .release-test-tab .release-test-tab-body .release-test-tab-body-row:hover {
  background-color: var(--background-color-secondary);
}
.release-test-tab-container .release-test-tab .release-test-tab-body:first-child {
  border-top: var(--border-width-default) solid var(--border-color-default);
}

.empty-test-state-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty-test-state-wrapper__heading {
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
}
.empty-test-state-wrapper__components {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
}

.version-tab-body {
  overflow-y: auto;
}
.version-tab-body .accordion {
  border-top: 0;
}
.version-tab-body .version-tab-body__default {
  margin: var(--spacing-xl) auto var(--spacing-xxxl);
  max-width: 616px;
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
}
.version-tab-body .version-tab-body__default__pane {
  width: 100%;
}
.version-tab-body .version-tab-body__default__tile-heading {
  margin-left: var(--spacing-m);
}
.version-tab-body .version-tab-body__default__tile-body {
  margin-top: var(--spacing-s);
}
.version-tab-body .version-tab-body__default__generate-collection-button {
  margin-left: auto;
}
.version-tab-body .version-tab-body__screen {
  margin-top: var(--spacing-m);
}
.version-tab-body .version-tab-body__screen__back-btn {
  margin-left: var(--spacing-m);
}
.version-tab-body .is-hidden {
  display: none;
}

.release-overview-tab {
  align-self: center;
  margin: var(--spacing-zero) auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.release-overview-tab__title {
  padding-left: 19px;
  margin-bottom: var(--spacing-s);
}
.release-overview-tab__notes {
  padding-right: var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.release-overview-tab__notes-empty-state {
  color: var(--content-color-secondary);
}
.release-overview-tab__notes-label {
  margin-bottom: var(--spacing-s);
}
.release-overview-tab__notes-md {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.release-overview-tab__notes-md p {
  margin: var(--spacing-zero);
}
.release-overview-tab__notes-md a {
  color: var(--content-color-link);
  text-decoration: none;
}
.release-overview-tab__notes-edit-mode {
  display: flex;
  cursor: text;
  width: 100%;
}
.release-overview-tab__notes-edit-mode-wrapper {
  width: 100%;
}
.release-overview-tab__notes-edit-icon {
  margin-left: var(--spacing-l);
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.release-overview-tab__notes-edit-icon use {
  fill: var(--content-color-secondary);
}
.release-overview-tab__notes-edit-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.release-overview-tab__notes-edit-icon svg {
  color: var(--content-color-secondary);
}
.release-overview-tab__notes-edit-mode:hover .release-overview-tab__notes-edit-icon {
  visibility: visible;
}
.release-overview-tab__summary .overview__meta-editor-summary-text {
  padding-left: var(--spacing-s);
}

.api-sidebar-list__empty-item__content {
  padding: var(--spacing-xs) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
}

.specification-editor-inline-decoration {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: var(--background-color-info);
}

.specification-editor-line-decoration {
  background: var(--highlight-background-color-info);
  width: 3px !important;
  margin-left: 3px;
}

.open-external-link-modal-message {
  margin-top: 20px;
}

.open-external-link-modal-message-url {
  color: var(--content-color-link);
}

.recommendations {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-secondary);
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
.recommendations .recommendation-item {
  position: relative;
  padding: 0 10px;
  background-color: var(--background-color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.recommendations .recommendation-item .recommendation-banner {
  flex: 0 0 32px;
  width: 32px;
  height: 40px;
  background: url("../assets/images/thumbnails/postmanaut-helmet.png");
}
.recommendations .recommendation-item .recommendation-description {
  line-height: 20px;
  padding: 10px 8px;
  align-items: center;
  word-break: break-word;
}
.recommendations .recommendation-item .recommendation-description .recommendation-description-title {
  font-weight: 600;
}
.recommendations .recommendation-item .recommendation-description .recommendation-action {
  display: inline-block;
  margin-left: 4px;
  font-weight: 600;
  color: var(--highlight-background-color-brand);
  cursor: pointer;
}
.recommendations .recommendation-item .recommendation-dismiss {
  cursor: pointer;
  line-height: 20px;
  padding: 14px 8px 14px 4px;
}
.recommendations .recommendation-item:hover, .recommendations .recommendation-item.is-hovered {
  background-color: var(--background-color-tertiary);
}

.loading-indicator-wrapper.sign-out-loading-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.sign-out-loading--text {
  margin-left: 150px;
}

.sign-out-warning--checkbox {
  position: absolute;
  margin-top: 0px;
}
.sign-out-warning--checkbox-text {
  margin-left: 24px;
}

.sign-out-warning-container {
  background-color: var(--background-color-error);
  padding: 10px 10px 10px 15px;
  margin-bottom: 15px;
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.sign-out-warning-container .sign-out-warning--header {
  color: var(--content-color-error);
  font-weight: 600;
}
.sign-out-warning-container .sign-out-warning--header i {
  vertical-align: middle;
  padding-right: 5px;
}
.sign-out-warning-container .sign-out-warning--warning-icon {
  margin-right: 7px;
  margin-top: 2px;
  flex-shrink: 0;
}
.sign-out-warning-container .sign-out-warning--warning-icon use {
  fill: var(--base-color-error);
}
.sign-out-warning-container .sign-out-warning--warning-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.sign-out-warning-container .sign-out-warning--warning-icon svg {
  color: var(--base-color-error);
}
.sign-out-warning-container .sign-out-warning--text {
  padding-right: 10px;
  margin-left: 24px;
}
.sign-out-warning-container .sign-out-warning--list {
  list-style-type: decimal;
  padding-left: 0;
  list-style-position: inside;
  line-height: 1.5;
}
.sign-out-warning-container .sign-out-settings-icon {
  vertical-align: middle;
}

.sidebar-mock-listing__error__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.sidebar-mock-listing__error {
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: var(--spacing-xxl);
  flex-direction: column;
  min-height: 300px;
}
.sidebar-mock-listing__error__content {
  text-align: center;
}
.sidebar-mock-listing__error__content__header {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
}
.sidebar-mock-listing__error__content__sub-header {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  margin: var(--spacing-l) var(--spacing-xl);
}
.sidebar-mock-listing__error__retry-button {
  height: var(--size-m);
  width: 100px;
}
.sidebar-mock-listing__container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.sidebar-mock-listing__container__list {
  flex: 1;
}

.mock-action-item-wrapper {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  padding: 0 var(--spacing-l);
  width: 100%;
}

.mock-request-access-tooltip-body {
  padding: var(--spacing-xs);
}
.mock-request-access-tooltip-body .title {
  padding-bottom: var(--spacing-m);
  line-height: 18px;
  font-size: var(--text-size-s);
}

.call-logs__error__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.call-logs__error {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 300px;
}
.call-logs__error__illustration {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/alert.svg");
}
.call-logs__error__content {
  text-align: center;
}
.call-logs__error__content__header {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
}
.call-logs__error__content__sub-header {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  margin: var(--spacing-l) var(--spacing-zero);
}
.call-logs__error__retry-button {
  height: var(--size-m);
  width: 180px;
}
.call-logs__empty-search-results {
  display: flex;
  flex: 1;
}
.call-logs__empty-search-results .no-result {
  margin: var(--spacing-zero) var(--spacing-xl);
  flex: 1;
}
.call-logs__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: var(--spacing-xxl) var(--spacing-zero);
  color: var(--content-color-primary);
}
.call-logs__empty__header {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
  margin-top: var(--spacing-l);
}
.call-logs__empty__body {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-l);
  line-height: var(--line-height-m);
  background-color: var(--background-color-secondary);
  padding: var(--spacing-l);
  border-radius: var(--border-radius-default);
}
.call-logs__empty__body__steps:not(:last-child) {
  margin-bottom: var(--spacing-l);
}
.call-logs__empty__body__header {
  margin-bottom: var(--spacing-l);
}
.call-logs__empty__body__steps__url {
  color: var(--content-color-link);
  cursor: pointer;
  word-break: break-all;
  width: fit-content;
  display: flex;
  align-items: center;
}
.call-logs__empty__body__steps .btn {
  font-weight: var(--text-weight-regular);
}
.call-logs__empty__body__copy__button {
  height: var(--size-s);
}
.call-logs__empty__body__copy__button__icon {
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-xs);
}
.call-logs__empty .learn-more-link {
  color: var(--content-color-link);
}
.call-logs__list__wrapper {
  display: flex;
  flex: 1;
  overflow-x: auto;
  flex-direction: column;
  padding-bottom: var(--spacing-xxl);
  overflow-y: overlay;
}
.call-logs__list-new {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 530px;
  padding: 0 var(--spacing-l);
}
.call-logs__list-new__input {
  width: 296px;
  margin-right: var(--spacing-xs);
}
.call-logs__list-new__header {
  margin-bottom: var(--spacing-l);
  margin-top: 26px;
  display: flex;
}
.call-logs__list-new__header__is-loading {
  cursor: default;
}
.call-logs__list-new__header__is-loading svg {
  animation: spin 1s linear infinite;
}
.call-logs__list-new__header__search-bar {
  max-width: 296px;
  display: flex;
  flex: 1;
  border-radius: var(--border-radius-default);
  height: var(--size-m);
  margin-right: var(--spacing-xs);
}
.call-logs__list-new__body {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.call-logs__list-new__body__load-more {
  text-align: center;
  padding: var(--spacing-l) var(--spacing-zero);
}
.call-logs__list-new__body__is-loading {
  opacity: 0.4;
}
.call-logs__list-new__body__header {
  padding: var(--spacing-s);
  padding-left: var(--spacing-zero);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
  height: 40px;
  box-sizing: border-box;
}
.call-logs__list-new__body__header__time, .call-logs__list-new__body__header__request, .call-logs__list-new__body__header__response {
  display: inline-block;
  vertical-align: sub;
  line-height: var(--line-height-s);
}
.call-logs__list-new__body__header__time {
  width: 20%;
  min-width: 20%;
  margin: var(--spacing-zero) var(--spacing-xxl);
}
.call-logs__list-new__body__header__request {
  width: 35%;
  min-width: 35%;
  margin-right: var(--spacing-xxl);
}
.call-logs__list-new__body__header__response {
  width: 25%;
  min-width: 25%;
}
.call-logs__list-new__footer {
  padding: var(--spacing-xxl) 0;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-primary);
  margin: var(--spacing-xl) var(--spacing-m) var(--spacing-zero);
  font-size: var(--text-size-m);
}
.call-logs__list-new__footer .learn-more-link {
  color: var(--content-color-link);
}
.call-logs__list-new__item__details {
  padding-left: var(--spacing-xxl);
}
.call-logs__list-new__item__title {
  cursor: pointer;
  position: relative;
  padding: var(--spacing-s);
  padding-left: var(--spacing-zero);
  line-height: var(--line-height-l);
  height: 40px;
  box-sizing: border-box;
}
.call-logs__list-new__item__title__request-icon {
  float: left;
  margin-right: var(--spacing-xs);
  text-transform: uppercase;
}
.call-logs__list-new__item__title__request-icon .collection-sidebar-list-item__request__label {
  line-height: var(--line-height-l);
}
.call-logs__list-new__item__title__date {
  display: inline-block;
  width: 20%;
  min-width: 20%;
  margin-right: var(--spacing-xxl);
  vertical-align: middle;
}
.call-logs__list-new__item__title__path {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 35%;
  min-width: 35%;
  margin-right: var(--spacing-xxl);
  vertical-align: middle;
}
.call-logs__list-new__item__title__example-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 25%;
  min-width: 25%;
  vertical-align: middle;
}
.call-logs__list-new__item__title__error-container {
  display: inline-block;
  width: 25%;
  min-width: 25%;
  vertical-align: middle;
}
.call-logs__list-new__item__title__error-example-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--content-color-error);
  max-width: 70%;
  display: inline-block;
  vertical-align: middle;
}
.call-logs__list-new__item__title__error-icon {
  vertical-align: middle;
  margin-left: var(--spacing-s);
}
.call-logs__list-new__item__title__error-tooltip {
  margin-left: var(--spacing-xs);
  vertical-align: middle;
}
.call-logs__list-new__item__title__error-tooltip__content {
  max-width: 224px;
}
.call-logs__list-new__item__title__error-tooltip__content__header {
  font-weight: var(--text-weight-medium);
}
.call-logs__list-new__item__title__arrow-icon {
  display: inline-block;
  height: var(--size-s);
  width: var(--size-s);
  text-align: center;
  margin-right: var(--spacing-s);
}
.call-logs__list-new__item__title__arrow-icon i {
  vertical-align: middle;
}
.call-logs__list-new__item__title.is-visible {
  background: var(--background-color-secondary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.call-logs__list-new__item__title.is-disabled {
  cursor: default;
}
.call-logs__list-new__item__body, .call-logs__list-new__item__header {
  position: relative;
  padding: var(--spacing-s);
  padding-left: var(--spacing-zero);
  display: inline-block;
  cursor: pointer;
  color: var(--content-color-tertiary);
  line-height: var(--line-height-s);
}
.call-logs__list-new__item__body__arrow-icon, .call-logs__list-new__item__header__arrow-icon {
  margin-right: var(--spacing-m);
  display: inline-block;
}
.call-logs__list-new__item__body__arrow-icon i, .call-logs__list-new__item__header__arrow-icon i {
  vertical-align: middle;
}
.call-logs__list-new__item__body__title, .call-logs__list-new__item__header__title {
  vertical-align: middle;
}
.call-logs__list-new__item__body__loader, .call-logs__list-new__item__header__loader {
  text-align: center;
  transform: scale(0.7);
}
.call-logs__list-new__item__body__error, .call-logs__list-new__item__header__error {
  margin-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
}
.call-logs__list-new__item__body__error__retry, .call-logs__list-new__item__header__error__retry {
  display: inline-block;
  cursor: pointer;
  color: var(--content-color-link);
}
.call-logs__list-new__item__body__wrapper, .call-logs__list-new__item__header__wrapper {
  display: inline-block;
  margin-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
  overflow-x: auto;
  overflow-y: auto;
  max-height: 240px;
  width: 90%;
  white-space: pre;
  font-family: var(--text-family-code);
}
.call-logs__list-new__item__body__wrapper__item, .call-logs__list-new__item__header__wrapper__item {
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.call-logs__list-new__item__body__wrapper__item__value, .call-logs__list-new__item__header__wrapper__item__value {
  color: var(--blue-30);
}
.call-logs__list-new__item__body__download {
  padding-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
}
.call-logs__list-new__item__body__response {
  padding-bottom: var(--spacing-s);
}
.call-logs__list-new__empty-state {
  padding: 0;
  max-width: 322px;
  padding-top: 38px;
}
.call-logs__list {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 768px;
}
.call-logs__list__header {
  margin: var(--spacing-xl) var(--spacing-l) var(--spacing-l) var(--spacing-l);
}
.call-logs__list__header__title {
  display: inline-block;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  line-height: var(--line-height-m);
}
.call-logs__list__header__refresh {
  margin-right: var(--spacing-xs);
}
.call-logs__list__header__refresh-button {
  margin-left: auto;
  vertical-align: middle;
}
.call-logs__list__header__is-loading {
  cursor: default;
}
.call-logs__list__header__is-loading svg {
  animation: spin 1s linear infinite;
}
.call-logs__list__header__search-bar {
  max-width: 296px;
  display: flex;
  flex: 1;
  border-radius: var(--border-radius-default);
  height: var(--size-m);
}
.call-logs__list__header__search-bar .input-search {
  margin-top: var(--spacing-zero);
}
.call-logs__list__body {
  font-size: var(--text-size-m);
  padding: 0 var(--spacing-l);
  color: var(--content-color-primary);
}
.call-logs__list__body__load-more {
  text-align: center;
  padding: var(--spacing-l) var(--spacing-zero);
}
.call-logs__list__body__is-loading {
  opacity: 0.4;
}
.call-logs__list__body__header {
  padding: var(--spacing-s);
  padding-left: var(--spacing-zero);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-secondary);
  height: 40px;
  box-sizing: border-box;
}
.call-logs__list__body__header__time, .call-logs__list__body__header__request, .call-logs__list__body__header__response {
  display: inline-block;
  vertical-align: sub;
  line-height: var(--line-height-s);
}
.call-logs__list__body__header__time {
  width: 20%;
  min-width: 20%;
  margin: var(--spacing-zero) var(--spacing-xxl);
}
.call-logs__list__body__header__request {
  width: 35%;
  min-width: 35%;
  margin-right: var(--spacing-xxl);
}
.call-logs__list__body__header__response {
  width: 25%;
  min-width: 25%;
}
.call-logs__list__footer {
  padding: var(--spacing-xxl) 0;
  color: var(--content-color-primary);
  margin: var(--spacing-zero) var(--spacing-m);
  font-size: var(--text-size-m);
}
.call-logs__list__footer .learn-more-link {
  color: var(--content-color-link);
}
.call-logs__list__item__details {
  padding-left: var(--spacing-xxl);
  background: var(--background-color-secondary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.call-logs__list__item__title {
  cursor: pointer;
  position: relative;
  padding: var(--spacing-s);
  padding-left: var(--spacing-zero);
  line-height: var(--line-height-l);
  height: 40px;
  box-sizing: border-box;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.call-logs__list__item__title__request-icon {
  float: left;
  margin-right: var(--spacing-xs);
  text-transform: uppercase;
}
.call-logs__list__item__title__request-icon .collection-sidebar-list-item__request__label {
  line-height: var(--line-height-l);
}
.call-logs__list__item__title__date {
  display: inline-block;
  width: 20%;
  min-width: 20%;
  margin-right: var(--spacing-xxl);
  vertical-align: middle;
}
.call-logs__list__item__title__path {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 35%;
  min-width: 35%;
  margin-right: var(--spacing-xxl);
  vertical-align: middle;
}
.call-logs__list__item__title__example-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 25%;
  min-width: 25%;
  vertical-align: middle;
}
.call-logs__list__item__title__error-container {
  display: inline-block;
  width: 25%;
  min-width: 25%;
  vertical-align: middle;
}
.call-logs__list__item__title__error-example-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--content-color-error);
  max-width: 70%;
  display: inline-block;
  vertical-align: middle;
}
.call-logs__list__item__title__error-icon {
  vertical-align: middle;
  margin-left: var(--spacing-s);
}
.call-logs__list__item__title__error-tooltip {
  margin-left: var(--spacing-xs);
  vertical-align: middle;
}
.call-logs__list__item__title__error-tooltip__content {
  font-size: var(--text-size-m);
  max-width: 224px;
}
.call-logs__list__item__title__error-tooltip__content__header {
  font-weight: var(--text-weight-medium);
}
.call-logs__list__item__title__arrow-icon {
  display: inline-block;
  height: var(--size-s);
  width: var(--size-s);
  text-align: center;
  margin-right: var(--spacing-s);
}
.call-logs__list__item__title__arrow-icon i {
  vertical-align: middle;
}
.call-logs__list__item__title__delete-button {
  position: absolute;
  right: var(--spacing-s);
}
.call-logs__list__item__title__delete-button button:hover {
  background-color: var(--background-color-error);
}
.call-logs__list__item__title.is-even {
  background: var(--background-color-secondary);
}
.call-logs__list__item__title.is-visible {
  background: var(--background-color-secondary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.call-logs__list__item__title.is-disabled {
  cursor: default;
}
.call-logs__list__item__body, .call-logs__list__item__header {
  position: relative;
  padding: var(--spacing-s);
  padding-left: var(--spacing-zero);
  display: inline-block;
  cursor: pointer;
  color: var(--content-color-tertiary);
  line-height: var(--line-height-s);
}
.call-logs__list__item__body__arrow-icon, .call-logs__list__item__header__arrow-icon {
  margin-right: var(--spacing-m);
  display: inline-block;
}
.call-logs__list__item__body__arrow-icon i, .call-logs__list__item__header__arrow-icon i {
  vertical-align: middle;
}
.call-logs__list__item__body__title, .call-logs__list__item__header__title {
  vertical-align: middle;
  color: var(--content-color-primary);
}
.call-logs__list__item__body__loader, .call-logs__list__item__header__loader {
  text-align: center;
  transform: scale(0.7);
}
.call-logs__list__item__body__error, .call-logs__list__item__header__error {
  margin-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
}
.call-logs__list__item__body__error__retry, .call-logs__list__item__header__error__retry {
  display: inline-block;
  cursor: pointer;
  color: var(--content-color-link);
}
.call-logs__list__item__body__wrapper, .call-logs__list__item__header__wrapper {
  display: inline-block;
  margin-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
  overflow-x: auto;
  overflow-y: auto;
  max-height: 240px;
  width: 90%;
  white-space: pre;
  font-family: var(--text-family-code);
}
.call-logs__list__item__body__wrapper__item, .call-logs__list__item__header__wrapper__item {
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.call-logs__list__item__body__wrapper__item__value, .call-logs__list__item__header__wrapper__item__value {
  color: var(--blue-30);
}
.call-logs__list__item__body__download {
  padding-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
}
.call-logs__list__item__body__response {
  padding-bottom: var(--spacing-s);
}

.mock-details__loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.mock-details__error__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.mock-details__error {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 300px;
}
.mock-details__error__illustration {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/alert.svg");
}
.mock-details__error__content {
  text-align: center;
  font-size: 12px;
}
.mock-details__error__content__header {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
}
.mock-details__error__content__sub-header {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  margin: var(--spacing-l) var(--spacing-zero);
}
.mock-details__error__retry-button {
  height: var(--size-m);
  width: 180px;
}
.mock-details__content-wrapper {
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 16px;
}
.mock-details__content-wrapper-old {
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
}
.mock-details__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 768px;
}

.mock-info-context-view-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.mock-info-context-view-wrapper {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.mock-info-context-view-wrapper .right-context-bar__title {
  color: var(--content-color-primary);
}
.mock-info-context-view-wrapper .mock-info-context-view {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}
.mock-info-context-view-wrapper .mock-info-context-view__error__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.mock-info-context-view-wrapper .mock-info-context-view__error {
  display: flex;
  flex: 1;
  align-items: center;
  margin-top: var(--spacing-l);
  flex-direction: column;
  min-height: 300px;
}
.mock-info-context-view-wrapper .mock-info-context-view__error__content {
  text-align: center;
}
.mock-info-context-view-wrapper .mock-info-context-view__error__content__header {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
}
.mock-info-context-view-wrapper .mock-info-context-view__error__content__sub-header {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  margin: var(--spacing-l) var(--spacing-xl);
}
.mock-info-context-view-wrapper .mock-info-context-view__error__retry-button {
  height: var(--size-m);
  width: 100px;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity {
  margin-bottom: var(--spacing-xl);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__label {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  display: flex;
  flex: 1;
  margin-right: var(--spacing-s);
  margin-top: var(--spacing-xs);
  align-items: center;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button {
  background-color: transparent;
  height: var(--size-xs);
  width: var(--size-xs);
  margin-left: var(--spacing-m);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy use {
  fill: var(--content-color-secondary);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy svg {
  color: var(--content-color-secondary);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy:hover use, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy.is-hovered use {
  fill: var(--content-color-primary);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy:hover svg:not([class*=PartnerIcon]) > path[fill], .mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy:hover svg, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__copy.is-hovered svg {
  color: var(--content-color-primary);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__success {
  cursor: default;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__success use {
  fill: var(--content-color-success);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__button__success svg {
  color: var(--content-color-success);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content .activity-item-profile-pic-wrapper {
  margin: var(--spacing-zero);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content .activity-item-profile-pic-wrapper .pm-user-avatar-icon {
  height: var(--size-s);
  width: var(--size-s);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content .activity-item-user {
  margin-left: var(--spacing-s);
  font-weight: var(--text-weight-regular);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__id, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__url, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__collection, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__environment {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__id, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__url {
  line-height: var(--line-height-m);
  background-color: var(--background-color-secondary);
  padding: 6px var(--spacing-s);
  border-radius: var(--border-radius-default);
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__collection, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__environment {
  margin-right: var(--spacing-s);
  color: var(--content-color-primary);
  cursor: pointer;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__collection:hover, .mock-info-context-view-wrapper .mock-info-context-view__entity__content__environment:hover {
  text-decoration: underline;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__collection {
  max-width: 50%;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__collection-fork-label {
  max-width: 25%;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__collection-version-tag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: var(--spacing-xs);
  padding: 0px var(--spacing-s);
  border-radius: 30px;
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  background-color: var(--background-color-tertiary);
  line-height: var(--line-height-s);
  max-width: 25%;
}
.mock-info-context-view-wrapper .mock-info-context-view__entity__content__collection-version-tag__current {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background-color: var(--background-color-primary);
  color: var(--content-color-tertiary);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.mock-details-header {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  padding: 0 var(--spacing-l);
}
.mock-details-header__old {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-size-m);
  padding: 0 var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.mock-details-header__mock-inactive-label {
  color: var(--content-color-tertiary);
  background-color: var(--background-color-tertiary);
  padding: var(--spacing-zero) var(--spacing-s);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  white-space: pre;
  text-overflow: ellipsis;
  min-width: 0px;
  max-width: 100px;
  border-radius: 30px;
  cursor: auto;
  margin-left: var(--spacing-s);
}
.mock-details-header__mock-name-wrapper {
  display: flex;
  flex: 1;
  min-width: 0;
  color: var(--content-color-primary);
  margin-right: var(--spacing-l);
  line-height: var(--line-height-m);
  align-items: center;
}
.mock-details-header__mock-name-wrapper .mock-rename-btn {
  margin-left: var(--spacing-s);
  visibility: hidden;
  display: flex;
  align-items: center;
}
.mock-details-header__mock-name-wrapper:hover .mock-rename-btn, .mock-details-header__mock-name-wrapper.is-hovered .mock-rename-btn {
  visibility: visible;
  cursor: pointer;
}
.mock-details-header__copy-link {
  visibility: hidden;
}
.mock-details-header__mock-name-wrapper:hover .mock-details-header__copy-link {
  visibility: visible;
}
.mock-details-header__mock-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mock-details-header__mock-actions {
  display: flex;
  align-items: center;
}
.mock-details-header__mock-actions__copy, .mock-details-header__mock-actions__refresh, .mock-details-header__mock-actions__docs {
  margin-right: 2px;
  height: var(--size-m);
  padding: var(--spacing-s);
}
.mock-details-header__mock-actions__copy__icon, .mock-details-header__mock-actions__refresh__icon, .mock-details-header__mock-actions__docs__icon {
  margin-right: var(--spacing-xs);
}
.mock-details-header__mock-actions__more-options {
  min-width: var(--size-m);
  height: var(--size-m);
  padding: var(--spacing-s);
}

.mock-sub-tab {
  padding-top: var(--spacing-zero);
  padding-left: var(--spacing-zero);
  padding-right: var(--spacing-zero);
  flex: 1;
  height: 100%;
  min-height: 0px;
}
.mock-sub-tab ul {
  margin-top: var(--spacing-zero);
}
.mock-sub-tab > div:first-child {
  margin-bottom: 0px;
}
.mock-sub-tab__header {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: var(--spacing-l);
  width: 100%;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.mock-sub-tab__panels {
  height: 100%;
}
.mock-sub-tab__panel {
  height: 100%;
}

.verticalDivider {
  border-left-color: rgb(214, 214, 214);
  border-width: 1px;
  border-left-style: solid;
  height: 16px;
  margin: 0 4px;
}

.mocks-dropdown-menu {
  min-width: 220px;
}
.mocks-dropdown-menu .dropdown-menu-item {
  padding: 0;
}
.mocks-dropdown-menu .dropdown-menu-item .dropdown-menu-item-icon {
  margin-right: var(--spacing-s);
}
.mocks-dropdown-menu .mock-action-item {
  display: flex;
  flex: 1;
}

.mock-edit-container {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: var(--spacing-xxxl);
}
.mock-edit-container__title {
  font-size: var(--text-size-xxl);
  font-weight: var(--text-weight-medium);
  padding-top: var(--spacing-xxl);
}
.mock-edit-container__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
  overflow-x: overlay;
  overflow-y: overlay;
}
.mock-edit-container__content .permissions-loading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.mock-edit-container__content .mock-edit-form__error {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-xxl);
  flex-direction: column;
  flex: 1;
  justify-content: center;
}
.mock-edit-container__content .mock-edit-form__error .creation-error__icon {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/alert.svg");
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-zero);
}
.mock-edit-container__content .mock-edit-form__error .creation-error__title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-zero);
  color: var(--content-color-primary);
}
.mock-edit-container__content .mock-edit-form__error .creation-error__subtitle {
  font-size: var(--text-size-m);
  margin: var(--spacing-l) var(--spacing-xl);
  color: var(--content-color-secondary);
}
.mock-edit-container__content .mock-edit-form__error .creation-error__actions .btn-primary {
  font-size: var(--text-size-m);
  height: var(--spacing-xxl);
  min-width: 60px;
  line-height: var(--line-height-xs);
}
.mock-edit-container__content .mock-edit-form__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 70px;
}
.mock-edit-container__content .mock-edit-form__content .step1.create-mock-form__body {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl) var(--spacing-zero);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group {
  margin-bottom: var(--spacing-l);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
  font-weight: var(--text-weight-medium);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__label.is-error {
  color: var(--base-color-error);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input {
  display: flex;
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__input__helptext {
  padding-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
  max-width: 522px;
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__input__helptext__content {
  max-width: 100%;
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__input__helptext .learn-more-link {
  color: var(--content-color-link);
  font-weight: var(--text-weight-regular);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field {
  display: flex;
  width: 320px;
  height: 100%;
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field__label {
  cursor: pointer;
  font-weight: var(--text-weight-medium);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .environment-dropdown__button-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .input-box {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .input-box__error {
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-error);
  background-color: var(--background-color-primary);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .btn-secondary {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  background: var(--background-color-secondary);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .input-checkbox {
  margin-right: var(--spacing-s);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .mock-name-empty__msg-container {
  margin-top: var(--spacing-xs);
}
.mock-edit-container__content .mock-edit-form__content .step1 .create-mock-form__body__input-group .mock-name-empty__msg-container__text {
  color: var(--content-color-error);
}
.mock-edit-container__content .mock-edit-form__content .is-loading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.mock-edit-container__content .mock-edit-form__content .create-mock-form__version-dropdown {
  width: 320px;
}
.mock-edit-container__content .mock-edit-form__content .create-mock-form__version-dropdown .create-mock-form__version-dropdown__button-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mock-edit-container__footer {
  margin-right: 70px;
  padding-left: var(--spacing-s);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.mock-edit-container__footer .btn-secondary {
  margin-right: var(--spacing-s);
}
.mock-edit-container__footer__button__container {
  display: flex;
  padding-top: var(--spacing-xxl);
  padding-bottom: var(--spacing-xxl);
}

.mock-delay-selector-v2__custom-input {
  max-width: 200px;
}
.mock-delay-selector-v2__custom-input__field + p {
  width: max-content;
}
.mock-delay-selector-v2__custom-input__metric {
  margin-top: var(--spacing-s);
}

.mock-delay-selector {
  width: 100%;
}
.mock-delay-selector .mock-delay__checkbox {
  display: flex;
}
.mock-delay-selector .mock-delay__checkbox-label {
  cursor: pointer;
  font-weight: var(--text-weight-medium);
}
.mock-delay-selector .mock-delay__options {
  min-width: 293px;
}
.mock-delay-selector .mock-delay__options .delay-duration-type-dropdown {
  margin-top: var(--spacing-m);
  height: var(--spacing-xxl);
}
.mock-delay-selector .mock-delay__options .delay-duration__input-wrapper .delay-duration__input {
  display: flex;
  width: fit-content;
}
.mock-delay-selector .mock-delay__options .delay-duration__input-wrapper .delay-duration__input .delay-duration__input-unit {
  margin: 6px auto;
  align-self: center;
  height: 100%;
  justify-content: center;
  position: relative;
  left: 6px;
  cursor: default;
}
.mock-delay-selector .mock-delay__options .delay-duration__input-wrapper .delay-duration__input .delay-duration__input-unit.is-disabled {
  color: var(--content-color-tertiary);
}
.mock-delay-selector .mock-delay__options .delay-duration__input-wrapper .delay-duration__input .delay-duration__input-field.is-disabled {
  cursor: default;
  opacity: 0.4;
}
.mock-delay-selector .mock-delay__options .delay-duration__error {
  display: flex;
  color: var(--base-color-error);
}

.mock-edit-tab__loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.mock-edit-tab__error {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 300px;
}
.mock-edit-tab__error__illustration {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/alert.svg");
}
.mock-edit-tab__error__content {
  text-align: center;
  font-size: var(--text-size-m);
}
.mock-edit-tab__error__content__header {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
}
.mock-edit-tab__error__content__sub-header {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  margin: var(--spacing-l) var(--spacing-zero);
}
.mock-edit-tab__error__retry-button {
  height: var(--size-m);
  width: 180px;
}

.mock-create-container {
  flex-direction: column;
  display: flex;
  height: 100%;
  overflow: auto;
  padding: var(--spacing-xxl);
  font-size: var(--text-size-m);
}
.mock-create-container .mock-create-container__content {
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);
  overflow: auto;
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-zero) var(--spacing-xs);
}
.mock-create-container .mock-create-container__content .step1.create-mock-form__body {
  display: flex;
  flex-direction: column;
}
.mock-create-container .mock-create-container__content .step1.is-loading {
  flex: 1;
  align-items: center;
  justify-content: center;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group {
  margin-bottom: var(--spacing-l);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
  font-weight: var(--text-weight-medium);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__label.is-error {
  color: var(--base-color-error);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input {
  display: flex;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__input__helptext {
  padding-left: var(--spacing-xxl);
  color: var(--content-color-tertiary);
  max-width: 522px;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__input__helptext__content {
  max-width: 100%;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__input__helptext .learn-more-link {
  color: var(--content-color-link);
  font-weight: var(--text-weight-regular);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field {
  display: flex;
  width: 350px;
  height: 100%;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field__label {
  cursor: pointer;
  font-weight: var(--text-weight-medium);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .environment-dropdown__button-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .input-box {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .input-box__error {
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-error);
  background-color: var(--background-color-primary);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .btn-secondary {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  background: var(--background-color-secondary);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .create-mock-form__version-dropdown {
  width: 350px;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .create-mock-form__body__field .create-mock-form__version-dropdown .create-mock-form__version-dropdown__button-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .create-mock-form__body__input .input-checkbox {
  margin-right: var(--spacing-s);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .mock-name-empty__msg-container {
  margin-top: var(--spacing-xs);
}
.mock-create-container .mock-create-container__content .step1 .create-mock-form__body__input-group .mock-name-empty__msg-container__text {
  color: var(--content-color-error);
}
.mock-create-container .mock-create-container__content .mock-delay__options {
  min-width: 323px;
}
.mock-create-container .assign-mock-collection___search-bar {
  max-width: 296px;
  margin-top: var(--spacing-l);
  display: inline-flex;
  flex: 1;
  border-radius: var(--border-radius-default);
  height: var(--size-m);
  width: 100%;
}
.mock-create-container .assign-mock-collection___search-bar .input-search {
  margin-top: var(--spacing-zero);
}
.mock-create-container .assign-mock-collection___view-toggle {
  float: right;
  margin-top: var(--spacing-l);
}
.mock-create-container .assign-mock-collection__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  height: 100%;
  width: 100%;
}
.mock-create-container .assign-mock-collection__container .assign-mock-collection__body {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mock-create-container .assign-mock-collection__container .assign-mock-collection__body .tabs {
  flex: 0 0 30px;
  justify-content: center;
  width: fit-content;
  width: -moz-max-content; /* Firefox */
  align-items: center;
}
.mock-create-container .assign-mock-collection__container .assign-mock-collection__body .assign-mock-collection__right {
  overflow: hidden;
  min-height: 70px;
  overflow: auto;
}
.mock-create-container .assign-mock-collection__container .assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__entity-empty {
  text-align: center;
  color: var(--content-color-secondary);
}
.mock-create-container .assign-mock-collection__container .assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__entity-empty__title {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--line-height-m);
  margin-top: var(--spacing-l);
}
.mock-create-container .assign-mock-collection__container .assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__entity-empty__subtitle {
  font-size: var(--text-size-m);
  margin-top: var(--line-height-m);
}
.mock-create-container .assign-mock-collection__container .step1 {
  overflow: auto;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__body__tab-content {
  flex: 1;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .tabs {
  border: var(--border-width-default) solid var(--border-color-default);
  margin-bottom: 17px;
  border-radius: var(--border-radius-default);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .tabs .tab-primary {
  border-bottom: var(--spacing-zero);
  background-color: var(--highlight-background-color-primary);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .tabs .tab-primary .tab-text-wrapper {
  margin-right: var(--spacing-m);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .tabs .tab-primary.is-active {
  background-color: var(--background-color-primary);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .tabs .tab-primary.is-active .tab-text-wrapper {
  box-shadow: none;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right__intro {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  width: 100%;
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-l);
  color: var(--content-color-primary);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right__intro .btn-text {
  line-height: var(--line-height-s);
  padding: 0 3px;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right__intro__heading {
  font-weight: 600;
  display: block;
  margin-bottom: var(--spacing-xs);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right {
  flex: 1;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__helptext {
  color: var(--content-color-primary);
  margin-top: 20px;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__helptext .btn-text {
  height: 0px;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__test-header {
  display: flex;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__test-header .input-checkbox {
  margin-right: 5px;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__right__table {
  flex: 1;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 0 224px;
  height: 40px;
  padding: var(--spacing-s);
  cursor: pointer;
  border-radius: var(--border-radius-default);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__card:hover, .mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__card.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__card .assign-mock-collection__card__icon {
  margin-right: 10px;
  margin-top: 2px;
  flex: 0 0 22px;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__card .assign-mock-collection__card__title {
  display: flex;
  align-items: center;
  max-width: 176px;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__card .assign-mock-collection__card__title span {
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-l);
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__card .assign-mock-collection__card__title .collection-meta-icons {
  overflow: visible;
}
.mock-create-container .assign-mock-collection__container .step1.assign-mock-collection__body .assign-mock-collection__right .assign-mock-collection__right__collections {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  border: var(--border-width-default) solid var(--border-color-default);
  overflow: auto;
  max-height: 100%;
  padding: 10px;
}
.mock-create-container .loading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.mock-create-container .mock-form-footer__container {
  display: flex;
  flex: 0 0 104px;
  align-items: center;
  border-top: var(--border-width-default) solid var(--border-color-default);
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
}
.mock-create-container .mock-form__footer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: var(--text-size-m);
  position: relative;
  margin-top: var(--spacing-m);
  grid-gap: var(--spacing-s);
}
.mock-create-container .mock-form__footer .btn-primary {
  margin-right: var(--spacing-s);
}
.mock-create-container .mock-create__error {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 300px;
}

.assign-mock-collection__right__table {
  height: 100%;
  flex: 1;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor {
  height: 100%;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor {
  height: 100%;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor .data-editor {
  overflow-y: auto;
  height: 100%;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor {
  margin-right: 1px;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor__cell--path .prefix {
  display: flex;
  align-items: center;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .dropdown-button .btn {
  height: 28px;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .DraftEditor-editorContainer .public-DraftEditor-content {
  height: 20px;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor {
  border: none;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor .data-editor__cell--path {
  display: flex;
  flex: 1;
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor .data-editor__cell--path .auto-suggest {
  margin: var(--spacing-zero);
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor .data-editor__cell--path .prefix {
  font-size: var(--text-size-m);
  padding-left: 5px;
  line-height: 25px;
  color: var(--content-color-secondary);
}
.assign-mock-collection__right__table .assign-mock-collection__data-editor .data-editor .data-editor__cell--path .data-editor__cell__content {
  margin-left: 3px;
}
.assign-mock-collection__right__table .data-editor {
  display: flex;
  flex-direction: column;
}
.assign-mock-collection__right__table .data-editor .data-editor__header {
  margin-right: var(--spacing-m);
  flex: 0 0 30px;
}
.assign-mock-collection__right__table .data-editor .data-editor__rows {
  overflow-y: scroll;
}
.assign-mock-collection__right__table .data-editor .data-editor__header .data-editor__header__cell {
  font-size: var(--text-size-s);
  color: var(--content-color-primary);
  font-weight: 600;
}
.assign-mock-collection__right__table .data-editor .data-editor__header .data-editor__header__cell .data-editor__header__cell__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assign-mock-collection__right__table .data-editor .data-editor__header .assign-mock-collection__test-header {
  overflow: hidden;
}
.assign-mock-collection__right__table .data-editor .data-editor__header .assign-mock-collection__test-header .input-checkbox {
  min-width: var(--size-xs);
}
.assign-mock-collection__right__table .data-editor .data-editor__header .assign-mock-collection__test-header span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assign-mock-collection__right__table .data-editor .data-editor__row .data-editor__reorder {
  width: 14px;
}
.assign-mock-collection__right__table .data-editor .data-editor__row .dropdown {
  margin: var(--spacing-zero) 5px;
}
.assign-mock-collection__right__table .data-editor .data-editor__row .dropdown-button .btn {
  background-color: transparent;
  box-shadow: none;
  padding: var(--spacing-zero);
}
.assign-mock-collection__right__table .data-editor .data-editor__row .data-editor__cell--time {
  display: flex;
  flex: 1;
  margin-right: 30px;
}
.assign-mock-collection__right__table .data-editor .data-editor__row .data-editor__cell--time .postfix {
  font-size: var(--text-size-m);
  padding-left: 5px;
  line-height: 25px;
  color: var(--content-color-secondary);
}
.assign-mock-collection__right__table .data-editor .data-editor__row .data-editor__custom-row.is-focused .data-editor__cell--time, .assign-mock-collection__right__table .data-editor .data-editor__row .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: var(--spacing-zero);
}
.assign-mock-collection__right__table .data-editor .data-editor__row:last-child .data-editor__custom-row.is-focused .data-editor__cell--time, .assign-mock-collection__right__table .data-editor .data-editor__row:last-child .data-editor__custom-row:hover .data-editor__cell--time {
  margin-right: 30px;
}

.info-pane__mock-servers {
  margin-top: var(--spacing-xl);
}
.info-pane__mock-servers__loading {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-l);
}
.info-pane__mock-servers__header-wrapper {
  display: flex;
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
}
.info-pane__mock-servers__header-wrapper__label {
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  cursor: default;
  flex: 1;
}
.info-pane__mock-servers__header-wrapper__create-new-mock {
  padding-top: 6px;
  padding-bottom: 6px;
  height: var(--size-s);
}
.info-pane__mock-servers__header-wrapper__create-new-mock__icon {
  padding-right: var(--spacing-xs);
}
.info-pane__mock-servers__show-all-mocks-label {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  padding-top: 2px;
  padding-bottom: 2px;
}
.info-pane__mock-servers__show-all-mocks-label:hover {
  cursor: pointer;
  text-decoration: underline;
}
.info-pane__mock-servers__toggle-wrapper {
  float: left;
  cursor: pointer;
}
.info-pane__mock-servers__toggle {
  margin-left: var(--spacing-zero);
  margin-top: var(--spacing-s);
  margin-right: var(--spacing-s);
  border-radius: var(--border-radius-s);
}
.info-pane__mock-servers__toggle :hover {
  background: var(--highlight-background-color-secondary);
}
.info-pane__mock-servers__mock-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}
.info-pane__mock-servers__mock-item__link {
  display: flex;
  overflow: hidden;
}
.info-pane__mock-servers__mock-item__link__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  padding-top: 2px;
  padding-bottom: 2px;
}
.info-pane__mock-servers__mock-item__link__name .aether-link-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-pane__mock-servers__mock-item__active {
  display: flex;
  align-items: center;
}
.info-pane__mock-servers__empty-state {
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.info-pane__mock-servers__error-state {
  margin-top: 6px;
  margin-bottom: 6px;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.info-pane__mock-servers__create-new {
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  margin-top: 6px;
  margin-bottom: 6px;
  padding: var(--spacing-zero);
}
.info-pane__mock-servers__create-new__icon {
  padding-right: var(--spacing-s);
}

.mock-status-icon {
  margin-right: var(--spacing-xs);
}

.inactive-mock-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
  background-color: var(--content-color-tertiary);
}
.inactive-mock-indicator__sidebar_fixed {
  margin-right: var(--spacing-xxxl);
}
.inactive-mock-indicator__sidebar_fixed.use-browser-tabs {
  margin-right: 56px;
}
.inactive-mock-indicator__banner {
  display: flex;
  flex: 1;
  background-color: var(--background-color-secondary);
  justify-content: left;
  align-items: center;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  line-height: var(--line-height-m);
  padding: var(--spacing-l);
  max-height: 42px;
  box-sizing: border-box;
}
.inactive-mock-indicator__banner__icon {
  margin-right: var(--spacing-s);
}

.mock-offline__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.mock-offline__sidebar {
  padding: var(--spacing-xxl) var(--spacing-l);
  margin-top: var(--spacing-xxl);
}
.mock-offline__sidebar .mock-offline__content__header {
  color: var(--content-color-secondary);
}

.mock-offline__tab {
  justify-content: center;
}
.mock-offline__tab .mock-offline__content__header {
  color: var(--content-color-primary);
}

.mock-offline__context-bar {
  margin-top: 64px;
}
.mock-offline__context-bar .mock-offline__content__header {
  color: var(--content-color-primary);
}

.mock-offline {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 200px;
}
.mock-offline__content {
  text-align: center;
  line-height: var(--line-height-m);
  margin-top: var(--spacing-l);
}
.mock-offline__content__header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-s);
}
.mock-offline__content__sub-header {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.mock-tab-error {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.mock-tab-error__content {
  display: flex;
  width: 415px;
  padding: var(--spacing-l);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mock-tab-error__content .error__illustration {
  margin-bottom: var(--spacing-l);
}
.mock-tab-error__content__title {
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.mock-tab-error__content__body {
  color: var(--content-color-secondary);
  text-align: center;
}
.mock-tab-error__content__refresh-button {
  margin-top: var(--spacing-l);
}

.mock-no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--content-color-primary);
  flex: 1;
  margin: var(--spacing-zero) var(--spacing-xl);
  margin-top: var(--spacing-xxxl);
}
.mock-no-result__label {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-bold);
  margin-bottom: 10px;
  margin-top: var(--spacing-l);
}
.mock-no-result__subtext {
  font-size: var(--text-size-m);
  opacity: 0.6;
}

.custom-mock-error {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.custom-mock-error__title {
  font-size: var(--text-size-xxl);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-bold);
  margin-bottom: 20px;
  margin-top: var(--spacing-l);
}
.custom-mock-error__subtitle {
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
  margin-bottom: 20px;
  max-width: 410px;
  text-align: center;
}
.custom-mock-error__action .btn {
  font-size: var(--text-size-xl);
  padding: 10px 20px;
}

.mock-overview-container {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
}
.mock-overview-container__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-zero);
  overflow: inherit;
  max-width: 560px;
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
}
.mock-overview-container__content__wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}
.mock-overview-container__content__border {
  max-width: 480px;
  padding-top: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.mock-overview-container__content__dropdown {
  max-width: 320px;
}
.mock-overview-container__content__dropdown__label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  margin-bottom: var(--spacing-s);
  font-weight: var(--text-weight-bold);
  display: flex;
  color: var(--content-color-secondary);
}
.mock-overview-container__content__dropdown__icon {
  margin-left: var(--spacing-xs);
  display: flex;
  align-items: center;
}
.mock-overview-container__content__dropdown__container {
  margin-top: var(--spacing-xl);
}
.mock-overview-container__content__delay-container {
  padding-top: var(--spacing-l);
}
.mock-overview-container__content__delay-container__label {
  padding-bottom: var(--spacing-s);
}
.mock-overview-container__content__delay-input-unit {
  display: flex;
  align-items: center;
  padding-left: var(--spacing-xs);
}
.mock-overview-container__content__delay-input-wrapper {
  padding-top: var(--spacing-m);
  max-width: 158px;
  display: flex;
}
.mock-overview-container__content__is-loading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.mock-overview-container__content__isPrivate__checkbox {
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
}
.mock-overview-container__content__isPrivate__container {
  padding-top: 28px;
}
.mock-overview-container__content__delay-duration-error {
  color: var(--base-color-error);
  padding-top: var(--spacing-xs);
}
.mock-overview-container__error {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-xxl);
  flex-direction: column;
  flex: 1;
  justify-content: center;
}
.mock-overview-container__save-header {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  justify-content: space-between;
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  align-items: center;
}

.mock-warning-text-wrapper {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-xs);
}
.mock-warning-text-wrapper__label {
  padding-left: var(--spacing-xs);
}

.mock-request-url-editor {
  flex: 1;
  min-width: 0;
  color: var(--content-color-primary);
  background-color: var(--background-color-secondary);
  height: 40px;
}
.mock-request-url-editor:focus, .mock-request-url-editor.is-focused {
  outline: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
  height: 100%;
}
.mock-request-url-editor .auto-suggest {
  height: 100%;
  border-right: solid var(--border-width-default) var(--border-color-strong);
  border-left: solid var(--border-width-default) var(--border-color-strong);
  box-sizing: border-box;
  margin-right: -1px;
}
.mock-request-url-editor .auto-suggest:hover, .mock-request-url-editor .auto-suggest.is-hovered {
  height: 100%;
  background-color: var(--highlight-background-color-secondary);
}
.mock-request-url-editor .auto-suggest.is-focused {
  background-color: var(--background-color-primary);
  height: unset;
  border-bottom: solid var(--border-width-default) var(--border-color-strong);
}
.mock-request-url-editor .DraftEditor-root {
  padding: 10px;
  padding-bottom: var(--spacing-m);
}
.mock-request-url-editor .public-DraftEditor-content, .mock-request-url-editor .public-DraftEditorPlaceholder-root {
  font-size: var(--text-size-m);
  line-height: 1.5;
}

.mock-endpoint-overview {
  margin-left: var(--spacing-l);
  margin-right: var(--spacing-l);
  margin-bottom: var(--spacing-s);
}
.mock-endpoint-overview__editor-wrapper {
  padding-top: var(--spacing-xl);
}
.mock-endpoint-overview__editor-wrapper__label {
  padding-bottom: var(--spacing-s);
}

.mock-request-method-editor {
  min-width: 100%;
  justify-content: space-between;
  border-radius: 3px 0px 0px 3px;
  background-color: var(--background-color-secondary);
}

.mocks-stub-dropdown-menu {
  min-width: 220px;
}

.mocks-stub-dropdown-menu .dropdown-menu-item {
  padding: 0 var(--spacing-l);
}

.mocks-stub-dropdown-menu .dropdown-menu-item .dropdown-menu-item-icon {
  margin-right: var(--spacing-s);
}

.mocks-stub-dropdown-menu .mock-stub-action-item {
  display: flex;
  flex: 1;
}

.mock-actions__more-actions-placeholder {
  height: var(--size-s);
  width: var(--size-s);
  background-color: transparent;
}

.mock-actions__toggle-active-placeholder {
  height: var(--size-s);
  width: var(--size-s);
  padding: 0 2px;
  background-color: transparent;
}

.mock-create-container-v2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  overflow: auto;
  padding-bottom: 36px;
  overflow-y: overlay;
}
.mock-create-container-v2__loading-indicator {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.mock-create-container-v2 .mock-form-v2 {
  margin-bottom: 20px;
}
.mock-create-container-v2 .mock-form-v2__radio-button {
  max-width: 345px;
  padding-bottom: var(--spacing-l);
}
.mock-create-container-v2 .mock-form-v2__radio-button > span > label {
  font-weight: var(--text-weight-medium);
}
.mock-create-container-v2 .mock-form-v2__footer {
  margin-left: -290px;
  gap: var(--spacing-s);
  display: flex;
}

.assign-mock-collection-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-s);
}
.assign-mock-collection-container__dropdown {
  width: 240px;
  margin-top: var(--spacing-s);
}
.assign-mock-collection-container__checkbox {
  margin-bottom: var(--spacing-xs);
  margin-top: var(--spacing-l);
}
.assign-mock-collection-container__helpertext {
  color: var(--content-color-secondary);
}
.assign-mock-collection-container__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xxl);
}
.assign-mock-collection-container__content__error-message {
  margin-top: var(--spacing-m);
  text-align: center;
}
.assign-mock-collection-container__content__error-message__description {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
  margin-right: var(--spacing-xs);
}
.assign-mock-collection-container__content__error-message__link-with-icon {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  margin-top: var(--spacing-m);
  color: var(--content-color-secondary);
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  cursor: pointer;
}
.assign-mock-collection-container__content__error-message__link {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-link);
  cursor: pointer;
}

.assign-mock-templates-container {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
  grid-column-gap: var(--spacing-xs);
  grid-row-gap: var(--spacing-xs);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  overflow: overlay;
  max-height: 212px;
  padding: var(--spacing-l);
  width: 482px;
  margin-left: -22px;
  margin-top: var(--spacing-m);
  border-radius: 3px;
}
.assign-mock-templates-container .is-active {
  background-color: var(--highlight-background-color-primary);
}
.assign-mock-templates-container__card {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 222px;
  flex-wrap: wrap;
  align-items: center;
  height: 60px;
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) 20px;
  cursor: pointer;
  border-radius: var(--border-radius-default);
}
.assign-mock-templates-container__card:hover, .assign-mock-templates-container__card.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.assign-mock-templates-container__card__icon {
  margin-top: var(--spacing-xs);
  flex: 0 0 22px;
}
.assign-mock-templates-container__card__title, .assign-mock-templates-container__card__subtitle {
  display: flex;
  align-items: center;
  max-width: 172px;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.assign-mock-templates-container__card span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.assign-mock-templates-container__card__subtitle {
  flex-basis: 100%;
  margin-left: 22px;
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
}

.assign-mock-template-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xxl);
}
.assign-mock-template-container__error-message {
  text-align: center;
  margin-top: var(--spacing-m);
}
.assign-mock-template-container__error-message__description {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
}
.assign-mock-template-container__error-message__link {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  margin-top: var(--spacing-m);
  color: var(--content-color-secondary);
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  cursor: pointer;
}

.mock-sidebar-stub-list-item {
  position: relative;
  padding-left: 16px;
}

.mock-sidebar-stub-scenario {
  position: relative;
  padding-left: 32px;
}

.mock-overview-confirmation-modal__footer {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

/**
 * Styling for `ConfigurationListItem` component
 */
.configuration-list-item__container {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  padding: var(--spacing-s) var(--spacing-l);
  box-sizing: border-box;
  width: 100%;
}
.configuration-list-item__meta-text {
  display: flex;
  width: 70%;
  flex-direction: column;
  justify-content: flex-start;
}
.configuration-list-item__title {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}
.configuration-list-item__description {
  margin-top: var(--spacing-xs);
}
.configuration-list-item__options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  min-width: 30%;
  margin-left: var(--spacing-l);
}
.configuration-list-item__input {
  display: flex;
}
.configuration-list-item__input--input-box {
  width: 80px;
}
.configuration-list-item__input--multi-select {
  width: 70%;
  padding: var(--spacing-xs);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background-color: var(--background-color-secondary);
}
.configuration-list-item__input--multi-select:hover, .configuration-list-item__input--multi-select.is-hovered {
  border-color: var(--border-color-strong);
  background-color: var(--highlight-background-color-secondary);
}
.configuration-list-item__input--multi-input {
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background-color: var(--background-color-secondary);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 80px;
  width: 70%;
  padding: var(--spacing-xs);
  overflow-y: auto;
  position: relative;
  font-size: var(--text-size-m);
}
.configuration-list-item__input--multi-input:hover, .configuration-list-item__input--multi-input.is-hovered {
  border-color: var(--border-color-strong);
  background-color: var(--highlight-background-color-secondary);
}
.configuration-list-item__input--multi-input--placeholder {
  margin: var(--spacing-xs);
  padding: var(--spacing-s) var(--spacing-xs);
  color: var(--content-color-tertiary);
  position: absolute;
}
.configuration-list-item__input-field {
  flex-grow: 1;
  max-width: 90%;
  margin: var(--spacing-s) var(--spacing-xs);
  padding: var(--spacing-xs);
  color: var(--content-color-primary);
}
.configuration-list-item__input .editable-tag {
  width: max-content;
  max-width: 90%;
}
.configuration-list-item__input .editable-tag__content {
  margin-right: var(--spacing-s);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.configuration-list-item__dropdown-btn {
  justify-content: space-between;
}
.configuration-list-item__selected-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 38px;
  height: auto;
  padding: var(--spacing-zero);
}
.configuration-list-item__selected-list .editable-tag {
  width: max-content;
}
.configuration-list-item__action {
  margin-top: var(--spacing-s);
  display: flex;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.configuration-list-item__action__override {
  display: inline-flex;
}
.configuration-list-item__action__override-btn {
  padding: var(--spacing-zero);
}
.configuration-list-item__action__normal {
  padding: var(--spacing-zero);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.configuration-list-item__action__normal-text > span {
  text-decoration: underline;
  cursor: pointer;
}

.interceptor-settings-filters-container {
  padding-bottom: 16px;
}

.interceptor-settings-filters-group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.interceptor-settings-filters-label {
  font-style: normal;
  font-weight: 600;
  flex: 0 0 32px;
  padding-right: 20px;
}

.interceptor-settings-filters-input {
  flex: 1;
}

.interceptor-settings-filters-input-help {
  color: var(--content-color-secondary);
  padding-top: 4px;
}

.interceptor-settings-filters-wrapper {
  overflow: auto;
}
.interceptor-settings-filters-wrapper .interceptor-bootcamp-lesson-banner {
  background-color: var(--background-color-primary);
  margin-bottom: 16px;
}

.interceptor-settings-show-filters {
  margin-top: 16px;
  max-height: 100px;
}
.interceptor-settings-show-filters .show-filters-btn {
  margin: 0px;
  padding: 0px;
}

.interceptor-show-filters-btn {
  margin-top: 16px;
  padding: 0px;
}

.interceptor-capture-cookies-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.interceptor-capture-cookies-label .info-button {
  display: inline-flex;
  margin-left: 5px;
}

.interceptor-settings-domain-label {
  padding-top: 16px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.interceptor-domains-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.interceptor-cookie-sync-domain-list {
  background: var(--background-color-primary);
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid var(--border-color-default);
  height: auto;
  margin-top: 16px;
  display: flex;
  align-items: stretch;
  background-color: var(--highlight-background-color-primary);
  width: 100%;
}

.interceptor-cookie-sync-domain-list > table {
  width: 100px;
  margin: 10px;
  text-align: left;
}

.interceptor-remove-domain-btn {
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-end;
  justify-content: flex-end;
}

.interceptor-capture-cookies {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.interceptor-capture-cookies .interceptor-capture-cookies-menu {
  flex: 0 0 40px;
  box-sizing: border-box;
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  padding: 0 10px;
}
.interceptor-capture-cookies .interceptor-capture-cookies-list {
  flex: 1;
}

.interceptor-capture-cookies-menu {
  display: flex;
  flex-direction: row;
}
.interceptor-capture-cookies-menu .interceptor-capture-cookies-menu__left {
  flex: 1;
  display: flex;
  align-items: center;
}
.interceptor-capture-cookies-menu .interceptor-capture-cookies-menu__right {
  flex: 0 0 auto;
  align-items: center;
  display: flex;
}
.interceptor-capture-cookies-menu .interceptor-capture-cookies-menu__right .interceptor-capture-requests-menu--interceptor-connected-label {
  color: var(--content-color-secondary);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.interceptor-capture-cookies-menu .interceptor-capture-cookies-menu-toggle {
  color: var(--content-color-secondary);
  margin-right: 5px;
}
.interceptor-capture-cookies-menu .interceptor-capture-cookies-menu--interceptor-connected-label {
  color: var(--content-color-secondary);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.interceptor-capture-cookies-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.interceptor-capture-cookies {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.interceptor-capture-cookies .interceptor-capture-cookies-menu {
  flex: 0 0 40px;
  box-sizing: border-box;
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  padding: 0 10px;
}
.interceptor-capture-cookies .interceptor-capture-cookies-list {
  flex: 1;
}

.interceptor-capture-cookies-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.interceptor-capture-cookies {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.interceptor-capture-cookies .interceptor-capture-cookies-menu {
  flex: 0 0 40px;
  box-sizing: border-box;
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  padding: 0 10px;
}
.interceptor-capture-cookies .interceptor-capture-cookies-list {
  flex: 1;
}

.interceptor-capture-cookies-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.interceptor-settings-add-domain {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
}

.interceptor-settings-add-domain-label {
  flex: 0 0 150px;
  padding-right: 16px;
}

.interceptor-settings-domain-input {
  flex: 1;
  margin-right: 10px;
}

.interceptor-domain-container td:first-child {
  flex-grow: 16;
}
.interceptor-domain-container td:last-child {
  flex-grow: 1;
  margin-left: 10px;
}

.interceptor-domain-list-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 250px;
}
.interceptor-domain-list-wrapper .interceptor-domain-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 8px 0px;
  border: 1px solid var(--border-color-default);
  overflow: auto;
  background-color: var(--highlight-background-color-primary);
}
.interceptor-domain-list-wrapper .interceptor-domain-list-item {
  display: flex;
  flex-direction: row;
  padding: 2px 8px;
  border-radius: 2px;
  align-items: center;
  height: 24px;
}
.interceptor-domain-list-wrapper .interceptor-domain-list-item .interceptor-domain-list-item-label {
  flex: 2;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.interceptor-domain-list-wrapper .interceptor-domain-list-item .interceptor-domain-list-item-value {
  flex: 0 0 70px;
  display: flex;
  justify-content: flex-end;
}
.interceptor-domain-list-wrapper .interceptor-domain-list-item .interceptor-domain-list-item-value .intereceptor-remove-domain-btn {
  cursor: pointer;
}

.interceptor-bridge-connected-dot {
  width: 8px;
  height: 8px;
  background-color: var(--base-color-success);
  border-radius: 50%;
  margin: 0 4px;
}

.interceptor-bridge-disconnected-dot {
  width: 8px;
  height: 8px;
  background-color: var(--content-color-secondary);
  border-radius: 50%;
  margin: 0 4px;
}

.interceptor-connection-indicator {
  display: inline-flex;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  background-color: var(--base-color-success);
  border-radius: 8px;
}
.interceptor-connection-indicator .connected {
  background-color: var(--base-color-success);
}
.interceptor-connection-indicator .disconnected {
  background-color: var(--content-color-secondary);
}

.capture-settings-tabs {
  border-bottom: 1px solid var(--border-color-default);
}

.interceptor-settings-connection-wrapper {
  padding: 0px;
}

.interceptor-settings-connection-group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.interceptor-target-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.interceptor-capture-requests-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.interceptor-capture-requests-menu {
  display: flex;
  flex-direction: row;
}
.interceptor-capture-requests-menu .interceptor-capture-requests-menu__left {
  flex: 1;
  display: flex;
  align-items: center;
}
.interceptor-capture-requests-menu .interceptor-capture-requests-menu__right {
  flex: 0 0 auto;
  align-items: center;
  display: flex;
}
.interceptor-capture-requests-menu .interceptor-capture-requests-toggle-label {
  color: var(--content-color-secondary);
  margin-right: 5px;
}
.interceptor-capture-requests-menu .interceptor-capture-requests-menu--interceptor-connected-label {
  color: var(--content-color-secondary);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.interceptor-capture-requests-banner {
  padding-bottom: var(--spacing-m);
}

.interceptor_capture_cookies_learn_more {
  padding: 0px;
}
.interceptor_capture_cookies_learn_more .external-link {
  padding-left: 2px;
  margin: 0px;
  color: var(--content-color-link);
  cursor: pointer;
}

.interceptor-encryption-key-tooltip-wrapper {
  max-width: 310px;
}

.interceptor-encryption-key-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.interceptor-encryption-key-label .info-button {
  display: inline-flex;
  margin-left: 5px;
}
.interceptor-encryption-key-label .info-button-new__tooltip {
  display: inline-flex;
  margin-left: 5px;
}

.encryption-key-mismatch-btn {
  height: 24px;
  width: 24px;
}
.encryption-key-mismatch-btn:active, .encryption-key-mismatch-btn.is-active {
  background-color: var(--highlight-background-color-primary);
}

.interceptor-encryption-info {
  display: inline-flex;
  margin-left: 5px;
}

.interceptor-encryption-key-warning use {
  fill: var(--base-color-error);
}
.interceptor-encryption-key-warning svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.interceptor-encryption-key-warning svg {
  color: var(--base-color-error);
}

.interceptor-secure-lock-icon use {
  fill: var(--base-color-success);
}
.interceptor-secure-lock-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-success);
}
.interceptor-secure-lock-icon svg {
  color: var(--base-color-success);
}

.interceptor-encryption-key-tooltip {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
  background-color: var(--background-color-secondary);
}

.interceptor-tooltip__text {
  margin-bottom: 16px;
}

.interceptor-encryption-indicator-icon {
  padding-right: 2px;
}

.interceptor-encryption-key-input {
  width: 100%;
  max-width: 280px;
}

.interceptor-secure-connection-label {
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: 14px;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.interceptor-secure-connection-label .key-match {
  color: var(--content-color-success);
}
.interceptor-secure-connection-label .key-mismatch {
  color: var(--content-color-error);
}

.interceptor-encryption-key-input-help {
  color: var(--content-color-secondary);
  padding-top: 4px;
  padding-bottom: 24px;
}
.interceptor-encryption-key-input-help .interceptor-key-validation-fail {
  color: var(--content-color-error);
}

.interceptor-encryption-key-save-btn {
  padding: 0px;
}

.interceptor-snap-warning {
  padding-top: 30px;
}
.interceptor-snap-warning .interceptor-snap-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--content-color-primary);
  padding-bottom: 8px;
}
.interceptor-snap-warning .interceptor-snap-info {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
  color: var(--content-color-secondary);
}

.capture-panel-header {
  padding: 10px;
}
.capture-panel-header .capture-panel-header__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: var(--content-color-primary);
}

.capture-settings-tabs {
  border-bottom: 1px solid var(--border-color-default);
}

.capture_requests_learn_more {
  padding-bottom: 16px;
}
.capture_requests_learn_more .external-link {
  padding-left: 2px;
  margin: 0px;
  color: var(--content-color-link);
  cursor: pointer;
}

.capture-requests-source-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;
}

.capture-container {
  font-size: var(--text-size-m);
  position: relative;
  display: inline-block;
  z-index: 100;
}

.capture_request_source_switcher {
  padding-bottom: 16px;
}

.capture-icon__nudge:after {
  content: "";
  background-color: var(--base-color-brand);
  position: absolute;
  border-radius: var(--border-radius-max);
  top: 4px;
  left: 22px;
  height: 7px;
  width: 7px;
}

.capture-icon-tab-stretch {
  margin: calc(-1 * var(--spacing-l));
}

.capture-modal-fixed-control {
  margin: calc(-1 * var(--spacing-l)) calc(-1 * var(--spacing-l)) var(--spacing-l) calc(-1 * var(--spacing-l));
}

.capture-request-body-container {
  overflow-y: overlay;
  overflow-x: hidden;
  padding-right: var(--spacing-l);
  padding-left: var(--spacing-xs);
  margin-right: calc(-1 * var(--spacing-l));
}

.capture-modal-body-size {
  max-height: min( 80vh - 240px, 45vh);
}

.additional-filters-area {
  margin-right: var(--spacing-xs);
}

.settings-container {
  color: var(--content-color-primary);
}

.capture-settings-content {
  padding: 20px 20px 0px 20px;
  font-size: 12px;
}

.capture-settings-connection-wrapper {
  padding: 0px;
}

.capture-settings-toggle-switch-container {
  padding: 8px 20px 0px 20px;
  font-size: 12px;
}

.capture-feed-container {
  position: absolute;
  right: 0;
}

.capture-feed {
  display: flex;
  flex-direction: column;
  overflow: overlay;
  box-sizing: border-box;
  width: 440px;
  height: 100%;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-default);
  margin-bottom: var(--spacing-m);
  margin-top: var(--spacing-xs);
  padding: var(--spacing-xl) var(--spacing-xl);
}
.capture-feed.is-hidden {
  display: none;
}

.interceptor-warning-banner {
  height: auto;
  align-items: center;
  color: var(--content-color-primary);
  box-sizing: border-box;
  margin-top: var(--spacing-l);
  padding: var(--spacing-l);
}

.interceptor-warning-banner {
  background: var(--background-color-warning);
}
.interceptor-warning-banner .interceptor-warning {
  margin-left: var(--spacing-s);
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-small);
}
.interceptor-warning-banner .interceptor-warning span {
  font-weight: bold;
}
.interceptor-warning-banner .interceptor-warning__title {
  display: block;
  margin-bottom: var(--spacing-s);
}

.proxy-startup-banner__container {
  background-color: var(--background-color-info);
  padding: var(--spacing-l);
  display: flex;
  flex-direction: row;
  margin-bottom: var(--spacing-l);
}
.proxy-startup-banner__close {
  cursor: pointer;
}

.proxy-banner-body {
  padding: 5px 0;
}

.proxy-info-banner__container {
  background-color: var(--background-color-secondary);
  padding: var(--spacing-l);
  display: flex;
  flex-direction: row;
}

.target-input-flex {
  flex: auto;
  overflow: hidden;
}

.target-option-fork-label-area {
  max-width: 100px;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.diff-strap-numbers__wrapper {
  display: flex;
  flex-direction: column;
  color: var(--content-color-tertiary);
  min-width: 30px;
  align-items: center;
}
.diff-strap-numbers__wrapper .diff-strap-numbers {
  min-height: 20px;
  padding: var(--spacing-zero) var(--spacing-xs);
}

.diff-strap-numbers-container {
  box-sizing: border-box;
  display: flex;
  background: var(--background-color-secondary);
  height: 100%;
  padding: var(--spacing-s) var(--spacing-zero);
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.changelog__wrapper {
  overflow-y: auto;
}

.changelog {
  margin-bottom: var(--spacing-xl);
  margin-right: var(--spacing-l);
}
.changelog__actor-icon {
  display: flex;
  width: var(--size-m);
  height: var(--size-m);
  margin: var(--spacing-zero) 10px;
  background-color: var(--background-color-secondary);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.changelog__actor-icon__content use {
  fill: var(--content-color-tertiary);
}
.changelog__actor-icon__content svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.changelog__actor-icon__content svg {
  color: var(--content-color-tertiary);
}
.changelog__more-loader {
  display: flex;
  justify-content: center;
  padding: var(--spacing-xl);
  padding-top: var(--spacing-zero);
}
.changelog .activity-item-header {
  padding-bottom: var(--spacing-l);
}
.changelog .activity-item-profile-pic-wrapper {
  margin: var(--spacing-zero) 10px;
}
.changelog__header {
  display: flex;
  flex: 1;
  flex-direction: column;
  line-height: var(--line-height-s);
}
.changelog__header__date {
  color: var(--content-color-tertiary);
  margin-bottom: var(--spacing-xs);
}
.changelog__header__action-description {
  color: var(--content-color-primary);
}
.changelog__header__action-description__actor-name {
  display: inline-block;
  font-weight: var(--text-weight-medium);
}
.changelog .changelog-content-container {
  border-left: var(--border-width-l) var(--border-style-solid) var(--border-color-default);
  margin-left: var(--spacing-xl);
  padding-left: var(--spacing-xl);
}
.changelog .changelog-content-container .changelog-content-container__content {
  display: flex;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  max-height: 208px;
  overflow: hidden;
  position: relative;
}
.changelog .changelog-content-container .changelog-content-container__content .changelog-content-container__content__shadow {
  position: absolute;
  width: 100%;
  height: 100%;
}
.changelog .changelog-content-container .changelog-content-container__content .changelog-content-container__content__shadow:hover, .changelog .changelog-content-container .changelog-content-container__content .changelog-content-container__content__shadow.is-hovered {
  box-shadow: inset 0 -16px 16px -16px var(--content-color-tertiary);
}
.changelog .changelog-content-container .changelog-content-container__content:hover, .changelog .changelog-content-container .changelog-content-container__content.is-hovered {
  cursor: pointer;
}
.changelog .changelog-content-container .changelog-content-container__content:hover:before, .changelog .changelog-content-container .changelog-content-container__content.is-hovered:before {
  bottom: var(--spacing-s);
  width: 120px;
  height: 18px;
  content: "Click to expand";
  color: var(--content-color-constant);
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  transition: bottom cubic-bezier(0.22, 0.61, 0.36, 1) 2s;
  border-radius: 24px;
  margin: var(--spacing-zero) auto;
  right: var(--spacing-zero);
  left: var(--spacing-zero);
  text-align: center;
}
.changelog .changelog-restore {
  border-left: var(--border-width-l) var(--border-style-solid) var(--border-color-default);
  margin-left: var(--spacing-xl);
  padding-left: var(--spacing-xl);
  padding-top: var(--spacing-l);
  color: var(--base-color-brand);
}
.changelog .changelog-restore .changelog-restore__loading {
  margin-left: var(--spacing-s);
}
.changelog .changelog-restore__button {
  padding: var(--spacing-s) var(--spacing-zero);
}

.diff-text {
  white-space: pre;
  padding: var(--spacing-s) var(--spacing-zero);
  flex: 1;
  color: var(--content-color-primary);
}
.diff-text .diff-text__line {
  height: 20px;
  display: flex;
  align-items: center;
  font-family: monospace;
}
.diff-text .diff-text__line-added {
  background-color: var(--background-color-success);
}
.diff-text .diff-text__line-removed {
  background-color: var(--background-color-error);
}

.diff-view-modal-content__fullscreen {
  display: flex;
  height: 100%;
}

.schema-changelog__error {
  display: flex;
  height: 100%;
}

.schema-editor-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.schema-editor-container .schema-editor-container__header {
  display: flex;
  flex: 0 0 37px;
  flex-direction: row;
  border-style: var(--border-style-solid);
  border-width: 0px 0px var(--border-width-default) 0px;
  border-color: var(--border-color-default);
  padding-left: 10px;
}
.schema-editor-container .schema-editor-container__header .schema-editor-container__header-left {
  display: flex;
  flex: 1;
  align-items: center;
}
.schema-editor-container .schema-editor-container__header .schema-editor-container__header-left .schema-editor-container__heading {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  font-size: 13px;
  font-weight: var(--text-weight-medium);
  margin-left: 5px;
}
.schema-editor-container .schema-editor-container__header .schema-editor-container__header-left .schema-editor-container__button {
  height: var(--size-s);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero);
  min-width: 110px;
  color: var(--content-color-primary);
}
.schema-editor-container .schema-editor-container__header .schema-editor-container__header-left .schema-editor-container__button use {
  fill: var(--content-color-primary);
}
.schema-editor-container .schema-editor-container__header .schema-editor-container__header-left .schema-editor-container__button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.schema-editor-container .schema-editor-container__header .schema-editor-container__header-left .schema-editor-container__button svg {
  color: var(--content-color-primary);
}
.schema-editor-container .schema-editor-container__header .schema-editor-container__header-right {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.schema-editor-container .schema-editor-container__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
.schema-editor-container .schema-editor-container__content__glyph-warning {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/warning-thumbnail.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}
.schema-editor-container .schema-editor-container__content__glyph-error {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/error.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}
.schema-editor-container__active-format {
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}

.schema-editor-warning-modal-content {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  font-family: var(--text-family-default);
}

.unsaved-changes-warning-modal-content {
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
}

.unsaved-changes-warning-modal-footer {
  display: flex;
  flex-direction: row;
}

.schema-editor-warning-modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.schema-editor-options {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.schema-editor-options .schema-editor-options__button-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: var(--spacing-l);
}
.schema-editor-options__active-language {
  margin-right: var(--spacing-m);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.schema-editor-options .schema-editor-options__normal-btn {
  margin-right: var(--spacing-m);
  height: var(--size-s);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.schema-editor-options .schema-editor-options__normal-btn use {
  fill: var(--content-color-primary);
}
.schema-editor-options .schema-editor-options__normal-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.schema-editor-options .schema-editor-options__normal-btn svg {
  color: var(--content-color-primary);
}
.schema-editor-options .schema-editor-options__icon-button--is-active use {
  fill: var(--base-color-brand);
}
.schema-editor-options .schema-editor-options__icon-button--is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.schema-editor-options .schema-editor-options__icon-button--is-active svg {
  color: var(--base-color-brand);
}
.schema-editor-options .schema-editor-options__beautify-button.is-disabled {
  cursor: default;
  opacity: 0.5;
}
.schema-editor-options__save, .schema-editor-options__request-access, .schema-editor-options__cancel {
  margin-right: var(--spacing-m);
  min-width: 60px;
}

.schema-editor__wrapper {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
}
.schema-editor__wrapper.comment-available__light .view-lines {
  cursor: url("../assets/images/thumbnails/select-cursor-dark.svg") 4 8, text;
}
.schema-editor__wrapper.comment-available__light .view-lines .view-line span {
  cursor: url("../assets/images/thumbnails/comment-select-cursor-dark.svg") 4 8, text;
}
.schema-editor__wrapper.comment-available__dark .view-lines {
  cursor: url("../assets/images/thumbnails/select-cursor-light.svg") 4 8, text;
}
.schema-editor__wrapper.comment-available__dark .view-lines .view-line span {
  cursor: url("../assets/images/thumbnails/comment-select-cursor-light.svg") 4 8, text;
}

.define-category {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.define-category .define-category__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--background-color-primary);
  overflow-y: hidden;
}
.define-category .define-category__content .define-category__loading-state {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.define-category .define-category__input-file {
  display: none;
}

.api-definition_upgrade_info_wrapper {
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px auto;
}
.api-definition_upgrade_info_wrapper .api-definition_upgrade_illustration {
  display: flex;
  justify-content: center;
  padding: var(--spacing-s);
}
.api-definition_upgrade_info_wrapper .api-definition_upgrade_text {
  text-align: center;
  padding: var(--spacing-s);
}
.api-definition_upgrade_info_wrapper .api-definition_upgrade_text .api-definition_refresh_text {
  text-decoration: underline;
  cursor: pointer;
}
.api-definition_upgrade_info_wrapper .api-definition_upgrade_button {
  padding: var(--spacing-s);
  min-width: 160px;
}
.api-definition_upgrade_info_wrapper .api-definition_upgrade_link {
  text-align: center;
  display: flex;
  color: var(--content-color-primary);
  text-decoration: underline;
  padding: var(--spacing-s);
}
.api-definition_upgrade_info_wrapper .api-definition_upgrade_heading {
  color: var(--content-color-primary);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-bold);
}

.add-schema__content {
  display: flex;
  flex: 1;
}
.add-schema__content .add-schema__wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}
.add-schema__content .add-schema__wrapper .add-schema__heading {
  padding: 10px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: var(--content-color-primary);
}
.add-schema__content .add-schema__wrapper .add-schema__dropdown-wrapper {
  padding: 5px;
}
.add-schema__content .add-schema__wrapper .add-schema__dropdown-wrapper .add-schema__button {
  width: 150px;
}
.add-schema__content .add-schema__wrapper .add-schema__dropdown-wrapper .add-schema__button-text {
  padding-left: 15px;
  padding-right: 15px;
}
.add-schema__content .add-schema__wrapper .add-schema__description {
  padding: 10px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: var(--content-color-secondary);
}

.add-schema-file-drop {
  display: flex;
  flex: 1;
}

.add-schema__dropdown-menu {
  width: 240px;
}

.add-schema__dropdown-menu__banner {
  width: 240px;
  padding-bottom: var(--spacing-zero);
}

.definition-create-container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  color: var(--content-color-primary);
}
.definition-create-container-main .definition-create-container-content {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xxl) var(--spacing-xl);
  margin: var(--spacing-zero) auto;
  width: 50%;
}
.definition-create-container-main .definition-create-container-content h6 {
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-m);
}
.definition-create-container-main .definition-create-container-content .definition-create-add-file__file-uploader {
  padding-bottom: var(--spacing-xs);
}
.definition-create-container-main .definition-create-container-content .definition-create-type {
  margin-top: var(--spacing-l);
}
.definition-create-container-main .definition-create-container-content .definition-create-format {
  margin-top: var(--spacing-l);
}
.definition-create-container-main .definition-create-container-content .definition-create-container-footer {
  margin-top: var(--spacing-xxl);
  display: flex;
}
.definition-create-container-main .definition-create-container-content .definition-create-container-footer .definition-create-container-footer_create_button {
  margin-right: var(--spacing-l);
}

.create-def-from-scratch-buttons {
  padding-top: 34px !important;
}

.create-definition-cta-footer {
  width: 100%;
  align-self: flex-end;
  padding-top: var(--spacing-l) !important;
  padding-bottom: var(--spacing-s);
}

.import-definition-radio-button {
  margin-top: 1px;
}

.import-definition-radio-button-container {
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: var(--spacing-xs);
  margin-left: var(--spacing-s);
}

.definition-author-scratch-heading-pad {
  padding-top: var(--spacing-xxxl) !important;
}

.integration-status__normal-btn {
  font-weight: var(--text-weight-medium);
  height: var(--size-s);
  margin-left: var(--spacing-l);
}
.integration-status__normal-btn .loading-indicator-wrapper {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-s);
}
.integration-status__loading {
  min-width: 180px;
}
.integration-status__error {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
  min-width: 160px;
}
.integration-status__error i {
  margin-right: var(--spacing-s);
}
.integration-status__error i use {
  fill: var(--content-color-warning);
}
.integration-status__error i svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-warning);
}
.integration-status__error i svg {
  color: var(--content-color-warning);
}
.integration-status__error.is-disabled {
  opacity: 1;
}
.integration-status__error.is-disabled:focus, .integration-status__error.is-disabled:hover {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
}
.integration-status__api {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
  min-width: 120px;
}
.integration-status__api i {
  margin-right: var(--spacing-s);
}
.integration-status__api i use {
  fill: var(--content-color-warning);
}
.integration-status__api i svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-warning);
}
.integration-status__api i svg {
  color: var(--content-color-warning);
}
.integration-status__api:focus, .integration-status__api:hover {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
}
.integration-status__default {
  min-width: 160px;
}
.integration-status__default i {
  margin-right: var(--spacing-s);
}
.integration-status__success {
  background-color: var(--background-color-brand);
  color: var(--content-color-brand);
}
.integration-status__success i {
  margin-right: var(--spacing-s);
}
.integration-status__success i use {
  fill: var(--content-color-brand);
}
.integration-status__success i svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.integration-status__success i svg {
  color: var(--content-color-brand);
}
.integration-status__success:focus, .integration-status__success:hover {
  background-color: var(--background-color-brand);
  color: var(--content-color-brand);
}
.integration-status__success__content {
  max-width: 120px;
}
.integration-status__running {
  background-color: var(--background-color-brand);
  color: var(--content-color-brand);
}
.integration-status__running i {
  margin-right: var(--spacing-s);
}
.integration-status__running i use {
  fill: var(--content-color-brand);
}
.integration-status__running i svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.integration-status__running i svg {
  color: var(--content-color-brand);
}
.integration-status__running:focus, .integration-status__running:hover {
  background-color: var(--background-color-brand);
  color: var(--content-color-brand);
}
.integration-status__running__content {
  max-width: 120px;
}
.integration-status__running .pm-icon-sync {
  animation: rotate 1s infinite;
}
@keyframes rotate {
  100% {
    transform: rotate(-180deg);
  }
}
.integration-status__failure {
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.integration-status__failure i {
  margin-right: var(--spacing-s);
}
.integration-status__failure i use {
  fill: var(--content-color-error);
}
.integration-status__failure i svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.integration-status__failure i svg {
  color: var(--content-color-error);
}
.integration-status__failure:focus, .integration-status__failure:hover {
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.integration-status__failure__content {
  max-width: 120px;
}
.integration-status__synced {
  background-color: var(--background-color-success);
  color: var(--content-color-success);
}
.integration-status__synced i {
  margin-right: var(--spacing-s);
}
.integration-status__synced i use {
  fill: var(--content-color-success);
}
.integration-status__synced i svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.integration-status__synced i svg {
  color: var(--content-color-success);
}
.integration-status__synced:focus, .integration-status__synced:hover {
  background-color: var(--background-color-success);
  color: var(--content-color-success);
}
.integration-status__synced__content {
  max-width: 120px;
}

.tooltip .integration-status__success__tooltip__entity {
  font-weight: var(--text-weight-medium);
}

.integration-status-btn {
  font-size: var(--text-size-m);
}

.validation-drawer__header-link:hover {
  text-decoration: underline;
}
.validation-drawer__header {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-s);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background-color: var(--background-color-primary);
  height: 40px;
  box-sizing: border-box;
  color: var(--content-color-secondary);
}
.validation-drawer__header__icon {
  margin-right: var(--spacing-s);
}
.validation-drawer__header__content {
  display: inline-flex;
  align-items: center;
  flex: 1;
  cursor: pointer;
  padding: var(--spacing-xs) var(--spacing-l);
}
.validation-drawer__header__content__issue-info {
  flex: 1;
  display: flex;
  align-items: center;
}
.validation-drawer__header__content__issue-info__tab {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-s);
  margin-left: var(--spacing-m);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  color: var(--content-color-primary);
}
.validation-drawer__header__content__issue-info__tab:hover {
  background-color: var(--background-color-tertiary);
}
.validation-drawer__header__content__issue-info__tab--selected {
  background-color: var(--highlight-background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--highlight-background-color-primary);
}
.validation-drawer__header__content__issue-info__badge {
  pointer-events: none;
}
.validation-drawer__header__right-section {
  display: flex;
}
.validation-drawer__header__right-section__expand-button, .validation-drawer__header__right-section__collapse-button {
  display: flex;
  align-items: center;
}
.validation-drawer__header__validating, .validation-drawer__header__validated, .validation-drawer__header__empty, .validation-drawer__header__syntax-error {
  display: flex;
  align-items: center;
}
.validation-drawer__header__validating .loading-indicator-wrapper {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-s);
  transform: scale(0.6);
}
.validation-drawer__header__validated .pm-icon {
  margin-right: var(--spacing-s);
}
.validation-drawer__header__validated use {
  fill: var(--content-color-success);
}
.validation-drawer__header__validated svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.validation-drawer__header__validated svg {
  color: var(--content-color-success);
}
.validation-drawer__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.validation-drawer__content__stale_warning {
  height: 40px;
}
.validation-drawer__content__stale_warning_btn {
  height: var(--size-s);
}
.validation-drawer__content_text {
  padding: var(--spacing-m);
}
.validation-drawer__content__empty, .validation-drawer__content__valid, .validation-drawer__content__loading, .validation-drawer__content__syntax-error {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.validation-drawer__content__run_button {
  color: var(--content-color-constant);
  height: var(--size-s);
  padding: 0 var(--spacing-m);
}
.validation-drawer__content__loading-text {
  padding-top: var(--spacing-m);
  color: var(--content-color-secondary);
}
.validation-drawer__content__empty__illustration {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/empty-schema.svg");
}
.validation-drawer__content__syntax-error__illustration {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/alert.svg");
}
.validation-drawer__content__header {
  display: flex;
  flex: 1;
  padding: var(--spacing-s) var(--spacing-l);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  align-items: center;
  color: var(--content-color-secondary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  max-height: var(--size-m);
  box-sizing: border-box;
  user-select: none;
}
.validation-drawer__content__header__file {
  width: 120px;
}
.validation-drawer__content__header__line {
  width: 82px;
}
.validation-drawer__content__header__type {
  width: 116px;
}
.validation-drawer__content__header__description {
  flex: 1;
}
.validation-drawer__content__header__cell {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.validation-drawer__content__items {
  overflow-y: auto;
  user-select: none;
  height: 100%;
}
.validation-drawer__content__item {
  display: flex;
  flex: 1;
  padding: var(--spacing-s) var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  align-items: center;
  color: var(--content-color-primary);
  box-shadow: inset 0px -1px 0px var(--border-color-default);
  cursor: pointer;
  max-height: var(--size-m);
  box-sizing: border-box;
}
.validation-drawer__content__item:hover {
  background-color: var(--background-color-secondary);
}
.validation-drawer__content__item:hover .validation-drawer__content__item__description__learn-more {
  display: flex;
}
.validation-drawer__content__item__file {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: var(--spacing-s);
  box-sizing: border-box;
}
.validation-drawer__content__item__line {
  width: 82px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: var(--spacing-s);
  box-sizing: border-box;
}
.validation-drawer__content__item__type {
  width: 116px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: var(--spacing-s);
  box-sizing: border-box;
}
.validation-drawer__content__item__description {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.validation-drawer__content__warning-button {
  color: var(--content-color-link);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  padding: var(--spacing-s) var(--spacing-l);
  cursor: pointer;
}

.schema-changelog-container__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
}
.schema-changelog-container__content .entity-empty__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.schema-changelog-container__content .changelog_empty, .schema-changelog-container__content .changelog_error, .schema-changelog-container__content .no_schema_found {
  border: none;
  min-height: 400px;
}
.schema-changelog-container__content .changelog_empty .entity-empty__title, .schema-changelog-container__content .changelog_error .entity-empty__title, .schema-changelog-container__content .no_schema_found .entity-empty__title {
  text-align: center;
  margin-top: var(--spacing-l);
}
.schema-changelog-container__content .entity-empty .entity-empty__illustration-wrapper--changelog .changelog-empty__illustration {
  display: block;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/activity-feed.svg");
}
.schema-changelog-container__content .changelog_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.schema-changelog-container__refresh-button__loading {
  cursor: default;
}
.schema-changelog-container__refresh-button__loading svg {
  animation: spin 1s linear infinite;
}

.changelog-offline__wrapper {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.changelog-offline {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 200px;
}
.changelog-offline__content {
  text-align: center;
  line-height: var(--line-height-m);
  margin-top: var(--spacing-l);
}
.changelog-offline__content__header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
}
.changelog-offline__content__sub-header {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.schema-navigation-drawer__content {
  display: flex;
  flex: 1;
  min-height: 0px;
  min-width: 0px;
}
.schema-navigation-drawer__content__loading {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.schema-navigation-drawer__content__error {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  padding-bottom: var(--spacing-m);
  padding-right: var(--spacing-s);
  overflow-y: overlay;
  overflow-x: hidden;
}
.schema-navigation-drawer__content__error .unable-to-load-illustration {
  min-width: 140px;
  min-height: 120px;
  margin-bottom: var(--spacing-m);
  margin-right: var(--spacing-xxl);
  margin-left: var(--spacing-xxl);
}
.schema-navigation-drawer__content__error__title, .schema-navigation-drawer__content__error__subtitle {
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  text-align: center;
  color: var(--content-color-primary);
}
.schema-navigation-drawer__content__error__title__secondary, .schema-navigation-drawer__content__error__subtitle__secondary {
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  margin-bottom: var(--spacing-xl);
  text-align: center;
}
.schema-navigation-drawer__content__error__title {
  margin-bottom: var(--spacing-s);
}
.schema-navigation-drawer__content__error__try-again {
  font-size: var(--text-size-s);
}
.schema-navigation-drawer__content__vertical-header {
  min-width: 48px;
  padding: var(--spacing-zero);
  display: inline;
}
.schema-navigation-drawer__content__vertical-header i {
  margin-top: 18px;
  margin-left: var(--spacing-l);
  cursor: pointer;
}
.schema-navigation-drawer__content__container {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-bottom: var(--spacing-m);
  padding-top: var(--spacing-m);
}
.schema-navigation-drawer__content__container:hover {
  overflow-y: auto;
}
.schema-navigation-drawer__content__container:hover .schema-navigation-drawer__content__container__section__node__padding {
  opacity: 1;
}
.schema-navigation-drawer__content__container__syntax-error-banner {
  color: var(--content-color-primary);
  background: var(--background-color-secondary);
  min-height: 86px;
  max-width: 350px;
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: var(--spacing-zero);
  position: sticky;
  padding-right: var(--spacing-m);
  padding-left: var(--spacing-xs);
  margin-top: var(--spacing-m);
  margin-right: var(--spacing-xs);
}
.schema-navigation-drawer__content__container__syntax-error-banner__content {
  display: flex;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}
.schema-navigation-drawer__content__container__syntax-error-banner__content__icon {
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
}
.schema-navigation-drawer__content__container__section .outline-empty-state-node {
  cursor: default;
}
.schema-navigation-drawer__content__container__section .outline-empty-state-node:hover {
  background: unset;
}
.schema-navigation-drawer__content__container__section .outline-empty-state-node__label {
  margin-left: 20px;
  color: var(--content-color-secondary);
}
.schema-navigation-drawer__content__container__section__node {
  display: flex;
  padding-left: var(--spacing-s);
  cursor: pointer;
}
.schema-navigation-drawer__content__container__section__node:hover {
  background: var(--highlight-background-color-secondary);
}
.schema-navigation-drawer__content__container__section__node__padding {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-left: var(--spacing-m);
  margin-left: var(--spacing-s);
  opacity: 0;
}
.schema-navigation-drawer__content__container__section__node__label {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  overflow: hidden;
}
.schema-navigation-drawer__content__container__section__node__label__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.schema-navigation-drawer__content__container__section__node__label__top {
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  overflow: hidden;
}
.schema-navigation-drawer__content__container__section__node__label__button {
  display: inline;
  padding: var(--spacing-zero);
}
.schema-navigation-drawer__content__container__section__node__label__button__icon {
  margin-right: var(--spacing-xs);
  vertical-align: text-top;
}
.schema-navigation-drawer__content__container__section__node__label__button__icon :hover {
  background: var(--highlight-background-color-tertiary);
}
.schema-navigation-drawer__content__container__section__node__label__request-icon {
  top: 10%;
  float: right;
  position: relative;
}
.schema-navigation-drawer__content__container__section__node__label__buffer {
  margin-right: 20px;
}

.schema-editor-custom-line-decoration {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: var(--background-color-info);
}

.schema-import-confirmation-modal__content {
  display: flex;
}
.schema-import-confirmation-modal__content__icon {
  margin-top: 2px;
}
.schema-import-confirmation-modal__content__body {
  display: flex;
  margin-left: var(--spacing-s);
}
.schema-import-confirmation-modal__footer__submit {
  margin-left: var(--spacing-s);
}

.rfa-banner__content {
  background: var(--background-color-info);
  margin-top: var(--spacing-m);
}
.rfa-banner__content__text {
  padding: var(--spacing-m) var(--spacing-l) var(--spacing-zero) var(--spacing-l);
}
.rfa-banner__content__button {
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-m) var(--spacing-l);
}

.schema-request-access-tooltip .tooltip-wrapper {
  max-width: 240px;
  padding: var(--spacing-m);
  margin-left: var(--spacing-l);
}
.schema-request-access-tooltip .tooltip-wrapper .btn {
  margin-top: var(--spacing-m);
}

.schema-editor-view {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  min-height: 0;
}
.schema-editor-view .schema-editor-view__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--background-color-primary);
  overflow-y: hidden;
}

.new-schema-editor__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.new-schema-editor-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.new-schema-editor-container .new-schema-editor-container__header {
  display: flex;
  flex: 0 0 37px;
  flex-direction: row;
  border-style: var(--border-style-solid);
  border-width: 0px 0px var(--border-width-default) 0px;
  border-color: var(--border-color-default);
  padding-left: 10px;
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__header-left {
  display: flex;
  flex: 1;
  align-items: center;
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__header-left .update-schema-type-link {
  font-size: var(--text-size-m);
  margin-right: var(--spacing-zero);
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__header-left .update-schema-type-icon {
  color: var(--content-color-link);
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__button {
  height: var(--size-s);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero);
  min-width: 110px;
  color: var(--content-color-primary);
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__button use {
  fill: var(--content-color-primary);
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__button svg {
  color: var(--content-color-primary);
}
.new-schema-editor-container .new-schema-editor-container__header .new-schema-editor-container__header-right {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.new-schema-editor-container .new-schema-editor-container__content {
  display: flex;
  flex: 1 1 0;
  min-height: 0;
  overflow: hidden;
}
.new-schema-editor-container__active-format {
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}

.file_editor__glyph-warning {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/warning-thumbnail.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-error {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/error.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_error {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/security-error.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_warn {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/security-warn.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_info {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/security-info.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.file_editor__glyph-security_hint {
  display: block;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/security-hint.svg");
  max-width: 12px;
  max-height: 12px;
  margin: 3px;
}

.new-schema-editor-options {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.new-schema-editor-options__active-language {
  margin-right: var(--spacing-m);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.new-schema-editor-options .new-schema-editor-options__normal-btn {
  margin-right: var(--spacing-m);
  height: var(--size-s);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.new-schema-editor-options .new-schema-editor-options__normal-btn use {
  fill: var(--content-color-primary);
}
.new-schema-editor-options .new-schema-editor-options__normal-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.new-schema-editor-options .new-schema-editor-options__normal-btn svg {
  color: var(--content-color-primary);
}
.new-schema-editor-options .new-schema-editor-options__icon-button--is-active use {
  fill: var(--base-color-brand);
}
.new-schema-editor-options .new-schema-editor-options__icon-button--is-active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.new-schema-editor-options .new-schema-editor-options__icon-button--is-active svg {
  color: var(--base-color-brand);
}
.new-schema-editor-options .new-schema-editor-options__beautify-button.is-disabled {
  cursor: default;
  opacity: 0.5;
}

.codegen__modal-dropdown {
  margin-left: var(--spacing-s);
}
.codegen__modal-dropdown.dropdown {
  width: 264px;
}
.codegen__modal-dropdown .btn {
  display: flex;
  width: 100%;
}
.codegen__modal-dropdown .btn .codegen__selected-label {
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.codegen__modal-dropdown .codegen__dropdown-menu {
  max-height: 190px;
  overflow-y: auto;
  width: 264px;
}
.codegen__modal-dropdown .codegen__dropdown-menu-item-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.codegen__generate-text {
  margin-top: var(--spacing-s);
  padding: var(--spacing-zero) var(--spacing-s);
  margin-bottom: var(--spacing-s);
  font-size: var(--spacing-m);
  font-weight: var(--text-weight-bold);
  color: var(--content-color-secondary);
}

.codegen__button-container {
  padding: var(--spacing-m) var(--spacing-zero);
}

.codegen__generate-button {
  margin-left: var(--spacing-s);
  height: var(--size-m);
}

.codegen__banner {
  margin-left: var(--spacing-s);
}
.codegen__banner .codegen__error-link {
  cursor: pointer;
  text-decoration: underline;
}

.codegen__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.codegen__icon {
  vertical-align: middle;
}

.codegen__container {
  margin-right: var(--spacing-m);
}

.codegen__beta-label {
  width: 50px;
  padding: 2px 6px;
  border-radius: 12px;
  background-color: var(--background-color-info);
  color: var(--content-color-info);
  margin-left: var(--spacing-xs);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
}

.codegen__unsupported-label {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
  margin-left: var(--spacing-s);
}

.codegen__description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-family: var(--text-family-default);
  padding: var(--spacing-s);
  line-height: var(--line-height-m);
}
.codegen__description .codegen__learn-more-link {
  text-decoration: underline;
  cursor: pointer;
}

.codegen__disabled {
  opacity: 0.4;
}

.codegen__options-container {
  padding: var(--spacing-m) var(--spacing-zero) var(--spacing-zero);
  font-size: var(--text-size-m);
  display: flex;
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
}
.codegen__options-container .codegen__options-text {
  white-space: pre;
  margin-left: var(--spacing-s);
}
.codegen__options-container .codegen__learn-more-link {
  text-decoration: underline;
  cursor: pointer;
}

.git-linked-codegen-container {
  padding-top: 70px;
}

.git-linked-codegen-error-text {
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}

.changelog {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
}

.api-definition-item {
  width: 100%;
}
.api-definition-item__hover-above {
  position: relative;
  box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
}
.api-definition-item__hover-above .api-definition-item__folder-hover {
  background-color: transparent;
}
.api-definition-item__left-section {
  display: flex;
  flex-direction: row;
  margin-right: var(--spacing-s);
  height: 100%;
  align-items: center;
}
.api-definition-item__left-section__indent {
  width: 11px;
  height: 100%;
  border-right: thin solid transparent;
}
.api-definition-item__toggle-button {
  margin-left: var(--spacing-xs);
  height: var(--size-xs);
  width: var(--size-xs);
  background-color: unset;
}
.api-definition-item__toggle-button use {
  fill: var(--content-color-primary);
}
.api-definition-item__toggle-button svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.api-definition-item__toggle-button svg {
  color: var(--content-color-primary);
}
.api-definition-item__toggle-button:hover, .api-definition-item__toggle-button.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.api-definition-item__toggle-button:hover use, .api-definition-item__toggle-button.is-hovered use {
  fill: var(--content-color-primary);
}
.api-definition-item__toggle-button:hover svg:not([class*=PartnerIcon]) > path[fill], .api-definition-item__toggle-button.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.api-definition-item__toggle-button:hover svg, .api-definition-item__toggle-button.is-hovered svg {
  color: var(--content-color-primary);
}
.api-definition-item__create-definition {
  padding-left: var(--spacing-xs);
}
.api-definition-item__create-definition-button {
  cursor: "pointer";
}
.api-definition-item__new {
  display: flex;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  color: var(--content-color-primary);
  padding: var(--spacing-zero) var(--spacing-s);
}
.api-definition-item__new__content {
  display: flex;
  flex: 1;
  min-width: 0;
  padding: var(--spacing-xs) var(--spacing-zero);
  align-items: center;
}
.api-definition-item__new__icon {
  display: flex;
  justify-content: flex-end;
  margin-right: var(--spacing-xs);
  align-items: center;
  height: var(--size-xs);
  min-width: var(--size-s);
}
.api-definition-item__new__name {
  margin-right: var(--spacing-xs);
  line-height: var(--line-height-m);
  display: flex;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.api-definition-item__new__name__box {
  border-color: var(--border-color-strong);
  border-radius: var(--border-radius-s);
  font-size: inherit;
  height: auto;
  padding: 2px var(--spacing-xs);
}
.api-definition-item__new__name .inline-input {
  height: var(--size-s);
}
.api-definition-item__new .api-definition-item__left-section__indent {
  border-color: transparent !important;
}
.api-definition-item .is-left-meta-visible {
  visibility: visible;
  display: flex;
  align-items: center;
}
.api-definition-item .is-left-meta-not-visible {
  visibility: hidden;
  opacity: 0;
}

.definition-item-focussed {
  box-shadow: 0px 0px 0px 2px var(--blue-30) inset;
}

.api-definition-item-base {
  height: 28px;
  padding: var(--spacing-zero) var(--spacing-s);
}

.api-definition-item .sidebar-list-item__icon-container {
  width: 24px;
  justify-content: flex-end;
  margin-right: var(--spacing-xs);
}

.api-definition-sidebar {
  margin-right: 1px;
  display: flex;
  position: relative;
}
.api-definition-sidebar__root {
  background-color: var(--background-color-info);
  padding: var(--spacing-xs) var(--spacing-s);
  display: flex;
  align-items: center;
  border-radius: 16px;
}
.api-definition-sidebar__root-title {
  color: var(--content-color-info);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
}
.api-definition-sidebar-empty:last-child .is-left-meta-not-visible {
  width: 0px;
}
.api-definition-sidebar__tour-trigger {
  height: 20px;
  width: 100%;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.api-definition-feature-branch .api-definition-file-status__modify {
  color: var(--yellow-40);
}
.api-definition-feature-branch .api-definition-file-status__modify path {
  fill: var(--yellow-40);
}
.api-definition-feature-branch .api-definition-file-status__new {
  color: var(--green-40);
}
.api-definition-feature-branch .api-definition-file-status__new path {
  fill: var(--green-40);
}

.api-definition-loader {
  display: flex;
  height: 28px;
  padding: var(--spacing-zero) var(--spacing-s);
}
.api-definition-loader__loading {
  animation: fade 1s ease-in-out infinite;
  display: flex;
  align-items: center;
  padding: var(--spacing-s) 0;
  margin-left: var(--spacing-xl);
}
.api-definition-loader__icon {
  height: 8px;
  width: 8px;
  background-color: var(--highlight-background-color-secondary);
  margin-left: var(--spacing-s);
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-m);
}
.api-definition-loader__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  height: 8px;
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-s);
}

.api-definition-migration__progress-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-l);
}
.api-definition-migration__progress-container {
  position: relative;
  width: 250px;
  border-radius: 4px;
  height: 8px;
  border: 1px solid var(--content-color-tertiary);
}
.api-definition-migration__progress {
  position: absolute;
  background-color: var(--content-color-tertiary);
  border-radius: 4px;
  height: 8px;
}
.api-definition-migration__content-title {
  margin-bottom: var(--spacing-l);
}
.api-definition-migration__content {
  border-radius: var(--border-radius-default);
  border: 1px;
  padding: var(--spacing-l);
}
.api-definition-migration__content-header {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-auto-flow: column;
}
.api-definition-migration__content-header__text {
  font-weight: var(--text-weight-medium);
  background-color: var(--background-color-primary);
  padding-right: var(--spacing-m);
}
.api-definition-migration__content-header__text-line {
  height: 1px;
  background-color: var(--border-color-strong);
  margin-top: var(--spacing-xs);
}
.api-definition-migration__content-point {
  position: relative;
  padding-left: var(--spacing-l);
  margin-top: 8px;
}
.api-definition-migration__content-point:before {
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  left: 0px;
  top: 8px;
  border: 1px;
  border-radius: 50%;
  background-color: var(--content-color-primary);
}
.api-definition-migration__checkload {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.api-definition-not-available {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-m);
}

.api-definition-delete-modal-content {
  line-height: var(--line-height-m);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.import-multifile-warning-banner {
  margin-top: var(--spacing-m);
}

.import-container-loading {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.import-hover-link {
  color: var(--content-color-link);
  display: flex;
}

.import-container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: var(--spacing-l);
  color: var(--content-color-primary);
}
.import-container-main .import-container-content {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.import-container-main .import-container-content h2 {
  font-size: var(--text-size-xxl);
  margin-bottom: var(--spacing-xl);
}
.import-container-main .import-container-content .import-container-entity {
  display: flex;
  flex-direction: column;
}
.import-container-main .import-container-content .import-container-entity .import-entity-header {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-m);
}
.import-container-main .import-container-content .import-container-entity .import-entity-header .import-entity-title {
  flex-grow: 2;
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-bold);
  display: flex;
}
.import-container-main .import-container-content .import-container-entity .import-entity-header .import-entity-title .import-entity-title-text {
  margin-left: var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-header .import-entity-summary {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  padding-top: var(--spacing-s);
  padding-left: var(--spacing-s);
  padding-bottom: var(--spacing-xs);
}
.import-container-main .import-container-content .import-container-entity .import-entity-header .import-add-entity {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  display: flex;
  cursor: pointer;
}
.import-container-main .import-container-content .import-container-entity .import-definition-entity-header {
  display: flex;
  flex-direction: column;
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding-bottom: var(--spacing-m);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table {
  display: flex;
  flex-direction: column;
  font-size: var(--text-size-m);
  margin-right: 2px;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-header {
  display: flex;
  padding: var(--spacing-s) 0;
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  font-weight: var(--text-weight-bold);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-header .import-entity-table-header-name {
  color: var(--content-color-secondary);
  flex-grow: 2;
  margin-left: var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-header .import-entity-table-header-type {
  width: 15%;
  color: var(--content-color-secondary);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container {
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-generate-collection-container {
  margin-left: var(--spacing-xl);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-generate-collection-tooltip {
  margin-left: var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-generate-collection-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-generate-collection-checkbox .input-checkbox.is-selected {
  background-color: var(--base-color-brand);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-generate-collection-checkbox .import-generate-collection-checkbox-label {
  margin-left: 10px;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-select-api-element-dropdown-header {
  margin-left: 28px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-select-api-element-dropdown-header.is-disabled {
  color: var(--content-color-tertiary);
  opacity: 0.6;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-select-api-element-dropdown {
  margin-left: 28px;
  margin-bottom: 10px;
  width: 272px;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-file-table-container {
  padding: 0;
  margin-bottom: var(--spacing-s);
  margin-left: var(--spacing-xl);
  width: 100%;
  padding-right: 30px;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-file-table-margin {
  border-left-color: var(--border-color-default);
  border-left-style: solid;
  border-left-width: var(--border-width-default);
  height: 5px;
  margin-left: var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-file-table-margin-max {
  border-left-color: var(--border-color-default);
  border-left-style: solid;
  border-left-width: var(--border-width-default);
  height: 5px;
  margin-left: 30px;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-table-content {
  display: flex;
  padding: var(--spacing-m) 0;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-table-content .bold {
  font-weight: var(--text-weight-bold);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-table-content .import-entity-table-content-type {
  width: 15%;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-table-content .import-entity-table-content-file-list {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  margin-left: var(--spacing-s);
  width: 70%;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-entity-table-content-container .import-entity-table-content-file-list-toggle {
  cursor: pointer;
  display: flex;
  margin: -3px var(--spacing-xl) var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container.generation-disabled {
  margin-top: var(--spacing-l);
  margin-left: 0;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-header {
  display: flex;
  align-items: center;
  margin: var(--spacing-l) var(--spacing-zero);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  cursor: pointer;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-header .import-options-dropdown-icon {
  margin-left: calc(var(--spacing-s) + var(--spacing-xs) * 0.5);
  margin-right: var(--spacing-s);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-header .toggle-button-icon {
  margin-right: 6px;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-header.is-disabled {
  color: var(--content-color-tertiary);
  opacity: 0.6;
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-header.is-disabled .toggle-button-icon use {
  fill: var(--content-color-tertiary);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-header.is-disabled .toggle-button-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-header.is-disabled .toggle-button-icon svg {
  color: var(--content-color-tertiary);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-content-wrapper {
  margin-left: var(--spacing-xs);
  margin-top: var(--spacing-l);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-content-wrapper .import-options-content {
  margin-bottom: var(--spacing-xxl);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-content-wrapper .import-options-content .import-options-content-header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
}
.import-container-main .import-container-content .import-container-entity .import-entity-table .import-options-container .import-options-content-wrapper .import-options-content .options-wrapper {
  padding: 10px;
  border: 1px solid var(--border-color-default);
}
.import-container-main .import-container-content .import-container-footer {
  padding: var(--spacing-xl) 0;
  display: flex;
}
.import-container-main .import-container-content .import-container-footer .import-container-footer_import_button {
  margin-right: var(--spacing-l);
}
.import-container-main .import-container-progress {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: calc(10 * var(--spacing-xxxl));
}
.import-container-main .import-container-progress .import-container-progress-header h6 {
  font-size: var(--text-size-m);
  margin: var(--spacing-s) var(--spacing-zero);
  color: var(--content-color-primary);
}
.import-container-main .import-container-progress .import-container-progress-content {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xs) var(--spacing-zero);
}
.import-container-main .import-container-progress .import-container-progress-content .import-container-progress-content-item {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-s) var(--spacing-zero);
}
.import-container-main .import-container-progress .import-container-progress-content .import-container-progress-content-item .import-container-progress-content-item-icon {
  padding: calc(var(--spacing-xs) - var(--spacing-xs) / 4) var(--spacing-xs);
}
.import-container-main .import-container-progress .import-container-progress-content .import-container-progress-content-item .import-container-progress-content-item-data {
  padding-left: var(--spacing-s);
}
.import-container-main .import-container-progress .import-container-progress-content .import-container-progress-content-summary {
  display: flex;
  flex-direction: row;
  padding-top: var(--spacing-m);
}
.import-container-main .import-container-progress .import-container-progress-content .import-container-progress-content-summary .import-container-progress-content-summary-spinner {
  padding-right: var(--spacing-m);
}
.import-container-main .import-container-progress .import-container-progress-footer {
  padding-top: var(--spacing-l);
}
.import-container-main .import-container-progress .import-container-progress-footer-banner {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  padding-top: var(--spacing-l);
}

.import-definition-subfile-margin {
  margin-left: var(--spacing-xxl);
}

.import-table-cell_padding {
  width: var(--spacing-m);
  height: 28px;
  border-left-color: var(--border-color-default);
  border-left-style: solid;
  border-left-width: var(--border-width-default);
  margin-left: 9px;
}

.import-table-cell_padding_no_nesting {
  min-height: 28px;
}

.import-table-cell_root_candidate_row_background {
  background-color: var(--background-color-tertiary);
  width: 100%;
}

.import-table-cell_container {
  display: flex;
  align-items: center;
}
.import-table-cell_container .import-table-cell_row {
  display: flex;
  width: 100%;
  align-items: center;
}
.import-table-cell_container .import-table-cell_header_row {
  display: flex;
  width: 100%;
  font-weight: var(--text-weight-bold);
  height: var(--size-s);
  padding: var(--spacing-s) 0;
  align-items: center;
  margin-left: var(--spacing-s);
  color: var(--content-color-secondary);
}
.import-table-cell_container .import-table-cell_root_candidate_row {
  cursor: pointer;
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
  padding-right: 31px;
}
.import-table-cell_container .import-table-cell_left {
  display: flex;
  flex-grow: 1;
}
.import-table-cell_container .import-table-cell_left .import-table-cell_name_icon {
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-s);
}
.import-table-cell_container .import-table-cell_left .import-table-cell_name {
  display: flex;
}
.import-table-cell_container .import-table-cell_left .import-table-cell_name .import-table-cell_name_text {
  display: flex;
}
.import-table-cell_container .import-table-cell_left .import-table-cell_name .import-table-cell_name_text .import-table-cell_name_rootFile_badge {
  display: flex;
  margin-left: var(--spacing-s);
  align-items: center;
}
.import-table-cell_container .import-table-cell_left .import-table-cell_name .import-table-cell_name_text .import-table-cell_name_rootFile_badge .rootFile_badge {
  text-transform: none;
}
.import-table-cell_container .import-table-cell_left .import-table-cell_cursor_pointer {
  cursor: pointer;
}

.import-filetable-padding {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
  width: 100%;
  padding-left: auto;
  padding-right: var(--spacing-xxxl);
}

.import-filetable-file-cell-padding {
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.import-table-cell_name_icon_style {
  padding-right: 5px;
  align-self: center;
}

.rootFile_badge_pad {
  padding-left: 10px;
  align-self: center;
}

.icon-minor-pad {
  cursor: pointer;
  padding-bottom: 2px;
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  margin-left: -6px;
  width: 22px;
}

.single-cell-major-pad {
  padding-left: 40px;
  padding-right: 20px;
}

.set-as-root-flex {
  cursor: pointer;
  margin-left: auto;
  padding-top: 2px;
  align-self: center;
  padding-left: auto;
}

.entity-filetable-container {
  padding-left: var(--spacing-m);
}

.import-generate-collection-container-collection {
  padding-left: 72px;
  padding-top: var(--spacing-xs);
}

.pad-free-space {
  padding-top: 10px;
}

.table-highlight:hover {
  background-color: var(--background-color-secondary);
}

.api-select-checkbox {
  padding-left: 10px;
}

.import-filetable-add-gap {
  gap: 2px;
}

.multifile-arrow-pad {
  cursor: pointer;
  margin-left: calc(var(--spacing-xs) * -1);
  margin-top: 1px;
}

.element-table-overflow-scroll {
  overflow-y: scroll;
  height: 80%;
  margin-top: var(--spacing-xxl);
}

.import-entity-gap {
  margin-top: var(--spacing-xxl);
}

.element-table-height-fill {
  height: 100%;
}

.import-container-entity-margin-override {
  margin: 0px !important;
}

.import-search-input {
  padding-bottom: var(--spacing-s);
}

.api-definition-name-text-details {
  margin-top: -2px;
  cursor: pointer;
}

.entity-row-margin-alignment {
  margin-top: -2px;
}

.import-options-container.generation-disabled {
  margin-top: var(--spacing-l);
  margin-left: 0;
}
.import-options-container .import-options-header {
  display: flex;
  align-items: center;
  margin: var(--spacing-s) var(--spacing-zero);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  cursor: pointer;
  margin-left: calc(var(--spacing-m) * -1);
}
.import-options-container .import-options-header .import-options-dropdown-icon {
  margin-left: calc(var(--spacing-s) + var(--spacing-xs) * 0.5);
  margin-right: var(--spacing-s);
}
.import-options-container .import-options-header .toggle-button-icon {
  margin-right: 6px;
}
.import-options-container .import-options-header.is-disabled {
  color: var(--content-color-tertiary);
  opacity: 0.6;
}
.import-options-container .import-options-header.is-disabled .toggle-button-icon use {
  fill: var(--content-color-tertiary);
}
.import-options-container .import-options-header.is-disabled .toggle-button-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.import-options-container .import-options-header.is-disabled .toggle-button-icon svg {
  color: var(--content-color-tertiary);
}
.import-options-container .import-options-content-wrapper {
  margin-left: calc(var(--spacing-xs) * -1);
  height: 350px;
}
.import-options-container .import-options-content-wrapper .import-options-content {
  margin-left: var(--spacing-s);
}
.import-options-container .import-options-content-wrapper .import-options-content .import-options-content-header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
}
.import-options-container .import-options-content-wrapper .import-options-content .options-wrapper {
  padding: 10px;
  border: 1px solid var(--border-color-default);
  border-radius: var(--spacing-xs);
  overflow-y: scroll;
  height: 312px;
}

.import-api-gateway-icon {
  width: var(--spacing-l);
  height: var(--spacing-l);
}

.import-gateway-icon-modifier {
  margin-top: 4px;
}

.aether-icon-modifier svg {
  color: inherit;
}
.aether-icon-modifier svg path[fill="#00AC69"] {
  fill: #00AC69;
}
.aether-icon-modifier:hover svg path[fill="#00AC69"] {
  fill: #00AC69;
}
.aether-icon-modifier svg path[fill="#1CE783"] {
  fill: #1CE783;
}
.aether-icon-modifier:hover svg path[fill="#1CE783"] {
  fill: #1CE783;
}
.aether-icon-modifier svg path[fill="#1D252C"] {
  fill: #1D252C;
}
.aether-icon-modifier:hover svg path[fill="#1D252C"] {
  fill: #1D252C;
}

.aether-icon-modifier-dark svg {
  color: inherit;
}
.aether-icon-modifier-dark svg path[fill="#00AC69"] {
  fill: #00AC69;
}
.aether-icon-modifier-dark:hover svg path[fill="#00AC69"] {
  fill: #00AC69;
}
.aether-icon-modifier-dark svg path[fill="#1CE783"] {
  fill: #1CE783;
}
.aether-icon-modifier-dark:hover svg path[fill="#1CE783"] {
  fill: #1CE783;
}
.aether-icon-modifier-dark svg path[fill="#1D252C"] {
  fill: #FFFFFF;
}
.aether-icon-modifier-dark:hover svg path[fill="#1D252C"] {
  fill: #FFFFFF;
}

.remove-bg-rect svg rect {
  fill: unset;
}

.tertiary-button-unset-hover-color:hover svg path[fill="#00AC69"] {
  fill: #00AC69;
}
.tertiary-button-unset-hover-color:hover svg path[fill="#1CE783"] {
  fill: #1CE783;
}
.tertiary-button-unset-hover-color:hover svg path[fill="#1D252C"] {
  fill: #1D252C;
}

.tertiary-button-unset-hover-color-dark:hover svg path[fill="#00AC69"] {
  fill: #00AC69;
}
.tertiary-button-unset-hover-color-dark:hover svg path[fill="#1CE783"] {
  fill: #1CE783;
}
.tertiary-button-unset-hover-color-dark:hover svg path[fill="#1D252C"] {
  fill: #FFFFFF;
}

.import-modal-single-select-override.dark-mode [class^=aether-dropdown__control]:hover {
  box-sizing: border-box;
  background-color: var(--highlight-background-color-primary);
  color: var(--content-color-primary);
}
.import-modal-single-select-override.dark-mode [class^=CustomSingleSelectComponents__EmptySelectedIconPlaceholder] {
  width: 0px;
}

.import-modal-single-select-override [class^=aether-dropdown__control]:hover {
  box-sizing: border-box;
  background-color: var(--highlight-background-color-primary);
  color: var(--content-color-primary);
}
.import-modal-single-select-override [class^=CustomSingleSelectComponents__EmptySelectedIconPlaceholder] {
  width: 0px;
}

.single-select-disable-click-wrapper {
  cursor: not-allowed;
}

.single-select-disable-click {
  pointer-events: none !important;
}

.import-modal-azure-icon-text {
  margin-top: 2px;
  margin-left: 1px;
}

.import-preview-padding-fix {
  margin-left: -2px;
  cursor: pointer;
}

.import-modal-cross-button {
  margin-left: auto;
  margin-top: var(--spacing-s);
  margin-right: var(--spacing-m);
  height: var(--spacing-xxl);
  margin-bottom: var(--spacing-xs);
}

.import-modal-cross-button-wrapper-default {
  margin-right: var(--spacing-xs);
}

.import-modal-cross-button-wrapper {
  height: 36px;
}

.import-modal-cross-button-wrapper-multifile {
  padding-top: var(--spacing-s);
  margin-right: var(--spacing-xs);
}

.import-modal-cross-button-margin-modifier {
  margin-right: var(--spacing-xs);
}

.import-modal-margin-remove {
  margin-bottom: 0px !important;
}

.import-modal-height-modifier {
  height: 490px !important;
}

.import-modal-max-height-modifier {
  max-height: 100vh !important;
}

.import-modal-tooltip-banner-flex-parent {
  padding-top: 4px !important;
}

.import-modal-tooltip-banner-dismiss-button {
  position: absolute;
  right: 16px;
  top: 14px;
}

.import-modal-tooltip-banner-flex {
  margin-top: calc(var(--spacing-xs) * -1);
  margin-bottom: calc(var(--spacing-xs) * -1);
}

.import-modal-tooltip-banner button {
  bottom: var(--spacing-xs);
}

.import-modal-content-margin-fix {
  margin-left: calc(var(--spacing-xs) * -1);
  margin-right: calc(var(--spacing-xs) * -1);
}

.import-modal-content-padding-fix {
  padding-bottom: var(--spacing-xs) !important;
  padding-right: var(--spacing-l) !important;
  padding-left: var(--spacing-l) !important;
}

.import-modal-horizontal-seperator {
  width: 580px;
  border-bottom: 1px;
  border-color: var(--content-color-secondary);
  margin-left: 22px;
}

.import-modal-integration-single-repo {
  display: none;
}

.import-modal-integration-icons {
  margin-left: auto;
  align-items: center;
  margin-right: auto;
  transform: scale(1.5);
  max-width: 14px;
}

.import-modal-shortcut-helper-text {
  height: var(--spacing-xxl);
  display: flex;
  align-items: center;
}

.import-modal-shortcut-helper-button {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.import-modal-api-collection-preview-line {
  position: relative;
  width: 1px;
  background-color: var(--border-color-strong);
  height: 205px; /* Set the height of the line */
  margin-left: 30px;
  margin-right: 15px !important;
  margin-top: var(--spacing-xl);
}

.import-modal-api-collection-preview-line::before {
  content: "1";
  color: var(--content-color-secondary);
  position: absolute;
  width: var(--spacing-l);
  height: var(--spacing-l);
  line-height: var(--spacing-l);
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--border-color-strong);
  font-size: 11px;
  left: -9px; /* Position the dots to the left of the line */
  top: 40px;
  background-color: transparent;
}

.import-modal-api-collection-preview-line::after {
  content: "2";
  color: var(--content-color-secondary);
  position: absolute;
  width: var(--spacing-l);
  height: var(--spacing-l);
  line-height: var(--spacing-l);
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--border-color-strong);
  background-color: transparent;
  font-size: 11px;
  left: -9px; /* Position the dots to the left of the line */
}

.import-modal-api-collection-preview-line::before {
  top: calc(var(--spacing-xl) * -1);
}

.import-modal-api-collection-preview-line::after {
  bottom: calc(var(--spacing-xl) * -1);
}

.import-modal-heading-on-api {
  padding-left: var(--spacing-xl);
  padding-top: var(--spacing-xl);
  padding-bottom: 18px;
}

.import-modal-multifile-table-heading {
  color: var(--content-color-primary);
  padding-left: var(--spacing-xl);
  margin-top: var(--spacing-xs) !important;
}

.import-modal-multifile-setting-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.import-modal-multifile-setting-heading {
  color: var(--content-color-primary);
  padding-left: 10px;
}

.import-modal-multifile-setting-back-button {
  cursor: pointer;
}

.import-modal-configuration-component-container {
  overflow: scroll;
  padding: 10px;
  border: 1px solid var(--border-color-default);
  border-radius: 4px;
  overflow-x: unset;
}

.import-modal-multifile-settings-button {
  cursor: pointer;
  display: flex;
  flex-grow: 2;
  align-items: center;
}

.import-modal-multifile-settings-text {
  color: var(--content-color-secondary);
  margin-left: var(--spacing-s);
}

.import-modal-import-settings {
  align-self: flex-end;
}

.import-modal-import-settings-back-icon {
  transform: scale(1.5);
  padding-left: var(--spacing-xl);
  padding-top: 6px;
  cursor: pointer;
}

.import-modal-api-collection-preview {
  margin-top: var(--spacing-s);
  height: 0px;
  overflow: hidden;
  transition: height 0.3s;
}

.import-modal-collection-name {
  margin-top: var(--spacing-s);
}

.import-modal-api-collection-preview.expanded {
  margin-top: var(--spacing-s);
  height: 260px;
  overflow: visible;
}

.import-modal-view-import-settings {
  display: flex;
  gap: var(--spacing-xs);
}

.import-modal-view-import-settings-button-modifier {
  cursor: pointer;
}

.apm-import-multi-select-container {
  transition: height 0.3s;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  height: 200px;
}

.apm-import-multi-select-container.expanded {
  height: 410px;
  overflow: visible;
  margin-bottom: 24px;
  max-width: 600px;
}

.import-modal-preview-spacing {
  padding-top: var(--spacing-xs);
}

.import-modal-preview-textarea {
  width: 535px;
}

.import-modal-loading-state-width-modifier {
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}

.import-modal-multifile-table-name-count {
  color: var(--content-color-secondary);
  padding-right: var(--spacing-l);
}

.import-modal-multifile-definition-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  background-color: var(--background-color-tertiary);
  line-height: var(--line-height-l);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-xs) var(--spacing-s);
  margin-right: var(--spacing-m);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
}

.import-modal-multifile-definition-checkbox-text {
  margin-left: var(--spacing-s);
}

.import-modal-multifile-table-name-type {
  color: var(--content-color-secondary);
}

.import-modal-multifile-create-definition-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.import-modal-footer-import-left-align {
  place-content: flex-start !important;
}

.import-modal-multifile-choose-options-helper-text {
  padding-top: var(--spacing-xs);
  display: flex;
}

.import-modal-multifile-choose-options-container {
  flex-grow: 1;
  padding: var(--spacing-m) var(--spacing-s);
}

.import-modal-multifile-choose-options-container-border {
  border-bottom: solid var(--border-width-default) var(--border-color-default);
}

.import-modal-multifile-table-api-option-container {
  display: inline-block;
  height: 22px;
}

.import-modal-multifile-table-api-option-text {
  margin-left: var(--spacing-s);
  padding-right: var(--spacing-xs);
}

.import-modal-multifile-table-api-option-cell {
  margin-left: var(--spacing-s);
}

.import-modal-multifile-table-container {
  max-height: 33vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.import-loading-screen_container {
  padding: var(--spacing-s);
  padding-left: var(--spacing-l);
}
.import-loading-screen_container .import-loading-screen_message {
  padding: var(--spacing-s);
}
.import-loading-screen_container .import-loading-screen_left-border {
  padding-left: var(--spacing-xl);
  border-left: var(--border-width-default) solid var(--highlight-background-color-primary);
  margin-left: var(--spacing-xs);
  margin-bottom: var(--spacing-l);
  color: var(--content-color-secondary);
}
.import-loading-screen_container .import-loading-screen_button-container {
  display: flex;
  place-content: flex-end;
  gap: var(--spacing-m);
  padding-top: var(--spacing-xl);
}

.import-api-usage-banner {
  padding-top: var(--spacing-xs);
  margin-left: 22px;
}

.import-api-usage-radiobutton-info-icon {
  margin-top: var(--spacing-xs);
}

.file-editor-header {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-m);
  padding: var(--spacing-s) var(--spacing-l);
  background-color: var(--background-color-primary);
  min-height: 32px;
}
.file-editor-header__comments {
  cursor: pointer;
}
.file-editor-header .file-editor-header__left-section {
  box-sizing: border-box;
  display: flex;
  width: calc(100% - 70px);
  align-items: center;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.file-editor-header .file-editor-header__left-section .file-name-title {
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
}
.file-editor-header .file-editor-header__left-section .file-name-title .breadcrumb__text {
  font-size: var(--text-size-l);
}
.file-editor-header .file-editor-header__left-section .file-name-title .edit-icon-rename {
  display: inline-flex;
  visibility: hidden;
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}
.file-editor-header .file-editor-header__left-section .file-name-title .edit-icon-rename:hover use, .file-editor-header .file-editor-header__left-section .file-name-title .edit-icon-rename.is-hovered use {
  fill: var(--content-color-primary);
}
.file-editor-header .file-editor-header__left-section .file-name-title .edit-icon-rename:hover svg:not([class*=PartnerIcon]) > path[fill], .file-editor-header .file-editor-header__left-section .file-name-title .edit-icon-rename.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.file-editor-header .file-editor-header__left-section .file-name-title .edit-icon-rename:hover svg, .file-editor-header .file-editor-header__left-section .file-name-title .edit-icon-rename.is-hovered svg {
  color: var(--content-color-primary);
}
.file-editor-header .file-editor-header__left-section .file-name-title:hover .edit-icon-rename, .file-editor-header .file-editor-header__left-section .file-name-title.is-hovered .edit-icon-rename {
  visibility: visible;
  cursor: pointer;
}
.file-editor-header .file-editor-header__left-section .file-name-title:hover .copy-link-icon, .file-editor-header .file-editor-header__left-section .file-name-title.is-hovered .copy-link-icon {
  visibility: visible;
  cursor: pointer;
}
.file-editor-header .file-editor-header__left-section .file-name-title .copy-link-icon {
  display: inline-flex;
  visibility: hidden;
  margin-right: var(--spacing-xs);
}
.file-editor-header .file-editor-header__left-section .file-name-title__root {
  margin-left: var(--spacing-xs);
  padding-right: 2px;
  display: flex;
  align-items: center;
}
.file-editor-header .file-editor-header__left-section .file-name-title__root-title {
  color: var(--content-color-info);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
}
.file-editor-header .file-editor-header__left-section .file-name-input {
  display: none;
}
.file-editor-header .file-editor-header__right-section {
  display: flex;
  width: 70px;
  align-items: center;
}
.file-editor-header .file-editor-header__right-section .file-editor-header__save-message {
  display: flex;
  margin-right: var(--spacing-l);
  align-items: center;
  width: max-content;
}
.file-editor-header .file-editor-header__right-section .button-wrapper {
  box-sizing: border-box;
  height: var(--size-m);
  display: flex;
  align-items: stretch;
  padding: var(--spacing-s);
  margin-right: 2px;
  cursor: pointer;
  user-select: none;
}
.file-editor-header .file-editor-header__right-section .button-wrapper .button-icon {
  margin-right: var(--spacing-xs);
}
.file-editor-header .file-editor-header__right-section .button-wrapper.file-report-button, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button {
  background: initial;
}
.file-editor-header .file-editor-header__right-section .button-wrapper.file-report-button.button-disabled:hover, .file-editor-header .file-editor-header__right-section .button-wrapper.file-report-button.button-disabled.is-hovered, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button.button-disabled:hover, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button.button-disabled.is-hovered, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button.button-disabled:hover, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button.button-disabled.is-hovered {
  background: initial;
}
.file-editor-header .file-editor-header__right-section .button-wrapper.file-report-button:hover, .file-editor-header .file-editor-header__right-section .button-wrapper.file-report-button.is-hovered, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button:hover, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button.is-hovered, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button:hover, .file-editor-header .file-editor-header__right-section .button-wrapper.file-share-button.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.file-editor-header .file-editor-header__right-section .button-wrapper .watch-label {
  margin-left: var(--spacing-s);
}
.file-editor-header .file-editor-header__right-section .button-wrapper .unwatch-icon use {
  fill: var(--content-color-secondary);
}
.file-editor-header .file-editor-header__right-section .button-wrapper .unwatch-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.file-editor-header .file-editor-header__right-section .button-wrapper .unwatch-icon svg {
  color: var(--content-color-secondary);
}
.file-editor-header .file-editor-header__right-section .button-wrapper.file-context-menu-button {
  padding: var(--spacing-zero);
  margin-right: var(--spacing-s);
}
.file-editor-header .file-editor-header__right-section .button-wrapper.file-context-menu-button .button-icon {
  height: var(--size-m);
  width: var(--size-m);
}
.file-editor-header .file-editor-header__right-section .button-wrapper.file-context-menu-button .button-icon.is-active {
  background-color: var(--highlight-background-color-tertiary);
}
.file-editor-header .file-editor-header__right-section .file-watch-button {
  margin-right: var(--spacing-s);
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper {
  display: flex;
  justify-content: center;
  height: var(--size-m);
  width: 70px;
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  height: 28px;
  width: var(--size-m);
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.right {
  left: 36px;
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-active-item {
  align-self: center;
  display: flex;
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  height: 100%;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher.plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__wrapper.disabled {
  opacity: 0.3;
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher .file-mode-switcher__icon use {
  fill: var(--content-color-tertiary);
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher .file-mode-switcher__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher .file-mode-switcher__icon svg {
  color: var(--content-color-tertiary);
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher .file-mode-switcher__icon.active use {
  fill: var(--base-color-brand);
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher .file-mode-switcher__icon.active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.file-editor-header .file-editor-header__right-section .file-mode-switcher .file-mode-switcher__icon.active svg {
  color: var(--base-color-brand);
}
.file-editor-header .file-editor-header__right-section .comments-ctx-toggler {
  padding: var(--spacing-zero) var(--spacing-s);
  font-size: var(--text-size-m);
  align-items: center;
  color: var(--content-color-brand);
  margin-right: var(--spacing-m);
}
.file-editor-header .file-editor-header__right-section .comments-ctx-toggler > .comment-wrapper {
  cursor: pointer;
}
.file-editor-header .file-editor-header__right-section .comments-ctx-toggler > .comment-wrapper.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.file-context-menu-dropdown .dropdown-menu {
  margin-top: var(--spacing-zero);
  margin-right: 3px;
  width: 200px;
}

.file-info-context-view-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.file-info-context-view-wrapper .right-context-bar__title {
  color: var(--content-color-primary);
}
.file-info-context-view-wrapper .file-info-context-view {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}
.file-info-context-view-wrapper .file-info-context-view__version-header {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
.file-info-context-view-wrapper .file-info-context-view__entity {
  margin-bottom: var(--spacing-l);
}
.file-info-context-view-wrapper .file-info-context-view__entity__label {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}
.file-info-context-view-wrapper .file-info-context-view__entity__content {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  display: flex;
  flex: 1;
  padding-right: var(--spacing-s);
  align-items: center;
  margin-top: var(--spacing-xs);
}
.file-info-context-view-wrapper .file-info-context-view__entity__content .btn {
  background-color: transparent;
}
.file-info-context-view-wrapper .file-info-context-view__entity__content .btn-icon {
  background-color: transparent;
  width: var(--size-xs);
  height: var(--size-xs);
}
.file-info-context-view-wrapper .file-info-context-view__entity__content .success-tick-icon {
  cursor: default;
}
.file-info-context-view-wrapper .file-info-context-view__entity__content .success-tick-icon use {
  fill: var(--content-color-success);
}
.file-info-context-view-wrapper .file-info-context-view__entity__content .success-tick-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.file-info-context-view-wrapper .file-info-context-view__entity__content .success-tick-icon svg {
  color: var(--content-color-success);
}
.file-info-context-view-wrapper .file-info-context-view__entity__content__id {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file-info-context-view-wrapper .file-info-context-view__entity__content__id--entity {
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  padding: 6px var(--spacing-s) 6px var(--spacing-s);
  margin-right: var(--spacing-s);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-offline {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-offline.sidebar {
  padding: var(--spacing-xxl) var(--spacing-l);
  margin-top: var(--spacing-xxl);
}
.file-offline.file-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.file-offline.context-bar {
  padding: var(--spacing-xl) var(--spacing-zero);
  margin-top: var(--spacing-xxxl);
}
.file-offline__blank-state {
  padding: var(--spacing-l);
}
.file-offline__content {
  text-align: center;
}
.file-offline__content__header {
  margin-bottom: var(--spacing-s);
}
.file-offline__content__sub-header {
  margin-bottom: var(--spacing-l);
}

.file-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.file-editor-container__network {
  overflow: visible;
}

.file-editor {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  min-height: 0;
}
.file-editor .file-tab-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
}
.file-editor .file-tab-content .file-tab-content-pane {
  overflow-y: hidden;
  overflow-x: auto;
}

.api-definition-overview {
  gap: var(--spacing-m);
  width: fit-content;
}
.api-definition-overview__separator {
  height: 3px;
  width: 3px;
  border-radius: 50%;
  border: 1px transparent;
  background-color: var(--content-color-secondary);
}
.api-definition-overview__item {
  min-width: max-content;
}
.api-definition-overview__update {
  min-width: 100%;
}

.api-definition-private {
  height: 100%;
  width: 100%;
}
.api-definition-private__home {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.api-definition-private__file {
  display: flex;
  height: 100%;
  width: 100%;
}
.api-definition-private__file-container {
  width: 100%;
  overflow: hidden;
}

.api-definition-private-schema-doc {
  width: 100%;
}

.api-definition-overview-icon {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}

.api-definition-home {
  height: 100%;
  overflow-y: auto;
  width: 100%;
}
.api-definition-home__title {
  padding: var(--spacing-s);
  border-bottom: 1px solid var(--border-color-default);
}
.api-definition-home__title__copy-link-icon {
  visibility: hidden;
}
.api-definition-home__title:hover .api-definition-home__title__copy-link-icon {
  visibility: visible;
}

.create-definition-block-container {
  padding-top: var(--spacing-xxl);
  margin-left: auto;
  margin-right: auto;
}

.import-definition-heading {
  padding-top: var(--spacing-xxl);
}

.create-definition-subheading {
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-xl);
}

.create-definition-block-image-mask {
  background-color: transparent;
}

.ws-outer-container {
  height: 100vh;
  overflow-y: scroll;
}

.ws-container {
  padding: var(--spacing-zero) var(--spacing-xl);
  margin: var(--spacing-zero) auto;
  max-width: 880px;
}
.ws-container__spam-report {
  padding-top: var(--spacing-xl);
  border-top: 1px solid var(--border-color-default);
}

.ws-settings-container {
  margin: auto;
  max-width: 440px;
}

.ws-content-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ws-content-left {
  margin-right: var(--spacing-l);
}

.ws-action-request {
  padding: var(--spacing-l) var(--spacing-zero);
  margin-bottom: var(--spacing-xl) var(--spacing-zero) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.ws-action-request__content {
  max-width: 600px;
}
.ws-action-request__content__heading {
  margin-bottom: var(--spacing-s);
}
.ws-action-request__content__heading a {
  text-decoration: none;
  color: var(--content-color-primary);
}
.ws-action-request__content__heading a:hover {
  color: var(--content-color-brand);
}
.ws-action-request__content__notes {
  padding: var(--spacing-s);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-secondary);
  margin-top: var(--spacing-s);
}
.ws-action-request__content__actions {
  margin-top: var(--spacing-m);
}

.ws-header {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  color: var(--content-color-primary);
  padding: var(--spacing-m) var(--spacing-zero);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ws-header__info {
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ws-header__info__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: var(--spacing-s);
  padding: var(--spacing-zero) var(--spacing-xs);
}
.ws-header__info__name__container {
  min-width: 0;
  width: 100%;
}
.ws-header__info__name--edit:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.ws-header__info__name__block {
  margin: var(--spacing-zero);
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  flex-grow: 1;
  min-width: 0;
}
.ws-header__info__name__block__fixed {
  display: flex;
}
.ws-header__info__name__block__fixed__text {
  margin-right: var(--spacing-s);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ws-header__info__name__block__fixed__copy-icon {
  visibility: hidden;
}
.ws-header__info__name__block__fixed:hover .ws-header__info__name__block__fixed__copy-icon {
  visibility: visible;
}
.ws-header__info__name-edit-mode {
  display: flex;
  align-items: center;
  cursor: text;
}
.ws-header__info__name-edit-mode .inline-input__wrapper {
  width: 100%;
}
.ws-header__info__name-edit-mode .inline-input__wrapper .input {
  line-height: calc(var(--line-height-xxxl) - 2px);
  padding: var(--spacing-zero) calc(var(--spacing-xs) - 1px);
}
.ws-header__info__name-edit-mode .input::placeholder {
  font-size: var(--text-size-xxl) !important;
}
.ws-header__info__name-copy-icon {
  visibility: hidden;
}
.ws-header__info__name-edit-mode:hover .ws-header__info__name-copy-icon {
  visibility: visible;
}
.ws-header__info__name:hover .ws-header__info__name-copy-icon {
  visibility: visible;
}
.ws-header__info__name-public-info {
  display: flex;
  align-items: flex-start;
  margin-top: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-warning);
  white-space: break-spaces;
}
.ws-header__info__name-public-info-icon {
  margin-right: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}
.ws-header__info__visibility__icon {
  margin-right: var(--spacing-xs);
  background: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  width: var(--size-s);
  height: var(--size-s);
}
.ws-header__info__visibility-block {
  margin-right: var(--spacing-s);
}
.ws-header-info-action-icons {
  display: flex;
  margin-left: var(--spacing-m);
  align-items: center;
}
.ws-header-info-action-icons-container__watch-btn button {
  color: var(--content-color-secondary);
  background-color: unset;
  min-width: unset;
}
.ws-header-info-action-icons-container__watch-btn button:hover {
  color: var(--content-color-primary);
}
.ws-header-info-action-icons-container__watch-btn button:first-of-type {
  border-right-color: var(--border-color-default);
}
.ws-header-info-action-icons-container__settings-btn {
  margin: var(--spacing-zero) var(--spacing-xs);
}
.ws-header-actions-delete {
  color: var(--content-color-error);
}
.ws-header-actions-delete:hover {
  color: unset;
}
.ws-header-block-icon use {
  fill: var(--content-color-secondary);
}
.ws-header-block-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.ws-header-block-icon svg {
  color: var(--content-color-secondary);
}
.ws-header-block-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
}
.ws-header-block-info__creator {
  margin-bottom: var(--spacing-l);
}
.ws-header-block-info__updated {
  margin-bottom: var(--spacing-l);
}
.ws-header-block-info__ws-id {
  border: none !important;
  box-shadow: none;
  background-color: var(--background-color-secondary);
}
.ws-header-block-info__ws-id input {
  color: transparent;
  text-shadow: 0 0 0 var(--content-color-primary);
}
.ws-header-block-info__ws-id:hover {
  border: none;
  background-color: var(--background-color-secondary);
}
.ws-header-block-info__copy {
  color: var(--content-color-secondary);
}
.ws-header-block-info__copy--copied:hover i svg {
  color: var(--base-color-success);
}
.ws-header-block-info__copy--copied i svg {
  color: var(--base-color-success);
}
.ws-header-dropdown .dropdown-menu {
  border-radius: var(--border-radius-default);
  background: var(--background-color-primary);
  box-shadow: var(--shadow-default);
  padding: var(--spacing-l);
}
.ws-header-dropdown-icon use {
  fill: var(--content-color-primary);
}
.ws-header-dropdown-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.ws-header-dropdown-icon svg {
  color: var(--content-color-primary);
}
.ws-header-actions-disable {
  opacity: 0.4;
  pointer-events: none;
}

.ws-meta {
  overflow-y: visible;
  overflow-x: hidden;
}
.ws-meta-summary {
  word-break: break-all;
}
.ws-meta-summary-text {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  word-break: break-word;
  padding: var(--spacing-xs);
  width: 100%;
}
.ws-meta-summary-text:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.ws-meta-summary-text-empty {
  color: var(--content-color-tertiary);
}
.ws-meta-summary-uneditable-empty:hover {
  cursor: text;
}
.ws-meta-summary-edit-mode {
  display: flex;
  font-size: var(--text-size-m);
  cursor: text;
}
.ws-meta-summary-edit-mode .inline-input__wrapper {
  width: 100%;
}
.ws-meta-summary-edit-mode-description {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-xs);
}
.ws-meta-summary-edit-mode-description-left {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  color: var(--content-color-warning);
}
.ws-meta-summary-edit-mode-word-count {
  margin-left: auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.ws-meta-summary-edit-icon {
  margin-left: var(--spacing-l);
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.ws-meta-summary-edit-icon use {
  fill: var(--content-color-secondary);
}
.ws-meta-summary-edit-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.ws-meta-summary-edit-icon svg {
  color: var(--content-color-secondary);
}
.ws-meta-summary-edit-mode:hover .ws-meta-summary-edit-icon {
  visibility: visible;
}
.ws-meta-description {
  font-size: var(--text-size-m);
  cursor: default;
  word-break: break-word;
}
.ws-meta-description-label {
  margin-bottom: var(--spacing-s);
}
.ws-meta-description-md {
  padding: var(--spacing-s);
  box-sizing: border-box;
  border: var(--border-width-default) var(--border-style-solid) transparent;
}
.ws-meta-description-md:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.ws-meta-description-md__uneditable {
  padding: var(--spacing-xs) var(--spacing-zero);
}
.ws-meta-description-md__uneditable__empty {
  color: var(--content-color-tertiary);
}
.ws-meta-description-md__uneditable:hover {
  cursor: text;
  background-color: unset;
  border-radius: unset;
}
.ws-meta-description-edit-mode {
  display: flex;
  cursor: text;
  width: 100%;
}
.ws-meta-description-edit-mode-text {
  width: 100%;
}
.ws-meta-description-edit-mode-text-placeholder {
  box-sizing: border-box;
  padding: var(--spacing-xs);
  width: 100%;
}
.ws-meta-description-edit-mode-text-placeholder:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.ws-meta-description-edit-mode-wrapper {
  width: 100%;
}
.ws-meta-description-edit-icon {
  margin-left: var(--spacing-l);
  height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.ws-meta-description-edit-icon use {
  fill: var(--content-color-secondary);
}
.ws-meta-description-edit-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.ws-meta-description-edit-icon svg {
  color: var(--content-color-secondary);
}
.ws-meta-description-edit-mode:hover .ws-meta-description-edit-icon {
  visibility: visible;
}

.ws-statistics-scratchpad {
  width: 50%;
}

.ws-statistics {
  padding-right: var(--spacing-l);
}
.ws-statistics-header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-l);
}
.ws-statistics-block {
  display: flex;
  justify-content: space-between;
}
.ws-statistics-value {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-primary);
  margin: var(--spacing-zero);
}
.ws-statistics-label {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  color: var(--content-color-tertiary);
  margin: var(--spacing-zero);
}
.ws-statistics-item {
  cursor: pointer;
}
.ws-statistics-item:hover .ws-statistics-label {
  color: var(--content-color-primary);
}

.ws-activity {
  width: 100%;
}
.ws-activity .requester-team-overview {
  margin: unset;
}
.ws-activity-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--spacing-s);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.ws-activity-header__refresh-btn {
  height: unset;
  padding: var(--spacing-s);
}
.ws-activity-header__refresh-btn i,
.ws-activity-header__refresh-btn svg {
  height: 12px;
  width: 12px;
}
.ws-activity .select-dropdown-container .select-dropdown-btn {
  display: flex;
  justify-content: space-between;
}
.ws-activity .workspace-activity-feed-filter-container {
  padding: var(--spacing-zero);
}
.ws-activity .workspace-activity-feed {
  padding: var(--spacing-zero);
}
.ws-activity .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-xl);
}
.ws-activity .btn-primary {
  min-width: 72px;
}
.ws-activity .workspace-activity-feed-refresh {
  display: flex;
  align-items: center;
}
.ws-activity .workspace-activity-item-heading {
  color: var(--content-color-secondary);
}
.ws-activity .workspace-activity-item-header .workspace-activity-item-head .workspace-activity-item-heading a {
  color: var(--content-color-primary);
  font-weight: 400;
  word-break: break-word;
}
.ws-activity .workspace-activity-item-header .workspace-activity-item-head .workspace-activity-item-heading a:hover {
  color: var(--content-color-link);
}

.ws-getstarted {
  font-size: var(--text-size-m);
}
.ws-getstarted .create-new-entity-section {
  max-width: max-content;
}
.ws-getstarted-header {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  color: var(--content-color-primary);
}

.ws-members {
  font-size: var(--text-size-m);
}
.ws-members-header {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  margin: var(--spacing-l) 0 var(--spacing-l) 0;
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ws-members-empty-text {
  color: var(--content-color-tertiary);
}
.ws-members-user-row {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-l);
  line-height: var(--line-height-m);
}
.ws-members-user-row-block {
  display: flex;
  align-items: center;
}
.ws-members-user-row a {
  text-decoration: none;
  color: var(--content-color-primary);
  cursor: pointer;
  max-width: 70%;
}
.ws-members-user-row a:hover {
  color: var(--content-color-link);
}
.ws-members-user-row-name {
  margin-left: var(--spacing-s);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ws-members-user-row-name-anon {
  color: var(--content-color-secondary);
}
.ws-members-user-row-role {
  margin-left: auto;
  text-transform: capitalize;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  margin-right: var(--spacing-s);
}
.ws-members-anon-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: var(--size-m);
  height: var(--size-m);
  background-color: var(--background-color-tertiary);
}
.ws-members-toggle-cta label {
  color: var(--content-color-secondary);
  user-select: none;
  cursor: pointer;
}

.ws-guest-intro {
  padding-bottom: var(--spacing-xl);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.ws-guest-intro__heading {
  margin-bottom: var(--spacing-s);
}

.ws-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ws-error-thumbnail {
  margin-bottom: var(--spacing-l);
}
.ws-error .error-card-container__icon {
  display: none;
}
.ws-error .error-card-container {
  justify-content: unset;
  height: unset;
}

.ws-backtop {
  display: flex;
  position: fixed;
  right: 5%;
  bottom: 5%;
  width: 100%;
}
.ws-backtop-btn {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 var(--spacing-l) 0 var(--spacing-s);
}
.ws-backtop-btn-icon {
  margin-right: var(--spacing-s);
}

.ws-publisher-info-header {
  margin-bottom: var(--spacing-s);
}
.ws-publisher-info-content {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  cursor: pointer;
}
.ws-publisher-info-content span {
  display: inline;
}
.ws-publisher-info-content-text {
  margin-left: var(--spacing-s);
  display: inline-block;
}
.ws-publisher-info-content-text .verified-icon {
  margin-left: var(--spacing-xs);
}
.ws-publisher-info-content-text:hover {
  text-decoration: underline;
  text-decoration-color: var(--content-color-primary);
}
.ws-publisher-info-img {
  width: var(--size-s);
  height: var(--size-s);
  object-fit: contain;
}

.ws-join-btn {
  font-size: var(--text-size-m);
  padding: var(--spacing-s) var(--spacing-l);
}

.agent-onboarding-modal {
  position: relative;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.agent-onboarding-modal-header {
  text-align: center;
  color: var(--content-color-primary);
  margin-left: var(--spacing-xxl);
  margin-right: var(--spacing-xxl);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.agent-onboarding-modal-header h1 {
  margin-top: var(--spacing-xl);
}
.agent-onboarding-modal-header-subtitle {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.agent-onboarding-modal-header p {
  margin: var(--spacing-l) 0;
}
.agent-onboarding-modal-close-button {
  cursor: pointer;
  position: absolute;
  right: 26px;
  top: 26px;
  width: 12px;
  height: 12px;
}
.agent-onboarding-modal-container {
  box-sizing: border-box;
  background: var(--background-color-primary);
}
.agent-onboarding-modal-container .modal-content {
  overflow-y: auto;
  max-height: 60vh;
  padding: var(--spacing-zero);
}
.agent-onboarding-modal-container .modal-footer {
  padding: var(--spacing-xl);
}
.agent-onboarding-modal-container .agent-onboarding-list-items {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  margin-left: var(--spacing-xxl);
  margin-right: var(--spacing-xxl);
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item {
  display: flex;
  margin: var(--spacing-xl) var(--spacing-xxl);
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-icon-container {
  margin-right: 12px;
  margin-top: 2px;
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container .title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container .title.done {
  color: var(--content-color-secondary);
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container .subtitle {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-weight: 400;
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container .subtitle-icon {
  vertical-align: middle;
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container .custom-subtitle .pm-icon {
  vertical-align: middle;
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container .custom-subtitle path {
  fill: var(--content-color-secondary);
}
.agent-onboarding-modal-container .agent-onboarding-list-items .agent-onboarding-list-item-content-container .custom-subtitle svg {
  color: var(--content-color-secondary);
}
.agent-onboarding-modal__actions {
  display: flex;
  align-items: center;
  flex: 1;
}
.agent-onboarding-modal__action-cancel {
  width: 120px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.agent-onboarding-modal__action-confirm {
  margin-left: var(--spacing-s);
  flex: 1;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-s);
}
.agent-onboarding-modal-download__action-confirm {
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-s) var(--spacing-m);
  width: 147px;
  height: var(--controls-size-default);
  background-color: var(--base-color-brand);
}

.notification-badge {
  background-color: var(--base-color-brand);
  position: absolute;
  top: 3px;
  right: 6px;
  width: calc(var(--size-xs) / 2);
  height: calc(var(--size-xs) / 2);
  border-radius: var(--border-radius-max);
  border: 3px var(--border-style-solid) var(--border-color-default);
}

.offline-state-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.offline-state-wrapper__heading {
  margin: 16px 0 8px;
}

.unjoined-workspace-modal {
  display: flex;
  flex-direction: column;
}
.unjoined-workspace-modal__loading {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unjoined-workspace-modal__content {
  margin: 28px 40px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.unjoined-workspace-modal__header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.unjoined-workspace-modal__header-close-btn-wrapper {
  height: 16px;
  width: 16px;
}
.unjoined-workspace-modal__header-close-btn-wrapper:hover {
  cursor: pointer;
}
.unjoined-workspace-modal__title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.unjoined-workspace-modal__description {
  margin-top: 8px;
  line-height: 20px;
}
.unjoined-workspace-modal__join-btn {
  margin-top: 24px;
}
.unjoined-workspace-modal__image {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/workspaces-join-artemis.svg");
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.presence-indicator {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-top: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}
.presence-indicator > *:first-child {
  margin-right: var(--spacing-s);
}
.presence-indicator__user-avatar-wrapper {
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-max);
  position: relative;
  cursor: pointer;
}
.presence-indicator__user-avatar-wrapper:hover {
  z-index: 1;
}
.presence-indicator__user-avatar-wrapper .avatar {
  border-radius: var(--border-radius-max);
  border: var(--border-width-l) var(--border-style-solid) var(--border-color-subdued);
}
.presence-indicator__user-avatar-wrapper .avatar:hover {
  transform: scale(1.1);
}
.presence-indicator__user-avatar-icon {
  cursor: pointer;
}
.presence-indicator__user-avatar-icon.inactive, .presence-indicator__user-avatar-icon-dropdown.inactive {
  opacity: 0.3;
}
.presence-indicator__user-avatar-icon.anonymous {
  cursor: pointer;
}
.presence-indicator__user-avatar-wrapper:not(:last-child) {
  margin-left: -4px;
}
.presence-indicator__user-avatar-wrapper.inactive .avatar {
  opacity: 0.3;
}
.presence-indicator__user-avatar-tooltip-status.active-status {
  color: var(--content-color-success);
  font-weight: var(--text-weight-bold);
  justify-content: flex-start;
}
.presence-indicator__user-avatar-tooltip-status {
  color: var(--content-color-secondary);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: left;
}
.presence-indicator__more-users {
  margin-left: -4px;
  margin-top: -1px;
  height: var(--size-s);
  width: var(--size-s);
  border-radius: var(--border-radius-max);
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--border-width-default) var(--border-style-solid) var(--background-color-secondary);
}
.presence-indicator__more-users:hover {
  transform: scale(1.1);
  z-index: 1;
}
.presence-indicator__more-users-text {
  margin: var(--spacing-s);
  font-size: var(--text-size-xs);
  font-weight: var(--text-weight-medium);
  vertical-align: middle;
}
.presence-indicator__more-users.opened {
  transform: scale(1.1);
  z-index: 1;
}
.presence-indicator__more-users.large {
  width: var(--controls-size-default);
  border-radius: 16px;
}
.presence-indicator__dropdown-container {
  width: 320px;
  max-height: 276px;
  position: absolute;
  color: var(--content-color-primary);
  background-color: var(--background-color-primary);
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-s);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  top: 42px;
  margin-right: var(--spacing-s);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  overflow-x: hidden;
  overflow-y: auto;
}
.presence-indicator__dropdown-header {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.presence-indicator__active-user, .presence-indicator__inactive-user {
  display: flex;
}
.presence-indicator__dropdown-users--active-header, .presence-indicator__dropdown-users--inactive-header {
  cursor: pointer;
}
.presence-indicator__dropdown-users--active-header, .presence-indicator__dropdown-users--inactive-header {
  font-size: 12px;
  display: inline-flex;
  font-weight: 600;
  text-align: left;
}
.presence-indicator__dropdown-users--active-header {
  color: var(--content-color-success);
}
.presence-indicator__dropdown-users--inactive-header {
  color: var(--content-color-tertiary);
}
.presence-indicator__active-user, .presence-indicator__inactive-user {
  display: flex;
  padding: var(--spacing-xs) var(--spacing-s) var(--spacing-xs) var(--spacing-s);
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
}
.presence-indicator__anonymous-user {
  cursor: default;
}
.presence-indicator__active-anonymous {
  display: flex;
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-xs) var(--spacing-s);
  font-size: var(--text-size-m);
  align-items: center;
}
.presence-indicator__inactive-user .avatar {
  opacity: 0.3;
}
.presence-indicator__user-name:hover {
  color: var(--content-color-link);
}
.presence-indicator__active-user-name:hover, .presence-indicator__inactive-user-name:hover {
  color: var(--content-color-link);
}
.presence-indicator__anonymous-user .presence-indicator__active-user-name:hover {
  color: var(--content-color-primary);
}
.presence-indicator__lastSeen {
  color: var(--content-color-secondary);
}
.presence-indicator__user-status-label {
  text-align: left;
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-xs) var(--spacing-s);
}
.presence-indicator__dropdown-empty-state {
  font-size: 11px;
  color: var(--content-color-tertiary);
  margin: 0 16px 4px 36px;
}
.presence-indicator__active-user-name {
  margin-left: var(--spacing-s);
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.presence-indicator__inactive-user-name, .presence-indicator__active-anonymous-name {
  margin-left: var(--spacing-s);
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.presence-indicator__tooltip {
  font-size: 12px;
  font-weight: var(--text-weight-regular);
}

.element-visibility {
  padding: var(--spacing-s) var(--spacing-m);
  border: var(--border-width-default) solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin-bottom: var(--spacing-xl);
}
.element-visibility-entity {
  display: flex;
}
.element-visibility-entity-name {
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.element-visibility-role {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-xl);
  margin-top: var(--spacing-xs);
}
.element-visibility-role__helptext {
  color: var(--content-color-secondary);
}
.element-visibility-role__btn {
  height: var(--size-s);
  margin-left: var(--spacing-xs);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.element-visibility-role__btn use {
  fill: var(--content-color-secondary);
}
.element-visibility-role__btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.element-visibility-role__btn svg {
  color: var(--content-color-secondary);
}

.permission-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--content-color-primary);
  padding: 10px 10px 20px;
  box-sizing: border-box;
}
.permission-denied .permission-denied--img {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/no-permission.svg");
  margin-bottom: 16px;
}
.permission-denied .permission-denied--title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
}
.permission-denied .permission-denied--title,
.permission-denied .permission-denied--subtitle {
  font-size: 12px;
  text-align: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.inline-comment__update-badge {
  display: flex;
  background-color: var(--base-color-brand);
  padding: 2px 8px;
  border-radius: 35px;
  color: var(--content-color-constant);
  font-size: 11px;
  cursor: pointer;
}
.inline-comment__update-badge span {
  margin-left: 4px;
}

.inline-comment__changes-badge-container {
  display: flex;
  justify-content: center;
}

.inline-comment__changes-badge {
  display: inline-flex;
  background-color: var(--base-color-brand);
  padding: 3px 10px;
  border-radius: 35px;
  margin-bottom: 12px;
  color: var(--content-color-constant);
  font-size: 11px;
  cursor: pointer;
}
.inline-comment__changes-badge .pm-icon {
  width: 10px;
  height: 10px;
}
.inline-comment__changes-badge span {
  margin-left: 4px;
}

.artemis-workspace-join {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.artemis-workspace-join__background {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/workspaces-join-artemis.svg");
}
.artemis-workspace-join__title {
  color: var(--content-color-primary);
  margin: 16px 0;
  font-size: 16px;
  font-weight: var(--text-weight-medium);
}
.artemis-workspace-join__description {
  color: var(--content-color-primary);
  font-size: 14px;
}
.artemis-workspace-join__join-btn {
  margin: 16px 0;
  min-width: 136px;
  font-size: 14px;
  font-weight: 600;
}
.artemis-workspace-join__browse-btn {
  color: var(--content-color-brand);
  font-size: 14px;
  font-weight: 600;
}

.workspace-not-found {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.workspace-not-found__img {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/empty-state-cooper.svg");
}
.workspace-not-found__title {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-m);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
}
.workspace-not-found__description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-xs);
}
.workspace-not-found__view-workspaces-btn {
  margin: var(--spacing-l) 0;
  height: var(--controls-size-default);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}

.workspace-forbidden {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.workspace-forbidden__img {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/empty-state-cooper.svg");
}
.workspace-forbidden__title {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-m);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
}
.workspace-forbidden__description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-xs);
}
.workspace-forbidden__view-workspaces-btn {
  margin: var(--spacing-l) 0;
  height: var(--controls-size-default);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}

.artemis-workspace-not-found {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.artemis-workspace-not-found__title {
  color: var(--content-color-primary);
  margin: 16px 0;
  font-size: 16px;
  font-weight: var(--text-weight-medium);
}
.artemis-workspace-not-found__description {
  color: var(--content-color-primary);
  font-size: 14px;
}
.artemis-workspace-not-found__view-workspaces-btn {
  margin: 16px 0;
  min-width: 170px;
  font-size: 14px;
  font-weight: 600;
}

.artemis-workspaces-canonical {
  margin: auto;
}
.artemis-workspaces-canonical__workspaces {
  margin: var(--spacing-l) auto 0;
  border: var(--border-width-default) solid var(--border-color-default);
  max-height: 160px;
  overflow-y: auto;
}
.artemis-workspaces-canonical__background {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/share-entity.svg");
  margin: auto;
}
.artemis-workspaces-canonical__view-btn {
  width: 100%;
  height: var(--size-m);
  margin-top: var(--spacing-l);
}
.artemis-workspaces-canonical__back-btn {
  width: 100%;
  height: var(--size-m);
  margin-top: var(--spacing-l);
  background-color: var(--background-color-tertiary);
}
.artemis-workspaces-canonical__header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xl);
  color: var(--content-color-primary);
  text-align: center;
}
.artemis-workspaces-canonical__message {
  color: var(--content-color-primary);
  text-align: center;
}
.artemis-workspaces-canonical-item__workspace-name {
  margin-left: var(--spacing-m);
}

.artemis-workspaces-canonical-item.selectedWorkspace {
  background-color: var(--highlight-background-color-primary);
}

.artemis-workspace-share-entity {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.artemis-workspace-share-entity__background {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/share-entity.svg");
}
.artemis-workspace-share-entity__title {
  color: var(--content-color-primary);
  margin: 16px 0;
  font-size: 16px;
  font-weight: var(--text-weight-medium);
}
.artemis-workspace-share-entity__description {
  color: var(--content-color-primary);
  font-size: 14px;
}
.artemis-workspace-share-entity__description-entity-name, .artemis-workspace-share-entity__description-workspace-name {
  font-weight: var(--text-weight-medium);
}
.artemis-workspace-share-entity__share-btn {
  margin: 16px 0 8px;
  min-width: 262px;
  font-size: 14px;
  font-weight: 600;
}
.artemis-workspace-share-entity__workspace-action {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.artemis-workspace-share-entity__workspace-action-actions {
  display: flex;
  margin-top: 8px;
}
.artemis-workspace-share-entity__workspace-action-text {
  color: var(--content-color-primary);
  font-size: 14px;
  text-align: center;
  line-height: 24px;
}
.artemis-workspace-share-entity__workspace-list {
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 4px;
  width: 100%;
  overflow: hidden;
}
.artemis-workspace-share-entity__workspace-list:hover {
  cursor: pointer;
  background-color: var(--highlight-background-color-primary);
}
.artemis-workspace-share-entity__workspace-name {
  color: var(--content-color-primary);
  font-size: 14px;
  margin-left: 8px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.artemis-workspace-share-entity__workspace {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.artemis-workspace-share-entity__workspace-list.unjoined:hover {
  cursor: default;
}
.artemis-workspace-share-entity__workspace.unjoined {
  opacity: 0.5;
}
.artemis-workspace-share-entity__join-btn {
  font-size: 14px;
  font-weight: 600;
  color: var(--content-color-brand);
  padding: 0;
}
.artemis-workspace-share-entity__view-btn {
  height: 32px;
  margin-left: 8px;
  min-width: 136px;
}
.artemis-workspace-share-entity__dropdown {
  height: 32px;
}
.artemis-workspace-share-entity__dropdown-menu {
  width: 232px;
  padding: 4px 0;
}
.artemis-workspace-share-entity__dropdown-menu .dropdown-menu-item {
  padding: 0;
}
.artemis-workspace-share-entity__dropdown-menu .dropdown-menu-item.is-disabled {
  opacity: 1;
}
.artemis-workspace-share-entity__dropdown-btn .btn {
  width: 232px;
  height: 32px;
  display: flex;
  justify-content: space-between;
}

.access-request__role-dropdown {
  height: var(--size-s);
  padding: var(--spacing-zero) var(--spacing-s);
}
.access-request__role-dropdown i {
  margin-left: var(--spacing-xs);
}

.artemis-workspace-error-state {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.artemis-workspace-error-state__background {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
}
.artemis-workspace-error-state__title {
  color: var(--content-color-primary);
  margin: 16px 0;
  font-size: 16px;
  font-weight: var(--text-weight-medium);
}
.artemis-workspace-error-state__description {
  color: var(--content-color-primary);
  font-size: 14px;
}
.artemis-workspace-error-state__switch-btn {
  margin: 16px 0;
  min-width: 136px;
  font-size: 14px;
  font-weight: 600;
}

.workspace-error-state {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.workspace-error-state__title {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-m);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
}
.workspace-error-state__description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-xs);
}
.workspace-error-state__switch-btn {
  margin: var(--spacing-l) 0;
  height: var(--controls-size-default);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}

.request-to-action-modal__content {
  margin-bottom: var(--spacing-s);
}
.request-to-action-modal__content__container__counter--error {
  color: var(--content-color-error);
}
.request-to-action-modal__content__container label {
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-xs);
}

.request-for-access-modal .modal-content {
  padding: var(--spacing-l) var(--spacing-xl);
}
.request-for-access-modal .modal-content--loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.request-for-access-modal .modal-footer {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.request-for-access-modal__title {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.request-for-access-modal__dropdown-container label {
  margin: var(--spacing-s) var(--spacing-zero) var(--spacing-s);
}
.request-for-access-modal__dropdown-container__label {
  margin-top: var(--spacing-l);
}
.request-for-access-modal__dropdown-container__text-container {
  display: flex;
  align-items: center;
  margin: var(--spacing-s) var(--spacing-zero) var(--spacing-l);
}
.request-for-access-modal__dropdown-container__text-container .pm-icon {
  margin-right: var(--spacing-s);
}
.request-for-access-modal__dropdown-container__approver {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.request-for-access-modal__dropdown-container__approver .avatar {
  margin-right: 8px;
}
.request-for-access-modal__dropdown-container__approver span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.request-for-access-modal__dropdown-container--error {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-error);
  border-radius: var(--border-radius-default);
}

.options-wrapper .options-list {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.options-wrapper .options-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.options-wrapper .options-list-item-label-description {
  display: flex;
  flex-direction: column;
  padding-right: var(--spacing-xxl);
}
.options-wrapper .options-list-item-label {
  flex: 2;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.options-wrapper .options-list-item-description-markdown {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-secondary);
  white-space: pre-wrap;
}
.options-wrapper .options-list-item-description-markdown h1,
.options-wrapper .options-list-item-description-markdown h2,
.options-wrapper .options-list-item-description-markdown h3,
.options-wrapper .options-list-item-description-markdown h4,
.options-wrapper .options-list-item-description-markdown h5,
.options-wrapper .options-list-item-description-markdown h6 {
  line-height: 1.5;
  margin: 1.5em 0 0.5em;
}
.options-wrapper .options-list-item-description-markdown h1 {
  font-size: 18px;
  font-weight: 700;
}
.options-wrapper .options-list-item-description-markdown h2 {
  font-size: 16px;
  font-weight: 700;
}
.options-wrapper .options-list-item-description-markdown h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.options-wrapper .options-list-item-description-markdown h4 {
  font-size: 14px;
  font-weight: 700;
}
.options-wrapper .options-list-item-description-markdown h5 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.options-wrapper .options-list-item-description-markdown h6 {
  font-size: 12px;
  font-weight: 600;
}
.options-wrapper .options-list-item-description-markdown hr {
  border-style: none;
  border-width: 0;
  border-bottom: 1px solid var(--border-color-default);
}
.options-wrapper .options-list-item-description-markdown blockquote {
  padding-left: 16px;
  margin-left: 8px;
  border-left: 4px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.options-wrapper .options-list-item-description-markdown blockquote blockquote {
  margin-left: 20px;
}
.options-wrapper .options-list-item-description-markdown p, .options-wrapper .options-list-item-description-markdown span {
  margin: 3px 0;
  font-size: 12px;
  font-weight: 400;
}
.options-wrapper .options-list-item-description-markdown em {
  font-style: italic;
}
.options-wrapper .options-list-item-description-markdown strong {
  font-weight: var(--text-weight-medium);
}
.options-wrapper .options-list-item-description-markdown ul, .options-wrapper .options-list-item-description-markdown ol {
  margin: 5px;
}
.options-wrapper .options-list-item-description-markdown a {
  color: var(--content-color-link);
  text-decoration: none;
}
.options-wrapper .options-list-item-description-markdown a:hover {
  text-decoration: underline;
}
.options-wrapper .options-list-item-description-markdown pre {
  padding: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.options-wrapper .options-list-item-description-markdown pre code {
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}
.options-wrapper .options-list-item-description-markdown code {
  padding: 1px 3px;
  font-family: "IBMPlexMono", "Cousine", monospace;
  font-size: 12px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.options-wrapper .options-list-item-description-markdown table {
  width: 100%;
  margin: 8px 0;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid var(--border-color-default);
}
.options-wrapper .options-list-item-description-markdown table th,
.options-wrapper .options-list-item-description-markdown table td {
  max-width: 360px;
  padding: 4px 8px;
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: 1px solid var(--border-color-default);
}
.options-wrapper .options-list-item-description-markdown table th:first-child,
.options-wrapper .options-list-item-description-markdown table td:first-child {
  border-left: none;
}
.options-wrapper .options-list-item-description-markdown table th:last-child,
.options-wrapper .options-list-item-description-markdown table td:last-child {
  border-right: none;
}
.options-wrapper .options-list-item-description-markdown table th {
  border-top: none;
}
.options-wrapper .options-list-item-description-markdown table tr:last-child th,
.options-wrapper .options-list-item-description-markdown table tr:last-child td {
  border-bottom: none;
}
.options-wrapper .options-list-item-description-markdown img {
  max-width: 100%;
}
.options-wrapper .options-list-item-value {
  flex: 0 0 100px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
}
.options-wrapper .dropdown {
  width: 100%;
  height: 30px;
  background-color: var(--background-color-tertiary);
}
.options-wrapper .dropdown .dropdown-button {
  display: flex;
}
.options-wrapper .dropdown .dropdown-button .btn {
  flex: 1;
  height: 30px;
  color: var(--content-color-secondary);
  justify-content: space-between;
}
.options-wrapper .dropdown .dropdown-button .btn use {
  fill: var(--content-color-secondary);
}
.options-wrapper .dropdown .dropdown-button .btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.options-wrapper .dropdown .dropdown-button .btn svg {
  color: var(--content-color-secondary);
}

.integration-context-bar-list {
  --integration-cb-list-width: 280px;
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  padding-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
}
.integration-context-bar-list__label-wrapper-label {
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  cursor: pointer;
}
.integration-context-bar-list__label-wrapper-toggle {
  margin-left: 0;
  margin-right: var(--spacing-s);
  margin-top: calc(var(--spacing-xs) / 2);
  vertical-align: text-top;
  cursor: pointer;
  float: left;
}
.integration-context-bar-list__label-wrapper-toggle:hover {
  background-color: var(--highlight-background-color-secondary);
}
.integration-context-bar-list__integration-list {
  padding-left: var(--spacing-xl);
}
.integration-context-bar-list__browse {
  padding-top: var(--spacing-l);
  padding-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  text-decoration: underline;
  cursor: pointer;
}
.integration-context-bar-list__browse:hover {
  color: var(--content-color-link);
}
.integration-context-bar-list__info {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-m);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
}
.integration-context-bar-list__info-header {
  font-weight: var(--text-weight-medium);
}
.integration-context-bar-list__info-body {
  color: var(--content-color-tertiary);
}
.integration-context-bar-list__info-footer {
  color: var(--content-color-tertiary);
  text-decoration: underline;
  cursor: pointer;
}
.integration-context-bar-list__info-footer:hover {
  color: var(--content-color-link);
}
.integration-context-bar-list__item {
  line-height: var(--line-height-m);
  padding-top: var(--spacing-m);
  display: flex;
}
.integration-context-bar-list__item-area {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding-right: var(--spacing-m);
}
.integration-context-bar-list__item-label {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.integration-context-bar-list__item-label:hover {
  color: var(--content-color-link);
  text-decoration: underline;
}
.integration-context-bar-list__item-last-run {
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
}
.integration-context-bar-list__item-icon {
  padding-right: var(--spacing-s);
  align-items: center;
  place-content: center;
}
.integration-context-bar-list__loading {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-l);
}
.integration-context-bar-list__status-info {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  padding-left: var(--spacing-xl);
  margin-top: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  margin-bottom: calc(var(--spacing-xs) + var(--spacing-xs) / 2);
  max-width: var(--integration-cb-list-width);
  display: inline-flex;
}

.integration-status-icon {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  cursor: pointer;
}
.integration-status-icon .agent-selection-label {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);
}
.integration-status-icon .sb__item__text {
  margin-bottom: 2px;
}
.integration-status-icon-error use {
  fill: var(--content-color-error) !important;
}
.integration-status-icon-error svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error) !important;
}
.integration-status-icon-error svg {
  color: var(--content-color-error) !important;
}
.integration-status-icon-success use {
  fill: var(--content-color-success) !important;
}
.integration-status-icon-success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success) !important;
}
.integration-status-icon-success svg {
  color: var(--content-color-success) !important;
}

.banner-container {
  padding: var(--spacing-l);
  display: flex;
  flex-direction: row;
}
.banner-container__neutral {
  background-color: var(--background-color-secondary);
}
.banner-container__info {
  background-color: var(--background-color-info);
}
.banner-container__warning {
  background-color: var(--background-color-warning);
}
.banner-container__error {
  background-color: var(--background-color-error);
}
.banner-container__success {
  background-color: var(--background-color-success);
}
.banner-container .banner-content {
  padding: 0 var(--spacing-s);
}
.banner-container-footer {
  padding-top: var(--spacing-s);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.import-via-code-repository-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  overflow-y: auto;
}
.import-via-code-repository-container .import-via-code-repository {
  display: flex;
}
.import-via-code-repository-container .import-via-code-repository__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__line {
  height: 0px;
  margin-top: var(--spacing-xl);
  /* border-color/strong */
  border-top: var(--border-width-default) solid var(--border-color-strong);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-title {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  margin-top: 2px;
  width: auto;
  float: left;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown {
  width: 480px;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .integrations-add-card__segment .Select--single.pm-select {
  font-size: 12px;
  height: 32px;
  padding: var(--spacing-zero);
  width: 480px;
  margin: 0px;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .integrations-add-card__segment .Select--single.pm-select .Select-menu {
  max-height: 130px;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-wrapper {
  padding: var(--spacing-zero);
  width: 480px;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-missing__message {
  display: flex;
  flex-direction: row;
  color: var(--content-color-secondary);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-missing__message .import-repository-message {
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-missing__message .btn-text {
  height: var(--size-xs);
  margin-top: var(--spacing-s);
  padding-left: var(--spacing-xs);
  color: var(--content-color-secondary);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repo-error {
  display: flex;
  flex-direction: row;
  color: var(--content-color-error);
  height: 20px;
  align-items: center;
  padding: 1px;
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repo-error .import-repo-error-btn {
  cursor: pointer;
  text-decoration: underline;
  margin-left: var(--spacing-xs);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repo-specify-manually {
  display: flex;
  flex-direction: row;
  color: var(--content-color-secondary);
  height: 20px;
  align-items: center;
  padding-left: 1px;
  padding-top: var(--spacing-s);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repo-specify-manually .import-repo-specify-manually-btn {
  cursor: pointer;
  text-decoration: underline;
  margin-left: var(--spacing-xs);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-error__message {
  display: flex;
  flex-direction: row;
  color: var(--content-color-error);
  padding-top: var(--spacing-s);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-error__message .import-repository-error__icon {
  padding: var(--spacing-xs);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-error__message .import-repository-error__icon use {
  fill: var(--base-color-error);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-error__message .import-repository-error__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-group .import-repository-error__message .import-repository-error__icon svg {
  color: var(--base-color-error);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-label {
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  padding-bottom: var(--spacing-s);
}
.import-via-code-repository-container .import-via-code-repository__content .import-via-code-repository__content-dropdown .import-dropdown-label--manual-input {
  height: 40px;
}

.add-margin-left {
  margin-left: var(--spacing-xs);
}

.import-via-code-repository-container-new-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: visible;
  margin-left: calc(var(--spacing-s) * -1);
}

.import-via-code-repository-user-info {
  display: flex;
  flex-direction: row;
  width: auto;
  float: left;
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);
}
.import-via-code-repository-user-info .import-via-code-repository-user-info__username {
  margin-left: var(--spacing-xs);
}
.import-via-code-repository-user-info .import-via-code-repository-user-info__username_title {
  margin-right: var(--spacing-s);
  color: var(--content-color-secondary);
}

.importers-repository-primary-screen {
  font-family: var(--text-family-default);
  text-align: left;
}
.importers-repository-primary-screen .entity-empty {
  border: none;
  padding: var(--spacing-zero);
  font-size: var(--text-size-m);
  align-items: flex-start;
}
.importers-repository-primary-screen .entity-empty.entity-empty__centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.importers-repository-primary-screen .entity-empty .entity-empty__title {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-xs);
  line-height: var(--line-height-xl);
}
.importers-repository-primary-screen .entity-empty .entity-empty__title.github-import-title {
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-zero);
}
.importers-repository-primary-screen .entity-empty .entity-empty__title.api-gateway-import-title {
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-zero);
}
.importers-repository-primary-screen .entity-empty .entity-empty__title.api-gateway-import-title.create-api-modal {
  font-size: var(--text-size-m);
}
.importers-repository-primary-screen .entity-empty .switch-to-workspace__title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-xs);
  line-height: var(--line-height-m);
}
.importers-repository-primary-screen .entity-empty .switch-to-workspace__subtitle {
  margin-bottom: 16px;
  text-align: center;
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: 20px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__authenticating_title {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--spacing-xs);
  line-height: var(--line-height-xl);
}
.importers-repository-primary-screen .entity-empty .entity-empty__authenticating_title.github-import-authenticating-title {
  font-size: var(--text-size-xxl);
  color: var(--content-color-primary);
  line-height: var(--line-height-xxl);
}
.importers-repository-primary-screen .entity-empty .entity-empty__subtitle {
  margin-bottom: 20px;
  text-align: left;
}
.importers-repository-primary-screen .entity-empty .entity-empty__subtitle.github-import-subtitle {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-l);
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}
.importers-repository-primary-screen .entity-empty .entity-empty__subtitle.reposelection-import-subtitle {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-l);
  width: 100%;
  white-space: nowrap;
}
.importers-repository-primary-screen .entity-empty .entity-empty__subtitle.api-gateway-import-subtitle {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-l);
  width: 100%;
  white-space: nowrap;
}
.importers-repository-primary-screen .entity-empty .entity-empty__title,
.importers-repository-primary-screen .entity-empty .entity-empty__subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--repository {
  margin-bottom: var(--spacing-l);
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--repository .code-repository-empty__illustration {
  display: block;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/integrations_cloud.svg");
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--github {
  display: flex;
  align-content: stretch;
  margin-bottom: var(--spacing-m);
  background-color: var(--grey-00);
  border-radius: var(--border-radius-default);
  width: 64px;
  height: 64px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--github .oauth-empty__illustration__github {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/github_logo.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--github .oauth-empty__illustration__bitbucket {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/bitbucket_logo.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--github .oauth-empty__illustration__gitlab {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/gitlab.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--github .oauth-empty__illustration__azure {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/azure.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__illustration-wrapper--github .oauth-empty__illustration__slack {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/slack_new.svg");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-button-override-github {
  margin-left: 2px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-button-override-github svg {
  color: rgb(22, 21, 20);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-button-override-github-dark {
  margin-left: 2px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-button-override-github-dark svg {
  color: white;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-button-override-github-dark svg rect {
  fill: unset;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-button-override-bitbucket svg {
  color: rgb(38, 132, 255);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-button-override-gitlab svg {
  color: rgb(252, 109, 38);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new {
  display: flex;
  flex-direction: row;
  width: 16px;
  height: 8px;
  padding: var(--spacing-m);
  cursor: pointer;
  left: 0px;
  top: 0px;
  align-items: flex-start;
  margin-left: -15px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper.icon-dark-mode {
  background-color: transparent;
  display: flex;
  align-content: stretch;
  border-radius: var(--border-radius-default);
  width: 30px;
  min-width: 30px;
  height: 30px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper.icon-dark-mode:hover {
  box-sizing: border-box;
  background-color: #303030;
  color: #FFFFFF;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper {
  background-color: transparent;
  display: flex;
  align-content: stretch;
  border-radius: var(--border-radius-default);
  width: 30px;
  min-width: 30px;
  height: 30px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper:hover {
  box-sizing: border-box;
  background-color: rgb(237, 237, 237);
  color: rgb(33, 33, 33);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper .repository-card-logo__gateway {
  width: 24px;
  height: 24px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper .repository-card-logo__github {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/github_logo.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper .repository-card-logo__bitbucket {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/bitbucket_logo.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper .repository-card-logo__gitlab {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/gitlab.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-icon-new .repository-card-logo-wrapper .repository-card-logo__azure {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/azure.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card {
  display: flex;
  flex-direction: row;
  width: 168px;
  height: 48px;
  padding: var(--spacing-m);
  cursor: pointer;
  left: 0px;
  top: 0px;
  align-items: center;
  border: var(--border-width-default) solid var(--border-color-strong);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  margin-right: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-loading-wrapper {
  display: flex;
  visibility: hidden;
  flex-direction: row;
  margin-left: var(--spacing-xxxl);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-title {
  width: 41px;
  height: 16px;
  left: 44px;
  margin-left: var(--spacing-s);
  top: calc(50% - 8px);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-logo-wrapper {
  display: flex;
  align-content: stretch;
  background-color: var(--grey-00);
  border-radius: var(--border-radius-default);
  width: 28px;
  height: 28px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-logo-wrapper .repository-card-logo__gateway {
  width: 24px;
  height: 24px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-logo-wrapper .repository-card-logo__github {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/github_logo.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-logo-wrapper .repository-card-logo__bitbucket {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/bitbucket_logo.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-logo-wrapper .repository-card-logo__gitlab {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/gitlab.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card .repository-card-logo-wrapper .repository-card-logo__azure {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/azure.png");
  margin: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card:hover {
  background: var(--background-color-tertiary);
  /* border-color/default */
  border: var(--border-width-default) solid var(--border-color-strong);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .repository-card:hover > .repository-card-loading-wrapper {
  visibility: visible;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled {
  display: flex;
  flex-direction: row;
  width: 190px;
  height: 48px;
  padding: var(--spacing-m);
  cursor: pointer;
  left: 0px;
  top: 0px;
  align-items: center;
  border: var(--border-width-default) solid var(--border-color-strong);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  margin-right: var(--spacing-l);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card .gateway-card-loading-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-loading-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-loading-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled .gateway-card-loading-wrapper {
  display: flex;
  visibility: hidden;
  flex-direction: row;
  margin-left: var(--spacing-xs);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card .gateway-card-title, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-title, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-title, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled .gateway-card-title {
  width: 120px;
  height: var(--size-xs);
  left: 44px;
  margin-left: var(--spacing-s);
  top: calc(50% - 8px);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card .gateway-card-logo-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-logo-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-logo-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled .gateway-card-logo-wrapper {
  display: flex;
  align-content: stretch;
  background-color: var(--grey-00);
  border-radius: var(--border-radius-default);
  width: 28px;
  height: 28px;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card .gateway-card-logo-wrapper .gateway-card-logo__icon, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-logo-wrapper .gateway-card-logo__icon, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-logo-wrapper .gateway-card-logo__icon, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled .gateway-card-logo-wrapper .gateway-card-logo__icon {
  width: var(--size-s);
  height: var(--size-s);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card .gateway-card-logo-wrapper__new-api, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-logo-wrapper__new-api, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-logo-wrapper__new-api, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled .gateway-card-logo-wrapper__new-api {
  display: flex;
  align-content: stretch;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  width: var(--size-s);
  height: var(--size-s);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card .gateway-card-logo-wrapper__new-api .gateway-card-logo__icon, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-logo-wrapper__new-api .gateway-card-logo__icon, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-logo-wrapper__new-api .gateway-card-logo__icon, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled .gateway-card-logo-wrapper__new-api .gateway-card-logo__icon {
  width: var(--size-s);
  height: var(--size-s);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled {
  cursor: default;
  background: var(--background-color-tertiary);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled {
  margin-top: var(--spacing-m);
  padding: 6px var(--spacing-m);
  margin-right: var(--spacing-zero);
  height: auto;
  width: auto;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-logo-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-logo-wrapper {
  display: flex;
  align-content: stretch;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  width: var(--size-xs);
  height: var(--size-xs);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-logo-wrapper .gateway-card-logo__icon, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-logo-wrapper .gateway-card-logo__icon {
  width: var(--size-s);
  height: var(--size-s);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api .gateway-card-title, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled .gateway-card-title {
  width: auto;
  height: var(--size-xs);
  left: 44px;
  margin-left: var(--spacing-s);
  top: calc(50% - 8px);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled {
  cursor: default;
  background: var(--background-color-tertiary);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card:hover, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled:hover, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api:hover, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled:hover {
  background: var(--background-color-tertiary);
  border: var(--border-width-default) solid var(--border-color-strong);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card:hover > .gateway-card-loading-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__disabled:hover > .gateway-card-loading-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api:hover > .gateway-card-loading-wrapper, .importers-repository-primary-screen .entity-empty .entity-empty__connect .gateway-card__new-api__disabled:hover > .gateway-card-loading-wrapper {
  visibility: visible;
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .import-code-connect__github {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .import-code-connect__icon use {
  fill: var(--content-color-constant);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .import-code-connect__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-constant);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect .import-code-connect__icon svg {
  color: var(--content-color-constant);
}
.importers-repository-primary-screen .entity-empty .entity-empty__connect__new-api {
  gap: var(--spacing-m);
}
.importers-repository-primary-screen .entity-empty .entity-empty-or__import-code {
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}
.importers-repository-primary-screen .entity-empty .entity-empty-or__import-code .entity-empty-line__import-code {
  width: 56px;
  height: 0px;
  margin-top: 5px;
  /* border-color/strong */
  border-top: var(--border-width-default) var(--border-color-strong);
}
.importers-repository-primary-screen .entity-empty .entity-empty-or__import-code .entity-empty-or-text__import-code {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  margin-right: var(--spacing-m);
  margin-left: var(--spacing-m);
}
.importers-repository-primary-screen .entity-empty .entity-empty__learn .entity-empty__learn__mouse-click-icon {
  padding-right: var(--spacing-s);
}

.import-code-repository-tab {
  display: flex;
  flex-direction: row;
}
.import-code-repository-tab .import-tabs-header--code-repository-new {
  width: 40px;
  left: 312px;
  padding: var(--spacing-zero);
  border-radius: 12px;
  background-color: var(--background-color-info);
  color: var(--content-color-info);
  font-weight: var(--text-weight-medium);
  margin-left: var(--spacing-xs);
}

.import-api-gateway-tab {
  display: flex;
  flex-direction: row;
}
.import-api-gateway-tab .import-tabs-header--api-gateway-new {
  width: 40px;
  left: 312px;
  padding: var(--spacing-zero);
  border-radius: 12px;
  background-color: var(--background-color-info);
  color: var(--content-color-info);
  font-weight: var(--text-weight-medium);
  margin-left: var(--spacing-xs);
}

.import-via-code-repository__banner {
  display: flex;
  align-items: center;
  background-color: var(--background-color-warning);
  padding: 10px var(--spacing-l) 10px var(--spacing-l);
  margin-top: var(--spacing-l);
}
.import-via-code-repository__banner .import-via-code-repository__banner__icon use {
  fill: var(--content-color-warning);
}
.import-via-code-repository__banner .import-via-code-repository__banner__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-warning);
}
.import-via-code-repository__banner .import-via-code-repository__banner__icon svg {
  color: var(--content-color-warning);
}
.import-via-code-repository__banner .import-via-code-repository__banner__message {
  margin-left: var(--spacing-l);
  color: var(--content-color-warning);
}

.import-via-code-repository__aetherbanner {
  padding-bottom: 18px;
}

.integration-oauth-icon-github {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/github_logo.png");
}

.integration-oauth-icon-bitbucket {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/bitbucket_logo.png");
}

.integration-oauth-icon-gitlab {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/gitlab.png");
}

.integration-oauth-icon-azure {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/azure.png");
}

.service-details-view-container-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-xxl);
}

.ci-cd-available-service-icon {
  width: 100%;
  height: auto;
}

.ci-cd-loading-indicator {
  position: relative;
  top: 50%;
  left: 50%;
}

.ci-cd-test-header {
  display: flex;
  background: var(--background-color-secondary);
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  height: var(--spacing-xxl);
  align-items: center;
  padding: 0px var(--spacing-l);
  border-radius: var(--border-radius-default);
}
.ci-cd-test-header .drop-icon {
  height: var(--spacing-xxl);
  padding: 0px;
  cursor: pointer;
}
.ci-cd-test-header .title {
  margin-left: var(--spacing-xs);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-bold);
  display: flex;
  align-items: center;
  height: var(--spacing-xxl);
}

.cancel-button {
  margin-top: var(--spacing-xl);
  float: left;
  margin-left: var(--spacing-s);
}

.ci-cd-builds-page-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container {
  display: flex;
  z-index: 2;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero) var(--spacing-s);
  min-height: 48px;
  align-items: center;
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container .ci-cd-builds-page-back-button-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: var(--spacing-s);
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container .project-service-name {
  width: 65%;
  display: flex;
  color: var(--content-color-secondary);
  align-items: center;
  font-size: var(--text-size-m);
  overflow: hidden;
  text-overflow: ellipsis;
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container .project-service-name .source {
  align-items: center;
  display: flex;
  margin-left: var(--spacing-s);
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container .right-hand-buttons {
  margin-left: auto;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container .right-hand-buttons .configure-newman-button {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  margin-right: var(--spacing-xs);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container .right-hand-buttons .configure-newman-button .configure-icon {
  margin: var(--spacing-zero) var(--spacing-xs);
}
.ci-cd-builds-page-container .ci-cd-builds-page-info-container .right-hand-buttons .integration-options {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container {
  margin: var(--spacing-s) var(--spacing-l);
  display: flex;
  justify-content: space-between;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .filter-container {
  display: flex;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .filter-container .filter-text {
  display: flex;
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  padding: var(--spacing-s) var(--spacing-xs);
  align-items: center;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .filter-container .filter-dropdowns {
  display: flex;
  padding: var(--spacing-s) var(--spacing-xs);
  align-items: center;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .filter-container .filter-dropdowns .filter-dropdowns--branch {
  margin-right: 2px;
  min-width: 122px;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .filter-container .filter-dropdowns .filter-dropdowns--branch .aether-dropdown__control {
  border: none;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .filter-container .ci-cd-builds-dropdown {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-zero);
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .filter-container .ci-cd-builds-dropdown .dropdown-button .ci-cd-chosen-branch {
  padding-top: var(--spacing-m);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .run-refresh-container {
  display: flex;
  justify-content: flex-end;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .run-refresh-container .btn.ci-cd-run-builds-button {
  font-weight: var(--text-weight-regular);
  display: flex;
  justify-content: flex-end;
}
.ci-cd-builds-page-container .ci-cd-builds-page-button-container .run-refresh-container .btn.ci-cd-refresh-builds-button {
  font-weight: var(--text-weight-regular);
  display: flex;
  justify-content: flex-end;
}
.ci-cd-builds-page-container .ci-cd-loading-build-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}
.ci-cd-builds-page-container .ci-cd-builds-table .ci-cd-builds-table-success {
  margin: var(--spacing-m);
  overflow: hidden;
}
.ci-cd-builds-page-container .view-more-button {
  width: 20%;
  display: flex;
  color: var(--content-color-link);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) 20px;
  margin-left: var(--spacing-xxl);
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  cursor: pointer;
}
.ci-cd-builds-page-container .compact-builds-configure-newman-button {
  width: 20%;
  display: flex;
  color: var(--content-color-link);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-m);
  margin-left: var(--spacing-xxl);
  margin-top: var(--spacing-s);
  cursor: pointer;
}
.ci-cd-builds-page-container .ci-cd-empty-state-illustration-wrapper {
  margin-bottom: var(--spacing-l);
}
.ci-cd-builds-page-container .table-content td {
  vertical-align: middle;
}
.ci-cd-builds-page-container .table-content td > span {
  display: block;
}
.ci-cd-builds-page-container .table-content td [class*=Table__StyledExpandCellWrapper] {
  margin-top: var(--spacing-zero);
}

.ci-cd-view-all-builds {
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-xxl) var(--spacing-xxl);
}

.ci-cd-run-builds-modal__dropdown .integrations-add-card__segment .Select--single.pm-select {
  font-size: var(--text-size-m);
  height: var(--spacing-xxl);
  width: 432px;
  margin: var(--spacing-zero);
}
.ci-cd-run-builds-modal__dropdown .integrations-add-card__segment .Select--single.pm-select .Select-menu {
  max-height: 350px;
  overflow-y: auto;
}
.ci-cd-run-builds-modal__dropdown .integrations-add-card__label-title {
  font-weight: var(--text-weight-bold);
}

.delete-option {
  color: var(--content-color-error);
}
.delete-option:hover {
  color: var(--content-color-secondary);
}

.compact-empty-state-content {
  margin: var(--spacing-s) var(--spacing-xl);
  padding: var(--spacing-zero) var(--spacing-l);
  display: flex;
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}

.error-compact-builds {
  padding: var(--spacing-zero) var(--spacing-s);
  display: flex;
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  margin: var(--spacing-l);
}

.ci-cd-configured-test-header {
  display: flex;
  background: var(--background-color-secondary);
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  height: var(--spacing-xxl);
  align-items: center;
  padding: var(--spacing-zero) var(--spacing-l);
}
.ci-cd-configured-test-header .drop-icon {
  height: var(--spacing-xxl);
  padding: var(--spacing-zero);
  cursor: pointer;
  background: var(--background-color-secondary);
}
.ci-cd-configured-test-header .title {
  margin-left: var(--spacing-xs);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: 600;
  width: 80%;
}
.ci-cd-configured-test-header .run-build-btn {
  float: right;
  margin-left: auto;
  flex: none;
}
.ci-cd-configured-test-header .integration-more-options {
  display: flex;
  cursor: pointer;
}

.menu-item-checkbox {
  border-radius: var(--spacing-zero);
}
.menu-item-checkbox.is-selected {
  background-color: var(--base-color-info);
}

.run-build-modal-button {
  width: 25%;
  margin: var(--spacing-l) var(--spacing-zero);
}

.builds-page-icon {
  display: flex;
  padding: var(--spacing-xs);
}

.builds-page-icon-spaced {
  display: flex;
  padding: var(--spacing-zero) var(--spacing-l);
}

.menu-item-div {
  width: 100%;
}

.span_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dynamic-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.rb-modal__content {
  overflow: visible;
  margin: var(--spacing-s) var(--spacing-s);
  padding: var(--spacing-xs) var(--spacing-l) var(--spacing-s) !important;
}
.rb-modal__footer {
  justify-content: flex-end !important;
  gap: var(--spacing-m);
  padding-bottom: var(--spacing-s) !important;
}

.configure-newman-button-tooltip {
  padding: var(--spacing-l);
}
.configure-newman-button-tooltip__header {
  font-size: var(--spacing-m);
  line-height: var(--spacing-l);
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
}
.configure-newman-button-tooltip__content {
  font-size: var(--spacing-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
}
.configure-newman-button-tooltip__link {
  cursor: pointer;
  text-decoration: underline;
}

.configure-newman-button-popover {
  min-width: 320px;
}

.integration-options-dropdown-button {
  height: var(--size-s);
  min-width: var(--size-s);
  width: var(--size-s);
}

.ci-cd-builds-button {
  margin-top: var(--spacing-s);
}

.row_icon {
  padding: var(--spacing-m) var(--spacing-s);
  height: var(--size-xs);
  width: var(--size-xs);
}

.table-without-head thead {
  display: none;
}

.build-expand-table-wrapper {
  background-color: var(--background-color-secondary);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.build-expand-table-wrapper .build-expand-table {
  margin-left: var(--spacing-xxl);
}

.build-row-text-with-icon {
  margin-left: var(--spacing-s);
}

build-row__link__icon {
  margin-top: var(--spacing-zero);
}

.ci-cd-configure-newman-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  height: 100%;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper {
  justify-content: flex-start;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper {
  margin-top: var(--spacing-l);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 984px;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-heading {
  display: flex;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-collections-heading {
  margin-right: var(--spacing-m);
  flex: 1;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-checkbox-heading {
  margin-right: var(--spacing-l);
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-environments-heading {
  flex: 1;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-dropdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-s) var(--spacing-zero);
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-dropdown-wrapper .ci-cd-configure-newman-checkbox {
  margin-right: var(--spacing-l);
  height: var(--size-xs);
  width: var(--size-xs);
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-dropdown-wrapper .ci-cd-configure-newman-collections {
  margin-right: var(--spacing-m);
  flex: 1;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-dropdown-wrapper .ci-cd-configure-newman-environments {
  flex: 1;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-dropdown-wrapper .ci-cd-configure-newman-remove-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin-left: var(--spacing-m);
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-button-stub {
  height: 32px;
  width: 44px;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-add-more-button {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-s);
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-service,
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-os {
  display: flex;
  flex: 1;
  position: relative;
  box-sizing: border-box;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-service > div,
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-os > div {
  flex: 1;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .ci-cd-configure-newman-service {
  margin-right: var(--spacing-m);
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .configure-newman-box-wrapper .configure-newman-box {
  border-radius: var(--border-radius-default);
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .configure-newman-box-wrapper .configure-newman-box .text-editor {
  overflow: scroll;
  min-height: fit-content;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .configure-newman-box-wrapper .configure-newman-box .monaco-editor {
  min-height: 324px;
}
.ci-cd-configure-newman-container .ci-cd-configure-newman-wrapper .ci-cd-configure-newman-content-wrapper .configure-newman-box-wrapper .configure-newman-box .monaco-editor .line-numbers {
  color: var(--content-color-tertiary);
}

.ci-cd-configure-newman-back-button {
  position: absolute;
  left: 4px;
  top: 4px;
}

.ci-cd-configure-newman-button-wrapper {
  margin-top: var(--spacing-xxl);
}

.ci-cd-configure-newman-api-creation-panel__content button {
  flex-shrink: 0;
}

.api-entity-dropdown.api-performance-dropdown {
  margin: var(--spacing-l) var(--spacing-zero);
}
.api-entity-dropdown .api-entity-dropdown-trigger .api-entity-dropdown-icon-wrapper {
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
}
.api-entity-dropdown .api-entity-dropdown-trigger .monitoring-illustrator-add-cta {
  display: flex;
  padding: var(--spacing-l);
  min-width: max-content;
}
.api-entity-dropdown .api-entity-dropdown-trigger .monitoring-illustrator-add-cta .monitoring-illustrator-add-cta-text {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}
.api-entity-dropdown .monitoring-illustrator {
  align-items: center;
  color: var(--content-color-primary);
  width: 100%;
}
.api-entity-dropdown .monitoring-illustrator .monitoring-dashboard-links {
  margin-left: auto;
}
.api-entity-dropdown .monitoring-illustrator .monitoring-illustrator-description {
  margin-top: var(--spacing-m);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  text-align: center;
  color: var(--content-color-secondary);
}
.api-entity-dropdown .monitoring-illustrator .monitoring-table-title-wrapper {
  display: flex;
  margin-left: 18px;
  align-items: center;
}
.api-entity-dropdown .monitoring-illustrator .monitoring-table-title-wrapper .monitoring-table-title {
  height: 20px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}

.monitoring-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.monitoring-services-loading-indicator {
  position: relative;
  top: 50%;
  left: 50%;
}

.table-loading-indicator {
  position: relative;
  margin-top: var(--spacing-xxl);
  left: 50%;
}

.deployments-table-title {
  margin-top: var(--spacing-xxl);
}

.api-monitoring-stats-empty-state {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-l);
  margin-top: 22px;
}

.monitoring-services-table {
  margin: var(--spacing-xs) var(--spacing-xl) var(--spacing-l);
}
.monitoring-services-table.monitoring-services-table--wo_bleed {
  margin-left: var(--spacing-zero);
  margin-right: var(--spacing-zero);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header {
  position: static;
  display: flex;
  height: var(--size-m);
  width: 100%;
  border-bottom: var(--border-style-solid) var(--border-width-default) var(--border-color-default);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-empty {
  width: var(--size-m);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-services {
  width: 30%;
  float: left;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-stats {
  width: 33%;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-opened-at {
  width: 15%;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-closed-at {
  width: 15%;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-event-name {
  width: 50%;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-severity {
  width: 15%;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-latency {
  float: left;
  width: 15%;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-error-rate {
  float: left;
  width: 15%;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-appdex {
  float: left;
  width: 15%;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-header .monitoring-services-table-header-cell-status {
  float: left;
  width: 15%;
  min-width: 115px;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row {
  display: flex;
  position: static;
  align-items: center;
  font-size: var(--text-size-m);
  height: fit-content;
  min-height: 32px;
  width: 100%;
  border-bottom: var(--border-style-solid) var(--border-width-default) var(--border-color-default);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-empty {
  width: var(--size-m);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-services {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 30%;
  float: left;
  height: 40px;
  line-height: var(--line-height-xxxl);
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-stats {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 33%;
  float: left;
  height: 32px;
  line-height: var(--line-height-xxxl);
  padding: var(--spacing-xs) var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-status-critical {
  width: 15%;
  color: var(--base-color-error);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-status-warning {
  width: 15%;
  color: var(--base-color-warning);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-opened-at {
  width: 15%;
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-closed-at {
  width: 15%;
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-event-name {
  width: 50%;
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-latency {
  float: left;
  width: 15%;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-error-rate {
  float: left;
  width: 15%;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-apdex {
  float: left;
  width: 15%;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-status {
  float: left;
  width: 15%;
  min-width: 115px;
  padding: var(--spacing-s) var(--spacing-zero);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row .monitoring-services-table-row-cell-status .health-status-badge {
  margin: var(--spacing-zero);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row:hover {
  background-color: var(--background-color-secondary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row:hover .monitoring-services-table-row-cell-services {
  cursor: pointer;
  background-color: var(--background-color-secondary);
  color: var(--content-color-link);
  text-decoration: underline;
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row:hover .monitoring-services-table-row-cell-latency {
  background-color: var(--background-color-secondary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row:hover .monitoring-services-table-row-cell-stats {
  background-color: var(--background-color-secondary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row:hover .monitoring-services-table-row-cell-error-rate {
  background-color: var(--background-color-secondary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row:hover .monitoring-services-table-row-cell-apdex {
  background-color: var(--background-color-secondary);
}
.monitoring-services-table .monitoring-services-table-success .monitoring-services-table-row-wrapper .monitoring-services-table-row:hover .monitoring-services-table-row-cell-status {
  background-color: var(--background-color-secondary);
}

.monitoring-typeswitcher {
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.monitoring-typeswitcher .plugin__workspace-view-switcher__wrapper {
  width: 165px;
  height: var(--size-m);
}
.monitoring-typeswitcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  height: 30px;
  line-height: var(--line-height-s);
  padding: var(--spacing-s) var(--spacing-m);
  box-sizing: border-box;
}
.monitoring-typeswitcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.left {
  width: 70px;
  left: 1px;
}
.monitoring-typeswitcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch.right {
  width: 95px;
  left: 69px;
}
.monitoring-typeswitcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  padding: var(--spacing-s) var(--spacing-m);
  line-height: var(--line-height-s);
  box-sizing: border-box;
}
.monitoring-typeswitcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item--left {
  flex: 0 0 70px;
  height: var(--size-m);
}
.monitoring-typeswitcher .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item--right {
  flex: 0 0 95px;
  height: var(--size-m);
}

.api-service-details-view-container-wrapper {
  display: flex;
  flex-direction: column;
}
.api-service-details-view-container-wrapper .no-monitoring-builds {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xxl);
  width: 507px;
  height: 237px;
  background: var(--background-color-primary);
}
.api-service-details-view-container-wrapper .no-monitoring-builds .no-monitoring-builds-illustration-wrapper {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-s);
}
.api-service-details-view-container-wrapper .no-monitoring-builds .no-monitoring-builds-title {
  position: static;
  height: 20px;
  left: 0px;
  right: 0px;
  top: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  text-align: center;
  letter-spacing: -0.12px;
  color: var(--content-color-primary);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: var(--spacing-s) var(--spacing-zero);
}
.api-service-details-view-container-wrapper .no-monitoring-builds .no-monitoring-builds-subtitle {
  position: static;
  height: 40px;
  left: 0px;
  right: 0px;
  top: 28px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  text-align: center;
  color: var(--content-color-secondary);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.api-service-details-view-container-wrapper .monitoring-configured-integrations > div > div {
  overflow: unset;
}
.api-service-details-view-container-wrapper .monitoring-configured-integrations-table {
  margin-left: var(--spacing-xxl);
}
.api-service-details-view-container-wrapper .monitoring-available-integrations {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--background-color-primary);
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-no-available-service {
  width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-no-available-service .monitoring-no-available-service-illustration {
  display: flex;
  width: 140px;
  height: auto;
  margin: auto;
  align-items: center;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-no-available-service .monitoring-no-available-service-content {
  width: 210px;
  margin: auto;
  display: flex;
  color: var(--content-color-secondary);
  font-family: Inter;
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  text-align: center;
  letter-spacing: -0.12px;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list {
  display: grid;
  justify-content: space-around;
  width: 100%;
  grid-column-gap: var(--spacing-xl);
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  margin-left: var(--spacing-l);
  margin-right: var(--spacing-l);
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item {
  width: 240px;
  height: 128px;
  cursor: pointer;
  border: var(--border-style-solid) var(--border-width-default) var(--border-color-strong);
  display: flex;
  box-sizing: border-box;
  padding: var(--spacing-m);
  border-radius: var(--border-radius-default);
  margin: auto;
  margin-bottom: var(--spacing-m);
  flex-direction: column;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item .monitoring-integrations-card-list-item-content-wrapper {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item .monitoring-integrations-card-list-item-content-wrapper .monitoring-integrations-card-list-item-icon-container {
  display: flex;
  height: var(--size-s);
  width: var(--size-s);
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item .monitoring-integrations-card-list-item-content-wrapper .monitoring-integrations-card-list-item-icon-container .monitoring-integrations-card-list-item-icon {
  width: 100%;
  height: auto;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item .monitoring-integrations-card-list-item-content-wrapper .monitoring-integrations-card-list-item-icon-container .monitoring-integrations-card-list-item-fallback-icon {
  width: 100%;
  height: auto;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item .monitoring-integrations-card-list-item-content-wrapper .monitoring-integrations-card-list-item-heading {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item .monitoring-integrations-card-list-item-content-wrapper .monitoring-integrations-card-list-item-description {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-xs);
  height: 50px;
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .monitoring-integrations-card-list-item .monitoring-integrations-card-list-item-content-wrapper .monitoring-integrations-card-list-item-heading-disabled {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
  margin-left: var(--spacing-s);
  color: var(--content-color-secondary);
}
.api-service-details-view-container-wrapper .monitoring-available-integrations .monitoring-integrations-card-list .list-item-disabled {
  cursor: not-allowed;
}

.api-monitoring-dashboard {
  height: -webkit-fill-available;
  align-items: center;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-dashboard-wrapper__inner {
  margin: 0 0 0 var(--spacing-s);
  overflow: auto;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper {
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: var(--background-color-primary);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .aether-dropdown__value-container {
  padding-left: 0;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .aether-dropdown__control {
  border: none !important;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .aether-dropdown__single-value {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  letter-spacing: -0.12px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .aether-dropdown__control--is-focused,
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .aether-dropdown__control--is-focused:hover {
  border: none !important;
  box-shadow: none !important;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .integrations-add-card__segment {
  margin-top: var(--spacing-zero);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .integrations-add-card__segment .integrations-add-card__input {
  padding-top: var(--spacing-zero);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .monitoring-service-selector-icon-wrapper {
  display: contents;
  align-items: center;
  padding: 6px var(--spacing-xs) 6px var(--spacing-s);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .monitoring-service-selector-icon-wrapper .aether-dropdown__menu {
  width: 300px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-service-selector .monitoring-service-selector-selected-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--content-color-primary);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar {
  display: flex;
  align-items: center;
  height: 30px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .api-monitoring-back-icon {
  display: flex;
  cursor: pointer;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .monitoring-service-selector-health-status {
  min-width: max-content;
  align-items: center;
  display: flex;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .api-monitoring-timeline-selector .integrations-add-card__segment {
  margin-top: var(--spacing-m);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .api-monitoring-timeline-selector .integrations-add-card__segment .integrations-add-card__input {
  padding-top: var(--spacing-zero);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .api-monitoring-timeline-selector .monitoring-timeline-selector-icon-wrapper {
  display: flex;
  align-items: center;
  padding: 6px var(--spacing-m) 6px var(--spacing-m);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .api-monitoring-timeline-selector .monitoring-timeline-selector-selected-item-text {
  width: 154px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--content-color-primary);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .monitoring-service-selector-icon-wrapper:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-titlebar .api-monitoring-newrelic-timeframe {
  float: right;
  margin-left: var(--spacing-m);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-wrapper .api-monitoring-more-options-icon-wrapper {
  display: flex;
  align-items: center;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-titlebar-divider {
  height: 47px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-title-wrapper {
  display: grid;
  grid-column: auto;
  grid-auto-flow: column;
  margin-top: var(--spacing-xl);
  margin-left: var(--spacing-l);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-title-wrapper .api-monitoring-chart-title {
  height: var(--size-xs);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-web-transaction-chart {
  display: grid;
  height: 447px;
  background-color: var(--background-color-tertiary);
  margin: var(--spacing-s) var(--spacing-l) var(--spacing-l) var(--spacing-l);
  align-items: center;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-web-transaction-chart .api-monitoring-web-transaction-chart-title-wrapper {
  display: flex;
  grid-area: 1/1;
  height: 431px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-web-transaction-chart .api-monitoring-web-transaction-chart-title-wrapper .api-monitoring-web-transaction-chart-title {
  font-size: var(--text-size-xxl);
  font-weight: var(--text-weight-medium);
  margin-top: var(--spacing-xxl);
  margin-left: var(--spacing-xxxl);
  z-index: 1;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-web-transaction-chart .api-monitoring-web-transaction-chart-iframe-wrapper {
  display: flex;
  align-items: center;
  grid-area: 1/1;
  height: 431px;
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  background-color: white;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-web-transaction-chart .api-monitoring-web-transaction-chart-iframe-wrapper .web-transaction-chart {
  width: 100%;
  height: 431px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-wrapper {
  display: flex;
  height: 325px;
  background-color: var(--background-color-tertiary);
  margin: var(--spacing-s) var(--spacing-l) var(--spacing-l) var(--spacing-l);
  align-items: center;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-wrapper .api-monitoring-web-throughput-chart-iframe-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 309px;
  margin-left: var(--spacing-s);
  align-items: center;
  background-color: white;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-wrapper .api-monitoring-web-throughput-chart-iframe-wrapper .throughput-chart {
  width: 100%;
  height: 309px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-wrapper .api-monitoring-web-error-rate-chart-iframe-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 309px;
  margin-left: var(--spacing-s);
  align-items: center;
  background-color: white;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-wrapper .api-monitoring-web-error-rate-chart-iframe-wrapper .error-rate-chart {
  width: 100%;
  height: 309px;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-wrapper .api-monitoring-web-apdex-chart-iframe-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 309px;
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  align-items: center;
  background-color: white;
}
.api-monitoring-dashboard .api-monitoring-dashboard-wrapper .api-monitoring-chart-wrapper .api-monitoring-web-apdex-chart-iframe-wrapper .apdex-chart {
  width: 100%;
  height: 309px;
}

.integrations-add-card__label-title {
  margin-bottom: var(--spacing-zero);
}

.integrations-add-actions__button--container {
  margin-top: var(--spacing-zero);
}

.iframe-loading-indicator {
  position: relative;
  margin-left: 50%;
}

.monitoring-menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: var(--size-m);
  min-width: 250px;
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-xs);
  color: var(--content-color-primary);
}
.monitoring-menu-item .monitoring-menu-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.monitoring-empty-dashboards {
  color: var(--content-color-secondary);
}

.popover-loading {
  display: flex;
  align-items: center;
  margin-left: 50%;
  height: var(--size-m);
  width: 250px;
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
}

.monitoring-popover-option-wrapper {
  max-height: 300px;
  overflow-y: auto;
}

.monitoring-menu-item:hover {
  background-color: var(--background-color-secondary);
}

.monitoring-service-selector-dropdown-title {
  margin-left: 23px;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  line-height: var(--line-height-xxl);
}

.monitoring-service-selector-dropdown-item {
  cursor: pointer;
  display: flex;
  min-width: 245px;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-right: var(--spacing-s);
  color: var(--content-color-primary);
}
.monitoring-service-selector-dropdown-item .monitoring-service-selector-dropdown-item-selected {
  display: flex;
  padding-right: var(--spacing-s);
  align-items: center;
  width: 16px;
}
.monitoring-service-selector-dropdown-item .monitoring-service-selector-dropdown-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.monitoring-timeline-selector-dropdown-item {
  cursor: pointer;
  display: flex;
  width: 190px;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-right: var(--spacing-s);
  color: var(--content-color-primary);
}
.monitoring-timeline-selector-dropdown-item .monitoring-timeline-selector-dropdown-item-selected {
  display: flex;
  margin-left: var(--spacing-l);
  padding-right: var(--spacing-s);
  align-items: center;
  width: 16px;
}

.monitoring-service-selector-dropdown-item:hover {
  background-color: var(--background-color-secondary);
}

.monitoring-timeline-selector-dropdown-item:hover {
  background-color: var(--background-color-secondary);
}

.bootstrap-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.add-integration-step-panel {
  padding: var(--spacing-zero);
}
.add-integration-step-panel .integrations-add-card__segment {
  margin-top: var(--spacing-zero) !important;
}

.monitor-integration-description {
  margin-bottom: var(--spacing-xl) !important;
  margin-top: var(--spacing-m) !important;
}

.api-monitoring-edit-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-secondary);
}

.api-monitoring-edit-disabled:hover {
  background-color: var(--background-color-secondary);
}

.api-monitoring-delete-disabled:hover {
  background-color: var(--background-color-secondary);
}

.api-monitoring-delete-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--red-40);
}

.alert-config-info-popover {
  display: flex;
  margin: var(--spacing-s);
  width: max-content;
  color: var(--content-color-primary);
}
.alert-config-info-popover .alert-config-info-popover-link {
  color: var(--content-color-primary);
  margin-left: var(--spacing-xs);
}

.datadog-table {
  padding-left: var(--spacing-s);
}

.apm-services-table td {
  vertical-align: middle;
}
.apm-services-table td > span {
  display: block;
}
.apm-services-table .health-status-badge {
  margin-left: var(--spacing-zero);
}

.monitoring-dashboard-links__menu__item {
  text-align: left;
}

.connected-integrations-wrapper {
  display: flex;
  flex: 0 0 30% !important;
}
.connected-integrations-wrapper .connected-integrations-action-loading {
  margin-left: var(--spacing-xs);
}
.connected-integrations-wrapper .connected-integrations-cell {
  display: flex;
  flex-wrap: wrap;
}
.connected-integrations-wrapper .connected-integrations-cell .connected-integrations-logo-wrapper {
  display: flex;
  cursor: pointer;
}
.connected-integrations-wrapper .connected-integrations-cell .connected-integrations-logo-wrapper .connected-integrations-logo {
  height: 20px;
  width: 20px;
  padding: 6px;
}

.is-connected-integrations-shrunk {
  flex: 0 0 40% !important;
}

.connected-integrations-empty-state {
  display: flex;
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}
.connected-integrations-empty-state .connected-integrations-empty-state-add-cta {
  margin-left: var(--spacing-xs);
}

.connected-integrations-content-wrapper {
  padding: var(--spacing-s) var(--spacing-s);
  color: var(--content-color-primary);
}
.connected-integrations-content-wrapper .connected-integrations-content-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-s);
  padding-bottom: var(--spacing-xs);
}
.connected-integrations-content-wrapper .connected-integrations-actions {
  display: flex;
  padding-top: var(--spacing-xs);
}
.connected-integrations-content-wrapper .connected-integrations-actions .connected-integrations-actions-edit {
  cursor: pointer;
  margin-right: var(--spacing-s);
}
.connected-integrations-content-wrapper .connected-integrations-actions .connected-integrations-actions-delete {
  cursor: pointer;
}
.connected-integrations-content-wrapper .connected-integrations-content-row {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}
.connected-integrations-content-wrapper .connected-integrations-content-row .connected-integrations-content-row-icon {
  display: flex;
  align-items: center;
}
.connected-integrations-content-wrapper .connected-integrations-content-row .connected-integrations-content-row-label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.connected-integrations-content-wrapper .connected-integrations-content-row .connected-integration-content-row-action {
  display: flex;
  margin-left: var(--spacing-xs);
  align-items: center;
  color: var(--content-color-secondary);
}
.connected-integrations-content-wrapper .connected-integrations-content-row .connected-integration-content-row-action .connected-integration-row-action-tag {
  margin-left: var(--spacing-zero) !important;
}
.connected-integrations-content-wrapper .connected-integrations-content-row .connected-integration-content-row-action .connected-integration-content-row-action-error {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-xs);
}
.connected-integrations-content-wrapper .is-hyperlink {
  cursor: pointer;
}
.connected-integrations-content-wrapper .is-action-link {
  cursor: pointer;
  color: var(--content-color-link) !important;
}
.connected-integrations-content-wrapper .is-spaced {
  padding-right: var(--spacing-xs);
}
.connected-integrations-content-wrapper .is-div {
  margin-left: var(--spacing-zero) !important;
}

.connected-integrations-tooltip {
  max-width: min-content !important;
  cursor: default;
}

.linked-monitor-runs-wrapper {
  display: flex;
  min-width: 20%;
  flex-wrap: wrap;
  margin-right: var(--spacing-m);
}
.linked-monitor-runs-wrapper .linked-monitor-runs-error {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
}
.linked-monitor-runs-wrapper .linked-monitor-runs-item .linked-monitor-runs-item-run-candle {
  cursor: pointer;
  width: 4px;
  height: 20px;
  margin-right: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  margin-top: var(--spacing-xs);
}
.linked-monitor-runs-wrapper .run-success {
  background-color: var(--content-color-success);
}
.linked-monitor-runs-wrapper .run-neutral {
  background-color: var(--content-color-tertiary);
}
.linked-monitor-runs-wrapper .run-failed {
  background-color: var(--content-color-error);
}
.linked-monitor-runs-wrapper .linked-monitor-runs-item-tooltip {
  min-width: max-content;
}
.linked-monitor-runs-wrapper .run-running {
  background-color: var(--content-color-tertiary);
  opacity: 0.8;
}
.linked-monitor-runs-wrapper .run-item-successful {
  opacity: 0.5;
}
.linked-monitor-runs-wrapper .run-item-successful:hover {
  opacity: 1;
}
.linked-monitor-runs-wrapper .run-item-errored {
  opacity: 0.2;
}
.linked-monitor-runs-wrapper .run-item-errored:hover {
  opacity: 0.4;
}
.linked-monitor-runs-wrapper .run-item-errored:focus {
  opacity: 0.8;
}

.linked-monitor-runs {
  margin-right: var(--spacing-zero) !important;
}

.is-runs-shrunk {
  min-width: 10% !important;
}

.monitor-actions {
  flex: 0 0 5% !important;
  padding-right: var(--spacing-s);
}

.linked-monitors-tooltip-wrapper {
  min-width: max-content;
  color: var(--content-color-primary);
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-date-wrapper {
  display: flex;
  align-items: center;
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-date-wrapper .linked-monitors-tooltip-status {
  margin-right: var(--spacing-xs);
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-date-wrapper .linked-monitors-tooltip-date {
  float: right;
  margin-left: 6px;
  margin-right: 6px;
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-header {
  font-weight: var(--text-weight-bold);
  font-size: var(--text-size-l);
  margin-left: 6px;
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-metrics-wrapper {
  margin-left: 6px;
  margin-right: 6px;
  margin-top: var(--spacing-s);
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-metrics-wrapper .linked-monitors-tooltip-metrics-row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 80px 80px 80px;
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-metrics-wrapper .linked-monitors-tooltip-metrics-row .linked-monitors-tooltip-metrics-row-separator {
  margin-right: 40px;
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-metrics-wrapper .linked-monitors-tooltip-metrics-row .linked-monitors-tooltip-metrics-row-value {
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-metrics-wrapper .uptime-monitors-tooltip-row {
  grid-auto-columns: 80px 10px auto !important;
}
.linked-monitors-tooltip-wrapper .linked-monitors-tooltip-table {
  overflow: hidden;
  min-width: max-content;
}

.monitor-run-button {
  margin-right: var(--spacing-s);
}
.monitor-run-button .btn-small {
  height: var(--size-s);
}

.run-monitor-btn {
  min-width: 63px;
  height: var(--size-s);
  margin-right: var(--spacing-s);
  padding: var(--spacing-s);
}

.observability-azure-dashboard-container {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-zero) var(--spacing-xxxl);
  height: 100%;
  overflow-y: auto;
}
.observability-azure-dashboard-container__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-xxl);
}
.observability-azure-dashboard-container__header__left-section {
  display: flex;
}
.observability-azure-dashboard-container__header__left-section__source {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-m);
  height: 100%;
  align-self: flex-end;
}
.observability-azure-dashboard-container__header__left-section__source__icon {
  margin: var(--spacing-zero) var(--spacing-xs);
}
.observability-azure-dashboard-container__header__right-section {
  display: flex;
  align-items: center;
}
.observability-azure-dashboard-container__header__right-section__import-button {
  margin-right: var(--spacing-xs);
}
.observability-azure-dashboard-container__content {
  margin-top: var(--spacing-xxl);
}
.observability-azure-dashboard-container__content__source {
  margin-bottom: var(--spacing-l);
}

.observability-azure-services-table tbody tr td {
  padding: var(--spacing-zero) var(--spacing-s);
}
.observability-azure-services-table tbody tr td .azure-custom-cell {
  height: 44px;
  display: flex;
  align-items: center;
}
.observability-azure-services-table tbody tr td .azure-custom-cell__no-source {
  color: var(--content-color-tertiary);
  margin-right: var(--spacing-s);
}
.observability-azure-services-table tbody tr td .azure-custom-cell__ip-address {
  color: var(--content-color-secondary);
  margin-left: var(--spacing-s);
}
.observability-azure-services-table__link__modal {
  max-width: 480px;
  margin: auto;
}
.observability-azure-services-table__link__modal__title > h2 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.observability-azure-services-table__link__modal__content {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
  overflow: visible;
}
.observability-azure-services-table__link__modal__footer {
  margin-top: var(--spacing-xxxl);
}

.observability-azure-table-container {
  margin: var(--spacing-xxl) var(--spacing-zero);
}
.observability-azure-table-container__heading {
  margin-bottom: var(--spacing-s);
}

.observability-detailed-dashboard-container {
  height: 100%;
}

.observability-azure-detailed-dashboard-container {
  padding: var(--spacing-xxl) var(--spacing-xxxl) var(--spacing-zero) var(--spacing-xxxl);
  height: 90%;
  overflow-y: auto;
}
.observability-azure-detailed-dashboard-container__header {
  border-bottom: var(--border-style-solid) var(--border-width-default);
  border-color: var(--border-color-strong);
  padding: var(--spacing-s) var(--spacing-s);
}
.observability-azure-detailed-dashboard-container__header__back {
  display: flex;
  align-items: center;
}
.observability-azure-detailed-dashboard-container__header__services {
  width: 178px;
  margin-left: var(--spacing-xs);
}
.observability-azure-detailed-dashboard-container__header__refresh {
  margin: var(--spacing-zero) var(--spacing-xs) !important;
}
.observability-azure-detailed-dashboard-container__header__import {
  margin-left: var(--spacing-xs);
}
.observability-azure-detailed-dashboard-container__integration-name {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.observability-azure-detailed-dashboard-container__status {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  margin-left: var(--spacing-l);
}
.observability-azure-detailed-dashboard-container__label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  margin-left: var(--spacing-l);
}
.observability-azure-detailed-dashboard-container__label__value {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-xs);
}
.observability-azure-detailed-dashboard-container__row2 {
  margin-top: var(--spacing-l);
}
.observability-azure-detailed-dashboard-container__row2__label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  margin-left: var(--spacing-l);
}
.observability-azure-detailed-dashboard-container__row2__label__value {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-xs);
}
.observability-azure-detailed-dashboard-container__row2__api {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.observability-azure-detailed-dashboard-container__row2__portal {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-l);
}

.postman-source-error__message {
  color: var(--content-color-error);
}
.postman-source-error__link {
  color: var(--content-color-error);
}
.postman-source-error__link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.observability-azure-add-integration__title__description {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-l);
}
.observability-azure-add-integration__nickname {
  margin-top: var(--spacing-xl);
  font-size: var(--text-size-l);
}
.observability-azure-add-integration__service {
  font-weight: var(--text-weight-medium);
  margin-top: var(--spacing-l);
}
.observability-azure-add-integration__service__workspace-selectors {
  margin-top: var(--spacing-l);
}
.observability-azure-add-integration__service__component {
  margin-bottom: var(--spacing-l);
}
.observability-azure-add-integration__service__section {
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.observability-azure-add-integration__service__section__container {
  margin-top: var(--spacing-l);
  border-bottom: var(--border-style-solid) var(--border-width-default);
  border-color: var(--border-color-strong);
}
.observability-azure-add-integration__service__section__header {
  margin-left: var(--spacing-xs);
}
.observability-azure-add-integration__service__section__header__link {
  cursor: pointer;
}
.observability-azure-add-integration__service__section__setup-service {
  margin-bottom: var(--spacing-l);
}
.observability-azure-add-integration__service__section__remove {
  display: flex;
}
.observability-azure-add-integration__buttons {
  margin-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xxl);
}
.observability-azure-add-integration__buttons__cancel {
  margin-left: var(--spacing-m);
}
.observability-azure-add-integration__buttons__add-service {
  margin-top: var(--spacing-l);
}

.azure-current-revision-modal-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.highlight-dropdown-options {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}

.gateway-import-schema-form-container {
  width: 100%;
  height: 100%;
}
.gateway-import-schema-form-container__segment {
  margin-top: var(--spacing-xl);
}

.observability-import-schema-modal__content {
  overflow-y: unset !important;
  padding-bottom: var(--spacing-m) !important;
}

.observability-service-details-view-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.observability-service-details-view-container-wrapper .no-observability-deployments {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 507px;
  background: var(--background-color-primary);
}
.observability-service-details-view-container-wrapper .no-observability-deployments .no-observability-deployments-title {
  position: static;
  height: 20px;
  left: var(--spacing-zero);
  right: var(--spacing-zero);
  top: var(--spacing-zero);
  font-family: Inter;
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  text-align: center;
  letter-spacing: -0.12px;
  color: var(--content-color-primary);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: var(--spacing-s) var(--spacing-zero);
}
.observability-service-details-view-container-wrapper .no-observability-deployments .no-observability-deployments-subtitle {
  position: static;
  height: 40px;
  max-width: 448px;
  left: var(--spacing-zero);
  right: var(--spacing-zero);
  top: 28px;
  font-family: Inter;
  font-style: normal;
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  text-align: center;
  color: var(--content-color-secondary);
  flex: none;
  order: 1;
  flex-grow: 0;
}
.observability-service-details-view-container-wrapper .observability-available-integrations {
  display: flex;
  width: 720px;
  margin-top: var(--spacing-l);
  background: var(--background-color-primary);
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-no-available-service {
  width: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-no-available-service .observability-no-available-service-illustration {
  display: flex;
  width: 140px;
  height: auto;
  margin: auto;
  align-items: center;
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-no-available-service .observability-no-available-service-content {
  width: 210px;
  margin: auto;
  display: flex;
  color: var(--content-color-secondary);
  font-family: Inter;
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  text-align: center;
  letter-spacing: -0.12px;
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list {
  width: 720px;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(180px, auto));
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list .observability-integrations-card-list-item {
  width: fit-content;
  cursor: pointer;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--spacing-s) var(--spacing-m);
  border-radius: var(--border-radius-default);
  color: var(--content-color-primary);
  margin-right: var(--spacing-s);
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list .observability-integrations-card-list-item .observability-integrations-card-list-item-content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list .observability-integrations-card-list-item .observability-integrations-card-list-item-content-wrapper .observability-integrations-card-list-item-icon-container {
  display: flex;
  align-items: center;
  height: var(--size-xs);
  width: var(--size-xs);
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list .observability-integrations-card-list-item .observability-integrations-card-list-item-content-wrapper .observability-integrations-card-list-item-icon-container .observability-integrations-card-list-item-icon {
  width: 100%;
  height: auto;
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list .observability-integrations-card-list-item .observability-integrations-card-list-item-content-wrapper .observability-integrations-card-list-item-icon-container .observability-integrations-card-list-item-fallback-icon {
  width: 100%;
  height: auto;
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list .observability-integrations-card-list-item .observability-integrations-card-list-item-content-wrapper .observability-integrations-card-list-item-heading {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-regular);
  margin-left: var(--spacing-s);
}
.observability-service-details-view-container-wrapper .observability-available-integrations .observability-integrations-card-list .list-item-disabled {
  cursor: not-allowed;
  color: var(--content-color-secondary);
}

.observability-deployments-page-container {
  overflow-x: hidden;
  align-items: center;
  margin-left: calc(var(--spacing-xxl) + var(--spacing-m));
  margin-right: calc(var(--spacing-xxl) + var(--spacing-m));
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-gateway-title-wrapper .observability-gateway-dashboard-link {
  cursor: pointer;
  display: flex;
  float: right;
  margin-left: var(--spacing-xs);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.observability-deployments-page-container .observability-gateway-title-wrapper .observability-gateway-dashboard-link__refresh {
  padding-right: var(spacing-xxl);
}
.observability-deployments-page-container .observability-gateway-title-wrapper .observability-gateway-external-link {
  display: flex;
}
.observability-deployments-page-container .observability-gateway-title-wrapper .observability-gateway-deploy-cta {
  right: 0;
  display: flex;
  align-items: center;
  float: right;
  margin-left: auto;
  min-width: max-content;
  box-sizing: border-box;
}
.observability-deployments-page-container .observability-gateway-title-wrapper .observability-gateway-import-cta {
  margin-left: var(--spacing-m);
}
.observability-deployments-page-container .observability-gateway-title-wrapper .observability-gateway-refresh-cta {
  display: flex;
  align-items: center;
  cursor: pointer;
  float: right;
  margin-left: var(--spacing-xs);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.observability-deployments-page-container .observability-gateway-title-wrapper .observability-gateway-more-options {
  display: flex;
  cursor: pointer;
  float: right;
  margin-right: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.observability-deployments-page-container .observability-stages-empty-state {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-s);
}
.observability-deployments-page-container .observability-stage-wrapper {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-xl);
}
.observability-deployments-page-container .observability-stage-wrapper .observability-stage-dropdown {
  height: var(--size-m);
  width: 240px;
  margin-left: var(--spacing-l);
}
.observability-deployments-page-container .observability-stage-wrapper .observability-stage-dropdown .integrations-add-card__segment {
  height: var(--size-m);
  margin-top: var(--spacing-zero);
}
.observability-deployments-page-container .observability-stage-wrapper .observability-stage-dropdown .integrations-add-card__segment .integrations-add-card__label-title {
  display: none;
}
.observability-deployments-page-container .observability-stage-wrapper .observability-stage-dropdown .integrations-add-card__segment .integrations-add-card__label-description {
  display: none;
}
.observability-deployments-page-container .observability-stage-wrapper .observability-stage-dropdown .integrations-add-card__segment .integrations-add-card__input {
  padding-top: var(--spacing-zero);
}
.observability-deployments-page-container .observability-stage-wrapper .observability-stage-active-deployment-id {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  float: right;
  text-align: right;
  margin-left: auto;
  right: 0;
  width: 135px;
}
.observability-deployments-page-container .observability-stage-wrapper .observability-stage-status {
  float: right;
  margin-left: var(--spacing-m);
  right: 0;
  margin-right: var(--spacing-l);
  width: 135px;
  height: 24px;
}
.observability-deployments-page-container .observability-stage-dropdown-wrapper {
  background-color: var(--background-color-secondary);
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}
.observability-deployments-page-container .observability-gateway-url {
  display: flex;
  width: 100%;
  margin-left: var(--spacing-l);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  padding-top: var(--spacing-m);
  color: var(--content-color-link);
}
.observability-deployments-page-container .observability-gateway-url .observability-gateway-url-title {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
}
.observability-deployments-page-container .observability-gateway-url__link {
  cursor: pointer;
}
.observability-deployments-page-container .observability-gateway-description {
  width: 630px;
  height: fit-content;
  margin-left: var(--spacing-l);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-l);
  padding-top: var(--spacing-m);
  color: var(--content-color-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
}
.observability-deployments-page-container .observability-gateway-deployments-separator {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-top: var(--spacing-xl);
}
.observability-deployments-page-container .observability-gateway-deployments-table-title-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: var(--spacing-xxl);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-s);
}
.observability-deployments-page-container .observability-gateway-deployments-table-title-wrapper .observability-gateway-deployments-table-title {
  font-weight: var(--text-weight-medium);
}
.observability-deployments-page-container .observability-gateway-deployments-table-view-all {
  display: flex;
  align-items: center;
  width: 100%;
}
.observability-deployments-page-container .observability-gateway-deployments-table-view-all .observability-gateway-deployments-table-view-all-btn {
  cursor: pointer;
  width: 178px;
  text-align: center;
  height: var(--size-m);
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-xxl);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
}
.observability-deployments-page-container .observability-gateway-logs-wrapper {
  display: flex;
  width: 100%;
  padding-top: var(--spacing-m);
  margin-left: var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.observability-deployments-page-container .observability-gateway-logs-wrapper .observability-gateway-access-logs {
  color: var(--content-color-link);
  cursor: pointer;
  margin-bottom: var(--spacing-l);
}
.observability-deployments-page-container .observability-gateway-logs-wrapper .observability-gateway-execution-logs {
  margin-left: var(--spacing-xl);
}
.observability-deployments-page-container .observability-gateway-logs-wrapper .observability-gateway-updated-at {
  float: right;
  margin-left: auto;
}
.observability-deployments-page-container .observability-gateway-logs-wrapper .observability-gateway-created-at {
  margin-left: var(--spacing-xl);
  float: right;
  margin-right: 18px;
  padding-right: 18px;
}
.observability-deployments-page-container .observability-deployments-table {
  margin: var(--spacing-s) var(--spacing-zero) var(--spacing-l) var(--spacing-zero);
  overflow-x: auto;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success {
  min-width: max-content;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header {
  display: flex;
  position: static;
  height: 33px;
  width: 100%;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-sizing: border-box;
  min-width: max-content;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header:last-child {
  flex-basis: 228px;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-dropdown {
  min-width: var(--size-m);
  box-sizing: border-box;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-id {
  width: 20%;
  min-width: 130px;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-id_short-col {
  width: 15%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-generic {
  display: flex;
  flex: 1;
  min-width: 130px;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-generic__info {
  cursor: pointer;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-generic:last-child {
  flex: 0;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-description {
  width: 55%;
  float: left;
  padding: var(--spacing-s);
  min-width: 240px;
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-description_short-col {
  width: 40%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-date {
  float: left;
  width: 25%;
  min-width: 125px;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-date_short-col {
  width: 20%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-source {
  float: left;
  width: 30%;
  padding: var(--spacing-s);
  min-width: 215px;
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-header .observability-deployments-table-header-cell-source_short-col {
  width: 25%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper {
  min-width: max-content;
  box-sizing: border-box;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row {
  display: flex;
  position: static;
  align-items: center;
  font-size: var(--text-size-m);
  height: fit-content;
  min-height: 36px;
  width: 100%;
  box-sizing: border-box;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-stage-link {
  visibility: hidden;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover .observability-deployments-table-row-cell-stage-link {
  visibility: visible;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-id {
  width: 20%;
  min-width: 130px;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-id_short-col {
  width: 15%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-dropdown {
  min-width: var(--size-m);
  box-sizing: border-box;
  cursor: pointer;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-generic {
  display: flex;
  flex: 1;
  min-width: 130px;
  float: left;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-description {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 55%;
  min-width: 240px;
  min-height: 36px;
  padding: var(--spacing-zero) var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-description_short-col {
  width: 40%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-date {
  float: left;
  width: 25%;
  min-width: 125px;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-date_short-col {
  width: 20%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-source {
  float: left;
  width: 30%;
  min-width: 215px;
  padding: var(--spacing-s);
  background: var(--background-color-primary);
  color: var(--content-color-primary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row .observability-deployments-table-row-cell-source_short-col {
  width: 25%;
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .is-closed {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover {
  background-color: var(--background-color-secondary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover .observability-deployments-table-row-cell-id {
  background-color: var(--background-color-secondary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover .observability-deployments-table-row-cell-dropdown {
  background-color: var(--background-color-secondary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover .observability-deployments-table-row-cell-description {
  background-color: var(--background-color-secondary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover .observability-deployments-table-row-cell-date {
  background-color: var(--background-color-secondary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover .observability-deployments-table-row-cell-source {
  background-color: var(--background-color-secondary);
}
.observability-deployments-page-container .observability-deployments-table .observability-deployments-table-success .observability-deployments-table-row-wrapper .observability-deployments-table-row:hover .observability-deployments-table-row-cell-generic {
  background-color: var(--background-color-secondary);
}
.observability-deployments-page-container .observability-deployments-table-sourced .observability-deployments-table-header, .observability-deployments-page-container .observability-deployments-table-sourced .observability-deployments-table-row {
  padding-left: var(--spacing-xxl);
}
.observability-deployments-page-container .delete-option {
  color: var(--content-color-error);
}
.observability-deployments-page-container .observability-gateway-postman-exports-container {
  margin-top: var(--spacing-xxl);
  max-width: fit-content;
}
.observability-deployments-page-container .observability-gateway-postman-exports-container__content__empty {
  margin-top: var(--spacing-s);
}
.observability-deployments-page-container .observability-gateway-postman-exports-container__content__table tr td, .observability-deployments-page-container .observability-gateway-postman-exports-container__content__table th {
  padding: var(--spacing-m) var(--spacing-s);
}
.observability-deployments-page-container .observability-gateway-banner {
  margin-top: var(--spacing-l);
}

.observability-edit-integration {
  cursor: pointer;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-primary);
}

.observability-edit-integration-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-secondary);
}

.observability-edit-integration:hover {
  background-color: var(--highlight-background-color-primary);
}

.observability-delete-integration {
  cursor: pointer;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--content-color-error);
}

.observability-delete-integration-disabled {
  cursor: not-allowed;
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  color: var(--red-40);
}

.observability-delete-integration:hover {
  background-color: var(--background-color-secondary);
}

.observability-delete-integration-disabled:hover {
  background-color: var(--background-color-secondary);
}

.observability-gateway-more-options-icon-wrapper {
  display: flex;
  align-items: center;
}

.observability-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
}

.observability-error {
  margin: auto;
  color: var(--content-color-primary);
}

.observability-deployments-id {
  margin-right: 2px;
}

.observability-collapsed-content {
  margin-left: var(--spacing-xs);
  padding-left: 3%;
}

.aws-gateway-dropdown .integrations-add-card__segment .Select--single.pm-select .Select-menu-outer {
  max-height: 100px;
}
.aws-gateway-dropdown .integrations-add-card__segment .Select--single.pm-select .Select-menu-outer .Select-menu {
  max-height: 98px;
}

.observability-modal__cancel-button {
  margin-right: var(--spacing-m);
}

.observability-gateway-deploy-cta__menu {
  margin-top: var(--spacing-s);
  min-width: 200px;
}

.observability-onboarding-tooltip-container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-m);
}
.observability-onboarding-tooltip-container__title {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-zero) var(--spacing-xs);
}
.observability-onboarding-tooltip-container__content {
  padding: var(--spacing-zero) var(--spacing-xs);
}
.observability-onboarding-tooltip-container__button {
  margin-top: var(--spacing-l);
}

.observability-gateway-deploy-cta__menu-heading {
  margin-bottom: var(--spacing-s);
  padding-top: var(--spacing-xs);
  padding-right: var(--spacing-s);
}

.observability-gateway-deploy-cta__menu-info {
  padding: var(--spacing-xs);
}

.deploy-page-container {
  height: 100%;
}
.deploy-page-container__content {
  overflow-y: auto !important;
}
.deploy-page-container__content .integrations-add {
  width: unset;
  max-width: 500px;
}
.deploy-page-container__header {
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-s) var(--spacing-l);
}

.connect-api-gateway-tab {
  overflow: auto;
  padding: var(--spacing-xxl);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.connect-api-gateway-tab .api-gateway-connecting {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xxxl);
}
.connect-api-gateway-tab .api-gateway-connecting__illustration_wrapper {
  background-color: var(--grey-00);
  width: 60px;
  height: 60px;
  border-radius: var(--border-radius-default);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-m);
}
.connect-api-gateway-tab .api-gateway-connecting__illustration__api_gateway_apigee {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/apigee.svg");
}
.connect-api-gateway-tab .api-gateway-connecting__illustration__api_management_azure {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/azure-apim.png");
}
.connect-api-gateway-tab .api-gateway-connecting__text-primary {
  margin-bottom: var(--spacing-l);
  font-size: var(--text-size-xxl);
  font-weight: var(--text-weight-bold);
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
}
.connect-api-gateway-tab .api-gateway-connecting__text-secondary {
  font-size: var(--text-size-l);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xl);
}
.connect-api-gateway-tab .api-gateway-connecting__cancel-btn {
  background-color: var(--background-color-tertiary);
}
.connect-api-gateway-tab .api-gateway-connecting__cancel-btn:hover {
  background-color: var(--highlight-background-color-tertiary);
}

.connect-apigee-gateway__container {
  margin-top: var(--spacing-xl);
  display: flex;
  justify-content: center;
}
.connect-apigee-gateway__form {
  margin-top: var(--spacing-s);
  width: 440px;
}
.connect-apigee-gateway__form__edit {
  width: 440px;
}
.connect-apigee-gateway__form__browse {
  margin-top: var(--spacing-l);
}
.connect-apigee-gateway__form--title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-l);
  color: var(--content-color-primary);
}
.connect-apigee-gateway__form--description {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-xl);
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}
.connect-apigee-gateway__form--component {
  margin-bottom: var(--spacing-l);
}
.connect-apigee-gateway__form--buttons {
  margin-top: var(--spacing-xl);
  float: right;
}
.connect-apigee-gateway__form--buttons--update {
  float: right;
  margin-bottom: var(--spacing-xxxl);
}
.connect-apigee-gateway__form--entity-selector {
  opacity: 0.4;
}
.connect-apigee-gateway__stepper {
  width: 440px;
}
.connect-apigee-gateway__stepper__description {
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xl);
  margin-top: var(--spacing-l);
}
.connect-apigee-gateway__stepper__authorize__description {
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-xs);
}
.connect-apigee-gateway__stepper__authorize__input {
  margin-top: var(--spacing-s);
}
.connect-apigee-gateway__stepper__authorize__service-account {
  margin-top: var(--spacing-xl);
}
.connect-apigee-gateway__stepper__authorize__private-key {
  margin-top: var(--spacing-l);
}
.connect-apigee-gateway__stepper__authorize__text {
  font-size: var(--text-size-s);
}

.apigee-dashboard-container {
  padding: var(--spacing-xxl) var(--spacing-xxxl) var(--spacing-xxxl) var(--spacing-xxxl);
}
.apigee-dashboard-container__header {
  display: flex;
  flex-direction: row;
}
.apigee-dashboard-container__header__row2 {
  margin-top: 6px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  flex-wrap: wrap;
}
.apigee-dashboard-container__header__integration-name {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.apigee-dashboard-container__header__api-proxy-id {
  margin-left: var(--text-size-xl);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
}
.apigee-dashboard-container__header__api-metrics {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.apigee-dashboard-container__header__options__delete__modal {
  max-width: 480px;
  margin: auto;
}
.apigee-dashboard-container__header__options__delete__modal__title > h2 {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.apigee-dashboard-container__header__options__delete__modal__content {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
}
.apigee-dashboard-container__header__source {
  margin-left: var(--spacing-l);
}
.apigee-dashboard-container__header__source__text {
  font-weight: var(--text-weight-medium);
  padding-right: var(--spacing-xs);
}
.apigee-dashboard-container__header__latest-revision__text {
  font-weight: var(--text-weight-medium);
  padding-right: var(--spacing-xs);
}
.apigee-dashboard-container__header__latest-revision__id {
  color: var(--content-color-secondary);
}
.apigee-dashboard-container__header__created-at__text {
  font-weight: var(--text-weight-medium);
  padding-right: var(--spacing-xs);
}
.apigee-dashboard-container__header__last-updated__text {
  font-weight: var(--text-weight-medium);
  padding-right: var(--spacing-xs);
  padding-left: var(--spacing-m);
}
.apigee-dashboard-container__content__deployments {
  margin-top: var(--spacing-xx);
}
.apigee-dashboard-container__content__deployments__heading {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.apigee-dashboard-container__content__deployments__heading--overview {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.apigee-dashboard-container__content__deployments__heading--overview__view-all {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  cursor: pointer;
}
.apigee-dashboard-container__content__deployments__table {
  margin-top: var(--spacing-s);
}
.apigee-dashboard-container__content__deployments__table--overview {
  margin-top: var(--spacing-l);
}
.apigee-dashboard-container__content__deployments__no-data {
  margin-top: var(--spacing-l);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.apigee-dashboard-container__content__proxy-endpoints {
  margin-top: var(--spacing-xxl);
}
.apigee-dashboard-container__content__proxy-endpoints__heading {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.apigee-dashboard-container__content__proxy-endpoints__table {
  margin-top: var(--spacing-s);
}
.apigee-dashboard-container__content__proxy-endpoints__loading {
  margin-top: var(--spacing-xl);
  margin-left: var(--spacing-l);
}
.apigee-dashboard-container__content__proxy-endpoints__no-data {
  margin-top: var(--spacing-l);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.apigee-dashboard-container__content__environments {
  margin-top: var(--spacing-xxl);
}
.apigee-dashboard-container__content__environments__heading {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  font-size: var(--text-size-xl);
  line-height: var(--line-height-m);
}
.apigee-dashboard-container__content__environments__table {
  margin-top: var(--spacing-xl);
}
.apigee-dashboard-container__content__environments__table__status {
  position: relative;
}
.apigee-dashboard-container__content__environments__table__status__icon {
  position: absolute;
  padding-top: 2px;
  padding-left: var(--spacing-xs);
}
.apigee-dashboard-container__content__environments__table__status__badge {
  margin-left: var(--spacing-zero);
}
.apigee-dashboard-container__content__environments__loading {
  margin-top: var(--spacing-xl);
  margin-left: var(--spacing-l);
}
.apigee-dashboard-container__content__environments__no-data {
  margin-top: var(--spacing-xl);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.apigee-dashboard-container__content__revision {
  cursor: pointer;
  text-decoration: underline;
}
.apigee-dashboard-container__content__revision:hover {
  color: var(--content-color-link);
}

.apigee-overview-tab {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-l);
}
.apigee-overview-tab__heading {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.apigee-overview-tab__heading__view-all {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  cursor: pointer;
}
.apigee-overview-tab__table {
  margin-top: var(--spacing-l);
}
.apigee-overview-tab__no-data {
  margin-top: var(--spacing-l);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.apigee-overview-tab__loading {
  margin-top: var(--spacing-l);
  margin-left: var(--spacing-l);
}

.dropdown-icon-remove .aether-dropdown__dropdown-indicator {
  display: none;
}

.dropdown-retry {
  text-decoration: underline;
  cursor: pointer;
  margin-left: var(--spacing-xs);
}

.loading-indicator-aether__dropdown {
  margin-right: var(--spacing-s);
}

.loading-indicator-import__dropdown {
  margin-right: var(--spacing-l);
}

.import-api-gateway__form {
  margin-top: var(--spacing-l);
}
.import-api-gateway__form--component {
  margin-bottom: var(--spacing-l);
}
.import-api-gateway__form--component__aws-account-id {
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
.import-api-gateway__form--last-component {
  margin-bottom: var(--spacing-zero);
}

.observability-deployments-table-row-cell-stage-link {
  flex: 0 !important;
}

.deploy-tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.deploy-form-container__segment {
  margin-top: var(--spacing-l);
}
.deploy-form-container__actions-segment {
  margin-top: var(--spacing-xl);
}
.deploy-form-container__description {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-xl);
}

.observability-dropdown-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background-color: var(--background-color-primary);
  padding: var(--spacing-s) var(--spacing-m);
}
.observability-dropdown-option__name {
  margin-left: var(--spacing-s);
}

.observability-dropdown-option:hover {
  background-color: var(--background-color-tertiary);
}

.gateway-options-release-view-container {
  min-height: var(--size-m);
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gateway-options-release-view-container__connect-gateway-heading {
  padding: var(--spacing-m);
}
.gateway-options-release-view-container__connected-gateway-button {
  max-width: 226px;
}
.gateway-options-release-view-container__connected-menu {
  width: 223px;
}
.gateway-options-release-view-container__connected-menu__info {
  margin-bottom: var(--spacing-s);
}
.gateway-options-release-view-container__connected-menu__deploy-button {
  width: 100%;
  height: var(--size-m);
  display: flex;
  align-items: center;
  padding-left: var(--spacing-xs);
  cursor: pointer;
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
}
.gateway-options-release-view-container__connected-menu__deploy-button:hover {
  background-color: var(--background-color-tertiary);
}

.deployments-overview-container {
  margin-top: var(--spacing-xxl);
}
.deployments-overview-container__header {
  margin-bottom: var(--spacing-m);
}
.deployments-overview-container__content__empty-text {
  margin-left: var(--spacing-s);
}
.deployments-overview-container__content__table tr td, .deployments-overview-container__content__table th {
  padding: var(--spacing-m) var(--spacing-s);
}

.postman-source-item-container {
  display: flex;
}
.postman-source-item-container__icon {
  margin-right: var(--spacing-xs);
}

.aws-cloudwatch__container {
  height: auto;
  min-height: 286px;
  width: 50%;
  min-width: 412px;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-l);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.aws-cloudwatch__container__main {
  height: 100%;
  overflow-y: scroll;
}
.aws-cloudwatch__container .metrics__graph {
  margin: var(--spacing-l);
}
.aws-cloudwatch__container .metrics__title {
  height: 20px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  text-align: center;
}
.aws-cloudwatch__container__empty {
  width: 50%;
  border: none;
  padding: var(--spacing-l);
}
.aws-cloudwatch__detailed-view {
  margin: var(--spacing-xl);
  margin-top: 64px;
}
.aws-cloudwatch__tooltip {
  cursor: pointer;
}
.aws-cloudwatch__checkbox {
  margin-top: var(--spacing-l);
}

.observability-aws-cloudwatch-container__header {
  border-bottom: var(--border-style-solid) var(--border-width-default);
  border-color: var(--border-color-strong);
  padding-right: var(--spacing-l);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  position: fixed;
  width: 100%;
  background: var(--background-color-primary);
}
.observability-aws-cloudwatch-container__header__back {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: var(--spacing-s);
}
.observability-aws-cloudwatch-container__header__stages {
  width: 178px;
  margin-left: var(--spacing-xs);
}
.observability-aws-cloudwatch-container__header__refresh {
  padding-right: var(--spacing-xxl);
}

.ci-cd-modal__cancel-button {
  margin-right: var(--spacing-m);
}

.pm-proxy-session-config-wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: var(--spacing-m);
  padding-top: var(--spacing-l);
  padding-right: var(--spacing-l);
}

.pm-proxy-session-config-body-wrapper {
  width: 100%;
  height: calc(100% - 20px);
}

.pm-proxy-session-config-body {
  width: 100%;
  height: 100%;
}

.pm-proxy-config-body-tabs {
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-top: var(--spacing-s);
  padding-right: 0;
  padding-bottom: 0;
}

.pm-proxy-config-body-tab-panels {
  width: 100%;
  height: calc(100% - 128px);
  display: flex !important;
  justify-content: center;
  overflow-y: auto;
}

.pm-proxy-config-body-tab-panels-proxy-panel {
  height: 100%;
  width: 40%;
}

.pm-proxy-panel-wrapper {
  width: 480px;
  max-width: 480px;
  margin: auto;
}

.pm-proxy-capture-button {
  margin-top: var(--spacing-l);
  bottom: var(--spacing-l);
  width: 30%;
}

.pm-interceptor-capture-button {
  bottom: var(--spacing-l);
  margin-top: var(--spacing-s);
}

.button-container {
  position: fixed;
  bottom: 0;
  width: 1124px;
  height: 64px;
  z-index: 100;
}

.pm-capture-body-container {
  margin: calc(-1 * var(--spacing-l));
}

.pm-capture-panel-header {
  padding-left: var(--spacing-zero);
  font-size: var(--size-xs);
  font-weight: 600;
  line-height: var(--size-s);
  color: var(--content-color-primary);
}

.pm-proxy-port-settings-tab {
  width: 40%;
}

.pm-proxy-tabs {
  padding-top: var(--spacing-zero);
}

.pm-clear-requests-button {
  width: 150px;
}

.pm-session-cookie-table-wrapper {
  margin-top: calc(-1 * (var(--spacing-xs) + var(--spacing-xl)));
}

.pm-proxy-session-landing-wrapper {
  height: 100%;
  width: 100%;
}

.pm-proxy-session-landing-header {
  position: absolute;
  width: 100%;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.pm-proxy-session-landing-body-content {
  margin: calc(2 * var(--spacing-xxl)) var(--spacing-zero);
}

.pm-proxy-session-landing-body {
  height: 100%;
  width: 100%;
  margin-top: 15vh;
  overflow-y: scroll;
}

.pm-proxy-session-capture-button {
  margin-left: calc(-1 * (var(--spacing-xxl)));
  bottom: var(--spacing-l);
}

.pm-proxy-session-landing-body-content-item-title {
  padding-top: var(--spacing-xxl);
}

.pm-proxy-session-landing-body-content-item-description {
  max-width: 420px;
}

.pm-proxy-session-landing-body-content-logo {
  padding-right: var(--spacing-s);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.pm-proxy-session-container {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: var(--spacing-l);
  height: 100%;
  width: 100%;
}

.proxy-session-header {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-m) var(--spacing-l);
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-zero) !important;
}

.pm-proxy-session-configuration {
  padding: var(--spacing-m) var(--spacing-l) var(--spacing-s) var(--spacing-l);
  margin-bottom: var(--spacing-zero) !important;
}

.pm-live-proxy-side-pane {
  padding-left: var(--spacing-s);
}

.pm-live-proxy-expanded-view__pane {
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l) var(--spacing-xs) var(--spacing-s) var(--spacing-l) !important;
  height: calc(100vh - 313px);
  overflow: scroll;
}

.pm-live-proxy-side-pane {
  padding-left: 8px;
}

.pm-live-proxy-expanded-view__pane {
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l) var(--spacing-xs) var(--spacing-s) var(--spacing-l) !important;
  height: calc(100vh - 313px);
  overflow: scroll;
}

.pm-proxy-session-header-top {
  padding-top: var(--spacing-s) !important;
}

.apply-bottom-border {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding-bottom: var(--spacing-l) !important;
}

.pm-live-sessions-requests-tab {
  width: 100%;
  height: 100%;
}
.pm-live-sessions-requests-tab .pane-group .pane-container .pane-resize-handle.ns {
  opacity: 0;
}

.pm-search-text-input {
  width: 20%;
}

.filter-button {
  padding-bottom: var(--spacing-s);
}

.pm-proxy-session-tabs {
  margin-top: calc(-1 * var(--spacing-s));
}

.proxy-session-name-input {
  min-width: var(--size-xs);
  margin-right: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
}

.fix-bottom {
  display: flex;
  position: fixed;
  bottom: 10px;
  width: 100%;
  height: 64px;
}

.fix-right {
  display: flex;
  position: absolute;
  padding-top: --var(spacing-xs);
  right: var(--spacing-l);
}

.session-tab-footer-right-section {
  display: flex;
  padding-right: var(--spacing-xxxl);
  padding-top: var(--spacing-s);
}

.session-tab-new-footer-right-section {
  display: flex;
  padding-right: var(--spacing-xs);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
}

.proxy-session-table tbody tr td {
  padding: 0 !important;
  vertical-align: middle !important;
  height: 24px;
}

.proxy-session-table thead th {
  height: 16px;
}

.proxy-session-table thead th:first-child > div {
  width: 13px;
  height: 13px;
}

.proxy-session-table tbody tr td [class^=Table__StyledCheckboxWrapper] {
  margin: var(--spacing-zero) var(--spacing-s) !important;
}

.session-tab-footer-right-section-time {
  padding-right: var(--spacing-s);
  font-family: "IBMPlexMono";
  font-size: var(--text-size-m);
  line-height: 20px;
  letter-spacing: 0px;
  color: #6B6B6B;
}

.apply-border-right {
  border-right: 1px solid var(--border-color-default);
}

.session-tab-footer-right-section-size {
  padding-left: var(--spacing-s);
  margin-right: var(--spacing-l);
  font-family: "IBMPlexMono";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #6B6B6B;
}

.pm-proxy-session-footer {
  z-index: 100;
}

.pm-proxy-session-footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  z-index: 100;
  left: 0;
  padding-left: 20px;
  border-top: 1px solid var(--border-color-default);
}

.apply-light-background {
  background: #fff;
}

.apply-dark-background {
  background: #212121;
}

.pm-session-table-wrapper {
  position: fixed;
  bottom: 64px;
  top: 194px;
  left: 20px;
  right: 20px;
  z-index: 200;
}

.pm-table-url {
  width: 100%;
  padding-right: var(--spacing-xs);
}

.pm-proxy-configure-url-popover {
  padding-top: 6px;
  padding-left: var(--spacing-m);
  cursor: pointer;
}

.pm-active-session-tab-panels {
  height: calc(100% - 40px);
  width: 100%;
}
.pm-active-session-tab-panels .pm-active-session-tab-panels-tab-panel {
  position: relative;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.pm-post-proxy-session-page {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: var(--spacing-l);
  height: 100%;
  width: 100%;
}

.pm-post-proxy-session-page-header {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-l) !important;
  display: flex !important;
}

.pm-post-proxy-session-details {
  height: var(--size-m);
}

.pm-proxy-session-tabs {
  height: calc(100% - 40px);
  width: 100%;
}

.pm-proxy-overview-page-tabs {
  height: calc(100% - 36px);
}

.pm-proxy-overview-page-tab-panels {
  width: 100%;
  height: calc(100% - 66px);
}

.pm-proxy-overview-page-tab-panels_tab-panel {
  width: 100%;
  height: 100%;
}

.pm-post-sessions-requests-tab {
  width: 100%;
  height: 100%;
}
.pm-post-sessions-requests-tab .pane-group .pane-container .pane-resize-handle.ns {
  opacity: 0;
}

.pm-proxy-overview-page-wrapper {
  height: 100%;
  width: 100%;
  align-items: center;
  overflow: auto;
  padding-bottom: var(--spacing-l);
}

.pm-post-proxy-session-page {
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: var(--spacing-l);
  height: 100%;
  width: 100%;
}

.pm-session-table-wrapper-overview-page {
  position: relative;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
}

.pm-expanded-row-container {
  overflow-x: hidden;
}

.pm-overview-chart {
  margin-top: var(--spacing-l);
  width: 100%;
  height: 300px;
}
.pm-overview-chart canvas {
  height: 100% !important;
}

.pm-overview-table-wrapper {
  margin-top: var(--spacing-l);
  height: fit-content;
}

.pm-overview-table {
  height: calc(100% - var(--spacing-l));
}

.overview-table {
  height: 100%;
}

.pm-post-session-proxy-badge {
  display: flex;
  align-items: center;
}

.pm-proxy-empty-state-container {
  margin: auto;
}

.pm-proxy-overview-tab-container {
  width: 50%;
  height: 100%;
}

.pm-proxy-interceptor-select-all-section {
  display: "flex";
  align-items: "center";
  background-color: var(--background-color-tertiary);
  height: var(--size-m);
}

.post-proxy-session-table tbody tr td {
  padding: 0 !important;
  vertical-align: middle !important;
  height: 24px;
}

.post-proxy-session-table thead th {
  height: 16px;
}

.post-proxy-session-table thead th:first-child > div {
  width: 13px;
  height: 13px;
}

.post-proxy-session-table tbody tr td [class^=Table__StyledCheckboxWrapper] {
  margin: var(--spacing-zero) var(--spacing-s) !important;
}

.pm-proxy-expanded-view [class^=Tabs__StyledTabsWrapper] {
  padding: var(--spacing-zero) !important;
}

.pm-proxy-expand-button {
  padding: var(--spacing-zero) !important;
}

.proxy-table-selected-row {
  background: var(--background-color-secondary) !important;
}

.pm-post-proxy-side-pane {
  padding-left: var(--spacing-s);
}

.pm-post-proxy-expanded-view__pane {
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l) var(--spacing-xs) var(--spacing-s) var(--spacing-l) !important;
  height: calc(100vh - 304px);
  overflow: scroll;
}

.pm-proxy-expanded-view__body-wrapper > div:first-child {
  width: fit-content;
}

.pm-proxy-expanded-view__body-wrapper {
  min-width: 300px;
  padding-top: 10px !important;
  padding-left: 4px !important;
}

.pm-proxy-side-pane-body-tab > div:first-child {
  padding: var(--spacing-zero) !important;
}

.pm-proxy-expanded-view {
  width: 100%;
  top: 10px;
  padding-bottom: var(--spacing-s);
}
.pm-proxy-expanded-view .pm-session-tab-header-table {
  padding: 10px 0;
  table-layout: fixed;
}
.pm-proxy-expanded-view .pm-session-tab-header-table .p {
  flex-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}
.pm-proxy-expanded-view__details-wrapper {
  padding: 0 0 var(--spacing-l) var(--spacing-zero);
}
.pm-proxy-expanded-view__details-wrapper__show-hide-details {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
  text-decoration: underline;
}
.pm-proxy-expanded-view__details-wrapper .btn {
  padding: 0;
}
.pm-proxy-expanded-view__details-wrapper .btn:hover {
  color: var(--content-color-secondary);
}

.session-tab-header {
  display: flex;
  flex-direction: column;
  padding-right: var(--spacing-l);
  align-items: flex-start;
  box-sizing: border-box;
}
.session-tab-header__name {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  line-height: var(--line-height-m);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.session-tab-header__name__container {
  display: flex;
  width: 100%;
}
.session-tab-header__name__input {
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.session-tab-header__name__wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.session-tab-header__name.is-editing .session-tab-header__name__wrapper {
  width: 100%;
}
.session-tab-header__name.is-editing .inline-input__wrapper {
  width: 100%;
}
.session-tab-header__name .inline-input__wrapper .input-box {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  padding: 2px;
}
.session-tab-header__name .breadcrumb__container {
  padding-left: 3px;
}
.session-tab-header__name .session-tab-header__edit-btn {
  cursor: pointer;
  background-color: transparent;
  visibility: hidden;
  width: var(--size-s);
  height: var(--size-s);
  margin-left: var(--spacing-xs);
}
.session-tab-header__name .session-tab-header__edit-btn:hover use, .session-tab-header__name .session-tab-header__edit-btn.is-hovered use {
  fill: var(--content-color-primary);
}
.session-tab-header__name .session-tab-header__edit-btn:hover svg:not([class*=PartnerIcon]) > path[fill], .session-tab-header__name .session-tab-header__edit-btn.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.session-tab-header__name .session-tab-header__edit-btn:hover svg, .session-tab-header__name .session-tab-header__edit-btn.is-hovered svg {
  color: var(--content-color-primary);
}
.session-tab-header__name:hover.editable .session-tab-header__edit-btn, .session-tab-header__name.is-hovered.editable .session-tab-header__edit-btn {
  visibility: visible;
}
.session-tab-header__name:hover .session-tab-header__copy-btn, .session-tab-header__name.is-hovered .session-tab-header__copy-btn {
  visibility: visible;
}
.session-tab-header__actions {
  display: flex;
  margin-left: var(--spacing-xxl);
  justify-content: flex-end;
  align-items: center;
}

.session-tab-header__port__wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.session-tab-header__port {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  line-height: var(--line-height-m);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.session-tab-header__port__container {
  display: flex;
  width: 100%;
}
.session-tab-header__port__input {
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.session-tab-header__port.is-editing .session-tab-header__name__wrapper {
  width: 100%;
}
.session-tab-header__port.is-editing .inline-input__wrapper {
  width: 100%;
}
.session-tab-header__port .inline-input__wrapper .input-box {
  padding: 2px;
}
.session-tab-header__port .breadcrumb__container {
  padding-left: 3px;
}
.session-tab-header__port .session-tab-header__edit-btn {
  cursor: pointer;
  background-color: transparent;
  visibility: hidden;
  width: var(--size-s);
  height: var(--size-s);
  margin-left: var(--spacing-xs);
}
.session-tab-header__port .session-tab-header__edit-btn:hover use, .session-tab-header__port .session-tab-header__edit-btn.is-hovered use {
  fill: var(--content-color-primary);
}
.session-tab-header__port .session-tab-header__edit-btn:hover svg:not([class*=PartnerIcon]) > path[fill], .session-tab-header__port .session-tab-header__edit-btn.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.session-tab-header__port .session-tab-header__edit-btn:hover svg, .session-tab-header__port .session-tab-header__edit-btn.is-hovered svg {
  color: var(--content-color-primary);
}
.session-tab-header__port:hover.editable .session-tab-header__edit-btn, .session-tab-header__port.is-hovered.editable .session-tab-header__edit-btn {
  visibility: visible;
}
.session-tab-header__port:hover .session-tab-header__copy-btn, .session-tab-header__port.is-hovered .session-tab-header__copy-btn {
  visibility: visible;
}

.session-tab-header.session-port-editor {
  padding: var(--spacing-s) calc(var(--spacing-m) / 2);
}

.interceptor-domains-list-wrapper {
  display: flex;
  flex-direction: row;
  max-height: 200px;
  min-height: 200px;
}
.interceptor-domains-list-wrapper .interceptor-domains-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 8px 0px;
  border-radius: 4px;
  overflow: auto;
  background-color: var(--background-color-tertiary);
}
.interceptor-domains-list-wrapper .interceptor-domain-list-item {
  display: flex;
  flex-direction: row;
  padding: 2px 4px;
  border-radius: 2px;
  align-items: center;
  height: 24px;
}
.interceptor-domains-list-wrapper .interceptor-domain-list-item .interceptor-domain-list-item-label {
  flex: 2;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.interceptor-domains-list-wrapper .interceptor-domain-list-item .interceptor-domain-list-item-value {
  flex: 0 0 70px;
  display: flex;
  justify-content: flex-end;
}
.interceptor-domains-list-wrapper .interceptor-domain-list-item .interceptor-domain-list-item-value .intereceptor-remove-domain-btn {
  cursor: pointer;
}

.unsupported-integrations-error-cta {
  color: var(--content-color-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.unsupported-integrations-error-cta__subtitle {
  width: max-content;
}
.unsupported-integrations-error-cta__subtitle__link {
  padding-left: var(--spacing-xs);
}

.datadog-apm-dashboard {
  height: -webkit-fill-available;
  align-items: center;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .datadog-apm-dashboard-wrapper--inner {
  overflow: auto;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper {
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: var(--background-color-primary);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .aether-dropdown__value-container {
  padding-left: 0;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .aether-dropdown__single-value {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  letter-spacing: -0.12px;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .aether-dropdown__control {
  border: none !important;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .aether-dropdown__control--is-focused,
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .aether-dropdown__control--is-focused:hover {
  border: none !important;
  box-shadow: none !important;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .integrations-add-card__segment {
  margin-top: var(--spacing-zero);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .integrations-add-card__segment .integrations-add-card__input {
  padding-top: var(--spacing-zero);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .monitoring-service-selector-icon-wrapper {
  display: contents;
  align-items: center;
  padding: 6px var(--spacing-xs) 6px var(--spacing-s);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .monitoring-service-selector-icon-wrapper .aether-dropdown__menu {
  width: 300px;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-service-selector .monitoring-service-selector-selected-item-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--content-color-primary);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .monitoring-service-selector-health-status {
  min-width: max-content;
  align-items: center;
  display: flex;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-timeline-selector {
  cursor: pointer;
  width: 190px;
  min-width: 90px;
  margin-left: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-timeline-selector .integrations-add-card__segment {
  margin-top: var(--spacing-m);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-timeline-selector .integrations-add-card__segment .integrations-add-card__input {
  padding-top: var(--spacing-zero);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-timeline-selector .monitoring-timeline-selector-icon-wrapper {
  display: flex;
  align-items: center;
  padding: 6px var(--spacing-m) 6px var(--spacing-m);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-timeline-selector .monitoring-timeline-selector-selected-item-text {
  width: 154px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--content-color-primary);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .monitoring-service-selector-icon-wrapper:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-timeline-selector:hover {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-btn {
  display: flex;
  align-items: center;
  min-width: max-content;
  float: right;
  margin-left: auto;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
  cursor: pointer;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-btn:hover {
  color: var(--content-color-link);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-timeframe {
  float: right;
  margin-left: var(--spacing-m);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .api-monitoring-more-options-icon-wrapper {
  display: flex;
  align-items: center;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .apm-titlebar-wrapper .apm-refresh-cta {
  display: flex;
  align-items: center;
  cursor: pointer;
  float: right;
  margin-left: var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .datadog-apm-titlebar-divider {
  height: 47px;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .datadog-apm-chart-title-wrapper {
  display: grid;
  grid-column: auto;
  grid-auto-flow: column;
  margin-top: var(--spacing-xl);
  margin-left: var(--spacing-l);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .datadog-apm-chart-title-wrapper .datadog-apm-chart-title {
  height: var(--size-xs);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  color: var(--content-color-primary);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .datadog-apm-chart-wrapper {
  display: flex;
  height: 426px;
  background-color: var(--background-color-tertiary);
  margin: var(--spacing-l) var(--spacing-l) var(--spacing-xs) var(--spacing-l);
  align-items: center;
  padding-right: var(--spacing-s);
  border-radius: var(--border-radius-default);
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .datadog-apm-chart-wrapper .datadog-apm-metric-chart-iframe-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 405px;
  margin-left: var(--spacing-s);
  align-items: center;
  background-color: white;
}
.datadog-apm-dashboard .datadog-apm-dashboard-wrapper .datadog-apm-chart-wrapper .datadog-apm-metric-chart-iframe-wrapper .metric-chart {
  width: 100%;
  height: 405px;
}

.migration-page {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  color: var(--content-color-primary);
  position: relative;
  height: calc(100% - 48px);
}
.migration-page .empty-state--absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.migration-page .empty-state__image.cooper {
  display: block;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/empty-state-cooper.svg");
}
.migration-page .migration-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  max-width: 600px;
}
.migration-page .loading-indicator-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.migration-page .migration-header {
  text-align: center;
  margin-bottom: 40px;
}
.migration-page .migration-header h1 {
  font-size: var(--text-size-xxxl);
  font-weight: var(--text-weight-medium);
  margin: 0 0 var(--spacing-m) 0;
}
.migration-page .migration-header p {
  font-size: var(--text-size-l);
  margin: 0;
}
.migration-page .migration-content {
  max-width: 392px;
  margin-bottom: 40px;
}
.migration-page .migration-content--entity-table {
  margin-bottom: 40px;
}
.migration-page .migration-content--entity-table__row {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  padding: 6px var(--spacing-s);
}
.migration-page .migration-content--entity-table__view-more {
  margin-top: var(--spacing-s);
  padding: 0;
  font-weight: var(--text-weight-regular);
}
.migration-page .migration-content--info__title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  margin: 0 0 var(--spacing-s) 0;
}
.migration-page .migration-content--info__desc {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.migration-page .migration-content--info__view-all {
  cursor: pointer;
  color: var(--content-color-link);
  font-size: var(--text-size-m);
  margin: var(--spacing-s) 0 0 0;
}
.migration-page .migration-content--info__view-all:hover {
  text-decoration: underline;
}
.migration-page .migration-content--info__advantage {
  display: flex;
  margin-top: var(--spacing-xl);
}
.migration-page .migration-content--info__advantage__icon {
  border-radius: 50%;
  background-color: var(--background-color-brand);
  height: 48px;
  width: 48px;
  margin-right: 20px;
  position: relative;
}
.migration-page .migration-content--info__advantage__icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.migration-page .migration-content--info__advantage__icon i, .migration-page .migration-content--info__advantage__icon svg {
  height: 24px;
  width: 24px;
}
.migration-page .migration-content--info__advantage__title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  margin: 0 0 var(--spacing-s) 0;
}
.migration-page .migration-content--info__advantage__desc {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.migration-page .migration-content--cta {
  margin: 40px 0;
}
.migration-page .migration-empty-state, .migration-page .migration-already-migrated {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  max-width: 600px;
}
.migration-page .migration-empty-state__icon-wrapper, .migration-page .migration-already-migrated__icon-wrapper {
  position: relative;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-xl);
}
.migration-page .migration-empty-state__icon-wrapper i, .migration-page .migration-already-migrated__icon-wrapper i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.migration-page .migration-empty-state__icon-wrapper i, .migration-page .migration-empty-state__icon-wrapper svg, .migration-page .migration-already-migrated__icon-wrapper i, .migration-page .migration-already-migrated__icon-wrapper svg {
  height: 32px;
  width: 32px;
}
.migration-page .migration-empty-state__title, .migration-page .migration-already-migrated__title {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
  margin: 0 0 var(--spacing-s) 0;
}
.migration-page .migration-empty-state__desc, .migration-page .migration-already-migrated__desc {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin: 0 0 var(--spacing-xl) 0;
}
.migration-page .migration-empty-state__desc .btn, .migration-page .migration-already-migrated__desc .btn {
  display: inline;
  padding: 0 3px;
  font-weight: var(--text-weight-regular);
}

.icon-tag__type-card {
  position: absolute;
  top: 0;
  right: var(--spacing-l);
  height: var(--size-m);
  width: var(--size-m);
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
}
.icon-tag__type-card.icon-tag__FEATURED {
  background-color: var(--background-color-warning);
}
.icon-tag__type-card i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.copy-link-btn {
  background-color: transparent !important;
  cursor: pointer;
}
.copy-link-btn__default use {
  fill: var(--content-color-secondary);
}
.copy-link-btn__default svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.copy-link-btn__default svg {
  color: var(--content-color-secondary);
}
.copy-link-btn__success use {
  fill: var(--content-color-success);
}
.copy-link-btn__success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.copy-link-btn__success svg {
  color: var(--content-color-success);
}
.copy-link-btn__hover use {
  fill: var(--content-color-primary);
}
.copy-link-btn__hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.copy-link-btn__hover svg {
  color: var(--content-color-primary);
}
.copy-link-btn__active {
  width: var(--size-s);
  height: var(--size-s);
  visibility: visible !important;
  display: inline-flex !important;
  background-color: transparent !important;
}
.copy-link-btn-popover {
  max-width: 320px !important;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.expandable-div {
  position: relative;
  overflow-y: hidden;
}
.expandable-div__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--size-m);
  border-radius: var(--border-radius-default);
  background: linear-gradient(to top, var(--highlight-background-color-secondary), rgba(255, 255, 255, 0));
  pointer-events: all;
}
.expandable-div__expand-button {
  position: absolute;
  bottom: var(--spacing-s);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-primary);
  padding: var(--spacing-xs) var(--spacing-m);
  font-size: var(--text-size-m);
  pointer-events: all;
  font-family: var(--text-family-default);
  border-radius: 999px;
}
.expandable-div__expand-button:focus {
  outline: none;
}

.manage-public {
  height: 100%;
}
.manage-public table {
  text-align: left;
  width: 100%;
  table-layout: fixed;
  overflow: visible;
}
.manage-public table th, .manage-public table td {
  height: 44px;
  justify-content: center;
  padding-left: var(--spacing-xs);
  vertical-align: middle;
}
.manage-public table tr {
  box-shadow: inset 0px -1px 0px var(--border-color-default);
  font-size: var(--spacing-m);
}
.manage-public td:nth-child(1):hover span, .manage-public td:nth-child(1).is-hovered span {
  text-decoration: underline;
}
.manage-public td:nth-child(5):hover a span, .manage-public td:nth-child(5).is-hovered a span {
  text-decoration: underline;
}
.manage-public .manage-empty-state {
  padding-top: 100px;
}
.manage-public .manage-banner-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xl);
  color: var(--content-color-primary);
  margin-top: 14px;
  margin-bottom: 0px;
}
.manage-public .manage-banner-subheading {
  font-weight: var(--text-weight-regular);
  font-family: Inter;
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
  margin-top: 8px;
  margin-bottom: 0px;
}
.manage-public .manage-pagination-buttons {
  padding: var(--spacing-m);
  margin-left: var(--spacing-xs);
  color: var(--content-color-primary);
}
.manage-public .manage-pagination-ellipsis {
  padding-top: calc(var(--spacing-xs) / 2);
  margin-left: var(--spacing-xs);
}
.manage-public .manage-pagination-left {
  visibility: hidden;
  width: var(--spacing-xxl);
}
.manage-public .manage-pagination-left i {
  visibility: hidden;
}
.manage-public .manage-pagination-right {
  visibility: hidden;
  width: var(--spacing-xxl);
}
.manage-public .manage-pagination-right i {
  visibility: hidden;
}
.manage-public .manage .loader {
  position: absolute;
  left: 50%;
  top: 50%;
}
.manage-public .manage .publish-requests-table-loader {
  position: relative;
  left: 50%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.manage-public .manage .table-loader {
  position: absolute;
  left: 50%;
  padding-top: 100px;
}
.manage-public .manage .copy-btn {
  width: 40px;
}
.manage-public .manage .copy-btn-container {
  background-color: var(--background-color-secondary);
  height: var(--size-s);
}
.manage-public .manage .copy-btn__default use {
  fill: var(--content-color-secondary);
}
.manage-public .manage .copy-btn__default svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.manage-public .manage .copy-btn__default svg {
  color: var(--content-color-secondary);
}
.manage-public .manage .copy-btn__hover use {
  fill: var(--content-color-primary);
}
.manage-public .manage .copy-btn__hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.manage-public .manage .copy-btn__hover svg {
  color: var(--content-color-primary);
}
.manage-public .manage .copy-btn__success use {
  fill: var(--content-color-success);
}
.manage-public .manage .copy-btn__success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.manage-public .manage .copy-btn__success svg {
  color: var(--content-color-success);
}
.manage-public .manage .delete-btn-container {
  background-color: var(--background-color-secondary);
  height: var(--size-s);
}
.manage-public .manage .input-select-v2-wrapper .input-search-group .input {
  font-weight: var(--font-weight-regular) !important;
}
.manage-public .tab-documentation-table-container tr .author__name--container {
  overflow: hidden;
}
.manage-public .tab-documentation-table-container tr .unpublish-button {
  visibility: hidden;
  display: flex;
  justify-content: center;
}
.manage-public .tab-documentation-table-container tr:hover .unpublish-button {
  visibility: visible;
}

.manage-public-delete-modal-listing {
  padding-left: var(--spacing-xl);
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-zero);
}
.manage-public-delete-modal-confirmation {
  padding-top: var(--spacing-m);
}
.manage-public-delete-modal-input {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-s);
}
.manage-public-delete-modal-input ::placeholder {
  color: var(--content-color-tertiary);
  opacity: 1;
}

.author__name {
  padding-left: var(--spacing-xs);
  vertical-align: middle;
}
.author__name--container {
  overflow: hidden;
}

.tab-workspace-dropdown {
  margin-bottom: var(--spacing-l);
  padding-left: var(--spacing-l);
}

.aether-filter-select-dropdown__menu-list {
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  overflow-x: hidden;
}

.manage-public .tab-links-header {
  margin-top: var(--spacing-m);
  display: inline-block;
}
.manage-public .tab-links-header__subtitle {
  max-width: 70ch;
  padding-top: var(--spacing-s);
}
.manage-public .tab-links-header__link {
  text-decoration: underline;
}
.manage-public .tab-links-header__link:hover, .manage-public .tab-links-header__link.is-hovered {
  text-decoration: underline;
  cursor: pointer;
}
.manage-public .tab-links-title {
  padding-top: var(--spacing-xl);
}
.manage-public .tab-links-banner {
  margin-top: var(--spacing-xl);
}
.manage-public .tab-links-banner__content div {
  align-items: center;
}
.manage-public .tab-links-toggle {
  display: inline-block;
}
.manage-public .tab-links-search {
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
  padding-right: var(--spacing-l);
  width: 280px;
}
.manage-public .tab-links-activity-bar {
  background-color: var(--background-color-secondary);
  height: 48px;
  margin-top: var(--spacing-l);
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-s);
}
.manage-public .tab-workspace-header {
  margin-bottom: var(--spacing-l);
  margin-top: var(--spacing-m);
  display: inline-block;
}
.manage-public .tab-workspace-search {
  width: 288px;
  height: var(--size-m);
  margin-bottom: var(--spacing-l);
}
.manage-public .tab-documentation-filter {
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-xl);
}
.manage-public .tab-documentation-title {
  padding-top: var(--spacing-xl);
}

.manage-postman-keys-container .api-keys-container .api-keys-listing-container {
  position: relative;
}
.manage-postman-keys-container .api-keys-container .pmak-status-group > label {
  padding-top: var(--spacing-s);
}
.manage-postman-keys-container .api-keys-container .api-keys-listing-table table tr:hover .key-action-section {
  visibility: visible;
}
.manage-postman-keys-container .api-keys-container .api-keys-listing-table table tr:hover .key-action-section .aether-button__left-icon, .manage-postman-keys-container .api-keys-container .api-keys-listing-table table tr:hover .key-action-section span {
  visibility: visible;
}
.manage-postman-keys-container .api-keys-container .api-keys-listing-table table [role=cell] {
  vertical-align: middle;
}
.manage-postman-keys-container .settings-container {
  margin-top: var(--spacing-s);
}
.manage-postman-keys-container .settings-container .definite-expiry-dropdown {
  position: absolute;
  left: 92px;
}

.copy-link-button-popover .tooltip-wrapper {
  padding: var(--spacing-xl);
  background-color: var(--background-color-primary);
  line-height: var(--line-height-m);
}

.private_network_bulk_add-collections-table-filter-select__popover .aether-filter-select-dropdown__control,
.private_network_bulk_add-collections-table-filter-select__popover .aether-filter-select-dropdown__menu {
  width: 200px;
}

.requester-bottom-pane-content {
  cursor: default;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: 12px;
  overflow: hidden;
  z-index: 1;
  color: var(--content-color-secondary);
}
.requester-bottom-pane-content .find-replace-wrapper {
  flex: 1 auto;
  border: solid var(--border-color-default);
  border-width: 1px 0;
  display: flex;
  height: 100%;
}
.requester-bottom-pane-content .find-replace-options-wrapper {
  flex: 0 0 450px;
  border-right: 1px solid var(--border-color-default);
  overflow: auto;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-box-container {
  padding: 20px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .section-label {
  font-weight: 600;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-box-wrapper {
  display: flex;
  margin: 10px 0;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-input-wrapper {
  flex: 1;
  margin-right: 10px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-input-extra-options {
  display: flex;
  float: left;
  margin-top: 10px;
  justify-content: space-between;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-input-extra-option-item {
  display: flex;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-input-extra-option-item:not(:last-child) {
  margin-right: 7px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-input-extra-option-item .input-checkbox {
  margin-right: 7px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-input-extra-option-item span {
  cursor: pointer;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper {
  margin-top: 20px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-options-wrapper {
  margin-top: 10px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-item {
  line-height: 25px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-item .radio-button {
  margin-right: 7px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entities {
  margin-left: 30px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entities.is-disabled {
  opacity: 0.4;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entity {
  display: flex;
  height: 25px;
  align-items: center;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entity .input-checkbox {
  margin-right: 5px;
  flex: 0 0 16px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entity.collection-entity {
  position: relative;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entity.environment-entity {
  position: relative;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entity .entity-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entity .entity-wrapper .expand-entity {
  margin-left: 5px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .find-target-wrapper .find-target-option-entity span {
  cursor: pointer;
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper {
  margin-top: 10px;
  padding: 20px;
  border-top: 1px solid var(--border-color-strong);
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper .replace-input-wrapper {
  display: flex;
  margin-top: 10px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper .replace-input-wrapper .replace-input-option-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 10px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper .replace-input-wrapper .replace-input-extra-options {
  display: flex;
  margin-top: 10px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper .replace-input-wrapper .replace-input-extra-options .input-checkbox {
  margin-right: 7px;
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper .replace-input-wrapper .replace-input-extra-options span {
  cursor: pointer;
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper .replace-input-wrapper .input-field {
  width: 100%;
}
.requester-bottom-pane-content .find-replace-options-wrapper .replace-box-wrapper.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.requester-bottom-pane-content .find-replace-options-wrapper hr {
  margin: 10px -20px 15px;
  border-color: var(--border-color-default);
}
.requester-bottom-pane-content .find-replace-results-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  box-sizing: border-box;
  height: 100%;
  flex: 1;
  overflow: auto;
}
.requester-bottom-pane-content .find-replace-results-wrapper .loading-indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-tab-content {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-results--tabs {
  border-bottom: 1px solid var(--border-color-default);
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace--field-selector {
  padding: 5px 0;
  position: relative;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace--field-selector .find-replace--field-selector-button {
  color: var(--content-color-primary);
  font-size: 11px;
  font-weight: 600;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace--field-selector .find-replace--field-selector-expand {
  margin-left: 5px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-empty-results-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--content-color-secondary);
  line-height: 20px;
  font-weight: 600;
  margin-top: 50px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-empty-results-wrapper.empty-results {
  margin-top: 0;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-empty-results-image {
  display: block;
  width: 90px;
  height: 75px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/emptysearch.svg");
  margin-bottom: 20px;
  background-position: 50%;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-empty-results-text {
  max-width: 550px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-empty-results-header {
  margin-bottom: 20px;
  opacity: 0.4;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace--found-results {
  overflow: auto;
  font-size: 11px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace--found-results .input-checkbox {
  margin-right: 10px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-info-panel {
  display: flex;
  margin: var(--spacing-zero) var(--spacing-xs);
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.requester-bottom-pane-content .find-replace-results-wrapper .find-replace-info-panel__icon {
  margin: var(--spacing-zero) var(--spacing-xs);
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target {
  position: relative;
  margin: 20px;
  padding-bottom: 20px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target.empty-name {
  border-top: 0;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target .identifier--GET {
  color: var(--base-color-success);
  margin-right: 5px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target .identifier--PUT {
  color: var(--content-color-info);
  margin-right: 5px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target .identifier--POST {
  color: var(--base-color-warning);
  margin-right: 5px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target .identifier--DELETE {
  color: var(--base-color-error);
  margin-right: 5px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target .identifier--FOLDER {
  display: inline-flex;
  margin-right: 5px;
  opacity: 0.3;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target .identifier--default {
  margin-right: 5px;
  font-size: 10px;
  font-weight: 600;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target__fork {
  max-width: 100px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target__fork span {
  font-size: 10px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target--header {
  position: relative;
  font-weight: 600;
  background-color: var(--background-color-secondary);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  -webkit-user-select: none;
  user-select: none;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target--header .target-collection {
  margin-right: 8px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target--header .target-environment {
  margin-right: 8px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target--header .found-in-target--count {
  color: var(--content-color-secondary);
  font-weight: 400;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target--header .found-in-target--name-wrapper {
  display: flex;
  align-items: center;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target--header .found-in-target--name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target--header .arrow {
  position: absolute;
  left: -16px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity {
  margin-top: 10px;
  border: 1px solid var(--border-color-strong);
  padding: 0 10px 10px;
  border-radius: var(--border-radius-default);
  position: relative;
}
.requester-bottom-pane-content .find-replace-results-wrapper .open-entity {
  position: absolute;
  font-size: 11px;
  font-weight: 600;
  right: 0;
  top: 7px;
  line-height: 20px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .open-entity.entity--request {
  top: 10px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__path-wrapper {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__path {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__path .entity-breadcrumb {
  max-width: 500px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__path .entity-name-wrapper {
  display: flex;
  align-items: center;
  max-width: 180px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__path .entity-name-wrapper .entity-name {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__path .path-prefix {
  opacity: 0.4;
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__field {
  padding: 10px 0 0 16px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__field-label-row {
  display: flex;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity__field-label {
  opacity: 0.4;
}
.requester-bottom-pane-content .find-replace-results-wrapper .search-instance-row {
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity--instance {
  height: 24px;
  line-height: 24px;
  padding: 0px 10px;
  margin: 5px 0;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
  position: relative;
  flex: 1;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity--instance .instance-tooltip {
  position: absolute;
  right: 20px;
}
.requester-bottom-pane-content .find-replace-results-wrapper .found-in-target-entity--instance .instance-highlight-text {
  color: var(--content-color-info);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.search-target-selector-wrapper {
  position: absolute;
  background-color: var(--background-color-secondary);
  left: 20px;
  z-index: 1;
  width: 300px;
  height: 200px;
  font-size: 11px;
  top: 0;
  border-radius: var(--border-radius-default);
  margin-bottom: 20px;
  box-shadow: var(--shadow-default);
}
.search-target-selector-wrapper .search-target-selector-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-target-selector-wrapper .search-target-selector-header {
  padding: 5px 10px;
  background-color: var(--background-color-primary);
}
.search-target-selector-wrapper .input-search-group {
  margin: 10px 0px;
  border-radius: var(--border-radius-default);
}
.search-target-selector-wrapper .search-target-selector-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-target-selector-wrapper .search-target-selector-meta--count {
  opacity: 0.4;
}
.search-target-selector-wrapper .search-target-selector-meta--count-selected {
  padding: 0px 10px;
  opacity: 0.4;
}
.search-target-selector-wrapper .search-target-selector-meta--action {
  padding: 0 0 0 10px;
  border-left: 1px solid var(--border-color-default);
  border-radius: 0px;
  line-height: 20px;
  font-size: 11px;
}
.search-target-selector-wrapper .search-target-selector--list {
  padding: 5px 0;
  border-top: 1px solid var(--border-color-default);
  height: 90px;
  overflow-y: auto;
}
.search-target-selector-wrapper .search-target-selector--list-item-fork {
  margin-left: 8px;
  margin-bottom: 4px;
  max-width: 100px;
}
.search-target-selector-wrapper .search-target-selector--list-item-fork .pm-icon {
  height: 10px;
  width: 10px;
}
.search-target-selector-wrapper .search-target-selector--list-item-fork span {
  font-size: 10px;
}
.search-target-selector-wrapper .search-target-selector--list-item {
  display: flex;
  padding: 5px 10px;
  cursor: pointer;
}
.search-target-selector-wrapper .search-target-selector--list-item:hover, .search-target-selector-wrapper .search-target-selector--list-item.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.search-target-selector-wrapper .search-target-selector--list-item span {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-field-selector-wrapper {
  position: absolute;
  background-color: var(--background-color-secondary);
  color: var(--content-color-primary);
  top: 30px;
  left: 5px;
  font-size: 10px;
  width: 150px;
  line-height: 20px;
  padding: 10px 15px;
  z-index: 1;
  max-height: 280px;
  overflow: auto;
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-default);
}
.search-field-selector-wrapper .search-field-selector--head {
  padding-bottom: 10px;
  font-weight: 600;
  opacity: 0.6;
}
.search-field-selector-wrapper .search-field-selector--list {
  margin-top: 5px;
}
.search-field-selector-wrapper .search-field-selector--section {
  border-top: 1px solid var(--border-color-default);
  position: relative;
  padding: 10px 0;
}
.search-field-selector-wrapper .search-field-selector--section-label {
  position: absolute;
  background-color: var(--background-color-secondary);
  top: -11px;
  padding-right: 5px;
  font-weight: var(--text-weight-medium);
}
.search-field-selector-wrapper .search-field-selector--section-item {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 3px 0;
}
.search-field-selector-wrapper .search-field-selector--section-item .input-checkbox {
  margin-right: 10px;
}

.documentation a {
  color: var(--content-color-link);
  text-decoration: none;
}
.documentation a:hover {
  text-decoration: underline;
}

.documentation-context-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.documentation-context-view .right-context-bar-header {
  flex-shrink: 0;
}
.documentation-context-view .loading-indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.documentation-context-view__entity-body-container {
  overflow: scroll;
  display: flex;
  flex: 1 1 auto;
  height: calc(100% - 48px);
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-s);
}
.documentation-context-view__entity-body-container .docs-context-view {
  width: 100%;
  height: 100%;
}
.documentation-context-view__entity-body-container .documentation-description__editor-container {
  height: 100%;
  overflow: hidden;
}
.documentation-context-view .collection-container,
.documentation-context-view .folder-container,
.documentation-context-view .request-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  word-break: break-word;
  height: 100%;
}
.documentation-context-view .request-container {
  margin-top: 0;
}
.documentation-context-view .request-container code[data-aether-id=aether-text-code] {
  font-size: var(--text-size-m);
}
.documentation-context-view .request-container .aether-dropdown__control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.documentation-context-view .request-container .aether-dropdown__control--is-focused {
  border: none !important;
  box-shadow: none !important;
}
.documentation-context-view .request-container .request-url-core + .description-editor-container > .entity-description-container {
  margin-top: 0;
}

.documentation-list__title {
  text-transform: uppercase;
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  margin-bottom: var(--spacing-m);
}
.documentation-list__items-container {
  /**
  * Displaying the icons in grid allows us to indent all the items description
  * and icons correctly as shown below without the need to hard code any
  * min-width or max-width
  *
  * icon-1                   description-1
  * long-icon-1 .            description-2
  * long-long-long-icon-2    description-3
  */
  display: grid;
  /**
  * max-content is meant to specify that the width of the icon columns would
  * the max-width of the icon and the rest area would be taken by its description
  */
  grid-template-columns: max-content 1fr;
  row-gap: var(--spacing-l);
  column-gap: var(--spacing-s);
  padding-bottom: var(--spacing-m);
  align-items: center;
}
.documentation-list__item-name {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  word-break: break-word;
  min-height: var(--line-height-m);
  max-height: var(--line-height-xxxl);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: flex;
  align-items: center;
}
.documentation-list__item-icon {
  display: inherit;
  justify-self: end;
}
.documentation-list__item-icon.with-padding {
  padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-xs) var(--spacing-zero);
}
.documentation-list__item-icon .icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  display: inherit;
}
.documentation-list__item-icon .icon-button:active {
  outline: none;
}
.documentation-list__item-icon .list-icon {
  vertical-align: middle;
}
.documentation-list__folder {
  display: flex;
  justify-content: flex-end;
  padding: 2px var(--spacing-zero) 2px var(--spacing-zero);
}
.documentation-list__folder-icon {
  display: block;
}
.documentation-list__folder-icon .list-icon {
  vertical-align: unset;
}

.documentation-list-item__marker {
  width: 0;
  height: 0;
  align-self: center;
}
.documentation-list-item__marker--visible {
  margin-right: var(--spacing-s);
  margin-bottom: var(--spacing-s);
  width: var(--spacing-s);
  height: var(--spacing-s);
  border-radius: var(--border-radius-max);
  background-color: var(--content-color-brand);
}

.documentation-workbench-view {
  --single-column__width: calc(32 * var(--size-m));
  --min-documentation-body-width: 360px;
  --api-documentation-sidebar-width: 285px;
  --max-editor-height: calc(100vh - 286px);
  --tab-meta-view-height: 210px;
  --documentation-bottom-padding: calc(10 * var(--spacing-m));
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: stretch;
  padding: var(--spacing-zero);
  font-size: var(--text-size-l);
  height: 100%;
  position: relative;
}
.documentation-workbench-view.is-loading {
  height: 100%;
  align-items: center;
}
.documentation-workbench-view.is-loading .loading-indicator-wrapper {
  height: 100%;
  align-items: center;
  display: flex;
}
.documentation-workbench-view .config-bar {
  border-bottom: thin var(--border-style-solid) var(--border-color-default);
  background-color: var(--background-color-primary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 2; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
.documentation-workbench-view .config-bar__upper-deck, .documentation-workbench-view .config-bar__lower-deck {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-height: var(--size-xs);
  width: inherit;
  padding: var(--spacing-zero) var(--spacing-l);
}
.documentation-workbench-view .config-bar .documentation-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.documentation-workbench-view .config-bar .documentation-header .documentation-fdv-breadcrumb > ul > li:last-child {
  color: var(--content-color-primary);
}
.documentation-workbench-view .config-bar .documentation-header .documentation-fdv-breadcrumb > ul > li:last-child > div {
  display: flex;
  align-items: center;
}
.documentation-workbench-view .config-bar .documentation-header .documentation-title {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.documentation-workbench-view .config-bar .documentation-header .copy-link-button {
  background-color: transparent;
  visibility: hidden;
}
.documentation-workbench-view .config-bar .documentation-header:hover .copy-link-button, .documentation-workbench-view .config-bar .documentation-header.is-hovered .copy-link-button {
  visibility: visible;
}
.documentation-workbench-view .config-bar .config-options {
  display: flex;
  align-items: center;
  font-style: normal;
}
.documentation-workbench-view .config-bar .config-options .config-entity {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  padding-right: var(--spacing-l);
}
.documentation-workbench-view .config-bar .config-options .config-entity label {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
.documentation-workbench-view .config-bar .config-options .config-entity .btn-text {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
.documentation-workbench-view .config-bar .config-options .config-entity .btn-text use {
  fill: var(--content-color-primary);
}
.documentation-workbench-view .config-bar .config-options .config-entity .btn-text svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.documentation-workbench-view .config-bar .config-options .config-entity .btn-text svg {
  color: var(--content-color-primary);
}
.documentation-workbench-view .config-bar .config-options .config-entity .dropdown-caret svg {
  color: var(--content-color-primary);
}
.documentation-workbench-view .config-bar .config-options .config-entity .language-settings-icon {
  cursor: pointer;
}
.documentation-workbench-view .config-bar__upper-deck {
  padding: var(--spacing-s) var(--spacing-l);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity {
  padding: var(--spacing-zero) var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity svg > path {
  fill: var(--content-color-secondary);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity svg {
  color: var(--content-color-secondary);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity .config-bar-button {
  height: var(--size-m);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity .config-bar-button.published {
  color: var(--content-color-success);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity .config-bar-button.published .dropdown-caret svg > path {
  fill: var(--content-color-success);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity .config-bar-button.published .dropdown-caret svg {
  color: var(--content-color-success);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity .config-bar-button .config-bar-icon {
  margin-right: var(--spacing-xs);
}
.documentation-workbench-view .config-bar__upper-deck .config-options .config-entity .config-bar-button .config-bar-icon.published-icon svg {
  color: var(--content-color-success);
}
.documentation-workbench-view .config-bar .documentation-dropdown-button {
  padding: var(--spacing-s) var(--spacing-m);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
.documentation-workbench-view .config-bar .documentation-config-bar-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.documentation-workbench-view .documentation-body-wrapper {
  flex: 1;
  overflow: auto;
}
.documentation-workbench-view .request-overview-container {
  width: 100%;
  box-sizing: border-box;
  max-width: var(--single-column__width);
  padding: var(--spacing-l) var(--spacing-xxl);
  margin: var(--spacing-zero) auto;
}
.documentation-workbench-view .request-overview-container .documentation-item-name__title input {
  font-size: 28px;
  margin-left: var(--spacing-s);
}
.documentation-workbench-view .request-overview-container .doc-heading {
  font-size: 20px;
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
}
.documentation-workbench-view .request-overview-container .doc-heading-kv {
  line-height: var(--line-height-xxl);
  letter-spacing: 0px;
}
.documentation-workbench-view .request-overview-container .request-url-core {
  margin-left: var(--spacing-s);
}
.documentation-workbench-view .request-overview-container .description-editor-container {
  margin-left: var(--spacing-xs);
}
.documentation-workbench-view .request-overview-container .description-editor-container .editor__container div {
  padding-left: var(--spacing-xs) !important;
  padding-bottom: var(--spacing-zero) !important;
}
.documentation-workbench-view .request-overview-container .request-overview-header-edit {
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--spacing-l);
}
.documentation-workbench-view .request-overview-container .request-overview-header-edit .documentation-item-name {
  flex: 1 1 1 !important;
  min-width: 250px;
}
.documentation-workbench-view .documentation-body-container {
  width: 100%;
  box-sizing: border-box;
  max-width: var(--single-column__width);
  padding: var(--spacing-zero) var(--spacing-xxl);
  margin: var(--spacing-l) auto var(--spacing-zero) auto;
  display: grid;
  grid-template-columns: minmax(var(--min-documentation-body-width), 2.75fr) 1fr;
  grid-gap: var(--spacing-xxl);
  align-items: flex-start;
}
.documentation-workbench-view .documentation-body-container.api-documentation-body-container {
  max-width: calc(var(--single-column__width) - 285px);
}
.documentation-workbench-view .documentation-body-container.api-documentation-body-container__network {
  grid-template-columns: unset;
}
.documentation-workbench-view .documentation-body-container .documentation-body {
  padding-bottom: var(--documentation-bottom-padding);
  flex: 1;
}
.documentation-workbench-view .documentation-body-container .documentation-body > * + * {
  margin-top: var(--spacing-xxl);
}
.documentation-workbench-view .documentation-body-container .documentation-body .open-request-button {
  flex-direction: row-reverse;
  padding-left: 10px;
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-xxxl);
}
.documentation-workbench-view .documentation-body-container .documentation-body .documentation-wrapper {
  gap: var(--spacing-xxl);
}
.documentation-workbench-view .documentation-body-container hr {
  height: 1px;
  border: none;
  margin: var(--spacing-m) var(--spacing-zero);
  background: var(--border-color-default);
}
.documentation-workbench-view .documentation-body-container .folder-documentation.docs-workbench-view,
.documentation-workbench-view .documentation-body-container .request-documentation.docs-workbench-view {
  height: auto;
}
.documentation-workbench-view .documentation-body-container .side-navigation {
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding-left: var(--spacing-l);
}
.documentation-workbench-view .documentation-body-container .side-navigation__publisher-info {
  padding-left: var(--spacing-s);
  padding-bottom: var(--spacing-xl);
}
.documentation-workbench-view .context-bar-header {
  justify-content: start;
}
.documentation-workbench-view .documentation-item-name {
  flex: 1;
  border: 1px solid var(--base-color-info);
}
.documentation-workbench-view .documentation-core-item-name__text {
  cursor: text;
}
.documentation-workbench-view .documentation-core-item-name__text:hover {
  background-color: var(--background-color-secondary);
}
.documentation-workbench-view .documentation-description__editor-container {
  padding-top: var(--spacing-l);
}
.documentation-workbench-view .description-editor-container {
  width: calc(100% + var(--spacing-m));
  margin-left: -6px;
}
.documentation-workbench-view .entity-authorization {
  padding: 0 var(--spacing-s);
}
.documentation-workbench-view .core-request-items-table {
  padding: 0 var(--spacing-s);
}
.documentation-workbench-view .core-request-body {
  padding: 0 var(--spacing-s);
}
.documentation-workbench-view .example-wrapper {
  padding: 0 var(--spacing-s);
}
.documentation-workbench-view [data-entity-type=request-body] {
  padding: 0 var(--spacing-s);
}
.documentation-workbench-view .collection-container {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}
.documentation-workbench-view .collection-container .documentation-core-item-name__text {
  margin-top: var(--spacing-l) !important;
}
.documentation-workbench-view .collection-container .documentation-core-item-name__text h1 {
  border: 1px solid transparent;
  padding: 0;
  padding-left: var(--spacing-xs);
}
.documentation-workbench-view .collection-container .documentation-core-item-name__text h1 span {
  padding: 0;
}
.documentation-workbench-view .collection-container .context-bar-header {
  margin-top: var(--spacing-l) !important;
}
.documentation-workbench-view .collection-container .context-bar-header h1 .inline-input__wrapper {
  height: calc(var(--line-height-xxxl));
}
.documentation-workbench-view .collection-container .input-field {
  padding: 0;
  padding-left: var(--spacing-xs);
}
.documentation-workbench-view .collection-container .input-box {
  width: 100%;
  padding: 0;
  font-size: 28px;
  border: none;
}
.documentation-workbench-view .folder-container {
  position: relative;
  flex-direction: column;
  gap: var(--spacing-l);
  word-break: break-word;
  margin-top: var(--spacing-zero);
}
.documentation-workbench-view .folder-container:hover .entity-copy-button {
  display: block;
}
.documentation-workbench-view .folder-container .documentation-core-item-name__text h2 {
  border: 1px solid transparent;
  padding: 0;
  padding-left: var(--spacing-xs);
}
.documentation-workbench-view .folder-container .documentation-core-item-name__text h2 div {
  padding: 0;
}
.documentation-workbench-view .folder-container .context-bar-header h2 .inline-input__wrapper {
  height: calc(var(--line-height-xxxl));
}
.documentation-workbench-view .folder-container .input-field {
  padding: 0;
  padding-left: var(--spacing-xs);
}
.documentation-workbench-view .folder-container .input-box {
  width: 100%;
  padding: 0;
  font-size: var(--text-size-xxxl);
  line-height: var(--text-size-xxxl);
  border: none;
}
.documentation-workbench-view .entity-copy-button {
  position: absolute;
  left: -28px;
  top: 6px;
  display: none;
}
.documentation-workbench-view .request-container {
  flex-direction: column;
  gap: var(--spacing-l);
  word-break: break-word;
  position: relative;
  margin-top: var(--spacing-zero);
}
.documentation-workbench-view .request-container .context-bar-header {
  align-items: normal;
}
.documentation-workbench-view .request-container .context-bar-header h3 span:first-child {
  padding-right: var(--spacing-s);
}
.documentation-workbench-view .request-container .context-bar-header h3 div:first-child {
  padding-left: 0;
}
.documentation-workbench-view .request-container .context-bar-header .inline-input__wrapper {
  height: calc(var(--line-height-xxxl));
}
.documentation-workbench-view .request-container .documentation-core-item-name__text h3 {
  border: 1px solid transparent;
  padding: 0;
  margin-left: var(--spacing-s);
}
.documentation-workbench-view .request-container .request-method {
  font-size: var(--text-size-xxxl);
  line-height: var(--text-size-xxxl);
}
.documentation-workbench-view .request-container .input-box {
  width: 100%;
  padding: 0;
  font-size: var(--text-size-xxxl);
  border: none;
  line-height: var(--text-size-xxxl);
}
.documentation-workbench-view .request-container code[data-aether-id=aether-text-code] {
  font-size: var(--text-size-m);
}
.documentation-workbench-view .request-container .aether-dropdown__control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.documentation-workbench-view .request-container .aether-dropdown__control--is-focused {
  border: none !important;
  box-shadow: none !important;
}
.documentation-workbench-view .request-container:hover .entity-copy-button {
  display: block;
}
.documentation-workbench-view .example-response-container {
  position: relative;
  margin-top: var(--spacing-m);
}
.documentation-workbench-view .example-response-container .example-response-tag {
  position: absolute;
  top: 28px;
  right: 0;
}

.documentation-dropdown .select-dropdown-btn span {
  max-width: calc(10 * var(--size-m));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.documentation-dropdown--config-bar .documentation-dropdown-menu-container {
  --version-selector-min-width: calc(5 * var(--size-s));
  --version-selector-max-width: calc(15 * var(--size-s));
  overflow: auto;
  padding: var(--spacing-s) var(--spacing-zero);
  max-height: calc(10 * var(--size-m));
  min-width: var(--version-selector-min-width);
  max-width: var(--version-selector-max-width);
}
.documentation-dropdown .documentation-dropdown-menu-container .dropdown-menu-item {
  height: auto;
  min-height: var(--controls-size-default);
}
.documentation-dropdown .documentation-dropdown-menu-container .input-search-group {
  border-radius: var(--border-radius-default);
  min-height: 30px;
  margin: var(--spacing-s);
}
.documentation-dropdown .documentation-dropdown-menu-container .documentation-dropdown-no-results {
  padding: var(--spacing-m);
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
}
.documentation-dropdown .documentation-dropdown-menu-container .documentation-dropdown-item {
  padding: var(--spacing-xs) var(--spacing-s);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.documentation-dropdown .documentation-dropdown-menu-container .documentation-dropdown-item .published-url {
  color: var(--content-color-link);
}
.documentation-dropdown .documentation-dropdown-menu-container .documentation-dropdown-item .published-url:hover {
  text-decoration: underline;
}
.documentation-dropdown.language-dropdown .documentation-dropdown-menu-container {
  min-width: 250px;
}

.documentation-description > * + * {
  margin-top: var(--spacing-l);
}
.documentation-description > div {
  padding: 2px var(--spacing-s);
}
.documentation-description--context-bar, .documentation-description--workbench {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.documentation-description--workbench .description-editor-wrapper {
  height: var(--max-editor-height);
}
.documentation-description--context-bar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: auto;
  height: 100%;
  margin-right: var(--spacing-s);
}
.documentation-description--context-bar .description-editor-container {
  height: 100%;
}
.documentation-description--context-bar .description-editor-wrapper {
  height: 100%;
}
.documentation-description .unsaved-changes {
  display: flex;
  align-items: center;
}
.documentation-description .unsaved-changes__icon {
  margin-right: var(--spacing-s);
}
.documentation-description .unsaved-changes__message {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}

.context-bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /**
   * Fix for Safari: Safari seems to be collapsing the context bar header/footer when the container
   * is a flex container and has the height set to 100% -- which is the case for us because scrolling
   * doesn't work otherwise. Forcibly setting the flex-shrink for the header and footer seems to fix
   * this in Safari without breaking it in other browsers.
   *
   * Tracking the removal of this (some day) in https://postmanlabs.atlassian.net/browse/DOCUMENTER-2159
   */
  flex-shrink: 0;
}
.context-bar-header__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  margin: var(--spacing-zero);
  color: var(--content-color-primary);
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--text-weight-medium);
}
.context-bar-header h1 > .documentation-item-name__title {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
}
.context-bar-header h2 > .documentation-item-name__title {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
}
.context-bar-header h3 > .documentation-item-name__title {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
}
.context-bar-header__auth-icon {
  margin-left: var(--spacing-s);
}
.context-bar-header .context-bar-actions {
  display: flex;
  align-items: center;
}

.request-documentation--url {
  margin-bottom: var(--spacing-l);
}
.request-documentation--url .request-url {
  padding: var(--spacing-xs) var(--spacing-s);
  color: var(--content-color-primary);
  background: var(--background-color-secondary);
  font-weight: var(--text-weight-regular);
  word-break: break-all;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.request-documentation.docs-workbench-view .open-request-button {
  flex-direction: row-reverse;
  padding-left: 10px;
}
.request-documentation.docs-workbench-view .open-example-button {
  flex-direction: row-reverse;
  padding-left: 10px;
}

.documentation-description .request-items-table .title {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}

.documentation-examples {
  --dropdown-label-max-width: 165px;
}
.documentation-examples__label-container {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.documentation-examples__label-container .dropdown {
  width: 70%;
}
.documentation-examples__label-container .dropdown .dropdown-button {
  text-align: right;
}
.documentation-examples__label {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-right: 20%;
}
.documentation-examples__item-label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-bold);
}
.documentation-examples__example-label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.documentation-examples__example-label--single {
  color: var(--content-color-primary);
  width: 100%;
  text-align: right;
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.documentation-examples__selector .btn {
  height: auto;
  padding: var(--spacing-zero);
  max-width: 100%;
}
.documentation-examples__menu-container {
  overflow: auto;
  padding: var(--spacing-xs) var(--spacing-zero);
  min-width: calc(10 * var(--size-s));
  max-width: 300px;
  max-height: calc(10 * var(--size-m));
}
.documentation-examples__menu-item {
  padding: var(--spacing-xs) var(--spacing-l) !important;
  height: auto;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.documentation-examples__dropdown-menu {
  min-width: calc(10 * var(--size-s));
  max-width: 300px;
  max-height: calc(13 * var(--size-xs));
  overflow-x: auto;
}
.documentation-examples__dropdown-menu .dropdown-menu-item {
  height: auto;
}
.documentation-examples__dropdown-item {
  line-height: var(--line-height-m);
  padding: var(--spacing-xs) var(--spacing-zero);
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.documentation-examples__snippet {
  margin-bottom: var(--spacing-m);
}

.documentation-example__response {
  margin-top: var(--spacing-xxl);
}

.documentation-examples-label--response {
  margin-bottom: var(--spacing-s);
}

.documentation-examples-tabs {
  margin-bottom: var(--spacing-l);
}

.documentation-examples-tab > .tab-text-wrapper {
  margin: var(--spacing-zero) var(--spacing-l) var(--spacing-zero) var(--spacing-zero);
}

.docs-example-response-headers-empty,
.docs-example-response-body-empty {
  font-size: var(--text-size-m);
  line-height: var(--text-size-m);
  text-align: center;
  color: var(--content-color-primary);
  background-color: var(--background-color-tertiary);
  padding: var(--spacing-l);
}
.docs-example-response-headers-empty__title,
.docs-example-response-body-empty__title {
  margin-bottom: var(--spacing-s);
  font-weight: var(--text-weight-medium);
}
.docs-example-response-headers-empty__body,
.docs-example-response-body-empty__body {
  font-size: var(--text-size-s);
}

.documentation-number-of-headers {
  color: var(--content-color-success);
  margin-left: var(--spacing-xs);
}

.documentation-item-name {
  margin: var(--spacing-zero);
  display: flex;
  overflow: hidden;
  align-items: baseline;
  color: var(--content-color-primary);
  position: relative;
  max-width: 100%;
}
.documentation-item-name--edit-mode {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
  border-radius: var(--border-radius-default);
}
.documentation-item-name__request {
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: var(--spacing-zero) var(--spacing-s);
}
.documentation-item-name__request--edit-mode {
  background-color: var(--background-color-secondary);
}
.documentation-item-name .request-method {
  text-transform: uppercase;
}
.documentation-item-name .request-method--edit-mode {
  background-color: var(--background-color-tertiary);
}
.documentation-item-name__title {
  overflow: hidden;
  overflow-wrap: break-word;
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: var(--content-color-primary);
}
.documentation-item-name__title--compact {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: normal;
}
.documentation-item-name__title--compact .documentation-item-name__text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.documentation-item-name__title .documentation-item-name__text {
  color: var(--content-color-primary);
  padding: var(--spacing-zero) var(--spacing-s);
  box-sizing: border-box;
}
.documentation-item-name__title .inline-input__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.documentation-item-name__title .inline-input__wrapper .input-box {
  border-color: transparent;
}
.documentation-item-name:hover .documentation-item-name__edit-icon {
  opacity: 1;
}
.documentation-item-name__edit-icon {
  padding: var(--spacing-zero) var(--spacing-s);
  opacity: 0;
  cursor: pointer;
}
.documentation-item-name__edit-icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  z-index: -1;
  opacity: 0;
}
.documentation-item-name__edit-icon:hover::before {
  opacity: 1;
}

h1 .inline-input__wrapper {
  height: calc(var(--line-height-xxxl) - 2px);
}

h2 .inline-input__wrapper {
  height: calc(var(--line-height-xxl) - 2px);
}

h3 .inline-input__wrapper {
  height: calc(var(--line-height-xl) - 2px);
}

.snippet-generator-modal.documentation-codegen .request-editor-snippet-generator-header.language-settings-subtitle {
  padding: 1% 1% !important;
}

/**
 * Used most of the styles from the markdown mixin, but modified according
 * to the styles to the public documentation and mockups present
 * in Figma file
 */
/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.description-preview {
  color: var(--content-color-primary);
  font-size: inherit;
  line-height: inherit;
  overflow-wrap: break-word;
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.description-preview--editable {
  padding: var(--spacing-xs) var(--spacing-s);
  height: auto !important;
}
.description-preview--overlay {
  background-color: var(--background-color-secondary);
}
.description-preview .click-to-expand-wrapper {
  position: relative;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.description-preview .click-to-expand-wrapper table {
  border: none;
}
.description-preview .click-to-expand-container {
  --max-table-height-without-scroll: calc(20 * var(--size-s));
  max-height: var(--max-table-height-without-scroll);
  width: 100%;
  overflow: hidden;
}
.description-preview .click-to-expand-container--overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--size-m);
  border-radius: var(--border-radius-default);
  background: linear-gradient(to top, var(--highlight-background-color-secondary), rgba(255, 255, 255, 0));
}
.description-preview .click-to-expand-container--expand-button {
  position: absolute;
  bottom: var(--spacing-s);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: calc(8 * var(--border-radius-default));
  color: var(--content-color-primary);
  padding: var(--spacing-xs) var(--spacing-m);
  font-size: var(--text-size-m);
}
.description-preview .click-to-expand-container--expand-button:focus {
  outline: none;
}
.description-preview__placeholder {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-l);
  overflow-wrap: break-word;
  flex: 1;
}
.description-preview__placeholder_small {
  color: var(--content-color-tertiary);
  overflow-wrap: break-word;
  flex: 1;
}
.description-preview__no-description {
  color: var(--content-color-tertiary);
  flex: 1;
}
.description-preview__markdown {
  flex: 1;
  overflow: hidden;
  max-width: 100%;
}
.description-preview__markdown div[data-entity-type=code-block] code {
  display: flex;
  flex-direction: column;
  width: 0;
}
.description-preview__edit-icon {
  position: sticky;
  top: var(--spacing-xs);
  right: 0;
  opacity: 0;
  cursor: pointer;
  height: var(--size-xs);
}
.description-preview:hover .description-preview__edit-icon {
  opacity: 1;
}

.markdown-editor {
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.markdown-editor__content-container {
  position: relative;
  min-height: 100%;
}
.markdown-editor__content--placeholder {
  opacity: 0;
}
.markdown-editor__content > pre {
  margin: var(--spacing-zero);
  width: 100%;
  font-family: inherit;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.markdown-editor__content > pre .bold {
  color: var(--content-color-primary);
}
.markdown-editor__content > pre .bold,
.markdown-editor__content > pre .title {
  font-weight: var(--text-weight-medium);
}
.markdown-editor__content > pre .italic {
  font-style: italic;
}
.markdown-editor__content > pre .tag,
.markdown-editor__content > pre .attr-value {
  color: var(--content-color-link);
}
.markdown-editor__content > pre .url {
  color: var(--content-color-link);
}
.markdown-editor__content > pre .tag {
  padding: var(--spacing-zero);
  max-width: none;
  overflow: visible;
  white-space: inherit;
}
.markdown-editor__content > pre .title {
  color: var(--content-color-primary);
}
.markdown-editor__content > pre .code.keyword {
  background-color: var(--highlight-background-color-primary);
  border-radius: var(--border-radius-s);
}
.markdown-editor__content > pre .code-block {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.markdown-editor__textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  resize: none;
  /**
   * -webkit-text-fill-color is used instead of color because setting color to
   * transparent hides the text cursor, wherease text-fill-color doesn't
   */
  -webkit-text-fill-color: transparent;
  caret-color: var(--content-color-secondary);
  overflow: hidden;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.markdown-editor__textarea--visible {
  -webkit-text-fill-color: var(--content-color-tertiary);
}
.markdown-editor__textarea:focus {
  outline: none;
}

:root {
  --min-editor-height: calc(10 * var(--size-m));
  --max-editor-height: calc(100vh - 350px);
}

.description-editor-wrapper {
  padding-bottom: var(--spacing-s);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.description-editor-wrapper > * + * {
  margin-top: var(--spacing-l);
}

.documentation-editor-footer {
  display: flex;
  justify-content: space-between;
}

.documentation-editor-dropdown {
  height: 24px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  color: var(--content-color-primary);
}
.documentation-editor-dropdown .dropdown-caret {
  position: relative;
  top: 4px;
  margin-left: 4px;
}
.documentation-editor-dropdown .dropdown-button {
  font-size: var(--text-size-m);
}
.documentation-editor-dropdown .dropdown-menu {
  min-width: 150px;
}

.description-editor-actions {
  display: flex;
}
.description-editor-actions > * + * {
  margin-left: var(--spacing-m);
}

.modal-content {
  background-color: var(--background-color-primary);
  padding: var(--spacing-xl) var(--spacing-xl);
  color: var(--content-color-primary);
  overflow-y: auto;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.description-editor {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  height: max-content;
}
.description-editor__radioactions__tag {
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);
  padding: var(--spacing-zero) var(--spacing-xs);
  background: var(--background-color-info);
  border-radius: 9999px;
  color: var(--content-color-info);
  font-weight: var(--text-weight-bold);
}
.description-editor__actions {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius-default);
  height: 28px;
  margin-bottom: var(--spacing-s);
  box-shadow: var(--aether-popover-shadow);
}
.description-editor__actions--left {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}
.description-editor__actions--left button {
  padding: var(--spacing-zero) var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  border: none;
  height: 100%;
  font-family: var(--text-family-default);
  color: var(--content-color-secondary);
  background-color: transparent;
}
.description-editor__actions--left button.active {
  color: var(--content-color-primary);
  box-shadow: inset 0px -1px 0px var(--base-color-brand);
}
.description-editor__actions--right {
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  margin: var(--spacing-s);
}
.description-editor__actions--right:hover {
  background-color: var(--background-color-secondary);
}
.description-editor__actions--right.active {
  background-color: var(--background-color-tertiary);
}
.description-editor__markdown-editor, .description-editor__preview {
  flex: 1;
  padding: var(--spacing-s);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
}
.description-editor__preview {
  overflow: auto;
}

.description-preview ul li {
  margin-left: var(--spacing-m);
}

.popup-info-wrapper {
  position: absolute;
  top: 36px;
  right: 8px;
  z-index: 1;
  width: 223px;
  height: auto;
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l);
  background-color: var(--background-color-secondary);
  box-shadow: var(--shadow-default);
}
.popup-info-wrapper::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -4px;
  right: var(--spacing-s);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--background-color-secondary);
}
.popup-info-wrapper h3 {
  margin: 0 0 var(--spacing-m);
  color: var(--content-color-primary);
}
.popup-info-wrapper p {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
}
.popup-info-wrapper__tag {
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);
  padding: var(--spacing-zero) var(--spacing-s);
  background: var(--background-color-info);
  border-radius: var(--border-radius-default);
  color: var(--content-color-info);
  font-weight: var(--text-weight-bold);
  font-size: var(--text-size-m);
}

.documentation-context-bar-footer {
  bottom: 0;
  display: flex;
  cursor: pointer;
  position: sticky;
  align-items: end;
  font-size: var(--text-size-m);
  color: var(--content-color-link);
  font-weight: var(--text-weight-regular);
  background: var(--background-color-primary);
  padding: var(--spacing-l) var(--spacing-s);
  border-top: var(--border-style-solid) var(--border-width-default) var(--border-color-default);
  /**
   * Fix for Safari: Safari seems to be collapsing the context bar header/footer when the container
   * is a flex container and has the height set to 100% -- which is the case for us because scrolling
   * doesn't work otherwise. Forcibly setting the flex-shrink for the header and footer seems to fix
   * this in Safari without breaking it in other browsers.
   *
   * Tracking the removal of this (some day) in https://postmanlabs.atlassian.net/browse/DOCUMENTER-2159
   */
  flex-shrink: 0;
}
.documentation-context-bar-footer > i {
  margin-left: var(--spacing-xs);
}

.documentation-code-default > * + * {
  margin-top: var(--spacing-s);
}
.documentation-code-default--title {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.documentation-code-default--title span:first-child {
  color: var(--content-color-primary);
  margin-right: var(--spacing-s);
}
.documentation-code-default--content pre {
  padding: var(--spacing-s);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
  max-height: calc(20 * var(--size-m));
}

.request-body--title {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  padding: 2px var(--spacing-zero);
}
.request-body--title--tertiary {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  line-height: 22px;
  padding: 1px var(--spacing-zero);
}
.request-body--graphql--title-label {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.request-body--graphql--title-label span:first-child {
  color: var(--content-color-primary);
  margin-right: var(--spacing-s);
}
.request-body--graphql--subtitle-label {
  margin-top: var(--spacing-m);
  font-size: var(--text-size-m) !important;
  line-height: var(--line-height-s) !important;
  color: var(--content-color-secondary) !important;
}
.request-body--urlencoded--title-label {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
  line-height: var(--line-height-m);
}
.request-body--urlencoded--title-label span:first-child {
  color: var(--content-color-primary);
  margin-right: var(--spacing-s);
}
.request-body--form-data--title-label {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-m);
}
.request-body--form-data--title-label span:first-child {
  color: var(--content-color-primary);
  margin-right: var(--spacing-s);
}
.request-body--binary--title-label {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-m);
}
.request-body--binary--title-label span:first-child {
  color: var(--content-color-primary);
  margin-right: var(--spacing-s);
}

.snippet-modal {
  --max-content-height: 70vh;
  max-height: var(--max-content-height);
}
.snippet-modal .modal-content {
  overflow: hidden;
}
.snippet-modal .highlighted-code--snippet-modal {
  max-height: calc(var(--max-content-height) - 4 * var(--spacing-xl));
}

.documentation-error-message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.documentation-error-message--container {
  display: flex;
  width: calc(10 * var(--size-m));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxl);
}
.documentation-error-message--title {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
  text-align: center;
}
.documentation-error-message--content {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  text-align: center;
}
.documentation-error-message--thumbnail {
  height: 200px;
  width: 200px;
  background-size: contain;
  margin-bottom: var(--spacing-l);
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
}
.documentation-error-message--link {
  margin-left: var(--spacing-xs);
}
.documentation-error-message--refresh-button {
  margin-top: var(--spacing-s);
}

.documentation-usage-error {
  border-top: var(--border-width-default) var(--border-style) var(--border-color-default);
  border-bottom: var(--border-width-default) var(--border-style) var(--border-color-default);
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.documentation-usage-error--title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  text-align: center;
}
.documentation-usage-error--meta {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  margin: var(--spacing-s) var(--spacing-zero);
  text-align: center;
}

.table-modal {
  --max-content-height: 70vh;
  max-height: var(--max-content-height);
}
.table-modal table {
  width: 100%;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.table-modal table tr {
  height: var(--size-m);
  min-height: var(--size-m);
}
.table-modal table th,
.table-modal table td {
  min-width: 77px;
  max-width: 77px;
  padding: var(--spacing-xs) var(--spacing-l);
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.table-modal table th:first-child,
.table-modal table td:first-child {
  border-left: none;
}
.table-modal table th:last-child,
.table-modal table td:last-child {
  border-right: none;
}
.table-modal table th {
  border-top: none;
  text-align: left;
  background-color: var(--background-color-secondary);
}
.table-modal table tr:last-child th,
.table-modal table tr:last-child td {
  border-bottom: none;
}
.table-modal .click-to-expand-container {
  --max-table-height-without-scroll: calc(
    /* The height of the header is 2*spacing-xl and the padding in the modal container is 2*spacing-xl */
      var(--max-content-height) - 4 * var(--spacing-xl)
  );
  overflow: auto;
}

.image-modal {
  margin: auto;
  text-align: center;
}
.image-modal .modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.image-modal .modal-content img {
  max-height: 100%;
  max-width: 100%;
}

.request-items-table .title {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.request-items-table .title-content-container > * + * {
  margin-left: var(--spacing-l);
}
.request-items-table .title--secondary {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
}

.request-item-row {
  display: flex;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  border-radius: var(--border-radius-default);
  word-break: break-word;
  color: var(--content-color-primary);
  min-height: var(--size-m);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.request-item-row .item-key,
.request-item-row .item-value {
  overflow-wrap: break-word;
  word-break: break-word;
  padding: var(--spacing-s) var(--spacing-zero);
}
.request-item-row .item-key {
  font-weight: var(--text-weight-medium);
  flex: 1;
  margin-right: var(--spacing-s);
}
.request-item-row .item-value {
  flex: 2;
  margin-left: var(--spacing-s);
  width: 50%;
}
.request-item-row .item-value .item-description {
  color: var(--content-color-secondary);
}
.request-item-row .item-value > div:not(:last-child):not(:empty) {
  margin-bottom: var(--spacing-s);
}

.entity-authorization__title-content-container {
  display: flex;
}
.entity-authorization__title-content-container > * + * {
  margin-left: var(--spacing-s);
}
.entity-authorization__title {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  padding: 2px var(--spacing-zero);
}
.entity-authorization__title--secondary {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
}
.entity-authorization__title--tertiary {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  line-height: 22px;
  padding: 1px var(--spacing-zero);
}
.entity-authorization__content {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
}
.entity-authorization__link {
  color: var(--content-color-link);
  cursor: pointer;
}
.entity-authorization__link:hover {
  text-decoration: underline;
}

.entity-authorization-accordian__title-container {
  border-radius: var(--border-radius-default);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-size-m);
}
.entity-authorization-accordian__title {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.entity-authorization-accordian__toggle-action {
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  background-color: transparent;
  color: var(--content-color-brand);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: var(--text-size-s);
}
.entity-authorization-accordian__toggle-action:focus {
  outline: none;
}
.entity-authorization-accordian__toggle-action > * + * {
  margin-left: var(--spacing-xs);
}

.entity-authorization-small > * + * {
  margin-top: var(--spacing-s);
}
.entity-authorization-small__title-content-container {
  display: flex;
}
.entity-authorization-small__title-content-container > * + * {
  margin-left: var(--spacing-s);
}
.entity-authorization-small__title {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  padding: 2px var(--spacing-zero);
}
.entity-authorization-small__title--secondary {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
}
.entity-authorization-small__title--tertiary {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  line-height: 22px;
  padding: 1px var(--spacing-zero);
}
.entity-authorization-small__content {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.entity-authorization-small__link {
  color: var(--content-color-link);
  cursor: pointer;
}
.entity-authorization-small__link:hover {
  text-decoration: underline;
}

.markdown-link {
  color: var(--content-color-link) !important;
  text-decoration: none;
}
.markdown-link:hover {
  color: var(--content-color-link) !important;
  text-decoration: underline;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.markdown-viewer {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  height: auto;
  position: relative;
  color: var(--content-color-primary);
  /**
  * As there we no mockups in artemis-v2 Figma file, copied the
  * same styles as present in the public & private documentation
  */
}
.markdown-viewer > * {
  margin: var(--spacing-zero);
}
.markdown-viewer > * + * {
  margin-top: var(--spacing-l);
}
.markdown-viewer h1 * {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
}
.markdown-viewer h2 * {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
}
.markdown-viewer h3 * {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
}
.markdown-viewer h4 * {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.markdown-viewer h5 * {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.markdown-viewer h6 * {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.markdown-viewer hr {
  border-style: none;
  border-width: 0;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.markdown-viewer blockquote {
  padding-left: var(--spacing-l);
  border-left: var(--border-width-xl) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-secondary);
  margin: var(--spacing-zero);
}
.markdown-viewer em {
  font-style: italic;
}
.markdown-viewer strong {
  font-weight: var(--text-weight-medium);
}
.markdown-viewer ul,
.markdown-viewer ol {
  margin-left: var(--spacing-s);
  padding-left: var(--spacing-m);
}
.markdown-viewer ul p,
.markdown-viewer ol p {
  margin: var(--spacing-zero);
}
.markdown-viewer ol li {
  list-style-type: decimal;
}
.markdown-viewer ol li ol li {
  list-style-type: lower-alpha;
}
.markdown-viewer ol li ol li ol li {
  list-style-type: lower-roman;
}
.markdown-viewer ol li ol li ol li ol li {
  list-style-type: lower-alpha;
}
.markdown-viewer ol li ol li ol li ol li ol li {
  list-style-type: lower-roman;
}
.markdown-viewer code {
  padding: 1px var(--spacing-xs);
  font-family: var(--text-family-code);
  background-color: var(--background-color-tertiary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.markdown-viewer pre {
  font-family: var(--text-family-code);
}
.markdown-viewer .highlighted-code code {
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.markdown-viewer table {
  width: 100%;
  border-radius: var(--border-radius-default);
  border-spacing: 0;
  border-collapse: collapse;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.markdown-viewer table tr {
  height: var(--size-m);
  min-height: var(--size-m);
}
.markdown-viewer table th,
.markdown-viewer table td {
  min-width: 77px;
  max-width: 77px;
  padding: var(--spacing-xs) var(--spacing-l);
  word-break: normal;
  vertical-align: top;
  white-space: pre-line;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.markdown-viewer table th:first-child,
.markdown-viewer table td:first-child {
  border-left: none;
}
.markdown-viewer table th:last-child,
.markdown-viewer table td:last-child {
  border-right: none;
}
.markdown-viewer table th {
  border-top: none;
  text-align: left;
  font-weight: normal;
  background-color: var(--background-color-secondary);
}
.markdown-viewer table tr:last-child th,
.markdown-viewer table tr:last-child td {
  border-bottom: none;
}
.markdown-viewer .md-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.markdown-viewer .md-image-wrapper.zoom {
  cursor: zoom-in;
}
.markdown-viewer .md-image-wrapper img {
  flex: 1;
  padding-bottom: var(--spacing-zero);
}
.markdown-viewer .md-image-wrapper .image-caption {
  margin-top: var(--spacing-xs);
  color: var(--content-color-secondary);
  text-align: center;
}
.markdown-viewer img {
  display: block;
  max-width: 100%;
  padding: 10px 0px;
}
.markdown-viewer .click-to-expand-wrapper {
  position: relative;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.markdown-viewer .click-to-expand-wrapper table {
  border: none;
}
.markdown-viewer .click-to-expand-container {
  --max-table-height-without-scroll: calc(20 * var(--size-s));
  max-height: var(--max-table-height-without-scroll);
  width: 100%;
  overflow: hidden;
}
.markdown-viewer .click-to-expand-container--overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--size-m);
  border-radius: var(--border-radius-default);
  background: linear-gradient(to top, var(--highlight-background-color-secondary), rgba(255, 255, 255, 0));
}
.markdown-viewer .click-to-expand-container--expand-button {
  position: absolute;
  bottom: var(--spacing-s);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: calc(8 * var(--border-radius-default));
  color: var(--content-color-primary);
  padding: var(--spacing-xs) var(--spacing-m);
  font-size: var(--text-size-m);
}
.markdown-viewer .click-to-expand-container--expand-button:focus {
  outline: none;
}
.markdown-viewer-truncation-text {
  font-weight: var(--text-weight-medium);
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--background-color-primary);
}
.markdown-viewer.truncated {
  height: calc(2 * var(--line-height-m));
  overflow: hidden;
}

comment {
  display: none;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.highlighted-code {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.highlighted-code * {
  box-sizing: border-box;
}
.highlighted-code__code-container {
  padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
  margin: var(--spacing-zero);
  color: var(--content-color-secondary);
  max-height: var(--container-max-height);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  position: relative;
  flex: 1;
  overflow: auto;
}
.highlighted-code__code-container code {
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.highlighted-code__code-container--word-wrapped code {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.highlighted-code__code-container--word-wrapped code .syntax-highlighted-code {
  white-space: pre-wrap;
}
.highlighted-code__config-container {
  display: flex;
  align-items: center;
  padding: var(--spacing-s);
}
.highlighted-code__config-container > * + * {
  margin-left: var(--spacing-s);
}
.highlighted-code__config-button {
  border: var(--border-width-default) var(--border-style-solid) transparent;
}
.highlighted-code__config-button--word-wrapped {
  background: var(--highlight-background-color-primary);
  border-color: var(--border-color-strong);
}
.highlighted-code__language-label {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
  padding: var(--spacing-s) var(--spacing-m);
  border-radius: var(--border-radius-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.highlighted-code__spacer {
  flex: 1;
}
.highlighted-code__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--size-m);
  border-radius: var(--spacing-zero) var(--spacing-zero) var(--border-radius-s) var(--border-radius-s);
  background: linear-gradient(180deg, rgba(189, 195, 199, 0) 0%, var(--grey-90) 100%, var(--grey-40) 100%);
  opacity: 0.1;
  mix-blend-mode: multiply;
  pointer-events: all;
}
.highlighted-code__expand-button {
  position: absolute;
  bottom: var(--spacing-s);
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-primary);
  padding: var(--spacing-xs) var(--spacing-m);
  font-size: var(--text-size-m);
  pointer-events: all;
  font-family: var(--text-family-default);
  border-radius: 999px;
}
.highlighted-code__expand-button:focus {
  outline: none;
}
.highlighted-code--documentation {
  --container-max-height: 200px;
}
.highlighted-code--documentation .highlighted-code__code-container {
  overflow: hidden;
}

.code-renderer {
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.code-renderer * {
  box-sizing: border-box;
}
.code-renderer__code-container {
  padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
  margin: var(--spacing-zero);
  color: var(--content-color-secondary);
  max-height: var(--container-max-height);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  position: relative;
  flex: 1;
  overflow: hidden;
}
.code-renderer__code-container.is-clipped {
  overflow: hidden;
}
.code-renderer__code-container code {
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
}
.code-renderer__code-container--word-wrapped code {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.code-renderer__code-container--word-wrapped code .syntax-highlighted-code {
  white-space: pre-wrap;
}

.documentation-publish-page-container {
  --publish-page-max-width: 750px;
  --publish-page-width: 60%;
  --button-height: 40px;
  display: flex;
  justify-content: center;
  color: var(--content-color-primary);
  height: 100%;
  min-height: 0;
  overflow-y: auto;
}
.documentation-publish-page-container .loading-indicator-wrapper {
  position: absolute;
  top: 50%;
}
.documentation-publish-page-container hr {
  display: block;
  height: var(--border-width-default);
  border: 0;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings {
  --input-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings {
    display: grid;
    grid-template-columns: 275px minmax(190px, 1fr);
    margin-left: var(--spacing-xxl);
  }
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings--limit-reached-banner {
  position: sticky;
  top: 0;
  z-index: 89;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar {
  display: flex;
  flex-direction: column;
  max-width: 275px;
  width: 30%;
  position: sticky;
  height: 250px;
  top: calc(2 * var(--spacing-xxl));
}
@media screen and (max-width: 1280px) {
  .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar {
    width: auto;
  }
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--collection-name {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-s);
  word-break: break-word;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--page-name {
  font-weight: bold;
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  margin: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--list {
  width: 275px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--list-item {
  height: var(--input-height);
  display: flex;
  align-items: center;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation ul {
  list-style: none;
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  color: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation li {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin: var(--spacing-zero) calc(var(--spacing-s) * -1);
  padding-left: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation .active-item {
  background: var(--background-color-brand);
  border-radius: var(--border-radius-default);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation__item {
  display: block;
  text-decoration: none;
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-zero);
  line-height: var(--line-height-l);
  color: var(--content-color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation__item:hover {
  color: var(--content-color-primary);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation .preview-documentation {
  display: inline-block;
  align-items: center;
  text-align: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  cursor: pointer;
  height: var(--input-height);
  margin-top: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content {
  display: flex;
  flex-direction: column;
  width: var(--publish-page-width);
  margin-left: 60px;
  margin-top: calc(2 * var(--spacing-xxl));
  margin-bottom: calc(2 * var(--spacing-xxl));
}
@media screen and (max-width: 1280px) {
  .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content {
    width: auto;
  }
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--main-label {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-l);
  margin-top: unset;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .documenter-preview-single-col {
  width: 360px;
  border-radius: var(--border-radius-default);
  margin-bottom: var(--spacing-xs);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .url--optional {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .url--helper-text--unverified {
  margin-top: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--sub-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
  margin-top: unset;
  display: inline;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .content--helper-text {
  display: inline-block;
  width: 48%;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .content--helper-text a {
  color: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .governance--helper-text {
  display: inline-block;
  width: 48%;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-zero) !important;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo {
  margin-bottom: 36px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .url--heading, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .url--heading, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .url--heading, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .url--heading {
  margin-bottom: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown {
  width: 392px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .aether-filter-select-dropdown__menu, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .aether-filter-select-dropdown__menu, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .aether-filter-select-dropdown__menu, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .aether-filter-select-dropdown__menu, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .aether-filter-select-dropdown__menu, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .aether-filter-select-dropdown__menu, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector .aether-filter-select-dropdown__menu, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown .aether-filter-select-dropdown__menu {
  width: 392px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown .select-dropdown-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown .select-dropdown-btn i > svg > path {
  fill: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown .select-dropdown-btn i > svg {
  color: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown .dropdown {
  width: 100%;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown .dropdown .btn-group-separated {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .documentation-config-bar-selector .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions--dropdown .dropdown .btn-group-separated .btn-text {
  border: none;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--versions {
  margin-bottom: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--environment, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--environment, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--environment, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .content--environment {
  margin-bottom: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .governance--textarea, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .governance--textarea, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .governance--textarea, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .governance--textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .loading-indicator-wrapper, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .loading-indicator-wrapper, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .loading-indicator-wrapper, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--seo .loading-indicator-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: var(--spacing-xxl);
  position: static;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .seo--title {
  margin-bottom: 28px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling {
  margin-bottom: var(--spacing-xxl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--logo {
  margin-top: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--logo--menu {
  margin-top: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--logo--image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 4px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors {
  margin-top: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--heading {
  margin-bottom: var(--spacing-m);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--heading--refresh {
  height: var(--spacing-l);
  width: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--heading--refresh svg {
  height: var(--spacing-m);
  width: var(--spacing-m);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--top-header {
  width: 124px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--header, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--code, .documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--highlight {
  width: 124px;
  margin-bottom: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--container-preview {
  background-color: var(--background-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--container-preview--default {
  margin-bottom: var(--spacing-xs);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--container-preview--default--tag {
  visibility: hidden;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker {
  display: flex;
  max-width: 110px;
  padding: 6px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker .input-box {
  padding: var(--spacing-zero);
  border: 0;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--input {
  height: 20px;
  text-transform: uppercase;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview {
  width: 20px;
  height: 20px;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: 50%;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview--invalid {
  background: var(--background-color-primary) !important;
  position: relative;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview--invalid:after {
  content: "";
  height: 20px;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  position: absolute;
  transform: rotate(45deg);
  left: 10px;
  top: 0px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview--invalid:before {
  content: "";
  height: 20px;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  position: absolute;
  transform: rotate(-45deg);
  left: 10px;
  top: 0px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--warning {
  color: var(--content-color-error);
  font-size: var(--text-size-xs);
  display: inline-block;
  margin-bottom: var(--spacing-xs);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--warning--hidden {
  visibility: hidden;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker .pm-form-control {
  text-transform: uppercase;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout {
  margin-bottom: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout > div:first-child {
  padding: var(--spacing-zero) !important;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout > div:first-child > ul:first-child {
  margin-top: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper {
  white-space: nowrap;
  height: var(--size-m);
  padding: 1px 2px;
  width: 196px;
  justify-content: space-around;
  display: block;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  padding: var(--spacing-xs);
  margin: 0.5px;
  height: 22px;
  width: 90px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch .plugin__workspace-view-active-item {
  top: 1px;
  position: relative;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .left {
  left: 2.5px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .right {
  left: 99px;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  display: inline-block;
  width: 90px;
  padding: var(--spacing-xs);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas {
  display: flex;
  justify-content: space-between;
  padding-bottom: calc(7 * var(--spacing-xxl));
  margin-top: var(--spacing-xxl);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons {
  display: inline-flex;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons .publish-doc-cancel-button {
  height: var(--button-height);
  background-color: var(--background-color-tertiary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons .publish-button {
  height: var(--button-height);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons .publish-button .loading-indicator-wrapper {
  position: static;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas .content--helper-text {
  margin-top: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-publish-settings .documentation-publish-settings-main-content .alert-wrapper {
  margin-top: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-default-link {
  color: inherit;
  text-decoration: underline;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-default-link__text {
  border-bottom: none;
}
.documentation-publish-page-container .documentation-publish-settings-page-customisation .documentation-default-link:hover {
  color: inherit;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status {
  display: flex;
  flex-direction: column;
  max-width: var(--publish-page-max-width);
  width: var(--publish-page-width);
  margin-top: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-breadcrumbs a:hover {
  border-bottom: none;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status a.documentation-published-breadcrumbs:hover {
  text-decoration: none !important;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header--buttons {
  display: inline-flex;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header div h1 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  display: inline;
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header .edit-settings-button {
  line-height: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header .unpublish-button {
  line-height: var(--text-size-m);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header .unpublish-button .loading-indicator-wrapper {
  position: static;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header .approve-button {
  line-height: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-header .approve-button .loading-indicator-wrapper {
  position: static;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--public-workspaces {
  margin-bottom: calc(3 * var(--spacing-xxxl));
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--token-banners {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--label {
  line-height: var(--line-height-m);
  letter-spacing: -0.12px;
  margin-top: var(--spacing-zero);
  color: var(--content-color-primary);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--limit-reached-banner {
  margin-bottom: var(--spacing-xl);
  position: sticky;
  top: 0;
  z-index: 89;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--private-collection-banner {
  margin-top: 24px;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--note {
  margin-top: 24px;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--content {
  margin: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--content--seo-heading {
  margin-top: var(--spacing-xs);
  font-weight: 600;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--content--collection-name {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--content--collection-name--value {
  font-size: 12px;
  font-weight: 400;
  color: var(--content-color-secondary);
  margin: var(--spacing-zero);
  line-height: var(--line-height-l);
  word-break: break-word;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--content--versions {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--content--versions--value {
  color: var(--content-color-secondary);
  margin: var(--spacing-zero);
  line-height: var(--line-height-l);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--content--note-to-cm {
  color: var(--content-color-secondary);
  line-height: var(--line-height-l);
  background-color: var(--background-color-secondary);
  padding: 12px 10px;
  border-radius: var(--border-radius-default);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--seo {
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--environment {
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--environment--top {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--environment--environment {
  margin-top: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--url {
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--url a {
  color: var(--content-color-link);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--url a:hover {
  cursor: pointer;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--copy-to-clipboard {
  margin-left: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--styling {
  margin: var(--spacing-l) var(--spacing-zero);
  margin-top: var(--spacing-xxl);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--container-preview {
  margin-top: var(--spacing-s);
  background-color: var(--background-color-secondary);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--container-preview--wrapper {
  flex: 1;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--container-preview .documenter-preview-single-col {
  width: 100%;
  border-radius: var(--border-radius-default);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--container-preview--default--tag {
  visibility: hidden;
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--collection-discovery .documentation-published-status-body--heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--collection-discovery__section {
  margin-bottom: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-status-page-customisation .documentation-published-status .documentation-published-status-body--collection-discovery__section h4 {
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
}

.select-dropdown-container .dropdown-content--dropdown-menu, .select-dropdown-container .dropdown-menu {
  max-width: 360px;
  width: 28.8%;
}
.select-dropdown-container .dropdown-content--dropdown-menu .select-dropdown, .select-dropdown-container .dropdown-menu .select-dropdown {
  width: unset;
}

.documentation-dropdown {
  --publish-page-dropdown-max-height: calc(10 * var(--size-xs));
}
.documentation-dropdown--publish-page .documentation-dropdown-menu-container, .documentation-dropdown--publish-page .dropdown-menu {
  max-height: var(--publish-page-dropdown-max-height) !important;
  max-width: 360px;
  width: 28.8%;
  overflow: auto;
}
.documentation-dropdown--publish-page .documentation-dropdown-menu-container .select-dropdown, .documentation-dropdown--publish-page .dropdown-menu .select-dropdown {
  width: unset;
}
.documentation-dropdown--empty .select-dropdown-btn {
  color: var(--content-color-tertiary) !important;
}

.documentation-published-status-modal__remove-btn {
  background-color: var(--base-color-error);
}
.documentation-published-status-modal__remove-btn:hover {
  background-color: var(--content-color-error);
}

.content--versions--dropdown-filter-select__popover div[class*=StyledClearButtonContainer] {
  display: none;
}

.doc-workspace-list-item {
  display: flex;
  height: var(--size-m);
  align-items: center;
  cursor: pointer;
}
.doc-workspace-list-item .loading-indicator {
  background-color: var(--content-color-secondary);
  max-height: 10px;
}
.doc-workspace-list-item .loading-indicator:before {
  background-color: var(--content-color-secondary);
  max-height: 10px;
}
.doc-workspace-list-item .loading-indicator:after {
  background-color: var(--content-color-secondary);
  max-height: 10px;
}
.doc-workspace-list-item span {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}
.doc-workspace-list-item .doc-workspace__join {
  display: flex;
  align-items: center;
  padding: 6px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
  height: var(--controls-size-small);
  border-radius: var(--border-radius-s);
  background: var(--background-color-tertiary);
  color: var(--content-color-secondary);
}
.doc-workspace-list-item .doc-workspace__shared {
  display: flex;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  opacity: 1;
  color: var(--content-color-secondary);
}
.doc-workspace-list-item .doc-workspace-list-item__name {
  flex: 1;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-left: var(--spacing-s);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.doc-workspace-list-item.is-disabled {
  opacity: 0.5;
  cursor: default;
}

.skeleton-unit {
  position: absolute;
  background-color: var(--background-color-tertiary);
  animation: fade 1s linear infinite;
}

.documentation-workbench-loader {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.documentation-workbench-loader .documentation-workbench-body {
  position: relative;
  height: 100%;
  width: 100%;
}
.documentation-workbench-loader .documentation-workbench-body--right {
  position: absolute;
}

.documentation-scratchpad-prompt {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  height: 100%;
  justify-content: center;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.documentation-scratchpad-prompt__icon-container {
  height: calc(2 * var(--size-m));
  width: calc(2 * var(--size-m));
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.documentation-scratchpad-prompt__icon-container .documentation-scratchpad-prompt__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.documentation-scratchpad-prompt__icon-container .documentation-scratchpad-prompt__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}
.documentation-scratchpad-prompt__title {
  color: var(--content-color-secondary);
  padding: var(--spacing-xs) var(--spacing-xxxl);
  text-align: center;
}
.documentation-scratchpad-prompt__title h4 {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-zero);
  margin-top: var(--spacing-l);
}
.documentation-scratchpad-prompt__body {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
  padding: var(--spacing-xs) var(--spacing-m);
  text-align: center;
}
.documentation-scratchpad-prompt__action {
  justify-content: start;
}
.documentation-scratchpad-prompt__action__icon {
  margin-right: var(--spacing-s);
}
.documentation-scratchpad-prompt__action__icon use {
  fill: var(--content-color-brand);
}
.documentation-scratchpad-prompt__action__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.documentation-scratchpad-prompt__action__icon svg {
  color: var(--content-color-brand);
}

.sticky-navigation-bar__container {
  padding-top: var(--spacing-xl);
  background-color: var(--background-color-primary);
  min-width: 0;
}

.floating-navigation-bar {
  --max-container-height: calc(15 * var(--size-s));
  --container-width: calc(11 * var(--size-s));
}
.floating-navigation-bar__container {
  position: absolute;
  bottom: calc(var(--spacing-xxl) + var(--spacing-xl));
  right: var(--spacing-xxl);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 300;
}
.floating-navigation-bar__container-api {
  bottom: var(--spacing-xl);
  right: var(--spacing-xxl);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 300;
  position: absolute;
}
.floating-navigation-bar__content-container {
  max-height: var(--max-container-height);
  overflow: auto;
  background-color: var(--background-color-primary);
  box-shadow: var(--shadow-default);
  padding: var(--spacing-l);
  border-radius: var(--border-radius-default);
  margin-bottom: var(--spacing-m);
  width: var(--container-width);
}
.floating-navigation-bar__content-container .side-navigation {
  position: relative;
}
.floating-navigation-bar__toggle-button {
  padding: var(--spacing-m);
  background-color: var(--background-color-primary);
  box-shadow: var(--shadow-default);
  border-radius: var(--border-radius-max);
  border: none;
  cursor: pointer;
}
.floating-navigation-bar__toggle-button > i {
  display: block;
}

.share-to-workspace-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-xxl);
}
.share-to-workspace-container .share-to-workspace-heading {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-family: var(--text-family-default);
  letter-spacing: -0.12px;
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-l);
}
.share-to-workspace-container .share-to-workspace-permissions-banner {
  margin-bottom: var(--spacing-l);
}
.share-to-workspace-container .share-to-workspace-optional {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
}
.share-to-workspace-container .share-to-workspace-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-zero);
  color: var(--content-color-secondary);
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .workspace-helper-text {
  display: inline-block;
  width: 48%;
  position: relative;
  top: -4px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown {
  display: inline-block;
  width: 48%;
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  height: var(--size-m);
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown .input-select-wrapper {
  background-color: var(--background-color-primary) !important;
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown .select-dropdown-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--input-height);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown .select-dropdown-btn i > svg > path {
  fill: var(--content-color-secondary);
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown .select-dropdown-btn i > svg {
  color: var(--content-color-secondary);
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown .dropdown {
  width: 100%;
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown .dropdown .btn-group-separated {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.share-to-workspace-container .share-to-workspace-dropdown-and-text .public-workspace-dropdown .dropdown .btn-group-separated .btn-text {
  border: none;
}

.share-to-workspace-dropdown {
  --publish-page-dropdown-max-height: 250px;
  --dropdown-max-width: calc(750px * 0.48);
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container,
.share-to-workspace-dropdown .dropdown-menu {
  max-height: var(--publish-page-dropdown-max-height) !important;
  max-width: var(--dropdown-max-width);
  width: 28.8%;
  overflow: auto;
  background-color: var(--background-color-primary);
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .input-search-group,
.share-to-workspace-dropdown .dropdown-menu .input-search-group {
  background-color: var(--background-color-primary);
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .select-dropdown,
.share-to-workspace-dropdown .dropdown-menu .select-dropdown {
  width: unset;
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item.is-disabled,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item.is-disabled {
  opacity: 1;
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item.is-disabled .doc-workspace-list-item__name,
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item.is-disabled i,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item.is-disabled .doc-workspace-list-item__name,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item.is-disabled i {
  opacity: 0.4;
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item.is-disabled:hover,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item.is-disabled:hover {
  background-color: var(--background-color-primary) !important;
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item .is-hovered,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item .is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item .documentation-dropdown-item,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item .documentation-dropdown-item {
  width: 100%;
  padding: 0;
}
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item .documentation-dropdown-item span,
.share-to-workspace-dropdown .documentation-dropdown-menu-container .dropdown-menu-item .documentation-dropdown-item .doc-workspace__join,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item .documentation-dropdown-item span,
.share-to-workspace-dropdown .dropdown-menu .dropdown-menu-item .documentation-dropdown-item .doc-workspace__join {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
}

.share-to-workspace-empty-dropdown {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-xxxl) var(--spacing-s);
  align-items: center;
  justify-content: space-evenly;
}
.share-to-workspace-empty-dropdown > svg {
  height: 44px;
  width: 44px;
}
.share-to-workspace-empty-dropdown > p {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  margin-top: var(--spacing-xl);
}

.documentation-published-status-body--workspace-list {
  display: flex;
  align-items: center;
}
.documentation-published-status-body--workspace-list a {
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
}
.documentation-published-status-body--workspace-list a:hover {
  color: var(--content-color-link);
  text-decoration: none;
}

.share-to-public-workspace-dropdown {
  display: flex;
  flex-direction: row;
  height: 284px;
}

.share-to-public-workspace-dropdown-menu {
  background-color: var(--background-color-primary);
  max-height: 322px;
  padding: var(--spacing-xs) var(--spacing-zero);
  box-sizing: border-box;
  min-width: 170px;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--hairline-strong--shade--one);
  box-shadow: var(--shadow-default);
  padding-bottom: var(--spacing-zero);
}
.share-to-public-workspace-dropdown-menu__spinner {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-xs);
}
.share-to-public-workspace-dropdown-menu__list {
  height: calc(8 * var(--size-m));
  overflow-y: scroll;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--background-color-primary);
}
.share-to-public-workspace-dropdown-menu__list__no-results {
  color: var(--content-color-secondary);
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  padding: 6px var(--spacing-l) 6px var(--spacing-l);
}
.share-to-public-workspace-dropdown-menu__list__button {
  cursor: pointer;
  width: 100%;
  background-color: var(--background-color-primary);
  height: var(--size-m);
  box-shadow: inset 0px 1px 0px var(--highlight-background-color-tertiary);
  border-radius: 0px 0px 3px 3px;
  color: var(--content-color-secondary);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  justify-content: start;
  padding: var(--spacing-s) var(--spacing-zero) var(--spacing-s) 18px;
}
.share-to-public-workspace-dropdown-menu__list__button.btn.is-disabled {
  color: var(--content-color-secondary) !important;
}
.share-to-public-workspace-dropdown-menu__list__button:hover {
  color: var(--content-color-brand);
}

.share-to-public-workspace-dropdown-item {
  background-color: var(--background-color-primary);
}

.input-select-wrapper {
  background-color: var(--background-color-tertiary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: var(--controls-size-default);
  position: relative;
  width: 210px;
}
.input-select-wrapper.is-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.input-select-wrapper.is-disabled .input {
  cursor: not-allowed;
}
.input-select-wrapper.highlight {
  background-color: var(--highlight-background-color-secondary);
}
.input-select-wrapper:hover, .input-select-wrapper.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.input-select-wrapper.is-open {
  background-color: var(--background-color-primary);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.input-select-wrapper .input-search-group {
  flex: 1;
  background: transparent;
  border: 0;
  border-radius: 0;
  padding-right: var(--spacing-zero);
}
.input-select-wrapper .input-search-group .input {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
}
.input-select-wrapper .input-search-group .input::-webkit-input-placeholder {
  font-size: var(--text-size-m);
}
.input-select-wrapper .dropdown-button {
  align-self: center;
  border-left: 0;
  background: transparent;
  border-radius: 0;
  flex: 0 0 30px;
  height: var(--controls-size-default);
  margin-left: auto;
  padding: var(--spacing-zero);
}
.input-select-wrapper .dropdown-button .dropdown-caret {
  margin-left: var(--spacing-zero);
}

.input-select-dropdown-menu .dropdown-menu-item {
  cursor: pointer;
}
.input-select-dropdown-menu .dropdown-menu-item.is-disabled {
  opacity: 1;
}
.input-select-dropdown-menu .dropdown-menu-item.is-disabled .doc-workspace-list-item__name,
.input-select-dropdown-menu .dropdown-menu-item.is-disabled i {
  opacity: 0.4;
}
.input-select-dropdown-menu .dropdown-menu-item.is-disabled:hover {
  background-color: var(--background-color-primary) !important;
}
.input-select-dropdown-menu .dropdown-menu-item .is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.input-select-dropdown-menu .dropdown-menu-item.is-selected {
  background-color: var(--highlight-background-color-primary);
}
.input-select-dropdown-menu .dropdown-menu-item .input-select-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.input-select-dropdown-menu .dropdown-menu-item .item-wrapper {
  overflow: hidden;
  display: flex;
  flex: 1;
  align-items: center;
}
.input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions {
  visibility: hidden;
  display: flex;
}
.input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--size-m);
  width: var(--size-m);
}
.input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action:hover use, .input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action.is-hovered use {
  fill: var(--content-color-brand);
}
.input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action:hover svg:not([class*=PartnerIcon]) > path[fill], .input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action:hover svg, .input-select-dropdown-menu .dropdown-menu-item .item-wrapper .item-actions .item-action.is-hovered svg {
  color: var(--content-color-brand);
}
.input-select-dropdown-menu .dropdown-menu-item:hover .item-wrapper .item-actions, .input-select-dropdown-menu .dropdown-menu-item.is-hovered .item-wrapper .item-actions {
  visibility: visible;
}
.input-select-dropdown-menu .dropdown-menu-item.is-selected .highlight {
  color: var(--content-color-brand);
}
.input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--save_method {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result {
  color: var(--content-color-tertiary);
  cursor: default;
}
.input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result:hover, .input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result.is-hovered {
  background-color: inherit;
}
.input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_result.is-focused {
  background-color: inherit;
}
.input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods {
  color: var(--content-color-tertiary);
  cursor: default;
}
.input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods:hover, .input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods.is-hovered {
  background-color: inherit;
}
.input-select-dropdown-menu .dropdown-menu-item.dropdown-menu-item--no_methods.is-focused {
  background-color: inherit;
}

.create-new-public-workspace-modal .modal-header {
  background-color: var(--background-color-primary);
  height: calc(2 * var(--size-m));
}
.create-new-public-workspace-modal .modal-header .modal-header-title {
  padding: var(--spacing-m) var(--spacing-xl);
  color: var(--content-color-primary);
}
.create-new-public-workspace-modal__create-workspace-label {
  color: var(--content-color-secondary);
  margin: var(--spacing-zero);
}
.create-new-public-workspace-modal__content {
  padding: var(--spacing-zero) var(--spacing-xl) var(--spacing-zero) var(--spacing-xl);
}
.create-new-public-workspace-modal__input-group__loading-indicator {
  height: var(--size-m);
}
.create-new-public-workspace-modal__input-group__loading-indicator .loading-indicator {
  top: 15%;
}
.create-new-public-workspace-modal__input-group__label {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  font-family: var(--text-family-default);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-xs);
}
.create-new-public-workspace-modal__input-group__visibility {
  margin-bottom: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.create-new-public-workspace-modal__input-group__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: calc(2 * var(--spacing-xxl));
}
.create-new-public-workspace-modal__input-group__cancel-button {
  margin-right: var(--spacing-s);
}
.create-new-public-workspace-modal__input-group--error {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-l);
  color: var(--content-color-error);
}
.create-new-public-workspace-modal__input-group--error .pm-icon {
  margin-right: var(--spacing-s);
}
.create-new-public-workspace-modal__input-group--error .pm-icon use {
  fill: var(--content-color-error);
}
.create-new-public-workspace-modal__input-group--error .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.create-new-public-workspace-modal__input-group--error .pm-icon svg {
  color: var(--content-color-error);
}

.documentation-split-container {
  display: flex;
  justify-content: space-between;
  height: calc(2 * var(--size-s));
  align-items: center;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: var(--spacing-zero) var(--spacing-l);
}
.documentation-split-container__left-deck {
  display: flex;
}
.documentation-split-container__left-deck > * + * {
  margin-left: var(--spacing-l);
}
.documentation-split-container__right-deck {
  display: flex;
}
.documentation-split-container__right-deck > * + * {
  margin-left: var(--spacing-l);
}
.documentation-split-container .config-bar-button {
  height: var(--size-m);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.documentation-split-container .config-bar-button.published {
  color: var(--content-color-success);
}
.documentation-split-container .config-bar-button.published .dropdown-caret svg > path {
  fill: var(--content-color-success);
}
.documentation-split-container .config-bar-button.published .dropdown-caret svg {
  color: var(--content-color-success);
}
.documentation-split-container .config-bar-button .config-bar-icon {
  margin-right: var(--spacing-xs);
}
.documentation-split-container .config-bar-button .config-bar-icon.published-icon svg {
  color: var(--content-color-success);
}
.documentation-split-container .dropdown-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
.documentation-split-container .dropdown-container label {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
.documentation-split-container .dropdown-container .btn-text {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
}
.documentation-split-container .dropdown-container .btn-text use {
  fill: var(--content-color-primary);
}
.documentation-split-container .dropdown-container .btn-text svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.documentation-split-container .dropdown-container .btn-text svg {
  color: var(--content-color-primary);
}
.documentation-split-container .dropdown-container .dropdown-caret use {
  fill: var(--content-color-primary);
}
.documentation-split-container .dropdown-container .dropdown-caret svg {
  color: var(--content-color-primary);
}
.documentation-split-container .dropdown-container .language-settings-icon {
  cursor: pointer;
}

.api-doc-offline-state-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.api-doc-offline-state-wrapper__heading {
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
}

.empty-documentation-state-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty-documentation-state-wrapper__illustration {
  margin-bottom: var(--spacing-l);
}
.empty-documentation-state-wrapper__heading {
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
}
.empty-documentation-state-wrapper__description {
  text-align: center;
  color: var(--content-color-secondary);
}
.empty-documentation-state-wrapper__components {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
}

.custom-domains--delete-container {
  display: flex;
  justify-content: flex-end;
  padding-right: var(--spacing-s) !important;
}
.custom-domains--delete-container--delete-button {
  cursor: pointer;
  visibility: hidden;
  position: relative;
  top: 3px;
}
.custom-domains .custom-domains-table--status-column {
  display: flex;
  align-items: center;
}
.custom-domains .custom-domains-table--badge {
  margin-left: var(--spacing-zero);
}
.custom-domains .info-button {
  display: inline-block;
}
.custom-domains .entity-learn-more-link {
  color: var(--content-color-secondary);
  cursor: pointer;
}
.custom-domains .entity-learn-more-link:hover {
  color: var(--content-color-link);
}

.custom-domains-detail .record-addition-heading {
  margin-bottom: var(--spacing-s);
}
.custom-domains-detail .verification-records-heading {
  margin-bottom: var(--spacing-l);
  margin-top: -8px;
}
.custom-domains-detail .txt-record-heading {
  margin-bottom: -16px;
}
.custom-domains-detail .custom-domains-copy-button-cname {
  position: relative;
  top: 40px;
}
.custom-domains-detail .custom-domains-copy-button-txt {
  position: relative;
  top: 65px;
}

.delete-custom-domain-modal-content {
  font-size: var(--text-size-m);
}

.api-documentation-wrapper {
  height: 100%;
}

.documentation-tab-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.documentation-tab-wrapper-legacy {
  height: 100%;
  overflow: hidden;
}
.documentation-tab-sidebar {
  border-right: var(--border-width-l) var(--border-style-solid) var(--border-color-default);
  max-height: 100%;
  width: 285px;
  max-width: 285px;
  padding: var(--spacing-s);
  overflow-x: auto;
  overflow-y: auto;
  box-sizing: border-box;
}
.documentation-tab-sidebar .sidebar-list-item__name-wrapper {
  display: flex;
  align-items: center;
}
.documentation-tab-sidebar .schema-doc-invalid-yaml-json .sidebar-list-item__name-wrapper {
  color: var(--content-color-secondary);
  font-style: italic;
}
.documentation-tab-sidebar-heading {
  margin-left: var(--spacing-s);
}
.documentation-tab-sidebar-empty-state {
  margin: 0;
  margin-left: var(--spacing-s);
}
.documentation-tab-sidebar-button {
  height: 24px;
  width: 24px;
}
.documentation-tab-sidebar .schema-documentation-sidebar .documentation-list__folder {
  display: flex;
  justify-content: flex-start;
  padding: 2px var(--spacing-zero) 2px var(--spacing-zero);
}
.documentation-tab-sidebar .schema-documentation-sidebar .documentation-list__item-name {
  min-height: 32px;
  max-height: 64px;
  padding-top: 0px;
  cursor: pointer;
}
.documentation-tab-sidebar .schema-documentation-sidebar .documentation-list__item-error {
  margin-left: auto;
}
.documentation-tab-sidebar .sidebar-list-item {
  padding-left: var(--spacing-zero);
  height: 32px;
}
.documentation-tab-sidebar .sidebar-list-item__content-wrapper {
  margin-left: var(--spacing-zero);
}
.documentation-tab-sidebar .sidebar-list-item.selected {
  background-color: var(--background-color-tertiary);
}
.documentation-tab-sidebar .sidebar-list-item.selected + .collection-documentation-folder-container {
  margin-top: var(--spacing-s);
}
.documentation-tab-sidebar .sidebar-list-item.selected + .schema-folder-container {
  margin-top: var(--spacing-s);
}
.documentation-tab-sidebar .documentation-list__title {
  text-transform: uppercase;
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  margin-bottom: var(--spacing-s);
}
.documentation-tab-sidebar .documentation-list__item {
  display: contents;
}
.documentation-tab-sidebar .documentation-list__items-container {
  /**
  * Displaying the icons in grid allows us to indent all the items description
  * and icons correctly as shown below without the need to hard code any
  * min-width or max-width
  *
  * icon-1                   description-1
  * long-icon-1 .            description-2
  * long-long-long-icon-2    description-3
  */
  display: grid;
  /**
  * max-content is meant to specify that the width of the icon columns would
  * the max-width of the icon and the rest area would be taken by its description
  */
  grid-template-columns: max-content 1fr;
  row-gap: var(--spacing-l);
  column-gap: var(--spacing-s);
  padding-bottom: var(--spacing-m);
  align-items: flex-start;
}
.documentation-tab-sidebar .documentation-list__item-name {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  word-break: break-word;
  min-height: 30px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-top: 2px;
}
.documentation-tab-sidebar .documentation-list__schema-path-icon {
  position: relative;
  top: 2px;
}
.documentation-tab-sidebar .documentation-list__request-item-icon {
  padding: 2px !important;
  word-break: initial;
}
.documentation-tab-sidebar .documentation-list__item-icon {
  display: inherit;
  justify-self: end;
}
.documentation-tab-sidebar .documentation-list__item-icon.with-padding {
  padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-xs) var(--spacing-zero);
}
.documentation-tab-sidebar .documentation-list__item-icon .icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: var(--spacing-zero) !important;
  padding: var(--spacing-zero);
  display: inherit;
}
.documentation-tab-sidebar .documentation-list__item-icon .icon-button:active {
  outline: none;
}
.documentation-tab-sidebar .documentation-list__item-icon .list-icon {
  vertical-align: middle;
}
.documentation-tab-sidebar .documentation-list__folder {
  display: flex;
  justify-content: flex-start;
  padding: 4px var(--spacing-zero) 2px var(--spacing-zero);
}
.documentation-tab-sidebar .documentation-list__folder-icon {
  display: block;
}
.documentation-tab-sidebar .documentation-list__folder-icon .list-icon {
  vertical-align: unset;
}
.documentation-tab-sidebar .documentation-list-item__marker {
  width: 0;
  height: 0;
  align-self: center;
}
.documentation-tab-sidebar .documentation-list-item__marker--visible {
  margin-right: var(--spacing-s);
  margin-bottom: var(--spacing-s);
  width: var(--spacing-s);
  height: var(--spacing-s);
  border-radius: var(--border-radius-max);
  background-color: var(--content-color-brand);
}
.documentation-tab-sidebar .collection-documentation-folder-container {
  display: grid;
  grid-template-columns: min-content min-content 1fr min-content;
  gap: 0px 6px;
  align-items: flex-start;
  margin-left: 10px;
}
.documentation-tab-sidebar .collection-documentation-folder-container .children-container {
  grid-column: span 4;
}
.documentation-tab-sidebar .collection-documentation-folder-container .collection-documentation-folder-container {
  grid-template-columns: var(--spacing-l) min-content 1fr min-content;
}
.documentation-tab-sidebar .schema-folder-container {
  display: grid;
  grid-template-columns: min-content min-content 1fr min-content;
  gap: 0px 6px;
  align-items: flex-start;
  margin-left: 10px;
}
.documentation-tab-sidebar .schema-folder-container .children-container {
  grid-column: span 4;
}
.documentation-tab-sidebar .schema-folder-container .schema-folder-container {
  grid-template-columns: 0px var(--spacing-xxl);
}
.documentation-tab-sidebar .doc-warning-icon {
  display: flex;
  margin-left: var(--spacing-s);
  align-items: center;
}
.documentation-tab-sidebar-title {
  margin: var(--spacing-zero) 1px;
  margin-bottom: var(--spacing-s);
  background-color: var(--background-color-tertiary);
}
.documentation-tab-content {
  height: 100%;
  flex: 1;
  overflow: auto;
}
.documentation-tab-content__network {
  --maxContentWidth: 1224px;
  --sides-padding: max(calc((90vw - var(--maxContentWidth)) / 2), var(--spacing-xxl));
  padding-right: var(--sides-padding);
}
.documentation-tab__generate-documentation {
  width: 185px;
  height: var(--size-m);
}
.documentation-tab__add-existing-documentation {
  width: 185px;
  height: var(--size-m);
  margin-top: var(--spacing-l);
}

.create-documentation-entity-modal .modal-content {
  line-height: var(--line-height-m);
  padding: var(--spacing-l);
  display: flex;
}
.create-documentation-entity-modal .modal-content .create-documentation-entity-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0;
}
.create-documentation-entity-modal .modal-content .create-documentation-entity-wrapper .intro-content-wrapper {
  margin-bottom: var(--spacing-xl);
}
.create-documentation-entity-modal .modal-content .create-documentation-entity-wrapper .intro-content-wrapper .intro-subtitle_learn-more {
  color: var(--content-color-link);
  cursor: pointer;
}
.create-documentation-entity-modal .modal-content .create-documentation-entity-wrapper .collection-name-input-wrapper {
  margin-bottom: var(--spacing-xl);
}
.create-documentation-entity-modal .modal-content .create-documentation-entity-wrapper .collection-name-input-wrapper .collection-name-input-header {
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-s);
}
.create-documentation-entity-modal .modal-content .create-documentation-entity-wrapper .collection-name-input-wrapper .collection-name-input {
  width: 60%;
}
.create-documentation-entity-modal .modal-footer {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.create-documentation-entity-modal .modal-footer .actions {
  display: flex;
  flex-basis: 55%;
}
.create-documentation-entity-modal .modal-footer .actions .btn {
  font-weight: var(--text-weight-medium);
  height: var(--size-m);
}
.create-documentation-entity-modal .modal-footer .actions .btn.btn-secondary {
  margin-right: var(--spacing-s);
}
.create-documentation-entity-modal .modal-footer .actions .btn.btn-primary {
  flex: 1;
}

.api-documentation-dropdown .api-documentation-dropdown-button-icon {
  display: flex;
  margin-left: var(--spacing-xs);
}
.api-documentation-dropdown-button {
  max-width: 200px;
}
.api-documentation-dropdown .api-documentation-item {
  display: flex;
  margin-right: var(--spacing-s);
  width: 85%;
}
.api-documentation-dropdown-menu-item.dropdown-menu-item {
  margin: 0 var(--spacing-xs);
  padding: 0 var(--spacing-xs) 0 var(--spacing-m);
  border-radius: var(--border-radius-default);
}
.api-documentation-dropdown-menu-item:hover .api-documentation-dropdown__more-actions {
  display: initial;
}
.api-documentation-dropdown-menu-item.is-selected {
  background-color: var(--highlight-background-color-secondary);
}
.api-documentation-dropdown__more-actions {
  display: none;
  margin-left: auto;
}
.api-documentation-dropdown__extra-actions {
  border-top: var(--border-width-default) solid var(--border-color-default);
}
.api-documentation-dropdown .dropdown-menu {
  width: 280px;
}
.api-documentation-dropdown__divider {
  border-top: 1px solid var(--border-color-strong);
  margin: var(--spacing-s) 0;
}

.documentation-overview-container {
  position: relative;
  overflow: hidden;
  max-height: 390px;
  background: var(--background-color-secondary);
  padding: var(--spacing-xl) var(--spacing-xxl);
}
.documentation-overview-container .collection-container .documentation-core-item-name__text {
  margin-top: 0px;
  padding: var(--spacing-s);
}
.documentation-overview-container.error-container {
  height: auto;
}
.documentation-overview-container.error-container .documentation-overview-container-content {
  display: flex;
  align-items: center;
}
.documentation-overview-container.empty-container {
  height: auto;
  text-align: center;
}
.documentation-overview-container-link {
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: flex-end;
  padding-bottom: var(--spacing-l);
  padding-right: var(--spacing-xxxl);
  height: 50%;
  display: flex;
  justify-content: right;
  background: linear-gradient(180deg, transparent 0%, var(--background-color-tertiary) 82.81%);
}
.documentation-overview-container-content {
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.documentation-overview-container .documentation-overview-container-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxl);
}
.documentation-overview-container .documentation-overview-container-content .collection-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  word-break: break-word;
  flex: 1;
}
.documentation-overview-container .documentation-overview-container-content .collection-container .description-editor-container {
  height: 100%;
}
.documentation-overview-container .documentation-overview-container-content .collection-container .description-editor-container .description-editor-wrapper {
  height: 100%;
}
.documentation-overview-container .documentation-overview-container-content .folder-container {
  flex-direction: column;
  gap: var(--spacing-l);
  word-break: break-word;
}
.documentation-overview-container .documentation-overview-container-content .request-container {
  flex-direction: column;
  gap: var(--spacing-l);
  word-break: break-word;
  position: relative;
}
.documentation-overview-container .documentation-overview-container-content .request-container code[data-aether-id=aether-text-code] {
  font-size: var(--text-size-m);
}
.documentation-overview-container .documentation-overview-container-content .request-container .aether-dropdown__control {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.documentation-overview-container .documentation-overview-container-content .request-container .aether-dropdown__control--is-focused {
  border: none !important;
  box-shadow: none !important;
}

.documentation-overview-header__collection-name-wrapper {
  padding: var(--spacing-m) var(--spacing-xs);
  cursor: pointer;
}
.documentation-overview-header__arrow-icon {
  display: flex;
}

.schema-documentation-overview-container {
  position: relative;
  overflow: hidden;
  max-height: 400px;
  min-height: 48px;
  background: var(--background-color-secondary);
  box-shadow: 0px 1px 0px 0px var(--highlight-background-color-primary) inset;
  width: 100%;
  height: auto;
}
.schema-documentation-overview-container.error-container {
  height: auto;
  padding: var(--spacing-l) var(--spacing-xl);
}
.schema-documentation-overview-container.error-container .schema-documentation-overview-container-content {
  display: flex;
  align-items: center;
}
.schema-documentation-overview-container.empty-container {
  height: auto;
  padding: var(--spacing-l) var(--spacing-xl);
}
.schema-documentation-overview-container-link {
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: flex-end;
  padding-bottom: var(--spacing-l);
  padding-right: var(--spacing-xxl);
  right: 0;
  height: 40%;
  display: flex;
  justify-content: right;
  background: linear-gradient(180deg, transparent 0%, var(--background-color-tertiary) 82.81%);
}
.schema-documentation-overview-container-content {
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.schema-documentation-overview-error-message {
  margin-right: var(--spacing-m);
}

.schema-documentation-overview-empty-message {
  margin-right: var(--spacing-m);
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
}
.ReactCrop:focus {
  outline: none;
}
.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__image {
  display: block;
  max-width: 100%;
  touch-action: none;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: none;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}
.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}
.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: 33.3333333333%;
}
.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: 66.6666666667%;
}
.ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}
.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: 33.3333333333%;
}
.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: 66.6666666667%;
}
.ReactCrop__drag-handle {
  position: absolute;
}
.ReactCrop__drag-handle::after {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-n::after {
  top: 0;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-e::after {
  right: 0;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-s::after {
  bottom: 0;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop .ord-w::after {
  left: 0;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }
  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

.documentation-logo-upload {
  text-align: center;
}
.documentation-logo-upload__input {
  position: relative;
  border: var(--border-width-l) dashed var(--border-color-default);
  border-radius: var(--border-radius-default);
  padding: calc(var(--spacing-xxl) * 2) 0;
}
.documentation-logo-upload__input input {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.documentation-logo-upload__input-prompt {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  text-align: center;
  margin: var(--spacing-l) 0;
}
.documentation-logo-upload__input-prompt-drag {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  text-align: center;
  margin: var(--spacing-l) 0;
  font-weight: var(--text-weight-bold);
}
.documentation-logo-upload__input-button {
  padding: var(--spacing-zero);
  transform: translateX(0);
}
.documentation-logo-upload__input-button input {
  cursor: pointer;
}
.documentation-logo-upload__input-button label {
  height: 100%;
  display: block;
  cursor: pointer;
  line-height: var(--line-height-xxxl);
  padding: 0 var(--spacing-l);
}

.documentation-publish-page-container {
  --publish-page-max-width: 750px;
  --publish-page-width: 60%;
  --button-height: 40px;
  display: flex;
  justify-content: center;
  color: var(--content-color-primary);
  height: 100%;
  min-height: 0;
  overflow-y: auto;
}
.documentation-publish-page-container .loading-indicator-wrapper {
  position: absolute;
  top: 50%;
}
.documentation-publish-page-container hr {
  display: block;
  height: var(--border-width-default);
  border: 0;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings {
  --input-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings--limit-reached-banner {
  position: sticky;
  top: 0;
  z-index: 89;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar {
  display: flex;
  flex-direction: column;
  max-width: 275px;
  width: 30%;
  position: sticky;
  top: 0;
  margin: var(--spacing-xxl) var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--collection-name {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-s);
  word-break: break-word;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--page-name {
  font-weight: bold;
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  margin: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--list {
  width: 275px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar--list-item {
  height: var(--input-height);
  display: flex;
  align-items: center;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation ul {
  list-style: none;
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  color: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation li {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin: var(--spacing-zero) calc(var(--spacing-s) * -1);
  padding-left: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation .active-item {
  background: var(--background-color-brand);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation__item {
  display: block;
  text-decoration: none;
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-zero);
  line-height: var(--line-height-l);
  color: var(--content-color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation__item:hover {
  color: var(--content-color-primary);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation .preview-documentation {
  color: var(--content-color-link);
  display: flex;
  align-items: center;
  text-align: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  cursor: pointer;
  height: var(--input-height);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation .preview-documentation i > svg {
  color: var(--content-color-link);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation .preview-documentation span {
  padding-left: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-left-sidebar .pm-sidebar-navigation .preview-label {
  color: var(--content-color-link);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content {
  display: flex;
  flex-direction: column;
  max-width: var(--publish-page-max-width);
  width: var(--publish-page-width);
  margin-left: 60px;
  margin-top: calc(2 * var(--spacing-xxl));
  margin-bottom: calc(2 * var(--spacing-xxl));
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--main-label {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-l);
  margin-top: unset;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--sub-label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xs);
  margin-top: unset;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content .content--helper-text {
  display: inline-block;
  width: 48%;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-tertiary);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content .governance--helper-text {
  display: inline-block;
  width: 48%;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-tertiary);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance {
  margin-bottom: 36px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .url--header-container, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .url--header-container, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .url--header-container {
  margin-bottom: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .url--header-container .url--heading, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .url--header-container .url--heading, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .url--header-container .url--heading {
  display: inline;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .url--header-container .url--optional, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .url--header-container .url--optional, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .url--header-container .url--optional {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown {
  display: inline-block;
  width: 48%;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  height: 32px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .select-dropdown-btn, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .select-dropdown-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .select-dropdown-btn i > svg > path, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .select-dropdown-btn i > svg > path {
  fill: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .select-dropdown-btn i > svg, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .select-dropdown-btn i > svg {
  color: var(--content-color-secondary);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .dropdown {
  width: 100%;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .dropdown .btn-group-separated, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .dropdown .btn-group-separated {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .documentation-config-bar-selector .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions--dropdown .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .documentation-config-bar-selector .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions--dropdown .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .documentation-config-bar-selector .dropdown .btn-group-separated .btn-text, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions--dropdown .dropdown .btn-group-separated .btn-text {
  border: none;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--dropdown, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--versions, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--versions, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--versions {
  margin-bottom: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .content--environment, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .content--environment, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .content--environment {
  margin-bottom: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .governance--textarea, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .governance--textarea, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .governance--textarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--content .loading-indicator-wrapper, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--url .loading-indicator-wrapper, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--governance .loading-indicator-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: var(--spacing-xxl);
  position: static;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling {
  margin-bottom: var(--spacing-xxl);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--code, .documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors--highlight {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker {
  position: relative;
  padding-right: var(--input-height);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--input {
  height: var(--input-height);
  margin-right: var(--spacing-s);
  text-transform: uppercase;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview {
  top: 0;
  right: 0;
  position: absolute;
  width: 38px;
  height: 38px;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview--invalid {
  background: var(--background-color-primary) !important;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview--invalid:after {
  content: "";
  height: 52px;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  position: absolute;
  transform: rotate(45deg);
  left: 19px;
  top: -7px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--preview--invalid:before {
  content: "";
  height: 52px;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  position: absolute;
  transform: rotate(-45deg);
  left: 18px;
  top: -7px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker--warning {
  color: var(--content-color-error);
  font-size: var(--text-size-m);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling .styling--colors .color-picker .pm-form-control {
  text-transform: uppercase;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout {
  margin-bottom: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout > div:first-child {
  padding: var(--spacing-zero) !important;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout > div:first-child > ul:first-child {
  margin-top: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper {
  white-space: nowrap;
  height: var(--size-m);
  padding: 1px 2px;
  width: 196px;
  justify-content: space-around;
  display: block;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch {
  padding: var(--spacing-xs);
  margin: 0.5px;
  height: 22px;
  width: 90px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch .plugin__workspace-view-active-item {
  top: 1px;
  position: relative;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .left {
  left: 2.5px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .right {
  left: 99px;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--styling--layout .plugin__workspace-view-switcher__wrapper .plugin__workspace-view-switcher__switch__item {
  display: inline-block;
  width: 90px;
  padding: var(--spacing-xs);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas {
  display: flex;
  justify-content: space-between;
  padding-bottom: calc(7 * var(--spacing-xxl));
  margin-top: var(--spacing-xxl);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons {
  display: inline-flex;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons .publish-doc-cancel-button {
  height: var(--button-height);
  background-color: var(--background-color-tertiary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons .publish-button {
  height: var(--button-height);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content--ctas--buttons .publish-button .loading-indicator-wrapper {
  position: static;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-publish-settings .documentation-publish-settings-main-content .alert-wrapper {
  margin-top: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-default-link {
  color: inherit;
  text-decoration: underline;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-default-link__text {
  border-bottom: none;
}
.documentation-publish-page-container .documentation-publish-settings-page-before-customisation .documentation-default-link:hover {
  color: inherit;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status {
  display: flex;
  flex-direction: column;
  max-width: var(--publish-page-max-width);
  width: var(--publish-page-width);
  margin-top: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-breadcrumbs a:hover {
  border-bottom: none;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header--buttons {
  display: inline-flex;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header div h1 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  display: inline;
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header .edit-settings-button {
  line-height: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header .unpublish-button {
  background-color: var(--base-color-error);
  line-height: var(--text-size-m);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header .unpublish-button .loading-indicator-wrapper {
  position: static;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header .unpublish-button--disabled {
  background-color: var(--red-40);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header .approve-button {
  line-height: var(--text-size-m);
  margin-right: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-header .approve-button .loading-indicator-wrapper {
  position: static;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--public-workspaces {
  margin-bottom: calc(3 * var(--spacing-xxxl));
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--token-banners {
  margin-top: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--label {
  margin-bottom: var(--spacing-s);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  letter-spacing: -0.12px;
  margin-top: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--limit-reached-banner {
  margin-bottom: var(--spacing-xl);
  position: sticky;
  top: 0;
  z-index: 89;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--private-collection-banner {
  margin-top: 24px;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--note {
  margin-top: 24px;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--content {
  margin: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--content--collection-name {
  margin: var(--spacing-zero);
  line-height: var(--line-height-l);
  word-break: break-word;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--content--versions {
  color: var(--content-color-tertiary);
  margin: var(--spacing-zero);
  line-height: var(--line-height-l);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--content--note-to-cm {
  color: var(--content-color-secondary);
  margin: var(--spacing-zero);
  line-height: var(--line-height-l);
  background-color: var(--background-color-tertiary);
  padding: 12px 10px;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--environment {
  margin: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--environment--top {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--environment--environment {
  margin-top: var(--spacing-zero);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--url {
  margin: var(--spacing-xxl) var(--spacing-zero) var(--spacing-zero) var(--spacing-zero);
  display: flex;
  flex-direction: column;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--url a {
  color: var(--content-color-link);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--url a:hover {
  cursor: pointer;
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--copy-to-clipboard {
  margin-left: var(--spacing-s);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--styling {
  margin: var(--spacing-l) var(--spacing-zero);
  margin-top: var(--spacing-xxl);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--collection-discovery .documentation-published-status-body--heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-l);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--collection-discovery__section {
  margin-bottom: var(--spacing-xl);
}
.documentation-publish-page-container .documentation-publish-status-page-before-customisation .documentation-published-status .documentation-published-status-body--collection-discovery__section h4 {
  line-height: var(--line-height-m);
  margin-bottom: var(--spacing-s);
}

.select-dropdown-container .dropdown-content--dropdown-menu, .select-dropdown-container .dropdown-menu {
  max-width: 360px;
  width: 28.8%;
}
.select-dropdown-container .dropdown-content--dropdown-menu .select-dropdown, .select-dropdown-container .dropdown-menu .select-dropdown {
  width: unset;
}

.documentation-dropdown {
  --publish-page-dropdown-max-height: calc(10 * var(--size-xs));
}
.documentation-dropdown--publish-page .documentation-dropdown-menu-container, .documentation-dropdown--publish-page .dropdown-menu {
  max-height: var(--publish-page-dropdown-max-height) !important;
  max-width: 360px;
  width: 28.8%;
  overflow: auto;
}
.documentation-dropdown--publish-page .documentation-dropdown-menu-container .select-dropdown, .documentation-dropdown--publish-page .dropdown-menu .select-dropdown {
  width: unset;
}
.documentation-dropdown--empty .select-dropdown-btn {
  color: var(--content-color-tertiary) !important;
}

.documentation-published-status-modal__remove-btn {
  background-color: var(--base-color-error);
}
.documentation-published-status-modal__remove-btn:hover {
  background-color: var(--content-color-error);
}

.sidebar-loading-state {
  height: 100%;
  border-right: 1px solid var(--border-color-default);
  border-left: 1px solid var(--border-color-default);
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
}
.sidebar-loading-state .sidebar-loading-state--item {
  display: flex;
  align-items: center;
  padding: var(--spacing-s) 0;
  margin-left: var(--spacing-s);
}
.sidebar-loading-state .sidebar-loading-state--item .sidebar-loading-state__icon {
  height: 8px;
  width: 8px;
  background-color: var(--highlight-background-color-secondary);
  margin-left: var(--spacing-s);
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-m);
  visibility: hidden;
}
.sidebar-loading-state .sidebar-loading-state--item .sidebar-loading-state__icon.has {
  visibility: visible;
}
.sidebar-loading-state .sidebar-loading-state--item .sidebar-loading-state-indentation {
  height: var(--size-s);
  border-right: 1px solid var(--border-color-default);
  width: 0;
  margin: 0 var(--spacing-s);
}
.sidebar-loading-state .sidebar-loading-state--item .sidebar-loading-state__pre-info {
  height: 8px;
  width: var(--size-s);
  background-color: var(--highlight-background-color-secondary);
  margin-left: var(--spacing-xs);
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-m);
}
.sidebar-loading-state .sidebar-loading-state--item .sidebar-loading-state__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  height: 8px;
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-s);
}
.sidebar-loading-state .sidebar-loading-state--nested-item {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-s);
}
.sidebar-loading-state .sidebar-loading-state--nested-item .sidebar-loading-state-indentation {
  height: var(--size-s);
  border-right: 1px solid var(--border-color-default);
  width: 0;
  margin: 0 var(--spacing-s);
}
.sidebar-loading-state .sidebar-loading-state--nested-item .sidebar-loading-state__pre-info {
  height: 8px;
  width: var(--size-s);
  background-color: var(--highlight-background-color-secondary);
  margin-left: var(--spacing-xs);
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-m);
}
.sidebar-loading-state .sidebar-loading-state--nested-item .sidebar-loading-state__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  height: 8px;
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-s);
}

.sidebar-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}
.sidebar-empty-state__illustration {
  height: 120px;
  width: 140px;
  margin-bottom: var(--spacing-l);
}
.sidebar-empty-state__head-and-body {
  margin-bottom: var(--spacing-l);
}
.sidebar-empty-state__head-and-body > div:not(:last-child) {
  margin-bottom: var(--spacing-s);
}
.sidebar-empty-state__title {
  text-align: center;
}
.sidebar-empty-state__description {
  text-align: center;
  display: flex;
}
.sidebar-empty-state__description span {
  display: inline-block;
  line-height: 18px;
}
.sidebar-empty-state__cta {
  display: flex;
}

.sidebar-error-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: var(--spacing-xxl) var(--spacing-l);
}
.sidebar-error-view__illustration {
  height: 120px;
  width: 140px;
  margin-bottom: var(--spacing-l);
}
.sidebar-error-view__head-and-body {
  margin-bottom: var(--spacing-l);
}
.sidebar-error-view__head-and-body > div:not(:last-child) {
  margin-bottom: var(--spacing-s);
}
.sidebar-error-view__title {
  text-align: center;
}
.sidebar-error-view__description {
  text-align: center;
  display: flex;
}
.sidebar-error-view__description span {
  display: inline-block;
}

.sidebar-offline-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: var(--spacing-xxl) var(--spacing-l);
}
.sidebar-offline-view__illustration {
  height: 120px;
  width: 140px;
  margin-bottom: var(--spacing-l);
}
.sidebar-offline-view__title {
  margin-bottom: var(--spacing-s);
  text-align: center;
}
.sidebar-offline-view__description {
  text-align: center;
  display: flex;
}
.sidebar-offline-view__description span {
  display: inline-block;
}

.sidebar-no-results-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}
.sidebar-no-results-state__illustration {
  height: 120px;
  width: 140px;
  margin-bottom: var(--spacing-l);
}
.sidebar-no-results-state__head-and-body {
  margin-bottom: var(--spacing-l);
}
.sidebar-no-results-state__head-and-body > div:not(:last-child) {
  margin-bottom: var(--spacing-s);
}
.sidebar-no-results-state__title {
  text-align: center;
}
.sidebar-no-results-state__description {
  text-align: center;
  display: flex;
}
.sidebar-no-results-state__description span {
  display: inline-block;
  line-height: 18px;
}

.update-user-profile-modal {
  z-index: 120;
}
.update-user-profile-modal-header .modal-header-title {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xl);
  font-weight: var(--text-weight-medium);
  padding-left: var(--spacing-xxl);
  padding-top: var(--spacing-xl);
}
.update-user-profile-modal-container {
  padding-left: var(--spacing-xxl);
  padding-right: var(--spacing-xxl);
}
.update-user-profile-modal-container__text {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-xl);
}
.update-user-profile-modal-container__input-name .label, .update-user-profile-modal-container__input-username .label {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-s);
  font-weight: var(--text-weight-medium);
}
.update-user-profile-modal-container__input-name .input-field, .update-user-profile-modal-container__input-username .input-field {
  margin-bottom: var(--spacing-xs);
}
.update-user-profile-modal-container__info {
  margin-top: var(--spacing-m);
  line-height: var(--line-height-m);
  color: var(--content-color-tertiary);
}
.update-user-profile-modal-container__info .profile-link {
  line-break: anywhere;
}
.update-user-profile-modal-container__actions {
  width: 100%;
  display: flex;
}
.update-user-profile-modal-container__actions .btn-secondary {
  margin-right: var(--spacing-s);
}
.update-user-profile-modal-container__actions .btn-primary {
  flex: 1;
}
.update-user-profile-modal-footer {
  padding: var(--spacing-xl) var(--spacing-xxl);
}

.empty-overlay {
  width: 100%;
  height: 48px;
  position: fixed;
  z-index: 100;
  background-color: var(--background-modal-backdrop) !important;
}

.scratchpad-button-wrapper {
  display: flex;
  align-items: center;
  padding: var(--spacing-zero) var(--spacing-s);
  width: 100px;
  height: 24px;
  background-color: var(--background-color-tertiary);
  border-radius: 12px;
  line-height: var(--text-size-s);
  cursor: pointer;
}
.scratchpad-button-wrapper .scratchpad-button-icon {
  padding: var(--spacing-zero) var(--spacing-xs);
}
.scratchpad-button-wrapper .scratchpad-button-text {
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-zero) var(--spacing-xs);
}

.contextbar-signin-prompt-state {
  height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 167px auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.contextbar-signin-prompt-state__icon-container {
  height: 56px;
  width: 56px;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contextbar-signin-prompt-state__icon-container .contextbar-signin-prompt-state__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.contextbar-signin-prompt-state__icon-container .contextbar-signin-prompt-state__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}
.contextbar-signin-prompt-state__title {
  color: var(--content-color-primary);
  padding: var(--spacing-xs) 40px;
  text-align: center;
}
.contextbar-signin-prompt-state__title h4 {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: 0;
  margin-top: var(--spacing-l);
}
.contextbar-signin-prompt-state__body {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
  padding: var(--spacing-xs) var(--spacing-m);
  text-align: center;
}
.contextbar-signin-prompt-state__link-action {
  margin-top: var(--spacing-xl);
}

.scratchpad-setup-modal {
  min-height: 232px;
}
.scratchpad-setup-modal__content .message {
  display: inline-block;
}
.scratchpad-setup-modal__content .message-error {
  margin-left: 0;
  vertical-align: top;
}
.scratchpad-setup-modal__content .message-success {
  margin-left: var(--spacing-s);
  vertical-align: top;
}
.scratchpad-setup-modal__content .description {
  margin-top: var(--spacing-s);
  color: var(--content-color-primary);
}
.scratchpad-setup-modal__content .link {
  color: var(--content-color-link);
  cursor: pointer;
}
.scratchpad-setup-modal__content__enterprise {
  margin-left: var(--spacing-s);
}
.scratchpad-setup-modal__content__banner {
  margin-top: var(--spacing-s);
}
.scratchpad-setup-modal__footer.modal-footer {
  position: relative;
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
  flex-basis: 72px;
}
.scratchpad-setup-modal__footer.modal-footer .btn {
  height: var(--controls-size-default);
  min-width: 50px;
  padding: var(--spacing-s) var(--spacing-m);
  margin-left: var(--spacing-s);
}
.scratchpad-setup-modal__footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 90%;
  background-color: var(--border-color-strong);
  margin: 0 var(--spacing-xl);
}

.scratchpad-migration-modal-container .scratchpad-migration-modal__modal-content {
  min-height: 450px;
}
.scratchpad-migration-modal-container .scratchpad-migration-modal__modal-content__inner {
  padding-top: var(--spacing-zero);
}
.scratchpad-migration-modal-container .scratchpad-migration-modal__modal-content__initial-error {
  flex-grow: 1;
  max-width: 390px;
  margin-top: var(--spacing-xxl);
}
.scratchpad-migration-modal-container .scratchpad-migration-modal__no-access {
  flex-grow: 1;
  margin-top: 56px;
}
.scratchpad-migration-modal-container .migrate-entity-modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.scratchpad-migration-modal-container .migrate-entity-modal__filters {
  display: flex;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
  height: var(--size-m);
}
.scratchpad-migration-modal-container .migrate-entity-modal__filters--no-margin {
  margin-top: var(--spacing-zero);
}
.scratchpad-migration-modal-container .migrate-entity-modal__filters .input-search-group {
  border-radius: var(--border-radius-default);
}
.scratchpad-migration-modal-container .migrate-entity-modal__filters .workspace-visibility-dropdown-container {
  margin-left: var(--spacing-s);
}
.scratchpad-migration-modal-container .migrate-entity-modal__filters .workspace-visibility-dropdown-container .dropdown-button .btn {
  height: 30px;
}
.scratchpad-migration-modal-container .migrate-entity-modal__empty-state {
  max-width: 390px;
  padding: var(--spacing-s);
}
.scratchpad-migration-modal-container .migrate-entity-modal .workspace-list-container {
  height: 288px;
  overflow: auto;
}
.scratchpad-migration-modal-container .migrate-entity-modal__workspace-list .workspace-list-container {
  height: 288px;
  overflow: auto;
}
.scratchpad-migration-modal-container .migrate-entity-modal__workspace-list--extended .workspace-list-container {
  height: 340px;
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__filters {
  display: flex;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
  height: var(--size-m);
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__filters--no-margin {
  margin-top: var(--spacing-zero);
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__filters .input-search-group {
  border-radius: var(--border-radius-default);
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__filters .workspace-visibility-dropdown-container {
  margin-left: var(--spacing-s);
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__filters .workspace-visibility-dropdown-container .dropdown-button .btn {
  height: 30px;
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__empty-state {
  max-width: 390px;
  padding: var(--spacing-s);
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal .workspace-list-container {
  height: 288px;
  overflow: auto;
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__workspace-list .workspace-list-container {
  height: 288px;
  overflow: auto;
}
.scratchpad-migration-modal-container .scratchpad-move-entity-modal__workspace-list--extended .workspace-list-container {
  height: 340px;
}

.sidebar-signin-prompt-state {
  height: 300px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 0 auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.sidebar-signin-prompt-state__icon-container {
  height: 56px;
  width: 56px;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.sidebar-signin-prompt-state__icon-container .sidebar-signin-prompt-state__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.sidebar-signin-prompt-state__icon-container .sidebar-signin-prompt-state__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}
.sidebar-signin-prompt-state__title {
  color: var(--content-color-secondary);
  padding: var(--spacing-xs) 40px;
  text-align: center;
}
.sidebar-signin-prompt-state__title h4 {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: 0;
  margin-top: var(--spacing-l);
}
.sidebar-signin-prompt-state__body {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
  padding: var(--spacing-xs) var(--spacing-m);
  text-align: center;
}
.sidebar-signin-prompt-state__link-action {
  margin-top: var(--spacing-xl);
  color: var(--content-color-brand);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
}

.workbench-signin-prompt-state {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
  align-items: center;
  margin: 25vh auto;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.workbench-signin-prompt-state__icon-container {
  height: 56px;
  width: 56px;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.workbench-signin-prompt-state__icon-container .workbench-signin-prompt-state__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.workbench-signin-prompt-state__icon-container .workbench-signin-prompt-state__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}
.workbench-signin-prompt-state__title {
  color: var(--content-color-secondary);
  padding: var(--spacing-xs) 40px;
  text-align: center;
}
.workbench-signin-prompt-state__title h4 {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: 0;
  margin-top: var(--spacing-l);
}
.workbench-signin-prompt-state__body {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-l);
  padding: var(--spacing-xs) var(--spacing-m);
  text-align: center;
}
.workbench-signin-prompt-state__action {
  padding: 0px;
  justify-content: start;
}
.workbench-signin-prompt-state__action__icon {
  margin-right: var(--spacing-s);
}
.workbench-signin-prompt-state__action__icon use {
  fill: var(--content-color-brand);
}
.workbench-signin-prompt-state__action__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-brand);
}
.workbench-signin-prompt-state__action__icon svg {
  color: var(--content-color-brand);
}

.billing-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  overflow-y: scroll;
}
@media screen and (max-width: 1280px) {
  .billing-container {
    display: grid;
    grid-template-columns: 250px minmax(180px, 1fr);
    margin-left: var(--spacing-xxl);
  }
}
.billing-container__error {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.billing-container__tabs {
  margin: 0 var(--spacing-s);
}
.billing-container__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.billing-container__side-nav {
  width: 250px;
  height: calc(100vh - 80px);
  border-right: var(--border-width-default) solid var(--border-color-strong);
  padding-top: var(--spacing-xxl);
}
.billing-container__side-nav-heading {
  margin: 0px 0px var(--spacing-l) 0px;
  color: var(--content-color-primary);
}
.billing-container__side-nav-heading--light {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
}
.billing-container__side-nav-item {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  font-size: var(--text-size-m);
  padding: var(--spacing-s);
  cursor: pointer;
}
.billing-container__side-nav-item.active {
  background-color: var(--highlight-background-color-tertiary);
  font-weight: var(--text-weight-medium);
}
.billing-container__inner {
  font-size: var(--text-size-l);
  max-width: 750px;
  width: 100%;
  padding-left: var(--spacing-xxl);
  position: relative;
}
.billing-container__inner .billing-heading {
  padding-left: var(--spacing-m);
  margin-top: var(--spacing-xl);
  color: var(--content-color-primary);
}
.billing-container__view {
  width: 750px;
  box-sizing: border-box;
  padding: var(--spacing-s);
}
.billing-container .empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.billing-container .empty-state--absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.billing-container .empty-state__body {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin: 0 0 var(--spacing-xl) 0;
}
.billing-container .empty-state__action {
  background: transparent;
}
.billing-container .empty-tab-state__title span {
  color: var(--content-color-primary);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.resource-usage-overview-item {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding: var(--spacing-l) var(--spacing-zero);
}
.resource-usage-overview-item__title {
  margin-block-start: var(--spacing-s);
  margin-block-end: var(--spacing-s);
}
.resource-usage-overview-item__progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resource-usage-overview-item__progress .billing-progress-bar {
  width: 70%;
}
.resource-usage-overview-item__detailed-usage-link {
  font-size: var(--text-size-m);
}
.resource-usage-overview-item__usage-details {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  padding: var(--spacing-s) var(--spacing-zero);
  color: var(--content-color-tertiary);
}
.resource-usage-overview-item .overage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resource-usage-overview-item .overage .toggle-switch-container {
  align-items: normal;
}
.resource-usage-overview-item .overage .toggle-switch-container .toggle-switch {
  margin-top: var(--spacing-l);
}
.resource-usage-overview-item .overage .loading-indicator-wrapper {
  padding-right: var(--spacing-l);
  padding-top: var(--spacing-xl);
  transform: scale(0.7);
}
.resource-usage-overview-item .overage-message__title {
  font-size: var(--text-size-m);
}
.resource-usage-overview-item .overage-message__description {
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-l);
  max-width: none;
}

.billing-progress-bar {
  position: relative;
  background-color: var(--background-color-tertiary);
  margin-top: var(--spacing-s);
  height: var(--spacing-xs);
  border-radius: var(--spacing-xs);
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.billing-progress-bar__indicator {
  float: left;
  width: 0;
  height: 100%;
  background-color: var(--base-color-brand);
  border-radius: var(--spacing-xs);
}
.billing-progress-bar__limit {
  background-color: var(--background-color-primary);
  border: var(--border-width-xl) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-max);
  height: 3px;
  width: 3px;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}
.billing-progress-bar__limit--dark {
  border: var(--border-width-xl) var(--border-style-solid) var(--content-color-tertiary);
}
.billing-progress-bar__overage-indicator {
  position: absolute;
  right: 10%;
  width: 7%;
  height: var(--spacing-xs);
  transform: translateX(100%);
  background-color: var(--blue-50);
  border-top-right-radius: var(--spacing-xs);
  border-bottom-right-radius: var(--spacing-xs);
}

.resource-usage-overview__container .alert-wrapper {
  font-size: var(--text-size-m);
  margin-bottom: var(--spacing-xxl);
}
.resource-usage-overview__container .alert-wrapper .alert-info {
  background-color: var(--background-color-info);
}
.resource-usage-overview__section-header {
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding-bottom: var(--spacing-l);
}
.resource-usage-overview__add-ons {
  padding-top: var(--spacing-xl);
}
.resource-usage-overview__included-resources {
  padding-top: var(--spacing-xxl);
}
.resource-usage-overview__list {
  padding: var(--spacing-zero) var(--spacing-s);
}
.resource-usage-overview__heading {
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-xs);
}
.resource-usage-overview__description {
  display: inline-block;
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  line-height: calc((var(--line-height-m) + var(--line-height-l)) / 2);
  max-width: none;
}
.resource-usage-overview__disabled-tooltip {
  margin-top: 0px;
}
.resource-usage-overview__disabled-tooltip-wrapper {
  display: inline-block;
}
.resource-usage-overview__disabled-tooltip-option {
  font-size: var(--text-size-m);
  margin: 0;
}
.resource-usage-overview__disabled-tooltip-heading {
  font-weight: var(--text-weight-medium);
}
.resource-usage-overview__disabled-tooltip-description {
  margin: var(--spacing-xs) 0;
}

.invoice-widget__upcoming {
  display: flex;
  justify-content: flex-start;
  margin: var(--spacing-s) 0px;
  align-items: center;
}
.invoice-widget__upcoming-status {
  margin-right: var(--spacing-l);
  background: var(--background-color-warning);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-warning);
  border-radius: 32px;
}
.invoice-widget__upcoming-details {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-s);
}
.invoice-widget__unpaid {
  margin: var(--spacing-s) 0px;
}
.invoice-widget__unpaid-invoice-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--spacing-s);
}
.invoice-widget__unpaid-status {
  margin-right: var(--spacing-l);
  background: var(--background-color-error);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-error);
  border-radius: 32px;
}
.invoice-widget__unpaid-details {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-s);
}
.invoice-widget__paid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: var(--spacing-s) 0px;
}
.invoice-widget__paid-status {
  margin-right: var(--spacing-l);
  background: var(--background-color-success);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-success);
  border-radius: 32px;
}
.invoice-widget__paid-details {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-left: var(--spacing-s);
}
.invoice-widget__status {
  display: flex;
}
.invoice-widget__invoice-link {
  text-decoration: underline;
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  margin-left: var(--spacing-l);
  cursor: pointer;
}

.billing-widget__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-s);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
}
.billing-widget__container .invoices-overview {
  width: 86%;
}
.billing-widget__inner-container {
  height: 100%;
  width: 75%;
}
.billing-widget__action-link {
  color: var(--content-color-link);
  font-size: var(--text-size-m);
  cursor: pointer;
}

.widget-empty-state {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
}
.widget-empty-state__icon {
  margin-right: var(--spacing-s);
}

.billing-information__loader {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.billing-information .alert-wrapper {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-xl);
}
.billing-information .alert-wrapper .alert-info {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-m);
  background-color: var(--background-color-info);
}
.billing-information__trial-banner {
  margin-bottom: var(--spacing-xl);
}
.billing-information__ach-banner {
  margin: var(--spacing-m) var(--spacing-zero);
}
.billing-information__free-plan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
}
.billing-information__free-plan-heading {
  line-height: var(--line-height-m);
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
}
.billing-information__free-plan-sub-heading {
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  margin-top: var(--spacing-s);
  line-height: var(--line-height-m);
  text-align: center;
  width: 55%;
}
.billing-information__free-plan-icon {
  height: 150px;
  width: 150px;
}
.billing-information__free-plan-icon i {
  height: 50%;
  width: 50%;
}
.billing-information__free-plan-icon svg {
  height: 100%;
  width: 100%;
}
.billing-information__free-plan-upgrade-button {
  margin-top: var(--spacing-l);
  padding: var(--spacing-s) var(--spacing-m);
  height: var(--size-m);
}
.billing-information__purchase-key-button {
  margin-top: var(--spacing-m);
}
.billing-information__links {
  padding: var(--spacing-xl) var(--spacing-m);
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
}
.billing-information__links span {
  cursor: pointer;
}

.contact-support-widget {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.dot-separator {
  position: relative;
  bottom: 3px;
  margin: var(--spacing-s);
}

.current-plan-details__header,
.autoflex-details__header {
  display: flex;
  align-items: center;
}
.current-plan-details__heading-wrapper,
.autoflex-details__heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: var(--spacing-s);
  margin: var(--spacing-m) 0;
}
.current-plan-details__heading,
.autoflex-details__heading {
  display: inline-block;
  position: relative;
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
  padding-left: var(--spacing-s);
}
.current-plan-details__pricing-change-badge,
.autoflex-details__pricing-change-badge {
  margin-left: var(--spacing-s);
}
.current-plan-details__link,
.autoflex-details__link {
  display: inline-block;
  color: var(--content-color-link);
  font-size: var(--text-size-m);
  cursor: pointer;
}
.current-plan-details__link.disabled,
.autoflex-details__link.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.current-plan-details__list,
.autoflex-details__list {
  width: 100%;
}
.current-plan-details__list-heading,
.autoflex-details__list-heading {
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-l);
}
.current-plan-details__list-heading-item,
.autoflex-details__list-heading-item {
  border-bottom: 1px solid var(--border-color-default);
  padding: var(--spacing-s);
  text-align: left;
}
.current-plan-details__list-row-item,
.autoflex-details__list-row-item {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  padding: var(--spacing-m) var(--spacing-s);
  color: var(--content-color-secondary);
  border-bottom: 1px solid var(--border-color-default);
}
.current-plan-details__amount-column,
.autoflex-details__amount-column {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  padding: var(--spacing-m) var(--spacing-s);
  color: var(--content-color-primary);
  border-bottom: 1px solid var(--border-color-default);
  text-align: right;
}
.current-plan-details__list-heading-item:last-child,
.autoflex-details__list-heading-item:last-child {
  text-align: right;
}
.current-plan-details__list-row-item:last-child,
.autoflex-details__list-row-item:last-child {
  text-align: right;
}
.current-plan-details__total,
.autoflex-details__total {
  position: relative;
  padding-bottom: var(--spacing-xxl);
  padding-top: var(--spacing-l);
  border-bottom: 1px solid var(--border-color-default);
}
.current-plan-details__frequency,
.autoflex-details__frequency {
  color: var(--content-color-secondary);
  text-align: end;
  font-size: var(--text-size-s);
  padding-top: var(--spacing-xs);
}
.current-plan-details__total-amount,
.autoflex-details__total-amount {
  color: var(--content-color-primary);
  text-align: end;
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  padding-bottom: calc(var(--spacing-xs) / 2);
}
.current-plan-details__discount-amount-column,
.autoflex-details__discount-amount-column {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  padding: var(--spacing-m) var(--spacing-s);
  color: var(--content-color-success);
  border-bottom: 1px solid var(--border-color-default);
  text-align: right;
}

.tax-info-tooltip .tooltip-wrapper {
  padding: 16px;
}
.tax-info-tooltip .tooltip-wrapper .tooltip-body .tax-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tax-info-tooltip .tooltip-wrapper .tooltip-body .tax-list .tax-item {
  padding: 2px 0px;
}
.tax-info-tooltip .tooltip-wrapper .tooltip-body .tax-list__percentage {
  float: right;
  margin-left: 8px;
}

.true-up-banner {
  background-color: var(--background-color-secondary);
  padding: var(--spacing-l);
  margin-top: var(--spacing-s);
}
.true-up-banner__heading-wrapper {
  display: flex;
}
.true-up-banner__heading {
  padding-left: var(--spacing-m);
}
.true-up-banner__content {
  padding-left: var(--spacing-xl);
}
.true-up-banner__content--text {
  max-width: none;
  margin-top: var(--spacing-xs);
}
.true-up-banner__content--button {
  background-color: var(--background-color-tertiary);
  margin-top: var(--spacing-s);
}

.true-up-modal__content-text {
  color: var(--content-color-primary);
}
.true-up-modal__footer {
  justify-content: end;
}
.true-up-modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.true-up-modal__action-cancel {
  margin-right: var(--spacing-s);
}
.true-up-modal__action-proceed {
  min-width: 140px;
}
.true-up-modal__checkbox-wrapper {
  margin-top: var(--spacing-xl);
}
.true-up-modal__checkbox-label {
  margin-left: var(--spacing-s);
  color: var(--content-color-secondary);
}

.payment-widget__loader {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-widget__heading {
  font-size: var(--text-size-m);
  line-height: var(--line-height-l);
}
.payment-widget__card-details {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.invoices__subheading {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-xl);
  border-bottom: 1px solid var(--border-color-default);
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
  max-width: none;
}
.invoices__container .invoice-list__list {
  width: 100%;
}
.invoices__container .invoice-list__list-heading {
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-l);
}
.invoices__container .invoice-list__list-heading-item {
  border-bottom: 1px solid var(--border-color-default);
  padding: var(--spacing-s);
  text-align: left;
  color: var(--content-color-secondary);
}
.invoices__container .billing-information-widget {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.invoices__container .billing-information-widget__address {
  display: flex;
}
.invoices__container .billing-information-widget .label {
  color: var(--content-color-secondary);
}
.invoices__status-overdue {
  margin-right: var(--spacing-l);
  background: var(--background-color-error);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-error);
  border-radius: 32px;
  display: inline-block;
}
.invoices__status-upcoming {
  margin-right: var(--spacing-l);
  background: var(--background-color-warning);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-warning);
  border-radius: 32px;
  display: inline-block;
}
.invoices__status-paid {
  background: var(--background-color-success);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-success);
  border-radius: 32px;
  display: inline-block;
}
.invoices__history {
  padding-bottom: var(--spacing-xxl);
}
.invoices__history-heading, .invoices__overdue-heading {
  padding-top: var(--spacing-xxl);
  padding-bottom: var(--spacing-l);
  display: none;
  position: relative;
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
}
@media (min-width: 768px) {
  .invoices__history-heading, .invoices__overdue-heading {
    display: block;
  }
}
.invoices__history-list-row-item, .invoices__overdue-list-row-item {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  padding: var(--spacing-m) var(--spacing-s);
  border-bottom: 1px solid var(--border-color-default);
  width: 15%;
}
.invoices__history-list-row-item-badge, .invoices__overdue-list-row-item-badge {
  margin: 0;
}
.invoices__history-list-row-item:last-child, .invoices__overdue-list-row-item:last-child {
  width: 43%;
}
.invoices__history-list-row-item:nth-child(2), .invoices__overdue-list-row-item:nth-child(2) {
  width: 16%;
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.invoices__history-retry-link, .invoices__overdue-retry-link {
  display: inline-block;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  cursor: pointer;
  margin-left: var(--spacing-s);
}
.invoices__history-list-row-item:last-child, .invoices__overdue-list-row-item:last-child {
  position: relative;
}
.invoices__history-download-link, .invoices__overdue-download-link {
  display: inline-block;
  position: absolute;
  left: 44%;
}
.invoices__upcoming {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-s);
}
.invoices__upcoming-status {
  margin-left: var(--spacing-l);
  background: var(--background-color-warning);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  color: var(--content-color-warning);
  border-radius: 32px;
}
.invoices__upcoming-default-card {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.payment-methods {
  padding-bottom: var(-spacing-xxl);
}
.payment-methods__loader {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-methods__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-methods__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-inline-start: var(--spacing-zero);
}
.payment-methods__list-row {
  padding: var(--spacing-l) var(--spacing-zero);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-methods__list-row:hover {
  background: var(--background-color-secondary);
}
.payment-methods__list-row:hover .payment-methods__default {
  display: inline-flex;
}
.payment-methods__list-row:hover .payment-methods__status {
  display: none;
}
.payment-methods__list-row:hover .payment-methods__remove {
  display: inline-flex;
}
.payment-methods__list-row:hover .payment-methods-primary {
  display: none;
}
.payment-methods__image {
  display: inline-block !important;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  background-color: var(--background-color-primary);
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-xs);
}
.payment-methods__image svg {
  padding: var(--spacing-zero) var(--spacing-s);
}
.payment-methods__image--visa {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/visa.svg");
}
.payment-methods__image--mastercard {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/mastercard.svg");
}
.payment-methods__image--amex {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/amex.svg");
}
.payment-methods__image--jcb {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/jcb.svg");
}
.payment-methods__image--unionPay {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/unionPay.svg");
}
.payment-methods__image--alipay {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/alipay.svg");
}
.payment-methods__image--discover {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/discover.svg");
}
.payment-methods__image--defaultCard {
  display: block;
  width: 46px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/defaultCard.svg");
}
.payment-methods__image--defaultBank {
  display: block;
  width: 42px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/bank.svg");
}
.payment-methods__wrapper {
  display: flex;
}
.payment-methods__subheading {
  overflow: auto;
  padding: var(--spacing-m) var(--spacing-zero) var(--spacing-xl) var(--spacing-zero);
}
.payment-methods__subheading-text {
  display: inline-block;
  color: var(--content-color-secondary);
  padding: 6px var(--spacing-zero);
  font-size: var(--text-size-l);
}
.payment-methods__border {
  color: var(--border-color-default);
  margin: var(--spacing-zero);
}
.payment-methods__add {
  float: right;
}
.payment-methods__details {
  display: inline-block;
  text-transform: capitalize;
}
.payment-methods__description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.payment-methods__action {
  padding: 2px 0px;
  float: right;
}
.payment-methods__remove {
  margin-right: var(--spacing-s);
  float: right;
  display: none;
}
.payment-methods__default {
  margin-right: var(--spacing-s);
  display: none;
}
.payment-methods__status {
  margin-right: var(--spacing-s);
  display: inline-flex;
  padding: var(--spacing-s);
}
.payment-methods-primary {
  margin-top: var(--spacing-xs);
  margin-right: var(--spacing-s);
  background: var(--background-color-info);
  padding: 2px 12px;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-info);
  border-radius: 32px;
  float: right;
}

.delete-payment-method__info {
  margin-top: var(--spacing-m);
  display: flex;
  color: var(--content-color-secondary);
}
.delete-payment-method__info-text {
  margin: var(--spacing-zero) var(--spacing-s);
}
.delete-payment-method__info i {
  margin: 1px var(--spacing-zero);
}
.delete-payment-method__action-delete {
  background-color: var(--base-color-error);
  margin-left: var(--spacing-s);
  height: var(--size-m);
}
.delete-payment-method__action-cancel {
  height: var(--size-m);
}

.add-payment-method {
  max-width: 420px;
}
.add-payment-method__title {
  margin-top: var(--spacing-m);
}
.add-payment-method__sub-title {
  margin-top: var(--spacing-xl);
}
.add-payment-method__tabs {
  margin-top: var(--spacing-xl);
}
.add-payment-method__tab-card div {
  margin-left: 0 !important;
}
.add-payment-method__ach {
  max-width: 490px;
  margin-top: var(--spacing-l);
}
.add-payment-method__ach__heading {
  margin-top: var(--spacing-l);
}
.add-payment-method__ach__description {
  font-size: var(--text-size-l);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-xl);
}
.add-payment-method__ach__buttons {
  margin-top: var(--spacing-l);
}
.add-payment-method__back-button {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
  cursor: pointer;
}
.add-payment-method__back-button-link {
  margin: 0px 0px 0px var(--spacing-xs);
}
.add-payment-method__info {
  margin-top: var(--spacing-m);
  display: flex;
  color: var(--content-color-secondary);
}
.add-payment-method__info-text {
  margin: var(--spacing-zero) var(--spacing-s);
}
.add-payment-method__info i {
  margin: 1px var(--spacing-zero);
}
.add-payment-method__card {
  margin: var(--spacing-l) var(--spacing-zero);
}
.add-payment-method__card-title {
  margin: var(--spacing-zero);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
}
.add-payment-method__card-details {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  width: 100%;
}
.add-payment-method__card-expiry-element {
  width: 50%;
  display: inline-block;
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  vertical-align: bottom;
}
.add-payment-method__card-cvc-element {
  width: 50%;
  display: inline-block;
  vertical-align: bottom;
}
.add-payment-method__label-title {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.add-payment-method__label-title span {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
}
.add-payment-method__label-description {
  margin-top: 0;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.add-payment-method__label-input {
  flex: 1;
  padding: var(--spacing-s) var(--spacing-zero);
}
.add-payment-method__address-title {
  margin: var(--spacing-zero);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
}
.add-payment-method__address-subtitle {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  padding: var(--spacing-xs) var(--spacing-zero);
}
.add-payment-method__address-segment {
  margin: var(--spacing-s) 0;
}
.add-payment-method__address-wrapper {
  position: relative;
  display: inline-block;
  width: 50%;
}
.add-payment-method__address-line2 {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  padding: var(--spacing-xs);
}
.add-payment-method--default {
  display: inline-flex;
  margin-right: var(--spacing-xs);
}
.add-payment-method--country-dropdown {
  display: inline-block;
  position: relative;
  height: var(--controls-size-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-s);
  background-color: var(--background-color-secondary);
  width: 100%;
}
.add-payment-method--country-dropdown .pm-form-control {
  width: 100%;
  height: 100%;
  border: 5px transparent;
  background-color: var(--background-color-secondary);
  font-size: var(--text-size-m);
}
.add-payment-method--country-dropdown select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 var(--spacing-m) 0 var(--spacing-s);
  -webkit-padding-end: var(--spacing-m);
  -webkit-padding-start: var(--spacing-s);
}
.add-payment-method--region-dropdown {
  display: inline-block;
  position: relative;
  height: var(--controls-size-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-s);
  width: 90%;
}
.add-payment-method--region-dropdown .pm-form-control {
  width: 100%;
  height: 100%;
  border: 5px transparent;
  background-color: var(--background-color-secondary);
  font-size: var(--text-size-m);
}
.add-payment-method--region-dropdown select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 var(--spacing-m) 0 var(--spacing-s);
  -webkit-padding-end: var(--spacing-m);
  -webkit-padding-start: var(--spacing-s);
}
.add-payment-method--country-dropdown:after, .add-payment-method--region-dropdown:after {
  content: "";
  border: 5px solid transparent;
  border-color: grey transparent transparent transparent;
  position: absolute;
  top: 45%;
  right: 12px;
  pointer-events: none;
}
.add-payment-method__actions {
  display: flex;
  align-items: center;
  padding-bottom: var(--spacing-xl);
}
.add-payment-method__action-confirm {
  margin-right: var(--spacing-s);
  height: var(--size-m);
}
.add-payment-method__action-delete {
  background-color: var(--base-color-error);
  margin-left: var(--spacing-s);
  height: var(--size-m);
}
.add-payment-method__action-cancel {
  height: var(--size-m);
}

.setup-payment-method__loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
.setup-payment-method__buttons {
  margin-top: var(--spacing-m);
}

.verify-payment-method {
  width: 480px;
}
.verify-payment-method__heading {
  margin-top: var(--spacing-s);
}
.verify-payment-method__description {
  margin-top: var(--spacing-xl);
}
.verify-payment-method__description__info {
  margin-top: var(--spacing-xl);
}
.verify-payment-method__banner__statement {
  margin-top: var(--spacing-m);
  display: block;
  width: 480px;
  height: 183px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/bankStatementBanner.svg");
  background-size: contain;
}
.verify-payment-method__info-banner {
  margin-top: var(--spacing-xl);
}
.verify-payment-method__form {
  margin-top: var(--spacing-xl);
}
.verify-payment-method__buttons {
  margin-top: var(--spacing-xl);
}

.future-instructions-widget__empty,
.current-plan-details-widget__empty {
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
}
.future-instructions-widget__amount,
.current-plan-details-widget__amount {
  margin: 0;
}
.future-instructions-widget ul,
.current-plan-details-widget ul {
  margin: 0;
  padding: 0;
}
.future-instructions-widget li,
.current-plan-details-widget li {
  list-style: none;
}
.future-instructions-widget__plan-details,
.current-plan-details-widget__plan-details {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.future-instructions-widget__badge,
.current-plan-details-widget__badge {
  margin-right: var(--spacing-s);
  margin-left: var(--spacing-zero);
}
.future-instructions-widget__tooltip,
.current-plan-details-widget__tooltip {
  display: flex !important;
  align-items: center;
  margin-left: var(--spacing-s);
}

.account-events__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: var(--spacing-xxxl);
}
.account-events__empty-icon {
  height: 150px;
  width: 150px;
}
.account-events__empty-icon svg {
  height: 100%;
  width: 100%;
}
.account-events__loader {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-events__list {
  margin: 0;
  padding: 0;
}
.account-events__list-heading {
  display: flex;
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-medium);
  border-bottom: 1px solid var(--border-color-default);
  padding: var(--spacing-s);
  padding-top: 0;
}
.account-events__list-heading-date {
  width: 25%;
}
.account-events__list-item {
  display: flex;
  padding: var(--spacing-m) var(--spacing-s);
  border-bottom: 1px solid var(--border-color-default);
}
.account-events__list-item-date {
  width: 25%;
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
}
.account-events__list-item-details {
  width: 75%;
}
.account-events__list-item-event-type {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-s);
}
.account-events__list-item-message {
  font-size: var(--text-size-m);
  color: var(--content-color-tertiary);
}

.account-history__description {
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-xl);
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
}

.edit-billing-information {
  width: 70%;
}
.edit-billing-information__heading {
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}
.edit-billing-information__back-button {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
  cursor: pointer;
}
.edit-billing-information__back-button-link {
  margin: 0px 0px 0px var(--spacing-xs);
}
.edit-billing-information__loader {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-billing-information__action {
  display: flex;
  padding-bottom: var(--spacing-xl);
}
.edit-billing-information__action--save {
  margin-right: var(--spacing-m);
}

.field-segment__container {
  margin: var(--spacing-s) 0;
}
.field-segment__label-title {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.field-segment__label-title span {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
}
.field-segment__label-description {
  margin-top: 0;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.field-segment__label-input {
  flex: 1;
  padding: var(--spacing-s) var(--spacing-zero);
}
.field-segment__error {
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  margin-top: var(--spacing-s);
}
.field-segment__error__btn {
  text-decoration: underline;
  cursor: pointer;
}

.subtotal-label__total-label-wrapper {
  display: flex;
}
.subtotal-label__total-label-wrapper .info-button {
  padding-left: var(--spacing-xs);
  padding-top: 2px;
}
.subtotal-label__total-label {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  padding-bottom: var(--spacing-xs);
}

.empty-state__body {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin: 0 0 var(--spacing-xl) 0;
}
.empty-state__action {
  background: transparent;
}
.empty-state__illustration-wrapper {
  margin-bottom: var(--spacing-xl);
}

.plan-interval-options__tabs {
  margin-bottom: var(--spacing-m);
  background-color: var(--background-color-tertiary);
  height: 46px;
}
.plan-interval-options__tab {
  padding: 22px var(--spacing-l);
  width: 121px;
  border: 1px solid var(--background-color-tertiary);
  justify-content: center;
}
.plan-interval-options__tab:active, .plan-interval-options__tab.is-active {
  background-color: var(--background-color-primary);
}

.stripe-payment-element {
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-l) var(--spacing-zero) !important;
}

.purchase-key {
  max-width: 520px;
  width: 100%;
  margin: var(--spacing-zero) auto;
}
.purchase-key h1 {
  margin-top: var(--spacing-xxl);
}
.purchase-key__review-banner {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}
.purchase-key__review-items {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}
.purchase-key__action-buttons {
  display: flex;
}
.purchase-key__action-confirm {
  min-width: 75px;
}
.purchase-key__action-cancel {
  margin-left: var(--spacing-s);
}

.set-instructions {
  max-width: 520px;
  width: 100%;
  margin: var(--spacing-zero) auto;
}
.set-instructions__error, .set-instructions__loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.set-instructions h1 {
  margin-top: var(--spacing-xxl);
}
.set-instructions__current-plan {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}
.set-instructions__current-plan-items {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-s);
}
.set-instructions__next-cycle-instructions {
  margin-top: var(--spacing-xl);
}
.set-instructions__current-instructions-banner {
  margin-top: var(--spacing-l);
}
.set-instructions__dropdown {
  margin-top: var(--spacing-l);
}
.set-instructions__future-instructions-banner {
  margin-top: var(--spacing-l);
}
.set-instructions__action-buttons {
  display: flex;
}
.set-instructions__action-confirm {
  min-width: 175px;
  margin-top: var(--spacing-l);
}
.set-instructions__action-cancel {
  margin-left: var(--spacing-s);
  margin-top: var(--spacing-l);
}

.secret-scanner-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--base-color-error);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.regex-list {
  width: 100%;
}
.regex-list .rt-thead {
  height: var(--size-m);
  background: var(--background-color-primary) !important;
}
.regex-list .rt-thead.-header {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.regex-list .rt-tbody .rt-td {
  padding: var(--spacing-xs) var(--spacing-s);
  min-height: 44px;
}
.regex-list .rt-tbody .rt-td .token-scanner-table-name {
  display: contents;
  font-size: var(--text-size-m);
}
.regex-list .rt-tr:hover .regex-list__edit-btn, .regex-list .rt-tr:hover .regex-list__delete-btn {
  visibility: visible;
  opacity: 1;
}
.regex-list__edit-btn, .regex-list__delete-btn {
  visibility: hidden;
  opacity: 0;
}

.default-regex-table .table-row {
  height: 44px;
  padding: var(--spacing-m) var(--spacing-s);
  margin-bottom: var(--spacing-zero);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.default-regex-table .table-row .infinite-loader {
  height: 20px;
  background-color: var(--background-color-tertiary);
  animation: fade 1s linear infinite;
  border-radius: 4px;
}
.default-regex-table .table-header {
  height: 32px;
  padding: var(--spacing-s);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.token-scanner-add-form .scan-option {
  gap: var(--spacing-s);
}
.token-scanner-add-form .scan-option-dry-run {
  margin-top: var(--spacing-s);
}
.token-scanner-add-form .scan-option-add-pattern, .token-scanner-add-form .scan-option-dry-run {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.dry-run-results {
  height: calc(100vh - 137px);
  overflow-y: scroll;
}
.dry-run-results.full-height {
  height: calc(100vh - 48px);
}
.dry-run-results .bottom-bar {
  position: fixed;
  bottom: 0;
  background-color: var(--background-color-primary);
  padding: var(--spacing-xl) var(--spacing-zero) var(--spacing-xxl) var(--spacing-zero);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.dry-run-results .bottom-bar.light-theme {
  box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 8px -4px;
}
.dry-run-results .bottom-bar.dark-theme {
  box-shadow: rgba(255, 255, 255, 0.2) 0px -2px 8px -4px;
}
.dry-run-results .leaks-listing-container .empty-secrets-container i > svg {
  width: 16px;
  height: 16px;
}
.dry-run-results .leak-details-sticky-header {
  padding-bottom: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.token-scanner-edit-form {
  overflow: auto;
}

.secret-info-popover {
  width: 300px;
  background: var(--background-color-primary);
  border-radius: 5px;
}
.secret-info-popover .block-info__hash-value, .secret-info-popover .block-info__hash-value:hover {
  background-color: var(--background-color-secondary);
  border: none;
  box-shadow: none;
}
.secret-info-popover .block-info__copy {
  color: var(--content-color-secondary);
}
.secret-info-popover .block-info__copy--copied:hover i svg {
  color: var(--base-color-success);
}
.secret-info-popover .block-info__copy--copied i svg {
  color: var(--base-color-success);
}

.leak-details-page .element-filter div[class^=FilterSelect__StyledFilterSelectPlaceHolder-] {
  color: var(--content-color-primary);
}

.aether-filter-select-dropdown__menu-list.invisible {
  max-height: 150px;
}
.aether-filter-select-dropdown__menu-list.invisible .aether-filter-select-dropdown__option, .aether-filter-select-dropdown__menu-list.invisible .aether-filter-select-dropdown__menu-notice {
  display: none;
}
.aether-filter-select-dropdown__menu-list.visible {
  visibility: visible;
  max-height: 250px;
}

.infinite-load-spinner .spinner-container {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  height: 16px;
  width: 16px;
}
.infinite-load-spinner .spinner-container > div {
  height: 100%;
  width: 4px;
  opacity: 0.2;
  -webkit-animation: gwQIXW 0.6s infinite ease-in-out;
  animation: gwQIXW 0.6s infinite ease-in-out;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  background-color: var(--content-color-tertiary);
}
.infinite-load-spinner .spinner-container .rect-two {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.infinite-load-spinner .spinner-container .rect-three {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.move-entity-modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.move-entity-modal__filters {
  display: flex;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-s);
  height: var(--size-m);
}
.move-entity-modal__filters--no-margin {
  margin-top: var(--spacing-zero);
}
.move-entity-modal__filters .input-search-group {
  border-radius: var(--border-radius-default);
}
.move-entity-modal__filters .workspace-visibility-dropdown-container {
  margin-left: var(--spacing-s);
}
.move-entity-modal__filters .workspace-visibility-dropdown-container .dropdown-button .btn {
  height: 30px;
}
.move-entity-modal__empty-state {
  max-width: 390px;
  padding: var(--spacing-s);
}
.move-entity-modal .workspace-list-container {
  height: 288px;
  overflow: auto;
}
.move-entity-modal__workspace-list .workspace-list-container {
  height: 288px;
  overflow: auto;
}
.move-entity-modal__workspace-list--extended .workspace-list-container {
  height: 340px;
}

.move-entity-modal-container__modal-content {
  min-height: 450px;
}
.move-entity-modal-container__modal-content__inner {
  padding-top: var(--spacing-zero);
}
.move-entity-modal-container__modal-content__initial-error {
  flex-grow: 1;
  max-width: 390px;
  margin-top: var(--spacing-xxl);
}
.move-entity-modal-container__no-access {
  flex-grow: 1;
  margin-top: 56px;
}

.workspace-visibility-dropdown-container__notifier {
  height: 6px;
  width: 6px;
  position: absolute;
  top: 5px;
  left: 17px;
  z-index: 1;
  background: var(--base-color-success);
  border-radius: var(--border-radius-max);
  border: var(--border-width-l) var(--border-style-solid) var(--background-color-primary);
  pointer-events: none;
}
.workspace-visibility-dropdown-container:hover .workspace-visibility-dropdown-container__notifier {
  border-color: var(--border-color-default);
}
.workspace-visibility-dropdown-container__header {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-xs);
}
.workspace-visibility-dropdown-container__item__icon {
  margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xl);
}
.workspace-visibility-dropdown-container__item__icon--active {
  margin-left: var(--spacing-s);
}

.workspace-list-container__spinner {
  display: flex;
  justify-content: center;
  margin: var(--spacing-l) var(--spacing-zero);
}
.workspace-list-container__error-state {
  margin-top: var(--spacing-xl);
  max-width: 390px;
}

.workspace-list-item-container {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-s) var(--spacing-m);
}
.workspace-list-item-container--enable-hover:hover {
  background-color: var(--highlight-background-color-primary);
  border-radius: var(--border-radius-default);
}
.workspace-list-item-container--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.workspace-list-item-container--selected {
  background-color: var(--highlight-background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.workspace-list-item-container__info {
  max-width: 95%;
}
.workspace-list-item-container__info__icon {
  margin-left: var(--spacing-xs);
  pointer-events: all;
}
.workspace-list-item-container__info__name {
  max-width: 200px;
}
.workspace-list-item-container__info__visibility-icon {
  margin-right: var(--spacing-s) !important;
}
.workspace-list-item-container__disabled-info__icon {
  pointer-events: all;
}

.collaboration-skeleton-unit {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-width-l);
  animation: fade 1s linear infinite;
}

.workspace-elements-list-container .workspace-elements-list__item {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  height: var(--size-m);
}
.workspace-elements-list-container .workspace-elements-list__item__info {
  flex: 1;
}
.workspace-elements-list-container .workspace-elements-list__item__info:hover .workspace-elements-list__item__info__identifier:not(.is-disabled) {
  text-decoration: underline;
  text-decoration-color: var(--content-color-primary);
}
.workspace-elements-list-container .workspace-elements-list__item__info__icon {
  padding: var(--spacing-xs);
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
}
.workspace-elements-list-container .workspace-elements-list__item__info__identifier {
  cursor: pointer;
  margin-left: var(--spacing-s);
}
.workspace-elements-list-container .workspace-elements-list__item__info__identifier.is-disabled {
  cursor: default;
}
.workspace-elements-list-container .workspace-elements-list__item__cta {
  display: none;
}
.workspace-elements-list-container .workspace-elements-list__item__cta__import {
  display: flex;
  padding: var(--spacing-xs);
  height: auto;
}
.workspace-elements-list-container .workspace-elements-list__item__cta__create {
  display: flex;
  padding: var(--spacing-xs);
  height: auto;
}
.workspace-elements-list-container .workspace-elements-list__item:hover {
  cursor: pointer;
}
.workspace-elements-list-container .workspace-elements-list__item:hover .workspace-elements-list__item__cta {
  display: flex;
  cursor: pointer;
  margin-left: var(--spacing-xs);
}
.workspace-elements-list-container .workspace-elements-list__item:hover .workspace-elements-list__item__info__icon:not(.is-disabled) svg {
  color: var(--content-color-primary);
}

.contributors {
  min-width: 0;
}
.contributors__heading {
  margin-bottom: var(--spacing-m);
}
.contributors .contributors-list {
  margin-bottom: var(--spacing-s);
}
.contributors__view-all {
  cursor: pointer;
}
.contributors__view-all:hover {
  text-decoration: underline;
  text-decoration-color: var(--content-color-secondary);
}

.contributors-modal__content > *:not(:last-child) {
  margin-bottom: var(--spacing-l);
}
.contributors-modal__content__info {
  margin-bottom: var(--spacing-l);
}
.contributors-modal__content__list {
  max-height: 408px;
  overflow: auto;
}
.contributors-modal__content__list .contributors-list {
  max-width: 360px;
}

.contributors-empty-state {
  min-width: 0;
}
.contributors-empty-state__avatar {
  border-radius: var(--border-radius-max);
}

.contributors-list__item:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--content-color-primary);
}
.contributors-list__private-user__icon {
  cursor: default;
}

.workspace-invite-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  min-height: 70px;
  max-height: 196px;
  overflow-y: auto;
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
}
.workspace-invite-container__teamPlan_alert {
  border: var(--border-width-default) var(--border-style-solid) var(--highlight-background-color-error);
}
.workspace-invite-container__onFocus {
  border: var(--border-width-default) var(--border-style-solid) var(--input-border-color-focus);
  box-shadow: 0px 0px 0px 2px var(--input-shadow-focus);
}
.workspace-invite-container__onError {
  border: var(--border-width-default) var(--border-style-solid) var(--highlight-background-color-error);
  box-shadow: unset;
}
.workspace-invite-container__empty-state--with-alert {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-error);
  margin-left: var(--spacing-xxl);
  margin-top: var(--spacing-s);
}
.workspace-invite-container .invite-placeholder {
  margin-left: var(--spacing-s);
  position: absolute;
  color: var(--content-color-tertiary);
}
.workspace-invite-container .active-input {
  flex: 1;
  align-items: center;
  color: var(--content-color-primary);
  padding: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) var(--spacing-s);
  margin: var(--spacing-xs);
}

.ws-invite-team-users-dropdown {
  background-color: var(--background-color-primary);
}
.ws-invite-team-users-dropdown .team-user-suggestion {
  height: 56px;
}
.ws-invite-team-users-dropdown .team-user-suggestion__name {
  max-width: calc(8 * var(--spacing-xxl));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ws-invite-team-users-dropdown .team-user-suggestion .avatar {
  margin-right: var(--spacing-s);
}
.ws-invite-team-users-dropdown .team-user-suggestion__email {
  margin-top: var(--spacing-xs);
  color: var(--content-color-secondary);
}
.ws-invite-team-users-dropdown .team-user-suggestion__role {
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}
.ws-invite-team-users-dropdown .team-user-suggestion .team-user-suggestion__group-tag {
  width: var(--size-s);
  height: var(--size-s);
  margin-right: var(--spacing-s);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}

.workspace-invite-input-file-drop {
  display: flex;
  flex: 1;
}

.workspace-invite-input-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: var(--text-size-m);
  height: 100%;
}

.workspace-invite-tag {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
}
.workspace-invite-tag .avatar {
  margin: 0 var(--spacing-xs);
}
.workspace-invite-tag .user-email {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.workspace-invite-tag .user-group {
  width: calc(var(--size-s) / 2);
  height: calc(var(--size-s) / 2);
  padding: calc(var(--spacing-xs) / 2);
  margin: 0 var(--spacing-xs);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}

.invite-workspace-modal .invite-workspace-modal__modal-content {
  overflow-y: unset;
  font-size: var(--text-size-m);
}
.invite-workspace-modal .invite-workspace-modal__modal-content .invite-file-upload {
  cursor: pointer;
  height: 100%;
  font-size: var(--text-size-s);
}
.invite-workspace-modal .invite-workspace-modal__modal-content .invite-file-upload :hover {
  color: var(--content-color-primary);
}
.invite-workspace-modal .invite-workspace-modal__input-area {
  margin-bottom: var(--spacing-l);
}
.invite-workspace-modal .invite-workspace-modal__footer {
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.invite-workspace-modal .invite-workspace-modal__manage-team {
  color: var(--content-color-secondary);
}

.ws-invite-link {
  padding-left: var(--spacing-zero);
  color: var(--content-color-link);
}
.ws-invite-link svg {
  color: var(--content-color-link);
}

.ws-invite-link-copied {
  padding-left: var(--spacing-zero);
  color: var(--content-color-success);
}
.ws-invite-link-copied svg {
  color: var(--content-color-success);
}

.ws-invite-link:hover {
  background-color: inherit;
  color: var(--invite-modal-copy-link-button-hover-color);
}
.ws-invite-link:hover svg {
  color: var(--invite-modal-copy-link-button-hover-color);
}

.ws-invite-link-disabled .ws-invite-link-disabled:hover {
  background-color: inherit;
  color: var(--content-color-tertiary);
}
.ws-invite-link-disabled .ws-invite-link-disabled:hover svg {
  color: var(--content-color-tertiary);
}

.share-element-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  max-height: 196px;
  overflow-y: auto;
  padding-left: var(--spacing-s);
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
}
.share-element-container__teamPlan_alert {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-error);
}
.share-element-container__onFocus {
  border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
  box-shadow: 0px 0px 0px 2px var(--blue-30);
}
.share-element-container__onError {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-error);
  box-shadow: unset;
}
.share-element-container__empty-state--with-alert {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-error);
  margin-left: var(--spacing-xxl);
  margin-top: var(--spacing-s);
}
.share-element-container__export {
  max-height: 116px;
}
.share-element-container .invite-placeholder {
  margin-left: var(--spacing-s);
  position: absolute;
  color: var(--content-color-tertiary);
}
.share-element-container .invite-placeholder__padding {
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-m);
}
.share-element-container .active-input {
  flex: 1;
  align-items: center;
  color: var(--content-color-primary);
  margin: var(--spacing-xs);
}

.share-invite-tag {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
}
.share-invite-tag .avatar {
  margin: 0 var(--spacing-xs);
}
.share-invite-tag .user-email {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.share-invite-tag .user-group {
  width: calc(var(--size-s) / 2);
  height: calc(var(--size-s) / 2);
  padding: calc(var(--spacing-xs) / 2);
  margin: 0 var(--spacing-xs);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}

.share-element-input-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: var(--text-size-m);
  height: 100%;
}
.share-element-input-wrapper .share-element-button {
  padding: var(--spacing-m) var(--spacing-l);
}

.active-input-centered {
  line-height: var(--line-height-l);
}
.active-input-centered:empty:before {
  content: attr(placeholder);
  color: var(--content-color-tertiary);
}

.share-element-team-users-dropdown {
  background-color: var(--background-color-primary);
  padding-right: var(--spacing-zero);
}
.share-element-team-users-dropdown .team-user-suggestion {
  height: var(--text-line-height-xxxl);
  margin: var(--spacing-zero);
}
.share-element-team-users-dropdown .team-user-suggestion__name {
  max-width: calc(8 * var(--spacing-xxl));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.share-element-team-users-dropdown .team-user-suggestion .avatar {
  margin-right: var(--spacing-s);
}
.share-element-team-users-dropdown .team-user-suggestion__email {
  margin-top: var(--spacing-xs);
  color: var(--content-color-secondary);
}
.share-element-team-users-dropdown .team-user-suggestion__role {
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}
.share-element-team-users-dropdown .team-user-suggestion .team-user-suggestion__group-tag {
  width: var(--size-s);
  height: var(--size-s);
  margin-right: var(--spacing-s);
  align-items: center;
  justify-content: center;
  align-self: center;
  background: var(--background-color-tertiary);
  border-radius: var(--border-radius-max);
}

.workspace-visibility-container {
  width: 100%;
}
.workspace-visibility-container--pending-request {
  padding: var(--spacing-l);
  margin-bottom: var(--spacing-m);
  color: var(--content-color-primary);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-info);
}
.workspace-visibility-container--pending-request-heading {
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
}
.workspace-visibility-container--pending-request > p:last-child {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-zero);
}
.workspace-visibility-container--pending-request > p:last-child a {
  text-decoration: none;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.workspace-visibility-container--pending-request > p:last-child a:hover {
  color: var(--content-color-link);
}
.workspace-visibility-container--info {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.workspace-visibility-container--info a {
  color: var(--content-color-link);
}
.workspace-visibility-container--info a:hover {
  text-decoration: underline;
}
.workspace-visibility-container--info .visibility-pm-icon {
  margin-right: var(--spacing-s);
}
.workspace-visibility-container--info .visibility-pm-icon use {
  fill: var(--content-color-tertiary);
}
.workspace-visibility-container--info .visibility-pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.workspace-visibility-container--info .visibility-pm-icon svg {
  color: var(--content-color-tertiary);
}

.role-list-show-more {
  max-width: calc(2 * var(--size-m));
}

.workspace-role-user-meta {
  max-width: 300px;
}

.workspace-role-user-meta-name {
  max-width: 65%;
}

.ws-settings-role-dropdown {
  padding-right: 0px;
}
.ws-settings-role-dropdown i {
  margin-left: var(--spacing-xs);
}

.workspace-role-dropdown-menu {
  width: 100%;
  min-width: 316px;
  background-color: var(--background-color-primary);
  padding-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  border: none;
}
.workspace-role-dropdown-menu .dropdown-menu-item.is-disabled {
  cursor: not-allowed;
}
.workspace-role-dropdown-menu .dropdown-menu-item.partner-lead-option {
  cursor: unset;
}
.workspace-role-dropdown-menu .partner-role {
  padding-top: var(--spacing-xs);
}
.workspace-role-dropdown-menu .selected-role-icon {
  padding-top: var(--spacing-xs);
}
.workspace-role-dropdown-menu .dropdown-menu-item {
  height: auto;
  padding: var(--spacing-zero);
}
.workspace-role-dropdown-menu .role-list-item {
  max-width: 100%;
  padding-right: var(--spacing-s);
  padding-left: var(--spacing-s);
}
.workspace-role-dropdown-menu .dropdown-menu-item span {
  white-space: unset;
}
.workspace-role-dropdown-menu .role-list-item-data {
  width: 100%;
}
.workspace-role-dropdown-menu .role-remove-button {
  color: var(--content-color-error);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.workspace-role-dropdown-menu .partner-lead-option {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.workspace-role-dropdown-menu .role-remove-button:hover {
  color: var(--content-color-constant);
  background-color: var(--base-color-error);
}

.ws-role-list-group-avatar {
  cursor: pointer;
}

.workspace-roles-list-item__members-list {
  width: 240px;
  max-height: 300px;
}
.workspace-roles-list-item__members-list .tooltip-wrapper {
  padding: var(--spacing-xl);
}
.workspace-roles-list-item__members-list__title {
  display: flex;
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--spacing-xs);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.workspace-roles-list-item__members-list__title__group-name {
  max-width: 108px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workspace-roles-list-item__members-list__title__no-members {
  max-width: unset;
}
.workspace-roles-list-item__members-list__content {
  max-height: 282px;
  overflow-y: auto;
  margin-bottom: var(--spacing-l);
  font-weight: var(--text-weight-regular);
}
.workspace-roles-list-item__members-list__content__no-members {
  margin-top: var(--spacing-xs);
}
.workspace-roles-list-item__members-list__content__no-members label {
  line-height: var(--line-height-m);
}
.workspace-roles-list-item__members-list__content__item {
  display: flex;
  margin-top: var(--spacing-s);
  align-items: center;
}
.workspace-roles-list-item__members-list__content__item__name {
  margin-left: var(--spacing-s);
  max-width: 176px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.workspace-roles-list-item__members-list__btn {
  margin-top: var(--spacing-xs);
  max-width: calc(3 * var(--size-m));
}
.workspace-roles-list-item__members-list__no-members {
  margin-bottom: var(--spacing-s);
}

.ws-settings {
  max-width: 440px;
  margin-bottom: var(--spacing-xxl);
  width: 100%;
}

.ws-back-to-overview-btn {
  max-width: calc(3 * var(--spacing-xxl));
  margin-top: var(--spacing-m);
  padding-left: var(--spacing-zero);
  justify-content: unset;
}

.ws-back-to-overview-btn:hover {
  color: var(--content-color-link);
  background-color: inherit;
}
.ws-back-to-overview-btn:hover svg {
  color: var(--content-color-link);
}

.create-workspace-visibility {
  width: 100%;
}
.create-workspace-visibility--pending-request {
  padding: var(--spacing-l);
  margin-bottom: var(--spacing-m);
  color: var(--content-color-primary);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-info);
}
.create-workspace-visibility--pending-request-heading {
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
}
.create-workspace-visibility--pending-request > p:last-child {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-zero);
}
.create-workspace-visibility--pending-request > p:last-child a {
  text-decoration: none;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.create-workspace-visibility--pending-request > p:last-child a:hover {
  color: var(--content-color-link);
}
.create-workspace-visibility--title {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  margin: unset;
  user-select: none;
  margin-bottom: var(--spacing-s);
}
.create-workspace-visibility--container {
  display: grid;
  row-gap: var(--spacing-xs);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-m);
}
.create-workspace-visibility--subtitle {
  margin-left: 28px;
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.create-workspace-visibility--roles {
  margin-left: 28px;
  display: inline-block;
  color: var(--content-color-secondary);
}
.create-workspace-visibility--roles--dropdown-button {
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-zero) var(--spacing-xs);
  height: var(--size-x);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.create-workspace-visibility--actions {
  margin-top: var(--spacing-l);
}
.create-workspace-visibility--actions-create-team-btn {
  width: calc(8 * var(--size-xs));
  margin-right: var(--spacing-s);
}
.create-workspace-visibility--actions-cancel-btn {
  background: none;
}
.create-workspace-visibility--dropdown-menu .pm-icon {
  margin-right: var(--spacing-m);
}
.create-workspace-visibility--dropdown-button {
  display: flex;
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-zero);
  height: var(--size-x);
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
}
.create-workspace-visibility--dropdown-button .pm-icon {
  margin-right: var(--spacing-m);
}
.create-workspace-visibility--beta-label {
  font-size: var(--text-size-xs);
  color: var(--content-color-info);
  font-weight: var(--text-weight-medium);
  position: relative;
  bottom: var(--spacing-xs);
  left: var(--spacing-xs);
}
.create-workspace-visibility--info {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-m);
  line-height: var(--line-height-m);
  color: var(--content-color-tertiary);
}
.create-workspace-visibility--info a {
  color: var(--content-color-link);
}
.create-workspace-visibility--info a:hover {
  text-decoration: underline;
}
.create-workspace-visibility--info .pm-icon {
  margin-right: var(--spacing-s);
}
.create-workspace-visibility--info .pm-icon use {
  fill: var(--content-color-tertiary);
}
.create-workspace-visibility--info .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.create-workspace-visibility--info .pm-icon svg {
  color: var(--content-color-tertiary);
}
.create-workspace-visibility--error {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-m);
  color: var(--content-color-error);
}
.create-workspace-visibility--error .pm-icon {
  margin-right: var(--spacing-s);
}
.create-workspace-visibility--error .pm-icon use {
  fill: var(--content-color-error);
}
.create-workspace-visibility--error .pm-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-error);
}
.create-workspace-visibility--error .pm-icon svg {
  color: var(--content-color-error);
}
.create-workspace-visibility--error a {
  margin-left: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-error);
  text-decoration: underline;
  cursor: pointer;
}
.create-workspace-visibility--footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--spacing-l);
}
.create-workspace-visibility--footer .btn:first-child {
  margin-right: var(--spacing-xl);
}

.create-ws-outer-container {
  height: 100vh;
  overflow-y: scroll;
}
.create-ws-outer-container .create-ws-container {
  margin: var(--spacing-zero) auto;
  max-width: 480px;
}
.create-ws-outer-container .create-ws-container .create-workspace-info-banner a {
  color: var(--content-color-link);
}
.create-ws-outer-container .create-ws-container .visibility-radio-loader {
  border-radius: 50%;
}

.create-workspace-info-banner a {
  color: var(--content-color-link);
}

.variables-empty {
  margin-top: 96px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}
.variables-empty__base-text {
  font-size: 14px;
  font-weight: var(--text-weight-medium);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
}
.variables-empty__request-access-text {
  font-size: 12px;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
}

.variables-editor {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.variables-editor__meta {
  margin-bottom: var(--spacing-l);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.variables-editor__meta .btn {
  padding: var(--spacing-zero);
}
.variables-editor__meta .btn-text {
  font-weight: var(--text-weight-regular);
}
.variables-editor__meta.is-bleed {
  padding: var(--spacing-zero) var(--spacing-l);
}
.variables-editor .external-link {
  font-size: var(--text-size-m);
}
.variables-editor__content {
  height: 100%;
  overflow-y: auto;
}
.variables-editor__content .key-value-form-row:hover:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-error, .variables-editor__content .key-value-form-row:hover:not(.row-focused) .key-value-form-column:last-child .auto-suggest.has-warning {
  margin-right: var(--spacing-xxxl);
}
.variables-editor__alert {
  margin: var(--spacing-l) var(--spacing-l);
}
.variables-editor__alert:empty {
  display: none;
}

.variables-editor__content.is-virtualized {
  height: 100%;
  overflow: hidden;
  padding: 0 var(--spacing-l);
}
.variables-editor__content.is-virtualized .key-value-editor {
  height: 100%;
}
.variables-editor__content.is-virtualized .key-value-editor .key-value-form-editor {
  height: 100%;
}
.variables-editor__content.is-virtualized .key-value-editor .key-value-form-editor .key-value-form-editor-sortable {
  height: 100%;
}
.variables-editor__content.is-virtualized .key-value-editor .key-value-form-editor .key-value-form-editor-sortable .key-value-form-editor-sortable__selectable {
  height: 100%;
}

.entity-tab-header {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s) var(--spacing-l);
  align-items: flex-start;
  box-sizing: border-box;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.entity-tab-header__name {
  display: flex;
  flex: 1;
  min-width: 0;
  align-items: center;
  line-height: var(--line-height-m);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.entity-tab-header__name__container {
  display: flex;
  width: 100%;
}
.entity-tab-header__name__input {
  padding-left: 3px;
  color: var(--content-color-primary);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.entity-tab-header__name__wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.entity-tab-header__name.is-editing .entity-tab-header__name__wrapper {
  width: 100%;
}
.entity-tab-header__name.is-editing .inline-input__wrapper {
  width: 100%;
}
.entity-tab-header__name .inline-input__wrapper .input-box {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  padding: 2px;
}
.entity-tab-header__name .breadcrumb__container {
  padding-left: 3px;
}
.entity-tab-header__name .breadcrumb__container:hover .breadcrumb__text:not(:last-child) .breadcrumb__name {
  transition: color 0.2s ease-in-out;
}
.entity-tab-header__name .breadcrumb__container .breadcrumb__text:last-child:hover .breadcrumb__name {
  cursor: auto;
  user-select: text;
  color: var(--content-color-primary);
}
.entity-tab-header__name .breadcrumb__container .breadcrumb__text:not(:last-child) .breadcrumb__name {
  color: var(--content-color-tertiary);
}
.entity-tab-header__name .breadcrumb__container .breadcrumb__text:not(:last-child) .breadcrumb__name:hover {
  color: var(--content-color-link);
}
.entity-tab-header__name .entity-tab-header__edit-btn {
  cursor: pointer;
  background-color: transparent;
  width: var(--size-s);
  height: var(--size-s);
  margin-left: var(--spacing-xs);
}
.entity-tab-header__name .entity-tab-header__edit-btn:hover use, .entity-tab-header__name .entity-tab-header__edit-btn.is-hovered use {
  fill: var(--content-color-primary);
}
.entity-tab-header__name .entity-tab-header__edit-btn:hover svg:not([class*=PartnerIcon]) > path[fill], .entity-tab-header__name .entity-tab-header__edit-btn.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.entity-tab-header__name .entity-tab-header__edit-btn:hover svg, .entity-tab-header__name .entity-tab-header__edit-btn.is-hovered svg {
  color: var(--content-color-primary);
}
.entity-tab-header__name.show-on-hover .entity-tab-header__copy-btn {
  visibility: hidden;
}
.entity-tab-header__name.show-on-hover:hover .entity-tab-header__copy-btn {
  visibility: visible;
}
.entity-tab-header__actions {
  display: flex;
  margin-left: var(--spacing-s);
  justify-content: flex-end;
  align-items: center;
}
.entity-tab-header .entity-tab-header__copy-btn {
  cursor: pointer;
}
.entity-tab-header__left-meta-component {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-xs);
}
.entity-tab-header__subtitle {
  max-width: 100%;
}
.entity-tab-header__subtitle > *:first-child {
  margin-top: var(--spacing-xs);
}
.entity-tab-header .environment-container {
  border-radius: var(--border-radius-default);
  margin-left: var(--spacing-m);
  height: var(--size-m);
}
.entity-tab-header .environment-container .input-select-v2-wrapper .input-search-group {
  border-top-left-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
}
.entity-tab-header .environment-container .input-select-v2-wrapper.is-open {
  width: 210px !important;
}
.entity-tab-header .environment-container .input-search-group:hover, .entity-tab-header .environment-container .input-search-group.is-hovered {
  background-color: transparent;
  border-color: transparent;
}
.entity-tab-header .environment-container .environment-splitter {
  height: 20px;
  border-left: var(--border-width-default) var(--border-style-solid);
  border-color: var(--environment-divider-border-color);
}
.entity-tab-header .environment-container .environment-selector-container {
  align-items: center;
  border-radius: var(--border-radius-default);
  border: var(--border-width-l) var(--border-style-solid) transparent;
  padding: 0 0 0 var(--spacing-s);
  margin-right: 0;
}
.entity-tab-header .environment-container .environment-selector-container:focus-within {
  border: var(--border-width-l) var(--border-style-solid) var(--environment-input-focus-color);
}
.entity-tab-header .environment-container .environment-selector-container-header--browser-tabs-enabled {
  border: none;
}
.entity-tab-header .environment-container .environment-selector-container-header--browser-tabs-enabled:focus-within {
  border: none;
}
.entity-tab-header .environment-container .environment-preview-container {
  margin-right: 0;
}

.environment-selector-container-no-margin-header {
  margin-right: 0px;
}

.environment-splitter-new {
  margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xs);
  height: var(--size-xs);
  border-left: var(--border-width-default) var(--border-style-solid);
  border-color: var(--environment-divider-border-color);
}

.generic-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.generic-empty-state__title {
  color: var(--content-color-primary);
}
.generic-empty-state__illustration + .generic-empty-state__title {
  margin-top: var(--spacing-l);
}
.generic-empty-state__message {
  text-align: center;
}
.generic-empty-state__illustration + .generic-empty-state__message {
  margin-top: var(--spacing-l);
}
.generic-empty-state__title + .generic-empty-state__message {
  margin-top: var(--spacing-s);
}
.generic-empty-state__primary-action {
  margin-top: var(--spacing-xl);
}
.generic-empty-state__secondary-action {
  margin-top: var(--spacing-xl);
}
.generic-empty-state__primary-action + .generic-empty-state__secondary-action {
  margin-top: var(--spacing-l);
}

.artemis-empty-state {
  display: flex;
  flex-direction: column;
}
.artemis-empty-state .artemis-empty-state__title {
  color: var(--content-color-primary);
}
.artemis-empty-state .artemis-empty-state__asset + .artemis-empty-state__title {
  margin-top: var(--spacing-l);
}
.artemis-empty-state .artemis-empty-state__message {
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}
.artemis-empty-state .artemis-empty-state__primary-action {
  margin-top: var(--spacing-l);
  height: 32px;
  width: fit-content;
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
}
.artemis-empty-state .artemis-empty-state__primary-action.margin-xl {
  margin-top: var(--spacing-xl);
}
.artemis-empty-state .artemis-empty-state__secondary-action {
  width: 250px;
  margin-top: var(--spacing-xl);
  padding: var(--spacing-zero);
}

.entity-meta-info {
  display: flex;
  flex-direction: column;
}
.entity-meta-info__item {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xl);
}
.entity-meta-info__item__label {
  display: flex;
  margin-bottom: var(--spacing-xs);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.entity-meta-info__item__content {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.entity-meta-info__item__content__name {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.entity-meta-info__item__content__id {
  width: 100%;
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  padding: 6px var(--spacing-s) 6px var(--spacing-s);
  margin-right: var(--spacing-s);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.entity-meta-info__item__content__action {
  height: var(--size-xs);
  width: 16px;
  margin-left: var(--spacing-s);
  background-color: transparent;
}
.entity-meta-info__item__content__action:hover, .entity-meta-info__item__content__action.is-hovered {
  background-color: transparent;
}
.entity-meta-info__item__content__action--copy use {
  fill: var(--content-color-secondary);
}
.entity-meta-info__item__content__action--copy svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.entity-meta-info__item__content__action--copy svg {
  color: var(--content-color-secondary);
}
.entity-meta-info__item__content__action--copy:hover use, .entity-meta-info__item__content__action--copy.is-hovered use {
  fill: var(--content-color-primary);
}
.entity-meta-info__item__content__action--copy:hover svg:not([class*=PartnerIcon]) > path[fill], .entity-meta-info__item__content__action--copy.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.entity-meta-info__item__content__action--copy:hover svg, .entity-meta-info__item__content__action--copy.is-hovered svg {
  color: var(--content-color-primary);
}
.entity-meta-info__item__content__action--success {
  cursor: default;
}
.entity-meta-info__item__content__action--success use {
  fill: var(--content-color-success);
}
.entity-meta-info__item__content__action--success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-success);
}
.entity-meta-info__item__content__action--success svg {
  color: var(--content-color-success);
}
.entity-meta-info__item__content.user-info {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.entity-meta-info__item__content.user-info .user-avatar {
  margin-right: var(--spacing-s);
}
.entity-meta-info__item__content.is-clickable {
  cursor: pointer;
}
.entity-meta-info__item:first-child {
  margin-top: var(--spacing-zero);
}

.entity-tab-empty-shell {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: var(--background-color-primary);
  animation: fade 1s linear infinite;
}
.entity-tab-empty-shell__header-wrapper {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.entity-tab-empty-shell__header-wrapper__content {
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-m) var(--spacing-l);
}
.entity-tab-empty-shell__header-wrapper__content__name-strip {
  height: var(--size-s);
  width: 196px;
}
.entity-tab-empty-shell__header-wrapper__content__actions {
  display: flex;
  width: 96px;
  justify-content: space-around;
}
.entity-tab-empty-shell__body-wrapper {
  display: flex;
  flex: 1;
}
.entity-tab-empty-shell__body-wrapper__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: var(--spacing-l);
}
.entity-tab-empty-shell__body-wrapper__content .tab-strip {
  display: flex;
  margin-bottom: var(--spacing-l);
}
.entity-tab-empty-shell__body-wrapper__content .tab-strip .tab {
  height: 20px;
  margin-right: var(--spacing-l);
}
.entity-tab-empty-shell__body-wrapper__content .tab-strip .tab.xl {
  width: 196px;
}
.entity-tab-empty-shell__body-wrapper__content .tab-strip .tab.md {
  width: 80px;
}
.entity-tab-empty-shell__body-wrapper__content .tab-strip .tab.s {
  width: 56px;
}
.entity-tab-empty-shell__body-wrapper__content .tab-strip .tab.xs {
  width: 40px;
}
.entity-tab-empty-shell__body-wrapper__content__primary {
  height: 120px;
  margin-bottom: var(--spacing-m);
}
.entity-tab-empty-shell__body-wrapper__content__secondary {
  width: 320px;
  height: 40px;
}
.entity-tab-empty-shell__body-wrapper__context-bar {
  display: flex;
  flex-direction: column;
  width: 40px;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.entity-tab-empty-shell__body-wrapper__context-bar > * {
  margin: var(--spacing-s) var(--spacing-s) var(--spacing-zero);
}
.entity-tab-empty-shell__left_editor {
  flex: 1;
}
.entity-tab-empty-shell__right-context-bar {
  width: 440px;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  padding: var(--spacing-xs) var(--spacing-xl) var(--spacing-s) var(--spacing-m);
  display: flex;
  box-sizing: border-box;
}
.entity-tab-empty-shell__right-context-bar .context-bar-options {
  display: flex;
  flex-direction: column;
}
.entity-tab-empty-shell__right-context-bar .context-bar-options .box {
  margin-bottom: var(--spacing-m);
  margin-top: var(--spacing-xs);
}
.entity-tab-empty-shell__right-context-bar .context-bar-body {
  margin: var(--spacing-s) var(--spacing-m) var(--spacing-s) var(--spacing-xl);
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.entity-tab-empty-shell__right-context-bar .context-bar-header {
  height: var(--size-s);
  width: 120px;
  background-color: var(--background-color-tertiary);
}
.entity-tab-empty-shell__right-context-bar .context-bar-close-icon {
  height: var(--size-s);
  width: var(--size-s);
  background-color: var(--background-color-tertiary);
}
.entity-tab-empty-shell .box {
  height: var(--size-s);
  width: var(--size-s);
}
.entity-tab-empty-shell .block {
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
}

.entity-tab-error {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.entity-tab-error__content {
  display: flex;
  width: 415px;
  padding: var(--spacing-l);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.entity-tab-error__content__illustration {
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: var(--spacing-l);
  background-color: var(--background-color-tertiary);
}
.entity-tab-error__content__illustration__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.entity-tab-error__content__illustration__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}
.entity-tab-error__content__illustration-wrapper {
  margin-bottom: var(--spacing-l);
}
.entity-tab-error__content__title {
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.entity-tab-error__content__title--forbidden {
  width: 80%;
  text-align: center;
}
.entity-tab-error__content__body {
  color: var(--content-color-secondary);
  text-align: center;
}
.entity-tab-error__content__body--forbidden {
  width: 80%;
}
.entity-tab-error__content__action {
  margin-top: var(--spacing-xl);
  height: var(--controls-size-default);
  min-width: 60px;
}

.entity-actions-dropdown .dropdown-menu {
  min-width: 200px;
}
.entity-actions-dropdown .dropdown-menu .dropdown-menu-item-icon {
  margin-right: var(--spacing-s);
}
.entity-actions-dropdown .dropdown-menu .entity__action-item {
  display: flex;
  flex: 1;
}
.entity-actions-dropdown.use-browser-tabs .dropdown-menu {
  background-color: var(--background-color-primary);
  /**
  * fixes open in New Tab option text & shortcut in WindowOS.
  * https://postmanlabs.atlassian.net/browse/APPUX-153
  */
  min-width: 250px;
}
.entity-actions-dropdown.use-browser-tabs .dropdown-menu .dropdown-menu-item-shortcut {
  margin-left: var(--spacing-l);
}

.request-permission-infobar {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.request-permission-infobar .infobar {
  min-height: 64px;
  background-color: var(--background-color-primary);
}
.request-permission-infobar .infobar .permission-infobar-icon {
  margin-right: var(--spacing-s);
}
.request-permission-infobar .infobar .permission-infobar-icon use {
  fill: var(--content-color-secondary);
}
.request-permission-infobar .infobar .permission-infobar-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.request-permission-infobar .infobar .permission-infobar-icon svg {
  color: var(--content-color-secondary);
}
.request-permission-infobar .infobar .infobar__text-wrapper {
  padding: var(--spacing-zero) var(--spacing-s);
}

.agent-unavailable-tooltip__header {
  color: var(--content-color-error);
}
.agent-unavailable-tooltip__body {
  color: var(--content-color-primary);
  margin-top: var(--spacing-xs);
  max-width: 250px;
}

.sidebar-error-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  background-color: var(--background-color-secondary);
  height: 100%;
  padding: var(--spacing-s);
  margin-top: var(--spacing-xxl);
}
.sidebar-error-wrapper .sidebar-error-thumbnail {
  height: 120px;
  width: 120px;
  background: url("../assets/images/thumbnails/browse-view-offline.svg") no-repeat center;
  background-size: contain;
  margin: var(--spacing-xl) var(--spacing-zero) var(--spacing-l) var(--spacing-zero);
}
.sidebar-error-wrapper .sidebar-error-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-l);
}
.sidebar-error-wrapper .sidebar-error-header {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.sidebar-error-wrapper .sidebar-error-content {
  font-size: var(--text-size-m);
  text-align: center;
  color: var(--content-color-secondary);
  margin-top: var(--spacing-xs);
}
.sidebar-error-wrapper .sidebar-error-button {
  font-size: var(--text-size-m);
  text-align: center;
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
}

.sidebar-offline-state {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: var(--spacing-xxl) var(--spacing-l) var(--spacing-zero);
  align-items: center;
}
.sidebar-offline-state__title {
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-s);
}
.sidebar-offline-state__body {
  color: var(--content-color-secondary);
  text-align: center;
}
.sidebar-offline-state__illustration {
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: var(--spacing-l);
  background-color: var(--background-color-tertiary);
}
.sidebar-offline-state__illustration__icon {
  height: var(--size-m);
  width: var(--size-m);
}
.sidebar-offline-state__illustration__icon svg {
  height: var(--size-m);
  width: var(--size-m);
}

.headers-editor {
  display: flex;
  background: var(--background-color-primary);
}
.headers-editor__group {
  flex: 1;
  min-width: 0;
}
.headers-editor__header {
  display: flex;
  align-items: center;
  user-select: none;
}
.headers-editor__preview-toggle {
  margin-left: var(--spacing-s);
  height: 20px;
  border-radius: 35px;
  background-color: var(--background-color-secondary);
  box-shadow: 0 0 0 1px var(--button-secondary-outline-color) inset;
}
.headers-editor__preview-toggle .preview-toggle__icon {
  margin-right: 6px;
}
.headers-editor__preview-toggle .preview-toggle__icon use {
  fill: var(--content-color-tertiary);
}
.headers-editor__preview-toggle .preview-toggle__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.headers-editor__preview-toggle .preview-toggle__icon svg {
  color: var(--content-color-tertiary);
}
.headers-editor__preview-toggle .preview-toggle__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-s);
}
.headers-editor__preview-toggle .headers-editor__header .headers-editor__title {
  display: flex;
  align-items: center;
  background: var(--background-color-primary);
  height: var(--size-m);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-tertiary);
}
.headers-editor__title {
  display: flex;
  align-items: center;
  height: var(--size-m);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.headers-editor__title--bleed {
  padding-left: var(--spacing-l);
}

.urlparams-editor {
  display: flex;
  background: var(--background-color-primary);
}
.urlparams-editor__group {
  width: 100%;
  flex: 1;
}
.urlparams-editor__title {
  display: flex;
  align-items: center;
  height: var(--size-m);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
}
.urlparams-editor__title--bleed {
  padding-left: var(--spacing-l);
}
.urlparams-editor__path-variables.is-hidden {
  display: none;
}

.custom-obj-inspector {
  display: flex;
  flex-direction: column;
}
.custom-obj-inspector__item {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  line-height: var(--line-height-m);
  font-family: var(--text-family-code);
}
.custom-obj-inspector__item.padded {
  padding-left: var(--spacing-l);
}
.custom-obj-inspector__item__name {
  cursor: pointer;
  margin-left: -16px;
  display: flex;
  color: var(--content-color-secondary);
  align-items: center;
}
.custom-obj-inspector__item__value {
  word-break: break-all;
  color: var(--content-color-info);
}
.custom-obj-inspector__item__caret {
  display: inline-block;
  font-size: 8px;
  margin-right: var(--spacing-xs);
  user-select: none;
  transform: rotateZ(0deg);
  color: var(--content-color-secondary);
}
.custom-obj-inspector__item__caret.down {
  transform: rotateZ(90deg);
}

.remote-iframe-viewer {
  width: 100%;
  height: 100%;
  background-color: var(--grey-00);
  flex: 1;
}
.remote-iframe-viewer .remote-iframe-viewer__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 200px;
}
.remote-iframe-viewer .remote-iframe-viewer__error .remote-iframe-viewer__error-icon {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/browse-view-offline.svg");
}
.remote-iframe-viewer .remote-iframe-viewer__error .remote-iframe-viewer__error-title {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-xs);
}
.remote-iframe-viewer .remote-iframe-viewer__error .remote-iframe-viewer__error-description {
  width: 350px;
  text-align: center;
}
.remote-iframe-viewer .remote-iframe-viewer__error .remote-iframe-viewer__error-reload {
  cursor: pointer;
  color: var(--content-color-brand);
}
.remote-iframe-viewer .remote-iframe-viewer__loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remote-iframe-viewer .remote-iframe-viewer__frame {
  width: 100%;
  height: 100%;
  border: 0;
}
.remote-iframe-viewer .remote-iframe-viewer__frame.hidden {
  display: none;
}

.fork-recommendation-tooltip .tippy-content {
  padding: 0;
}
.fork-recommendation-tooltip__title {
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-s);
}
.fork-recommendation-tooltip__secondary-message {
  margin-top: var(--spacing-l);
}
.fork-recommendation-tooltip__actions {
  margin-top: var(--spacing-xl);
}
.fork-recommendation-tooltip__actions button.primary-action {
  background: var(--button-primary-background-color);
  border-radius: var(--border-radius-default);
}
.fork-recommendation-tooltip__actions button.secondary-action {
  border-radius: var(--border-radius-default);
}

.agent-selection-details__popover .tippy-content {
  width: 288px;
  padding: var(--spacing-xl);
  background-color: var(--background-color-primary);
}
.agent-selection-details__popover .tippy-content:has(#desktop-callout) {
  width: 351px;
}

.agent-selection-details__confirm {
  margin-top: var(--spacing-m);
  height: var(--size-m);
  width: 100%;
}

.agent-selection-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.agent-selection-icon .agent-selection-label {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  margin-left: var(--spacing-xs);
}
@media only screen and (max-width: 768px) {
  .agent-selection-icon .agent-selection-label {
    display: none;
  }
}
.agent-selection-icon .sb__item__text {
  margin-bottom: 2px;
}
.agent-selection-icon-error use {
  fill: var(--base-color-error) !important;
}
.agent-selection-icon-error svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error) !important;
}
.agent-selection-icon-error svg {
  color: var(--base-color-error) !important;
}
.agent-selection-icon-success use {
  fill: var(--content-color-secondary) !important;
}
.agent-selection-icon-success svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary) !important;
}
.agent-selection-icon-success svg {
  color: var(--content-color-secondary) !important;
}
.agent-selection-icon-warning {
  transform: rotate(180deg);
}
.agent-selection-icon-warning use {
  fill: var(--base-color-brand) !important;
}
.agent-selection-icon-warning svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand) !important;
}
.agent-selection-icon-warning svg {
  color: var(--base-color-brand) !important;
}

.agent-selection-details__wrapper {
  display: flex;
  align-items: flex-start;
  height: 100%;
  border-radius: var(--border-radius-default);
  margin-top: 20px;
  background: var(--background-color-primary);
  gap: var(--spacing-m);
  cursor: pointer;
}
.agent-selection-details__wrapper:not(:first-child) {
  margin-top: var(--spacing-l);
}
.agent-selection-details__wrapper.active {
  border-color: var(--base-color-brand);
}
.agent-selection-details__wrapper.unsupported {
  cursor: not-allowed;
  pointer-events: none;
}
.agent-selection-details__wrapper.auto-select {
  cursor: default;
}
.agent-selection-details__wrapper .agent-selection-toggle_switch__wrapper {
  flex-shrink: 0;
}

.agent-selection-details__divider {
  background-color: var(--border-color-default);
  height: var(--border-width-default);
  margin-top: var(--spacing-l);
}

.agent-selection-item__other_agents {
  cursor: pointer;
  display: flex;
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
  margin-left: 0;
  gap: var(--spacing-s);
}
.agent-selection-item__other_agents.disabled {
  color: var(--content-color-tertiary);
}
.agent-selection-item__radio {
  border: var(--border-width-default) var(--border-style-solid) var(--radio-border-color);
  border-radius: 50%;
  flex: 0 0 var(--spacing-l);
  height: var(--spacing-l);
  box-sizing: border-box;
  margin-top: 2px;
  cursor: pointer;
}
.agent-selection-item__radio:hover:not(.active), .agent-selection-item__radio.is-hovered:not(.active) {
  border-color: var(--radio-border-color);
}
.agent-selection-item__radio:hover:hover.disabled, .agent-selection-item__radio:hover.is-hovered.disabled, .agent-selection-item__radio.is-hovered:hover.disabled, .agent-selection-item__radio.is-hovered.is-hovered.disabled {
  border-color: var(--radio-disabled-border-color);
}
.agent-selection-item__radio.active {
  border-width: var(--border-width-xl);
  border-color: var(--radio-checked-background-color);
}
.agent-selection-item__radio.unsupported {
  cursor: not-allowed;
  border-color: var(--radio-disabled-border-color);
}
.agent-selection-item__radio.disabled {
  border-color: var(--radio-disabled-border-color);
}
.agent-selection-item__label {
  display: flex;
  gap: 4px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
}
.agent-selection-item__label.unsupported {
  color: var(--content-color-tertiary);
}
.agent-selection-item__label.unsupported > h5 {
  color: var(--content-color-tertiary);
}
.agent-selection-item__label .secondary {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-regular);
}
.agent-selection-item__label.disabled {
  color: var(--content-color-tertiary);
}
.agent-selection-item__label.disabled > h5 {
  color: var(--content-color-tertiary);
}
.agent-selection-item__description {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  color: var(--content-color-secondary);
}
.agent-selection-item__description .details__link {
  text-decoration: underline;
  cursor: pointer;
}
.agent-selection-item__description.unsupported {
  color: var(--content-color-error);
}
.agent-selection-item__description.disabled {
  color: var(--content-color-tertiary);
}

.collection-editor-header__action {
  margin-right: var(--spacing-s);
}
.collection-editor-header__action.with-label i {
  margin-right: var(--spacing-xs);
}
.collection-editor-header__action-experiment {
  margin-right: var(--spacing-s);
  min-width: auto;
}
.collection-editor-header__action-experiment.btn {
  height: var(--size-m);
}
.collection-editor-header__action-experiment.with-label i {
  margin-right: var(--spacing-xs);
}
.collection-editor-header__subtitle__fork-label span.fork-label {
  max-width: unset;
  line-height: var(--line-height-s);
}

.collection-watch-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: var(--spacing-s);
}
.collection-watch-button .watch-button {
  min-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: var(--text-size-m);
}
.collection-watch-button .watch-label {
  margin-left: var(--spacing-s);
}
.collection-watch-button .watcher-count-btn {
  margin: var(--spacing-zero);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  user-select: none;
  background-color: var(--background-color-tertiary);
  box-sizing: border-box;
  width: 34px;
  height: var(--size-m);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.collection-watch-button .watcher-count-btn:hover, .collection-watch-button .watcher-count-btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.collection-watch-button .watcher-count-btn .count-label {
  color: var(--content-color-secondary);
  height: var(--size-s);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.collection-watch-button .watcher-count-btn .count-label.button-disabled:hover, .collection-watch-button .watcher-count-btn .count-label.button-disabled.is-hovered {
  background-color: initial;
}
.collection-watch-button .watcher-count-btn.is-disabled {
  cursor: default;
}
.collection-watch-button .watcher-count-btn.is-disabled:hover, .collection-watch-button .watcher-count-btn.is-disabled.is-hovered {
  background-color: var(--background-color-tertiary);
}

.collection-editor-content {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.collection-editor-content__variables-editor {
  margin-top: var(--spacing-m);
}
.collection-editor-content__variables-editor .key-value-form-editor {
  position: relative;
}
.collection-editor-content__variables-editor .key-value-form-editor::before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  height: 5px;
  width: calc(100% - 0.75rem);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.collection-editor-content__variables-editor .key-value-form-editor::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: calc(100% - 0.75rem);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.collection-editor-content__variables-editor .key-value-form-editor .key-value-form-header-row {
  overflow-y: scroll;
}
.collection-editor-content .documentation-usage-error {
  width: 100%;
}

.collection-editor__content {
  height: 100%;
}

.collection-info-cb {
  display: flex;
  flex-direction: column;
  overflow: auto hidden;
}
.collection-info-cb .right-context-bar-header {
  color: var(--content-color-primary);
}
.collection-info-cb__container {
  height: 100%;
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}

.collection-sidebar-list-item__loading {
  height: 100%;
  overflow: hidden;
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);
}
.collection-sidebar-list-item__loading .sidebar-loading-state--item {
  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-zero);
  margin-left: var(--spacing-xxl);
  animation: fade 1s ease-in-out infinite;
}
.collection-sidebar-list-item__loading .sidebar-loading-state--item .sidebar-loading-state__icon {
  height: 8px;
  width: 8px;
  background-color: var(--highlight-background-color-secondary);
  margin: var(--spacing-zero) var(--spacing-s);
  border-radius: var(--border-radius-s);
  visibility: hidden;
}
.collection-sidebar-list-item__loading .sidebar-loading-state--item .sidebar-loading-state__icon.has {
  visibility: visible;
}
.collection-sidebar-list-item__loading .sidebar-loading-state--item .sidebar-loading-state-indentation {
  height: var(--size-s);
  border-right: 1px solid var(--border-color-default);
  width: 0;
  margin: var(--spacing-zero) var(--spacing-s);
}
.collection-sidebar-list-item__loading .sidebar-loading-state--item .sidebar-loading-state__pre-info {
  height: 8px;
  width: var(--size-s);
  background-color: var(--highlight-background-color-secondary);
  margin-left: var(--spacing-xs);
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-m);
}
.collection-sidebar-list-item__loading .sidebar-loading-state--item .sidebar-loading-state__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  height: 8px;
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-s);
}

@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
.documentation-overview-footer {
  bottom: 0;
  display: flex;
  align-self: end;
  align-items: center;
  cursor: pointer;
  font-size: var(--text-size-l);
  color: var(--content-color-link);
  font-weight: var(--text-weight-regular);
  background: var(--background-color-primary);
  padding: var(--spacing-l) var(--spacing-m);
  border-top: var(--border-style-solid) var(--border-width-default) var(--border-color-default);
  /**
   * Fix for Safari: Safari seems to be collapsing the context bar header/footer when the container
   * is a flex container and has the height set to 100% -- which is the case for us because scrolling
   * doesn't work otherwise. Forcibly setting the flex-shrink for the header and footer seems to fix
   * this in Safari without breaking it in other browsers.
   *
   * Tracking the removal of this (some day) in https://postmanlabs.atlassian.net/browse/DOCUMENTER-2159
   */
  flex-shrink: 0;
}
.documentation-overview-footer > i {
  margin-left: var(--spacing-xs);
}

.env-sidebar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.env-sidebar-menu__actions-refresh-wrapper {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.env-sidebar-menu__actions-refresh-wrapper:hover use, .env-sidebar-menu__actions-refresh-wrapper.is-hovered use {
  fill: var(--base-color-brand);
}
.env-sidebar-menu__actions-refresh-wrapper:hover svg:not([class*=PartnerIcon]) > path[fill], .env-sidebar-menu__actions-refresh-wrapper.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.env-sidebar-menu__actions-refresh-wrapper:hover svg, .env-sidebar-menu__actions-refresh-wrapper.is-hovered svg {
  color: var(--base-color-brand);
}
.env-sidebar-menu__actions-refresh-wrapper.is-disabled {
  cursor: default;
  opacity: 0.4;
}
.env-sidebar-menu__actions-refresh-wrapper.is-disabled .env-sidebar-menu__actions-refresh-loading:hover use, .env-sidebar-menu__actions-refresh-wrapper.is-disabled .env-sidebar-menu__actions-refresh-loading.is-hovered use {
  fill: var(--content-color-secondary);
}
.env-sidebar-menu__actions-refresh-wrapper.is-disabled .env-sidebar-menu__actions-refresh-loading:hover svg:not([class*=PartnerIcon]) > path[fill], .env-sidebar-menu__actions-refresh-wrapper.is-disabled .env-sidebar-menu__actions-refresh-loading.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.env-sidebar-menu__actions-refresh-wrapper.is-disabled .env-sidebar-menu__actions-refresh-loading:hover svg, .env-sidebar-menu__actions-refresh-wrapper.is-disabled .env-sidebar-menu__actions-refresh-loading.is-hovered svg {
  color: var(--content-color-secondary);
}

.env-sidebar-menu__actions-refresh-loading {
  cursor: default;
  -webkit-animation: rotating 1s linear infinite;
}
.env-sidebar-menu__actions-refresh-loading:hover use, .env-sidebar-menu__actions-refresh-loading.is-hovered use {
  fill: var(--content-color-secondary);
}
.env-sidebar-menu__actions-refresh-loading:hover svg:not([class*=PartnerIcon]) > path[fill], .env-sidebar-menu__actions-refresh-loading.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.env-sidebar-menu__actions-refresh-loading:hover svg, .env-sidebar-menu__actions-refresh-loading.is-hovered svg {
  color: var(--content-color-secondary);
}

.env-sidebar-list-container {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-s);
  flex: 1;
}
.env-sidebar-list-container > .sidebar-list-item {
  flex: unset;
}
.env-sidebar-list-container .env-sidebar-list {
  display: flex;
  flex: 1;
}
.env-sidebar-list-container__api-sidebar {
  margin-left: 32px;
}

.empty-search-item {
  display: flex;
  justify-content: center;
  color: var(--content-color-tertiary);
  padding: var(--spacing-l);
}
.empty-search-item__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.env-sidebar-list-item {
  display: flex;
  margin: var(--spacing-zero) var(--spacing-s);
  height: 32px;
  align-items: center;
  border-radius: var(--border-radius-default);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.env-sidebar-list-item:hover, .env-sidebar-list-item.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.env-sidebar-list-item:hover .env-sidebar-list-item__actions, .env-sidebar-list-item.is-hovered .env-sidebar-list-item__actions {
  display: flex;
  margin-left: var(--spacing-s);
}
.env-sidebar-list-item:hover .env-sidebar-list-item__actions .btn, .env-sidebar-list-item.is-hovered .env-sidebar-list-item__actions .btn {
  background-color: transparent;
}
.env-sidebar-list-item.selected {
  background-color: var(--background-color-brand);
}
.env-sidebar-list-item__icon {
  display: flex;
  align-items: center;
  margin: var(--spacing-zero) var(--spacing-s);
}
.env-sidebar-list-item__icon use {
  fill: var(--content-color-secondary);
}
.env-sidebar-list-item__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.env-sidebar-list-item__icon svg {
  color: var(--content-color-secondary);
}
.env-sidebar-list-item__content {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.env-sidebar-list-item__content__name {
  padding: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.env-sidebar-list-item__content .inline-input__wrapper .input-box {
  padding: 2px;
}
.env-sidebar-list-item__actions {
  display: none;
}
.env-sidebar-list-item__meta {
  display: none;
  color: var(--content-color-brand);
  margin: var(--spacing-zero) var(--spacing-s);
}
.env-sidebar-list-item__meta.is-active {
  display: flex;
  align-items: center;
}

.environment-sidebar-list-item__dummy {
  display: flex;
  flex-direction: column;
}
.environment-sidebar-list-item__dummy .environment-sidebar-list-item__dummy__head {
  flex: 0 0 auto;
}

.environment-sidebar-list-item__dummy__head {
  display: flex;
  flex-direction: row;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  background-color: var(--background-color-secondary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.environment-sidebar-list-item__dummy__head:focus, .environment-sidebar-list-item__dummy__head.is-focused {
  background-color: var(--highlight-background-color-tertiary);
}
.environment-sidebar-list-item__dummy__head:hover, .environment-sidebar-list-item__dummy__head.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy__icon-wrapper {
  flex: 0 0 28px;
  display: flex;
  justify-content: flex-end;
  margin: var(--spacing-zero) var(--spacing-xs) var(--spacing-zero) var(--spacing-xl);
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy__icon-wrapper .icon-environment {
  padding: 0 5px;
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy-api-environment__icon-wrapper {
  flex: 0 0 28px;
  display: flex;
  justify-content: flex-end;
  margin-left: var(--spacing-xs);
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy-api-environment__icon-wrapper .icon-environment {
  padding: 0 5px;
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy__meta {
  flex: 1;
  margin-left: 28px;
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy__meta__transient {
  flex: 1;
  margin-left: var(--spacing-zero);
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy__meta .environment-sidebar-list-item__dummy__name__wrapper {
  display: flex;
  align-items: center;
  height: var(--size-s);
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy__meta .environment-sidebar-list-item__dummy__name__wrapper .inline-input__wrapper {
  height: var(--size-s);
}
.environment-sidebar-list-item__dummy__head .environment-sidebar-list-item__dummy__meta .environment-sidebar-list-item__dummy__name__wrapper .inline-input {
  font-size: var(--text-size-m);
}

.environment-sidebar-list-item__dummy__meta {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
}

.environment-sidebar-list-item__dummy__name__wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.environment-sidebar-list-item__dummy__name__wrapper .environment-sidebar-list-item__dummy__name {
  font-size: var(--text-size-m);
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-primary);
  line-height: var(--line-height-m);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.environment-sidebar-list-item__dummy__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.environment-info-cb {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.environment-info-cb .right-context-bar-header {
  color: var(--content-color-primary);
}
.environment-info-cb__container {
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}

.environment-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.environment-editor .entity-tab-header {
  border-bottom: none;
}
.environment-editor__action {
  margin-right: var(--spacing-s);
}
.environment-editor__action-save i, .environment-editor__action i {
  margin-right: var(--spacing-xs);
}
.environment-editor__action-share {
  margin-right: var(--spacing-s);
}
.environment-editor .globals-editor__action:last-child {
  margin-right: unset;
}
.environment-editor__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.environment-editor__content__variables-editor .key-value-form-editor {
  position: relative;
}
.environment-editor__content__variables-editor .key-value-form-editor::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 5px;
  width: calc(100% - 0.75rem);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.environment-editor__content__variables-editor .key-value-form-editor::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: calc(100% - 0.75rem);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.environment-editor__content__variables-editor .key-value-form-editor .key-value-form-header-row {
  overflow-y: scroll;
}
.environment-editor__content .variables-editor__content {
  overflow-x: hidden;
}

.environment-meta-icon {
  margin-right: var(--spacing-xs);
}

.example-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.example-editor .request-editor__main__url-group {
  margin-right: var(--spacing-zero);
}
.example-editor__send-as-request-button {
  margin-left: var(--spacing-s);
}
.example-editor__send-as-request-button > button {
  height: 100%;
  background-color: var(--button-main-background-color);
  font-size: 14px;
  font-weight: var(--text-weight-medium);
  color: var(--button-main-content-color);
  box-shadow: 0 0 0 1px var(--button-main-outline-color);
  min-width: 100px;
}
.example-editor__send-as-request-button > button:hover, .example-editor__send-as-request-button > button.is-hovered {
  background-color: var(--button-main-hover-background-color);
}
.example-editor__send-as-request-button > button:active, .example-editor__send-as-request-button > button.is-active {
  background-color: var(--button-main-active-background-color);
}
.example-editor__send-as-request-button.is-sending .btn {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  font-size: var(--font-size-m);
}
.example-editor__send-as-request-button.is-sending .btn:hover, .example-editor__send-as-request-button.is-sending .btn.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.example-editor__send-as-request-button.is-sending .btn:last-child {
  display: none;
}
.example-editor__send-as-request-button.is-loading .btn:first-child, .example-editor__send-as-request-button.is-disabled .btn:first-child {
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.example-editor__send-as-request-button.is-loading .btn:first-child:hover, .example-editor__send-as-request-button.is-loading .btn:first-child.is-hovered, .example-editor__send-as-request-button.is-disabled .btn:first-child:hover, .example-editor__send-as-request-button.is-disabled .btn:first-child.is-hovered {
  background-color: var(--button-main-background-color);
}
.example-editor__send-as-request-button.is-loading .btn:first-child:active, .example-editor__send-as-request-button.is-loading .btn:first-child.is-active, .example-editor__send-as-request-button.is-disabled .btn:first-child:active, .example-editor__send-as-request-button.is-disabled .btn:first-child.is-active {
  background-color: var(--button-main-background-color);
}

.example-editor-header {
  border-bottom: none;
}
.example-editor-header__action {
  margin-right: var(--spacing-s);
}
.example-editor-header__action:last-child {
  margin-right: unset;
}
.example-editor-header__action .hasLabel {
  margin-right: var(--spacing-xs);
}

.response-header-editor {
  overflow-y: auto;
}
.response-header-editor .key-value-form-editor.is-bleed {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.response-urlparams-editor-container {
  overflow-y: auto;
}
.response-urlparams-editor-container .urlparams-editor__params, .response-urlparams-editor-container .urlparams-editor__path-variables {
  margin-right: var(--spacing-l);
  margin-left: var(--spacing-l);
}
.response-urlparams-editor-container .urlparams-editor__params .key-value-form-editor, .response-urlparams-editor-container .urlparams-editor__path-variables .key-value-form-editor {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default) !important;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.response-urlparams-editor-container .urlparams-editor__params .key-value-form-editor-sortable__selectable, .response-urlparams-editor-container .urlparams-editor__path-variables .key-value-form-editor-sortable__selectable {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}

.example-info-cb {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.example-info-cb .right-context-bar-header {
  color: var(--content-color-primary);
}
.example-info-cb__container {
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}

.example-meta-icons {
  display: flex;
  margin-left: var(--spacing-xs);
}
.example-meta-icons__item {
  margin-right: var(--spacing-xs);
}
.example-meta-icons__item use {
  fill: var(--content-color-tertiary);
}
.example-meta-icons__item svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.example-meta-icons__item svg {
  color: var(--content-color-tertiary);
}

.folder-editor-header__action, .folder-editor-header__share-button {
  margin-right: var(--spacing-s);
}
.folder-editor-header__action.last-item, .last-item.folder-editor-header__share-button {
  margin-right: 0;
}
.folder-editor-header__action i, .folder-editor-header__share-button i {
  margin-right: var(--spacing-xs);
}

.folder-editor-header__share-button {
  margin-right: var(--spacing-xs);
}
.folder-editor-header__share-button i {
  margin-right: 0;
}

.folder-editor-content {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.collection-editor-tabs,
.folder-editor-tabs {
  margin-top: var(--spacing-s);
}

.folder-info-cb {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.folder-info-cb .right-context-bar-header {
  color: var(--content-color-primary);
}
.folder-info-cb__container {
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}

.folder-meta-icons {
  display: flex;
  margin-left: var(--spacing-xs);
}
.folder-meta-icons__item {
  margin-right: var(--spacing-xs);
}
.folder-meta-icons__item use {
  fill: var(--content-color-tertiary);
}
.folder-meta-icons__item svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.folder-meta-icons__item svg {
  color: var(--content-color-tertiary);
}

.globals-sidebar-list-item {
  display: flex;
  margin: var(--spacing-zero) var(--spacing-s);
  height: 32px;
  align-items: center;
  border-radius: var(--border-radius-default);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.globals-sidebar-list-item:hover, .globals-sidebar-list-item.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.globals-sidebar-list-item:hover .globals-sidebar-list-item__actions, .globals-sidebar-list-item.is-hovered .globals-sidebar-list-item__actions {
  display: flex;
  margin-left: var(--spacing-s);
}
.globals-sidebar-list-item:hover .globals-sidebar-list-item__actions .btn, .globals-sidebar-list-item.is-hovered .globals-sidebar-list-item__actions .btn {
  background-color: transparent;
}
.globals-sidebar-list-item.selected {
  background-color: var(--background-color-primary);
}
.globals-sidebar-list-item__icon {
  display: flex;
  align-items: center;
  margin: var(--spacing-zero) var(--spacing-s);
}
.globals-sidebar-list-item__icon use {
  fill: var(--content-color-info);
}
.globals-sidebar-list-item__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-info);
}
.globals-sidebar-list-item__icon svg {
  color: var(--content-color-info);
}
.globals-sidebar-list-item__content {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  color: var(--content-color-primary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.globals-sidebar-list-item__actions {
  display: none;
}

.globals-separator {
  height: 1px;
  background-color: var(--border-color-default);
  margin: var(--spacing-s);
}

.globals-info-cb {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.globals-info-cb .right-context-bar-header {
  color: var(--content-color-primary);
}
.globals-info-cb__container {
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}

.globals-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.globals-editor__action {
  margin-right: var(--spacing-s);
}
.globals-editor__action i {
  margin-right: var(--spacing-xs);
}
.globals-editor .globals-editor__action:last-child {
  margin-right: unset;
}
.globals-editor__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.globals-editor__content .variables-editor:first-child .variables-editor__content:first-child .key-value-form-editor {
  border-top: none;
}
.globals-editor__variables-editor {
  margin-top: var(--spacing-m);
}
.globals-editor__variables-editor .key-value-form-editor {
  position: relative;
}
.globals-editor__variables-editor .key-value-form-editor::before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  height: 5px;
  width: calc(100% - 0.75rem);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.globals-editor__variables-editor .key-value-form-editor::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: calc(100% - 0.75rem);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.globals-editor__variables-editor .key-value-form-editor .key-value-form-header-row {
  overflow-y: scroll;
}
.globals-editor .entity-tab-header + .variables-editor .variables-editor__content:first-child .key-value-form-editor,
.globals-editor .request-permission-infobar + .variables-editor .variables-editor__content:first-child .key-value-form-editor {
  border-top: none;
}

.request-info-cb {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.request-info-cb .right-context-bar-header {
  color: var(--content-color-primary);
}
.request-info-cb__container {
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-s);
}

.request-meta-icons {
  display: flex;
  margin-left: var(--spacing-xs);
}
.request-meta-icons__item {
  margin-right: var(--spacing-xs);
}
.request-meta-icons__item use {
  fill: var(--content-color-tertiary);
}
.request-meta-icons__item svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.request-meta-icons__item svg {
  color: var(--content-color-tertiary);
}

.request-type-switcher-dropdown__button {
  box-shadow: 0 0 0 var(--border-width-default) var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.request-type-switcher-dropdown__button .btn {
  color: var(--content-color-primary);
  background-color: var(--background-color-secondary);
  height: var(--size-s);
  font-weight: var(--font-weight-regular);
  padding: var(--spacing-xs);
}
.request-type-switcher-dropdown__button .btn:hover, .request-type-switcher-dropdown__button .btn.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.request-type-switcher-dropdown__button .btn:active, .request-type-switcher-dropdown__button .btn.is-active {
  background-color: var(--highlight-background-color-tertiary);
}
.request-type-switcher-dropdown__button__content {
  display: flex;
  align-items: center;
}
.request-type-switcher-dropdown__button__label {
  margin-left: var(--spacing-s);
}
.request-type-switcher-dropdown__option__label {
  margin-left: var(--spacing-s);
}

.websocket-request-tab-contents {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  min-height: 0;
}

.websocket-request-tab-content {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
}
.websocket-request-tab-content.is-hidden {
  display: none;
}
.websocket-request-tab-content .websocket-headers {
  margin-bottom: var(--spacing-m);
}

.websocket .request-editor__tabs {
  padding-left: var(--spacing-xs);
}
.websocket .request-editor__tabs .tab-text-wrapper {
  padding-top: unset;
}

.websocket-settings {
  overflow-y: auto;
  flex: 1;
}

.websocket-headers {
  overflow-y: auto;
  flex: 1;
  padding: var(--spacing-zero) var(--spacing-l);
}

.websocket-events {
  overflow-y: auto;
  margin: var(--spacing-s) var(--spacing-m);
}

.table-editor-cell__name:not(:focus-within) {
  border-right: none !important;
}
.table-editor-header__name {
  border-right: none !important;
}
.table-editor-cell__subscribeOnConnect {
  align-items: center !important;
}
.table-editor-cell__description {
  padding-left: var(--spacing-l) !important;
}
.table-editor-header__description {
  padding-left: var(--spacing-l) !important;
}
.table-editor-header__subscribeOnConnect {
  padding-right: var(--spacing-xs) !important;
  justify-content: center !important;
}

.websocket-query-params {
  overflow-y: auto;
  flex: 1;
  padding: var(--spacing-zero) var(--spacing-l);
}
.websocket-query-params .key-value-bulk-editor .bulk-editor__controls {
  padding-right: var(--spacing-xs);
}
.websocket-query-params .urlparams-editor {
  margin-bottom: var(--spacing-m);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.websocket-message-stream-controls {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-l);
  z-index: 1;
}
.websocket-message-stream-controls.add-shadow {
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.12);
}
.websocket-message-stream-controls__filter-dropdown {
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow-default);
}
.websocket-message-stream-controls__filter-dropdown .message-type-filter-item > span {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  height: var(--controls-size-default);
}
.websocket-message-stream-controls__filter-input {
  display: flex;
  align-items: center;
  width: 240px;
  min-width: 100px;
}
.websocket-message-stream-controls__right-side {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
}

.websocket-stream {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.websocket-stream.connecting {
  opacity: 0.4;
  pointer-events: none;
}
.websocket-stream__list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.websocket-stream__list div div div div:first-child .websocket-hidden-messages {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  margin-top: var(--spacing-xs);
}
.websocket-stream__jump-to-latest {
  display: flex;
  justify-content: center;
}
.websocket-stream__jump-to-latest__btn {
  position: absolute;
  border-radius: 20px;
  top: 48px;
  z-index: 10;
  padding: var(--spacing-xs) var(--spacing-m);
  background-color: var(--content-color-link);
  color: var(--content-color-constant);
  font-weight: var(--text-weight-medium);
  box-shadow: var(--shadow-default);
}
.websocket-stream__jump-to-latest__btn i svg {
  color: var(--content-color-constant);
}
.websocket-stream__jump-to-latest__btn:hover, .websocket-stream__jump-to-latest__btn.is-hovered {
  background-color: var(--content-color-link);
  color: var(--content-color-constant);
}
.websocket-stream__jump-to-latest__btn:hover i svg, .websocket-stream__jump-to-latest__btn.is-hovered i svg {
  color: var(--content-color-constant);
}

.websocket-stream-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.stream-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
}
.stream-empty-state__icon {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/astronaut.svg");
}
.stream-empty-state__message {
  width: 320px;
}

.websocket-hidden-messages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-s);
  padding: var(--spacing-s) var(--spacing-xs) var(--spacing-s) var(--spacing-l);
  margin: var(--spacing-zero) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  background-color: var(--background-color-secondary);
}
.websocket-hidden-messages__left-section {
  display: flex;
  align-items: center;
  flex: 1;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.websocket-hidden-messages__left-section__icon {
  margin-right: var(--spacing-m);
}
.websocket-hidden-messages__right-section {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-l);
}

.websocket-message-pane-label {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  height: var(--size-m);
  margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-l);
}
.websocket-message-pane-label__text {
  cursor: pointer;
  user-select: none;
}
.websocket-message-pane-label__actions {
  display: flex;
  align-items: center;
}
.websocket-message-pane-label__actions__status {
  display: flex;
  align-items: center;
  cursor: default;
}
.websocket-message-pane-label__actions__arrow {
  display: flex;
  margin-block: var(--spacing-s);
  padding-left: var(--spacing-s);
  cursor: pointer;
}

.websocket-connection-statistics {
  width: 270px;
}
.websocket-connection-statistics__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--size-s);
  margin-top: var(--spacing-xs);
}
.websocket-connection-statistics__item:first-child {
  margin-top: unset;
}
.websocket-connection-statistics__item__header {
  color: var(--content-color-secondary);
}
.websocket-connection-statistics__item__label {
  color: var(--content-color-secondary);
}
.websocket-connection-statistics__item__value {
  width: 60%;
  display: flex;
  align-items: center;
  color: var(--content-color-primary);
}
.websocket-connection-statistics__item__value span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.websocket-message-lang-dropdown-btn {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-default);
  color: var(--content-color-secondary);
}
.websocket-message-lang-dropdown-btn:hover, .websocket-message-lang-dropdown-btn.is-active {
  background-color: var(--highlight-background-color-secondary);
}
.websocket-message-lang-dropdown-btn .dropdown-caret {
  margin-left: var(--spacing-xs);
}

.message-relational-info-toolbar {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: var(--size-m);
  padding: var(--spacing-xs) var(--spacing-l);
}
.message-relational-info-toolbar__deselect {
  line-height: 1.6;
}
.message-relational-info-toolbar > * {
  margin-right: var(--spacing-l);
}

.websocket-message-viewer__editor {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-secondary);
  flex: 1 auto;
  height: 100%;
  min-width: 0;
}
.websocket-message-viewer__editor__toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--size-m);
  padding: var(--spacing-xs) var(--spacing-zero) var(--spacing-xs) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.websocket-message-viewer__editor__toolbar.is-binary {
  justify-content: flex-end;
}
.websocket-message-viewer__editor__toolbar__section-l, .websocket-message-viewer__editor__toolbar__section-r {
  display: flex;
  align-items: center;
}
.websocket-message-viewer__editor__toolbar__section-l .line-wrap-active i svg {
  color: var(--content-color-brand);
}
.websocket-message-viewer__editor__toolbar__section-l > * {
  margin-right: var(--spacing-s);
}
.websocket-message-viewer__editor__monaco-editor {
  height: 100%;
}
.websocket-message-viewer__editor__monaco-editor .monaco-editor .monaco-editor-background {
  background-color: var(--background-color-secondary);
}
.websocket-message-viewer__editor__monaco-editor .monaco-editor .margin-view-overlays {
  background-color: var(--background-color-secondary);
}
.websocket-message-viewer__editor__monaco-editor .monaco-editor .margin-view-overlays .line-numbers {
  color: var(--content-color-tertiary);
}

.websocket-message-viewer__iframe {
  display: flex;
  flex: 1 auto;
  height: 100%;
}

.websocket-editor-header {
  border-bottom: 0;
}
.websocket-editor-header__actions {
  display: flex;
  align-items: center;
}
.websocket-editor-header__actions__type-selector__btn {
  background-color: var(--background-color-info);
  border-radius: var(--border-radius-default);
}
.websocket-editor-header__actions__type-selector__btn .btn {
  height: var(--size-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  font-family: var(--text-family-default);
  color: var(--content-color-primary);
}
.websocket-editor-header__actions__type-selector .dropdown-caret {
  margin-left: var(--spacing-xs);
}
.websocket-editor-header__actions .request-editor-save-button {
  margin-right: var(--spacing-zero);
}
.websocket-editor-header__actions > *:not(:last-child) {
  margin-right: var(--spacing-s);
}
.websocket-editor-header__actions .request-editor-save-button--tertiary .btn-group .btn {
  min-width: var(--size-s);
  border-left: var(--button-group-separator);
  padding: var(--spacing-s) var(--spacing-xs);
  margin-left: -1px;
}
.websocket-editor-header__actions .request-editor-save-button--tertiary .btn-group button {
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
}

.websocket-saved-messages {
  padding-bottom: var(--spacing-l);
  overflow-y: auto;
}
.websocket-saved-messages--empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: var(--spacing-xl);
  padding-right: var(--spacing-xl);
}
.websocket-saved-messages__message {
  display: flex;
  align-items: center;
  gap: 0 var(--spacing-s);
  padding: 0 var(--spacing-l);
  height: 32px;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  cursor: pointer;
}
.websocket-saved-messages__message:hover {
  background: var(--background-color-secondary);
}
.websocket-saved-messages__message:hover .websocket-saved-messages__message-menu {
  display: inline-block;
}
.websocket-saved-messages__message .websocket-saved-messages__message-menu {
  display: none;
}
.websocket-saved-messages__message--selected {
  background: var(--background-color-tertiary);
  cursor: default;
}
.websocket-saved-messages__message--selected .websocket-saved-messages__message-menu {
  display: inline-block;
}
.websocket-saved-messages__message--selected:hover {
  background: var(--background-color-tertiary);
}
.websocket-saved-messages__message .event-badge {
  margin-left: 0;
}
.websocket-saved-messages__message-title {
  flex: 1;
  overflow-x: hidden;
}
.websocket-saved-messages__message-title .inline-input_placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.websocket-saved-messages__right-side {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
.websocket-saved-messages__more-options {
  height: var(--size-s) !important;
  width: var(--size-s) !important;
}
.websocket-saved-messages__more-options:hover {
  background: var(--highlight-background-color-primary);
}
.websocket-saved-messages__more-options:not(:hover) {
  background: inherit;
}

.websocket-tabs-badge {
  display: inline-flex;
  width: 8px;
  height: 8px;
  margin-left: var(--spacing-xs);
  background-color: var(--base-color-success);
  border-radius: 8px;
}

.websocket-request-info {
  padding: var(--spacing-zero) var(--spacing-s);
}

.websocket-request-documentation {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s);
}
.websocket-request-documentation__body {
  display: flex;
  flex: 1 auto;
  height: 100%;
  flex-direction: column;
  margin-right: var(--spacing-l);
  gap: var(--spacing-xl);
}
.websocket-request-documentation__body > *:not(:first-child) {
  margin-left: var(--spacing-s);
}

.ws-documentation-table > div {
  padding-top: var(--spacing-zero);
}

.raw-websocket-compose {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin-right: var(--spacing-s);
}
.raw-websocket-compose__editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: var(--spacing-l);
  min-height: 0;
}
.raw-websocket-compose__editor .text-editor .monaco-editor .margin-view-overlays .line-numbers {
  color: var(--content-color-tertiary);
}
.raw-websocket-compose__editor .text-editor .monaco-editor .monaco-placeholder {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.raw-websocket-compose__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-zero);
  margin-left: var(--spacing-l);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.raw-websocket-compose__footer__left {
  display: flex;
  align-items: center;
}
.raw-websocket-compose__footer__left__lang-selector {
  margin-right: var(--spacing-xs);
}

.websocket-stream-item {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-zero) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.websocket-stream-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-m);
  padding: var(--spacing-xs) var(--spacing-m) var(--spacing-xs) var(--spacing-zero);
  cursor: pointer;
}
.websocket-stream-item__header__left-section {
  display: flex;
  align-items: center;
  flex: 1;
  overflow-x: hidden;
}
.websocket-stream-item__header__left-section__checkbox {
  margin-left: var(--spacing-xs);
}
.websocket-stream-item__header__left-section__checkbox div span span {
  margin-right: unset;
}
.websocket-stream-item__header__left-section__type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--size-xs);
  min-width: var(--size-xs);
  margin-left: var(--spacing-xl);
}
.websocket-stream-item__header__left-section__checkbox + .websocket-stream-item__header__left-section__type-icon {
  margin-left: 6px;
}
.websocket-stream-item__header__left-section__message {
  margin-left: var(--spacing-xl);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.websocket-stream-item__header__left-section__message.add-ellipsis::after {
  content: "...";
  font-family: var(--text-family-default);
}
.websocket-stream-item__header__right-section {
  display: flex;
  align-items: center;
  min-width: 96px;
  margin-left: var(--spacing-xl);
  justify-content: flex-end;
}
.websocket-stream-item__header__right-section__time, .websocket-stream-item__header__right-section__quick-actions {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-s);
}
.websocket-stream-item__header__right-section__time:last-child, .websocket-stream-item__header__right-section__quick-actions:last-child {
  margin-right: var(--spacing-xl);
}
.websocket-stream-item__header__right-section__time {
  font-variant-numeric: tabular-nums;
}
.websocket-stream-item__header__right-section__quick-actions .info-button {
  align-items: center;
  justify-content: center;
  padding: var(--spacing-s);
  box-sizing: border-box;
}
.websocket-stream-item__header.is-hovered {
  background-color: var(--background-color-secondary);
}
.websocket-stream-item__header.is-selected {
  background-color: var(--background-color-secondary);
}
.websocket-stream-item__body {
  display: flex;
  flex-direction: column;
}
.websocket-stream-item__body__message-body-viewer {
  height: 250px;
  box-sizing: border-box;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.websocket-stream-item__body__obj-inspector {
  display: flex;
  flex-direction: column;
  height: 240px;
  padding-left: var(--spacing-xl);
  overflow: auto;
  white-space: pre-wrap;
  word-break: break-all;
}
.websocket-stream-item__body__obj-inspector__error {
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.websocket-stream-item__body__obj-inspector > * {
  margin-bottom: var(--spacing-s);
}
.websocket-stream-item__body__obj-inspector.no-details {
  height: var(--size-m);
}
.websocket-stream-item__body__plain-text {
  display: flex;
  align-items: center;
  height: var(--size-s);
  padding-left: var(--spacing-xl);
  margin-bottom: var(--spacing-s);
  white-space: pre;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
}
.websocket-stream-item__body__plain-text__description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.websocket-stream-item.no-body .websocket-stream-item__header {
  cursor: default;
}
.websocket-stream-item.server-message .websocket-stream-item__header__left-section__message {
  font-family: var(--text-family-code);
}
.websocket-stream-item.server-message .websocket-stream-item__header__left-section__type-icon {
  background-color: var(--background-color-info);
}
.websocket-stream-item.client-message .websocket-stream-item__header__left-section__message {
  font-family: var(--text-family-code);
}
.websocket-stream-item.client-message .websocket-stream-item__header__left-section__type-icon {
  background-color: var(--background-color-warning);
}

.websocket-stream-info-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.websocket-stream-info-tooltip > * {
  margin-bottom: var(--spacing-s);
}
.websocket-stream-info-tooltip span:last-child {
  margin-bottom: unset;
}

.raw-websocket-messages-documentation {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}
.raw-websocket-messages-documentation__item {
  display: flex;
  flex-direction: column;
}
.raw-websocket-messages-documentation > span {
  font-weight: var(--text-weight-bold);
}

.event-badge {
  display: flex;
  align-items: center;
  max-width: 120px;
}
.event-badge__name {
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.event-badge__name-wrapper {
  display: flex;
  align-items: center;
  padding: var(--spacing-zero) var(--spacing-s);
  height: 20px;
  box-sizing: border-box;
  border: var(--border-width-default) var(--border-style-solid);
  border-radius: var(--border-radius-default);
  overflow-x: hidden;
  --perceived-lightness: calc(var(--r)*0.2126/255 + var(--g)*0.7152/255 + var(--b)*0.0722/255);
}
.event-badge__name-wrapper--light {
  --lightness-threshold: 0.6;
  --background-alpha: 0.18;
  --border-alpha: 0.3;
  --lightness-switch: max(0, min(calc(var(--perceived-lightness)*-1000 - var(--lightness-threshold)*-1000), 1));
  --lighten-by: calc((var(--lightness-threshold) * 100 - var(--perceived-lightness) * 100) * var(--lightness-switch));
  background-color: rgba(var(--r), var(--g), var(--b), var(--background-alpha));
  border-color: hsla(var(--h), calc(var(--s) * 1%), calc((var(--l)) * 0.4%), var(--border-alpha));
  color: hsl(var(--h), calc(var(--s) * 1%), calc((var(--l)) * 0.4%));
}
.event-badge__name-wrapper--dark {
  --lightness-threshold: 0.6;
  --background-alpha: 0.18;
  --border-alpha: 0.3;
  --lightness-switch: max(0, min(calc(var(--perceived-lightness)*-1000 - var(--lightness-threshold)*-1000), 1));
  --lighten-by: calc((var(--lightness-threshold) * 100 - var(--perceived-lightness) * 100) * var(--lightness-switch));
  background-color: rgba(var(--r), var(--g), var(--b), var(--background-alpha));
  border-color: hsla(var(--h), calc(var(--s) * 1%), calc((var(--l) + var(--lighten-by)) * 1%), var(--border-alpha));
  color: hsl(var(--h), calc(var(--s) * 1%), calc((var(--l) + var(--lighten-by)) * 1%));
}

.socketIO-compose {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin-right: var(--spacing-s);
}
.socketIO-compose__multi-arg {
  padding-top: var(--spacing-l);
}
.socketIO-compose__multi-arg__editor {
  height: 100%;
  flex: 1;
  overflow: hidden;
  min-height: 0;
}
.socketIO-compose__multi-arg__editor .text-editor .monaco-editor .margin-view-overlays .line-numbers {
  color: var(--content-color-tertiary);
}
.socketIO-compose__multi-arg__editor .text-editor .monaco-editor .monaco-placeholder {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.socketIO-compose__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-zero);
  flex-wrap: wrap;
  margin-left: var(--spacing-l);
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  row-gap: var(--spacing-xs);
}
.socketIO-compose__footer__left, .socketIO-compose__footer__right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.socketIO-compose__footer__left {
  gap: var(--spacing-s) var(--spacing-s);
}
.socketIO-compose__footer__right {
  margin-left: var(--spacing-s);
  gap: var(--spacing-s) var(--spacing-m);
}
.socketIO-compose__footer__send-btn {
  background-color: var(--base-color-info);
  color: var(--content-color-constant);
}
.socketIO-compose__footer__send-btn.is-disabled {
  background-color: var(--base-color-info);
  color: var(--content-color-constant);
  opacity: 0.4;
}
.socketIO-compose__footer__send-btn:not(.is-disabled):hover, .socketIO-compose__footer__send-btn:not(.is-disabled).is-hovered {
  background-color: var(--highlight-background-color-info);
}
.socketIO-compose__footer__send-btn:not(.is-disabled):active, .socketIO-compose__footer__send-btn:not(.is-disabled).is-active {
  background-color: var(--highlight-background-color-info);
}

.socketIO-stream-item {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-zero) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.socketIO-stream-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-m);
  padding: var(--spacing-xs) var(--spacing-m) var(--spacing-xs) var(--spacing-zero);
  cursor: pointer;
}
.socketIO-stream-item__header__left-section {
  display: flex;
  align-items: center;
  flex: 1;
  overflow-x: hidden;
}
.socketIO-stream-item__header__left-section__checkbox {
  margin-left: var(--spacing-xs);
}
.socketIO-stream-item__header__left-section__checkbox div span span {
  margin-right: unset;
}
.socketIO-stream-item__header__left-section__type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--size-xs);
  min-width: var(--size-xs);
  margin-left: var(--spacing-xl);
}
.socketIO-stream-item__header__left-section__checkbox + .socketIO-stream-item__header__left-section__type-icon {
  margin-left: 6px;
}
.socketIO-stream-item__header__left-section__message {
  margin-left: var(--spacing-xl);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.socketIO-stream-item__header__left-section__message.add-ellipsis::after {
  content: "...";
  font-family: var(--text-family-default);
}
.socketIO-stream-item__header__left-section__arg-count-badge {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-left: var(--spacing-s);
}
.socketIO-stream-item__header__left-section__arg-count-badge .badge {
  text-transform: unset;
}
.socketIO-stream-item__header__left-section .event-badge {
  margin-left: var(--spacing-xl);
}
.socketIO-stream-item__header__right-section {
  display: flex;
  align-items: center;
  min-width: 96px;
  margin-left: var(--spacing-xl);
  justify-content: flex-end;
}
.socketIO-stream-item__header__right-section__time, .socketIO-stream-item__header__right-section__quick-actions {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-s);
}
.socketIO-stream-item__header__right-section__time:last-child, .socketIO-stream-item__header__right-section__quick-actions:last-child {
  margin-right: var(--spacing-xl);
}
.socketIO-stream-item__header__right-section__time {
  font-variant-numeric: tabular-nums;
}
.socketIO-stream-item__header__right-section__quick-actions .info-button {
  align-items: center;
  justify-content: center;
  padding: var(--spacing-s);
  box-sizing: border-box;
}
.socketIO-stream-item__header.is-hovered {
  background-color: var(--background-color-secondary);
}
.socketIO-stream-item__header.is-selected {
  background-color: var(--background-color-secondary);
}
.socketIO-stream-item__body {
  display: flex;
  flex-direction: column;
}
.socketIO-stream-item__body__message-body-viewer {
  height: 250px;
  box-sizing: border-box;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.socketIO-stream-item__body__obj-inspector {
  display: flex;
  flex-direction: column;
  height: 240px;
  padding-left: var(--spacing-xl);
  overflow: auto;
  white-space: pre-wrap;
  word-break: break-all;
}
.socketIO-stream-item__body__obj-inspector__error {
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.socketIO-stream-item__body__obj-inspector > * {
  margin-bottom: var(--spacing-s);
}
.socketIO-stream-item__body__obj-inspector.no-details {
  height: var(--size-m);
}
.socketIO-stream-item__body__plain-text {
  display: flex;
  align-items: center;
  height: var(--size-s);
  padding-left: var(--spacing-xl);
  margin-bottom: var(--spacing-s);
  white-space: pre;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-family: var(--text-family-default);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
}
.socketIO-stream-item__body__plain-text__description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.socketIO-stream-item.no-body .socketIO-stream-item__header {
  cursor: default;
}
.socketIO-stream-item.server-message .socketIO-stream-item__header__left-section__message {
  font-family: var(--text-family-code);
}
.socketIO-stream-item.server-message .socketIO-stream-item__header__left-section__type-icon {
  background-color: var(--background-color-info);
}
.socketIO-stream-item.client-message .socketIO-stream-item__header__left-section__message {
  font-family: var(--text-family-code);
}
.socketIO-stream-item.client-message .socketIO-stream-item__header__left-section__type-icon {
  background-color: var(--background-color-warning);
}

.socketIO-stream-info-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.socketIO-stream-info-tooltip > * {
  margin-bottom: var(--spacing-s);
}
.socketIO-stream-info-tooltip span:last-child {
  margin-bottom: unset;
}

.multi-arg-message-editor {
  display: flex;
  flex: 1 auto;
  height: 100%;
  align-items: center;
  min-height: 0;
}

.args-nav-bar {
  display: flex;
  flex-direction: column;
  flex: 0 0 64px;
  height: 100%;
  overflow-y: auto;
  border-right: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.args-nav-bar::-webkit-scrollbar {
  display: none;
}
.args-nav-bar__item-wrapper {
  display: flex;
  align-items: center;
}

.args-nav-bar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--size-m);
  padding: var(--spacing-xs);
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}
.args-nav-bar-item__close {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
}
.args-nav-bar-item__close:hover {
  background-color: var(--background-color-error);
}
.args-nav-bar-item:hover {
  background-color: var(--background-color-secondary);
}
.args-nav-bar-item.is-active {
  background-color: var(--background-color-tertiary);
}

.socketio-messages-documentation {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}
.socketio-messages-documentation:first-child {
  margin-bottom: var(--spacing-l);
}
.socketio-messages-documentation > span {
  font-weight: var(--text-weight-bold);
}
.socketio-messages-documentation__event {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}
.socketio-messages-documentation__event__snippet {
  display: flex;
  flex-direction: column;
}
.socketio-messages-documentation__event__snippet__multi-arg {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
}
.socketio-messages-documentation__event__snippet__code {
  min-width: 0;
  border-radius: unset;
  border: unset;
  height: 100%;
  width: 100%;
}

.extensible-collection-editor-header.entity-tab-header {
  border-bottom: unset;
}
.extensible-collection-editor-header__actions {
  display: flex;
  align-items: center;
}
.extensible-collection-editor-header__actions > * {
  margin-right: var(--spacing-s);
}

.extensible-collection-editor-content {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.extensible-collection-editor-content__variables-editor > * {
  padding: var(--spacing-l) var(--spacing-l) var(--spacing-zero);
}

.collection-explorer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.collection-explorer__path {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-bottom: var(--spacing-s);
}
.collection-explorer__path__empty {
  margin-left: var(--spacing-s);
}
.collection-explorer__path .breadcrumb__container {
  flex: 1;
  margin-left: var(--spacing-s);
}
.collection-explorer__blank-state {
  display: flex;
  flex: 1;
  padding: var(--spacing-xl) var(--spacing-l);
}

.addrequest-top-container-modal .modal-content,
.save-multiple-requests-modal .modal-content {
  padding-bottom: 0;
}
.addrequest-top-container-modal .modal-content .addrequest-top-container,
.save-multiple-requests-modal .modal-content .addrequest-top-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
}
.addrequest-top-container-modal .modal-content .addrequest-top-container .addrequest-collection-selector-row,
.save-multiple-requests-modal .modal-content .addrequest-top-container .addrequest-collection-selector-row {
  flex: 1;
  min-height: 0;
}
.addrequest-top-container-modal .modal-content .addrequest-top-container .addrequest-collection-selector-row .collection-explorer,
.save-multiple-requests-modal .modal-content .addrequest-top-container .addrequest-collection-selector-row .collection-explorer {
  height: 100%;
}

.save-multiple-requests-modal .addrequest-top-container {
  max-height: 100%;
}

.addrequest-top-container .row {
  padding: 7px 0;
}
.addrequest-top-container .add-request-helptext {
  padding-bottom: 20px;
}
.addrequest-top-container .add-request-helptext .btn {
  padding: 0px;
}
.addrequest-top-container .addrequest-top-container-name-row {
  padding-top: 0px;
}
.addrequest-top-container .field-label {
  margin-bottom: var(--spacing-s);
}
.addrequest-top-container .addrequest-description-row {
  color: var(--content-color-tertiary);
}
.addrequest-top-container .addrequest-description-row .add-description-button {
  margin-bottom: var(--spacing-m);
}

.addrequest-collection-name-container .input-search,
.addrequest-collection-selector-row .input-search {
  font-size: 12px;
}
.addrequest-collection-name-container .addrequest-new-collection-name-row,
.addrequest-collection-selector-row .addrequest-new-collection-name-row {
  padding: 20px 0 0 0;
}
.addrequest-collection-name-container .addrequest-collection-selector-row,
.addrequest-collection-selector-row .addrequest-collection-selector-row {
  padding: 0;
}
.addrequest-collection-name-container .addrequest-collection-selector-row .field-label,
.addrequest-collection-selector-row .addrequest-collection-selector-row .field-label {
  text-align: center;
  position: relative;
  display: flex;
  flex: 1;
  margin-bottom: 10px;
  background-color: var(--background-color-primary);
}

.addrequest-top-container-name-row .addrequest__request-name:not(:focus-within) {
  background-color: var(--background-color-secondary);
}
.addrequest-top-container-name-row .addrequest__request-name:not(:focus-within):not(.error) {
  border-color: var(--border-color-default);
}

.addrequest-request-description-editor {
  height: 70px;
}
.addrequest-request-description-editor .text-editor .monaco-editor .margin {
  background-color: var(--background-color-secondary);
}
.addrequest-request-description-editor .text-editor .monaco-editor .monaco-editor-background {
  background-color: var(--background-color-secondary);
}
.addrequest-request-description-editor .text-editor .monaco-editor.focused .monaco-editor-background {
  background-color: var(--background-color-primary);
}
.addrequest-request-description-editor .text-editor .monaco-editor .monaco-placeholder {
  color: var(--content-color-secondary);
}

.save-multiple-requests-modal .field-label {
  margin-bottom: 10px;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.stars-loader-container {
  max-height: 20px;
}
.stars-loader-container .svg-container {
  display: inline-block;
  cursor: pointer;
}
.stars-loader-container .animate {
  animation: growShrink 0.3s infinite alternate;
}
.stars-loader-container .delay {
  animation-delay: 0.9s; /* Add a 1-second delay */
}
.stars-loader-container .small {
  width: 6px;
  height: 6px;
}
.stars-loader-container .two-stars-left {
  gap: 2px;
}
@keyframes growShrink {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1.5);
  }
}

.ai-parent-container {
  position: absolute;
}
.ai-parent-container.snippets-collapsed {
  top: 8px;
  right: 38px;
}
.ai-parent-container.snippets-collapsed .snippets-ai-btn {
  display: none;
}
.ai-parent-container .script-editor-ai-btn {
  right: 16px;
  position: relative;
}
.ai-parent-container .script-editor-ai-btn.hide {
  display: none;
}
.ai-parent-container.snippets-expanded {
  width: 100%;
  top: -4px;
}
.ai-parent-container.snippets-expanded .script-editor-ai-btn {
  display: none;
}
.ai-parent-container.snippets-expanded .ai-popover-container {
  width: 100%;
}
.ai-parent-container.snippets-expanded .ai-popover-container .snippets-ai-btn {
  cursor: pointer;
  display: flex;
  gap: 8px;
  border: 1px solid var(--purple-20);
  border-radius: 4px;
  margin: 0 36px 0 8px;
  padding: 8px;
}
.ai-parent-container.snippets-expanded .ai-popover-container .snippets-ai-btn svg {
  margin-top: 2px;
}
.ai-parent-container.snippets-expanded .ai-popover-container .snippets-ai-btn.error-icon svg {
  width: 16px;
  color: var(--purple-60) !important;
}
.ai-parent-container.snippets-expanded .ai-popover-container .snippets-ai-btn.error-icon svg path {
  fill: var(--purple-60) !important;
}
.ai-parent-container.snippets-expanded .ai-popover-container .snippets-ai-btn .snippets-ai-btn-text h5 {
  line-height: 20px;
}
.ai-parent-container .alpha-badge {
  font-size: 10px;
}
.ai-parent-container .postbot-suggestion-badge {
  cursor: pointer;
}
.ai-parent-container .postbot-suggestion-badge, .ai-parent-container .alpha-badge {
  background-color: var(--purple-10);
  line-height: 16px;
  border-radius: 4px;
  padding: 0px 4px 0 4px;
  color: var(--purple-60);
  letter-spacing: 0.4px;
}
.ai-parent-container .ai-tests-loader .rect-one, .ai-parent-container .ai-tests-loader .rect-two, .ai-parent-container .ai-tests-loader .rect-three {
  background-color: var(--purple-60);
}
.ai-parent-container .popover-icon-container {
  position: absolute;
  top: 7px;
  right: 7px;
}
.ai-parent-container .popover-icon-container button .rect-one, .ai-parent-container .popover-icon-container button .rect-two, .ai-parent-container .popover-icon-container button .rect-three {
  background-color: var(--background-color-primary);
}
.ai-parent-container .popover-icon-container.bg-purple button, .ai-parent-container .script-editor-ai-btn.bg-purple button {
  background-color: var(--purple-60);
}
.ai-parent-container .popover-icon-container.bg-purple button:hover, .ai-parent-container .script-editor-ai-btn.bg-purple button:hover {
  background-color: var(--purple-60);
}
.ai-parent-container .popover-icon-container .popover-btn, .ai-parent-container .popover-icon-container .ai-play-btn, .ai-parent-container .script-editor-ai-btn .popover-btn, .ai-parent-container .script-editor-ai-btn .ai-play-btn {
  width: 24px;
  height: 24px;
  z-index: 2;
  background-color: var(--background-color-primary);
  border: 1px solid var(--purple-60);
}
.ai-parent-container .popover-icon-container .popover-btn span, .ai-parent-container .popover-icon-container .ai-play-btn span, .ai-parent-container .script-editor-ai-btn .popover-btn span, .ai-parent-container .script-editor-ai-btn .ai-play-btn span {
  display: flex;
}
.ai-parent-container .popover-icon-container .popover-btn svg, .ai-parent-container .popover-icon-container .ai-play-btn svg, .ai-parent-container .script-editor-ai-btn .popover-btn svg, .ai-parent-container .script-editor-ai-btn .ai-play-btn svg {
  color: var(--purple-60) !important;
}
.ai-parent-container .popover-icon-container .popover-btn svg path, .ai-parent-container .popover-icon-container .ai-play-btn svg path, .ai-parent-container .script-editor-ai-btn .popover-btn svg path, .ai-parent-container .script-editor-ai-btn .ai-play-btn svg path {
  fill: var(--purple-60) !important;
}
.ai-parent-container .popover-icon-container .popover-btn.error-icon svg, .ai-parent-container .popover-icon-container .ai-play-btn.error-icon svg, .ai-parent-container .script-editor-ai-btn .popover-btn.error-icon svg, .ai-parent-container .script-editor-ai-btn .ai-play-btn.error-icon svg {
  color: var(--purple-60) !important;
}
.ai-parent-container .popover-icon-container .popover-btn.error-icon svg path, .ai-parent-container .popover-icon-container .ai-play-btn.error-icon svg path, .ai-parent-container .script-editor-ai-btn .popover-btn.error-icon svg path, .ai-parent-container .script-editor-ai-btn .ai-play-btn.error-icon svg path {
  fill: var(--purple-60) !important;
}
.ai-parent-container .popover-icon-container .popover-btn:hover, .ai-parent-container .popover-icon-container .ai-play-btn:hover, .ai-parent-container .script-editor-ai-btn .popover-btn:hover, .ai-parent-container .script-editor-ai-btn .ai-play-btn:hover {
  background-color: var(--background-color-primary);
}
.ai-parent-container .popover-icon-container .cancel-ai-request-btn, .ai-parent-container .script-editor-ai-btn .cancel-ai-request-btn {
  background: none !important;
}
.ai-parent-container .ai-popper {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  opacity: 0;
  background-color: var(--background-color-primary);
  padding: 12px 0 0 0;
  margin-right: -40px;
  margin-top: -8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.ai-parent-container .ai-popper.show-popper {
  width: 324px;
  opacity: 1;
  right: 32px;
}
.ai-parent-container .ai-popper.hide-popper {
  width: 0;
  opacity: 0;
  padding: 0;
}
.ai-parent-container .ai-popper.hide-popper .popover-icon-container {
  display: none;
}
.ai-parent-container .ai-popper .line-divider {
  width: calc(100% - 16px);
  height: 1px;
  background-color: var(--border-color-default);
}
.ai-parent-container .ai-popper .suggestion-container {
  margin: 4px 8px 12px 8px;
}
.ai-parent-container .ai-popper .suggestion-container span {
  margin: 0 4px 8px 4px !important;
  cursor: pointer;
}
.ai-parent-container .ai-popper .suggestion-container.hide-tree {
  display: none;
}
.ai-parent-container .ai-popper .script-summary-container, .ai-parent-container .ai-popper .script-error-container, .ai-parent-container .ai-popper .script-warning-container {
  margin-top: 8px;
}
.ai-parent-container .ai-popper .script-summary-container .warning-message, .ai-parent-container .ai-popper .script-error-container .warning-message, .ai-parent-container .ai-popper .script-warning-container .warning-message {
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-warning);
  padding: var(--spacing-s);
}
.ai-parent-container .ai-popper .script-summary-container .warning-message .warning-btn-container, .ai-parent-container .ai-popper .script-error-container .warning-message .warning-btn-container, .ai-parent-container .ai-popper .script-warning-container .warning-message .warning-btn-container {
  margin-left: 22px;
}
.ai-parent-container .ai-popper .script-summary-container .warning-message .message-warning-icon i, .ai-parent-container .ai-popper .script-error-container .warning-message .message-warning-icon i, .ai-parent-container .ai-popper .script-warning-container .warning-message .message-warning-icon i {
  margin-right: 6px;
}
.ai-parent-container .ai-popper .script-summary-container .script-summary, .ai-parent-container .ai-popper .script-summary-container .warning-message, .ai-parent-container .ai-popper .script-summary-container .error-message-container, .ai-parent-container .ai-popper .script-error-container .script-summary, .ai-parent-container .ai-popper .script-error-container .warning-message, .ai-parent-container .ai-popper .script-error-container .error-message-container, .ai-parent-container .ai-popper .script-warning-container .script-summary, .ai-parent-container .ai-popper .script-warning-container .warning-message, .ai-parent-container .ai-popper .script-warning-container .error-message-container {
  margin: 0 8px 8px 8px;
}
.ai-parent-container .ai-popper .script-summary-container .script-summary, .ai-parent-container .ai-popper .script-error-container .script-summary, .ai-parent-container .ai-popper .script-warning-container .script-summary {
  margin: 0 8px 8px 12px;
}
.ai-parent-container .ai-popper .error-message-container {
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-error);
  padding: var(--spacing-s);
}
.ai-parent-container .ai-popper .error-message-container i {
  margin: 1px 6px 0 0;
}
.ai-parent-container .ai-popper.snippets-popper {
  border: 1px solid var(--purple-30);
  right: 42px;
  top: 4px;
  z-index: 10;
}
.ai-parent-container .ai-popper.snippets-popper.show-popper {
  z-index: 11;
  width: 328px;
}
.ai-parent-container .ai-popper .loading-tests-txt {
  width: calc(100% - 16px);
  margin: 8px 0 8px 16px;
}
.ai-parent-container .ai-popper .loading-tests-txt .cancel-ai-request-btn {
  height: max-content;
  margin-top: 4px;
  background: none;
}
.ai-parent-container .ai-popper .loading-tests-txt .cancel-ai-request-btn svg {
  color: var(--purple-60);
}
.ai-parent-container .ai-play-btn svg path {
  stroke: var(--grey-00) !important;
}
.ai-parent-container .input-and-suggestion-container {
  width: 100%;
}
.ai-parent-container .ai-input-container {
  width: calc(100% - 48px);
  margin: 0 36px 8px 12px !important;
}
.ai-parent-container .ai-input-container .ai-textarea {
  width: 100%;
  min-height: 80px;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
  color: var(--content-color-primary);
}
.ai-parent-container .ai-input-container div[contentEditable]:empty::before {
  content: attr(data-placeholder);
  color: var(--content-color-tertiary);
}
.ai-parent-container .postbot-feeback-btn {
  width: 100%;
  justify-content: start;
  margin-bottom: var(--spacing-s);
}
.ai-parent-container .postbot-feeback-btn button {
  width: 100%;
  justify-content: start;
  padding-left: 12px;
  color: var(--purple-60);
  border-radius: 0;
}
.ai-parent-container .postbot-feeback-btn button svg {
  color: var(--purple-60);
}
.ai-parent-container .postbot-feeback-btn button:hover {
  background-color: var(--purple-10);
  color: var(--purple-60);
}
.ai-parent-container .postbot-feeback-btn button:hover svg {
  color: var(--purple-60);
}
.ai-parent-container.dark-theme .popover-icon-container button .rect-one, .ai-parent-container.dark-theme .popover-icon-container button .rect-two, .ai-parent-container.dark-theme .popover-icon-container button .rect-three, .ai-parent-container.dark-theme .script-editor-ai-btn button .rect-one, .ai-parent-container.dark-theme .script-editor-ai-btn button .rect-two, .ai-parent-container.dark-theme .script-editor-ai-btn button .rect-three {
  background-color: var(--grey-00);
}
.ai-parent-container.dark-theme .alpha-badge, .ai-parent-container.dark-theme .postbot-suggestion-badge {
  background-color: var(--purple-80) !important;
  color: var(--purple-30) !important;
}
.ai-parent-container.dark-theme .snippets-ai-btn {
  border: 1px solid var(--purple-60) !important;
}
.ai-parent-container.dark-theme .snippets-ai-btn * {
  color: var(--purple-30);
}
.ai-parent-container.dark-theme .snippets-ai-btn svg {
  color: var(--purple-30);
}
.ai-parent-container.dark-theme .snippets-ai-btn svg path {
  fill: var(--purple-30);
}
.ai-parent-container.dark-theme .snippets-popper, .ai-parent-container.dark-theme .ai-popper {
  border: 1px solid var(--purple-70);
}
.ai-parent-container.dark-theme .suggestion-container span {
  background-color: var(--purple-70);
  color: var(--purple-30);
}
.ai-parent-container.dark-theme .popover-btn {
  border: 1px solid var(--purple-40);
}
.ai-parent-container.dark-theme .popover-btn svg {
  color: var(--purple-40) !important;
}
.ai-parent-container.dark-theme .popover-btn svg path {
  fill: var(--purple-40) !important;
}
.ai-parent-container.dark-theme .summary-item span {
  color: var(--purple-40);
}
.ai-parent-container.dark-theme .script-error-container svg {
  color: var(--purple-40) !important;
}
.ai-parent-container.dark-theme .postbot-feeback-btn button {
  color: var(--purple-50);
}
.ai-parent-container.dark-theme .postbot-feeback-btn button svg {
  color: var(--purple-50);
}
.ai-parent-container.dark-theme .postbot-feeback-btn button:hover {
  background: none;
}
.ai-parent-container .cancel-ai-request-btn svg {
  color: var(--purple-40) !important;
}

.ai-opt-in-container {
  position: absolute;
  top: -4px;
  right: 8px;
  padding: var(--spacing-xl) var(--spacing-l) !important;
  background-color: var(--background-color-primary);
  border-radius: 4px;
  box-shadow: var(--shadow-default);
  z-index: 11;
  text-align: center;
}
.ai-opt-in-container h4 {
  margin: 16px 0 8px 0 !important;
}
.ai-opt-in-container .close-ai-opt-in {
  position: absolute;
  right: 4px;
  top: 4px;
}
.ai-opt-in-container .opt-in-terms-text {
  padding: 0 12px;
  margin-bottom: 16px !important;
}
.ai-opt-in-container .ai-consent-description {
  margin-bottom: 16px !important;
  line-height: 20px;
}
.ai-opt-in-container .ai-consent-description svg {
  margin-right: 4px;
}
.ai-opt-in-container .ai-consent-description svg g path {
  stroke: var(--content-color-secondary);
}

.global-postbot-script-btn {
  display: flex;
  padding: 10px 0 var(--spacing-s) 0;
  gap: var(--spacing-s);
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color-default);
  margin: 0 var(--spacing-s) var(--spacing-m) var(--spacing-s);
}

.PopoverErrorBreakdownChart__position-popover {
  position: relative;
  left: var(--spacing-xxl);
}

.test-suite-header {
  padding: var(--spacing-s) var(--spacing-l) !important;
}
.test-suite-header .suite-collection-name {
  margin-left: 4px;
  cursor: pointer;
}
.test-suite-header .suite-collection-name:hover {
  text-decoration: underline;
}
.test-suite-header .test-suite-ctas .generate-test-suite-btn.dark {
  background-color: var(--purple-50);
  color: var(--purple-10);
}
.test-suite-header .test-suite-ctas .generate-test-suite-btn.dark svg {
  color: var(--purple-10);
}
.test-suite-header .test-suite-ctas .generate-test-suite-btn.light {
  background-color: var(--purple-60);
}
.test-suite-header .test-suite-ctas .generate-test-suite-btn:disabled {
  opacity: 50%;
}
.test-suite-header .test-suite-ctas .save-test-suite-btn .rect-one, .test-suite-header .test-suite-ctas .save-test-suite-btn .rect-two, .test-suite-header .test-suite-ctas .save-test-suite-btn .rect-three, .test-suite-header .test-suite-ctas .generate-test-suite-btn .rect-one, .test-suite-header .test-suite-ctas .generate-test-suite-btn .rect-two, .test-suite-header .test-suite-ctas .generate-test-suite-btn .rect-three {
  background-color: var(--purple-10);
}
.test-suite-header .test-suite-ctas .save-test-suite-btn .save-test-suite-btn .rect-one, .test-suite-header .test-suite-ctas .save-test-suite-btn .save-test-suite-btn .rect-two, .test-suite-header .test-suite-ctas .save-test-suite-btn .save-test-suite-btn .rect-three, .test-suite-header .test-suite-ctas .save-test-suite-btn .generate-test-suite-btn .rect-one, .test-suite-header .test-suite-ctas .save-test-suite-btn .generate-test-suite-btn .rect-two, .test-suite-header .test-suite-ctas .save-test-suite-btn .generate-test-suite-btn .rect-three, .test-suite-header .test-suite-ctas .generate-test-suite-btn .save-test-suite-btn .rect-one, .test-suite-header .test-suite-ctas .generate-test-suite-btn .save-test-suite-btn .rect-two, .test-suite-header .test-suite-ctas .generate-test-suite-btn .save-test-suite-btn .rect-three, .test-suite-header .test-suite-ctas .generate-test-suite-btn .generate-test-suite-btn .rect-one, .test-suite-header .test-suite-ctas .generate-test-suite-btn .generate-test-suite-btn .rect-two, .test-suite-header .test-suite-ctas .generate-test-suite-btn .generate-test-suite-btn .rect-three {
  background-color: var(--purple-10);
}

.ai-loader.dark p {
  color: var(--purple-30);
}
.ai-loader.dark svg path {
  fill: var(--purple-30);
}
.ai-loader.light p {
  color: var(--purple-60);
}
.ai-loader.light svg path {
  fill: var(--purple-60);
}

.test-suite-container {
  overflow: hidden;
  height: calc(100% - 64px);
  padding: 0 var(--spacing-l) 0 var(--spacing-l) !important;
}
.test-suite-container .test-suite-table {
  border-collapse: collapse;
  width: 100%;
}
.test-suite-container .test-suite-table th {
  text-align: left;
  height: 40px;
  position: sticky;
  top: 0;
  border: 1px solid var(--border-color-default);
  background-color: var(--background-color-primary);
  z-index: 1;
}
.test-suite-container .test-suite-table th .suite-header-content {
  width: 100%;
  height: 100%;
  padding: 0 8px 0 16px;
}
.test-suite-container .test-suite-table th .suite-header-content button {
  font-weight: 500;
}
.test-suite-container .test-suite-table td {
  border: 1px solid var(--border-color-default);
}
.test-suite-container .test-suite-table td {
  padding: 16px;
  vertical-align: top;
}
.test-suite-container .test-suite-table td.test-suite-tests-col {
  padding: 8px 16px 16px 16px;
}
.test-suite-container .test-suite-table td.test-suite-tests-col.no-tests {
  padding-top: 16px;
}
.test-suite-container .test-suite-table td.test-suite-tests-col.test-results {
  padding: 8px 0;
}
.test-suite-container .test-suite-table .test-suite-request-col {
  width: 30%;
}
.test-suite-container .test-suite-table .test-suite-request-col .request-name-row {
  margin-bottom: var(--spacing-m);
}
.test-suite-container .test-suite-table .test-suite-request-col .request-name-row .suite-request-link {
  line-height: 16px;
}
.test-suite-container .test-suite-table .test-suite-request-col .request-method-icon {
  margin-right: var(--spacing-xs);
}
.test-suite-container .test-suite-table .test-suite-request-col .response-dropdown-item .response-dropdown-label {
  cursor: pointer;
}
.test-suite-container .test-suite-table .test-suite-request-col .response-dropdown-item .response-dropdown-label .response-data-collapsed {
  transform: rotate(-90deg);
}
.test-suite-container .test-suite-table .response-data-block code, .test-suite-container .test-suite-table .test-data-block code {
  display: block;
  max-height: 400px;
  overflow: scroll;
  background-color: var(--background-color-primary);
  padding: 0 0 0 16px;
}
.test-suite-container .test-suite-table .test-suite-response-col {
  width: 20%;
}
.test-suite-container .test-suite-table .test-suite-tests-col.response-error {
  padding: 0;
  position: relative;
}
.test-suite-container .test-suite-table .test-suite-tests-col.response-error .response-error-banner {
  top: 0;
  height: 100%;
}
.test-suite-container .test-suite-table .test-suite-tests-col.response-error .response-error-banner.non-json-error {
  position: absolute;
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container code {
  cursor: default;
  padding: var(--spacing-s);
  margin: 0 16px var(--spacing-s) 16px;
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container pre {
  margin: 0;
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container .script-title-row {
  padding: var(--spacing-s) var(--spacing-l);
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container .script-title-row .delete-script-icon {
  height: fit-content;
  opacity: 0;
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container .script-title-row .ai-added-script-icon {
  align-self: center;
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container .script-title-row:hover {
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container .script-title-row:hover .delete-script-icon {
  opacity: 1;
  background-color: inherit;
}
.test-suite-container .test-suite-table .test-suite-tests-col .test-info-container .suite-monaco-editor {
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin: 0 16px;
}
.test-suite-container .test-suite-table .test-suite-script-item {
  margin-left: var(--spacing-s);
  width: max-content;
}
.test-suite-container .test-suite-table .script-title-row.expanded {
  margin-bottom: var(--spacing-s);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.global__postbot .response-warning-icon {
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
}
.global__postbot .response-warning-icon svg {
  color: var(--content-color-warning);
}
.global__postbot .response-error-icon {
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
}
.global__postbot .response-error-icon svg {
  color: var(--content-color-error);
}
.global__postbot:hover .ai-suggestions-container svg {
  color: var(--content-color-secondary) !important;
}
.global__postbot:hover .global-postbot-cta svg {
  color: var(--postbot-content) !important;
}
.global__postbot:hover .response-warning-icon {
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
}
.global__postbot:hover .response-warning-icon svg {
  color: var(--content-color-warning) !important;
}
.global__postbot:hover .response-error-icon {
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-zero) var(--spacing-zero);
}
.global__postbot:hover .response-error-icon svg {
  color: var(--content-color-error) !important;
}
.global__postbot .global-postbot-settings-container .postbot-shortcut-container {
  position: absolute;
  top: -56px;
  padding: 6px var(--spacing-s);
  background-color: var(--tooltip-background-color);
  border-radius: var(--border-radius-s);
  box-shadow: var(--tooltip-box-shadow);
  border: 1px solid var(--tooltip-border-color);
}
.global__postbot .global-postbot-settings-container .global-postbot-cta {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}
.global__postbot .global-postbot-settings-container .global-postbot-cta span {
  color: var(--postbot-content);
}
.global__postbot .global-postbot-settings-container .global-postbot-cta svg {
  color: var(--postbot-content);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  bottom: 32px;
  right: var(--spacing-xxl);
  width: 282px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-strong);
  border-radius: 8px;
  box-shadow: var(--shadow-strong);
  padding-top: 36px;
  z-index: 2;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper.focused .postbot-header p {
  color: var(--content-color-secondary);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper.focused .postbot-header .global-postbot-header-icon svg {
  color: var(--content-color-secondary) !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-header {
  background: var(--background-color-primary);
  z-index: 3;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-header .postbot-header-items {
  justify-content: space-between;
  width: 100%;
  padding: 8px 8px 4px 12px;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-header .collapse-handle {
  position: absolute;
  width: 100%;
  height: 8px;
  top: 0;
  left: 0;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-header .collapse-handle:hover {
  cursor: ns-resize;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-header p {
  line-height: 11px;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .star-loader-wrapper {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--postbot-content);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-message-container {
  transition: 0.3s;
  transition-delay: 0.3s;
  height: 358px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-message-container p {
  white-space: normal;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-message-container.fade-out {
  animation: fadeOut 0s forwards;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-message-container .postbot-intro-icon-container {
  background-color: var(--postbot-background);
  border-radius: 50%;
  width: 72px;
  height: 72px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: var(--spacing-xl);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container {
  z-index: 3;
  background-color: var(--background-color-primary);
  padding: var(--spacing-s);
  align-items: flex-end;
  border-top: 1px solid var(--border-color-default);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container.missing-prompt {
  border: 2px solid var(--red-30);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .postbot-textarea {
  width: 100%;
  cursor: text;
  font-size: 12px;
  max-height: 240px;
  white-space: normal;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 0;
  padding: 2px 0 2px 8px;
  border: 1px solid transparent;
  user-select: text;
  color: var(--content-color-primary);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .postbot-textarea.executing-minified-prompt {
  color: var(--content-color-secondary);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .postbot-textarea:empty::before {
  content: attr(data-placeholder);
  color: var(--content-color-tertiary);
  line-height: var(--line-height-s);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .ai-play-btn, .global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .cancel-ai-request-btn {
  width: 24px;
  height: 24px;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .ai-play-btn span, .global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .cancel-ai-request-btn span {
  display: flex;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .ai-play-btn {
  background-color: var(--postbot-button-primary);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .ai-play-btn svg path {
  stroke: var(--postbot-button-primary-label) !important;
  fill: var(--postbot-button-primary) !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .cancel-ai-request-btn {
  background: none;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .postbot-input-container .cancel-ai-request-btn svg {
  color: var(--postbot-content) !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-suggestions-container {
  margin: var(--spacing-s) var(--spacing-m);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-secondary);
  padding: var(--spacing-xs) var(--spacing-s);
  z-index: 2;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-suggestions-container .suggestion-item {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
  padding: 6px 8px;
  border-bottom: 1px solid var(--border-color-default);
  cursor: pointer;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-suggestions-container .suggestion-item-tag {
  margin-left: auto;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-suggestions-container .suggestion-item.selected {
  background-color: var(--highlight-background-color-tertiary) !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-suggestions-container .suggestion-item:hover {
  background-color: var(--highlight-background-color-primary);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-suggestions-container .suggestion-item:last-of-type {
  border: none;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container {
  flex-grow: 1;
  flex-basis: 0;
  margin: var(--spacing-l) var(--spacing-m) var(--spacing-s) var(--spacing-m) !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item {
  padding: var(--spacing-s);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-secondary);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container.feedback {
  margin: 0 var(--spacing-m) var(--spacing-s) !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container.feedback .ai-history-item {
  margin-bottom: var(--spacing-s);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .markdown-viewer-container {
  color: var(--postbot-content);
  font-size: var(--text-size-m);
  margin: -12px 0;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item {
  padding: 8px 12px;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-response-message {
  margin-bottom: var(--spacing-s);
  border-bottom: none !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .markdown-viewer-container {
  color: var(--postbot-content);
  font-size: var(--text-size-m);
  margin: -12px 0 -5px 0;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container {
  z-index: 2;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container::-webkit-scrollbar, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container:hover, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container:hover {
  scrollbar-color: #888 transparent;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container:hover::-webkit-scrollbar-thumb, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container:hover::-webkit-scrollbar-thumb {
  background: #888;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container:hover::-webkit-scrollbar-thumb:hover, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container:hover::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item {
  user-select: text;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item:first-of-type, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item:first-of-type {
  margin-top: auto;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-prompt-container, .global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-response-message, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-prompt-container, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-response-message {
  border-bottom: 1px solid var(--border-color-default);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-prompt-container:last-of-type, .global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-response-message:last-of-type, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-prompt-container:last-of-type, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-response-message:last-of-type {
  border: none;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-prompt-container, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-prompt-container {
  padding-bottom: 4px;
  white-space: normal;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-prompt-container p, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-prompt-container p {
  white-space: pre-line;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-message-with-prompt, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-message-with-prompt {
  padding-top: 8px;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-message-with-prompt .history-loading button, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-message-with-prompt .history-loading button {
  padding: 0;
  background: none;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-response-message, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-response-message {
  white-space: normal;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-response-message .postbot-message-content *, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-response-message .postbot-message-content * {
  color: var(--postbot-content);
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-response-message .response-warning-container .warning-message-container, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-response-message .response-warning-container .warning-message-container {
  margin: 0 !important;
}
.global__postbot .global-postbot-settings-container .global-postbot-popper .ai-history-container .ai-history-item .history-response-message .response-warning-container button, .global__postbot .global-postbot-settings-container .global-postbot-popper .minified-history-container .ai-history-item .history-response-message .response-warning-container button {
  width: fit-content;
  align-self: flex-start;
  margin: var(--spacing-s) 0 0 var(--spacing-l);
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.inline-tooltip {
  background-color: var(--background-color-secondary);
  padding: var(--spacing-xs);
  border-radius: var(--border-radius-s);
  border: 1px solid var(--tooltip-border-color);
  box-shadow: var(--tooltip-box-shadow);
  margin: 0;
}

.runner-runs {
  display: flex;
  height: 100%;
  overflow-x: auto;
}
.runner-runs .runner-runs-selector {
  flex: 1 0 350px;
  margin-top: 40px;
}
.runner-runs .runner-runs-selector .runner-runs-selector__item--environment,
.runner-runs .runner-runs-selector .runner-runs-selector__item--iterations,
.runner-runs .runner-runs-selector .runner-runs-selector__item--delay,
.runner-runs .runner-runs-selector .runner-runs-selector__item--data-file,
.runner-runs .runner-runs-selector .runner-runs-selector__item--data-file-type,
.runner-runs .runner-runs-selector .runner-runs-selector__item--persist,
.runner-runs .runner-runs-selector .runner-runs-selector__item--customize,
.runner-runs .runner-runs-selector .runner-runs-selector__item--initial-cookies,
.runner-runs .runner-runs-selector .runner-runs-selector__item--save-cookies,
.runner-runs .runner-runs-selector .runner-runs-selector__item--response {
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
}
.runner-runs .runner-runs-selector .runner-runs-selector__item--environment .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--iterations .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--delay .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--data-file .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--data-file-type .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--persist .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--customize .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--initial-cookies .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--save-cookies .input-field,
.runner-runs .runner-runs-selector .runner-runs-selector__item--response .input-field {
  flex: 1;
  max-width: 300px;
}
.runner-runs .runner-runs-selector .runner-runs-selector__meta--persist,
.runner-runs .runner-runs-selector .runner-runs-selector__meta--response,
.runner-runs .runner-runs-selector .runner-runs-selector__meta--customize,
.runner-runs .runner-runs-selector .runner-runs-selector__meta--initial-cookies,
.runner-runs .runner-runs-selector .runner-runs-selector__meta--save-cookies {
  display: flex;
  justify-content: flex-end;
}
.runner-runs .runner-runs-selector .runner-runs-selector__environment {
  flex: 1;
}
.runner-runs .runner-runs-selector .runner-runs-selector__environment .input-search {
  position: relative;
}
.runner-runs .runner-runs-selector .runner-runs-selector__meta {
  flex: 0 0 100px;
}
.runner-runs .runner-runs-customizer__emptystate {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-image: repeating-linear-gradient(45deg, var(--border-color-default) 0, var(--border-color-default) 5px, transparent 5px, transparent 10px) 1;
  border-radius: var(--border-radius-default);
}
.runner-runs .runner-runs-customizer__non-emptystate {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.runner-runs .runner-runs-customizer {
  display: flex;
  flex-direction: column;
  flex: 1 0 330px;
  position: relative;
  margin: var(--spacing-l) var(--spacing-zero) var(--spacing-l) var(--spacing-l);
  padding: var(--spacing-l);
  padding-left: var(--spacing-s);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__header {
  margin-bottom: var(--spacing-l);
  display: flex;
  justify-content: space-between;
  padding-left: var(--spacing-s);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__header .runner-runs-customizer__header-buttons {
  display: flex;
  align-items: center;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__header .runner-runs-customizer__header-buttons .button {
  padding: var(--spacing-zero) var(--spacing-xs);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-secondary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__header .runner-runs-customizer__header-buttons .button:hover, .runner-runs .runner-runs-customizer .runner-runs-customizer__header .runner-runs-customizer__header-buttons .button.is-hovered {
  color: var(--content-color-primary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__header .runner-runs-customizer__header-buttons .separator {
  margin: var(--spacing-zero) var(--spacing-xs);
  background-color: var(--border-color-default);
  height: var(--size-xs);
  width: var(--border-width-default);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__header .runner-runs-customizer__header-buttons .close {
  padding: var(--spacing-zero) var(--spacing-xs);
  margin-left: var(--spaing-xl);
  cursor: pointer;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container {
  flex: 1;
  display: flex;
  background-color: var(--background-color-primary);
  contain: strict;
  min-height: 200px;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__container--empty {
  margin: auto;
  max-width: 310px;
  text-align: center;
  color: var(--content-color-tertiary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__container--hydrating {
  margin: auto;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list-layout {
  display: flex;
  width: 100%;
  align-items: center;
  gap: var(--spacing-xs);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list-layout .runner-runs-customizer__list-item {
  flex-grow: 1;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list-item :hover:not(div[data-aether-id=aether-checkbox]):not(i):not(svg) {
  background-color: var(--background-color-secondary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list-item .runner-runs-customizer__list {
  margin-left: 24px;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list {
  flex: 1;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row {
  height: var(--size-m);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 4px;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__sort-icon {
  opacity: 0;
  padding: 0 4px;
  cursor: -webkit-grab;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_check,
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_request-icon,
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_folder-icon,
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_name {
  margin: 0 4px;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_folder-icon use {
  fill: var(--content-color-tertiary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_folder-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_folder-icon svg {
  color: var(--content-color-tertiary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_request-icon {
  width: 32px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: var(--content-color-secondary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--GET {
  color: var(--content-color-success);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--PUT {
  color: var(--content-color-info);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--POST {
  color: var(--content-color-warning);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--PATCH {
  color: var(--content-color-patch-method);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--OPTIONS {
  color: var(--content-color-options-method);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--HEAD {
  color: var(--content-color-success);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--PATCH {
  color: var(--content-color-patch-method);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .request-method--DELETE {
  color: var(--content-color-error);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row__parents {
  display: flex;
  margin-left: 4px;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row__parents .customizer__row_icons {
  display: flex;
  align-items: center;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row .customizer__row_name {
  flex: 1;
  white-space: nowrap;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row.is-dragged {
  opacity: 0.1;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row.is-drop-hovered-top {
  position: relative;
  box-shadow: 0 2px 0 var(--base-color-brand) inset;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row.is-drop-hovered-bottom {
  position: relative;
  box-shadow: 0 2px 0 var(--base-color-brand);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row:hover .customizer__sort-icon, .runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row.is-hovered .customizer__sort-icon {
  opacity: 1;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row:hover .customizer__sort-icon.is-disabled, .runner-runs .runner-runs-customizer .runner-runs-customizer__container .runner-runs-customizer__list .runner-runs-customizer__list-item__row.is-hovered .customizer__sort-icon.is-disabled {
  opacity: 0;
  cursor: default;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container.is-disabled {
  opacity: 0.1;
  cursor: default;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__container.is-disabled .runner-runs-customizer__list-item__row:hover .customizer__sort-icon, .runner-runs .runner-runs-customizer .runner-runs-customizer__container.is-disabled .runner-runs-customizer__list-item__row.is-hovered .customizer__sort-icon {
  opacity: 0;
  cursor: default;
}
.runner-runs .runner-runs-customizer__history-import-link {
  margin-top: 24px;
}
.runner-runs .runner-runs-customizer__history-import-button {
  padding: var(--spacing-xs);
  font-weight: var(--text-weight-regular);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__disable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__disable-overlay .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__disable-overlay .content .title {
  font-weight: var(--text-weight-medium);
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--content-color-primary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__disable-overlay .content .description {
  width: 360px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  margin-top: 8px;
  color: var(--content-color-primary);
}
.runner-runs .runner-runs-customizer .runner-runs-customizer__disable-overlay .content .button {
  width: 240px;
  height: 32px;
  font-weight: 600;
  line-height: 20px;
  font-size: 12px;
  margin-top: 24px;
}
.runner-runs .runner-recent-runs {
  flex: 1;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}
.runner-runs .runner-recent-runs .runner-recent__onboarding-recommendation-viewer {
  padding: 16px;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-runs .runner-recent-runs .runner-recent-runs__header {
  display: flex;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-left {
  display: flex;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-left .runner-recent-runs__header__tabs {
  display: flex;
  flex: 0 0 100px;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-left .runner-recent-runs__header__tabs .runner-recent-runs__header__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right {
  display: flex;
  flex: 1;
  position: relative;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right {
  justify-content: flex-end;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .input-search-group {
  flex: 0.5;
  min-width: 140px;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .runner-recent-runs__header__bulk-action {
  background: none;
}
.runner-runs .runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .runner-recent-runs__header__bulk-action:hover {
  background: none;
}

.runner-runs.is-hidden {
  display: none;
}

.runner-recent-runs__runs-list .loading-indicator-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item {
  display: flex;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__section-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 280px;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__section-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__indicator-wrapper {
  display: flex;
  align-items: center;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__actions .runner-recent-runs__runs-list-item__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item.is-selected {
  background-color: var(--highlight-background-color-secondary);
}

.runner-runs-selector {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  overflow-y: auto;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.runner-runs-selector .runner-runs-selector__contents {
  padding: var(--spacing-l);
}
.runner-runs-selector .collection-explorer {
  height: 240px;
}
.runner-runs-selector .collection-explorer .explorer-row .explorer-row__icon {
  margin-top: 9px;
}
.runner-runs-selector .runner-runs-selector__item {
  display: flex;
  margin-bottom: var(--spacing-l);
  color: var(--content-color-primary);
}
.runner-runs-selector .runner-runs-selector__item.runner-runs-selector__item--target .item {
  border-bottom: 1px var(--border-style-solid) var(--border-color-strong);
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__meta {
  margin-right: 8px;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__postfix {
  color: var(--content-color-secondary);
  margin-left: var(--spacing-s);
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__postfix .runner-runs-selector__postfix-icon {
  vertical-align: middle;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__postfix--response:hover, .runner-runs-selector .runner-runs-selector__item .runner-runs-selector__postfix--response.is-hovered {
  cursor: pointer;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__meta--target {
  margin-bottom: 10px;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item--environment {
  width: auto;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector-selected-env-label > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value {
  display: flex;
  min-width: 0;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value__text {
  margin-left: 8px;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--data-file {
  min-width: 80px;
  width: 80px;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--data-file.input {
  position: absolute;
  left: 108px;
  opacity: 0;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--persist,
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--response,
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--customize,
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--initial-cookies,
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--save-cookies {
  cursor: pointer;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__postfix--data-file {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__postfix--data-file-remove {
  background-color: transparent;
}
.runner-runs-selector .runner-runs-selector__item .runner-runs-selector__item__value--data-file-preview {
  margin-left: 8px;
}
.runner-runs-selector .runner-runs-selector__start-test {
  flex: 1;
  background-color: var(--button-main-background-color);
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-zero);
  max-width: 300px;
}
.runner-runs-selector .runner-runs-selector__start-test span {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.runner-runs-selector .runner-runs-selector__start-test:focus, .runner-runs-selector .runner-runs-selector__start-test.is-focused {
  background-color: var(--button-main-background-color);
}
.runner-runs-selector .runner-runs-selector__start-test:hover, .runner-runs-selector .runner-runs-selector__start-test.is-hovered {
  background-color: var(--button-main-hover-background-color);
}
.runner-runs-selector .runner-runs-selector__start-test:active, .runner-runs-selector .runner-runs-selector__start-test.is-active {
  background-color: var(--button-main-active-background-color);
}

.runner-recent-runs {
  background-color: var(--background-color-primary);
  border-left: var(--border-style-solid) var(--border-color-default);
  position: relative;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.runner-recent-runs .runner-recent-runs__header {
  border-bottom: 1px var(--border-style-solid) var(--border-color-default);
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-left {
  font-size: 12px;
  font-weight: 300;
  color: var(--content-color-primary);
  align-items: center;
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-left .runner-recent-runs__header__tabs {
  padding: 0 10px;
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right {
  padding: 10px;
  align-items: center;
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .input-search-group {
  margin: 0 10px;
  background-color: var(--background-color-secondary);
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .runner-recent-runs__header__import-selector {
  position: absolute;
  top: 10px;
  right: 93px;
  width: 100px;
  opacity: 0;
  padding-top: 30px;
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .runner-recent-runs__header__import {
  width: 100px;
  font-weight: 600;
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .separator {
  margin: 0 4px;
  background-color: var(--border-color-default);
  height: 16px;
  width: 1px;
}
.runner-recent-runs .runner-recent-runs__header .runner-recent-runs__header__section-right .runner-recent-runs__header__delete-all {
  font-weight: 600;
}

.runner-recent-runs__runs-list {
  overflow-y: auto;
  bottom: 0;
  width: 100%;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-empty-list-item {
  color: var(--content-color-secondary);
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item {
  height: 35px;
  padding: 20px;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  cursor: pointer;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__indicator-wrapper {
  padding-right: 15px;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__indicator {
  width: 15px;
  height: 15px;
  border-radius: var(--border-radius-default);
  background-color: var(--content-color-tertiary);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__indicator.is-passed {
  background-color: var(--base-color-success);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__indicator.is-failed, .runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__indicator.is-stopped {
  background-color: var(--base-color-error);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__indicator.is-running {
  background-color: var(--background-color-info);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__collection {
  font-size: 13px;
  font-weight: 300;
  max-width: 260px;
  color: var(--content-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__environment {
  font-size: 11px;
  color: var(--content-color-secondary);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__status {
  font-size: 11px;
  font-weight: 600;
  color: var(--content-color-secondary);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__status.is-passed {
  color: var(--base-color-success);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__status.is-failed, .runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__status.is-stopped {
  color: var(--base-color-error);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__status.is-running {
  color: var(--background-color-info);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__date {
  font-size: 11px;
  color: var(--content-color-secondary);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__action--export-icon,
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item .runner-recent-runs__runs-list-item__action--delete-icon {
  display: none;
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item:hover, .runner-recent-runs__runs-list .runner-recent-runs__runs-list-item.is-hovered {
  background-color: var(--background-color-tertiary);
}
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item:hover .runner-recent-runs__runs-list-item__action--export-icon,
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item:hover .runner-recent-runs__runs-list-item__action--delete-icon, .runner-recent-runs__runs-list .runner-recent-runs__runs-list-item.is-hovered .runner-recent-runs__runs-list-item__action--export-icon,
.runner-recent-runs__runs-list .runner-recent-runs__runs-list-item.is-hovered .runner-recent-runs__runs-list-item__action--delete-icon {
  display: initial;
}

.preview-data-header-wrapper,
.preview-data-row-wrapper {
  display: flex;
  align-items: center;
}
.preview-data-header-wrapper.highlighted-row,
.preview-data-row-wrapper.highlighted-row {
  background: var(--yellow-10);
}

.preview-data-header {
  flex: 0 0 150px;
  min-height: var(--spacing-xxxl);
  padding: 0px 5px;
  word-break: break-all;
  align-self: baseline;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.preview-data-header.hidden {
  display: none;
}

.preview-data-value {
  flex: 0 0 150px;
  min-height: 30px;
  padding: 5px;
  word-break: break-all;
  align-self: baseline;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.preview-data-value.hidden {
  display: none;
}
.preview-data-value.disabled {
  color: var(--grey-40);
  align-self: auto;
  user-select: none;
}

.preview-data-value:first-child,
.preview-data-header:first-child {
  flex: 0 0 65px;
}

.runner-recent-runs__runs-list-item__actions .runner-recent-runs__runs-list-item__action {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  background-color: transparent;
}
.runner-recent-runs__runs-list-item__actions .runner-recent-runs__runs-list-item__action:hover, .runner-recent-runs__runs-list-item__actions .runner-recent-runs__runs-list-item__action.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.runner-recent-runs__runs-list-item__actions .runner-recent-runs__runs-list-item__action:active, .runner-recent-runs__runs-list-item__actions .runner-recent-runs__runs-list-item__action.is-active {
  background-color: var(--highlight-background-color-secondary);
}

.runner-runs-selector__postfix-tooltip {
  margin-left: 5px;
}

.runner-runs__environment-selector-menu .dropdown-menu-item .item-wrapper .item-label {
  flex: initial;
}
.runner-runs__environment-selector-menu .dropdown-menu-item .item-wrapper .item-label .highlight {
  color: var(--content-color-brand);
}

/**
When 'use browser tabs' setting is enabled, environment selector is rendered in run screen with custom styles.
*/
.runner-runs-selector__item .artemis-environment-selector {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-default);
  width: 300px;
  min-width: 210px;
}
.runner-runs-selector__item .artemis-environment-selector .environment-selector-container {
  margin: 0;
}
.runner-runs-selector__item .artemis-environment-selector .input-search-group:hover, .runner-runs-selector__item .artemis-environment-selector .input-search-group.is-hovered {
  border-top-left-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
}
.runner-runs-selector__item .artemis-environment-selector:hover {
  border-color: var(--border-color-strong);
}
.runner-runs-selector__item .input-select-v2-wrapper {
  width: 100%;
}
.runner-runs-selector__item .runner-runs-selector__item__value.input.use-browser-tabs {
  background-color: transparent;
}
.runner-runs-selector__item .runner-runs-selector__item__value.input.use-browser-tabs:hover {
  background-color: var(--highlight-background-color-secondary);
  border-color: var(--border-color-strong);
}
.runner-runs-selector__item .runner-runs-selector__item__value.input.use-browser-tabs:focus {
  background-color: transparent;
}

.runner-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--content-color-primary);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.runner-results.is-hidden {
  display: none;
}
.runner-results .runner-contents__loading_container {
  margin: auto;
}
.runner-results .runner-results__section-top {
  display: flex;
  flex-direction: column;
}
.runner-results .runner-results__section-top .runner-results__section-top__tabs {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-results .runner-results__section-bottom {
  flex: 1;
  display: flex;
}

.runner-results__automate-run__menu-list {
  background-color: var(--background-color-primary);
}

.runner-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-s) var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-results-header .runner-results-header__meta {
  display: flex;
  align-items: center;
}
.runner-results-header .runner-results-header__meta > *:not(:last-child) {
  margin-right: var(--spacing-s);
}
.runner-results-header .runner-results-header__name {
  display: inline-flex;
}
.runner-results-header__copy-btn {
  display: none;
}
.runner-results-header__meta:hover .runner-results-header__copy-btn {
  display: flex;
}
.runner-results-header .runner-results-header__actions {
  display: flex;
  align-items: center;
  min-height: var(--size-m);
}

.runner-results__body {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-s);
  flex: 1;
  overflow-y: hidden;
}
.runner-results__body .runner-results__body__activity {
  display: flex;
  align-items: center;
}

.runner-summary {
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.runner-summary.is-hidden {
  display: none;
}

.runner-summary__section-top {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-summary__section-top__header {
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-s) var(--spacing-xl) var(--spacing-zero) var(--spacing-l);
  height: var(--size-m);
  align-items: center;
}
.runner-summary__section-top__label {
  white-space: pre;
  color: var(--content-color-tertiary);
}
.runner-summary__section-top__filter {
  flex: 0 1 200px;
}

.runner-summary__section-bottom {
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.runner-summary__header-iterations {
  position: sticky;
  top: 0;
  flex: 0 0 36px;
  background-color: var(--background-color-primary);
  z-index: 2;
  opacity: 0.95;
  display: flex;
}
.runner-summary__header-iterations .runner-summary__meta--placeholder {
  flex: 1 0 340px;
}
.runner-summary__header-iterations .runner-summary__header-iteration__tabs {
  display: flex;
  width: 50%;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-summary__header-iterations .runner-summary__header-iteration__tabs .runner-summary__header-iteration__tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.runner-summary__header-iterations .runner-summary__header-iteration__tabs .runner-summary__header-iteration__tab .runner-summary__header-iteration__tab__count {
  display: flex;
  align-items: center;
  justify-content: center;
}

.runner-summary__body {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-summary__body--empty {
  color: var(--content-color-secondary);
  /* TODO: move it to Text component */
  font-size: var(--text-size-l);
  /* Make it centered */
  margin: var(--spacing-xl) auto;
}

.runner-summary__body__timeline {
  display: flex;
  height: 32px;
}
.runner-summary__body__timeline__keyframe-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.runner-summary__body__timeline__keyframe {
  display: flex;
  justify-content: center;
  align-items: center;
}

.runner-summary__header__back {
  margin-left: 10px;
}
.runner-summary__header__back .runner-summary__back-icon {
  margin-right: 10px;
}

.runner-summary__request-list-item__header {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  height: 32px;
  box-sizing: border-box;
}
.runner-summary__request-list-item__meta {
  display: flex;
  flex: 1 0 324px;
  align-items: center;
  line-height: var(--line-height-m);
  padding-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.runner-summary__request-list-item__expand {
  cursor: pointer;
}
.runner-summary__request-list-item__expand--placeholder {
  width: var(--size-xs);
}
.runner-summary__request-list-item__method {
  font-size: var(--text-size-xs);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-zero) var(--spacing-s);
  color: var(--content-color-primary);
  cursor: pointer;
}
.runner-summary__request-list-item__method--GET {
  color: var(--content-color-success);
}
.runner-summary__request-list-item__method--POST {
  color: var(--content-color-warning);
}
.runner-summary__request-list-item__method--PATCH {
  color: var(--content-color-patch-method);
}
.runner-summary__request-list-item__method--PUT {
  color: var(--content-color-info);
}
.runner-summary__request-list-item__method--DELETE {
  color: var(--content-color-error);
}
.runner-summary__request-list-item__method--OPTIONS {
  color: var(--content-color-options-method);
}
.runner-summary__request-list-item__method--HEAD {
  color: var(--content-color-success);
}
.runner-summary__request-list-item__name {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
  margin: var(--spacing-zero) var(--spacing-xs);
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}
.runner-summary__request-list-item__test-count {
  display: flex;
  white-space: pre;
  margin-left: auto;
  padding: var(--spacing-zero) var(--spacing-s);
  color: var(--content-color-tertiary);
}
.runner-summary__request-list-item__test-count .test-count--passed {
  color: var(--base-color-success);
}
.runner-summary__request-list-item__test-count .test-count--failed {
  color: var(--base-color-error);
}

.runner-summary__test-list-item {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 12px;
  height: 32px;
  box-sizing: border-box;
  color: var(--content-color-primary);
}
.runner-summary__test-list-item::before {
  content: "";
  width: 2px;
  height: 100%;
  margin: var(--spacing-zero) 32px var(--spacing-zero) 48px;
}
.runner-summary__test-list-item.is-passed::before {
  background-color: var(--base-color-success);
}
.runner-summary__test-list-item.is-skipped::before {
  background-color: var(--content-color-tertiary);
}
.runner-summary__test-list-item.is-failed::before {
  background-color: var(--base-color-error);
}
.runner-summary__test-list-item__meta {
  display: flex;
  flex: 1 0 258px;
  align-items: center;
}
.runner-summary__test-list-item__name {
  margin-left: 10px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}

/* timeline stuff */
.runner-summary__request-list-item__timeline,
.runner-summary__test-list-item__timeline {
  overflow: auto;
  width: 50%;
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-summary__request-list-item__timeline::-webkit-scrollbar,
.runner-summary__test-list-item__timeline::-webkit-scrollbar {
  display: none;
}
.runner-summary__request-list-item__timeline .no-scrollbar,
.runner-summary__test-list-item__timeline .no-scrollbar {
  scrollbar-width: none;
}

.runner-summary__body__timeline__keyframe {
  width: 24px;
  height: 24px;
}
.runner-summary__body__timeline__keyframe.is-hovered:after {
  content: "";
  background-color: var(--runner-summary-vertical-bar-hover-background-color);
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  z-index: 1;
  pointer-events: none;
}
.runner-summary__body__timeline__keyframe.is-green .pm-icon, .runner-summary__body__timeline__keyframe.is-red .pm-icon {
  display: none;
}
.runner-summary__body__timeline__keyframe.is-green.is-hovered .pm-icon, .runner-summary__body__timeline__keyframe.is-red.is-hovered .pm-icon {
  display: flex;
}
.runner-summary__body__timeline__keyframe.is-green {
  background-color: var(--background-color-success);
}
.runner-summary__body__timeline__keyframe.is-red {
  background-color: var(--base-color-error);
}
.runner-summary__body__timeline__keyframe.is-grey {
  background-color: var(--background-color-tertiary);
}

.runner-summary__header-iteration__tabs {
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
}
.runner-summary__header-iteration__tabs::-webkit-scrollbar {
  display: none;
}

.runner-summary__header-iteration__tab {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  color: var(--content-color-secondary);
  font-size: 13px;
  min-width: 24px;
  position: relative;
}
.runner-summary__header-iteration__tab:hover:after, .runner-summary__header-iteration__tab.is-hovered:after {
  content: "";
  background-color: var(--runner-summary-vertical-bar-hover-background-color);
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  z-index: 1;
  pointer-events: none;
}
.runner-summary__header-iteration__tab:hover .runner-summary__header-iteration__tab__count, .runner-summary__header-iteration__tab.is-hovered .runner-summary__header-iteration__tab__count {
  background-color: var(--highlight-background-color-secondary);
}
.runner-summary__header-iteration__tab .runner-summary__header-iteration__tab__count {
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-default);
}

.runner-results__iterations__tabs {
  flex: 0 0 var(--spacing-xl);
  background-color: var(--background-color-primary);
  color: var(--content-color-tertiary);
  font-size: var(--text-size-xs);
  border-left: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.runner-results__iterations__tabs div::-webkit-scrollbar {
  display: none;
}

.runner-results__iterations__tab {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: var(--size-s);
  cursor: pointer;
}
.runner-results__iterations__tab.is-done {
  color: var(--content-color-primary);
}
.runner-results__iterations__tab.is-done.is-failed {
  color: var(--content-color-error);
}
.runner-results__iterations__tab.is-done:hover, .runner-results__iterations__tab.is-done.is-hovered {
  background-color: var(--highlight-background-color-primary);
}
.runner-results__iterations__tab.is-running {
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  background-color: var(--background-color-secondary);
}
.runner-results__iterations__tab.is-active {
  background-color: var(--background-color-tertiary);
}
.runner-results__iterations__tab.is-active:hover, .runner-results__iterations__tab.is-active.is-hovered {
  background-color: var(--highlight-background-color-tertiary);
}
.runner-results__iterations__tab.is-pending {
  cursor: default;
}

.flowstudio {
  flex: 1;
}
.flowstudio *,
.flowstudio *::before, *::after .flowstudio {
  box-sizing: border-box;
}

.fs-gn__auto-input {
  height: 14px;
  width: 14px;
  border-radius: 2px;
  border: unset;
  background-color: var(--grey-40);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
}
.fs-gn__auto-input .fs-gn__auto-handle {
  cursor: pointer;
  position: relative;
  margin: 0px;
  align-items: center;
  height: 6px;
  width: 6px;
  transform: unset;
  border: unset;
  top: unset;
  right: unset;
  left: unset;
  background: unset;
  z-index: 2;
  border-width: unset;
  border-style: unset;
  border-color: unset;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: var(--background-color-primary);
}
.fs-gn__auto-input .fs-gn__auto-handle.react-flow__handle-connecting.react-flow__handle-valid {
  cursor: crosshair;
}
.fs-gn__auto-input .fs-gn__auto-handle::before {
  --hitbox-padding: 4px;
  --hitbox-size: calc(100% + var(--hitbox-padding) * 2);
  --hitbox-position: calc(var(--hitbox-padding) * -1);
  content: "";
  width: var(--hitbox-size);
  height: var(--hitbox-size);
  background: none;
  position: absolute;
  left: --hitbox-position;
  top: --hitbox-position;
}
.fs-gn__auto-input .fs-gn__auto-handle.connected {
  background-color: var(--grey-40);
}
.fs-gn__auto-input .fs-gn__auto-handle.connected.error {
  background-color: var(--content-color-error);
}
.fs-gn__auto-input .fs-gn__auto-handle.connected.active {
  background-color: var(--base-color-success);
}
.fs-gn__auto-input .fs-gn__auto-handle:hover {
  height: 8px;
  width: 8px;
}
.fs-gn__auto-input .fs-gn__auto-connector {
  width: 24px;
  height: 4px;
  position: absolute;
  right: 0;
}
.fs-gn__auto-input .connector {
  stroke-dasharray: 5 5 10;
  stroke: var(--grey-40);
  stroke-width: 2;
  background-color: var(--background-color-primary);
}
.fs-gn__auto-input.error {
  background-color: var(--content-color-error);
}
.fs-gn__auto-input.error .connector {
  stroke: var(--content-color-error);
}
.fs-gn__auto-input.active {
  background-color: var(--base-color-success);
}
.fs-gn__auto-input.active .connector {
  stroke: var(--base-color-success);
}
.fs-gn__auto-input.connected {
  border-color: var(--grey-40);
  background-color: var(--background-color-primary);
  border-width: 2px;
  border-style: solid;
}
.fs-gn__auto-input.connected.active {
  border-color: var(--base-color-success);
}
.fs-gn__auto-input.connected.error {
  border-color: var(--content-color-error);
}
.fs-gn__auto-input.react-flow__handle-connecting.react-flow__handle-valid {
  cursor: crosshair;
}
.fs-gn__auto-input:hover .fs-gn__auto-handle {
  height: 8px;
  width: 8px;
}

.fs-gn__auto-output-container {
  margin-left: auto;
}

.fs-gn__auto-output {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--background-color-primary);
  position: relative;
}
.fs-gn__auto-output .fs-gn__auto-handle {
  cursor: pointer;
  position: relative;
  margin: 0px;
  align-items: center;
  height: 8px;
  width: 8px;
  transform: unset;
  border: unset;
  top: unset;
  right: unset;
  left: unset;
  background: unset;
  background-color: var(--grey-40);
  border-radius: 2px;
  z-index: 2;
  box-sizing: border-box;
}
.fs-gn__auto-output .fs-gn__auto-handle::before {
  --hitbox-padding: 4px;
  --hitbox-size: calc(100% + var(--hitbox-padding) * 2);
  --hitbox-position: calc(var(--hitbox-padding) * -1);
  content: "";
  width: var(--hitbox-size);
  height: var(--hitbox-size);
  background: none;
  position: absolute;
  left: var(--hitbox-position);
  top: var(--hitbox-position);
}
.fs-gn__auto-output .fs-gn__auto-handle.react-flow__handle-connecting.react-flow__handle-valid {
  cursor: crosshair;
}
.fs-gn__auto-output .fs-gn__auto-handle.error {
  background-color: var(--content-color-error);
}
.fs-gn__auto-output .fs-gn__auto-handle.active {
  background-color: var(--base-color-success);
}
.fs-gn__auto-output .fs-gn__auto-handle:hover {
  height: 10px;
  width: 10px;
}
.fs-gn__auto-output .fs-gn__auto-connector {
  width: 16px;
  height: 4px;
  position: absolute;
  left: 2px;
}
.fs-gn__auto-output .connector {
  stroke-dasharray: 7 5 5;
  stroke: var(--grey-40);
  stroke-width: 2;
}
.fs-gn__auto-output.error .connector {
  stroke: var(--content-color-error);
}
.fs-gn__auto-output.active .connector {
  stroke: var(--base-color-success);
}
.fs-gn__auto-output.auto-width {
  width: auto;
}
.fs-gn__auto-output.auto-width .fs-gn__block {
  width: auto;
}

.react-flow__node .gn-port__handle {
  background: unset;
  border: unset;
  border-radius: unset;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  right: unset;
  left: unset;
  top: unset;
  transform: unset;
  cursor: pointer;
}

.fql-postbot-icon {
  display: flex !important;
}
.fql-postbot-icon svg {
  color: var(--purple-60) !important;
}
.fql-postbot-icon.dark svg {
  color: var(--purple-40) !important;
}

.Popover {
  box-shadow: var(--shadow-default);
  background-color: var(--background-color-primary);
  color: var(--content-color-tertiary);
  border: 1px var(--border-color-default) solid;
  border-radius: 8px;
  padding: 5px;
  margin: -5px;
  display: flex;
}
.Popover .bubble-menu-icon {
  border: 1px var(--border-color-default) solid;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Popover .bubble-menu-icon.disabled {
  pointer-events: none;
}
.Popover .bubble-menu-icon:hover, .Popover .bubble-menu-icon.is-active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-tertiary);
  cursor: pointer;
}
.Popover .bubble-menu-icon + .bubble-menu-icon {
  margin-left: 6px;
}

.flowstudio .edge__hovering-area {
  stroke-width: 16px;
  fill: none;
  stroke: none;
  cursor: pointer;
}
.flowstudio .edge__hovering-area:hover + .edge__data {
  stroke-width: 3;
}
.flowstudio .fs__edge {
  fill: none;
  cursor: pointer;
}
.flowstudio .fs__edge.edge__data {
  pointer-events: none;
}
.flowstudio .fs__edge.edge__data.error {
  stroke: var(--content-color-error);
}
.flowstudio .fs__edge.edge__data.disabled {
  stroke: var(--grey-40);
}
.flowstudio .fs__edge.edge__data.chooser-attached {
  stroke: var(--blue-40);
  stroke-width: 3;
  stroke-dasharray: 5;
}
.flowstudio .fs__edge.edge__signal {
  stroke-dasharray: 5;
  stroke: var(--grey-40);
}
.flowstudio .fs__edge.edge__signal:hover {
  stroke-width: 3;
}
.flowstudio .fs__edge.edge__signal.animated {
  stroke: var(--base-color-success);
}
.flowstudio .fs__edge.edge__signal.error {
  stroke: var(--content-color-error);
}
.flowstudio .fs__edge.edge__signal.active, .flowstudio .fs__edge.edge__signal.disabled {
  stroke: var(--grey-40);
}
.flowstudio .fs__edge.edge__signal.animated {
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
}
.flowstudio .react-flow__edge.selected .fs__edge.edge__data {
  stroke-width: 3;
  stroke: var(--blue-40);
}
.flowstudio .react-flow__edge.selected .fs__edge.edge__data.error {
  stroke: var(--base-color-error);
}
.flowstudio .react-flow__edge.selected .fs__edge.edge__signal {
  stroke: var(--content-color-success);
  stroke-width: 3;
}
.flowstudio .react-flow__edge.selected .fs__edge.edge__signal.error {
  stroke: var(--base-color-error);
}
.flowstudio .react-flow__edge.updating .fs__edge {
  stroke: var(--grey-50);
}
.flowstudio .edge__connection {
  stroke: var(--grey-50);
}

.homepage-right-section-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-bottom: var(--spacing-xxl);
  padding: var(--spacing-xxl) var(--spacing-l);
}
.homepage-right-section-container--responsive {
  padding-top: 0;
}
@media only screen and (min-width: 1600px) {
  .homepage-right-section-container {
    overflow-y: unset;
    width: unset;
    max-width: 552px;
    margin-left: auto;
  }
}
@media only screen and (max-width: 1300px) {
  .homepage-right-section-container {
    grid-column: 2;
    max-width: 584px;
  }
}

.team-activity-feed-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--spacing-xxl);
  flex: 0 0 320px;
}
.team-activity-feed-empty-state__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}
@media only screen and (max-width: 1300px) {
  .team-activity-feed-empty-state {
    grid-column: 2;
  }
}
.team-activity-feed-empty-state-main-title {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  align-self: flex-start;
}
.team-activity-feed-empty-state-illustration {
  margin-top: var(--spacing-xxxl);
  display: block;
  width: 140px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/activity-feed-mock.svg");
}
.team-activity-feed-empty-state-title {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-top: var(--spacing-xxl);
}
.team-activity-feed-empty-state-subtitle {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
}
.team-activity-feed-empty-state--create-team {
  margin-top: var(--spacing-xl);
  width: 107px;
  height: var(--size-m);
}

.request-to-join__actions {
  display: flex;
  align-items: center;
}
.request-to-join__action-confirm {
  margin-left: 8px;
  width: 160px;
}
.request-to-join__character-usage {
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  color: var(--content-color-secondary);
}
.request-to-join textarea {
  resize: none;
  background: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  padding: 8px;
  margin-top: 8px;
}
.request-to-join .textarea-error-text {
  padding-left: 0;
  justify-content: space-between;
}
.request-to-join__block {
  margin: 8px 0;
}

.auto-flex-modal .modal-content {
  padding: var(--spacing-xxxl) var(--spacing-xxl) var(--spacing-l);
  max-height: 80vh;
}
.auto-flex-modal .modal-content .header-title {
  font-size: 20px;
  display: flex;
  justify-content: center;
}
.auto-flex-modal .modal-content .header-description {
  margin-top: var(--spacing-l);
  text-align: center;
}
.auto-flex-modal .modal-content .header-description span {
  font-size: var(--text-size-l);
  line-height: 22px;
  display: inline-block;
  color: var(--content-color-secondary);
}
.auto-flex-modal .modal-content .content-container {
  display: flex;
  margin-top: var(--spacing-xl);
  justify-content: space-evenly;
}
.auto-flex-modal .modal-content .content-container .section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auto-flex-modal .modal-content .content-container .section svg {
  width: 104px;
  height: 88px;
}
.auto-flex-modal .modal-content .content-container .section .title {
  margin-top: var(--spacing-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.auto-flex-modal .modal-content .content-container .section .description {
  margin-top: var(--spacing-s);
  text-align: center;
  width: 228px;
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.auto-flex-modal .modal-footer {
  flex-direction: column;
  padding-bottom: var(--spacing-xxxl);
}
.auto-flex-modal .modal-footer .btn-primary {
  padding: var(--spacing-s) var(--spacing-m);
}
.auto-flex-modal .modal-footer .btn-secondary {
  margin-top: var(--spacing-l);
  padding: var(--spacing-s) var(--spacing-m);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.free-plan-banner {
  background-color: var(--background-color-secondary);
  margin-bottom: var(--spacing-xxl);
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-l) var(--spacing-l);
  border-radius: var(--border-radius-default);
  position: relative;
  box-sizing: border-box;
}
.free-plan-banner .description {
  margin: var(--spacing-m) var(--spacing-xxl) var(--spacing-m) var(--spacing-zero);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  display: block;
}
.free-plan-banner .btn-secondary {
  margin-top: var(--spacing-s);
  padding: var(--spacing-s) var(--spacing-m);
  border: 1px solid rgba(48, 48, 48, 0.2);
  color: var(--content-color-primary);
}
.free-plan-banner .btn-tertiary {
  position: absolute;
  top: var(--spacing-s);
  right: var(--spacing-s);
}
.free-plan-banner .link {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-left: var(--spacing-l);
  color: var(--content-color-secondary);
}
.free-plan-banner .illustration {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.free-plan-banner .illustration svg {
  width: 216px;
  height: 72px;
}

.free-plan-modal .modal-content {
  padding: var(--spacing-xxxl) var(--spacing-xxl) var(--spacing-l);
  max-height: 80vh;
}
.free-plan-modal .modal-content .header-title {
  font-size: 20px;
  display: flex;
  justify-content: center;
}
.free-plan-modal .modal-content .header-description {
  font-size: var(--text-size-l);
  display: block;
  margin-top: var(--spacing-s);
  text-align: center;
  line-height: 22px;
  color: var(--content-color-secondary);
}
.free-plan-modal .modal-content .content-container {
  display: flex;
  margin-top: var(--spacing-xxl);
  justify-content: space-around;
}
.free-plan-modal .modal-content .content-container .section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.free-plan-modal .modal-content .content-container .section svg {
  width: 104px;
  height: 88px;
}
.free-plan-modal .modal-content .content-container .section .title {
  margin-top: var(--spacing-s);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-medium);
}
.free-plan-modal .modal-content .content-container .section .description {
  margin-top: var(--spacing-s);
  text-align: center;
  width: 200px;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.free-plan-modal .modal-footer {
  flex-direction: column;
  padding-bottom: var(--spacing-xxxl);
}
.free-plan-modal .modal-footer .btn-primary {
  padding: var(--spacing-s) var(--spacing-m);
}

.overview-page-content-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex: 3;
  padding: var(--spacing-xxl) var(--spacing-l);
}
.overview-page-content-container--responsive {
  padding-bottom: 0;
}

.overview-page-plan-alert {
  margin-bottom: var(--spacing-xxl);
}
.overview-page-plan-alert .alert-content {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.overview-page-plan-alert .alert-content .alert-actions {
  width: calc(4 * var(--size-s));
  margin-top: var(--spacing-zero);
}
.overview-page-plan-alert .alert-content .alert-actions .alert-primary-action {
  margin-right: var(--spacing-zero);
  width: calc(4 * var(--size-s));
  margin-left: var(--spacing-s);
}
.overview-page-plan-alert-icon {
  color: var(--base-color-info);
  background: var(--background-color-primary);
  font-size: var(--text-size-m);
  height: var(--size-xs);
  padding: var(--spacing-zero) var(--spacing-s);
  margin-top: var(--spacing-s);
  font-weight: var(--text-weight-bold);
  margin-right: var(--spacing-s);
  border-radius: var(--spacing-s);
}

.apinetwork-page-container {
  display: grid;
  place-items: center;
  font-family: var(--text-family-code);
  font-size: 32px;
  height: 100%;
  color: var(--content-color-primary);
}

.workspaces-page {
  /* Remove default margins and padding */
  /* Remove list styles on ul, ol elements  */
  display: flex;
  justify-content: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: unset;
}
.workspaces-page body,
.workspaces-page h1,
.workspaces-page h2,
.workspaces-page h3,
.workspaces-page h4,
.workspaces-page p,
.workspaces-page figure,
.workspaces-page blockquote,
.workspaces-page dl,
.workspaces-page dd,
.workspaces-page ul,
.workspaces-page ol {
  margin: 0;
  padding: 0;
}
.workspaces-page ul,
.workspaces-page ol {
  list-style: none;
}
.workspaces-page-container {
  width: 960px;
  padding: var(--spacing-xl);
  box-sizing: border-box;
}
.workspaces-page-container-breadcrumbs {
  margin-bottom: var(--spacing-m);
}
.workspaces-page-container-public-workspaces {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  flex-direction: column;
  margin-bottom: var(--spacing-xxl);
  padding-bottom: var(--spacing-l);
}
.workspaces-page-container-public-workspaces .pm-link {
  text-decoration: none;
  font-weight: var(--text-weight-regular);
}
.workspaces-page-container-public-workspaces .pm-link:hover {
  text-decoration: underline;
  color: var(--content-color-link);
}
.workspaces-page-container-public-workspaces strong {
  font-weight: var(--text-weight-medium);
}
.workspaces-page-container-public-workspaces .btn.btn-secondary {
  margin-right: var(--spacing-l);
  margin-top: var(--spacing-xs);
}
.workspaces-page-container-public-workspaces-show-all {
  margin-top: var(--spacing-l);
  margin-left: var(--spacing-l);
  font-weight: var(--text-weight-small);
}
.workspaces-page-container-public-workspaces-request {
  display: flex;
  margin-top: var(--spacing-l);
  margin-left: var(--spacing-l);
}
.workspaces-page-container-public-workspaces .avatar {
  margin-right: var(--spacing-l);
}
.workspaces-page-container-public-workspaces-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: var(--spacing-s);
}
.workspaces-page-container-public-workspaces-info-description {
  color: var(--content-color-secondary);
  word-break: break-word;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-zero);
}
.workspaces-page-container-public-workspaces-info-actions {
  display: flex;
  justify-content: space-between;
}
.workspaces-page-container-public-workspaces-info-actions-time {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.workspaces-page-container-public-workspaces-info-actions-button {
  margin-right: var(--spacing-l);
  margin-top: var(--spacing-xs);
}
.workspaces-page-container-filters {
  display: flex;
  justify-content: space-between;
}
.workspaces-page-container-filters-label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-right: var(--spacing-xs);
}
.workspaces-page-container-filters-label-workspace {
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
}
.workspaces-page-container .ws-list-empty-state {
  margin-top: var(--spacing-xxxl);
  max-height: 480px;
}

.workspaces-page-container-loader {
  display: flex;
  align-items: center;
  min-height: 50%;
  width: 100%;
  justify-content: center;
}

.workspaces-page-pagination-loader {
  display: flex;
  align-items: center;
  height: 76px;
  width: 100%;
  justify-content: center;
  border-top: var(--border-width-default) solid var(--highlight-background-color-primary);
}

.workspace-visibility-dropdown {
  max-width: 116px;
}
.workspace-visibility-dropdown__header {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-xs);
}
.workspace-visibility-dropdown .btn.workspace-visibility-dropdown__unselected {
  color: var(--content-color-tertiary);
}
.workspace-visibility-dropdown__unselected {
  color: var(--content-color-tertiary);
}
.workspace-visibility-dropdown__item__icon {
  margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xl);
}
.workspace-visibility-dropdown__item__icon--active {
  margin-left: var(--spacing-s);
}
.workspace-visibility-dropdown .btn {
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
}
.workspace-visibility-dropdown .dropdown-menu {
  background-color: var(--background-color-primary);
}
.workspace-visibility-dropdown .is-active {
  color: var(--content-color-secondary);
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
  border-radius: var(--border-radius-default);
  box-shadow: 0px 0px 0px 2px var(--blue-30);
}
.workspace-visibility-dropdown__dark-mode .is-active {
  color: var(--content-color-secondary);
  transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  border: var(--border-width-default) var(--border-style-solid) var(--blue-50);
  border-radius: var(--border-radius-default);
  box-shadow: 0px 0px 0px 2px var(--blue-50);
}

.workspace-sort-dropdown {
  max-width: 208px;
}
.workspace-sort-dropdown__header {
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-xs);
}
.workspace-sort-dropdown__item__icon {
  margin: var(--spacing-zero) var(--spacing-s) var(--spacing-zero) var(--spacing-xl);
}
.workspace-sort-dropdown__item__icon--active {
  margin-left: var(--spacing-s);
}
.workspace-sort-dropdown .btn {
  border: var(--border-width-default) var(--border-style-solid) var(--content-color-tertiary);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-primary);
}
.workspace-sort-dropdown .btn .label {
  color: var(--content-color-secondary);
}
.workspace-sort-dropdown .dropdown-menu {
  background-color: var(--background-color-primary);
}
.workspace-sort-dropdown .is-active {
  color: var(--content-color-secondary);
  transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  border: var(--border-width-default) var(--border-style-solid) var(--blue-30);
  border-radius: var(--border-radius-default);
  box-shadow: 0px 0px 0px 2px var(--blue-30);
}
.workspace-sort-dropdown__dark-mode .is-active {
  color: var(--content-color-secondary);
  transition: border 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  border: var(--border-width-default) var(--border-style-solid) var(--blue-50);
  border-radius: var(--border-radius-default);
  box-shadow: 0px 0px 0px 2px var(--blue-50);
}

.workspace-row {
  border-top: var(--border-width-default) solid var(--highlight-background-color-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.workspace-row:hover {
  background-color: var(--highlight-background-color-primary);
}
.workspace-row-details {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  padding: var(--spacing-l) var(--spacing-s);
}
.workspace-row-details__icon {
  width: var(--size-s);
  height: var(--size-s);
  background-color: var(--background-color-secondary);
  display: grid;
  place-content: center;
  border-radius: var(--border-radius-default);
}
.workspace-row-details-meta {
  max-width: 736px;
}

.workspace-members-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 192px;
  padding: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.workspace-members-list-more {
  color: var(--content-color-tertiary);
}
.workspace-members-list-item__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  display: block;
}
.workspace-members-list > * + * {
  margin-top: var(--spacing-xs);
}

.workspace-members {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--spacing-s);
}
.workspace-members > * + * {
  margin-left: -8px;
}
.workspace-members .workspace-member {
  border: var(--border-width-l) solid var(--background-color-primary);
  border-radius: 24px;
}
.workspace-members .workspace-member:hover {
  z-index: 5;
}
.workspace-members .workspace-member:nth-child(2) {
  z-index: 1;
}
.workspace-members .workspace-member:nth-child(2):hover {
  z-index: 5;
}
.workspace-members .workspace-member:nth-child(3) {
  z-index: 2;
}
.workspace-members .workspace-member:nth-child(3):hover {
  z-index: 5;
}
.workspace-members .workspace-member-show-more {
  min-width: var(--size-s);
  height: var(--size-s);
  display: grid;
  place-content: center;
  background: var(--background-color-tertiary);
  color: var(--content-color-secondary);
  z-index: 3;
}
.workspace-members .workspace-member-show-more-text {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.workspace-members .workspace-member-show-more:hover {
  z-index: 5;
}

.workspace-page-discoverable-teams {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xl);
}
.workspace-page-discoverable-teams__subtitle {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-l);
}
.workspace-page-discoverable-teams__subtitle .domain {
  color: var(--base-color-info);
}
.workspace-page-discoverable-teams-list {
  margin-top: calc(var(--spacing-xs) + var(--spacing-l));
  width: 400px;
}
.workspace-page-discoverable-teams-list > * + * {
  margin-top: var(--spacing-s);
}
.workspace-page-discoverable-teams-list__item {
  cursor: pointer;
  display: flex;
  list-style-type: none;
  padding: var(--spacing-s);
  height: 48px;
  background: var(--background-color-primary);
  border: var(--border-width-default) solid var(--border-color-default);
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  max-width: 580px;
}
.workspace-page-discoverable-teams-list__item--team-icon {
  width: 32px;
  object-fit: contain;
}
.workspace-page-discoverable-teams-list__item--team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
  max-width: 184px;
}
.workspace-page-discoverable-teams-actions {
  margin-top: var(--spacing-l);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-weight: var(--text-weight-medium);
  color: var(--content-color-brand);
  width: 400px;
}
.workspace-page-discoverable-teams-actions--center {
  justify-content: center;
}
.workspace-page-discoverable-teams-actions--view-more {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  padding-left: 0;
}
.workspace-page-discoverable-teams-actions--create-team {
  height: 32px;
}
.workspace-page-discoverable-teams-actions--create-team i {
  margin-right: var(--spacing-s);
}

.workspace-list-loading-state {
  height: 452px;
  width: 960px;
  overflow: hidden;
  animation: fade 1s ease-in-out infinite;
  display: flex;
  flex-direction: column;
}
.workspace-list-loading-state .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-l);
  margin-top: var(--spacing-xl);
}
.workspace-list-loading-state .header .left-content {
  display: flex;
}
.workspace-list-loading-state .header .left-content .search-input {
  background-color: var(--highlight-background-color-secondary);
  animation: fade 1s ease-in-out infinite;
  min-width: 228px;
  height: var(--size-m);
  border-radius: var(--border-radius-default);
  margin-right: var(--spacing-m);
}
.workspace-list-loading-state .header .left-content .visibility-filter {
  background-color: var(--highlight-background-color-secondary);
  animation: fade 1s ease-in-out infinite;
  min-width: 88px;
  height: var(--size-m);
  border-radius: var(--border-radius-default);
  padding-right: var(--spacing-zero);
}
.workspace-list-loading-state .header .sort {
  background-color: var(--highlight-background-color-secondary);
  animation: fade 1s ease-in-out infinite;
  min-width: 136px;
  height: var(--size-m);
  border-radius: var(--border-radius-default);
  padding-right: var(--spacing-zero);
  margin-left: calc(8 * var(--spacing-xxxl));
}
.workspace-list-loading-state .list-item {
  display: flex;
  flex: 1;
  padding: var(--spacing-l) var(--spacing-s);
  box-sizing: border-box;
  border-top: var(--border-width-default) solid var(--highlight-background-color-primary);
}
.workspace-list-loading-state .list-item .list-item-icon {
  height: var(--size-s);
  width: var(--size-s);
  background-color: var(--highlight-background-color-secondary);
  animation: fade 1s ease-in-out infinite;
  border-radius: var(--border-radius-default);
}
.workspace-list-loading-state .list-item .list-item-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: var(--spacing-m);
}
.workspace-list-loading-state .list-item .list-item-info .list-item-name {
  display: flex;
  flex: 1;
  background-color: var(--highlight-background-color-secondary);
  animation: fade 1s ease-in-out infinite;
  border-radius: var(--border-radius-default);
  margin-right: calc(12 * var(--spacing-xxxl));
  margin-bottom: var(--spacing-m);
}
.workspace-list-loading-state .list-item .list-item-info .list-item-summary {
  display: flex;
  flex: 1;
  background-color: var(--highlight-background-color-secondary);
  animation: fade 1s ease-in-out infinite;
  border-radius: var(--border-radius-default);
  margin-right: calc(13 * var(--spacing-xxxl));
}

.team-integrations__status-summary {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.team-integrations__status-summary__icon {
  vertical-align: sub;
  margin-right: var(--spacing-xs);
}
.team-integrations__status-summary__text {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  max-width: calc(4 * var(--spacing-xl));
  text-overflow: ellipsis;
  overflow: hidden;
}
.team-integrations__status-summary__text-success {
  color: var(--content-color-success);
}
.team-integrations__status-summary__text-configured {
  color: var(--content-color-tertiary);
}
.team-integrations__status-summary__text-error {
  color: var(--content-color-error);
}

.integration-runs__error {
  margin-top: 120px;
}
.integration-runs__error__action {
  max-width: 320px;
  width: 320px;
}

.team-integrations {
  margin: 0 var(--spacing-s);
}
.team-integrations-title {
  margin: 0 var(--spacing-xl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxxl);
}
.team-integrations-description {
  margin: 0 var(--spacing-xl);
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
}

.team-integrations.page-1 {
  margin: 0 auto;
}
@media (max-width: 1259px) {
  .team-integrations.page-1 {
    width: 100%;
  }
}
@media (min-width: 1260px) {
  .team-integrations.page-1 {
    width: 960px;
  }
}

.team-integrations.page-2 {
  max-width: 960px;
  width: 960px;
  margin: 0 auto;
}

.team-integrations-actionables {
  margin: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.team-integrations-actionables .integrations-sort-search-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  padding-bottom: var(--spacing-xs);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.team-integrations-actionables .integrations-sort-search-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 1024px) {
  .team-integrations-actionables .integrations-sort-search-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.team-integrations-actionables .integrations-sort-search {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .team-integrations-actionables .integrations-sort-search {
    flex-direction: column;
    padding-bottom: var(--spacing-l);
  }
  .team-integrations-actionables .integrations-sort-search > .team-integrations-filter__actions {
    padding-bottom: var(--spacing-l);
  }
}
.team-integrations-actionables .integrations-sort-dropdown {
  max-width: 300px;
  width: 200px;
}
.team-integrations-actionables .integrations-sort-dropdown .Select-control {
  background: var(--background-color-secondary);
  border: 1px solid var(--border-color-strong);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
}
.team-integrations-actionables .integrations-sort-dropdown .Select-control .Select-value {
  color: var(--content-color-secondary);
}
.team-integrations-actionables .integrations-sort-dropdown .Select-control .Select-arrow {
  border-color: var(--border-color-strong) transparent transparent;
}
.team-integrations-actionables .integrations-sort-dropdown .Select-control.is-open .Select-control .Select-arrow-zone .Select-arrow {
  border-bottom-color: var(--border-color-strong);
}
.team-integrations-actionables .integrations-search {
  padding-left: var(--spacing-l);
  width: 288px;
}
@media (max-width: 1024px) {
  .team-integrations-actionables .integrations-search {
    padding-left: var(--spacing-zero);
  }
}
.team-integrations-actionables .integrations-search .input-search-group {
  border-radius: var(--border-radius-default);
  height: var(--size-m);
}
.team-integrations-actionables .integrations-search .pm-form-control-sm {
  background: var(--background-color-secondary);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  border: 1px solid var(--border-color-strong);
  padding: var(--spacing-zero) var(--spacing-xxl) var(--spacing-zero) var(--spacing-xxl);
}
.team-integrations-actionables .integrations-search .pm-form-control-sm {
  height: 40px;
}
.team-integrations-actionables .integrations-search .pm-icon-input .pm-icon.pm-icon-sm {
  margin: var(--spacing-m) var(--spacing-s);
}

.team-integrations-filter__actions {
  display: flex;
  align-items: center;
}
.team-integrations-filter__actions .dropdown {
  height: var(--size-m);
}
.team-integrations-filter__actions .team-integrations-filter__actions-sort-button {
  display: flex;
  width: 150px;
  justify-content: space-between;
  height: var(--size-m);
  color: var(--content-color-primary);
  background-color: var(--background-color-secondary);
  border: 1px solid var(--border-color-default);
}
.team-integrations-filter__actions .team-integrations-filter__actions-sort-button:hover, .team-integrations-filter__actions .team-integrations-filter__actions-sort-button.is-hovered {
  border-color: var(--border-color-strong);
  background-color: var(--highlight-background-color-secondary);
}
.team-integrations-filter__actions .team-integrations-filter__actions-sort-button:active, .team-integrations-filter__actions .team-integrations-filter__actions-sort-button.is-active {
  border-color: var(--border-color-strong);
  background-color: var(--highlight-background-color-secondary);
}
.team-integrations-filter__actions .team-integrations-filter__actions__meta {
  padding-right: var(--spacing-xs);
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}

.team-integrations-empty-state-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-l);
  min-height: 100%;
  margin-top: var(--spacing-xl);
}

.team-integrations-empty-state {
  padding-top: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team-integrations-empty-state__illustration-wrapper {
  margin-bottom: var(--spacing-l);
}

.team-integrations-empty-state-actions {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-s);
}

.btn-secondary.browse-integrations {
  height: var(--size-m);
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  padding: var(--spacing-zero) var(--spacing-m);
}

.btn-primary.add-integrations {
  height: var(--size-m);
  font-style: normal;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  padding: var(--spacing-zero) var(--spacing-m);
}

.team-integrations__list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 var(--spacing-l) 0;
  padding-left: var(--spacing-m);
}
.team-integrations__list li {
  margin-bottom: var(--spacing-l);
  display: inline-flex;
  flex-direction: column;
  border: solid thin var(--border-color-default);
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  width: 288px;
  margin: var(--spacing-m);
  height: 135px;
  padding: var(--spacing-xl);
}
.team-integrations__list li:hover {
  cursor: pointer;
  border: 1px solid var(--border-color-strong);
}
.team-integrations__list-item-service-icon-wrapper {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-default);
  position: relative;
}
.team-integrations__list-item-service-icon-wrapper__fallback-icon {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/integration-stroke.svg");
}
.team-integrations__list-item-service-icon {
  height: auto;
  width: 100%;
}
.team-integrations__list-item-service-name {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-xl);
  color: var(--content-color-primary);
  padding-left: var(--spacing-s);
  padding-right: var(--spacing-l);
}
.team-integrations__list-item-service-name__div {
  max-width: calc(4 * var(--spacing-xl));
  text-overflow: ellipsis;
  overflow: hidden;
}
.team-integrations__list-item-rule-name {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-l);
  display: flex;
  flex-grow: 1;
  height: var(--spacing-xxxl);
}
.team-integrations__list-item-rule-details {
  display: flex;
  flex-direction: row;
}
.team-integrations__list-item-rule-details .team-integrations__list-item-service-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.team-integrations__list-item-status-item .pm-icon-error path {
  fill: var(--content-color-error);
}

.team-integrations.page-1 .integrations-search {
  padding-left: 0px;
}

.team-integrations-no-search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  color: var(--content-color-primary);
}
.team-integrations-no-search-results .no-search-results-image {
  display: block;
  width: 120px;
  height: 105px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/emptysearch.svg");
  margin-bottom: 20px;
}
.team-integrations-no-search-results__label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: var(--spacing-s);
}
.team-integrations-no-search-results__subtext {
  font-size: 12px;
}
.team-integrations-no-search-results__subtext--body {
  opacity: unset;
}
.team-integrations-no-search-results__subtext--body .external-link__icon svg {
  height: var(--spacing-m);
  width: var(--spacing-m);
  margin-left: var(--spacing-xs);
  color: var(--content-color-link);
}
.team-integrations-no-search-results__subtext--body .pm-link {
  color: var(--content-color-link);
}
.team-integrations-no-search-results__illustration-wrapper {
  margin-bottom: var(--spacing-l);
}

.no-team-integrations {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 10px 20px;
  box-sizing: border-box;
}
.no-team-integrations .no-team-integrations__illustration-wrapper {
  margin-bottom: var(--spacing-l);
}
.no-team-integrations .no-team-integrations__title {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.no-team-integrations .no-team-integrations__subtitle {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  text-align: center;
  margin-bottom: var(--spacing-l);
  max-width: 500px;
  line-height: var(--line-height-m);
}

.no-team-integrations .no-team-integrations__illustration-wrapper--integrations .integrations-empty__illustration {
  display: block;
  width: 115px;
  height: 95px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/integrations.svg");
}

.team-integrations-header-icon-wrapper .btn {
  width: 150px;
}

.team-integrations-detail__header {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
}

.team-integrations-detail__header-text {
  margin-left: var(--spacing-l);
}
.team-integrations-detail__header-text--title {
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxxl);
}
.team-integrations-detail__header-text--description {
  font-style: normal;
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
}

.team-integrations-detail__service-icon-wrapper {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: var(--spacing-xs);
  align-items: center;
  border-radius: var(--border-radius-default);
  background: var(--background-color-secondary);
}

.team-integrations-detail__service-icon {
  height: auto;
  width: 100%;
}

.team-integrations-detail__fallback-icon {
  display: block;
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/integration-stroke.svg");
}

.team-integrations-breadcrumbs {
  padding: 0 var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}

.team-integrations-back-btn {
  color: var(--content-color-tertiary);
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.team-integrations-team-name {
  color: var(--content-color-tertiary);
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  word-break: break-all;
  padding-right: var(--spacing-s);
  cursor: pointer;
}

.team-integrations-integration-name {
  color: var(--content-color-tertiary);
  font-style: normal;
  font-weight: normal;
  padding-left: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.team-integrations-integration-page {
  color: var(--content-color-tertiary);
  font-style: normal;
  font-weight: normal;
  padding: 0 var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  cursor: pointer;
}

.team-integrations-detail__types-list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  width: 100%;
  display: inline-block;
}
.team-integrations-detail__types-list li {
  width: 100%;
  box-sizing: border-box;
  margin-left: var(--spacing-xl);
}
.team-integrations-detail__types-item {
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) solid var(--border-color-default);
  padding: var(--spacing-xl);
  margin: var(--spacing-l) 0;
}
@media (min-width: 768px) {
  .team-integrations-detail__types-item {
    padding: var(--spacing-xl);
  }
}
.team-integrations-detail__types-item-status {
  margin-bottom: var(--spacing-xl);
  cursor: pointer;
}
.team-integrations-detail__types-item-status .pm-icon-error path {
  fill: var(--content-color-error);
}
.team-integrations-detail__types-item-name-status {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.team-integrations-detail__types-item-name-link {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
  color: var(--content-color-primary);
  text-decoration: none;
  margin-bottom: var(--spacing-xl);
}
.team-integrations-detail__types-item-info {
  padding: 0;
}
.team-integrations-detail__types-item-info > h4 {
  margin: 0;
  font-weight: var(--text-weight-medium);
  display: inline-block;
  color: var(--content-color-brand);
}
.team-integrations-detail__types-item-info > p {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-l);
  margin: var(--spacing-s) 0 0 0;
}

.team-integrations-detail-actionables {
  margin: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.team-integrations-detail-actionables .integrations-sort-search-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  padding-bottom: var(--spacing-xs);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.team-integrations-detail-actionables .integrations-sort-search-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
@media (max-width: 1024px) {
  .team-integrations-detail-actionables .integrations-sort-search-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.team-integrations-detail-actionables .integrations-sort-search {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .team-integrations-detail-actionables .integrations-sort-search {
    flex-direction: column;
    padding-bottom: var(--spacing-l);
  }
  .team-integrations-detail-actionables .integrations-sort-search > .team-integrations-search {
    padding-bottom: var(--spacing-l);
  }
  .team-integrations-detail-actionables .integrations-sort-search > .team-integrations-filter {
    padding-bottom: var(--spacing-l);
  }
}
.team-integrations-detail-actionables .team-integrations-filter {
  width: 240px;
}
.team-integrations-detail-actionables .team-integrations-search {
  width: 240px;
}
.team-integrations-detail-actionables .team-integrations-search .input-search-group {
  background-color: var(--background-color-primary);
  border-color: var(--content-color-tertiary);
  border-radius: var(--border-radius-default);
  height: var(--size-m);
}
.team-integrations-detail-actionables .team-integrations-search .pm-form-control-sm {
  background: var(--background-color-secondary);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  border: var(--border-width-default) solid var(--border-color-strong);
  padding: var(--spacing-zero) var(--spacing-xxl) var(--spacing-zero) var(--spacing-xxl);
}
.team-integrations-detail-actionables .team-integrations-search .pm-form-control-sm {
  height: 40px;
}
.team-integrations-detail-actionables .team-integrations-search .pm-icon-input .pm-icon.pm-icon-sm {
  margin: var(--spacing-m) var(--spacing-s);
}

.team-integrations-detail-footer {
  display: flex;
  justify-content: center;
  padding: var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}

.team-integrations-sort-add {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-s);
  align-items: flex-end;
}
.team-integrations-sort-add .team-integrations-sort {
  width: 166px;
}

.team-integrations-detail__entity {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
}
.team-integrations-detail__entity-service {
  display: flex;
}
.team-integrations-detail__entity-service-link {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  text-decoration: none;
  color: var(--content-color-secondary);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
}
.team-integrations-detail__entity-service-link--name {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.team-integrations-detail__entity-service-link--name--error {
  color: var(--content-color-secondary);
  width: 180px;
  font-weight: var(--text-weight-regular);
}
.team-integrations-detail__entity-service-link--text {
  max-width: fit-content;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}
.team-integrations-detail__entity-service__icon {
  margin-right: var(--spacing-s);
  position: relative;
  vertical-align: text-top;
}

.team-integrations-detail__user {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  white-space: nowrap;
}
.team-integrations-detail__user.is-clickable {
  cursor: pointer;
}
.team-integrations-detail__user-icon {
  margin-right: var(--spacing-s);
  position: relative;
  vertical-align: text-top;
}
.team-integrations-detail__user-label {
  font-weight: var(--text-weight-medium);
  padding-left: var(--spacing-s);
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.team-integrations-detail__user-name {
  font-weight: var(--text-weight-medium);
  padding-left: 2px;
}

.team-integrations-detail__lastrun {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  white-space: nowrap;
}
.team-integrations-detail__lastrun__icon {
  margin-right: var(--spacing-s);
  position: relative;
  vertical-align: text-top;
}
.team-integrations-detail__lastrun-text {
  font-weight: var(--text-weight-medium);
  padding-left: 2px;
}

.team-integrations-error-state {
  border: none;
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.team-integrations-error-state__illustration-wrapper {
  margin-bottom: var(--spacing-l);
}
.team-integrations-error-state__title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-s);
}
.team-integrations-error-state__subtitle {
  margin-bottom: var(--spacing-xl);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
}
.team-integrations-error-state__title, .team-integrations-error-state__subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.team-integrations-error-state .offline__illustration {
  height: 120px;
  width: 120px;
}

.integration-entity-error-icon {
  margin-left: var(--spacing-s);
}
.integration-entity-error-icon i {
  vertical-align: middle;
  padding-bottom: 1%;
}

.integration-entity-error-tooltip {
  margin-bottom: var(--spacing-xs);
  max-width: 204px;
}

.loading-team-integrations {
  padding: calc(var(--spacing-xl) * 0.5) calc(var(--spacing-xl) * 0.5) var(--spacing-xxl) calc(var(--spacing-xl) * 0.5);
  overflow: hidden;
  background-color: var(--background-color-primary);
}
@media (min-width: 768px) {
  .loading-team-integrations {
    padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xxl) var(--spacing-xl);
  }
}
.loading-team-integrations__list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  display: flex;
  flex-wrap: wrap;
  animation: fade 1s ease-in-out infinite;
  padding: 0 0 var(--spacing-l) 0;
  padding-left: var(--spacing-m);
}
.loading-team-integrations__list li {
  margin-bottom: var(--spacing-l);
  display: inline-flex;
  flex-direction: column;
  border: solid thin var(--border-color-default);
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  width: 288px;
  margin: var(--spacing-m);
  height: 130px;
  padding: var(--spacing-xl);
}
.loading-team-integrations__list li:hover {
  border: 1px solid var(--border-color-strong);
}
.loading-team-integrations__service-icon-wrapper {
  height: 16px;
  width: 16px;
  border-radius: var(--border-radius-default);
  background: var(--background-color-secondary);
  position: relative;
  background-color: var(--background-color-tertiary);
}
.loading-team-integrations__service-icon {
  transform: translateX(-50%) translateY(-50%);
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: var(--background-color-tertiary);
  background: center/contain no-repeat;
}
.loading-team-integrations__service-name {
  background-color: var(--background-color-tertiary);
  height: 16px;
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-l);
}
.loading-team-integrations__rule-details {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.loading-team-integrations__rule-details .loading-team-integrations__service-details {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.loading-team-integrations__service-description {
  overflow: hidden;
  margin-bottom: var(--spacing-xxl);
  height: 24px;
  background-color: var(--background-color-tertiary);
}
.loading-team-integrations__status-item {
  height: 16px;
  background-color: var(--background-color-tertiary);
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.loading-team-integrations-in-detail__types-list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  animation: fade 1s ease-in-out infinite;
}
.loading-team-integrations-in-detail__types-list li {
  width: 100%;
  box-sizing: border-box;
  margin-left: var(--spacing-xl);
}
.loading-team-integrations-in-detail__types-list > li:first-child {
  margin-top: var(--spacing-l);
}
.loading-team-integrations-in-detail__types-item {
  border-radius: var(--border-radius-default);
  border: 1px solid var(--border-color-default);
  padding: var(--spacing-xl);
  margin: var(--spacing-l) 0;
}
@media (min-width: 768px) {
  .loading-team-integrations-in-detail__types-item {
    padding: var(--spacing-xl);
  }
}
.loading-team-integrations-in-detail__types-item-status {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xl);
  background-color: var(--background-color-tertiary);
  height: 20px;
}
.loading-team-integrations-in-detail__types-item-name-status {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.loading-team-integrations-in-detail__types-item-name-link {
  background-color: var(--background-color-tertiary);
  height: 28px;
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xl);
}
.loading-team-integrations-in-detail__types-item-info {
  padding: 0;
}
.loading-team-integrations-in-detail__types-item-info > h4 {
  margin: 0;
  display: inline-block;
}
.loading-team-integrations-in-detail__types-item-info > p {
  margin: var(--spacing-s) 0 0 0;
}
.loading-team-integrations-in-detail__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  padding: var(--spacing-l) var(--spacing-xl) var(--spacing-s) var(--spacing-xl);
}
.loading-team-integrations-in-detail__header-text {
  margin-left: var(--spacing-l);
  animation: fade 1s ease-in-out infinite;
}
.loading-team-integrations-in-detail__header-text--title {
  height: 32px;
  margin-bottom: var(--spacing-s);
  background-color: var(--background-color-tertiary);
}
.loading-team-integrations-in-detail__header-text--description {
  height: 16px;
  background-color: var(--background-color-tertiary);
}
.loading-team-integrations-in-detail__service-icon-wrapper {
  animation: fade 1s ease-in-out infinite;
  height: 64px;
  width: 64px;
  background-color: var(--background-color-tertiary);
  border-radius: var(--border-radius-default);
  position: relative;
}
.loading-team-integrations-in-detail__service-icon {
  animation: fade 1s ease-in-out infinite;
  background-color: var(--background-color-tertiary);
  transform: translateX(-50%) translateY(-50%);
  height: 64px;
  width: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: center/contain no-repeat;
}
.loading-team-integrations-in-detail__entity {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
}
.loading-team-integrations-in-detail__entity-service {
  display: flex;
}
.loading-team-integrations-in-detail__entity-service-link {
  background-color: var(--background-color-tertiary);
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 170px;
  margin-right: 20px;
}
.loading-team-integrations-in-detail__entity-service__icon {
  margin-right: var(--spacing-s);
  position: relative;
  vertical-align: text-top;
}
.loading-team-integrations-in-detail__user {
  display: flex;
  align-items: center;
  background-color: var(--background-color-tertiary);
  height: 28px;
}
.loading-team-integrations-in-detail__lastrun {
  display: flex;
  align-items: center;
  background-color: var(--background-color-tertiary);
  height: 20px;
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.loading-team-integrations-actionables {
  animation: fade 1s ease-in-out infinite;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--spacing-l) var(--spacing-xl) var(--spacing-zero) var(--spacing-xl);
}
.loading-team-integrations-actionables__search-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.loading-team-integrations-actionables__sort-add {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.loading-team-integrations-actionables__sort {
  height: 28px;
  background-color: var(--background-color-tertiary);
  margin-right: var(--spacing-l);
}
.loading-team-integrations-actionables__search {
  height: 28px;
  background-color: var(--background-color-tertiary);
  margin-right: var(--spacing-l);
}
.loading-team-integrations-actionables__filter {
  height: 28px;
  background-color: var(--background-color-tertiary);
}
.loading-team-integrations-actionables__add-integration {
  height: 28px;
  background-color: var(--background-color-tertiary);
}

.team-integrations-offline-state {
  border: none;
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.team-integrations-offline-state__illustration-wrapper {
  margin-bottom: 0px;
}
.team-integrations-offline-state__title {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-l);
}
.team-integrations-offline-state__subtitle {
  margin-bottom: var(--spacing-xl);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
}
.team-integrations-offline-state__title, .team-integrations-offline-state__subtitle {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.team-integrations-offline-state .offline__illustration {
  height: 120px;
  width: 120px;
}

@media (min-width: 1280px) {
  .pm-container.integrations-container {
    max-width: 100%;
  }
}
.browse-integration-categories-sidebar {
  position: sticky;
  top: 0;
  width: 270px;
  height: 100vh;
  float: left;
  overflow-y: auto;
  padding: var(--spacing-zero) var(--spacing-xl) 0 0;
  text-transform: capitalize;
}
.browse-integration-categories-sidebar__back-button {
  margin-bottom: var(--spacing-l);
}
.browse-integration-categories-sidebar__back-button:hover {
  background-color: transparent;
}
.browse-integration-categories-sidebar__list-wrapper {
  padding: 0 var(--spacing-s);
}
.browse-integration-categories-sidebar__list {
  list-style-type: none;
  padding: 0;
  margin-top: var(--spacing-l);
  cursor: pointer;
}
.browse-integration-categories-sidebar__list > .browse-integration-categories-sidebar__list--item:not(:last-child) {
  margin-bottom: var(--spacing-s);
}
.browse-integration-categories-sidebar__list--item {
  height: var(--size-m);
  line-height: var(--line-height-xxl);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  padding: 0px var(--spacing-s);
  color: var(--content-color-primary);
}
.browse-integration-categories-sidebar__list--item-is-selected {
  background: var(--highlight-background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.browse-integration-categories-sidebar__list--item:hover {
  background: var(--highlight-background-color-tertiary);
  border-radius: var(--border-radius-default);
}
.browse-integration-categories-sidebar__list--item .name {
  float: left;
}
.browse-integration-categories-sidebar__list--item .count {
  float: right;
}

.browse-integrations-page {
  width: 1280px;
  margin: auto;
}
.browse-integrations-page .input-search-group {
  margin-bottom: var(--spacing-l);
  max-width: 275px;
  min-width: 230px;
  border-radius: var(--border-radius-default);
  height: var(--size-m);
}
.browse-integrations-page-heading {
  font-size: var(--text-size-xxxl);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-xxxl);
  color: var(--content-color-primary);
  padding-left: var(--spacing-s);
}

.browse-integrations__list {
  overflow: hidden;
  padding: var(--spacing-zero) var(--spacing-xxl) var(--spacing-l) var(--spacing-xxl);
  position: relative;
}
.browse-integrations__list--description {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
  margin-bottom: var(--spacing-xxl);
  padding: 0 var(--spacing-s);
}

/**
  * Breakpoint-aware visibility
  */
@media (max-width: 600px) {
  .visible-desktop-block {
    display: none;
  }
}

.integrations-cards__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.integrations-cards__list > .integrations-cards__list-item {
  margin-right: var(--spacing-xl);
}
.integrations-cards__list-item {
  display: flex;
  margin-bottom: var(--spacing-xl);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  cursor: pointer;
  padding: var(--spacing-l);
  width: 240px;
  min-height: 160px;
}
.integrations-cards__list-item--heading-wrapper, .integrations-cards__list-item--heading {
  display: flex;
}
.integrations-cards__list-item--heading-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: var(--spacing-s);
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.integrations-cards__list-item--heading {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.integrations-cards__list-item--icon-container {
  height: 48px;
  width: 48px;
  padding: var(--spacing-xs);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  background: var(--background-color-secondary);
  margin-bottom: var(--spacing-xs);
  display: flex;
  justify-content: center;
  align-items: center;
}
.integrations-cards__list-item--icon {
  height: auto;
  width: 100%;
}
.integrations-cards__list-item--fallback-icon {
  display: block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/integration-stroke.svg");
}
.integrations-cards__list-item--badge {
  border-radius: 12px;
  height: var(--spacing-l);
  padding: 0px var(--spacing-s);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
  background-color: var(--background-color-info);
  color: var(--content-color-info);
  text-transform: capitalize;
}
.integrations-cards__list-item--badge-noteworthy {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
}
.integrations-cards__list-item--badge-new {
  background-color: var(--background-color-info);
  color: var(--content-color-info);
}
.integrations-cards__list-item--desc {
  text-align: left;
  color: var(--content-color-secondary);
}
.integrations-cards__list-item:hover {
  border: 1px solid var(--border-color-strong);
}

.request-integration {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--spacing-l);
  margin: auto;
  max-width: 718px;
  text-align: left;
}
.request-integration__error-dog {
  width: 175px;
  height: 185px;
}
.request-integration__image {
  max-width: var(--es-image-width);
  margin-bottom: var(--spacing-xl);
}
.request-integration__title {
  color: var(--content-color-primary);
}
.request-integration__body {
  line-height: var(--line-height-xs);
  color: var(--content-color-primary);
  margin: var(--spacing-l) auto var(--spacing-xxl);
}
.request-integration__action {
  margin-bottom: var(--spacing-xl);
  padding-top: var(--spacing-l);
  color: var(--content-color-link);
  font-weight: var(--text-weight-medium);
}
.request-integration__action .external-link__icon svg {
  height: var(--spacing-m);
  width: var(--spacing-m);
  margin-left: var(--spacing-xs);
  color: var(--content-color-link);
}
.request-integration__description {
  padding: var(--pacing-xxl) 0;
  display: flex;
  flex-direction: column;
}
.request-integration__crash-handler {
  margin-top: 200px;
}
.request-integration__crash-handler .empty-state__body {
  max-width: 500px;
}
.request-integration .pm-link {
  color: var(--content-color-link);
}

.loading-integrations-cards__list {
  animation: fade 1s ease-in-out infinite;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.loading-integrations-cards__list > .loading-integrations-cards__list-item:not(:last-child) {
  margin-right: var(--spacing-xl);
}
.loading-integrations-cards__list-item {
  display: flex;
  margin-bottom: var(--spacing-xl);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-l);
  width: 240px;
  min-height: 160px;
}
.loading-integrations-cards__list-item--heading-wrapper, .loading-integrations-cards__list-item--heading {
  display: flex;
}
.loading-integrations-cards__list-item--heading-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: var(--spacing-s);
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.loading-integrations-cards__list-item--heading {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.loading-integrations-cards__list-item--icon-container {
  height: 48px;
  width: 48px;
  border-radius: var(--border-radius-default);
  background: var(--background-color-secondary);
  position: relative;
  margin-bottom: var(--spacing-xs);
}
.loading-integrations-cards__list-item--icon {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
  background: center/contain no-repeat;
}
.loading-integrations-cards__list-item--desc {
  text-align: left;
  color: var(--content-color-secondary);
}
.loading-integrations-cards__list-item--desc-line1, .loading-integrations-cards__list-item--desc-line2, .loading-integrations-cards__list-item--desc-line3 {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-m);
  height: 12px;
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.loading-integration-categories-sidebar {
  animation: fade 1s ease-in-out infinite;
  position: sticky;
  top: 0;
  width: 270px;
  height: 100vh;
  float: left;
  overflow-y: auto;
  padding: var(--spacing-m) var(--spacing-xl) 0 0;
  text-transform: capitalize;
}
.loading-integration-categories-sidebar__list-wrapper {
  padding: 0 var(--spacing-s);
}
.loading-integration-categories-sidebar__list-wrapper-heading {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-m);
  height: 24px;
}
.loading-integration-categories-sidebar__list {
  list-style-type: none;
  padding: 0;
  margin-top: var(--spacing-xxl);
}
.loading-integration-categories-sidebar__list > .loading-integration-categories-sidebar__list--item:not(:last-child) {
  margin-bottom: var(--spacing-xl);
}
.loading-integration-categories-sidebar__list--item {
  padding: 0px var(--spacing-s);
  background-color: var(--background-color-tertiary);
  height: 28px;
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.loading-browse-integrations-page {
  width: 1280px;
  margin: auto;
}
.loading-browse-integrations-page .input-search-group {
  margin-bottom: var(--spacing-xl);
  width: 215px;
  border-radius: var(--border-radius-default);
  height: var(--size-m);
}
.loading-browse-integrations-page-heading {
  animation: fade 1s ease-in-out infinite;
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-xxxl);
  height: 28px;
  margin-left: var(--spacing-s);
}

.loading-browse-integrations__list {
  animation: fade 1s ease-in-out infinite;
  overflow: hidden;
  padding: var(--spacing-xl) var(--spacing-xxl) var(--spacing-l) var(--spacing-xxl);
  position: relative;
}
.loading-browse-integrations__list--text {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-l);
  height: 28px;
}
.loading-browse-integrations__list--search {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-l);
  height: 28px;
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.integrations-browse-view-breadcrumbs {
  padding: 0 var(--spacing-s);
  margin-bottom: var(--spacing-l);
}
.integrations-browse-view-breadcrumbs__team-name {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  word-break: break-all;
  padding-right: var(--spacing-s);
  cursor: pointer;
}
.integrations-browse-view-breadcrumbs__divider {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-browse-view-breadcrumbs__integration-page {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  padding: 0 var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  cursor: pointer;
}
.integrations-browse-view-breadcrumbs__text {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  padding-left: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.integration-service__title-available {
  margin-top: 0px;
  margin-bottom: var(--spacing-xs);
}
.integration-service__overview {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .integration-service__overview {
    width: 288px;
    margin-right: var(--spacing-xl);
  }
}
.integration-service__overview hr {
  margin: var(--spacing-l) 0;
}
.integration-service__detailed-view {
  margin-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
  min-height: 640px;
}
@media (min-width: 768px) {
  .integration-service__detailed-view {
    margin-top: 0;
    padding-bottom: initial;
  }
}
.integration-service__details-wrapper {
  padding: var(--spacing-l) 0 var(--spacing-l) var(--spacing-zero);
}
.integration-service__details-wrapper__show-hide-details {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-secondary);
  text-decoration: underline;
}
.integration-service__details-wrapper .btn {
  padding: 0;
}
.integration-service__details-wrapper .btn:hover {
  color: var(--content-color-secondary);
}
.integration-service__icon-wrapper {
  height: 32px;
  width: 32px;
  position: absolute;
  top: var(--spacing-l);
  left: var(--spacing-l);
  border: 1px solid var(--border-color-default);
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-l);
}
@media (min-width: 768px) {
  .integration-service__icon-wrapper {
    height: 120px;
    width: 120px;
    padding: var(--spacing-xl);
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.integration-service__icon {
  width: 32px;
  height: 32px;
  background: center/contain no-repeat;
}
@media (min-width: 768px) {
  .integration-service__icon {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  .integration-service__fallback-icon {
    display: block;
    width: 72px;
    height: 72px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    background-image: url("../assets/images/thumbnails/integration-stroke.svg");
  }
}
.integration-service__name {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
  margin: var(--spacing-xl);
  margin-left: 40px;
}
@media (min-width: 768px) {
  .integration-service__name {
    margin: 0px;
  }
}
.integration-service__description {
  font-size: var(--text-size-m);
  color: var(--grey-60);
  margin: var(--spacing-xs) 0 0 40px;
  line-height: var(--line-height-s);
}
@media (min-width: 768px) {
  .integration-service__description {
    margin: var(--spacing-s) 0 var(--spacing-l);
  }
}
.integration-service__extended-description {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-secondary);
}
.integration-service__extended-description p a {
  color: var(--content-color-secondary);
}
.integration-service__actions {
  margin: 0;
  padding: 0;
}
.integration-service__actions .pm-btn {
  margin: 0 var(--spacing-s) var(--spacing-s) 0;
}
.integration-service__metadata {
  position: relative;
  min-height: 36px;
  line-height: 36px;
}
.integration-service__metadata .pm-icon {
  top: 18px;
  left: 15px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}
.integration-service__metadata .pm-icon svg {
  position: inherit;
  color: var(--grey-40);
}
.integration-service__metadata .pm-icon path {
  fill: var(--grey-40);
}
.integration-service__metadata--categories ul {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.integration-service__metadata--categories li {
  display: inline-block;
  margin-right: var(--spacing-s);
}
.integration-service__metadata--categories li .pm-badge span {
  color: var(--grey-60);
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: none;
  font-weight: var(--text-weight-medium);
  font-style: normal;
  font-size: var(--text-size-xs);
  line-height: var(--line-height-xs);
}
.integration-service__metadata--status {
  font-size: var(--text-size-s);
  color: var(--grey-80);
}
.integration-service__types-list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.integration-service__types-list > li:first-child {
  padding-top: 20px;
}
.integration-service__types-list > li:last-child {
  border-bottom: none;
}
.integration-service__types-list li {
  width: 100%;
}
.integration-service__types-item {
  border-radius: var(--border-radius-s);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  padding: var(--spacing-l) 0;
}
.integration-service__types-item__status-summary {
  clear: both;
  padding-top: var(--spacing-l);
}
.integration-service__types-item__status-summary--link {
  text-decoration: none;
}
.integration-service__types-item__configured {
  clear: both;
  padding-top: var(--spacing-m);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integration-service__types-item__configured-integrations-loading {
  display: flex;
  flex-direction: row;
  color: var(--content-color-tertiary);
}
.integration-service__types-item__configured-integrations-loading .loading-indicator-wrapper {
  margin-left: var(--spacing-m);
  padding-right: var(--spacing-m);
}
.integration-service__types-item__configured .link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--content-color-secondary);
}
@media (min-width: 768px) {
  .integration-service__types-item {
    padding: var(--spacing-l) 0;
  }
}
.integration-service__types-item-info {
  padding: 0;
}
.integration-service__types-item-actions {
  padding: 0;
  margin-top: var(--spacing-s);
}
.integration-service__types-item-actions .btn-secondary {
  height: 32px;
  padding: 8px 12px;
}
.integration-service__types-item-actions .btn-secondary.is-disabled {
  opacity: 0.6;
}
@media (min-width: 768px) {
  .integration-service__types-item-actions {
    text-align: right;
    margin-top: 0;
  }
}
.integration-service__types-item-actionables {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.integration-service__types-item-actionables-description {
  display: flex;
  flex-direction: column;
  flex-basis: 65%;
}

.integration-service__metadata .search-result-badge {
  margin-left: 0;
}

.integration-service__extended-description a {
  text-decoration: underline;
}

.integrations-service--badge {
  margin: var(--spacing-xl) 0 var(--spacing-s) var(--spacing-zero);
  height: var(--spacing-l);
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
  font-weight: var(--text-weight-medium);
  text-transform: capitalize;
}
.integrations-service--badge-noteworthy {
  background-color: var(--background-color-warning);
  color: var(--content-color-warning);
  border-radius: 12px;
  padding: 0 var(--spacing-s);
}
.integrations-service--badge-new {
  background-color: var(--background-color-info);
  color: var(--content-color-info);
  border-radius: 12px;
  padding: 0 var(--spacing-s);
}

.integration-service-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.integration-service-details-page {
  display: flex;
  flex-direction: column;
  width: 960px;
  padding: 0 24px;
  box-sizing: border-box;
}

.integrations-service-details-breadcrumbs {
  margin-bottom: var(--spacing-l);
}
.integrations-service-details-breadcrumbs__team-name {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  word-break: break-all;
  padding-right: var(--spacing-s);
  cursor: pointer;
}
.integrations-service-details-breadcrumbs__divider {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-service-details-breadcrumbs__integration-page {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  padding: 0 var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  cursor: pointer;
}
.integrations-service-details-breadcrumbs__browse {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  padding: 0 var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  cursor: pointer;
}
.integrations-service-details-breadcrumbs__service-text {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  padding-left: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.error-loading-configured-integrations__text-error {
  padding-left: var(--spacing-xs);
  color: var(--content-color-error);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.error-loading-configured-integrations__text-error-retry {
  color: var(--content-color-error);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
  cursor: pointer;
  text-decoration: underline;
}
.error-loading-configured-integrations__text-error-retry.is-disabled {
  cursor: not-allowed;
}

.offline-state-configured-integrations__text-error {
  padding-left: var(--spacing-xs);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}

.loading-integration-service__overview {
  display: flex;
  flex-direction: column;
  animation: fade 1s ease-in-out infinite;
}
@media (min-width: 768px) {
  .loading-integration-service__overview {
    width: 288px;
    margin-right: var(--spacing-xl);
  }
}
.loading-integration-service__detailed-view {
  margin-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
  min-height: 640px;
  animation: fade 1s ease-in-out infinite;
}
@media (min-width: 768px) {
  .loading-integration-service__detailed-view {
    margin-top: 0;
    padding-bottom: initial;
  }
}
.loading-integration-service__detailed-view-heading {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-m);
  height: 32px;
}
.loading-integration-service__details-wrapper {
  padding: var(--spacing-xl) 0 var(--spacing-l) var(--spacing-zero);
}
.loading-integration-service__icon-wrapper {
  height: 32px;
  width: 32px;
  position: absolute;
  top: var(--spacing-l);
  left: var(--spacing-l);
  border: 1px solid var(--border-color-default);
  box-sizing: border-box;
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-l);
}
@media (min-width: 768px) {
  .loading-integration-service__icon-wrapper {
    height: 120px;
    width: 120px;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.loading-integration-service__icon {
  width: 100%;
  height: auto;
  background-color: var(--background-color-tertiary);
}
@media (min-width: 768px) {
  .loading-integration-service__icon {
    width: 100%;
    height: auto;
  }
}
.loading-integration-service__name {
  margin-bottom: var(--spacing-l);
  background-color: var(--background-color-tertiary);
  height: 32px;
}
@media (min-width: 768px) {
  .loading-integration-service__name {
    margin: 0px;
  }
}
.loading-integration-service__description {
  margin: var(--spacing-xs) 0 0 40px;
  line-height: var(--line-height-s);
}
@media (min-width: 768px) {
  .loading-integration-service__description {
    margin: var(--spacing-s) 0 var(--spacing-l);
  }
}
.loading-integration-service__extended-description-line-1, .loading-integration-service__extended-description-line-2, .loading-integration-service__extended-description-line-3 {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-m);
  height: 12px;
}
.loading-integration-service__actions {
  margin: 0;
  padding: 0;
}
.loading-integration-service__metadata {
  position: relative;
  min-height: 36px;
  line-height: 36px;
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-xl);
}
.loading-integration-service__metadata--categories-text {
  background-color: var(--background-color-tertiary);
  height: 16px;
}
.loading-integration-service__types-list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  animation: fade 1s ease-in-out infinite;
}
.loading-integration-service__types-list > li:first-child {
  padding-top: 20px;
}
.loading-integration-service__types-list > li:last-child {
  border-bottom: none;
}
.loading-integration-service__types-list li {
  width: 100%;
}
.loading-integration-service__types-item {
  border-radius: var(--border-radius-s);
  border-bottom: 1px solid var(--grey-10);
  padding: var(--spacing-l) 0;
}
.loading-integration-service__types-item__status-summary {
  clear: both;
  padding-top: var(--spacing-l);
}
.loading-integration-service__types-item__status-summary--link {
  text-decoration: none;
}
.loading-integration-service__types-item__configured {
  clear: both;
  line-height: var(--line-height-m);
  background-color: var(--background-color-tertiary);
  height: 20px;
  margin-top: var(--spacing-m);
}
@media (min-width: 768px) {
  .loading-integration-service__types-item {
    padding: var(--spacing-l) 0;
  }
}
.loading-integration-service__types-item-info {
  padding: 0;
}
.loading-integration-service__types-item-actions {
  padding: 0;
  margin-top: var(--spacing-s);
  background-color: var(--background-color-tertiary);
  height: 32px;
}
.loading-integration-service__types-item-actions .btn-secondary {
  height: 32px;
  padding: var(--spacing-s) var(--spacing-m);
}
@media (min-width: 768px) {
  .loading-integration-service__types-item-actions {
    text-align: right;
    margin-top: 0;
  }
}
.loading-integration-service__types-item-actionables {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loading-integration-service__types-item-actionables-description {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
}
.loading-integration-service__types-item-actionables-description-heading {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-m);
  height: 16px;
}
.loading-integration-service__types-item-actionables-description-body {
  background-color: var(--background-color-tertiary);
  margin-bottom: var(--spacing-m);
  height: 16px;
}

.integration-service__metadata.search-result-badge {
  margin-left: 0;
}

.add-integrations-wrapper {
  width: 440px;
}

.integration-add-actions-separator {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}

.integrations-add-card__segment {
  display: flex;
  flex-direction: column;
}
.integrations-add-card__label-title {
  color: var(--content-color-primary);
  font-style: normal;
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.integrations-add-card__label-title-v2 {
  font-weight: var(--text-weight-medium);
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.integrations-add-card__label-description {
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.integrations-add-card__input {
  flex: 1;
  padding-top: var(--spacing-s);
}
.integrations-add-card .pm-link {
  text-decoration: underline;
  color: var(--content-color-secondary);
}
.integrations-add-card .pm-link:hover {
  color: var(--content-color-secondary);
}

.integrations-add-actions__button--container {
  margin-top: var(--spacing-xl);
  float: right;
  padding-bottom: var(--spacing-xxxl);
}
.integrations-add-actions__button--cancel {
  border-radius: var(--border-radius-default);
}
.integrations-add-actions__button--save-changes {
  margin-right: var(--spacing-s);
  border-radius: var(--border-radius-default);
}

.align-left {
  float: left;
}

.aws-gateway__button > .integrations-add-actions__button--container {
  margin-right: var(--spacing-m);
  margin-top: var(--spacing-xxl);
  float: left;
}

.api-version-add-actions__button--container {
  margin-top: var(--spacing-xl);
  float: right;
  padding-bottom: var(--spacing-xl);
}
.api-version-add-actions__button--cancel {
  border-radius: var(--border-radius-default);
  margin-right: var(--spacing-s);
}
.api-version-add-actions__button--save-changes {
  border-radius: var(--border-radius-default);
}

.integration-page-banner a {
  text-decoration: underline;
  color: var(--content-color-primary);
}
.integration-page-banner .alert-info {
  background-color: var(--background-color-info);
}
.integration-page-banner .alert-info .alert-content .alert-title {
  font-size: var(--text-size-m);
}

.integrations-add-actions__button--container {
  margin-top: var(--spacing-xl);
  float: right;
  padding-bottom: var(--spacing-xxxl);
}
.integrations-add-actions__button--cancel {
  border-radius: var(--border-radius-default);
  margin-right: var(--spacing-s);
}
.integrations-add-actions__button--proceed {
  border-radius: var(--border-radius-default);
}

.align-left {
  float: left;
}

.integration-add-actions-separator {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-s);
}

.integration-add-consent {
  margin-bottom: var(--spacing-xxl);
}

.integrations-add {
  width: 440px;
}

.integrations-form__segment {
  margin-top: var(--spacing-l);
}
.integrations-form__segment__label-title {
  color: var(--content-color-primary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-form__segment__label-title span {
  color: var(--content-color-secondary);
}
.integrations-form__segment__label-description {
  margin-top: 0;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-form__segment__label-description p {
  display: inline;
}
.integrations-form__segment__input {
  flex: 1;
  padding-top: var(--spacing-s);
}
.integrations-form__segment__input__error {
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  margin-top: var(--spacing-xs);
}
.integrations-form__segment__input__error__btn {
  text-decoration: underline;
  cursor: pointer;
}
.integrations-form__segment__input__helper {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xs);
}
.integrations-form__segment__input__helper__btn {
  text-decoration: none;
  color: var(--content-color-info);
  cursor: pointer;
}
.integrations-form-actions__button--container {
  margin-top: var(--spacing-xl);
  float: right;
  padding-bottom: var(--spacing-xxxl);
}
.integrations-form-actions__button--cancel {
  border-radius: var(--border-radius-default);
  margin-right: var(--spacing-s);
}
.integrations-form-actions__button--proceed {
  border-radius: var(--border-radius-default);
}

.integrations-add-card {
  width: 100%;
  height: 100%;
}
.integrations-add-card__label-title {
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  font-weight: 600;
  line-height: var(--line-height-s);
}
.integrations-add-card__label-title span {
  color: var(--content-color-secondary);
}
.integrations-add-card__segment {
  margin-top: var(--spacing-l);
}
.integrations-add-card__alert {
  margin-bottom: 24px;
}
.integrations-add-card__label-description {
  margin-top: 0;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.integrations-add-card__label-description-external-link {
  margin-top: 0;
  display: flex;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-s);
  line-height: var(--line-height-s);
}
.integrations-add-card__external-link {
  display: flex;
  align-items: center;
  color: var(--content-color-primary);
}
.integrations-add-card__description {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-xl);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.integrations-add-card__input {
  flex: 1;
  padding-top: var(--spacing-s);
}
.integrations-add-card__error {
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  margin-top: var(--spacing-xs);
}
.integrations-add-card__error__btn {
  text-decoration: underline;
  cursor: pointer;
}
.integrations-add-card .loading-placeholder {
  justify-content: space-between;
  align-items: center;
}
.integrations-add-card .pm-link {
  text-decoration: underline;
  color: var(--content-color-secondary);
}
.integrations-add-card .pm-link:hover {
  color: var(--content-color-secondary);
}
.integrations-add-card__advanced {
  color: var(--orange-40);
  margin: var(--spacing-s) 0;
  cursor: pointer;
}
.integrations-add-card__advanced svg {
  color: var(--orange-40);
}
.integrations-add-card__select {
  width: 100%;
}
.integrations-add-card__name {
  text-align: left;
}
.integrations-add-card-actions {
  margin-top: var(--spacing-l);
}
.integrations-add-card-actions__cancel {
  width: 96px;
  float: left;
}
.integrations-add-card__authed-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--spacing-xl);
  font-size: var(--text-size-m);
  margin-right: 8px;
}
.integrations-add-card__authed-user__text {
  margin-right: 8px;
}
.integrations-add-card__authed-user___name {
  color: var(--content-color-secondary);
}
.integrations-add-card__authed-user p {
  font-weight: 600;
}
.integrations-add-card__authed-user img {
  border-radius: 50%;
  width: var(--spacing-xxl);
  margin-right: var(--spacing-xs);
}
.integrations-add-card__authed-user span {
  font-size: var(--font-size-m);
  color: var(--content-color-secondary);
}
.integrations-add-card__fail-alert {
  margin: var(--spacing-l) 0;
}
.integrations-add-card__workspace-switcher-icon {
  margin-right: var(--spacing-l);
  vertical-align: sub;
}
@media (max-width: 480px) {
  .integrations-add-card__workspace-switcher-icon {
    visibility: hidden;
  }
}
.integrations-add-card__auth-tabs {
  display: flex;
  justify-content: space-between;
  background-color: var(--background-color-primary);
  padding: 0px var(--spacing-s) var(--spacing-zero) var(--spacing-zero);
  border-bottom: var(--border-width-default) solid var(--border-color-default);
  min-width: 440px;
}
.integrations-add-card__auth-tabs-tab-title {
  display: flex;
  align-items: center;
}
.integrations-add-card__auth-tabs-tab-title__label {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.integrations-add-card__auth-tabs-tab.tab--authenticate.tab-primary .tab-text-wrapper {
  margin-left: 0px;
}
.integrations-add-card__auth-tabs .tab-primary.is-active .tab-text-wrapper {
  box-shadow: inset 0px -1px 0px var(--content-color-primary);
}
.integrations-add-card__auth-tabs .is-active .integrations-add-card__auth-tabs-tab-title__label {
  color: var(--content-color-primary);
}
.integrations-add-card__auth-tabs .integrations-add-card__auth-tabs-tab-title__label {
  display: flex;
}
.integrations-add-card__auth-tabs .integrations-add-card__authenticated-icon {
  margin-left: var(--spacing-xs);
}

.integrations-add .tab-primary .tab-text-wrapper {
  margin: var(--spacing-zero) var(--spacing-m);
}

.integrations-radio-button__select-group--regions {
  margin-bottom: var(--spacing-s);
  font-size: 12px;
}

.integrations-radio-button__select-group--options {
  margin-bottom: var(--spacing-s);
  font-size: 12px;
}
.integrations-radio-button__select-group--options span {
  margin-left: 4px;
}

.integrations-radio-button__select-group--regions__radio {
  margin-right: var(--spacing-xs);
}

.integrations-consent {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
  margin-bottom: 16px;
}

.custom-owner-input-field {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
}
.custom-owner-input-field .pm-link {
  text-decoration: underline;
  color: var(--content-color-secondary);
}

.integrations-checkbox {
  margin-left: 4px;
}
.integrations-checkbox__select-consent {
  display: flex;
  flex: 1;
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
  margin-bottom: 16px;
  font-size: var(--text-size-m);
}
.integrations-checkbox__select-consent .input-checkbox {
  margin-right: var(--spacing-s);
}
.integrations-checkbox__select-consent .input-checkbox.is-selected {
  background-color: var(--orange-40);
  border-color: var(--orange-40);
}

.integrations-checkbox__select-group--events {
  display: flex;
  flex: 1;
  color: var(--content-color-primary);
  margin-top: var(--spacing-s);
  font-size: var(--text-size-m);
}
.integrations-checkbox__select-group--events .input-checkbox {
  margin-right: var(--spacing-s);
}
.integrations-checkbox__select-group--events .input-checkbox.is-selected {
  background-color: var(--orange-40);
  border-color: var(--orange-40);
}

.integrations-checkbox__select-group {
  margin-bottom: 32px;
}
.integrations-checkbox__select-group--actions {
  display: flex;
  flex: 1;
  color: var(--content-color-primary);
  margin-top: var(--spacing-m);
  font-size: var(--text-size-m);
}
.integrations-checkbox__select-group--actions .input-checkbox {
  margin-right: var(--spacing-s);
}
.integrations-checkbox__select-group--actions .input-checkbox.is-selected {
  background-color: var(--orange-40);
  border-color: var(--orange-40);
}
.integrations-checkbox__select-group--actions .checkbox__helper-text {
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xs);
}
.integrations-checkbox__select-group--label-title {
  color: var(--content-color-primary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-top: var(--spacing-l);
}

.push-double--bottom {
  margin-bottom: 32px !important;
}
.push-double--bottom__add-to-team {
  margin-bottom: var(--spacing-l) !important;
}

.custom-apigateway__input {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
}

.add-new-relic {
  margin-bottom: var(--spacing-xxl);
  margin-top: var(--spacing-l);
}

.integrations-add-card__input .input.input-box.is-disabled {
  color: var(--content-color-secondary);
  cursor: not-allowed;
  opacity: 0.4;
}
.integrations-add-card__input .hide-toggle .password-input__toggle {
  visibility: hidden;
}

.integrations-tags-segment-input__container {
  padding: 0px;
  position: relative;
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  display: flex;
  min-height: var(--controls-size-default);
  max-height: 80px;
  overflow-y: auto;
  align-items: stretch;
  background-color: var(--background-color-secondary);
}

.integrations-tags-segment-input__container:hover {
  background-color: var(--background-color-tertiary);
  cursor: text;
}

.integrations-tags-segment-input__container:focus-within {
  background-color: var(--background-color-primary);
}

.integrations-tags-segment-active__input {
  padding-left: 10px;
  padding-right: 20px;
  font-size: var(--text-size-m);
}

.integrations-tags-segment-text__content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: var(--spacing-s);
}

.integrations-tags-segment-editable__tag {
  height: 20px;
  border-radius: var(--border-radius-default);
  margin: var(--spacing-xs);
  padding-left: var(--spacing-xs);
  padding-right: var(--spacing-xs);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}
.integrations-tags-segment-editable__tag .text:hover {
  cursor: text;
}
.integrations-tags-segment-editable__tag .pm-icon {
  margin-right: var(--spacing-s);
}
.integrations-tags-segment-editable__tag .tag-remove-btn:hover {
  cursor: pointer;
}
.integrations-tags-segment-editable__tag-regular {
  background-color: var(--background-color-secondary);
  border: solid 1px var(--border-color-default);
}
.integrations-tags-segment-editable__tag-error {
  border: 1px dashed var(--base-color-error);
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.integrations-tags-segment-editable__tag.is-selected {
  border: 1px solid var(--base-color-info);
}

.integrations__external-link {
  color: var(--content-color-secondary);
  text-decoration: underline;
}
.integrations__external-link:hover {
  color: var(--content-color-link);
  text-decoration: underline;
}
.integrations__external-link:hover svg {
  color: var(--content-color-link);
}
.integrations-form__disclosed {
  padding-left: 28px;
}

.integrations-banner {
  height: 92px;
  padding-top: var(--spacing-l);
  font-size: var(--text-size-m);
}
.integrations-banner__container {
  display: flex;
  background-color: var(--background-color-info);
  padding: var(--spacing-l);
}
.integrations-banner__text-container {
  margin-left: var(--spacing-s);
}
.integrations-banner__heading {
  font-weight: var(--text-weight-medium);
  size: var(--text-size-m);
  line-height: var(--line-height-s);
  margin-bottom: var(--spacing-xs);
}
.integrations-banner__text {
  line-height: var(--line-height-m);
}
.integrations-banner__close-btn {
  float: right;
  margin-left: auto;
  cursor: pointer;
}

.integrations-add-step {
  margin-top: var(--spacing-xl);
}
.integrations-add-step__title {
  font-weight: var(--text-weight-medium);
  size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-bottom: var(--spacing-s);
}
.integrations-add-step__text {
  line-height: var(--line-height-m);
  font-size: var(--text-size-m);
}

.integrations-info-segment {
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-info-segment__title {
  font-weight: var(--text-weight-medium);
}
.integrations-info-segment__description {
  line-height: var(--line-height-s);
  font-size: var(--text-size-s);
}
.integrations-info-segment__value-container {
  display: flex;
}
.integrations-info-segment__value-segment {
  margin-top: var(--spacing-s);
}
.integrations-info-segment__value {
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-xxl);
}
.integrations-info-segment__copy-btn {
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-xs);
  width: var(--size-m);
  height: var(--size-m);
  padding: var(--spacing-zero);
  cursor: pointer;
}
.integrations-info-segment__copy-btn-container {
  margin: var(--spacing-s);
}
.integrations-info-segment--account-id > .integrations-info-segment {
  margin-top: var(--spacing-m);
}

.integrations-field-segment__role-arn > .integrations-add-card__segment {
  margin-top: var(--spacing-m);
}

.integrations-connected-account__form__header {
  margin-top: var(--spacing-m);
  color: var(--content-color-primary);
}
.integrations-connected-account__form__header__primary-text {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.integrations-connected-account__form__header__helper {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-xs);
}
.integrations-connected-account__form__header__helper__btn {
  text-decoration: none;
  color: var(--content-color-info);
  cursor: pointer;
}
.integrations-connected-account__form__header__btn {
  margin-left: var(--spacing-l);
}

.connected-accounts--user-name {
  max-width: 150px;
}
.connected-accounts--create-installed-app {
  z-index: 10;
  position: fixed;
  background-color: var(--background-color-primary);
  width: 100%;
  height: 100%;
  top: var(--spacing-xxxl) !important;
  left: var(--spacing-zero) !important;
}
.connected-accounts--create-installed-app--form {
  min-width: 480px !important;
  width: 35% !important;
  margin-top: var(--spacing-xxl);
}
.connected-accounts--update-installed-app {
  z-index: 10;
  position: fixed;
  background-color: var(--background-color-primary);
  width: 100%;
  height: 100%;
  top: var(--spacing-xxxl) !important;
  left: var(--spacing-zero) !important;
}
.connected-accounts--update-installed-app--form {
  min-width: 480px !important;
  width: 35% !important;
  margin-top: var(--spacing-xxl);
}
.connected-accounts--delete-modal--banner {
  margin-top: var(--spacing-m);
}
.connected-accounts--spinner {
  width: 100%;
  height: 100% !important;
}
.connected-accounts--blank-state-wrapper {
  margin-left: calc(var(--spacing-xl) + var(--spacing-xs));
}
.connected-accounts--collapse-wrapper {
  cursor: pointer;
  margin-bottom: var(--spacing-m) !important;
}
.connected-accounts--collapse-icon {
  margin-right: var(--spacing-m) !important;
}
.connected-accounts--card {
  cursor: pointer;
  width: 288px;
  height: 184px;
}
.connected-accounts--card-github {
  margin-top: var(--spacing-xl);
}
.connected-accounts .is-disabled.is-disabled {
  cursor: default;
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.connected-accounts--icon rect {
  fill: var(--background-color-tertiary);
}
.connected-accounts--icon svg {
  border-radius: var(--border-radius-s);
  transform: scale(2.25);
  margin-left: var(--spacing-xxl);
  margin-bottom: var(--spacing-l);
  margin-top: var(--spacing-xxl);
  background-color: var(--background-color-tertiary);
  padding: var(--spacing-xs);
}
.connected-accounts--app-name {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-l);
  margin-left: var(--spacing-l);
}
.connected-accounts--app-details {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-m);
  margin-left: var(--spacing-l);
}
.connected-accounts--card {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
}
.connected-accounts--delete-container {
  display: flex;
  justify-content: flex-end;
  padding-right: var(--spacing-s) !important;
}
.connected-accounts--delete-container--delete-button {
  cursor: pointer;
  visibility: hidden;
  position: relative;
  top: 3px;
  height: var(--spacing-l) !important;
  margin-top: 3%;
}
.connected-accounts--slack-delete {
  margin-left: calc(var(--spacing-xxl) + var(--spacing-l));
}
.connected-accounts--edit-container {
  display: flex;
  justify-content: flex-end;
  padding-right: var(--spacing-s) !important;
}
.connected-accounts--edit-container--edit-button {
  cursor: pointer;
  visibility: hidden;
  position: relative;
  top: 3px;
  height: var(--spacing-l) !important;
  margin-top: 3%;
  margin-left: var(--spacing-l) !important;
}
.connected-accounts--update-container {
  display: flex;
  justify-content: flex-end;
  padding-right: var(--spacing-s) !important;
}
.connected-accounts--update-container--update-button {
  cursor: pointer;
  visibility: hidden;
  position: relative;
}
.connected-accounts .connected-accounts-table--connectedto-column {
  display: flex;
  align-items: center;
}
.connected-accounts .connected-accounts-table--setupby-column {
  display: flex;
  align-items: center;
}
.connected-accounts .connected-accounts-table--lastrun-column {
  display: flex;
  align-items: center;
}
.connected-accounts .connected-accounts-table--createdby-column {
  display: flex;
  align-items: center;
}
.connected-accounts .info-button {
  display: inline-block;
}
.connected-accounts .entity-learn-more-link {
  color: var(--content-color-secondary);
  cursor: pointer;
}
.connected-accounts .entity-learn-more-link:hover {
  color: var(--content-color-link);
}

.delete-connected-accounts-modal-header {
  font-size: var(--text-size-m);
}

.delete-connected-accounts-modal-content {
  font-size: var(--text-size-m);
}

.connected-account-breadcrumbs {
  margin-bottom: var(--spacing-xxl);
}

.connected-account--back-btn {
  cursor: pointer;
}
.connected-account--back-btn:hover {
  color: var(--content-color-link);
}
.connected-account--back-btn:hover path {
  fill: var(--content-color-link);
}

.connected-account--back-btn-wrapper {
  width: 108px;
  margin-bottom: var(--spacing-xl) !important;
  display: flex;
  direction: row;
  align-items: center;
  gap: var(--spacing-s);
}

.connected-account-empty-integrations {
  width: 480px;
  margin-top: calc(var(--spacing-xxl) + var(--spacing-xl));
  margin-right: var(--spacing-xxl);
}

.connected-account-integrations-list {
  margin-right: var(--spacing-xxl) !important;
}
.connected-account-integrations-list--table {
  margin-bottom: var(--spacing-m);
}

.connected-account__user {
  display: flex;
  align-items: center;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  white-space: nowrap;
}
.connected-account__user-icon {
  margin-right: var(--spacing-s);
  position: relative;
  vertical-align: text-top;
}
.connected-account__user-label {
  font-weight: var(--text-weight-medium);
  padding-left: var(--spacing-s);
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.connected-account__user-label.is-clickable {
  cursor: pointer;
}
.connected-account__user-name {
  font-weight: var(--text-weight-medium);
  padding-left: 2px;
}

.integrations-list--set-up-by {
  margin-top: var(--spacing-m);
}

.integration-edit-wrap__error {
  margin-top: 120px;
}
.integration-edit-wrap__error__action {
  max-width: 240px;
  width: 240px;
}

.integrations-form__segment {
  margin-top: var(--spacing-l);
}
.integrations-form__segment__label-title {
  color: var(--content-color-primary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-form__segment__label-title span {
  color: var(--content-color-secondary);
}
.integrations-form__segment__label-description {
  margin-top: 0;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-form__segment__label-description p {
  display: inline;
}
.integrations-form__segment__input {
  flex: 1;
  padding-top: var(--spacing-s);
}
.integrations-form__segment__input__error {
  font-size: var(--text-size-m);
  color: var(--content-color-error);
  margin-top: var(--spacing-xs);
}
.integrations-form__segment__input__error__btn {
  text-decoration: underline;
  cursor: pointer;
}
.integrations-form__segment__input__inline-token {
  width: 440px;
}
.integrations-form__segment__input__helper {
  font-size: var(--text-size-m);
  margin-top: var(--spacing-xs);
}
.integrations-form__segment__input__helper__btn {
  text-decoration: none;
  color: var(--content-color-info);
  cursor: pointer;
}
.integrations-form-actions__button--container {
  margin-top: var(--spacing-xl);
  float: left;
  padding-bottom: var(--spacing-xxxl);
}
.integrations-form-actions__button--cancel {
  border-radius: var(--border-radius-default);
  margin-left: var(--spacing-s);
}
.integrations-form-actions__button--save-changes {
  border-radius: var(--border-radius-default);
}

.integration-edit, .integrations-edit {
  margin: 0 auto;
  width: 720px;
}
.integration-edit__error, .integrations-edit__error {
  margin-top: 120px;
}
.integration-edit__error__action, .integrations-edit__error__action {
  max-width: 320px;
  width: 320px;
}
.integration-edit .integration-edit-actions__separator, .integration-edit .integrations-edit-actions__separator, .integrations-edit .integration-edit-actions__separator, .integrations-edit .integrations-edit-actions__separator {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  border-radius: var(--border-radius-s);
}
.integration-edit .integration-edit-actions__button, .integration-edit .integrations-edit-actions__button, .integrations-edit .integration-edit-actions__button, .integrations-edit .integrations-edit-actions__button {
  margin-bottom: var(--spacing-l);
}
.integration-edit .integration-edit-card, .integration-edit .integrations-edit-card, .integrations-edit .integration-edit-card, .integrations-edit .integrations-edit-card {
  margin: 0 140px;
  position: relative;
}
.integration-edit .integration-edit-card__head, .integration-edit .integrations-edit-card__head, .integrations-edit .integration-edit-card__head, .integrations-edit .integrations-edit-card__head {
  margin-bottom: var(--spacing-s);
}
.integration-edit .integration-edit-card .integration-edit-actions-separator, .integration-edit .integration-edit-card .integrations-edit-actions-separator, .integration-edit .integrations-edit-card .integration-edit-actions-separator, .integration-edit .integrations-edit-card .integrations-edit-actions-separator, .integrations-edit .integration-edit-card .integration-edit-actions-separator, .integrations-edit .integration-edit-card .integrations-edit-actions-separator, .integrations-edit .integrations-edit-card .integration-edit-actions-separator, .integrations-edit .integrations-edit-card .integrations-edit-actions-separator {
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
  margin-bottom: 0;
}
.integration-edit .integration-edit-card .integrations-edit-actions__button--container, .integration-edit .integrations-edit-card .integrations-edit-actions__button--container, .integrations-edit .integration-edit-card .integrations-edit-actions__button--container, .integrations-edit .integrations-edit-card .integrations-edit-actions__button--container {
  margin-top: var(--spacing-xl);
  float: left;
  padding-bottom: var(--spacing-xxxl);
}
.integration-edit .integration-edit-card .integrations-edit-actions__button--container-inline, .integration-edit .integrations-edit-card .integrations-edit-actions__button--container-inline, .integrations-edit .integration-edit-card .integrations-edit-actions__button--container-inline, .integrations-edit .integrations-edit-card .integrations-edit-actions__button--container-inline {
  align-self: flex-end;
  padding-right: var(--spacing-xl);
}
.integration-edit .integration-edit-card .integrations-edit-actions__button--cancel, .integration-edit .integrations-edit-card .integrations-edit-actions__button--cancel, .integrations-edit .integration-edit-card .integrations-edit-actions__button--cancel, .integrations-edit .integrations-edit-card .integrations-edit-actions__button--cancel {
  border-radius: var(--border-radius-default);
  margin-left: var(--spacing-s);
}
.integration-edit .integration-edit-card .integrations-edit-actions__button--save-changes, .integration-edit .integrations-edit-card .integrations-edit-actions__button--save-changes, .integrations-edit .integration-edit-card .integrations-edit-actions__button--save-changes, .integrations-edit .integrations-edit-card .integrations-edit-actions__button--save-changes {
  border-radius: var(--border-radius-default);
}
.integration-edit .integrations-add-card__segment, .integrations-edit .integrations-add-card__segment {
  margin-top: var(--spacing-l);
}
.integration-edit .integrations-add-card__label-description, .integrations-edit .integrations-add-card__label-description {
  margin-top: 0;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integration-edit .integrations-add-card__label-description-external-link, .integrations-edit .integrations-add-card__label-description-external-link {
  margin-top: 0;
  display: flex;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integration-edit .integrations-add-card__external-link, .integrations-edit .integrations-add-card__external-link {
  display: flex;
  align-items: center;
  color: var(--content-color-primary);
}
.integration-edit .integrations-add-card__description, .integrations-edit .integrations-add-card__description {
  margin-top: 0px;
  margin-bottom: var(--spacing-l);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.integration-edit .integrations-add-card__advanced, .integrations-edit .integrations-add-card__advanced {
  color: var(--orange-40);
  margin: var(--spacing-s) 0;
  cursor: pointer;
}
.integration-edit .integrations-add-card__advanced svg, .integrations-edit .integrations-add-card__advanced svg {
  color: var(--orange-40);
}
.integration-edit .integrations-add-card__select, .integrations-edit .integrations-add-card__select {
  width: 100%;
}
.integration-edit .integrations-add-card__name, .integrations-edit .integrations-add-card__name {
  text-align: center;
}
.integration-edit .integrations-add-card-actions, .integrations-edit .integrations-add-card-actions {
  margin-top: var(--spacing-l);
}
.integration-edit .integrations-add-card-actions__cancel, .integrations-edit .integrations-add-card-actions__cancel {
  width: 96px;
  float: left;
}
.integration-edit .integrations-add-card__authed-user, .integrations-edit .integrations-add-card__authed-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--spacing-xl);
  font-size: var(--text-size-m);
  margin-right: 8px;
}
.integration-edit .integrations-add-card__authed-user__text, .integrations-edit .integrations-add-card__authed-user__text {
  margin-right: 8px;
}
.integration-edit .integrations-add-card__authed-user___name, .integrations-edit .integrations-add-card__authed-user___name {
  color: var(--content-color-secondary);
}
.integration-edit .integrations-add-card__authed-user p, .integrations-edit .integrations-add-card__authed-user p {
  font-weight: 600;
}
.integration-edit .integrations-add-card__authed-user img, .integrations-edit .integrations-add-card__authed-user img {
  border-radius: 50%;
  width: var(--spacing-xxl);
  margin-right: var(--spacing-xs);
}
.integration-edit .integrations-add-card__authed-user span, .integrations-edit .integrations-add-card__authed-user span {
  font-size: var(--font-size-m);
  color: var(--content-color-secondary);
}
.integration-edit .integrations-add-card__fail-alert, .integrations-edit .integrations-add-card__fail-alert {
  margin: var(--spacing-l) 0;
}

.integrations-connected-account__form__header {
  margin-top: var(--spacing-m);
  color: var(--content-color-primary);
}
.integrations-connected-account__form__header__primary-text {
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.integrations-connected-account__form__header__helper {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-xs);
}
.integrations-connected-account__form__header__helper__btn {
  text-decoration: none;
  color: var(--content-color-info);
  cursor: pointer;
}
.integrations-connected-account__form__header__btn {
  margin-left: var(--spacing-l);
}

.create-installed-app--label {
  margin-bottom: var(--spacing-xs) !important;
}
.create-installed-app--label-secondary {
  margin-top: var(--spacing-s) !important;
}
.create-installed-app--label-disabled {
  background-color: var(--background-color-secondary) !important;
}
.create-installed-app--description {
  margin-top: calc(-1 * var(--spacing-s)) !important;
  margin-bottom: var(--spacing-xs);
}

.update-installed-app--label {
  margin-bottom: var(--spacing-xs) !important;
}
.update-installed-app--label-secondary {
  margin-top: var(--spacing-s) !important;
}
.update-installed-app--label-disabled {
  background-color: var(--background-color-secondary) !important;
}
.update-installed-app--description {
  margin-top: calc(-1 * var(--spacing-s)) !important;
  margin-bottom: var(--spacing-xs);
}

.api-version-integration {
  width: 100%;
  counter-reset: section;
}
.api-version-integration__error__action {
  min-width: 240px;
}
.api-version-integration__delete-button {
  background-color: var(--base-color-error);
}
.api-version-integration__delete-button:hover {
  background-color: var(--base-color-error);
}
.api-version-integration__header {
  padding-bottom: var(--spacing-s);
}
.api-version-integration__header__title {
  font-size: var(--spacing-xl);
  display: inline-block;
  font-weight: var(--text-weight-medium);
  margin-right: var(--spacing-l);
  vertical-align: middle;
}
.api-version-integration__header__icon {
  background-color: var(--base-color-success);
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  width: var(--spacing-xl);
  height: var(--spacing-xl);
}
.api-version-integration__header__icon i {
  vertical-align: middle;
}
.api-version-integration__header__icon i svg {
  height: 12px;
  width: 12px;
  color: var(--content-color-constant);
}
.api-version-integration__header__description {
  color: var(--content-color-secondary);
  padding: var(--spacing-s) 0;
  border-bottom: 1px solid var(--border-color-default);
}
.api-version-integration__content__header {
  margin: var(--spacing-xl) 0;
  position: relative;
  width: 100%;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-l);
}
.api-version-integration__content__header:before {
  counter-increment: section;
  content: counter(section);
  position: absolute;
  left: -48px;
  width: var(--spacing-xl);
  height: var(--spacing-xl);
  text-align: center;
  line-height: var(--line-height-l);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
}
.api-version-integration__content__header__title {
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.api-version-integration__content__header__description__content {
  max-width: 468px;
  display: inline-block;
}
.api-version-integration__content__header__description__button {
  margin-top: var(--spacing-l);
}
.api-version-integration__content__header strong {
  font-weight: var(--text-weight-medium);
}
.api-version-integration__content__header a {
  display: block;
}
.api-version-integration__content__header strong {
  color: var(--content-color-primary);
}
.api-version-integration__content__input-entity {
  margin-bottom: var(--spacing-xl);
  width: 450px;
  display: inline-block;
}
.api-version-integration__content__input-entity .pm-form-control {
  background-color: var(--background-color-secondary);
}
.api-version-integration__content__input-entity .disabled {
  opacity: 1;
  pointer-events: initial;
  text-overflow: ellipsis;
}
.api-version-integration__content__input-entity .pm-form__label {
  font-size: var(--text-size-m);
}
.api-version-integration__content__copy-button {
  margin-left: var(--spacing-s);
}
.api-version-integration__content__table {
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
}
.api-version-integration__content__table__empty {
  max-width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.api-version-integration__content__table__empty__title {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  margin-top: 8px;
  padding: 6px;
}
.api-version-integration__content__table__empty__add-button {
  margin-bottom: 8px;
}
.api-version-integration__content__table__empty__description {
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-l);
  text-align: center;
  margin: 20px 0;
}
.api-version-integration__content__table__empty .empty-state__title {
  font-size: var(--text-size-l);
}
.api-version-integration__content__table__header {
  border-bottom: 1px solid var(--border-color-default);
  padding: var(--spacing-s) var(--spacing-l);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.api-version-integration__content__table__header__name, .api-version-integration__content__table__header__branch {
  display: inline-block;
  min-width: 80px;
  margin-right: var(--spacing-l);
  color: var(--content-color-secondary);
}
.api-version-integration__content__table__header__path {
  display: inline-block;
  min-width: 150px;
  margin-right: var(--spacing-l);
  color: var(--content-color-secondary);
}
.api-version-integration__content__table__header__actions {
  display: inline-block;
}
.api-version-integration__content__table__loader {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}
.api-version-integration__content__table__item {
  padding: var(--spacing-s) var(--spacing-l);
  line-height: var(--spacing-xxl);
  height: 48px;
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.api-version-integration__content__table__item:hover {
  background-color: var(--background-color-primary);
}
.api-version-integration__content__table__item:hover .api-version-integration__content__table__item__actions {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  display: inline-block;
  width: 40px;
  min-width: 40px;
  text-align: right;
}
.api-version-integration__content__table__item:hover .api-version-integration__content__table__item__actions i {
  cursor: pointer;
}
.api-version-integration__content__table__item__name, .api-version-integration__content__table__item__branch {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  display: inline-block;
  width: 80px;
  min-width: 80px;
  margin-right: var(--spacing-l);
}
.api-version-integration__content__table__item__path {
  vertical-align: middle;
  display: inline-block;
  width: 150px;
  min-width: 150px;
  margin-right: var(--spacing-l);
}
.api-version-integration__content__table__item__actions {
  display: none;
}

.integrations-add-card__input2 {
  display: flex;
}

.api-version-delete-modal__entity {
  font-weight: var(--text-weight-medium);
}

.integrations-add-card__error {
  margin-top: var(--spacing-xs);
}

.integrations-add-card__retry-btn {
  cursor: pointer;
  text-decoration: underline;
}

.api-version-modal .pm-h3 {
  margin: var(--spacing-l) 0;
}
.api-version-modal__close {
  position: absolute;
  top: var(--spacing-xxl);
  right: var(--spacing-xxl);
}
.api-version-modal__error__action {
  min-width: 240px;
}
.api-version-modal__header {
  margin-bottom: var(--spacing-xl);
}
.api-version-modal__entity__label {
  font-weight: var(--text-weight-medium);
  display: block;
  margin-bottom: var(--spacing-s);
}
.api-version-modal__name, .api-version-modal__branch, .api-version-modal__path {
  margin-bottom: var(--spacing-l);
}
.api-version-modal__branch-loader {
  float: right;
  position: relative;
  top: 36px;
  right: 10px;
}
.api-version-modal__branch-try-again {
  background: none;
  border: none;
  padding: 0px;
  color: var(--base-color-brand);
  cursor: pointer;
  outline: none;
  float: right;
  position: relative;
  top: 35px;
  right: var(--spacing-s);
}
.api-version-modal__branch-success {
  float: right;
  position: relative;
  top: 36px;
  right: 12px;
}
.api-version-modal__actions {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-xl);
}
.api-version-modal__actions__cancel {
  width: 112px;
  margin-right: var(--spacing-s);
}
.api-version-modal__actions__submit {
  width: 264px;
  min-width: 264px;
}
.api-version-modal__source {
  margin: var(--spacing-l) 0;
}

.integrations-modal-content__schema-conflict {
  margin-bottom: var(--spacing-m);
}

.integrations-api-schema-sync__actions {
  margin-top: var(--spacing-xxl);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.integration-runs__list-container {
  width: 100%;
  max-width: 960px;
  padding: var(--spacing-zero) var(--spacing-xl) var(--spacing-xl) var(--spacing-xl);
}
.integration-runs__list-container__breadcrumb {
  margin-left: var(--spacing-l);
}
.integration-runs__list-container__content {
  margin-left: var(--spacing-l);
}
.integration-runs__list-container__loading {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
.integration-runs__list-container__header {
  display: flex;
  padding: var(--spacing-s) var(--spacing-s);
}
.integration-runs__list-container__header__sec1 {
  width: 60%;
}
.integration-runs__list-container__header__sec1__title {
  color: var(--content-color-primary);
  font-style: normal;
  font-size: var(--text-size-xxl);
}
.integration-runs__list-container__header__sec1__subtitle {
  font-weight: var(--text-weight-small);
  margin-top: 5px;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
}
.integration-runs__list-container__header__sec2 {
  width: 40%;
}
.integration-runs__list-container__main {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.integration-runs__list-container__main__banner {
  padding-bottom: var(--spacing-xl);
}
.integration-runs__list-container__main__sec1 {
  width: 60%;
  color: var(--content-color-secondary);
}
.integration-runs__list-container__main__sec1__header {
  display: flex;
  border-bottom: 1px solid var(--grey-10);
  padding: var(--spacing-s) var(--spacing-l);
  font-size: var(--text-size-m);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--content-color-tertiary);
}
.integration-runs__list-container__main__sec1__header__time {
  width: 40%;
}
.integration-runs__list-container__main__sec1__header__status {
  width: 10%;
}
.integration-runs__list-container__main__sec1__header__reason {
  width: 50%;
}
.integration-runs__list-container__main__sec1__empty {
  text-align: center;
  color: var(--content-color-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-xxl) var(--spacing-l);
  line-height: var(--line-height-s);
  position: relative;
}
.integration-runs__list-container__main__sec1__empty__text {
  margin-top: var(--spacing-l);
  font-size: var(--text-size-l);
  color: var(--content-color-secondary);
}
.integration-runs__list-container__main__sec1__items {
  display: flex;
  flex-direction: column;
}
.integration-runs__list-container__main__sec1__items__item {
  display: flex;
  padding: var(--spacing-s) var(--spacing-l);
  font-size: var(--text-size-m);
}
.integration-runs__list-container__main__sec1__items__item__time {
  width: 40%;
  color: var(--content-color-primary);
}
.integration-runs__list-container__main__sec1__items__item__status {
  font-size: var(--text-size-s);
  font-weight: 600;
  text-align: center;
}
.integration-runs__list-container__main__sec1__items__item__status__success {
  border-radius: 10px;
  padding: 2px 3px;
  background-color: var(--background-color-success);
  color: var(--content-color-success);
  text-transform: uppercase;
}
.integration-runs__list-container__main__sec1__items__item__status__failure {
  border-radius: 10px;
  padding: 2px 4px;
  background-color: var(--background-color-error);
  color: var(--content-color-error);
  text-transform: uppercase;
}
.integration-runs__list-container__main__sec1__items__item__status__running {
  border-radius: 10px;
  padding: 2px var(--spacing-xs);
  background-color: var(--background-color-tertiary);
  color: var(--content-color-secondary);
  text-transform: uppercase;
}
.integration-runs__list-container__main__sec1__items__item__reason {
  width: 50%;
  margin-left: 5px;
  margin-bottom: 4px;
}
.integration-runs__list-container__main__sec1__items__item__reason__msg {
  display: inline;
}
.integration-runs__list-container__main__sec1__items__item__reason__icon {
  display: inline;
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-left: 5px;
}
.integration-runs__list-container__main__sec1__items__item__reason__icon svg {
  transform: translateY(1px);
}
.integration-runs__list-container__main__sec2 {
  width: 30%;
  height: 200px;
}
.integration-runs__list-container__main__sec2__container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.integration-runs__list-container__main__sec2__container__item {
  width: 100%;
  height: 30%;
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
}
.integration-runs__list-container__main__sec2__container__item__title {
  font-weight: 400;
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  line-height: 12px;
}
.integration-runs__list-container__main__sec2__container__item__description {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.integration-runs__list-container__main__sec2__container__item__description__text {
  margin-left: 5px;
  width: 100%;
}
.integration-runs__list-container__main__sec2__container__item__description__text__div {
  max-width: fit-content;
  cursor: pointer;
}
.integration-runs__list-container__main__sec2__container__item__description__icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
  width: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.integration-runs__list-container__main__sec2__container__item__description__fallback-icon {
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/thumbnails/integration-stroke.svg");
}
.integration-runs__list-container__main__sec2__container .integration-page-banner .alert-info {
  margin-top: var(--spacing-xl);
}
.integration-runs__list-container__main__sec2__container .integration-page-banner .alert-info .alert-content .alert-actions .btn-primary {
  background-color: transparent;
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-regular);
  border: 1px solid rgba(48, 48, 48, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
}

.runlog-tooltip {
  max-width: 360px !important;
}

.trace-container {
  border-top: 1px solid var(--border-color-default);
}
.trace-container__trace {
  padding-left: var(--spacing-xl);
}
.trace-container__toggle {
  margin-left: var(--spacing-s);
}

.integration-run-log__steps-to-fix {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  margin: var(--spacing-m);
}
.integration-run-log__steps-to-fix p a {
  color: var(--content-color-primary);
  text-decoration: none;
  cursor: pointer;
}
.integration-run-log__tooltip-body {
  margin: 0;
  padding: 0;
}

.integrations-meta__loading {
  margin-left: var(--spacing-m);
}

.integration-entity__link {
  cursor: pointer;
}

.integrations-run-actions__container {
  display: flex;
}
.integrations-run-actions__button--edit {
  margin-right: var(--spacing-s);
  border-radius: var(--border-radius-s);
}
.integrations-run-actions__button--delete {
  border-radius: var(--border-radius-s);
}
.integrations-run-actions__button--modal-delete {
  border-radius: var(--border-radius-s);
  background-color: var(--base-color-error);
}
.integrations-run-actions__button--modal-delete:hover {
  background-color: var(--base-color-error);
}
.integrations-run-actions__button--refresh {
  margin-right: var(--spacing-s);
  border-radius: var(--border-radius-s);
}
.integrations-run-actions__button--cancel {
  border-radius: var(--border-radius-s);
}

.integrations-run-breadcrumbs {
  padding: 0 var(--spacing-s);
  margin-bottom: var(--spacing-l);
}
.integrations-run-breadcrumbs__team-name {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  word-break: break-all;
  padding-right: var(--spacing-s);
  cursor: pointer;
}
.integrations-run-breadcrumbs__divider {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.integrations-run-breadcrumbs__integration-page {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  padding: 0 var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  cursor: pointer;
}
.integrations-run-breadcrumbs__text {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  padding-left: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.integrations-add-card__segment .menu, .integrations-form__segment__input .menu {
  position: absolute;
  background: var(--background-color-primar);
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin-top: 8px;
  width: 100%;
  box-shadow: 0 0 10px -5px var(--grey-90);
}
.integrations-add-card__segment .menu-section, .integrations-form__segment__input .menu-section {
  border-bottom: 1px solid var(--border-color-default);
  border-top: 1px solid var(--border-color-default);
}
.integrations-add-card__segment .menu-section:first-of-type, .integrations-form__segment__input .menu-section:first-of-type {
  border-top: 0;
}
.integrations-add-card__segment .menu-section:last-of-type, .integrations-form__segment__input .menu-section:last-of-type {
  border-bottom: 0;
}
.integrations-add-card__segment .menu-section + .menu-section, .integrations-form__segment__input .menu-section + .menu-section {
  border-top: 0;
}
.integrations-add-card__segment .menu-section__title, .integrations-form__segment__input .menu-section__title {
  font-size: 1rem;
  padding: 0.5rem;
  font-weight: 700;
}
.integrations-add-card__segment .menu-section-title:empty, .integrations-form__segment__input .menu-section-title:empty {
  padding: 0;
}
.integrations-add-card__segment .menu-section-title:empty + .menu-section, .integrations-form__segment__input .menu-section-title:empty + .menu-section {
  border-top: 0;
}
.integrations-add-card__segment .option, .integrations-form__segment__input .option {
  min-height: 24px;
  width: 100%;
  padding: 8px 16px;
}
.integrations-add-card__segment .option.focused, .integrations-add-card__segment .option:hover, .integrations-form__segment__input .option.focused, .integrations-form__segment__input .option:hover {
  background: var(--background-color-secondary);
  cursor: pointer;
}
.integrations-add-card__segment .option:focus, .integrations-form__segment__input .option:focus {
  border: 10px solid var(--border-color-default);
}
.integrations-add-card__segment .option.disabled, .integrations-form__segment__input .option.disabled {
  opacity: 0.5;
  background: var(--background-color-secondary);
}
.integrations-add-card__segment .option.disabled:hover, .integrations-form__segment__input .option.disabled:hover {
  cursor: no-drop;
}
.integrations-add-card__segment .Select, .integrations-form__segment__input .Select {
  width: 100%;
}
.integrations-add-card__segment .Select, .integrations-add-card__segment .Select div, .integrations-add-card__segment .Select input, .integrations-add-card__segment .Select span, .integrations-form__segment__input .Select, .integrations-form__segment__input .Select div, .integrations-form__segment__input .Select input, .integrations-form__segment__input .Select span {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: var(--text-size-m);
}
.integrations-add-card__segment .Select.is-disabled > .Select-control, .integrations-form__segment__input .Select.is-disabled > .Select-control {
  background-color: var(--background-color-tertiary);
  cursor: not-allowed;
}
.integrations-add-card__segment .Select.is-disabled > .Select-control:hover, .integrations-form__segment__input .Select.is-disabled > .Select-control:hover {
  box-shadow: none;
  cursor: not-allowed;
}
.integrations-add-card__segment .Select.is-disabled .Select-arrow-zone, .integrations-form__segment__input .Select.is-disabled .Select-arrow-zone {
  cursor: not-allowed;
  pointer-events: none;
}
.integrations-add-card__segment .is-error .Select-control, .integrations-form__segment__input .is-error .Select-control {
  border: 1px solid var(--base-color-error);
}
.integrations-add-card__segment .Select-control, .integrations-form__segment__input .Select-control {
  background: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--border-color-default);
  color: var(--content-color-primary);
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 32px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.integrations-add-card__segment .Select-control:hover, .integrations-form__segment__input .Select-control:hover {
  box-shadow: none;
  background: var(--background-color-tertiary);
}
.integrations-add-card__segment .is-disabled .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label, .integrations-form__segment__input .is-disabled .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
  color: var(--content-color-secondary);
}
.integrations-add-card__segment .Select-control:disabled, .integrations-form__segment__input .Select-control:disabled {
  cursor: not-allowed;
}
.integrations-add-card__segment .Select-control:focus, .integrations-form__segment__input .Select-control:focus {
  box-shadow: none;
  background: var(--background-color-primary);
}
.integrations-add-card__segment .Select-control .Select-input:focus, .integrations-form__segment__input .Select-control .Select-input:focus {
  outline: none;
  box-shadow: none;
  background: var(--background-color-primary);
}
.integrations-add-card__segment .is-searchable.is-open > .Select-control, .integrations-form__segment__input .is-searchable.is-open > .Select-control {
  cursor: text;
}
.integrations-add-card__segment .is-open > .Select-control, .integrations-form__segment__input .is-open > .Select-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: var(--background-color-primary);
  border-color: var(--border-color-default);
}
.integrations-add-card__segment .is-open > .Select-control .Select-arrow, .integrations-form__segment__input .is-open > .Select-control .Select-arrow {
  top: -2px;
  border-color: transparent transparent var(--grey-50);
  border-width: 0 4px 4px;
}
.integrations-add-card__segment .is-searchable.is-focused:not(.is-open) > .Select-control, .integrations-form__segment__input .is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}
.integrations-add-card__segment .is-focused:not(.is-open) > .Select-control, .integrations-form__segment__input .is-focused:not(.is-open) > .Select-control {
  border-color: transparent;
  box-shadow: none;
}
.integrations-add-card__segment .Select--single > .Select-control .Select-value, .integrations-add-card__segment .Select-placeholder, .integrations-form__segment__input .Select--single > .Select-control .Select-value, .integrations-form__segment__input .Select-placeholder {
  bottom: 0;
  color: var(--content-color-tertiary);
  left: 0;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.integrations-add-card__segment .Select-input, .integrations-form__segment__input .Select-input {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  max-width: calc(100% - 24px);
  overflow: hidden;
}
.integrations-add-card__segment .Select-input > input, .integrations-form__segment__input .Select-input > input {
  background: none transparent;
  border: 0;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 14px;
  padding: 6px 0 10px;
  -webkit-appearance: none;
}
.integrations-add-card__segment .is-focused .Select-input > input, .integrations-form__segment__input .is-focused .Select-input > input {
  cursor: text;
}
.integrations-add-card__segment .Select-control:not(.is-searchable) > .Select-input, .integrations-form__segment__input .Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}
.integrations-add-card__segment .Select-loading-zone, .integrations-form__segment__input .Select-loading-zone {
  cursor: pointer;
  display: table-cell;
  text-align: center;
}
.integrations-add-card__segment .Select-loading, .integrations-add-card__segment .Select-loading-zone, .integrations-form__segment__input .Select-loading, .integrations-form__segment__input .Select-loading-zone {
  position: relative;
  vertical-align: middle;
  width: 16px;
}
.integrations-add-card__segment .Select-loading, .integrations-form__segment__input .Select-loading {
  -webkit-animation: Select-animation-spin 0.4s linear infinite;
  -o-animation: Select-animation-spin 0.4s infinite linear;
  animation: Select-animation-spin 0.4s linear infinite;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid var(--border-color-default);
  border-right-color: var(--content-color-primary);
  display: inline-block;
}
.integrations-add-card__segment .Select-clear-zone, .integrations-form__segment__input .Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 0.2s;
  -o-animation: Select-animation-fadeIn 0.2s;
  animation: Select-animation-fadeIn 0.2s;
  color: var(--grey-50);
  cursor: pointer;
  display: inline-block;
  position: absolute;
  bottom: 50%;
  right: 15px;
  transform: translateY(50%);
  width: 15px;
  height: 15px;
  margin-bottom: 1px;
}
.integrations-add-card__segment .Select-clear-zone:hover, .integrations-form__segment__input .Select-clear-zone:hover {
  color: var(--grey-50);
}
.integrations-add-card__segment .Select-clear, .integrations-form__segment__input .Select-clear {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
}
.integrations-add-card__segment .Select--multi .Select-clear-zone, .integrations-form__segment__input .Select--multi .Select-clear-zone {
  width: 15px;
}
.integrations-add-card__segment .Select--multi .Select-multi-value-wrapper, .integrations-form__segment__input .Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}
.integrations-add-card__segment .Select .Select-aria-only, .integrations-form__segment__input .Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}
.integrations-add-card__segment .Select-arrow-zone, .integrations-form__segment__input .Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  padding-right: 4px;
}
.integrations-add-card__segment .Select-arrow, .integrations-form__segment__input .Select-arrow {
  border-color: var(--grey-50) transparent transparent;
  border-style: solid;
  border-width: 4px 4px 2px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}
.integrations-add-card__segment .is-open .Select-arrow, .integrations-add-card__segment .Select-arrow-zone:hover > .Select-arrow, .integrations-form__segment__input .is-open .Select-arrow, .integrations-form__segment__input .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: var(--grey-50);
}
@-webkit-keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Select-animation-fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.integrations-add-card__segment .Select--single.pm-select, .integrations-form__segment__input .Select--single.pm-select {
  font-size: 12px;
  height: 32px;
  width: 100%;
  max-width: 100%;
  margin: 0px;
}
.integrations-add-card__segment .Select--single.pm-select .Select-value, .integrations-form__segment__input .Select--single.pm-select .Select-value {
  pointer-events: none;
}
.integrations-add-card__segment .Select--multi.pm-select .Select-value, .integrations-form__segment__input .Select--multi.pm-select .Select-value {
  cursor: pointer;
  color: var(--content-color-secondary);
  border: var(--border-width-default) solid var(--border-color-default);
  border-radius: var(--border-radius-default);
  margin: var(--spacing-xs);
}
.integrations-add-card__segment .Select--single.pm-select .Select-control, .integrations-form__segment__input .Select--single.pm-select .Select-control {
  display: inline-block;
  overflow: visible;
  max-width: 100%;
  width: auto;
  cursor: pointer;
}
.integrations-add-card__segment .Select--single.pm-select .Select-control:hover, .integrations-form__segment__input .Select--single.pm-select .Select-control:hover {
  box-shadow: none;
}
.integrations-add-card__segment .Select--single.pm-select .Select-placeholder, .integrations-add-card__segment .Select--single.pm-select .Select-value, .integrations-form__segment__input .Select--single.pm-select .Select-placeholder, .integrations-form__segment__input .Select--single.pm-select .Select-value {
  position: static;
  padding-right: 24px;
}
.integrations-add-card__segment .Select--single.pm-select .Select-input, .integrations-form__segment__input .Select--single.pm-select .Select-input {
  top: 0;
  left: 0;
  position: absolute;
}
.integrations-add-card__segment .Select--single.pm-select .Select-arrow-zone, .integrations-form__segment__input .Select--single.pm-select .Select-arrow-zone {
  top: 50%;
  right: 0;
  padding: 0;
  position: absolute;
  display: inline-block;
  transform: translateY(-50%);
}
.integrations-add-card__segment .Select--single.pm-select .Select-menu-outer, .integrations-form__segment__input .Select--single.pm-select .Select-menu-outer {
  width: 100%;
  margin-top: 4px;
  min-width: 120px;
  max-width: 100%;
  max-height: 400px;
  border-radius: var(--border-radius-default);
  font-size: 14px;
}
.integrations-add-card__segment .Select--single.pm-select .Select-menu, .integrations-form__segment__input .Select--single.pm-select .Select-menu {
  max-height: 398px;
}
.integrations-add-card__segment .Select--single.pm-select .Select-option, .integrations-form__segment__input .Select--single.pm-select .Select-option {
  overflow: hidden;
  padding-left: 16px;
  padding-right: 32px;
  color: var(--content-color-primary);
  font-size: var(--text-size-s);
  white-space: nowrap;
  text-overflow: ellipsis;
}
.integrations-add-card__segment .Select--multi.pm-select .Select-option, .integrations-form__segment__input .Select--multi.pm-select .Select-option {
  overflow: hidden;
  padding-left: 16px;
  padding-right: 32px;
  color: var(--content-color-primary);
  font-size: var(--text-size-s);
  white-space: nowrap;
  text-overflow: ellipsis;
}
.integrations-add-card__segment .Select--multi.pm-select .Select-option:first-child, .integrations-form__segment__input .Select--multi.pm-select .Select-option:first-child {
  margin-top: 8px;
}
.integrations-add-card__segment .Select--multi.pm-select .Select-option:last-child, .integrations-form__segment__input .Select--multi.pm-select .Select-option:last-child {
  margin-bottom: 8px;
}
.integrations-add-card__segment .Select--multi.pm-select .Select-option:hover, .integrations-form__segment__input .Select--multi.pm-select .Select-option:hover {
  background: var(--background-color-tertiary);
  color: var(--content-color-primary);
}
.integrations-add-card__segment .Select--multi.pm-select .Select-option:active, .integrations-form__segment__input .Select--multi.pm-select .Select-option:active {
  background: var(--background-color-secondary);
}
.integrations-add-card__segment .Select--multi.pm-select--align-right .Select-menu-outer, .integrations-form__segment__input .Select--multi.pm-select--align-right .Select-menu-outer {
  right: 0;
}
.integrations-add-card__segment .Select-option .disabled-option, .integrations-form__segment__input .Select-option .disabled-option {
  color: var(--content-color-tertiary);
}
.integrations-add-card__segment .Select--single.pm-select .Select-option:first-child, .integrations-form__segment__input .Select--single.pm-select .Select-option:first-child {
  margin-top: 8px;
}
.integrations-add-card__segment .Select--single.pm-select .Select-option:last-child, .integrations-form__segment__input .Select--single.pm-select .Select-option:last-child {
  margin-bottom: 8px;
}
.integrations-add-card__segment .Select--single.pm-select .Select-option:hover, .integrations-form__segment__input .Select--single.pm-select .Select-option:hover {
  background: var(--background-color-tertiary);
  color: var(--content-color-primary);
}
.integrations-add-card__segment .Select--single.pm-select .Select-option:active, .integrations-form__segment__input .Select--single.pm-select .Select-option:active {
  background: var(--background-color-secondary);
}
.integrations-add-card__segment .Select--single.pm-select--align-right .Select-menu-outer, .integrations-form__segment__input .Select--single.pm-select--align-right .Select-menu-outer {
  right: 0;
}
.integrations-add-card__segment .Select--single.pm-select--loading .Select-arrow, .integrations-form__segment__input .Select--single.pm-select--loading .Select-arrow {
  display: none;
}
.integrations-add-card__segment .Select--single.pm-select .Select-placeholder, .integrations-form__segment__input .Select--single.pm-select .Select-placeholder {
  color: var(--content-color-tertiary);
}
.integrations-add-card__segment .Select--single.pm-select .Select-value, .integrations-form__segment__input .Select--single.pm-select .Select-value {
  color: var(--content-color-primary);
}
.integrations-add-card__segment .Select--single.pm-select-text, .integrations-form__segment__input .Select--single.pm-select-text {
  display: inline-block;
}
.integrations-add-card__segment .Select--single.pm-select-text .Select-control, .integrations-form__segment__input .Select--single.pm-select-text .Select-control {
  background: none;
  border: thin solid transparent;
}
.integrations-add-card__segment .Select--single.pm-select-text:not(.is-focused) .Select-control, .integrations-form__segment__input .Select--single.pm-select-text:not(.is-focused) .Select-control {
  border: none;
}
.integrations-add-card__segment .Select--single.pm-select-text:not(.is-focused) .Select-control:hover, .integrations-form__segment__input .Select--single.pm-select-text:not(.is-focused) .Select-control:hover {
  box-shadow: none;
}
.integrations-add-card__segment .Select--single.pm-select-text--dark .Select-control, .integrations-form__segment__input .Select--single.pm-select-text--dark .Select-control {
  border: thin solid transparent;
}
.integrations-add-card__segment .Select--single.pm-select-text--dark .Select-arrow, .integrations-add-card__segment .Select--single.pm-select-text--dark .Select-placeholder, .integrations-add-card__segment .Select--single.pm-select-text--dark .Select-value, .integrations-add-card__segment .Select--single.pm-select-text--dark:active .Select-arrow, .integrations-add-card__segment .Select--single.pm-select-text--dark:active .Select-placeholder, .integrations-add-card__segment .Select--single.pm-select-text--dark:active .Select-value, .integrations-add-card__segment .Select--single.pm-select-text--dark:hover .Select-arrow, .integrations-add-card__segment .Select--single.pm-select-text--dark:hover .Select-placeholder, .integrations-add-card__segment .Select--single.pm-select-text--dark:hover .Select-value, .integrations-form__segment__input .Select--single.pm-select-text--dark .Select-arrow, .integrations-form__segment__input .Select--single.pm-select-text--dark .Select-placeholder, .integrations-form__segment__input .Select--single.pm-select-text--dark .Select-value, .integrations-form__segment__input .Select--single.pm-select-text--dark:active .Select-arrow, .integrations-form__segment__input .Select--single.pm-select-text--dark:active .Select-placeholder, .integrations-form__segment__input .Select--single.pm-select-text--dark:active .Select-value, .integrations-form__segment__input .Select--single.pm-select-text--dark:hover .Select-arrow, .integrations-form__segment__input .Select--single.pm-select-text--dark:hover .Select-placeholder, .integrations-form__segment__input .Select--single.pm-select-text--dark:hover .Select-value {
  color: var(--content-color-constant);
  border-top-color: var(--grey-00);
}
.integrations-add-card__segment .Select--single.pm-select-text--dark.is-open .Select-arrow, .integrations-add-card__segment .Select--single.pm-select-text--dark.is-open:active .Select-arrow, .integrations-add-card__segment .Select--single.pm-select-text--dark.is-open:hover .Select-arrow, .integrations-form__segment__input .Select--single.pm-select-text--dark.is-open .Select-arrow, .integrations-form__segment__input .Select--single.pm-select-text--dark.is-open:active .Select-arrow, .integrations-form__segment__input .Select--single.pm-select-text--dark.is-open:hover .Select-arrow {
  border-bottom-color: var(--grey-00);
}
.integrations-add-card__segment .Select--single .pm-select-loader, .integrations-form__segment__input .Select--single .pm-select-loader {
  position: absolute;
  top: 50%;
  right: var(--spacing-l);
  transform: translateY(-50%);
}
.integrations-add-card__segment .Select--single.pm-select-md, .integrations-form__segment__input .Select--single.pm-select-md {
  font-size: 14px;
}
.integrations-add-card__segment .Select--single.pm-select-md, .integrations-add-card__segment .Select--single.pm-select-md .Select-control, .integrations-add-card__segment .Select--single.pm-select-md .Select-input, .integrations-form__segment__input .Select--single.pm-select-md, .integrations-form__segment__input .Select--single.pm-select-md .Select-control, .integrations-form__segment__input .Select--single.pm-select-md .Select-input {
  height: 40px;
}
.integrations-add-card__segment .Select--single.pm-select-md .Select-input, .integrations-add-card__segment .Select--single.pm-select-md .Select-placeholder, .integrations-add-card__segment .Select--single.pm-select-md .Select-value, .integrations-form__segment__input .Select--single.pm-select-md .Select-input, .integrations-form__segment__input .Select--single.pm-select-md .Select-placeholder, .integrations-form__segment__input .Select--single.pm-select-md .Select-value {
  line-height: 40px;
}
.integrations-add-card__segment .Select--single.pm-select-lg, .integrations-form__segment__input .Select--single.pm-select-lg {
  font-size: 16px;
}
.integrations-add-card__segment .Select--single.pm-select-lg, .integrations-add-card__segment .Select--single.pm-select-lg .Select-control, .integrations-add-card__segment .Select--single.pm-select-lg .Select-input, .integrations-form__segment__input .Select--single.pm-select-lg, .integrations-form__segment__input .Select--single.pm-select-lg .Select-control, .integrations-form__segment__input .Select--single.pm-select-lg .Select-input {
  height: 48px;
}
.integrations-add-card__segment .Select--single.pm-select-lg .Select-input, .integrations-add-card__segment .Select--single.pm-select-lg .Select-placeholder, .integrations-add-card__segment .Select--single.pm-select-lg .Select-value, .integrations-form__segment__input .Select--single.pm-select-lg .Select-input, .integrations-form__segment__input .Select--single.pm-select-lg .Select-placeholder, .integrations-form__segment__input .Select--single.pm-select-lg .Select-value {
  line-height: 48px;
}
.integrations-add-card__segment .Select--single.pm-select--block, .integrations-add-card__segment .Select--single.pm-select--block .Select-control, .integrations-form__segment__input .Select--single.pm-select--block, .integrations-form__segment__input .Select--single.pm-select--block .Select-control {
  width: 100%;
  max-width: 100%;
}
.integrations-add-card__segment .Select--single.pm-select--block .Select-menu-outer, .integrations-form__segment__input .Select--single.pm-select--block .Select-menu-outer {
  width: 100%;
  max-width: none;
}
.integrations-add-card__segment .Select--single.is-searchable .Select-control, .integrations-add-card__segment .Select--single.is-searchable .Select-multi-value-wrapper, .integrations-form__segment__input .Select--single.is-searchable .Select-control, .integrations-form__segment__input .Select--single.is-searchable .Select-multi-value-wrapper {
  display: block;
}
.integrations-add-card__segment .react-16-dropdown, .integrations-form__segment__input .react-16-dropdown {
  display: inline-block;
}
.integrations-add-card__segment .react-16-dropdown .trigger-renderer, .integrations-form__segment__input .react-16-dropdown .trigger-renderer {
  position: relative;
  padding: 8px;
}
.integrations-add-card__segment .react-16-dropdown .trigger-renderer:focus, .integrations-form__segment__input .react-16-dropdown .trigger-renderer:focus {
  outline: none;
}
.integrations-add-card__segment .react-16-dropdown.pm-dropdown--block, .integrations-add-card__segment .react-16-dropdown.pm-dropdown--block .trigger, .integrations-form__segment__input .react-16-dropdown.pm-dropdown--block, .integrations-form__segment__input .react-16-dropdown.pm-dropdown--block .trigger {
  display: block;
}
.integrations-add-card__segment .react-16-dropdown.pm-dropdown--block .trigger-renderer, .integrations-form__segment__input .react-16-dropdown.pm-dropdown--block .trigger-renderer {
  width: 100%;
  display: block;
}
.integrations-add-card__segment .react-16-dropdown.pm-more-options-dropdown .pm-btn__default-icon svg, .integrations-form__segment__input .react-16-dropdown.pm-more-options-dropdown .pm-btn__default-icon svg {
  height: 14px;
  width: 14px;
}
.integrations-add-card__segment .react-16-dropdown.pm-dropdown-md .trigger-renderer, .integrations-form__segment__input .react-16-dropdown.pm-dropdown-md .trigger-renderer {
  font-size: 14px;
  line-height: 24px;
}
.integrations-add-card__segment .react-16-dropdown.pm-dropdown-lg .trigger-renderer, .integrations-form__segment__input .react-16-dropdown.pm-dropdown-lg .trigger-renderer {
  font-size: 16px;
  line-height: 28px;
}
.integrations-add-card__segment .react-16-dropdown-portal .option, .integrations-form__segment__input .react-16-dropdown-portal .option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.integrations-add-card__segment .react-16-dropdown-portal .menu, .integrations-form__segment__input .react-16-dropdown-portal .menu {
  padding: 8px 0;
  max-width: 300px;
  min-width: 120px;
  z-index: 100;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}
.integrations-add-card__segment .react-16-dropdown-portal .menu:focus, .integrations-add-card__segment .react-16-dropdown-portal .menu > div:focus, .integrations-form__segment__input .react-16-dropdown-portal .menu:focus, .integrations-form__segment__input .react-16-dropdown-portal .menu > div:focus {
  outline: none;
}
.integrations-add-card__segment .Select-menu-outer, .integrations-form__segment__input .Select-menu-outer {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  border-top-color: var(--border-color-default);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}
.integrations-add-card__segment .Select-menu, .integrations-form__segment__input .Select-menu {
  max-height: 198px;
  overflow-y: auto;
}
.integrations-add-card__segment .Select-option, .integrations-form__segment__input .Select-option {
  box-sizing: border-box;
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.integrations-add-card__segment .Select-option:last-child, .integrations-form__segment__input .Select-option:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.integrations-add-card__segment .Select-option.is-selected, .integrations-form__segment__input .Select-option.is-selected {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}
.integrations-add-card__segment .Select-option.is-focused, .integrations-form__segment__input .Select-option.is-focused {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}
.integrations-add-card__segment .Select-option.is-disabled, .integrations-form__segment__input .Select-option.is-disabled {
  color: var(--content-color-tertiary);
  cursor: not-allowed;
}
.integrations-add-card__segment .Select-noresults, .integrations-form__segment__input .Select-noresults {
  box-sizing: border-box;
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  cursor: default;
  display: block;
  padding: 8px 10px;
}
.integrations-add-card__segment .Select--multi .Select-input, .integrations-form__segment__input .Select--multi .Select-input {
  vertical-align: middle;
  top: 0;
  left: 0;
  padding-left: 2px;
}
.integrations-add-card__segment .Select--multi.has-value .Select-input, .integrations-form__segment__input .Select--multi.has-value .Select-input {
  margin-left: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  height: var(--size-m);
  font-size: var(--text-size-m);
  line-height: var(--line-height-xxl);
  text-align: center;
}
.integrations-add-card__segment .Select--multi .Select-value, .integrations-form__segment__input .Select--multi .Select-value {
  background-color: var(--background-color-primary);
  border-radius: 2px;
  border: 1px solid var(--blue-20);
  color: var(--blue-40);
  display: inline-block;
  font-size: var(--text-size-s);
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
}
.integrations-add-card__segment .Select--multi .Select-value-icon, .integrations-add-card__segment .Select--multi .Select-value-label, .integrations-form__segment__input .Select--multi .Select-value-icon, .integrations-form__segment__input .Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle;
  color: var(--content-color-secondary);
}
.integrations-add-card__segment .Select--multi .Select-value-label, .integrations-form__segment__input .Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px;
}
.integrations-add-card__segment .Select--multi a.Select-value-label, .integrations-form__segment__input .Select--multi a.Select-value-label {
  color: var(--content-color-primary);
  text-decoration: none;
}
.integrations-add-card__segment .Select--multi a.Select-value-label:hover, .integrations-form__segment__input .Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}
.integrations-add-card__segment .Select--multi .Select-value-icon, .integrations-form__segment__input .Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: var(--border-width-default) solid var(--border-color-default);
  padding: 1px 5px 3px;
}
.integrations-add-card__segment .Select--multi .Select-value-icon:focus, .integrations-add-card__segment .Select--multi .Select-value-icon:hover, .integrations-form__segment__input .Select--multi .Select-value-icon:focus, .integrations-form__segment__input .Select--multi .Select-value-icon:hover {
  background-color: var(--background-color-primary);
  color: var(--content-color-primary);
}
.integrations-add-card__segment .Select--multi .Select-value-icon:active, .integrations-form__segment__input .Select--multi .Select-value-icon:active {
  background-color: var(--blue-20);
}
.integrations-add-card__segment .Select--multi.is-disabled .Select-value, .integrations-form__segment__input .Select--multi.is-disabled .Select-value {
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-default);
  color: var(--content-color-secondary);
}
.integrations-add-card__segment .Select--multi.is-disabled .Select-value-icon, .integrations-form__segment__input .Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid var(--border-color-default);
}
.integrations-add-card__segment .Select--multi.is-disabled .Select-value-icon:active, .integrations-add-card__segment .Select--multi.is-disabled .Select-value-icon:focus, .integrations-add-card__segment .Select--multi.is-disabled .Select-value-icon:hover, .integrations-form__segment__input .Select--multi.is-disabled .Select-value-icon:active, .integrations-form__segment__input .Select--multi.is-disabled .Select-value-icon:focus, .integrations-form__segment__input .Select--multi.is-disabled .Select-value-icon:hover {
  background-color: var(--background-color-primary);
  cursor: not-allowed;
}
@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}

.integrations-form__segment__label-title {
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  font-weight: 600;
  line-height: var(--line-height-s);
}
.integrations-form__segment__label-title span {
  color: var(--content-color-secondary);
}
.integrations-form__segment__label-description {
  margin-top: 0;
  color: var(--content-color-secondary);
  font-style: normal;
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
}

.no-edit-integration, .no-create-integration {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 10px 20px;
  box-sizing: border-box;
}
.no-edit-integration__illustration-wrapper, .no-create-integration__illustration-wrapper {
  margin-bottom: 35px;
}
.no-edit-integration__title, .no-create-integration__title {
  font-size: var(--text-size-xl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-bottom: 20px;
}
.no-edit-integration__subtitle, .no-create-integration__subtitle {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  text-align: center;
  margin-bottom: 40px;
  max-width: 500px;
  line-height: var(--line-height-m);
}

.no-edit-integration__illustration-wrapper--integrations .integrations-empty__illustration, .no-create-integration__illustration-wrapper--integrations .integrations-empty__illustration {
  display: block;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/alert.svg");
}

.integration-runs-page-modal {
  height: 174px;
}
.integration-runs-page-modal .modal-footer {
  flex: 0 0 72px;
  padding: var(--spacing-l) var(--spacing-xl);
}
.integration-runs-page-modal .modal-content {
  padding: var(--spacing-l) var(--spacing-xl);
}
.integration-runs-page-modal .modal-header .modal-header-close-button-wrapper {
  display: none;
}

.integrations-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: auto;
  height: 100%;
  color: var(--content-color-primary);
  flex: 1;
  overflow-y: scroll;
  padding: var(--spacing-xxl) var(--spacing-xxl);
}

.team-details-widget {
  margin-top: var(--spacing-xxl);
}
.team-details-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.team-details-widget-team-logo-container {
  height: 100px;
  width: 100px;
  background: var(--background-color-primary);
  display: grid;
  place-content: center;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  border: 1px solid var(--border-color-default);
  border-radius: 4px;
}
.team-details-widget-team-logo-container .team-details-widget-team-logo {
  width: 56px;
  object-fit: contain;
}
.team-details-widget-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--spacing-s);
  width: 250px;
}
.team-details-widget-details__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  text-decoration: none;
}
.team-details-widget-details__domain {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-s);
}
.team-details-widget-members-container {
  display: flex;
  margin-top: var(--spacing-s);
}
.team-details-widget-members-container .team-details-widget-invite-button {
  width: 69px;
  height: 32px;
  margin-left: var(--spacing-s);
  background-color: var(--base-color-info);
  color: var(--content-color-constant);
}
.team-details-widget-members-container .team-details-widget-invite-button .pm-icon {
  margin-right: var(--spacing-xs);
}
.team-details-widget-members-container .team-details-widget-invite-button svg path {
  fill: var(--content-color-constant);
}
.team-details-widget-members-container .team-details-widget-invite-button svg {
  color: var(--content-color-constant);
}
.team-details-widget-members-container .team-details-widget-invite-button:hover {
  background-color: var(--highlight-background-color-info);
}
.team-details-widget-members-container .team-details-widget-invite-button:hover svg path {
  fill: var(--content-color-constant);
}
.team-details-widget-members-container .team-details-widget-invite-button:hover svg {
  color: var(--content-color-constant);
}
.team-details-widget-members-container .team-details-widget-members-avatar-list {
  display: flex;
}
.team-details-widget-members-container .team-details-widget-members-avatar-list > * + * {
  margin-left: -8px;
}
.team-details-widget-members-container .team-details-widget-members-avatar-list .team-details-widget-members-avatar-list-item {
  border: 1px solid var(--background-color-primary);
  border-radius: 32px;
  height: 32px;
}
.team-details-widget-members-container .team-details-widget-members-avatar-list .team-details-widget-members-avatar-list-item:nth-child(2) {
  z-index: 1;
}
.team-details-widget-members-container .team-details-widget-members-avatar-list .team-details-widget-members-show-more {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: grid;
  place-content: center;
  background: var(--background-color-tertiary);
  color: var(--content-color-secondary);
}
.team-details-widget-members-container .team-details-widget-members-avatar-list .team-details-widget-members-show-more-text {
  font-weight: var(--text-weight-medium);
  font-size: 11px;
  line-height: 16px;
}

.team-details-widget-members-list {
  list-style: none;
  padding: 8px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 192px;
  color: var(--content-color-primary);
}
.team-details-widget-members-list-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.team-details-widget-members-list-item {
  display: flex;
  align-items: center;
  height: 48px;
}
.team-details-widget-members-list-item__name {
  padding: 0 var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  max-width: 132px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.team-details-widget-members-list-team-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-s);
  font-weight: var(--text-weight-medium);
  font-size: 11px;
  line-height: 12px;
  color: var(--content-color-link);
}
.team-details-widget-members-list-team-link i {
  margin-left: var(--spacing-s);
}
.team-details-widget-members-list-team-link i svg {
  color: var(--content-color-link);
}
.team-details-widget-members-list-team-link i svg path {
  fill: var(--content-color-link);
}

.empty-greeting-shell {
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: fade 1s ease-in-out infinite;
  overflow: hidden;
  margin-bottom: var(--spacing-l);
}
.empty-greeting-shell__title {
  height: var(--size-s);
  width: calc(6 * var(--size-m));
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-tertiary);
}
.empty-greeting-shell__subText {
  height: 12px;
  width: 350px;
  border-radius: var(--border-radius-default);
  margin-top: 12px;
  background-color: var(--background-color-tertiary);
}
@keyframes fade {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.homepage-greetings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 72px;
  width: 100%;
  margin-bottom: var(--spacing-xxl);
}
.homepage-greetings .homepage-greeting-header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  display: flex;
  color: var(--content-color-primary);
  word-break: break-word;
}
.homepage-greetings .homepage-greeting-body {
  display: flex;
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}

.homepage-team-apis {
  margin-bottom: var(--spacing-xxl);
}
.homepage-team-apis-header {
  display: flex;
}
.homepage-team-apis-header-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  margin: var(--spacing-zero);
  color: var(--content-color-primary);
}
.homepage-team-apis-header-explore {
  background: none;
  border: none;
  align-self: center;
  color: var(--content-color-link);
  margin-left: var(--spacing-l);
  font-size: 11px;
  line-height: 12px;
}
.homepage-team-apis-header-explore i {
  vertical-align: text-bottom;
  margin-left: var(--spacing-s);
}
.homepage-team-apis-header-explore i svg {
  color: var(--content-color-link);
}
.homepage-team-apis-header-explore i svg use {
  fill: var(--content-color-link);
}
.homepage-team-apis-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  grid-row: auto;
  grid-column-gap: var(--spacing-xxl);
  grid-row-gap: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.homepage-team-apis-cards__card {
  box-sizing: border-box;
  border: 0.5px solid var(--border-color-default);
  min-height: 160px;
  border-radius: 4px;
  padding: 20px 24px;
  cursor: pointer;
}
.homepage-team-apis-cards__card:hover {
  background-color: var(--background-color-secondary);
}
.homepage-team-apis-cards__card-name {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin: 0 0 4px 0;
  word-break: break-word;
  display: -webkit-box;
  color: var(--content-color-primary);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.homepage-team-apis-cards__card-metric {
  display: flex;
  margin-bottom: 8px;
}
.homepage-team-apis-cards__card-metric-versions {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: 0 var(--spacing-l) 0 0;
}
.homepage-team-apis-cards__card-metric-views {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-tertiary);
  margin: 0;
}
.homepage-team-apis-cards__card-description {
  color: var(--content-color-secondary);
  overflow: hidden;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: 0;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.homepage-get-started {
  margin-bottom: var(--spacing-xxl);
}
.homepage-get-started-header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
  margin-top: 0;
}
.homepage-get-started-cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  grid-row: auto;
  grid-column-gap: var(--spacing-xxl);
  grid-row-gap: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.homepage-get-started-cards__manage {
  display: flex;
  width: 300px;
  align-items: center;
}
.homepage-get-started-cards__badge {
  margin-left: var(--spacing-s);
  margin-bottom: var(--spacing-xs);
}
.homepage-get-started-cards__card {
  box-sizing: border-box;
  border: 0.5px solid var(--border-color-default);
  height: 160px;
  border-radius: 4px;
  padding: 20px 24px;
}
.homepage-get-started-cards__card-name {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin: 0 0 4px 0;
  color: var(--content-color-primary);
}
.homepage-get-started-cards__card-description {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: 0;
  height: 60px;
  color: var(--content-color-secondary);
}
.homepage-get-started-cards__card-button {
  display: flex;
  margin-top: 16px;
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  padding: 0;
  color: var(--content-color-link);
  border: none;
  background: none;
}
.homepage-get-started-cards__card-button i {
  vertical-align: middle;
  margin-left: var(--spacing-s);
}
.homepage-get-started-cards__card-button i svg {
  color: var(--content-color-link);
}
.homepage-get-started-cards__card-button i svg use {
  fill: var(--content-color-link);
}
.homepage-get-started-cards__card .is-disabled {
  opacity: 0.5;
  cursor: progress;
}

.homepage-get-started-empty {
  margin: 24px 0;
  max-width: 584px;
  animation: fade 2s ease-in-out infinite;
}
.homepage-get-started-empty-header {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  height: 16px;
  width: 50%;
  background-color: var(--background-color-tertiary);
}
.homepage-get-started-empty-cards {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-row: auto;
  grid-column-gap: var(--spacing-xxl);
  grid-row-gap: var(--spacing-l);
  margin-top: var(--spacing-l);
}
.homepage-get-started-empty-cards__card {
  box-sizing: border-box;
  border: 0.5px solid var(--border-color-default);
  height: 160px;
  border-radius: 4px;
  padding: 20px 24px;
}
.homepage-get-started-empty-cards__card-name {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  width: 100%;
  margin: 0 0 8px 0;
  height: 12px;
  margin-bottom: var(--spacing-l);
  background-color: var(--background-color-tertiary);
}
.homepage-get-started-empty-cards__card-description {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: 0;
  width: 100%;
  height: 32px;
  background-color: var(--background-color-tertiary);
}

.external-links-widget-container .external-links {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0;
}
.external-links-widget-container .external-links .external-link {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.external-links-widget-container .external-links .external-link__text {
  margin-left: var(--spacing-s);
}
.external-links-widget-container .external-links > * + * {
  margin-top: var(--spacing-m);
}

.sidebar-discoverable-teams-widget {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-l);
}
.sidebar-discoverable-teams-widget-bottom-divider {
  border-top: var(--border-width-default) var(--border-style) var(--border-color-default);
  margin: var(--spacing-xxl) 0 0 0;
  height: 1px;
  width: 100%;
}

.sidebar-discoverable-teams {
  margin: var(--spacing-s) 0 var(--spacing-l);
  padding: 0;
}
.sidebar-discoverable-teams > * + * {
  margin-top: var(--spacing-xs);
}
.sidebar-discoverable-teams--team {
  display: flex;
  list-style-type: none;
  padding: var(--spacing-s) var(--spacing-m);
  height: auto;
  background: var(--background-color-tertiary);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  align-items: center;
  transition: background 0.2s ease-in-out;
  width: 100%;
}
.sidebar-discoverable-teams--team:hover button {
  opacity: 1;
  pointer-events: auto;
}
.sidebar-discoverable-teams--team:hover div {
  max-width: 100px;
}
.sidebar-discoverable-teams--team-icon {
  width: var(--size-s);
  object-fit: contain;
}
.sidebar-discoverable-teams--team-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-m);
  margin-left: var(--spacing-m);
  color: var(--content-color-primary);
  max-width: 164px;
}
.sidebar-discoverable-teams--team-count {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--text-size-s);
  margin-left: var(--spacing-m);
  color: var(--content-color-secondary);
  max-width: 164px;
}
.sidebar-discoverable-teams--team-join-button {
  margin-left: auto;
  opacity: 0;
  pointer-events: none;
}

.homepage-pending-tasks {
  margin-bottom: var(--spacing-xxl);
}
.homepage-pending-tasks-heading {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-l);
}
.homepage-pending-tasks-pending-invites {
  border: 0.5px solid var(--border-color-default);
  box-sizing: border-box;
  border-radius: var(--border-radius-default);
  display: flex;
  padding: var(--spacing-l);
  min-width: 320px;
  margin-bottom: var(--spacing-s);
}
.homepage-pending-tasks-pending-invites .pm-link {
  text-decoration: none;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
}
@media only screen and (min-width: 2200px) {
  .homepage-pending-tasks-pending-invites {
    width: 552px;
  }
}
.homepage-pending-tasks-pending-invites .avatar {
  margin-right: var(--spacing-s);
}
.homepage-pending-tasks-pending-invites-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.homepage-pending-tasks-pending-invites-info-description {
  color: var(--content-color-secondary);
  word-break: break-word;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero) var(--spacing-zero) var(--spacing-s) var(--spacing-zero);
}
.homepage-pending-tasks-pending-invites-info-description:hover a {
  color: var(--content-color-link);
}
.homepage-pending-tasks-pending-invites-info-description a {
  text-decoration: none;
  color: var(--content-color-primary);
  font-weight: var(--text-weight-regular);
}
.homepage-pending-tasks-pending-invites-info-description a:hover {
  text-decoration: underline;
}
.homepage-pending-tasks-pending-invites-info-description p {
  margin-bottom: var(spacing-xs);
}
.homepage-pending-tasks-pending-invites-info-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.homepage-pending-tasks-pending-invites-info-actions-time {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.homepage-pending-tasks-pending-invites-info-actions-text {
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  padding: var(--spacing-s) 10px;
}
.homepage-pending-tasks-pending-invites-info-close {
  margin: -8px 0 0 -4px;
}

.homepage-team-activity-feed {
  position: relative;
  margin-bottom: var(--spacing-xxl);
  flex: 2;
}
.homepage-team-activity-feed .loading-indicator-wrapper {
  text-align: center;
}
.homepage-team-activity-feed .workspace-activity-feed-date-group-wrapper .workspace-activity-item-wrapper {
  font-size: 12px;
}
.homepage-team-activity-feed .workspace-activity-feed-date-group-wrapper .workspace-activity-item-wrapper a {
  word-break: break-word;
}
.homepage-team-activity-feed-end {
  color: var(--content-color-secondary);
  text-align: center;
  margin: var(--spacing-l) 0;
}
.homepage-team-activity-feed-title {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.homepage-team-activity-feed-items {
  list-style: none;
  padding: 0;
  margin: var(--spacing-l) 0 0 0;
  position: relative;
}
.homepage-team-activity-feed-items > * + * {
  margin-top: var(--spacing-xxl);
}
.homepage-team-activity-feed-items::before {
  position: absolute;
  content: "";
  height: 100%;
  border-left: 1px solid var(--highlight-background-color-primary);
  left: var(--spacing-l);
  top: var(--spacing-xxl);
  z-index: -1;
}
.homepage-team-activity-feed-item {
  display: flex;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.homepage-team-activity-feed-item-details-container {
  margin-left: var(--spacing-l);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.homepage-team-activity-feed-item-details-container a {
  text-decoration: none;
  color: var(--content-color-link);
  font-weight: var(--text-weight-regular);
  word-break: break-word;
}
.homepage-team-activity-feed-item-details-container p {
  margin-top: 0;
  margin-bottom: 4px;
}
.homepage-team-activity-feed-item-details-container p strong {
  font-weight: 600;
}
.homepage-team-activity-feed-item__description {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  max-width: 272px;
  overflow-wrap: break-word;
}
.homepage-team-activity-feed-item__time {
  margin-top: var(--spacing-xs);
  color: var(--content-color-secondary);
  margin-bottom: 0;
}
.homepage-team-activity-feed-item .homepage-team-activity-feed-item-entity-link,
.homepage-team-activity-feed-item .pm-link {
  cursor: pointer;
  color: var(--content-color-primary);
}
.homepage-team-activity-feed-item .homepage-team-activity-feed-item-entity-link:hover,
.homepage-team-activity-feed-item .pm-link:hover {
  color: var(--content-color-link);
  text-decoration: none;
}
.homepage-team-activity-feed .workspace-activity-item-heading {
  word-break: break-word;
}

.homepage-recent-workspaces {
  margin-bottom: var(--spacing-xxl);
}
.homepage-recent-workspaces .recent-workspaces-list {
  width: 100%;
}
.homepage-recent-workspaces .recent-workspaces-list-items {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.homepage-recent-workspaces .recent-workspaces-list-items .recent-workspaces-list-item {
  min-width: 0;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.homepage-recent-workspaces .recent-workspaces-list-items .recent-workspaces-list-item:hover {
  background-color: var(--highlight-background-color-primary);
}

.resource-usage {
  border: 1px solid var(--border-color-default);
  border-radius: 4px;
  padding: var(--spacing-xl);
  width: 272px;
  margin-top: var(--spacing-xxl);
}
.resource-usage__heading {
  font-size: var(--text-size-xxl);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-primary);
  margin-top: 0px;
}
.resource-usage__limit-reset-date {
  font-size: var(--text-size-s);
  color: var(--content-color-tertiary);
  margin-bottom: var(--spacing-l);
  line-height: var(--line-height-m);
}
.resource-usage__body .team-usage-container {
  border: none;
  padding: 0;
}
.resource-usage__view-all-link {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  margin-top: var(--spacing-l);
  padding: 0;
  color: var(--content-color-link);
  border: none;
  background: none;
}

.announcement {
  position: relative;
  margin-bottom: var(--spacing-xxl);
}
.announcement-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--text-size-xxl);
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
  line-height: var(--line-height-xxl);
}
.announcement-toggle-action {
  padding: 0 var(--spacing-s);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-link);
  cursor: pointer;
}
.announcement-show-more {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-m);
  text-align: center;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
  color: var(--content-color-link);
  cursor: pointer;
}
.announcement-list {
  margin: var(--spacing-m) 0 var(--spacing-xl) 0;
}
.announcement-list__left {
  margin-top: var(--spacing-m);
  position: relative;
  width: 32px;
  height: 32px;
}
.announcement-list__left-category {
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 18px;
  top: 18px;
  background-color: var(--background-color-secondary);
  border-radius: 50%;
}
.announcement-list__right {
  margin: var(--spacing-s) 0 0 var(--spacing-l);
}
.announcement-list__item {
  display: flex;
  margin-bottom: var(--spacing-l);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.announcement-list__item--title, .announcement-list__item--summary, .announcement-list__item--meta {
  display: flex;
  align-items: center;
  color: var(--content-color-secondary);
}
.announcement-list__item--title {
  display: inline;
}
.announcement-list__item--new {
  margin-left: var(--spacing-s);
}
.announcement-list__item--meta {
  margin-top: var(--spacing-xs);
}
.announcement-list__item--meta_separator {
  color: var(--content-color-tertiary);
  display: inline-block;
  margin: 0 var(--spacing-xs);
}
.announcement-list__item--summary {
  overflow: hidden;
  display: -webkit-box;
  margin: var(--spacing-xs) 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.announcement-list__item--action {
  margin-top: var(--spacing-s);
}
.announcement-list--new {
  color: var(--base-color-brand);
  font-size: var(--text-size-s);
  margin-left: var(--spacing-xs);
}

.page-offline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
}
.page-offline__icon-container {
  height: calc(var(--size-xs) * 3.5);
  width: calc(var(--size-xs) * 3.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: var(--spacing-l);
  background: var(--background-color-tertiary);
}
.page-offline__icon-container--icon, .page-offline__icon-container--icon > svg {
  height: var(--size-m);
  width: var(--size-m);
}
.page-offline__title {
  font-weight: 600;
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
  margin-bottom: var(--spacing-s);
}
.page-offline__subtitle {
  font-weight: 400;
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}

.upgrade-plan-modal-content {
  padding: var(--size-xs);
  text-align: center;
}
.upgrade-plan-modal-content-close-icon {
  position: absolute;
  top: var(--spacing-l);
  right: var(--spacing-l);
}
.upgrade-plan-modal-content-heading {
  margin: var(--spacing-s);
}
.upgrade-plan-modal-content-loader {
  margin-bottom: var(--spacing-m);
}
.upgrade-plan-modal-content-text {
  margin-top: var(--spacing-zero);
  margin-bottom: var(--spacing-l);
}
.upgrade-plan-modal-content-illustration {
  margin: var(--size-m) auto calc(var(--spacing-s)) auto;
}
.upgrade-plan-modal-content-action-primary {
  width: 126px;
  display: flex;
  margin: auto auto var(--spacing-m) auto;
}
.upgrade-plan-modal-content-action-outline {
  width: 126px;
}

.enable-auto-flex__action {
  display: flex;
  justify-content: center;
  align-items: center;
}
.enable-auto-flex__action-cancel {
  margin-right: var(--spacing-s);
}
.enable-auto-flex__action-proceed {
  min-width: 140px;
}
.enable-auto-flex__checkbox.is-selected {
  background-color: var(--base-color-brand);
}
.enable-auto-flex__checkbox-wrapper {
  display: flex;
  margin-top: var(--spacing-xl);
}
.enable-auto-flex__checkbox-label {
  margin-left: var(--spacing-s);
  color: var(--content-color-secondary);
}

.header-container {
  max-width: 900px;
  margin: 0 auto;
  padding-top: var(--spacing-xxxl);
}
.header-container .header--title-container {
  margin: 0 auto;
  color: var(--content-color-primary);
}
.header-container .header--title-container .header--title {
  text-align: center;
  font-size: var(--text-size-xxxl);
  font-weight: var(--text-weight-bold);
}
.header-container .header--title-container .header--subtitle {
  margin: var(--spacing-s) var(--spacing-s) 0px var(--spacing-s);
  text-align: center;
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}

.compound-input__label {
  font-size: var(--text-size-l);
  font-weight: var(--text-weight-bold);
  margin-bottom: var(--spacing-s);
  color: var(--content-color-primary);
}
.compound-input__field input {
  height: 40px;
}
.compound-input__field input:active {
  border-color: var(--base-color-brand);
}
.compound-input__message {
  margin: var(--spacing-xs) var(--spacing-zero);
  display: inline-block;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.compound-input__error {
  margin: var(--spacing-xs) var(--spacing-zero);
  color: var(--content-color-error);
  display: inline-block;
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.image-upload-modal-close {
  position: absolute;
  right: var(--spacing-l);
  top: var(--spacing-l);
  cursor: pointer;
  padding: 0 var(--spacing-xs);
}
.image-upload-modal-upload {
  flex-grow: 2;
}
.image-upload-modal-back {
  margin-left: var(--spacing-zero) !important;
  flex-grow: 1;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
}
.ReactCrop:focus {
  outline: none;
}
.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__image {
  display: block;
  max-width: 100%;
  touch-action: none;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: none;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}
.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}
.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: 33.3333333333%;
}
.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: 66.6666666667%;
}
.ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}
.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: 33.3333333333%;
}
.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: 66.6666666667%;
}
.ReactCrop__drag-handle {
  position: absolute;
}
.ReactCrop__drag-handle::after {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-n::after {
  top: 0;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-e::after {
  right: 0;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-s::after {
  bottom: 0;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop .ord-w::after {
  left: 0;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }
  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

.pm-image-input {
  text-align: center;
}
.pm-image-input__input {
  position: relative;
  border: solid var(--border-width-default) var(--border-color-default);
  padding: calc(var(--spacing-xxl) * 2) 0;
}
.pm-image-input__input input {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.pm-image-input__input-prompt {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  text-align: center;
  margin: var(--spacing-l) 0;
}
.pm-image-input__input-prompt-drag {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  text-align: center;
  margin: var(--spacing-l) 0;
  font-weight: var(--text-weight-bold);
}
.pm-image-input__input-button {
  padding: var(--spacing-zero);
  transform: translateX(0);
}
.pm-image-input__input-button input {
  cursor: pointer;
}
.pm-image-input__input-button label {
  height: 100%;
  display: block;
  cursor: pointer;
  line-height: var(--line-height-xxxl);
  padding: 0 var(--spacing-l);
}

.onboarding .tag, .team-onboarding .tag {
  display: inline-table;
  vertical-align: middle;
  border-radius: var(--border-radius-default);
  margin: var(--spacing-xs);
  padding: var(--spacing-s) var(--spacing-m);
  color: var(--content-color-primary);
}
.onboarding .tag-icon, .onboarding .tag-text, .onboarding .tag-remove-btn, .team-onboarding .tag-icon, .team-onboarding .tag-text, .team-onboarding .tag-remove-btn {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.onboarding .tag-text, .team-onboarding .tag-text {
  padding: 0 var(--spacing-s);
}
.onboarding .tag-remove-btn, .onboarding .tag-icon, .team-onboarding .tag-remove-btn, .team-onboarding .tag-icon {
  line-height: 10px;
}
.onboarding .tag .text:hover, .team-onboarding .tag .text:hover {
  cursor: text;
}
.onboarding .tag .tag-remove-btn:hover, .team-onboarding .tag .tag-remove-btn:hover {
  cursor: pointer;
}
.onboarding .tag-regular, .team-onboarding .tag-regular {
  background-color: var(--background-color-secondary);
  border: solid 1px var(--border-color-strong);
}
.onboarding .tag-error, .team-onboarding .tag-error {
  border: 1px dashed var(--base-color-error);
  background: var(--background-color-error);
}
.onboarding .tags-input-container, .team-onboarding .tags-input-container {
  background-color: var(--background-color-primary);
  border: var(--border-width-default) solid var(--border-color-strong);
  border-radius: var(--border-radius-default);
  min-height: 168px;
  max-height: 220px;
  overflow: auto;
  word-break: break-all;
  font-size: var(--text-size-m);
  padding: var(--spacing-s);
  position: relative;
  box-sizing: border-box;
}
.onboarding .tags-input-container .tags-input-placeholder, .team-onboarding .tags-input-container .tags-input-placeholder {
  position: absolute;
  top: var(--spacing-s);
  left: var(--spacing-s);
  padding: var(--spacing-s);
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
}
.onboarding .tags-input-container .active-input, .team-onboarding .tags-input-container .active-input {
  display: inline-block;
  vertical-align: middle;
  line-height: var(--line-height-m);
  min-width: var(--spacing-xxl);
  height: var(--spacing-xl);
  margin: var(--spacing-xs);
  padding: var(--spacing-xs);
  border: none;
  outline: none;
}
.onboarding .tags-input-component-focused, .team-onboarding .tags-input-component-focused {
  border-color: var(--base-color-brand);
}
.onboarding .tags-input-component-error, .team-onboarding .tags-input-component-error {
  border-color: var(--base-color-error);
}

.team-profile {
  display: block;
  color: var(--content-color-primary);
}
.team-profile__main {
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  padding-bottom: var(--spacing-xl);
}
.team-profile--actions {
  margin-top: var(--spacing-xl);
  display: flex;
}
.team-profile--actions-primary {
  width: 152px;
  font-size: var(--text-size-l);
}
.team-profile--actions-secondary {
  padding: var(--spacing-s) var(--spacing-l);
  font-size: var(--text-size-l);
}
.team-profile__setup-team {
  max-width: 568px;
  margin: var(--spacing-xxxl) auto var(--spacing-xl);
  position: relative;
}
.team-profile__setup-team .input {
  font-size: var(--text-size-l);
}
.team-profile__team-name {
  margin: var(--spacing-s) var(--spacing-l) 0 0;
  width: 50%;
  display: inline-block;
}
.team-profile__url-title {
  margin-top: var(--spacing-xl);
  font-weight: var(--text-weight-bold);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
}
.team-profile__url-subtitle {
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}
.team-profile__domain {
  max-width: 400px;
  margin-top: var(--spacing-s);
  display: flex;
}
.team-profile__domain-field {
  display: inline-block;
  flex-basis: 208px;
}
.team-profile__domain-field input {
  text-align: right;
}
.team-profile__domain-suffix {
  display: inline-block;
  line-height: var(--line-height-xxxl);
  margin-left: var(--spacing-s);
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
}
.team-profile__team-discovery {
  font-weight: var(--text-weight-bold);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  margin-top: var(--spacing-xl);
  color: var(--content-color-primary);
}
.team-profile__team-discovery-content {
  margin-top: var(--spacing-s);
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  max-width: 435px;
  color: var(--content-color-secondary);
}

.team-profile-picture-container {
  margin-top: var(--spacing-l);
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-left: var(--spacing-m);
}
.team-profile-picture-container .pm-user-icon {
  display: inline-block;
  vertical-align: middle;
  background-size: 100%;
  width: 68px;
  height: 68px;
}
.team-profile-picture-container .team-profile-picture-icon {
  display: inline-block;
}
.team-profile-picture-container .team-profile-picture-icon .pm-user-icon--custom {
  background: center/cover no-repeat;
  background-size: 100%;
}
.team-profile-picture-container .team-profile-picture-upload {
  display: inline-block;
  vertical-align: middle;
  margin-left: var(--spacing-s);
  color: var(--content-color-brand);
  cursor: pointer;
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}

.team-discovery__toggle {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-l);
}
.team-discovery__switch {
  width: 70px;
}
.team-discovery__loader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.team-discovery__turn-on-message {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-left: var(--spacing-l);
}

@-webkit-keyframes zoom-in {
  from {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes zoom-in {
  from {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.invite-member {
  width: 450px;
  margin: var(--spacing-xxl) auto;
  display: flex;
  flex-direction: column;
  color: var(--content-color-primary);
}
.invite-member--invite-left-container {
  display: flex;
  justify-content: space-between;
}
.invite-member--invite-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.invite-member--invite-slots {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--grey-60);
}
.invite-member--invite-team-full {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  color: var(--content-color-success);
}
.invite-member--invite-team-full-error {
  color: var(--content-color-error);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.invite-member--tags-container {
  margin-top: var(--spacing-s);
}
.invite-member--slot-error {
  margin-top: var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-error);
}
.invite-member--slot-error a {
  text-decoration: underline;
}
.invite-member--invite-link {
  margin-top: var(--spacing-xl);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  padding-bottom: var(--spacing-xl);
}
.invite-member--invite-link-title {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
}
.invite-member--invite-link-subtitle {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
  margin-top: var(--spacing-s);
  color: var(--content-color-secondary);
}
.invite-member--invite-link-generate-btn {
  width: 136px;
  margin-top: var(--spacing-l);
}
.invite-member--invite-link-container {
  margin-top: var(--spacing-s);
  display: flex;
}
.invite-member--invite-link--input {
  flex-grow: 1;
  margin-right: var(--spacing-s);
}
.invite-member--invite-link .invite-link-copied input {
  border-color: var(--base-color-success);
}
.invite-member--invite-link .link-copied {
  background: var(--base-color-success);
  color: var(--content-color-constant);
}
.invite-member--invite-link .tick-icon-alternate {
  -webkit-animation: zoom-in 0.1s linear none;
  animation: zoom-in 0.1s linear none;
  margin-right: 8px;
}
.invite-member--invite-link .tick-icon-alternate svg {
  color: var(--content-color-constant) !important;
}
.invite-member--actions {
  margin-top: var(--spacing-xl);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
.invite-member--actions-primary {
  width: 152px;
  font-size: var(--text-size-l);
}
.invite-member--actions-secondary {
  font-size: var(--text-size-l);
}

.invite-member-onboarding-plan-alert {
  margin-bottom: var(--spacing-l);
}
.invite-member-onboarding-plan-alert .alert {
  padding: var(--spacing-s);
}
.invite-member-onboarding-plan-alert .alert .alert-icon {
  margin-top: var(--spacing-s);
}
.invite-member-onboarding-plan-alert .alert .alert-icon svg {
  color: var(--content-color-info);
}
.invite-member-onboarding-plan-alert .alert .alert-content {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.invite-member-onboarding-plan-alert .alert .alert-content .alert-actions {
  width: calc(4 * var(--size-s));
  margin-top: var(--spacing-zero);
}
.invite-member-onboarding-plan-alert .alert .alert-content .alert-actions .alert-primary-action {
  margin-right: var(--spacing-zero);
  width: calc(4 * var(--size-s));
  margin-left: var(--spacing-s);
}

.fetching-team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.team-onboarding__page-loader {
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
}

.team-onboarding {
  overflow-y: auto;
  height: 100vh;
}

.creating-team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: calc(4 * var(--spacing-xxl));
}
.creating-team-title {
  margin-top: var(--size-s);
  color: var(--content-color-primary);
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-s);
}
.creating-team-subtext {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  max-width: calc(15 * var(--size-m));
  text-align: center;
}
.creating-team-subtext p {
  margin: unset;
}
.creating-team-subtitle {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  max-width: calc(15 * var(--size-m));
  text-align: center;
}
.creating-team-info {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-m);
  margin-top: var(--spacing-s);
}
.creating-team-alert {
  margin: var(--spacing-s) var(--spacing-zero);
  max-width: calc(15 * var(--size-m));
}
.creating-team-action {
  margin-top: var(--spacing-l);
  width: 126px;
}
.creating-team-action-outline {
  margin-top: var(--spacing-l);
}
.creating-team-icon {
  border-radius: var(--size-m);
  height: calc(var(--size-m) + var(--size-s));
  width: calc(var(--size-m) + var(--size-s));
  display: flex;
  background: var(--background-color-success);
  justify-content: center;
  align-items: center;
}
.creating-team-icon i {
  height: unset;
  width: unset;
}
.creating-team-icon i svg {
  height: var(--size-m);
  width: var(--size-m);
}

.free-trial-activation {
  padding: 0 !important;
  overflow-y: hidden;
}
.free-trial-activation__illustration {
  text-align: center;
  padding: var(--spacing-xxxl) 0;
  background-color: var(--background-color-secondary);
}
.free-trial-activation__content {
  margin: var(--spacing-xl);
}
.free-trial-activation__content-actions .action-icon {
  border-radius: var(--border-radius-max);
  width: var(--size-m);
  height: var(--size-m);
  background-color: var(--background-color-secondary);
}
.free-trial-activation__content-actions .action-icon i {
  margin: var(--spacing-s);
}
.free-trial-activation__footer {
  padding-bottom: var(--spacing-l);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.report-loading-indicator {
  left: 50%;
  position: absolute;
  top: 50%;
}

.report {
  background: var(--background-color-tertiary);
  min-height: calc(100vh - 48px);
  display: flex;
}
.report--business-plan {
  display: block;
  overflow: auto;
}
.report .report-pm-container {
  margin-top: 0;
  margin-bottom: 0;
}
.report-navigation, .report-navigation-subnav, .report-navigation-subnav-collapsed {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  position: sticky;
  top: 0;
  float: left;
  width: 304px;
}
.report-navigation {
  display: none;
  background: var(--background-color-primary);
  height: calc(100vh - 48px);
  padding-left: var(--spacing-l);
  overflow: auto;
  flex: 0 0 320px;
}
.report-navigation .pm-h4 {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-m);
  margin-left: var(--spacing-l);
  color: var(--content-color-primary);
  font-size: var(--text-size-l);
}
.report-navigation-count-badge {
  background: var(--background-color-tertiary);
  margin-left: var(--spacing-s);
  padding: var(--spacing-zero) 2px;
  line-height: var(--line-height-s);
  font-size: var(--text-size-s);
  display: inline-block;
  border-radius: var(--border-radius-s);
}
.report-navigation--empty {
  color: var(--content-color-tertiary);
  margin: 20px auto;
  font-size: var(--text-size-l);
  text-align: center;
  width: 208px;
}
.report-navigation--empty__image {
  display: inline-flex !important;
  margin: var(--spacing-m) 0 20px;
}
.report-navigation--empty__body {
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
}
.report-navigation--business-plan .pm-h2 {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-zero);
  margin-left: var(--spacing-l);
  color: var(--content-color-primary);
  font-size: var(--text-size-xl);
}
.report-navigation-subnav {
  padding-right: 32px;
  padding-left: var(--spacing-s);
  width: calc(304px - var(--spacing-xl));
  user-select: none;
}
.report-navigation-subnav__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin: 6px var(--spacing-zero);
  padding: var(--spacing-zero) var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-primary);
}
.report-navigation-subnav__item:hover {
  background: var(--background-color-brand);
  cursor: pointer;
}
.report-navigation-subnav__item--active {
  background: var(--background-color-brand);
  font-weight: var(--text-weight-medium);
}
.report-navigation-subnav__item--active:hover {
  background: rgba(242, 107, 58, 0.1);
}
.report-navigation-subnav__item.disabled-item {
  background: var(--background-color-primary);
  color: var(--content-color-tertiary);
  cursor: not-allowed;
}
.report-navigation-subnav__item.disabled-item:hover {
  background: var(--background-color-primary);
}
.report-navigation-subnav__item:last-child {
  margin-bottom: var(--spacing-l);
}
.report-navigation-subnav__list-item {
  display: flex;
  width: 100%;
  height: 100%;
}
.report-navigation-subnav__list-item__toggle-icon {
  display: flex;
  align-items: center;
  width: fit-content;
}
.report-navigation-subnav__list-item__label {
  padding-left: var(--spacing-xs);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin: auto;
}
.report-navigation-subnav__list-item__label--left-indent {
  padding-left: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.report-navigation-subnav-sublist {
  padding: var(--spacing-zero);
  width: calc(304px - var(--spacing-xl));
  position: relative;
}
.report-navigation-subnav-sublist .vertical-line {
  position: absolute;
  width: 1px;
  left: var(--spacing-l);
  height: 100%;
  top: var(--spacing-zero);
  background-color: var(--border-color-default);
}
.report-navigation-subnav-sublist .left {
  left: 28px;
}
.report-navigation-subnav-sublist__third-layer {
  padding-left: 60px;
}
.report-navigation-subnav-sublist__item {
  display: flex;
  align-items: center;
  height: var(--spacing-xl);
  margin: 6px 0;
  padding: 6px var(--spacing-s);
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  word-break: break-word;
}
.report-navigation-subnav-sublist__item:hover {
  background: var(--background-color-brand);
  cursor: pointer;
}
.report-navigation-subnav-sublist__item--active {
  background: var(--background-color-brand);
  font-weight: var(--text-weight-medium);
}
.report-navigation-subnav-sublist .indent {
  padding-left: 20px;
}
.report-navigation-subnav-sublist .double-indent {
  padding-left: 40px;
}
.report-navigation-subnav__back-button {
  margin: var(--spacing-xl) var(--spacing-zero) 38px var(--spacing-s);
  padding: var(--spacing-xs) var(--spacing-zero);
  height: var(--spacing-xl);
  width: calc(304px - 36px);
  background: var(--background-color-secondary);
  display: flex;
  align-items: center;
}
.report-navigation-subnav__back-button .pm-icon {
  display: inline-block;
  vertical-align: middle;
  margin: var(--spacing-zero) var(--spacing-s);
}
.report-navigation-subnav__back-button:hover {
  cursor: pointer;
}
.report-navigation-subnav__search {
  height: var(--spacing-xxl);
  position: relative;
  margin: var(--spacing-l) var(--spacing-l) var(--spacing-s) var(--spacing-s);
  width: calc(304px - 36px);
}
.report-navigation-subnav__search .input-search-group {
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  background-color: var(--background-color-primary);
}
.report-navigation-subnav__search .input-search-group:hover {
  border-color: var(--border-color-default);
}
.report-navigation-subnav__search .input-search-group:focus-within {
  border-color: var(--base-color-brand);
}
.report-navigation-subnav-filter-api-type {
  height: var(--size-s);
  margin: var(--spacing-s);
  border-radius: var(--border-radius-default);
}
.report-navigation-subnav-filter-api-type .dropdown-button .btn {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-regular);
}
.report-navigation-subnav-filter-api-type .dropdown-button .btn-tertiary {
  padding: var(--spacing-xs);
  height: var(--size-s);
}
.report-navigation-subnav-filter-api-type .dropdown-menu .dropdown-menu-item {
  padding-left: calc(var(--spacing-xs) + var(--spacing-xxl));
}
.report-navigation-subnav-filter-api-type .dropdown-menu .dropdown-menu-item.api-page-type-item.api-page-type-item--active {
  padding-left: var(--spacing-s);
}
.report-navigation-subnav-filter-api-type .dropdown-menu .dropdown-menu-item.api-page-type-item.api-page-type-item--active i {
  margin-right: var(--spacing-m);
}
.report-navigation-subnav-filter-api-type .dropdown-menu .dropdown-menu-item.api-page-type-item__text {
  font-size: var(--text-size-s);
}
.report-navigation-subnav-filter-api-type .dropdown-menu .dropdown-menu-item:nth-child(3) {
  border-bottom: 1px solid var(--border-color-default);
}
.report-navigation-subnav-collapsed {
  padding: var(--spacing-zero);
  width: calc(304px - 80px);
}
.report-navigation-subnav-collapsed__count {
  color: var(--content-color-tertiary);
  flex-shrink: 0;
  align-items: center;
  display: flex;
}
.report-navigation-subnav-collapsed__count .loading-indicator-wrapper {
  padding-right: 10px;
}
.report-navigation-subnav-collapsed__item {
  height: var(--spacing-xl);
  color: var(--content-color-primary);
  display: flex;
  justify-content: space-between;
}
.report-navigation-subnav-collapsed__item .pm-icon {
  display: table-cell;
  vertical-align: middle;
}
.report-navigation-subnav-collapsed__item--active {
  background: var(--background-color-brand);
}
.report-navigation-subnav--scrollable {
  max-height: calc(100vh - 242px - 48px);
  overflow: auto;
}
.report__last-updated {
  position: relative;
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: inline-block;
  margin-left: var(--spacing-l);
  padding-bottom: var(--spacing-s);
}
.report__last-updated-info {
  margin-left: var(--spacing-s);
  vertical-align: middle;
}
.report__last-updated-info svg {
  height: 12px;
  width: 12px;
}
.report__last-updated-subnav {
  padding: 9px var(--spacing-s);
  font-size: var(--text-size-s);
  color: var(--content-color-secondary);
  display: table;
  background: var(--background-color-primary);
}
.report__last-updated-subnav-info {
  margin-left: var(--spacing-xs);
  display: none;
}
.report__last-updated-subnav-info svg {
  height: 12px;
  width: 12px;
}
.report-faq-text {
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: block;
  margin-bottom: var(--spacing-s);
  margin-top: var(--spacing-xl);
}
.report-faq-text .pm-link {
  color: var(--content-color-secondary);
}
.report .push--left {
  margin-left: 16px !important;
}
.report-error-section {
  text-align: center;
  padding-top: var(--spacing-xxl);
  margin-top: 144px;
  overflow: hidden;
}
.report-error-section__image {
  display: inline-flex !important;
  max-width: 160px;
  margin-bottom: var(--spacing-l);
}
.report-error-section__image.warning {
  display: block;
  width: 160px;
  height: 141px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/warning.svg");
}
.report-error-section__title {
  color: var(--content-color-primary);
  margin-top: var(--spacing-l);
  font-size: var(--text-size-xl);
}
.report-error-section__body {
  line-height: var(--line-height-xs);
  color: var(--content-color-secondary);
  margin: var(--spacing-l) auto var(--spacing-xxl);
  font-size: var(--text-size-l);
}

.report-graph-card {
  padding: var(--spacing-l);
  margin: var(--spacing-l) 0;
  background: var(--background-color-primary);
  border-radius: var(--border-radius-s);
  border: 1px solid var(--border-color-default);
  min-height: 94px;
  position: relative;
}
.report-graph-card--clickable {
  cursor: pointer;
}
.report-graph-card__loader {
  margin: var(--spacing-xxl);
  text-align: center;
}
.report-graph-card__empty {
  text-align: center;
  padding: var(--spacing-xxl);
  font-size: var(--text-size-l);
}
.report-graph-card__empty p {
  color: var(--content-color-primary);
}
.report-graph-card__list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  margin-top: var(--spacing-l);
  max-height: 200px;
  overflow: auto;
  margin-left: -var(--spacing-s);
  margin-right: -var(--spacing-s);
}
.report-graph-card__list li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  height: 20px;
  padding: 10px var(--spacing-s);
  border-radius: var(--border-radius-s);
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
}
.report-graph-card__list li:hover {
  background: var(--background-color-secondary);
}
.report-graph-card__info {
  position: absolute;
  right: var(--spacing-l);
  top: var(--spacing-l);
}
.report-graph-card__info svg {
  height: 12px;
  width: 12px;
}
.report-graph-card__title {
  font-weight: var(--text-weight-medium);
  margin-bottom: var(--spacing-l);
  margin-top: 0;
}
.report-graph-card__summary {
  font-size: var(--text-size-s);
  font-weight: var(--text-weight-medium);
  margin: var(--spacing-l) 0;
}
.report-graph-card__summary-metric {
  text-transform: uppercase;
  color: var(--content-color-secondary);
  font-size: var(--text-size-s);
  margin-left: var(--spacing-s);
  font-weight: var(--text-weight-medium);
}
.report-graph-card__count {
  display: inline-block;
  font-size: var(--text-size-xxl);
  font-weight: var(--text-weight-medium);
  vertical-align: middle;
  color: var(--content-color-secondary);
}
.report-graph-card__delta {
  position: relative;
  height: 20px;
  margin-left: var(--spacing-l);
  padding-left: 20px;
  font-size: var(--text-size-s);
  line-height: var(--line-height-xs);
  background: rgba(249, 249, 249, 0.15);
  border-radius: var(--border-radius-s);
  display: inline-block;
  padding-right: var(--spacing-xs);
}
.report-graph-card__delta .pm-icon {
  position: absolute;
  top: 2px;
  left: 2px;
}
.report-graph-card__delta--success {
  background: rgba(12, 187, 82, 0.15);
  color: var(--base-color-success);
}
.report-graph-card__delta--error {
  background: rgba(237, 75, 72, 0.15);
  color: var(--content-color-error);
}
.report-graph-card__dots {
  margin-top: var(--spacing-l);
  font-size: var(--text-size-xs);
  color: var(--content-color-secondary);
}
.report-graph-card__dots span {
  display: inline-block;
  margin-right: var(--spacing-s);
  height: var(--spacing-s);
  width: var(--spacing-s);
  border-radius: 50%;
  background-color: var(--base-color-success);
}
.report-graph-card__dots--negative span {
  background-color: var(--base-color-error);
}
.report-graph-card__column-name {
  margin-top: var(--spacing-l);
  border-bottom: 1px solid var(--border-color-default);
  padding-bottom: var(--spacing-s);
  font-weight: var(--text-weight-regular);
  color: var(--content-color-secondary);
  font-size: var(--text-size-l);
}
.report-graph-card .push-half--bottom {
  margin-bottom: var(--spacing-s) !important;
}

.report-table .rt-td {
  padding: var(--spacing-s) !important;
}
.report-table .rt-tbody {
  max-height: 400px;
  overflow: auto;
}
.report-table__missing {
  margin-top: var(--spacing-l);
  border-top: 1px solid var(--border-color-default);
  font-size: var(--text-size-m);
}
.report-table__missing span {
  display: inline-block;
  margin-top: var(--spacing-s);
}
.report-table__missing p {
  margin: var(--spacing-xs) 0 0 0;
  color: var(--content-color-secondary);
}

.report-back {
  display: flex;
  justify-content: left;
  margin-top: var(--spacing-l);
  margin-left: -var(--spacing-s);
  width: fit-content;
}
.report-back .pm-icon {
  margin-right: 10px;
}
.report-back:hover {
  background: var(--background-color-tertiary);
}

@media (min-width: 480px) {
  .report-navigation {
    border-right-style: solid;
    border-color: var(--border-color-strong);
    border-width: 1px;
    display: block;
    overflow-x: hidden;
  }
  .report > .report-pm-container {
    padding-left: calc(304px + var(--spacing-l));
  }
}
.report-pm-container {
  margin-top: 24px;
  padding: var(--spacing-zero) var(--spacing-l);
  margin-bottom: 24px;
  min-width: 320px;
}
@media (min-width: 768px) {
  .report-pm-container {
    margin: 24px auto;
    padding: var(--spacing-zero) var(--spacing-s);
  }
}

.report-pm-container:before, .report-pm-container:after {
  content: " ";
  display: table;
}

.report-pm-container:after {
  clear: both;
}

@media (max-width: 768px) {
  .report-pm-container {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) {
  .report-pm-container {
    max-width: 994px;
  }
}
@media (min-width: 1024px) {
  .report-pm-container {
    max-width: 1104px;
  }
}
@media (min-width: 1280px) {
  .report-pm-container {
    max-width: 1104px;
  }
}
.report-empty-state {
  text-align: center;
}
.report-empty-state--absolute-center {
  padding-left: 320px;
}
.report-empty-state__image {
  margin: auto;
  margin-top: 80px;
  max-width: 160px;
  margin-bottom: var(--spacing-l);
}
.report-empty-state__image.activity {
  display: block;
  width: 160px;
  height: 126px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  background-image: url("../assets/images/empty-state/activity.svg");
}
.report-empty-state__title {
  color: var(--content-color-primary);
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-s);
}
.report-empty-state__body {
  max-width: 600px;
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  margin: var(--spacing-l) auto var(--spacing-xxl);
  font-size: var(--text-size-s);
}
.report-empty-state__action {
  margin-bottom: var(--spacing-xl);
}
.report-empty-state__subtext {
  text-align: center;
  margin-bottom: var(--spacing-xl);
  line-height: var(--line-height-s);
}

.report-team {
  margin-left: 135px;
  margin-right: 135px;
}
.report-team .pm-h1 {
  display: inline-block;
  margin-top: var(--spacing-xxl);
  font-size: var(--text-size-xxxl);
  margin-bottom: 0;
}
.report-team-filters {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-s);
}
.report-team-filters__label {
  color: var(--content-color-secondary);
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
}
.report-team-filters .pm-select {
  display: inline-block;
  vertical-align: middle;
  margin: 0 var(--spacing-l);
  cursor: pointer;
}
.report-team-filters .pm-select .Select-clear-zone {
  display: none;
}
.report-team .pm-row {
  margin-left: -15px;
  margin-right: -15px;
}
.report-team .pm-row .pm-col-md-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .report-team .pm-row .pm-col-md-4 {
    width: 33.33333%;
  }
}
.report-team .pm-link {
  text-decoration: underline;
}
.report-team .pm-link:hover {
  color: var(--content-color-link);
  text-decoration: underline;
  cursor: pointer;
}
.report-team .pm-link:visited {
  text-decoration: none;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.report-api {
  margin-left: 135px;
  margin-right: 135px;
}
.report-api .pm-h1 {
  display: inline-block;
  margin-top: var(--spacing-xxl);
  margin-bottom: 0;
}
.report-api__versions {
  display: inline-block;
  vertical-align: middle;
  margin-top: var(--spacing-xxl);
}
.report-api__versions .pm-badge {
  margin: 0 var(--spacing-l);
  vertical-align: middle;
}
.report-api__versions .pm-link {
  vertical-align: middle;
}
.report-api-filters {
  margin-top: var(--spacing-l);
}
.report-api-filters__label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.report-api-filters .pm-select {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  margin-left: var(--spacing-l);
  cursor: pointer;
  width: 130px;
}
.report-api-filters .pm-select .Select-clear-zone {
  display: none;
}
.report-api-filters__collections .Select-menu-outer .Select-menu .Select-option:first-child {
  padding-bottom: var(--spacing-l);
  border-bottom: 1px solid var(--border-color-default);
}
.report-api-versions {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.report-api-versions li {
  height: 40px;
  margin: var(--spacing-l) 0;
  margin-left: -var(--spacing-s);
  margin-right: -var(--spacing-s);
  padding: 10px var(--spacing-s);
  border-radius: var(--border-radius-s);
}
.report-api-versions li:hover {
  background: rgba(241, 90, 36, 0.1);
  cursor: pointer;
}
.report-api-metrics {
  margin-top: var(--spacing-l);
}
.report-api-metrics__label {
  font-weight: var(--text-weight-medium);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
}
.report-api-metrics__list {
  list-style: none;
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
  display: inline-block;
}
.report-api-metrics__list > li {
  display: inline-block;
}
.report-api-metrics__item {
  font-size: var(--text-size-s);
  margin-left: var(--spacing-xs);
  font-weight: var(--text-weight-medium);
  background: var(--border-color-strong);
  border-radius: var(--border-radius-s);
  padding: 0 var(--spacing-s);
}
.report-api-metrics__item a {
  text-decoration: none;
  color: var(--content-color-secondary);
}
.report-api__no-reports {
  padding-top: var(--spacing-xxl);
  overflow: hidden;
}
.report-api__no-reports .empty-state__title {
  margin-top: var(--spacing-l);
}
.report-api__no-reports .empty-state__body {
  max-width: 600px;
  margin-top: var(--spacing-s);
}
.report-api .pm-link {
  text-decoration: underline;
}
.report-api .pm-link:hover {
  color: var(--content-color-link);
  text-decoration: underline;
  cursor: pointer;
}
.report-api .pm-link:visited {
  text-decoration: none;
}
.report-api .report-loading-indicator {
  display: block;
  margin: 64px auto 16px;
  max-width: 48px;
  position: unset;
}
@media (min-width: 768px) {
  .report-api .report-loading-indicator {
    margin-top: 192px;
  }
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.ReactTable {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--background-color-primary);
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: var(--border-color-default);
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: var(--border-color-default);
  border-width: 8px;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: var(--border-color-default);
  border-width: 10px;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px var(--border-color-default);
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}

.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: var(--highlight-background-color-primary);
}

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--background-color-primary);
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: var(--content-color-constant);
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable {
  border: none;
}
.ReactTable .rt-thead {
  background: var(--background-color-secondary);
}
.ReactTable .rt-thead.-header {
  box-shadow: none;
}
.ReactTable .rt-thead .rt-tr {
  text-align: left;
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  line-height: 30px;
  padding: 0 var(--spacing-s);
  color: var(--content-color-secondary);
  border-right: none;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer:hover,
.ReactTable .rt-thead .rt-td.-cursor-pointer:hover {
  color: var(--content-color-primary);
  background: var(--highlight-background-color-secondary);
}
.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none;
  color: var(--content-color-link);
}
.ReactTable .rt-thead .rt-th.-sort-asc:hover, .ReactTable .rt-thead .rt-th.-sort-desc:hover,
.ReactTable .rt-thead .rt-td.-sort-asc:hover,
.ReactTable .rt-thead .rt-td.-sort-desc:hover {
  color: var(--content-color-link);
}
.ReactTable .rt-thead .rt-th.-sort-asc > div:after,
.ReactTable .rt-thead .rt-td.-sort-asc > div:after {
  top: -2px;
  left: 8px;
  height: 0;
  content: " ";
  position: relative;
  display: inline-block;
  border-color: transparent;
  border-top-color: inherit;
  border-width: 4px 4px 0;
  border-style: solid;
}
.ReactTable .rt-thead .rt-th.-sort-desc > div:after,
.ReactTable .rt-thead .rt-td.-sort-desc > div:after {
  top: -2px;
  left: 8px;
  height: 0;
  content: " ";
  position: relative;
  display: inline-block;
  border-color: transparent;
  border-bottom-color: inherit;
  border-width: 0 4px 4px;
  border-style: solid;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid thin var(--border-color-default);
}
.ReactTable .rt-tbody .rt-td {
  font-size: var(--text-size-l);
  color: var(--content-color-primary);
  border: none;
}
.ReactTable .rt-tbody .rt-tr__anchor {
  text-decoration: none;
  color: inherit;
}
.ReactTable .rt-td {
  padding: 0;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: var(--background-color-secondary);
}

.report-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--background-color-tertiary);
}

.report-view-section {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-xxl);
}
.report-view-section-wrapper {
  width: 100%;
  flex: 1;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.report-view-section--header {
  color: var(--content-color-primary);
  line-height: var(--line-height-m);
  font-size: var(--text-size-l);
  margin-bottom: var(--spacing-l);
}
.report-view-section--tiles {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: var(--spacing-xl);
  color: var(--content-color-primary);
}

.page-loader {
  display: grid;
  justify-content: center;
  margin: var(--spacing-l) var(--spacing-zero);
}

.section-loader {
  display: grid;
  justify-content: center;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.error-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
}
.error-banner-primary-text {
  margin-bottom: var(--spacing-s);
  margin-top: var(--spacing-l);
}
.error-banner-secondary-text {
  margin-bottom: var(--spacing-l);
}

.drill-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-xxl);
}
.drill-state__title {
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}
.drill-state__redirect-text {
  margin-top: var(--spacing-xxxl);
  margin-bottom: var(--spacing-m);
}

.embeddable-report-graph-card__title {
  color: var(--content-color-secondary);
  font-size: var(--text-size-m);
  margin: var(--spacing-s) var(--spacing-zero);
}
.embeddable-report-graph-card__summary {
  color: var(--content-color-primary);
  font-size: var(--text-size-m);
  display: flex;
  justify-content: space-between;
}
.embeddable-report-graph-card__summary__disclaimer {
  color: var(--content-color-tertiary);
  font-size: var(--text-size-xs);
}
.embeddable-report-graph-card__loader {
  display: flex;
  justify-content: center;
}
.embeddable-report-graph-card__empty {
  color: var(--content-color-primary);
}
.embeddable-report-graph-card__empty__body {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}

.invite-to-team-modal__content {
  margin-bottom: var(--spacing-l) !important;
}
.invite-to-team-modal__content-error div {
  margin-top: var(--spacing-xs);
}
.invite-to-team-modal__content .invite-file-upload {
  cursor: pointer;
  height: 100%;
  font-size: var(--text-size-s);
}
.invite-to-team-modal__content .invite-file-upload :hover {
  color: var(--content-color-primary);
}
.invite-to-team-modal .ws-invite-link {
  padding: unset;
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
:root {
  --min-editor-height: calc(10 * var(--size-m));
}

.mimir {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: visible;
  font-family: var(--text-family-default);
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.mimir.edit-mode .media-wrapper {
  pointer-events: none;
}
.mimir.edit-mode .media-element:hover .media-handle::after {
  opacity: 1;
}
.mimir.edit-mode .media-element:hover .media-handle::after:hover::after, .mimir.edit-mode .media-element:hover .media-handle::after:focus::after, .mimir.edit-mode .media-element:hover .media-handle::after:active::after {
  opacity: 1;
}
.mimir.edit-mode .link-element {
  pointer-events: none;
}
.mimir.edit-mode .image-item {
  cursor: pointer;
}
.mimir .slate-HeadingToolbar {
  width: 100%;
  min-height: unset;
  border-radius: var(--border-radius-default);
  box-shadow: var(--aether-popover-shadow);
  height: 28px;
  max-height: unset;
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  border: var(--spacing-zero);
  background: var(--background-color-primary);
}
.mimir .toolbar__anchor {
  height: auto;
  background: var(--background-color-primary);
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 100;
  border-radius: var(--border-radius-default);
  width: 100%;
  position: sticky;
  top: var(--spacing-zero);
  opacity: 1;
  visibility: visible;
  transition: opacity 100ms ease-in-out;
}
.mimir .toolbar__anchor.hide {
  display: none;
  opacity: 0;
}
.mimir .toolbar__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-zero) var(--spacing-s);
}
.mimir .toolbar__info {
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  margin: var(--spacing-s) var(--spacing-zero);
}
.mimir .toolbar__info:hover {
  background-color: var(--background-color-secondary);
}
.mimir .toolbar__info.active {
  background-color: var(--background-color-tertiary);
}
.mimir .toolbar__list {
  height: fit-content;
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  flex: 1;
  display: flex;
  overflow: hidden;
}
.mimir .toolbar__list .toolbar__list__divider--vertical {
  height: 50%;
  margin: auto var(--spacing-xs);
  border-left: 1px solid var(--border-color-default);
}
.mimir .toolbar__list .toolbar__list__divider--horizontal {
  width: 100%;
  height: 0;
  margin: var(--spacing-s) var(--spacing-zero);
  border-bottom: 1px solid var(--border-color-default);
}
.mimir .toolbar__list .toolbar__list__item {
  display: flex;
  align-items: center;
}
.mimir .toolbar__list .toolbar__list__item > * + * {
  margin-left: var(--spacing-xs);
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button {
  height: var(--size-s);
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton {
  height: var(--size-s);
  width: var(--size-s);
  border-radius: 3px;
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton svg {
  width: 12px;
  height: 12px;
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton svg * {
  fill: var(--content-color-secondary);
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton:hover {
  background: var(--highlight-background-color-secondary);
  color: var(--content-color-primary) !important;
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton:hover svg * {
  fill: var(--content-color-primary);
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton-active {
  background-color: var(--highlight-background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton-active svg * {
  fill: var(--content-color-primary);
}
.mimir .toolbar__list .postbot__trigger svg {
  color: var(--purple-50) !important;
}
.mimir .toolbar__list .postbot__trigger.dark svg {
  color: var(--purple-30) !important;
}
.mimir .toolbar__list .toolbar__dropdown__heading {
  display: flex;
  height: var(--size-s);
  max-width: 95px;
  border-radius: var(--border-radius-default);
  padding: 2px var(--spacing-xs);
  font-family: Inter;
  font-size: var(--text-size-m);
  font-style: normal;
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  letter-spacing: var(--spacing-zero);
  text-align: left;
  align-items: center;
  color: var(--content-color-secondary);
  cursor: pointer;
}
.mimir .toolbar__list .toolbar__dropdown__heading > * + * {
  margin-left: var(--spacing-xs);
}
.mimir .toolbar__list .toolbar__dropdown__heading:hover {
  background: var(--background-color-secondary);
  color: var(--content-color-secondary);
}
.mimir .toolbar__list .toolbar__dropdown__heading--active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir .toolbar__list .toolbar__dropdown__heading--disabled {
  color: var(--content-color-tertiary);
  opacity: 0.5;
  pointer-events: none;
}
.mimir .toolbar__list .toolbar__dropdown__heading--disabled svg {
  width: 12px;
  height: 12px;
}
.mimir .toolbar__list .toolbar__dropdown__heading--disabled svg * {
  fill: var(--content-color-tertiary);
}
.mimir .toolbar__list .toolbar__dropdown__heading--disabled:hover {
  background: var(--background-color-tertiary);
  color: var(--content-color-tertiary);
}
.mimir .toolbar__list .toolbar__dropdown__heading .title__truncated {
  min-width: 18px;
}
.mimir .toolbar__list .toolbar__dropdown__heading .title__full-size {
  min-width: 67px;
}
.mimir .toolbar__list .toolbar__dropdown__menu {
  display: flex;
  height: var(--size-s);
  border-radius: var(--border-radius-default);
  font-family: Inter;
  font-size: var(--text-size-m);
  font-style: normal;
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  letter-spacing: var(--spacing-zero);
  text-align: left;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--content-color-secondary);
}
.mimir .toolbar__list .toolbar__dropdown__menu > * + * {
  margin-left: var(--spacing-xs);
}
.mimir .toolbar__list .toolbar__dropdown__menu:hover {
  background: var(--background-color-secondary);
  color: var(--content-color-secondary);
}
.mimir .toolbar__list .toolbar__dropdown__menu--active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir .toolbar__list .toolbar__dropdown {
  display: flex;
  width: 200px;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  max-width: 200px;
  font-family: Inter;
  font-size: var(--text-size-m);
  font-style: normal;
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  letter-spacing: var(--spacing-zero);
  text-align: center;
  padding: 2px var(--spacing-zero);
  top: 30px;
  left: 2px;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-default);
}
.mimir .toolbar__list .toolbar__dropdown .slate-ToolbarButton {
  width: inherit !important;
  height: var(--size-m) !important;
  justify-content: flex-start;
  padding: var(--spacing-zero) var(--spacing-l);
  color: var(--content-color-secondary);
}
.mimir .toolbar__list .toolbar__dropdown .slate-ToolbarButton svg {
  width: 12px;
  height: 12px;
}
.mimir .toolbar__list .toolbar__dropdown .slate-ToolbarButton svg * {
  fill: var(--content-color-secondary);
}
.mimir .toolbar__list .toolbar__dropdown .slate-ToolbarButton:hover {
  background: var(--background-color-secondary);
  color: var(--content-color-secondary) !important;
}
.mimir .toolbar__list .toolbar__dropdown .slate-ToolbarButton-active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir .toolbar__list .toolbar__dropdown .slate-ToolbarButton-active svg * {
  fill: var(--content-color-primary);
}
.mimir .toolbar__list .toolbar__dropdown .toolbar-dropdown-icon {
  display: flex;
  position: relative;
  min-width: 100%;
}
.mimir .toolbar__list .toolbar__dropdown .toolbar-dropdown-shortcut-label {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  position: absolute;
  right: var(--spacing-zero);
}
.mimir .toolbar__list .toolbar-button--disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--size-s);
  width: var(--size-s);
  vertical-align: middle;
  color: var(--content-color-tertiary);
  opacity: 0.5;
  pointer-events: none;
}
.mimir .toolbar__list .toolbar-button--disabled svg {
  width: 12px;
  height: 12px;
}
.mimir .toolbar__list .toolbar-button--disabled svg * {
  fill: var(--content-color-tertiary);
}
.mimir .toolbar__list .toolbar__dropdown.code {
  left: 162px;
}
.mimir .toolbar__list .toolbar__dropdown.table {
  left: 72px;
}
.mimir .postbot__description__placeholder {
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
  color: var(--content-color-tertiary);
}
.mimir .editor__container {
  width: 100%;
  margin-top: var(--spacing-xs);
  height: max-content;
}
.mimir .editor__container > div {
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--spacing-l);
  color: var(--content-color-primary);
}
.mimir .editor__container > div > * {
  margin: var(--spacing-zero);
}
.mimir .editor__container > div > * + * {
  margin-top: var(--spacing-s);
}
.mimir .editor__container > div > *:first-child {
  margin-top: var(--spacing-zero) !important;
}
.mimir .editor__container > div p {
  word-break: break-word;
  padding: var(--spacing-zero);
}
.mimir .editor__container > div p span[data-slate-placeholder=true] {
  width: calc(100% - var(--spacing-l)) !important;
}
.mimir .editor__container > div p > * {
  margin: var(--spacing-zero);
}
.mimir .editor__container > div p > * + * {
  margin-top: var(--spacing-l);
}
.mimir .editor__container > div h1 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--text-weight-bold);
  margin-top: var(--spacing-xxl);
  color: var(--content-color-primary);
}
.mimir .editor__container > div h2 {
  font-size: 20px;
  line-height: var(--line-height-xxl);
  font-weight: var(--text-weight-bold);
  margin-top: var(--spacing-xl);
  color: var(--content-color-primary);
}
.mimir .editor__container > div h3 {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xl);
  font-weight: var(--text-weight-bold);
  margin-top: 20px;
  color: var(--content-color-primary);
}
.mimir .editor__container > div h4 {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-l);
  font-weight: var(--text-weight-bold);
  margin-top: var(--spacing-l);
  color: var(--content-color-primary);
}
.mimir .editor__container > div h5 {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-bold);
  color: var(--content-color-primary);
}
.mimir .editor__container > div h6 {
  font-size: var(--text-size-l);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-bold);
  color: var(--content-color-secondary);
}
.mimir .editor__container > div hr {
  height: 0;
  border-style: none;
  border-width: var(--spacing-zero);
  padding: var(--spacing-zero);
  margin-top: var(--spacing-l);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.mimir .editor__container > div .hr--selected hr {
  border-color: var(--blue-70);
}
.mimir .editor__container > div blockquote {
  padding: var(--spacing-zero);
  padding-left: var(--spacing-l);
  border-left: var(--border-width-xl) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-secondary);
  margin-top: var(--spacing-l);
}
.mimir .editor__container > div em {
  font-style: italic;
}
.mimir .editor__container > div strong {
  font-weight: var(--text-weight-bold);
}
.mimir .editor__container > div ul,
.mimir .editor__container > div ol {
  margin-left: var(--spacing-m);
  padding-left: var(--spacing-m);
  margin-top: var(--spacing-l);
}
.mimir .editor__container > div ul li,
.mimir .editor__container > div ol li {
  padding-left: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}
.mimir .editor__container > div ul p,
.mimir .editor__container > div ol p {
  margin: var(--spacing-zero);
}
.mimir .editor__container > div ul ul,
.mimir .editor__container > div ul ol,
.mimir .editor__container > div ol ul,
.mimir .editor__container > div ol ol {
  margin-top: var(--spacing-xs);
}
.mimir .editor__container > div ol li {
  list-style-type: decimal;
}
.mimir .editor__container > div ol li ol li {
  list-style-type: lower-alpha;
}
.mimir .editor__container > div ol li ol li ol li {
  list-style-type: lower-roman;
}
.mimir .editor__container > div ol li ol li ol li ol li {
  list-style-type: lower-alpha;
}
.mimir .editor__container > div ol li ol li ol li ol li ol li {
  list-style-type: lower-roman;
}
.mimir .editor__container > div code {
  padding: 1px var(--spacing-xs);
  font-size: var(--text-size-m);
  font-family: var(--text-family-code);
  background-color: var(--background-color-tertiary);
  line-height: 1.8;
}
.mimir .editor__container > div code p {
  margin: var(--spacing-zero);
}
.mimir .editor__container > div .highlighted-code code {
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.mimir .editor__container > div .slate-table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: overlay;
  overflow-y: hidden;
  cursor: default;
  margin-top: var(--spacing-l);
}
.mimir .editor__container > div table {
  width: 100%;
  border-radius: var(--border-radius-default);
  border-spacing: var(--size-m);
  border-collapse: collapse;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.mimir .editor__container > div table tr {
  height: var(--size-m);
  min-height: var(--size-m);
}
.mimir .editor__container > div table th,
.mimir .editor__container > div table td {
  min-width: 77px;
  max-width: 77px;
  padding: var(--spacing-s);
  word-break: normal;
  vertical-align: top;
  cursor: text;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  background: transparent;
}
.mimir .editor__container > div table th:first-child,
.mimir .editor__container > div table td:first-child {
  border-left: none;
}
.mimir .editor__container > div table th:last-child,
.mimir .editor__container > div table td:last-child {
  border-right: none;
}
.mimir .editor__container > div table th > div,
.mimir .editor__container > div table td > div {
  padding: 0 !important;
}
.mimir .editor__container > div table th .slate-TableCellElement-resizableWrapper,
.mimir .editor__container > div table td .slate-TableCellElement-resizableWrapper {
  display: none;
}
.mimir .editor__container > div table th .slate-TableCellElement-content,
.mimir .editor__container > div table td .slate-TableCellElement-content {
  z-index: 0 !important;
}
.mimir .editor__container > div table th {
  border-top: none;
  text-align: left;
  font-weight: 600;
  background-color: var(--background-color-secondary);
}
.mimir .editor__container > div table tr:last-child th,
.mimir .editor__container > div table tr:last-child td {
  border-bottom: none;
}
.mimir .editor__container > div img {
  display: block;
  max-width: 100%;
  height: auto;
}
.mimir .editor__container > div iframe {
  display: block;
  max-width: 100%;
}
.mimir .editor__container > div .slate-codeblock-wrapper {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: var(--spacing-l);
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-s);
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__control {
  margin-top: var(--spacing-zero) !important;
  width: 130px !important;
  background-color: var(--background-color-secondary);
  border: none;
  cursor: pointer;
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__control .css-b8ldur-Input {
  margin-top: var(--spacing-zero) !important;
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__indicators {
  margin-top: var(--spacing-zero) !important;
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__indicators .aether-dropdown__dropdown-indicator {
  margin-top: var(--spacing-zero) !important;
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__header__right {
  display: flex;
  margin: var(--spacing-zero);
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__header__right > * {
  margin: var(--spacing-zero);
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__button {
  margin-left: var(--spacing-s);
  border: var(--border-width-default) var(--border-style-solid) transparent;
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__button.word-wrapped {
  background: var(--highlight-background-color-primary);
  border-color: var(--border-color-strong);
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__content {
  padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
  margin: var(--spacing-zero);
  color: var(--content-color-primary);
  max-height: var(--container-max-height);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  position: relative;
  flex: 1;
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__content code {
  display: flex;
  flex-direction: column;
  color: var(--content-color-secondary) !important;
  background-color: transparent;
  border: none;
  border-radius: var(--spacing-zero);
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__content code.word-wrapped {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.mimir .editor__container > div .slate-codeblock-wrapper .codeblock__content code.word-wrapped .syntax-highlighted-code {
  white-space: pre-wrap;
}
.mimir .editor__container > div .slate-mention, .mimir .editor__container > div .slate-mention_input {
  margin-top: var(--spacing-zero);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) 2px;
}
.mimir .editor__container > div .slate-mention_input {
  background-color: var(--highlight-background-color-tertiary);
}

.mimir-small {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: visible;
  font-family: var(--text-family-default);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.mimir-small.edit-mode .media-wrapper {
  pointer-events: none;
}
.mimir-small.edit-mode .media-element:hover .media-handle::after {
  opacity: 1;
}
.mimir-small.edit-mode .media-element:hover .media-handle::after:hover::after, .mimir-small.edit-mode .media-element:hover .media-handle::after:focus::after, .mimir-small.edit-mode .media-element:hover .media-handle::after:active::after {
  opacity: 1;
}
.mimir-small.edit-mode .link-element {
  pointer-events: none;
}
.mimir-small.edit-mode .image-item {
  cursor: pointer;
}
.mimir-small .slate-HeadingToolbar {
  height: 40px;
  max-height: unset;
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  border: var(--spacing-zero);
  box-shadow: var(--shadow-default);
  border-radius: var(--border-radius-default) var(--border-radius-default) var(--spacing-zero) var(--spacing-zero);
  background: var(--background-color-primary);
}
.mimir-small .toolbar__anchor {
  width: 100%;
  position: absolute;
  top: var(--spacing-zero);
  z-index: 3;
  opacity: 1;
  visibility: visible;
  transition: opacity 100ms ease-in-out;
}
.mimir-small .toolbar__anchor.hide {
  display: none;
  opacity: 0;
}
.mimir-small .toolbar__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-zero) var(--spacing-s);
}
.mimir-small .toolbar__info {
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  margin: var(--spacing-s) var(--spacing-zero);
}
.mimir-small .toolbar__info:hover {
  background-color: var(--background-color-secondary);
}
.mimir-small .toolbar__info.active {
  background-color: var(--background-color-tertiary);
}
.mimir-small .toolbar__list {
  padding: var(--spacing-zero);
  margin: var(--spacing-zero);
  flex: 1;
  display: flex;
  overflow: hidden;
}
.mimir-small .toolbar__list .toolbar__list__divider--vertical {
  height: 50%;
  margin: auto var(--spacing-xs);
  border-left: 1px solid var(--border-color-default);
}
.mimir-small .toolbar__list .toolbar__list__divider--horizontal {
  width: 100%;
  height: 0;
  margin: var(--spacing-s) var(--spacing-zero);
  border-bottom: 1px solid var(--border-color-default);
}
.mimir-small .toolbar__list .toolbar__list__item {
  display: flex;
  align-items: center;
}
.mimir-small .toolbar__list .toolbar__list__item > * + * {
  margin-left: var(--spacing-xs);
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button {
  height: var(--size-s);
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton {
  height: var(--size-s);
  width: var(--size-s);
  border-radius: 3px;
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton svg {
  width: 12px;
  height: 12px;
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton svg * {
  fill: var(--content-color-secondary);
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton:hover {
  background: var(--highlight-background-color-secondary);
  color: var(--content-color-primary) !important;
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton:hover svg * {
  fill: var(--content-color-primary);
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton-active {
  background-color: var(--highlight-background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir-small .toolbar__list .toolbar__list__item .toolbar__button .slate-ToolbarButton-active svg * {
  fill: var(--content-color-primary);
}
.mimir-small .toolbar__list .toolbar__dropdown__heading {
  display: flex;
  height: var(--size-s);
  max-width: 95px;
  border-radius: var(--border-radius-default);
  padding: 2px var(--spacing-xs);
  font-family: Inter;
  font-size: var(--text-size-m);
  font-style: normal;
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  letter-spacing: var(--spacing-zero);
  text-align: left;
  align-items: center;
  color: var(--content-color-secondary);
}
.mimir-small .toolbar__list .toolbar__dropdown__heading > * + * {
  margin-left: var(--spacing-xs);
}
.mimir-small .toolbar__list .toolbar__dropdown__heading:hover {
  background: var(--background-color-secondary);
  color: var(--content-color-secondary);
}
.mimir-small .toolbar__list .toolbar__dropdown__heading--active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir-small .toolbar__list .toolbar__dropdown__heading--disabled {
  color: var(--content-color-tertiary);
  opacity: 0.5;
  pointer-events: none;
}
.mimir-small .toolbar__list .toolbar__dropdown__heading--disabled svg {
  width: 12px;
  height: 12px;
}
.mimir-small .toolbar__list .toolbar__dropdown__heading--disabled svg * {
  fill: var(--content-color-tertiary);
}
.mimir-small .toolbar__list .toolbar__dropdown__heading--disabled:hover {
  background: var(--background-color-tertiary);
  color: var(--content-color-tertiary);
}
.mimir-small .toolbar__list .toolbar__dropdown__heading .title__truncated {
  min-width: 18px;
}
.mimir-small .toolbar__list .toolbar__dropdown__heading .title__full-size {
  min-width: 67px;
}
.mimir-small .toolbar__list .toolbar__dropdown__menu {
  display: flex;
  height: var(--size-s);
  border-radius: var(--border-radius-default);
  font-family: Inter;
  font-size: var(--text-size-m);
  font-style: normal;
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  letter-spacing: var(--spacing-zero);
  text-align: left;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--content-color-secondary);
}
.mimir-small .toolbar__list .toolbar__dropdown__menu > * + * {
  margin-left: var(--spacing-xs);
}
.mimir-small .toolbar__list .toolbar__dropdown__menu:hover {
  background: var(--background-color-secondary);
  color: var(--content-color-secondary);
}
.mimir-small .toolbar__list .toolbar__dropdown__menu--active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir-small .toolbar__list .toolbar__dropdown {
  display: flex;
  width: 200px;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  max-width: 200px;
  font-family: Inter;
  font-size: var(--text-size-m);
  font-style: normal;
  font-weight: var(--text-weight-regular);
  line-height: var(--line-height-m);
  letter-spacing: var(--spacing-zero);
  text-align: center;
  padding: 2px var(--spacing-zero);
  top: 40px;
  left: 2px;
  background-color: var(--background-color-primary);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-default);
}
.mimir-small .toolbar__list .toolbar__dropdown .slate-ToolbarButton {
  width: inherit !important;
  height: var(--size-m) !important;
  justify-content: flex-start;
  padding: var(--spacing-zero) var(--spacing-l);
  color: var(--content-color-secondary);
}
.mimir-small .toolbar__list .toolbar__dropdown .slate-ToolbarButton svg {
  width: 12px;
  height: 12px;
}
.mimir-small .toolbar__list .toolbar__dropdown .slate-ToolbarButton svg * {
  fill: var(--content-color-secondary);
}
.mimir-small .toolbar__list .toolbar__dropdown .slate-ToolbarButton:hover {
  background: var(--background-color-secondary);
  color: var(--content-color-secondary) !important;
}
.mimir-small .toolbar__list .toolbar__dropdown .slate-ToolbarButton-active {
  background-color: var(--background-color-tertiary);
  color: var(--content-color-primary) !important;
}
.mimir-small .toolbar__list .toolbar__dropdown .slate-ToolbarButton-active svg * {
  fill: var(--content-color-primary);
}
.mimir-small .toolbar__list .toolbar__dropdown .toolbar-dropdown-icon {
  display: flex;
  position: relative;
  min-width: 100%;
}
.mimir-small .toolbar__list .toolbar__dropdown .toolbar-dropdown-shortcut-label {
  color: var(--content-color-tertiary);
  font-weight: var(--text-weight-regular);
  position: absolute;
  right: var(--spacing-zero);
}
.mimir-small .toolbar__list .toolbar-button--disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--size-s);
  width: var(--size-s);
  vertical-align: middle;
  color: var(--content-color-tertiary);
  opacity: 0.5;
  pointer-events: none;
}
.mimir-small .toolbar__list .toolbar-button--disabled svg {
  width: 12px;
  height: 12px;
}
.mimir-small .toolbar__list .toolbar-button--disabled svg * {
  fill: var(--content-color-tertiary);
}
.mimir-small .toolbar__list .toolbar__dropdown.code {
  left: 162px;
}
.mimir-small .toolbar__list .toolbar__dropdown.table {
  left: 72px;
}
.mimir-small .editor__container {
  position: absolute;
  width: 100%;
  margin-top: 40px;
  height: calc(100% - 40px);
}
.mimir-small .editor__container > div {
  max-height: calc(100% - 64px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--spacing-l);
  color: var(--content-color-primary);
}
.mimir-small .editor__container div {
  /**
  * As there we no mockups in artemis-v2 Figma file, copied the
  * same styles as present in the public & private documentation
  */
}
.mimir-small .editor__container div > * {
  margin: var(--spacing-zero);
}
.mimir-small .editor__container div > * + * {
  margin-top: var(--spacing-l);
}
.mimir-small .editor__container div p {
  word-break: break-word;
  padding: var(--spacing-zero);
  margin: 1em var(--spacing-zero);
}
.mimir-small .editor__container div p span[data-slate-placeholder=true] {
  width: calc(100% - var(--spacing-l)) !important;
}
.mimir-small .editor__container div p > * {
  margin: var(--spacing-zero);
}
.mimir-small .editor__container div p > * + * {
  margin-top: var(--spacing-l);
}
.mimir-small .editor__container div h1,
.mimir-small .editor__container div h2,
.mimir-small .editor__container div h3,
.mimir-small .editor__container div h4,
.mimir-small .editor__container div h5,
.mimir-small .editor__container div h6 {
  color: var(--content-color-primary);
  font-weight: var(--text-weight-medium);
}
.mimir-small .editor__container div h1 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
}
.mimir-small .editor__container div h2 {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
}
.mimir-small .editor__container div h3 {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
}
.mimir-small .editor__container div h4 {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.mimir-small .editor__container div h5 {
  font-size: 0.83em;
}
.mimir-small .editor__container div h6 {
  font-size: 0.67em;
}
.mimir-small .editor__container div ul li {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--text-weight-regular);
}
.mimir-small .editor__container div h1 * {
  font-size: var(--text-size-xxxl);
  line-height: var(--line-height-xxxl);
}
.mimir-small .editor__container div h2 * {
  font-size: var(--text-size-xxl);
  line-height: var(--line-height-xxl);
}
.mimir-small .editor__container div h3 * {
  font-size: var(--text-size-xl);
  line-height: var(--line-height-xl);
}
.mimir-small .editor__container div h4 * {
  font-size: var(--text-size-l);
  line-height: var(--line-height-l);
}
.mimir-small .editor__container div h5 * {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
}
.mimir-small .editor__container div h6 * {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}
.mimir-small .editor__container div hr {
  height: 0;
  border-style: none;
  border-width: var(--spacing-zero);
  padding: var(--spacing-zero);
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.mimir-small .editor__container div .hr--selected hr {
  border-color: var(--blue-70);
}
.mimir-small .editor__container div blockquote {
  padding: var(--spacing-zero);
  padding-left: var(--spacing-l);
  border-left: var(--border-width-xl) var(--border-style-solid) var(--border-color-default);
  color: var(--content-color-secondary);
}
.mimir-small .editor__container div em {
  font-style: italic;
}
.mimir-small .editor__container div strong {
  font-weight: var(--text-weight-medium);
}
.mimir-small .editor__container div ul,
.mimir-small .editor__container div ol {
  margin-left: var(--spacing-s);
  padding-left: var(--spacing-m);
}
.mimir-small .editor__container div ul p,
.mimir-small .editor__container div ol p {
  margin: var(--spacing-zero);
}
.mimir-small .editor__container div ol li {
  list-style-type: decimal;
}
.mimir-small .editor__container div ol li ol li {
  list-style-type: lower-alpha;
}
.mimir-small .editor__container div ol li ol li ol li {
  list-style-type: lower-roman;
}
.mimir-small .editor__container div ol li ol li ol li ol li {
  list-style-type: lower-alpha;
}
.mimir-small .editor__container div ol li ol li ol li ol li ol li {
  list-style-type: lower-roman;
}
.mimir-small .editor__container div code {
  padding: 1px var(--spacing-xs);
  font-size: var(--text-size-m);
  font-family: var(--text-family-code);
  background-color: var(--background-color-tertiary);
}
.mimir-small .editor__container div code p {
  margin: var(--spacing-zero);
}
.mimir-small .editor__container div .highlighted-code code {
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.mimir-small .editor__container div .slate-table-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: overlay;
  overflow-y: hidden;
  cursor: default;
}
.mimir-small .editor__container div table {
  width: 100%;
  border-radius: var(--border-radius-default);
  border-spacing: var(--size-m);
  border-collapse: collapse;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
}
.mimir-small .editor__container div table tr {
  height: var(--size-m);
  min-height: var(--size-m);
}
.mimir-small .editor__container div table th,
.mimir-small .editor__container div table td {
  min-width: 77px;
  max-width: 77px;
  padding: var(--spacing-s);
  word-break: normal;
  vertical-align: top;
  cursor: text;
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  background: transparent;
}
.mimir-small .editor__container div table th:first-child,
.mimir-small .editor__container div table td:first-child {
  border-left: none;
}
.mimir-small .editor__container div table th:last-child,
.mimir-small .editor__container div table td:last-child {
  border-right: none;
}
.mimir-small .editor__container div table th > div,
.mimir-small .editor__container div table td > div {
  padding: 0 !important;
}
.mimir-small .editor__container div table th .slate-TableCellElement-resizableWrapper,
.mimir-small .editor__container div table td .slate-TableCellElement-resizableWrapper {
  display: none;
}
.mimir-small .editor__container div table th .slate-TableCellElement-content,
.mimir-small .editor__container div table td .slate-TableCellElement-content {
  z-index: 0 !important;
}
.mimir-small .editor__container div table th {
  border-top: none;
  text-align: left;
  font-weight: 600;
  background-color: var(--background-color-secondary);
}
.mimir-small .editor__container div table tr:last-child th,
.mimir-small .editor__container div table tr:last-child td {
  border-bottom: none;
}
.mimir-small .editor__container div img {
  display: block;
  max-width: 100%;
  height: auto;
}
.mimir-small .editor__container div iframe {
  display: block;
  max-width: 100%;
}
.mimir-small .editor__container div .slate-codeblock-wrapper {
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  border-radius: var(--border-radius-default);
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-s);
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__control {
  margin-top: var(--spacing-zero) !important;
  width: 130px !important;
  background-color: var(--background-color-secondary);
  border: none;
  cursor: pointer;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__control .css-b8ldur-Input {
  margin-top: var(--spacing-zero) !important;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__indicators {
  margin-top: var(--spacing-zero) !important;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__header__left .codeblock__dropdown .aether-dropdown__indicators .aether-dropdown__dropdown-indicator {
  margin-top: var(--spacing-zero) !important;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__header__right {
  display: flex;
  margin: var(--spacing-zero);
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__header__right > * {
  margin: var(--spacing-zero);
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__button {
  margin-left: var(--spacing-s);
  border: var(--border-width-default) var(--border-style-solid) transparent;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__button.word-wrapped {
  background: var(--highlight-background-color-primary);
  border-color: var(--border-color-strong);
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__content {
  padding: var(--spacing-zero) var(--spacing-m) var(--spacing-m) var(--spacing-m);
  margin: var(--spacing-zero);
  color: var(--content-color-primary);
  max-height: var(--container-max-height);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  position: relative;
  flex: 1;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__content code {
  display: flex;
  flex-direction: column;
  color: var(--content-color-secondary) !important;
  background-color: transparent;
  border: none;
  border-radius: var(--spacing-zero);
  font-family: var(--text-family-code);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin: var(--spacing-zero);
  padding: var(--spacing-zero);
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__content code.word-wrapped {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.mimir-small .editor__container div .slate-codeblock-wrapper .codeblock__content code.word-wrapped .syntax-highlighted-code {
  white-space: pre-wrap;
}
.mimir-small .editor__container div .slate-mention, .mimir-small .editor__container div .slate-mention_input {
  margin-top: var(--spacing-zero);
  font-size: var(--text-size-m);
  padding: var(--spacing-zero) 2px;
}
.mimir-small .editor__container div .slate-mention_input {
  background-color: var(--highlight-background-color-tertiary);
}

.toolbar__tooltip-body {
  margin: var(--spacing-s) var(--spacing-m);
  font-family: Inter;
  font-style: normal;
  font-weight: var(--text-weight-regular);
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  text-align: center;
  color: var(--content-color-primary);
}
.toolbar__tooltip-body-shortcutKeys {
  color: var(--content-color-tertiary);
}

.prism-token {
  /** prism-coy theme */
}
.prism-token.token.comment, .prism-token.token.block-comment, .prism-token.token.prolog, .prism-token.token.doctype, .prism-token.token.cdata {
  color: #7d8b99;
}
.prism-token.token.punctuation {
  color: #5f6364;
}
.prism-token.token.tag {
  padding: 0 !important;
}
.prism-token.token.property, .prism-token.token.tag, .prism-token.token.boolean, .prism-token.token.number, .prism-token.token.function-name, .prism-token.token.constant, .prism-token.token.symbol, .prism-token.token.deleted {
  color: #c92c2c;
}
.prism-token.token.selector, .prism-token.token.attr-name, .prism-token.token.string, .prism-token.token.char, .prism-token.token.function, .prism-token.token.builtin, .prism-token.token.inserted {
  color: #2f9c0a;
}
.prism-token.token.operator, .prism-token.token.entity, .prism-token.token.url, .prism-token.token.variable {
  color: #a67f59;
}
.prism-token.token.atrule, .prism-token.token.attr-value, .prism-token.token.keyword, .prism-token.token.class-name {
  color: #1990b8;
}
.prism-token.token.regex, .prism-token.token.important {
  color: #e90;
}
.prism-token.language-css .token.string, .prism-token.style .token.string {
  color: #a67f59;
}
.prism-token.token.important {
  font-weight: normal;
}
.prism-token.token.bold {
  font-weight: bold;
}
.prism-token.token.italic {
  font-style: italic;
}
.prism-token.token.entity {
  cursor: help;
}
.prism-token.token.namespace {
  opacity: 0.7;
}

.token.tag {
  padding: 0 !important;
}

comment {
  display: none;
}

.mimir-settings-modal .modal-content {
  overflow-y: visible;
}

.mimir-loading-indicator {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slate-toolbar-tooltip .arrow {
  filter: drop-shadow(-2px 3px 2px rgba(0, 0, 0, 0.1));
}

.image-element {
  animation: fadeIn 300ms ease-in-out;
  margin-top: var(--spacing-l) !important;
}
.image-element.selected .image-item {
  box-shadow: rgb(59, 130, 249) 0px 0px 0px 1px;
  opacity: 0.85;
}
.image-element .image-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 2px;
}
.image-element .image-item {
  animation: fadeIn 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  margin: auto;
  cursor: zoom-in;
}
.image-element .image-resize:hover .image-handle::after {
  opacity: 1;
}
.image-element .image-resize .image-caption {
  width: 100%;
  border-style: none;
  margin-top: var(--spacing-s);
  padding: 0px;
  resize: none;
  text-align: center;
  overflow: hidden;
  background-color: transparent;
  color: var(--content-color-primary);
}
.image-element .image-resize .image-caption:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.image-element .image-resize .image-caption:focus::placeholder {
  opacity: 0;
}
.image-element .image-resize .image-caption + div {
  margin-top: 0;
}
.image-element .image-resize .image-handle {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  user-select: none;
  width: 1.5rem;
  height: 100%;
  top: 0px;
  z-index: 10;
}
.image-element .image-resize .image-handle::after {
  display: flex;
  background-color: var(--content-color-info);
  content: " ";
  width: 3px;
  height: 64px;
  max-height: 30%;
  border-radius: 6px;
  opacity: 0.3;
}
.image-element .image-resize .image-handle:hover::after, .image-element .image-resize .image-handle:focus::after, .image-element .image-resize .image-handle:active::after {
  background-color: var(--content-color-info);
  opacity: 1;
}
.image-element .image-resize .image-handle.handle-left {
  left: -0.1rem;
}
.image-element .image-resize .image-handle.handle-right {
  align-items: flex-end;
  right: -0.4rem;
}
.image-element .image-loader {
  background-color: var(--background-color-secondary);
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-default);
  animation: fadeIn 300ms ease-in-out;
}
.image-element .image-loader.image-loader-data {
  position: relative;
  width: auto;
  min-height: auto;
}
.image-element .image-loader.image-loader-data .image-loader-preview {
  filter: blur(3px);
}
.image-element .image-loader.image-loader-data .image-loader-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-element .image-loader .image-loader-progress {
  width: 80%;
  height: 10px;
  position: relative;
  overflow: hidden;
  border-radius: calc(2 * var(--border-color-default));
  background-color: var(--background-color-primary);
}
.image-element .image-loader .image-loader-progress-bar {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  justify-self: end;
  background-color: var(--highlight-background-color-tertiary);
  border-radius: calc(2 * var(--border-color-default));
}

.media-element {
  animation: fadeIn 300ms ease-in-out;
  margin-top: var(--spacing-l) !important;
}
.media-element.selected .media-item {
  box-shadow: rgb(59, 130, 249) 0px 0px 0px 1px;
  opacity: 0.85;
}
.media-element .media-plus-caption-wrapper {
  display: flex;
  justify-content: center;
}
.media-element .media-item {
  border-style: none;
  animation: fadeIn 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  cursor: pointer;
}
.media-element .media-resize:hover .media-handle::after {
  opacity: 0;
}
.media-element .media-resize.iFrameLoading {
  display: none;
}
.media-element .media-resize .media-caption {
  width: 100%;
  border-style: none;
  margin-top: var(--spacing-s);
  padding-bottom: var(--spacing-s);
  resize: none;
  text-align: center;
  overflow: hidden;
  background-color: transparent;
  color: var(--content-color-primary);
}
.media-element .media-resize .media-caption:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.media-element .media-resize .media-caption + div {
  margin-top: 0;
}
.media-element .media-resize .media-handle {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  user-select: none;
  width: 1.5rem;
  height: 100%;
  top: 0px;
  z-index: 10;
}
.media-element .media-resize .media-handle::after {
  opacity: 0;
  display: flex;
  background-color: var(--content-color-info);
  content: " ";
  width: 3px;
  height: 64px;
  max-height: 30%;
  border-radius: 6px;
}
.media-element .media-resize .media-handle:hover::after, .media-element .media-resize .media-handle:focus::after, .media-element .media-resize .media-handle:active::after {
  background-color: var(--content-color-info);
  opacity: 0;
}
.media-element .media-resize .media-handle.handle-left {
  left: -0.1rem;
}
.media-element .media-resize .media-handle.handle-right {
  align-items: flex-end;
  right: -0.4rem;
}
.media-element .media-loader {
  background-color: var(--background-color-secondary);
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-default);
  cursor: pointer;
}

.link-element .image-item {
  cursor: pointer;
}

.toolbar-shortcut-label {
  color: var(--content-color-tertiary);
}

.link-element {
  color: var(--content-color-link);
  text-decoration: initial;
}
.link-element :hover,
.link-element :visited:hover {
  text-decoration: underline;
}

.table-balloon-container {
  position: absolute;
  cursor: pointer;
  z-index: 4;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-balloon-container .table__cell__action {
  width: var(--spacing-m);
  height: var(--size-xs);
  padding: 0 2px;
  border-radius: 3px;
  background-color: transparent;
}
.table-balloon-container .table__cell__action--active {
  background-color: var(--highlight-background-color-primary);
}
.table-balloon-container .table__cell__action:hover {
  background-color: var(--highlight-background-color-primary);
}

.table__cell__dropdown {
  position: absolute;
  display: none;
  opacity: 0;
  width: 135px;
  z-index: 4;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: opacity 200ms ease-out, top 75ms ease-out, left 75ms ease-out;
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-default);
  border: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  box-shadow: var(--shadow-default);
  height: fit-content;
}
.table__cell__dropdown--visible {
  display: flex;
}
.table__cell__dropdown .toolbar__cell__action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--spacing-s) 10px;
}
.table__cell__dropdown .toolbar__cell__action:hover {
  background-color: var(--highlight-background-color-primary);
}
.table__cell__dropdown .toolbar__cell__action .slate-ToolbarButton {
  width: 100%;
  height: auto;
  justify-content: flex-start;
  background-color: transparent;
}
.table__cell__dropdown .toolbar__cell__action .slate-ToolbarButton:hover {
  background-color: transparent;
}
.table__cell__dropdown .toolbar__cell__action svg path {
  stroke: var(--content-color-secondary);
}
.table__cell__dropdown .toolbar__cell__action--deltab svg path {
  fill: var(--content-color-error);
  stroke: unset;
}
.table__cell__dropdown .toolbar__cell__action--deltab .icon__label {
  color: var(--content-color-error);
}

.table-balloon-container .slate-ToolbarButton {
  border-radius: var(--border-width-xl);
  background-color: transparent;
}

.table-balloon-container .slate-ToolbarButton-active {
  background-color: var(--highlight-background-color-primary);
}

.table-balloon-container .slate-ToolbarButton:hover {
  background-color: var(--highlight-background-color-primary);
}

.icon__label {
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  margin-left: var(--spacing-s);
  color: var(--content-color-primary);
}

.image-balloon-container {
  position: absolute;
  box-shadow: var(--shadow-default);
  border-radius: var(--border-width-xl);
  background: var(--background-color-primary);
  z-index: 2;
  padding: var(--spacing-m) !important;
  align-items: center;
  color: var(--content-color-link);
  display: flex;
  width: 350px;
}
.image-balloon-container button {
  white-space: nowrap;
}
.image-balloon-container.small {
  width: 255px;
}
.image-balloon-container.auto {
  width: auto;
  padding: var(--spacing-zero) !important;
}
.image-balloon-container--legacy {
  width: 300px;
  padding: var(--spacing-s);
}
.image-balloon-container .image-close-btn {
  position: absolute;
  top: var(--spacing-s);
  right: var(--spacing-s);
}
.image-balloon-container .image-balloon-tabs {
  width: 100%;
  padding: var(--spacing-zero);
}
.image-balloon-container .image-balloon-banner {
  margin-top: var(--spacing-m);
}
.image-balloon-container .image-balloon-loader {
  width: 68px;
  height: 38px;
  line-height: 36px;
}
.image-balloon-container input:disabled + label[for*=mimir-file-uploader] {
  opacity: 0.5;
}
.image-balloon-container label[for*=mimir-file-uploader] {
  margin-top: var(--spacing-zero);
  color: var(--content-color-constant);
  background-color: var(--base-color-brand);
  height: 38px;
  line-height: 36px;
  font-weight: bold;
}
.image-balloon-container label[for*=mimir-file-uploader] + div {
  display: none;
}
.image-balloon-container .image-url-input {
  flex-grow: 1;
  background: var(--background-color-secondary);
  border: 1px solid var(--content-color-tertiary);
  border-radius: var(--border-width-xl);
  padding: 3px var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: 18px;
}
.image-balloon-container .image-url-input::placeholder {
  color: var(--content-color-tertiary);
}
.image-balloon-container .text-button {
  cursor: pointer;
  font-size: var(--text-size-m);
  height: var(--size-s);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px var(--spacing-xs) !important;
  margin: 0px 0px 0px var(--spacing-s) !important;
  color: var(--content-color-secondary) !important;
}
.image-balloon-container .text-button.text-button--disabled {
  color: var(--content-color-tertiary) !important;
  cursor: not-allowed;
}
.image-balloon-container .text-button svg,
.image-balloon-container .text-button path {
  fill: var(--content-color-secondary) !important;
}
.image-balloon-container .text-button svg {
  color: var(--content-color-secondary) !important;
}
.image-balloon-container .icon-button {
  height: var(--size-s);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-size-m) !important;
  line-height: 18px !important;
  cursor: pointer;
  margin: 0px var(--spacing-s) !important;
}
.image-balloon-container .icon-button svg,
.image-balloon-container .icon-button path {
  fill: var(--content-color-secondary) !important;
}
.image-balloon-container .icon-button svg {
  color: var(--content-color-secondary) !important;
}
.image-balloon-container .usage-container {
  background-color: var(--background-color-secondary);
  padding: var(--spacing-s);
  margin-top: var(--spacing-s);
  border-radius: var(--border-radius-default);
  width: 100%;
  color: var(--content-color-secondary);
  animation: fadeIn 300ms ease-in-out;
}
.image-balloon-container .usage-container .file-usage-bars {
  position: relative;
  height: 5px;
}
.image-balloon-container .usage-container .file-usage-bars .file-limit,
.image-balloon-container .usage-container .file-usage-bars .file-usage {
  position: absolute;
  left: 0;
  height: 100%;
  border-radius: var(--border-radius-default);
}
.image-balloon-container .usage-container .file-usage-bars .file-limit {
  background-color: var(--background-color-tertiary);
  width: 100%;
}
.image-balloon-container .usage-container .file-usage-bars .file-usage {
  background-color: var(--base-color-success);
  width: 0;
  transition: width 500ms ease-in-out;
  cursor: pointer;
}
.image-balloon-container .usage-container .file-usage-bars .file-usage--warning {
  background-color: var(--base-color-warning);
}
.image-balloon-container .usage-container .file-usage-bars .file-usage--error {
  background-color: var(--base-color-error);
}
.image-balloon-container .usage-container .file-usage-text {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-zero);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

.link-balloon-container {
  position: absolute;
  box-shadow: var(--shadow-default);
  border-radius: var(--border-width-xl);
  background: var(--background-color-primary);
  z-index: 2;
  margin-top: var(--spacing-l);
  align-items: center;
  transition: top 75ms ease-out, left 75ms ease-out;
  width: 350px;
  padding: var(--spacing-s) !important;
  overflow: hidden;
}
.link-balloon-container.small, .link-balloon-container.edit {
  width: 255px;
  padding: var(--spacing-xs) var(--spacing-s) !important;
}
.link-balloon-container .link-close-btn {
  position: absolute;
  top: var(--spacing-s);
  right: var(--spacing-s);
  padding: 0 var(--spacing-xs);
  height: 24px;
}
.link-balloon-container .has-error {
  padding-bottom: 20px;
}
.link-balloon-container .link-input::placeholder {
  color: var(--content-color-tertiary);
}
.link-balloon-container .link-input.link-url-input {
  color: var(--content-color-link);
}
.link-balloon-container .link-unlink-btn svg {
  width: 16px;
  height: 16px;
}
.link-balloon-container .link-label {
  flex: 1;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.media-balloon-container {
  position: absolute;
  box-shadow: var(--shadow-default);
  border-radius: var(--border-width-xl);
  background: var(--background-color-primary);
  z-index: 2;
  padding: var(--spacing-m) !important;
  align-items: center;
  color: var(--content-color-link);
  display: flex;
  width: 350px;
}
.media-balloon-container button {
  white-space: nowrap;
}
.media-balloon-container.small {
  width: 255px;
}
.media-balloon-container.auto {
  width: auto;
  padding: var(--spacing-zero) !important;
}
.media-balloon-container input:disabled + label[for*=mimir-file-uploader] {
  opacity: 0.5;
}
.media-balloon-container label[for*=mimir-file-uploader] {
  margin-top: var(--spacing-zero);
  color: var(--content-color-constant);
  background-color: var(--base-color-brand);
  height: 38px;
  line-height: 36px;
  font-weight: bold;
}
.media-balloon-container label[for*=mimir-file-uploader] + div {
  display: none;
}
.media-balloon-container .media-url-input {
  flex-grow: 1;
  background: var(--background-color-secondary);
  border: 1px solid var(--content-color-tertiary);
  border-radius: var(--border-width-xl);
  padding: 3px var(--spacing-s);
  font-size: var(--text-size-m);
  line-height: 18px;
}
.media-balloon-container .media-url-input::placeholder {
  color: var(--content-color-tertiary);
}
.media-balloon-container .text-button {
  cursor: pointer;
  font-size: var(--text-size-m);
  height: var(--size-s);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px var(--spacing-xs) !important;
  margin: 0px 0px 0px var(--spacing-s) !important;
  color: var(--content-color-secondary) !important;
}
.media-balloon-container .text-button.text-button--disabled {
  color: var(--content-color-tertiary) !important;
  cursor: not-allowed;
}
.media-balloon-container .text-button svg,
.media-balloon-container .text-button path {
  fill: var(--content-color-secondary) !important;
}
.media-balloon-container .text-button svg {
  color: var(--content-color-secondary) !important;
}
.media-balloon-container .icon-button {
  height: var(--size-s);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-size-m) !important;
  line-height: 18px !important;
  cursor: pointer;
  margin: 0px var(--spacing-s) !important;
}
.media-balloon-container .icon-button svg,
.media-balloon-container .icon-button path {
  fill: var(--content-color-secondary) !important;
}
.media-balloon-container .icon-button svg {
  color: var(--content-color-secondary) !important;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

.delete-ws-confirmation-modal-ws-name {
  word-break: break-all;
  font-weight: var(--text-weight-medium);
}

.context-bar-code-snippet-view {
  display: flex;
  flex-direction: column;
  height: inherit;
}

.context-bar-code-snippet-body {
  display: flex;
  flex-direction: column;
  height: inherit;
  padding: var(--spacing-zero) var(--spacing-s);
}
.context-bar-code-snippet-body .context-bar-codegen-controls {
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: space-between;
}
.context-bar-code-snippet-body .context-bar-codegen-controls .context-bar-codegen-controls-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.context-bar-code-snippet-body .context-bar-codegen-controls .context-bar-codegen-controls-left .context-bar-codegen-controls-left__setting {
  display: flex;
  margin-left: var(--spacing-m);
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  padding-right: var(--spacing-l);
}
.context-bar-code-snippet-body .context-bar-codegen-controls .context-bar-codegen-controls-left .context-bar-codegen-controls-left__setting .language-settings-icon {
  cursor: pointer;
}
.context-bar-code-snippet-body .context-bar-codegen-controls .context-bar-codegen-controls-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.context-bar-code-snippet-body .context-bar-codegen-controls .context-bar-codegen-controls-right .btn {
  width: var(--size-m);
  height: var(--size-m);
}
.context-bar-code-snippet-body .context-bar-codegen-controls .context-bar-codegen-controls-right .context-bar-codegen-controls-right__copy {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}
.context-bar-code-snippet-body .context-bar-codegen-controls .context-bar-codegen-controls-right .context-bar-codegen-controls-right__copy .code-copy-icon {
  cursor: pointer;
}
.context-bar-code-snippet-body .context-bar-code-snippet-editor {
  display: flex;
  margin-top: var(--spacing-l);
  height: inherit;
}

.context-bar-dropdown-wrapper {
  padding: 0px;
  width: 200px;
}

.audit-logs-export-toast {
  margin-right: var(--spacing-xxl);
}

.generic-sidebar-dropdown-actions-container {
  width: 201px;
}

.generic-sidebar-list .api-sidebar-list__item-empty-state {
  padding: var(--spacing-s) var(--spacing-l);
}
.generic-sidebar-list .generic-sidebar-item-loader {
  display: flex;
  height: 28px;
  padding: var(--spacing-zero) var(--spacing-s);
}
.generic-sidebar-list .generic-sidebar-item-loader__loading {
  animation: fade 1s ease-in-out infinite;
  display: flex;
  align-items: center;
  padding: var(--spacing-s) 0;
  margin-left: var(--spacing-xl);
}
.generic-sidebar-list .generic-sidebar-item-loader__icon {
  height: 8px;
  width: 8px;
  background-color: var(--highlight-background-color-secondary);
  margin-left: var(--spacing-s);
  border-radius: var(--border-radius-s);
  margin-right: var(--spacing-m);
}
.generic-sidebar-list .generic-sidebar-item-loader__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100px;
  height: 8px;
  background-color: var(--highlight-background-color-secondary);
  border-radius: var(--border-radius-s);
}
.generic-sidebar-list:hover .generic-sidebar-list__item__left-section__indent {
  border-right: thin solid var(--border-color-default);
}

.generic-sidebar-list-item {
  display: flex;
  height: 28px;
  align-items: center;
  font-size: var(--text-size-m);
  flex: 1;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--content-color-primary);
  padding: var(--spacing-zero) var(--spacing-s);
}
.generic-sidebar-list-item button[data-testid=sidebar-action-btn] {
  width: 24px;
  height: 24px;
}
.generic-sidebar-list-item button[data-testid=sidebar-action-btn]:hover {
  background-color: var(--sidebar-button-tertiary-hover-background-color);
}
.generic-sidebar-list-item button[data-testid=sidebar-action-btn]:active {
  background-color: var(--sidebar-button-tertiary-active-background-color);
}
.generic-sidebar-list-item:hover, .generic-sidebar-list-item.is-hovered {
  background-color: var(--highlight-background-color-secondary);
}
.generic-sidebar-list-item.is-active {
  background-color: var(--highlight-background-color-secondary);
}
.generic-sidebar-list-item:focus, .generic-sidebar-list-item.is-focused {
  background-color: var(--background-color-brand);
  border-radius: var(--border-radius-default);
}
.generic-sidebar-list-item.selected {
  color: var(--content-color-primary);
  background-color: var(--highlight-background-color-tertiary);
}
.generic-sidebar-list-item__icon-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: var(--size-xs);
  min-width: var(--size-s);
}
.generic-sidebar-list-item__name-wrapper {
  line-height: var(--line-height-m);
  display: flex;
  min-width: 0px;
  margin-right: var(--spacing-xs);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.generic-sidebar-list-item__name-wrapper .inline-input {
  height: var(--size-s);
}
.generic-sidebar-list-item__name-wrapper:hover {
  color: var(--content-color-primary);
}
.generic-sidebar-list-item__name-wrapper.use-browser-tabs {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.generic-sidebar-list-item__name-wrapper__icon-after-text-container {
  margin-left: var(--spacing-xs);
}
.generic-sidebar-list-item__content-wrapper.edit-mode .generic-sidebar-list-item__name-wrapper {
  margin-right: var(--spacing-xs);
  width: 100%;
}
.generic-sidebar-list-item__content-wrapper.edit-mode .inline-input__wrapper {
  width: 100%;
}
.generic-sidebar-list-item__status-indicators {
  display: flex;
}
.generic-sidebar-list-item__status-indicators i {
  margin-right: var(--spacing-xs);
}
.generic-sidebar-list-item__link {
  flex: 1;
  overflow: hidden;
}
.generic-sidebar-list-item__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.generic-sidebar-list-item.focused.is-sidebar-in-focus {
  box-shadow: var(--sidebar-item-shadow-focus);
}
.generic-sidebar-list-item .name {
  margin-right: var(--spacing-xs);
}
.generic-sidebar-list-item.selected {
  background-color: var(--highlight-background-color-primary);
}
.generic-sidebar-list-item.selected.is-sidebar-in-focus {
  background-color: var(--highlight-background-color-tertiary);
  box-shadow: 2px 0px 0 var(--base-color-brand) inset;
}
.generic-sidebar-list-item.selected.is-sidebar-in-focus.focused {
  box-shadow: 2px 0px 0 var(--base-color-brand) inset, var(--sidebar-item-shadow-focus);
}
.generic-sidebar-list-item.drop-hovered-on {
  box-shadow: inset 0px 0px 0px 2px var(--base-color-brand);
}
.generic-sidebar-list-item.drop-hovered-top {
  box-shadow: 0 2px 0 var(--base-color-brand) inset;
}
.generic-sidebar-list-item.drop-hovered-bottom {
  box-shadow: 0 2px 0 var(--base-color-brand);
}

.generic-sidebar-list__item__empty-state {
  margin-left: calc(var(--spacing-l) + var(--spacing-xs));
  font-size: var(--text-size-m);
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
  padding: var(--spacing-s) var(--spacing-l) var(--spacing-s) var(--spacing-l);
}
.generic-sidebar-list__item__left-section {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.generic-sidebar-list__item__left-section__indent {
  border-right: thin solid transparent;
}
.generic-sidebar-list__item__left-section__toggle-list {
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
  max-height: var(--size-xs);
  width: var(--size-xs);
}
.generic-sidebar-list__item__left-section__toggle-list use {
  fill: var(--content-color-primary);
}
.generic-sidebar-list__item__left-section__toggle-list svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.generic-sidebar-list__item__left-section__toggle-list svg {
  color: var(--content-color-primary);
}
.generic-sidebar-list__item__left-section__toggle-list-placeholder {
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
  height: var(--size-xs);
  width: var(--size-xs);
  background-color: unset;
}
.generic-sidebar-list__item__left-section__entity-type-icon {
  overflow: hidden;
  margin-right: var(--spacing-xs);
}
.generic-sidebar-list__item__left-section .is-left-meta-visible {
  display: flex;
  visibility: visible;
}
.generic-sidebar-list__item__left-section .is-left-meta-not-visible {
  visibility: hidden;
}

.generic-sidebar-list-item__body--empty {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  color: var(--content-color-secondary);
  display: flex;
  padding: var(--spacing-zero) var(--spacing-s);
}

.generic-sidebar-list-item__icon-container {
  justify-content: start;
  padding-right: var(--spacing-zero);
}

.generic-sidebar-list-item__name {
  padding-right: var(--spacing-xs);
}

.generic-sidebar-list-item__content-wrapper {
  margin-left: var(--spacing-xs);
}

.generic-sidebar-list-item__name-wrapper {
  align-items: center;
}

.generic-sidebar-list-item__actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.key-value-form-row__input_warning_errors {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: #b89500;
  padding-bottom: 1px;
  text-underline-offset: 1px;
}

body {
  background-color: var(--background-color-primary);
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
}

.console-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.console-container .separator {
  margin: var(--spacing-zero) var(--spacing-xs);
  background-color: var(--border-color-default);
  height: var(--size-xs);
  width: var(--border-width-default);
}

.console__text-editor__container {
  display: flex;
}
.console__text-editor__container .console__text-editor {
  display: flex;
  flex: 1;
}

.console__text-editor__header {
  display: flex;
}
.console__text-editor__header .truncated-info__button {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: var(--spacing-xs);
}
.console__text-editor__header .truncated-info__button .truncated-info__icon use {
  fill: var(--content-color-tertiary);
}
.console__text-editor__header .truncated-info__button .truncated-info__icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-tertiary);
}
.console__text-editor__header .truncated-info__button .truncated-info__icon svg {
  color: var(--content-color-tertiary);
}

.console__blob-viewer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-color-primary);
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.console__blob-viewer .is-hidden {
  display: none;
}
.console__blob-viewer .console__text-editor__header {
  display: flex;
  justify-content: space-between;
  color: var(--content-color-primary);
  align-items: center;
  height: var(--size-m);
  padding: var(--spacing-zero) var(--spacing-s);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.console__blob-viewer .console__text-editor__header .header__section-left {
  display: flex;
  line-height: var(--line-height-s);
  font-size: var(--text-size-m);
  font-weight: var(--text-weight-medium);
}
.console__blob-viewer .console__text-editor__header .header__section-right {
  display: flex;
}
.console__blob-viewer .console__text-editor__header .header__section-right__close-icon {
  cursor: pointer;
}
.console__blob-viewer .console__text-editor__container {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: var(--spacing-s);
}
.console__blob-viewer .console__text-editor__container .console__text-editor {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.console-header {
  display: flex;
  align-items: center;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
  padding: 10px 10px 9px 10px;
  background: var(--background-color-primary);
  z-index: 10;
}
.console-header .console-header__left {
  flex: 2;
  display: flex;
  align-items: center;
}
.console-header .console-header__left .search-input {
  margin-right: var(--spacing-m);
  flex-grow: 1;
}
.console-header .console-header__left .search-input .input-search-group {
  border-radius: var(--border-radius-default);
  padding-right: var(--spacing-zero);
}
.console-header .console-header__left .search-input .input-search-group .input-search-group__search-cancel-wrapper {
  padding-right: var(--spacing-s);
}
.console-header .console-header__left .search-input .input-search-group.is-focused.is-highlighted {
  border: var(--border-width-default) var(--border-style-solid) var(--base-color-brand);
  border-radius: var(--border-radius-default);
}
.console-header .console-header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}
.console-header .console-header__right .clear-btn {
  min-width: 70px;
  margin-left: var(--spacing-m);
}
.console-header .console-header__right .copy-btn {
  margin-left: var(--spacing-s);
}

/* stylelint-disable postman-stylelint/color-no-hex-rgb */
.console-list {
  flex: 1;
  overflow-y: auto;
  position: relative;
}
.console-list.is-disabled {
  opacity: 0.4;
}

.console-list__empty {
  font-size: var(--text-size-m);
  color: var(--content-color-primary);
  text-align: center;
  padding: var(--spacing-l);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.console-list__empty .console-list__empty--description {
  margin-top: var(--spacing-s);
}

.console-list__empty__container {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
}
.console-list__empty__container .console-list__empty {
  margin: auto;
}

.console-list__loading {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
}

.console-list-item {
  font-size: var(--text-size-m);
  line-height: var(--line-height-xs);
  font-family: var(--text-family-code);
  color: var(--content-color-primary);
}
.console-list-item .c-flex {
  padding: 6px 10px var(--spacing-xs);
  display: flex;
  align-items: flex-start;
  min-height: var(--size-s);
  box-sizing: border-box;
  border-bottom: var(--border-width-default) var(--border-style-solid) var(--border-color-default);
}
.console-list-item .c-flex .c-flex__left {
  flex: 1;
  display: flex;
}
.console-list-item .c-flex .c-flex__left .c-li__icon {
  margin-right: 5px;
}
.console-list-item .c-flex .c-flex__right {
  display: flex;
  flex: 0;
}
.console-list-item .c-flex .c-flex__right .c-li__icon {
  margin-left: var(--spacing-s);
}
.console-list-item .c-flex.is-error {
  background-color: var(--background-color-error);
  color: var(--content-color-error);
}
.console-list-item .c-flex.is-warning {
  background-color: var(--background-color-warning);
}
.console-list-item .c-flex.is-cloud {
  background-color: var(--background-color-tertiary);
}
.console-list-item .c-flex.is-indirect .c-li__network-msg {
  opacity: 0.6;
}
.console-list-item .c-li__icon {
  width: 12px;
}
.console-list-item .c-li__icon.empty {
  padding: var(--spacing-zero) 6px;
  box-sizing: border-box;
}
.console-list-item .c-li__timestamp {
  margin-right: var(--spacing-s);
  color: var(--content-color-tertiary);
}
.console-list-item .c-li__msg-wrap {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
}
.console-list-item .c-li__msg-wrap .c-li__r-fill {
  height: 4px;
  width: 0;
  overflow: hidden;
  flex-basis: 100%;
}
.console-list-item .c-li__msg-wrap .c-li__msg-obj {
  margin-right: var(--spacing-m);
  white-space: pre-wrap;
  word-break: break-all;
}
.console-list-item .c-li__msg-wrap .c-li__msg-obj li > ol > li {
  margin: var(--spacing-xs) var(--spacing-zero) var(--spacing-zero) !important;
}
.console-list-item .c-li__msg-wrap .c-li__msg-str {
  margin-right: var(--spacing-m);
}
.console-list-item .c-li__network-wrap {
  flex: 1;
}
.console-list-item .c-li__network-wrap .c-li__network-msg {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  word-break: break-all;
}
.console-list-item .c-li__network-wrap .c-li__network-msg .network-msg__caret {
  margin-top: -2px;
}
.console-list-item .c-li__network-wrap .c-li__network-string {
  padding-left: 18px;
}
.console-list-item .c-li__network-wrap .c-li__network-string .network-string__name {
  cursor: pointer;
  margin-left: -18px;
  display: flex;
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  align-items: center;
}
.console-list-item .c-li__network-wrap .c-li__network-string .network-string__name .info-button {
  margin-left: var(--spacing-s);
}
.console-list-item .c-li__network-wrap .c-li__network-string .network-string__value {
  word-break: break-all;
  color: var(--content-color-success);
  line-height: var(--line-height-m);
}
.console-list-item .c-li__network-wrap .c-li__network-string .network-string__value.body {
  background: var(--background-color-tertiary);
  padding: var(--spacing-s);
  color: var(--content-color-primary);
  border-radius: var(--border-radius-default);
  margin: 2px var(--spacing-zero) var(--spacing-zero) -var(--spacing-s);
}
.console-list-item .c-li__network-wrap .c-li__network-string .network__value {
  word-break: break-all;
  color: var(--content-color-info);
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded {
  margin-left: var(--spacing-s);
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-tabs {
  margin: var(--spacing-xs) var(--spacing-zero);
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded {
  display: flex;
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left {
  flex: 1;
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left li > ol > li {
  line-height: var(--line-height-m);
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left .console-net-item {
  word-break: break-all;
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left .console-net-item .console-net-item__body {
  padding-bottom: var(--spacing-zero);
  border-bottom: none;
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left .console-net-item .console-net-item__body-left__request,
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left .console-net-item .console-net-item__body-left__request-body,
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left .console-net-item .console-net-item__body-left__response,
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left .console-net-item .console-net-item__body-left__response-body {
  padding-left: var(--spacing-zero);
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__left > * {
  margin: 7px var(--spacing-zero);
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__right {
  margin-top: 7px;
  min-width: 52px;
  text-align: right;
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-expanded .network-expanded__right .network__switch-view {
  font-weight: var(--text-weight-medium);
  padding: var(--spacing-zero);
}
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-error,
.console-list-item .c-li__network-wrap .c-li__network-wrap--expanded .c-li__network-warning {
  margin: var(--spacing-s) var(--spacing-zero);
}
.console-list-item .c-li__network-wrap .network-expand__caret {
  display: inline-block;
  font-size: 8px;
  margin-right: var(--spacing-xs);
  user-select: none;
  transform: rotateZ(0deg);
  color: var(--content-color-secondary);
}
.console-list-item .c-li__network-wrap .network-expand__caret.down {
  transform: rotateZ(90deg);
}
.console-list-item .c-li__network-wrap .network__message {
  flex: 1;
}
.console-list-item .c-li__network-wrap .network__response-meta {
  color: var(--content-color-success);
  display: flex;
}
.console-list-item .c-li__network-wrap .network__response-meta .separator {
  margin: var(--spacing-zero) var(--spacing-s);
  height: 11px;
}
.console-list-item .c-li__empty::after {
  content: "∅";
  opacity: 0.6;
}
.console-list-item .c-li__clear-msg {
  font-style: italic;
  opacity: 0.6;
}

.wf__item {
  white-space: pre-wrap;
}
.wf__item .wf__sp {
  background: radial-gradient(circle, var(--content-color-primary), rgba(192, 192, 0, 0) 1px);
  opacity: 0.6;
}
.wf__item .wf__tb {
  font-size: 6px;
}
.wf__item .wf__nl {
  display: block;
  width: 8px;
  position: relative;
}
.wf__item .wf__tk {
  word-break: break-all;
  line-height: 14px;
}
.wf__item .wf__tb::before {
  content: "→";
  opacity: 0.6;
  font-size: var(--text-size-xs);
  position: absolute;
}
.wf__item .wf__nl::before {
  content: "↵";
  opacity: 0.4;
  font-size: var(--text-size-xs);
  position: absolute;
  padding-left: 0.5px;
}
.wf__item .wf__ch {
  opacity: 0.6;
  margin: 1px;
}
.wf__item .wf__qt::after {
  content: '"';
  opacity: 0.6;
}

.c__react-inspector-value {
  color: var(--content-color-success);
  word-break: break-all;
  margin-left: var(--spacing-xs);
}

.is-hidden {
  display: none;
}

.node-renderer__root-node {
  color: var(--content-color-secondary);
  line-height: var(--line-height-m);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.node-renderer__root-node .info-button {
  margin-left: var(--spacing-s);
}

.network-formatter__item {
  line-height: var(--line-height-m);
}

.console-list-item.is-overflown:last-of-type .c-flex {
  border-bottom: none;
}

.console-list__pretty span li div {
  display: inline-flex;
}
.console-list__pretty span li div span {
  line-height: var(--line-height-m);
}

.console__large-body__btn {
  font-weight: var(--text-weight-medium);
  margin-left: 2px;
  background: none;
  height: unset;
  margin-bottom: 2px;
}
.console__large-body__btn:hover {
  background: none;
}

.console__body-viewer__open-icon use {
  fill: var(--base-color-primary);
}
.console__body-viewer__open-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-primary);
}
.console__body-viewer__open-icon svg {
  color: var(--base-color-primary);
}

.console__inline__open-icon {
  vertical-align: middle;
  margin-bottom: 2px;
}
.console__inline__open-icon use {
  fill: var(--base-color-brand);
}
.console__inline__open-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.console__inline__open-icon svg {
  color: var(--base-color-brand);
}

.console__unsupported-body {
  line-height: var(--line-height-m);
  color: var(--content-color-secondary);
}

.console-footer {
  background-color: var(--background-color-secondary);
  padding: 2px 5px;
  font-size: 13px;
  border-top: var(--border-width-default) var(--border-style-solid) var(--border-color-strong);
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.console-footer .console-footer__settings {
  margin: 3px 5px 3px 4px;
  display: flex;
  color: var(--content-color-secondary);
}
.console-footer .console-footer__settings .settings__item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.console-footer .console-footer__settings .settings__item .settings__checkbox.is-selected {
  background-color: var(--base-color-brand);
}
.console-footer .console-footer__settings .settings__item .settings__label {
  margin-left: var(--spacing-xs);
}
.console-footer .console-footer__settings .settings__item.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}
.console-footer .console-footer__settings .settings__item:not(:last-of-type) {
  margin-right: var(--spacing-l);
}
.console-footer .console-footer__meta-settings {
  display: flex;
}
.console-footer .console-footer__meta-settings .meta-settings__left {
  align-items: center;
  display: flex;
  flex: 50%;
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta {
  margin: var(--spacing-zero) var(--spacing-xs);
  display: flex;
  align-items: center;
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta .meta__error-icon use {
  fill: var(--base-color-error);
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta .meta__error-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-error);
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta .meta__error-icon svg {
  color: var(--base-color-error);
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta .meta__warning-icon use {
  fill: var(--base-color-warning);
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta .meta__warning-icon svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-warning);
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta .meta__warning-icon svg {
  color: var(--base-color-warning);
}
.console-footer .console-footer__meta-settings .meta-settings__left .meta .meta__label {
  margin-left: var(--spacing-xs);
  color: var(--content-color-primary);
}
.console-footer .console-footer__meta-settings .meta-settings__right {
  justify-content: flex-end;
  align-items: center;
}
.console-footer .console-footer__meta-settings .meta-settings__right .settings-icon {
  background: none;
  max-height: 20px;
  margin: 2px var(--spacing-zero);
}
.console-footer .console-footer__meta-settings .meta-settings__right .settings-icon:hover use {
  fill: var(--base-color-brand);
}
.console-footer .console-footer__meta-settings .meta-settings__right .settings-icon:hover svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.console-footer .console-footer__meta-settings .meta-settings__right .settings-icon:hover svg {
  color: var(--base-color-brand);
}
.console-footer .console-footer__meta-settings .meta-settings__right .settings-icon .settings-icon--active use {
  fill: var(--base-color-brand);
}
.console-footer .console-footer__meta-settings .meta-settings__right .settings-icon .settings-icon--active svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--base-color-brand);
}
.console-footer .console-footer__meta-settings .meta-settings__right .settings-icon .settings-icon--active svg {
  color: var(--base-color-brand);
}

.console-actions {
  display: flex;
  align-items: center;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}
.console-actions .console-actions__meta {
  display: flex;
}
.console-actions .console-actions__meta .meta__item {
  margin-right: var(--spacing-s);
  display: flex;
  align-items: center;
}
.console-actions .console-actions__meta .meta__item .meta__error {
  color: var(--content-color-primary);
}
.console-actions .console-actions__meta .meta__item .meta__error--icon {
  margin-right: var(--spacing-xs);
}
.console-actions .console-actions__meta .meta__item .meta__warning {
  color: var(--content-color-primary);
}
.console-actions .console-actions__meta .meta__item .meta__warning--icon {
  margin-right: var(--spacing-xs);
}
.console-actions .console-actions__separator {
  height: var(--size-xs);
  width: var(--border-width-default);
  background: var(--border-color-default);
}
.console-actions .console-actions__clear-button {
  display: flex;
  margin-right: var(--spacing-s);
}
.console-actions .console-actions__clear-button .clear-btn {
  height: var(--size-s);
}
.console-actions .console-actions__settings .dropdown-button {
  display: flex;
}
.console-actions .console-actions__settings use {
  fill: var(--content-color-secondary);
}
.console-actions .console-actions__settings svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.console-actions .console-actions__settings svg {
  color: var(--content-color-secondary);
}
.console-actions .console-actions__settings:hover use, .console-actions .console-actions__settings.is-hovered use {
  fill: var(--content-color-primary);
}
.console-actions .console-actions__settings:hover svg:not([class*=PartnerIcon]) > path[fill], .console-actions .console-actions__settings.is-hovered svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-primary);
}
.console-actions .console-actions__settings:hover svg, .console-actions .console-actions__settings.is-hovered svg {
  color: var(--content-color-primary);
}
.console-actions .console-actions__open-window-button {
  height: var(--size-xs);
  width: var(--size-xs);
  cursor: pointer;
}

.console-filter .filter-btn {
  font-size: var(--text-size-s);
  font-weight: var(--font-weight-regular);
  color: var(--content-color-secondary);
  height: 22px;
}
.console-filter .filter-btn use {
  fill: var(--content-color-secondary);
}
.console-filter .filter-btn svg:not([class*=PartnerIcon]) > path[fill] {
  fill: var(--content-color-secondary);
}
.console-filter .filter-btn svg {
  color: var(--content-color-secondary);
}
.console-filter .filter-btn .dropdown-caret {
  margin-left: var(--spacing-xs);
}

.filter__menu-item {
  padding: var(--spacing-l);
}
.filter__menu-item .menu-item__wrapper {
  display: flex;
  height: 30px;
  flex: 1;
  align-items: center;
}
.filter__menu-item .menu-item__checkbox.is-selected {
  background-color: var(--checkbox-checked-background-color);
}
.filter__menu-item .menu-item__label {
  margin-left: var(--spacing-m);
}

.app-crash-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif;
  background-color: var(--background-color-primary);
  height: 100%;
}
.app-crash-wrapper .app-crash-wrapper__blank-state {
  padding: var(--spacing-l);
}
.app-crash-wrapper .app-crash-thumbnail {
  height: 200px;
  width: 200px;
  background: url("../assets/images/thumbnails/browse-view-offline.svg") no-repeat center;
  background-size: contain;
  margin-bottom: 10px;
}
.app-crash-wrapper .app-crash-content-container {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app-crash-wrapper .app-crash-header {
  font-size: 18px;
  font-weight: 600;
  color: var(--content-color-primary);
}
.app-crash-wrapper .app-crash-content {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
  color: var(--content-color-secondary);
}
.app-crash-wrapper .app-crash__learn-more-link {
  color: var(--content-color-link, #0265D2);
  cursor: pointer;
}
.app-crash-wrapper .app-crash__learn-more-link:hover {
  text-decoration: underline;
}